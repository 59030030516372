import { UserRole } from 'types/UserRoleEnum';
import { ReimbursementErrorType } from 'types/ReimbursementErrorTypeEnum';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { ISO8601DateTimeString } from 'utils/gql-date-scalar-type';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AccountsPayableNumber: { input: string; output: string; }
  /**
   * GraphQL custom BigInt scalar type
   *   Why do we need it?
   *     - Native GraphQL Int type can handle a signed 32-bit integer
   *   Why not 64 bits:
   *     - JavaScript can only handle integer values up to a magnitude of 53 bits.
   *   GraphQL BigInt can represent values between -(2^53) + 1 and 2^53 - 1.
   *
   * Important note on document Amount:
   *   - MongoDB NumberDecimal (128-bit decimal-based floating-point values) is using for storing amount
   *   - Money type (ts-money npm package) converts it to non decimal number based on currency
   *   - Example: storing 100 Euro in database will resolve as 10000 in GraphQL
   *   - The safe range for amount is: -(2 ** 53 / 100) + 1 and (2 ** 53 / 100) - 1
   *
   * TODO:
   *   - Extend graphql-bigint package with custom GraphQL error
   *   - Using Money (with BigInt) in other types
   */
  BigInt: { input: number; output: number; }
  Date: { input: ISO8601DateTimeString; output: ISO8601DateTimeString; }
  EmailAddress: { input: string; output: string; }
  IBAN: { input: string; output: string; }
  OrganizationSlug: { input: string; output: string; }
  Primitive: { input: any; output: any; }
  UUID: { input: string; output: string; }
  Void: { input: void; output: void; }
};

export type Absence = {
  __typename?: 'Absence';
  fromDate?: Maybe<Scalars['Date']['output']>;
  membershipId?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AbsenceStatus>;
  substituteMembershipId?: Maybe<Scalars['String']['output']>;
  toDate?: Maybe<Scalars['Date']['output']>;
};

export enum AbsenceStatus {
  Away = 'AWAY',
  AwaySoon = 'AWAY_SOON'
}

export type AccessLevel = {
  __typename?: 'AccessLevel';
  name: AccessLevelName;
  users: Array<ActivityUser>;
};

export type AccessLevelError = {
  __typename?: 'AccessLevelError';
  message: Scalars['String']['output'];
  type: AccessLevelErrorType;
};

export enum AccessLevelErrorType {
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export enum AccessLevelName {
  Accountants = 'ACCOUNTANTS',
  Admins = 'ADMINS',
  Approvers = 'APPROVERS',
  CardHolder = 'CARD_HOLDER',
  Editors = 'EDITORS',
  NotifiedPersons = 'NOTIFIED_PERSONS',
  Requesters = 'REQUESTERS',
  ResponsiblePersons = 'RESPONSIBLE_PERSONS',
  Uploader = 'UPLOADER',
  Viewers = 'VIEWERS'
}

export type AccessLevels = {
  __typename?: 'AccessLevels';
  id: Scalars['ID']['output'];
  levels: Array<AccessLevel>;
};

export type AccessLevelsResult = AccessLevelError | AccessLevels;

export type AccountingArea = {
  __typename?: 'AccountingArea';
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type AccountingAreaCommandResult = AccountingAreaError | AccountingAreaSuccess;

export type AccountingAreaCount = {
  __typename?: 'AccountingAreaCount';
  count: Scalars['Int']['output'];
};

export type AccountingAreaDoesNotExistError = DocumentApprovalError & {
  __typename?: 'AccountingAreaDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type AccountingAreaError = {
  __typename?: 'AccountingAreaError';
  kind: AccountingAreaErrorType;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum AccountingAreaErrorType {
  Duplicate = 'DUPLICATE',
  Forbidden = 'FORBIDDEN',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type AccountingAreaField = {
  __typename?: 'AccountingAreaField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: AccountingArea;
};

export type AccountingAreaIsArchivedError = DocumentApprovalError & {
  __typename?: 'AccountingAreaIsArchivedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type AccountingAreaRequiredError = DocumentApprovalError & {
  __typename?: 'AccountingAreaRequiredError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type AccountingAreaResult = AccountingArea | AccountingAreaError;

export type AccountingAreaSuccess = {
  __typename?: 'AccountingAreaSuccess';
  id: Scalars['ID']['output'];
};

export type AccountingAreaUpdatedEvent = DocumentTimeLineItem & {
  __typename?: 'AccountingAreaUpdatedEvent';
  accountingAreaId?: Maybe<Scalars['String']['output']>;
  accountingAreaName?: Maybe<Scalars['String']['output']>;
  clientInfo?: Maybe<ClientInfo>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<ActivityUser>;
};

export type AccountingAreasFilterInput = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountsNumberAlreadyAssigned = {
  __typename?: 'AccountsNumberAlreadyAssigned';
  accountsPayableNumber?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AccountsNumbersIdentical = {
  __typename?: 'AccountsNumbersIdentical';
  accountsNumber: Scalars['AccountsPayableNumber']['output'];
  name: Scalars['String']['output'];
};

export type AccountsPayableNumberAlreadyAssigned = {
  __typename?: 'AccountsPayableNumberAlreadyAssigned';
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AccountsPayableNumberSettings = {
  __typename?: 'AccountsPayableNumberSettings';
  enabled: Scalars['Boolean']['output'];
  startNumber: Scalars['Int']['output'];
};

export type AccountsPayableNumberSettingsInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  startNumber?: InputMaybe<Scalars['Int']['input']>;
};

export enum AcitvityEventType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  System = 'SYSTEM',
  Updated = 'UPDATED'
}

export type ActivateCardInput = {
  activationCode: Scalars['String']['input'];
  cardId: Scalars['String']['input'];
};

export type Activity = {
  __typename?: 'Activity';
  createdAt: Scalars['Date']['output'];
  events?: Maybe<Array<ActivityEvent>>;
  id: Scalars['ID']['output'];
  type: ActivityType;
};

export type ActivityComment = {
  __typename?: 'ActivityComment';
  author?: Maybe<ActivityUser>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ActivityContact = {
  __typename?: 'ActivityContact';
  id: Scalars['ID']['output'];
  name: ActivityContactName;
};

export type ActivityContactName = {
  __typename?: 'ActivityContactName';
  value: Scalars['String']['output'];
};

export type ActivityCostCenter = {
  __typename?: 'ActivityCostCenter';
  id: Scalars['ID']['output'];
  readableName: Scalars['String']['output'];
};

export type ActivityEvent = {
  __typename?: 'ActivityEvent';
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<AcitvityEventType>;
  values?: Maybe<EventValues>;
};

export type ActivityExpenseItemArchived = {
  __typename?: 'ActivityExpenseItemArchived';
  archivedAt?: Maybe<Scalars['Date']['output']>;
};

export type ActivityExpenseItemBookingAdded = {
  __typename?: 'ActivityExpenseItemBookingAdded';
  booking?: Maybe<ExpenseItemBooking>;
  currency?: Maybe<Scalars['String']['output']>;
};

export type ActivityExpenseItemBookingRemoved = {
  __typename?: 'ActivityExpenseItemBookingRemoved';
  booking?: Maybe<ExpenseItemBooking>;
  currency?: Maybe<Scalars['String']['output']>;
};

export type ActivityExpenseItemBookingUpdated = {
  __typename?: 'ActivityExpenseItemBookingUpdated';
  bookingId: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  newBooking?: Maybe<ExpenseItemBooking>;
  oldBooking?: Maybe<ExpenseItemBooking>;
};

export type ActivityExpenseItemExpenseDateUpdated = {
  __typename?: 'ActivityExpenseItemExpenseDateUpdated';
  newExpenseDate?: Maybe<Scalars['Date']['output']>;
  oldExpenseDate?: Maybe<Scalars['Date']['output']>;
};

export type ActivityExpenseItemFileAdded = {
  __typename?: 'ActivityExpenseItemFileAdded';
  fileId?: Maybe<Scalars['String']['output']>;
};

export type ActivityExpenseItemFileRemoved = {
  __typename?: 'ActivityExpenseItemFileRemoved';
  fileId?: Maybe<Scalars['String']['output']>;
};

export type ActivityExpenseItemGeneralDataExtracted = {
  __typename?: 'ActivityExpenseItemGeneralDataExtracted';
  currency?: Maybe<Scalars['String']['output']>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  sourceFileId?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Money>;
};

export type ActivityExpenseItemHospitalityDataExtracted = {
  __typename?: 'ActivityExpenseItemHospitalityDataExtracted';
  currency?: Maybe<Scalars['String']['output']>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  receiptAmount?: Maybe<Money>;
  sourceFileId?: Maybe<Scalars['String']['output']>;
  tipAmount?: Maybe<Money>;
};

export type ActivityExpenseItemHospitalityExternalGuestsUpdated = {
  __typename?: 'ActivityExpenseItemHospitalityExternalGuestsUpdated';
  newExternalGuests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  oldExternalGuests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ActivityExpenseItemHospitalityInternalGuestsUpdated = {
  __typename?: 'ActivityExpenseItemHospitalityInternalGuestsUpdated';
  newInternalGuests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  oldInternalGuests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ActivityExpenseItemHospitalityLocationUpdated = {
  __typename?: 'ActivityExpenseItemHospitalityLocationUpdated';
  newLocation?: Maybe<Scalars['String']['output']>;
  oldLocation?: Maybe<Scalars['String']['output']>;
};

export type ActivityExpenseItemHospitalityReceiptAmountUpdated = {
  __typename?: 'ActivityExpenseItemHospitalityReceiptAmountUpdated';
  newCurrency?: Maybe<Scalars['String']['output']>;
  newReceiptAmount?: Maybe<Scalars['Float']['output']>;
  oldCurrency?: Maybe<Scalars['String']['output']>;
  oldReceiptAmount?: Maybe<Scalars['Float']['output']>;
};

export type ActivityExpenseItemHospitalityTipAmountUpdated = {
  __typename?: 'ActivityExpenseItemHospitalityTipAmountUpdated';
  newCurrency?: Maybe<Scalars['String']['output']>;
  newTipAmount?: Maybe<Scalars['Float']['output']>;
  oldCurrency?: Maybe<Scalars['String']['output']>;
  oldTipAmount?: Maybe<Scalars['Float']['output']>;
};

export type ActivityExpenseItemInvoiceNumberUpdated = {
  __typename?: 'ActivityExpenseItemInvoiceNumberUpdated';
  newInvoiceNumber?: Maybe<Scalars['String']['output']>;
  oldInvoiceNumber?: Maybe<Scalars['String']['output']>;
};

export type ActivityExpenseItemTitleUpdated = {
  __typename?: 'ActivityExpenseItemTitleUpdated';
  newTitle?: Maybe<Scalars['String']['output']>;
  oldTitle?: Maybe<Scalars['String']['output']>;
};

export type ActivityExpenseItemTotalAmountUpdated = {
  __typename?: 'ActivityExpenseItemTotalAmountUpdated';
  newCurrency?: Maybe<Scalars['String']['output']>;
  newTotalAmount?: Maybe<Scalars['Float']['output']>;
  oldCurrency?: Maybe<Scalars['String']['output']>;
  oldTotalAmount?: Maybe<Scalars['Float']['output']>;
};

export type ActivityPerDiemItemMealUpdated = {
  __typename?: 'ActivityPerDiemItemMealUpdated';
  updatedDays: Array<PerDiemUpdatedDay>;
};

export type ActivityReimbursementCaseAmendmentRequested = {
  __typename?: 'ActivityReimbursementCaseAmendmentRequested';
  comment: ActivityComment;
  requestedAt: Scalars['Date']['output'];
};

export type ActivityReimbursementCaseApproved = {
  __typename?: 'ActivityReimbursementCaseApproved';
  approvedAt: Scalars['Date']['output'];
};

export type ActivityReimbursementCaseCommentAdded = {
  __typename?: 'ActivityReimbursementCaseCommentAdded';
  comment: ActivityComment;
};

export type ActivityReimbursementCaseCreated = {
  __typename?: 'ActivityReimbursementCaseCreated';
  createdAt: Scalars['Date']['output'];
};

export type ActivityReimbursementCaseItemAdded = {
  __typename?: 'ActivityReimbursementCaseItemAdded';
  expenseItemId?: Maybe<Scalars['String']['output']>;
  expenseItemType?: Maybe<ReimbursementItemType>;
};

export type ActivityReimbursementCaseItemExcluded = {
  __typename?: 'ActivityReimbursementCaseItemExcluded';
  expenseItemId?: Maybe<Scalars['String']['output']>;
  expenseItemType?: Maybe<ReimbursementItemType>;
};

export type ActivityReimbursementCaseItemExportStatusUpdated = {
  __typename?: 'ActivityReimbursementCaseItemExportStatusUpdated';
  exportGuid?: Maybe<Scalars['String']['output']>;
  exportType?: Maybe<ReimbursementCaseItemExportType>;
  failedItems?: Maybe<Array<Maybe<ExportStatusFailedItem>>>;
  successfulItems?: Maybe<Array<Maybe<ExportStatusSuccessfulItem>>>;
};

export type ActivityReimbursementCaseItemIncluded = {
  __typename?: 'ActivityReimbursementCaseItemIncluded';
  expenseItemId?: Maybe<Scalars['String']['output']>;
  expenseItemType?: Maybe<ReimbursementItemType>;
};

export type ActivityReimbursementCaseItemRemoved = {
  __typename?: 'ActivityReimbursementCaseItemRemoved';
  expenseItemId?: Maybe<Scalars['String']['output']>;
  expenseItemType?: Maybe<ReimbursementItemType>;
};

export type ActivityReimbursementCaseNoteUpdated = {
  __typename?: 'ActivityReimbursementCaseNoteUpdated';
  newNote?: Maybe<Scalars['String']['output']>;
  oldNote?: Maybe<Scalars['String']['output']>;
};

export type ActivityReimbursementCaseRejected = {
  __typename?: 'ActivityReimbursementCaseRejected';
  comment: ActivityComment;
  rejectedAt: Scalars['Date']['output'];
  rejectedBy: ActivityUser;
};

export type ActivityReimbursementCaseResetApproval = {
  __typename?: 'ActivityReimbursementCaseResetApproval';
  resetAt?: Maybe<Scalars['Date']['output']>;
};

export type ActivityReimbursementCaseSubmittedForApproval = {
  __typename?: 'ActivityReimbursementCaseSubmittedForApproval';
  approvers?: Maybe<Array<ActivityUser>>;
  submittedAt: Scalars['Date']['output'];
};

export type ActivityReimbursementCaseSubmittedForReview = {
  __typename?: 'ActivityReimbursementCaseSubmittedForReview';
  submittedAt: Scalars['Date']['output'];
};

export type ActivityReimbursementCaseTargetMembershipUpdated = {
  __typename?: 'ActivityReimbursementCaseTargetMembershipUpdated';
  newTargetMembership?: Maybe<ActivityUser>;
  oldTargetMembership?: Maybe<ActivityUser>;
};

export type ActivityReimbursementCaseTitleUpdated = {
  __typename?: 'ActivityReimbursementCaseTitleUpdated';
  newTitle?: Maybe<Scalars['String']['output']>;
  oldTitle?: Maybe<Scalars['String']['output']>;
};

export type ActivityReimbursementCaseTriggeredWorkflow = {
  __typename?: 'ActivityReimbursementCaseTriggeredWorkflow';
  submittedAt: Scalars['Date']['output'];
  workflow: ActivityWorkflow;
};

export type ActivityReimbursementCaseWorkflowStepApproved = {
  __typename?: 'ActivityReimbursementCaseWorkflowStepApproved';
  approvedAt: Scalars['Date']['output'];
  /** The user that approved the step */
  approvedBy: ActivityUser;
};

export type ActivityReimbursementCaseWorkflowStepRejected = {
  __typename?: 'ActivityReimbursementCaseWorkflowStepRejected';
  rejectedAt: Scalars['Date']['output'];
  /** The user that rejected the step */
  rejectedBy: ActivityUser;
};

export type ActivityReimbursementCaseWorkflowStepSkipped = {
  __typename?: 'ActivityReimbursementCaseWorkflowStepSkipped';
  approvers?: Maybe<Array<Maybe<ActivityUser>>>;
  skippedAt: Scalars['Date']['output'];
  workflowStep?: Maybe<ActivityWorkflowStep>;
};

export type ActivityTransactionAmountUpdated = {
  __typename?: 'ActivityTransactionAmountUpdated';
  newAmount: TransactionAmount;
};

export type ActivityTransactionMarkedAsExported = {
  __typename?: 'ActivityTransactionMarkedAsExported';
  exportId: Scalars['String']['output'];
};

export type ActivityTransactionMarkedAsInvoiceNotNeeded = {
  __typename?: 'ActivityTransactionMarkedAsInvoiceNotNeeded';
  reason: CardIssuerTransactionInvoiceNotNeededReason;
  reasonComment?: Maybe<Scalars['String']['output']>;
};

export type ActivityTransactionReconciled = {
  __typename?: 'ActivityTransactionReconciled';
  amount: TransactionAmount;
  merchantName?: Maybe<Scalars['String']['output']>;
  transactionCreatedAt: Scalars['Date']['output'];
};

export type ActivityTransactionStatusUpdated = {
  __typename?: 'ActivityTransactionStatusUpdated';
  newStatus: Scalars['String']['output'];
};

export enum ActivityType {
  Ai = 'AI',
  Neutral = 'Neutral',
  User = 'User'
}

export type ActivityUser = {
  __typename?: 'ActivityUser';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ActivityWorkflow = {
  __typename?: 'ActivityWorkflow';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ActivityWorkflowStep = {
  __typename?: 'ActivityWorkflowStep';
  conditions?: Maybe<Array<WorkflowStepCondition>>;
  resolution?: Maybe<WorkflowStepResolution>;
};

export type AddRoleMembershipInput = {
  membershipId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type AdditionalContactInfo = {
  __typename?: 'AdditionalContactInfo';
  companyName?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<ContactType>;
  individualFirstName?: Maybe<Scalars['String']['output']>;
  individualLastName?: Maybe<Scalars['String']['output']>;
  notSpecifiedName?: Maybe<Scalars['String']['output']>;
  paymentMedium?: Maybe<ContactPaymentMedium>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type AdditionalErpExpense = {
  __typename?: 'AdditionalERPExpense';
  createdAt?: Maybe<Scalars['Date']['output']>;
  distributionRule?: Maybe<Scalars['String']['output']>;
  distributionRule2?: Maybe<Scalars['String']['output']>;
  expenseCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputVatGroup?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  outputVatGroup?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AdditionalExpenseInfo = {
  __typename?: 'AdditionalExpenseInfo';
  expenseCode?: Maybe<Scalars['String']['output']>;
  expenseType: SapExpenseType;
};

export type AggregatedEcmDocument = {
  __typename?: 'AggregatedEcmDocument';
  accessModificationPermissions: EcmDocumentAccessModificationPermissions;
  amount?: Maybe<Money>;
  archivedTags: Array<Tag>;
  contactId?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  costCenter?: Maybe<CostCenter>;
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  documentDate?: Maybe<Scalars['Date']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentStatus?: Maybe<EcmDocumentStatus>;
  documentSubCategory?: Maybe<EcmDocumentSubCategoryField>;
  /** @deprecated Field no longer supported */
  documentType: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  extractedContact?: Maybe<EcmDocumentExtractedContact>;
  fileSource?: Maybe<EcmFileSource>;
  /** Global identifier of the document in Candis */
  globalDocumentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isSensitive?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notifyPerson?: Maybe<EcmDocumentNotifyPerson>;
  /** Returns a list of actions the current membership is authorized to perform on the document */
  permissionsForMembership?: Maybe<Array<EcmDocumentPermission>>;
  responsiblePerson?: Maybe<EcmDocumentResponsiblePerson>;
  startDate?: Maybe<Scalars['Date']['output']>;
  tags: Array<Tag>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  terminationReminderDate?: Maybe<Scalars['Date']['output']>;
  timeline?: Maybe<Array<DocumentTimeLineItem>>;
  type: DocumentType;
  updatedAt: Scalars['Date']['output'];
  updatedBy?: Maybe<User>;
};

export type AmountFilter = {
  maxAmount?: InputMaybe<Scalars['BigInt']['input']>;
  minAmount?: InputMaybe<Scalars['BigInt']['input']>;
};

export type AmountFilters = {
  __typename?: 'AmountFilters';
  maxAmount?: Maybe<Scalars['BigInt']['output']>;
  minAmount?: Maybe<Scalars['BigInt']['output']>;
};

export type ApiError = {
  __typename?: 'ApiError';
  message: Scalars['String']['output'];
  response: ApiErrorResponse;
  stack: Scalars['String']['output'];
};

export type ApiErrorConfig = {
  __typename?: 'ApiErrorConfig';
  method: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ApiErrorResponse = {
  __typename?: 'ApiErrorResponse';
  config: ApiErrorConfig;
  data: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  statusText: Scalars['String']['output'];
};

export type ApplyForCreditCardsInput = {
  authorizedRepresentative: CreditCardApplicationAuthorizedRepresentativeData;
  comment?: InputMaybe<Scalars['String']['input']>;
  company: CreditCardApplicationCompanyData;
  otherContacts: Array<CreditCardApplicationContactData>;
};

export type ApplyForCreditCardsOutput = {
  __typename?: 'ApplyForCreditCardsOutput';
  accepted: Scalars['Boolean']['output'];
};

export type ApprovalHighlight = {
  __typename?: 'ApprovalHighlight';
  chunks: Array<HighlightChunk>;
  field: ApprovalHighlightableFields;
};

export enum ApprovalHighlightableFields {
  AccountingArea = 'ACCOUNTING_AREA',
  Amount = 'AMOUNT',
  ContactAccountsPayableNumber = 'CONTACT_ACCOUNTS_PAYABLE_NUMBER',
  ContactName = 'CONTACT_NAME',
  CostCenter = 'COST_CENTER',
  CostObject = 'COST_OBJECT',
  CreatedBy = 'CREATED_BY',
  DocumentNumber = 'DOCUMENT_NUMBER',
  ExtraCostInfo = 'EXTRA_COST_INFO',
  FileName = 'FILE_NAME',
  Iban = 'IBAN',
  PurchaseOrderNumber = 'PURCHASE_ORDER_NUMBER',
  RawContent = 'RAW_CONTENT',
  UpdatedBy = 'UPDATED_BY'
}

export type ApprovalInvoiceBooking = {
  __typename?: 'ApprovalInvoiceBooking';
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  costCenter?: Maybe<ApprovalInvoiceCostCenter>;
  costObject?: Maybe<ApprovalInvoiceCostCenter>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  extraCostInfo?: Maybe<ApprovalInvoiceCostCenter>;
  paymentConditionId?: Maybe<Scalars['String']['output']>;
};

export type ApprovalInvoiceContact = {
  __typename?: 'ApprovalInvoiceContact';
  accountsPayableNumber?: Maybe<Scalars['String']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ApprovalInvoiceCostCenter = {
  __typename?: 'ApprovalInvoiceCostCenter';
  cachingKey: Scalars['String']['output'];
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  readableName?: Maybe<Scalars['String']['output']>;
};

export type ApprovalInvoiceDocument = {
  __typename?: 'ApprovalInvoiceDocument';
  accountingArea?: Maybe<ApprovalInvoiceDocumentAccountingArea>;
  amount?: Maybe<Money>;
  approvers?: Maybe<Array<User>>;
  associatedDocuments?: Maybe<Array<Maybe<AssociatedDocument>>>;
  bookings?: Maybe<Array<Maybe<ApprovalInvoiceBooking>>>;
  contact?: Maybe<ApprovalInvoiceContact>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  eInvoice?: Maybe<DocumentEInvoiceField>;
  globalDocumentId: Scalars['String']['output'];
  /** @deprecated Do not use, it is an empty array. Use the edge highlights instead */
  highlights: Array<ApprovalHighlight>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['Date']['output']>;
  invoiceDeliveryDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  isEInvoice?: Maybe<Scalars['Boolean']['output']>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  isPayable?: Maybe<Scalars['Boolean']['output']>;
  payment?: Maybe<ApprovalInvoicePayment>;
  purchaseOrderData?: Maybe<PurchaseOrderData>;
  purchaseOrders?: Maybe<Array<Maybe<PurchaseOrder>>>;
  requestedAt?: Maybe<Scalars['Date']['output']>;
  requester?: Maybe<User>;
  status: DocumentStatus;
  tags: Array<Tag>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ApprovalInvoiceDocumentAccountingArea = {
  __typename?: 'ApprovalInvoiceDocumentAccountingArea';
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ApprovalInvoiceDocumentsResponse = {
  __typename?: 'ApprovalInvoiceDocumentsResponse';
  edges: Array<ApprovalInvoiceEdge>;
  pageInfo: PageInfo;
};

export type ApprovalInvoiceEdge = {
  __typename?: 'ApprovalInvoiceEdge';
  cursor: Scalars['String']['output'];
  highlights: Array<ApprovalHighlight>;
  node: ApprovalInvoiceDocument;
};

export type ApprovalInvoicePayment = {
  __typename?: 'ApprovalInvoicePayment';
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  method?: Maybe<PaymentMethod>;
  paidAt?: Maybe<Scalars['Date']['output']>;
};

export type ApprovalInvoiceWorkflowActor = {
  __typename?: 'ApprovalInvoiceWorkflowActor';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ApprovalInvoicesFilterInput = {
  accountingAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  accountsPayableNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  amount?: InputMaybe<AmountFilter>;
  approverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contactIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costCenterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costObjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilterInput>;
  dueDateWithCashDiscount?: InputMaybe<DateFilterInput>;
  extraCostInfoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hasTransactionLinked?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceDate?: InputMaybe<DateFilterInput>;
  invoiceDeliveryDate?: InputMaybe<DateFilterInput>;
  invoiceDueDate?: InputMaybe<DateFilterInput>;
  isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
  requestedAt?: InputMaybe<DateFilterInput>;
  requesterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<DocumentStatus>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ApprovalReimbursementCasesEdge = {
  __typename?: 'ApprovalReimbursementCasesEdge';
  cursor: Scalars['String']['output'];
  node: ApprovalReimbursementCasesListItem;
};

export type ApprovalReimbursementCasesListItem = {
  __typename?: 'ApprovalReimbursementCasesListItem';
  approvers?: Maybe<Array<User>>;
  archivedAt?: Maybe<Scalars['Date']['output']>;
  contact?: Maybe<ReimbursementCaseContact>;
  createTransfer: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  createdByMembership: User;
  firstSubmittedForReviewAt?: Maybe<Scalars['Date']['output']>;
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  lastApprovedAt?: Maybe<Scalars['Date']['output']>;
  lastApprovedByMembership?: Maybe<User>;
  lastSubmittedForApprovalAt?: Maybe<Scalars['Date']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['Date']['output']>;
  requestedAt?: Maybe<Scalars['Date']['output']>;
  requester?: Maybe<User>;
  status: ReimbursementCaseStatus;
  targetMembership?: Maybe<User>;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount: Money;
  updatedAt: Scalars['Date']['output'];
};

export type ApprovalReimbursementCasesResponse = {
  __typename?: 'ApprovalReimbursementCasesResponse';
  edges?: Maybe<Array<ApprovalReimbursementCasesEdge>>;
  pageInfo: ReimbursementCasesPageInfo;
};

export type ApproveCardLimitChangeRequestInput = {
  approvedLabel?: InputMaybe<Scalars['String']['input']>;
  approvedLimit?: InputMaybe<CardIssuerAmountInput>;
  approvedLimitRenewFrequency?: InputMaybe<CardLimitRenewFrequency>;
  approvedPurpose?: InputMaybe<Scalars['String']['input']>;
  approvedTransactionLimit?: InputMaybe<CardIssuerAmountInput>;
  cardRequestId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type ApprovePhysicalCardRequestInput = {
  approvedLabel?: InputMaybe<Scalars['String']['input']>;
  approvedLimit?: InputMaybe<CardIssuerAmountInput>;
  approvedLimitRenewFrequency?: InputMaybe<CardLimitRenewFrequency>;
  approvedTransactionLimit?: InputMaybe<CardIssuerAmountInput>;
  cardRequestId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type ApproveSingleUseVirtualCardRequestInput = {
  approvedPurpose?: InputMaybe<Scalars['String']['input']>;
  approvedTransactionLimit?: InputMaybe<CardIssuerAmountInput>;
  cardRequestId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type ApproveVirtualCardRequestInput = {
  approvedLabel?: InputMaybe<Scalars['String']['input']>;
  approvedLimit?: InputMaybe<CardIssuerAmountInput>;
  approvedLimitRenewFrequency?: InputMaybe<CardLimitRenewFrequency>;
  approvedTransactionLimit?: InputMaybe<CardIssuerAmountInput>;
  approvedValidityPeriod?: InputMaybe<Scalars['Int']['input']>;
  cardRequestId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

/** Document approval event */
export type ApprovedEvent = DocumentTimeLineItem & {
  __typename?: 'ApprovedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
  /** The users that approved the document */
  users?: Maybe<Array<Maybe<ActivityUser>>>;
};

/** Document approver extraction event */
export type ApproverExtractedEvent = DocumentTimeLineItem & {
  __typename?: 'ApproverExtractedEvent';
  /** The users who were extracted as approvers */
  approvers?: Maybe<Array<Maybe<ActivityUser>>>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

export type ArchiveAllDocumentsDateFilter = {
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};

export type ArchiveAllDocumentsEdge = {
  __typename?: 'ArchiveAllDocumentsEdge';
  cursor: Scalars['String']['output'];
  highlights: Array<ArchiveAllHighlight>;
  node: ArchiveAllDocumentsListItem;
};

export type ArchiveAllDocumentsFilterInput = {
  amount?: InputMaybe<AmountFilter>;
  contactId?: InputMaybe<Array<Scalars['String']['input']>>;
  documentDate?: InputMaybe<ArchiveAllDocumentsDateFilter>;
  documentStatus?: InputMaybe<Array<ArchiveAllDocumentsStatus>>;
  documentType?: InputMaybe<Array<Scalars['String']['input']>>;
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ArchiveAllDocumentsListItem = {
  __typename?: 'ArchiveAllDocumentsListItem';
  contactId?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['String']['output'];
  documentDate?: Maybe<Scalars['Date']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentStatus?: Maybe<ArchiveAllDocumentsStatus>;
  /** @deprecated Field no longer supported */
  documentType: Scalars['String']['output'];
  /** Global identifier of the document in Candis */
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Do not use, it is an empty array. Use the edge highlights instead */
  highlights: Array<ArchiveAllHighlight>;
  id: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  /**
   * This field is specifically for Invoices (not ECM). But is needed here because the ALL DOCUMENTS view on the UI uses the query getECMDocuments.
   * Once this view is updated to use the correct query, this field should be removed.
   */
  isEInvoice?: Maybe<Scalars['Boolean']['output']>;
  isSensitive?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  reimbursementItem?: Maybe<ArchiveAllDocumentsListItemReimbursementItem>;
  responsiblePerson?: Maybe<ArchiveAllDocumentsListItemResponsiblePerson>;
  tags: Array<Tag>;
  type: DocumentType;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['String']['output'];
};

export type ArchiveAllDocumentsListItemReimbursementItem = {
  __typename?: 'ArchiveAllDocumentsListItemReimbursementItem';
  caseId: Scalars['String']['output'];
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  type: ReimbursementItemType;
};

export type ArchiveAllDocumentsListItemResponsiblePerson = {
  __typename?: 'ArchiveAllDocumentsListItemResponsiblePerson';
  primary: Array<User>;
};

export type ArchiveAllDocumentsPageInfo = {
  __typename?: 'ArchiveAllDocumentsPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextPageCursor?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type ArchiveAllDocumentsResponse = {
  __typename?: 'ArchiveAllDocumentsResponse';
  edges?: Maybe<Array<ArchiveAllDocumentsEdge>>;
  pageInfo: ArchiveAllDocumentsPageInfo;
};

export type ArchiveAllDocumentsSortInput = {
  contact?: InputMaybe<ArchiveAllDocumentsSortOrder>;
  documentDate?: InputMaybe<ArchiveAllDocumentsSortOrder>;
  documentName?: InputMaybe<ArchiveAllDocumentsSortOrder>;
  documentNumber?: InputMaybe<ArchiveAllDocumentsSortOrder>;
  documentStatus?: InputMaybe<ArchiveAllDocumentsSortOrder>;
  documentType?: InputMaybe<ArchiveAllDocumentsSortOrder>;
};

export enum ArchiveAllDocumentsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ArchiveAllDocumentsStatus {
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Check = 'CHECK',
  Draft = 'DRAFT',
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  New = 'NEW',
  Open = 'OPEN',
  PartiallyExported = 'PARTIALLY_EXPORTED',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Review = 'REVIEW',
  Stored = 'STORED'
}

export enum ArchiveAllDocumentsType {
  Contract = 'CONTRACT',
  DeliveryNote = 'DELIVERY_NOTE',
  Empty = 'EMPTY',
  GeneralExpense = 'GENERAL_EXPENSE',
  HospitalityExpense = 'HOSPITALITY_EXPENSE',
  Invoice = 'INVOICE',
  Offer = 'OFFER',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  Other = 'OTHER',
  PurchaseOrder = 'PURCHASE_ORDER'
}

export type ArchiveAllHighlight = {
  __typename?: 'ArchiveAllHighlight';
  chunks: Array<HighlightChunk>;
  field: ArchiveAllHighlightableFields;
};

export enum ArchiveAllHighlightableFields {
  Amount = 'AMOUNT',
  ContactAccountsPayableNumber = 'CONTACT_ACCOUNTS_PAYABLE_NUMBER',
  ContactName = 'CONTACT_NAME',
  CreatedBy = 'CREATED_BY',
  DocumentName = 'DOCUMENT_NAME',
  DocumentNumber = 'DOCUMENT_NUMBER',
  FileName = 'FILE_NAME',
  Notes = 'NOTES',
  RawContent = 'RAW_CONTENT',
  UpdatedBy = 'UPDATED_BY'
}

export enum ArchiveErrorCodes {
  ContactsNotFound = 'CONTACTS_NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export type ArchiveInvoiceDocument = {
  __typename?: 'ArchiveInvoiceDocument';
  accountingArea?: Maybe<ArchiveInvoiceDocumentAccountingArea>;
  amount?: Maybe<Money>;
  approvers: Array<User>;
  associatedDocuments?: Maybe<Array<Maybe<AssociatedDocument>>>;
  bookings: Array<ArchiveInvoiceDocumentBooking>;
  contact?: Maybe<ArchiveInvoiceDocumentContact>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentFile?: Maybe<DocumentFile>;
  eInvoice?: Maybe<DocumentEInvoiceField>;
  globalDocumentId: Scalars['String']['output'];
  /** @deprecated Do not use, it is an empty array. Use the edge highlights instead */
  highlights: Array<ArchiveInvoicesHighlight>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceCategoryType?: Maybe<Scalars['String']['output']>;
  invoiceDate?: Maybe<Scalars['Date']['output']>;
  invoiceDeliveryDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  isEInvoice?: Maybe<Scalars['Boolean']['output']>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  isPayable?: Maybe<Scalars['Boolean']['output']>;
  payment?: Maybe<ArchiveInvoiceDocumentPayment>;
  purchaseOrderData?: Maybe<PurchaseOrderData>;
  purchaseOrders?: Maybe<Array<Maybe<PurchaseOrder>>>;
  requestedAt?: Maybe<Scalars['Date']['output']>;
  requester?: Maybe<User>;
  status: DocumentStatus;
  swiftCode?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ArchiveInvoiceDocumentAccountingArea = {
  __typename?: 'ArchiveInvoiceDocumentAccountingArea';
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ArchiveInvoiceDocumentBooking = {
  __typename?: 'ArchiveInvoiceDocumentBooking';
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  costCenter?: Maybe<ArchiveInvoiceDocumentCostCenter>;
  costObject?: Maybe<ArchiveInvoiceDocumentCostCenter>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  extraCostInfo?: Maybe<ArchiveInvoiceDocumentCostCenter>;
  generalLedgerAccount?: Maybe<ArchiveInvoiceDocumentGeneralLedgerAccount>;
  note?: Maybe<Scalars['String']['output']>;
  paymentConditionId?: Maybe<Scalars['String']['output']>;
  postingText?: Maybe<Scalars['String']['output']>;
};

export type ArchiveInvoiceDocumentContact = {
  __typename?: 'ArchiveInvoiceDocumentContact';
  accountsPayableNumber?: Maybe<Scalars['String']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ArchiveInvoiceDocumentCostCenter = {
  __typename?: 'ArchiveInvoiceDocumentCostCenter';
  cachingKey: Scalars['String']['output'];
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  readableName?: Maybe<Scalars['String']['output']>;
};

export type ArchiveInvoiceDocumentEdge = {
  __typename?: 'ArchiveInvoiceDocumentEdge';
  cursor: Scalars['String']['output'];
  highlights: Array<ArchiveInvoicesHighlight>;
  node: ArchiveInvoiceDocument;
};

export type ArchiveInvoiceDocumentGeneralLedgerAccount = {
  __typename?: 'ArchiveInvoiceDocumentGeneralLedgerAccount';
  accountCode?: Maybe<Scalars['String']['output']>;
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  readableName?: Maybe<Scalars['String']['output']>;
};

export type ArchiveInvoiceDocumentPayment = {
  __typename?: 'ArchiveInvoiceDocumentPayment';
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  method?: Maybe<PaymentMethod>;
  paidAt?: Maybe<Scalars['Date']['output']>;
};

export type ArchiveInvoiceDocumentsFilterInput = {
  accountingAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  accountsPayableNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  amount?: InputMaybe<AmountFilter>;
  approverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  artistSocialInsuranceCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  cardTransactionStatus?: InputMaybe<Array<TransactionStatus>>;
  contactIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costCenterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costObjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilterInput>;
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDateWithCashDiscount?: InputMaybe<DateFilterInput>;
  extraCostInfoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  generalLedgerAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hasTransactionLinked?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceDate?: InputMaybe<DateFilterInput>;
  invoiceDeliveryDate?: InputMaybe<DateFilterInput>;
  invoiceDueDate?: InputMaybe<DateFilterInput>;
  isDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  isInvoiceCorrection?: InputMaybe<Scalars['Boolean']['input']>;
  isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  isPayable?: InputMaybe<Scalars['Boolean']['input']>;
  isWaitingForClarification?: InputMaybe<Scalars['Boolean']['input']>;
  paidAt?: InputMaybe<DateFilterInput>;
  requesterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<DocumentStatus>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ArchiveInvoiceDocumentsResponse = {
  __typename?: 'ArchiveInvoiceDocumentsResponse';
  edges?: Maybe<Array<ArchiveInvoiceDocumentEdge>>;
  pageInfo: PageInfo;
};

export type ArchiveInvoicesHighlight = {
  __typename?: 'ArchiveInvoicesHighlight';
  chunks: Array<HighlightChunk>;
  field: ArchiveInvoicesHighlightableFields;
};

export enum ArchiveInvoicesHighlightableFields {
  AccountingArea = 'ACCOUNTING_AREA',
  Amount = 'AMOUNT',
  ContactAccountsPayableNumber = 'CONTACT_ACCOUNTS_PAYABLE_NUMBER',
  ContactName = 'CONTACT_NAME',
  CostCenter = 'COST_CENTER',
  CostObject = 'COST_OBJECT',
  CreatedBy = 'CREATED_BY',
  DocumentNumber = 'DOCUMENT_NUMBER',
  ExtraCostInfo = 'EXTRA_COST_INFO',
  FileName = 'FILE_NAME',
  GeneralLedgerAccount = 'GENERAL_LEDGER_ACCOUNT',
  Iban = 'IBAN',
  Notes = 'NOTES',
  PostingText = 'POSTING_TEXT',
  PurchaseOrderNumber = 'PURCHASE_ORDER_NUMBER',
  RawContent = 'RAW_CONTENT',
  UpdatedBy = 'UPDATED_BY'
}

export type ArchiveOrDeleteReimbursementCaseResponse = {
  __typename?: 'ArchiveOrDeleteReimbursementCaseResponse';
  archived: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
};

export type ArchiveReimbursementCasesEdge = {
  __typename?: 'ArchiveReimbursementCasesEdge';
  cursor: Scalars['String']['output'];
  node: ArchiveReimbursementCasesListItem;
};

export type ArchiveReimbursementCasesListItem = {
  __typename?: 'ArchiveReimbursementCasesListItem';
  approvers?: Maybe<Array<User>>;
  archivedAt?: Maybe<Scalars['Date']['output']>;
  contact?: Maybe<ReimbursementCaseContact>;
  createTransfer: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  createdByMembership: User;
  firstSubmittedForReviewAt?: Maybe<Scalars['Date']['output']>;
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  lastApprovedAt?: Maybe<Scalars['Date']['output']>;
  lastApprovedByMembership?: Maybe<User>;
  lastClosedAt?: Maybe<Scalars['Date']['output']>;
  lastSubmittedForApprovalAt?: Maybe<Scalars['Date']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['Date']['output']>;
  requestedAt?: Maybe<Scalars['Date']['output']>;
  requester?: Maybe<User>;
  status: ReimbursementCaseStatus;
  targetMembership?: Maybe<User>;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount: Money;
  updatedAt: Scalars['Date']['output'];
};

export type ArchiveReimbursementCasesResponse = {
  __typename?: 'ArchiveReimbursementCasesResponse';
  edges?: Maybe<Array<ArchiveReimbursementCasesEdge>>;
  pageInfo: ReimbursementCasesPageInfo;
};

export enum ArtistSocialInsuranceCode {
  Bk = 'BK',
  Dk = 'DK',
  M = 'M',
  W = 'W'
}

export type AssociatedDocument = GoodsReceipt | PurchaseOrder;

export enum AssociationSource {
  AdditionalExpense = 'ADDITIONAL_EXPENSE',
  GoodsReceipt = 'GOODS_RECEIPT',
  PurchaseOrder = 'PURCHASE_ORDER'
}

export type AttachFilesInput = {
  documentId: Scalars['String']['input'];
  fileIds: Array<Scalars['String']['input']>;
};

export type AttachmentConvertedToDocumentEvent = DocumentTimeLineItem & {
  __typename?: 'AttachmentConvertedToDocumentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The document that this document was attached to */
  source?: Maybe<DocumentData>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type AttachmentDeletedFromDocumentEvent = DocumentTimeLineItem & {
  __typename?: 'AttachmentDeletedFromDocumentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The file that was uploaded */
  fileName?: Maybe<Scalars['String']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type AttachmentDetachedFromDocumentEvent = DocumentTimeLineItem & {
  __typename?: 'AttachmentDetachedFromDocumentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The file that was uploaded */
  fileName?: Maybe<Scalars['String']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type AttachmentDocumentNotFoundError = {
  __typename?: 'AttachmentDocumentNotFoundError';
  errorCode: Scalars['String']['output'];
};

export type AttachmentDocumentRemovedError = {
  __typename?: 'AttachmentDocumentRemovedError';
  errorCode: Scalars['String']['output'];
  /** The document was deleted on this date. For older documents we might not have this information available */
  removedAt?: Maybe<Scalars['Date']['output']>;
  /** Id of the user that removed the document. For older documents we moight not have this information available */
  removedBy?: Maybe<Scalars['String']['output']>;
};

export type AuditReportRequest = {
  __typename?: 'AuditReportRequest';
  ecmDocumentIds?: Maybe<Array<Scalars['String']['output']>>;
  fromDate: Scalars['Date']['output'];
  invoiceIds?: Maybe<Array<Scalars['String']['output']>>;
  organizationId: Scalars['String']['output'];
  recipientEmail: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  requestedFor: Scalars['String']['output'];
  status: Scalars['String']['output'];
  toDate: Scalars['Date']['output'];
  transactionIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type AuthenticatorDeviceInfo = {
  __typename?: 'AuthenticatorDeviceInfo';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AuthorizationUser = {
  __typename?: 'AuthorizationUser';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AvailableDocumentTypes = {
  __typename?: 'AvailableDocumentTypes';
  documentCategory?: Maybe<DocumentCategory>;
  documentType: Scalars['String']['output'];
};

export type AvailableMonth = {
  __typename?: 'AvailableMonth';
  month: Scalars['Int']['output'];
  numberOfDocumentProvisions: Scalars['Int']['output'];
  numberOfDocumentReversals: Scalars['Int']['output'];
  numberOfDocuments: Scalars['Int']['output'];
  numberOfReimbursementItems: Scalars['Int']['output'];
  numberOfSettlements: Scalars['Int']['output'];
  numberOfTxnBasedDocuments: Scalars['Int']['output'];
  numberOfTxnProvisions: Scalars['Int']['output'];
  numberOfTxnReversals: Scalars['Int']['output'];
  numberOfTxnWithoutDocuments: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export enum BdsPreRequisiteStep {
  ConnectBds = 'CONNECT_BDS',
  ImportContacts = 'IMPORT_CONTACTS',
  ImportPaymentConditions = 'IMPORT_PAYMENT_CONDITIONS',
  OrderBds = 'ORDER_BDS',
  SetupProvisions = 'SETUP_PROVISIONS'
}

export type BasicAccountingInformation = {
  __typename?: 'BasicAccountingInformation';
  account_length: Scalars['Int']['output'];
  fiscal_year_end: Scalars['String']['output'];
  fiscal_year_start: Scalars['String']['output'];
  ledgers: DatevClientLedgerInfo;
};

export enum BdsPermissionError {
  ClientPermissionMissing = 'CLIENT_PERMISSION_MISSING',
  DatevSettingsNotFound = 'DATEV_SETTINGS_NOT_FOUND',
  NoTestExtfJobFound = 'NO_TEST_EXTF_JOB_FOUND',
  OnlineRightOrLicenseMissing = 'ONLINE_RIGHT_OR_LICENSE_MISSING',
  UnknownError = 'UNKNOWN_ERROR',
  WrongConnectionType = 'WRONG_CONNECTION_TYPE'
}

export type BdsPermissionErrorResult = {
  __typename?: 'BdsPermissionErrorResult';
  error: BdsPermissionError;
  status: BdsPermissionStatus;
};

export type BdsPermissionResult = BdsPermissionErrorResult | BdsPermissionSuccessResult;

export enum BdsPermissionStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type BdsPermissionSuccessResult = {
  __typename?: 'BdsPermissionSuccessResult';
  status: BdsPermissionStatus;
};

export type BigIntRangeInput = {
  max?: InputMaybe<Scalars['BigInt']['input']>;
  min?: InputMaybe<Scalars['BigInt']['input']>;
};

export type Booking = {
  __typename?: 'Booking';
  amount?: Maybe<FloatField>;
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  bookingKey?: Maybe<BookingKeyField>;
  costCenter?: Maybe<CostCenterField>;
  costObject?: Maybe<CostCenterField>;
  discountAmount?: Maybe<FloatField>;
  discountPaymentDate?: Maybe<DateField>;
  discountPercentage?: Maybe<FloatField>;
  dueDate?: Maybe<DateField>;
  extraCostInfo?: Maybe<CostCenterField>;
  generalLedgerAccount?: Maybe<BookingAccountField>;
  id: Scalars['ID']['output'];
  netAmount?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<StringField>;
  paymentConditionId?: Maybe<StringField>;
  postingText?: Maybe<Scalars['String']['output']>;
  projectCode?: Maybe<ProjectCodeField>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  vatRate?: Maybe<FloatField>;
};

export type BookingAccount = {
  __typename?: 'BookingAccount';
  accountCode: Scalars['String']['output'];
  /** @deprecated Use `accountCode` */
  accountNumber?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  readableName: Scalars['String']['output'];
  type: BookingAccountType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type BookingAccountCommandResult = BookingAccountError | BookingAccountSuccess;

export type BookingAccountError = {
  __typename?: 'BookingAccountError';
  accountCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `accountCode` */
  accountNumber?: Maybe<Scalars['Int']['output']>;
  kind: BookingAccountErrorType;
  message?: Maybe<Scalars['String']['output']>;
};

export enum BookingAccountErrorType {
  Duplicate = 'DUPLICATE',
  Forbidden = 'FORBIDDEN',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type BookingAccountFetchResult = BookingAccount | BookingAccountError;

export type BookingAccountField = {
  __typename?: 'BookingAccountField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: BookingAccount;
};

export type BookingAccountFilterInput = {
  id?: InputMaybe<Array<Scalars['String']['input']>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BookingAccountImportHistory = {
  __typename?: 'BookingAccountImportHistory';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy: User;
  file: BookingAccountImportHistoryFile;
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type BookingAccountImportHistoryFile = {
  __typename?: 'BookingAccountImportHistoryFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BookingAccountImportResult = BookingAccountError | BookingAccountsBulkImportReport;

export type BookingAccountPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingAccountPaginationResult = {
  __typename?: 'BookingAccountPaginationResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<BookingAccount>;
};

export type BookingAccountQueryInput = {
  accountCode?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated "Use `accountCode`" */
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  readableName?: InputMaybe<Scalars['String']['input']>;
};

export enum BookingAccountSearchField {
  AccountCode = 'ACCOUNT_CODE',
  /** @deprecated Use `accountCode` */
  AccountNumber = 'ACCOUNT_NUMBER',
  Name = 'NAME',
  ReadableName = 'READABLE_NAME'
}

export type BookingAccountSearchInput = {
  field: BookingAccountSearchField;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum BookingAccountSortField {
  AccountCode = 'ACCOUNT_CODE',
  /** @deprecated Use `accountCode` */
  AccountNumber = 'ACCOUNT_NUMBER',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type BookingAccountSortInput = {
  direction: SortDirection;
  field: BookingAccountSortField;
};

export type BookingAccountSuccess = {
  __typename?: 'BookingAccountSuccess';
  id: Scalars['ID']['output'];
};

export enum BookingAccountType {
  GeneralLedger = 'GENERAL_LEDGER'
}

export type BookingAccountValidation = {
  __typename?: 'BookingAccountValidation';
  maxLength: Scalars['Int']['output'];
  minLength: Scalars['Int']['output'];
};

export type BookingAccountsBulkImportReport = {
  __typename?: 'BookingAccountsBulkImportReport';
  errors: Array<BookingAccountError>;
  imported: Array<BookingAccountSuccess>;
};

export type BookingErrorTypes = BookingKeyArchivedError | BookingKeyChangedError | BookingKeyDocumentDirectionError | BookingKeyDoesNotExistError | BookingKeyRequiredError | CashDiscountAllFieldsDoesNotExistError | CostCenterArchivedError | CostCenterChangedError | CostCenterDoesNotExistError | CostCenterRequiredError | CostObjectArchivedError | CostObjectChangedError | CostObjectDoesNotExistError | CostObjectRequiredError | DocumentAcquisitionReversalValidationError | DocumentHasBookingKeyButMissingGlaError | ExtraCostInfoArchivedError | ExtraCostInfoChangedError | ExtraCostInfoDoesNotExistError | GeneralLedgerArchivedError | GeneralLedgerChangedError | GeneralLedgerDoesNotExistError | GeneralLedgerRequiredError | InvalidDiscountAmountError | InvalidDiscountPaymentDateError | InvalidDiscountPercentageError | ProjectCodeArchivedError | ProjectCodeDoesNotExistError;

export type BookingField = {
  __typename?: 'BookingField';
  id?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type BookingInput = {
  amount: Scalars['Float']['input'];
  artistSocialInsuranceCode?: InputMaybe<ArtistSocialInsuranceCode>;
  bookingKeyId?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  costObjectId?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountPaymentDate?: InputMaybe<Scalars['Date']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  extraCostInfoId?: InputMaybe<Scalars['String']['input']>;
  generalLedgerAccountId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  netAmount?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  paymentConditionId?: InputMaybe<Scalars['String']['input']>;
  postingText?: InputMaybe<Scalars['String']['input']>;
  projectCodeId?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  sapExpenseType?: InputMaybe<SapExpenseType>;
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};

export type BookingKey = {
  __typename?: 'BookingKey';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAcquisitionReverse?: Maybe<Scalars['Boolean']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use `taxCode` */
  key: Scalars['Int']['output'];
  organizationId: Scalars['String']['output'];
  readableName: Scalars['String']['output'];
  taxCode: Scalars['String']['output'];
  taxPercentage?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type BookingKeyArchivedError = DocumentApprovalError & {
  __typename?: 'BookingKeyArchivedError';
  bookingKey: BookingKey;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BookingKeyChangedError = DocumentApprovalError & {
  __typename?: 'BookingKeyChangedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BookingKeyCommandResult = BookingKeyError | BookingKeySuccess;

export type BookingKeyDocumentDirectionError = DocumentApprovalError & {
  __typename?: 'BookingKeyDocumentDirectionError';
  bookingKey: BookingKey;
  documentDirection: Scalars['String']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BookingKeyDoesNotExistError = DocumentApprovalError & {
  __typename?: 'BookingKeyDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BookingKeyError = {
  __typename?: 'BookingKeyError';
  /** @deprecated Use `taxCode` */
  key?: Maybe<Scalars['Int']['output']>;
  kind: BookingKeyErrorType;
  message?: Maybe<Scalars['String']['output']>;
  taxCode?: Maybe<Scalars['String']['output']>;
};

export enum BookingKeyErrorType {
  Duplicate = 'DUPLICATE',
  Forbidden = 'FORBIDDEN',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type BookingKeyFetchResult = BookingKey | BookingKeyError;

export type BookingKeyField = {
  __typename?: 'BookingKeyField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: BookingKey;
};

export type BookingKeyImportResult = BookingKeyError | BookingKeysBulkImportReport;

export type BookingKeyRequiredError = DocumentApprovalError & {
  __typename?: 'BookingKeyRequiredError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BookingKeySuccess = {
  __typename?: 'BookingKeySuccess';
  id: Scalars['ID']['output'];
};

export type BookingKeysBulkImportReport = {
  __typename?: 'BookingKeysBulkImportReport';
  errors: Array<BookingKeyError>;
  imported: Array<BookingKeySuccess>;
};

export type BookingModificationNotAllowedError = DocumentApprovalError & {
  __typename?: 'BookingModificationNotAllowedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BookingsError = DocumentApprovalError & {
  __typename?: 'BookingsError';
  error: BookingErrorTypes;
  index: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BooleanField = {
  __typename?: 'BooleanField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Array<Maybe<BooleanSource>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type BooleanSource = {
  __typename?: 'BooleanSource';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type BuiltinRole = Role & {
  __typename?: 'BuiltinRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isLegacyRole: Scalars['Boolean']['output'];
  members: Array<User>;
  name: BuiltinRoleName;
  permissions: Array<Permission>;
};

export enum BuiltinRoleName {
  Accountant = 'accountant',
  Admin = 'admin',
  Approver = 'approver',
  CardManager = 'card_manager',
  Owner = 'owner',
  Requester = 'requester'
}

export type BulkArchiveCostCentersInput = {
  createdOnOrBefore: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  type: CostCenterTypesForBulkArchive;
};

export type BulkChangeGeneralLedgerAccountStatus = {
  accountNumbers: Array<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type CalculateDocumentGrossNetTaxRoundingAmountsError = {
  __typename?: 'CalculateDocumentGrossNetTaxRoundingAmountsError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CalculateDocumentGrossNetTaxRoundingAmountsErrors = {
  __typename?: 'CalculateDocumentGrossNetTaxRoundingAmountsErrors';
  errors: Array<CalculateDocumentGrossNetTaxRoundingAmountsError>;
};

export type CalculateDocumentGrossNetTaxRoundingAmountsInput = {
  currency: DocumentCurrency;
  grossAmount: Scalars['Float']['input'];
  splitBookingsAmounts: Array<CalculateDocumentGrossNetTaxRoundingAmountsSplitBookingAmounts>;
};

export type CalculateDocumentGrossNetTaxRoundingAmountsResult = CalculateDocumentGrossNetTaxRoundingAmountsErrors | CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult;

export type CalculateDocumentGrossNetTaxRoundingAmountsSplitBookingAmounts = {
  netAmount: Scalars['Float']['input'];
  taxAmount: Scalars['Float']['input'];
};

export type CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult = {
  __typename?: 'CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult';
  currency: DocumentCurrency;
  grossAmount: Scalars['Float']['output'];
  netAmount: Scalars['Float']['output'];
  rounding: Scalars['Float']['output'];
  taxAmount: Scalars['Float']['output'];
};

export type CancelCardRequestInput = {
  cardRequestId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type CandisApiSession = {
  __typename?: 'CandisAPISession';
  authorizationUser: AuthorizationUser;
  clientName: Scalars['String']['output'];
  connectionDate: Scalars['Date']['output'];
  sessionId: Scalars['String']['output'];
};

export type CandisApiClient = {
  __typename?: 'CandisApiClient';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type CandisApiClientRequest = {
  name: Scalars['String']['input'];
  redirectUrls: Array<Scalars['String']['input']>;
};

export type CandisApiCoreDataImportHistoryEntry = {
  __typename?: 'CandisApiCoreDataImportHistoryEntry';
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<MemberInfo>;
  failedCandisAPICoreDataImportCount: Scalars['Int']['output'];
  operation: CoreDataImportOperation;
  organizationId: Scalars['String']['output'];
  processId: Scalars['ID']['output'];
  status: CoreDataProcessStatus;
  successfulCandisAPICoreDataImportCount: Scalars['Int']['output'];
  type: CoreDataTypes;
  updatedAt: Scalars['Date']['output'];
};

export type CandisReference = {
  __typename?: 'CandisReference';
  creatorId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
};

export type CardAccountingData = {
  __typename?: 'CardAccountingData';
  approvalWorkflowTemplateId?: Maybe<Scalars['String']['output']>;
  approverIds?: Maybe<Array<Array<Scalars['String']['output']>>>;
  artistSocialInsuranceCode?: Maybe<Scalars['String']['output']>;
  bookingKeyId?: Maybe<Scalars['String']['output']>;
  contactId?: Maybe<Scalars['String']['output']>;
  costCenterId?: Maybe<Scalars['String']['output']>;
  costObjectId?: Maybe<Scalars['String']['output']>;
  extraCostInfoId?: Maybe<Scalars['String']['output']>;
  generalLedgerAccountId?: Maybe<Scalars['String']['output']>;
  paymentConditionId?: Maybe<Scalars['String']['output']>;
};

export type CardAccountingDataFilterInput = {
  approvalWorkflowTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  approverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  artistSocialInsuranceCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  bookingKeyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contactIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costCenterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costObjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  extraCostInfoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  generalLedgerAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentConditionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CardAccountingDataInput = {
  approvalWorkflowTemplateId?: InputMaybe<Scalars['String']['input']>;
  approverIds?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  artistSocialInsuranceCode?: InputMaybe<Scalars['String']['input']>;
  bookingKeyId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  costObjectId?: InputMaybe<Scalars['String']['input']>;
  extraCostInfoId?: InputMaybe<Scalars['String']['input']>;
  generalLedgerAccountId?: InputMaybe<Scalars['String']['input']>;
  paymentConditionId?: InputMaybe<Scalars['String']['input']>;
};

export enum CardActionName {
  CardholderMustHave_2Fa = 'CARDHOLDER_MUST_HAVE_2FA',
  CardholderShouldExists = 'CARDHOLDER_SHOULD_EXISTS',
  UsageAgreementMustBeAgreed = 'USAGE_AGREEMENT_MUST_BE_AGREED'
}

export enum CardActivityType {
  CardIssued = 'CARD_ISSUED',
  CardLimitChanged = 'CARD_LIMIT_CHANGED',
  CardLocked = 'CARD_LOCKED',
  CardRequestCancelled = 'CARD_REQUEST_CANCELLED',
  CardRequestLimitChangesApproved = 'CARD_REQUEST_LIMIT_CHANGES_APPROVED',
  CardRequestLimitChangesRequested = 'CARD_REQUEST_LIMIT_CHANGES_REQUESTED',
  CardRequestPhysicalCardApproved = 'CARD_REQUEST_PHYSICAL_CARD_APPROVED',
  CardRequestPhysicalCardRequested = 'CARD_REQUEST_PHYSICAL_CARD_REQUESTED',
  CardRequestRejected = 'CARD_REQUEST_REJECTED',
  CardRequestSingleUseVirtualCardApproved = 'CARD_REQUEST_SINGLE_USE_VIRTUAL_CARD_APPROVED',
  CardRequestSingleUseVirtualCardRequested = 'CARD_REQUEST_SINGLE_USE_VIRTUAL_CARD_REQUESTED',
  CardRequestVirtualCardApproved = 'CARD_REQUEST_VIRTUAL_CARD_APPROVED',
  CardRequestVirtualCardRequested = 'CARD_REQUEST_VIRTUAL_CARD_REQUESTED',
  CardTerminated = 'CARD_TERMINATED',
  CardUnlocked = 'CARD_UNLOCKED'
}

export enum CardBrand {
  Candis = 'CANDIS',
  Pliant = 'PLIANT'
}

export enum CardCategory {
  AdvertisingAndMarketing = 'ADVERTISING_AND_MARKETING',
  ComputingAndSoftware = 'COMPUTING_AND_SOFTWARE',
  EducationAndTraining = 'EDUCATION_AND_TRAINING',
  ElectronicsAndItEquipment = 'ELECTRONICS_AND_IT_EQUIPMENT',
  EntertainmentAndWellness = 'ENTERTAINMENT_AND_WELLNESS',
  FoodAndDrinks = 'FOOD_AND_DRINKS',
  GiftsAndVouchers = 'GIFTS_AND_VOUCHERS',
  MaterialsAndPackaging = 'MATERIALS_AND_PACKAGING',
  OfficeSuppliesAndEquipment = 'OFFICE_SUPPLIES_AND_EQUIPMENT',
  Other = 'OTHER',
  Services = 'SERVICES',
  TravelAndAccommodation = 'TRAVEL_AND_ACCOMMODATION'
}

export type CardIssuedEvent = CardTimeLineItem & {
  __typename?: 'CardIssuedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  limit?: Maybe<TransactionAmount>;
  transactionLimit: TransactionAmount;
  user: ActivityUser;
};

export type CardIssuerAmountInput = {
  currency: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type CardIssuerCancelParkedCardInput = {
  cardId: Scalars['String']['input'];
};

export type CardIssuerCard = {
  __typename?: 'CardIssuerCard';
  accountingData?: Maybe<CardAccountingData>;
  allRequests: Array<CardIssuerCardRequest>;
  availableBalance?: Maybe<TransactionAmount>;
  brand?: Maybe<CardBrand>;
  cardholder: CardholderMembership;
  category?: Maybe<CardCategory>;
  expiryDate?: Maybe<Scalars['Date']['output']>;
  hasAccountingData: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isInvoiceNeeded: Scalars['Boolean']['output'];
  issuingDate?: Maybe<Scalars['Date']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  lastTransactionCreatedAt?: Maybe<Scalars['Date']['output']>;
  limit?: Maybe<TransactionAmount>;
  limitPeriodEnd?: Maybe<Scalars['Date']['output']>;
  limitRenewDate?: Maybe<Scalars['Date']['output']>;
  limitRenewFrequency?: Maybe<CardLimitRenewFrequency>;
  linkedVendors?: Maybe<VendorsForRecurringPaymentsConnectionPageBased>;
  numberOfArchiveTransactions?: Maybe<Scalars['Int']['output']>;
  numberOfInboxTransactions?: Maybe<Scalars['Int']['output']>;
  pendingActions?: Maybe<Array<CardIssuerCardAction>>;
  pendingRequest?: Maybe<CardIssuerCardRequest>;
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  purpose?: Maybe<Scalars['String']['output']>;
  refNum?: Maybe<Scalars['String']['output']>;
  replacedBy?: Maybe<User>;
  replacedByCardId?: Maybe<Scalars['String']['output']>;
  replacedCardId?: Maybe<Scalars['String']['output']>;
  replacementReason?: Maybe<CardIssuerCardReplacementReason>;
  shippingDate?: Maybe<Scalars['Date']['output']>;
  spendingBalance?: Maybe<TransactionAmount>;
  status: CardStatus;
  team?: Maybe<Team>;
  timeline?: Maybe<Array<CardTimeLineItem>>;
  token?: Maybe<Scalars['String']['output']>;
  transactionLimit?: Maybe<TransactionAmount>;
  type: CardType;
  uniqueToken?: Maybe<Scalars['String']['output']>;
};


export type CardIssuerCardTimelineArgs = {
  filters?: InputMaybe<CardTimeLineFiltersInput>;
};


export type CardIssuerCardUniqueTokenArgs = {
  flow?: InputMaybe<CardIssuerCardCredentialFlow>;
  period?: InputMaybe<Scalars['Int']['input']>;
};

export type CardIssuerCardAction = {
  __typename?: 'CardIssuerCardAction';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: CardActionName;
};

export type CardIssuerCardCredential = {
  __typename?: 'CardIssuerCardCredential';
  token?: Maybe<Scalars['String']['output']>;
  uniqueToken?: Maybe<Scalars['String']['output']>;
};

export enum CardIssuerCardCredentialFlow {
  Mcd = 'MCD',
  Mpp = 'MPP'
}

export type CardIssuerCardCredentialRequiresRecentAuthn = CardIssuerCardCredential | RecentAuthnRequiredError;

export type CardIssuerCardDateFilterInput = {
  expiryDate?: InputMaybe<DateFilterInput>;
  issuingDate?: InputMaybe<DateFilterInput>;
};

export type CardIssuerCardEdge = {
  __typename?: 'CardIssuerCardEdge';
  cursor: Scalars['String']['output'];
  node: CardIssuerCard;
};

export type CardIssuerCardMetadata = {
  __typename?: 'CardIssuerCardMetadata';
  brand?: Maybe<CardBrand>;
  cardholder: CardholderMembership;
  category?: Maybe<CardCategory>;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  purpose?: Maybe<Scalars['String']['output']>;
  refNum?: Maybe<Scalars['String']['output']>;
  status: CardStatus;
  team?: Maybe<Team>;
  type: CardType;
};

export type CardIssuerCardMetadataEdge = {
  __typename?: 'CardIssuerCardMetadataEdge';
  cursor: Scalars['String']['output'];
  node: CardIssuerCardMetadata;
};

export enum CardIssuerCardReplacementReason {
  Damaged = 'DAMAGED',
  ExpiringSoon = 'EXPIRING_SOON',
  Lost = 'LOST',
  Other = 'OTHER',
  Stolen = 'STOLEN'
}

export type CardIssuerCardRequest = {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  resolutionComment?: Maybe<Scalars['String']['output']>;
  status: CardRequestStatus;
  team?: Maybe<Team>;
};

export type CardIssuerCardRequestsFiltersInput = {
  cardTypes?: InputMaybe<Array<CardType>>;
  cardholderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<CardCategory>>;
  dateRangeFilters?: InputMaybe<CardIssuerCardDateFilterInput>;
  membershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  refNums?: InputMaybe<Array<Scalars['String']['input']>>;
  requestStatuses?: InputMaybe<Array<CardRequestStatus>>;
};

export enum CardIssuerCardSortField {
  AvailableAndRequestedLimitsCombined = 'AVAILABLE_AND_REQUESTED_LIMITS_COMBINED',
  AvailableBalance = 'AVAILABLE_BALANCE',
  CardholderFullName = 'CARDHOLDER_FULL_NAME',
  ExpiryDate = 'EXPIRY_DATE',
  IssuingDate = 'ISSUING_DATE',
  IssuingDatePrimaryRequestedDateSecondary = 'ISSUING_DATE_PRIMARY_REQUESTED_DATE_SECONDARY',
  LabelAndRefNumQuery = 'LABEL_AND_REF_NUM_QUERY',
  LastTransactionCreatedAt = 'LAST_TRANSACTION_CREATED_AT',
  Query = 'QUERY',
  RefNum = 'REF_NUM',
  Requests = 'REQUESTS',
  SpendingBalance = 'SPENDING_BALANCE',
  Status = 'STATUS',
  TransactionLimit = 'TRANSACTION_LIMIT',
  Type = 'TYPE'
}

export type CardIssuerCardUsageAgreementInput = {
  cardActionId: Scalars['String']['input'];
  cardId: Scalars['String']['input'];
};

export type CardIssuerCardholder = {
  __typename?: 'CardIssuerCardholder';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  memberships: Array<Maybe<CardIssuerCardholderMembership>>;
};

export type CardIssuerCardholderEdge = {
  __typename?: 'CardIssuerCardholderEdge';
  cursor: Scalars['String']['output'];
  node: CardIssuerCardholder;
};

export type CardIssuerCardholderMembership = {
  __typename?: 'CardIssuerCardholderMembership';
  candisOrganizationId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  membershipId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
};

export enum CardIssuerCardholderSortField {
  CardCreatedAt = 'CARD_CREATED_AT',
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL'
}

export type CardIssuerCardholdersFiltersInput = {
  hasCandisMembership?: InputMaybe<Scalars['Boolean']['input']>;
  hasCards?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransactionWithMissingInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransactions?: InputMaybe<Scalars['Boolean']['input']>;
  membershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CardIssuerCardholdersSortInput = {
  direction: SortDirection;
  field: CardIssuerCardholderSortField;
};

export type CardIssuerCardsFiltersInput = {
  accountingData?: InputMaybe<CardAccountingDataFilterInput>;
  brands?: InputMaybe<Array<CardBrand>>;
  cardholderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<CardCategory>>;
  dateRangeFilters?: InputMaybe<CardIssuerCardDateFilterInput>;
  hasAccountingData?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingRequests?: InputMaybe<Scalars['Boolean']['input']>;
  membershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  refNums?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<CardStatus>>;
  teamIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<CardType>>;
};

export type CardIssuerCardsForCardManagerFiltersInput = {
  accountingData?: InputMaybe<CardAccountingDataFilterInput>;
  brands?: InputMaybe<Array<CardBrand>>;
  cardholderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<CardCategory>>;
  dateRangeFilters?: InputMaybe<CardIssuerCardDateFilterInput>;
  hasAccountingData?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingRequests?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransactionWithMissingInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransactions?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  includedIds?: InputMaybe<Array<Scalars['String']['input']>>;
  membershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  refNums?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<CardStatus>>;
  teamIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<CardType>>;
};

export type CardIssuerCardsForCardholderFiltersInput = {
  accountingData?: InputMaybe<CardAccountingDataFilterInput>;
  categories?: InputMaybe<Array<CardCategory>>;
  dateRangeFilters?: InputMaybe<CardIssuerCardDateFilterInput>;
  hasAccountingData?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingRequests?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  includedIds?: InputMaybe<Array<Scalars['String']['input']>>;
  refNums?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<CardStatus>>;
  teamIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<CardType>>;
};

export type CardIssuerCardsQueryInput = {
  labelAndRefNumQuery?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type CardIssuerCardsSortInput = {
  direction: SortDirection;
  field: CardIssuerCardSortField;
};

export type CardIssuerGenerateTestTransactionsInput = {
  amountFrom?: InputMaybe<Scalars['Float']['input']>;
  amountTo?: InputMaybe<Scalars['Float']['input']>;
  cardId: Scalars['String']['input'];
  currency?: InputMaybe<GenerateTestTransactionsCurrency>;
  merchantName?: InputMaybe<Scalars['String']['input']>;
  numberOfTransactions?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TransactionStatus>;
  type?: InputMaybe<GenerateTestTransactionsType>;
};

export type CardIssuerLockUnlockCardInput = {
  cardId: Scalars['String']['input'];
  locked: Scalars['Boolean']['input'];
};

export type CardIssuerMerchant = {
  __typename?: 'CardIssuerMerchant';
  id: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum CardIssuerMerchantSortField {
  MerchantName = 'MERCHANT_NAME',
  Query = 'QUERY'
}

export type CardIssuerMerchantsFiltersInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CardIssuerMerchantsQueryInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type CardIssuerMerchantsSortInput = {
  direction: SortDirection;
  field: CardIssuerMerchantSortField;
};

export type CardIssuerMobileWalletTokenization = {
  __typename?: 'CardIssuerMobileWalletTokenization';
  cardId: Scalars['String']['output'];
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  provisioningCompletedAt?: Maybe<Scalars['Date']['output']>;
  provisioningDeletedAt?: Maybe<Scalars['Date']['output']>;
  provisioningStartedAt: Scalars['Date']['output'];
  provisioningStatus: CardIssuerMobileWalletTokenizationProvisioningStatus;
  tokenExpirationDate?: Maybe<Scalars['Date']['output']>;
  tokenStatus: CardIssuerMobileWalletTokenizationTokenStatus;
  tokenizationId?: Maybe<Scalars['String']['output']>;
  walletType?: Maybe<CardIssuerMobileWalletTokenizationWalletType>;
};

export enum CardIssuerMobileWalletTokenizationProvisioningStatus {
  PasscodeSent = 'PASSCODE_SENT',
  TokenActivated = 'TOKEN_ACTIVATED',
  TokenActivationFailed = 'TOKEN_ACTIVATION_FAILED',
  TokenCreated = 'TOKEN_CREATED',
  TokenProvisioned = 'TOKEN_PROVISIONED'
}

export type CardIssuerMobileWalletTokenizationSortInput = {
  direction: SortDirection;
  field: CardIssuerMobileWalletTokenizationsSortField;
};

export enum CardIssuerMobileWalletTokenizationTokenStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Suspended = 'SUSPENDED',
  Unknown = 'UNKNOWN'
}

export enum CardIssuerMobileWalletTokenizationWalletType {
  Android = 'ANDROID',
  Apple = 'APPLE'
}

export type CardIssuerMobileWalletTokenizationsFiltersInput = {
  cardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  provisioningStatuses?: InputMaybe<Array<CardIssuerMobileWalletTokenizationProvisioningStatus>>;
  tokenStatuses?: InputMaybe<Array<CardIssuerMobileWalletTokenizationTokenStatus>>;
  walletTypes?: InputMaybe<Array<CardIssuerMobileWalletTokenizationWalletType>>;
};

export type CardIssuerMobileWalletTokenizationsPageBased = {
  __typename?: 'CardIssuerMobileWalletTokenizationsPageBased';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records?: Maybe<Array<CardIssuerMobileWalletTokenization>>;
};

export enum CardIssuerMobileWalletTokenizationsSortField {
  CreatedAt = 'CREATED_AT'
}

export enum CardIssuerOnboardingStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE',
  OnboardingDetails = 'ONBOARDING_DETAILS',
  OnboardingEmailFailed = 'ONBOARDING_EMAIL_FAILED',
  OnboardingEmailSent = 'ONBOARDING_EMAIL_SENT',
  OnboardingRisk = 'ONBOARDING_RISK',
  OnboardingSddMandate = 'ONBOARDING_SDD_MANDATE',
  OnboardingTerms = 'ONBOARDING_TERMS',
  OnboardingWait = 'ONBOARDING_WAIT',
  Pending = 'PENDING'
}

export type CardIssuerOrganizationInput = {
  cardManagerMembershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationOrigin: CardIssuerOrganizationOrigin;
  pliantOrganizationId: Scalars['String']['input'];
  pliantOrganizationName: Scalars['String']['input'];
  syncFrom: Scalars['String']['input'];
};

export type CardIssuerOrganizationOnboarding = {
  __typename?: 'CardIssuerOrganizationOnboarding';
  requestedAt?: Maybe<Scalars['Date']['output']>;
  requestedBy?: Maybe<User>;
  status?: Maybe<CardIssuerOnboardingStatus>;
};

export enum CardIssuerOrganizationOrigin {
  Candis = 'CANDIS',
  Pliant = 'PLIANT'
}

export type CardIssuerOrganizationsMap = {
  __typename?: 'CardIssuerOrganizationsMap';
  candis?: Maybe<CandisReference>;
  pliant?: Maybe<PliantReference>;
};

export type CardIssuerPageBasedPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CardIssuerPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CardIssuerPaymentSettlement = {
  __typename?: 'CardIssuerPaymentSettlement';
  actualPaymentDate?: Maybe<Scalars['Date']['output']>;
  amount: PaymentSettlementAmount;
  cutOffDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  isRecentMostPaidPaymentInStatement?: Maybe<Scalars['Boolean']['output']>;
  paymentCreatedAt: Scalars['Date']['output'];
  plannedPaymentDate?: Maybe<Scalars['Date']['output']>;
  publicPaymentId?: Maybe<Scalars['String']['output']>;
  statement?: Maybe<CardIssuerStatement>;
  status: PaymentSettlementStatus;
};

export type CardIssuerPaymentSettlementEdge = {
  __typename?: 'CardIssuerPaymentSettlementEdge';
  cursor: Scalars['String']['output'];
  node: CardIssuerPaymentSettlement;
};

export type CardIssuerPaymentSettlementFiltersInput = {
  isExported?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<PaymentSettlementStatus>>;
};

export enum CardIssuerPaymentSettlementSortField {
  ActualPaymentDate = 'ACTUAL_PAYMENT_DATE'
}

export type CardIssuerPaymentSettlementSortInput = {
  direction: SortDirection;
  field: CardIssuerPaymentSettlementSortField;
};

export type CardIssuerReplaceCardInput = {
  cardId: Scalars['String']['input'];
  expiryPeriodMonths: Scalars['Int']['input'];
  terminateCardReason: CardIssuerCardReplacementReason;
};

export type CardIssuerRequestOrganizationOnboardingInput = {
  address: Scalars['String']['input'];
  annualRevenue: Scalars['Float']['input'];
  authorizedRepresentativeEmail: Scalars['String']['input'];
  authorizedRepresentativeFirstName?: InputMaybe<Scalars['String']['input']>;
  authorizedRepresentativeLastName?: InputMaybe<Scalars['String']['input']>;
  authorizedRepresentativeName?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  contactEmail: Scalars['String']['input'];
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone: Scalars['String']['input'];
  foundationYear: Scalars['Int']['input'];
  monthlyCardLimit: Scalars['Float']['input'];
  numberOfEmployees: Scalars['Int']['input'];
  paymentFrequency: PaymentFrequencyType;
  plannedNumberOfCards?: InputMaybe<Scalars['Int']['input']>;
  zipCode: Scalars['Int']['input'];
};

export type CardIssuerSettlement = {
  __typename?: 'CardIssuerSettlement';
  actualPaymentDate?: Maybe<Scalars['Date']['output']>;
  amount: TransactionAmount;
  cutOffDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  isRecentMostPaidPaymentInStatement?: Maybe<Scalars['Boolean']['output']>;
  method: CardIssuerSettlementMethod;
  paymentCreatedAt: Scalars['Date']['output'];
  paymentDate?: Maybe<Scalars['Date']['output']>;
  paymentDefermentInDays?: Maybe<Scalars['Int']['output']>;
  plannedPaymentDate?: Maybe<Scalars['Date']['output']>;
  publicPaymentId: Scalars['String']['output'];
  statement?: Maybe<CardIssuerStatement>;
  status: CardIssuerSettlementStatus;
  type: CardIssuerSettlementType;
};

export type CardIssuerSettlementEdge = {
  __typename?: 'CardIssuerSettlementEdge';
  cursor: Scalars['String']['output'];
  node: CardIssuerSettlement;
};

export enum CardIssuerSettlementMethod {
  DirectDebitB2B = 'DIRECT_DEBIT_B2B',
  DirectDebitCore = 'DIRECT_DEBIT_CORE',
  WireTransfer = 'WIRE_TRANSFER'
}

export enum CardIssuerSettlementOpenStatementFilter {
  ExcludeRecentMostPaidPayment = 'EXCLUDE_RECENT_MOST_PAID_PAYMENT',
  IncludeOnlyRecentMostPaidPayment = 'INCLUDE_ONLY_RECENT_MOST_PAID_PAYMENT'
}

export enum CardIssuerSettlementPaidSettlementsFilter {
  ExcludeRecentMostFromOpenStatements = 'EXCLUDE_RECENT_MOST_FROM_OPEN_STATEMENTS',
  IncludeOnlyRecentMostFromOpenStatements = 'INCLUDE_ONLY_RECENT_MOST_FROM_OPEN_STATEMENTS'
}

export enum CardIssuerSettlementStatus {
  Booked = 'BOOKED',
  Deferred = 'DEFERRED',
  Discarded = 'DISCARDED',
  Failed = 'FAILED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Planned = 'PLANNED',
  ReadyForPmt = 'READY_FOR_PMT',
  Reversed = 'REVERSED',
  Upcoming = 'UPCOMING'
}

export enum CardIssuerSettlementType {
  Bill = 'BILL',
  BillPaymentReversal = 'BILL_PAYMENT_REVERSAL',
  DirectDebitReversal = 'DIRECT_DEBIT_REVERSAL',
  Other = 'OTHER',
  Payout = 'PAYOUT',
  Topup = 'TOPUP'
}

export type CardIssuerSettlementsConnection = {
  __typename?: 'CardIssuerSettlementsConnection';
  edges?: Maybe<Array<CardIssuerSettlementEdge>>;
  node?: Maybe<Array<CardIssuerSettlement>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CardIssuerSettlementsConnectionPageBased = {
  __typename?: 'CardIssuerSettlementsConnectionPageBased';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records?: Maybe<Array<CardIssuerSettlement>>;
};

export type CardIssuerSettlementsDateFilterInput = {
  actualPaymentDateFilter?: InputMaybe<DateFilterInput>;
  cutOffDateFilter?: InputMaybe<DateFilterInput>;
  paymentDateFilter?: InputMaybe<DateFilterInput>;
  plannedPaymentDateFilter?: InputMaybe<DateFilterInput>;
};

export enum CardIssuerSettlementsFilterSortField {
  ActualPaymentDate = 'ACTUAL_PAYMENT_DATE',
  CutOffDate = 'CUT_OFF_DATE',
  PaymentDate = 'PAYMENT_DATE',
  PlannedPaymentDate = 'PLANNED_PAYMENT_DATE'
}

export type CardIssuerSettlementsFiltersInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isExported?: InputMaybe<Scalars['Boolean']['input']>;
  methods?: InputMaybe<Array<CardIssuerSettlementMethod>>;
  openStatement?: InputMaybe<CardIssuerSettlementOpenStatementFilter>;
  paidSettlements?: InputMaybe<CardIssuerSettlementPaidSettlementsFilter>;
  statuses?: InputMaybe<Array<CardIssuerSettlementStatus>>;
  types?: InputMaybe<Array<CardIssuerSettlementType>>;
};

export type CardIssuerSettlementsSortInput = {
  direction: SortDirection;
  field: CardIssuerSettlementsFilterSortField;
};

export type CardIssuerSpendOverviewInsight = {
  __typename?: 'CardIssuerSpendOverviewInsight';
  details: Array<CardIssuerSpendOverviewInsightItem>;
  granularity: InsightGranularity;
};

export type CardIssuerSpendOverviewInsightItem = {
  __typename?: 'CardIssuerSpendOverviewInsightItem';
  dateLabel: Scalars['Date']['output'];
  spentAmount?: Maybe<Money>;
};

export type CardIssuerStatement = {
  __typename?: 'CardIssuerStatement';
  cardIssuer: CardIssuerStatementCardIssuerData;
  /** @deprecated Will be removed soon. */
  csvFile?: Maybe<File>;
  /** @deprecated Use pdfFile instead. */
  file?: Maybe<File>;
  /** @deprecated Will be removed soon. */
  hasCsvFile: Scalars['Boolean']['output'];
  /** @deprecated Use hasPdfFile instead. */
  hasFile: Scalars['Boolean']['output'];
  hasPdfFile: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  pdfFile?: Maybe<File>;
};


export type CardIssuerStatementCsvFileArgs = {
  csvFileRecordId?: InputMaybe<Scalars['String']['input']>;
};

export type CardIssuerStatementCardIssuerData = {
  __typename?: 'CardIssuerStatementCardIssuerData';
  closingBalance: TransactionAmount;
  isClosed: Scalars['Boolean']['output'];
  openingBalance: TransactionAmount;
  statementPeriodEnd: Scalars['Date']['output'];
  statementPeriodStart: Scalars['Date']['output'];
  totalPaymentAmount: TransactionAmount;
  totalTransactionAmount: TransactionAmount;
};

export type CardIssuerStatementCsvFile = {
  __typename?: 'CardIssuerStatementCsvFile';
  file?: Maybe<File>;
  hasFile: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type CardIssuerStatementEdge = {
  __typename?: 'CardIssuerStatementEdge';
  cursor: Scalars['String']['output'];
  node: CardIssuerStatement;
};

export type CardIssuerStatementsConnection = {
  __typename?: 'CardIssuerStatementsConnection';
  edges?: Maybe<Array<CardIssuerStatementEdge>>;
  node?: Maybe<Array<CardIssuerStatement>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CardIssuerStatementsConnectionPageBased = {
  __typename?: 'CardIssuerStatementsConnectionPageBased';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records?: Maybe<Array<CardIssuerStatement>>;
};

export type CardIssuerStatementsDateFilterInput = {
  ongoingDuringDateFilter?: InputMaybe<DateFilterInput>;
};

export type CardIssuerStatementsFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CardIssuerStatementsFilterSortField {
  StatementPeriodStart = 'STATEMENT_PERIOD_START',
  Sum = 'SUM'
}

export type CardIssuerStatementsSortInput = {
  direction: SortDirection;
  field: CardIssuerStatementsFilterSortField;
};

export type CardIssuerTerminateCardInput = {
  cardId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  reason: TerminationReason;
};

export type CardIssuerTransaction = {
  __typename?: 'CardIssuerTransaction';
  accountingData?: Maybe<TransactionCardAccountingData>;
  billingAmount?: Maybe<TransactionAmount>;
  billingAmountForAccounting?: Maybe<TransactionAmount>;
  cardBrand?: Maybe<CardBrand>;
  cardId: Scalars['String']['output'];
  cardLabel?: Maybe<Scalars['String']['output']>;
  cardName?: Maybe<Scalars['String']['output']>;
  cardPurpose?: Maybe<Scalars['String']['output']>;
  cardRefNum?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<CardType>;
  category?: Maybe<TransactionCategory>;
  confirmedAt?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use localizedDeclineReason instead */
  declineReason?: Maybe<TransactionDeclinedInfo>;
  documents: Array<Document>;
  fxFee: TransactionFxFee;
  id: Scalars['String']['output'];
  invoiceAssociationStatus?: Maybe<InvoiceAssociationStatus>;
  invoiceNotNeeded?: Maybe<CardIssuerTransactionInvoiceNotNeeded>;
  isExported?: Maybe<Scalars['Boolean']['output']>;
  localizedDeclineReason?: Maybe<TransactionDeclinedReason>;
  member: MemberInfo;
  merchantId?: Maybe<Scalars['String']['output']>;
  merchantLegalName?: Maybe<Scalars['String']['output']>;
  merchantLogoUrl?: Maybe<Scalars['String']['output']>;
  merchantName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TransactionStatus>;
  timeline?: Maybe<Array<TransactionTimeLineItem>>;
  transactionAmount?: Maybe<TransactionAmount>;
  transactionAmountForAccounting?: Maybe<TransactionAmount>;
  transactionCreatedAt?: Maybe<Scalars['Date']['output']>;
  type?: Maybe<TransactionType>;
  wallet?: Maybe<TransactionWallet>;
};

export type CardIssuerTransactionCountInput = {
  view: CardIssuerTransactionView;
};

export type CardIssuerTransactionDateFilter = {
  __typename?: 'CardIssuerTransactionDateFilter';
  transactionCreatedAt: DateFilterType;
};

export type CardIssuerTransactionDateFilterInput = {
  automatchingLinkedAt?: InputMaybe<DateFilterInput>;
  transactionCreatedAt?: InputMaybe<DateFilterInput>;
};

export type CardIssuerTransactionEdge = {
  __typename?: 'CardIssuerTransactionEdge';
  cursor: Scalars['String']['output'];
  node: CardIssuerTransaction;
};

export type CardIssuerTransactionFilterInput = {
  cardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cardholderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<TransactionCategory>>;
  dateRangeFilters?: InputMaybe<CardIssuerTransactionDateFilterInput>;
  excludeType?: InputMaybe<Array<TransactionType>>;
  invoiceAssociationStatus?: InputMaybe<Array<InvoiceAssociationStatus>>;
  membershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  merchantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  merchantNames?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<TransactionStatus>>;
  type?: InputMaybe<Array<TransactionType>>;
};

export type CardIssuerTransactionInvoiceNotNeeded = {
  __typename?: 'CardIssuerTransactionInvoiceNotNeeded';
  comment?: Maybe<Scalars['String']['output']>;
  reason: CardIssuerTransactionInvoiceNotNeededReason;
};

export enum CardIssuerTransactionInvoiceNotNeededReason {
  CardNeedsNoInvoice = 'CARD_NEEDS_NO_INVOICE',
  InvoiceAlreadyExported = 'INVOICE_ALREADY_EXPORTED',
  NeedsNoInvoice = 'NEEDS_NO_INVOICE',
  Other = 'OTHER'
}

export type CardIssuerTransactionPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CardIssuerTransactionPaginationResult = {
  __typename?: 'CardIssuerTransactionPaginationResult';
  pageInfo?: Maybe<PageInfo>;
  records: Array<CardIssuerTransaction>;
};

export enum CardIssuerTransactionSortField {
  CardholderFullName = 'CARDHOLDER_FULL_NAME',
  CardName = 'CARD_NAME',
  CardRefNum = 'CARD_REF_NUM',
  ConfirmedAt = 'CONFIRMED_AT',
  FxTransactionAmount = 'FX_TRANSACTION_AMOUNT',
  MerchantName = 'MERCHANT_NAME',
  Status = 'STATUS',
  TransactionAmount = 'TRANSACTION_AMOUNT',
  TransactionCreatedAt = 'TRANSACTION_CREATED_AT'
}

export type CardIssuerTransactionSortInput = {
  direction: SortDirection;
  field: CardIssuerTransactionSortField;
};

export enum CardIssuerTransactionView {
  Archive = 'ARCHIVE',
  Inbox = 'INBOX',
  Unassociated = 'UNASSOCIATED'
}

export type CardIssuerTransactionsCount = {
  __typename?: 'CardIssuerTransactionsCount';
  count: Scalars['Int']['output'];
};

export type CardIssuerTransactionsInsights = {
  __typename?: 'CardIssuerTransactionsInsights';
  aggregate: TransactionsInsightsMonetaryDetail;
  cardholders: Array<TransactionsCardholderInsightsItem>;
};

export type CardIssuerTransactionsQueryInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type CardLimitChangeApprovedEvent = CardTimeLineItem & {
  __typename?: 'CardLimitChangeApprovedEvent';
  approvedLimit: TransactionAmount;
  approvedTransactionLimit: TransactionAmount;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type CardLimitChangeRequest = CardIssuerCardRequest & {
  __typename?: 'CardLimitChangeRequest';
  approvedLimit?: Maybe<TransactionAmount>;
  approvedLimitRenewFrequency?: Maybe<CardLimitRenewFrequency>;
  approvedTransactionLimit?: Maybe<TransactionAmount>;
  comment: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  requestedLimit?: Maybe<TransactionAmount>;
  requestedLimitRenewFrequency?: Maybe<CardLimitRenewFrequency>;
  requestedTransactionLimit: TransactionAmount;
  resolutionComment?: Maybe<Scalars['String']['output']>;
  status: CardRequestStatus;
  team?: Maybe<Team>;
};

export type CardLimitChangeRequestedEvent = CardTimeLineItem & {
  __typename?: 'CardLimitChangeRequestedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  requestedLimit: TransactionAmount;
  requestedTransactionLimit: TransactionAmount;
  user: ActivityUser;
};

export type CardLimitChangedEvent = CardTimeLineItem & {
  __typename?: 'CardLimitChangedEvent';
  createdAt: Scalars['Date']['output'];
  currentLimit?: Maybe<TransactionAmount>;
  currentTransactionLimit: TransactionAmount;
  id: Scalars['ID']['output'];
  newLimit?: Maybe<TransactionAmount>;
  newTransactionLimit: TransactionAmount;
  user: ActivityUser;
};

export enum CardLimitRenewFrequency {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Total = 'TOTAL'
}

export type CardLockedEvent = CardTimeLineItem & {
  __typename?: 'CardLockedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type CardMetadataConnection = {
  __typename?: 'CardMetadataConnection';
  edges?: Maybe<Array<CardIssuerCardMetadataEdge>>;
  node?: Maybe<Array<CardIssuerCardMetadata>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CardReference = {
  __typename?: 'CardReference';
  id: Scalars['String']['output'];
};

export type CardRequestApprovedInAppNotification = InAppNotification & {
  __typename?: 'CardRequestApprovedInAppNotification';
  cardId: Scalars['String']['output'];
  cardLabel?: Maybe<Scalars['String']['output']>;
  cardManager: User;
  cardRefNum: Scalars['String']['output'];
  cardRequestId: Scalars['String']['output'];
  cardRequestStatusChangedAt: Scalars['Date']['output'];
  cardRequestType: CardRequestType;
  cardholderId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  limit?: Maybe<TransactionAmount>;
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  requester: User;
  resolutionComment?: Maybe<Scalars['String']['output']>;
  transactionLimit?: Maybe<TransactionAmount>;
};

export type CardRequestCancelledEvent = CardTimeLineItem & {
  __typename?: 'CardRequestCancelledEvent';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type CardRequestCreatedInAppNotification = InAppNotification & {
  __typename?: 'CardRequestCreatedInAppNotification';
  cardId: Scalars['String']['output'];
  cardLabel?: Maybe<Scalars['String']['output']>;
  cardRefNum: Scalars['String']['output'];
  cardRequestCreatedAt: Scalars['Date']['output'];
  cardRequestType: CardRequestType;
  cardholderId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  limit?: Maybe<TransactionAmount>;
  newRequestedLimit?: Maybe<TransactionAmount>;
  newRequestedTransactionLimit?: Maybe<TransactionAmount>;
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  requester: User;
  transactionLimit?: Maybe<TransactionAmount>;
};

export type CardRequestRejectedEvent = CardTimeLineItem & {
  __typename?: 'CardRequestRejectedEvent';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type CardRequestRejectedInAppNotification = InAppNotification & {
  __typename?: 'CardRequestRejectedInAppNotification';
  cardId: Scalars['String']['output'];
  cardLabel?: Maybe<Scalars['String']['output']>;
  cardManager: User;
  cardRefNum: Scalars['String']['output'];
  cardRequestId: Scalars['String']['output'];
  cardRequestStatusChangedAt: Scalars['Date']['output'];
  cardRequestType: CardRequestType;
  cardholderId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  limit?: Maybe<TransactionAmount>;
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  requester: User;
  resolutionComment?: Maybe<Scalars['String']['output']>;
  transactionLimit?: Maybe<TransactionAmount>;
};

export enum CardRequestStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum CardRequestType {
  ChangeCardLimitRequest = 'ChangeCardLimitRequest',
  IssueNewPhysicalCardRequest = 'IssueNewPhysicalCardRequest',
  IssueNewSingleUseVirtualCardRequest = 'IssueNewSingleUseVirtualCardRequest',
  IssueNewVirtualCardRequest = 'IssueNewVirtualCardRequest'
}

export type CardRequestsCounts = {
  __typename?: 'CardRequestsCounts';
  details: CardRequestsCountsDetails;
  total: Scalars['Int']['output'];
};

export type CardRequestsCountsByStatuses = {
  __typename?: 'CardRequestsCountsByStatuses';
  APPROVED?: Maybe<Scalars['Int']['output']>;
  CANCELED?: Maybe<Scalars['Int']['output']>;
  PENDING?: Maybe<Scalars['Int']['output']>;
  REJECTED?: Maybe<Scalars['Int']['output']>;
};

export type CardRequestsCountsByTypes = {
  __typename?: 'CardRequestsCountsByTypes';
  byStatus: CardRequestsCountsByStatuses;
  count: Scalars['Int']['output'];
};

export type CardRequestsCountsDetails = {
  __typename?: 'CardRequestsCountsDetails';
  ChangeCardLimitRequest?: Maybe<CardRequestsCountsByTypes>;
  IssueNewPhysicalCardRequest?: Maybe<CardRequestsCountsByTypes>;
  IssueNewSingleUseVirtualCardRequest?: Maybe<CardRequestsCountsByTypes>;
  IssueNewVirtualCardRequest?: Maybe<CardRequestsCountsByTypes>;
};

export enum CardRole {
  CardManager = 'CARD_MANAGER'
}

export type CardSettingsDataInput = {
  isInvoiceNeeded: Scalars['Boolean']['input'];
};

export enum CardStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Locked = 'LOCKED',
  LockedPin = 'LOCKED_PIN',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED',
  RequestCancelled = 'REQUEST_CANCELLED',
  RequestRejected = 'REQUEST_REJECTED',
  Terminated = 'TERMINATED'
}

export type CardSubscriptionInput = {
  cardId: Scalars['String']['input'];
};

export type CardTerminatedEvent = CardTimeLineItem & {
  __typename?: 'CardTerminatedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type CardTimeLineFiltersInput = {
  activityTypes?: InputMaybe<Array<CardActivityType>>;
};

export type CardTimeLineItem = {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
};

export enum CardType {
  Black = 'BLACK',
  Physical = 'PHYSICAL',
  SingleUse = 'SINGLE_USE',
  Virtual = 'VIRTUAL'
}

export type CardUnlockedEvent = CardTimeLineItem & {
  __typename?: 'CardUnlockedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type CardholderMembership = {
  __typename?: 'CardholderMembership';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  membershipEmail?: Maybe<Scalars['String']['output']>;
  membershipId?: Maybe<Scalars['String']['output']>;
};

export type CardholdersConnection = {
  __typename?: 'CardholdersConnection';
  edges?: Maybe<Array<CardIssuerCardholderEdge>>;
  node?: Maybe<Array<CardIssuerCardholder>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CardsConnection = {
  __typename?: 'CardsConnection';
  edges?: Maybe<Array<CardIssuerCardEdge>>;
  node?: Maybe<Array<CardIssuerCard>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CardsConnectionPageBased = {
  __typename?: 'CardsConnectionPageBased';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records?: Maybe<Array<CardIssuerCard>>;
};

export type CardsCounts = {
  __typename?: 'CardsCounts';
  total: Scalars['Int']['output'];
};

export type CardsInvolvement = {
  __typename?: 'CardsInvolvement';
  total: Scalars['Int']['output'];
};

export type CashDiscountAllFieldsDoesNotExistError = DocumentApprovalError & {
  __typename?: 'CashDiscountAllFieldsDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ChangeCardLimitInput = {
  cardId: Scalars['String']['input'];
  limit: CardIssuerAmountInput;
  limitRenewFrequency?: InputMaybe<CardLimitRenewFrequency>;
  transactionLimit: CardIssuerAmountInput;
};

export type ChangeCreditorAccountNumbersLengthInput = {
  newLength: Scalars['Int']['input'];
  oldLength: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};

export type ChangeCreditorAccountNumbersLengthResponse = {
  __typename?: 'ChangeCreditorAccountNumbersLengthResponse';
  failedUpdates?: Maybe<Array<Contact>>;
  message: Scalars['String']['output'];
};

export type ChangedValue = {
  __typename?: 'ChangedValue';
  propertyName: Scalars['String']['output'];
  propertyValue: Scalars['String']['output'];
};

export type ChargebeePlan = {
  __typename?: 'ChargebeePlan';
  currencyCode?: Maybe<Scalars['String']['output']>;
  documentVolume?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  periodUnit?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  pricingModel?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ChargebeeSubscription = {
  __typename?: 'ChargebeeSubscription';
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  plan?: Maybe<ChargebeePlan>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ChartOfAccount = {
  __typename?: 'ChartOfAccount';
  accountLength?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<ChartOfAccountCode>;
};

export enum ChartOfAccountCode {
  Afe_14 = 'AFE_14',
  Afg_49 = 'AFG_49',
  Bmw_54 = 'BMW_54',
  Cam_47 = 'CAM_47',
  Dt_80 = 'DT_80',
  Fi_55 = 'FI_55',
  Gm_53 = 'GM_53',
  Hr_70 = 'HR_70',
  Mp_81 = 'MP_81',
  Mvca_51 = 'MVCA_51',
  Mvo_93 = 'MVO_93',
  Npc_42 = 'NPC_42',
  Other = 'OTHER',
  Pa_97 = 'PA_97',
  Pg_57 = 'PG_57',
  Siapbv_45 = 'SIAPBV_45',
  Skr_03 = 'SKR_03',
  Skr_04 = 'SKR_04',
  Skr_07 = 'SKR_07',
  Skr_99 = 'SKR_99'
}

export type ChartOfAccountInput = {
  accountLength?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<ChartOfAccountCode>;
};

export type CheckIfCardIssuerOrganizationIsReadyForMigrationCardData = {
  __typename?: 'CheckIfCardIssuerOrganizationIsReadyForMigrationCardData';
  id: Scalars['String']['output'];
  idInPliant?: Maybe<Scalars['String']['output']>;
  status: CardStatus;
  type: CardType;
};

export type CheckIfCardIssuerOrganizationIsReadyForMigrationDetails = {
  __typename?: 'CheckIfCardIssuerOrganizationIsReadyForMigrationDetails';
  active: Scalars['Boolean']['output'];
  activeCards: Array<CheckIfCardIssuerOrganizationIsReadyForMigrationCardData>;
  idInPliant?: Maybe<Scalars['String']['output']>;
  migrated: Scalars['Boolean']['output'];
  orgExists: Scalars['Boolean']['output'];
  pendingTransactions: Array<CheckIfCardIssuerOrganizationIsReadyForMigrationTransactionData>;
};

export type CheckIfCardIssuerOrganizationIsReadyForMigrationResult = {
  __typename?: 'CheckIfCardIssuerOrganizationIsReadyForMigrationResult';
  details: CheckIfCardIssuerOrganizationIsReadyForMigrationDetails;
  ready: Scalars['Boolean']['output'];
};

export type CheckIfCardIssuerOrganizationIsReadyForMigrationTransactionData = {
  __typename?: 'CheckIfCardIssuerOrganizationIsReadyForMigrationTransactionData';
  id: Scalars['String']['output'];
  idInPliant?: Maybe<Scalars['String']['output']>;
  status: TransactionStatus;
  type: TransactionType;
};

export type ClientInfo = {
  __typename?: 'ClientInfo';
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type ClientInput = {
  clientNumber: Scalars['String']['input'];
  consultantNumber: Scalars['String']['input'];
  exportType?: InputMaybe<DatevClientExportType>;
  id: Scalars['String']['input'];
  isDxsoCashLedgerConfigured?: InputMaybe<Scalars['Boolean']['input']>;
  isDxsoJobsAvailable: Scalars['Boolean']['input'];
  isExtfsFilesAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type Collaborator = {
  __typename?: 'Collaborator';
  currentField?: Maybe<Scalars['String']['output']>;
  lastActivity: Scalars['Date']['output'];
  membership?: Maybe<User>;
};

export type Comment = {
  __typename?: 'Comment';
  author?: Maybe<User>;
  id: Scalars['ID']['output'];
  mentionedUsers?: Maybe<Array<User>>;
  organization: Organization;
  resourceId?: Maybe<Scalars['String']['output']>;
  resourceType: CommentResourceType;
  text?: Maybe<Scalars['String']['output']>;
};

export enum CommentCreateErrorKind {
  InvalidInput = 'INVALID_INPUT',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export type CommentCreated = {
  __typename?: 'CommentCreated';
  commentId: Scalars['ID']['output'];
};

export type CommentCreatedError = {
  __typename?: 'CommentCreatedError';
  kind?: Maybe<CommentCreateErrorKind>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum CommentResourceType {
  Document = 'Document',
  EcmDocument = 'EcmDocument',
  ReimbursementCase = 'ReimbursementCase'
}

export type CommonReimbursementItem = {
  bookings: Array<ReimbursementBooking>;
  createdAt: Scalars['Date']['output'];
  extractedData?: Maybe<Array<CommonReimbursementItemExtraction>>;
  extractedDataAccepted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  reimbursementCaseId: Scalars['String']['output'];
  status: ReimbursementItemStatus;
  type: ReimbursementItemType;
  updatedAt: Scalars['Date']['output'];
};

export type CommonReimbursementItemExtraction = {
  currency?: Maybe<Scalars['String']['output']>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  sourceFile: File;
};

export type ConnectedGoodsReceiptData = {
  __typename?: 'ConnectedGoodsReceiptData';
  goodsReceiptId?: Maybe<Scalars['String']['output']>;
  receiptNumber?: Maybe<Scalars['String']['output']>;
};

export type ConnectedInvoicesData = {
  __typename?: 'ConnectedInvoicesData';
  connectionMethod?: Maybe<ConnectionMethod>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type ConnectedPurchaseOrdersData = {
  __typename?: 'ConnectedPurchaseOrdersData';
  purchaseOrderId?: Maybe<Scalars['String']['output']>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
};

export type ConnectionDetails = {
  __typename?: 'ConnectionDetails';
  connectionError?: Maybe<ConnectionError>;
  connectionStatus: ConnectionStatus;
};

export type ConnectionError = {
  __typename?: 'ConnectionError';
  errorMessage: ConnectionMessage;
  rawErrorMessage?: Maybe<Scalars['String']['output']>;
  resolutionMessage: ConnectionMessage;
};

export type ConnectionMessage = {
  __typename?: 'ConnectionMessage';
  de: Scalars['String']['output'];
  en: Scalars['String']['output'];
};

export enum ConnectionMethod {
  AutoMatched = 'AUTO_MATCHED',
  ManuallyMatched = 'MANUALLY_MATCHED'
}

export enum ConnectionStatus {
  ConnectedSuccessfully = 'CONNECTED_SUCCESSFULLY',
  LoginFailed = 'LOGIN_FAILED',
  UdfCreationFailed = 'UDF_CREATION_FAILED',
  UdfFetchFailed = 'UDF_FETCH_FAILED'
}

export type Contact = {
  __typename?: 'Contact';
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  additionalContactInfo?: Maybe<AdditionalContactInfo>;
  automaticPayment?: Maybe<BooleanField>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryISOCode?: Maybe<Scalars['String']['output']>;
  createTransfer?: Maybe<BooleanField>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  creator?: Maybe<User>;
  /**
   * If the root Contact type is being used, Typescript will show this
   * property as being possibly defined. It is no longer in any GQL query, only
   * the `accountsPayableNumber` property is.
   * @deprecated Use `accountsPayableNumber`
   */
  creditorAccountNumber?: Maybe<Scalars['Int']['output']>;
  customerNumber?: Maybe<Scalars['String']['output']>;
  dueDateOffset?: Maybe<IntField>;
  email?: Maybe<Scalars['String']['output']>;
  employeeMembership?: Maybe<User>;
  iban?: Maybe<StringField>;
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  mergedContacts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: StringField;
  organizationId?: Maybe<Scalars['String']['output']>;
  paymentCondition?: Maybe<PaymentCondition>;
  paymentConditionId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postOfficeBox?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  relationshipType?: Maybe<ContactRelationshipType>;
  street?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  taxNumber?: Maybe<StringField>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vatId?: Maybe<StringField>;
};

export type ContactApnRequiredError = DocumentApprovalError & {
  __typename?: 'ContactAPNRequiredError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ContactArnRequiredError = DocumentApprovalError & {
  __typename?: 'ContactARNRequiredError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ContactActivated = {
  __typename?: 'ContactActivated';
  activatedContactIds: Array<Scalars['String']['output']>;
};

export type ContactActivatedError = {
  __typename?: 'ContactActivatedError';
  code?: Maybe<ArchiveErrorCodes>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactAlreadyExists = {
  __typename?: 'ContactAlreadyExists';
  name: Scalars['String']['output'];
};

export type ContactArchived = {
  __typename?: 'ContactArchived';
  archivedContactIds: Array<Scalars['String']['output']>;
};

export type ContactArchivedError = {
  __typename?: 'ContactArchivedError';
  code?: Maybe<ArchiveErrorCodes>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ContactCreated = {
  __typename?: 'ContactCreated';
  id: Scalars['ID']['output'];
};

export type ContactDoesNotExistError = DocumentApprovalError & {
  __typename?: 'ContactDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum ContactDuplicateBaseField {
  Iban = 'IBAN',
  Name = 'NAME',
  TaxNumber = 'TAX_NUMBER',
  VatId = 'VAT_ID'
}

/** Document Contact extraction event */
export type ContactExtractionEvent = DocumentTimeLineItem & {
  __typename?: 'ContactExtractionEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Exctracted contact */
  contact?: Maybe<ActivityContact>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Fields that were extracted from contact */
  extractedFields?: Maybe<Array<Maybe<ExtractedField>>>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

export type ContactField = {
  __typename?: 'ContactField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: Contact;
};

export enum ContactFilterOptionsField {
  IbanOrBankAccountNumber = 'IBAN_OR_BANK_ACCOUNT_NUMBER'
}

export type ContactFilterOptionsPaginationResult = {
  __typename?: 'ContactFilterOptionsPaginationResult';
  pageInfo: SimplePageInfo;
  records: Array<Contact>;
};

export type ContactFilterOptionsQueryInput = {
  field: ContactFilterOptionsField;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ContactImportErrorFile = {
  __typename?: 'ContactImportErrorFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ContactImportHistory = {
  __typename?: 'ContactImportHistory';
  countFailedToCreate?: Maybe<Scalars['Int']['output']>;
  countSuccessfullyCreated?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy: User;
  errorFile?: Maybe<ContactImportErrorFile>;
  file: ContactImportHistoryFile;
  hasErrorsToAcknowledge?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importErrors?: Maybe<Array<ImportContactError>>;
  importedContacts?: Maybe<Array<Scalars['ID']['output']>>;
  organizationId: Scalars['String']['output'];
  status?: Maybe<ContactImportStatus>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ContactImportHistoryErrorResult = {
  __typename?: 'ContactImportHistoryErrorResult';
  errorFile: ContactImportHistoryFile;
  id: Scalars['String']['output'];
  importedAt: Scalars['Date']['output'];
  importedBy: User;
};

export type ContactImportHistoryFile = {
  __typename?: 'ContactImportHistoryFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum ContactImportStatus {
  Failed = 'FAILED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING'
}

export type ContactIsArchivedError = DocumentApprovalError & {
  __typename?: 'ContactIsArchivedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum ContactPaymentMedium {
  NoInformation = 'NO_INFORMATION',
  NoPayment = 'NO_PAYMENT',
  SepaBulk = 'SEPA_BULK',
  SepaSingle = 'SEPA_SINGLE'
}

export enum ContactRelationshipType {
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Supplier = 'SUPPLIER',
  SupplierCustomer = 'SUPPLIER_CUSTOMER'
}

export enum ContactSortField {
  AccountsPayableNumber = 'ACCOUNTS_PAYABLE_NUMBER',
  AccountsReceivableNumber = 'ACCOUNTS_RECEIVABLE_NUMBER',
  CreatedAt = 'CREATED_AT',
  /** @deprecated Use `ACCOUNTS_PAYABLE_NUMBER` */
  CreditorAccountNumber = 'CREDITOR_ACCOUNT_NUMBER',
  Name = 'NAME'
}

export type ContactSource = {
  __typename?: 'ContactSource';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  value: Contact;
};

export type ContactSupplierEmailPayload = {
  __typename?: 'ContactSupplierEmailPayload';
  cc?: Maybe<Array<Scalars['String']['output']>>;
  htmlBody?: Maybe<Scalars['String']['output']>;
  recipients?: Maybe<Array<Scalars['String']['output']>>;
  replyTo?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  textBody?: Maybe<Scalars['String']['output']>;
};

export type ContactSupplierSendEmailEvent = DocumentTimeLineItem & {
  __typename?: 'ContactSupplierSendEmailEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the email was sent */
  createdAt?: Maybe<Scalars['Date']['output']>;
  emailPayload?: Maybe<ContactSupplierEmailPayload>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that contacted the supplier */
  user?: Maybe<ActivityUser>;
};

export enum ContactType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
  NotSpecified = 'NOT_SPECIFIED'
}

export type ContactValidationError = {
  __typename?: 'ContactValidationError';
  message?: Maybe<Scalars['String']['output']>;
  type: ContactValidationErrorType;
};

export enum ContactValidationErrorType {
  AllNameFieldsAreEmpty = 'ALL_NAME_FIELDS_ARE_EMPTY',
  AllNameFieldsAreEmptyForIndividual = 'ALL_NAME_FIELDS_ARE_EMPTY_FOR_INDIVIDUAL',
  BankAccountNumberAndIbanMismatch = 'BANK_ACCOUNT_NUMBER_AND_IBAN_MISMATCH',
  BankAccountNumberMissingSwiftCode = 'BANK_ACCOUNT_NUMBER_MISSING_SWIFT_CODE',
  CustomerNumberWithCustomerContactError = 'CUSTOMER_NUMBER_WITH_CUSTOMER_CONTACT_ERROR',
  DuplicateCreditorAccountNumber = 'DUPLICATE_CREDITOR_ACCOUNT_NUMBER',
  DuplicateName = 'DUPLICATE_NAME',
  FoundSimilarContactsWithDifferentTaxNumbers = 'FOUND_SIMILAR_CONTACTS_WITH_DIFFERENT_TAX_NUMBERS',
  FoundSimilarContactsWithDifferentVatIds = 'FOUND_SIMILAR_CONTACTS_WITH_DIFFERENT_VAT_IDS',
  IdentifiersMissingForEmployeeContact = 'IDENTIFIERS_MISSING_FOR_EMPLOYEE_CONTACT',
  InvalidAccountsPayableNumber = 'INVALID_ACCOUNTS_PAYABLE_NUMBER',
  InvalidAccountsReceivableNumber = 'INVALID_ACCOUNTS_RECEIVABLE_NUMBER',
  InvalidBankAccountNumber = 'INVALID_BANK_ACCOUNT_NUMBER',
  InvalidCity = 'INVALID_CITY',
  InvalidCompanyName = 'INVALID_COMPANY_NAME',
  InvalidContactType = 'INVALID_CONTACT_TYPE',
  InvalidContactTypeNumber = 'INVALID_CONTACT_TYPE_NUMBER',
  InvalidCountryCode = 'INVALID_COUNTRY_CODE',
  InvalidCreditorAccountNumber = 'INVALID_CREDITOR_ACCOUNT_NUMBER',
  InvalidCustomerNumber = 'INVALID_CUSTOMER_NUMBER',
  InvalidDueDateOffset = 'INVALID_DUE_DATE_OFFSET',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidIban = 'INVALID_IBAN',
  InvalidIndividualFirstName = 'INVALID_INDIVIDUAL_FIRST_NAME',
  InvalidIndividualLastName = 'INVALID_INDIVIDUAL_LAST_NAME',
  InvalidName = 'INVALID_NAME',
  InvalidNotSpecifiedName = 'INVALID_NOT_SPECIFIED_NAME',
  InvalidPaymentCondition = 'INVALID_PAYMENT_CONDITION',
  InvalidPaymentMedium = 'INVALID_PAYMENT_MEDIUM',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  InvalidPostalCode = 'INVALID_POSTAL_CODE',
  InvalidPostOfficeBox = 'INVALID_POST_OFFICE_BOX',
  InvalidShortName = 'INVALID_SHORT_NAME',
  InvalidStreet = 'INVALID_STREET',
  InvalidSwiftCode = 'INVALID_SWIFT_CODE',
  InvalidTaxNumber = 'INVALID_TAX_NUMBER',
  InvalidVatId = 'INVALID_VAT_ID',
  PaymentConditionArchived = 'PAYMENT_CONDITION_ARCHIVED',
  PaymentConditionNotFound = 'PAYMENT_CONDITION_NOT_FOUND',
  SwiftCodeAndIbanCountryMismatch = 'SWIFT_CODE_AND_IBAN_COUNTRY_MISMATCH',
  SwiftCodeMissingIbanOrBankAccountNumber = 'SWIFT_CODE_MISSING_IBAN_OR_BANK_ACCOUNT_NUMBER',
  UnexpectedUpdateError = 'UNEXPECTED_UPDATE_ERROR'
}

export type ContactsActivatedResult = ContactActivated | ContactActivatedError;

export type ContactsArchivedResult = ContactArchived | ContactArchivedError;

export type ContactsBulkImportRecord = {
  __typename?: 'ContactsBulkImportRecord';
  importId: Scalars['ID']['output'];
};

export type ContactsFilterInput = {
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: InputMaybe<Array<Scalars['AccountsPayableNumber']['input']>>;
  accountsReceivableNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  /** @deprecated Use `accountsPayableNumber` */
  creditorAccountNumber?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Formerly `hasCreditorAccountNumber` */
  hasAccountsPayableNumber?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsReceivableNumber?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Use `hasAccountsPayableNumber` */
  hasCreditorAccountNumber?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Array<Scalars['String']['input']>>;
  ibanAndBankAccountNumber?: InputMaybe<Array<IbanAndBankAccountNumberFilterInput>>;
  ibanOrBankAccountNumber?: InputMaybe<Array<IbanOrBankAccountNumberFilterInput>>;
  id?: InputMaybe<Array<Scalars['String']['input']>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  relationshipType?: InputMaybe<Array<ContactRelationshipType>>;
  taxNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  vatId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContactsImportInBackgroundResult = ContactsBulkImportRecord | UnauthorizedError;

export type ContactsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactsPaginationResult = {
  __typename?: 'ContactsPaginationResult';
  pageInfo: SimplePageInfo;
  records: Array<Contact>;
};

export type ContactsQueryInput = {
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: InputMaybe<Scalars['String']['input']>;
  accountsReceivableNumber?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `accountsPayableNumber` */
  creditorAccountNumber?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type ContactsSortInput = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<ContactSortField>;
};

export type ContractSubCategoriesSortInput = {
  createdAt?: InputMaybe<ContractSubCategoriesSortOrder>;
  documentCount?: InputMaybe<ContractSubCategoriesSortOrder>;
  name?: InputMaybe<ContractSubCategoriesSortOrder>;
};

export enum ContractSubCategoriesSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ContractSubCategory = {
  __typename?: 'ContractSubCategory';
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  documentCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy?: Maybe<User>;
};

export type ConvertDocumentInput = {
  documentType: Scalars['String']['input'];
  globalDocumentId: Scalars['String']['input'];
};

export type ConvertDocumentResponse = {
  __typename?: 'ConvertDocumentResponse';
  globalDocumentId: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
};

export type ConvertDocumentsToAttachmentsInput = {
  documentId: Scalars['String']['input'];
  documentsToConvertIds: Array<Scalars['String']['input']>;
};

export type CoreDataExportStatus = {
  __typename?: 'CoreDataExportStatus';
  hasExportedContacts: Scalars['Boolean']['output'];
  hasExportedPaymentConditions: Scalars['Boolean']['output'];
};

export enum CoreDataImportOperation {
  Create = 'CREATE',
  Update = 'UPDATE'
}

export enum CoreDataProcessStatus {
  Failed = 'FAILED',
  PartiallySucceeded = 'PARTIALLY_SUCCEEDED',
  Processing = 'PROCESSING',
  Succeeded = 'SUCCEEDED'
}

export enum CoreDataTypes {
  BookingKey = 'BOOKING_KEY',
  Contact = 'CONTACT',
  CostCenter = 'COST_CENTER',
  CostObject = 'COST_OBJECT',
  GeneralLedger = 'GENERAL_LEDGER',
  PaymentCondition = 'PAYMENT_CONDITION'
}

export type CostCenter = {
  __typename?: 'CostCenter';
  approverIds?: Maybe<Array<Scalars['String']['output']>>;
  approvers?: Maybe<Array<User>>;
  code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  readableName: Scalars['String']['output'];
  type: CostCenterTypes;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CostCenterAlreadyExists = {
  __typename?: 'CostCenterAlreadyExists';
  code: Scalars['String']['output'];
};

export type CostCenterArchivedError = DocumentApprovalError & {
  __typename?: 'CostCenterArchivedError';
  costCenter: CostCenter;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CostCenterChangedError = DocumentApprovalError & {
  __typename?: 'CostCenterChangedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CostCenterCreated = {
  __typename?: 'CostCenterCreated';
  id: Scalars['ID']['output'];
};

export type CostCenterDoesNotExistError = DocumentApprovalError & {
  __typename?: 'CostCenterDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Document cost center extraction event */
export type CostCenterExtractionEvent = DocumentTimeLineItem & {
  __typename?: 'CostCenterExtractionEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Extracted Const Center */
  costCenter1?: Maybe<ActivityCostCenter>;
  /** Extracted Const Object */
  costCenter2?: Maybe<ActivityCostCenter>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

export type CostCenterField = {
  __typename?: 'CostCenterField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: CostCenter;
};

export type CostCenterFilterInput = {
  approvers?: InputMaybe<Array<Scalars['String']['input']>>;
  code?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Array<Scalars['String']['input']>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<CostCenterTypes>>;
};

export type CostCenterFilterOptionsInput = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CostCenterTypes>;
};

export type CostCenterImportHistoryFile = {
  __typename?: 'CostCenterImportHistoryFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CostCenterImportHistoryItem = {
  __typename?: 'CostCenterImportHistoryItem';
  file: CostCenterImportHistoryFile;
  id: Scalars['ID']['output'];
  importedAt: Scalars['Date']['output'];
  membership: User;
};

export type CostCenterPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CostCenterPaginationResult = {
  __typename?: 'CostCenterPaginationResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<CostCenter>;
};

export type CostCenterQueryInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  readableName?: InputMaybe<Scalars['String']['input']>;
};

export type CostCenterRequiredError = DocumentApprovalError & {
  __typename?: 'CostCenterRequiredError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum CostCenterSearchField {
  Code = 'CODE',
  Name = 'NAME',
  ReadableName = 'READABLE_NAME'
}

export type CostCenterSearchInput = {
  field: CostCenterSearchField;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CostCenterTypes>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum CostCenterSortField {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type CostCenterSortInput = {
  direction: SortDirection;
  field: CostCenterSortField;
};

export enum CostCenterTypes {
  CostCenter = 'COST_CENTER',
  CostObject = 'COST_OBJECT',
  ExtraCostInfo = 'EXTRA_COST_INFO'
}

export enum CostCenterTypesForBulkArchive {
  All = 'ALL',
  CostCenter = 'COST_CENTER',
  CostObject = 'COST_OBJECT',
  ExtraCostInfo = 'EXTRA_COST_INFO'
}

export type CostCenterUpdated = {
  __typename?: 'CostCenterUpdated';
  id: Scalars['ID']['output'];
};

export type CostCentersBulkImportResult = {
  __typename?: 'CostCentersBulkImportResult';
  duplicates: Array<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  imported: Array<Scalars['String']['output']>;
};

export type CostCentersConnection = {
  __typename?: 'CostCentersConnection';
  cursor?: Maybe<Cursor>;
  records?: Maybe<Array<Maybe<CostCenter>>>;
};

export type CostObjectArchivedError = DocumentApprovalError & {
  __typename?: 'CostObjectArchivedError';
  costObject: CostCenter;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CostObjectChangedError = DocumentApprovalError & {
  __typename?: 'CostObjectChangedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CostObjectDoesNotExistError = DocumentApprovalError & {
  __typename?: 'CostObjectDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CostObjectRequiredError = DocumentApprovalError & {
  __typename?: 'CostObjectRequiredError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type CountEcmDocumentsWithFullTextSearchResponse = {
  __typename?: 'CountEcmDocumentsWithFullTextSearchResponse';
  count: Scalars['Int']['output'];
};

export type CreateAccountingAreaInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateBookingAccountInput = {
  accountCode: Scalars['String']['input'];
  /** @deprecated "Use `accountCode`" */
  accountNumber?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type CreateBookingKeyInput = {
  description: Scalars['String']['input'];
  /** @deprecated "Use `taxCode`" */
  key?: InputMaybe<Scalars['Int']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCommentInput = {
  entityId: Scalars['String']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
};

export type CreateCommentResult = CommentCreated | CommentCreatedError;

export type CreateContactInput = {
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: InputMaybe<Scalars['AccountsPayableNumber']['input']>;
  accountsReceivableNumber?: InputMaybe<Scalars['String']['input']>;
  automaticPayment?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<ContactType>;
  countryISOCode?: InputMaybe<Scalars['String']['input']>;
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  creatorId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `accountsPayableNumber` */
  creditorAccountNumber?: InputMaybe<Scalars['Int']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated superseded by payment conditions */
  dueDateOffset?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  individualFirstName?: InputMaybe<Scalars['String']['input']>;
  individualLastName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notSpecifiedName?: InputMaybe<Scalars['String']['input']>;
  paymentConditionId?: InputMaybe<Scalars['String']['input']>;
  paymentMedium?: InputMaybe<ContactPaymentMedium>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postOfficeBox?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  relationshipType?: InputMaybe<ContactRelationshipType>;
  source?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactResult = AccountsNumberAlreadyAssigned | AccountsNumbersIdentical | AccountsPayableNumberAlreadyAssigned | ContactAlreadyExists | ContactCreated;

export type CreateContractSubCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCostCenterInput = {
  approvers?: InputMaybe<Array<UserInput>>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: CostCenterTypes;
};

export type CreateCostCenterResult = CostCenterAlreadyExists | CostCenterCreated;

export type CreateCustomRecurringPaymentVendorInput = {
  category?: InputMaybe<CardCategory>;
  name: Scalars['String']['input'];
};

export type CreateCustomRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  initialMembershipIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  permissions: Array<Scalars['String']['input']>;
};

export type CreateDocumentRelationshipResponse = {
  __typename?: 'CreateDocumentRelationshipResponse';
  duplicateRelationships: Scalars['Int']['output'];
  newRelationships: Scalars['Int']['output'];
};

export type CreateDocumentRelationshipsInput = {
  sourceDocumentId: Scalars['UUID']['input'];
  targetDocumentIds: Array<Scalars['UUID']['input']>;
};

export type CreateDocumentsResponse = {
  __typename?: 'CreateDocumentsResponse';
  errors: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type CreateEcmDocumentResponse = {
  __typename?: 'CreateEcmDocumentResponse';
  ecmDocumentId: Scalars['String']['output'];
};

export type CreateIdentityProviderResponse = {
  __typename?: 'CreateIdentityProviderResponse';
  idpAlias: Scalars['String']['output'];
  ssoConfigurationId: Scalars['String']['output'];
};

export type CreateOrUpdateContactInput = {
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: InputMaybe<Scalars['AccountsPayableNumber']['input']>;
  automaticPayment?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<ContactType>;
  contactTypeNumber?: InputMaybe<Scalars['Int']['input']>;
  countryISOCode?: InputMaybe<Scalars['String']['input']>;
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  creatorId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `accountsPayableNumber` */
  creditorAccountNumber?: InputMaybe<Scalars['Int']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated superseded by payment conditions */
  dueDateOffset?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  individualFirstName?: InputMaybe<Scalars['String']['input']>;
  individualLastName?: InputMaybe<Scalars['String']['input']>;
  notSpecifiedName?: InputMaybe<Scalars['String']['input']>;
  paymentConditionNumber?: InputMaybe<Scalars['Int']['input']>;
  paymentMediumNumber?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postOfficeBox?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganizationInput = {
  /** Chargebee ID for that Company */
  chargebeeSubscriptionId: Scalars['String']['input'];
  /** Id of the holding company that org belongs to */
  holdingId?: InputMaybe<Scalars['String']['input']>;
  /** Details of the first users to be invited to the Organization */
  initialUsers: Array<InviteUserInput>;
  /** Locale for the Company */
  locale: Locale;
  /** The name of the Organization */
  name: Scalars['String']['input'];
  /** Salesforce Account ID for that Company */
  salesforceAccountId: Scalars['String']['input'];
  /** The slug/realm of the Organization in Keycloak */
  slug: Scalars['OrganizationSlug']['input'];
  /** VatId associated to the new organization */
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaymentConditionInput = {
  discountOffset?: InputMaybe<Scalars['Int']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  dueDateOffset: Scalars['Int']['input'];
  paymentConditionNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePerDiemItemInput = {
  bookings?: InputMaybe<Array<ReimbursementBookingInput>>;
  reimbursementCaseId: Scalars['String']['input'];
  segments?: InputMaybe<Array<PerDiemSegmentInput>>;
  status?: InputMaybe<ReimbursementItemStatusInput>;
};

export type CreateReimbursementCaseInput = {
  advancedAccountingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  suggestedCostCenterId?: InputMaybe<Scalars['String']['input']>;
  suggestedCostObjectId?: InputMaybe<Scalars['String']['input']>;
  suggestedGeneralLedgerAccountId?: InputMaybe<Scalars['String']['input']>;
  targetMembershipId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateTeamBodyInput = {
  members: Array<TeamMemberInput>;
  name: Scalars['String']['input'];
};

export type CreateTestOrganizationInput = {
  /** Id of the holding company */
  holdingId?: InputMaybe<Scalars['String']['input']>;
  /** Details of the first users to be invited to the Organization */
  initialUsers: Array<InviteUserInput>;
  /** Locale for the Company */
  locale: Locale;
  /** The name of the Organization. It will be prefixed with 'Test' */
  name: Scalars['String']['input'];
  /** Salesforce Account ID for that test Company */
  salesforceAccountId: Scalars['String']['input'];
  /** The slug/realm of the Organization in Keycloak */
  slug: Scalars['OrganizationSlug']['input'];
};

export type CreateTransactionCommentResponse = {
  __typename?: 'CreateTransactionCommentResponse';
  id: Scalars['String']['output'];
};

export type CreateUserInput = {
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  locale: Locale;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  roles: Array<UserRole>;
  teamIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateUserSsoInput = {
  email: Scalars['EmailAddress']['input'];
  /** firsName Only when the organization isUserDataUpdatedViaSSO is false */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** lastName Only when the organization isUserDataUpdatedViaSSO is false */
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale: Locale;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** roles Only when the organization isMembershipRolesUpdatedViaSSO is false */
  roles?: InputMaybe<Array<UserRole>>;
  teamIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateWorkflowTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
  steps: Array<WorkflowTemplateStepInput>;
};

export type CreatedBy = {
  __typename?: 'CreatedBy';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CreditCardApplicationAuthorizedRepresentativeData = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  salutation?: InputMaybe<OnboardingContactSalutation>;
};

export type CreditCardApplicationCompanyData = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  foundationYear: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  numberOfEmployees: Scalars['Int']['input'];
  plannedCreditLimit: Scalars['Int']['input'];
  plannedNumberOfCards?: InputMaybe<Scalars['Int']['input']>;
  postalCode: Scalars['Int']['input'];
  yearlyRevenue: Scalars['BigInt']['input'];
};

export type CreditCardApplicationContactData = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  salutation?: InputMaybe<OnboardingContactSalutation>;
};

export type CreditCardInvolvements = {
  __typename?: 'CreditCardInvolvements';
  cardholder?: Maybe<CardIssuerCardholder>;
  cards?: Maybe<CardsInvolvement>;
};

export type CreditCardInvolvementsInput = {
  membershipId: Scalars['String']['input'];
};

export type CreditCardLimitThresholds = {
  __typename?: 'CreditCardLimitThresholds';
  PHYSICAL?: Maybe<TransactionAmount>;
  SINGLE_USE?: Maybe<TransactionAmount>;
  VIRTUAL?: Maybe<TransactionAmount>;
};

export type CreditCardLimitThresholdsInput = {
  PHYSICAL?: InputMaybe<CardIssuerAmountInput>;
  SINGLE_USE?: InputMaybe<CardIssuerAmountInput>;
  VIRTUAL?: InputMaybe<CardIssuerAmountInput>;
};

export type CreditCardsLedger = {
  __typename?: 'CreditCardsLedger';
  bookingAccount?: Maybe<Scalars['String']['output']>;
  transitAccount?: Maybe<Scalars['String']['output']>;
};

export type CreditCardsLedgerInput = {
  bookingAccount?: InputMaybe<Scalars['String']['input']>;
  transitAccount?: InputMaybe<Scalars['String']['input']>;
};

export type CreditorContact = {
  __typename?: 'CreditorContact';
  contactId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CsvExportResponse = {
  __typename?: 'CsvExportResponse';
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CsvFileForTransactions = {
  __typename?: 'CsvFileForTransactions';
  file: File;
};

export type Cursor = {
  __typename?: 'Cursor';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
  hasAfter?: Maybe<Scalars['Boolean']['output']>;
  hasBefore?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomRole = Role & {
  __typename?: 'CustomRole';
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  members: Array<User>;
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<User>;
};

export enum DatevExportType {
  /** DATEV accounting:extf-files API */
  DatevAccountingExtfFiles = 'DATEV_ACCOUNTING_EXTF_FILES',
  /** DATEV accounting:documents API */
  DatevDcoAccountingDocuments = 'DATEV_DCO_ACCOUNTING_DOCUMENTS',
  /** DATEV accounting:dxso-jobs API */
  DatevDcoAccountingDxsoJobs = 'DATEV_DCO_ACCOUNTING_DXSO_JOBS'
}

export type DateBookingValue = {
  __typename?: 'DateBookingValue';
  hasChanged: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Date']['output']>;
};

export type DateField = {
  __typename?: 'DateField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Array<Maybe<DateSource>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['Date']['output'];
};

export type DateFilter = {
  __typename?: 'DateFilter';
  dateFrom: Scalars['String']['output'];
  dateTo: Scalars['String']['output'];
  filterOption: DateFilterOption;
};

export type DateFilterInput = {
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
  filterOption: DateFilterOption;
};

export enum DateFilterOption {
  Custom = 'CUSTOM'
}

export type DateFilterType = {
  __typename?: 'DateFilterType';
  dateFrom: Scalars['String']['output'];
  dateTo: Scalars['String']['output'];
  filterOption: DateFilterOption;
};

export type DateSource = {
  __typename?: 'DateSource';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['Date']['output'];
};

export type DatevAvailableService = {
  __typename?: 'DatevAvailableService';
  service: DatevService;
  warning?: Maybe<DatevPermissionWarning>;
};

export type DatevBdsPreRequisites = {
  __typename?: 'DatevBDSPreRequisites';
  completed: Scalars['Boolean']['output'];
  step: BdsPreRequisiteStep;
};

export type DatevBdsPreRequisitesInput = {
  completed: Scalars['Boolean']['input'];
  step: BdsPreRequisiteStep;
};

export enum DatevClientExportType {
  AccountingDocuments = 'ACCOUNTING_DOCUMENTS',
  DxsoJobs = 'DXSO_JOBS'
}

export type DatevClientInfo = {
  __typename?: 'DatevClientInfo';
  basic_accounting_information: Array<BasicAccountingInformation>;
  client_number: Scalars['Int']['output'];
  consultant_number: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  is_document_management_available: Scalars['Boolean']['output'];
  ledgerState: DatevClientLedgerStates;
};

export type DatevClientInput = {
  clientNumber: Scalars['String']['input'];
  consultantNumber: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type DatevClientLedgerInfo = {
  __typename?: 'DatevClientLedgerInfo';
  is_accounts_payable_ledger_available: Scalars['Boolean']['output'];
  is_accounts_receivable_ledger_available: Scalars['Boolean']['output'];
  is_cash_ledger_available: Scalars['Boolean']['output'];
};

export type DatevClientLedgerStates = {
  __typename?: 'DatevClientLedgerStates';
  accountsPayable: LedgerState;
  accountsReceivable: LedgerState;
  cash: LedgerState;
};

export enum DatevConnectionType {
  DatevDuo = 'DATEV_DUO',
  DatevRewe = 'DATEV_REWE'
}

export type DatevCoreDataSource = {
  __typename?: 'DatevCoreDataSource';
  isAccountLengthSourceDatev: Scalars['Boolean']['output'];
  isChartOfAccountCodeSourceDatev: Scalars['Boolean']['output'];
  isFiscalStartDateSourceDatev: Scalars['Boolean']['output'];
};

export enum DatevIntegration {
  Datev = 'DATEV',
  DatevDxso = 'DATEV_DXSO'
}

export type DatevLocationParams = {
  __typename?: 'DatevLocationParams';
  /** Client Number of the related export entry */
  clientNumber: Scalars['String']['output'];
  /** Consultant Number of the related export entry */
  consultantNumber: Scalars['String']['output'];
  /** Document Export Guid of the related export entry */
  documentExportGuid: Scalars['String']['output'];
};

export type DatevOperationalStatus = {
  __typename?: 'DatevOperationalStatus';
  hasIncidentOnCandis: Scalars['Boolean']['output'];
  hasIncidentOnDatev: Scalars['Boolean']['output'];
  isOperational: Scalars['Boolean']['output'];
};

export enum DatevPermissionWarning {
  MissingRdsPermission = 'MISSING_RDS_PERMISSION'
}

export enum DatevProvisionsFilter {
  DeliveryDate = 'DELIVERY_DATE',
  InvoiceDate = 'INVOICE_DATE'
}

export enum DatevService {
  AccountingDocuments = 'ACCOUNTING_DOCUMENTS',
  DxsoJobs = 'DXSO_JOBS',
  ExtfFilesImport = 'EXTF_FILES_IMPORT'
}

export type DatevSettingsInput = {
  isAutomaticEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReweEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteAttachmentsInput = {
  attachmentIds: Array<Scalars['String']['input']>;
  documentId: Scalars['String']['input'];
};

export type DeleteByIdResp = {
  __typename?: 'DeleteByIdResp';
  count?: Maybe<Scalars['Int']['output']>;
};

export type DeleteCardForRecurringPaymentVendorInput = {
  cardLinkId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
};

export type DeleteDocumentRelationshipResponse = {
  __typename?: 'DeleteDocumentRelationshipResponse';
  id: Scalars['String']['output'];
};

export type DeleteFileUploadAttemptInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFileUploadAttemptResponse = {
  __typename?: 'DeleteFileUploadAttemptResponse';
  id: Scalars['ID']['output'];
};

export type DeleteIbanResult = DeleteIbanSuccessful | IbanError;

export type DeleteIbanSuccessful = {
  __typename?: 'DeleteIbanSuccessful';
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteInsightsWidgetsResult = {
  __typename?: 'DeleteInsightsWidgetsResult';
  deletedCount?: Maybe<Scalars['Int']['output']>;
};

export type DeleteReimbursementItemResponse = {
  __typename?: 'DeleteReimbursementItemResponse';
  deleted: Scalars['Boolean']['output'];
};

export type DeleteTablePreferenceInput = {
  tableId: Scalars['String']['input'];
};

export type DeletedDocumentFile = {
  __typename?: 'DeletedDocumentFile';
  downloadUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
};

export type DeliveryDateConflictError = DocumentApprovalError & {
  __typename?: 'DeliveryDateConflictError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DetachAttachmentsInput = {
  attachmentIds: Array<Scalars['String']['input']>;
  documentId: Scalars['String']['input'];
};

export enum DirectDocumentAccess {
  Edit = 'EDIT',
  /** Using NONE will remove the access level for the subject */
  None = 'NONE',
  View = 'VIEW'
}

export type DisconnectAllAssociatedDocumentsResponse = {
  __typename?: 'DisconnectAllAssociatedDocumentsResponse';
  documentIds: Array<Scalars['String']['output']>;
};

export type DisconnectPurchaseOrdersFromDocumentResponse = {
  __typename?: 'DisconnectPurchaseOrdersFromDocumentResponse';
  disconnectedPurchaseOrderIds: Array<Scalars['String']['output']>;
};

export type Document = UserAccess & {
  __typename?: 'Document';
  accessModificationPermissions: InvoiceAccessModificationPermissions;
  accountingArea?: Maybe<AccountingAreaField>;
  amount?: Maybe<FloatField>;
  approvedBy?: Maybe<Array<User>>;
  approvers?: Maybe<Array<User>>;
  archivedTags: Array<Tag>;
  associatedDocuments: Array<Maybe<AssociatedDocument>>;
  attachments?: Maybe<Array<DocumentAttachment>>;
  automaticPayment?: Maybe<BooleanField>;
  bookingKey?: Maybe<BookingKeyField>;
  bookings?: Maybe<Array<Booking>>;
  category?: Maybe<DocumentCategoryField>;
  contact?: Maybe<ContactField>;
  costCenter?: Maybe<CostCenterField>;
  costObject?: Maybe<CostCenterField>;
  createTransfer?: Maybe<BooleanField>;
  createdAt: Scalars['Date']['output'];
  currency?: Maybe<DocumentCurrencyField>;
  /**
   * This is deprecated but must remain on the type due to Document implementing
   * UserAccess.
   *
   * It will always be an empty array.
   * @deprecated Do not use, it is an empty array. Use the user's roles instead
   */
  currentUserAccess: Array<Scalars['String']['output']>;
  currentWorkflowStep?: Maybe<WorkflowStep>;
  datevGuid?: Maybe<StringField>;
  documentFile?: Maybe<DocumentFile>;
  eInvoice?: Maybe<DocumentEInvoiceField>;
  eInvoiceErrors?: Maybe<Array<EInvoiceValidationError>>;
  /** @deprecated Use eInvoiceErrors instead */
  eInvoiceValidationErrors?: Maybe<Array<Scalars['String']['output']>>;
  extractedContact?: Maybe<ExtractedContact>;
  from?: Maybe<DateField>;
  generalLedgerAccount?: Maybe<BookingAccountField>;
  /**
   * Global identifier of the document in Candis.
   *
   * TODO: make it required once ALL documents have ParentDocuments
   */
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  hasAttachments: Scalars['Boolean']['output'];
  hasSettlementPaymentLinked?: Maybe<Scalars['Boolean']['output']>;
  hasTransactionLinked?: Maybe<Scalars['Boolean']['output']>;
  iban?: Maybe<StringField>;
  id: Scalars['ID']['output'];
  invoiceDate?: Maybe<DateField>;
  invoiceDeliveryDate?: Maybe<DateField>;
  invoiceDeliveryPeriod?: Maybe<InvoiceDeliveryPeriod>;
  invoiceDueDate?: Maybe<DateField>;
  invoiceNumber?: Maybe<StringField>;
  invoicePostingDate?: Maybe<DateField>;
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  isEInvoice?: Maybe<Scalars['Boolean']['output']>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  isPayable?: Maybe<Scalars['Boolean']['output']>;
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isWaitingForClarification?: Maybe<Scalars['Boolean']['output']>;
  mentionedUsers?: Maybe<Array<User>>;
  note?: Maybe<StringField>;
  originalDocumentId?: Maybe<StringField>;
  payee?: Maybe<ContactField>;
  /**
   * Current payment state of the document. A document is considered Unpaid
   * when no payment information is available.
   */
  paymentState: DocumentPaymentState;
  permissionsForMembership?: Maybe<Array<InvoicePermission>>;
  purchaseOrderData?: Maybe<PurchaseOrderData>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
  purchaseOrders?: Maybe<Array<Maybe<PurchaseOrder>>>;
  requestedAt?: Maybe<DateField>;
  requester?: Maybe<User>;
  rolesThatAreEligibleToApprove?: Maybe<Array<Scalars['String']['output']>>;
  roundingAmount?: Maybe<Scalars['Float']['output']>;
  sourceClassification?: Maybe<StringField>;
  status: DocumentStatus;
  suggestedApprovers?: Maybe<SuggestedApproversField>;
  swiftCode?: Maybe<StringField>;
  tags: Array<Tag>;
  timeline?: Maybe<Array<DocumentTimeLineItem>>;
  to?: Maybe<DateField>;
  transactions?: Maybe<Array<CardIssuerTransaction>>;
  vatRate?: Maybe<FloatField>;
  workflow?: Maybe<Workflow>;
};

export type DocumentAccessChangeInput = {
  access: DirectDocumentAccess;
  subject: DocumentAccessSubject;
};

export type DocumentAccessSubject = {
  id: Scalars['String']['input'];
  type: DocumentAccessSubjectType;
};

export enum DocumentAccessSubjectType {
  Membership = 'MEMBERSHIP',
  Team = 'TEAM'
}

export type DocumentAcquisitionReversalValidationError = DocumentApprovalError & {
  __typename?: 'DocumentAcquisitionReversalValidationError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DocumentAmountField = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type DocumentApprovalError = {
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DocumentApprovalWorkflowStep = WorkflowStep & {
  __typename?: 'DocumentApprovalWorkflowStep';
  appliedAt?: Maybe<Scalars['Date']['output']>;
  approvers?: Maybe<Array<WorkflowUser>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next?: Maybe<WorkflowStep>;
  resolution?: Maybe<WorkflowStepResolution>;
  resolvedAt?: Maybe<Scalars['Date']['output']>;
  type?: Maybe<WorkflowTemplateStepType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DocumentAssociationsCreatedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentAssociationsCreatedEvent';
  associatedGoodsReceiptsWithPurchaseOrders?: Maybe<Array<GoodsReceiptAssociationWithPurchaseOrders>>;
  associatedPurchaseOrders?: Maybe<Array<Maybe<PurchaseOrderAssociation>>>;
  associatedPurchaseOrdersWithGoodsReceipts?: Maybe<Array<PurchaseOrderAssociationWithGoodsReceipts>>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /**
   * user who removed the booking associations
   * @deprecated Field no longer supported
   */
  user?: Maybe<ActivityUser>;
};

export type DocumentAssociationsRemovedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentAssociationsRemovedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  disconnectedGoodsReceiptsWithPurchaseOrders?: Maybe<Array<Maybe<GoodsReceiptAssociationWithPurchaseOrders>>>;
  disconnectedPurchaseOrders?: Maybe<Array<Maybe<PurchaseOrderAssociation>>>;
  disconnectedPurchaseOrdersWithGoodsReceipts?: Maybe<Array<Maybe<PurchaseOrderAssociationWithGoodsReceipts>>>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /**
   * user who removed the booking associations
   * @deprecated Field no longer supported
   */
  user?: Maybe<ActivityUser>;
};

export type DocumentAttachment = {
  __typename?: 'DocumentAttachment';
  attachedBy?: Maybe<User>;
  id: Scalars['String']['output'];
  isDetachable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  originalDocumentId?: Maybe<Scalars['String']['output']>;
  pages: Array<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  rank: Scalars['Int']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export type DocumentBookingAssociation = {
  __typename?: 'DocumentBookingAssociation';
  additionalExpenseInfo?: Maybe<AdditionalExpenseInfo>;
  articleId?: Maybe<Scalars['String']['output']>;
  bookingId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  documentId: Scalars['String']['output'];
  goodsReceiptsInfo?: Maybe<Array<GoodsReceiptInfo>>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  purchaseOrdersInfo?: Maybe<Array<PurchaseOrderInfo>>;
  quantity: Scalars['Float']['output'];
  source: AssociationSource;
  unitPrice: Money;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DocumentCannotBeModifedError = DocumentApprovalError & {
  __typename?: 'DocumentCannotBeModifedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: DocumentStatus;
};

export type DocumentCategory = {
  __typename?: 'DocumentCategory';
  direction: Scalars['String']['output'];
  documentType: Scalars['String']['output'];
  isBdsExportable?: Maybe<Scalars['Boolean']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isRds1_0Exportable: Scalars['Boolean']['output'];
  supportedCurrencies?: Maybe<Array<Maybe<DocumentCurrency>>>;
};

export type DocumentCategoryField = {
  __typename?: 'DocumentCategoryField';
  direction?: Maybe<StringField>;
  documentType?: Maybe<StringField>;
};

export type DocumentCategoryInputField = {
  direction: Scalars['String']['input'];
  documentType: Scalars['String']['input'];
};

export type DocumentChangedAsEcmDocumentLineItem = {
  amount?: Maybe<Money>;
  contact?: Maybe<ActivityContact>;
  costCenter?: Maybe<CostCenter>;
  documentDate?: Maybe<Scalars['Date']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentStatus?: Maybe<Scalars['String']['output']>;
  documentSubCategory?: Maybe<EcmDocumentSubCategoryField>;
  documentType?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  isSensitive?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notifyPerson?: Maybe<EcmDocumentNotifyPerson>;
  responsiblePerson?: Maybe<EcmDocumentResponsiblePerson>;
  startDate?: Maybe<Scalars['Date']['output']>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  terminationReminderDate?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<ActivityUser>;
};

/** Document file upload event */
export type DocumentClassifiedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentClassifiedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The document type with which the document was classified */
  documentType?: Maybe<DocumentType>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentCollaboration = {
  __typename?: 'DocumentCollaboration';
  editors: Array<Collaborator>;
  id: Scalars['ID']['output'];
  viewers: Array<Collaborator>;
};

export type DocumentCommentCreatedInAppNotification = InAppNotification & {
  __typename?: 'DocumentCommentCreatedInAppNotification';
  author: User;
  commentCreatedAt: Scalars['Date']['output'];
  commentText: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentType: DocumentType;
  fileName: Scalars['String']['output'];
  globalDocumentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceAmount?: Maybe<NotificationAmount>;
  invoiceContact?: Maybe<Scalars['String']['output']>;
  invoiceStatus?: Maybe<Scalars['String']['output']>;
  invoiceType?: Maybe<Scalars['String']['output']>;
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
};

/** Document comment creation event */
export type DocumentCommentEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentCommentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** The posted comment */
  comment?: Maybe<ActivityComment>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  _source?: Maybe<Scalars['String']['output']>;
  edges?: Maybe<Array<DocumentEdge>>;
  node?: Maybe<Array<Document>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentConvertedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentConvertedEvent';
  clientInfo?: Maybe<ClientInfo>;
  convertedFrom?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<ActivityUser>;
};

export type DocumentConvertedToAttachmentEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentConvertedToAttachmentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The document that this document is attached to */
  target?: Maybe<DocumentData>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentCount = {
  __typename?: 'DocumentCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export enum DocumentCurrency {
  Aed = 'AED',
  Afl = 'AFL',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Imp = 'IMP',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jep = 'JEP',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Prb = 'PRB',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Tvd = 'TVD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zmw = 'ZMW',
  Zwd = 'ZWD',
  Zwl = 'ZWL'
}

export type DocumentCurrencyField = {
  __typename?: 'DocumentCurrencyField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: DocumentCurrency;
};

export type DocumentCurrencySource = {
  __typename?: 'DocumentCurrencySource';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  value: DocumentCurrency;
};

export type DocumentData = {
  __typename?: 'DocumentData';
  fileName?: Maybe<Scalars['String']['output']>;
};

export type DocumentDataExtractionSettings = {
  __typename?: 'DocumentDataExtractionSettings';
  suggestOutgoingInvoices: Scalars['Boolean']['output'];
};

export type DocumentDataFileRecord = {
  __typename?: 'DocumentDataFileRecord';
  createdAt: Scalars['Date']['output'];
  creator?: Maybe<User>;
  dateRangeFilters?: Maybe<DocumentDateFilters>;
  ecmFilters?: Maybe<EcmDocumentFilters>;
  file?: Maybe<FileResponse>;
  fileType?: Maybe<DocumentDataFileType>;
  filters?: Maybe<DownloadFilters>;
  id: Scalars['ID']['output'];
  isAcknowledged: Scalars['Boolean']['output'];
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  recordCount?: Maybe<Scalars['Int']['output']>;
  reimbursementCaseFilters?: Maybe<ReimbursementCaseFilters>;
  searchInput?: Maybe<DocumentSearchInput>;
  status?: Maybe<DocumentDataFileStatus>;
  validUntil?: Maybe<Scalars['Date']['output']>;
};

export enum DocumentDataFileStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED',
  Successful = 'SUCCESSFUL'
}

export enum DocumentDataFileType {
  Csv = 'CSV',
  Zip = 'ZIP'
}

export type DocumentDateFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  dueDateWithCashDiscount?: InputMaybe<DateFilterInput>;
  invoiceDate?: InputMaybe<DateFilterInput>;
  invoiceDeliveryDate?: InputMaybe<DateFilterInput>;
  invoiceDueDate?: InputMaybe<DateFilterInput>;
  paidAt?: InputMaybe<DateFilterInput>;
};

export type DocumentDateFilters = {
  __typename?: 'DocumentDateFilters';
  createdAt?: Maybe<DateFilter>;
  dueDateWithCashDiscount?: Maybe<DateFilter>;
  invoiceDate?: Maybe<DateFilter>;
  invoiceDeliveryDate?: Maybe<DateFilter>;
  invoiceDueDate?: Maybe<DateFilter>;
  paidAt?: Maybe<DateFilter>;
};

export type DocumentDeletedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentDeletedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that deleted the document */
  user?: Maybe<ActivityUser>;
};

export type DocumentDoesNotExistError = DocumentApprovalError & {
  __typename?: 'DocumentDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DocumentDownloadUrl = {
  __typename?: 'DocumentDownloadUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentEInvoiceField = {
  __typename?: 'DocumentEInvoiceField';
  error?: Maybe<EInvoiceError>;
  errorMessages?: Maybe<Array<Scalars['String']['output']>>;
  format?: Maybe<EInvoiceFormat>;
};

export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  cursor: Scalars['String']['output'];
  node: Document;
};

export type DocumentFastApproveErrors = {
  __typename?: 'DocumentFastApproveErrors';
  errors: Array<FastApproveError>;
};

export type DocumentFastApproveInput = {
  accountingAreaId?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Float']['input'];
  automaticPayment?: InputMaybe<Scalars['Boolean']['input']>;
  bookings: Array<BookingInput>;
  category: DocumentCategoryInputField;
  contactId: Scalars['String']['input'];
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  currency: DocumentCurrency;
  iban?: InputMaybe<Scalars['IBAN']['input']>;
  invoiceDate: Scalars['Date']['input'];
  invoiceDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  invoiceDeliveryPeriod?: InputMaybe<InvoiceDeliveryPeriodInput>;
  invoiceDueDate?: InputMaybe<Scalars['Date']['input']>;
  invoiceNumber: Scalars['String']['input'];
  invoicePostingDate?: InputMaybe<Scalars['Date']['input']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  roundingAmount?: InputMaybe<Scalars['Float']['input']>;
  status: DocumentStatus;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentFastApproveOptionsInput = {
  duplicateCheckByContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DocumentFastApproveResult = Document | DocumentFastApproveErrors;

export type DocumentFile = {
  __typename?: 'DocumentFile';
  /** Some document files may have an additional XML file for the EInvoice data */
  eInvoice?: Maybe<EInvoiceFile>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pages: Array<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
  versions: Scalars['String']['output'];
};

/** Document file upload event */
export type DocumentFileReplacedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentFileReplacedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The file that was uploaded */
  newFile?: Maybe<File>;
  /** The file that was replaced */
  oldFile?: Maybe<File>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentFilterInput = {
  accountingAreaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  accountsPayableNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  approverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  artistSocialInsuranceCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  cardTransactionStatus?: InputMaybe<Array<TransactionStatus>>;
  contactIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costCenterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costObjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  documentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  extraCostInfoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  generalLedgerAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hasTransactionLinked?: InputMaybe<Scalars['Boolean']['input']>;
  isDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  isInvoiceCorrection?: InputMaybe<Scalars['Boolean']['input']>;
  isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  isPayable?: InputMaybe<Scalars['Boolean']['input']>;
  isWaitingForClarification?: InputMaybe<Scalars['Boolean']['input']>;
  requesterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<DocumentStatus>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DocumentFilters = {
  __typename?: 'DocumentFilters';
  approvers?: Maybe<Array<User>>;
  contacts?: Maybe<Array<Contact>>;
  costCenters?: Maybe<Array<CostCenter>>;
  costObjects?: Maybe<Array<CostCenter>>;
  extraCostInfos?: Maybe<Array<CostCenter>>;
  generalLedgerAccounts?: Maybe<Array<BookingAccount>>;
  hasTransactionLinked?: Maybe<Scalars['Boolean']['output']>;
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  isPayable?: Maybe<Scalars['Boolean']['output']>;
  requesters?: Maybe<Array<User>>;
  statuses?: Maybe<Array<DocumentStatus>>;
};

export type DocumentGrossNetTaxRoundingAmountChangedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentGrossNetTaxRoundingAmountChangedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentGrossAmount?: Maybe<Scalars['Float']['output']>;
  documentNetAmount?: Maybe<Scalars['Float']['output']>;
  documentRoundingAmount?: Maybe<Scalars['Float']['output']>;
  documentTaxAmount?: Maybe<Scalars['Float']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** user who updated document level amount values */
  user?: Maybe<ActivityUser>;
};

export type DocumentGrossNetTaxRoundingAmountsCalculationError = DocumentApprovalError & {
  __typename?: 'DocumentGrossNetTaxRoundingAmountsCalculationError';
  expectedResult?: Maybe<CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult>;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DocumentGrossNetTaxRoundingAmountsValidationError = DocumentApprovalError & {
  __typename?: 'DocumentGrossNetTaxRoundingAmountsValidationError';
  index?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DocumentHasBookingKeyButMissingGlaError = DocumentApprovalError & {
  __typename?: 'DocumentHasBookingKeyButMissingGLAError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Document imported using migration tool */
export type DocumentImportedByMigrationEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentImportedByMigrationEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The file that was imported */
  file?: Maybe<File>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  importedFromSystem?: Maybe<Scalars['String']['output']>;
  user?: Maybe<ActivityUser>;
};

export type DocumentIsAlreadyRequestedForApprovalError = DocumentApprovalError & {
  __typename?: 'DocumentIsAlreadyRequestedForApprovalError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: DocumentStatus;
};

export type DocumentMetric = {
  __typename?: 'DocumentMetric';
  count: Scalars['Int']['output'];
};

export type DocumentPaymentData = {
  __typename?: 'DocumentPaymentData';
  isPaid: Scalars['Boolean']['output'];
  paidAt?: Maybe<Scalars['Date']['output']>;
};

/** When a documents payment's information gets updated */
export type DocumentPaymentInfoUpdatedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentPaymentInfoUpdatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt: Scalars['Date']['output'];
  /** The unique id of the activity item */
  id: Scalars['ID']['output'];
  /** The user that generated the SEPA transfer */
  updatedBy: ActivityUser;
  /** Payment fields that were part of the update */
  updatedFields: Array<UpdatedPaymentField>;
  updatedPaymentFields: Array<UpdatedDocumentPaymentField>;
};

export type DocumentPaymentState = PaidDocumentState | UnpaidDocumentState;

export type DocumentRejectedInAppNotification = InAppNotification & {
  __typename?: 'DocumentRejectedInAppNotification';
  commentText: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  documentNumber: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceAmount?: Maybe<NotificationAmount>;
  invoiceContact?: Maybe<Scalars['String']['output']>;
  invoiceStatus?: Maybe<Scalars['String']['output']>;
  invoiceType?: Maybe<Scalars['String']['output']>;
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  rejectedAt: Scalars['Date']['output'];
  rejectedBy: User;
};

export type DocumentRelationship = {
  __typename?: 'DocumentRelationship';
  id?: Maybe<Scalars['String']['output']>;
  linkedDocument?: Maybe<RelatedDocumentUnion>;
};

export type DocumentRelationshipCountReponse = {
  __typename?: 'DocumentRelationshipCountReponse';
  totalCount: Scalars['Int']['output'];
};

export type DocumentRelationshipCreatedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentRelationshipCreatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  linkedDocuments?: Maybe<Array<Maybe<RelatedDocumentUnion>>>;
  user?: Maybe<ActivityUser>;
};

export type DocumentRelationshipRemovedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentRelationshipRemovedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  unlinkedDocument?: Maybe<RelatedDocumentUnion>;
  user?: Maybe<ActivityUser>;
};

export type DocumentRequestApprovalErrors = {
  __typename?: 'DocumentRequestApprovalErrors';
  errors: Array<RequestApprovalError>;
};

export type DocumentRequestApprovalInput = {
  accountingAreaId?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Float']['input'];
  approverIds?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  automaticPayment?: InputMaybe<Scalars['Boolean']['input']>;
  bookings: Array<BookingInput>;
  category: DocumentCategoryInputField;
  contactId: Scalars['String']['input'];
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  currency: DocumentCurrency;
  iban?: InputMaybe<Scalars['IBAN']['input']>;
  invoiceDate: Scalars['Date']['input'];
  invoiceDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  invoiceDeliveryPeriod?: InputMaybe<InvoiceDeliveryPeriodInput>;
  invoiceDueDate?: InputMaybe<Scalars['Date']['input']>;
  invoiceNumber: Scalars['String']['input'];
  invoicePostingDate?: InputMaybe<Scalars['Date']['input']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  roundingAmount?: InputMaybe<Scalars['Float']['input']>;
  status: DocumentStatus;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  workflowTemplateId?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentRequestApprovalOptionsInput = {
  duplicateCheckByContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DocumentRequestApprovalResult = Document | DocumentRequestApprovalErrors;

/** When a document has been reset by the Candis due to unusual reasons */
export type DocumentResetByCandis = DocumentTimeLineItem & {
  __typename?: 'DocumentResetByCandis';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt: Scalars['Date']['output'];
  /** The unique id of the activity item */
  id: Scalars['ID']['output'];
  /** The reason why the document was reset by Candis */
  reason: Scalars['String']['output'];
};

/** When a User manually reset the Document */
export type DocumentResetManualEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentResetManualEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

/** When a document is reset due to transaction amount being updated */
export type DocumentResetTransactionAmountUpdatedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentResetTransactionAmountUpdatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

/** When a document is reset due to transaction being linked to it */
export type DocumentResetTransactionLinkedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentResetTransactionLinkedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

/** When a document is reset due to transaction being unlinked from it */
export type DocumentResetTransactionUnLinkedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentResetTransactionUnLinkedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

/** When a document is reset due to an involved user being de-activated */
export type DocumentResetUserDeactivationEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentResetUserDeactivationEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that was de-activated */
  targetUser?: Maybe<ActivityUser>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

/** When a document is reset due to an involved user having their role changed */
export type DocumentResetUserRoleChangedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentResetUserRoleChangedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that had their role changed */
  targetUser?: Maybe<ActivityUser>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentSearchInput = {
  __typename?: 'DocumentSearchInput';
  searchScope?: Maybe<SearchScope>;
  searchType?: Maybe<SearchType>;
};

/** When a Sepa XML transfer was generated for a given document */
export type DocumentSepaTransferGeneratedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentSepaTransferGeneratedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The date when the SEPA transfer was executed */
  executionDate: Scalars['Date']['output'];
  /** The user that generated the SEPA transfer */
  generatedBy: ActivityUser;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The associated payment's id */
  paymentId: Scalars['String']['output'];
};

export type DocumentSort = {
  direction: SortDirection;
  field: DocumentSortField;
};

export enum DocumentSortField {
  CreatedAt = 'CREATED_AT',
  GrossAmount = 'GROSS_AMOUNT',
  InvoiceDate = 'INVOICE_DATE',
  InvoiceDueDate = 'INVOICE_DUE_DATE',
  Status = 'STATUS'
}

export type DocumentStatsAggregate = {
  __typename?: 'DocumentStatsAggregate';
  aggregate: DocumentStatsMonetaryDetail;
  details: Array<DocumentStatsMonetaryDetail>;
  exchangeRateCalculatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DocumentStatsMonetaryDetail = {
  __typename?: 'DocumentStatsMonetaryDetail';
  amount: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  currency: DocumentCurrency;
};

export enum DocumentStatus {
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  New = 'NEW',
  Open = 'OPEN',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED'
}

/** Document got stored in the ECM system */
export type DocumentStoredAsEcmDocumentEvent = DocumentChangedAsEcmDocumentLineItem & DocumentTimeLineItem & {
  __typename?: 'DocumentStoredAsEcmDocumentEvent';
  amount?: Maybe<Money>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  contact?: Maybe<ActivityContact>;
  costCenter?: Maybe<CostCenter>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentDate?: Maybe<Scalars['Date']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentStatus?: Maybe<Scalars['String']['output']>;
  documentSubCategory?: Maybe<EcmDocumentSubCategoryField>;
  documentType?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isSensitive?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notifyPerson?: Maybe<EcmDocumentNotifyPerson>;
  responsiblePerson?: Maybe<EcmDocumentResponsiblePerson>;
  startDate?: Maybe<Scalars['Date']['output']>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  terminationReminderDate?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<ActivityUser>;
};

export type DocumentSubmitWorkflowStepErrors = {
  __typename?: 'DocumentSubmitWorkflowStepErrors';
  errors: Array<SubmitWorkflowStepError>;
};

export type DocumentTagUpdatedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentTagUpdatedEvent';
  archivedTags: Array<Tag>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  tags: Array<Tag>;
  user?: Maybe<ActivityUser>;
};

/** Represents any sort of activity on a document, e.g. an approval or export */
export type DocumentTimeLineItem = {
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

/** Document transaction comment creation event */
export type DocumentTransactionCommentCreatedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentTransactionCommentCreatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** The posted comment */
  comment?: Maybe<ActivityComment>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentTransactionLinkedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentTransactionLinkedEvent';
  /** Amount */
  amount?: Maybe<Money>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** Transaction Merchant Name */
  merchantName?: Maybe<Scalars['String']['output']>;
  /** Automatching task id */
  receiptAutomatchingTaskId?: Maybe<Scalars['String']['output']>;
  /** Transaction Date */
  transactionDate?: Maybe<Scalars['Date']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentTransactionReconciledEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentTransactionReconciledEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The reconciled transaction activity */
  properties: ActivityTransactionReconciled;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentTransactionStatusUpdatedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentTransactionStatusUpdatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** New transaction status */
  newStatus: Scalars['String']['output'];
  /** Old transaction status */
  oldStatus?: Maybe<Scalars['String']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type DocumentTransactionUnlinkedEvent = DocumentTimeLineItem & {
  __typename?: 'DocumentTransactionUnlinkedEvent';
  /** Amount */
  amount?: Maybe<Money>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** Transaction Merchant Name */
  merchantName?: Maybe<Scalars['String']['output']>;
  /** Automatching task id */
  receiptAutomatchingTaskId?: Maybe<Scalars['String']['output']>;
  /** Transaction Date */
  transactionDate?: Maybe<Scalars['Date']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export enum DocumentType {
  AccountStatement = 'ACCOUNT_STATEMENT',
  Contract = 'CONTRACT',
  DeliveryNote = 'DELIVERY_NOTE',
  ExpenseReimbursementItem = 'EXPENSE_REIMBURSEMENT_ITEM',
  FeeNotice = 'FEE_NOTICE',
  Guarantee = 'GUARANTEE',
  Invoice = 'INVOICE',
  Offer = 'OFFER',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  Other = 'OTHER',
  PurchaseOrder = 'PURCHASE_ORDER',
  Reminder = 'REMINDER',
  RemittanceAdvise = 'REMITTANCE_ADVISE',
  TravelReimbursementItem = 'TRAVEL_REIMBURSEMENT_ITEM'
}

export type DocumentTypeId = {
  category: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type DocumentUnion = AggregatedEcmDocument | Document;

/** Document was updated in the ECM system */
export type DocumentUpdatedAsEcmDocumentEvent = DocumentChangedAsEcmDocumentLineItem & DocumentTimeLineItem & {
  __typename?: 'DocumentUpdatedAsEcmDocumentEvent';
  amount?: Maybe<Money>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  contact?: Maybe<ActivityContact>;
  costCenter?: Maybe<CostCenter>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentDate?: Maybe<Scalars['Date']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentStatus?: Maybe<Scalars['String']['output']>;
  documentSubCategory?: Maybe<EcmDocumentSubCategoryField>;
  documentType?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isSensitive?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notifyPerson?: Maybe<EcmDocumentNotifyPerson>;
  responsiblePerson?: Maybe<EcmDocumentResponsiblePerson>;
  startDate?: Maybe<Scalars['Date']['output']>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  terminationReminderDate?: Maybe<Scalars['Date']['output']>;
  updatedFields: Array<Maybe<Scalars['String']['output']>>;
  user?: Maybe<ActivityUser>;
};

export type DocumentUploadUrl = {
  __typename?: 'DocumentUploadUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentWithId = {
  __typename?: 'DocumentWithId';
  id: Scalars['ID']['output'];
};

export type DocumentWithIncompletePaymentData = PayableDocument & {
  __typename?: 'DocumentWithIncompletePaymentData';
  amount: Money;
  contact: Contact;
  currency: DocumentCurrency;
  /** Discount fields */
  discountAmount?: Maybe<Money>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** The date displayed on the invoice */
  invoiceDate: Scalars['Date']['output'];
  invoiceDueDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  status: DocumentStatus;
};

export type DocumentWorkflowStepSubmitBookingInput = {
  amount: Scalars['Float']['input'];
  artistSocialInsuranceCode?: InputMaybe<ArtistSocialInsuranceCode>;
  bookingKeyId?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  costObjectId?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountPaymentDate?: InputMaybe<Scalars['Date']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  extraCostInfoId?: InputMaybe<Scalars['String']['input']>;
  generalLedgerAccountId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  netAmount?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  paymentConditionId?: InputMaybe<Scalars['String']['input']>;
  postingText?: InputMaybe<Scalars['String']['input']>;
  projectCodeId?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  sapExpenseType?: InputMaybe<SapExpenseType>;
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};

export type DocumentWorkflowStepSubmitInput = {
  accountingAreaId?: InputMaybe<Scalars['String']['input']>;
  approverId?: InputMaybe<Scalars['String']['input']>;
  bookingKeyId?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<Array<DocumentWorkflowStepSubmitBookingInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  costObjectId?: InputMaybe<Scalars['String']['input']>;
  extraCostInfoId?: InputMaybe<Scalars['String']['input']>;
  generalLedgerAccountId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  resolution: WorkflowStepResolutionTypes;
  roundingAmount?: InputMaybe<Scalars['Float']['input']>;
  stepId: Scalars['String']['input'];
};

export type DocumentWorkflowStepSubmitResult = Document | DocumentSubmitWorkflowStepErrors;

export type DocumentsListResponse = {
  __typename?: 'DocumentsListResponse';
  count?: Maybe<Scalars['Int']['output']>;
  documents?: Maybe<Array<Document>>;
};

export type DocumentsReadyForExportResponse = {
  __typename?: 'DocumentsReadyForExportResponse';
  documents: Array<ExportableDocument>;
};

export type DownloadDocumentPdFsError = {
  __typename?: 'DownloadDocumentPDFsError';
  kind?: Maybe<DownloadDocumentPdFsErrorKind>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum DownloadDocumentPdFsErrorKind {
  Forbidden = 'FORBIDDEN',
  LimitReached = 'LIMIT_REACHED',
  NoDocumentsFound = 'NO_DOCUMENTS_FOUND',
  Unknown = 'UNKNOWN'
}

export type DownloadDocumentPdFsResponse = DownloadDocumentPdFsError | DownloadDocumentPdFsResult;

export type DownloadDocumentPdFsResult = {
  __typename?: 'DownloadDocumentPDFsResult';
  response?: Maybe<Scalars['String']['output']>;
};

export type DownloadFilters = {
  __typename?: 'DownloadFilters';
  approvers?: Maybe<Array<User>>;
  artistSocialInsuranceCodes?: Maybe<Array<Scalars['String']['output']>>;
  contacts?: Maybe<Array<Contact>>;
  costCenters?: Maybe<Array<CostCenter>>;
  costObjects?: Maybe<Array<CostCenter>>;
  extraCostInfos?: Maybe<Array<CostCenter>>;
  generalLedgerAccounts?: Maybe<Array<BookingAccount>>;
  hasTransactionLinked?: Maybe<Scalars['Boolean']['output']>;
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  isPayable?: Maybe<Scalars['Boolean']['output']>;
  requesters?: Maybe<Array<User>>;
  statuses?: Maybe<Array<DocumentStatus>>;
};

export type DuplicateGroup = {
  __typename?: 'DuplicateGroup';
  contacts: Array<Maybe<Contact>>;
  value: Scalars['String']['output'];
};

export enum EInvoiceError {
  InvalidXml = 'INVALID_XML',
  OutdatedFormat = 'OUTDATED_FORMAT'
}

export type EInvoiceErrorCodeUrls = {
  __typename?: 'EInvoiceErrorCodeUrls';
  de: Scalars['String']['output'];
  en: Scalars['String']['output'];
};

export type EInvoiceFile = {
  __typename?: 'EInvoiceFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum EInvoiceFormat {
  Unknown = 'UNKNOWN',
  XrechnungCii = 'XRECHNUNG_CII',
  XrechnungUbl = 'XRECHNUNG_UBL',
  ZugferdBasic = 'ZUGFERD_BASIC',
  ZugferdBasicWl = 'ZUGFERD_BASIC_WL',
  ZugferdEn16931 = 'ZUGFERD_EN16931',
  ZugferdExtended = 'ZUGFERD_EXTENDED',
  ZugferdMinimum = 'ZUGFERD_MINIMUM',
  ZugferdV1 = 'ZUGFERD_V1',
  ZugferdXrechnung = 'ZUGFERD_XRECHNUNG'
}

export type EInvoiceValidationError = {
  __typename?: 'EInvoiceValidationError';
  code?: Maybe<Scalars['String']['output']>;
  codeHelpCenterUrl?: Maybe<EInvoiceErrorCodeUrls>;
  location?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type EcmCreateSignedUrlInput = {
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export type EcmCreateSignedUrlResponse = {
  __typename?: 'EcmCreateSignedUrlResponse';
  fields: Array<KeyValue>;
  url: Scalars['String']['output'];
};

export type EcmDateFilter = {
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};

export type EcmDocument = {
  __typename?: 'EcmDocument';
  accessModificationPermissions: EcmDocumentAccessModificationPermissions;
  amount?: Maybe<Money>;
  archivedTags: Array<Tag>;
  contactId?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  costCenterId?: Maybe<Scalars['String']['output']>;
  costCenterName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['String']['output'];
  documentDate?: Maybe<Scalars['Date']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  documentStatus?: Maybe<EcmDocumentStatus>;
  documentSubCategory?: Maybe<EcmDocumentSubCategoryField>;
  /** @deprecated Field no longer supported */
  documentType: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  fileName: Scalars['String']['output'];
  /** Global identifier of the document in Candis */
  globalDocumentId: Scalars['String']['output'];
  /** @deprecated Do not use, it is an empty array. Use the edge highlights instead */
  highlights?: Maybe<Array<EcmHighlight>>;
  id: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  /**
   * This field is specifically for Invoices (not ECM). But is needed here because the ALL DOCUMENTS view on the UI uses the query getECMDocuments.
   * Once this view is updated to use the correct query, this field should be removed.
   */
  isEInvoice?: Maybe<Scalars['Boolean']['output']>;
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isSensitive?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notifyPerson?: Maybe<EcmDocumentNotifyPerson>;
  /** Returns a list of actions the current membership is authorized to perform on the document */
  permissionsForMembership?: Maybe<Array<EcmDocumentPermission>>;
  responsiblePerson?: Maybe<EcmDocumentResponsiblePerson>;
  startDate?: Maybe<Scalars['Date']['output']>;
  tags: Array<Tag>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  terminationReminderDate?: Maybe<Scalars['Date']['output']>;
  type: DocumentType;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['String']['output'];
};

export type EcmDocumentAccessModificationPermissions = {
  __typename?: 'EcmDocumentAccessModificationPermissions';
  viewAccess: Scalars['Boolean']['output'];
};

export enum EcmDocumentContractStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum EcmDocumentContractSubCategory {
  CompanyAgreement = 'COMPANY_AGREEMENT',
  DeliveryContract = 'DELIVERY_CONTRACT',
  Insurance = 'INSURANCE',
  LeasingContract = 'LEASING_CONTRACT',
  LoanAgreement = 'LOAN_AGREEMENT',
  MaintenanceContract = 'MAINTENANCE_CONTRACT',
  Other = 'OTHER',
  PurchasingContract = 'PURCHASING_CONTRACT',
  RentalContract = 'RENTAL_CONTRACT',
  ServiceContract = 'SERVICE_CONTRACT'
}

export type EcmDocumentEdge = {
  __typename?: 'EcmDocumentEdge';
  cursor: Scalars['String']['output'];
  highlights: Array<EcmHighlight>;
  record: EcmDocument;
};

export type EcmDocumentExtractedContact = {
  __typename?: 'EcmDocumentExtractedContact';
  automaticPayment?: Maybe<Scalars['Boolean']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  cachingKey: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  customerNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postOfficeBox?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  vatId?: Maybe<Scalars['String']['output']>;
};

export type EcmDocumentFilters = {
  __typename?: 'EcmDocumentFilters';
  contactId?: Maybe<Array<Contact>>;
  contractStatus?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  documentDate?: Maybe<DateFilter>;
  documentStatus?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  documentSubCategory?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  documentType?: Maybe<Array<DocumentType>>;
  endDate?: Maybe<DateFilter>;
  notifyPerson?: Maybe<Array<User>>;
  responsiblePerson?: Maybe<Array<User>>;
  startDate?: Maybe<DateFilter>;
  /** @deprecated Use documentSubCategory */
  subCategory?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  terminationDate?: Maybe<DateFilter>;
  terminationReminderDate?: Maybe<DateFilter>;
};

export type EcmDocumentNotifyPerson = {
  __typename?: 'EcmDocumentNotifyPerson';
  primary: Array<User>;
};

export type EcmDocumentNotifyPersonInput = {
  primary: Array<Scalars['String']['input']>;
};

export enum EcmDocumentPermission {
  AddRelationship = 'ADD_RELATIONSHIP',
  Comment = 'COMMENT',
  Delete = 'DELETE',
  ManageAccess = 'MANAGE_ACCESS',
  ManageTags = 'MANAGE_TAGS',
  RemoveRelationship = 'REMOVE_RELATIONSHIP',
  UpdateData = 'UPDATE_DATA',
  View = 'VIEW'
}

export type EcmDocumentResponsiblePerson = {
  __typename?: 'EcmDocumentResponsiblePerson';
  primary: Array<User>;
};

export type EcmDocumentResponsiblePersonInput = {
  primary: Array<Scalars['String']['input']>;
};

export enum EcmDocumentStatus {
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  New = 'NEW',
  Open = 'OPEN',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Stored = 'STORED'
}

export type EcmDocumentSubCategoryField = {
  __typename?: 'EcmDocumentSubCategoryField';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type EcmDocumentTypeSettingRecord = {
  __typename?: 'EcmDocumentTypeSettingRecord';
  documentCount?: Maybe<Scalars['Int']['output']>;
  isNonEcmType: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type EcmDocumentTypeSettingsSortInput = {
  documentCount?: InputMaybe<EcmDocumentTypeSettingsSortOrder>;
  isVisible?: InputMaybe<EcmDocumentTypeSettingsSortOrder>;
  name?: InputMaybe<EcmDocumentTypeSettingsSortOrder>;
};

export enum EcmDocumentTypeSettingsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type EcmFileSource = {
  __typename?: 'EcmFileSource';
  contentType: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type EcmFilterInput = {
  amount?: InputMaybe<AmountFilter>;
  contactId?: InputMaybe<Array<Scalars['String']['input']>>;
  contractStatus?: InputMaybe<Array<EcmDocumentContractStatus>>;
  costCenterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  documentDate?: InputMaybe<EcmDateFilter>;
  documentStatus?: InputMaybe<Array<EcmDocumentStatus>>;
  documentSubCategory?: InputMaybe<Array<Scalars['String']['input']>>;
  documentType?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<EcmDateFilter>;
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  notifyPerson?: InputMaybe<Array<Scalars['String']['input']>>;
  responsiblePerson?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<EcmDateFilter>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  terminationDate?: InputMaybe<EcmDateFilter>;
  terminationReminderDate?: InputMaybe<EcmDateFilter>;
};

export type EcmHighlight = {
  __typename?: 'EcmHighlight';
  chunks: Array<HighlightChunk>;
  field: EcmHighlightableFields;
};

export enum EcmHighlightableFields {
  Amount = 'AMOUNT',
  ContactAccountsPayableNumber = 'CONTACT_ACCOUNTS_PAYABLE_NUMBER',
  ContactName = 'CONTACT_NAME',
  CostCenter = 'COST_CENTER',
  CreatedBy = 'CREATED_BY',
  DocumentName = 'DOCUMENT_NAME',
  DocumentNumber = 'DOCUMENT_NUMBER',
  FileName = 'FILE_NAME',
  Notes = 'NOTES',
  RawContent = 'RAW_CONTENT',
  UpdatedBy = 'UPDATED_BY'
}

export type EcmPageInfo = {
  __typename?: 'EcmPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextPageCursor: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
};

export type EcmSortInput = {
  amount?: InputMaybe<EcmSortOrder>;
  contact?: InputMaybe<EcmSortOrder>;
  documentDate?: InputMaybe<EcmSortOrder>;
  documentName?: InputMaybe<EcmSortOrder>;
  documentNumber?: InputMaybe<EcmSortOrder>;
  documentStatus?: InputMaybe<EcmSortOrder>;
  documentSubCategory?: InputMaybe<EcmSortOrder>;
  documentType?: InputMaybe<EcmSortOrder>;
  ecmDocumentCostCenter?: InputMaybe<EcmSortOrder>;
  endDate?: InputMaybe<EcmSortOrder>;
  startDate?: InputMaybe<EcmSortOrder>;
  terminationDate?: InputMaybe<EcmSortOrder>;
  terminationReminderDate?: InputMaybe<EcmSortOrder>;
};

export enum EcmSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type EmailImportErrorNotification = {
  __typename?: 'EmailImportErrorNotification';
  emailMessageId: Scalars['ID']['output'];
  fromAddress: Scalars['String']['output'];
};

export type EmailInboxMessage = {
  __typename?: 'EmailInboxMessage';
  attachments?: Maybe<Array<EmailInboxMessageAttachment>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  dkimVerdict?: Maybe<EmailInboxMessageVerdict>;
  dmarcVerdict?: Maybe<EmailInboxMessageVerdict>;
  from?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Activity>>;
  htmlLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Total number of attachments */
  numberOfAttachments?: Maybe<Scalars['Int']['output']>;
  /** Number of erroneous attachments */
  numberOfErroneousAttachments?: Maybe<Scalars['Int']['output']>;
  /** Number of generated documents */
  numberOfGeneratedDocuments?: Maybe<Scalars['Int']['output']>;
  receivedAt?: Maybe<Scalars['Date']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  spamVerdict?: Maybe<EmailInboxMessageVerdict>;
  spfVerdict?: Maybe<EmailInboxMessageVerdict>;
  /** E-Mail processing status */
  status: EmailInboxMessageStatus;
  subject?: Maybe<Scalars['String']['output']>;
  textLink?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  virusVerdict?: Maybe<EmailInboxMessageVerdict>;
};

export type EmailInboxMessageAttachment = EmailInboxMessageAttachmentWithDocument | EmailInboxMessageAttachmentWithError;

export type EmailInboxMessageAttachmentError = AttachmentDocumentNotFoundError | AttachmentDocumentRemovedError | EmailMessageVirusDetected | InvalidXRechnungAttachment | MaxAttachmentSizeExceeded | UnprocessableAttachment | UnprocessableXmlAttachment | UnsupportedAttachmentType;

export type EmailInboxMessageAttachmentWithDocument = {
  __typename?: 'EmailInboxMessageAttachmentWithDocument';
  documentFileName: Scalars['String']['output'];
  documentId?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  isEInvoice: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type EmailInboxMessageAttachmentWithError = {
  __typename?: 'EmailInboxMessageAttachmentWithError';
  error: EmailInboxMessageAttachmentError;
  fileId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
};

export enum EmailInboxMessageStatus {
  /**  E-mail could not be fully processed e.g, an attachment might have exceeded the maximum file size or was unproccessable */
  Failed = 'FAILED',
  /**  E-mail was received and documents might have been generated out of the email's attachments or email's body */
  Received = 'RECEIVED',
  /**  E-mail could not be fully processed e.g, an attachment might have unsupported type */
  Warn = 'WARN'
}

export enum EmailInboxMessageVerdict {
  Fail = 'FAIL',
  Gray = 'GRAY',
  Pass = 'PASS',
  ProcessingFailed = 'PROCESSING_FAILED',
  None = 'none',
  Quarantine = 'quarantine',
  Reject = 'reject'
}

export type EmailMessageVirusDetected = {
  __typename?: 'EmailMessageVirusDetected';
  errorCode: Scalars['String']['output'];
};

export type EntityChangeLog = {
  __typename?: 'EntityChangeLog';
  actionPerformedAt?: Maybe<Scalars['Date']['output']>;
  actionPerformedBy?: Maybe<ActivityUser>;
  actionType: Scalars['String']['output'];
  changedValues?: Maybe<Array<ChangedValue>>;
  clientInfo?: Maybe<ClientInfo>;
  operationName: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  uniqueId: Scalars['String']['output'];
  wasImpersonated: Scalars['Boolean']['output'];
};

export enum EntityType {
  Contacts = 'CONTACTS'
}

export enum ErrorCode {
  NotFound = 'NOT_FOUND',
  ServerError = 'SERVER_ERROR',
  Unauthorized = 'UNAUTHORIZED'
}

export type ErrorDetails = {
  __typename?: 'ErrorDetails';
  errorCode: ErrorCode;
  errorMessage: Scalars['String']['output'];
  formattedErrorMessage: Scalars['String']['output'];
};

export type EvaluateCoreDataExport = {
  __typename?: 'EvaluateCoreDataExport';
  coreDataExportStatus: CoreDataExportStatus;
  exportableCoreDataCount: ExportableCoreDataMetric;
};

export type EventValues = {
  __typename?: 'EventValues';
  new?: Maybe<Scalars['Primitive']['output']>;
  old?: Maybe<Scalars['Primitive']['output']>;
};

export type ExchangeRateDetail = {
  __typename?: 'ExchangeRateDetail';
  amountInBaseCurrency?: Maybe<Scalars['Float']['output']>;
  baseCurrency: DocumentCurrency;
  rate?: Maybe<Scalars['Float']['output']>;
};

export type ExpenseItemArchivedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemArchivedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemArchived;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemBooking = {
  __typename?: 'ExpenseItemBooking';
  amount?: Maybe<Scalars['Float']['output']>;
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  bookingKey?: Maybe<BookingKey>;
  costCenter?: Maybe<CostCenter>;
  costObject?: Maybe<CostCenter>;
  extraCostInfo?: Maybe<CostCenter>;
  generalLedgerAccount?: Maybe<BookingAccount>;
  netAmount?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  postingText?: Maybe<Scalars['String']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export type ExpenseItemBookingAddedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemBookingAddedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemBookingAdded;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemBookingRemovedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemBookingRemovedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemBookingRemoved;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemBookingUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemBookingUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemBookingUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemExpenseDateUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemExpenseDateUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemExpenseDateUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemFileAddedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemFileAddedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemFileAdded;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemFileRemovedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemFileRemovedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemFileRemoved;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemGeneralDataExtractedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemGeneralDataExtractedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemGeneralDataExtracted;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemHospitalityDataExtractedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemHospitalityDataExtractedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemHospitalityDataExtracted;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemHospitalityExternalGuestsUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemHospitalityExternalGuestsUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemHospitalityExternalGuestsUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemHospitalityInternalGuestsUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemHospitalityInternalGuestsUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemHospitalityInternalGuestsUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemHospitalityLocationUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemHospitalityLocationUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemHospitalityLocationUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemHospitalityReceiptAmountUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemHospitalityReceiptAmountUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemHospitalityReceiptAmountUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemHospitalityTipAmountUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemHospitalityTipAmountUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemHospitalityTipAmountUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemInvoiceNumberUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemInvoiceNumberUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemInvoiceNumberUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemPerDiemMealUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemPerDiemMealUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityPerDiemItemMealUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemTitleUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemTitleUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemTitleUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ExpenseItemTotalAmountUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ExpenseItemTotalAmountUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityExpenseItemTotalAmountUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type Export = {
  __typename?: 'Export';
  cardSettlements: Array<CardIssuerPaymentSettlement>;
  client?: Maybe<Scalars['ID']['output']>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  consultantNumber?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  creator?: Maybe<User>;
  /** @deprecated Field no longer supported */
  documents?: Maybe<Array<ExportDocument>>;
  exportableEntities?: Maybe<Array<ExportableEntity>>;
  exportableEntityInfo?: Maybe<Array<ExportableEntityInfo>>;
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integration?: Maybe<ExportIntegration>;
  originExportId?: Maybe<Scalars['ID']['output']>;
  provisions: Array<Provision>;
  reimbursementItems: Array<ExportableReimbursementItem>;
  reversals: Array<Reversal>;
  status: ExportStatus;
  timeFrame?: Maybe<TimeFrame>;
  transactions: Array<CardIssuerTransaction>;
  type: ExportType;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ExportBookingAccountsGenericResult = {
  __typename?: 'ExportBookingAccountsGenericResult';
  url: Scalars['String']['output'];
};

export type ExportBookingKeysGenericResult = {
  __typename?: 'ExportBookingKeysGenericResult';
  url: Scalars['String']['output'];
};

/** Organization's Export Configuration Details */
export type ExportConfiguration = {
  __typename?: 'ExportConfiguration';
  fiscalYearStartDay?: Maybe<Scalars['Int']['output']>;
  fiscalYearStartMonth?: Maybe<Scalars['Int']['output']>;
  isReweEnabled: Scalars['Boolean']['output'];
};

export type ExportConfigurationInput = {
  fiscalYearStartDay?: InputMaybe<Scalars['Int']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  isAutomaticEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReweEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExportConnection = {
  __typename?: 'ExportConnection';
  edges?: Maybe<Array<ExportEdge>>;
  node?: Maybe<Array<Export>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ExportContactsResult = {
  __typename?: 'ExportContactsResult';
  url: Scalars['String']['output'];
};

export type ExportCostCentersGenericResult = {
  __typename?: 'ExportCostCentersGenericResult';
  url: Scalars['String']['output'];
};

export type ExportDocument = {
  __typename?: 'ExportDocument';
  docStatus?: Maybe<ExportDocumentStatus>;
  document: Document;
};

export type ExportDocumentContact = {
  __typename?: 'ExportDocumentContact';
  accountsPayableNumber?: Maybe<Scalars['String']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExportDocumentEs = {
  __typename?: 'ExportDocumentEs';
  amount?: Maybe<Money>;
  contact?: Maybe<ExportDocumentContact>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  documentGuid?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  earliestDueDate?: Maybe<Scalars['Date']['output']>;
  globalDocumentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceDate: Scalars['Date']['output'];
  invoiceNumber: Scalars['String']['output'];
  paymentState: ExportDocumentPayment;
  status: DocumentStatus;
  transactionStatus?: Maybe<TransactionStatus>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ExportDocumentEsEdge = {
  __typename?: 'ExportDocumentEsEdge';
  cursor: Scalars['String']['output'];
  node: ExportDocumentEs;
};

export type ExportDocumentPayment = {
  __typename?: 'ExportDocumentPayment';
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  method?: Maybe<PaymentMethod>;
  paidAt?: Maybe<Scalars['Date']['output']>;
};

export type ExportDocumentStatus = {
  __typename?: 'ExportDocumentStatus';
  details?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  exportError?: Maybe<ExportError>;
  exportMethod?: Maybe<Scalars['String']['output']>;
  type: ExportStatus;
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ExportDocumentsEsResponse = {
  __typename?: 'ExportDocumentsEsResponse';
  edges: Array<ExportDocumentEsEdge>;
  pageInfo: PageInfo;
};

export type ExportDocumentsFilterInput = {
  contactIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExportDocumentsSortInput = {
  amount?: InputMaybe<ExportDocumentsSortOrder>;
  contact?: InputMaybe<ExportDocumentsSortOrder>;
  invoiceDate?: InputMaybe<ExportDocumentsSortOrder>;
};

export enum ExportDocumentsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ExportEdge = {
  __typename?: 'ExportEdge';
  cursor: Scalars['String']['output'];
  node: Export;
};

export type ExportError = {
  __typename?: 'ExportError';
  code?: Maybe<ExportErrorCode>;
  errorCode?: Maybe<Scalars['String']['output']>;
  errorDescription?: Maybe<Scalars['String']['output']>;
  errorUri?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum ExportErrorCode {
  AbortedBecauseOfOtherDocument = 'ABORTED_BECAUSE_OF_OTHER_DOCUMENT',
  CardSettlementNotFound = 'CARD_SETTLEMENT_NOT_FOUND',
  CashLedgerMisConfiguration = 'CASH_LEDGER_MIS_CONFIGURATION',
  CashLedgerWasNotFound = 'CASH_LEDGER_WAS_NOT_FOUND',
  CombinedPdfFailure = 'COMBINED_PDF_FAILURE',
  CsvFailure = 'CSV_FAILURE',
  DatevIsUnavailable = 'DATEV_IS_UNAVAILABLE',
  Dco01004 = 'DCO01004',
  Dco01007ProblematicDocument = 'DCO01007_PROBLEMATIC_DOCUMENT',
  Dco01007TransferAborted = 'DCO01007_TRANSFER_ABORTED',
  DocumentAlreadyImportedToAnotherClientAccount = 'DOCUMENT_ALREADY_IMPORTED_TO_ANOTHER_CLIENT_ACCOUNT',
  DocumentInformationNotSent = 'DOCUMENT_INFORMATION_NOT_SENT',
  DocumentNotAcceptedDuringTransfer = 'DOCUMENT_NOT_ACCEPTED_DURING_TRANSFER',
  DocumentNotFound = 'DOCUMENT_NOT_FOUND',
  DxsoProtocolError = 'DXSO_PROTOCOL_ERROR',
  E101 = 'E101',
  E102 = 'E102',
  E103 = 'E103',
  E104 = 'E104',
  E105 = 'E105',
  E106 = 'E106',
  E107 = 'E107',
  E108 = 'E108',
  E109 = 'E109',
  E110 = 'E110',
  E111 = 'E111',
  E112 = 'E112',
  E113 = 'E113',
  E114 = 'E114',
  E115 = 'E115',
  E116 = 'E116',
  E117 = 'E117',
  E118 = 'E118',
  Econnreset = 'ECONNRESET',
  Etimedout_500 = 'ETIMEDOUT_500',
  FailedToCreateZipArchive = 'FAILED_TO_CREATE_ZIP_ARCHIVE',
  Forbidden_403 = 'FORBIDDEN_403',
  InvalidBookingKey = 'INVALID_BOOKING_KEY',
  InvalidDocument = 'INVALID_DOCUMENT',
  NonExportable = 'NON_EXPORTABLE',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotEligibleForExport = 'NOT_ELIGIBLE_FOR_EXPORT',
  NotExported = 'NOT_EXPORTED',
  NotExportedBecauseFiscalYearNotSetup = 'NOT_EXPORTED_BECAUSE_FISCAL_YEAR_NOT_SETUP',
  Stuck = 'STUCK',
  TransactionNotFound = 'TRANSACTION_NOT_FOUND',
  Unknown = 'UNKNOWN',
  UnsupportedDxsoDocuments = 'UNSUPPORTED_DXSO_DOCUMENTS'
}

export type ExportFilters = {
  hash?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<ExportStatus>;
};

export type ExportIntegration = {
  __typename?: 'ExportIntegration';
  details?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<DatevExportType>;
};

export type ExportPaymentConditionsGenericResult = {
  __typename?: 'ExportPaymentConditionsGenericResult';
  url: Scalars['String']['output'];
};

export enum ExportStatus {
  /**
   * Deprecated: this value is only kept for backward compatibility with old export statuses and export document statuses
   * @deprecated Field no longer supported
   */
  Aborted = 'ABORTED',
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  Failed = 'FAILED',
  PartialyExported = 'PARTIALY_EXPORTED',
  /**
   * Deprecated: this value is only kept for backward compatibility with old export document statuses
   * @deprecated Field no longer supported
   */
  Pending = 'PENDING',
  Unknown = 'UNKNOWN'
}

export type ExportStatusFailedItem = {
  __typename?: 'ExportStatusFailedItem';
  reimbursementItemId?: Maybe<Scalars['String']['output']>;
  reimbursementItemTitle?: Maybe<Scalars['String']['output']>;
  reimbursementItemType?: Maybe<ReimbursementItemType>;
};

export type ExportStatusSuccessfulItem = {
  __typename?: 'ExportStatusSuccessfulItem';
  datevLocationParams?: Maybe<ReimbursementCaseItemDatevLocationParams>;
  reimbursementItemId?: Maybe<Scalars['String']['output']>;
  reimbursementItemTitle?: Maybe<Scalars['String']['output']>;
  reimbursementItemType?: Maybe<ReimbursementItemType>;
};

export enum ExportType {
  AddisonFormat = 'ADDISON_FORMAT',
  Api = 'API',
  CandisCsv = 'CANDIS_CSV',
  Datev = 'DATEV',
  /** DATEV Buchungsdatenservice */
  DatevBds = 'DATEV_BDS',
  DatevCsv = 'DATEV_CSV',
  DatevDco = 'DATEV_DCO',
  DatevDxso = 'DATEV_DXSO',
  DatevDxsoZip = 'DATEV_DXSO_ZIP',
  SapB1 = 'SAP_B1',
  SapCsv = 'SAP_CSV',
  Zip = 'ZIP'
}

export type ExportableCardTransaction = {
  __typename?: 'ExportableCardTransaction';
  associatedCardTransactions?: Maybe<Array<ExportableDocument>>;
  unassociatedCardTransactions?: Maybe<Array<CardIssuerTransaction>>;
};

export type ExportableContact = {
  __typename?: 'ExportableContact';
  accountsPayableNumber?: Maybe<Scalars['String']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  additionalContactInfo?: Maybe<AdditionalContactInfo>;
  id: Scalars['String']['output'];
  membershipId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  relationshipType?: Maybe<ContactRelationshipType>;
  status: ExportableContactStatus;
};

export enum ExportableContactStatus {
  New = 'NEW',
  Updated = 'UPDATED'
}

export type ExportableCoreDataMetric = {
  __typename?: 'ExportableCoreDataMetric';
  exportableContactCount: DocumentMetric;
  exportablePaymentConditionCount: DocumentMetric;
  newAndUpdatedExportableContactCount: DocumentMetric;
  newAndUpdatedExportablePaymentConditionCount: DocumentMetric;
};

/**
 * A Document that has been approved and it's ready to be exported
 *
 * TODO: this type should inherit from a DocumentBase interface
 */
export type ExportableDocument = {
  __typename?: 'ExportableDocument';
  amount: Money;
  contact: Contact;
  /** Reflects a Datev dynamic direction type. */
  direction?: Maybe<Scalars['String']['output']>;
  /** Universal identifier for the document */
  documentGuid: Scalars['ID']['output'];
  /** Reflects a Datev dynamic document type. */
  documentType?: Maybe<Scalars['String']['output']>;
  earliestDueDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** The date displayed on the invoice */
  invoiceDate: Scalars['Date']['output'];
  /**
   * Use earliestDueDate
   * @deprecated Field no longer supported
   */
  invoiceDueDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  /**
   * Current payment state of the document. A document is considered Unpaid
   * when no payment information is available.
   */
  paymentState: DocumentPaymentState;
  /**
   * Indicates the transaction status attached to this document.
   * this status later indicates whether the document is exportable or not.
   *
   * only organizations with the credit card feature activated will return non-null values for this field.
   */
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ExportableDocumentConnection = {
  __typename?: 'ExportableDocumentConnection';
  edges?: Maybe<Array<ExportableDocumentEdge>>;
  node?: Maybe<Array<ExportableDocument>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ExportableDocumentEdge = {
  __typename?: 'ExportableDocumentEdge';
  cursor: Scalars['String']['output'];
  node: ExportableDocument;
};

export type ExportableEntitiesMetric = {
  __typename?: 'ExportableEntitiesMetric';
  documentReadyForExportCount: DocumentMetric;
  pendingTransactionCount: DocumentMetric;
  provisionsReadyForExportCount: DocumentMetric;
  reimbursementItemsReadyForExportCount: DocumentMetric;
  reversalsReadyForExportCount: DocumentMetric;
  settlementsNotReadyForExportCount: DocumentMetric;
  settlementsReadyForExportCount: DocumentMetric;
  transactionReadyForExportCount: DocumentMetric;
};

export type ExportableEntity = {
  __typename?: 'ExportableEntity';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ExportableEntityType>;
};

export type ExportableEntityInfo = {
  __typename?: 'ExportableEntityInfo';
  details?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  exportError?: Maybe<ExportError>;
  exportMethod?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status: ExportStatus;
  type?: Maybe<ExportableEntityType>;
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum ExportableEntityType {
  AggregatedContacts = 'AGGREGATED_CONTACTS',
  AggregatedPaymentConditions = 'AGGREGATED_PAYMENT_CONDITIONS',
  CardSettlement = 'CARD_SETTLEMENT',
  CardTransaction = 'CARD_TRANSACTION',
  Contact = 'CONTACT',
  Document = 'DOCUMENT',
  /** @deprecated PAYMENT_CONDITION is deprecated in favor of AGGREGATED_PAYMENT_CONDITIONS */
  PaymentCondition = 'PAYMENT_CONDITION',
  Provision = 'PROVISION',
  ProvisionReversal = 'PROVISION_REVERSAL',
  ReimbursementItem = 'REIMBURSEMENT_ITEM'
}

export type ExportablePaymentCondition = {
  __typename?: 'ExportablePaymentCondition';
  discountOffset?: Maybe<Scalars['Int']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDateOffset: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  paymentConditionName?: Maybe<Scalars['String']['output']>;
  paymentConditionNumber: Scalars['Int']['output'];
  status: ExportablePaymentConditionStatus;
};

export enum ExportablePaymentConditionStatus {
  New = 'NEW',
  Updated = 'UPDATED'
}

export type ExportablePovisionFilters = {
  types: Array<ProvisionEntityType>;
};

export type ExportableReimbursementItem = {
  __typename?: 'ExportableReimbursementItem';
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  reimbursementCase: ExportableReimbursementItemCase;
  reimbursementItemDate: Scalars['Date']['output'];
  title: Scalars['String']['output'];
  totalAmount: Money;
  type: ReimbursementItemType;
};

export type ExportableReimbursementItemCase = {
  __typename?: 'ExportableReimbursementItemCase';
  id: Scalars['String']['output'];
  submittedForReviewAt: Scalars['Date']['output'];
  targetMembership: User;
  title: Scalars['String']['output'];
};

export type ExportableReimbursementItemConnection = {
  __typename?: 'ExportableReimbursementItemConnection';
  edges?: Maybe<Array<ExportableReimbursementItemEdge>>;
  node: Array<ExportableReimbursementItem>;
  pageInfo?: Maybe<PageInfo>;
};

export type ExportableReimbursementItemDateFiltersInput = {
  reimbursementItemDate?: InputMaybe<DateFilterInput>;
  submittedForReviewAt?: InputMaybe<DateFilterInput>;
};

export type ExportableReimbursementItemEdge = {
  __typename?: 'ExportableReimbursementItemEdge';
  cursor: Scalars['String']['output'];
  node: ExportableReimbursementItem;
};

export type ExportableReimbursementItemFilters = {
  dateRangeFilters?: InputMaybe<ExportableReimbursementItemDateFiltersInput>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ExportableReimbursementItemSort {
  CreatedAt = 'CREATED_AT',
  ReimbursementCaseTitle = 'REIMBURSEMENT_CASE_TITLE',
  ReimbursementItemDate = 'REIMBURSEMENT_ITEM_DATE',
  SubmittedForReviewAt = 'SUBMITTED_FOR_REVIEW_AT',
  TargetMembershipFullName = 'TARGET_MEMBERSHIP_FULL_NAME',
  Title = 'TITLE',
  TotalAmount = 'TOTAL_AMOUNT',
  Type = 'TYPE'
}

export type ExportableReimbursementItemSortInput = {
  direction: SortDirection;
  field: ExportableReimbursementItemSort;
};

export type ExportableReversalFilters = {
  types: Array<ProvisionEntityType>;
};

/** Document export event */
export type ExportedEvent = DocumentTimeLineItem & {
  __typename?: 'ExportedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** How to find the exported document in DATEV */
  datevLocationParams?: Maybe<DatevLocationParams>;
  /** Id of the related export entry */
  exportId?: Maybe<Scalars['String']['output']>;
  /** What kind of export this Event contains */
  exportType?: Maybe<ExportType>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that exported the document */
  user?: Maybe<ActivityUser>;
};

export type ExtendAccountNumbersLengthInput = {
  newLength: Scalars['Int']['input'];
  oldLength: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};

export type ExtendBookingAccountNumbersLengthResponse = {
  __typename?: 'ExtendBookingAccountNumbersLengthResponse';
  failedUpdates?: Maybe<Array<BookingAccount>>;
  message: Scalars['String']['output'];
};

export type ExternalApiMetadata = {
  __typename?: 'ExternalApiMetadata';
  apiError?: Maybe<ApiError>;
  endpoint: Scalars['String']['output'];
  requestPayloadAsString?: Maybe<Scalars['String']['output']>;
};

export type ExtfFilesJob = {
  __typename?: 'ExtfFilesJob';
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  number_of_accounting_records: Scalars['Int']['output'];
  reference_id?: Maybe<Scalars['String']['output']>;
  result: Scalars['String']['output'];
  validation_details?: Maybe<ExtfFilesJobValidationDetails>;
};

export type ExtfFilesJobValidationAffectedElement = {
  __typename?: 'ExtfFilesJobValidationAffectedElement';
  name: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type ExtfFilesJobValidationDetails = {
  __typename?: 'ExtfFilesJobValidationDetails';
  affected_elements: Array<Maybe<ExtfFilesJobValidationAffectedElement>>;
  detail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ExtraCostInfoArchivedError = DocumentApprovalError & {
  __typename?: 'ExtraCostInfoArchivedError';
  extraCostInfo: CostCenter;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ExtraCostInfoChangedError = DocumentApprovalError & {
  __typename?: 'ExtraCostInfoChangedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ExtraCostInfoDoesNotExistError = DocumentApprovalError & {
  __typename?: 'ExtraCostInfoDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ExtractedContact = {
  __typename?: 'ExtractedContact';
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  automaticPayment?: Maybe<BooleanField>;
  bankAccountNumber?: Maybe<StringField>;
  cachingKey: Scalars['String']['output'];
  city?: Maybe<StringField>;
  countryISOCode?: Maybe<StringField>;
  createTransfer?: Maybe<BooleanField>;
  /** @deprecated Use `accountsPayableNumber` */
  creditorAccountNumber?: Maybe<Scalars['Int']['output']>;
  customerNumber?: Maybe<StringField>;
  dueDateOffset?: Maybe<IntField>;
  email?: Maybe<StringField>;
  iban?: Maybe<StringField>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<StringField>;
  phoneNumber?: Maybe<StringField>;
  postOfficeBox?: Maybe<StringField>;
  postalCode?: Maybe<StringField>;
  street?: Maybe<StringField>;
  swiftCode?: Maybe<StringField>;
  taxNumber?: Maybe<StringField>;
  vatId?: Maybe<StringField>;
};

/** Set of extractions */
export type ExtractedField = {
  __typename?: 'ExtractedField';
  /** property that was changed */
  key?: Maybe<Scalars['String']['output']>;
  /** value that was applied */
  value?: Maybe<Scalars['String']['output']>;
};

/** Document metadata extraction event */
export type ExtractionEvent = DocumentTimeLineItem & {
  __typename?: 'ExtractionEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The id of the organization this activity belongs to */
  organizationId?: Maybe<Scalars['String']['output']>;
};

export type FailedEntity = {
  errorDetails?: Maybe<Array<ErrorDetails>>;
};

export type FastApproveError = AccountingAreaDoesNotExistError | AccountingAreaIsArchivedError | AccountingAreaRequiredError | BookingKeyArchivedError | BookingKeyDoesNotExistError | BookingKeyRequiredError | BookingModificationNotAllowedError | BookingsError | ContactApnRequiredError | ContactArnRequiredError | ContactDoesNotExistError | ContactIsArchivedError | CostCenterArchivedError | CostCenterDoesNotExistError | CostCenterRequiredError | CostObjectArchivedError | CostObjectDoesNotExistError | CostObjectRequiredError | DeliveryDateConflictError | DocumentCannotBeModifedError | DocumentDoesNotExistError | DocumentGrossNetTaxRoundingAmountsCalculationError | DocumentGrossNetTaxRoundingAmountsValidationError | DocumentIsAlreadyRequestedForApprovalError | ExtraCostInfoArchivedError | ExtraCostInfoDoesNotExistError | GeneralLedgerArchivedError | GeneralLedgerDoesNotExistError | GeneralLedgerRequiredError | InvalidBookingAmountError | InvalidBookingIdError | InvalidDeliveryDateError | InvalidDueDateError | InvalidGrossAmountError | InvalidInvoiceDateError | InvalidSwiftCodeError | InvoiceHasNoBookingsError | NotCurrentWorkflowStepError | SumOfBookingsDoesNotMatchGrossAmountError | SwiftCodeAndIbanCountryMismatchError | SwiftCodeMissingIbanError | TransactionDataMismatchError | UnexpectedDocumentApprovalError | WorkflowStepAlreadyApprovedError | WorkflowTemplateArchivedError | WorkflowTemplateDoesNotExistError | WrongDocumentTypeConnectedToSapEntityError | XRechnungInvoiceHasNoPdfError;

export type File = {
  __typename?: 'File';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FileAttachedToDocumentEvent = DocumentTimeLineItem & {
  __typename?: 'FileAttachedToDocumentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The file that was uploaded */
  file?: Maybe<File>;
  /** Whether the file was attached automatically by Candis from the EInvoice */
  fromEInvoice?: Maybe<Scalars['Boolean']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** ID of the original document if the file was attached from within CANDIS */
  originalDocumentId?: Maybe<Scalars['String']['output']>;
  /** Whether the attached file has since been detached or deleted */
  sinceDetachedOrDeleted?: Maybe<Scalars['Boolean']['output']>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type FileInput = {
  createdAt: Scalars['Date']['input'];
  documentId?: InputMaybe<Scalars['String']['input']>;
  errorDetails?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  state: FileState;
};

export type FileResponse = {
  __typename?: 'FileResponse';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export enum FileState {
  Error = 'ERROR',
  Uploaded = 'UPLOADED',
  Uploading = 'UPLOADING'
}

export type FileUpload = {
  __typename?: 'FileUpload';
  file: UploadedFile;
  id: Scalars['String']['output'];
  postOptions?: Maybe<Array<FileUploadPostOption>>;
  url: Scalars['String']['output'];
};

export type FileUploadAttempt = {
  __typename?: 'FileUploadAttempt';
  createdAt: Scalars['Date']['output'];
  documentId?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<DocumentType>;
  errorMessages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  file: UploadedFileAttempt;
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: FileUploadAttemptStatus;
  updatedAt: Scalars['Date']['output'];
  wasDeleted: Scalars['Boolean']['output'];
};

export enum FileUploadAttemptStatus {
  DocumentClassified = 'DOCUMENT_CLASSIFIED',
  DocumentCreated = 'DOCUMENT_CREATED',
  DocumentDataExtracted = 'DOCUMENT_DATA_EXTRACTED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export enum FileUploadContext {
  Avatars = 'AVATARS',
  Default = 'DEFAULT',
  Documents = 'DOCUMENTS',
  TemporaryFiles = 'TEMPORARY_FILES'
}

export type FileUploadParams = {
  contentType: Scalars['String']['input'];
  context: FileUploadContext;
  emailMessageId?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type FileUploadPostOption = {
  __typename?: 'FileUploadPostOption';
  field: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Document file upload event */
export type FileUploadedByEmailEvent = DocumentTimeLineItem & {
  __typename?: 'FileUploadedByEmailEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The file that was uploaded */
  file?: Maybe<File>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The email address, for email uploads. */
  uploaderEmail?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

/** Document file upload event */
export type FileUploadedEvent = DocumentTimeLineItem & {
  __typename?: 'FileUploadedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The file that was uploaded */
  file?: Maybe<File>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** Outdated property kept to backward compatibility */
  uploaderEmail?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The user that caused this event */
  user?: Maybe<ActivityUser>;
};

export type FilterInput = {
  field: Scalars['String']['input'];
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FinancialInsights = {
  __typename?: 'FinancialInsights';
  avg?: Maybe<InsightsAggregate>;
  sum?: Maybe<InsightsAggregate>;
};

export type FinancialInsightsInput = {
  preferences: InsightsPreferencesInput;
};

export type FloatBookingValue = {
  __typename?: 'FloatBookingValue';
  hasChanged: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type FloatField = {
  __typename?: 'FloatField';
  confidence?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Array<Maybe<FloatSource>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type FloatSource = {
  __typename?: 'FloatSource';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type ForeignCurrencyFeeSavings = {
  __typename?: 'ForeignCurrencyFeeSavings';
  aggregate: ForeignCurrencyFeeSavingsInsight;
  details: Array<ForeignCurrencyFeeSavingsInsight>;
};

export type ForeignCurrencyFeeSavingsInsight = {
  __typename?: 'ForeignCurrencyFeeSavingsInsight';
  currency: Scalars['String']['output'];
  estimatedFeesSaving: Scalars['Float']['output'];
  numberOfInvoices: Scalars['Int']['output'];
  totalAmount: Scalars['Float']['output'];
};

export type FormatCardholderNameForCreditCardsInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type FormatCardholderNameForCreditCardsOutput = {
  __typename?: 'FormatCardholderNameForCreditCardsOutput';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type GeneralExpenseItem = CommonReimbursementItem & {
  __typename?: 'GeneralExpenseItem';
  bookings: Array<ReimbursementBooking>;
  createdAt: Scalars['Date']['output'];
  errors: Array<ReimbursementError>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  extractedData?: Maybe<Array<GeneralExpenseItemExtraction>>;
  extractedDataAccepted?: Maybe<Scalars['Boolean']['output']>;
  files?: Maybe<Array<File>>;
  id: Scalars['String']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  reimbursementCaseId: Scalars['String']['output'];
  status: ReimbursementItemStatus;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Money>;
  type: ReimbursementItemType;
  updatedAt: Scalars['Date']['output'];
};

export type GeneralExpenseItemExtraction = CommonReimbursementItemExtraction & {
  __typename?: 'GeneralExpenseItemExtraction';
  currency?: Maybe<Scalars['String']['output']>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  sourceFile: File;
  totalAmount?: Maybe<Money>;
};

export type GeneralExpenseItemInput = {
  bookings?: InputMaybe<Array<ReimbursementBookingInput>>;
  expenseDate?: InputMaybe<Scalars['Date']['input']>;
  extractedDataAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  reimbursementCaseId: Scalars['String']['input'];
  status?: InputMaybe<ReimbursementItemStatusInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<MoneyInput>;
};

export type GeneralLedgerArchivedError = DocumentApprovalError & {
  __typename?: 'GeneralLedgerArchivedError';
  generalLedgerAccount: BookingAccount;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GeneralLedgerChangedError = DocumentApprovalError & {
  __typename?: 'GeneralLedgerChangedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GeneralLedgerDoesNotExistError = DocumentApprovalError & {
  __typename?: 'GeneralLedgerDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GeneralLedgerRequiredError = DocumentApprovalError & {
  __typename?: 'GeneralLedgerRequiredError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type GenerateCsvFileForStatementInput = {
  statementId: Scalars['String']['input'];
};

export enum GenerateTestTransactionsCurrency {
  Eur = 'EUR',
  Usd = 'USD'
}

export enum GenerateTestTransactionsType {
  Purchase = 'PURCHASE',
  PurchaseChargeback = 'PURCHASE_CHARGEBACK',
  PurchaseChargebackReversal = 'PURCHASE_CHARGEBACK_REVERSAL',
  PurchaseRefund = 'PURCHASE_REFUND',
  StatusInquiry = 'STATUS_INQUIRY'
}

export type GetCommentInput = {
  id: Scalars['ID']['input'];
};

export type GetContactInput = {
  id: Scalars['String']['input'];
};

export type GetContactsFuzzyInput = {
  name: Scalars['String']['input'];
};

export type GetContactsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GetContractSubCategoriesInput = {
  includeDocumentCount?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ContractSubCategoriesSortInput>;
};

export type GetCostCenterInput = {
  id: Scalars['String']['input'];
};

export type GetCsvFileForStatementInput = {
  csvFileRecordId: Scalars['String']['input'];
  statementId: Scalars['String']['input'];
};

export type GetDocumentRelationshipInput = {
  globalDocumentId: Scalars['UUID']['input'];
};

export type GetDocumentRelationshipResponse = {
  __typename?: 'GetDocumentRelationshipResponse';
  relationships: Array<DocumentRelationship>;
};

export type GetDocumentsInput = {
  approverId?: InputMaybe<Scalars['String']['input']>;
  categoryDocumentType?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mentionedUserId?: InputMaybe<Scalars['String']['input']>;
  payee?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentStatus>;
  statusIn?: InputMaybe<Array<InputMaybe<DocumentStatus>>>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type GetEcmDocumentTypeSettingsInput = {
  includeNonEcmTypes?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmDocumentTypeSettingsSortInput>;
};

export type GetEcmDocumentsResponse = {
  __typename?: 'GetEcmDocumentsResponse';
  edges?: Maybe<Array<EcmDocumentEdge>>;
  pageInfo: EcmPageInfo;
  records?: Maybe<Array<EcmDocument>>;
};

export type GetOrganizationsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GetTagsInput = {
  includeDocumentCount?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<TagSortInput>;
};

export type GetUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type GetUserInvolvementForEcmDocumentCount = {
  __typename?: 'GetUserInvolvementForEcmDocumentCount';
  count: Scalars['Int']['output'];
};

export type GetUserInvolvementForEcmDocumentResponse = {
  __typename?: 'GetUserInvolvementForEcmDocumentResponse';
  notifyPerson: GetUserInvolvementForEcmDocumentCount;
  responsiblePerson: GetUserInvolvementForEcmDocumentCount;
};

export type GetVendorSuggestionsForRecurringPaymentsDateFilterInput = {
  invoiceDateFilter?: InputMaybe<DateFilterInput>;
};

export type GetVendorSuggestionsForRecurringPaymentsFilterInput = {
  documentStatuses?: InputMaybe<Array<DocumentStatus>>;
  includeIgnoredVendors?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetVendorSuggestionsForRecurringPaymentsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type GetVendorSuggestionsForRecurringPaymentsSortInput = {
  direction: SortDirection;
  field: VendorSuggestionsForRecurringPaymentsSortField;
};

export type GetVendorsForRecurringPaymentsFilterInput = {
  includeIgnoredVendors?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetVendorsForRecurringPaymentsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type GetVendorsForRecurringPaymentsSortInput = {
  direction: SortDirection;
  field: VendorsForRecurringPaymentsSortField;
};

export type GetWorkflowTemplatesInput = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GoodReceiptsFailedEntity = FailedEntity & {
  __typename?: 'GoodReceiptsFailedEntity';
  errorDetails?: Maybe<Array<ErrorDetails>>;
  rawEntity: GoodReceiptsRawEntity;
};

export type GoodReceiptsRawEntity = {
  __typename?: 'GoodReceiptsRawEntity';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GoodsLineItem = {
  __typename?: 'GoodsLineItem';
  description: Scalars['String']['output'];
  discountPercentageValue: Scalars['Float']['output'];
  generalLedgerAccount: Scalars['String']['output'];
  itemNumber: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  lineTotal: Money;
  quantity: Scalars['Float']['output'];
  taxCode: Scalars['String']['output'];
  taxTotal: Money;
  unitPrice: Money;
};

export type GoodsReceipt = {
  __typename?: 'GoodsReceipt';
  _id: Scalars['String']['output'];
  accountsPayableNumber: Scalars['String']['output'];
  amount: Money;
  connectedInvoices?: Maybe<Array<ConnectedInvoicesData>>;
  connectedPurchaseOrders?: Maybe<Array<Maybe<ConnectedPurchaseOrdersData>>>;
  contactName: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deliveryDate: Scalars['Date']['output'];
  goodsLineItems?: Maybe<Array<GoodsReceiptGoodsLineItem>>;
  goodsReceiptType: GoodsReceiptType;
  organizationId: Scalars['String']['output'];
  postingDate: Scalars['Date']['output'];
  purchaseOrders?: Maybe<Array<Maybe<PurchaseOrder>>>;
  receiptNumber: Scalars['String']['output'];
  sapB1Data?: Maybe<SapB1Data>;
  serviceLineItems?: Maybe<Array<GoodsReceiptServiceLineItem>>;
  status: GoodsReceiptStatus;
};

export type GoodsReceiptAssociation = {
  __typename?: 'GoodsReceiptAssociation';
  goodsReceiptId?: Maybe<Scalars['String']['output']>;
  receiptNumber?: Maybe<Scalars['String']['output']>;
};

export type GoodsReceiptAssociationDetails = {
  __typename?: 'GoodsReceiptAssociationDetails';
  goodsReceiptId?: Maybe<Scalars['String']['output']>;
  receiptNumber?: Maybe<Scalars['String']['output']>;
};

export type GoodsReceiptAssociationWithPurchaseOrders = {
  __typename?: 'GoodsReceiptAssociationWithPurchaseOrders';
  associatedPurchaseOrders?: Maybe<Array<Maybe<PurchaseOrderAssociationDetails>>>;
  goodsReceiptId?: Maybe<Scalars['String']['output']>;
  receiptNumber?: Maybe<Scalars['String']['output']>;
};

export type GoodsReceiptConnection = {
  __typename?: 'GoodsReceiptConnection';
  edges?: Maybe<Array<GoodsReceiptEdge>>;
  pageInfo?: Maybe<PageInfo>;
};

export type GoodsReceiptCountInput = {
  view: GoodsReceiptView;
};

export type GoodsReceiptEdge = {
  __typename?: 'GoodsReceiptEdge';
  cursor: Scalars['String']['output'];
  node: GoodsReceipt;
};

export type GoodsReceiptFilterInput = {
  accountsPayableNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<GoodsReceiptStatus>>;
};

export type GoodsReceiptGoodsLineItem = {
  __typename?: 'GoodsReceiptGoodsLineItem';
  description: Scalars['String']['output'];
  discountPercentageValue: Scalars['Float']['output'];
  generalLedgerAccount: Scalars['String']['output'];
  itemNumber: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  lineTotal: Money;
  quantity: Scalars['Float']['output'];
  taxCode: Scalars['String']['output'];
  taxTotal?: Maybe<Money>;
  unitPrice: Money;
};

export type GoodsReceiptImportHistoryEntry = {
  __typename?: 'GoodsReceiptImportHistoryEntry';
  createdAt: Scalars['Date']['output'];
  createdBy: User;
  finishedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  importResult?: Maybe<GoodsReceiptImportHistoryEntryImportResult>;
  status: GoodsReceiptImportHistoryEntryStatus;
  updatedAt: Scalars['Date']['output'];
};

export type GoodsReceiptImportHistoryEntryImportResult = {
  __typename?: 'GoodsReceiptImportHistoryEntryImportResult';
  failedToImportDocumentsCount: Scalars['Int']['output'];
  successfullyCreatedDocumentsCount: Scalars['Int']['output'];
  successfullyUpdatedDocumentsCount: Scalars['Int']['output'];
};

export enum GoodsReceiptImportHistoryEntryStatus {
  Failed = 'FAILED',
  InProcess = 'IN_PROCESS',
  PartiallySuccess = 'PARTIALLY_SUCCESS',
  Success = 'SUCCESS'
}

export type GoodsReceiptInfo = {
  __typename?: 'GoodsReceiptInfo';
  articleId: Scalars['String']['output'];
  goodsReceiptCreationDate: Scalars['Date']['output'];
  goodsReceiptId: Scalars['String']['output'];
  goodsReceiptLineNumber: Scalars['Int']['output'];
  linkedPurchaseOrderId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  unitPrice: Money;
};

export type GoodsReceiptPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum GoodsReceiptQueryField {
  ContactName = 'CONTACT_NAME',
  ReceiptNumber = 'RECEIPT_NUMBER',
  Status = 'STATUS',
  SupplierRefNumber = 'SUPPLIER_REF_NUMBER',
  TotalAmount = 'TOTAL_AMOUNT'
}

export type GoodsReceiptServiceLineItem = {
  __typename?: 'GoodsReceiptServiceLineItem';
  description: Scalars['String']['output'];
  generalLedgerAccount: Scalars['String']['output'];
  itemNumber: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  lineTotal: Money;
  taxCode: Scalars['String']['output'];
  taxTotal?: Maybe<Money>;
  unitPrice?: Maybe<Money>;
};

export enum GoodsReceiptSortField {
  ContactName = 'CONTACT_NAME',
  CreatedAt = 'CREATED_AT',
  DeliveryDate = 'DELIVERY_DATE',
  PostingDate = 'POSTING_DATE',
  ReceiptNumber = 'RECEIPT_NUMBER',
  Status = 'STATUS',
  SupplierRefNumber = 'SUPPLIER_REF_NUMBER',
  TotalAmount = 'TOTAL_AMOUNT'
}

export type GoodsReceiptSortInput = {
  direction: SortDirection;
  field: GoodsReceiptSortField;
  targetValue?: InputMaybe<Scalars['Float']['input']>;
};

export enum GoodsReceiptStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export enum GoodsReceiptType {
  Goods = 'GOODS',
  Service = 'SERVICE'
}

export enum GoodsReceiptView {
  Archive = 'ARCHIVE',
  Inbox = 'INBOX'
}

export type GoodsReceiptsCount = {
  __typename?: 'GoodsReceiptsCount';
  count: Scalars['Int']['output'];
};

export type HighlightChunk = {
  __typename?: 'HighlightChunk';
  matches: Array<HighlightMatch>;
  text: Scalars['String']['output'];
};

export type HighlightMatch = {
  __typename?: 'HighlightMatch';
  length: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

/** A holding company in candis. A holding company will manage multiple organizations and will ensure core data is shared among different orgs in same holding */
export type Holding = {
  __typename?: 'Holding';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type HospitalityExpenseItem = CommonReimbursementItem & {
  __typename?: 'HospitalityExpenseItem';
  bookings: Array<ReimbursementBooking>;
  createdAt: Scalars['Date']['output'];
  errors: Array<ReimbursementError>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  externalGuests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  extractedData?: Maybe<Array<HospitalityExpenseItemExtraction>>;
  extractedDataAccepted?: Maybe<Scalars['Boolean']['output']>;
  files?: Maybe<Array<File>>;
  id: Scalars['String']['output'];
  internalGuests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  receiptAmount?: Maybe<Money>;
  reimbursementCaseId: Scalars['String']['output'];
  status: ReimbursementItemStatus;
  tipAmount?: Maybe<Money>;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Money>;
  type: ReimbursementItemType;
  updatedAt: Scalars['Date']['output'];
};

export type HospitalityExpenseItemExtraction = CommonReimbursementItemExtraction & {
  __typename?: 'HospitalityExpenseItemExtraction';
  currency?: Maybe<Scalars['String']['output']>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  receiptAmount?: Maybe<Money>;
  sourceFile: File;
  tipAmount?: Maybe<Money>;
};

export type HospitalityExpenseItemInput = {
  bookings?: InputMaybe<Array<ReimbursementBookingInput>>;
  expenseDate?: InputMaybe<Scalars['Date']['input']>;
  externalGuests?: InputMaybe<Array<Scalars['String']['input']>>;
  extractedDataAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  internalGuests?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  receiptAmount?: InputMaybe<MoneyInput>;
  reimbursementCaseId: Scalars['String']['input'];
  status?: InputMaybe<ReimbursementItemStatusInput>;
  tipAmount?: InputMaybe<MoneyInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type IRecurringPaymentVendor = {
  category?: Maybe<CardCategory>;
  id: Scalars['String']['output'];
  isIgnored: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  source: RecurringPaymentVendorSource;
};

export enum IamAuthType {
  Mfa = 'MFA',
  Pwd = 'PWD'
}

export type Iban = {
  __typename?: 'Iban';
  iban: Scalars['IBAN']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  swiftCode?: Maybe<Scalars['String']['output']>;
};

export type IbanAndBankAccountNumberFilterInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
};

export type IbanError = {
  __typename?: 'IbanError';
  kind?: Maybe<IbanErrorKind>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum IbanErrorKind {
  Duplicate = 'DUPLICATE',
  Forbidden = 'FORBIDDEN',
  Incomplete = 'INCOMPLETE',
  Invalid = 'INVALID',
  Unknown = 'UNKNOWN'
}

export type IbanInput = {
  iban: Scalars['IBAN']['input'];
  name: Scalars['String']['input'];
  swiftCode?: InputMaybe<Scalars['String']['input']>;
};

export type IbanOrBankAccountNumberFilterInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
};

export type IbanResult = Iban | IbanError;

export type IdentityProvider = {
  __typename?: 'IdentityProvider';
  alias: Scalars['String']['output'];
  config: IdentityProviderConfiguration;
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  firstBrokerLoginFlowAlias: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  userAttributeMappers: Array<IdentityProviderUserAttributeMapper>;
  userRoleMappers: Array<IdentityProviderUserRoleMpper>;
};

export type IdentityProviderConfiguration = IdentityProviderOidc | IdentityProviderSaml;

export type IdentityProviderInput = {
  alias: Scalars['String']['input'];
  configOIDC?: InputMaybe<IdentityProviderOidcInput>;
  configSAML?: InputMaybe<IdentityProviderSamlInput>;
  displayName: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  firstBrokerLoginFlowAlias: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type IdentityProviderOidc = {
  __typename?: 'IdentityProviderOIDC';
  authorizationUrl: Scalars['String']['output'];
  clientAuthMethod: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  jwksUrl?: Maybe<Scalars['String']['output']>;
  logoutUrl?: Maybe<Scalars['String']['output']>;
  pkceEnabled?: Maybe<Scalars['Boolean']['output']>;
  syncMode?: Maybe<SyncMode>;
  tokenUrl: Scalars['String']['output'];
  useJwksUrl: Scalars['Boolean']['output'];
  validateSignature: Scalars['Boolean']['output'];
};

export type IdentityProviderOidcInput = {
  authorizationUrl: Scalars['String']['input'];
  clientAuthMethod: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  jwksUrl?: InputMaybe<Scalars['String']['input']>;
  logoutUrl?: InputMaybe<Scalars['String']['input']>;
  pkceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  syncMode?: InputMaybe<SyncMode>;
  tokenUrl: Scalars['String']['input'];
  useJwksUrl: Scalars['Boolean']['input'];
  validateSignature: Scalars['Boolean']['input'];
};

export type IdentityProviderSaml = {
  __typename?: 'IdentityProviderSAML';
  entityId: Scalars['String']['output'];
  idpEntityId: Scalars['String']['output'];
  nameIDPolicyFormat: NameIdPolicyFormat;
  postBindingAuthnRequest?: Maybe<Scalars['Boolean']['output']>;
  postBindingResponse?: Maybe<Scalars['Boolean']['output']>;
  principalType: PrincipalType;
  signSpMetadata?: Maybe<Scalars['Boolean']['output']>;
  signatureAlgorithm?: Maybe<Scalars['String']['output']>;
  signingCertificate?: Maybe<Scalars['String']['output']>;
  singleLogoutServiceUrl?: Maybe<Scalars['String']['output']>;
  singleSignOnServiceUrl: Scalars['String']['output'];
  syncMode?: Maybe<SyncMode>;
  validateSignature: Scalars['Boolean']['output'];
  wantAssertionsSigned?: Maybe<Scalars['Boolean']['output']>;
  wantAuthnRequestsSigned?: Maybe<Scalars['Boolean']['output']>;
};

export type IdentityProviderSamlInput = {
  entityId: Scalars['String']['input'];
  idpEntityId: Scalars['String']['input'];
  nameIDPolicyFormat: NameIdPolicyFormat;
  postBindingAuthnRequest?: InputMaybe<Scalars['Boolean']['input']>;
  postBindingResponse?: InputMaybe<Scalars['Boolean']['input']>;
  principalType: PrincipalType;
  signSpMetadata?: InputMaybe<Scalars['Boolean']['input']>;
  signatureAlgorithm?: InputMaybe<Scalars['String']['input']>;
  signingCertificate?: InputMaybe<Scalars['String']['input']>;
  singleLogoutServiceUrl?: InputMaybe<Scalars['String']['input']>;
  singleSignOnServiceUrl: Scalars['String']['input'];
  syncMode?: InputMaybe<SyncMode>;
  validateSignature: Scalars['Boolean']['input'];
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']['input']>;
  wantAuthnRequestsSigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IdentityProviderUserAttributeMapper = {
  __typename?: 'IdentityProviderUserAttributeMapper';
  attributeName: Scalars['String']['output'];
  attributeUser: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identityProviderMapper: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type IdentityProviderUserRoleMpper = {
  __typename?: 'IdentityProviderUserRoleMpper';
  attributeKey: Scalars['String']['output'];
  attributeValue: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identityProviderMapper: Scalars['String']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type ImportAndUpdateContactInput = {
  contactInputData: CreateOrUpdateContactInput;
  errors?: InputMaybe<Array<ContactValidationErrorType>>;
};

export type ImportContactError = {
  __typename?: 'ImportContactError';
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<ContactType>;
  contactTypeNumber?: Maybe<Scalars['Int']['output']>;
  countryISOCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `accountsPayableNumber` */
  creditorAccountNumber?: Maybe<Scalars['Int']['output']>;
  dueDateOffset?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  individualFirstName?: Maybe<Scalars['String']['output']>;
  individualLastName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notSpecifiedName?: Maybe<Scalars['String']['output']>;
  paymentConditionNumber?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postOfficeBox?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  shortName?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  validationErrors: Array<ContactValidationError>;
  vatId?: Maybe<Scalars['String']['output']>;
};

export type ImportErrorDetails = {
  __typename?: 'ImportErrorDetails';
  errorCode: ErrorCode;
  errorMessage: Scalars['String']['output'];
  externalApiMetadata?: Maybe<ExternalApiMetadata>;
  formattedErrorMessage: Scalars['String']['output'];
};

export type ImportHistory = {
  __typename?: 'ImportHistory';
  _id: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: CreatedBy;
  durationInMs?: Maybe<Scalars['Int']['output']>;
  failedEntities?: Maybe<Array<FailedEntity>>;
  finishedAt?: Maybe<Scalars['Date']['output']>;
  importErrorDetails?: Maybe<ImportErrorDetails>;
  status: ImportHistoryStatus;
  successfullyCreatedEntitiesCount: Scalars['Int']['output'];
  successfullyUpdatedEntitiesCount: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export enum ImportHistoryEntityType {
  GoodReceipt = 'GOOD_RECEIPT',
  ProjectCode = 'PROJECT_CODE'
}

export enum ImportHistoryStatus {
  Failed = 'FAILED',
  InProcess = 'IN_PROCESS',
  PartiallySuccess = 'PARTIALLY_SUCCESS',
  Success = 'SUCCESS'
}

export type ImportSapB1GoodsReceiptsResult = GoodsReceiptImportHistoryEntry | SapB1ImportResponse;

export type ImportSapB1PurchaseOrdersResult = PurchaseOrderImportHistoryEntry | SapB1ImportResponse;

export type InAppNotification = {
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
};

export type InAppNotificationDateFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
};

export type InAppNotificationFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<InAppNotificationType>>;
};

export type InAppNotificationPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type InAppNotificationPaginationResult = {
  __typename?: 'InAppNotificationPaginationResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<InAppNotification>;
};

export enum InAppNotificationSortField {
  CreatedAt = 'CREATED_AT'
}

export type InAppNotificationSortInput = {
  direction: SortDirection;
  field: InAppNotificationSortField;
};

export enum InAppNotificationType {
  CardRequestApprovedInAppNotification = 'CardRequestApprovedInAppNotification',
  CardRequestCreatedInAppNotification = 'CardRequestCreatedInAppNotification',
  CardRequestRejectedInAppNotification = 'CardRequestRejectedInAppNotification',
  DocumentCommentCreatedInAppNotification = 'DocumentCommentCreatedInAppNotification',
  DocumentRejectedInAppNotification = 'DocumentRejectedInAppNotification',
  ReimbursementCaseAmendmentRequestedInAppNotification = 'ReimbursementCaseAmendmentRequestedInAppNotification',
  ReimbursementCaseApprovedInAppNotification = 'ReimbursementCaseApprovedInAppNotification',
  ReimbursementCaseCommentCreatedInAppNotification = 'ReimbursementCaseCommentCreatedInAppNotification',
  ReimbursementCaseItemExcludedInAppNotification = 'ReimbursementCaseItemExcludedInAppNotification',
  ReimbursementCaseRejectedInAppNotification = 'ReimbursementCaseRejectedInAppNotification',
  TransactionCommentCreatedInAppNotification = 'TransactionCommentCreatedInAppNotification',
  TransactionDeclinedInAppNotification = 'TransactionDeclinedInAppNotification'
}

export type InAppNotificationsResponse = {
  __typename?: 'InAppNotificationsResponse';
  records: Array<InAppNotification>;
};

export type InboxCardIssuerTransactionFiltersInput = {
  cardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cardholderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<TransactionCategory>>;
  dateRangeFilters?: InputMaybe<CardIssuerTransactionDateFilterInput>;
  merchantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  merchantNames?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<TransactionStatus>>;
  type?: InputMaybe<Array<TransactionType>>;
};

export type InboxHighlight = {
  __typename?: 'InboxHighlight';
  chunks: Array<HighlightChunk>;
  field: InboxHighlightableFields;
};

export enum InboxHighlightableFields {
  Amount = 'AMOUNT',
  ContactAccountsPayableNumber = 'CONTACT_ACCOUNTS_PAYABLE_NUMBER',
  ContactName = 'CONTACT_NAME',
  CostCenter = 'COST_CENTER',
  CostObject = 'COST_OBJECT',
  DocumentNumber = 'DOCUMENT_NUMBER',
  ExtraCostInfo = 'EXTRA_COST_INFO',
  FileName = 'FILE_NAME',
  PurchaseOrderNumber = 'PURCHASE_ORDER_NUMBER',
  RawContent = 'RAW_CONTENT'
}

export type InboxInvoiceDocument = {
  __typename?: 'InboxInvoiceDocument';
  amount?: Maybe<Money>;
  associatedDocuments?: Maybe<Array<Maybe<AssociatedDocument>>>;
  bookings?: Maybe<Array<Maybe<InboxInvoiceDocumentBooking>>>;
  contact?: Maybe<InboxInvoiceDocumentContact>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentFile?: Maybe<DocumentFile>;
  eInvoice?: Maybe<DocumentEInvoiceField>;
  globalDocumentId: Scalars['String']['output'];
  hasAttachments?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Do not use, it is an empty array. Use the edge highlights instead */
  highlights: Array<InboxHighlight>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['Date']['output']>;
  invoiceDeliveryDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  isEInvoice?: Maybe<Scalars['Boolean']['output']>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  isPayable?: Maybe<Scalars['Boolean']['output']>;
  payment?: Maybe<InboxInvoiceDocumentPayment>;
  purchaseOrderData?: Maybe<PurchaseOrderData>;
  purchaseOrders?: Maybe<Array<Maybe<PurchaseOrder>>>;
  status: DocumentStatus;
  tags: Array<Tag>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type InboxInvoiceDocumentBooking = {
  __typename?: 'InboxInvoiceDocumentBooking';
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  costCenter?: Maybe<InboxInvoiceDocumentCostCenter>;
  costObject?: Maybe<InboxInvoiceDocumentCostCenter>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  extraCostInfo?: Maybe<InboxInvoiceDocumentCostCenter>;
  paymentConditionId?: Maybe<Scalars['String']['output']>;
};

export type InboxInvoiceDocumentContact = {
  __typename?: 'InboxInvoiceDocumentContact';
  accountsPayableNumber?: Maybe<Scalars['String']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type InboxInvoiceDocumentCostCenter = {
  __typename?: 'InboxInvoiceDocumentCostCenter';
  cachingKey: Scalars['String']['output'];
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  readableName?: Maybe<Scalars['String']['output']>;
};

export type InboxInvoiceDocumentEdge = {
  __typename?: 'InboxInvoiceDocumentEdge';
  cursor: Scalars['String']['output'];
  highlights: Array<InboxHighlight>;
  node: InboxInvoiceDocument;
};

export type InboxInvoiceDocumentPayment = {
  __typename?: 'InboxInvoiceDocumentPayment';
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  method?: Maybe<PaymentMethod>;
  paidAt?: Maybe<Scalars['Date']['output']>;
};

export type InboxInvoiceDocumentsFilterInput = {
  accountsPayableNumber?: InputMaybe<Array<Scalars['String']['input']>>;
  amount?: InputMaybe<AmountFilter>;
  artistSocialInsuranceCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  contactIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costCenterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costObjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilterInput>;
  dueDateWithCashDiscount?: InputMaybe<DateFilterInput>;
  extraCostInfoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hasTransactionLinked?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceDate?: InputMaybe<DateFilterInput>;
  invoiceDeliveryDate?: InputMaybe<DateFilterInput>;
  invoiceDueDate?: InputMaybe<DateFilterInput>;
  isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<DocumentStatus>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InboxInvoiceDocumentsResponse = {
  __typename?: 'InboxInvoiceDocumentsResponse';
  edges: Array<InboxInvoiceDocumentEdge>;
  pageInfo: PageInfo;
};

export type InboxReimbursementCasesEdge = {
  __typename?: 'InboxReimbursementCasesEdge';
  cursor: Scalars['String']['output'];
  node: InboxReimbursementCasesListItem;
};

export type InboxReimbursementCasesListItem = {
  __typename?: 'InboxReimbursementCasesListItem';
  approvers?: Maybe<Array<User>>;
  archivedAt?: Maybe<Scalars['Date']['output']>;
  contact?: Maybe<ReimbursementCaseContact>;
  createTransfer: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  createdByMembership: User;
  firstSubmittedForReviewAt?: Maybe<Scalars['Date']['output']>;
  globalDocumentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  lastApprovedAt?: Maybe<Scalars['Date']['output']>;
  lastApprovedByMembership?: Maybe<User>;
  lastSubmittedForApprovalAt?: Maybe<Scalars['Date']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['Date']['output']>;
  requestedAt?: Maybe<Scalars['Date']['output']>;
  requester?: Maybe<User>;
  status: ReimbursementCaseStatus;
  targetMembership?: Maybe<User>;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount: Money;
  updatedAt: Scalars['Date']['output'];
};

export type InboxReimbursementCasesResponse = {
  __typename?: 'InboxReimbursementCasesResponse';
  edges?: Maybe<Array<InboxReimbursementCasesEdge>>;
  pageInfo: ReimbursementCasesPageInfo;
};

export enum InsightBudgetType {
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export enum InsightGranularity {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type InsightsAggregate = {
  __typename?: 'InsightsAggregate';
  aggregate: InsightsMonetaryDetail;
  details: Array<InsightsMonetaryDetail>;
};

export enum InsightsErrorCodes {
  MaxWidgetsLimitReached = 'MAX_WIDGETS_LIMIT_REACHED',
  MaxWidgetsNotOnDashboardLimitReached = 'MAX_WIDGETS_NOT_ON_DASHBOARD_LIMIT_REACHED',
  MaxWidgetsOnDashboardLimitReached = 'MAX_WIDGETS_ON_DASHBOARD_LIMIT_REACHED'
}

export type InsightsMonetaryDetail = {
  __typename?: 'InsightsMonetaryDetail';
  amount: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  currency: DocumentCurrency;
  exchangeRate?: Maybe<ExchangeRateDetail>;
};

export type InsightsPreferences = {
  __typename?: 'InsightsPreferences';
  unit: InsightsWidgetReportingUnit;
};

export type InsightsPreferencesInput = {
  unit: InsightsWidgetReportingUnit;
};

export type InsightsWidget = {
  __typename?: 'InsightsWidget';
  budget?: Maybe<Array<InsightsWidgetBudgets>>;
  creator?: Maybe<User>;
  filters?: Maybe<DocumentFilters>;
  id: Scalars['ID']['output'];
  isOwnedByCurrentUser: Scalars['Boolean']['output'];
  preferences: InsightsWidgetPreference;
  sharedWithUsers?: Maybe<Array<User>>;
  title: Scalars['String']['output'];
  unsubscribedUsers?: Maybe<Array<User>>;
};

export type InsightsWidgetBudget = {
  __typename?: 'InsightsWidgetBudget';
  monthlyBudgets: Array<InsightsWidgetMonthlyBudget>;
  year: Scalars['Int']['output'];
};

export type InsightsWidgetBudgetInput = {
  monthlyBudgets: Array<InsightsWidgetMonthlyBudgetInput>;
  year: Scalars['Int']['input'];
};

export type InsightsWidgetBudgets = {
  __typename?: 'InsightsWidgetBudgets';
  monthlyBudgets: InsightsWidgetMonthlyBudgets;
  type: InsightBudgetType;
  year: Scalars['Int']['output'];
};

export type InsightsWidgetBudgetsInput = {
  monthlyBudgets: InsightsWidgetMonthlyBudgetsInput;
  type: InsightBudgetType;
  year: Scalars['Int']['input'];
};

export type InsightsWidgetInput = {
  budget?: InputMaybe<Array<InsightsWidgetBudgetsInput>>;
  filters?: InputMaybe<DocumentFilterInput>;
  preferences: InsightsWidgetPreferenceInput;
  sharedWithUsers?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
  unsubscribedUsers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InsightsWidgetMonthlyBudget = {
  __typename?: 'InsightsWidgetMonthlyBudget';
  april?: Maybe<Scalars['Int']['output']>;
  august?: Maybe<Scalars['Int']['output']>;
  december?: Maybe<Scalars['Int']['output']>;
  february?: Maybe<Scalars['Int']['output']>;
  january?: Maybe<Scalars['Int']['output']>;
  july?: Maybe<Scalars['Int']['output']>;
  june?: Maybe<Scalars['Int']['output']>;
  march?: Maybe<Scalars['Int']['output']>;
  may?: Maybe<Scalars['Int']['output']>;
  november?: Maybe<Scalars['Int']['output']>;
  october?: Maybe<Scalars['Int']['output']>;
  september?: Maybe<Scalars['Int']['output']>;
};

export type InsightsWidgetMonthlyBudgetInput = {
  april?: InputMaybe<Scalars['Int']['input']>;
  august?: InputMaybe<Scalars['Int']['input']>;
  december?: InputMaybe<Scalars['Int']['input']>;
  february?: InputMaybe<Scalars['Int']['input']>;
  january?: InputMaybe<Scalars['Int']['input']>;
  july?: InputMaybe<Scalars['Int']['input']>;
  june?: InputMaybe<Scalars['Int']['input']>;
  march?: InputMaybe<Scalars['Int']['input']>;
  may?: InputMaybe<Scalars['Int']['input']>;
  november?: InputMaybe<Scalars['Int']['input']>;
  october?: InputMaybe<Scalars['Int']['input']>;
  september?: InputMaybe<Scalars['Int']['input']>;
};

export type InsightsWidgetMonthlyBudgets = {
  __typename?: 'InsightsWidgetMonthlyBudgets';
  april?: Maybe<Scalars['Float']['output']>;
  august?: Maybe<Scalars['Float']['output']>;
  december?: Maybe<Scalars['Float']['output']>;
  february?: Maybe<Scalars['Float']['output']>;
  january?: Maybe<Scalars['Float']['output']>;
  july?: Maybe<Scalars['Float']['output']>;
  june?: Maybe<Scalars['Float']['output']>;
  march?: Maybe<Scalars['Float']['output']>;
  may?: Maybe<Scalars['Float']['output']>;
  november?: Maybe<Scalars['Float']['output']>;
  october?: Maybe<Scalars['Float']['output']>;
  september?: Maybe<Scalars['Float']['output']>;
};

export type InsightsWidgetMonthlyBudgetsInput = {
  april?: InputMaybe<Scalars['Float']['input']>;
  august?: InputMaybe<Scalars['Float']['input']>;
  december?: InputMaybe<Scalars['Float']['input']>;
  february?: InputMaybe<Scalars['Float']['input']>;
  january?: InputMaybe<Scalars['Float']['input']>;
  july?: InputMaybe<Scalars['Float']['input']>;
  june?: InputMaybe<Scalars['Float']['input']>;
  march?: InputMaybe<Scalars['Float']['input']>;
  may?: InputMaybe<Scalars['Float']['input']>;
  november?: InputMaybe<Scalars['Float']['input']>;
  october?: InputMaybe<Scalars['Float']['input']>;
  september?: InputMaybe<Scalars['Float']['input']>;
};

export type InsightsWidgetPreference = {
  __typename?: 'InsightsWidgetPreference';
  onDashboard: Scalars['Boolean']['output'];
  widgetId: Scalars['ID']['output'];
};

export type InsightsWidgetPreferenceInput = {
  onDashboard: Scalars['Boolean']['input'];
};

export enum InsightsWidgetReportingUnit {
  Gross = 'GROSS',
  Net = 'NET'
}

export type InsightsWidgetsPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countTotal?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type InsightsWidgetsPaginationResult = {
  __typename?: 'InsightsWidgetsPaginationResult';
  pageInfo?: Maybe<PageInfo>;
  records: Array<InsightsWidget>;
};

export type IntField = {
  __typename?: 'IntField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Array<Maybe<IntSource>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
};

export type IntSource = {
  __typename?: 'IntSource';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
};

export enum IntegrationName {
  Datev = 'DATEV',
  DatevAdjacent = 'DATEV_ADJACENT',
  Other = 'OTHER',
  Sap = 'SAP'
}

export type InvalidBookingAmountError = DocumentApprovalError & {
  __typename?: 'InvalidBookingAmountError';
  amount: Scalars['Float']['output'];
  index: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidBookingIdError = DocumentApprovalError & {
  __typename?: 'InvalidBookingIdError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidDeliveryDateError = DocumentApprovalError & {
  __typename?: 'InvalidDeliveryDateError';
  deliveryDate: Scalars['Date']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
  referenceDate: Scalars['Date']['output'];
};

export type InvalidDiscountAmountError = DocumentApprovalError & {
  __typename?: 'InvalidDiscountAmountError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidDiscountPaymentDateError = DocumentApprovalError & {
  __typename?: 'InvalidDiscountPaymentDateError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidDiscountPercentageError = DocumentApprovalError & {
  __typename?: 'InvalidDiscountPercentageError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidDueDateError = DocumentApprovalError & {
  __typename?: 'InvalidDueDateError';
  dueDate: Scalars['Date']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
  referenceDate: Scalars['Date']['output'];
};

export type InvalidEInvoiceDetectedEvent = DocumentTimeLineItem & {
  __typename?: 'InvalidEInvoiceDetectedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  eInvoiceError?: Maybe<EInvoiceError>;
  eInvoiceFormat?: Maybe<EInvoiceFormat>;
  eInvoiceVersion?: Maybe<Scalars['String']['output']>;
  errorMessages?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type InvalidGrossAmountError = DocumentApprovalError & {
  __typename?: 'InvalidGrossAmountError';
  grossAmount: Scalars['Float']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidInvoiceDateError = DocumentApprovalError & {
  __typename?: 'InvalidInvoiceDateError';
  invoiceDate: Scalars['Date']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
  referenceDate: Scalars['Date']['output'];
};

export type InvalidSwiftCodeError = DocumentApprovalError & {
  __typename?: 'InvalidSwiftCodeError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidTaxNumber = {
  __typename?: 'InvalidTaxNumber';
  kind: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type InvalidVatId = {
  __typename?: 'InvalidVatID';
  kind: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type InvalidXRechnungAttachment = {
  __typename?: 'InvalidXRechnungAttachment';
  errorCode: Scalars['String']['output'];
};

export type InviteSsoUserInput = {
  email: Scalars['EmailAddress']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Locale>;
};

export type InviteUserInput = {
  email: Scalars['EmailAddress']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  locale?: InputMaybe<Locale>;
};

export type InvoiceAccessModificationPermissions = {
  __typename?: 'InvoiceAccessModificationPermissions';
  viewAccess: Scalars['Boolean']['output'];
};

export enum InvoiceAssociationStatus {
  AutoMatched = 'AUTO_MATCHED',
  Missing = 'MISSING',
  NotNeeded = 'NOT_NEEDED',
  Uploaded = 'UPLOADED'
}

export type InvoiceDeliveryPeriod = {
  __typename?: 'InvoiceDeliveryPeriod';
  endDate: Scalars['Date']['output'];
  startDate: Scalars['Date']['output'];
};

export type InvoiceDeliveryPeriodInput = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type InvoiceHasNoBookingsError = DocumentApprovalError & {
  __typename?: 'InvoiceHasNoBookingsError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum InvoicePermission {
  AddRelationship = 'ADD_RELATIONSHIP',
  Comment = 'COMMENT',
  Delete = 'DELETE',
  ManageAccess = 'MANAGE_ACCESS',
  ManageTags = 'MANAGE_TAGS',
  RemoveRelationship = 'REMOVE_RELATIONSHIP',
  View = 'VIEW'
}

export type IssueCardSuggestionInput = {
  cardholderId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<CardIssuerAmountInput>;
};

export type IssuePhysicalCardInput = {
  acceptedCardAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  accountingData?: InputMaybe<CardAccountingDataInput>;
  category?: InputMaybe<CardCategory>;
  label?: InputMaybe<Scalars['String']['input']>;
  limit: CardIssuerAmountInput;
  limitRenewFrequency: CardLimitRenewFrequency;
  membershipId: Scalars['String']['input'];
  settingsData?: InputMaybe<CardSettingsDataInput>;
  suggestions?: InputMaybe<IssueCardSuggestionInput>;
  transactionLimit: CardIssuerAmountInput;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type IssueSingleUseVirtualCardInput = {
  acceptedCardAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  accountingData?: InputMaybe<CardAccountingDataInput>;
  category?: InputMaybe<CardCategory>;
  membershipId: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
  settingsData?: InputMaybe<CardSettingsDataInput>;
  suggestions?: InputMaybe<IssueCardSuggestionInput>;
  transactionLimit: CardIssuerAmountInput;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type IssueVirtualCardInput = {
  acceptedCardAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  accountingData?: InputMaybe<CardAccountingDataInput>;
  category?: InputMaybe<CardCategory>;
  label?: InputMaybe<Scalars['String']['input']>;
  limit: CardIssuerAmountInput;
  limitRenewFrequency: CardLimitRenewFrequency;
  membershipId: Scalars['String']['input'];
  settingsData?: InputMaybe<CardSettingsDataInput>;
  suggestions?: InputMaybe<IssueCardSuggestionInput>;
  transactionLimit: CardIssuerAmountInput;
  validityPeriod: Scalars['Int']['input'];
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LastLoginInfo = {
  __typename?: 'LastLoginInfo';
  mobile?: Maybe<Scalars['Date']['output']>;
  web?: Maybe<Scalars['Date']['output']>;
};

export enum LedgerState {
  Found = 'Found',
  NotFound = 'NotFound',
  OutdatedFiscalYear = 'OutdatedFiscalYear'
}

export type LinkCardForRecurringPaymentSuggestionInput = {
  cardId?: InputMaybe<Scalars['String']['input']>;
};

export type LinkCardForRecurringPaymentVendorInput = {
  cardId: Scalars['String']['input'];
  customVendorName?: InputMaybe<Scalars['String']['input']>;
  suggestions?: InputMaybe<LinkCardForRecurringPaymentSuggestionInput>;
  vendorId: Scalars['String']['input'];
};

export type LinkedCard = {
  __typename?: 'LinkedCard';
  card: CardIssuerCard;
  id: Scalars['String']['output'];
  transactionState: LinkedCardTransactionState;
};

export type LinkedCardMetadata = {
  __typename?: 'LinkedCardMetadata';
  cardId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum LinkedCardTransactionState {
  CannotCompute = 'CANNOT_COMPUTE',
  CardCheckFailed = 'CARD_CHECK_FAILED',
  CardCheckNotDone = 'CARD_CHECK_NOT_DONE',
  CardCheckSucceeded = 'CARD_CHECK_SUCCEEDED',
  PurchaseFailed = 'PURCHASE_FAILED',
  PurchaseSucceeded = 'PURCHASE_SUCCEEDED'
}

export type ListAdditionalErpExpensesInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListAdditionalErpExpensesResult = {
  __typename?: 'ListAdditionalERPExpensesResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<Maybe<AdditionalErpExpense>>;
};

export type ListGoodsReceiptImportHistoryEntriesInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListGoodsReceiptImportHistoryEntriesResult = {
  __typename?: 'ListGoodsReceiptImportHistoryEntriesResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<Maybe<GoodsReceiptImportHistoryEntry>>;
};

export type ListImportHistoryEntriesInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListImportHistoryEntriesResult = {
  __typename?: 'ListImportHistoryEntriesResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<Maybe<ImportHistory>>;
};

export type ListPurchaseOrderImportHistoryEntriesInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ListPurchaseOrderImportHistoryEntriesResult = {
  __typename?: 'ListPurchaseOrderImportHistoryEntriesResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<Maybe<PurchaseOrderImportHistoryEntry>>;
};

export enum Locale {
  De = 'DE',
  En = 'EN'
}

export type MaxAttachmentSizeExceeded = {
  __typename?: 'MaxAttachmentSizeExceeded';
  errorCode: Scalars['String']['output'];
};

export type MemberInfo = {
  __typename?: 'MemberInfo';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  membershipId?: Maybe<Scalars['String']['output']>;
};

export enum MemberSortField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type MemberSortInput = {
  direction: SortDirection;
  field: MemberSortField;
};

export type MembershipForFilter = {
  __typename?: 'MembershipForFilter';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type MembershipPreferences = {
  __typename?: 'MembershipPreferences';
  recentlyUsed: Array<RecentlyUsed>;
};

export type MembershipRole = {
  __typename?: 'MembershipRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isBuiltinRole?: Maybe<Scalars['Boolean']['output']>;
  isLegacyRole?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type MembershipRoleFilters = {
  isBuiltinRole?: InputMaybe<Scalars['Boolean']['input']>;
  isLegacyRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Status of the user's membership to an organization */
export enum MembershipStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type MembershipTeamsCapabilitiesForCreditCards = {
  __typename?: 'MembershipTeamsCapabilitiesForCreditCards';
  canManageCardsForThisTeam?: Maybe<Scalars['Boolean']['output']>;
  issueCardLimitThresholds?: Maybe<CreditCardLimitThresholds>;
  team?: Maybe<Team>;
  teamId: Scalars['String']['output'];
};

export type MembershipTeamsCapabilitiesForCreditCardsFilters = {
  canManageCardsForThisTeam?: InputMaybe<Scalars['Boolean']['input']>;
  membershipId: Scalars['String']['input'];
};

export type MembershipTeamsCapabilitiesForCreditCardsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum MembershipTeamsCapabilitiesSortField {
  CreatedAt = 'CREATED_AT'
}

export type MembershipTeamsCapabilitiesSortInput = {
  direction: SortDirection;
  field: MembershipTeamsCapabilitiesSortField;
};

export type MerchantsConnectionPageBased = {
  __typename?: 'MerchantsConnectionPageBased';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records?: Maybe<Array<CardIssuerMerchant>>;
};

export type MergeContactsInput = {
  ids: Array<Scalars['String']['input']>;
  targetContact?: InputMaybe<MergeContactsTargetContactInput>;
};

export type MergeContactsTargetContactInput = {
  /** Contact ID of the Contact to be used as the target of the merge */
  id?: InputMaybe<Scalars['String']['input']>;
  /** A new name to be used for the Contact used as the target of the merge */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MergeContractSubCategoriesInput = {
  ids: Array<Scalars['String']['input']>;
  targetSubCategory: MergeContractSubCategoriesTargetInput;
};

export type MergeContractSubCategoriesTargetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MergeTagsInput = {
  ids: Array<Scalars['String']['input']>;
  targetTag: MergeTagsTargetTagInput;
};

export type MergeTagsTargetTagInput = {
  /** A new description to be used for the Tag used as the target of the merge */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Tag ID of the Tag to be used as the target of the merge */
  id?: InputMaybe<Scalars['String']['input']>;
  /** A new name to be used for the Tag used as the target of the merge */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Document metadata extraction event */
export type MetaDataExtractionEvent = DocumentTimeLineItem & {
  __typename?: 'MetaDataExtractionEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Extracted fields list */
  extractedFields?: Maybe<Array<Maybe<ExtractedField>>>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

export type MileageReimbursementItem = CommonReimbursementItem & {
  __typename?: 'MileageReimbursementItem';
  applicableRate: Money;
  bookings: Array<ReimbursementBooking>;
  calculatedDistanceInKm: Scalars['Float']['output'];
  createdAt: Scalars['Date']['output'];
  distanceOverride?: Maybe<MileageReimbursementItemDistanceOverride>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  extractedData?: Maybe<Array<CommonReimbursementItemExtraction>>;
  extractedDataAccepted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isRoundTrip: Scalars['Boolean']['output'];
  locations: Array<MileageReimbursementItemLocation>;
  reimbursementCaseId: Scalars['String']['output'];
  status: ReimbursementItemStatus;
  totalAmount?: Maybe<Money>;
  type: ReimbursementItemType;
  updatedAt: Scalars['Date']['output'];
  vehicleType: MileageReimbursementItemVehicleType;
};

export type MileageReimbursementItemDistanceOverride = {
  __typename?: 'MileageReimbursementItemDistanceOverride';
  distanceInKm: Scalars['Float']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

export type MileageReimbursementItemDistanceOverrideInput = {
  distanceInKm: Scalars['Float']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MileageReimbursementItemInput = {
  bookings?: InputMaybe<Array<ReimbursementBookingInput>>;
  distanceOverride?: InputMaybe<MileageReimbursementItemDistanceOverrideInput>;
  expenseDate?: InputMaybe<Scalars['Date']['input']>;
  isRoundTrip?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Array<MileageReimbursementItemLocationInput>>;
  reimbursementCaseId: Scalars['String']['input'];
  status?: InputMaybe<ReimbursementItemStatusInput>;
  vehicleType?: InputMaybe<MileageReimbursementItemVehicleType>;
};

export type MileageReimbursementItemLocation = {
  __typename?: 'MileageReimbursementItemLocation';
  id: Scalars['String']['output'];
  mapboxId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MileageReimbursementItemLocationInput = {
  id: Scalars['String']['input'];
  mapboxId?: InputMaybe<Scalars['String']['input']>;
};

export type MileageReimbursementItemLocationSearchResponse = {
  __typename?: 'MileageReimbursementItemLocationSearchResponse';
  mapboxId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MileageReimbursementItemLocationsSearchInput = {
  query: Scalars['String']['input'];
};

export type MileageReimbursementItemLocationsSearchResponse = {
  __typename?: 'MileageReimbursementItemLocationsSearchResponse';
  attribution?: Maybe<Scalars['String']['output']>;
  locations: Array<MileageReimbursementItemLocationSearchResponse>;
};

export enum MileageReimbursementItemVehicleType {
  Car = 'CAR',
  Motorbike = 'MOTORBIKE'
}

export type MobileAppInvitationEmailRecipient = {
  email?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['String']['input']>;
};

export type MobileDeviceRegistrationInput = {
  token: Scalars['String']['input'];
};

export type ModifyAccessError = {
  __typename?: 'ModifyAccessError';
  message: Scalars['String']['output'];
  type: ModifyAccessErrorType;
};

export enum ModifyAccessErrorType {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export type ModifyAccessInput = {
  /**
   * Changes to be made to the document access levels
   * Should not include items where the access did not change for the membership
   */
  changes: Array<DocumentAccessChangeInput>;
  globalDocumentId: Scalars['String']['input'];
};

export type ModifyAccessResult = AccessLevels | ModifyAccessError;

/** A type to deal with all cash */
export type Money = {
  __typename?: 'Money';
  amount: Scalars['BigInt']['output'];
  currency: DocumentCurrency;
  precision: Scalars['Int']['output'];
};

/** An input type to deal with all cash */
export type MoneyInput = {
  amount: Scalars['BigInt']['input'];
  currency: DocumentCurrency;
  precision: Scalars['Int']['input'];
};

export type MonitoringMyRequests = {
  __typename?: 'MonitoringMyRequests';
  toApprove?: Maybe<TasksCountsInsight>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  acceptCardUsageAgreement: CardReference;
  accountsPayableNumberSettings: AccountsPayableNumberSettings;
  accountsPayableNumberSettingsForEmployees: AccountsPayableNumberSettings;
  acknowledgeContactsImportHistoryErrors: Scalars['Boolean']['output'];
  activateAccountingArea: AccountingAreaCommandResult;
  activateBookingAccount: BookingAccountCommandResult;
  activateBookingKey: BookingKeyCommandResult;
  activateCard: Scalars['Boolean']['output'];
  activateContacts?: Maybe<ContactsActivatedResult>;
  activateDocumentType?: Maybe<Scalars['Void']['output']>;
  /** Sets the membership status to Activate */
  activateMembership?: Maybe<Scalars['Boolean']['output']>;
  activatePayment?: Maybe<Scalars['Boolean']['output']>;
  /** Upon adding auth devices, add an authenticator device for verification */
  addAuthenticatorDevice: PendingAuthenticatorDeviceVerification;
  /** Upon adding auth devices, add an authenticator device for verification, re-auth needed */
  addAuthenticatorDeviceProtected: PendingAuthenticatorDeviceVerificationRequiresRecentAuthn;
  addFileUpload?: Maybe<Scalars['Boolean']['output']>;
  addRoleMembership: Role;
  applyForCreditCards: ApplyForCreditCardsOutput;
  approveCardLimitChangeRequest: Scalars['Boolean']['output'];
  approvePhysicalCardRequest: Scalars['Boolean']['output'];
  approveSingleUseVirtualCardRequest: Scalars['Boolean']['output'];
  approveVirtualCardRequest: Scalars['Boolean']['output'];
  archiveAccountingArea: AccountingAreaCommandResult;
  archiveBookingAccount: BookingAccountCommandResult;
  archiveBookingKey: BookingKeyCommandResult;
  archiveContacts?: Maybe<ContactsArchivedResult>;
  archiveOrDeleteReimbursementCase: ArchiveOrDeleteReimbursementCaseResponse;
  archivePaymentCondition: PaymentConditionCommandResult;
  archiveWorkflowTemplate: WorkflowTemplate;
  associateGoodsReceiptsToDocument: Array<DocumentBookingAssociation>;
  associatePurchaseOrdersToDocument: Array<DocumentBookingAssociation>;
  attachFiles?: Maybe<Document>;
  /** @deprecated bookingAccountImportFileUploadURL is deprecated in favor of getFileUploadUrl */
  bookingAccountImportFileUploadURL: UploadFilesResponse;
  bulkCreateBookingAccounts: BookingAccountImportResult;
  bulkCreateBookingKeys: BookingKeyImportResult;
  bulkCreateOrUpdateContactsInBackground?: Maybe<ContactsImportInBackgroundResult>;
  bulkImportCostCenters: CostCentersBulkImportResult;
  cancelCardRequest: Scalars['Boolean']['output'];
  cancelInvitation?: Maybe<Scalars['Void']['output']>;
  cancelParkedCard: Scalars['Boolean']['output'];
  /** Cancels a PENDING phone number verification for the user */
  cancelPendingPhoneNumberVerification?: Maybe<Scalars['Void']['output']>;
  cancelRequestChangeUserEmail?: Maybe<Scalars['Void']['output']>;
  changeCardLimit: Scalars['Boolean']['output'];
  checkBDSExtfImportPermission: Scalars['Boolean']['output'];
  clearAllNotifications: Scalars['Boolean']['output'];
  connectDatevClient?: Maybe<Scalars['Void']['output']>;
  /** @deprecated contactsImportFileUploadURL is deprecated in favor of getFileUploadUrl */
  contactsImportFileUploadURL: UploadFilesResponse;
  convertDocument: ConvertDocumentResponse;
  convertDocumentsToAttachments?: Maybe<Document>;
  /** @deprecated costCenterRequestImportFileUploadURL is deprecated in favor of getFileUploadUrl */
  costCenterRequestImportFileUploadURL: UploadFilesResponse;
  createAccountingArea: AccountingAreaCommandResult;
  createBookingAccount: BookingAccountCommandResult;
  createBookingKey: BookingKeyCommandResult;
  createCommentOnECMDocument: CreateCommentResult;
  createCommentOnInvoice: CreateCommentResult;
  createCommentOnReimbursementCase: CreateCommentResult;
  createContact: CreateContactResult;
  createContractSubCategory: ContractSubCategory;
  createCostCenter_new: CreateCostCenterResult;
  createCsvFileForTransactions: CsvFileForTransactions;
  createCustomRecurringPaymentVendor: RecurringPaymentVendor;
  createCustomRole: CustomRole;
  createDataFileForDocuments?: Maybe<DocumentDataFileRecord>;
  createDataFileForEcmDocuments?: Maybe<DocumentDataFileRecord>;
  createDataFileForReimbursementCases?: Maybe<DocumentDataFileRecord>;
  /**
   * Old way to create a comment
   *
   * @deprecated: we need to keep it as long as mobile apps are using it
   * @deprecated use createCommentOnInvoice or createCommentOnEcmDocument instead
   */
  createDocumentComment?: Maybe<DocumentCommentEvent>;
  createDocumentRelationships: CreateDocumentRelationshipResponse;
  createGeneralExpenseItem: GeneralExpenseItem;
  createHospitalityExpenseItem: HospitalityExpenseItem;
  createIban: IbanResult;
  createIdentityProvider: CreateIdentityProviderResponse;
  createInsightsWidget: Scalars['ID']['output'];
  createMileageReimbursementItem: MileageReimbursementItem;
  createOrganization?: Maybe<Organization>;
  createPDFZipFileForDocuments?: Maybe<DownloadDocumentPdFsResponse>;
  createPDFZipFileForEcmDocuments?: Maybe<DownloadDocumentPdFsResponse>;
  createPaymentCondition: PaymentConditionCommandResult;
  createPerDiemItem: PerDiemItem;
  createReimbursementCase: ReimbursementCase;
  createSapB1Credentials?: Maybe<SapB1Response>;
  createTag: Tag;
  createTeam?: Maybe<Team>;
  createTestBDSExtfImportJob?: Maybe<Scalars['Void']['output']>;
  createTestOrganization?: Maybe<Organization>;
  createTransactionComment: CreateTransactionCommentResponse;
  createUser?: Maybe<User>;
  createUserSSO?: Maybe<User>;
  createWorkflowTemplate: WorkflowTemplate;
  deactivateDocumentType?: Maybe<Scalars['Void']['output']>;
  /** Sets the membership status to Inactive */
  deactivateMembership?: Maybe<Scalars['Void']['output']>;
  deactivatePayment?: Maybe<Scalars['Boolean']['output']>;
  deleteAttachments?: Maybe<Document>;
  deleteById?: Maybe<DeleteByIdResp>;
  deleteCardForRecurringPaymentVendor: RecurringPaymentVendorWithLikedCards;
  deleteDocumentRelationship: DeleteDocumentRelationshipResponse;
  deleteFile?: Maybe<Scalars['Void']['output']>;
  deleteFileUploadAttempt: DeleteFileUploadAttemptResponse;
  deleteIban: DeleteIbanResult;
  deleteInsightsWidgets?: Maybe<DeleteInsightsWidgetsResult>;
  deleteOriginal?: Maybe<DeleteByIdResp>;
  deletePromoPreference?: Maybe<Scalars['Void']['output']>;
  deleteReimbursementItem: DeleteReimbursementItemResponse;
  deleteSapB1Credentials?: Maybe<SapB1Response>;
  deleteTablePreferences?: Maybe<Scalars['Void']['output']>;
  detachAttachments?: Maybe<Document>;
  disconnectAllAssociatedDocuments: DisconnectAllAssociatedDocumentsResponse;
  disconnectAllPurchaseOrdersFromDocument: DisconnectPurchaseOrdersFromDocumentResponse;
  disconnectDatevClient: Scalars['Boolean']['output'];
  /**
   * Returns a fileID and upload info to upload a document file attachment
   * @deprecated documentAttachmentUploadUrl is deprecated in favor of getFileUploadUrl
   */
  documentAttachmentUploadUrl: UploadFilesResponse;
  documentFastApprove?: Maybe<DocumentFastApproveResult>;
  /** Returns a fileID and upload info to upload a document file */
  documentFileUploadUrl: UploadFilesResponse;
  documentRequestApprovalUnionResponse?: Maybe<DocumentRequestApprovalResult>;
  documentWorkflowStepSubmitUnionResponse?: Maybe<DocumentWorkflowStepSubmitResult>;
  downloadPdfFilesForArchiveAllDocuments: DownloadDocumentPdFsResponse;
  ecmCreateSignedUrl: EcmCreateSignedUrlResponse;
  exportAddisonFormat: NewExportZipResponse;
  exportArchiveDownloadUrl: Scalars['String']['output'];
  exportArchiveDownloadUrls: Array<Maybe<Scalars['String']['output']>>;
  exportBookingAccountsGeneric: ExportBookingAccountsGenericResult;
  exportBookingKeysGeneric: ExportBookingKeysGenericResult;
  exportCandisCsv: CsvExportResponse;
  /** @deprecated Use exportContactsDatev */
  exportContacts: ExportContactsResult;
  exportContactsDatev: ExportContactsResult;
  exportContactsGeneric: ExportContactsResult;
  exportCostCentersGeneric: ExportCostCentersGenericResult;
  /** Export to DATEV with Buchungsdatenservice */
  exportDatevBds: Scalars['String']['output'];
  /** Export entities with Datev DUO Method */
  exportDatevDuo: Scalars['String']['output'];
  /** Export entities with DATEV Format */
  exportDatevFormat: Scalars['String']['output'];
  /** Export entities with Datev XML Method */
  exportDatevXml: Scalars['String']['output'];
  exportPaymentConditions: PaymentConditionsExportResult;
  exportPaymentConditionsGeneric: ExportPaymentConditionsGenericResult;
  exportReEvaluate_unsafe: ReEvaluateExportResults;
  exportSapB1: SapB1ExportResult;
  exportSapB1Saga: SapB1ExportResult;
  /** Export entities with Zip Method */
  exportZip: Scalars['String']['output'];
  fastApproveReimbursementCase: ReimbursementCaseStateChangeResponse;
  forceDeactivateMembership?: Maybe<Scalars['Void']['output']>;
  forceUpdateMembership?: Maybe<Scalars['Void']['output']>;
  formatCardholderNameForCreditCards: FormatCardholderNameForCreditCardsOutput;
  generateCoreDataImportErrorFileById?: Maybe<FileResponse>;
  generateCsvFileForStatement: CardIssuerStatementCsvFile;
  generateSepaTransferXml: SepaXmlResponse;
  generateTestTransactions?: Maybe<Scalars['Boolean']['output']>;
  getFileUploadUrl: FileUpload;
  getTablePreferences: TablePreference;
  importDatevCoreData?: Maybe<Scalars['Void']['output']>;
  importPaymentConditions: Array<PaymentConditionCommandResult>;
  importPaymentConditionsWithUpdate: Array<PaymentConditionImportResult>;
  importSapB1AdditionalERPExpenses?: Maybe<Scalars['Void']['output']>;
  importSapB1Contacts?: Maybe<SapB1ImportResponse>;
  importSapB1CostObjects?: Maybe<SapB1ImportResponse>;
  importSapB1GeneralLedgerAccounts?: Maybe<SapB1ImportResponse>;
  /** @deprecated Use importSapB1GoodsReceiptsV2 instead */
  importSapB1GoodsReceipts?: Maybe<SapB1ImportResponse>;
  importSapB1GoodsReceiptsV2?: Maybe<ImportSapB1GoodsReceiptsResult>;
  importSapB1ProjectCodes?: Maybe<SapB1ImportResponse>;
  /** @deprecated Use importSapB1PurchaseOrdersV2 instead */
  importSapB1PurchaseOrders?: Maybe<SapB1ImportResponse>;
  importSapB1PurchaseOrdersV2?: Maybe<ImportSapB1PurchaseOrdersResult>;
  importSapB1TaxCodes?: Maybe<SapB1ImportResponse>;
  issuePhysicalCardIssuerCard: CardReference;
  issueSingleUseVirtualCardIssuerCard: CardReference;
  issueVirtualCardIssuerCard: CardReference;
  keepDuplicate?: Maybe<DocumentWithId>;
  linkCardForRecurringPaymentVendor: RecurringPaymentVendorWithLikedCards;
  linkCardIssuerOrganizations?: Maybe<CardIssuerOrganizationsMap>;
  linkDocumentAndTransactions?: Maybe<Array<Maybe<CardIssuerTransaction>>>;
  lockUnlockCard: Scalars['Boolean']['output'];
  markDocumentDataFileRecordsAsAcknowledged: Scalars['Boolean']['output'];
  mergeContacts?: Maybe<Contact>;
  mergeContractSubCategories: ContractSubCategory;
  mergeTags: Tag;
  modifyDocumentAccess: ModifyAccessResult;
  newExportSapCsv: CsvExportResponse;
  new_buildDatevAuthUrlForClients: Scalars['String']['output'];
  new_buildDatevAuthUrlForExportDocuments: Scalars['String']['output'];
  new_requestDatevAuthTokens: Scalars['Boolean']['output'];
  new_updateDatevExportType?: Maybe<Scalars['Void']['output']>;
  obtainNewSlackToken?: Maybe<Scalars['Void']['output']>;
  ops_addExportMetadata: OpsAddExportMetadataResult;
  ops_bulkActivateGeneralLedgerAccounts: Scalars['String']['output'];
  ops_bulkArchiveCostCenters: Scalars['String']['output'];
  ops_bulkArchiveGeneralLedgerAccounts: Scalars['String']['output'];
  ops_bulkDeleteDocuments: OpsBulkDeleteDocumentsResult;
  /** Allows Customer Operation to invite one user multiple organization */
  ops_bulkInviteUserToOrganizations: Array<User>;
  /** Allows Customer Operation to invite multiple users to one organization */
  ops_bulkInviteUsersToOrganization: Array<User>;
  ops_bulkLinkContactsToMemberships?: Maybe<OpsBulkLinkContactsToMembershipsOutput>;
  ops_bulkReactivateCostCenters: Scalars['String']['output'];
  /** Bulk update for mfa settings */
  ops_bulkUpdateOrganizationMfaSettings: Array<Organization>;
  ops_cancelMembershipInvitations: Array<Scalars['String']['output']>;
  ops_changeIntegrationForOrganization: OpsConfigureIntegrationResponse;
  ops_changeSuperAdmin: Scalars['String']['output'];
  ops_configureIntegrationForOrganization: OpsConfigureIntegrationResponse;
  ops_createCandisApiClient: CandisApiClient;
  ops_createEmailAccountForOrganization: OrganizationEmailAccount;
  /** Create a holding company */
  ops_createHolding?: Maybe<Holding>;
  ops_createSnapshot: Scalars['String']['output'];
  /** Allows Customer Ops Deactivate credit card organization for migration */
  ops_deactivateCardIssuerOrganizationForMigration: OpsDeactivateCardIssuerOrganizationForMigrationResponse;
  ops_deactivateUser: Scalars['String']['output'];
  ops_deleteUserPreferences?: Maybe<Scalars['Void']['output']>;
  ops_disableEmailDigest: Scalars['String']['output'];
  ops_enableEmailDigest: Scalars['String']['output'];
  /** Allows Customer Operation to re-trigger pipeline that will ensure cardholder exists in candis and pliant */
  ops_ensureCardholderExists: OpsEnsureCardholderExistsOutput;
  ops_extendBookingAccountNumbersLength: ExtendBookingAccountNumbersLengthResponse;
  ops_extendCreditorAccountNumbersLength: ChangeCreditorAccountNumbersLengthResponse;
  ops_fixNewInvoicesCounter: OpsFixNewInvoicesCounterResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Force check ES documents from beginning
   */
  ops_forceCheckEsDocumentsFromBeginning: OpsForceCheckEsDocumentsFromBeginningResult;
  ops_generateDocumentSnapshots: OpsGenerateDocumentSnapshotsResults;
  ops_generateMultipleDocumentSnapshots: Array<OpsGenerateMultipleDocumentSnapshotsResult>;
  ops_increaseUserNextMfaWindow: Scalars['String']['output'];
  /** Allows Customer Operation to invite a user to a specific organization */
  ops_inviteUserToOrganization: User;
  /** Removes Documents, Contacts and Accounting core data for the organization */
  ops_purgeOrganizationData: Scalars['String']['output'];
  ops_purgeReimbursementData: PurgeReimbursementDataResponse;
  ops_reduceAccountsPayableNumbersLength: ChangeCreditorAccountNumbersLengthResponse;
  ops_removeAuthenticatorDevice?: Maybe<Scalars['Void']['output']>;
  ops_requestGoBDReportCSV: Scalars['String']['output'];
  /** Asynchronously triggers a Parent Documents synchronization for a given organization and Ecm Documents */
  ops_syncParentDocumentsForOrganization: Scalars['String']['output'];
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fix broken layoutId (float values) in documents and convert them to integers.
   */
  ops_synchronizationFixDocumentsConvertFloatToIntOnLayoutId: OpsSynchronizationFixDocumentsConvertFloatToIntOnLayoutIdResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fix missing approved by values by filling them with last approvers ids
   */
  ops_synchronizationFixRewriteApprovedByFromApproversIds: OpsSynchronizationFixRewriteApprovedByFromApproversIdsResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fix missing documentNumber/invoiceId values by filling them with available filename
   */
  ops_synchronizationFixesFillContactNameFromLinkedContact: OpsSynchronizationFixesFillContactNameFromLinkedContactResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fix missing documentNumber/invoiceId values by filling them with available filename
   */
  ops_synchronizationFixesFillDocumentNumberFromFile: OpsSynchronizationFixesFillDocumentNumberFromFileResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fill missing resolvedValue from source
   */
  ops_synchronizationFixesFillResolvedValueFromSource: OpsSynchronizationFixesFillResolvedValueFromSourceResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fix missing documentNumber/invoiceId values by filling them with available filename
   */
  ops_synchronizationFixesRewriteApprovedAtAndByFromActivity: OpsSynchronizationFixesRewriteApprovedAtAndByFromActivityResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fix missing documentNumber/invoiceId values by filling them with available filename
   */
  ops_synchronizationFixesRewriteMissingFieldsBookingCostCenterFromLinkedItem: OpsSynchronizationFixesRewriteMissingFieldsBookingCostCenterFromLinkedItemResult;
  /**
   * ⚠️ WARNING ⚠️
   * This operation should be used only by developers.
   * This operation is irreversible.
   * ---
   * Fix missing documentNumber/invoiceId values by filling them with available filename
   */
  ops_synchronizationFixesRewriteRequestedAtAndRequesterFromActivity: OpsSynchronizationFixesRewriteRequestedAtAndRequesterFromActivityResult;
  /** Allows Customer Operation to re-trigger pipeline that will re fetch all the receipts from pliant for the transactions in an inbox */
  ops_synchronizePliantReceiptsForTransactions: OpsSynchronizePliantReceiptsForTransactionsOutput;
  ops_transferOwnership: Scalars['String']['output'];
  /** Asynchronously triggers a Parent Documents synchronization for the given organizations */
  ops_triggerSyncParentDocuments: Scalars['String']['output'];
  ops_updateContactRelationType?: Maybe<Scalars['Void']['output']>;
  ops_updateHolding?: Maybe<Holding>;
  ops_updateOrganizationMfaSettings?: Maybe<Organization>;
  ops_updateOrganizationSSOConfigurationId?: Maybe<Scalars['Void']['output']>;
  ops_updateSSOConfiguration?: Maybe<Scalars['Void']['output']>;
  ops_updateUserSkipSSOLogin: Scalars['String']['output'];
  /** Trigger a validation and removal of stale documents for the given organizations */
  ops_validateAndRemoveDeletedDocuments: Scalars['String']['output'];
  organizationContactInformation: OrganizationContactInformationResult;
  processDocument?: Maybe<Document>;
  processEcmDocument: CreateEcmDocumentResponse;
  processFile: ProcessFileResponse;
  reconnectAndVerifySapB1Connection?: Maybe<SapB1Settings>;
  registerMobileDevice: Scalars['Boolean']['output'];
  rejectCardRequest: Scalars['Boolean']['output'];
  removeAll?: Maybe<Scalars['Boolean']['output']>;
  /** Removes the avatar for the target membership */
  removeAvatar?: Maybe<Scalars['Boolean']['output']>;
  removeEcmDocument: CreateEcmDocumentResponse;
  removeFileUploadById?: Maybe<Scalars['Boolean']['output']>;
  removeOrganizationSSOConfiguration?: Maybe<Scalars['Void']['output']>;
  removeRoleMembership: Role;
  removeUser?: Maybe<User>;
  replaceCard: CardIssuerCard;
  requestCardLimitChange: Scalars['Boolean']['output'];
  requestChangeUserEmail: RequestChangeUserEmailResult;
  /** @deprecated Use applyForCreditCards instead */
  requestOnboardingForCardIssuerOrganization?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Requests a new phone number verification for the user.
   *
   * Upon verification, the user's phone number will get updated
   */
  requestPhoneNumberVerification: PendingPhoneNumberVerification;
  requestPhoneNumberVerificationProtected: PendingPhoneNumberVerificationRequiresRecentAuthn;
  requestPhysicalCardIssuerCard: CardReference;
  requestReimbursementCaseAmendment: ReimbursementCaseStateChangeResponse;
  requestSingleUseVirtualCardIssuerCard: CardReference;
  requestUploadAvatarUrl?: Maybe<UploadAvatarResponse>;
  requestVirtualCardIssuerCard: CardReference;
  resendChangeEmailRequestVerificationEmail?: Maybe<Scalars['Void']['output']>;
  resendInvitation?: Maybe<User>;
  /** Resends a PENDING phone number verification (OTP code) */
  resendPendingPhoneNumberVerification?: Maybe<Scalars['Void']['output']>;
  resetDocument?: Maybe<Document>;
  resetReimbursementCaseApproval: ReimbursementCaseStateChangeResponse;
  restoreWorkflowTemplate: WorkflowTemplate;
  revokeCandisAPISession: RevokeCandisApiSessionResult;
  savePromoPreference: PromoPreference;
  saveTablePreferences: TablePreference;
  sendEmail?: Maybe<SendEmailResponse>;
  sendEmailToSupplier?: Maybe<SendEmailResponse>;
  sendMobileAppInvitationEmail?: Maybe<Scalars['Void']['output']>;
  setAbsenceForMember: SetUserAbsenceResult;
  setAccountsPayableNumberForContact?: Maybe<Contact>;
  /** Sets the consent for tracking for user in true/false format */
  setConsentForTracking?: Maybe<Scalars['Void']['output']>;
  setConsentForTrackingConfiguration?: Maybe<Scalars['Void']['output']>;
  setCurrentUserAbsence: SetUserAbsenceResult;
  setFiscalYearConfigured?: Maybe<Scalars['Void']['output']>;
  setIbanAsOrgDefault: IbanResult;
  setNextFreeAccountsPayableNumberForContact?: Maybe<Contact>;
  /** Set the membership status of all users in an organization */
  setOrganizationMembershipsStatus?: Maybe<SetOrganizationMembershipsStatusResponse>;
  setRecurringPaymentVendorIgnoredState: RecurringPaymentVendor;
  setRoleIsArchived: CustomRole;
  setTeamIsArchived?: Maybe<Team>;
  setTeamSettingsForCreditCards: TeamSettingsForCreditCards;
  setTransactionInvoiceNeeded: CardIssuerTransaction;
  setTransactionInvoiceNotNeeded: CardIssuerTransaction;
  sfsSwitchBookingKeyForApproverOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchBookingKeyForApproverOnManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchBookingKeyOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchBookingKeyOnManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostCenterForApproverOffAutomatically?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostCenterForApproverOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostCenterForApproverOnManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostCenterOffAutomatically?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostCenterOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostCenterOnManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostObjectForApproverOffAutomatically?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostObjectForApproverOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostObjectForApproverOnManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostObjectOffAutomatically?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostObjectOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchCostObjectOnManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchGeneralLedgerAccountForApproverOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchGeneralLedgerAccountForApproverOnManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchGeneralLedgerAccountOffManually?: Maybe<Scalars['Void']['output']>;
  sfsSwitchGeneralLedgerAccountOnManually?: Maybe<Scalars['Void']['output']>;
  softDeleteFileUploadByFileId?: Maybe<Scalars['Boolean']['output']>;
  storeEcmDocument: StoreEcmDocumentResponse;
  storeSensitiveEcmDocument: StoreEcmDocumentResponse;
  submitReimbursementCaseForApproval: ReimbursementCaseStateChangeResponse;
  submitReimbursementCaseForReview: ReimbursementCaseStateChangeResponse;
  submitReimbursementCaseWorkflowStep: ReimbursementCaseStateChangeResponse;
  syncDocumentTypes: Scalars['Boolean']['output'];
  terminateCard: Scalars['Boolean']['output'];
  toggleInAppNotifications: Scalars['Int']['output'];
  trackEvent?: Maybe<Scalars['Void']['output']>;
  transferOwnership: TransferOwnershipResult;
  unarchivePaymentCondition: PaymentConditionCommandResult;
  unlinkTransactionFromDocument?: Maybe<CardIssuerTransaction>;
  unsetAccountsPayableNumberForContact?: Maybe<Contact>;
  unsubscribeInsightsWidget?: Maybe<Scalars['Boolean']['output']>;
  updateAccountingArea: AccountingAreaCommandResult;
  updateAccountsPayableNumberForEmployeeContact?: Maybe<Contact>;
  updateAvatar?: Maybe<Scalars['Void']['output']>;
  updateBookingAccount: BookingAccountCommandResult;
  updateBookingKey: BookingKeyCommandResult;
  updateCardAccountingData: Scalars['Boolean']['output'];
  updateCardForRecurringPaymentVendor: RecurringPaymentVendorWithLikedCards;
  updateCardIssuerOrganizationSettings: OrganizationCreditCardsSettings;
  updateCardLabel: Scalars['Boolean']['output'];
  updateCardPurpose: Scalars['Boolean']['output'];
  updateCardSettingsData: Scalars['Boolean']['output'];
  updateChartOfAccount?: Maybe<Scalars['Void']['output']>;
  updateContact?: Maybe<Contact>;
  updateContractSubCategory: ContractSubCategory;
  updateContractSubCategoryStatus: ContractSubCategory;
  updateCostCenterStatus?: Maybe<CostCenter>;
  updateCostCenter_new: UpdateCostCenterResult;
  updateCreditCardsLedger?: Maybe<Scalars['Void']['output']>;
  updateCurrentUser?: Maybe<User>;
  updateCustomRole: CustomRole;
  updateDatevBDSPreRequisites?: Maybe<Scalars['Void']['output']>;
  updateDatevClientCashLedgerConfigured?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Use updateCreditCardsLedger instead */
  updateDatevCreditCardsLedger?: Maybe<Scalars['Void']['output']>;
  updateDatevProvisionsLedger?: Maybe<Scalars['Void']['output']>;
  updateDocumentDataExtractionSettings?: Maybe<Scalars['Void']['output']>;
  updateDocumentTags: Array<Tag>;
  updateDocumentTypeBookingAccount?: Maybe<Scalars['Void']['output']>;
  updateEcmDocument: UpdateEcmDocumentResponse;
  updateEcmDocumentTypePreferences: EcmDocumentTypeSettingRecord;
  updateExportConfiguration?: Maybe<Scalars['Void']['output']>;
  updateExportConfigurationNew?: Maybe<Scalars['Void']['output']>;
  updateFileUpload?: Maybe<Scalars['Boolean']['output']>;
  updateGeneralExpenseItem: GeneralExpenseItem;
  updateHospitalityExpenseItem: HospitalityExpenseItem;
  updateIban: IbanResult;
  updateIdentityProvider?: Maybe<Scalars['Void']['output']>;
  updateInsightsPreferences: InsightsPreferences;
  updateInsightsWidget?: Maybe<InsightsWidget>;
  updateInsightsWidgetPreferences?: Maybe<Scalars['Boolean']['output']>;
  updateMembership?: Maybe<Scalars['Void']['output']>;
  updateMileageReimbursementItem: MileageReimbursementItem;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationSSOConfiguration?: Maybe<Scalars['Void']['output']>;
  updatePaymentCondition: PaymentConditionCommandResult;
  updatePaymentData?: Maybe<Scalars['Void']['output']>;
  updatePaymentStartDate?: Maybe<Scalars['Boolean']['output']>;
  updatePerDiemItem: PerDiemItem;
  updateReimbursementCase: ReimbursementCase;
  updateSSOConfiguration?: Maybe<Scalars['Void']['output']>;
  updateSapB1Credentials?: Maybe<SapB1Response>;
  updateSapB1Settings?: Maybe<SapB1Response>;
  updateTag: Tag;
  updateTagStatus: Tag;
  updateTeam?: Maybe<Team>;
  updateUserPaymentInfo?: Maybe<UserPaymentInfoUpdatedResponse>;
  updateWaitingForClarification?: Maybe<Scalars['Void']['output']>;
  updateWorkflowTemplate: WorkflowTemplate;
  /**
   * Returns a fileID and upload info to upload a document
   * @deprecated Use fileUploadUrl instead
   */
  uploadDocument?: Maybe<UploadFilesResponse>;
  /** Completes a PENDING device verification for a user through the given token and deviceId */
  verifyAuthenticatorDevice: VerifyAuthenticatorDeviceResult;
  /** Completes a PENDING phone number verification for a user through the given OTP code */
  verifyPhoneNumber: VerifyPhoneNumberResult;
};


export type MutationAcceptCardUsageAgreementArgs = {
  input: CardIssuerCardUsageAgreementInput;
};


export type MutationAccountsPayableNumberSettingsArgs = {
  settings: AccountsPayableNumberSettingsInput;
};


export type MutationAccountsPayableNumberSettingsForEmployeesArgs = {
  settings: AccountsPayableNumberSettingsInput;
};


export type MutationAcknowledgeContactsImportHistoryErrorsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationActivateAccountingAreaArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivateBookingAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivateBookingKeyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivateCardArgs = {
  input: ActivateCardInput;
};


export type MutationActivateContactsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationActivateDocumentTypeArgs = {
  documentTypeId: DocumentTypeId;
};


export type MutationActivateMembershipArgs = {
  membershipId: Scalars['String']['input'];
};


export type MutationActivatePaymentArgs = {
  startDate: Scalars['Date']['input'];
};


export type MutationAddAuthenticatorDeviceArgs = {
  name: Scalars['String']['input'];
};


export type MutationAddAuthenticatorDeviceProtectedArgs = {
  name: Scalars['String']['input'];
};


export type MutationAddFileUploadArgs = {
  file?: InputMaybe<FileInput>;
};


export type MutationAddRoleMembershipArgs = {
  input: AddRoleMembershipInput;
};


export type MutationApplyForCreditCardsArgs = {
  input: ApplyForCreditCardsInput;
};


export type MutationApproveCardLimitChangeRequestArgs = {
  input: ApproveCardLimitChangeRequestInput;
};


export type MutationApprovePhysicalCardRequestArgs = {
  input: ApprovePhysicalCardRequestInput;
};


export type MutationApproveSingleUseVirtualCardRequestArgs = {
  input: ApproveSingleUseVirtualCardRequestInput;
};


export type MutationApproveVirtualCardRequestArgs = {
  input: ApproveVirtualCardRequestInput;
};


export type MutationArchiveAccountingAreaArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveBookingAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveBookingKeyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveContactsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationArchiveOrDeleteReimbursementCaseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchivePaymentConditionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveWorkflowTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAssociateGoodsReceiptsToDocumentArgs = {
  documentId: Scalars['String']['input'];
  goodsReceiptsIds: Array<Scalars['String']['input']>;
};


export type MutationAssociatePurchaseOrdersToDocumentArgs = {
  documentId: Scalars['String']['input'];
  purchaseOrderIds: Array<Scalars['String']['input']>;
};


export type MutationAttachFilesArgs = {
  input: AttachFilesInput;
};


export type MutationBookingAccountImportFileUploadUrlArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};


export type MutationBulkCreateBookingAccountsArgs = {
  fileId?: InputMaybe<Scalars['String']['input']>;
  input: Array<CreateBookingAccountInput>;
};


export type MutationBulkCreateBookingKeysArgs = {
  input: Array<CreateBookingKeyInput>;
};


export type MutationBulkCreateOrUpdateContactsInBackgroundArgs = {
  fileId: Scalars['String']['input'];
  input: Array<ImportAndUpdateContactInput>;
};


export type MutationBulkImportCostCentersArgs = {
  fileId?: InputMaybe<Scalars['String']['input']>;
  input: Array<CreateCostCenterInput>;
};


export type MutationCancelCardRequestArgs = {
  input: CancelCardRequestInput;
};


export type MutationCancelInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelParkedCardArgs = {
  input: CardIssuerCancelParkedCardInput;
};


export type MutationCancelRequestChangeUserEmailArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangeCardLimitArgs = {
  input: ChangeCardLimitInput;
};


export type MutationConnectDatevClientArgs = {
  input: ClientInput;
};


export type MutationContactsImportFileUploadUrlArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};


export type MutationConvertDocumentArgs = {
  input: ConvertDocumentInput;
};


export type MutationConvertDocumentsToAttachmentsArgs = {
  input: ConvertDocumentsToAttachmentsInput;
};


export type MutationCostCenterRequestImportFileUploadUrlArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};


export type MutationCreateAccountingAreaArgs = {
  input: CreateAccountingAreaInput;
};


export type MutationCreateBookingAccountArgs = {
  input: CreateBookingAccountInput;
};


export type MutationCreateBookingKeyArgs = {
  input: CreateBookingKeyInput;
};


export type MutationCreateCommentOnEcmDocumentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCommentOnInvoiceArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCommentOnReimbursementCaseArgs = {
  input: CreateCommentInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateContractSubCategoryArgs = {
  input: CreateContractSubCategoryInput;
};


export type MutationCreateCostCenter_NewArgs = {
  input: CreateCostCenterInput;
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCsvFileForTransactionsArgs = {
  filters?: InputMaybe<CardIssuerTransactionFilterInput>;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
};


export type MutationCreateCustomRecurringPaymentVendorArgs = {
  input: CreateCustomRecurringPaymentVendorInput;
};


export type MutationCreateCustomRoleArgs = {
  input: CreateCustomRoleInput;
};


export type MutationCreateDataFileForDocumentsArgs = {
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  type?: InputMaybe<DocumentDataFileType>;
};


export type MutationCreateDataFileForEcmDocumentsArgs = {
  filter?: InputMaybe<EcmFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<EcmSortInput>;
  type?: InputMaybe<DocumentDataFileType>;
};


export type MutationCreateDataFileForReimbursementCasesArgs = {
  filter?: InputMaybe<ReimbursementCasesFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<ReimbursementCasesSortInput>;
  type?: InputMaybe<DocumentDataFileType>;
};


export type MutationCreateDocumentCommentArgs = {
  id: Scalars['ID']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
};


export type MutationCreateDocumentRelationshipsArgs = {
  input: CreateDocumentRelationshipsInput;
};


export type MutationCreateGeneralExpenseItemArgs = {
  input: GeneralExpenseItemInput;
};


export type MutationCreateHospitalityExpenseItemArgs = {
  input: HospitalityExpenseItemInput;
};


export type MutationCreateIbanArgs = {
  input: IbanInput;
};


export type MutationCreateIdentityProviderArgs = {
  input: IdentityProviderInput;
};


export type MutationCreateInsightsWidgetArgs = {
  input: InsightsWidgetInput;
};


export type MutationCreateMileageReimbursementItemArgs = {
  input: MileageReimbursementItemInput;
};


export type MutationCreateOrganizationArgs = {
  input?: InputMaybe<CreateOrganizationInput>;
};


export type MutationCreatePdfZipFileForDocumentsArgs = {
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
};


export type MutationCreatePdfZipFileForEcmDocumentsArgs = {
  filter?: InputMaybe<EcmFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<EcmSortInput>;
};


export type MutationCreatePaymentConditionArgs = {
  input: CreatePaymentConditionInput;
};


export type MutationCreatePerDiemItemArgs = {
  input: CreatePerDiemItemInput;
};


export type MutationCreateReimbursementCaseArgs = {
  input: CreateReimbursementCaseInput;
};


export type MutationCreateSapB1CredentialsArgs = {
  sapB1Credentials: SapB1CredentialsInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamBodyInput;
};


export type MutationCreateTestOrganizationArgs = {
  input?: InputMaybe<CreateTestOrganizationInput>;
};


export type MutationCreateTransactionCommentArgs = {
  mentionedMembershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserSsoArgs = {
  input: CreateUserSsoInput;
};


export type MutationCreateWorkflowTemplateArgs = {
  input: CreateWorkflowTemplateInput;
};


export type MutationDeactivateDocumentTypeArgs = {
  documentTypeId: DocumentTypeId;
};


export type MutationDeactivateMembershipArgs = {
  membershipId: Scalars['String']['input'];
};


export type MutationDeleteAttachmentsArgs = {
  input: DeleteAttachmentsInput;
};


export type MutationDeleteByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCardForRecurringPaymentVendorArgs = {
  input: DeleteCardForRecurringPaymentVendorInput;
};


export type MutationDeleteDocumentRelationshipArgs = {
  relationshipId: Scalars['String']['input'];
};


export type MutationDeleteFileArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationDeleteFileUploadAttemptArgs = {
  input: DeleteFileUploadAttemptInput;
};


export type MutationDeleteIbanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInsightsWidgetsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteOriginalArgs = {
  docId: Scalars['String']['input'];
};


export type MutationDeletePromoPreferenceArgs = {
  promoId: Scalars['String']['input'];
};


export type MutationDeleteReimbursementItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTablePreferencesArgs = {
  input: DeleteTablePreferenceInput;
};


export type MutationDetachAttachmentsArgs = {
  input: DetachAttachmentsInput;
};


export type MutationDisconnectAllAssociatedDocumentsArgs = {
  invoiceId: Scalars['String']['input'];
};


export type MutationDisconnectAllPurchaseOrdersFromDocumentArgs = {
  documentId: Scalars['String']['input'];
};


export type MutationDocumentAttachmentUploadUrlArgs = {
  options: UploadOptions;
};


export type MutationDocumentFastApproveArgs = {
  id: Scalars['ID']['input'];
  input: DocumentFastApproveInput;
  options?: InputMaybe<DocumentFastApproveOptionsInput>;
};


export type MutationDocumentFileUploadUrlArgs = {
  options: UploadOptions;
};


export type MutationDocumentRequestApprovalUnionResponseArgs = {
  id: Scalars['ID']['input'];
  input: DocumentRequestApprovalInput;
  options?: InputMaybe<DocumentRequestApprovalOptionsInput>;
};


export type MutationDocumentWorkflowStepSubmitUnionResponseArgs = {
  id: Scalars['ID']['input'];
  input: DocumentWorkflowStepSubmitInput;
};


export type MutationDownloadPdfFilesForArchiveAllDocumentsArgs = {
  filter?: InputMaybe<ArchiveAllDocumentsFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<ArchiveAllDocumentsSortInput>;
};


export type MutationEcmCreateSignedUrlArgs = {
  input: EcmCreateSignedUrlInput;
};


export type MutationExportAddisonFormatArgs = {
  cardSettlements?: InputMaybe<Array<Scalars['String']['input']>>;
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  originExportId?: InputMaybe<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']>;
  transactionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationExportArchiveDownloadUrlArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExportArchiveDownloadUrlsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExportCandisCsvArgs = {
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  originExportId?: InputMaybe<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']>;
  reversalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  timeFrame: TimeFrameInput;
};


export type MutationExportDatevBdsArgs = {
  cardSettlementIds: Array<Scalars['String']['input']>;
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  exportAllContacts?: InputMaybe<Scalars['Boolean']['input']>;
  exportAllPaymentConditions?: InputMaybe<Scalars['Boolean']['input']>;
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  provisionIds: Array<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']>;
  reversalIds: Array<Scalars['String']['input']>;
  timeFrame: TimeFrameInput;
  transactionIds: Array<Scalars['String']['input']>;
};


export type MutationExportDatevDuoArgs = {
  cardSettlementIds: Array<Scalars['String']['input']>;
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']>;
  transactionIds: Array<Scalars['String']['input']>;
};


export type MutationExportDatevFormatArgs = {
  cardSettlementIds: Array<Scalars['String']['input']>;
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  provisionIds: Array<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']>;
  reversalIds: Array<Scalars['String']['input']>;
  timeFrame: TimeFrameInput;
  transactionIds: Array<Scalars['String']['input']>;
};


export type MutationExportDatevXmlArgs = {
  cardSettlementIds: Array<Scalars['String']['input']>;
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']>;
  transactionIds: Array<Scalars['String']['input']>;
};


export type MutationExportReEvaluate_UnsafeArgs = {
  exportId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationExportSapB1Args = {
  exportGuid?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExportSapB1SagaArgs = {
  exportGuid: Scalars['String']['input'];
  isReexport?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationExportZipArgs = {
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']>;
};


export type MutationFastApproveReimbursementCaseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationForceDeactivateMembershipArgs = {
  membershipId: Scalars['String']['input'];
};


export type MutationForceUpdateMembershipArgs = {
  id: Scalars['String']['input'];
  input: UpdateUserInput;
};


export type MutationFormatCardholderNameForCreditCardsArgs = {
  input: FormatCardholderNameForCreditCardsInput;
};


export type MutationGenerateCoreDataImportErrorFileByIdArgs = {
  processId: Scalars['ID']['input'];
  type: CoreDataTypes;
};


export type MutationGenerateCsvFileForStatementArgs = {
  input: GenerateCsvFileForStatementInput;
};


export type MutationGenerateSepaTransferXmlArgs = {
  input: SepaXmlInput;
};


export type MutationGenerateTestTransactionsArgs = {
  input: CardIssuerGenerateTestTransactionsInput;
};


export type MutationGetFileUploadUrlArgs = {
  params: FileUploadParams;
};


export type MutationGetTablePreferencesArgs = {
  tableId: Scalars['String']['input'];
};


export type MutationImportPaymentConditionsArgs = {
  fileId: Scalars['String']['input'];
  input: Array<CreatePaymentConditionInput>;
};


export type MutationImportPaymentConditionsWithUpdateArgs = {
  fileId: Scalars['String']['input'];
  input: Array<CreatePaymentConditionInput>;
};


export type MutationImportSapB1GoodsReceiptsArgs = {
  importFilters?: InputMaybe<SapB1GoodsReceiptsImportFilters>;
};


export type MutationImportSapB1GoodsReceiptsV2Args = {
  importFilters?: InputMaybe<SapB1GoodsReceiptsImportFilters>;
};


export type MutationImportSapB1PurchaseOrdersArgs = {
  importFilters?: InputMaybe<SapB1PurchaseOrdersImportFilters>;
};


export type MutationImportSapB1PurchaseOrdersV2Args = {
  importFilters?: InputMaybe<SapB1PurchaseOrdersImportFilters>;
};


export type MutationIssuePhysicalCardIssuerCardArgs = {
  input: IssuePhysicalCardInput;
};


export type MutationIssueSingleUseVirtualCardIssuerCardArgs = {
  input: IssueSingleUseVirtualCardInput;
};


export type MutationIssueVirtualCardIssuerCardArgs = {
  input: IssueVirtualCardInput;
};


export type MutationKeepDuplicateArgs = {
  docId: Scalars['String']['input'];
};


export type MutationLinkCardForRecurringPaymentVendorArgs = {
  input: LinkCardForRecurringPaymentVendorInput;
};


export type MutationLinkCardIssuerOrganizationsArgs = {
  input: CardIssuerOrganizationInput;
};


export type MutationLinkDocumentAndTransactionsArgs = {
  documentId: Scalars['String']['input'];
  transactionIds: Array<Scalars['String']['input']>;
};


export type MutationLockUnlockCardArgs = {
  input: CardIssuerLockUnlockCardInput;
};


export type MutationMarkDocumentDataFileRecordsAsAcknowledgedArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationMergeContactsArgs = {
  input: MergeContactsInput;
};


export type MutationMergeContractSubCategoriesArgs = {
  input: MergeContractSubCategoriesInput;
};


export type MutationMergeTagsArgs = {
  input: MergeTagsInput;
};


export type MutationModifyDocumentAccessArgs = {
  input: ModifyAccessInput;
};


export type MutationNewExportSapCsvArgs = {
  documentIds: Array<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  originExportId?: InputMaybe<Scalars['String']['input']>;
  timeFrame?: InputMaybe<TimeFrameInput>;
};


export type MutationNew_BuildDatevAuthUrlForClientsArgs = {
  connectionType?: InputMaybe<DatevConnectionType>;
  state: Scalars['String']['input'];
};


export type MutationNew_BuildDatevAuthUrlForExportDocumentsArgs = {
  state: Scalars['String']['input'];
};


export type MutationNew_RequestDatevAuthTokensArgs = {
  authUrl: Scalars['String']['input'];
};


export type MutationNew_UpdateDatevExportTypeArgs = {
  exportType: DatevClientExportType;
};


export type MutationObtainNewSlackTokenArgs = {
  token: ObtainSlackTokenInput;
};


export type MutationOps_AddExportMetadataArgs = {
  documentIds: Array<Scalars['String']['input']>;
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_BulkActivateGeneralLedgerAccountsArgs = {
  input: BulkChangeGeneralLedgerAccountStatus;
};


export type MutationOps_BulkArchiveCostCentersArgs = {
  input: BulkArchiveCostCentersInput;
};


export type MutationOps_BulkArchiveGeneralLedgerAccountsArgs = {
  input: BulkChangeGeneralLedgerAccountStatus;
};


export type MutationOps_BulkDeleteDocumentsArgs = {
  input: OpsBulkDeleteDocumentsInput;
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_BulkInviteUserToOrganizationsArgs = {
  input: OpsInviteUserInput;
  organizationSlugs: Array<Scalars['String']['input']>;
};


export type MutationOps_BulkInviteUsersToOrganizationArgs = {
  input: Array<OpsInviteUserInput>;
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_BulkLinkContactsToMembershipsArgs = {
  input: OpsBulkLinkContactsToMembershipsInput;
  options?: InputMaybe<OpsBulkLinkContactsToMembershipsOptionsInput>;
};


export type MutationOps_BulkReactivateCostCentersArgs = {
  input: BulkArchiveCostCentersInput;
};


export type MutationOps_BulkUpdateOrganizationMfaSettingsArgs = {
  input: UpdateOrganizationMfaSettingsInput;
  organizationSlugs: Array<Scalars['String']['input']>;
};


export type MutationOps_CancelMembershipInvitationsArgs = {
  membershipIds: Array<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type MutationOps_ChangeIntegrationForOrganizationArgs = {
  integrationName: IntegrationName;
  organizationId: Scalars['String']['input'];
};


export type MutationOps_ChangeSuperAdminArgs = {
  isSuperAdmin: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationOps_ConfigureIntegrationForOrganizationArgs = {
  integrationName: IntegrationName;
  organizationId: Scalars['String']['input'];
};


export type MutationOps_CreateCandisApiClientArgs = {
  input: CandisApiClientRequest;
};


export type MutationOps_CreateEmailAccountForOrganizationArgs = {
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_CreateHoldingArgs = {
  name: Scalars['String']['input'];
};


export type MutationOps_CreateSnapshotArgs = {
  input?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationOps_DeactivateCardIssuerOrganizationForMigrationArgs = {
  input: OpsDeactivateCardIssuerOrganizationForMigrationInput;
};


export type MutationOps_DeactivateUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationOps_DisableEmailDigestArgs = {
  email: Scalars['String']['input'];
};


export type MutationOps_EnableEmailDigestArgs = {
  email: Scalars['String']['input'];
};


export type MutationOps_EnsureCardholderExistsArgs = {
  input: OpsEnsureCardholderExistsInput;
};


export type MutationOps_ExtendBookingAccountNumbersLengthArgs = {
  input: ExtendAccountNumbersLengthInput;
};


export type MutationOps_ExtendCreditorAccountNumbersLengthArgs = {
  input: ChangeCreditorAccountNumbersLengthInput;
};


export type MutationOps_FixNewInvoicesCounterArgs = {
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_ForceCheckEsDocumentsFromBeginningArgs = {
  magicWord: Scalars['String']['input'];
};


export type MutationOps_GenerateDocumentSnapshotsArgs = {
  documentIds: Array<Scalars['String']['input']>;
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_GenerateMultipleDocumentSnapshotsArgs = {
  batches: Array<OpsGenerateMultipleDocumentSnapshotsInput>;
};


export type MutationOps_IncreaseUserNextMfaWindowArgs = {
  email: Scalars['String']['input'];
};


export type MutationOps_InviteUserToOrganizationArgs = {
  input: OpsInviteUserInput;
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_PurgeOrganizationDataArgs = {
  input: PurgeOrganizationDataInput;
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_PurgeReimbursementDataArgs = {
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_ReduceAccountsPayableNumbersLengthArgs = {
  input: ChangeCreditorAccountNumbersLengthInput;
};


export type MutationOps_RemoveAuthenticatorDeviceArgs = {
  userId: Scalars['String']['input'];
};


export type MutationOps_RequestGoBdReportCsvArgs = {
  input: RequestGoBdReportCsvInput;
};


export type MutationOps_SyncParentDocumentsForOrganizationArgs = {
  ecmDocumentIds: Array<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixDocumentsConvertFloatToIntOnLayoutIdArgs = {
  documentIds: Array<Scalars['String']['input']>;
  magicWord: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixRewriteApprovedByFromApproversIdsArgs = {
  documentIds: Array<Scalars['String']['input']>;
  magicWord: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixesFillContactNameFromLinkedContactArgs = {
  documentIds: Array<Scalars['String']['input']>;
  magicWord: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixesFillDocumentNumberFromFileArgs = {
  documentIds: Array<Scalars['String']['input']>;
  magicWord: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixesFillResolvedValueFromSourceArgs = {
  documentsToFix: Array<OpsSynchronizationFixesFillResolvedValueFromSourceInput>;
  magicWord: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixesRewriteApprovedAtAndByFromActivityArgs = {
  documentIds: Array<Scalars['String']['input']>;
  magicWord: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixesRewriteMissingFieldsBookingCostCenterFromLinkedItemArgs = {
  documentIds: Array<Scalars['String']['input']>;
  magicWord: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_SynchronizationFixesRewriteRequestedAtAndRequesterFromActivityArgs = {
  documentIds: Array<Scalars['String']['input']>;
  magicWord: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_SynchronizePliantReceiptsForTransactionsArgs = {
  input: OpsSynchronizePliantReceiptsForTransactionsInput;
};


export type MutationOps_TransferOwnershipArgs = {
  input: OpsTransferOwnershipInput;
};


export type MutationOps_TriggerSyncParentDocumentsArgs = {
  organizationIds: Array<Scalars['String']['input']>;
};


export type MutationOps_UpdateContactRelationTypeArgs = {
  membershipIds: Array<Scalars['String']['input']>;
  relationshipType: ContactRelationshipType;
};


export type MutationOps_UpdateHoldingArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationOps_UpdateOrganizationMfaSettingsArgs = {
  input: UpdateOrganizationMfaSettingsInput;
  organizationSlug: Scalars['String']['input'];
};


export type MutationOps_UpdateOrganizationSsoConfigurationIdArgs = {
  organizationSlug: Scalars['String']['input'];
  ssoConfigurationId: Scalars['String']['input'];
};


export type MutationOps_UpdateSsoConfigurationArgs = {
  input: UpdateOrganizationSsoInput;
  ssoConfigurationId: Scalars['String']['input'];
};


export type MutationOps_UpdateUserSkipSsoLoginArgs = {
  skipSSOLogin: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationOps_ValidateAndRemoveDeletedDocumentsArgs = {
  onlyValidate?: InputMaybe<Scalars['Boolean']['input']>;
  organizationIds: Array<Scalars['String']['input']>;
};


export type MutationOrganizationContactInformationArgs = {
  input: OrganizationContactInformationInput;
};


export type MutationProcessDocumentArgs = {
  fileId: Scalars['String']['input'];
  transactionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationProcessEcmDocumentArgs = {
  input: ProcessEcmDocumentInput;
};


export type MutationProcessFileArgs = {
  input: ProcessFileInput;
};


export type MutationRegisterMobileDeviceArgs = {
  input: MobileDeviceRegistrationInput;
};


export type MutationRejectCardRequestArgs = {
  input: RejectCardRequestInput;
};


export type MutationRemoveAvatarArgs = {
  membershipId: Scalars['String']['input'];
};


export type MutationRemoveEcmDocumentArgs = {
  input: RemoveEcmDocumentInput;
};


export type MutationRemoveFileUploadByIdArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveOrganizationSsoConfigurationArgs = {
  organizationSlug: Scalars['String']['input'];
};


export type MutationRemoveRoleMembershipArgs = {
  input: RemoveRoleMembershipInput;
};


export type MutationRemoveUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationReplaceCardArgs = {
  input: CardIssuerReplaceCardInput;
};


export type MutationRequestCardLimitChangeArgs = {
  input: RequestCardLimitChangeInput;
};


export type MutationRequestChangeUserEmailArgs = {
  input: RequestChangeUserEmailInput;
};


export type MutationRequestOnboardingForCardIssuerOrganizationArgs = {
  input: CardIssuerRequestOrganizationOnboardingInput;
};


export type MutationRequestPhoneNumberVerificationArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationRequestPhoneNumberVerificationProtectedArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationRequestPhysicalCardIssuerCardArgs = {
  input: RequestPhysicalCardInput;
};


export type MutationRequestReimbursementCaseAmendmentArgs = {
  input: RequestReimbursementCaseAmendmentInput;
};


export type MutationRequestSingleUseVirtualCardIssuerCardArgs = {
  input: RequestSingleUseVirtualCardInput;
};


export type MutationRequestVirtualCardIssuerCardArgs = {
  input: RequestVirtualCardInput;
};


export type MutationResendChangeEmailRequestVerificationEmailArgs = {
  id: Scalars['String']['input'];
};


export type MutationResendInvitationArgs = {
  membershipId: Scalars['String']['input'];
  organizationSlug?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetReimbursementCaseApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreWorkflowTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRevokeCandisApiSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationSavePromoPreferenceArgs = {
  input: SavePromoPreferenceInput;
};


export type MutationSaveTablePreferencesArgs = {
  input: SaveTablePreferenceInput;
};


export type MutationSendEmailArgs = {
  emailPayload: SendEmailWithoutTemplateArgs;
};


export type MutationSendEmailToSupplierArgs = {
  input: SendEmailInput;
};


export type MutationSendMobileAppInvitationEmailArgs = {
  input: SendMobileAppInvitationEmailInput;
};


export type MutationSetAbsenceForMemberArgs = {
  input: SetAbsenceForTeamMemberInput;
};


export type MutationSetAccountsPayableNumberForContactArgs = {
  accountsPayableNumber: Scalars['AccountsPayableNumber']['input'];
  id: Scalars['ID']['input'];
};


export type MutationSetConsentForTrackingArgs = {
  trackingEnabled: Scalars['Boolean']['input'];
};


export type MutationSetConsentForTrackingConfigurationArgs = {
  intercom?: InputMaybe<Scalars['Boolean']['input']>;
  trackingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetCurrentUserAbsenceArgs = {
  input: SetCurrentUserAbsenceInput;
};


export type MutationSetIbanAsOrgDefaultArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetNextFreeAccountsPayableNumberForContactArgs = {
  accountsPayableNumber?: InputMaybe<Scalars['AccountsPayableNumber']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationSetOrganizationMembershipsStatusArgs = {
  input: SetOrganizationMembershipsStatusInput;
};


export type MutationSetRecurringPaymentVendorIgnoredStateArgs = {
  input: SetRecurringPaymentVendorIgnoredStateInput;
};


export type MutationSetRoleIsArchivedArgs = {
  id: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
};


export type MutationSetTeamIsArchivedArgs = {
  id: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
};


export type MutationSetTeamSettingsForCreditCardsArgs = {
  input: SetTeamSettingsForCreditCardsInput;
};


export type MutationSetTransactionInvoiceNeededArgs = {
  input: SetTransactionInvoiceNeededInput;
};


export type MutationSetTransactionInvoiceNotNeededArgs = {
  input: SetTransactionInvoiceNotNeededInput;
};


export type MutationSoftDeleteFileUploadByFileIdArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationStoreEcmDocumentArgs = {
  input: StoreEcmDocumentInput;
};


export type MutationStoreSensitiveEcmDocumentArgs = {
  input: StoreSensitiveEcmDocumentInput;
};


export type MutationSubmitReimbursementCaseForApprovalArgs = {
  input: SubmitReimbursementCaseForApprovalInput;
};


export type MutationSubmitReimbursementCaseForReviewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSubmitReimbursementCaseWorkflowStepArgs = {
  input: SubmitReimbursementCaseWorkflowStepInput;
};


export type MutationTerminateCardArgs = {
  input: CardIssuerTerminateCardInput;
};


export type MutationToggleInAppNotificationsArgs = {
  dateRangeFilters?: InputMaybe<InAppNotificationDateFilterInput>;
  filters?: InputMaybe<InAppNotificationFilterInput>;
  input: ToggleInAppNotificationsInput;
};


export type MutationTrackEventArgs = {
  input: TrackEventInput;
};


export type MutationTransferOwnershipArgs = {
  input: TransferOwnershipInput;
};


export type MutationUnarchivePaymentConditionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnlinkTransactionFromDocumentArgs = {
  documentId: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};


export type MutationUnsetAccountsPayableNumberForContactArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnsubscribeInsightsWidgetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateAccountingAreaArgs = {
  input: UpdateAccountingAreaInput;
};


export type MutationUpdateAccountsPayableNumberForEmployeeContactArgs = {
  accountsPayableNumber?: InputMaybe<Scalars['AccountsPayableNumber']['input']>;
  membershipId: Scalars['ID']['input'];
};


export type MutationUpdateAvatarArgs = {
  id: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationUpdateBookingAccountArgs = {
  input: UpdateBookingAccountInput;
};


export type MutationUpdateBookingKeyArgs = {
  input: UpdateBookingKeyInput;
};


export type MutationUpdateCardAccountingDataArgs = {
  input: UpdateCardAccountingDataInput;
};


export type MutationUpdateCardForRecurringPaymentVendorArgs = {
  input: UpdateCardForRecurringPaymentVendorInput;
};


export type MutationUpdateCardIssuerOrganizationSettingsArgs = {
  input: UpdateCardIssuerOrganizationSettingsInput;
};


export type MutationUpdateCardLabelArgs = {
  input: UpdateCardLabelInput;
};


export type MutationUpdateCardPurposeArgs = {
  input: UpdateCardPurposeInput;
};


export type MutationUpdateCardSettingsDataArgs = {
  input: UpdateCardSettingsDataInput;
};


export type MutationUpdateChartOfAccountArgs = {
  chartOfAccountInput: ChartOfAccountInput;
};


export type MutationUpdateContactArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContactInput;
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContractSubCategoryArgs = {
  input: UpdateContractSubCategoryInput;
};


export type MutationUpdateContractSubCategoryStatusArgs = {
  input: UpdateContractSubCategoryStatusInput;
};


export type MutationUpdateCostCenterStatusArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCostCenterInput;
};


export type MutationUpdateCostCenter_NewArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCostCenterInput;
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCreditCardsLedgerArgs = {
  creditCardsLedger: CreditCardsLedgerInput;
};


export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput;
};


export type MutationUpdateCustomRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateDatevBdsPreRequisitesArgs = {
  datevBDSPreRequisites: Array<DatevBdsPreRequisitesInput>;
};


export type MutationUpdateDatevClientCashLedgerConfiguredArgs = {
  cashLedgerConfigured: Scalars['Boolean']['input'];
};


export type MutationUpdateDatevCreditCardsLedgerArgs = {
  creditCardsLedger: CreditCardsLedgerInput;
};


export type MutationUpdateDatevProvisionsLedgerArgs = {
  provisionsLedger: ProvisionsLedgerInput;
};


export type MutationUpdateDocumentDataExtractionSettingsArgs = {
  settings: UpdateDocumentDataExtractionSettingsInput;
};


export type MutationUpdateDocumentTagsArgs = {
  input: UpdateDocumentTagsInput;
};


export type MutationUpdateDocumentTypeBookingAccountArgs = {
  bookingAccount?: InputMaybe<Scalars['String']['input']>;
  documentTypeId: DocumentTypeId;
};


export type MutationUpdateEcmDocumentArgs = {
  input: UpdateEcmDocumentInput;
};


export type MutationUpdateEcmDocumentTypePreferencesArgs = {
  input: UpdateEcmDocumentTypeSettingsInput;
};


export type MutationUpdateExportConfigurationArgs = {
  datevSettings?: InputMaybe<DatevSettingsInput>;
};


export type MutationUpdateExportConfigurationNewArgs = {
  input: ExportConfigurationInput;
};


export type MutationUpdateFileUploadArgs = {
  file?: InputMaybe<FileInput>;
};


export type MutationUpdateGeneralExpenseItemArgs = {
  id: Scalars['ID']['input'];
  input: GeneralExpenseItemInput;
};


export type MutationUpdateHospitalityExpenseItemArgs = {
  id: Scalars['ID']['input'];
  input: HospitalityExpenseItemInput;
};


export type MutationUpdateIbanArgs = {
  id: Scalars['ID']['input'];
  input: UpdateIbanInput;
};


export type MutationUpdateIdentityProviderArgs = {
  input: IdentityProviderInput;
  ssoConfigurationId: Scalars['String']['input'];
};


export type MutationUpdateInsightsPreferencesArgs = {
  input: InsightsPreferencesInput;
};


export type MutationUpdateInsightsWidgetArgs = {
  id: Scalars['ID']['input'];
  input: InsightsWidgetInput;
};


export type MutationUpdateInsightsWidgetPreferencesArgs = {
  id: Scalars['ID']['input'];
  input: InsightsWidgetPreferenceInput;
};


export type MutationUpdateMembershipArgs = {
  id: Scalars['String']['input'];
  input: UpdateUserInput;
};


export type MutationUpdateMileageReimbursementItemArgs = {
  id: Scalars['ID']['input'];
  input: MileageReimbursementItemInput;
};


export type MutationUpdateOrganizationArgs = {
  input?: InputMaybe<UpdateOrganizationsInput>;
  realmName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOrganizationSsoConfigurationArgs = {
  organizationSlug: Scalars['String']['input'];
  ssoConfigurationId: Scalars['String']['input'];
};


export type MutationUpdatePaymentConditionArgs = {
  input: UpdatePaymentConditionInput;
};


export type MutationUpdatePaymentDataArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePaymentDataInput;
};


export type MutationUpdatePaymentStartDateArgs = {
  startDate: Scalars['Date']['input'];
};


export type MutationUpdatePerDiemItemArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePerDiemItemInput;
};


export type MutationUpdateReimbursementCaseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateReimbursementCaseInput;
};


export type MutationUpdateSsoConfigurationArgs = {
  input: UpdateSsoConfigurationInput;
  ssoConfigurationId: Scalars['String']['input'];
};


export type MutationUpdateSapB1CredentialsArgs = {
  sapB1Credentials: SapB1CredentialsInput;
};


export type MutationUpdateSapB1SettingsArgs = {
  sapB1Settings: SapB1SettingsInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTagStatusArgs = {
  input: UpdateTagStatusInput;
};


export type MutationUpdateTeamArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTeamBodyInput;
};


export type MutationUpdateUserPaymentInfoArgs = {
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateWaitingForClarificationArgs = {
  id: Scalars['ID']['input'];
  isWaiting: Scalars['Boolean']['input'];
};


export type MutationUpdateWorkflowTemplateArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWorkflowTemplateInput;
};


export type MutationUploadDocumentArgs = {
  options: UploadOptions;
};


export type MutationVerifyAuthenticatorDeviceArgs = {
  deviceId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};


export type MutationVerifyPhoneNumberArgs = {
  otpCode: Scalars['String']['input'];
};

export enum NameIdPolicyFormat {
  EmailAddress = 'EmailAddress',
  Entity = 'Entity',
  Kerberos = 'Kerberos',
  Persistent = 'Persistent',
  Transient = 'Transient',
  Unspecified = 'Unspecified',
  WindowsDomainQualifiedName = 'WindowsDomainQualifiedName',
  X509SubjectName = 'X509SubjectName'
}

export type NewExportZipResponse = {
  __typename?: 'NewExportZIPResponse';
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type NotCurrentWorkflowStepError = DocumentApprovalError & {
  __typename?: 'NotCurrentWorkflowStepError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  data: NotificationData;
  id: Scalars['ID']['output'];
};

export type NotificationAmount = {
  __typename?: 'NotificationAmount';
  currencyCode: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type NotificationData = EmailImportErrorNotification | UploadFileItem;

export type NotificationsResponse = {
  __typename?: 'NotificationsResponse';
  records: Array<Notification>;
};

export type OpsEntityChangeLog = {
  __typename?: 'OPSEntityChangeLog';
  actionPerformedAt?: Maybe<Scalars['Date']['output']>;
  actionPerformedBy?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<Scalars['String']['output']>;
  changeEvent?: Maybe<Scalars['String']['output']>;
  changedValues?: Maybe<Array<ChangedValue>>;
  contextId?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  operationName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
};

export type ObtainSlackTokenInput = {
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export enum OnboardingContactSalutation {
  Mr = 'MR',
  Mrs = 'MRS'
}

export type OpsAddExportMetadataResult = {
  __typename?: 'OpsAddExportMetadataResult';
  failed: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsBulkDeleteDocumentsInput = {
  documentIds: Array<Scalars['String']['input']>;
};

export type OpsBulkDeleteDocumentsResult = {
  __typename?: 'OpsBulkDeleteDocumentsResult';
  deletedCount: Scalars['Int']['output'];
  invalidDocumentIds: Array<Scalars['String']['output']>;
};

export type OpsBulkLinkContactsToMembershipsInput = {
  records: Array<OpsBulkLinkContactsToMembershipsInputRecord>;
};

export type OpsBulkLinkContactsToMembershipsInputRecord = {
  contactAPN: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  membershipEmail: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type OpsBulkLinkContactsToMembershipsOptionsInput = {
  shouldExcludeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsBulkLinkContactsToMembershipsOutput = {
  __typename?: 'OpsBulkLinkContactsToMembershipsOutput';
  records: Array<OpsBulkLinkContactsToMembershipsOutputRow>;
};

export type OpsBulkLinkContactsToMembershipsOutputRow = {
  __typename?: 'OpsBulkLinkContactsToMembershipsOutputRow';
  contactAPN: Scalars['String']['output'];
  contactId?: Maybe<Scalars['String']['output']>;
  contactName: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  membershipEmail: Scalars['String']['output'];
  membershipId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type OpsCheckIfCardIssuerOrganizationIsReadyForMigrationInput = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type OpsConfigureIntegrationResponse = {
  __typename?: 'OpsConfigureIntegrationResponse';
  integrationName: IntegrationName;
  organizationId: Scalars['String']['output'];
};

export type OpsDatevPermissionInfo = {
  __typename?: 'OpsDatevPermissionInfo';
  isBDSAvailable: Scalars['Boolean']['output'];
  isRDSAvailable: Scalars['Boolean']['output'];
};

export type OpsDeactivateCardIssuerOrganizationForMigrationDetails = {
  __typename?: 'OpsDeactivateCardIssuerOrganizationForMigrationDetails';
  latestMigratedOrgId?: Maybe<Scalars['String']['output']>;
  newOrgIdInPliant: Scalars['String']['output'];
  oldOrgIdInPliant: Scalars['String']['output'];
};

export type OpsDeactivateCardIssuerOrganizationForMigrationInput = {
  organizationId: Scalars['String']['input'];
  testRun: Scalars['Boolean']['input'];
};

export type OpsDeactivateCardIssuerOrganizationForMigrationResponse = {
  __typename?: 'OpsDeactivateCardIssuerOrganizationForMigrationResponse';
  details?: Maybe<OpsDeactivateCardIssuerOrganizationForMigrationDetails>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsEnsureCardholderExistsInput = {
  membershipId: Scalars['String']['input'];
};

export type OpsEnsureCardholderExistsOutput = {
  __typename?: 'OpsEnsureCardholderExistsOutput';
  success: Scalars['Boolean']['output'];
};

export type OpsFindDuplicateContactsInput = {
  field: ContactDuplicateBaseField;
  holdingId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type OpsFixNewInvoicesCounterResult = {
  __typename?: 'OpsFixNewInvoicesCounterResult';
  fixedProblems: Scalars['Int']['output'];
};

export type OpsForceCheckEsDocumentsFromBeginningResult = {
  __typename?: 'OpsForceCheckEsDocumentsFromBeginningResult';
  success: Scalars['Boolean']['output'];
};

export type OpsGenerateDocumentSnapshotsResults = {
  __typename?: 'OpsGenerateDocumentSnapshotsResults';
  failed: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsGenerateMultipleDocumentSnapshotsInput = {
  documentIds: Array<Scalars['String']['input']>;
  organizationSlug: Scalars['String']['input'];
};

export type OpsGenerateMultipleDocumentSnapshotsResult = {
  __typename?: 'OpsGenerateMultipleDocumentSnapshotsResult';
  failed: Array<Scalars['String']['output']>;
  organizationSlug: Scalars['String']['output'];
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsInviteUserInput = {
  email: Scalars['EmailAddress']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Locale>;
  roles: Array<UserRole>;
};

export type OpsSynchronizationFixDocumentsConvertFloatToIntOnLayoutIdResult = {
  __typename?: 'OpsSynchronizationFixDocumentsConvertFloatToIntOnLayoutIdResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizationFixRewriteApprovedByFromApproversIdsResult = {
  __typename?: 'OpsSynchronizationFixRewriteApprovedByFromApproversIdsResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizationFixesFillContactNameFromLinkedContactResult = {
  __typename?: 'OpsSynchronizationFixesFillContactNameFromLinkedContactResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizationFixesFillDocumentNumberFromFileResult = {
  __typename?: 'OpsSynchronizationFixesFillDocumentNumberFromFileResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizationFixesFillResolvedValueFromSourceInput = {
  documentId: Scalars['String']['input'];
  propertyPath: Scalars['String']['input'];
  sourceName: Scalars['String']['input'];
};

export type OpsSynchronizationFixesFillResolvedValueFromSourceResult = {
  __typename?: 'OpsSynchronizationFixesFillResolvedValueFromSourceResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizationFixesRewriteApprovedAtAndByFromActivityResult = {
  __typename?: 'OpsSynchronizationFixesRewriteApprovedAtAndByFromActivityResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizationFixesRewriteMissingFieldsBookingCostCenterFromLinkedItemResult = {
  __typename?: 'OpsSynchronizationFixesRewriteMissingFieldsBookingCostCenterFromLinkedItemResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizationFixesRewriteRequestedAtAndRequesterFromActivityResult = {
  __typename?: 'OpsSynchronizationFixesRewriteRequestedAtAndRequesterFromActivityResult';
  failed: Array<Scalars['String']['output']>;
  notChanged: Array<Scalars['String']['output']>;
  notFound: Array<Scalars['String']['output']>;
  succeeded: Array<Scalars['String']['output']>;
};

export type OpsSynchronizePliantReceiptsForTransactionsInput = {
  transactionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OpsSynchronizePliantReceiptsForTransactionsOutput = {
  __typename?: 'OpsSynchronizePliantReceiptsForTransactionsOutput';
  success: Scalars['Boolean']['output'];
};

export type OpsTransferOwnershipInput = {
  email: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** A company using Candis (a customer) */
export type Organization = {
  __typename?: 'Organization';
  /** Subscription as returned by Chargebee */
  billingSubscription?: Maybe<ChargebeeSubscription>;
  /** Chargebee ID */
  chargebeeSubscriptionId?: Maybe<Scalars['String']['output']>;
  creditCardsDetails?: Maybe<OrganizationCreditCardsDetails>;
  creditCardsSettings?: Maybe<OrganizationCreditCardsSettings>;
  documentCounts?: Maybe<OrganizationDocumentCounts>;
  emailAccounts?: Maybe<OrganizationEmailAccountsResponse>;
  /** export configuration: This returns null and throws an error if the role of the current user is not ADMIN. */
  exportConfiguration?: Maybe<ExportConfiguration>;
  hasActiveBookingAccounts?: Maybe<Scalars['Boolean']['output']>;
  hasActiveBookingKeys?: Maybe<Scalars['Boolean']['output']>;
  hasActiveCreditCards?: Maybe<Scalars['Boolean']['output']>;
  hasActiveWorkflowTemplates?: Maybe<Scalars['Boolean']['output']>;
  hasContacts?: Maybe<Scalars['Boolean']['output']>;
  hasCostCenters?: Maybe<Scalars['Boolean']['output']>;
  hasCostObjects?: Maybe<Scalars['Boolean']['output']>;
  hasExtraCostInfos?: Maybe<Scalars['Boolean']['output']>;
  /** Indicate if an organization has SSO configured */
  hasIdpSSO?: Maybe<Scalars['Boolean']['output']>;
  /** Indicate if an organization has MFA enabled */
  hasMFA?: Maybe<Scalars['Boolean']['output']>;
  hasTransactionsActivated?: Maybe<Scalars['Boolean']['output']>;
  /** Indicate id of holding org if it exists */
  holdingId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Identity provider configuration for an organization */
  idpConfig?: Maybe<OrganizationIdpConfig>;
  integrationName?: Maybe<IntegrationName>;
  /** Indicate if an organization is a demo account */
  isTest?: Maybe<Scalars['Boolean']['output']>;
  locale?: Maybe<Locale>;
  /** Indicate the channels preferred by the organization for MFA */
  mfaPreferredChannels?: Maybe<Array<Maybe<OrganizationMfaChannels>>>;
  name: Scalars['String']['output'];
  /**
   * Indicate contact information about this specific organization.
   * For example org VatId and Tax number. Can be expandded to store more info about org
   */
  organizationContactInformation?: Maybe<OrganizationContactInformation>;
  /** payment activation */
  payment: OrganizationPayment;
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Same than id the Organization is referred to as the organizationId.
   *
   * It is called realmName due to legacy reasons
   * @deprecated Use id instead
   */
  realmName: Scalars['String']['output'];
  /** Salesforce account id associated with this Organization */
  salesforceAccountId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  ssoConfigurationId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationContactInformation = {
  __typename?: 'OrganizationContactInformation';
  taxNumber?: Maybe<Scalars['String']['output']>;
  vatId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationContactInformationError = InvalidTaxNumber | InvalidVatId;

export type OrganizationContactInformationErrors = {
  __typename?: 'OrganizationContactInformationErrors';
  errors: Array<OrganizationContactInformationError>;
};

export type OrganizationContactInformationInput = {
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationContactInformationResult = OrganizationContactInformation | OrganizationContactInformationErrors;

export enum OrganizationCreditCardsAccountType {
  Credit = 'CREDIT',
  Prefunded = 'PREFUNDED'
}

export type OrganizationCreditCardsAddress = {
  __typename?: 'OrganizationCreditCardsAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  streetAdditional?: Maybe<Scalars['String']['output']>;
  streetFull: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  streetNumber: Scalars['String']['output'];
};

export type OrganizationCreditCardsDetails = {
  __typename?: 'OrganizationCreditCardsDetails';
  availableLimit: TransactionAmount;
  balance: TransactionAmount;
  fxFeePercentage?: Maybe<Scalars['Float']['output']>;
  paymentFrequency: PaymentFrequencyType;
  totalLimit: TransactionAmount;
};

export type OrganizationCreditCardsProcessingAccount = {
  __typename?: 'OrganizationCreditCardsProcessingAccount';
  bankName?: Maybe<Scalars['String']['output']>;
  beneficiary: Scalars['String']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
};

export type OrganizationCreditCardsSettings = {
  __typename?: 'OrganizationCreditCardsSettings';
  accountType: OrganizationCreditCardsAccountType;
  billingAddress: OrganizationCreditCardsAddress;
  billingEmail?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  deliveryAddress?: Maybe<OrganizationCreditCardsAddress>;
  deliveryAddressSameAsBillingAddress: Scalars['Boolean']['output'];
  foundationDate?: Maybe<Scalars['Date']['output']>;
  isCompanyNamePrintable: Scalars['Boolean']['output'];
  legalForm: Scalars['String']['output'];
  nationalRegisterCourt: Scalars['String']['output'];
  nationalRegisterId?: Maybe<Scalars['String']['output']>;
  nationalRegisterType?: Maybe<Scalars['String']['output']>;
  processingAccount: OrganizationCreditCardsProcessingAccount;
  tradeName?: Maybe<Scalars['String']['output']>;
  tradeRegisterNumber: Scalars['String']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationDocumentCounts = {
  __typename?: 'OrganizationDocumentCounts';
  approved: Scalars['Int']['output'];
  errorCode?: Maybe<OrganizationOverviewErrors>;
  exported: Scalars['Int']['output'];
  new: Scalars['Int']['output'];
  open: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
};

export type OrganizationEmailAccount = {
  __typename?: 'OrganizationEmailAccount';
  address?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationEmailAccountsResponse = {
  __typename?: 'OrganizationEmailAccountsResponse';
  cursor?: Maybe<Cursor>;
  records?: Maybe<Array<Maybe<OrganizationEmailAccount>>>;
};

export type OrganizationIdpConfig = {
  __typename?: 'OrganizationIdpConfig';
  /** Indicate if a new user can register automatically just by login with this IDP. */
  canUserRegisterViaSSO: Scalars['Boolean']['output'];
  /** Emails domains that belongs to this organization, used for automatic IDP detection. */
  emailDomains: Array<Scalars['String']['output']>;
  /** Configured identity provider alias, same alias defined in keycloak */
  idpProvider: Scalars['String']['output'];
  /** Indicate if user roles are imported from SSO idp or manage in candis */
  isMembershipRolesUpdatedViaSSO: Scalars['Boolean']['output'];
  /** Indicate if user data(name,lastname) is imported from SSO idp or manage in candis */
  isUserDataUpdatedViaSSO: Scalars['Boolean']['output'];
};

export enum OrganizationMfaChannels {
  Email = 'email',
  Sms = 'sms'
}

export type OrganizationMembershipCountByStatus = {
  __typename?: 'OrganizationMembershipCountByStatus';
  active: Scalars['Int']['output'];
  inActive: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OrganizationMembershipsFiltersInput = {
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  hasMobileLogin?: InputMaybe<Scalars['Boolean']['input']>;
  mobileLoginDateFilter?: InputMaybe<DateFilterInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  roleNames?: InputMaybe<Array<Scalars['String']['input']>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MembershipStatus>;
  statuses?: InputMaybe<Array<MembershipStatus>>;
};

export type OrganizationMembershipsPageBasedInput = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type OrganizationMembershipsWithPagination = {
  __typename?: 'OrganizationMembershipsWithPagination';
  pageInfo: SimplePageInfoDetails;
  records: Array<User>;
};

export enum OrganizationOverviewErrors {
  NotAllowed = 'NOT_ALLOWED'
}

export type OrganizationPayment = {
  __typename?: 'OrganizationPayment';
  isActive: Scalars['Boolean']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type OrganizationsInsightsDetails = {
  __typename?: 'OrganizationsInsightsDetails';
  membership: User;
  monitoringMyRequests?: Maybe<MonitoringMyRequests>;
  organization: Organization;
  reimbursementCases?: Maybe<ReimbursementCasesInsight>;
  summary: TasksCountsInsight;
  toActivateCards?: Maybe<TasksCountsInsight>;
  toApprove?: Maybe<TasksCountsInsight>;
  toExport?: Maybe<TasksCountsInsight>;
  toProcess?: Maybe<TasksCountsInsight>;
  toUploadInvoicesForTransactions?: Maybe<TasksCountsInsight>;
};

export type PageBasedMembershipTeamsCapabilitiesForCreditCardsList = {
  __typename?: 'PageBasedMembershipTeamsCapabilitiesForCreditCardsList';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records: Array<MembershipTeamsCapabilitiesForCreditCards>;
};

export type PageBasedPermissionsList = {
  __typename?: 'PageBasedPermissionsList';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records: Array<Permission>;
};

export type PageBasedRolesList = {
  __typename?: 'PageBasedRolesList';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records: Array<Role>;
};

export type PageBasedTeamList = {
  __typename?: 'PageBasedTeamList';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records: Array<Team>;
};

export type PageBasedTransactionDownloadHistoryList = {
  __typename?: 'PageBasedTransactionDownloadHistoryList';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records: Array<TransactionDownloadHistory>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Only forwared pagination is supported. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Only forwared pagination is supported. */
  startCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PaginationInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<FilterInput>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SortInput>>;
};

export type PaidDocument = {
  __typename?: 'PaidDocument';
  amount: Money;
  creditor: CreditorContact;
  creditorIban: Scalars['IBAN']['output'];
  creditorSwiftCode?: Maybe<Scalars['String']['output']>;
  currency: DocumentCurrency;
  discountAmount?: Maybe<Money>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  documentId: Scalars['ID']['output'];
  invoiceDate: Scalars['Date']['output'];
  invoiceDueDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
};

export type PaidDocumentState = {
  __typename?: 'PaidDocumentState';
  isPaid: Scalars['Boolean']['output'];
  method: PaymentMethod;
  paidAt: Scalars['Date']['output'];
};

export type PayableAmounts = {
  __typename?: 'PayableAmounts';
  discountedAmount?: Maybe<Money>;
  totalPayableAmount?: Maybe<Money>;
};

/**
 * A Document that could be potentially paid. See concrete implementations:
 * SepaExportableDocument and DocumentWithIncompletePaymentData
 *
 * TODO: this type should inherit from a DocumentBase interface
 */
export type PayableDocument = {
  amount: Money;
  contact: Contact;
  currency: DocumentCurrency;
  /** Discount fields */
  discountAmount?: Maybe<Money>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** The date displayed on the invoice */
  invoiceDate: Scalars['Date']['output'];
  invoiceDueDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  status: DocumentStatus;
};

export type PayableDocumentBooking = {
  __typename?: 'PayableDocumentBooking';
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  paymentConditionId?: Maybe<Scalars['String']['output']>;
};

export type PayableDocumentConnection = {
  __typename?: 'PayableDocumentConnection';
  edges?: Maybe<Array<PayableDocumentEdge>>;
  node?: Maybe<Array<PayableDocument>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PayableDocumentContact = {
  __typename?: 'PayableDocumentContact';
  accountsPayableNumber?: Maybe<Scalars['String']['output']>;
  accountsReceivableNumber?: Maybe<Scalars['String']['output']>;
  cachingKey: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PayableDocumentEdge = {
  __typename?: 'PayableDocumentEdge';
  cursor: Scalars['String']['output'];
  node: PayableDocument;
};

export type PayableDocumentEs = {
  __typename?: 'PayableDocumentEs';
  amount?: Maybe<Money>;
  bookings?: Maybe<Array<Maybe<PayableDocumentBooking>>>;
  contact?: Maybe<PayableDocumentContact>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  discountAmount?: Maybe<Money>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  globalDocumentId: Scalars['String']['output'];
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['Date']['output']>;
  invoiceDueDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  isPayable?: Maybe<Scalars['Boolean']['output']>;
  isSEPAExportable: Scalars['Boolean']['output'];
  status: DocumentStatus;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PayableDocumentEsEdge = {
  __typename?: 'PayableDocumentEsEdge';
  cursor: Scalars['String']['output'];
  node: PayableDocumentEs;
};

export type PayableDocumentsEsResponse = {
  __typename?: 'PayableDocumentsEsResponse';
  edges: Array<PayableDocumentEsEdge>;
  pageInfo: PageInfo;
};

export type PayableDocumentsListResponse = {
  __typename?: 'PayableDocumentsListResponse';
  documents: Array<PayableDocument>;
};

/**
 * Because we do not store BIC/SWIFT codes we cannot accept IBANs
 * from the UK. Therefore we separately return the count for each of them.
 */
export type PayableDocumentsResponse = {
  __typename?: 'PayableDocumentsResponse';
  euValidIbanDocumentsCount: Scalars['Int']['output'];
  gbValidIbanDocumentsCount?: Maybe<Scalars['Int']['output']>;
};

export type PayableDocumentsSortInput = {
  amount?: InputMaybe<PayableDocumentsSortOrder>;
  contact?: InputMaybe<PayableDocumentsSortOrder>;
  dueDateWithCashDiscount?: InputMaybe<PayableDocumentsSortOrder>;
  iban?: InputMaybe<PayableDocumentsSortOrder>;
  invoiceDueDate?: InputMaybe<PayableDocumentsSortOrder>;
  invoiceNumber?: InputMaybe<PayableDocumentsSortOrder>;
  status?: InputMaybe<PayableDocumentsSortOrder>;
};

export enum PayableDocumentsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PayeeDoesNotExistError = DocumentApprovalError & {
  __typename?: 'PayeeDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Payment = SepaTransferPayment;

export type PaymentCondition = {
  __typename?: 'PaymentCondition';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  discountOffset?: Maybe<Scalars['Int']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDateOffset: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  paymentConditionName?: Maybe<Scalars['String']['output']>;
  paymentConditionNumber: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<User>;
};

export type PaymentConditionCommandResult = PaymentCondition | PaymentConditionError;

export type PaymentConditionError = {
  __typename?: 'PaymentConditionError';
  discountOffset?: Maybe<Scalars['Int']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  dueDateOffset?: Maybe<Scalars['Int']['output']>;
  kind: PaymentConditionErrorKind;
  message?: Maybe<Scalars['String']['output']>;
  paymentConditionName?: Maybe<Scalars['String']['output']>;
  paymentConditionNumber?: Maybe<Scalars['Int']['output']>;
};

export enum PaymentConditionErrorKind {
  Duplicate = 'DUPLICATE',
  Forbidden = 'FORBIDDEN',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN',
  ValidationError = 'VALIDATION_ERROR'
}

export type PaymentConditionGetResult = PaymentCondition | PaymentConditionError;

export type PaymentConditionImportResult = {
  __typename?: 'PaymentConditionImportResult';
  newValue: PaymentConditionCommandResult;
  oldValue?: Maybe<PaymentCondition>;
};

export type PaymentConditionsExport = {
  __typename?: 'PaymentConditionsExport';
  file?: Maybe<PaymentConditionsExportFile>;
};

export type PaymentConditionsExportError = {
  __typename?: 'PaymentConditionsExportError';
  kind: PaymentConditionsExportErrorKind;
  message?: Maybe<Scalars['String']['output']>;
};

export enum PaymentConditionsExportErrorKind {
  EmptyPaymentConditions = 'EMPTY_PAYMENT_CONDITIONS',
  Forbidden = 'FORBIDDEN',
  Unknown = 'UNKNOWN'
}

export type PaymentConditionsExportFile = {
  __typename?: 'PaymentConditionsExportFile';
  url?: Maybe<Scalars['String']['output']>;
};

export type PaymentConditionsExportResult = PaymentConditionsExport | PaymentConditionsExportError;

export type PaymentConditionsFilter = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentConditionsImportErrorFile = {
  __typename?: 'PaymentConditionsImportErrorFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type PaymentConditionsImportFile = {
  __typename?: 'PaymentConditionsImportFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type PaymentConditionsImportHistory = {
  __typename?: 'PaymentConditionsImportHistory';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  errorFile?: Maybe<PaymentConditionsImportErrorFile>;
  file?: Maybe<PaymentConditionsImportFile>;
  id: Scalars['ID']['output'];
  importedConditions?: Maybe<Array<Scalars['String']['output']>>;
  status: PaymentConditionsImportStatus;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum PaymentConditionsImportStatus {
  Failed = 'FAILED',
  Processed = 'PROCESSED',
  Started = 'STARTED'
}

export enum PaymentFrequencyType {
  BiWeekly = 'BI_WEEKLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Prefund = 'PREFUND',
  Prefunded = 'PREFUNDED',
  Weekly = 'WEEKLY'
}

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
};

/** Payment method used to mark a document as paid */
export enum PaymentMethod {
  CardSettlementPayment = 'CARD_SETTLEMENT_PAYMENT',
  CardTransaction = 'CARD_TRANSACTION',
  Manual = 'MANUAL',
  SepaTransfer = 'SEPA_TRANSFER'
}

export type PaymentSettlementAmount = {
  __typename?: 'PaymentSettlementAmount';
  currency: Scalars['String']['output'];
  precision: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type PaymentSettlementConnection = {
  __typename?: 'PaymentSettlementConnection';
  edges?: Maybe<Array<CardIssuerPaymentSettlementEdge>>;
  node?: Maybe<Array<CardIssuerPaymentSettlement>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum PaymentSettlementStatus {
  Booked = 'BOOKED',
  Deferred = 'DEFERRED',
  Discarded = 'DISCARDED',
  Failed = 'FAILED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Planned = 'PLANNED',
  ReadyForPmt = 'READY_FOR_PMT',
  Reversed = 'REVERSED',
  Upcoming = 'UPCOMING'
}

export type PaymentStateValue = {
  __typename?: 'PaymentStateValue';
  isPaid: Scalars['Boolean']['output'];
  paidAt?: Maybe<Scalars['Date']['output']>;
};

export type PaymentsResponse = {
  __typename?: 'PaymentsResponse';
  pageInfo?: Maybe<PageInfo>;
  records: Array<Payment>;
};

export type PendingAuthenticatorDeviceVerification = {
  __typename?: 'PendingAuthenticatorDeviceVerification';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  secret: Scalars['String']['output'];
  secretQRDataUrl: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type PendingAuthenticatorDeviceVerificationRequiresRecentAuthn = PendingAuthenticatorDeviceVerification | RecentAuthnRequiredError;

export type PendingChangeMembershipEmail = {
  __typename?: 'PendingChangeMembershipEmail';
  newEmail: Scalars['String']['output'];
};

export type PendingPhoneNumberVerification = {
  __typename?: 'PendingPhoneNumberVerification';
  id: Scalars['ID']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type PendingPhoneNumberVerificationRequiresRecentAuthn = PendingPhoneNumberVerification | RecentAuthnRequiredError;

export type PerDiemDay = {
  __typename?: 'PerDiemDay';
  date: Scalars['Date']['output'];
  dayRate: Money;
  id: Scalars['String']['output'];
  isFullDay: Scalars['Boolean']['output'];
  location: PerDiemLocation;
  meals: PerDiemDayMeals;
  numberOfMinutes: Scalars['Int']['output'];
  /** @deprecated Use meals instead */
  providedBreakfast: Scalars['Boolean']['output'];
  /** @deprecated Use meals instead */
  providedDinner: Scalars['Boolean']['output'];
  /** @deprecated Use meals instead */
  providedLunch: Scalars['Boolean']['output'];
  reimbursableRate: Money;
  segmentId: Scalars['String']['output'];
};

export type PerDiemDayMeal = {
  __typename?: 'PerDiemDayMeal';
  deduction: Money;
  provided: Scalars['Boolean']['output'];
  rateRatio: Scalars['Float']['output'];
};

export type PerDiemDayMeals = {
  __typename?: 'PerDiemDayMeals';
  breakfast: PerDiemDayMeal;
  dinner: PerDiemDayMeal;
  lunch: PerDiemDayMeal;
};

export type PerDiemDaysInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  providedBreakfast?: InputMaybe<Scalars['Boolean']['input']>;
  providedDinner?: InputMaybe<Scalars['Boolean']['input']>;
  providedLunch?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PerDiemItem = CommonReimbursementItem & {
  __typename?: 'PerDiemItem';
  bookings: Array<ReimbursementBooking>;
  createdAt: Scalars['Date']['output'];
  currentStep: PerDiemStep;
  days: Array<PerDiemDay>;
  errors: Array<ReimbursementError>;
  expenseDate?: Maybe<Scalars['Date']['output']>;
  extractedData?: Maybe<Array<CommonReimbursementItemExtraction>>;
  extractedDataAccepted?: Maybe<Scalars['Boolean']['output']>;
  files?: Maybe<Array<File>>;
  id: Scalars['String']['output'];
  reimbursementCaseId: Scalars['String']['output'];
  segments?: Maybe<Array<PerDiemSegment>>;
  status: ReimbursementItemStatus;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Money>;
  type: ReimbursementItemType;
  updatedAt: Scalars['Date']['output'];
};

export type PerDiemLocation = {
  __typename?: 'PerDiemLocation';
  country: Scalars['String']['output'];
  countryIsoCode: Scalars['String']['output'];
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  region: Scalars['String']['output'];
  startAt: Scalars['Date']['output'];
};

export type PerDiemLocationResponse = {
  __typename?: 'PerDiemLocationResponse';
  locations: Array<PerDiemLocation>;
};

export type PerDiemLocationsInput = {
  endAt: Scalars['Date']['input'];
};

export type PerDiemMealInfo = {
  __typename?: 'PerDiemMealInfo';
  providedBreakfast?: Maybe<Scalars['Boolean']['output']>;
  providedDinner?: Maybe<Scalars['Boolean']['output']>;
  providedLunch?: Maybe<Scalars['Boolean']['output']>;
};

export type PerDiemSegment = {
  __typename?: 'PerDiemSegment';
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<PerDiemLocation>;
  startAt?: Maybe<Scalars['Date']['output']>;
};

export type PerDiemSegmentInput = {
  endAt?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['Date']['input']>;
};

export enum PerDiemStep {
  Days = 'DAYS',
  Segments = 'SEGMENTS'
}

export type PerDiemUpdatedDay = {
  __typename?: 'PerDiemUpdatedDay';
  date: Scalars['Date']['output'];
  dayId: Scalars['ID']['output'];
  new: PerDiemMealInfo;
  old: PerDiemMealInfo;
};

export type Permission = {
  __typename?: 'Permission';
  category: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type PermissionFilterInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

export type PermissionsForRole = {
  __typename?: 'PermissionsForRole';
  permissions: Array<Permission>;
};

export type PhysicalCardApprovedEvent = CardTimeLineItem & {
  __typename?: 'PhysicalCardApprovedEvent';
  approvedLimit: TransactionAmount;
  approvedTransactionLimit: TransactionAmount;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type PhysicalCardRequest = CardIssuerCardRequest & {
  __typename?: 'PhysicalCardRequest';
  approvedLimit?: Maybe<TransactionAmount>;
  approvedLimitRenewFrequency?: Maybe<CardLimitRenewFrequency>;
  approvedTransactionLimit?: Maybe<TransactionAmount>;
  category?: Maybe<CardCategory>;
  comment: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  requestedLimit?: Maybe<TransactionAmount>;
  requestedLimitRenewFrequency?: Maybe<CardLimitRenewFrequency>;
  requestedTransactionLimit: TransactionAmount;
  resolutionComment?: Maybe<Scalars['String']['output']>;
  status: CardRequestStatus;
  team?: Maybe<Team>;
  type: CardType;
  validityPeriod: Scalars['Int']['output'];
};

export type PhysicalCardRequestedEvent = CardTimeLineItem & {
  __typename?: 'PhysicalCardRequestedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  requestedLimit: TransactionAmount;
  requestedTransactionLimit: TransactionAmount;
  user: ActivityUser;
};

export type PliantReference = {
  __typename?: 'PliantReference';
  organizationId: Scalars['String']['output'];
};

export enum PrincipalType {
  AttributeFriendlyName = 'AttributeFriendlyName',
  AttributeName = 'AttributeName',
  SubjectNameId = 'SubjectNameId'
}

export type ProcessEcmDocumentInput = {
  documentType: DocumentType;
  fileId: Scalars['String']['input'];
  isSensitive: Scalars['Boolean']['input'];
};

export type ProcessFileInput = {
  fileId: Scalars['String']['input'];
};

export type ProcessFileResponse = {
  __typename?: 'ProcessFileResponse';
  globalDocumentId: Scalars['String']['output'];
};

export type ProjectCode = {
  __typename?: 'ProjectCode';
  code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  validFrom?: Maybe<Scalars['Date']['output']>;
  validTo?: Maybe<Scalars['Date']['output']>;
};

export type ProjectCodeArchivedError = DocumentApprovalError & {
  __typename?: 'ProjectCodeArchivedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
  projectCode: ProjectCode;
};

export type ProjectCodeDoesNotExistError = DocumentApprovalError & {
  __typename?: 'ProjectCodeDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ProjectCodeFailedEntity = FailedEntity & {
  __typename?: 'ProjectCodeFailedEntity';
  errorDetails?: Maybe<Array<ErrorDetails>>;
  rawEntity: ProjectCodeRawEntity;
};

export type ProjectCodeField = {
  __typename?: 'ProjectCodeField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: ProjectCode;
};

export type ProjectCodeFilterInput = {
  code?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Array<Scalars['String']['input']>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProjectCodePaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectCodePaginationResult = {
  __typename?: 'ProjectCodePaginationResult';
  pageInfo: SimplePageInfoDetails;
  records: Array<ProjectCode>;
};

export type ProjectCodeQueryInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  readableName?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCodeRawEntity = {
  __typename?: 'ProjectCodeRawEntity';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ProjectCodeSortField {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type ProjectCodeSortInput = {
  direction: SortDirection;
  field: ProjectCodeSortField;
};

export type PromoPreference = {
  __typename?: 'PromoPreference';
  promoId: Scalars['String']['output'];
  seenAt: Scalars['Date']['output'];
};

export type Provision = {
  __typename?: 'Provision';
  /** @deprecated Use account in Bookings */
  account: Scalars['String']['output'];
  bookings?: Maybe<Array<ProvisionBooking>>;
  contact: ProvisionContact;
  deliveryDate: Scalars['Date']['output'];
  entity: ProvisionEntity;
  /** @deprecated Use glAccounts in Bookings */
  glAccount: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceDate: Scalars['Date']['output'];
  netAmount: Money;
};

export type ProvisionBooking = {
  __typename?: 'ProvisionBooking';
  account: Scalars['String']['output'];
  glAccount: Scalars['String']['output'];
};

export type ProvisionConnection = {
  __typename?: 'ProvisionConnection';
  edges?: Maybe<Array<Provision>>;
  node?: Maybe<Array<Provision>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ProvisionContact = {
  __typename?: 'ProvisionContact';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Provision created event */
export type ProvisionCreatedEvent = DocumentTimeLineItem & {
  __typename?: 'ProvisionCreatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

/** Provision deleted event */
export type ProvisionDeletedEvent = DocumentTimeLineItem & {
  __typename?: 'ProvisionDeletedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

export type ProvisionEntity = {
  __typename?: 'ProvisionEntity';
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type: ProvisionEntityType;
};

export enum ProvisionEntityType {
  Document = 'DOCUMENT',
  Transaction = 'TRANSACTION'
}

/** Document Provision export event */
export type ProvisionExportedEvent = DocumentTimeLineItem & {
  __typename?: 'ProvisionExportedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Id of the related export entry */
  exportId?: Maybe<Scalars['String']['output']>;
  /** What kind of export this Event contains */
  exportType?: Maybe<ExportType>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that exported the document */
  user?: Maybe<ActivityUser>;
};

export type ProvisionFilterInput = {
  entityTypes: Array<ProvisionEntityType>;
  isExported: Scalars['Boolean']['input'];
};

export type ProvisionsLedger = {
  __typename?: 'ProvisionsLedger';
  otherAssetsAccountNumber?: Maybe<Scalars['String']['output']>;
  provisionAccountNumber?: Maybe<Scalars['String']['output']>;
  provisionsFilter?: Maybe<DatevProvisionsFilter>;
};

export type ProvisionsLedgerInput = {
  otherAssetsAccountNumber?: InputMaybe<Scalars['String']['input']>;
  provisionAccountNumber?: InputMaybe<Scalars['String']['input']>;
  provisionsFilter?: InputMaybe<DatevProvisionsFilter>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  _id: Scalars['String']['output'];
  accountsPayableNumber: Scalars['String']['output'];
  amount: Money;
  connectedGoodsReceipts?: Maybe<Array<ConnectedGoodsReceiptData>>;
  connectedInvoices?: Maybe<Array<ConnectedInvoicesData>>;
  contactName: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deliveryDate: Scalars['Date']['output'];
  goodsLineItems?: Maybe<Array<GoodsLineItem>>;
  goodsReceipts?: Maybe<Array<Maybe<GoodsReceipt>>>;
  invoiceAssociationStatus: PurchaseOrdersInvoiceAssociationStatus;
  orderNumber: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  postingDate: Scalars['Date']['output'];
  purchaseOrderType: PurchaseOrderType;
  remainingAmount?: Maybe<Money>;
  sapB1Data?: Maybe<SapB1Data>;
  serviceLineItems?: Maybe<Array<ServiceLineItem>>;
  status: PurchaseOrderStatus;
};

export type PurchaseOrderAssociation = {
  __typename?: 'PurchaseOrderAssociation';
  associatedGoodsReceipts?: Maybe<Array<Maybe<GoodsReceiptAssociation>>>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  purchaseOrderId?: Maybe<Scalars['String']['output']>;
};

export type PurchaseOrderAssociationDetails = {
  __typename?: 'PurchaseOrderAssociationDetails';
  orderNumber: Scalars['String']['output'];
  purchaseOrderId: Scalars['String']['output'];
};

export type PurchaseOrderAssociationWithGoodsReceipts = {
  __typename?: 'PurchaseOrderAssociationWithGoodsReceipts';
  associatedGoodsReceipts?: Maybe<Array<Maybe<GoodsReceiptAssociationDetails>>>;
  orderNumber: Scalars['String']['output'];
  purchaseOrderId: Scalars['String']['output'];
};

export type PurchaseOrderConnection = {
  __typename?: 'PurchaseOrderConnection';
  edges?: Maybe<Array<PurchaseOrderEdge>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchaseOrderCountInput = {
  view: PurchaseOrderView;
};

export type PurchaseOrderData = {
  __typename?: 'PurchaseOrderData';
  integrationType: PurchaseOrderIntegrationType;
  orderNumber: Scalars['String']['output'];
  purchaseOrderId: Scalars['String']['output'];
};

export type PurchaseOrderEdge = {
  __typename?: 'PurchaseOrderEdge';
  cursor: Scalars['String']['output'];
  node: PurchaseOrder;
};

export type PurchaseOrderFilterInput = {
  accountPayableNumber?: InputMaybe<Scalars['String']['input']>;
  accountsPayableNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amountCurrency?: InputMaybe<DocumentCurrency>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use accountsPayableNumbers instead for same result */
  contacts?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAtRange?: InputMaybe<DateFilterInput>;
  deliveryDateRange?: InputMaybe<DateFilterInput>;
  glAccount?: InputMaybe<Scalars['String']['input']>;
  invoiceAssociationStatuses?: InputMaybe<Array<PurchaseOrdersInvoiceAssociationStatus>>;
  itemNumber?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  postingDateRange?: InputMaybe<DateFilterInput>;
  purchaseOrderType?: InputMaybe<PurchaseOrderType>;
  statuses?: InputMaybe<Array<PurchaseOrderStatus>>;
};

export type PurchaseOrderImportHistoryEntry = {
  __typename?: 'PurchaseOrderImportHistoryEntry';
  createdAt: Scalars['Date']['output'];
  createdBy: User;
  finishedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  importResult?: Maybe<PurchaseOrderImportHistoryEntryImportResult>;
  status: PurchaseOrderImportHistoryEntryStatus;
  updatedAt: Scalars['Date']['output'];
};

export type PurchaseOrderImportHistoryEntryImportResult = {
  __typename?: 'PurchaseOrderImportHistoryEntryImportResult';
  failedToImportDocumentsCount: Scalars['Int']['output'];
  successfullyCreatedDocumentsCount: Scalars['Int']['output'];
  successfullyUpdatedDocumentsCount: Scalars['Int']['output'];
};

export enum PurchaseOrderImportHistoryEntryStatus {
  Failed = 'FAILED',
  InProcess = 'IN_PROCESS',
  PartiallySuccess = 'PARTIALLY_SUCCESS',
  Success = 'SUCCESS'
}

export type PurchaseOrderInfo = {
  __typename?: 'PurchaseOrderInfo';
  articleId: Scalars['String']['output'];
  purchaseOrderCreationDate: Scalars['Date']['output'];
  purchaseOrderId: Scalars['String']['output'];
  purchaseOrderLineNumber: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  unitPrice: Money;
};

export enum PurchaseOrderIntegrationType {
  Other = 'OTHER',
  Sap = 'SAP'
}

export type PurchaseOrderLinkedEvent = DocumentTimeLineItem & {
  __typename?: 'PurchaseOrderLinkedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** order number of the purchase order */
  orderNumber?: Maybe<Scalars['String']['output']>;
  /** purchase order id */
  purchaseOrderId?: Maybe<Scalars['String']['output']>;
  /** user who linked the purchase order */
  user?: Maybe<ActivityUser>;
};

export type PurchaseOrderPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum PurchaseOrderSortField {
  ContactName = 'CONTACT_NAME',
  CreatedAt = 'CREATED_AT',
  DeliveryDate = 'DELIVERY_DATE',
  InvoiceAssociationStatus = 'INVOICE_ASSOCIATION_STATUS',
  OrderNumber = 'ORDER_NUMBER',
  PostingDate = 'POSTING_DATE',
  Status = 'STATUS',
  SupplierRefNumber = 'SUPPLIER_REF_NUMBER',
  TotalAmount = 'TOTAL_AMOUNT'
}

export type PurchaseOrderSortInput = {
  direction: SortDirection;
  field: PurchaseOrderSortField;
  targetValue?: InputMaybe<Scalars['Float']['input']>;
};

export enum PurchaseOrderStatus {
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Delivered = 'DELIVERED',
  Exported = 'EXPORTED',
  Open = 'OPEN',
  Paid = 'PAID',
  PartiallyFulfilled = 'PARTIALLY_FULFILLED'
}

export enum PurchaseOrderType {
  Goods = 'GOODS',
  Service = 'SERVICE'
}

export type PurchaseOrderUnlinkedEvent = DocumentTimeLineItem & {
  __typename?: 'PurchaseOrderUnlinkedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** order number of the purchase order */
  orderNumber?: Maybe<Scalars['String']['output']>;
  /** purchase order id */
  purchaseOrderId?: Maybe<Scalars['String']['output']>;
  /** user who unlinked the purchase order */
  user?: Maybe<ActivityUser>;
};

export enum PurchaseOrderView {
  Archive = 'ARCHIVE',
  Inbox = 'INBOX'
}

export type PurchaseOrdersCount = {
  __typename?: 'PurchaseOrdersCount';
  count: Scalars['Int']['output'];
};

export enum PurchaseOrdersInvoiceAssociationStatus {
  Attached = 'ATTACHED',
  Incomplete = 'INCOMPLETE',
  Missing = 'MISSING'
}

export enum PurchaserOrderQueryField {
  AccountsPayableNumber = 'ACCOUNTS_PAYABLE_NUMBER',
  ContactName = 'CONTACT_NAME',
  GlAccount = 'GL_ACCOUNT',
  InvoiceAssociationStatus = 'INVOICE_ASSOCIATION_STATUS',
  ItemDescription = 'ITEM_DESCRIPTION',
  ItemNumber = 'ITEM_NUMBER',
  OrderNumber = 'ORDER_NUMBER',
  Status = 'STATUS',
  SupplierRefNumber = 'SUPPLIER_REF_NUMBER',
  TotalAmount = 'TOTAL_AMOUNT'
}

export type PurgeOrganizationDataInput = {
  deleteBookingAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  deleteBookingKeys?: InputMaybe<Scalars['Boolean']['input']>;
  deleteContacts?: InputMaybe<Scalars['Boolean']['input']>;
  deleteCostCenters?: InputMaybe<Scalars['Boolean']['input']>;
  deleteCreditCardsData?: InputMaybe<Scalars['Boolean']['input']>;
  deleteReimbursementData?: InputMaybe<Scalars['Boolean']['input']>;
  deleteWorkflowTemplates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PurgeReimbursementDataResponse = {
  __typename?: 'PurgeReimbursementDataResponse';
  deletedActivities: Scalars['Int']['output'];
  deletedBookings: Scalars['Int']['output'];
  deletedMemberships: Scalars['Int']['output'];
  deletedReimbursementCases: Scalars['Int']['output'];
  deletedReimbursementItems: Scalars['Int']['output'];
  totalDeleted: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  /** Paginated list of exports */
  _exports: ExportConnection;
  accountingAreaById: AccountingAreaResult;
  accountingAreas: Array<AccountingArea>;
  accountsPayableNumberSettings: AccountsPayableNumberSettings;
  accountsPayableNumberSettingsForEmployees: AccountsPayableNumberSettings;
  additionalERPExpenseById?: Maybe<AdditionalErpExpense>;
  additionalERPExpenses?: Maybe<ListAdditionalErpExpensesResult>;
  approvalInvoiceDocuments: ApprovalInvoiceDocumentsResponse;
  approvalReimbursementCases: ApprovalReimbursementCasesResponse;
  approvalReimbursementCasesCount: ReimbursementCasesCountResponse;
  approvers: Array<User>;
  archiveAllDocuments: ArchiveAllDocumentsResponse;
  archiveCardIssuerTransactions: TransactionConnection;
  archiveInvoiceDocuments: ArchiveInvoiceDocumentsResponse;
  archiveReimbursementCases: ArchiveReimbursementCasesResponse;
  archiveReimbursementCasesCount: ReimbursementCasesCountResponse;
  archivedDocuments: DocumentConnection;
  /** Get authenticator device information */
  authenticatorDevice?: Maybe<AuthenticatorDeviceInfo>;
  availableDocumentCategories: Array<DocumentCategory>;
  availableDocumentTypes: Array<AvailableDocumentTypes>;
  bookingAccount: BookingAccountFetchResult;
  bookingAccountAccountCodeFilter: Array<Scalars['String']['output']>;
  /** @deprecated Use `bookingAccountAccountCodeFilter` */
  bookingAccountAccountNumberFilter: Array<Scalars['String']['output']>;
  bookingAccountNameFilter: Array<Scalars['String']['output']>;
  bookingAccountPagination: BookingAccountPaginationResult;
  bookingAccountSearch: Array<BookingAccount>;
  bookingAccountsDuplicateCheck: Array<Scalars['String']['output']>;
  /** @deprecated Use `bookingAccountsDuplicateCheck` */
  bookingAccountsForDuplicateCheck: Array<Scalars['Int']['output']>;
  bookingAccountsImportHistory: Array<BookingAccountImportHistory>;
  bookingKey: BookingKeyFetchResult;
  bookingKeys: Array<BookingKey>;
  bookingKeysActive: Array<BookingKey>;
  bookingKeysArchived: Array<BookingKey>;
  calculateDocumentGrossNetTaxRoundingAmounts: CalculateDocumentGrossNetTaxRoundingAmountsResult;
  candisApiCoreDataImportHistory: Array<CandisApiCoreDataImportHistoryEntry>;
  cardIssuerCardsMetadata: CardMetadataConnection;
  cardIssuerSettlement: CardIssuerSettlement;
  cardIssuerSettlements: CardIssuerSettlementsConnection;
  cardIssuerSettlementsPageBased: CardIssuerSettlementsConnectionPageBased;
  cardIssuerStatement: CardIssuerStatement;
  cardIssuerStatementCsvFile: CardIssuerStatementCsvFile;
  cardIssuerStatements: CardIssuerStatementsConnection;
  cardIssuerStatementsPageBased: CardIssuerStatementsConnectionPageBased;
  /** Returns a list of card settlement payment are ready to be exported */
  cardSettlementsReadyForExport: PaymentSettlementConnection;
  cardTransactionsNotReadyForExport: ExportableCardTransaction;
  cardTransactionsReadyForExport: ExportableCardTransaction;
  checkIfCardIssuerOrganizationIsReadyForMigration: CheckIfCardIssuerOrganizationIsReadyForMigrationResult;
  contact?: Maybe<Contact>;
  contactAccountsPayableNumberFilter: Array<Scalars['String']['output']>;
  contactAccountsReceivableNumberFilter: Array<Scalars['String']['output']>;
  contactByAccountsNumber?: Maybe<Contact>;
  contactByAccountsPayableNumber?: Maybe<Contact>;
  contactByEmployeeMembershipId?: Maybe<Contact>;
  contactByName?: Maybe<Contact>;
  contactFilterOptionsPagination: ContactFilterOptionsPaginationResult;
  contactIbanFilter: Array<Scalars['String']['output']>;
  contactNameFilter: Array<Scalars['String']['output']>;
  contactTaxNumberFilter: Array<Scalars['String']['output']>;
  contactVatIdFilter: Array<Scalars['String']['output']>;
  contacts?: Maybe<Array<Contact>>;
  contactsForExport?: Maybe<Array<ExportableContact>>;
  contactsImportHistoriesWithUnacknowledgedErrors: Array<ContactImportHistory>;
  contactsImportHistory: Array<ContactImportHistory>;
  contactsImportHistoryById?: Maybe<ContactImportHistory>;
  contactsImportHistoryError?: Maybe<ContactImportHistoryErrorResult>;
  contactsImportedForCompany: Scalars['Boolean']['output'];
  contactsPagination: ContactsPaginationResult;
  contractSubCategories: Array<ContractSubCategory>;
  contractSubCategory: ContractSubCategory;
  costCenter?: Maybe<CostCenter>;
  costCenterArchivedCountForApprover?: Maybe<Scalars['Int']['output']>;
  costCenterCodeFilter: Array<Scalars['String']['output']>;
  costCenterCountForApprover?: Maybe<Scalars['Int']['output']>;
  costCenterGetImportHistory: Array<CostCenterImportHistoryItem>;
  costCenterNameFilter: Array<Scalars['String']['output']>;
  costCenterPagination: CostCenterPaginationResult;
  costCenterSearch: Array<CostCenter>;
  costCentersGetForDuplicationCheck: Array<Scalars['String']['output']>;
  countAccountingAreas: AccountingAreaCount;
  countBookingAccounts: Scalars['Int']['output'];
  countCardIssuerTransactions: CardIssuerTransactionsCount;
  countCardRequests?: Maybe<CardRequestsCounts>;
  countCards?: Maybe<CardsCounts>;
  countClassifyingDocuments: DocumentMetric;
  countContacts: Scalars['Int']['output'];
  countCostCenters: Scalars['Int']['output'];
  countDocumentsByStatus: DocumentMetric;
  countDocumentsIRejected: DocumentMetric;
  countDocumentsOthersApproved: DocumentMetric;
  countDocumentsOthersRejected: DocumentMetric;
  countEcmDocumentsWithFullTextSearch: CountEcmDocumentsWithFullTextSearchResponse;
  countExportableContacts: Scalars['Int']['output'];
  countGoodsReceipts: GoodsReceiptsCount;
  countOpenApprovals: DocumentMetric;
  countOpenOverdueApprovals: DocumentMetric;
  countPayableDocuments: DocumentMetric;
  countPendingApprovals: DocumentMetric;
  /** @deprecated Use getPayableDocumentsInfo instead */
  countPendingDocumentsByMembership: DocumentMetric;
  countPendingOverdueApprovals: DocumentMetric;
  countPendingReimbursementCasesByMembership: DocumentMetric;
  countProjectCodes: Scalars['Int']['output'];
  countPurchaseOrders: PurchaseOrdersCount;
  countTeams?: Maybe<TeamCount>;
  creditCardInvolvements: CreditCardInvolvements;
  creditCardsLedger: CreditCardsLedger;
  currentSlackToken?: Maybe<RedirectSlackResponse>;
  currentUser?: Maybe<User>;
  datevAvailableServices: Array<DatevAvailableService>;
  datevBDSPreRequisites: Array<DatevBdsPreRequisites>;
  datevBdsPermission: BdsPermissionResult;
  datevBookingAccountValidation: BookingAccountValidation;
  datevChartOfAccount?: Maybe<ChartOfAccount>;
  datevClientInfo: DatevClientInfo;
  /** @deprecated Use creditCardsLedger instead */
  datevCreditCardsLedger: CreditCardsLedger;
  datevProvisionsLedger?: Maybe<ProvisionsLedger>;
  datevStatus: DatevOperationalStatus;
  documentAccessLevels: AccessLevelsResult;
  documentDataExtractionSettings?: Maybe<DocumentDataExtractionSettings>;
  documentDataFileRecords?: Maybe<Array<DocumentDataFileRecord>>;
  documentRelationships: GetDocumentRelationshipResponse;
  documentRelationshipsCount: DocumentRelationshipCountReponse;
  /** @deprecated Get document tags with the document itself */
  documentTags: Array<Tag>;
  documents?: Maybe<DocumentConnection>;
  documentsForExport: ExportableDocumentConnection;
  documentsStats: DocumentStatsAggregate;
  ecmDocumentTypeSettings: Array<EcmDocumentTypeSettingRecord>;
  emailInboxMessage?: Maybe<EmailInboxMessage>;
  emailInboxPagination?: Maybe<EmailInboxPaginationResponse>;
  emailInboxSearch: Array<EmailInboxMessage>;
  entityAuditLogs: Array<EntityChangeLog>;
  evaluateCoreDataExport: EvaluateCoreDataExport;
  /** Query to get an export by Guid. */
  export?: Maybe<Export>;
  exportDocumentsEs: ExportDocumentsEsResponse;
  exports: Array<Export>;
  /** @deprecated Use cardIssuerPayments */
  fetchCardIssuerPaymentSettlements: PaymentSettlementConnection;
  fileUploadModal: UploadModal;
  fileUploads: Array<UploadFileItem>;
  financialInsights: FinancialInsights;
  getAggregatedEcmDocument: AggregatedEcmDocument;
  /** Get a list of available months for DATEV CSV export from either ready-to-export documents or an already exported entry */
  getAvailableMonths: Array<AvailableMonth>;
  getCardIssuerCardById: CardIssuerCard;
  getCardIssuerCardCredential: CardIssuerCardCredentialRequiresRecentAuthn;
  getCardIssuerCardholderByMembershipId?: Maybe<CardIssuerCardholder>;
  getCardIssuerCardholders: CardholdersConnection;
  getCardIssuerCardsForCardManager: CardsConnection;
  getCardIssuerCardsForCardholder: CardsConnection;
  getCardIssuerOrganizationOnboarding?: Maybe<CardIssuerOrganizationOnboarding>;
  getCardIssuerTransactionById: CardIssuerTransaction;
  getComment?: Maybe<Comment>;
  getCountExportableEntities: ExportableEntitiesMetric;
  getCountOfWorkflowTemplatesByApproverId: WorkflowTemplateCounters;
  getCurrentUserAbsence?: Maybe<Absence>;
  getDocument?: Maybe<Document>;
  getDocumentBookingAssociationsByInvoiceId?: Maybe<Array<DocumentBookingAssociation>>;
  getDocumentDownloadUrlById?: Maybe<DocumentDownloadUrl>;
  getEcmDocuments: GetEcmDocumentsResponse;
  getFileUploadAttempts: Array<FileUploadAttempt>;
  getForeignCurrencyFeeSavings: ForeignCurrencyFeeSavings;
  /** @deprecated getForeignCurrencyFeeSavingsSummary was deprecated in favor of getForeignCurrencyFeeSavings */
  getForeignCurrencyFeeSavingsSummary: Array<ForeignCurrencyFeeSavingsInsight>;
  getGoodsReceiptImportHistoryEntryById: GoodsReceiptImportHistoryEntry;
  getIdentitiyProvider: IdentityProvider;
  getInitialBdsExports: Array<Export>;
  getInsightsWidget?: Maybe<InsightsWidget>;
  getLatestDatevExportOfDocument?: Maybe<Export>;
  getMemberAbsence?: Maybe<Absence>;
  getMembersForNewCard?: Maybe<Array<User>>;
  /** new logic, finds free APNs in a range based on provided input */
  getNextFreeAccountsPayableNumber?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  getNextFreeAccountsPayableNumberForEmployees?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  getOrgMembersAbsence: Array<Absence>;
  /** @deprecated Field no longer supported */
  getOrganizations?: Maybe<Array<Organization>>;
  getOrganizationsInsightsDetails: Array<OrganizationsInsightsDetails>;
  getOrganizationsOverallTaskSummary: TasksCountsInsight;
  getPINForCard: Scalars['String']['output'];
  getPageBasedCardIssuerCards: CardsConnectionPageBased;
  getPageBasedCardIssuerMerchants: MerchantsConnectionPageBased;
  getPageBasedTransactionDownloadHistory: PageBasedTransactionDownloadHistoryList;
  getPaginatedInsightsWidgets: InsightsWidgetsPaginationResult;
  /** Get total payable amount and discounted payable amount */
  getPayableAmounts?: Maybe<PayableAmounts>;
  getPayableDocumentsInfo: PayableDocumentsResponse;
  getPurchaseOrderByInvoiceId?: Maybe<PurchaseOrder>;
  getPurchaseOrderImportHistoryEntryById: PurchaseOrderImportHistoryEntry;
  getSSOConfigurationById: SsoConfiguration;
  getSpendOverviewInsights: CardIssuerSpendOverviewInsight;
  getTransactionsInsights: CardIssuerTransactionsInsights;
  getUserInvolvementForEcmDocuments?: Maybe<GetUserInvolvementForEcmDocumentResponse>;
  getVendorCardholdersSuggestionsForRecurringPayments: VendorCardholdersSuggestionsForRecurringPayments;
  getVendorCardsSuggestionsForRecurringPayments: VendorCardsSuggestionsForRecurringPayments;
  getVendorForRecurringPaymentsById: VendorForRecurringPaymentData;
  getVendorSuggestionsCountForRecurringPayments: VendorSuggestionsCountForRecurringPayments;
  getVendorSuggestionsForRecurringPayments: VendorSuggestionsForRecurringPaymentsConnectionPageBased;
  getVendorsForRecurringPayments: VendorsForRecurringPaymentsConnectionPageBased;
  importHistories?: Maybe<ListImportHistoryEntriesResult>;
  importHistoryById?: Maybe<ImportHistory>;
  importPaymentConditions: Array<PaymentConditionCommandResult>;
  importPaymentConditionsWithUpdate: Array<PaymentConditionImportResult>;
  inAppNotifications: InAppNotificationPaginationResult;
  inAppNotificationsCount: Scalars['Int']['output'];
  inboxCardIssuerTransactions?: Maybe<TransactionConnection>;
  inboxGoodsReceipts?: Maybe<GoodsReceiptConnection>;
  inboxInvoiceDocuments: InboxInvoiceDocumentsResponse;
  inboxPurchaseOrders?: Maybe<PurchaseOrderConnection>;
  inboxReimbursementCases: InboxReimbursementCasesResponse;
  inboxReimbursementCasesCount: ReimbursementCasesCountResponse;
  insightsPreferences: InsightsPreferences;
  isTrackingEnabledForUser: Scalars['Boolean']['output'];
  listApprovalsDocuments: DocumentConnection;
  listCandisAPISessions: Array<CandisApiSession>;
  /** @deprecated Field no longer supported */
  listExportDocuments: DocumentsListResponse;
  listGoodsReceiptImportHistoryEntries: ListGoodsReceiptImportHistoryEntriesResult;
  listIbans: Array<Iban>;
  listInboxDocuments: DocumentConnection;
  listPurchaseOrderImportHistoryEntries: ListPurchaseOrderImportHistoryEntriesResult;
  listSSOConfigurations: Array<SsoConfiguration>;
  /** Get Membership by membershipId */
  membership: User;
  membershipPreferences: MembershipPreferences;
  membershipTeamsCapabilitiesForCreditCards: PageBasedMembershipTeamsCapabilitiesForCreditCardsList;
  /** @deprecated Use getOrganizationMemberships with status:Active, emailVerified:true */
  memberships: Array<User>;
  mileageReimbursementItemLocationsSearch: MileageReimbursementItemLocationsSearchResponse;
  mobileWalletTokenizations: CardIssuerMobileWalletTokenizationsPageBased;
  new_datevClients: Array<New_DatevClient>;
  new_datevSettings?: Maybe<New_DatevSettings>;
  /**
   * old logic of just giving first free APN after the maximum one
   * @deprecated: use 'getNextFreeAccountsPayableNumber' instead
   */
  nextFreeAccountsPayableNumber?: Maybe<Scalars['AccountsPayableNumber']['output']>;
  notifications?: Maybe<NotificationsResponse>;
  ops_checkIfCardIssuerOrganizationIsReadyForMigration: CheckIfCardIssuerOrganizationIsReadyForMigrationResult;
  ops_eInvoiceValidationErrors?: Maybe<Array<Scalars['String']['output']>>;
  ops_entityAuditLogs: Array<OpsEntityChangeLog>;
  ops_fetchBDSJobStatus?: Maybe<Array<Maybe<ExtfFilesJob>>>;
  ops_fetchDatevPermissionInfo: OpsDatevPermissionInfo;
  ops_findDuplicateContacts: Array<DuplicateGroup>;
  ops_getAuditReportRequest?: Maybe<AuditReportRequest>;
  ops_getDeletedDocument?: Maybe<RetainedDeletedDocument>;
  ops_getOrganizationInfo: Organization;
  organization?: Maybe<Organization>;
  organizationMembershipCountByStatus: OrganizationMembershipCountByStatus;
  organizationMemberships: Array<User>;
  organizationMembershipsWithPagination: OrganizationMembershipsWithPagination;
  organizationsByMembership: Array<Organization>;
  /** Returns a list of documents which can be paid */
  payableDocuments: PayableDocumentsListResponse;
  payableDocumentsEs: PayableDocumentsEsResponse;
  payableDocumentsPagination: PayableDocumentConnection;
  payment?: Maybe<Payment>;
  paymentCondition: PaymentConditionGetResult;
  paymentConditions: Array<PaymentCondition>;
  paymentConditionsCount: Scalars['Int']['output'];
  paymentConditionsForExport?: Maybe<Array<ExportablePaymentCondition>>;
  paymentConditionsImportHistory?: Maybe<Array<PaymentConditionsImportHistory>>;
  payments: PaymentsResponse;
  pendingChangeMembershipEmail?: Maybe<PendingChangeMembershipEmail>;
  /** @deprecated Use getOrganizationMemberships with emailVerified:false */
  pendingInvites: Array<User>;
  /** Gets any PENDING phone number verification for the user */
  pendingPhoneNumberVerification?: Maybe<PendingPhoneNumberVerification>;
  perDiemLocations: PerDiemLocationResponse;
  permissionsForRole?: Maybe<PermissionsForRole>;
  projectCode?: Maybe<ProjectCode>;
  projectCodePagination: ProjectCodePaginationResult;
  provisions: Array<Provision>;
  provisionsReadyForExport: ProvisionConnection;
  reimbursementCaseById: ReimbursementCase;
  reimbursementCasesCount: ReimbursementCasesCounts;
  /** @deprecated Use 'archiveReimbursementCases' or 'inboxReimbursementCases' instead. */
  reimbursementCasesCursorBased: ReimbursementCasesConnection;
  reimbursementCasesPageBased: ReimbursementCasesConnectionPageBased;
  reimbursementCasesSummary: ReimbursementCasesSummary;
  reimbursementItem: CommonReimbursementItem;
  reimbursementItems: Array<CommonReimbursementItem>;
  reimbursementItemsReadyForExport: ExportableReimbursementItemConnection;
  reversalsReadyForExport: ReversalConnection;
  role: Role;
  /** @deprecated Use permissionsForRole or role.permissions instead. */
  rolePermissions: PageBasedPermissionsList;
  roles: PageBasedRolesList;
  sapB1Credentials?: Maybe<SapB1Credentials>;
  sapB1Settings?: Maybe<SapB1Settings>;
  searchDocuments: DocumentConnection;
  /** Refetch a SEPA Transfer XML from an existing Payment */
  sepaTransferXml?: Maybe<SepaXmlResponse>;
  sfsSettings: SfsSettings;
  sfsSettingsForApprover: SfsSettings;
  sfsSuggestionForApprover: SfsSuggestions;
  sfsSuggestionForRequester: SfsSuggestions;
  tag: Tag;
  tags: Array<Tag>;
  tagsForDuplicationCheck: Array<Scalars['String']['output']>;
  team?: Maybe<Team>;
  teamInvolvements: TeamInvolvements;
  teamMembers: Array<User>;
  teamSettingsForCreditCards: TeamSettingsForCreditCards;
  teams?: Maybe<PageBasedTeamList>;
  unacknowledgedDocumentDataFileRecords: Array<DocumentDataFileRecord>;
  unassociatedCardIssuerTransactions: TransactionConnection;
  verifySapB1Credentials?: Maybe<SapB1Response>;
  workflowTemplate: WorkflowTemplate;
  workflowTemplates: Array<WorkflowTemplate>;
  workflowTemplatesCounters: WorkflowTemplateCounters;
};


export type Query_ExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<ExportFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAccountingAreaByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryAccountingAreasArgs = {
  filter: AccountingAreasFilterInput;
};


export type QueryAdditionalErpExpenseByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryAdditionalErpExpensesArgs = {
  input?: InputMaybe<ListAdditionalErpExpensesInput>;
};


export type QueryApprovalInvoiceDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ApprovalInvoicesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryApprovalReimbursementCasesArgs = {
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
};


export type QueryApprovalReimbursementCasesCountArgs = {
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
};


export type QueryApproversArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArchiveAllDocumentsArgs = {
  filter?: InputMaybe<ArchiveAllDocumentsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<ArchiveAllDocumentsSortInput>;
};


export type QueryArchiveCardIssuerTransactionsArgs = {
  filters?: InputMaybe<CardIssuerTransactionFilterInput>;
  input: CardIssuerTransactionPaginationInput;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
  sortBy?: InputMaybe<CardIssuerTransactionSortInput>;
};


export type QueryArchiveInvoiceDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ArchiveInvoiceDocumentsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryArchiveReimbursementCasesArgs = {
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
};


export type QueryArchiveReimbursementCasesCountArgs = {
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
};


export type QueryArchivedDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryAvailableDocumentCategoriesArgs = {
  includeInactiveTypes?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAvailableDocumentTypesArgs = {
  includeInactiveTypes?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBookingAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBookingAccountAccountCodeFilterArgs = {
  accountCode?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBookingAccountAccountNumberFilterArgs = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBookingAccountNameFilterArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBookingAccountPaginationArgs = {
  filters?: InputMaybe<BookingAccountFilterInput>;
  input?: InputMaybe<BookingAccountPaginationInput>;
  queries?: InputMaybe<BookingAccountQueryInput>;
  sortBy?: InputMaybe<BookingAccountSortInput>;
};


export type QueryBookingAccountSearchArgs = {
  input: BookingAccountSearchInput;
};


export type QueryBookingAccountsDuplicateCheckArgs = {
  accountCodes: Array<Scalars['String']['input']>;
};


export type QueryBookingAccountsForDuplicateCheckArgs = {
  accountNumbers: Array<Scalars['Int']['input']>;
};


export type QueryBookingKeyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCalculateDocumentGrossNetTaxRoundingAmountsArgs = {
  input: CalculateDocumentGrossNetTaxRoundingAmountsInput;
};


export type QueryCandisApiCoreDataImportHistoryArgs = {
  type: CoreDataTypes;
};


export type QueryCardIssuerCardsMetadataArgs = {
  filters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  input: CardIssuerPaginationInput;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
};


export type QueryCardIssuerSettlementArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCardIssuerSettlementsArgs = {
  dateRangeFilters?: InputMaybe<CardIssuerSettlementsDateFilterInput>;
  filters?: InputMaybe<CardIssuerSettlementsFiltersInput>;
  input: CardIssuerPaginationInput;
  sortBy?: InputMaybe<CardIssuerSettlementsSortInput>;
};


export type QueryCardIssuerSettlementsPageBasedArgs = {
  dateRangeFilters?: InputMaybe<CardIssuerSettlementsDateFilterInput>;
  filters?: InputMaybe<CardIssuerSettlementsFiltersInput>;
  input?: InputMaybe<CardIssuerPageBasedPaginationInput>;
  sortBy?: InputMaybe<CardIssuerSettlementsSortInput>;
};


export type QueryCardIssuerStatementArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCardIssuerStatementCsvFileArgs = {
  input: GetCsvFileForStatementInput;
};


export type QueryCardIssuerStatementsArgs = {
  dateRangeFilters?: InputMaybe<CardIssuerStatementsDateFilterInput>;
  filters?: InputMaybe<CardIssuerStatementsFilterInput>;
  input?: InputMaybe<CardIssuerPaginationInput>;
  sortBy?: InputMaybe<CardIssuerStatementsSortInput>;
};


export type QueryCardIssuerStatementsPageBasedArgs = {
  dateRangeFilters?: InputMaybe<CardIssuerStatementsDateFilterInput>;
  filters?: InputMaybe<CardIssuerStatementsFilterInput>;
  input?: InputMaybe<CardIssuerPageBasedPaginationInput>;
  sortBy?: InputMaybe<CardIssuerStatementsSortInput>;
};


export type QueryCardSettlementsReadyForExportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  openStatement?: InputMaybe<CardIssuerSettlementOpenStatementFilter>;
  paidSettlements?: InputMaybe<CardIssuerSettlementPaidSettlementsFilter>;
  statuses: Array<PaymentSettlementStatus>;
};


export type QueryContactArgs = {
  input: GetContactInput;
};


export type QueryContactAccountsPayableNumberFilterArgs = {
  accountsPayableNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContactAccountsReceivableNumberFilterArgs = {
  accountsReceivableNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContactByAccountsNumberArgs = {
  accountsNumber: Scalars['String']['input'];
};


export type QueryContactByAccountsPayableNumberArgs = {
  accountsPayableNumber: Scalars['AccountsPayableNumber']['input'];
};


export type QueryContactByEmployeeMembershipIdArgs = {
  membershipId: Scalars['String']['input'];
};


export type QueryContactByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryContactFilterOptionsPaginationArgs = {
  input?: InputMaybe<ContactsPaginationInput>;
  query: ContactFilterOptionsQueryInput;
  sortBy?: InputMaybe<ContactsSortInput>;
};


export type QueryContactIbanFilterArgs = {
  iban?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContactNameFilterArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContactTaxNumberFilterArgs = {
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContactVatIdFilterArgs = {
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContactsArgs = {
  input?: InputMaybe<GetContactsInput>;
};


export type QueryContactsForExportArgs = {
  fetchAllRecords: Scalars['Boolean']['input'];
};


export type QueryContactsImportHistoryByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactsPaginationArgs = {
  filters?: InputMaybe<ContactsFilterInput>;
  input?: InputMaybe<ContactsPaginationInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
};


export type QueryContractSubCategoriesArgs = {
  input?: InputMaybe<GetContractSubCategoriesInput>;
};


export type QueryContractSubCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryCostCenterArgs = {
  input: GetCostCenterInput;
};


export type QueryCostCenterArchivedCountForApproverArgs = {
  membershipId: Scalars['String']['input'];
};


export type QueryCostCenterCodeFilterArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCenterFilterOptionsInput>;
};


export type QueryCostCenterCountForApproverArgs = {
  membershipId: Scalars['String']['input'];
};


export type QueryCostCenterNameFilterArgs = {
  filter?: InputMaybe<CostCenterFilterOptionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCostCenterPaginationArgs = {
  filters?: InputMaybe<CostCenterFilterInput>;
  input?: InputMaybe<CostCenterPaginationInput>;
  queries?: InputMaybe<CostCenterQueryInput>;
  sortBy?: InputMaybe<CostCenterSortInput>;
};


export type QueryCostCenterSearchArgs = {
  input: CostCenterSearchInput;
};


export type QueryCostCentersGetForDuplicationCheckArgs = {
  costCenterCodes: Array<Scalars['String']['input']>;
  costCenterType: CostCenterTypes;
};


export type QueryCountAccountingAreasArgs = {
  filter: AccountingAreasFilterInput;
};


export type QueryCountBookingAccountsArgs = {
  filters?: InputMaybe<BookingAccountFilterInput>;
};


export type QueryCountCardIssuerTransactionsArgs = {
  filters?: InputMaybe<CardIssuerTransactionFilterInput>;
  input: CardIssuerTransactionCountInput;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
};


export type QueryCountCardRequestsArgs = {
  filters?: InputMaybe<CardIssuerCardRequestsFiltersInput>;
};


export type QueryCountCardsArgs = {
  filters?: InputMaybe<CardIssuerCardsFiltersInput>;
};


export type QueryCountContactsArgs = {
  filters?: InputMaybe<ContactsFilterInput>;
};


export type QueryCountCostCentersArgs = {
  filters?: InputMaybe<CostCenterFilterInput>;
};


export type QueryCountDocumentsByStatusArgs = {
  status: DocumentStatus;
};


export type QueryCountEcmDocumentsWithFullTextSearchArgs = {
  filter?: InputMaybe<EcmFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmSortInput>;
};


export type QueryCountGoodsReceiptsArgs = {
  filters?: InputMaybe<GoodsReceiptFilterInput>;
  input: GoodsReceiptCountInput;
};


export type QueryCountPayableDocumentsArgs = {
  hasIban?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCountPendingDocumentsByMembershipArgs = {
  membershipId: Scalars['String']['input'];
};


export type QueryCountPendingReimbursementCasesByMembershipArgs = {
  membershipId: Scalars['String']['input'];
};


export type QueryCountProjectCodesArgs = {
  filters?: InputMaybe<ProjectCodeFilterInput>;
};


export type QueryCountPurchaseOrdersArgs = {
  filters?: InputMaybe<PurchaseOrderFilterInput>;
  input: PurchaseOrderCountInput;
};


export type QueryCountTeamsArgs = {
  filters?: InputMaybe<TeamsFilterInput>;
};


export type QueryCreditCardInvolvementsArgs = {
  input: CreditCardInvolvementsInput;
};


export type QueryCurrentUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocumentAccessLevelsArgs = {
  globalDocumentId: Scalars['String']['input'];
};


export type QueryDocumentRelationshipsArgs = {
  input: GetDocumentRelationshipInput;
};


export type QueryDocumentRelationshipsCountArgs = {
  input: GetDocumentRelationshipInput;
};


export type QueryDocumentTagsArgs = {
  globalDocumentId: Scalars['String']['input'];
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DocumentSort>;
};


export type QueryDocumentsForExportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


export type QueryDocumentsStatsArgs = {
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEcmDocumentTypeSettingsArgs = {
  input: GetEcmDocumentTypeSettingsInput;
};


export type QueryEmailInboxMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEmailInboxPaginationArgs = {
  filters?: InputMaybe<EmailInboxFilterInput>;
  input?: InputMaybe<EmailInboxPaginationInput>;
  sortBy?: InputMaybe<EmailInboxSortInput>;
};


export type QueryEmailInboxSearchArgs = {
  input: EmailInboxSearchInput;
};


export type QueryEntityAuditLogsArgs = {
  entityId: Scalars['String']['input'];
  entityType: EntityType;
};


export type QueryExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportDocumentsEsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ExportDocumentsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ExportDocumentsSortInput>;
};


export type QueryExportsArgs = {
  input?: InputMaybe<ExportFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchCardIssuerPaymentSettlementsArgs = {
  filters?: InputMaybe<CardIssuerPaymentSettlementFiltersInput>;
  input: CardIssuerPaginationInput;
  sortBy?: InputMaybe<CardIssuerPaymentSettlementSortInput>;
};


export type QueryFinancialInsightsArgs = {
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
  input?: InputMaybe<FinancialInsightsInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAggregatedEcmDocumentArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAvailableMonthsArgs = {
  originalExportId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCardIssuerCardByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCardIssuerCardCredentialArgs = {
  flow?: InputMaybe<CardIssuerCardCredentialFlow>;
  id: Scalars['ID']['input'];
  period?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCardIssuerCardholderByMembershipIdArgs = {
  membershipId: Scalars['ID']['input'];
};


export type QueryGetCardIssuerCardholdersArgs = {
  filters?: InputMaybe<CardIssuerCardholdersFiltersInput>;
  input: CardIssuerPaginationInput;
  sortBy?: InputMaybe<CardIssuerCardholdersSortInput>;
};


export type QueryGetCardIssuerCardsForCardManagerArgs = {
  filters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  input: CardIssuerPaginationInput;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
};


export type QueryGetCardIssuerCardsForCardholderArgs = {
  filters?: InputMaybe<CardIssuerCardsForCardholderFiltersInput>;
  input: CardIssuerPaginationInput;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
};


export type QueryGetCardIssuerTransactionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCommentArgs = {
  input: GetCommentInput;
};


export type QueryGetCountOfWorkflowTemplatesByApproverIdArgs = {
  approverId: Scalars['String']['input'];
};


export type QueryGetDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDocumentBookingAssociationsByInvoiceIdArgs = {
  invoiceId: Scalars['String']['input'];
};


export type QueryGetDocumentDownloadUrlByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetEcmDocumentsArgs = {
  filter?: InputMaybe<EcmFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<EcmSortInput>;
};


export type QueryGetForeignCurrencyFeeSavingsArgs = {
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
};


export type QueryGetForeignCurrencyFeeSavingsSummaryArgs = {
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
};


export type QueryGetGoodsReceiptImportHistoryEntryByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetIdentitiyProviderArgs = {
  ssoConfigurationId: Scalars['String']['input'];
};


export type QueryGetInitialBdsExportsArgs = {
  limit: Scalars['Int']['input'];
};


export type QueryGetInsightsWidgetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLatestDatevExportOfDocumentArgs = {
  documentId: Scalars['ID']['input'];
};


export type QueryGetMemberAbsenceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetNextFreeAccountsPayableNumberArgs = {
  input?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetNextFreeAccountsPayableNumberForEmployeesArgs = {
  input?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrganizationsArgs = {
  input?: InputMaybe<GetOrganizationsInput>;
  sort?: InputMaybe<Array<SortInput>>;
};


export type QueryGetOrganizationsInsightsDetailsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryGetPinForCardArgs = {
  cardId: Scalars['String']['input'];
};


export type QueryGetPageBasedCardIssuerCardsArgs = {
  filters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  input: CardIssuerPageBasedPaginationInput;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
};


export type QueryGetPageBasedCardIssuerMerchantsArgs = {
  filters?: InputMaybe<CardIssuerMerchantsFiltersInput>;
  input: CardIssuerPageBasedPaginationInput;
  queries?: InputMaybe<CardIssuerMerchantsQueryInput>;
  sortBy?: InputMaybe<CardIssuerMerchantsSortInput>;
};


export type QueryGetPageBasedTransactionDownloadHistoryArgs = {
  input: TransactionDownloadHistoryPageBasedPaginationInput;
  sortBy?: InputMaybe<TransactionDownloadHistorySortInput>;
};


export type QueryGetPaginatedInsightsWidgetsArgs = {
  input: InsightsWidgetsPaginationInput;
};


export type QueryGetPayableDocumentsInfoArgs = {
  hasIban?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetPurchaseOrderByInvoiceIdArgs = {
  invoiceId: Scalars['String']['input'];
};


export type QueryGetPurchaseOrderImportHistoryEntryByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSsoConfigurationByIdArgs = {
  ssoConfigurationId: Scalars['String']['input'];
};


export type QueryGetSpendOverviewInsightsArgs = {
  dateRangeFilters?: InputMaybe<SpendOverviewInsightsDateFilterInput>;
  filters?: InputMaybe<SpendOverviewInsightsFilterInput>;
  input?: InputMaybe<SpendOverviewInsightsInput>;
};


export type QueryGetTransactionsInsightsArgs = {
  dateRangeFilters?: InputMaybe<CardIssuerTransactionDateFilterInput>;
  filters?: InputMaybe<TransactionsInsightsFilterInput>;
  input?: InputMaybe<TransactionsInsightsInput>;
};


export type QueryGetUserInvolvementForEcmDocumentsArgs = {
  membershipId: Scalars['String']['input'];
};


export type QueryGetVendorCardholdersSuggestionsForRecurringPaymentsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetVendorCardsSuggestionsForRecurringPaymentsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetVendorForRecurringPaymentsByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetVendorSuggestionsCountForRecurringPaymentsArgs = {
  dateRangeFilters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsDateFilterInput>;
  filters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsFilterInput>;
  input?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsInput>;
};


export type QueryGetVendorSuggestionsForRecurringPaymentsArgs = {
  dateRangeFilters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsDateFilterInput>;
  filters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsFilterInput>;
  input?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsInput>;
  sortBy?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsSortInput>;
};


export type QueryGetVendorsForRecurringPaymentsArgs = {
  filters?: InputMaybe<GetVendorsForRecurringPaymentsFilterInput>;
  input?: InputMaybe<GetVendorsForRecurringPaymentsInput>;
  sortBy?: InputMaybe<GetVendorsForRecurringPaymentsSortInput>;
};


export type QueryImportHistoriesArgs = {
  importHistoryInput?: InputMaybe<ListImportHistoryEntriesInput>;
  type: ImportHistoryEntityType;
};


export type QueryImportHistoryByIdArgs = {
  id: Scalars['String']['input'];
  type: ImportHistoryEntityType;
};


export type QueryImportPaymentConditionsArgs = {
  input: Array<CreatePaymentConditionInput>;
};


export type QueryImportPaymentConditionsWithUpdateArgs = {
  input: Array<CreatePaymentConditionInput>;
};


export type QueryInAppNotificationsArgs = {
  dateRangeFilters?: InputMaybe<InAppNotificationDateFilterInput>;
  filters?: InputMaybe<InAppNotificationFilterInput>;
  input?: InputMaybe<InAppNotificationPaginationInput>;
  sortBy?: InputMaybe<InAppNotificationSortInput>;
};


export type QueryInAppNotificationsCountArgs = {
  dateRangeFilters?: InputMaybe<InAppNotificationDateFilterInput>;
  filters?: InputMaybe<InAppNotificationFilterInput>;
};


export type QueryInboxCardIssuerTransactionsArgs = {
  filters?: InputMaybe<InboxCardIssuerTransactionFiltersInput>;
  input: CardIssuerTransactionPaginationInput;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
  sortBy?: InputMaybe<CardIssuerTransactionSortInput>;
};


export type QueryInboxGoodsReceiptsArgs = {
  filters?: InputMaybe<GoodsReceiptFilterInput>;
  input: GoodsReceiptPaginationInput;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFields?: InputMaybe<Array<GoodsReceiptQueryField>>;
  sortBy?: InputMaybe<GoodsReceiptSortInput>;
};


export type QueryInboxInvoiceDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<InboxInvoiceDocumentsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryInboxPurchaseOrdersArgs = {
  filters?: InputMaybe<PurchaseOrderFilterInput>;
  input: PurchaseOrderPaginationInput;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFields?: InputMaybe<Array<PurchaserOrderQueryField>>;
  sortBy?: InputMaybe<PurchaseOrderSortInput>;
};


export type QueryInboxReimbursementCasesArgs = {
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
};


export type QueryInboxReimbursementCasesCountArgs = {
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
};


export type QueryListApprovalsDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
};


export type QueryListGoodsReceiptImportHistoryEntriesArgs = {
  input?: InputMaybe<ListGoodsReceiptImportHistoryEntriesInput>;
};


export type QueryListInboxDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
};


export type QueryListPurchaseOrderImportHistoryEntriesArgs = {
  input?: InputMaybe<ListPurchaseOrderImportHistoryEntriesInput>;
};


export type QueryMembershipArgs = {
  membershipId: Scalars['String']['input'];
};


export type QueryMembershipTeamsCapabilitiesForCreditCardsArgs = {
  filters: MembershipTeamsCapabilitiesForCreditCardsFilters;
  input: MembershipTeamsCapabilitiesForCreditCardsInput;
  sortBy?: InputMaybe<MembershipTeamsCapabilitiesSortInput>;
};


export type QueryMembershipsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMileageReimbursementItemLocationsSearchArgs = {
  input: MileageReimbursementItemLocationsSearchInput;
};


export type QueryMobileWalletTokenizationsArgs = {
  filters?: InputMaybe<CardIssuerMobileWalletTokenizationsFiltersInput>;
  input: CardIssuerPageBasedPaginationInput;
  sortBy?: InputMaybe<CardIssuerMobileWalletTokenizationSortInput>;
};


export type QueryOps_CheckIfCardIssuerOrganizationIsReadyForMigrationArgs = {
  input: OpsCheckIfCardIssuerOrganizationIsReadyForMigrationInput;
};


export type QueryOps_EInvoiceValidationErrorsArgs = {
  documentId: Scalars['String']['input'];
};


export type QueryOps_EntityAuditLogsArgs = {
  entityId: Scalars['String']['input'];
};


export type QueryOps_FetchBdsJobStatusArgs = {
  exportId: Scalars['String']['input'];
};


export type QueryOps_FetchDatevPermissionInfoArgs = {
  organizationSlug: Scalars['String']['input'];
};


export type QueryOps_FindDuplicateContactsArgs = {
  input: OpsFindDuplicateContactsInput;
};


export type QueryOps_GetAuditReportRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type QueryOps_GetDeletedDocumentArgs = {
  entityId: Scalars['String']['input'];
};


export type QueryOps_GetOrganizationInfoArgs = {
  organizationSlug: Scalars['String']['input'];
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOrganizationMembershipsArgs = {
  filters?: InputMaybe<OrganizationMembershipsFiltersInput>;
  sortBy?: InputMaybe<MemberSortInput>;
};


export type QueryOrganizationMembershipsWithPaginationArgs = {
  filters?: InputMaybe<OrganizationMembershipsFiltersInput>;
  pageBasedInput: OrganizationMembershipsPageBasedInput;
  sortBy?: InputMaybe<MemberSortInput>;
};


export type QueryOrganizationsByMembershipArgs = {
  membershipId: Scalars['ID']['input'];
};


export type QueryPayableDocumentsEsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<PayableDocumentsSortInput>;
};


export type QueryPayableDocumentsPaginationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentConditionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentConditionsArgs = {
  filter: PaymentConditionsFilter;
};


export type QueryPaymentConditionsCountArgs = {
  filter: PaymentConditionsFilter;
};


export type QueryPaymentConditionsForExportArgs = {
  fetchAllRecords: Scalars['Boolean']['input'];
};


export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPendingChangeMembershipEmailArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPendingInvitesArgs = {
  input?: InputMaybe<GetUserInput>;
};


export type QueryPerDiemLocationsArgs = {
  input: PerDiemLocationsInput;
};


export type QueryPermissionsForRoleArgs = {
  filters?: InputMaybe<PermissionFilterInput>;
};


export type QueryProjectCodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectCodePaginationArgs = {
  filters?: InputMaybe<ProjectCodeFilterInput>;
  input?: InputMaybe<ProjectCodePaginationInput>;
  queries?: InputMaybe<ProjectCodeQueryInput>;
  sortBy?: InputMaybe<ProjectCodeSortInput>;
};


export type QueryProvisionsArgs = {
  filters?: InputMaybe<ProvisionFilterInput>;
};


export type QueryProvisionsReadyForExportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters: ExportablePovisionFilters;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReimbursementCaseByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReimbursementCasesCountArgs = {
  filters?: InputMaybe<ReimbursementCaseFiltersInput>;
};


export type QueryReimbursementCasesCursorBasedArgs = {
  filters?: InputMaybe<ReimbursementCaseFiltersInput>;
  input: ReimbursementCasePaginationInput;
  queries?: InputMaybe<ReimbursementCaseQueryInput>;
  sortBy?: InputMaybe<ReimbursementCaseSortInput>;
};


export type QueryReimbursementCasesPageBasedArgs = {
  filters?: InputMaybe<ReimbursementCaseFiltersInput>;
  input: ReimbursementCasePageBasedPaginationInput;
  queries?: InputMaybe<ReimbursementCaseQueryInput>;
  sortBy?: InputMaybe<ReimbursementCaseSortInput>;
};


export type QueryReimbursementCasesSummaryArgs = {
  filters?: InputMaybe<ReimbursementCasesSummaryFilterInput>;
};


export type QueryReimbursementItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReimbursementItemsArgs = {
  filters: ReimbursementItemFiltersInput;
  input?: InputMaybe<ReimbursementItemPageBasedPaginationInput>;
};


export type QueryReimbursementItemsReadyForExportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ExportableReimbursementItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ExportableReimbursementItemSortInput>;
};


export type QueryReversalsReadyForExportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters: ExportableReversalFilters;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolePermissionsArgs = {
  filters?: InputMaybe<PermissionFilterInput>;
  input: RolesPageBasedPaginationInput;
  roleId: Scalars['ID']['input'];
};


export type QueryRolesArgs = {
  filters?: InputMaybe<RolesFiltersInput>;
  input: RolesPageBasedPaginationInput;
  queries?: InputMaybe<RolesQueryInput>;
  sortBy?: InputMaybe<RolesSortInput>;
};


export type QuerySearchDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  searchInput?: InputMaybe<SearchInput>;
};


export type QuerySepaTransferXmlArgs = {
  paymentId: Scalars['ID']['input'];
};


export type QuerySfsSuggestionForApproverArgs = {
  contactId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
};


export type QuerySfsSuggestionForRequesterArgs = {
  contactId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
};


export type QueryTagArgs = {
  id: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  input?: InputMaybe<GetTagsInput>;
};


export type QueryTagsForDuplicationCheckArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};


export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTeamInvolvementsArgs = {
  filters: TeamInvolvementsInput;
};


export type QueryTeamMembersArgs = {
  input?: InputMaybe<GetUserInput>;
};


export type QueryTeamSettingsForCreditCardsArgs = {
  input: TeamSettingsForCreditCardsInput;
};


export type QueryTeamsArgs = {
  filters?: InputMaybe<TeamsFilterInput>;
  input: TeamsPageBasedPaginationInput;
  queries?: InputMaybe<TeamQueryInput>;
  sortBy?: InputMaybe<TeamsSortInput>;
};


export type QueryUnassociatedCardIssuerTransactionsArgs = {
  filters?: InputMaybe<InboxCardIssuerTransactionFiltersInput>;
  input: CardIssuerTransactionPaginationInput;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
  sortBy?: InputMaybe<CardIssuerTransactionSortInput>;
};


export type QueryVerifySapB1CredentialsArgs = {
  sapB1Credentials?: InputMaybe<SapB1CredentialsInput>;
};


export type QueryWorkflowTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkflowTemplatesArgs = {
  input?: InputMaybe<GetWorkflowTemplatesInput>;
  sort?: InputMaybe<SortInput>;
};

export type ReEvaluateExportResults = {
  __typename?: 'ReEvaluateExportResults';
  exportId: Scalars['String']['output'];
  unstuckDocuments: Array<Maybe<Scalars['String']['output']>>;
  unstuckEntities: Array<Maybe<Scalars['String']['output']>>;
  wasExportStuck: Scalars['Boolean']['output'];
};

export type RecentAuthnRequiredError = {
  __typename?: 'RecentAuthnRequiredError';
  requiredAuthType: IamAuthType;
  /** How long, in seconds, the user will be able to perform the same action before needing to reauthenticate again */
  validForSeconds: Scalars['Int']['output'];
};

export type RecentlyUsed = {
  __typename?: 'RecentlyUsed';
  entityType: RecentlyUsedEntity;
  values: Array<RecentlyUsedValue>;
};

export enum RecentlyUsedEntity {
  CostCenter = 'COST_CENTER',
  CostObject = 'COST_OBJECT',
  Gla = 'GLA',
  TaxCode = 'TAX_CODE'
}

export type RecentlyUsedValue = {
  __typename?: 'RecentlyUsedValue';
  id: Scalars['String']['output'];
  readableName: Scalars['String']['output'];
};

export type RecentlyUsedValueInput = {
  id: Scalars['String']['input'];
};

export type Recipient = {
  email: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RecurringPaymentExchangeRateDetail = {
  __typename?: 'RecurringPaymentExchangeRateDetail';
  rate: Scalars['Float']['output'];
  valueInBaseCurrency: Money;
};

export type RecurringPaymentInsightsAggregate = {
  __typename?: 'RecurringPaymentInsightsAggregate';
  aggregate: RecurringPaymentInsightsMonetaryDetail;
  details: Array<RecurringPaymentInsightsMonetaryDetail>;
};

export type RecurringPaymentInsightsMonetaryDetail = {
  __typename?: 'RecurringPaymentInsightsMonetaryDetail';
  count: Scalars['Int']['output'];
  exchangeRate?: Maybe<RecurringPaymentExchangeRateDetail>;
  value: Money;
};

export type RecurringPaymentVendor = IRecurringPaymentVendor & {
  __typename?: 'RecurringPaymentVendor';
  category?: Maybe<CardCategory>;
  id: Scalars['String']['output'];
  isIgnored: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  source: RecurringPaymentVendorSource;
};

export enum RecurringPaymentVendorSource {
  FromSuggestions = 'FROM_SUGGESTIONS',
  ManuallyAdded = 'MANUALLY_ADDED'
}

export type RecurringPaymentVendorWithLikedCards = IRecurringPaymentVendor & {
  __typename?: 'RecurringPaymentVendorWithLikedCards';
  category?: Maybe<CardCategory>;
  id: Scalars['String']['output'];
  isIgnored: Scalars['Boolean']['output'];
  linkedCards?: Maybe<Array<LinkedCardMetadata>>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  source: RecurringPaymentVendorSource;
};

export type RedirectSlackResponse = {
  __typename?: 'RedirectSlackResponse';
  redirectUrl?: Maybe<Scalars['String']['output']>;
  tokenValid: Scalars['Boolean']['output'];
};

export type ReimbursementBooking = {
  __typename?: 'ReimbursementBooking';
  amount?: Maybe<FloatField>;
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  bookingKey?: Maybe<BookingKeyField>;
  costCenter?: Maybe<CostCenterField>;
  costObject?: Maybe<CostCenterField>;
  dueDate?: Maybe<DateField>;
  extraCostInfo?: Maybe<CostCenterField>;
  generalLedgerAccount?: Maybe<BookingAccountField>;
  id: Scalars['ID']['output'];
  netAmount?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<StringField>;
  postingText?: Maybe<Scalars['String']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  vatRate?: Maybe<FloatField>;
};

export type ReimbursementBookingInput = {
  amount: Scalars['Float']['input'];
  artistSocialInsuranceCode?: InputMaybe<ArtistSocialInsuranceCode>;
  bookingKeyId?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  costObjectId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  extraCostInfoId?: InputMaybe<Scalars['String']['input']>;
  generalLedgerAccountId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  netAmount?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  postingText?: InputMaybe<Scalars['String']['input']>;
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};

export type ReimbursementCase = {
  __typename?: 'ReimbursementCase';
  advancedAccountingEnabled?: Maybe<Scalars['Boolean']['output']>;
  contact?: Maybe<ReimbursementCaseContact>;
  createTransfer: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  createdByMembership: User;
  errors: Array<ReimbursementError>;
  firstSubmittedForReviewAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  isPaid: Scalars['Boolean']['output'];
  lastApprovedByMembership?: Maybe<User>;
  note?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['Date']['output']>;
  status: ReimbursementCaseStatus;
  suggestedCostCenter?: Maybe<CostCenter>;
  suggestedCostObject?: Maybe<CostCenter>;
  suggestedGeneralLedgerAccount?: Maybe<BookingAccount>;
  targetMembership?: Maybe<User>;
  timeline?: Maybe<Array<ReimbursementTimeLineItem>>;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount: Money;
  updatedAt: Scalars['Date']['output'];
  workflow?: Maybe<Workflow>;
};

export type ReimbursementCaseAmendmentRequestedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseAmendmentRequestedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseAmendmentRequested;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseAmendmentRequestedInAppNotification = InAppNotification & {
  __typename?: 'ReimbursementCaseAmendmentRequestedInAppNotification';
  commentText: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  reimbursementCaseId: Scalars['String']['output'];
  reimbursementCaseTargetMembership: User;
  reimbursementCaseTitle: Scalars['String']['output'];
  reimbursementCaseTotalAmount: TransactionAmount;
  reviewedAt: Scalars['Date']['output'];
  reviewedBy: User;
};

export type ReimbursementCaseAndItemErrors = {
  __typename?: 'ReimbursementCaseAndItemErrors';
  reimbursementCaseErrors?: Maybe<ReimbursementCaseErrors>;
  reimbursementItemsErrors?: Maybe<Array<ReimbursementItemErrors>>;
};

export type ReimbursementCaseApprovedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseApprovedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseApproved;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseApprovedInAppNotification = InAppNotification & {
  __typename?: 'ReimbursementCaseApprovedInAppNotification';
  approvedAt: Scalars['Date']['output'];
  approvedBy: User;
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  reimbursementCaseId: Scalars['String']['output'];
  reimbursementCaseTargetMembership: User;
  reimbursementCaseTitle: Scalars['String']['output'];
  reimbursementCaseTotalAmount: TransactionAmount;
};

export type ReimbursementCaseApproverMembershipsFiltersInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReimbursementCaseApproverMembershipsPageBased = {
  __typename?: 'ReimbursementCaseApproverMembershipsPageBased';
  pageInfo: SimplePageInfoDetails;
  records?: Maybe<Array<MembershipForFilter>>;
};

export type ReimbursementCaseApproverMembershipsQueryInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum ReimbursementCaseApproverMembershipsSortField {
  ApproverMembershipFullName = 'APPROVER_MEMBERSHIP_FULL_NAME'
}

export type ReimbursementCaseApproverMembershipsSortInput = {
  direction: SortDirection;
  field: ReimbursementCaseApproverMembershipsSortField;
};

export type ReimbursementCaseCommentAddedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseCommentAddedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseCommentAdded;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseCommentCreatedInAppNotification = InAppNotification & {
  __typename?: 'ReimbursementCaseCommentCreatedInAppNotification';
  author: User;
  commentCreatedAt: Scalars['Date']['output'];
  commentText: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  reimbursementCaseId: Scalars['String']['output'];
  reimbursementCaseTargetMembership: User;
  reimbursementCaseTitle: Scalars['String']['output'];
  reimbursementCaseTotalAmount: TransactionAmount;
};

export type ReimbursementCaseContact = {
  __typename?: 'ReimbursementCaseContact';
  accountsPayableNumber?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  contactId?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
};

export type ReimbursementCaseCostCentersFiltersInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReimbursementCaseCostCentersPageBased = {
  __typename?: 'ReimbursementCaseCostCentersPageBased';
  pageInfo: SimplePageInfoDetails;
  records?: Maybe<Array<CostCenter>>;
};

export type ReimbursementCaseCostCentersQueryInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum ReimbursementCaseCostCentersSortField {
  Name = 'NAME'
}

export type ReimbursementCaseCostCentersSortInput = {
  direction: SortDirection;
  field: ReimbursementCaseCostCentersSortField;
};

export type ReimbursementCaseCreatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseCreatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseCreated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseDateFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  firstSubmittedForReviewAt?: InputMaybe<DateFilterInput>;
  paidAt?: InputMaybe<DateFilterInput>;
};

export type ReimbursementCaseDetails = {
  __typename?: 'ReimbursementCaseDetails';
  approvedInLast30DaysAmount: Money;
  approvedInLast30DaysCount: Scalars['Int']['output'];
  last30DaysAmount: Money;
  last30DaysCount: Scalars['Int']['output'];
  olderThan14DaysCount: Scalars['Int']['output'];
  totalAmount: Money;
  totalCount: Scalars['Int']['output'];
  withoutInvoiceCount: Scalars['Int']['output'];
};

export type ReimbursementCaseEdge = {
  __typename?: 'ReimbursementCaseEdge';
  cursor: Scalars['String']['output'];
  node: ReimbursementCase;
};

export type ReimbursementCaseErrors = {
  __typename?: 'ReimbursementCaseErrors';
  errors: Array<ReimbursementError>;
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ReimbursementCaseFilters = {
  __typename?: 'ReimbursementCaseFilters';
  approversIds?: Maybe<Array<User>>;
  archivedAt?: Maybe<DateFilter>;
  createTransfer?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<DateFilter>;
  createdByIds?: Maybe<Array<User>>;
  exportedAt?: Maybe<DateFilter>;
  firstSubmittedToReviewAt?: Maybe<DateFilter>;
  ids?: Maybe<Array<Scalars['String']['output']>>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  lastApprovedAt?: Maybe<DateFilter>;
  lastApprovedByIds?: Maybe<Array<User>>;
  lastSubmittedToApprovalAt?: Maybe<DateFilter>;
  paidAt?: Maybe<DateFilter>;
  requesterIds?: Maybe<Array<User>>;
  statuses?: Maybe<Array<ReimbursementCaseStatus>>;
  targetMembershipIbans?: Maybe<Array<Scalars['String']['output']>>;
  targetMembershipIds?: Maybe<Array<User>>;
  totalAmount?: Maybe<AmountFilters>;
  updatedAt?: Maybe<DateFilter>;
  updatedByIds?: Maybe<Array<User>>;
};

export type ReimbursementCaseFiltersInput = {
  costCenterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  costObjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  currentApproverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dateRangeFilters?: InputMaybe<ReimbursementCaseDateFilterInput>;
  generalLedgerAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ibans?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<ReimbursementCaseStatus>>;
  targetMembershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  titles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReimbursementCaseItemAddedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseItemAddedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseItemAdded;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseItemDatevLocationParams = {
  __typename?: 'ReimbursementCaseItemDatevLocationParams';
  datevClientNumber?: Maybe<Scalars['String']['output']>;
  datevConsultantNumber?: Maybe<Scalars['String']['output']>;
  entityExportGuid?: Maybe<Scalars['String']['output']>;
};

export type ReimbursementCaseItemExcludedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseItemExcludedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseItemExcluded;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseItemExcludedInAppNotification = InAppNotification & {
  __typename?: 'ReimbursementCaseItemExcludedInAppNotification';
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  reimbursementCaseId: Scalars['String']['output'];
  reimbursementCaseTargetMembership: User;
  reimbursementCaseTitle: Scalars['String']['output'];
  reimbursementCaseTotalAmount: TransactionAmount;
  title?: Maybe<Scalars['String']['output']>;
  totalAmount: TransactionAmount;
  type: ReimbursementItemType;
  updatedAt: Scalars['Date']['output'];
  updatedBy: User;
};

export type ReimbursementCaseItemExportStatusUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseItemExportStatusUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseItemExportStatusUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export enum ReimbursementCaseItemExportType {
  AddisonFormat = 'ADDISON_FORMAT',
  Api = 'API',
  CandisCsv = 'CANDIS_CSV',
  Datev = 'DATEV',
  DatevBds = 'DATEV_BDS',
  DatevCsv = 'DATEV_CSV',
  DatevDxsoZip = 'DATEV_DXSO_ZIP',
  SapB1 = 'SAP_B1',
  SapCsv = 'SAP_CSV',
  Zip = 'ZIP'
}

export type ReimbursementCaseItemIncludedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseItemIncludedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseItemIncluded;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseItemRemovedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseItemRemovedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseItemRemoved;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseNoteUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseNoteUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseNoteUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCasePageBasedPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ReimbursementCasePaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ReimbursementCaseQueryInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ReimbursementCaseRejectedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseRejectedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseRejected;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseRejectedInAppNotification = InAppNotification & {
  __typename?: 'ReimbursementCaseRejectedInAppNotification';
  commentText: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  reimbursementCaseId: Scalars['String']['output'];
  reimbursementCaseTargetMembership: User;
  reimbursementCaseTitle: Scalars['String']['output'];
  reimbursementCaseTotalAmount: TransactionAmount;
  rejectedAt: Scalars['Date']['output'];
  rejectedBy: User;
};

export type ReimbursementCaseResetApprovalEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseResetApprovalEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseResetApproval;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export enum ReimbursementCaseSortField {
  ApproverMembershipFullName = 'APPROVER_MEMBERSHIP_FULL_NAME',
  CostCenter = 'COST_CENTER',
  CreatedAt = 'CREATED_AT',
  PaidAt = 'PAID_AT',
  Status = 'STATUS',
  SubmittedForReviewAt = 'SUBMITTED_FOR_REVIEW_AT',
  TargetMembershipFullName = 'TARGET_MEMBERSHIP_FULL_NAME',
  Title = 'TITLE',
  TotalAmount = 'TOTAL_AMOUNT',
  UpdatedAt = 'UPDATED_AT'
}

export type ReimbursementCaseSortInput = {
  direction: SortDirection;
  field: ReimbursementCaseSortField;
};

export type ReimbursementCaseStateChangeResponse = {
  __typename?: 'ReimbursementCaseStateChangeResponse';
  errors: ReimbursementCaseAndItemErrors;
  success: Scalars['Boolean']['output'];
};

export { ReimbursementCaseStatus };

export type ReimbursementCaseSubmittedForApprovalEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseSubmittedForApprovalEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseSubmittedForApproval;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseSubmittedForReviewEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseSubmittedForReviewEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseSubmittedForReview;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseTargetMembershipUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseTargetMembershipUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseTargetMembershipUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseTargetMembershipsFiltersInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReimbursementCaseTargetMembershipsPageBased = {
  __typename?: 'ReimbursementCaseTargetMembershipsPageBased';
  pageInfo: SimplePageInfoDetails;
  records?: Maybe<Array<MembershipForFilter>>;
};

export type ReimbursementCaseTargetMembershipsQueryInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum ReimbursementCaseTargetMembershipsSortField {
  TargetMembershipFullName = 'TARGET_MEMBERSHIP_FULL_NAME'
}

export type ReimbursementCaseTargetMembershipsSortInput = {
  direction: SortDirection;
  field: ReimbursementCaseTargetMembershipsSortField;
};

export type ReimbursementCaseTitleUpdatedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseTitleUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseTitleUpdated;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseTriggeredWorkflowEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseTriggeredWorkflowEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseTriggeredWorkflow;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseWorkflowStepApprovedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseWorkflowStepApprovedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseWorkflowStepApproved;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseWorkflowStepRejectedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseWorkflowStepRejectedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseWorkflowStepRejected;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCaseWorkflowStepSkippedEvent = ReimbursementTimeLineItem & {
  __typename?: 'ReimbursementCaseWorkflowStepSkippedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityReimbursementCaseWorkflowStepSkipped;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
  user: ActivityUser;
};

export type ReimbursementCasesConnection = {
  __typename?: 'ReimbursementCasesConnection';
  edges?: Maybe<Array<ReimbursementCaseEdge>>;
  node?: Maybe<Array<ReimbursementCase>>;
  pageInfo: PageInfo;
};

export type ReimbursementCasesConnectionPageBased = {
  __typename?: 'ReimbursementCasesConnectionPageBased';
  pageInfo: SimplePageInfoDetails;
  records?: Maybe<Array<ReimbursementCase>>;
};

export type ReimbursementCasesCountResponse = {
  __typename?: 'ReimbursementCasesCountResponse';
  totalCount: Scalars['Int']['output'];
};

export type ReimbursementCasesCounts = {
  __typename?: 'ReimbursementCasesCounts';
  total: Scalars['Int']['output'];
};

export type ReimbursementCasesDateFilter = {
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};

export type ReimbursementCasesFilterInput = {
  approversIds?: InputMaybe<Array<Scalars['String']['input']>>;
  archivedAt?: InputMaybe<ReimbursementCasesDateFilter>;
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<ReimbursementCasesDateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  exportedAt?: InputMaybe<ReimbursementCasesDateFilter>;
  firstSubmittedToReviewAt?: InputMaybe<ReimbursementCasesDateFilter>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  lastApprovedAt?: InputMaybe<ReimbursementCasesDateFilter>;
  lastApprovedByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  lastClosedAt?: InputMaybe<ReimbursementCasesDateFilter>;
  lastSubmittedToApprovalAt?: InputMaybe<ReimbursementCasesDateFilter>;
  paidAt?: InputMaybe<ReimbursementCasesDateFilter>;
  requesterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<ReimbursementCaseStatus>>;
  targetMembershipIbans?: InputMaybe<Array<Scalars['String']['input']>>;
  targetMembershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  totalAmount?: InputMaybe<AmountFilter>;
  updatedAt?: InputMaybe<ReimbursementCasesDateFilter>;
  updatedByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReimbursementCasesInsight = {
  __typename?: 'ReimbursementCasesInsight';
  toApprove?: Maybe<TasksCountsInsight>;
  toCheck?: Maybe<TasksCountsInsight>;
  toExport?: Maybe<TasksCountsInsight>;
  toReview?: Maybe<TasksCountsInsight>;
};

export type ReimbursementCasesPageInfo = {
  __typename?: 'ReimbursementCasesPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextPageCursor?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type ReimbursementCasesSortInput = {
  createdAt?: InputMaybe<ReimbursementCasesSortOrder>;
  firstSubmittedToReviewAt?: InputMaybe<ReimbursementCasesSortOrder>;
  isPaid?: InputMaybe<ReimbursementCasesSortOrder>;
  lastApprovedByName?: InputMaybe<ReimbursementCasesSortOrder>;
  lastClosedAt?: InputMaybe<ReimbursementCasesSortOrder>;
  lastSubmittedToApprovalAt?: InputMaybe<ReimbursementCasesSortOrder>;
  paidAt?: InputMaybe<ReimbursementCasesSortOrder>;
  status?: InputMaybe<ReimbursementCasesSortOrder>;
  targetMembershipIban?: InputMaybe<ReimbursementCasesSortOrder>;
  targetMembershipName?: InputMaybe<ReimbursementCasesSortOrder>;
  title?: InputMaybe<ReimbursementCasesSortOrder>;
  totalAmount?: InputMaybe<ReimbursementCasesSortOrder>;
  updatedAt?: InputMaybe<ReimbursementCasesSortOrder>;
};

export enum ReimbursementCasesSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ReimbursementCasesSummary = {
  __typename?: 'ReimbursementCasesSummary';
  approved: ReimbursementCaseDetails;
  closed: ReimbursementCaseDetails;
  draft: ReimbursementCaseDetails;
  open: ReimbursementCaseDetails;
};

export type ReimbursementCasesSummaryFilterInput = {
  targetMembershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReimbursementError = {
  __typename?: 'ReimbursementError';
  errors: Array<ReimbursementErrorType>;
  field: Scalars['String']['output'];
};

export { ReimbursementErrorType };

export type ReimbursementItemErrors = {
  __typename?: 'ReimbursementItemErrors';
  errors: Array<ReimbursementError>;
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: ReimbursementItemType;
};

export type ReimbursementItemFiltersInput = {
  reimbursementCaseId: Scalars['String']['input'];
};

export type ReimbursementItemMetadata = {
  __typename?: 'ReimbursementItemMetadata';
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ReimbursementItemType>;
};

export type ReimbursementItemPageBasedPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum ReimbursementItemStatus {
  Archived = 'ARCHIVED',
  Excluded = 'EXCLUDED',
  Exported = 'EXPORTED',
  Exporting = 'EXPORTING',
  Included = 'INCLUDED',
  ReadyToExport = 'READY_TO_EXPORT'
}

export enum ReimbursementItemStatusInput {
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export enum ReimbursementItemType {
  GeneralExpense = 'GENERAL_EXPENSE',
  HospitalityExpense = 'HOSPITALITY_EXPENSE',
  Mileage = 'MILEAGE',
  PerDiem = 'PER_DIEM'
}

export type ReimbursementTimeLineItem = {
  clientInfo?: Maybe<ClientInfo>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reimbursementItemMetadata?: Maybe<ReimbursementItemMetadata>;
  resourceId: Scalars['String']['output'];
};

export type RejectCardRequestInput = {
  cardRequestId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
};

/** Document rejection event */
export type RejectedEvent = DocumentTimeLineItem & {
  __typename?: 'RejectedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** The author that posted the comment */
  comment?: Maybe<ActivityComment>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that declined the document */
  user?: Maybe<ActivityUser>;
};

export type RelatedDocument = {
  __typename?: 'RelatedDocument';
  document?: Maybe<DocumentUnion>;
  id: Scalars['String']['output'];
  type: DocumentType;
};

export type RelatedDocumentError = {
  __typename?: 'RelatedDocumentError';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type RelatedDocumentUnion = RelatedDocument | RelatedDocumentError;

export type RemoveCommentInput = {
  id: Scalars['ID']['input'];
};

export type RemoveCommentOutput = {
  __typename?: 'RemoveCommentOutput';
  deleted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type RemoveEcmDocumentInput = {
  id: Scalars['String']['input'];
};

export type RemoveRoleMembershipInput = {
  membershipId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type RequestApprovalError = AccountingAreaDoesNotExistError | AccountingAreaIsArchivedError | AccountingAreaRequiredError | BookingKeyArchivedError | BookingKeyDoesNotExistError | BookingsError | ContactApnRequiredError | ContactArnRequiredError | ContactDoesNotExistError | ContactIsArchivedError | CostCenterArchivedError | CostCenterDoesNotExistError | CostObjectArchivedError | CostObjectDoesNotExistError | DeliveryDateConflictError | DocumentCannotBeModifedError | DocumentDoesNotExistError | DocumentGrossNetTaxRoundingAmountsCalculationError | DocumentGrossNetTaxRoundingAmountsValidationError | DocumentIsAlreadyRequestedForApprovalError | ExtraCostInfoArchivedError | ExtraCostInfoDoesNotExistError | GeneralLedgerArchivedError | GeneralLedgerDoesNotExistError | InvalidBookingAmountError | InvalidDeliveryDateError | InvalidDueDateError | InvalidGrossAmountError | InvalidInvoiceDateError | InvalidSwiftCodeError | PayeeDoesNotExistError | SumOfBookingsDoesNotMatchGrossAmountError | SwiftCodeAndIbanCountryMismatchError | SwiftCodeMissingIbanError | TransactionDataMismatchError | UnexpectedDocumentApprovalError | WorkflowTemplateArchivedError | WorkflowTemplateDoesNotExistError | WrongDocumentTypeConnectedToSapEntityError | XRechnungInvoiceHasNoPdfError;

/** Document request approval event */
export type RequestApprovalEvent = DocumentTimeLineItem & {
  __typename?: 'RequestApprovalEvent';
  /** List of users who can approve a step */
  approvers?: Maybe<Array<Maybe<SubstituteUser>>>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /**
   * List of users who can approve a step
   * @deprecated Substitute information added to approvers
   */
  requestedFrom?: Maybe<Array<Maybe<SubstituteUser>>>;
  /** The user that requested the document to be approved */
  user?: Maybe<ActivityUser>;
};

export type RequestCardLimitChangeInput = {
  cardId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
  requestedLimit: CardIssuerAmountInput;
  requestedLimitRenewFrequency?: InputMaybe<CardLimitRenewFrequency>;
  requestedTransactionLimit: CardIssuerAmountInput;
};

export type RequestChangeUserEmailConflict = {
  __typename?: 'RequestChangeUserEmailConflict';
  email: Scalars['String']['output'];
};

export type RequestChangeUserEmailInput = {
  membershipId: Scalars['String']['input'];
  newEmail: Scalars['EmailAddress']['input'];
};

export type RequestChangeUserEmailResult = RequestChangeUserEmailConflict | RequestChangeUserEmailSucceeded;

export type RequestChangeUserEmailSucceeded = {
  __typename?: 'RequestChangeUserEmailSucceeded';
  email: Scalars['String']['output'];
};

export type RequestDocumentApprovalInput = {
  approverIds?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  documentId: Scalars['String']['input'];
  workflowTemplateId?: InputMaybe<Scalars['String']['input']>;
};

export type RequestGoBdReportCsvInput = {
  fromDate: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  recipientEmail: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
};

export type RequestPhysicalCardInput = {
  acceptedCardAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  accountingData?: InputMaybe<CardAccountingDataInput>;
  category?: InputMaybe<CardCategory>;
  comment: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  requestedLimit: CardIssuerAmountInput;
  requestedLimitRenewFrequency: CardLimitRenewFrequency;
  requestedTransactionLimit: CardIssuerAmountInput;
};

export type RequestReimbursementCaseAmendmentInput = {
  caseId: Scalars['String']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text: Scalars['String']['input'];
};

export type RequestSingleUseVirtualCardInput = {
  acceptedCardAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  accountingData?: InputMaybe<CardAccountingDataInput>;
  category?: InputMaybe<CardCategory>;
  purpose: Scalars['String']['input'];
  requestedTransactionLimit: CardIssuerAmountInput;
};

export type RequestVirtualCardInput = {
  acceptedCardAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  accountingData?: InputMaybe<CardAccountingDataInput>;
  category?: InputMaybe<CardCategory>;
  comment: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  requestedLimit: CardIssuerAmountInput;
  requestedLimitRenewFrequency: CardLimitRenewFrequency;
  requestedTransactionLimit: CardIssuerAmountInput;
  validityPeriod: Scalars['Int']['input'];
};

export type Resource = {
  __typename?: 'Resource';
  value: Scalars['String']['output'];
};

export type RetainedDeletedDocument = {
  __typename?: 'RetainedDeletedDocument';
  attachments?: Maybe<Array<DeletedDocumentFile>>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  entityType: Scalars['String']['output'];
  file?: Maybe<DeletedDocumentFile>;
  impersonatedByUserId?: Maybe<Scalars['String']['output']>;
  operationName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
};

export type Reversal = {
  __typename?: 'Reversal';
  /** @deprecated Use account in Bookings */
  account: Scalars['String']['output'];
  bookings?: Maybe<Array<ProvisionBooking>>;
  contact: ReversalContact;
  deliveryDate: Scalars['Date']['output'];
  entity: ProvisionEntity;
  /** @deprecated Use glAccounts in Bookings */
  glAccount: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceDate: Scalars['Date']['output'];
  netAmount: Money;
};

export type ReversalConnection = {
  __typename?: 'ReversalConnection';
  edges?: Maybe<Array<Reversal>>;
  node?: Maybe<Array<Reversal>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ReversalContact = {
  __typename?: 'ReversalContact';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Reversal created event */
export type ReversalCreatedEvent = DocumentTimeLineItem & {
  __typename?: 'ReversalCreatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
};

/** Document Reversal export event */
export type ReversalExportedEvent = DocumentTimeLineItem & {
  __typename?: 'ReversalExportedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Id of the related export entry */
  exportId?: Maybe<Scalars['String']['output']>;
  /** What kind of export this Event contains */
  exportType?: Maybe<ExportType>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that exported the document */
  user?: Maybe<ActivityUser>;
};

export type RevokeCandisApiSessionError = {
  __typename?: 'RevokeCandisAPISessionError';
  kind: RevokeCandisApiSessionErrorKind;
  message: Scalars['String']['output'];
};

export enum RevokeCandisApiSessionErrorKind {
  Forbidden = 'FORBIDDEN',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type RevokeCandisApiSessionResult = RevokeCandisApiSessionError | RevokeCandisApiSessionSuccessful;

export type RevokeCandisApiSessionSuccessful = {
  __typename?: 'RevokeCandisAPISessionSuccessful';
  isRevoked: Scalars['Boolean']['output'];
};

export type Role = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  members: Array<User>;
  permissions: Array<Permission>;
};

export type RolesFiltersInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isBuiltinRole?: InputMaybe<Scalars['Boolean']['input']>;
  isLegacyRole?: InputMaybe<Scalars['Boolean']['input']>;
  membershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RolesPageBasedPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type RolesQueryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum RolesSortField {
  Name = 'NAME'
}

export type RolesSortInput = {
  direction: SortDirection;
  field: RolesSortField;
};

export type SsoConfiguration = {
  __typename?: 'SSOConfiguration';
  canUserRegisterViaSSO: Scalars['Boolean']['output'];
  emailDomains: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idpAlias: Scalars['String']['output'];
  isMembershipRolesUpdatedViaSSO: Scalars['Boolean']['output'];
  isUserDataUpdatedViaSSO: Scalars['Boolean']['output'];
  owner: Scalars['String']['output'];
};

export type SapB1Credentials = {
  __typename?: 'SapB1Credentials';
  companyDbId: Scalars['String']['output'];
  serviceLayerUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type SapB1CredentialsInput = {
  companyDbId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  serviceLayerUrl: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type SapB1Data = {
  __typename?: 'SapB1Data';
  cardCode?: Maybe<Scalars['String']['output']>;
  cardName?: Maybe<Scalars['String']['output']>;
  docEntry?: Maybe<Scalars['Int']['output']>;
  docNumber?: Maybe<Scalars['Int']['output']>;
  seriesNumber?: Maybe<Scalars['Int']['output']>;
  supplierRefNumber?: Maybe<Scalars['String']['output']>;
};

/** An error response object from SAP B1 export (returned if export fails) */
export type SapB1ExportErrorResponse = {
  __typename?: 'SapB1ExportErrorResponse';
  code: SapB1ExportErrorResponseCode;
};

/**
 * An error code that can be returned during the export for the whole
 * export record when the export fails
 */
export enum SapB1ExportErrorResponseCode {
  AuthError = 'AUTH_ERROR',
  BadRequest = 'BAD_REQUEST',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

/** A response object from SAP B1 export */
export type SapB1ExportResponse = {
  __typename?: 'SapB1ExportResponse';
  exportGuid: Scalars['String']['output'];
};

export type SapB1ExportResult = SapB1ExportErrorResponse | SapB1ExportResponse;

/** A Document that has been approved and it's ready to be exported with SAP B1 API */
export type SapB1ExportableDocument = {
  __typename?: 'SapB1ExportableDocument';
  amount: Money;
  contact: Contact;
  /** Universal identifier for the document */
  documentGuid: Scalars['ID']['output'];
  /** MongoDB identifier for the document */
  id: Scalars['ID']['output'];
  /** The date displayed on the invoice */
  invoiceDate: Scalars['Date']['output'];
  invoiceNumber: Scalars['String']['output'];
};

/**
 * An error code that can be returned for individual export entity
 * when the export fails
 */
export enum SapB1ExportedEntityErrorCode {
  DocumentFilteredOut = 'DOCUMENT_FILTERED_OUT',
  SapB1ApiError = 'SAP_B1_API_ERROR',
  UnexpectedError = 'UNEXPECTED_ERROR',
  ValidationFailed = 'VALIDATION_FAILED'
}

export type SapB1GoodsReceiptsImportFilters = {
  accountsPayableNumber?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  receiptNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum SapB1ImportErrorCode {
  AuthError = 'AUTH_ERROR',
  BadRequest = 'BAD_REQUEST',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export type SapB1ImportResponse = {
  __typename?: 'SapB1ImportResponse';
  code: SapB1ImportErrorCode;
};

export type SapB1PurchaseOrdersImportFilters = {
  accountsPayableNumber?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SapB1Response = {
  __typename?: 'SapB1Response';
  code: SapB1SettingsErrorCode;
};

export type SapB1Settings = {
  __typename?: 'SapB1Settings';
  connectionDetails?: Maybe<ConnectionDetails>;
  creditCardGeneralLedgerAccount?: Maybe<Scalars['String']['output']>;
  creditCardId?: Maybe<Scalars['Int']['output']>;
  moneyInTransitGeneralLedgerAccount?: Maybe<Scalars['String']['output']>;
  outgoingPaymentSeries?: Maybe<Scalars['Int']['output']>;
  purchaseInvoiceSeries: Scalars['Int']['output'];
  sapB1Version?: Maybe<Scalars['String']['output']>;
};

export enum SapB1SettingsErrorCode {
  CredentialsNotFound = 'CREDENTIALS_NOT_FOUND',
  InvalidLoginCredentials = 'INVALID_LOGIN_CREDENTIALS',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export type SapB1SettingsInput = {
  creditCardGeneralLedgerAccount?: InputMaybe<Scalars['String']['input']>;
  creditCardId?: InputMaybe<Scalars['Int']['input']>;
  moneyInTransitGeneralLedgerAccount?: InputMaybe<Scalars['String']['input']>;
  outgoingPaymentSeries?: InputMaybe<Scalars['Int']['input']>;
  purchaseInvoiceSeries: Scalars['Int']['input'];
  sapB1Version?: InputMaybe<Scalars['String']['input']>;
};

export enum SapExpenseType {
  Freight = 'FREIGHT',
  Packaging = 'PACKAGING'
}

export type SavePromoPreferenceInput = {
  promoId: Scalars['String']['input'];
};

export type SaveRecentlyUsedInput = {
  entityType: RecentlyUsedEntity;
  value: RecentlyUsedValueInput;
};

export type SaveTablePreferenceInput = {
  columns: Array<TableColumnInput>;
  id: Scalars['String']['input'];
};

export type SearchInput = {
  searchScope?: InputMaybe<SearchScope>;
  searchType?: InputMaybe<SearchType>;
};

export enum SearchScope {
  DataFields = 'DATA_FIELDS',
  EntireDocument = 'ENTIRE_DOCUMENT'
}

export enum SearchType {
  Contains = 'CONTAINS',
  ExactMatch = 'EXACT_MATCH',
  PostfixMatch = 'POSTFIX_MATCH',
  PrefixMatch = 'PREFIX_MATCH'
}

export type SendEmailInput = {
  documentId: Scalars['String']['input'];
  emailInput: SendEmailWithoutTemplateArgs;
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  _id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SendEmailWithoutTemplateArgs = {
  bcc?: InputMaybe<Array<Recipient>>;
  cc?: InputMaybe<Array<Recipient>>;
  htmlBody?: InputMaybe<Scalars['String']['input']>;
  recipients: Array<Recipient>;
  replyTo?: InputMaybe<Recipient>;
  subject?: InputMaybe<Scalars['String']['input']>;
  textBody?: InputMaybe<Scalars['String']['input']>;
};

export type SendMobileAppInvitationEmailInput = {
  recipients: Array<MobileAppInvitationEmailRecipient>;
};

/** An invoice document that is fully ready for SEPA XML export */
export type SepaExportableDocument = PayableDocument & {
  __typename?: 'SepaExportableDocument';
  amount: Money;
  contact: Contact;
  /** IBAN from the receiving party */
  creditorIban: Scalars['IBAN']['output'];
  creditorSwiftCode?: Maybe<Scalars['String']['output']>;
  currency: DocumentCurrency;
  /** Discount fields */
  discountAmount?: Maybe<Money>;
  discountPaymentDate?: Maybe<Scalars['Date']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** The date displayed on the invoice */
  invoiceDate: Scalars['Date']['output'];
  invoiceDueDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber: Scalars['String']['output'];
  status: DocumentStatus;
};

export type SepaTransferPayment = {
  __typename?: 'SepaTransferPayment';
  creationDate: Scalars['Date']['output'];
  currency: DocumentCurrency;
  debtorIban: Scalars['IBAN']['output'];
  id: Scalars['ID']['output'];
  numberOfPaidDocuments: Scalars['Int']['output'];
  /** The user who initiated the payment */
  paidDocuments: Array<PaidDocument>;
  paymentInitiator: User;
  swiftCode?: Maybe<Scalars['String']['output']>;
  totalAmount: Money;
};

export type SepaXml = {
  __typename?: 'SepaXml';
  /** Stringified XML */
  content: Scalars['String']['output'];
  currency: DocumentCurrency;
  fileName: Scalars['String']['output'];
};

export type SepaXmlInput = {
  /** IBAN from the sender */
  debtorIban: Scalars['IBAN']['input'];
  documentIds: Array<Scalars['ID']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Valid SEPA XML or errors */
export type SepaXmlResponse = SepaXml | SepaXmlValidationError;

/** Sepa XML XSD Schema validation Error */
export type SepaXmlValidationError = {
  __typename?: 'SepaXmlValidationError';
  message: Scalars['String']['output'];
};

export type ServiceLineItem = {
  __typename?: 'ServiceLineItem';
  description: Scalars['String']['output'];
  generalLedgerAccount: Scalars['String']['output'];
  itemNumber: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  lineTotal: Money;
  taxCode: Scalars['String']['output'];
  taxTotal: Money;
};

export type SetAbsenceError = {
  __typename?: 'SetAbsenceError';
  kind?: Maybe<SetAbsenceErrorKind>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum SetAbsenceErrorKind {
  Forbidden = 'FORBIDDEN',
  UpdatingSubstituteInOpenDocumentsFailed = 'UPDATING_SUBSTITUTE_IN_OPEN_DOCUMENTS_FAILED',
  ValidationError = 'VALIDATION_ERROR'
}

export type SetAbsenceForTeamMemberInput = {
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  substituteMembershipId?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  userMembershipId?: InputMaybe<Scalars['String']['input']>;
};

export type SetCurrentUserAbsenceInput = {
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  substituteMembershipId?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type SetOrganizationMembershipsStatusInput = {
  organizationId: Scalars['String']['input'];
  status: MembershipStatus;
};

export type SetOrganizationMembershipsStatusResponse = {
  __typename?: 'SetOrganizationMembershipsStatusResponse';
  notUpdatedMemberships?: Maybe<Scalars['Int']['output']>;
  updatedMemberships?: Maybe<Scalars['Int']['output']>;
};

export type SetRecurringPaymentVendorIgnoredStateInput = {
  ignored: Scalars['Boolean']['input'];
  vendorId: Scalars['String']['input'];
};

export type SetTeamSettingsForCreditCardsInput = {
  limitThresholds?: InputMaybe<CreditCardLimitThresholdsInput>;
  linkedCardIds: Array<Scalars['String']['input']>;
  teamAdminCanManageCards: Scalars['Boolean']['input'];
  teamId: Scalars['String']['input'];
};

export type SetTransactionInvoiceNeededInput = {
  transactionId: Scalars['String']['input'];
};

export type SetTransactionInvoiceNotNeededInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reason: CardIssuerTransactionInvoiceNotNeededReason;
  transactionId: Scalars['String']['input'];
};

export type SetUserAbsenceResult = Absence | SetAbsenceError;

export type SfsApproversSuggestion = {
  __typename?: 'SfsApproversSuggestion';
  confidence: Scalars['Float']['output'];
  value?: Maybe<Array<Array<Scalars['String']['output']>>>;
};

export type SfsBookingKeySuggestion = {
  __typename?: 'SfsBookingKeySuggestion';
  confidence: Scalars['Float']['output'];
  value: BookingKey;
};

export type SfsCostCenterSuggestion = {
  __typename?: 'SfsCostCenterSuggestion';
  confidence: Scalars['Float']['output'];
  value: CostCenter;
};

export type SfsGeneralLedgerAccountSuggestion = {
  __typename?: 'SfsGeneralLedgerAccountSuggestion';
  confidence: Scalars['Float']['output'];
  value: BookingAccount;
};

export enum SfsSettingSwitchMethod {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Reactivated = 'REACTIVATED'
}

export type SfsSettings = {
  __typename?: 'SfsSettings';
  bookingKey: SfsSettingsSwitch;
  costCenter: SfsSettingsSwitch;
  costObject: SfsSettingsSwitch;
  generalLedgerAccount: SfsSettingsSwitch;
};

export type SfsSettingsSwitch = {
  __typename?: 'SfsSettingsSwitch';
  method: SfsSettingSwitchMethod;
  on: Scalars['Boolean']['output'];
};

export type SfsSuggestions = {
  __typename?: 'SfsSuggestions';
  approverUserIds?: Maybe<SfsApproversSuggestion>;
  bookingKey?: Maybe<SfsBookingKeySuggestion>;
  costCenter1?: Maybe<SfsCostCenterSuggestion>;
  costCenter2?: Maybe<SfsCostCenterSuggestion>;
  generalLedgerAccount?: Maybe<SfsGeneralLedgerAccountSuggestion>;
  workflowTemplate?: Maybe<SfsWorkflowTemplateSuggestion>;
};

export type SfsWorkflowTemplateSuggestion = {
  __typename?: 'SfsWorkflowTemplateSuggestion';
  confidence: Scalars['Float']['output'];
  value: SfsWorkflowTemplateSuggestionValue;
};

export type SfsWorkflowTemplateSuggestionValue = {
  __typename?: 'SfsWorkflowTemplateSuggestionValue';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SimplePageInfo = {
  __typename?: 'SimplePageInfo';
  currentPage: Scalars['Int']['output'];
  exceedsPageLimit: Scalars['Boolean']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SimplePageInfoDetails = {
  __typename?: 'SimplePageInfoDetails';
  currentPage: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SingleBookingUpdatedEvent = DocumentTimeLineItem & {
  __typename?: 'SingleBookingUpdatedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  fields: Array<BookingField>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that updated the bookings */
  user?: Maybe<ActivityUser>;
};

export type SingleUseVirtualCardApprovedEvent = CardTimeLineItem & {
  __typename?: 'SingleUseVirtualCardApprovedEvent';
  approvedTransactionLimit: TransactionAmount;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type SingleUseVirtualCardRequest = CardIssuerCardRequest & {
  __typename?: 'SingleUseVirtualCardRequest';
  approvedTransactionLimit?: Maybe<TransactionAmount>;
  category?: Maybe<CardCategory>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  purpose: Scalars['String']['output'];
  requestedTransactionLimit: TransactionAmount;
  resolutionComment?: Maybe<Scalars['String']['output']>;
  status: CardRequestStatus;
  team?: Maybe<Team>;
  type: CardType;
  validityPeriod: Scalars['Int']['output'];
};

export type SingleUseVirtualCardRequestedEvent = CardTimeLineItem & {
  __typename?: 'SingleUseVirtualCardRequestedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  requestedTransactionLimit: TransactionAmount;
  user: ActivityUser;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortInput = {
  field: Scalars['String']['input'];
  order?: InputMaybe<Order>;
};

export type SpendOverviewInsightsDateFilterInput = {
  dateFilter: DateFilterInput;
};

export type SpendOverviewInsightsFilterInput = {
  cardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cardTypes?: InputMaybe<Array<CardType>>;
  cardholderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<TransactionCategory>>;
  merchantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  merchantNames?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<TransactionStatus>>;
  teamIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<TransactionType>>;
};

export type SpendOverviewInsightsInput = {
  granularity?: InputMaybe<InsightGranularity>;
};

export enum SplitActionTypes {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export type SplitBooking = {
  __typename?: 'SplitBooking';
  action?: Maybe<SplitActionTypes>;
  fields: Array<BookingField>;
  number?: Maybe<Scalars['Int']['output']>;
};

export type SplitBookingUpdatedEvent = DocumentTimeLineItem & {
  __typename?: 'SplitBookingUpdatedEvent';
  bookings: Array<SplitBooking>;
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that updated the bookings */
  user?: Maybe<ActivityUser>;
};

export type StoreEcmDocumentInput = {
  amount?: InputMaybe<MoneyInput>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  documentDate?: InputMaybe<Scalars['Date']['input']>;
  documentName?: InputMaybe<Scalars['String']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  documentSubCategory?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  globalDocumentId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated This field is no longer needed and will be removed in future versions. */
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notifyPerson?: InputMaybe<EcmDocumentNotifyPersonInput>;
  responsiblePerson?: InputMaybe<EcmDocumentResponsiblePersonInput>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  terminationDate?: InputMaybe<Scalars['Date']['input']>;
  terminationReminderDate?: InputMaybe<Scalars['Date']['input']>;
};

export type StoreEcmDocumentResponse = {
  __typename?: 'StoreEcmDocumentResponse';
  ecmDocumentId: Scalars['String']['output'];
};

export type StoreSensitiveEcmDocumentInput = {
  amount?: InputMaybe<MoneyInput>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  documentDate?: InputMaybe<Scalars['Date']['input']>;
  documentName?: InputMaybe<Scalars['String']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  documentSubCategory?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  notifyPerson?: InputMaybe<EcmDocumentNotifyPersonInput>;
  responsiblePerson?: InputMaybe<EcmDocumentResponsiblePersonInput>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  terminationDate?: InputMaybe<Scalars['Date']['input']>;
  terminationReminderDate?: InputMaybe<Scalars['Date']['input']>;
};

export type StringBookingValue = {
  __typename?: 'StringBookingValue';
  hasChanged: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StringField = {
  __typename?: 'StringField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Array<Maybe<StringSource>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type StringSource = {
  __typename?: 'StringSource';
  confidence: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type SubmitReimbursementCaseForApprovalInput = {
  approverIds?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  reimbursementCaseId: Scalars['String']['input'];
  workflowTemplateId?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitReimbursementCaseWorkflowStepInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reimbursementCaseId: Scalars['String']['input'];
  resolution: WorkflowStepResolutionTypes;
  stepId: Scalars['String']['input'];
};

export type SubmitWorkflowStepError = AccountingAreaDoesNotExistError | AccountingAreaIsArchivedError | AccountingAreaRequiredError | BookingKeyArchivedError | BookingKeyDoesNotExistError | BookingKeyRequiredError | BookingModificationNotAllowedError | BookingsError | CostCenterArchivedError | CostCenterDoesNotExistError | CostCenterRequiredError | CostObjectArchivedError | CostObjectDoesNotExistError | CostObjectRequiredError | DocumentCannotBeModifedError | DocumentDoesNotExistError | DocumentGrossNetTaxRoundingAmountsCalculationError | DocumentGrossNetTaxRoundingAmountsValidationError | ExtraCostInfoArchivedError | ExtraCostInfoDoesNotExistError | GeneralLedgerArchivedError | GeneralLedgerDoesNotExistError | GeneralLedgerRequiredError | InvalidBookingAmountError | InvalidBookingIdError | InvoiceHasNoBookingsError | NotCurrentWorkflowStepError | SumOfBookingsDoesNotMatchGrossAmountError | UnexpectedDocumentApprovalError | WorkflowStepAlreadyApprovedError;

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']['output']>;
  card?: Maybe<CardIssuerCard>;
  documentCollaborators: DocumentCollaboration;
  inAppNotification?: Maybe<InAppNotification>;
};


export type SubscriptionCardArgs = {
  input?: InputMaybe<CardSubscriptionInput>;
};


export type SubscriptionDocumentCollaboratorsArgs = {
  documentId: Scalars['ID']['input'];
};

/** Substitute added event */
export type SubstituteAddedEvent = DocumentTimeLineItem & {
  __typename?: 'SubstituteAddedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** where was the event added from. Could be user or candis/system */
  source?: Maybe<Scalars['String']['output']>;
  /** the substitute user. Inside it should contain the absent user. */
  substitute?: Maybe<SubstituteUser>;
};

/** Substitute removed event */
export type SubstituteRemovedEvent = DocumentTimeLineItem & {
  __typename?: 'SubstituteRemovedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** where was the event added from. Could be user or candis/system */
  source?: Maybe<Scalars['String']['output']>;
  /** the substitute user. Inside it should contain the absent user. */
  substitute?: Maybe<SubstituteUser>;
};

export type SubstituteUser = {
  __typename?: 'SubstituteUser';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSubstituting?: Maybe<ActivityUser>;
  name: Scalars['String']['output'];
};

export type SuggestedApproversField = {
  __typename?: 'SuggestedApproversField';
  confidence: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  value: Array<User>;
};

export type SumOfBookingsDoesNotMatchGrossAmountError = DocumentApprovalError & {
  __typename?: 'SumOfBookingsDoesNotMatchGrossAmountError';
  bookingsSum: Scalars['Float']['output'];
  grossAmount: Scalars['Float']['output'];
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type SwiftCodeAndIbanCountryMismatchError = DocumentApprovalError & {
  __typename?: 'SwiftCodeAndIbanCountryMismatchError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type SwiftCodeMissingIbanError = DocumentApprovalError & {
  __typename?: 'SwiftCodeMissingIBANError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type SyncDocumentsResponse = {
  __typename?: 'SyncDocumentsResponse';
  count: Scalars['Int']['output'];
  errors?: Maybe<Array<CreateDocumentsResponse>>;
};

export enum SyncMode {
  Force = 'FORCE',
  Import = 'IMPORT',
  Legacy = 'LEGACY'
}

export type SyncOptions = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type SystemApprovalWorkflowStep = WorkflowStep & {
  __typename?: 'SystemApprovalWorkflowStep';
  appliedAt?: Maybe<Scalars['Date']['output']>;
  approvers?: Maybe<Array<WorkflowUser>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next?: Maybe<WorkflowStep>;
  resolution?: Maybe<WorkflowStepResolution>;
  resolvedAt?: Maybe<Scalars['Date']['output']>;
  type?: Maybe<WorkflowTemplateStepType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type TableColumn = {
  __typename?: 'TableColumn';
  id: Scalars['String']['output'];
  isFixed?: Maybe<Scalars['Boolean']['output']>;
  isVisible: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type TableColumnInput = {
  id: Scalars['String']['input'];
  isFixed?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
};

export type TablePreference = {
  __typename?: 'TablePreference';
  columns: Array<TableColumn>;
  id: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  documentCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy?: Maybe<User>;
};

export type TagSortInput = {
  createdAt?: InputMaybe<TagSortOrder>;
  documentCount?: InputMaybe<TagSortOrder>;
  name?: InputMaybe<TagSortOrder>;
};

export enum TagSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TasksCountsInsight = {
  __typename?: 'TasksCountsInsight';
  overdue: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Team = {
  __typename?: 'Team';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  members: Array<TeamMember>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TeamCount = {
  __typename?: 'TeamCount';
  total?: Maybe<Scalars['Int']['output']>;
};

export type TeamInvolvements = {
  __typename?: 'TeamInvolvements';
  total: Scalars['Int']['output'];
};

export type TeamInvolvementsInput = {
  membershipId: Scalars['String']['input'];
};

export type TeamMember = {
  __typename?: 'TeamMember';
  isAdmin: Scalars['Boolean']['output'];
  membership: User;
};

export type TeamMemberInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  membershipId: Scalars['String']['input'];
};

export type TeamQueryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TeamSettingsForCreditCards = {
  __typename?: 'TeamSettingsForCreditCards';
  limitThresholds?: Maybe<CreditCardLimitThresholds>;
  linkedCardIds: Array<Scalars['String']['output']>;
  teamAdminCanManageCards: Scalars['Boolean']['output'];
};

export type TeamSettingsForCreditCardsInput = {
  teamId: Scalars['String']['input'];
};

export type TeamsFilterInput = {
  adminMembershipIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  membershipIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TeamsPageBasedPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum TeamsSortField {
  CreatedAt = 'CREATED_AT',
  MemberCount = 'MEMBER_COUNT',
  Name = 'NAME'
}

export type TeamsSortInput = {
  direction: SortDirection;
  field: TeamsSortField;
};

export enum TerminationReason {
  Lost = 'LOST',
  Other = 'OTHER',
  Stolen = 'STOLEN'
}

export type TimeFrame = {
  __typename?: 'TimeFrame';
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type TimeFrameInput = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type ToggleInAppNotificationsInput = {
  isRead: Scalars['Boolean']['input'];
};

export type TrackEventInput = {
  event: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['String']['input']>;
};

export type TrackingConfiguration = {
  __typename?: 'TrackingConfiguration';
  intercom: Scalars['Boolean']['output'];
  trackingEnabled: Scalars['Boolean']['output'];
};

export type TransactionAmount = {
  __typename?: 'TransactionAmount';
  currency: Scalars['String']['output'];
  precision: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type TransactionAmountChangedEvent = TransactionTimeLineItem & {
  __typename?: 'TransactionAmountChangedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityTransactionAmountUpdated;
  user: ActivityUser;
};

export type TransactionCardAccountingData = {
  __typename?: 'TransactionCardAccountingData';
  approvalWorkflowTemplateId?: Maybe<Scalars['String']['output']>;
  approvers?: Maybe<Array<Array<User>>>;
  artistSocialInsuranceCode?: Maybe<Scalars['String']['output']>;
  bookingKeyId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  /** @deprecated Use contact.id instead */
  contactId?: Maybe<Scalars['String']['output']>;
  costCenterId?: Maybe<Scalars['String']['output']>;
  costObjectId?: Maybe<Scalars['String']['output']>;
  extraCostInfoId?: Maybe<Scalars['String']['output']>;
  generalLedgerAccountId?: Maybe<Scalars['String']['output']>;
  paymentConditionId?: Maybe<Scalars['String']['output']>;
};

export enum TransactionCategory {
  AdvertisingAndMarketing = 'ADVERTISING_AND_MARKETING',
  ComputingAndSoftware = 'COMPUTING_AND_SOFTWARE',
  EducationAndTraining = 'EDUCATION_AND_TRAINING',
  ElectronicsAndItEquipment = 'ELECTRONICS_AND_IT_EQUIPMENT',
  EntertainmentAndWellness = 'ENTERTAINMENT_AND_WELLNESS',
  FoodAndDrinks = 'FOOD_AND_DRINKS',
  GiftsAndVouchers = 'GIFTS_AND_VOUCHERS',
  MaterialsAndPackaging = 'MATERIALS_AND_PACKAGING',
  OfficeSuppliesAndEquipment = 'OFFICE_SUPPLIES_AND_EQUIPMENT',
  Other = 'OTHER',
  Services = 'SERVICES',
  TravelAndAccommodation = 'TRAVEL_AND_ACCOMMODATION'
}

export type TransactionCommentCreatedInAppNotification = InAppNotification & {
  __typename?: 'TransactionCommentCreatedInAppNotification';
  author: User;
  cardId: Scalars['String']['output'];
  cardLabel?: Maybe<Scalars['String']['output']>;
  cardRefNum: Scalars['String']['output'];
  cardholderId: Scalars['String']['output'];
  commentCreatedAt: Scalars['Date']['output'];
  commentText: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  merchantName: Scalars['String']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  transactionAmount: TransactionAmount;
  transactionId: Scalars['String']['output'];
};

export type TransactionCommentEvent = TransactionTimeLineItem & {
  __typename?: 'TransactionCommentEvent';
  clientInfo?: Maybe<ClientInfo>;
  comment: ActivityComment;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges?: Maybe<Array<CardIssuerTransactionEdge>>;
  node?: Maybe<Array<CardIssuerTransaction>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TransactionDataMismatchError = DocumentApprovalError & {
  __typename?: 'TransactionDataMismatchError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type TransactionDeclinedInAppNotification = InAppNotification & {
  __typename?: 'TransactionDeclinedInAppNotification';
  cardId: Scalars['String']['output'];
  cardLabel?: Maybe<Scalars['String']['output']>;
  cardRefNum: Scalars['String']['output'];
  cardholderFirstName?: Maybe<Scalars['String']['output']>;
  cardholderId: Scalars['String']['output'];
  cardholderLastName?: Maybe<Scalars['String']['output']>;
  declineReason: TransactionDeclinedReason;
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  merchantName: Scalars['String']['output'];
  notificationCreatedAt: Scalars['Date']['output'];
  organizationId: Scalars['String']['output'];
  transactionAmount: TransactionAmount;
  transactionCreatedAt: Scalars['Date']['output'];
  transactionId: Scalars['String']['output'];
};

export type TransactionDeclinedInfo = {
  __typename?: 'TransactionDeclinedInfo';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TransactionDeclinedReason = {
  __typename?: 'TransactionDeclinedReason';
  description?: Maybe<TransactionDeclinedReasonDescription>;
  title?: Maybe<TransactionDeclinedReasonTitle>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TransactionDeclinedReasonDescription = {
  __typename?: 'TransactionDeclinedReasonDescription';
  de?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
};

export type TransactionDeclinedReasonTitle = {
  __typename?: 'TransactionDeclinedReasonTitle';
  de?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
};

export type TransactionDownloadHistory = {
  __typename?: 'TransactionDownloadHistory';
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  file?: Maybe<File>;
  filters: TransactionDownloadHistoryFilter;
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type TransactionDownloadHistoryFilter = {
  __typename?: 'TransactionDownloadHistoryFilter';
  cards?: Maybe<Array<CardIssuerCard>>;
  categories?: Maybe<Array<TransactionCategory>>;
  dateRangeFilters?: Maybe<CardIssuerTransactionDateFilter>;
  invoiceAssociationStatuses?: Maybe<Array<InvoiceAssociationStatus>>;
  merchantNames?: Maybe<Array<Scalars['String']['output']>>;
  statuses?: Maybe<Array<TransactionStatus>>;
  types?: Maybe<Array<TransactionType>>;
};

export type TransactionDownloadHistoryPageBasedPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum TransactionDownloadHistorySortField {
  CreatedDate = 'CREATED_DATE'
}

export type TransactionDownloadHistorySortInput = {
  direction: SortDirection;
  field: TransactionDownloadHistorySortField;
};

export type TransactionFxFee = {
  __typename?: 'TransactionFxFee';
  fxFeeAmount?: Maybe<TransactionAmount>;
  fxFeePercentage?: Maybe<Scalars['Float']['output']>;
};

export type TransactionMarkedAsExportedEvent = TransactionTimeLineItem & {
  __typename?: 'TransactionMarkedAsExportedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityTransactionMarkedAsExported;
  user: ActivityUser;
};

export type TransactionMarkedAsInvoiceNeededEvent = TransactionTimeLineItem & {
  __typename?: 'TransactionMarkedAsInvoiceNeededEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type TransactionMarkedAsInvoiceNotNeededEvent = TransactionTimeLineItem & {
  __typename?: 'TransactionMarkedAsInvoiceNotNeededEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityTransactionMarkedAsInvoiceNotNeeded;
  user: ActivityUser;
};

export type TransactionReconciledEvent = TransactionTimeLineItem & {
  __typename?: 'TransactionReconciledEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityTransactionReconciled;
  user: ActivityUser;
};

export enum TransactionStatus {
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Reversed = 'REVERSED'
}

export type TransactionStatusUpdatedEvent = TransactionTimeLineItem & {
  __typename?: 'TransactionStatusUpdatedEvent';
  clientInfo?: Maybe<ClientInfo>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  properties: ActivityTransactionStatusUpdated;
  user: ActivityUser;
};

export type TransactionTimeLineItem = {
  clientInfo?: Maybe<ClientInfo>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export enum TransactionType {
  CashWithdrawal = 'CASH_WITHDRAWAL',
  Chargeback = 'CHARGEBACK',
  Purchase = 'PURCHASE',
  Recharge = 'RECHARGE',
  Refund = 'REFUND',
  StatusInquiry = 'STATUS_INQUIRY'
}

export enum TransactionWallet {
  Android = 'ANDROID',
  Apple = 'APPLE',
  Other = 'OTHER'
}

export type TransactionsCardInsightsItem = {
  __typename?: 'TransactionsCardInsightsItem';
  aggregate: TransactionsInsightsMonetaryDetail;
  card: CardIssuerCard;
};

export type TransactionsCardholderInsightsItem = {
  __typename?: 'TransactionsCardholderInsightsItem';
  aggregate: TransactionsInsightsMonetaryDetail;
  cardholder: CardholderMembership;
  cards: Array<TransactionsCardInsightsItem>;
};

export type TransactionsInsightsFilterInput = {
  cardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cardTypes?: InputMaybe<Array<CardType>>;
  cardholderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<TransactionCategory>>;
  invoiceAssociationStatus?: InputMaybe<Array<InvoiceAssociationStatus>>;
  membershipIds?: InputMaybe<Array<Scalars['String']['input']>>;
  merchantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  merchantNames?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<TransactionStatus>>;
  types?: InputMaybe<Array<TransactionType>>;
};

export type TransactionsInsightsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type TransactionsInsightsMonetaryDetail = {
  __typename?: 'TransactionsInsightsMonetaryDetail';
  count: Scalars['Int']['output'];
  maxTransactionCreatedAt?: Maybe<Scalars['Date']['output']>;
  minTransactionCreatedAt?: Maybe<Scalars['Date']['output']>;
  sum: Money;
};

export type TransactionsReadyForExportResponse = {
  __typename?: 'TransactionsReadyForExportResponse';
  transactions: Array<CardIssuerTransaction>;
};

export type TransferOwnershipError = {
  __typename?: 'TransferOwnershipError';
  kind?: Maybe<TransferOwnershipErrorKind>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum TransferOwnershipErrorKind {
  Default = 'DEFAULT',
  NotEnabled = 'NOT_ENABLED'
}

export type TransferOwnershipInput = {
  membershipId: Scalars['String']['input'];
};

export type TransferOwnershipResult = BuiltinRole | TransferOwnershipError;

export type UnauthorizedError = {
  __typename?: 'UnauthorizedError';
  message?: Maybe<Scalars['String']['output']>;
};

export type UnexpectedDocumentApprovalError = DocumentApprovalError & {
  __typename?: 'UnexpectedDocumentApprovalError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type UnpaidDocumentState = {
  __typename?: 'UnpaidDocumentState';
  isPaid: Scalars['Boolean']['output'];
};

export type UnprocessableAttachment = {
  __typename?: 'UnprocessableAttachment';
  errorCode: Scalars['String']['output'];
};

export type UnprocessableXmlAttachment = {
  __typename?: 'UnprocessableXmlAttachment';
  errorCode: Scalars['String']['output'];
};

export type UnsupportedAttachmentType = {
  __typename?: 'UnsupportedAttachmentType';
  errorCode: Scalars['String']['output'];
};

export type UpdateAccountingAreaInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateBookingAccountInput = {
  accountCode: Scalars['String']['input'];
  /** @deprecated "Use `accountCode`" */
  accountNumber?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateBookingKeyInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  /** @deprecated "Use `taxCode`" */
  key?: InputMaybe<Scalars['Int']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCardAccountingDataInput = {
  accountingData: CardAccountingDataInput;
  cardId: Scalars['String']['input'];
};

export type UpdateCardForRecurringPaymentVendorInput = {
  cardId: Scalars['String']['input'];
  cardLinkId: Scalars['String']['input'];
  customVendorName?: InputMaybe<Scalars['String']['input']>;
  vendorId: Scalars['String']['input'];
};

export type UpdateCardIssuerOrganizationSettingsInput = {
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  deliveryAddress?: InputMaybe<UpdateCardIssuerOrganizationSettingsInputDeliveryAddress>;
  deliveryAddressSameAsBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCardIssuerOrganizationSettingsInputDeliveryAddress = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  streetAdditional?: InputMaybe<Scalars['String']['input']>;
  streetName: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
};

export type UpdateCardLabelInput = {
  cardId: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type UpdateCardPurposeInput = {
  cardId: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
};

export type UpdateCardSettingsDataInput = {
  cardId: Scalars['String']['input'];
  settingsData: CardSettingsDataInput;
};

export type UpdateContactInput = {
  /** Formerly `creditorAccountNumber` */
  accountsPayableNumber?: InputMaybe<Scalars['AccountsPayableNumber']['input']>;
  accountsReceivableNumber?: InputMaybe<Scalars['String']['input']>;
  automaticPayment?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<ContactType>;
  countryISOCode?: InputMaybe<Scalars['String']['input']>;
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Use `accountsPayableNumber` */
  creditorAccountNumber?: InputMaybe<Scalars['Int']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated superseded by payment conditions */
  dueDateOffset?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  individualFirstName?: InputMaybe<Scalars['String']['input']>;
  individualLastName?: InputMaybe<Scalars['String']['input']>;
  membershipId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notSpecifiedName?: InputMaybe<Scalars['String']['input']>;
  paymentConditionId?: InputMaybe<Scalars['String']['input']>;
  paymentMedium?: InputMaybe<ContactPaymentMedium>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postOfficeBox?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  relationshipType?: InputMaybe<ContactRelationshipType>;
  street?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractSubCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractSubCategoryStatusInput = {
  id: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

export type UpdateCostCenterInput = {
  approvers?: InputMaybe<Array<UserInput>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCostCenterResult = CostCenterAlreadyExists | CostCenterUpdated;

export type UpdateCurrentUserInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  locale: Locale;
};

export type UpdateDocumentDataExtractionSettingsInput = {
  suggestOutgoingInvoices: Scalars['Boolean']['input'];
};

export type UpdateDocumentOptions = {
  accountingAreaId?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<DocumentAmountField>;
  approvedAt?: InputMaybe<Scalars['Date']['input']>;
  approvedByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  approverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  auditTrailFileId?: InputMaybe<Scalars['String']['input']>;
  automaticPayment?: InputMaybe<Scalars['Boolean']['input']>;
  bookings?: InputMaybe<Array<BookingInput>>;
  category?: InputMaybe<DocumentCategoryInputField>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Scalars['IBAN']['input']>;
  invoiceDate?: InputMaybe<Scalars['Date']['input']>;
  invoiceDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  invoiceDeliveryPeriod?: InputMaybe<InvoiceDeliveryPeriodInput>;
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  invoicePostingDate?: InputMaybe<Scalars['Date']['input']>;
  mentionedUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  originalDocumentId?: InputMaybe<Scalars['String']['input']>;
  previousApproverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  requestedAt?: InputMaybe<Scalars['Date']['input']>;
  requesterId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentStatus>;
  suggestedApproverIds?: InputMaybe<Array<Scalars['String']['input']>>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['String']['input']>;
  workflowStepId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDocumentTagsInput = {
  globalDocumentId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};

export type UpdateEcmDocumentInput = {
  amount?: InputMaybe<MoneyInput>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  documentDate?: InputMaybe<Scalars['Date']['input']>;
  documentName?: InputMaybe<Scalars['String']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  documentSubCategory?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notifyPerson?: InputMaybe<EcmDocumentNotifyPersonInput>;
  responsiblePerson?: InputMaybe<EcmDocumentResponsiblePersonInput>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  terminationDate?: InputMaybe<Scalars['Date']['input']>;
  terminationReminderDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateEcmDocumentResponse = {
  __typename?: 'UpdateEcmDocumentResponse';
  ecmDocumentId: Scalars['String']['output'];
};

export type UpdateEcmDocumentTypeSettingsInput = {
  isVisible: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type UpdateIbanInput = {
  iban?: InputMaybe<Scalars['IBAN']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationMfaSettingsInput = {
  mfaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  mfaPreferredChannels?: InputMaybe<Array<OrganizationMfaChannels>>;
};

export type UpdateOrganizationSsoInput = {
  canUserRegisterViaSSO: Scalars['Boolean']['input'];
  emailDomains: Array<Scalars['String']['input']>;
  idpAlias: Scalars['String']['input'];
  isMembershipRolesUpdatedViaSSO: Scalars['Boolean']['input'];
  isUserDataUpdatedViaSSO: Scalars['Boolean']['input'];
  owner: Scalars['String']['input'];
};

export type UpdateOrganizationsInput = {
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  fiscalStartMonth?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  salesforceAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentConditionInput = {
  discountOffset?: InputMaybe<Scalars['Int']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  dueDateOffset?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  paymentConditionNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePaymentDataInput = {
  automaticPayment?: InputMaybe<Scalars['Boolean']['input']>;
  createTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Scalars['IBAN']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  paidAt?: InputMaybe<Scalars['Date']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePerDiemItemInput = {
  bookings?: InputMaybe<Array<ReimbursementBookingInput>>;
  currentStep?: InputMaybe<PerDiemStep>;
  days?: InputMaybe<Array<PerDiemDaysInput>>;
  reimbursementCaseId: Scalars['String']['input'];
  segments?: InputMaybe<Array<PerDiemSegmentInput>>;
  status?: InputMaybe<ReimbursementItemStatusInput>;
};

export type UpdateReimbursementCaseInput = {
  advancedAccountingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  suggestedCostCenterId?: InputMaybe<Scalars['String']['input']>;
  suggestedCostObjectId?: InputMaybe<Scalars['String']['input']>;
  suggestedGeneralLedgerAccountId?: InputMaybe<Scalars['String']['input']>;
  targetMembershipId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
};

export type UpdateSsoConfigurationInput = {
  canUserRegisterViaSSO: Scalars['Boolean']['input'];
  emailDomains: Array<Scalars['String']['input']>;
  isMembershipRolesUpdatedViaSSO: Scalars['Boolean']['input'];
  isUserDataUpdatedViaSSO: Scalars['Boolean']['input'];
};

export type UpdateTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTagStatusInput = {
  id: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

export type UpdateTeamBodyInput = {
  members?: InputMaybe<Array<TeamMemberInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  cardRoles?: InputMaybe<Array<CardRole>>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  locale: Locale;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  roles: Array<UserRole>;
};

export type UpdateWorkflowTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  steps?: InputMaybe<Array<WorkflowTemplateStepInput>>;
};

export type UpdatedAutomaticPaymentField = {
  __typename?: 'UpdatedAutomaticPaymentField';
  automaticPayment: Scalars['Boolean']['output'];
};

export type UpdatedCreateTransferField = {
  __typename?: 'UpdatedCreateTransferField';
  createTransfer: Scalars['Boolean']['output'];
};

export type UpdatedDocumentPaymentField = UpdatedCreateTransferField | UpdatedIbanField | UpdatedPaymentStateField | UpdatedSwiftCodeField;

export type UpdatedIbanField = {
  __typename?: 'UpdatedIBANField';
  iban?: Maybe<Scalars['IBAN']['output']>;
};

export type UpdatedPaymentField = UpdatedAutomaticPaymentField | UpdatedIbanField | UpdatedPaymentStateField | UpdatedSwiftCodeField;

export type UpdatedPaymentStateField = {
  __typename?: 'UpdatedPaymentStateField';
  paymentState: PaymentStateValue;
};

export type UpdatedSwiftCodeField = {
  __typename?: 'UpdatedSwiftCodeField';
  swiftCode?: Maybe<Scalars['String']['output']>;
};

export type UploadAvatarResponse = {
  __typename?: 'UploadAvatarResponse';
  downloadUrl: Scalars['String']['output'];
  postOptions?: Maybe<Array<Maybe<UploadPostOption>>>;
  uploadUrl: Scalars['String']['output'];
};

export type UploadFileItem = {
  __typename?: 'UploadFileItem';
  createdAt: Scalars['Date']['output'];
  documentId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  state: FileState;
};

export type UploadFilesResponse = {
  __typename?: 'UploadFilesResponse';
  file?: Maybe<DocumentFile>;
  id: Scalars['String']['output'];
  postOptions?: Maybe<Array<Maybe<UploadPostOption>>>;
  url: Scalars['String']['output'];
};

export type UploadModal = {
  __typename?: 'UploadModal';
  uploadModalVisible: Scalars['Boolean']['output'];
};

export type UploadOptions = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type UploadPostOption = {
  __typename?: 'UploadPostOption';
  field: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UploadedFileAttempt = {
  __typename?: 'UploadedFileAttempt';
  contentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type User = {
  __typename?: 'User';
  analyticsId?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  cardRoles?: Maybe<Array<CardRole>>;
  createdTimestamp: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  intercomHash?: Maybe<Scalars['String']['output']>;
  invitedTimestamp?: Maybe<Scalars['String']['output']>;
  lastLoginInfo?: Maybe<LastLoginInfo>;
  lastName: Scalars['String']['output'];
  locale?: Maybe<Locale>;
  membershipRoles: Array<MembershipRole>;
  name: Scalars['String']['output'];
  paymentInfo?: Maybe<PaymentInfo>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<UserPreferences>;
  roles?: Maybe<Array<UserRole>>;
  status?: Maybe<MembershipStatus>;
  trackingConfiguration?: Maybe<TrackingConfiguration>;
  username: Scalars['String']['output'];
};


export type UserMembershipRolesArgs = {
  filters?: InputMaybe<MembershipRoleFilters>;
};

export type UserAccess = {
  /** @deprecated Do not use, it is an empty array. Use the user's roles instead */
  currentUserAccess: Array<Scalars['String']['output']>;
};

/** Document workflow step user approval event */
export type UserApprovalDocumentWorkflowStep = DocumentTimeLineItem & {
  __typename?: 'UserApprovalDocumentWorkflowStep';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that approved the document */
  user?: Maybe<ActivityUser>;
};

export type UserApprovalWorkflowStep = WorkflowStep & {
  __typename?: 'UserApprovalWorkflowStep';
  appliedAt?: Maybe<Scalars['Date']['output']>;
  approvers?: Maybe<Array<WorkflowUser>>;
  conditions?: Maybe<Array<WorkflowStepCondition>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next?: Maybe<WorkflowStep>;
  resolution?: Maybe<WorkflowStepResolution>;
  resolvedAt?: Maybe<Scalars['Date']['output']>;
  type?: Maybe<WorkflowTemplateStepType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserInput = {
  label: Scalars['String']['input'];
  text: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UserPaymentInfoUpdatedResponse = {
  __typename?: 'UserPaymentInfoUpdatedResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  promos: Array<PromoPreference>;
  tables: Array<TablePreference>;
};

/** Document workflow step user rejection event */
export type UserRejectedDocumentWorkflowStep = DocumentTimeLineItem & {
  __typename?: 'UserRejectedDocumentWorkflowStep';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The user that rejected the document */
  user?: Maybe<ActivityUser>;
};

export { UserRole };

export type VendorCardSuggestionForRecurringPayments = {
  __typename?: 'VendorCardSuggestionForRecurringPayments';
  card: CardIssuerCard;
  linkedVendors: Array<RecurringPaymentVendor>;
  linkedVendorsCount: Scalars['Int']['output'];
};

export type VendorCardholderSuggestionForRecurringPayments = {
  __typename?: 'VendorCardholderSuggestionForRecurringPayments';
  cardholder: MemberInfo;
};

export type VendorCardholdersSuggestionsForRecurringPayments = {
  __typename?: 'VendorCardholdersSuggestionsForRecurringPayments';
  suggestions?: Maybe<Array<VendorCardholderSuggestionForRecurringPayments>>;
};

export type VendorCardsSuggestionsForRecurringPayments = {
  __typename?: 'VendorCardsSuggestionsForRecurringPayments';
  suggestions: Array<VendorCardSuggestionForRecurringPayments>;
};

export type VendorForRecurringPaymentData = {
  __typename?: 'VendorForRecurringPaymentData';
  id: Scalars['String']['output'];
  linkedCards: Array<LinkedCard>;
  vendor: RecurringPaymentVendor;
};

export type VendorSuggestionForRecurringPayment = {
  __typename?: 'VendorSuggestionForRecurringPayment';
  contactIds: Array<Scalars['String']['output']>;
  hasTransactions?: Maybe<Scalars['Boolean']['output']>;
  maxMonthlyInvoiceAmount: Money;
  sum: RecurringPaymentInsightsAggregate;
  vendor: RecurringPaymentVendor;
};

export type VendorSuggestionsCountForRecurringPayments = {
  __typename?: 'VendorSuggestionsCountForRecurringPayments';
  linkedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type VendorSuggestionsForRecurringPaymentsConnectionPageBased = {
  __typename?: 'VendorSuggestionsForRecurringPaymentsConnectionPageBased';
  linkedCount: Scalars['Int']['output'];
  pageInfo: SimplePageInfoDetails;
  records: Array<VendorSuggestionForRecurringPayment>;
};

export enum VendorSuggestionsForRecurringPaymentsSortField {
  Sum = 'SUM'
}

export type VendorsForRecurringPaymentsConnectionPageBased = {
  __typename?: 'VendorsForRecurringPaymentsConnectionPageBased';
  pageInfo: SimplePageInfoDetails;
  records: Array<VendorForRecurringPaymentData>;
};

export enum VendorsForRecurringPaymentsSortField {
  CardholderFullName = 'CARDHOLDER_FULL_NAME',
  CardRefNum = 'CARD_REF_NUM',
  Name = 'NAME',
  RecentlyLinked = 'RECENTLY_LINKED'
}

export type VerifyAuthenticatorDeviceResult = {
  __typename?: 'VerifyAuthenticatorDeviceResult';
  verified: Scalars['Boolean']['output'];
};

export type VerifyPhoneNumberResult = {
  __typename?: 'VerifyPhoneNumberResult';
  phoneNumber: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type VirtualCardApprovedEvent = CardTimeLineItem & {
  __typename?: 'VirtualCardApprovedEvent';
  approvedLimit: TransactionAmount;
  approvedTransactionLimit: TransactionAmount;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: ActivityUser;
};

export type VirtualCardRequest = CardIssuerCardRequest & {
  __typename?: 'VirtualCardRequest';
  approvedLabel?: Maybe<Scalars['String']['output']>;
  approvedLimit?: Maybe<TransactionAmount>;
  approvedLimitRenewFrequency?: Maybe<CardLimitRenewFrequency>;
  approvedTransactionLimit?: Maybe<TransactionAmount>;
  approvedValidityPeriod?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<CardCategory>;
  comment: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  permissionsForMembership?: Maybe<Array<Scalars['String']['output']>>;
  requestedLimit?: Maybe<TransactionAmount>;
  requestedLimitRenewFrequency?: Maybe<CardLimitRenewFrequency>;
  requestedTransactionLimit: TransactionAmount;
  resolutionComment?: Maybe<Scalars['String']['output']>;
  status: CardRequestStatus;
  team?: Maybe<Team>;
  type: CardType;
  validityPeriod: Scalars['Int']['output'];
};

export type VirtualCardRequestedEvent = CardTimeLineItem & {
  __typename?: 'VirtualCardRequestedEvent';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  requestedLimit: TransactionAmount;
  requestedTransactionLimit: TransactionAmount;
  user: ActivityUser;
};

/** Workflow */
export type Workflow = {
  __typename?: 'Workflow';
  /** Identifies if a workflow has been completed */
  completed?: Maybe<Scalars['Boolean']['output']>;
  /** Identifies the date and time when the workflow was completed. */
  completedAt?: Maybe<Scalars['Date']['output']>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Workflow step creator */
  creator?: Maybe<User>;
  /** Non resolved applied step */
  currentStep?: Maybe<WorkflowStep>;
  /** Workflow description */
  description?: Maybe<Scalars['String']['output']>;
  /** Workflow id */
  id?: Maybe<Scalars['ID']['output']>;
  /** Workflow name */
  name?: Maybe<Scalars['String']['output']>;
  /** List of workflow steps belonging to this workflow */
  steps?: Maybe<Array<WorkflowStep>>;
  /** workflow type */
  type?: Maybe<WorkflowTypes>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** The workflow template used to create this workflow */
  workflowTemplate?: Maybe<WorkflowTemplate>;
};

/** Document workflow creation event */
export type WorkflowCreatedForDocumentEvent = DocumentTimeLineItem & {
  __typename?: 'WorkflowCreatedForDocumentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** workflow name */
  name?: Maybe<Scalars['String']['output']>;
  /** The user that created the workflow */
  user?: Maybe<ActivityUser>;
};

export type WorkflowStep = {
  appliedAt?: Maybe<Scalars['Date']['output']>;
  approvers?: Maybe<Array<WorkflowUser>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next?: Maybe<WorkflowStep>;
  resolution?: Maybe<WorkflowStepResolution>;
  resolvedAt?: Maybe<Scalars['Date']['output']>;
  type?: Maybe<WorkflowTemplateStepType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type WorkflowStepAlreadyApprovedError = DocumentApprovalError & {
  __typename?: 'WorkflowStepAlreadyApprovedError';
  approvedByUser: User;
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type WorkflowStepCondition = {
  __typename?: 'WorkflowStepCondition';
  field: WorkflowStepConditionFields;
  operator: WorkflowStepConditionOperators;
  values: Array<Scalars['Primitive']['output']>;
};

export enum WorkflowStepConditionFields {
  Amount = 'AMOUNT',
  Contact = 'CONTACT'
}

export enum WorkflowStepConditionOperators {
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  IsOneOf = 'IS_ONE_OF',
  LessThan = 'LESS_THAN',
  LessThanEquals = 'LESS_THAN_EQUALS'
}

export type WorkflowStepResolution = {
  __typename?: 'WorkflowStepResolution';
  approvers?: Maybe<Array<WorkflowUser>>;
  comment?: Maybe<Scalars['String']['output']>;
  type: WorkflowStepResolutionTypes;
};

export enum WorkflowStepResolutionTypes {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Skipped = 'SKIPPED'
}

/** Document workflow step skipped event */
export type WorkflowStepSkippedEvent = DocumentTimeLineItem & {
  __typename?: 'WorkflowStepSkippedEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The users that were skipped for approval */
  users?: Maybe<Array<Maybe<ActivityUser>>>;
  /** Skipped step */
  workflowStep?: Maybe<ActivityWorkflowStep>;
};

export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate';
  createdAt?: Maybe<Scalars['Date']['output']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  steps?: Maybe<Array<WorkflowTemplateStep>>;
  updater?: Maybe<User>;
};

/** Document workflow template application event */
export type WorkflowTemplateAppliedToDocumentEvent = DocumentTimeLineItem & {
  __typename?: 'WorkflowTemplateAppliedToDocumentEvent';
  /** Client Info */
  clientInfo?: Maybe<ClientInfo>;
  /** Identifies the date and time when the object was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** The unique id of the activity item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The name of the template applied to the document */
  workflow?: Maybe<ActivityWorkflow>;
};

export type WorkflowTemplateArchivedError = DocumentApprovalError & {
  __typename?: 'WorkflowTemplateArchivedError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
  workflowTemplate: WorkflowTemplate;
};

export type WorkflowTemplateCondition = {
  __typename?: 'WorkflowTemplateCondition';
  field: WorkflowStepConditionFields;
  operator: WorkflowStepConditionOperators;
  values?: Maybe<Array<Scalars['Primitive']['output']>>;
};

export type WorkflowTemplateConditionInput = {
  field: WorkflowStepConditionFields;
  operator: WorkflowStepConditionOperators;
  values: Array<Scalars['Primitive']['input']>;
};

export type WorkflowTemplateCounters = {
  __typename?: 'WorkflowTemplateCounters';
  active: Scalars['Int']['output'];
  all: Scalars['Int']['output'];
  archived: Scalars['Int']['output'];
};

export type WorkflowTemplateDoesNotExistError = DocumentApprovalError & {
  __typename?: 'WorkflowTemplateDoesNotExistError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type WorkflowTemplateStep = {
  __typename?: 'WorkflowTemplateStep';
  conditions?: Maybe<Array<WorkflowTemplateCondition>>;
  order?: Maybe<Scalars['Int']['output']>;
  resolvers?: Maybe<Array<Maybe<User>>>;
  type?: Maybe<WorkflowTemplateStepType>;
};

export type WorkflowTemplateStepInput = {
  conditions?: InputMaybe<Array<WorkflowTemplateConditionInput>>;
  order: Scalars['Int']['input'];
  resolvers?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: WorkflowTemplateStepType;
};

export enum WorkflowTemplateStepType {
  DocumentApproval = 'DOCUMENT_APPROVAL',
  SystemApproval = 'SYSTEM_APPROVAL',
  UserApproval = 'USER_APPROVAL'
}

export enum WorkflowTypes {
  DocumentWorkflow = 'DOCUMENT_WORKFLOW'
}

export type WorkflowUser = {
  __typename?: 'WorkflowUser';
  analyticsId?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  cardRoles?: Maybe<Array<CardRole>>;
  createdTimestamp: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  intercomHash?: Maybe<Scalars['String']['output']>;
  invitedTimestamp?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  locale?: Maybe<Locale>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<UserRole>>;
  status?: Maybe<MembershipStatus>;
  substitute?: Maybe<User>;
  trackingConfiguration?: Maybe<TrackingConfiguration>;
  username: Scalars['String']['output'];
};

export type WrongDocumentTypeConnectedToSapEntityError = DocumentApprovalError & {
  __typename?: 'WrongDocumentTypeConnectedToSapEntityError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type XRechnungInvoiceHasNoPdfError = DocumentApprovalError & {
  __typename?: 'XRechnungInvoiceHasNoPDFError';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type EmailInboxFilterInput = {
  id: Scalars['String']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailInboxPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type EmailInboxPaginationResponse = {
  __typename?: 'emailInboxPaginationResponse';
  pageInfo?: Maybe<SimplePageInfoDetails>;
  records?: Maybe<Array<Maybe<EmailInboxMessage>>>;
};

export type EmailInboxResponse = {
  __typename?: 'emailInboxResponse';
  records?: Maybe<Array<Maybe<EmailInboxMessage>>>;
};

export enum EmailInboxSearchField {
  Status = 'STATUS'
}

export type EmailInboxSearchInput = {
  field: EmailInboxSearchField;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum EmailInboxSortField {
  Receivedat = 'RECEIVEDAT',
  Sender = 'SENDER',
  Subject = 'SUBJECT'
}

export type EmailInboxSortInput = {
  direction: SortDirection;
  field: EmailInboxSortField;
};

export type New_DatevClient = {
  __typename?: 'new_DatevClient';
  clientNumber: Scalars['String']['output'];
  connectedAt?: Maybe<Scalars['Date']['output']>;
  connectedBy?: Maybe<Scalars['String']['output']>;
  consultantNumber: Scalars['String']['output'];
  exportType: DatevClientExportType;
  id: Scalars['String']['output'];
  isBdsPermissionVerified?: Maybe<Scalars['Boolean']['output']>;
  isDxsoCashLedgerConfigured?: Maybe<Scalars['Boolean']['output']>;
  isDxsoJobsAvailable: Scalars['Boolean']['output'];
  isExtfsFilesAvailable?: Maybe<Scalars['Boolean']['output']>;
  isFiscalYearConfiguredForYear2024?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type New_DatevDocumentType = {
  __typename?: 'new_DatevDocumentType';
  bookingAccount?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  debitCreditIdentifier?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type New_DatevSettings = {
  __typename?: 'new_DatevSettings';
  chartOfAccount?: Maybe<ChartOfAccount>;
  client?: Maybe<New_DatevClient>;
  connectionType?: Maybe<DatevConnectionType>;
  creditCardsLedger?: Maybe<CreditCardsLedger>;
  datevCoreDataSource?: Maybe<DatevCoreDataSource>;
  documentTypes?: Maybe<Array<New_DatevDocumentType>>;
  hasAuthTokenForExportDocuments: Scalars['Boolean']['output'];
  hasAuthTokenForReadClients: Scalars['Boolean']['output'];
  isFiscalYearConfigured: Scalars['Boolean']['output'];
  provisionsLedger?: Maybe<ProvisionsLedger>;
  refreshTokenExpiredAt?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Field no longer supported */
  usedLatestVersion?: Maybe<Scalars['Boolean']['output']>;
};

export type GetMembershipsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMembershipsQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'roles' | 'avatarUrl'>
  )> }
);

export type CreateDocumentCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type CreateDocumentCommentMutation = (
  { __typename?: 'Mutation' }
  & { createDocumentComment?: Maybe<(
    { __typename?: 'DocumentCommentEvent' }
    & Pick<DocumentCommentEvent, 'id'>
  )> }
);

export type CreateCommentOnInvoiceMutationVariables = Exact<{
  input: CreateCommentInput;
}>;


export type CreateCommentOnInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createCommentOnInvoice: (
    { __typename?: 'CommentCreated' }
    & Pick<CommentCreated, 'commentId'>
  ) | (
    { __typename?: 'CommentCreatedError' }
    & Pick<CommentCreatedError, 'message' | 'kind'>
  ) }
);

export type CreateCommentOnEcmDocumentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;


export type CreateCommentOnEcmDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createCommentOnECMDocument: (
    { __typename?: 'CommentCreated' }
    & Pick<CommentCreated, 'commentId'>
  ) | (
    { __typename?: 'CommentCreatedError' }
    & Pick<CommentCreatedError, 'message' | 'kind'>
  ) }
);

export type DocumentAccessLevelsQueryVariables = Exact<{
  globalDocumentId: Scalars['String']['input'];
}>;


export type DocumentAccessLevelsQuery = (
  { __typename?: 'Query' }
  & { documentAccessLevels: (
    { __typename: 'AccessLevelError' }
    & Pick<AccessLevelError, 'type' | 'message'>
  ) | (
    { __typename: 'AccessLevels' }
    & Pick<AccessLevels, 'id'>
    & { levels: Array<(
      { __typename?: 'AccessLevel' }
      & Pick<AccessLevel, 'name'>
      & { users: Array<(
        { __typename?: 'ActivityUser' }
        & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
      )> }
    )> }
  ) }
);

export type ModifyDocumentAccessMutationVariables = Exact<{
  input: ModifyAccessInput;
}>;


export type ModifyDocumentAccessMutation = (
  { __typename?: 'Mutation' }
  & { modifyDocumentAccess: (
    { __typename: 'AccessLevels' }
    & Pick<AccessLevels, 'id'>
    & { levels: Array<(
      { __typename?: 'AccessLevel' }
      & Pick<AccessLevel, 'name'>
      & { users: Array<(
        { __typename?: 'ActivityUser' }
        & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
      )> }
    )> }
  ) | (
    { __typename: 'ModifyAccessError' }
    & Pick<ModifyAccessError, 'type' | 'message'>
  ) }
);

export type DocumentDetailsCardQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DocumentDetailsCardQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'status'>
    & { amount?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value'>
    )>, currency?: Maybe<(
      { __typename?: 'DocumentCurrencyField' }
      & Pick<DocumentCurrencyField, 'value'>
    )>, invoiceDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value'>
    )>, invoiceNumber?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, contact?: Maybe<(
      { __typename?: 'ContactField' }
      & { value: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      ) }
    )>, originalDocumentId?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, extractedContact?: Maybe<(
      { __typename?: 'ExtractedContact' }
      & Pick<ExtractedContact, 'cachingKey'>
      & { name?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )> }
    )> }
  )> }
);

export type ListInboxDocumentsForAttachmentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  sort?: InputMaybe<SortInput>;
}>;


export type ListInboxDocumentsForAttachmentsQuery = (
  { __typename?: 'Query' }
  & { listInboxDocuments: (
    { __typename?: 'DocumentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DocumentEdge' }
      & Pick<DocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'Document' }
        & Pick<Document, 'isDuplicate' | 'isOverdue' | 'isWaitingForClarification' | 'id' | 'status' | 'createdAt' | 'isEInvoice' | 'hasAttachments'>
        & { contact?: Maybe<(
          { __typename?: 'ContactField' }
          & { value: (
            { __typename?: 'Contact' }
            & Pick<Contact, 'id'>
            & { name: (
              { __typename?: 'StringField' }
              & Pick<StringField, 'value'>
            ) }
          ) }
        )>, extractedContact?: Maybe<(
          { __typename?: 'ExtractedContact' }
          & Pick<ExtractedContact, 'cachingKey'>
          & { name?: Maybe<(
            { __typename?: 'StringField' }
            & Pick<StringField, 'value'>
          )> }
        )>, documentFile?: Maybe<(
          { __typename?: 'DocumentFile' }
          & Pick<DocumentFile, 'id' | 'name' | 'size'>
        )>, eInvoice?: Maybe<(
          { __typename?: 'DocumentEInvoiceField' }
          & Pick<DocumentEInvoiceField, 'format'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & TagFragment
        )> }
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type GetInboxInvoiceDocumentsForAttachmentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<InboxInvoiceDocumentsFilterInput>;
  sort?: InputMaybe<SortInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
  withAccountsReceivableNumber?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetInboxInvoiceDocumentsForAttachmentsQuery = (
  { __typename?: 'Query' }
  & { inboxInvoiceDocuments: (
    { __typename?: 'InboxInvoiceDocumentsResponse' }
    & { edges: Array<(
      { __typename?: 'InboxInvoiceDocumentEdge' }
      & Pick<InboxInvoiceDocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'InboxInvoiceDocument' }
        & Pick<InboxInvoiceDocument, 'hasAttachments'>
        & { documentFile?: Maybe<(
          { __typename?: 'DocumentFile' }
          & Pick<DocumentFile, 'name' | 'size'>
        )> }
        & InboxInvoiceDocumentDataFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    ) }
  ) }
);

export type GetDocumentFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentFileQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'status' | 'isEInvoice'>
    & { documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'name' | 'url' | 'versions' | 'pages' | 'size'>
      & { eInvoice?: Maybe<(
        { __typename?: 'EInvoiceFile' }
        & Pick<EInvoiceFile, 'id' | 'url' | 'name'>
      )> }
    )>, eInvoice?: Maybe<(
      { __typename?: 'DocumentEInvoiceField' }
      & Pick<DocumentEInvoiceField, 'format' | 'error'>
    )>, attachments?: Maybe<Array<(
      { __typename?: 'DocumentAttachment' }
      & Pick<DocumentAttachment, 'id' | 'name' | 'url' | 'pages' | 'rank' | 'size' | 'isDetachable' | 'originalDocumentId'>
      & { attachedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>> }
  )> }
);

export type DocumentAttachmentUploadUrlMutationVariables = Exact<{
  options: UploadOptions;
}>;


export type DocumentAttachmentUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { documentAttachmentUploadUrl: (
    { __typename?: 'UploadFilesResponse' }
    & Pick<UploadFilesResponse, 'id' | 'url'>
    & { postOptions?: Maybe<Array<Maybe<(
      { __typename?: 'UploadPostOption' }
      & Pick<UploadPostOption, 'field' | 'value'>
    )>>> }
  ) }
);

export type AttachFilesMutationVariables = Exact<{
  input: AttachFilesInput;
}>;


export type AttachFilesMutation = (
  { __typename?: 'Mutation' }
  & { attachFiles?: Maybe<(
    { __typename: 'Document' }
    & Pick<Document, 'id'>
    & { timeline?: Maybe<Array<(
      { __typename: 'AccountingAreaUpdatedEvent' }
      & Pick<AccountingAreaUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApprovedEvent' }
      & Pick<ApprovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApproverExtractedEvent' }
      & Pick<ApproverExtractedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentConvertedToDocumentEvent' }
      & Pick<AttachmentConvertedToDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentDeletedFromDocumentEvent' }
      & Pick<AttachmentDeletedFromDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentDetachedFromDocumentEvent' }
      & Pick<AttachmentDetachedFromDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ContactExtractionEvent' }
      & Pick<ContactExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ContactSupplierSendEmailEvent' }
      & Pick<ContactSupplierSendEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'CostCenterExtractionEvent' }
      & Pick<CostCenterExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsCreatedEvent' }
      & Pick<DocumentAssociationsCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsRemovedEvent' }
      & Pick<DocumentAssociationsRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentClassifiedEvent' }
      & Pick<DocumentClassifiedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentCommentEvent' }
      & Pick<DocumentCommentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedEvent' }
      & Pick<DocumentConvertedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedToAttachmentEvent' }
      & Pick<DocumentConvertedToAttachmentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentDeletedEvent' }
      & Pick<DocumentDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentFileReplacedEvent' }
      & Pick<DocumentFileReplacedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
      & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentImportedByMigrationEvent' }
      & Pick<DocumentImportedByMigrationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentPaymentInfoUpdatedEvent' }
      & Pick<DocumentPaymentInfoUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipCreatedEvent' }
      & Pick<DocumentRelationshipCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipRemovedEvent' }
      & Pick<DocumentRelationshipRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetByCandis' }
      & Pick<DocumentResetByCandis, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetManualEvent' }
      & Pick<DocumentResetManualEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionAmountUpdatedEvent' }
      & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionLinkedEvent' }
      & Pick<DocumentResetTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionUnLinkedEvent' }
      & Pick<DocumentResetTransactionUnLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserDeactivationEvent' }
      & Pick<DocumentResetUserDeactivationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserRoleChangedEvent' }
      & Pick<DocumentResetUserRoleChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentSepaTransferGeneratedEvent' }
      & Pick<DocumentSepaTransferGeneratedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentStoredAsEcmDocumentEvent' }
      & Pick<DocumentStoredAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTagUpdatedEvent' }
      & Pick<DocumentTagUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionCommentCreatedEvent' }
      & Pick<DocumentTransactionCommentCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionLinkedEvent' }
      & Pick<DocumentTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionReconciledEvent' }
      & Pick<DocumentTransactionReconciledEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionStatusUpdatedEvent' }
      & Pick<DocumentTransactionStatusUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionUnlinkedEvent' }
      & Pick<DocumentTransactionUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentUpdatedAsEcmDocumentEvent' }
      & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExportedEvent' }
      & Pick<ExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExtractionEvent' }
      & Pick<ExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileAttachedToDocumentEvent' }
      & Pick<FileAttachedToDocumentEvent, 'id' | 'createdAt'>
      & FileAttachedToDocumentEventDataFragment
    ) | (
      { __typename: 'FileUploadedByEmailEvent' }
      & Pick<FileUploadedByEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileUploadedEvent' }
      & Pick<FileUploadedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'InvalidEInvoiceDetectedEvent' }
      & Pick<InvalidEInvoiceDetectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'MetaDataExtractionEvent' }
      & Pick<MetaDataExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionCreatedEvent' }
      & Pick<ProvisionCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionDeletedEvent' }
      & Pick<ProvisionDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionExportedEvent' }
      & Pick<ProvisionExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderLinkedEvent' }
      & Pick<PurchaseOrderLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderUnlinkedEvent' }
      & Pick<PurchaseOrderUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RejectedEvent' }
      & Pick<RejectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RequestApprovalEvent' }
      & Pick<RequestApprovalEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalCreatedEvent' }
      & Pick<ReversalCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalExportedEvent' }
      & Pick<ReversalExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SingleBookingUpdatedEvent' }
      & Pick<SingleBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SplitBookingUpdatedEvent' }
      & Pick<SplitBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteAddedEvent' }
      & Pick<SubstituteAddedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteRemovedEvent' }
      & Pick<SubstituteRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserApprovalDocumentWorkflowStep' }
      & Pick<UserApprovalDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserRejectedDocumentWorkflowStep' }
      & Pick<UserRejectedDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowCreatedForDocumentEvent' }
      & Pick<WorkflowCreatedForDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowStepSkippedEvent' }
      & Pick<WorkflowStepSkippedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowTemplateAppliedToDocumentEvent' }
      & Pick<WorkflowTemplateAppliedToDocumentEvent, 'id' | 'createdAt'>
    )>>, attachments?: Maybe<Array<(
      { __typename?: 'DocumentAttachment' }
      & Pick<DocumentAttachment, 'id' | 'name' | 'url' | 'pages' | 'rank' | 'size'>
    )>> }
  )> }
);

export type DeleteAttachmentsMutationVariables = Exact<{
  input: DeleteAttachmentsInput;
}>;


export type DeleteAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & { deleteAttachments?: Maybe<(
    { __typename: 'Document' }
    & Pick<Document, 'id'>
    & { timeline?: Maybe<Array<(
      { __typename: 'AccountingAreaUpdatedEvent' }
      & Pick<AccountingAreaUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApprovedEvent' }
      & Pick<ApprovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApproverExtractedEvent' }
      & Pick<ApproverExtractedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentConvertedToDocumentEvent' }
      & Pick<AttachmentConvertedToDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentDeletedFromDocumentEvent' }
      & Pick<AttachmentDeletedFromDocumentEvent, 'id' | 'createdAt'>
      & AttachmentDeletedFromDocumentEventDataFragment
    ) | (
      { __typename: 'AttachmentDetachedFromDocumentEvent' }
      & Pick<AttachmentDetachedFromDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ContactExtractionEvent' }
      & Pick<ContactExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ContactSupplierSendEmailEvent' }
      & Pick<ContactSupplierSendEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'CostCenterExtractionEvent' }
      & Pick<CostCenterExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsCreatedEvent' }
      & Pick<DocumentAssociationsCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsRemovedEvent' }
      & Pick<DocumentAssociationsRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentClassifiedEvent' }
      & Pick<DocumentClassifiedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentCommentEvent' }
      & Pick<DocumentCommentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedEvent' }
      & Pick<DocumentConvertedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedToAttachmentEvent' }
      & Pick<DocumentConvertedToAttachmentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentDeletedEvent' }
      & Pick<DocumentDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentFileReplacedEvent' }
      & Pick<DocumentFileReplacedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
      & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentImportedByMigrationEvent' }
      & Pick<DocumentImportedByMigrationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentPaymentInfoUpdatedEvent' }
      & Pick<DocumentPaymentInfoUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipCreatedEvent' }
      & Pick<DocumentRelationshipCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipRemovedEvent' }
      & Pick<DocumentRelationshipRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetByCandis' }
      & Pick<DocumentResetByCandis, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetManualEvent' }
      & Pick<DocumentResetManualEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionAmountUpdatedEvent' }
      & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionLinkedEvent' }
      & Pick<DocumentResetTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionUnLinkedEvent' }
      & Pick<DocumentResetTransactionUnLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserDeactivationEvent' }
      & Pick<DocumentResetUserDeactivationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserRoleChangedEvent' }
      & Pick<DocumentResetUserRoleChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentSepaTransferGeneratedEvent' }
      & Pick<DocumentSepaTransferGeneratedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentStoredAsEcmDocumentEvent' }
      & Pick<DocumentStoredAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTagUpdatedEvent' }
      & Pick<DocumentTagUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionCommentCreatedEvent' }
      & Pick<DocumentTransactionCommentCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionLinkedEvent' }
      & Pick<DocumentTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionReconciledEvent' }
      & Pick<DocumentTransactionReconciledEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionStatusUpdatedEvent' }
      & Pick<DocumentTransactionStatusUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionUnlinkedEvent' }
      & Pick<DocumentTransactionUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentUpdatedAsEcmDocumentEvent' }
      & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExportedEvent' }
      & Pick<ExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExtractionEvent' }
      & Pick<ExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileAttachedToDocumentEvent' }
      & Pick<FileAttachedToDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileUploadedByEmailEvent' }
      & Pick<FileUploadedByEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileUploadedEvent' }
      & Pick<FileUploadedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'InvalidEInvoiceDetectedEvent' }
      & Pick<InvalidEInvoiceDetectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'MetaDataExtractionEvent' }
      & Pick<MetaDataExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionCreatedEvent' }
      & Pick<ProvisionCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionDeletedEvent' }
      & Pick<ProvisionDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionExportedEvent' }
      & Pick<ProvisionExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderLinkedEvent' }
      & Pick<PurchaseOrderLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderUnlinkedEvent' }
      & Pick<PurchaseOrderUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RejectedEvent' }
      & Pick<RejectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RequestApprovalEvent' }
      & Pick<RequestApprovalEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalCreatedEvent' }
      & Pick<ReversalCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalExportedEvent' }
      & Pick<ReversalExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SingleBookingUpdatedEvent' }
      & Pick<SingleBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SplitBookingUpdatedEvent' }
      & Pick<SplitBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteAddedEvent' }
      & Pick<SubstituteAddedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteRemovedEvent' }
      & Pick<SubstituteRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserApprovalDocumentWorkflowStep' }
      & Pick<UserApprovalDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserRejectedDocumentWorkflowStep' }
      & Pick<UserRejectedDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowCreatedForDocumentEvent' }
      & Pick<WorkflowCreatedForDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowStepSkippedEvent' }
      & Pick<WorkflowStepSkippedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowTemplateAppliedToDocumentEvent' }
      & Pick<WorkflowTemplateAppliedToDocumentEvent, 'id' | 'createdAt'>
    )>>, attachments?: Maybe<Array<(
      { __typename?: 'DocumentAttachment' }
      & Pick<DocumentAttachment, 'id' | 'name' | 'url' | 'pages' | 'rank' | 'size'>
    )>> }
  )> }
);

export type ConvertDocumentsToAttachmentsMutationVariables = Exact<{
  input: ConvertDocumentsToAttachmentsInput;
}>;


export type ConvertDocumentsToAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & { convertDocumentsToAttachments?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  )> }
);

export type DetachAttachmentsMutationVariables = Exact<{
  input: DetachAttachmentsInput;
}>;


export type DetachAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & { detachAttachments?: Maybe<(
    { __typename: 'Document' }
    & Pick<Document, 'id'>
    & { timeline?: Maybe<Array<(
      { __typename: 'AccountingAreaUpdatedEvent' }
      & Pick<AccountingAreaUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApprovedEvent' }
      & Pick<ApprovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApproverExtractedEvent' }
      & Pick<ApproverExtractedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentConvertedToDocumentEvent' }
      & Pick<AttachmentConvertedToDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentDeletedFromDocumentEvent' }
      & Pick<AttachmentDeletedFromDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentDetachedFromDocumentEvent' }
      & Pick<AttachmentDetachedFromDocumentEvent, 'id' | 'createdAt'>
      & AttachmentDetachedFromDocumentEventDataFragment
    ) | (
      { __typename: 'ContactExtractionEvent' }
      & Pick<ContactExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ContactSupplierSendEmailEvent' }
      & Pick<ContactSupplierSendEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'CostCenterExtractionEvent' }
      & Pick<CostCenterExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsCreatedEvent' }
      & Pick<DocumentAssociationsCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsRemovedEvent' }
      & Pick<DocumentAssociationsRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentClassifiedEvent' }
      & Pick<DocumentClassifiedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentCommentEvent' }
      & Pick<DocumentCommentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedEvent' }
      & Pick<DocumentConvertedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedToAttachmentEvent' }
      & Pick<DocumentConvertedToAttachmentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentDeletedEvent' }
      & Pick<DocumentDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentFileReplacedEvent' }
      & Pick<DocumentFileReplacedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
      & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentImportedByMigrationEvent' }
      & Pick<DocumentImportedByMigrationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentPaymentInfoUpdatedEvent' }
      & Pick<DocumentPaymentInfoUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipCreatedEvent' }
      & Pick<DocumentRelationshipCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipRemovedEvent' }
      & Pick<DocumentRelationshipRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetByCandis' }
      & Pick<DocumentResetByCandis, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetManualEvent' }
      & Pick<DocumentResetManualEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionAmountUpdatedEvent' }
      & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionLinkedEvent' }
      & Pick<DocumentResetTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionUnLinkedEvent' }
      & Pick<DocumentResetTransactionUnLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserDeactivationEvent' }
      & Pick<DocumentResetUserDeactivationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserRoleChangedEvent' }
      & Pick<DocumentResetUserRoleChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentSepaTransferGeneratedEvent' }
      & Pick<DocumentSepaTransferGeneratedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentStoredAsEcmDocumentEvent' }
      & Pick<DocumentStoredAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTagUpdatedEvent' }
      & Pick<DocumentTagUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionCommentCreatedEvent' }
      & Pick<DocumentTransactionCommentCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionLinkedEvent' }
      & Pick<DocumentTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionReconciledEvent' }
      & Pick<DocumentTransactionReconciledEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionStatusUpdatedEvent' }
      & Pick<DocumentTransactionStatusUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionUnlinkedEvent' }
      & Pick<DocumentTransactionUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentUpdatedAsEcmDocumentEvent' }
      & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExportedEvent' }
      & Pick<ExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExtractionEvent' }
      & Pick<ExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileAttachedToDocumentEvent' }
      & Pick<FileAttachedToDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileUploadedByEmailEvent' }
      & Pick<FileUploadedByEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileUploadedEvent' }
      & Pick<FileUploadedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'InvalidEInvoiceDetectedEvent' }
      & Pick<InvalidEInvoiceDetectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'MetaDataExtractionEvent' }
      & Pick<MetaDataExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionCreatedEvent' }
      & Pick<ProvisionCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionDeletedEvent' }
      & Pick<ProvisionDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionExportedEvent' }
      & Pick<ProvisionExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderLinkedEvent' }
      & Pick<PurchaseOrderLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderUnlinkedEvent' }
      & Pick<PurchaseOrderUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RejectedEvent' }
      & Pick<RejectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RequestApprovalEvent' }
      & Pick<RequestApprovalEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalCreatedEvent' }
      & Pick<ReversalCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalExportedEvent' }
      & Pick<ReversalExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SingleBookingUpdatedEvent' }
      & Pick<SingleBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SplitBookingUpdatedEvent' }
      & Pick<SplitBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteAddedEvent' }
      & Pick<SubstituteAddedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteRemovedEvent' }
      & Pick<SubstituteRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserApprovalDocumentWorkflowStep' }
      & Pick<UserApprovalDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserRejectedDocumentWorkflowStep' }
      & Pick<UserRejectedDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowCreatedForDocumentEvent' }
      & Pick<WorkflowCreatedForDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowStepSkippedEvent' }
      & Pick<WorkflowStepSkippedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowTemplateAppliedToDocumentEvent' }
      & Pick<WorkflowTemplateAppliedToDocumentEvent, 'id' | 'createdAt'>
    )>>, attachments?: Maybe<Array<(
      { __typename?: 'DocumentAttachment' }
      & Pick<DocumentAttachment, 'id' | 'name' | 'url' | 'pages' | 'rank' | 'size'>
    )>> }
  )> }
);

export type DocumentSubmitWorkflowStepErrorsFragment = (
  { __typename?: 'DocumentSubmitWorkflowStepErrors' }
  & { errors: Array<(
    { __typename?: 'AccountingAreaDoesNotExistError' }
    & Pick<AccountingAreaDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'AccountingAreaIsArchivedError' }
    & Pick<AccountingAreaIsArchivedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'AccountingAreaRequiredError' }
    & Pick<AccountingAreaRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingKeyArchivedError' }
    & Pick<BookingKeyArchivedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingKeyDoesNotExistError' }
    & Pick<BookingKeyDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingKeyRequiredError' }
    & Pick<BookingKeyRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingModificationNotAllowedError' }
    & Pick<BookingModificationNotAllowedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingsError' }
    & Pick<BookingsError, 'kind' | 'message'>
    & BookingsErrorDataFragment
  ) | (
    { __typename?: 'CostCenterArchivedError' }
    & Pick<CostCenterArchivedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostCenterDoesNotExistError' }
    & Pick<CostCenterDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostCenterRequiredError' }
    & Pick<CostCenterRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostObjectArchivedError' }
    & Pick<CostObjectArchivedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostObjectDoesNotExistError' }
    & Pick<CostObjectDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostObjectRequiredError' }
    & Pick<CostObjectRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'DocumentCannotBeModifedError' }
    & Pick<DocumentCannotBeModifedError, 'kind' | 'message'>
    & DocumentCannotBeModifedErrorDataFragment
  ) | (
    { __typename?: 'DocumentDoesNotExistError' }
    & Pick<DocumentDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'DocumentGrossNetTaxRoundingAmountsCalculationError' }
    & Pick<DocumentGrossNetTaxRoundingAmountsCalculationError, 'kind' | 'message'>
  ) | (
    { __typename?: 'DocumentGrossNetTaxRoundingAmountsValidationError' }
    & Pick<DocumentGrossNetTaxRoundingAmountsValidationError, 'kind' | 'message'>
  ) | (
    { __typename?: 'ExtraCostInfoArchivedError' }
    & Pick<ExtraCostInfoArchivedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'ExtraCostInfoDoesNotExistError' }
    & Pick<ExtraCostInfoDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'GeneralLedgerArchivedError' }
    & Pick<GeneralLedgerArchivedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'GeneralLedgerDoesNotExistError' }
    & Pick<GeneralLedgerDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'GeneralLedgerRequiredError' }
    & Pick<GeneralLedgerRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'InvalidBookingAmountError' }
    & Pick<InvalidBookingAmountError, 'kind' | 'message'>
  ) | (
    { __typename?: 'InvalidBookingIdError' }
    & Pick<InvalidBookingIdError, 'kind' | 'message'>
  ) | (
    { __typename?: 'InvoiceHasNoBookingsError' }
    & Pick<InvoiceHasNoBookingsError, 'kind' | 'message'>
  ) | (
    { __typename?: 'NotCurrentWorkflowStepError' }
    & Pick<NotCurrentWorkflowStepError, 'kind' | 'message'>
  ) | (
    { __typename?: 'SumOfBookingsDoesNotMatchGrossAmountError' }
    & Pick<SumOfBookingsDoesNotMatchGrossAmountError, 'kind' | 'message'>
  ) | (
    { __typename?: 'UnexpectedDocumentApprovalError' }
    & Pick<UnexpectedDocumentApprovalError, 'kind' | 'message'>
  ) | (
    { __typename?: 'WorkflowStepAlreadyApprovedError' }
    & Pick<WorkflowStepAlreadyApprovedError, 'kind' | 'message'>
    & WorkflowStepAlreadyApprovedErrorDataFragment
  )> }
);


export type DocumentSubmitWorkflowStepErrorsFragmentVariables = Exact<{ [key: string]: never; }>;

export type SubmitWorkflowStepMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: DocumentWorkflowStepSubmitInput;
}>;


export type SubmitWorkflowStepMutation = (
  { __typename?: 'Mutation' }
  & { documentWorkflowStepSubmitUnionResponse?: Maybe<(
    { __typename: 'Document' }
    & DocumentDataFragment
  ) | (
    { __typename: 'DocumentSubmitWorkflowStepErrors' }
    & DocumentSubmitWorkflowStepErrorsFragment
  )> }
);

export type SmartFieldSuggestionsForApproverQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
}>;


export type SmartFieldSuggestionsForApproverQuery = (
  { __typename?: 'Query' }
  & { sfsSuggestionForApprover: (
    { __typename?: 'SfsSuggestions' }
    & { costCenter1?: Maybe<(
      { __typename?: 'SfsCostCenterSuggestion' }
      & Pick<SfsCostCenterSuggestion, 'confidence'>
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'readableName'>
      ) }
    )>, costCenter2?: Maybe<(
      { __typename?: 'SfsCostCenterSuggestion' }
      & Pick<SfsCostCenterSuggestion, 'confidence'>
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'readableName'>
      ) }
    )>, generalLedgerAccount?: Maybe<(
      { __typename?: 'SfsGeneralLedgerAccountSuggestion' }
      & Pick<SfsGeneralLedgerAccountSuggestion, 'confidence'>
      & { value: (
        { __typename?: 'BookingAccount' }
        & Pick<BookingAccount, 'id' | 'readableName'>
      ) }
    )>, bookingKey?: Maybe<(
      { __typename?: 'SfsBookingKeySuggestion' }
      & Pick<SfsBookingKeySuggestion, 'confidence'>
      & { value: (
        { __typename?: 'BookingKey' }
        & Pick<BookingKey, 'id' | 'readableName'>
      ) }
    )> }
  ) }
);

export type ActiveMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveMembersQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'roles' | 'avatarUrl'>
  )> }
);

export type CalculateDocumentGrossNetTaxRoundingAmountsQueryVariables = Exact<{
  input: CalculateDocumentGrossNetTaxRoundingAmountsInput;
}>;


export type CalculateDocumentGrossNetTaxRoundingAmountsQuery = (
  { __typename?: 'Query' }
  & { calculateDocumentGrossNetTaxRoundingAmounts: (
    { __typename?: 'CalculateDocumentGrossNetTaxRoundingAmountsErrors' }
    & { errors: Array<(
      { __typename?: 'CalculateDocumentGrossNetTaxRoundingAmountsError' }
      & Pick<CalculateDocumentGrossNetTaxRoundingAmountsError, 'kind' | 'message'>
    )> }
  ) | (
    { __typename?: 'CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult' }
    & Pick<CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult, 'currency' | 'netAmount' | 'grossAmount' | 'taxAmount' | 'rounding'>
  ) }
);

export type CreateInsightsWidgetMutationVariables = Exact<{
  input: InsightsWidgetInput;
}>;


export type CreateInsightsWidgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createInsightsWidget'>
);

export type UpdateInsightsWidgetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: InsightsWidgetInput;
}>;


export type UpdateInsightsWidgetMutation = (
  { __typename?: 'Mutation' }
  & { updateInsightsWidget?: Maybe<(
    { __typename?: 'InsightsWidget' }
    & Pick<InsightsWidget, 'id'>
  )> }
);

export type InsightsPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type InsightsPreferencesQuery = (
  { __typename?: 'Query' }
  & { insightsPreferences: (
    { __typename?: 'InsightsPreferences' }
    & Pick<InsightsPreferences, 'unit'>
  ) }
);

export type UpdateInsightsPreferencesMutationVariables = Exact<{
  input: InsightsPreferencesInput;
}>;


export type UpdateInsightsPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateInsightsPreferences: (
    { __typename?: 'InsightsPreferences' }
    & Pick<InsightsPreferences, 'unit'>
  ) }
);

export type ExportBookingAccountsGenericMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportBookingAccountsGenericMutation = (
  { __typename?: 'Mutation' }
  & { exportBookingAccountsGeneric: (
    { __typename?: 'ExportBookingAccountsGenericResult' }
    & Pick<ExportBookingAccountsGenericResult, 'url'>
  ) }
);

export type ExportBookingKeysGenericMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportBookingKeysGenericMutation = (
  { __typename?: 'Mutation' }
  & { exportBookingKeysGeneric: (
    { __typename?: 'ExportBookingKeysGenericResult' }
    & Pick<ExportBookingKeysGenericResult, 'url'>
  ) }
);

export type ExportCostCentersGenericMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportCostCentersGenericMutation = (
  { __typename?: 'Mutation' }
  & { exportCostCentersGeneric: (
    { __typename?: 'ExportCostCentersGenericResult' }
    & Pick<ExportCostCentersGenericResult, 'url'>
  ) }
);

export type GetDocumentForSidebarQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentForSidebarQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'status'>
    & { datevGuid?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'url'>
    )> }
  )> }
);

export type GetDatevLocationParamsForDocumentQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type GetDatevLocationParamsForDocumentQuery = (
  { __typename?: 'Query' }
  & { getLatestDatevExportOfDocument?: Maybe<(
    { __typename?: 'Export' }
    & Pick<Export, 'clientNumber' | 'consultantNumber'>
  )> }
);

export type ContactIbanFilterQueryVariables = Exact<{
  iban: Scalars['String']['input'];
}>;


export type ContactIbanFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactIbanFilter'>
);

export type ContactNameFilterQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type ContactNameFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactNameFilter'>
);

export type ContactTaxNumberFilterQueryVariables = Exact<{
  taxNumber: Scalars['String']['input'];
}>;


export type ContactTaxNumberFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactTaxNumberFilter'>
);

export type ContactVatIdFilterQueryVariables = Exact<{
  vatId: Scalars['String']['input'];
}>;


export type ContactVatIdFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactVatIdFilter'>
);

export type ContactAccountsPayableNumberFilterQueryVariables = Exact<{
  accountsPayableNumber: Scalars['String']['input'];
}>;


export type ContactAccountsPayableNumberFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactAccountsPayableNumberFilter'>
);

export type ContactAccountsReceivableNumberFilterQueryVariables = Exact<{
  accountsReceivableNumber: Scalars['String']['input'];
}>;


export type ContactAccountsReceivableNumberFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactAccountsReceivableNumberFilter'>
);

export type GetCardStatusByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCardStatusByIdQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardById: (
    { __typename?: 'CardIssuerCard' }
    & Pick<CardIssuerCard, 'status'>
  ) }
);

export type DocumentApprovalStepFragment = (
  { __typename?: 'DocumentApprovalWorkflowStep' }
  & Pick<DocumentApprovalWorkflowStep, 'id'>
  & { resolution?: Maybe<(
    { __typename?: 'WorkflowStepResolution' }
    & Pick<WorkflowStepResolution, 'type'>
    & { approvers?: Maybe<Array<(
      { __typename?: 'WorkflowUser' }
      & Pick<WorkflowUser, 'id' | 'name'>
    )>> }
  )>, approvers?: Maybe<Array<(
    { __typename?: 'WorkflowUser' }
    & Pick<WorkflowUser, 'id' | 'name'>
    & { substitute?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )>> }
);


export type DocumentApprovalStepFragmentVariables = Exact<{ [key: string]: never; }>;

export type UserApprovalStepFragment = (
  { __typename?: 'UserApprovalWorkflowStep' }
  & Pick<UserApprovalWorkflowStep, 'id'>
  & { resolution?: Maybe<(
    { __typename?: 'WorkflowStepResolution' }
    & Pick<WorkflowStepResolution, 'type'>
    & { approvers?: Maybe<Array<(
      { __typename?: 'WorkflowUser' }
      & Pick<WorkflowUser, 'id' | 'name'>
    )>> }
  )>, approvers?: Maybe<Array<(
    { __typename?: 'WorkflowUser' }
    & Pick<WorkflowUser, 'id' | 'name'>
    & { substitute?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )>> }
);


export type UserApprovalStepFragmentVariables = Exact<{ [key: string]: never; }>;

export type SystemApprovalStepFragment = (
  { __typename?: 'SystemApprovalWorkflowStep' }
  & Pick<SystemApprovalWorkflowStep, 'id'>
  & { resolution?: Maybe<(
    { __typename?: 'WorkflowStepResolution' }
    & Pick<WorkflowStepResolution, 'type'>
    & { approvers?: Maybe<Array<(
      { __typename?: 'WorkflowUser' }
      & Pick<WorkflowUser, 'id' | 'name'>
    )>> }
  )>, approvers?: Maybe<Array<(
    { __typename?: 'WorkflowUser' }
    & Pick<WorkflowUser, 'id' | 'name'>
    & { substitute?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )>> }
);


export type SystemApprovalStepFragmentVariables = Exact<{ [key: string]: never; }>;

export type WorkflowVisualizationFragment = (
  { __typename?: 'Workflow' }
  & Pick<Workflow, 'id' | 'name' | 'description'>
  & { workflowTemplate?: Maybe<(
    { __typename?: 'WorkflowTemplate' }
    & Pick<WorkflowTemplate, 'id' | 'name'>
    & { steps?: Maybe<Array<(
      { __typename?: 'WorkflowTemplateStep' }
      & { resolvers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )>>> }
    )>> }
  )>, steps?: Maybe<Array<(
    { __typename?: 'DocumentApprovalWorkflowStep' }
    & DocumentApprovalStepFragment
  ) | (
    { __typename?: 'SystemApprovalWorkflowStep' }
    & SystemApprovalStepFragment
  ) | (
    { __typename?: 'UserApprovalWorkflowStep' }
    & UserApprovalStepFragment
  )>> }
);


export type WorkflowVisualizationFragmentVariables = Exact<{ [key: string]: never; }>;

export type CountEcmDocumentsWithFullTextSearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EcmFilterInput>;
}>;


export type CountEcmDocumentsWithFullTextSearchQuery = (
  { __typename?: 'Query' }
  & { countEcmDocumentsWithFullTextSearch: (
    { __typename?: 'CountEcmDocumentsWithFullTextSearchResponse' }
    & Pick<CountEcmDocumentsWithFullTextSearchResponse, 'count'>
  ) }
);

export type GetOrgMembersAbsenceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgMembersAbsenceQuery = (
  { __typename?: 'Query' }
  & { getOrgMembersAbsence: Array<(
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'toDate' | 'note' | 'status' | 'membershipId' | 'substituteMembershipId'>
  )> }
);

export type ConvertDocumentMutationVariables = Exact<{
  input: ConvertDocumentInput;
}>;


export type ConvertDocumentMutation = (
  { __typename?: 'Mutation' }
  & { convertDocument: (
    { __typename?: 'ConvertDocumentResponse' }
    & Pick<ConvertDocumentResponse, 'globalDocumentId' | 'invoiceId'>
  ) }
);

export type CountClassifyingDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountClassifyingDocumentsQuery = (
  { __typename?: 'Query' }
  & { countClassifyingDocuments: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type OrganizationMembershipsQueryVariables = Exact<{
  filters?: InputMaybe<OrganizationMembershipsFiltersInput>;
  sortBy?: InputMaybe<MemberSortInput>;
}>;


export type OrganizationMembershipsQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'firstName' | 'lastName' | 'roles' | 'locale' | 'email' | 'avatarUrl' | 'status' | 'createdTimestamp' | 'username'>
    & { paymentInfo?: Maybe<(
      { __typename?: 'PaymentInfo' }
      & Pick<PaymentInfo, 'iban' | 'swiftCode'>
    )>, membershipRoles: Array<(
      { __typename: 'MembershipRole' }
      & Pick<MembershipRole, 'id' | 'name' | 'description' | 'isArchived' | 'isBuiltinRole' | 'isLegacyRole'>
    )> }
  )> }
);

export type GetMostActiveCardholdersQueryVariables = Exact<{
  input: CardIssuerPaginationInput;
  filters?: InputMaybe<CardIssuerCardholdersFiltersInput>;
  sortBy?: InputMaybe<CardIssuerCardholdersSortInput>;
}>;


export type GetMostActiveCardholdersQuery = (
  { __typename?: 'Query' }
  & { getMostActiveCardholders: (
    { __typename?: 'CardholdersConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardholderEdge' }
      & { node: (
        { __typename?: 'CardIssuerCardholder' }
        & Pick<CardIssuerCardholder, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        & { memberships: Array<Maybe<(
          { __typename?: 'CardIssuerCardholderMembership' }
          & Pick<CardIssuerCardholderMembership, 'membershipId' | 'candisOrganizationId'>
        )>> }
      ) }
    )>> }
  ) }
);

export type ActivateCardMutationVariables = Exact<{
  input: ActivateCardInput;
}>;


export type ActivateCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateCard'>
);

export type LockUnlockCardMutationVariables = Exact<{
  input: CardIssuerLockUnlockCardInput;
}>;


export type LockUnlockCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'lockUnlockCard'>
);

export type GetCardIssuerCardUniqueTokenByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCardIssuerCardUniqueTokenByIdQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardById: (
    { __typename?: 'CardIssuerCard' }
    & Pick<CardIssuerCard, 'id' | 'token' | 'uniqueToken'>
  ) }
);

export type CardCredentialByCardIdQueryVariables = Exact<{
  cardId: Scalars['ID']['input'];
}>;


export type CardCredentialByCardIdQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardCredential: (
    { __typename: 'CardIssuerCardCredential' }
    & Pick<CardIssuerCardCredential, 'token' | 'uniqueToken'>
  ) | (
    { __typename: 'RecentAuthnRequiredError' }
    & Pick<RecentAuthnRequiredError, 'requiredAuthType' | 'validForSeconds'>
  ) }
);

export type GetPinForCardQueryVariables = Exact<{
  cardId: Scalars['String']['input'];
}>;


export type GetPinForCardQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPINForCard'>
);

export type CardIssuerCardsForCardholderQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type CardIssuerCardsForCardholderQuery = (
  { __typename?: 'Query' }
  & { prevCard: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & Pick<CardIssuerCardEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerCard' }
        & Pick<CardIssuerCard, 'id'>
      ) }
    )>> }
  ), nextCard: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & Pick<CardIssuerCardEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerCard' }
        & Pick<CardIssuerCard, 'id'>
      ) }
    )>> }
  ) }
);

export type AcceptCardUsageAgreementMutationVariables = Exact<{
  input: CardIssuerCardUsageAgreementInput;
}>;


export type AcceptCardUsageAgreementMutation = (
  { __typename?: 'Mutation' }
  & { acceptCardUsageAgreement: (
    { __typename?: 'CardReference' }
    & Pick<CardReference, 'id'>
  ) }
);

export type GetCardPendingActionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCardPendingActionsQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardById: (
    { __typename?: 'CardIssuerCard' }
    & Pick<CardIssuerCard, 'id'>
    & { pendingActions?: Maybe<Array<(
      { __typename?: 'CardIssuerCardAction' }
      & Pick<CardIssuerCardAction, 'id' | 'name' | 'createdAt'>
    )>> }
  ) }
);

export type CardSubscriptionVariables = Exact<{
  input?: InputMaybe<CardSubscriptionInput>;
}>;


export type CardSubscription = (
  { __typename?: 'Subscription' }
  & { card?: Maybe<(
    { __typename?: 'CardIssuerCard' }
    & CreditCardByIdDataFragment
  )> }
);

export type CardForCardholderSubscriptionVariables = Exact<{
  input?: InputMaybe<CardSubscriptionInput>;
}>;


export type CardForCardholderSubscription = (
  { __typename?: 'Subscription' }
  & { card?: Maybe<(
    { __typename?: 'CardIssuerCard' }
    & CreditCardByIdDataFragment
  )> }
);

export type RequestVirtualCardIssuerCardMutationVariables = Exact<{
  input: RequestVirtualCardInput;
}>;


export type RequestVirtualCardIssuerCardMutation = (
  { __typename?: 'Mutation' }
  & { requestVirtualCardIssuerCard: (
    { __typename?: 'CardReference' }
    & Pick<CardReference, 'id'>
  ) }
);

export type RequestSingleUseVirtualCardIssuerCardMutationVariables = Exact<{
  input: RequestSingleUseVirtualCardInput;
}>;


export type RequestSingleUseVirtualCardIssuerCardMutation = (
  { __typename?: 'Mutation' }
  & { requestSingleUseVirtualCardIssuerCard: (
    { __typename?: 'CardReference' }
    & Pick<CardReference, 'id'>
  ) }
);

export type RequestPhysicalCardIssuerCardMutationVariables = Exact<{
  input: RequestPhysicalCardInput;
}>;


export type RequestPhysicalCardIssuerCardMutation = (
  { __typename?: 'Mutation' }
  & { requestPhysicalCardIssuerCard: (
    { __typename?: 'CardReference' }
    & Pick<CardReference, 'id'>
  ) }
);

export type IssueVirtualCardIssuerCardMutationVariables = Exact<{
  input: IssueVirtualCardInput;
}>;


export type IssueVirtualCardIssuerCardMutation = (
  { __typename?: 'Mutation' }
  & { issueVirtualCardIssuerCard: (
    { __typename?: 'CardReference' }
    & Pick<CardReference, 'id'>
  ) }
);

export type IssueSingleUseVirtualCardIssuerCardMutationVariables = Exact<{
  input: IssueSingleUseVirtualCardInput;
}>;


export type IssueSingleUseVirtualCardIssuerCardMutation = (
  { __typename?: 'Mutation' }
  & { issueSingleUseVirtualCardIssuerCard: (
    { __typename?: 'CardReference' }
    & Pick<CardReference, 'id'>
  ) }
);

export type IssuePhysicalCardIssuerCardMutationVariables = Exact<{
  input: IssuePhysicalCardInput;
}>;


export type IssuePhysicalCardIssuerCardMutation = (
  { __typename?: 'Mutation' }
  & { issuePhysicalCardIssuerCard: (
    { __typename?: 'CardReference' }
    & Pick<CardReference, 'id'>
  ) }
);

export type ApproveVirtualCardRequestMutationVariables = Exact<{
  input: ApproveVirtualCardRequestInput;
}>;


export type ApproveVirtualCardRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveVirtualCardRequest'>
);

export type ApproveSingleUseVirtualCardRequestMutationVariables = Exact<{
  input: ApproveSingleUseVirtualCardRequestInput;
}>;


export type ApproveSingleUseVirtualCardRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveSingleUseVirtualCardRequest'>
);

export type ApprovePhysicalCardRequestMutationVariables = Exact<{
  input: ApprovePhysicalCardRequestInput;
}>;


export type ApprovePhysicalCardRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approvePhysicalCardRequest'>
);

export type RejectCardRequestMutationVariables = Exact<{
  input: RejectCardRequestInput;
}>;


export type RejectCardRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectCardRequest'>
);

export type CancelCardRequestMutationVariables = Exact<{
  input: CancelCardRequestInput;
}>;


export type CancelCardRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCardRequest'>
);

export type RequestCardLimitChangeMutationVariables = Exact<{
  input: RequestCardLimitChangeInput;
}>;


export type RequestCardLimitChangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestCardLimitChange'>
);

export type ChangeCardLimitMutationVariables = Exact<{
  input: ChangeCardLimitInput;
}>;


export type ChangeCardLimitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeCardLimit'>
);

export type ApproveCardLimitChangeRequestMutationVariables = Exact<{
  input: ApproveCardLimitChangeRequestInput;
}>;


export type ApproveCardLimitChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveCardLimitChangeRequest'>
);

export type UpdateCardAccountingDataMutationVariables = Exact<{
  input: UpdateCardAccountingDataInput;
}>;


export type UpdateCardAccountingDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCardAccountingData'>
);

export type ReplaceCardMutationVariables = Exact<{
  input: CardIssuerReplaceCardInput;
}>;


export type ReplaceCardMutation = (
  { __typename?: 'Mutation' }
  & { replaceCard: (
    { __typename?: 'CardIssuerCard' }
    & Pick<CardIssuerCard, 'id' | 'refNum'>
  ) }
);

export type UpdateCardSettingsDataMutationVariables = Exact<{
  input: UpdateCardSettingsDataInput;
}>;


export type UpdateCardSettingsDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCardSettingsData'>
);

export type VirtualCardRequestDataFragment = (
  { __typename?: 'VirtualCardRequest' }
  & Pick<VirtualCardRequest, 'validityPeriod' | 'approvedLabel' | 'label' | 'comment' | 'requestedLimitRenewFrequency'>
  & { requestedLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, requestedTransactionLimit: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ) }
);


export type VirtualCardRequestDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SingleUseVirtualCardRequestDataFragment = (
  { __typename?: 'SingleUseVirtualCardRequest' }
  & Pick<SingleUseVirtualCardRequest, 'purpose'>
  & { requestedTransactionLimit: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ) }
);


export type SingleUseVirtualCardRequestDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PhysicalCardRequestDataFragment = (
  { __typename?: 'PhysicalCardRequest' }
  & Pick<PhysicalCardRequest, 'comment' | 'label' | 'requestedLimitRenewFrequency'>
  & { requestedLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, requestedTransactionLimit: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ) }
);


export type PhysicalCardRequestDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CardLimitChangeRequestDataFragment = (
  { __typename?: 'CardLimitChangeRequest' }
  & Pick<CardLimitChangeRequest, 'comment' | 'requestedLimitRenewFrequency'>
  & { requestedTransactionLimit: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ), requestedLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )> }
);


export type CardLimitChangeRequestDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CreditCardByIdDataFragment = (
  { __typename?: 'CardIssuerCard' }
  & Pick<CardIssuerCard, 'id' | 'permissionsForMembership' | 'type' | 'status' | 'label' | 'refNum' | 'issuingDate' | 'shippingDate' | 'expiryDate' | 'purpose' | 'limitRenewFrequency' | 'limitRenewDate' | 'brand' | 'hasAccountingData' | 'replacedByCardId' | 'replacedCardId' | 'isInvoiceNeeded'>
  & { cardholder: (
    { __typename?: 'CardholderMembership' }
    & Pick<CardholderMembership, 'id' | 'membershipId' | 'membershipEmail' | 'firstName' | 'lastName'>
  ), limit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, spendingBalance?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, availableBalance?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, transactionLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, pendingRequest?: Maybe<(
    { __typename: 'CardLimitChangeRequest' }
    & Pick<CardLimitChangeRequest, 'id' | 'status' | 'createdAt'>
    & CardLimitChangeRequestDataFragment
  ) | (
    { __typename: 'PhysicalCardRequest' }
    & Pick<PhysicalCardRequest, 'id' | 'status' | 'createdAt'>
    & PhysicalCardRequestDataFragment
  ) | (
    { __typename: 'SingleUseVirtualCardRequest' }
    & Pick<SingleUseVirtualCardRequest, 'id' | 'status' | 'createdAt'>
    & SingleUseVirtualCardRequestDataFragment
  ) | (
    { __typename: 'VirtualCardRequest' }
    & Pick<VirtualCardRequest, 'id' | 'status' | 'createdAt'>
    & VirtualCardRequestDataFragment
  )>, allRequests: Array<(
    { __typename: 'CardLimitChangeRequest' }
    & Pick<CardLimitChangeRequest, 'id' | 'status' | 'createdAt'>
    & CardLimitChangeRequestDataFragment
  ) | (
    { __typename: 'PhysicalCardRequest' }
    & Pick<PhysicalCardRequest, 'id' | 'status' | 'createdAt'>
    & PhysicalCardRequestDataFragment
  ) | (
    { __typename: 'SingleUseVirtualCardRequest' }
    & Pick<SingleUseVirtualCardRequest, 'id' | 'status' | 'createdAt'>
    & SingleUseVirtualCardRequestDataFragment
  ) | (
    { __typename: 'VirtualCardRequest' }
    & Pick<VirtualCardRequest, 'id' | 'status' | 'createdAt'>
    & VirtualCardRequestDataFragment
  )>, pendingActions?: Maybe<Array<(
    { __typename?: 'CardIssuerCardAction' }
    & Pick<CardIssuerCardAction, 'id' | 'name' | 'createdAt'>
  )>>, linkedVendors?: Maybe<(
    { __typename?: 'VendorsForRecurringPaymentsConnectionPageBased' }
    & { records: Array<(
      { __typename?: 'VendorForRecurringPaymentData' }
      & { vendor: (
        { __typename?: 'RecurringPaymentVendor' }
        & Pick<RecurringPaymentVendor, 'name'>
      ), linkedCards: Array<(
        { __typename?: 'LinkedCard' }
        & Pick<LinkedCard, 'transactionState'>
        & { card: (
          { __typename?: 'CardIssuerCard' }
          & Pick<CardIssuerCard, 'id'>
        ) }
      )> }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount'>
    ) }
  )>, accountingData?: Maybe<(
    { __typename?: 'CardAccountingData' }
    & Pick<CardAccountingData, 'contactId' | 'costCenterId' | 'costObjectId' | 'generalLedgerAccountId' | 'bookingKeyId' | 'artistSocialInsuranceCode' | 'extraCostInfoId' | 'paymentConditionId' | 'approvalWorkflowTemplateId' | 'approverIds'>
  )>, replacedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )> }
);


export type CreditCardByIdDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetCardIssuerCardByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCardIssuerCardByIdQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardById: (
    { __typename?: 'CardIssuerCard' }
    & CreditCardByIdDataFragment
  ) }
);

export type UpdateCardLabelMutationVariables = Exact<{
  input: UpdateCardLabelInput;
}>;


export type UpdateCardLabelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCardLabel'>
);

export type TerminateCardMutationVariables = Exact<{
  input: CardIssuerTerminateCardInput;
}>;


export type TerminateCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'terminateCard'>
);

export type TerminateProcessingCardMutationVariables = Exact<{
  input: CardIssuerCancelParkedCardInput;
}>;


export type TerminateProcessingCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelParkedCard'>
);

export type UpdateCardPurposeMutationVariables = Exact<{
  input: UpdateCardPurposeInput;
}>;


export type UpdateCardPurposeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCardPurpose'>
);

export type AggregatedEcmDocumentFragment = (
  { __typename?: 'AggregatedEcmDocument' }
  & Pick<AggregatedEcmDocument, 'globalDocumentId' | 'contactName' | 'createdAt' | 'documentName' | 'notes' | 'documentNumber' | 'documentStatus' | 'documentDate' | 'isSensitive' | 'type' | 'terminationReminderDate' | 'startDate' | 'endDate'>
  & { fileSource?: Maybe<(
    { __typename?: 'EcmFileSource' }
    & Pick<EcmFileSource, 'id' | 'name'>
  )>, amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'avatarUrl' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'description'>
  )>, notifyPerson?: Maybe<(
    { __typename?: 'EcmDocumentNotifyPerson' }
    & { primary: Array<(
      { __typename?: 'User' }
      & Pick<User, 'avatarUrl' | 'name'>
    )> }
  )>, responsiblePerson?: Maybe<(
    { __typename?: 'EcmDocumentResponsiblePerson' }
    & { primary: Array<(
      { __typename?: 'User' }
      & Pick<User, 'avatarUrl' | 'name'>
    )> }
  )> }
);


export type AggregatedEcmDocumentFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'status' | 'isOverdue' | 'eInvoiceValidationErrors' | 'isEInvoice' | 'createdAt'>
  & { globalId: Document['globalDocumentId'] }
  & { amount?: Maybe<(
    { __typename?: 'FloatField' }
    & Pick<FloatField, 'value'>
  )>, currency?: Maybe<(
    { __typename?: 'DocumentCurrencyField' }
    & Pick<DocumentCurrencyField, 'value'>
  )>, contact?: Maybe<(
    { __typename?: 'ContactField' }
    & { value: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'id'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    ) }
  )>, invoiceDate?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, invoiceNumber?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, eInvoice?: Maybe<(
    { __typename?: 'DocumentEInvoiceField' }
    & Pick<DocumentEInvoiceField, 'format' | 'error'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'description'>
  )>, documentFile?: Maybe<(
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'name'>
  )>, bookings?: Maybe<Array<(
    { __typename?: 'Booking' }
    & { note?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )> }
  )>>, note?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, attachments?: Maybe<Array<(
    { __typename?: 'DocumentAttachment' }
    & Pick<DocumentAttachment, 'id'>
  )>> }
);


export type DocumentFragmentVariables = Exact<{ [key: string]: never; }>;

export type RelatedDocumentFragment = (
  { __typename?: 'RelatedDocument' }
  & Pick<RelatedDocument, 'id'>
  & { document?: Maybe<(
    { __typename?: 'AggregatedEcmDocument' }
    & AggregatedEcmDocumentFragment
  ) | (
    { __typename?: 'Document' }
    & DocumentFragment
  )> }
);


export type RelatedDocumentFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentRelationshipsQueryVariables = Exact<{
  input: GetDocumentRelationshipInput;
}>;


export type DocumentRelationshipsQuery = (
  { __typename?: 'Query' }
  & { documentRelationships: (
    { __typename?: 'GetDocumentRelationshipResponse' }
    & { relationships: Array<(
      { __typename?: 'DocumentRelationship' }
      & Pick<DocumentRelationship, 'id'>
      & { linkedDocument?: Maybe<(
        { __typename?: 'RelatedDocument' }
        & RelatedDocumentFragment
      ) | { __typename?: 'RelatedDocumentError' }> }
    )> }
  ) }
);

export type DocumentRelationshipsFilesQueryVariables = Exact<{
  input: GetDocumentRelationshipInput;
}>;


export type DocumentRelationshipsFilesQuery = (
  { __typename?: 'Query' }
  & { documentRelationships: (
    { __typename?: 'GetDocumentRelationshipResponse' }
    & { relationships: Array<(
      { __typename?: 'DocumentRelationship' }
      & { linkedDocument?: Maybe<(
        { __typename?: 'RelatedDocument' }
        & { document?: Maybe<(
          { __typename: 'AggregatedEcmDocument' }
          & { fileSource?: Maybe<(
            { __typename?: 'EcmFileSource' }
            & Pick<EcmFileSource, 'name' | 'url' | 'id'>
          )> }
        ) | (
          { __typename: 'Document' }
          & { documentFile?: Maybe<(
            { __typename?: 'DocumentFile' }
            & Pick<DocumentFile, 'name' | 'url' | 'id'>
          )>, attachments?: Maybe<Array<(
            { __typename?: 'DocumentAttachment' }
            & Pick<DocumentAttachment, 'name' | 'url' | 'id'>
          )>> }
        )> }
      ) | { __typename?: 'RelatedDocumentError' }> }
    )> }
  ) }
);

export type DeleteDocumentRelationshipMutationVariables = Exact<{
  relationshipId: Scalars['String']['input'];
}>;


export type DeleteDocumentRelationshipMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocumentRelationship: (
    { __typename?: 'DeleteDocumentRelationshipResponse' }
    & Pick<DeleteDocumentRelationshipResponse, 'id'>
  ) }
);

export type CreateDocumentRelationshipsMutationVariables = Exact<{
  input: CreateDocumentRelationshipsInput;
}>;


export type CreateDocumentRelationshipsMutation = (
  { __typename?: 'Mutation' }
  & { createDocumentRelationships: { __typename: 'CreateDocumentRelationshipResponse' } }
);

export type InAppNotificationData_CardRequestApprovedInAppNotification_Fragment = (
  { __typename: 'CardRequestApprovedInAppNotification' }
  & Pick<CardRequestApprovedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_CardRequestCreatedInAppNotification_Fragment = (
  { __typename: 'CardRequestCreatedInAppNotification' }
  & Pick<CardRequestCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_CardRequestRejectedInAppNotification_Fragment = (
  { __typename: 'CardRequestRejectedInAppNotification' }
  & Pick<CardRequestRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_DocumentCommentCreatedInAppNotification_Fragment = (
  { __typename: 'DocumentCommentCreatedInAppNotification' }
  & Pick<DocumentCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_DocumentRejectedInAppNotification_Fragment = (
  { __typename: 'DocumentRejectedInAppNotification' }
  & Pick<DocumentRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_ReimbursementCaseAmendmentRequestedInAppNotification_Fragment = (
  { __typename: 'ReimbursementCaseAmendmentRequestedInAppNotification' }
  & Pick<ReimbursementCaseAmendmentRequestedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_ReimbursementCaseApprovedInAppNotification_Fragment = (
  { __typename: 'ReimbursementCaseApprovedInAppNotification' }
  & Pick<ReimbursementCaseApprovedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_ReimbursementCaseCommentCreatedInAppNotification_Fragment = (
  { __typename: 'ReimbursementCaseCommentCreatedInAppNotification' }
  & Pick<ReimbursementCaseCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_ReimbursementCaseItemExcludedInAppNotification_Fragment = (
  { __typename: 'ReimbursementCaseItemExcludedInAppNotification' }
  & Pick<ReimbursementCaseItemExcludedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_ReimbursementCaseRejectedInAppNotification_Fragment = (
  { __typename: 'ReimbursementCaseRejectedInAppNotification' }
  & Pick<ReimbursementCaseRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_TransactionCommentCreatedInAppNotification_Fragment = (
  { __typename: 'TransactionCommentCreatedInAppNotification' }
  & Pick<TransactionCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationData_TransactionDeclinedInAppNotification_Fragment = (
  { __typename: 'TransactionDeclinedInAppNotification' }
  & Pick<TransactionDeclinedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
);

export type InAppNotificationDataFragment = InAppNotificationData_CardRequestApprovedInAppNotification_Fragment | InAppNotificationData_CardRequestCreatedInAppNotification_Fragment | InAppNotificationData_CardRequestRejectedInAppNotification_Fragment | InAppNotificationData_DocumentCommentCreatedInAppNotification_Fragment | InAppNotificationData_DocumentRejectedInAppNotification_Fragment | InAppNotificationData_ReimbursementCaseAmendmentRequestedInAppNotification_Fragment | InAppNotificationData_ReimbursementCaseApprovedInAppNotification_Fragment | InAppNotificationData_ReimbursementCaseCommentCreatedInAppNotification_Fragment | InAppNotificationData_ReimbursementCaseItemExcludedInAppNotification_Fragment | InAppNotificationData_ReimbursementCaseRejectedInAppNotification_Fragment | InAppNotificationData_TransactionCommentCreatedInAppNotification_Fragment | InAppNotificationData_TransactionDeclinedInAppNotification_Fragment;


export type InAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CardRequestApprovedInAppNotificationDataFragment = (
  { __typename: 'CardRequestApprovedInAppNotification' }
  & Pick<CardRequestApprovedInAppNotification, 'cardRequestType' | 'resolutionComment' | 'cardId' | 'cardRefNum' | 'cardRequestStatusChangedAt'>
  & { limit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, transactionLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, cardManager: (
    { __typename?: 'User' }
    & Pick<User, 'avatarUrl' | 'name'>
  ) }
);


export type CardRequestApprovedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CardRequestCreatedInAppNotificationDataFragment = (
  { __typename: 'CardRequestCreatedInAppNotification' }
  & Pick<CardRequestCreatedInAppNotification, 'cardRequestCreatedAt' | 'cardRequestType' | 'cardId' | 'cardRefNum'>
  & { requester: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ), newRequestedLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, newRequestedTransactionLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )> }
);


export type CardRequestCreatedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CardRequestRejectedInAppNotificationDataFragment = (
  { __typename: 'CardRequestRejectedInAppNotification' }
  & Pick<CardRequestRejectedInAppNotification, 'organizationId' | 'cardRequestStatusChangedAt' | 'cardRequestType' | 'resolutionComment' | 'cardRefNum'>
  & { limit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, transactionLimit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, cardManager: (
    { __typename?: 'User' }
    & Pick<User, 'avatarUrl' | 'name'>
  ) }
);


export type CardRequestRejectedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentCommentCreatedInAppNotificationDataFragment = (
  { __typename: 'DocumentCommentCreatedInAppNotification' }
  & Pick<DocumentCommentCreatedInAppNotification, 'documentId' | 'globalDocumentId' | 'documentType' | 'commentText' | 'commentCreatedAt' | 'invoiceType' | 'invoiceContact' | 'invoiceStatus' | 'fileName' | 'organizationId'>
  & { documentNumberCommentCreated: DocumentCommentCreatedInAppNotification['documentNumber'] }
  & { invoiceAmount?: Maybe<(
    { __typename?: 'NotificationAmount' }
    & Pick<NotificationAmount, 'value' | 'currencyCode'>
  )>, author: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ) }
);


export type DocumentCommentCreatedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionDeclinedInAppNotificationDataFragment = (
  { __typename: 'TransactionDeclinedInAppNotification' }
  & Pick<TransactionDeclinedInAppNotification, 'transactionId' | 'merchantName' | 'cardRefNum' | 'organizationId' | 'transactionCreatedAt' | 'notificationCreatedAt'>
  & { declineReason: (
    { __typename?: 'TransactionDeclinedReason' }
    & { title?: Maybe<(
      { __typename?: 'TransactionDeclinedReasonTitle' }
      & Pick<TransactionDeclinedReasonTitle, 'de' | 'en'>
    )> }
  ), transactionAmount: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ) }
);


export type TransactionDeclinedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentRejectedInAppNotificationDataFragment = (
  { __typename: 'DocumentRejectedInAppNotification' }
  & Pick<DocumentRejectedInAppNotification, 'documentId' | 'commentText' | 'rejectedAt' | 'invoiceContact' | 'documentNumber' | 'fileName' | 'organizationId'>
  & { invoiceAmount?: Maybe<(
    { __typename: 'NotificationAmount' }
    & Pick<NotificationAmount, 'value' | 'currencyCode'>
  )>, rejectedBy: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ) }
);


export type DocumentRejectedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionCommentCreatedInAppNotificationDataFragment = (
  { __typename: 'TransactionCommentCreatedInAppNotification' }
  & Pick<TransactionCommentCreatedInAppNotification, 'transactionId' | 'commentText' | 'commentCreatedAt' | 'cardId' | 'cardRefNum' | 'cardLabel' | 'merchantName' | 'organizationId'>
  & { transactionAmount: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
  ), author: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ) }
);


export type TransactionCommentCreatedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseRejectedInAppNotificationDataFragment = (
  { __typename: 'ReimbursementCaseRejectedInAppNotification' }
  & Pick<ReimbursementCaseRejectedInAppNotification, 'reimbursementCaseId' | 'reimbursementCaseTitle' | 'commentText' | 'rejectedAt'>
  & { reimbursementCaseTotalAmount: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ), rejectedBy: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ) }
);


export type ReimbursementCaseRejectedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseItemExcludedInAppNotificationDataFragment = (
  { __typename: 'ReimbursementCaseItemExcludedInAppNotification' }
  & Pick<ReimbursementCaseItemExcludedInAppNotification, 'title' | 'type' | 'reimbursementCaseId' | 'reimbursementCaseTitle' | 'updatedAt'>
  & { totalAmount: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ), updatedBy: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ) }
);


export type ReimbursementCaseItemExcludedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseAmendmentRequestedInAppNotificationDataFragment = (
  { __typename: 'ReimbursementCaseAmendmentRequestedInAppNotification' }
  & Pick<ReimbursementCaseAmendmentRequestedInAppNotification, 'reimbursementCaseId' | 'reimbursementCaseTitle' | 'commentText' | 'reviewedAt'>
  & { reimbursementCaseTotalAmount: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ), reviewedBy: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ) }
);


export type ReimbursementCaseAmendmentRequestedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseApprovedInAppNotificationDataFragment = (
  { __typename: 'ReimbursementCaseApprovedInAppNotification' }
  & Pick<ReimbursementCaseApprovedInAppNotification, 'reimbursementCaseId' | 'reimbursementCaseTitle' | 'approvedAt'>
  & { approvedBy: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ) }
);


export type ReimbursementCaseApprovedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseCommentCreatedInAppNotificationDataFragment = (
  { __typename: 'ReimbursementCaseCommentCreatedInAppNotification' }
  & Pick<ReimbursementCaseCommentCreatedInAppNotification, 'reimbursementCaseId' | 'reimbursementCaseTitle' | 'commentText' | 'commentCreatedAt'>
  & { author: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ), reimbursementCaseTotalAmount: (
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  ) }
);


export type ReimbursementCaseCommentCreatedInAppNotificationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type InAppNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InAppNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { inAppNotification?: Maybe<(
    { __typename: 'CardRequestApprovedInAppNotification' }
    & Pick<CardRequestApprovedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_CardRequestApprovedInAppNotification_Fragment
    & CardRequestApprovedInAppNotificationDataFragment
  ) | (
    { __typename: 'CardRequestCreatedInAppNotification' }
    & Pick<CardRequestCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_CardRequestCreatedInAppNotification_Fragment
    & CardRequestCreatedInAppNotificationDataFragment
  ) | (
    { __typename: 'CardRequestRejectedInAppNotification' }
    & Pick<CardRequestRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_CardRequestRejectedInAppNotification_Fragment
    & CardRequestRejectedInAppNotificationDataFragment
  ) | (
    { __typename: 'DocumentCommentCreatedInAppNotification' }
    & Pick<DocumentCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_DocumentCommentCreatedInAppNotification_Fragment
    & DocumentCommentCreatedInAppNotificationDataFragment
  ) | (
    { __typename: 'DocumentRejectedInAppNotification' }
    & Pick<DocumentRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_DocumentRejectedInAppNotification_Fragment
    & DocumentRejectedInAppNotificationDataFragment
  ) | (
    { __typename: 'ReimbursementCaseAmendmentRequestedInAppNotification' }
    & Pick<ReimbursementCaseAmendmentRequestedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_ReimbursementCaseAmendmentRequestedInAppNotification_Fragment
    & ReimbursementCaseAmendmentRequestedInAppNotificationDataFragment
  ) | (
    { __typename: 'ReimbursementCaseApprovedInAppNotification' }
    & Pick<ReimbursementCaseApprovedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_ReimbursementCaseApprovedInAppNotification_Fragment
    & ReimbursementCaseApprovedInAppNotificationDataFragment
  ) | (
    { __typename: 'ReimbursementCaseCommentCreatedInAppNotification' }
    & Pick<ReimbursementCaseCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_ReimbursementCaseCommentCreatedInAppNotification_Fragment
    & ReimbursementCaseCommentCreatedInAppNotificationDataFragment
  ) | (
    { __typename: 'ReimbursementCaseItemExcludedInAppNotification' }
    & Pick<ReimbursementCaseItemExcludedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_ReimbursementCaseItemExcludedInAppNotification_Fragment
    & ReimbursementCaseItemExcludedInAppNotificationDataFragment
  ) | (
    { __typename: 'ReimbursementCaseRejectedInAppNotification' }
    & Pick<ReimbursementCaseRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_ReimbursementCaseRejectedInAppNotification_Fragment
    & ReimbursementCaseRejectedInAppNotificationDataFragment
  ) | (
    { __typename: 'TransactionCommentCreatedInAppNotification' }
    & Pick<TransactionCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_TransactionCommentCreatedInAppNotification_Fragment
    & TransactionCommentCreatedInAppNotificationDataFragment
  ) | (
    { __typename: 'TransactionDeclinedInAppNotification' }
    & Pick<TransactionDeclinedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
    & InAppNotificationData_TransactionDeclinedInAppNotification_Fragment
    & TransactionDeclinedInAppNotificationDataFragment
  )> }
);

export type InAppNotificationsPageBasedQueryVariables = Exact<{
  input?: InputMaybe<InAppNotificationPaginationInput>;
  filters?: InputMaybe<InAppNotificationFilterInput>;
  dateRangeFilters?: InputMaybe<InAppNotificationDateFilterInput>;
  sortBy?: InputMaybe<InAppNotificationSortInput>;
}>;


export type InAppNotificationsPageBasedQuery = (
  { __typename?: 'Query' }
  & { inAppNotifications: (
    { __typename?: 'InAppNotificationPaginationResult' }
    & { records: Array<(
      { __typename: 'CardRequestApprovedInAppNotification' }
      & Pick<CardRequestApprovedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_CardRequestApprovedInAppNotification_Fragment
      & CardRequestApprovedInAppNotificationDataFragment
    ) | (
      { __typename: 'CardRequestCreatedInAppNotification' }
      & Pick<CardRequestCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_CardRequestCreatedInAppNotification_Fragment
      & CardRequestCreatedInAppNotificationDataFragment
    ) | (
      { __typename: 'CardRequestRejectedInAppNotification' }
      & Pick<CardRequestRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_CardRequestRejectedInAppNotification_Fragment
      & CardRequestRejectedInAppNotificationDataFragment
    ) | (
      { __typename: 'DocumentCommentCreatedInAppNotification' }
      & Pick<DocumentCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_DocumentCommentCreatedInAppNotification_Fragment
      & DocumentCommentCreatedInAppNotificationDataFragment
    ) | (
      { __typename: 'DocumentRejectedInAppNotification' }
      & Pick<DocumentRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_DocumentRejectedInAppNotification_Fragment
      & DocumentRejectedInAppNotificationDataFragment
    ) | (
      { __typename: 'ReimbursementCaseAmendmentRequestedInAppNotification' }
      & Pick<ReimbursementCaseAmendmentRequestedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_ReimbursementCaseAmendmentRequestedInAppNotification_Fragment
      & ReimbursementCaseAmendmentRequestedInAppNotificationDataFragment
    ) | (
      { __typename: 'ReimbursementCaseApprovedInAppNotification' }
      & Pick<ReimbursementCaseApprovedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_ReimbursementCaseApprovedInAppNotification_Fragment
      & ReimbursementCaseApprovedInAppNotificationDataFragment
    ) | (
      { __typename: 'ReimbursementCaseCommentCreatedInAppNotification' }
      & Pick<ReimbursementCaseCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_ReimbursementCaseCommentCreatedInAppNotification_Fragment
      & ReimbursementCaseCommentCreatedInAppNotificationDataFragment
    ) | (
      { __typename: 'ReimbursementCaseItemExcludedInAppNotification' }
      & Pick<ReimbursementCaseItemExcludedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_ReimbursementCaseItemExcludedInAppNotification_Fragment
      & ReimbursementCaseItemExcludedInAppNotificationDataFragment
    ) | (
      { __typename: 'ReimbursementCaseRejectedInAppNotification' }
      & Pick<ReimbursementCaseRejectedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_ReimbursementCaseRejectedInAppNotification_Fragment
      & ReimbursementCaseRejectedInAppNotificationDataFragment
    ) | (
      { __typename: 'TransactionCommentCreatedInAppNotification' }
      & Pick<TransactionCommentCreatedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_TransactionCommentCreatedInAppNotification_Fragment
      & TransactionCommentCreatedInAppNotificationDataFragment
    ) | (
      { __typename: 'TransactionDeclinedInAppNotification' }
      & Pick<TransactionDeclinedInAppNotification, 'id' | 'isRead' | 'notificationCreatedAt' | 'organizationId'>
      & InAppNotificationData_TransactionDeclinedInAppNotification_Fragment
      & TransactionDeclinedInAppNotificationDataFragment
    )>, pageInfo: (
      { __typename: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount' | 'currentPage' | 'pageCount' | 'pageSize'>
    ) }
  ) }
);

export type OrganizationsByMembershipQueryVariables = Exact<{
  membershipId: Scalars['ID']['input'];
}>;


export type OrganizationsByMembershipQuery = (
  { __typename?: 'Query' }
  & { organizationsByMembership: Array<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'slug'>
  )> }
);

export type InAppNotificationsCountQueryVariables = Exact<{
  filters?: InputMaybe<InAppNotificationFilterInput>;
  dateRangeFilters?: InputMaybe<InAppNotificationDateFilterInput>;
}>;


export type InAppNotificationsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'inAppNotificationsCount'>
);

export type ToggleInAppNotificationsMutationVariables = Exact<{
  input: ToggleInAppNotificationsInput;
  filters?: InputMaybe<InAppNotificationFilterInput>;
  dateRangeFilters?: InputMaybe<InAppNotificationDateFilterInput>;
}>;


export type ToggleInAppNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleInAppNotifications'>
);

export type DocumentWorkflowStatusDataFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'status'>
  & { currentWorkflowStep?: Maybe<(
    { __typename?: 'DocumentApprovalWorkflowStep' }
    & Pick<DocumentApprovalWorkflowStep, 'id'>
  ) | (
    { __typename?: 'SystemApprovalWorkflowStep' }
    & Pick<SystemApprovalWorkflowStep, 'id'>
  ) | (
    { __typename?: 'UserApprovalWorkflowStep' }
    & Pick<UserApprovalWorkflowStep, 'id'>
  )>, workflow?: Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id'>
    & { steps?: Maybe<Array<(
      { __typename?: 'DocumentApprovalWorkflowStep' }
      & Pick<DocumentApprovalWorkflowStep, 'id'>
      & { resolution?: Maybe<(
        { __typename?: 'WorkflowStepResolution' }
        & Pick<WorkflowStepResolution, 'type'>
      )>, approvers?: Maybe<Array<(
        { __typename?: 'WorkflowUser' }
        & Pick<WorkflowUser, 'id'>
        & { substitute?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name'>
        )> }
      )>> }
    ) | (
      { __typename?: 'SystemApprovalWorkflowStep' }
      & Pick<SystemApprovalWorkflowStep, 'id'>
      & { resolution?: Maybe<(
        { __typename?: 'WorkflowStepResolution' }
        & Pick<WorkflowStepResolution, 'type'>
      )>, approvers?: Maybe<Array<(
        { __typename?: 'WorkflowUser' }
        & Pick<WorkflowUser, 'id'>
        & { substitute?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name'>
        )> }
      )>> }
    ) | (
      { __typename?: 'UserApprovalWorkflowStep' }
      & Pick<UserApprovalWorkflowStep, 'id'>
      & { resolution?: Maybe<(
        { __typename?: 'WorkflowStepResolution' }
        & Pick<WorkflowStepResolution, 'type'>
      )>, approvers?: Maybe<Array<(
        { __typename?: 'WorkflowUser' }
        & Pick<WorkflowUser, 'id'>
        & { substitute?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name'>
        )> }
      )>> }
    )>> }
  )> }
);


export type DocumentWorkflowStatusDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentWorkflowStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DocumentWorkflowStatusQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & DocumentWorkflowStatusDataFragment
  )> }
);

export type GetCountExportableEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountExportableEntitiesQuery = (
  { __typename?: 'Query' }
  & { getCountExportableEntities: (
    { __typename?: 'ExportableEntitiesMetric' }
    & { documentReadyForExportCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ), transactionReadyForExportCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ), pendingTransactionCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ), settlementsReadyForExportCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ), settlementsNotReadyForExportCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ), provisionsReadyForExportCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ), reversalsReadyForExportCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ), reimbursementItemsReadyForExportCount: (
      { __typename?: 'DocumentMetric' }
      & Pick<DocumentMetric, 'count'>
    ) }
  ) }
);

export type CountDocumentsByStatusNewQueryVariables = Exact<{ [key: string]: never; }>;


export type CountDocumentsByStatusNewQuery = (
  { __typename?: 'Query' }
  & { countDocumentsByStatus: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CountDocumentsByStatusRejectedForSidebarQueryVariables = Exact<{ [key: string]: never; }>;


export type CountDocumentsByStatusRejectedForSidebarQuery = (
  { __typename?: 'Query' }
  & { countDocumentsByStatus: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CountDocumentsByStatusApprovedForApprovalBlockQueryVariables = Exact<{ [key: string]: never; }>;


export type CountDocumentsByStatusApprovedForApprovalBlockQuery = (
  { __typename?: 'Query' }
  & { countDocumentsByStatus: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CountDocumentsIRejectedQueryVariables = Exact<{ [key: string]: never; }>;


export type CountDocumentsIRejectedQuery = (
  { __typename?: 'Query' }
  & { countDocumentsIRejected: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CountOpenApprovalsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountOpenApprovalsQuery = (
  { __typename?: 'Query' }
  & { countOpenApprovals: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CountPayableDocumentsQueryVariables = Exact<{
  hasIban?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CountPayableDocumentsQuery = (
  { __typename?: 'Query' }
  & { countPayableDocuments: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CountCardIssuerTransactionsQueryVariables = Exact<{
  input: CardIssuerTransactionCountInput;
  filters?: InputMaybe<CardIssuerTransactionFilterInput>;
}>;


export type CountCardIssuerTransactionsQuery = (
  { __typename?: 'Query' }
  & { countCardIssuerTransactions: (
    { __typename?: 'CardIssuerTransactionsCount' }
    & Pick<CardIssuerTransactionsCount, 'count'>
  ) }
);

export type InboxDocumentNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type InboxDocumentNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ) }
);

export type InboxInvoiceDocumentsNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type InboxInvoiceDocumentsNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'InboxInvoiceDocumentsResponse' }
    & BasicInboxInvoiceDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'InboxInvoiceDocumentsResponse' }
    & BasicInboxInvoiceDocumentConnectionDataFragment
  ) }
);

export type ApprovalsDocumentNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type ApprovalsDocumentNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ) }
);

export type ApprovalsDocumentNewEsNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type ApprovalsDocumentNewEsNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'ApprovalInvoiceDocumentsResponse' }
    & BasicApprovalDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'ApprovalInvoiceDocumentsResponse' }
    & BasicApprovalDocumentConnectionDataFragment
  ) }
);

export type ArchiveDocumentNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type ArchiveDocumentNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ) }
);

export type ArchiveDocumentsNavigationWithSearchNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
}>;


export type ArchiveDocumentsNavigationWithSearchNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ) }
);

export type ArchiveInvoiceDocumentsNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
}>;


export type ArchiveInvoiceDocumentsNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'ArchiveInvoiceDocumentsResponse' }
    & BasicArchiveInvoiceDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'ArchiveInvoiceDocumentsResponse' }
    & BasicArchiveInvoiceDocumentConnectionDataFragment
  ) }
);

export type EcmDocumentConnectionDataFragment = (
  { __typename?: 'GetEcmDocumentsResponse' }
  & { edges?: Maybe<Array<(
    { __typename?: 'EcmDocumentEdge' }
    & Pick<EcmDocumentEdge, 'cursor'>
    & { node: (
      { __typename?: 'EcmDocument' }
      & Pick<EcmDocument, 'id' | 'invoiceId' | 'documentType'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & TagFragment
      )> }
    ) }
  )>>, pageInfo: (
    { __typename?: 'EcmPageInfo' }
    & Pick<EcmPageInfo, 'totalCount'>
  ) }
);


export type EcmDocumentConnectionDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type AllDocumentConnectionDataFragment = (
  { __typename?: 'ArchiveAllDocumentsResponse' }
  & { edges?: Maybe<Array<(
    { __typename?: 'ArchiveAllDocumentsEdge' }
    & Pick<ArchiveAllDocumentsEdge, 'cursor'>
    & { node: (
      { __typename?: 'ArchiveAllDocumentsListItem' }
      & Pick<ArchiveAllDocumentsListItem, 'id' | 'invoiceId' | 'documentType'>
      & { reimbursementItem?: Maybe<(
        { __typename?: 'ArchiveAllDocumentsListItemReimbursementItem' }
        & Pick<ArchiveAllDocumentsListItemReimbursementItem, 'caseId'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & TagFragment
      )> }
    ) }
  )>>, pageInfo: (
    { __typename?: 'ArchiveAllDocumentsPageInfo' }
    & Pick<ArchiveAllDocumentsPageInfo, 'totalCount'>
  ) }
);


export type AllDocumentConnectionDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type EcmDocumentNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EcmFilterInput>;
}>;


export type EcmDocumentNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'GetEcmDocumentsResponse' }
    & EcmDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'GetEcmDocumentsResponse' }
    & EcmDocumentConnectionDataFragment
  ) }
);

export type AllDocumentNavigationDataQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ArchiveAllDocumentsFilterInput>;
}>;


export type AllDocumentNavigationDataQuery = (
  { __typename?: 'Query' }
  & { prevDocument: (
    { __typename?: 'ArchiveAllDocumentsResponse' }
    & AllDocumentConnectionDataFragment
  ), nextDocument: (
    { __typename?: 'ArchiveAllDocumentsResponse' }
    & AllDocumentConnectionDataFragment
  ) }
);

export type SapB1ImportResponseDataFragment = (
  { __typename: 'SapB1ImportResponse' }
  & Pick<SapB1ImportResponse, 'code'>
);


export type SapB1ImportResponseDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PurchaseOrderImportHistoryEntryDataFragment = (
  { __typename: 'PurchaseOrderImportHistoryEntry' }
  & Pick<PurchaseOrderImportHistoryEntry, 'id'>
);


export type PurchaseOrderImportHistoryEntryDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GoodsReceiptImportHistoryEntryDataFragment = (
  { __typename: 'GoodsReceiptImportHistoryEntry' }
  & Pick<GoodsReceiptImportHistoryEntry, 'id'>
);


export type GoodsReceiptImportHistoryEntryDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ImportSapB1GeneralLedgerAccountsMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportSapB1GeneralLedgerAccountsMutation = (
  { __typename?: 'Mutation' }
  & { importSapB1GeneralLedgerAccounts?: Maybe<(
    { __typename?: 'SapB1ImportResponse' }
    & Pick<SapB1ImportResponse, 'code'>
  )> }
);

export type ImportSapB1TaxCodesMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportSapB1TaxCodesMutation = (
  { __typename?: 'Mutation' }
  & { importSapB1TaxCodes?: Maybe<(
    { __typename?: 'SapB1ImportResponse' }
    & Pick<SapB1ImportResponse, 'code'>
  )> }
);

export type ImportSapB1CostObjectsMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportSapB1CostObjectsMutation = (
  { __typename?: 'Mutation' }
  & { importSapB1CostObjects?: Maybe<(
    { __typename?: 'SapB1ImportResponse' }
    & Pick<SapB1ImportResponse, 'code'>
  )> }
);

export type ImportSapB1ContactsMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportSapB1ContactsMutation = (
  { __typename?: 'Mutation' }
  & { importSapB1Contacts?: Maybe<(
    { __typename?: 'SapB1ImportResponse' }
    & Pick<SapB1ImportResponse, 'code'>
  )> }
);

export type ImportSapB1PurchaseOrdersV2MutationVariables = Exact<{
  importFilters?: InputMaybe<SapB1PurchaseOrdersImportFilters>;
}>;


export type ImportSapB1PurchaseOrdersV2Mutation = (
  { __typename?: 'Mutation' }
  & { importSapB1PurchaseOrdersV2?: Maybe<(
    { __typename?: 'PurchaseOrderImportHistoryEntry' }
    & PurchaseOrderImportHistoryEntryDataFragment
  ) | (
    { __typename?: 'SapB1ImportResponse' }
    & SapB1ImportResponseDataFragment
  )> }
);

export type ImportSapB1GoodsReceiptsMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportSapB1GoodsReceiptsMutation = (
  { __typename?: 'Mutation' }
  & { importSapB1GoodsReceipts?: Maybe<(
    { __typename?: 'SapB1ImportResponse' }
    & SapB1ImportResponseDataFragment
  )> }
);

export type ImportSapB1GoodsReceiptsV2MutationVariables = Exact<{
  importFilters?: InputMaybe<SapB1GoodsReceiptsImportFilters>;
}>;


export type ImportSapB1GoodsReceiptsV2Mutation = (
  { __typename?: 'Mutation' }
  & { importSapB1GoodsReceiptsV2?: Maybe<(
    { __typename?: 'GoodsReceiptImportHistoryEntry' }
    & GoodsReceiptImportHistoryEntryDataFragment
  ) | (
    { __typename?: 'SapB1ImportResponse' }
    & SapB1ImportResponseDataFragment
  )> }
);

export type ImportSapB1ProjectCodesMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportSapB1ProjectCodesMutation = (
  { __typename?: 'Mutation' }
  & { importSapB1ProjectCodes?: Maybe<(
    { __typename?: 'SapB1ImportResponse' }
    & Pick<SapB1ImportResponse, 'code'>
  )> }
);

export type TrackEventMutationVariables = Exact<{
  input: TrackEventInput;
}>;


export type TrackEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trackEvent'>
);

export type CurrentUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'phoneNumber' | 'firstName' | 'lastName' | 'locale' | 'createdTimestamp' | 'analyticsId' | 'intercomHash' | 'roles' | 'cardRoles' | 'avatarUrl'>
    & { membershipRoles: Array<(
      { __typename: 'MembershipRole' }
      & Pick<MembershipRole, 'id' | 'name' | 'isBuiltinRole'>
    )>, preferences?: Maybe<(
      { __typename?: 'UserPreferences' }
      & { tables: Array<(
        { __typename?: 'TablePreference' }
        & Pick<TablePreference, 'id'>
        & { columns: Array<(
          { __typename?: 'TableColumn' }
          & Pick<TableColumn, 'id' | 'isVisible' | 'isFixed' | 'label'>
        )> }
      )>, promos: Array<(
        { __typename?: 'PromoPreference' }
        & Pick<PromoPreference, 'promoId' | 'seenAt'>
      )> }
    )>, trackingConfiguration?: Maybe<(
      { __typename?: 'TrackingConfiguration' }
      & Pick<TrackingConfiguration, 'intercom' | 'trackingEnabled'>
    )>, paymentInfo?: Maybe<(
      { __typename?: 'PaymentInfo' }
      & Pick<PaymentInfo, 'iban' | 'swiftCode'>
    )> }
  )> }
);

export type UpdateCurrentUserMutationVariables = Exact<{
  input: UpdateCurrentUserInput;
}>;


export type UpdateCurrentUserMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'locale'>
  )> }
);

export type SaveTableConfigurationsMutationVariables = Exact<{
  input: SaveTablePreferenceInput;
}>;


export type SaveTableConfigurationsMutation = (
  { __typename?: 'Mutation' }
  & { saveTablePreferences: (
    { __typename?: 'TablePreference' }
    & Pick<TablePreference, 'id'>
    & { columns: Array<(
      { __typename?: 'TableColumn' }
      & Pick<TableColumn, 'id' | 'isVisible' | 'isFixed' | 'label'>
    )> }
  ) }
);

export type ResetTableConfigurationsMutationVariables = Exact<{
  input: DeleteTablePreferenceInput;
}>;


export type ResetTableConfigurationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTablePreferences'>
);

export type SavePromoPreferenceMutationVariables = Exact<{
  input: SavePromoPreferenceInput;
}>;


export type SavePromoPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { savePromoPreference: (
    { __typename?: 'PromoPreference' }
    & Pick<PromoPreference, 'promoId'>
  ) }
);

export type DeletePromoPreferenceMutationVariables = Exact<{
  promoId: Scalars['String']['input'];
}>;


export type DeletePromoPreferenceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePromoPreference'>
);

export type GetCardIssuerCardholdersQueryVariables = Exact<{
  input: CardIssuerPaginationInput;
  filters?: InputMaybe<CardIssuerCardholdersFiltersInput>;
}>;


export type GetCardIssuerCardholdersQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardholders: (
    { __typename?: 'CardholdersConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardholderEdge' }
      & { node: (
        { __typename?: 'CardIssuerCardholder' }
        & Pick<CardIssuerCardholder, 'id' | 'firstName' | 'lastName'>
        & { memberships: Array<Maybe<(
          { __typename?: 'CardIssuerCardholderMembership' }
          & Pick<CardIssuerCardholderMembership, 'membershipId' | 'organizationId'>
        )>> }
      ) }
    )>> }
  ) }
);

export type GetCardIssuerCardsForCardholderQueryVariables = Exact<{
  input: CardIssuerPaginationInput;
  filters?: InputMaybe<CardIssuerCardsForCardholderFiltersInput>;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
}>;


export type GetCardIssuerCardsForCardholderQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardsForCardholder: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & Pick<CardIssuerCardEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerCard' }
        & Pick<CardIssuerCard, 'id' | 'lastTransactionCreatedAt' | 'type' | 'status' | 'label' | 'refNum' | 'issuingDate' | 'expiryDate' | 'purpose' | 'limitRenewFrequency' | 'brand' | 'hasAccountingData'>
        & { cardholder: (
          { __typename?: 'CardholderMembership' }
          & Pick<CardholderMembership, 'id' | 'membershipId' | 'membershipEmail' | 'avatarUrl' | 'firstName' | 'lastName'>
        ), limit?: Maybe<(
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        )>, spendingBalance?: Maybe<(
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        )>, availableBalance?: Maybe<(
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        )>, transactionLimit?: Maybe<(
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        )>, pendingRequest?: Maybe<(
          { __typename: 'CardLimitChangeRequest' }
          & Pick<CardLimitChangeRequest, 'id' | 'status' | 'createdAt'>
          & CardLimitChangeRequestDataFragment
        ) | (
          { __typename: 'PhysicalCardRequest' }
          & Pick<PhysicalCardRequest, 'id' | 'status' | 'createdAt'>
          & PhysicalCardRequestDataFragment
        ) | (
          { __typename: 'SingleUseVirtualCardRequest' }
          & Pick<SingleUseVirtualCardRequest, 'id' | 'status' | 'createdAt'>
          & SingleUseVirtualCardRequestDataFragment
        ) | (
          { __typename: 'VirtualCardRequest' }
          & Pick<VirtualCardRequest, 'id' | 'status' | 'createdAt'>
          & VirtualCardRequestDataFragment
        )> }
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type CardIssuerCardEdgeDataFragment = (
  { __typename?: 'CardIssuerCardEdge' }
  & Pick<CardIssuerCardEdge, 'cursor'>
  & { node: (
    { __typename?: 'CardIssuerCard' }
    & Pick<CardIssuerCard, 'id' | 'type' | 'status' | 'label' | 'refNum' | 'category' | 'issuingDate' | 'lastTransactionCreatedAt' | 'purpose' | 'expiryDate' | 'brand' | 'limitRenewFrequency' | 'hasAccountingData'>
    & { cardholder: (
      { __typename?: 'CardholderMembership' }
      & Pick<CardholderMembership, 'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'membershipEmail' | 'membershipId'>
    ), limit?: Maybe<(
      { __typename?: 'TransactionAmount' }
      & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
    )>, spendingBalance?: Maybe<(
      { __typename?: 'TransactionAmount' }
      & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
    )>, availableBalance?: Maybe<(
      { __typename?: 'TransactionAmount' }
      & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
    )>, transactionLimit?: Maybe<(
      { __typename?: 'TransactionAmount' }
      & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
    )>, pendingRequest?: Maybe<(
      { __typename: 'CardLimitChangeRequest' }
      & Pick<CardLimitChangeRequest, 'id' | 'status' | 'createdAt'>
      & CardLimitChangeRequestDataFragment
    ) | (
      { __typename: 'PhysicalCardRequest' }
      & Pick<PhysicalCardRequest, 'id' | 'status' | 'createdAt'>
      & PhysicalCardRequestDataFragment
    ) | (
      { __typename: 'SingleUseVirtualCardRequest' }
      & Pick<SingleUseVirtualCardRequest, 'id' | 'status' | 'createdAt'>
      & SingleUseVirtualCardRequestDataFragment
    ) | (
      { __typename: 'VirtualCardRequest' }
      & Pick<VirtualCardRequest, 'id' | 'status' | 'createdAt'>
      & VirtualCardRequestDataFragment
    )> }
  ) }
);


export type CardIssuerCardEdgeDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetCardIssuerCardsForCardManagerQueryVariables = Exact<{
  input: CardIssuerPaginationInput;
  filters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
}>;


export type GetCardIssuerCardsForCardManagerQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardsForCardManager: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & CardIssuerCardEdgeDataFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type GetCardIssuerCardsRefNumbersAndLabelsQueryVariables = Exact<{
  input: CardIssuerPaginationInput;
}>;


export type GetCardIssuerCardsRefNumbersAndLabelsQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardsRefNumbersAndLabels: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & { node: (
        { __typename?: 'CardIssuerCard' }
        & Pick<CardIssuerCard, 'id' | 'refNum' | 'label'>
      ) }
    )>> }
  ) }
);

export type GetCardIssuerCardholderByMembershipIdQueryVariables = Exact<{
  membershipId: Scalars['ID']['input'];
}>;


export type GetCardIssuerCardholderByMembershipIdQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerCardholderByMembershipId?: Maybe<(
    { __typename?: 'CardIssuerCardholder' }
    & Pick<CardIssuerCardholder, 'id'>
  )> }
);

export type AddFileUploadMutationVariables = Exact<{
  file?: InputMaybe<FileInput>;
}>;


export type AddFileUploadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addFileUpload'>
);

export type UpdateFileUploadMutationVariables = Exact<{
  file?: InputMaybe<FileInput>;
}>;


export type UpdateFileUploadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFileUpload'>
);

export type RemoveAllMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveAllMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAll'>
);

export type RemoveFileUploadByIdMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RemoveFileUploadByIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeFileUploadById'>
);

export type SoftDeleteFileUploadByFileIdMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SoftDeleteFileUploadByFileIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'softDeleteFileUploadByFileId'>
);

export type FileUploadsQueryVariables = Exact<{ [key: string]: never; }>;


export type FileUploadsQuery = (
  { __typename?: 'Query' }
  & { fileUploads: Array<(
    { __typename?: 'UploadFileItem' }
    & Pick<UploadFileItem, 'id' | 'createdAt' | 'fileName' | 'state' | 'errorDetails' | 'documentId' | 'fileId' | 'isDeleted'>
  )> }
);

export type FileUploadModalQueryVariables = Exact<{ [key: string]: never; }>;


export type FileUploadModalQuery = (
  { __typename?: 'Query' }
  & { fileUploadModal: (
    { __typename?: 'UploadModal' }
    & Pick<UploadModal, 'uploadModalVisible'>
  ) }
);

export type OrganizationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type OrganizationQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'realmName' | 'name' | 'hasCostCenters' | 'hasCostObjects' | 'hasExtraCostInfos' | 'hasActiveBookingAccounts' | 'hasActiveBookingKeys' | 'hasActiveWorkflowTemplates' | 'hasTransactionsActivated' | 'permissionsForMembership' | 'hasContacts' | 'slug' | 'salesforceAccountId' | 'locale' | 'chargebeeSubscriptionId' | 'integrationName' | 'hasIdpSSO'>
    & { payment: (
      { __typename?: 'OrganizationPayment' }
      & Pick<OrganizationPayment, 'isActive' | 'startDate'>
    ), creditCardsDetails?: Maybe<(
      { __typename?: 'OrganizationCreditCardsDetails' }
      & Pick<OrganizationCreditCardsDetails, 'paymentFrequency'>
    )>, creditCardsSettings?: Maybe<(
      { __typename?: 'OrganizationCreditCardsSettings' }
      & Pick<OrganizationCreditCardsSettings, 'tradeName' | 'legalForm' | 'foundationDate' | 'country' | 'accountType' | 'nationalRegisterCourt' | 'nationalRegisterId' | 'nationalRegisterType' | 'tradeRegisterNumber' | 'vatId' | 'billingEmail' | 'isCompanyNamePrintable' | 'deliveryAddressSameAsBillingAddress'>
      & { billingAddress: (
        { __typename?: 'OrganizationCreditCardsAddress' }
        & Pick<OrganizationCreditCardsAddress, 'country' | 'city' | 'postalCode' | 'streetFull' | 'streetName' | 'streetNumber' | 'streetAdditional'>
      ), deliveryAddress?: Maybe<(
        { __typename?: 'OrganizationCreditCardsAddress' }
        & Pick<OrganizationCreditCardsAddress, 'country' | 'city' | 'postalCode' | 'streetFull' | 'streetName' | 'streetNumber' | 'streetAdditional'>
      )>, processingAccount: (
        { __typename?: 'OrganizationCreditCardsProcessingAccount' }
        & Pick<OrganizationCreditCardsProcessingAccount, 'bankName' | 'iban'>
      ) }
    )>, exportConfiguration?: Maybe<(
      { __typename?: 'ExportConfiguration' }
      & Pick<ExportConfiguration, 'isReweEnabled' | 'fiscalYearStartDay' | 'fiscalYearStartMonth'>
    )>, idpConfig?: Maybe<(
      { __typename?: 'OrganizationIdpConfig' }
      & Pick<OrganizationIdpConfig, 'isMembershipRolesUpdatedViaSSO' | 'isUserDataUpdatedViaSSO'>
    )> }
  )> }
);

export type SetConsentForTrackingConfigurationMutationVariables = Exact<{
  trackingEnabled: Scalars['Boolean']['input'];
  intercom: Scalars['Boolean']['input'];
}>;


export type SetConsentForTrackingConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setConsentForTrackingConfiguration'>
);

export type GetOrganizationsOverallTaskSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsOverallTaskSummaryQuery = (
  { __typename?: 'Query' }
  & { getOrganizationsOverallTaskSummary: (
    { __typename?: 'TasksCountsInsight' }
    & Pick<TasksCountsInsight, 'total' | 'overdue'>
  ) }
);

export type GetOrganizationsInsightsDetailsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type GetOrganizationsInsightsDetailsQuery = (
  { __typename?: 'Query' }
  & { getOrganizationsInsightsDetails: Array<(
    { __typename?: 'OrganizationsInsightsDetails' }
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'slug'>
    ), membership: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), summary: (
      { __typename?: 'TasksCountsInsight' }
      & Pick<TasksCountsInsight, 'total' | 'overdue'>
    ), toProcess?: Maybe<(
      { __typename?: 'TasksCountsInsight' }
      & Pick<TasksCountsInsight, 'total' | 'overdue'>
    )>, toApprove?: Maybe<(
      { __typename?: 'TasksCountsInsight' }
      & Pick<TasksCountsInsight, 'total' | 'overdue'>
    )>, toExport?: Maybe<(
      { __typename?: 'TasksCountsInsight' }
      & Pick<TasksCountsInsight, 'total' | 'overdue'>
    )>, monitoringMyRequests?: Maybe<(
      { __typename?: 'MonitoringMyRequests' }
      & { toApprove?: Maybe<(
        { __typename?: 'TasksCountsInsight' }
        & Pick<TasksCountsInsight, 'overdue' | 'total'>
      )> }
    )>, reimbursementCases?: Maybe<(
      { __typename?: 'ReimbursementCasesInsight' }
      & { toReview?: Maybe<(
        { __typename?: 'TasksCountsInsight' }
        & Pick<TasksCountsInsight, 'total' | 'overdue'>
      )>, toCheck?: Maybe<(
        { __typename?: 'TasksCountsInsight' }
        & Pick<TasksCountsInsight, 'total' | 'overdue'>
      )>, toApprove?: Maybe<(
        { __typename?: 'TasksCountsInsight' }
        & Pick<TasksCountsInsight, 'total' | 'overdue'>
      )>, toExport?: Maybe<(
        { __typename?: 'TasksCountsInsight' }
        & Pick<TasksCountsInsight, 'total' | 'overdue'>
      )> }
    )> }
  )> }
);

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<(
    { __typename?: 'NotificationsResponse' }
    & { records: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id'>
      & { data: (
        { __typename?: 'EmailImportErrorNotification' }
        & Pick<EmailImportErrorNotification, 'fromAddress' | 'emailMessageId'>
      ) | { __typename?: 'UploadFileItem' } }
    )> }
  )> }
);

export type ClearAllNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearAllNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearAllNotifications'>
);

export type GenerateTestTransactionsMutationVariables = Exact<{
  input: CardIssuerGenerateTestTransactionsInput;
}>;


export type GenerateTestTransactionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateTestTransactions'>
);

export type SetCurrentUserAbsenceMutationVariables = Exact<{
  input: SetCurrentUserAbsenceInput;
}>;


export type SetCurrentUserAbsenceMutation = (
  { __typename?: 'Mutation' }
  & { setCurrentUserAbsence: (
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'toDate' | 'status' | 'note' | 'substituteMembershipId'>
  ) | (
    { __typename?: 'SetAbsenceError' }
    & Pick<SetAbsenceError, 'kind' | 'message'>
  ) }
);

export type SetAbsenceForMemberMutationVariables = Exact<{
  input: SetAbsenceForTeamMemberInput;
}>;


export type SetAbsenceForMemberMutation = (
  { __typename?: 'Mutation' }
  & { setAbsenceForMember: (
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'toDate' | 'status' | 'note' | 'membershipId' | 'substituteMembershipId'>
  ) | (
    { __typename?: 'SetAbsenceError' }
    & Pick<SetAbsenceError, 'kind' | 'message'>
  ) }
);

export type GetCurrentUserAbsenceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserAbsenceQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserAbsence?: Maybe<(
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'toDate' | 'status' | 'note' | 'substituteMembershipId'>
  )> }
);

export type GetMemberAbsenceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetMemberAbsenceQuery = (
  { __typename?: 'Query' }
  & { getMemberAbsence?: Maybe<(
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'toDate' | 'status' | 'note' | 'membershipId' | 'substituteMembershipId'>
  )> }
);

export type RequestUploadAvatarUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestUploadAvatarUrlMutation = (
  { __typename?: 'Mutation' }
  & { requestUploadAvatarUrl?: Maybe<(
    { __typename?: 'UploadAvatarResponse' }
    & Pick<UploadAvatarResponse, 'uploadUrl' | 'downloadUrl'>
    & { postOptions?: Maybe<Array<Maybe<(
      { __typename?: 'UploadPostOption' }
      & Pick<UploadPostOption, 'field' | 'value'>
    )>>> }
  )> }
);

export type RemoveAvatarMutationVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type RemoveAvatarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAvatar'>
);

export type UpdateAvatarMutationVariables = Exact<{
  id: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;


export type UpdateAvatarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAvatar'>
);

export type PendingChangeMembershipEmailQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PendingChangeMembershipEmailQuery = (
  { __typename?: 'Query' }
  & { pendingChangeMembershipEmail?: Maybe<(
    { __typename?: 'PendingChangeMembershipEmail' }
    & Pick<PendingChangeMembershipEmail, 'newEmail'>
  )> }
);

export type RequestChangeUserEmailMutationVariables = Exact<{
  input: RequestChangeUserEmailInput;
}>;


export type RequestChangeUserEmailMutation = (
  { __typename?: 'Mutation' }
  & { requestChangeUserEmail: (
    { __typename: 'RequestChangeUserEmailConflict' }
    & Pick<RequestChangeUserEmailConflict, 'email'>
  ) | (
    { __typename: 'RequestChangeUserEmailSucceeded' }
    & Pick<RequestChangeUserEmailSucceeded, 'email'>
  ) }
);

export type CancelRequestChangeUserEmailMutationVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type CancelRequestChangeUserEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelRequestChangeUserEmail'>
);

export type ResendChangeEmailRequestVerificationEmailMutationVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type ResendChangeEmailRequestVerificationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendChangeEmailRequestVerificationEmail'>
);

export type PendingPhoneNumberVerificationQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingPhoneNumberVerificationQuery = (
  { __typename?: 'Query' }
  & { pendingPhoneNumberVerification?: Maybe<(
    { __typename?: 'PendingPhoneNumberVerification' }
    & Pick<PendingPhoneNumberVerification, 'id' | 'phoneNumber'>
  )> }
);

export type RequestPhoneNumberVerificationMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;


export type RequestPhoneNumberVerificationMutation = (
  { __typename?: 'Mutation' }
  & { requestPhoneNumberVerification: (
    { __typename?: 'PendingPhoneNumberVerification' }
    & Pick<PendingPhoneNumberVerification, 'id' | 'phoneNumber'>
  ) }
);

export type CancelPendingPhoneNumberVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelPendingPhoneNumberVerificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelPendingPhoneNumberVerification'>
);

export type ResendPendingPhoneNumberVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendPendingPhoneNumberVerificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendPendingPhoneNumberVerification'>
);

export type VerifyPhoneNumberMutationVariables = Exact<{
  otpCode: Scalars['String']['input'];
}>;


export type VerifyPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { verifyPhoneNumber: (
    { __typename?: 'VerifyPhoneNumberResult' }
    & Pick<VerifyPhoneNumberResult, 'verified' | 'phoneNumber'>
  ) }
);

export type AuthenticatorDeviceQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthenticatorDeviceQuery = (
  { __typename?: 'Query' }
  & { authenticatorDevice?: Maybe<(
    { __typename?: 'AuthenticatorDeviceInfo' }
    & Pick<AuthenticatorDeviceInfo, 'id' | 'name'>
  )> }
);

export type AddAuthenticatorDeviceMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type AddAuthenticatorDeviceMutation = (
  { __typename?: 'Mutation' }
  & { addAuthenticatorDevice: (
    { __typename?: 'PendingAuthenticatorDeviceVerification' }
    & Pick<PendingAuthenticatorDeviceVerification, 'id' | 'name' | 'secret' | 'secretQRDataUrl'>
  ) }
);

export type AddAuthenticatorDeviceProtectedMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type AddAuthenticatorDeviceProtectedMutation = (
  { __typename?: 'Mutation' }
  & { addAuthenticatorDeviceProtected: (
    { __typename: 'PendingAuthenticatorDeviceVerification' }
    & Pick<PendingAuthenticatorDeviceVerification, 'id' | 'name' | 'secret' | 'secretQRDataUrl'>
  ) | (
    { __typename: 'RecentAuthnRequiredError' }
    & Pick<RecentAuthnRequiredError, 'requiredAuthType' | 'validForSeconds'>
  ) }
);

export type VerifyAuthenticatorDeviceMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type VerifyAuthenticatorDeviceMutation = (
  { __typename?: 'Mutation' }
  & { verifyAuthenticatorDevice: (
    { __typename?: 'VerifyAuthenticatorDeviceResult' }
    & Pick<VerifyAuthenticatorDeviceResult, 'verified'>
  ) }
);

export type UpdateUserPaymentInfoMutationVariables = Exact<{
  iban?: InputMaybe<Scalars['String']['input']>;
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserPaymentInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPaymentInfo?: Maybe<(
    { __typename?: 'UserPaymentInfoUpdatedResponse' }
    & Pick<UserPaymentInfoUpdatedResponse, 'success'>
  )> }
);

export type DocumentFileUploadUrlMutationVariables = Exact<{
  options: UploadOptions;
}>;


export type DocumentFileUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { documentFileUploadUrl: (
    { __typename?: 'UploadFilesResponse' }
    & Pick<UploadFilesResponse, 'id' | 'url'>
    & { file?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'url' | 'name'>
    )>, postOptions?: Maybe<Array<Maybe<(
      { __typename?: 'UploadPostOption' }
      & Pick<UploadPostOption, 'field' | 'value'>
    )>>> }
  ) }
);

export type ProcessFileMutationVariables = Exact<{
  input: ProcessFileInput;
}>;


export type ProcessFileMutation = (
  { __typename?: 'Mutation' }
  & { processFile: (
    { __typename?: 'ProcessFileResponse' }
    & Pick<ProcessFileResponse, 'globalDocumentId'>
  ) }
);

export type ProcessDocumentMutationVariables = Exact<{
  fileId: Scalars['String']['input'];
  transactionIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ProcessDocumentMutation = (
  { __typename?: 'Mutation' }
  & { processDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  )> }
);

export type ProcessEcmDocumentMutationVariables = Exact<{
  input: ProcessEcmDocumentInput;
}>;


export type ProcessEcmDocumentMutation = (
  { __typename?: 'Mutation' }
  & { processEcmDocument: (
    { __typename?: 'CreateEcmDocumentResponse' }
    & Pick<CreateEcmDocumentResponse, 'ecmDocumentId'>
  ) }
);

export type GetOrganizationLocalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetOrganizationLocalQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'locale'>
  )> }
);

export type CurrentSlackTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentSlackTokenQuery = (
  { __typename?: 'Query' }
  & { currentSlackToken?: Maybe<(
    { __typename?: 'RedirectSlackResponse' }
    & Pick<RedirectSlackResponse, 'tokenValid' | 'redirectUrl'>
  )> }
);

export type ObtainNewSlackTokenMutationVariables = Exact<{
  token: ObtainSlackTokenInput;
}>;


export type ObtainNewSlackTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'obtainNewSlackToken'>
);

export type DeleteFileUploadAttemptMutationVariables = Exact<{
  input: DeleteFileUploadAttemptInput;
}>;


export type DeleteFileUploadAttemptMutation = (
  { __typename?: 'Mutation' }
  & { deleteFileUploadAttempt: (
    { __typename?: 'DeleteFileUploadAttemptResponse' }
    & Pick<DeleteFileUploadAttemptResponse, 'id'>
  ) }
);

export type GetFileUploadAttemptsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFileUploadAttemptsQuery = (
  { __typename?: 'Query' }
  & { getFileUploadAttempts: Array<(
    { __typename?: 'FileUploadAttempt' }
    & Pick<FileUploadAttempt, 'id' | 'createdAt' | 'documentId' | 'documentType' | 'errorMessages' | 'globalDocumentId' | 'status' | 'updatedAt' | 'wasDeleted'>
    & { file: (
      { __typename?: 'UploadedFileAttempt' }
      & Pick<UploadedFileAttempt, 'id' | 'name' | 'size' | 'contentType'>
    ) }
  )> }
);

export type ListApprovalsDocumentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  sort?: InputMaybe<SortInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListApprovalsDocumentsQuery = (
  { __typename?: 'Query' }
  & { listApprovalsDocuments: (
    { __typename?: 'DocumentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DocumentEdge' }
      & Pick<DocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'Document' }
        & DocumentListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'totalCount' | 'hasNextPage'>
    )> }
  ) }
);

export type ListApprovalsDocumentsBasicQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  sort?: InputMaybe<SortInput>;
}>;


export type ListApprovalsDocumentsBasicQuery = (
  { __typename?: 'Query' }
  & { listApprovalsDocuments: (
    { __typename?: 'DocumentConnection' }
    & BasicDocumentConnectionDataFragment
  ) }
);

export type GetApprovalInvoiceDocumentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ApprovalInvoicesFilterInput>;
  sort?: InputMaybe<SortInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
  withAccountsReceivableNumber?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type GetApprovalInvoiceDocumentsQuery = (
  { __typename?: 'Query' }
  & { approvalInvoiceDocuments: (
    { __typename?: 'ApprovalInvoiceDocumentsResponse' }
    & { edges: Array<(
      { __typename?: 'ApprovalInvoiceEdge' }
      & Pick<ApprovalInvoiceEdge, 'cursor'>
      & { node: (
        { __typename?: 'ApprovalInvoiceDocument' }
        & ApprovalInvoiceDocumentDataFragment
      ), highlights: Array<(
        { __typename?: 'ApprovalHighlight' }
        & Pick<ApprovalHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'totalCount' | 'hasNextPage'>
    ) }
  ) }
);

export type GetApprovalInvoiceDocumentsBasicsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ApprovalInvoicesFilterInput>;
  sort?: InputMaybe<SortInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type GetApprovalInvoiceDocumentsBasicsQuery = (
  { __typename?: 'Query' }
  & { approvalInvoiceDocuments: (
    { __typename?: 'ApprovalInvoiceDocumentsResponse' }
    & BasicApprovalDocumentConnectionDataFragment
  ) }
);

export type ApprovalReimbursementCasesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type ApprovalReimbursementCasesQuery = (
  { __typename?: 'Query' }
  & { approvalReimbursementCases: (
    { __typename?: 'ApprovalReimbursementCasesResponse' }
    & { pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ), edges?: Maybe<Array<(
      { __typename?: 'ApprovalReimbursementCasesEdge' }
      & Pick<ApprovalReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'ApprovalReimbursementCasesListItem' }
        & Pick<ApprovalReimbursementCasesListItem, 'id' | 'createdAt' | 'title' | 'status' | 'requestedAt'>
        & { requester?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>, approvers?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>>, targetMembership?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>, totalAmount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        ) }
      ) }
    )>> }
  ) }
);

export type ApprovalReimbursementCasesCountQueryVariables = Exact<{
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
}>;


export type ApprovalReimbursementCasesCountQuery = (
  { __typename?: 'Query' }
  & { approvalReimbursementCasesCount: (
    { __typename?: 'ReimbursementCasesCountResponse' }
    & Pick<ReimbursementCasesCountResponse, 'totalCount'>
  ) }
);

export type GeneralExpenseItemDataFragment = (
  { __typename?: 'GeneralExpenseItem' }
  & Pick<GeneralExpenseItem, 'type'>
  & { files?: Maybe<Array<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url' | 'id'>
  )>> }
);


export type GeneralExpenseItemDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type HospitalityExpenseItemDataFragment = (
  { __typename?: 'HospitalityExpenseItem' }
  & Pick<HospitalityExpenseItem, 'type'>
  & { files?: Maybe<Array<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url' | 'id'>
  )>> }
);


export type HospitalityExpenseItemDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PerDiemItemDataFragment = (
  { __typename?: 'PerDiemItem' }
  & Pick<PerDiemItem, 'type'>
  & { files?: Maybe<Array<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url' | 'id'>
  )>> }
);


export type PerDiemItemDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetExpenseForPreviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetExpenseForPreviewQuery = (
  { __typename?: 'Query' }
  & { reimbursementItem: (
    { __typename: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id'>
    & GeneralExpenseItemDataFragment
  ) | (
    { __typename: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id'>
    & HospitalityExpenseItemDataFragment
  ) | (
    { __typename: 'MileageReimbursementItem' }
    & Pick<MileageReimbursementItem, 'id'>
  ) | (
    { __typename: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id'>
    & PerDiemItemDataFragment
  ) }
);

export type _ListArchiveDocumentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  sort?: InputMaybe<SortInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type _ListArchiveDocumentsQuery = (
  { __typename?: 'Query' }
  & { archivedDocuments: (
    { __typename?: 'DocumentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DocumentEdge' }
      & Pick<DocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'Document' }
        & DocumentListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type ListMatchedArchiveDocumentsQueryVariables = Exact<{
  query: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  filters?: InputMaybe<DocumentFilterInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ListMatchedArchiveDocumentsQuery = (
  { __typename?: 'Query' }
  & { searchDocuments: (
    { __typename?: 'DocumentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DocumentEdge' }
      & Pick<DocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'Document' }
        & DocumentListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'totalCount'>
    )> }
  ) }
);

export type ArchiveInvoiceDocumentsQueryVariables = Exact<{
  query: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ArchiveInvoiceDocumentsFilterInput>;
  sort?: InputMaybe<SortInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
  withAccountsReceivableNumber?: InputMaybe<Scalars['Boolean']['input']>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type ArchiveInvoiceDocumentsQuery = (
  { __typename?: 'Query' }
  & { archiveInvoiceDocuments: (
    { __typename?: 'ArchiveInvoiceDocumentsResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ArchiveInvoiceDocumentEdge' }
      & Pick<ArchiveInvoiceDocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'ArchiveInvoiceDocument' }
        & ArchiveInvoiceDocumentsDataFragment
      ), highlights: Array<(
        { __typename?: 'ArchiveInvoicesHighlight' }
        & Pick<ArchiveInvoicesHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'totalCount'>
    ) }
  ) }
);

export type UnacknowledgedDocumentDataFileRecordsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnacknowledgedDocumentDataFileRecordsQuery = (
  { __typename?: 'Query' }
  & { unacknowledgedDocumentDataFileRecords: Array<(
    { __typename?: 'DocumentDataFileRecord' }
    & Pick<DocumentDataFileRecord, 'id' | 'isAcknowledged'>
  )> }
);

export type MarkDocumentDataFileRecordsAsAcknowledgedMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type MarkDocumentDataFileRecordsAsAcknowledgedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markDocumentDataFileRecordsAsAcknowledged'>
);

export type CreateDataFileForDocumentsMutationVariables = Exact<{
  type?: InputMaybe<DocumentDataFileType>;
  filters?: InputMaybe<DocumentFilterInput>;
  query?: InputMaybe<Scalars['String']['input']>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type CreateDataFileForDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { createDataFileForDocuments?: Maybe<(
    { __typename?: 'DocumentDataFileRecord' }
    & Pick<DocumentDataFileRecord, 'id' | 'recordCount'>
    & { file?: Maybe<(
      { __typename?: 'FileResponse' }
      & Pick<FileResponse, 'id' | 'name' | 'url'>
    )> }
  )> }
);

export type CreateDataFileForEcmDocumentsMutationVariables = Exact<{
  type?: InputMaybe<DocumentDataFileType>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmSortInput>;
  filter?: InputMaybe<EcmFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type CreateDataFileForEcmDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { createDataFileForEcmDocuments?: Maybe<(
    { __typename?: 'DocumentDataFileRecord' }
    & Pick<DocumentDataFileRecord, 'id' | 'recordCount'>
    & { file?: Maybe<(
      { __typename?: 'FileResponse' }
      & Pick<FileResponse, 'id' | 'name' | 'url'>
    )> }
  )> }
);

export type CreateZipFileForDocumentsMutationVariables = Exact<{
  filters?: InputMaybe<DocumentFilterInput>;
  query?: InputMaybe<Scalars['String']['input']>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type CreateZipFileForDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { createPDFZipFileForDocuments?: Maybe<(
    { __typename?: 'DownloadDocumentPDFsError' }
    & Pick<DownloadDocumentPdFsError, 'message' | 'kind'>
  ) | (
    { __typename?: 'DownloadDocumentPDFsResult' }
    & Pick<DownloadDocumentPdFsResult, 'response'>
  )> }
);

export type CreatePdfZipFileForEcmDocumentsMutationVariables = Exact<{
  filters?: InputMaybe<EcmFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmSortInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type CreatePdfZipFileForEcmDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { createPDFZipFileForEcmDocuments?: Maybe<(
    { __typename?: 'DownloadDocumentPDFsError' }
    & Pick<DownloadDocumentPdFsError, 'kind' | 'message'>
  ) | (
    { __typename?: 'DownloadDocumentPDFsResult' }
    & Pick<DownloadDocumentPdFsResult, 'response'>
  )> }
);

export type DownloadPdfFilesForArchiveAllDocumentsMutationVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ArchiveAllDocumentsSortInput>;
  filter?: InputMaybe<ArchiveAllDocumentsFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type DownloadPdfFilesForArchiveAllDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { downloadPdfFilesForArchiveAllDocuments: (
    { __typename?: 'DownloadDocumentPDFsError' }
    & Pick<DownloadDocumentPdFsError, 'kind' | 'message'>
  ) | (
    { __typename?: 'DownloadDocumentPDFsResult' }
    & Pick<DownloadDocumentPdFsResult, 'response'>
  ) }
);

export type GetDocumentForPreviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentForPreviewQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & { documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'name' | 'url'>
    )>, attachments?: Maybe<Array<(
      { __typename?: 'DocumentAttachment' }
      & Pick<DocumentAttachment, 'id' | 'name' | 'url'>
    )>>, contact?: Maybe<(
      { __typename?: 'ContactField' }
      & { value: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      ) }
    )> }
  )> }
);

export type GetEcmDocumentForPreviewQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEcmDocumentForPreviewQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'id' | 'type' | 'contactName'>
    & { fileSource?: Maybe<(
      { __typename?: 'EcmFileSource' }
      & Pick<EcmFileSource, 'name' | 'url'>
    )> }
  ) }
);

export type GetEcmContractsQueryVariables = Exact<{
  next?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmSortInput>;
  filter?: InputMaybe<EcmFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type GetEcmContractsQuery = (
  { __typename?: 'Query' }
  & { getEcmDocuments: (
    { __typename?: 'GetEcmDocumentsResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'EcmDocumentEdge' }
      & Pick<EcmDocumentEdge, 'cursor'>
      & { record: (
        { __typename?: 'EcmDocument' }
        & Pick<EcmDocument, 'contactId' | 'contactName' | 'costCenterId' | 'costCenterName' | 'documentName' | 'documentNumber' | 'documentType' | 'invoiceId' | 'endDate' | 'id' | 'globalDocumentId' | 'isSensitive' | 'notes' | 'startDate' | 'terminationDate' | 'terminationReminderDate'>
        & { amount?: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        )>, notifyPerson?: Maybe<(
          { __typename?: 'EcmDocumentNotifyPerson' }
          & { primary: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'avatarUrl'>
          )> }
        )>, responsiblePerson?: Maybe<(
          { __typename?: 'EcmDocumentResponsiblePerson' }
          & { primary: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'avatarUrl'>
          )> }
        )>, documentSubCategory?: Maybe<(
          { __typename?: 'EcmDocumentSubCategoryField' }
          & Pick<EcmDocumentSubCategoryField, 'id' | 'name'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & TagFragment
        )> }
      ), highlights: Array<(
        { __typename?: 'EcmHighlight' }
        & Pick<EcmHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>>, pageInfo: (
      { __typename?: 'EcmPageInfo' }
      & Pick<EcmPageInfo, 'hasNextPage' | 'nextPageCursor' | 'totalCount'>
    ) }
  ) }
);

export type GetEcmDocumentsQueryVariables = Exact<{
  next?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmSortInput>;
  filter?: InputMaybe<EcmFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type GetEcmDocumentsQuery = (
  { __typename?: 'Query' }
  & { getEcmDocuments: (
    { __typename?: 'GetEcmDocumentsResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'EcmDocumentEdge' }
      & Pick<EcmDocumentEdge, 'cursor'>
      & { record: (
        { __typename?: 'EcmDocument' }
        & Pick<EcmDocument, 'id' | 'globalDocumentId' | 'contactId' | 'contactName' | 'documentDate' | 'documentName' | 'documentNumber' | 'documentStatus' | 'documentType' | 'invoiceId' | 'isSensitive' | 'isEInvoice' | 'notes'>
        & { responsiblePerson?: Maybe<(
          { __typename?: 'EcmDocumentResponsiblePerson' }
          & { primary: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'avatarUrl'>
          )> }
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & TagFragment
        )> }
      ), highlights: Array<(
        { __typename?: 'EcmHighlight' }
        & Pick<EcmHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>>, pageInfo: (
      { __typename?: 'EcmPageInfo' }
      & Pick<EcmPageInfo, 'hasNextPage' | 'nextPageCursor' | 'totalCount'>
    ) }
  ) }
);

export type ArchiveAllDocumentsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ArchiveAllDocumentsSortInput>;
  filter?: InputMaybe<ArchiveAllDocumentsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  previous?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type ArchiveAllDocumentsQuery = (
  { __typename?: 'Query' }
  & { archiveAllDocuments: (
    { __typename?: 'ArchiveAllDocumentsResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ArchiveAllDocumentsEdge' }
      & Pick<ArchiveAllDocumentsEdge, 'cursor'>
      & { node: (
        { __typename?: 'ArchiveAllDocumentsListItem' }
        & Pick<ArchiveAllDocumentsListItem, 'id' | 'globalDocumentId' | 'contactId' | 'contactName' | 'documentDate' | 'documentName' | 'documentNumber' | 'documentStatus' | 'documentType' | 'invoiceId' | 'isSensitive' | 'isEInvoice' | 'notes'>
        & { reimbursementItem?: Maybe<(
          { __typename?: 'ArchiveAllDocumentsListItemReimbursementItem' }
          & Pick<ArchiveAllDocumentsListItemReimbursementItem, 'caseId'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & TagFragment
        )> }
      ), highlights: Array<(
        { __typename?: 'ArchiveAllHighlight' }
        & Pick<ArchiveAllHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>>, pageInfo: (
      { __typename?: 'ArchiveAllDocumentsPageInfo' }
      & Pick<ArchiveAllDocumentsPageInfo, 'hasNextPage' | 'totalCount'>
      & { endCursor: ArchiveAllDocumentsPageInfo['nextPageCursor'] }
    ) }
  ) }
);

export type GetGoodsReceiptsQueryVariables = Exact<{
  input: GoodsReceiptPaginationInput;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFields?: InputMaybe<Array<GoodsReceiptQueryField> | GoodsReceiptQueryField>;
  filters?: InputMaybe<GoodsReceiptFilterInput>;
  sortBy?: InputMaybe<GoodsReceiptSortInput>;
}>;


export type GetGoodsReceiptsQuery = (
  { __typename?: 'Query' }
  & { inboxGoodsReceipts?: Maybe<(
    { __typename?: 'GoodsReceiptConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'GoodsReceiptEdge' }
      & Pick<GoodsReceiptEdge, 'cursor'>
      & { node: (
        { __typename?: 'GoodsReceipt' }
        & Pick<GoodsReceipt, '_id' | 'accountsPayableNumber' | 'contactName' | 'deliveryDate' | 'status' | 'receiptNumber' | 'organizationId'>
        & { connectedPurchaseOrders?: Maybe<Array<Maybe<(
          { __typename?: 'ConnectedPurchaseOrdersData' }
          & Pick<ConnectedPurchaseOrdersData, 'purchaseOrderId' | 'purchaseOrderNumber'>
        )>>>, goodsLineItems?: Maybe<Array<(
          { __typename?: 'GoodsReceiptGoodsLineItem' }
          & Pick<GoodsReceiptGoodsLineItem, 'quantity' | 'itemNumber' | 'description'>
        )>>, connectedInvoices?: Maybe<Array<(
          { __typename?: 'ConnectedInvoicesData' }
          & Pick<ConnectedInvoicesData, 'invoiceId' | 'invoiceNumber'>
        )>>, amount: (
          { __typename: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        ), sapB1Data?: Maybe<(
          { __typename?: 'SapB1Data' }
          & Pick<SapB1Data, 'supplierRefNumber'>
        )> }
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  )> }
);

export type GoodsReceiptsImportHistoryPaginationQueryVariables = Exact<{
  input?: InputMaybe<ListGoodsReceiptImportHistoryEntriesInput>;
}>;


export type GoodsReceiptsImportHistoryPaginationQuery = (
  { __typename?: 'Query' }
  & { listGoodsReceiptImportHistoryEntries: (
    { __typename?: 'ListGoodsReceiptImportHistoryEntriesResult' }
    & { records: Array<Maybe<(
      { __typename?: 'GoodsReceiptImportHistoryEntry' }
      & GoodsReceiptsImportHistoryDataFragment
    )>>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type PurchaseOrdersImportHistoryDataFragment = (
  { __typename?: 'PurchaseOrderImportHistoryEntry' }
  & Pick<PurchaseOrderImportHistoryEntry, 'id' | 'createdAt' | 'updatedAt' | 'finishedAt' | 'status'>
  & { createdBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ), importResult?: Maybe<(
    { __typename?: 'PurchaseOrderImportHistoryEntryImportResult' }
    & Pick<PurchaseOrderImportHistoryEntryImportResult, 'successfullyCreatedDocumentsCount' | 'successfullyUpdatedDocumentsCount' | 'failedToImportDocumentsCount'>
  )> }
);


export type PurchaseOrdersImportHistoryDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ArchivePurchaseOrdersQueryVariables = Exact<{
  input: PurchaseOrderPaginationInput;
  query?: InputMaybe<Scalars['String']['input']>;
  queryFields?: InputMaybe<Array<PurchaserOrderQueryField> | PurchaserOrderQueryField>;
  filters?: InputMaybe<PurchaseOrderFilterInput>;
  sortBy?: InputMaybe<PurchaseOrderSortInput>;
}>;


export type ArchivePurchaseOrdersQuery = (
  { __typename?: 'Query' }
  & { inboxPurchaseOrders?: Maybe<(
    { __typename?: 'PurchaseOrderConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'PurchaseOrderEdge' }
      & Pick<PurchaseOrderEdge, 'cursor'>
      & { node: (
        { __typename?: 'PurchaseOrder' }
        & PurchaseOrderListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  )> }
);

export type PurchaseOrderImportHistoryPaginationQueryVariables = Exact<{
  input?: InputMaybe<ListPurchaseOrderImportHistoryEntriesInput>;
}>;


export type PurchaseOrderImportHistoryPaginationQuery = (
  { __typename?: 'Query' }
  & { listPurchaseOrderImportHistoryEntries: (
    { __typename?: 'ListPurchaseOrderImportHistoryEntriesResult' }
    & { records: Array<Maybe<(
      { __typename?: 'PurchaseOrderImportHistoryEntry' }
      & PurchaseOrdersImportHistoryDataFragment
    )>>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type PurchaseOrderImportHistoryByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PurchaseOrderImportHistoryByIdQuery = (
  { __typename?: 'Query' }
  & { getPurchaseOrderImportHistoryEntryById: (
    { __typename?: 'PurchaseOrderImportHistoryEntry' }
    & PurchaseOrdersImportHistoryDataFragment
  ) }
);

export type CreateDataFileForReimbursementCasesMutationVariables = Exact<{
  type?: InputMaybe<DocumentDataFileType>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ReimbursementCasesSortInput>;
  filter?: InputMaybe<ReimbursementCasesFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type CreateDataFileForReimbursementCasesMutation = (
  { __typename?: 'Mutation' }
  & { createDataFileForReimbursementCases?: Maybe<(
    { __typename?: 'DocumentDataFileRecord' }
    & Pick<DocumentDataFileRecord, 'id' | 'recordCount'>
    & { file?: Maybe<(
      { __typename?: 'FileResponse' }
      & Pick<FileResponse, 'id' | 'name' | 'url'>
    )> }
  )> }
);

export type ArchiveReimbursementCasesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type ArchiveReimbursementCasesQuery = (
  { __typename?: 'Query' }
  & { archiveReimbursementCases: (
    { __typename?: 'ArchiveReimbursementCasesResponse' }
    & { pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ), edges?: Maybe<Array<(
      { __typename?: 'ArchiveReimbursementCasesEdge' }
      & Pick<ArchiveReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'ArchiveReimbursementCasesListItem' }
        & Pick<ArchiveReimbursementCasesListItem, 'id' | 'createdAt' | 'isPaid' | 'paidAt' | 'firstSubmittedForReviewAt' | 'lastClosedAt' | 'title' | 'status'>
        & { lastApprovedByMembership?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>, targetMembership?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>, totalAmount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        ) }
      ) }
    )>> }
  ) }
);

export type CreateCsvFileForTransactionsMutationVariables = Exact<{
  filters?: InputMaybe<CardIssuerTransactionFilterInput>;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
}>;


export type CreateCsvFileForTransactionsMutation = (
  { __typename?: 'Mutation' }
  & { createCsvFileForTransactions: (
    { __typename?: 'CsvFileForTransactions' }
    & { file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'url'>
    ) }
  ) }
);

export type ArchiveCardIssuerTransactionsQueryVariables = Exact<{
  input: CardIssuerTransactionPaginationInput;
  filters?: InputMaybe<CardIssuerTransactionFilterInput>;
  sortBy?: InputMaybe<CardIssuerTransactionSortInput>;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
}>;


export type ArchiveCardIssuerTransactionsQuery = (
  { __typename?: 'Query' }
  & { archiveCardIssuerTransactions: (
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type GetForeignCurrencyFeeSavingsQueryVariables = Exact<{
  filters?: InputMaybe<DocumentFilterInput>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
}>;


export type GetForeignCurrencyFeeSavingsQuery = (
  { __typename?: 'Query' }
  & { getForeignCurrencyFeeSavings: (
    { __typename?: 'ForeignCurrencyFeeSavings' }
    & { aggregate: (
      { __typename?: 'ForeignCurrencyFeeSavingsInsight' }
      & Pick<ForeignCurrencyFeeSavingsInsight, 'currency' | 'totalAmount' | 'estimatedFeesSaving'>
    ) }
  ) }
);

export type New_RequestDatevAuthTokensMutationVariables = Exact<{
  authUrl: Scalars['String']['input'];
}>;


export type New_RequestDatevAuthTokensMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'new_requestDatevAuthTokens'>
);

export type ContactHistoryQueryVariables = Exact<{
  entityId: Scalars['String']['input'];
  entityType: EntityType;
}>;


export type ContactHistoryQuery = (
  { __typename?: 'Query' }
  & { entityAuditLogs: Array<(
    { __typename?: 'EntityChangeLog' }
    & Pick<EntityChangeLog, 'uniqueId' | 'actionType' | 'operationName' | 'actionPerformedAt' | 'organizationId' | 'wasImpersonated'>
    & { actionPerformedBy?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
    )>, changedValues?: Maybe<Array<(
      { __typename?: 'ChangedValue' }
      & Pick<ChangedValue, 'propertyName' | 'propertyValue'>
    )>> }
  )> }
);

export type ContactsImportHistoryByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ContactsImportHistoryByIdQuery = (
  { __typename?: 'Query' }
  & { contactsImportHistoryById?: Maybe<(
    { __typename?: 'ContactImportHistory' }
    & Pick<ContactImportHistory, 'id' | 'organizationId' | 'importedContacts' | 'status'>
    & { importErrors?: Maybe<Array<(
      { __typename?: 'ImportContactError' }
      & Pick<ImportContactError, 'contactType'>
    )>> }
  )> }
);

export type BulkCreateOrUpdateContactsInBackgroundMutationVariables = Exact<{
  input: Array<ImportAndUpdateContactInput> | ImportAndUpdateContactInput;
  fileId: Scalars['String']['input'];
}>;


export type BulkCreateOrUpdateContactsInBackgroundMutation = (
  { __typename?: 'Mutation' }
  & { bulkCreateOrUpdateContactsInBackground?: Maybe<(
    { __typename?: 'ContactsBulkImportRecord' }
    & Pick<ContactsBulkImportRecord, 'importId'>
  ) | { __typename?: 'UnauthorizedError' }> }
);

export type ContactsImportFileUploadUrlMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
}>;


export type ContactsImportFileUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { contactsImportFileUploadURL: (
    { __typename?: 'UploadFilesResponse' }
    & Pick<UploadFilesResponse, 'id' | 'url'>
    & { postOptions?: Maybe<Array<Maybe<(
      { __typename?: 'UploadPostOption' }
      & Pick<UploadPostOption, 'field' | 'value'>
    )>>> }
  ) }
);

export type PaymentConditionNumbersQueryVariables = Exact<{
  filter: PaymentConditionsFilter;
}>;


export type PaymentConditionNumbersQuery = (
  { __typename?: 'Query' }
  & { paymentConditions: Array<(
    { __typename?: 'PaymentCondition' }
    & Pick<PaymentCondition, 'paymentConditionNumber' | 'isArchived'>
  )> }
);

export type IbanInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  filters?: InputMaybe<ContactsFilterInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type IbanInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { ibanPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ), iban?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )> }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type VatIdInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  filters?: InputMaybe<ContactsFilterInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type VatIdInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { vatIdPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ), vatId?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )> }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type TaxNumberInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  filters?: InputMaybe<ContactsFilterInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type TaxNumberInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { taxNumberPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ), taxNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )> }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type AccountsPayableNumberInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  filters?: InputMaybe<ContactsFilterInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type AccountsPayableNumberInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { accountsPayableNumberPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'accountsPayableNumber'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type AccountsReceivableNumberInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  filters?: InputMaybe<ContactsFilterInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type AccountsReceivableNumberInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { accountsReceivableNumberPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'accountsReceivableNumber'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type ContactFilterOptionsInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  query: ContactFilterOptionsQueryInput;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type ContactFilterOptionsInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { contactFilterOptionsPagination: (
    { __typename?: 'ContactFilterOptionsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'bankAccountNumber'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ), iban?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )> }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount' | 'exceedsPageLimit'>
    ) }
  ) }
);

export type ContactDataFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'organizationId' | 'email' | 'phoneNumber' | 'accountsPayableNumber' | 'accountsReceivableNumber' | 'createdAt' | 'updatedAt' | 'relationshipType' | 'swiftCode' | 'bankAccountNumber' | 'street' | 'postOfficeBox' | 'postalCode' | 'city' | 'countryISOCode' | 'customerNumber' | 'isArchived'>
  & { name: (
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  ), iban?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, taxNumber?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, vatId?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, dueDateOffset?: Maybe<(
    { __typename?: 'IntField' }
    & Pick<IntField, 'value'>
  )>, paymentCondition?: Maybe<(
    { __typename?: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'dueDateOffset' | 'discountOffset' | 'discountPercentage' | 'paymentConditionName'>
  )>, createTransfer?: Maybe<(
    { __typename?: 'BooleanField' }
    & Pick<BooleanField, 'value'>
  )>, additionalContactInfo?: Maybe<(
    { __typename?: 'AdditionalContactInfo' }
    & Pick<AdditionalContactInfo, 'contactType' | 'shortName' | 'companyName' | 'individualFirstName' | 'individualLastName' | 'notSpecifiedName' | 'paymentMedium'>
  )> }
);


export type ContactDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type MergeContactsMutationVariables = Exact<{
  input: MergeContactsInput;
}>;


export type MergeContactsMutation = (
  { __typename?: 'Mutation' }
  & { mergeContacts?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: { __typename: 'AccountsNumberAlreadyAssigned' | 'AccountsNumbersIdentical' } | (
    { __typename: 'AccountsPayableNumberAlreadyAssigned' }
    & Pick<AccountsPayableNumberAlreadyAssigned, 'id' | 'name' | 'accountsPayableNumber'>
  ) | (
    { __typename: 'ContactAlreadyExists' }
    & Pick<ContactAlreadyExists, 'name'>
  ) | (
    { __typename: 'ContactCreated' }
    & Pick<ContactCreated, 'id'>
  ) }
);

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type ContactQueryVariables = Exact<{
  input: GetContactInput;
}>;


export type ContactQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type ContactsPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  filters?: InputMaybe<ContactsFilterInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type ContactsPaginationQuery = (
  { __typename?: 'Query' }
  & { contactsPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & ContactDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'totalCount'>
    ) }
  ) }
);

export type ContactsInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ContactsPaginationInput>;
  filters?: InputMaybe<ContactsFilterInput>;
  queries?: InputMaybe<ContactsQueryInput>;
  sortBy?: InputMaybe<ContactsSortInput>;
}>;


export type ContactsInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { contactsPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & ContactDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type SetAccountsPayableNumberForContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  accountsPayableNumber: Scalars['AccountsPayableNumber']['input'];
}>;


export type SetAccountsPayableNumberForContactMutation = (
  { __typename?: 'Mutation' }
  & { setAccountsPayableNumberForContact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type UnsetAccountsPayableNumberForContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnsetAccountsPayableNumberForContactMutation = (
  { __typename?: 'Mutation' }
  & { unsetAccountsPayableNumberForContact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type ContactsImportHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactsImportHistoryQuery = (
  { __typename?: 'Query' }
  & { contactsImportHistory: Array<(
    { __typename?: 'ContactImportHistory' }
    & Pick<ContactImportHistory, 'id' | 'organizationId' | 'createdAt' | 'countFailedToCreate' | 'countSuccessfullyCreated'>
    & { file: (
      { __typename?: 'ContactImportHistoryFile' }
      & Pick<ContactImportHistoryFile, 'id' | 'name' | 'url'>
    ), errorFile?: Maybe<(
      { __typename?: 'ContactImportErrorFile' }
      & Pick<ContactImportErrorFile, 'id' | 'name' | 'url'>
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    ) }
  )> }
);

export type ContactsImportHistoryErrorQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactsImportHistoryErrorQuery = (
  { __typename?: 'Query' }
  & { contactsImportHistoryError?: Maybe<(
    { __typename?: 'ContactImportHistoryErrorResult' }
    & Pick<ContactImportHistoryErrorResult, 'id' | 'importedAt'>
    & { errorFile: (
      { __typename?: 'ContactImportHistoryFile' }
      & Pick<ContactImportHistoryFile, 'id' | 'name' | 'url'>
    ), importedBy: (
      { __typename?: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    ) }
  )> }
);

export type ContactsImportHistoriesWithUnacknowledgedErrorsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactsImportHistoriesWithUnacknowledgedErrorsQuery = (
  { __typename?: 'Query' }
  & { contactsImportHistoriesWithUnacknowledgedErrors: Array<(
    { __typename?: 'ContactImportHistory' }
    & Pick<ContactImportHistory, 'id' | 'organizationId' | 'hasErrorsToAcknowledge'>
  )> }
);

export type AcknowledgeContactsImportHistoryErrorsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type AcknowledgeContactsImportHistoryErrorsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acknowledgeContactsImportHistoryErrors'>
);

export type ExportContactsMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportContactsMutation = (
  { __typename?: 'Mutation' }
  & { exportContacts: (
    { __typename?: 'ExportContactsResult' }
    & Pick<ExportContactsResult, 'url'>
  ) }
);

export type ExportContactsGenericMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportContactsGenericMutation = (
  { __typename?: 'Mutation' }
  & { exportContactsGeneric: (
    { __typename?: 'ExportContactsResult' }
    & Pick<ExportContactsResult, 'url'>
  ) }
);

export type GetNextFreeAccountsPayableNumberQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetNextFreeAccountsPayableNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNextFreeAccountsPayableNumber'>
);

export type ArchiveContactsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type ArchiveContactsMutation = (
  { __typename?: 'Mutation' }
  & { archiveContacts?: Maybe<(
    { __typename?: 'ContactArchived' }
    & Pick<ContactArchived, 'archivedContactIds'>
  ) | (
    { __typename?: 'ContactArchivedError' }
    & Pick<ContactArchivedError, 'message' | 'code'>
  )> }
);

export type ActivateContactsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type ActivateContactsMutation = (
  { __typename?: 'Mutation' }
  & { activateContacts?: Maybe<(
    { __typename?: 'ContactActivated' }
    & Pick<ContactActivated, 'activatedContactIds'>
  ) | (
    { __typename?: 'ContactActivatedError' }
    & Pick<ContactActivatedError, 'message' | 'code'>
  )> }
);

export type CandisApiCoreDataImportHistoryQueryVariables = Exact<{
  type: CoreDataTypes;
}>;


export type CandisApiCoreDataImportHistoryQuery = (
  { __typename?: 'Query' }
  & { candisApiCoreDataImportHistory: Array<(
    { __typename?: 'CandisApiCoreDataImportHistoryEntry' }
    & Pick<CandisApiCoreDataImportHistoryEntry, 'processId' | 'organizationId' | 'status' | 'operation' | 'type' | 'createdAt' | 'updatedAt' | 'successfulCandisAPICoreDataImportCount' | 'failedCandisAPICoreDataImportCount'>
    & { createdBy?: Maybe<(
      { __typename?: 'MemberInfo' }
      & Pick<MemberInfo, 'firstName' | 'lastName' | 'avatarUrl' | 'membershipId'>
    )> }
  )> }
);

export type GenerateCoreDataImportErrorFileByIdMutationVariables = Exact<{
  processId: Scalars['ID']['input'];
  type: CoreDataTypes;
}>;


export type GenerateCoreDataImportErrorFileByIdMutation = (
  { __typename?: 'Mutation' }
  & { generateCoreDataImportErrorFileById?: Maybe<(
    { __typename?: 'FileResponse' }
    & Pick<FileResponse, 'id' | 'url' | 'name' | 'size'>
  )> }
);

export type GetManagerCreditCardInsightsQueryVariables = Exact<{
  cardInput: CardIssuerPaginationInput;
  cardRequestFilters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  cardLimitFilters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  cardQueries?: InputMaybe<CardIssuerCardsQueryInput>;
  cardSortBy?: InputMaybe<CardIssuerCardsSortInput>;
  transactionInput: CardIssuerTransactionPaginationInput;
  transactionFilters?: InputMaybe<CardIssuerTransactionFilterInput>;
  transactionSortBy?: InputMaybe<CardIssuerTransactionSortInput>;
  transactionQueries?: InputMaybe<CardIssuerTransactionsQueryInput>;
}>;


export type GetManagerCreditCardInsightsQuery = (
  { __typename?: 'Query' }
  & { cardRequests: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & CardIssuerCardEdgeDataFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ), limitRequests: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & CardIssuerCardEdgeDataFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ), declinedTransactions: (
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type GetCardholderCreditCardInsightsQueryVariables = Exact<{
  cardInput: CardIssuerPaginationInput;
  cardRequestFilters?: InputMaybe<CardIssuerCardsForCardholderFiltersInput>;
  cardLimitFilters?: InputMaybe<CardIssuerCardsForCardholderFiltersInput>;
  cardQueries?: InputMaybe<CardIssuerCardsQueryInput>;
  cardSortBy?: InputMaybe<CardIssuerCardsSortInput>;
  transactionInput: CardIssuerTransactionPaginationInput;
  transactionFilters?: InputMaybe<CardIssuerTransactionFilterInput>;
  transactionSortBy?: InputMaybe<CardIssuerTransactionSortInput>;
  transactionQueries?: InputMaybe<CardIssuerTransactionsQueryInput>;
}>;


export type GetCardholderCreditCardInsightsQuery = (
  { __typename?: 'Query' }
  & { cardRequests: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & CardIssuerCardEdgeDataFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ), limitRequests: (
    { __typename?: 'CardsConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerCardEdge' }
      & CardIssuerCardEdgeDataFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ), declinedTransactions: (
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type CreditCardLimitOrgQueryVariables = Exact<{ [key: string]: never; }>;


export type CreditCardLimitOrgQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & { creditCardsDetails?: Maybe<(
      { __typename?: 'OrganizationCreditCardsDetails' }
      & { availableLimit: (
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
      ), totalLimit: (
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
      ) }
    )> }
  )> }
);

export type GetPlannedPaymentDateQueryVariables = Exact<{
  dateRangeFilters?: InputMaybe<CardIssuerSettlementsDateFilterInput>;
}>;


export type GetPlannedPaymentDateQuery = (
  { __typename?: 'Query' }
  & { cardIssuerSettlementsPageBased: (
    { __typename?: 'CardIssuerSettlementsConnectionPageBased' }
    & { records?: Maybe<Array<(
      { __typename?: 'CardIssuerSettlement' }
      & Pick<CardIssuerSettlement, 'id' | 'plannedPaymentDate'>
    )>> }
  ) }
);

export type CardIssuerCardDataFragment = (
  { __typename?: 'CardIssuerCard' }
  & Pick<CardIssuerCard, 'id' | 'type' | 'status' | 'label' | 'purpose' | 'refNum' | 'brand'>
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )>, cardholder: (
    { __typename?: 'CardholderMembership' }
    & Pick<CardholderMembership, 'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'membershipEmail' | 'membershipId'>
  ), availableBalance?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
  )>, limit?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
  )>, pendingActions?: Maybe<Array<(
    { __typename?: 'CardIssuerCardAction' }
    & Pick<CardIssuerCardAction, 'name'>
  )>> }
);


export type CardIssuerCardDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetPageBasedCardIssuerCardsQueryVariables = Exact<{
  input: CardIssuerPageBasedPaginationInput;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
  filters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
}>;


export type GetPageBasedCardIssuerCardsQuery = (
  { __typename?: 'Query' }
  & { getPageBasedCardIssuerCards: (
    { __typename?: 'CardsConnectionPageBased' }
    & { records?: Maybe<Array<(
      { __typename?: 'CardIssuerCard' }
      & CardIssuerCardDataFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  ) }
);

export type GetCardsInfiniteScrollPaginationQueryVariables = Exact<{
  input: CardIssuerPageBasedPaginationInput;
  queries?: InputMaybe<CardIssuerCardsQueryInput>;
  filters?: InputMaybe<CardIssuerCardsForCardManagerFiltersInput>;
  sortBy?: InputMaybe<CardIssuerCardsSortInput>;
}>;


export type GetCardsInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { getPageBasedCardIssuerCards: (
    { __typename?: 'CardsConnectionPageBased' }
    & { records?: Maybe<Array<(
      { __typename?: 'CardIssuerCard' }
      & CardIssuerCardDataFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  ) }
);

export type GetSpendOverviewInsightsQueryVariables = Exact<{
  input?: InputMaybe<SpendOverviewInsightsInput>;
  filters?: InputMaybe<SpendOverviewInsightsFilterInput>;
  dateRangeFilters?: InputMaybe<SpendOverviewInsightsDateFilterInput>;
}>;


export type GetSpendOverviewInsightsQuery = (
  { __typename?: 'Query' }
  & { getSpendOverviewInsights: (
    { __typename?: 'CardIssuerSpendOverviewInsight' }
    & { details: Array<(
      { __typename?: 'CardIssuerSpendOverviewInsightItem' }
      & Pick<CardIssuerSpendOverviewInsightItem, 'dateLabel'>
      & { spentAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      )> }
    )> }
  ) }
);

export type MerchantNamesInfinteScrollPaginationQueryVariables = Exact<{
  input: CardIssuerPageBasedPaginationInput;
  queries?: InputMaybe<CardIssuerMerchantsQueryInput>;
  sortBy?: InputMaybe<CardIssuerMerchantsSortInput>;
}>;


export type MerchantNamesInfinteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { getPageBasedCardIssuerMerchants: (
    { __typename?: 'MerchantsConnectionPageBased' }
    & { records?: Maybe<Array<(
      { __typename?: 'CardIssuerMerchant' }
      & Pick<CardIssuerMerchant, 'id' | 'name' | 'logoUrl'>
    )>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  ) }
);

export type MerchanNamePageBasedPaginationQueryVariables = Exact<{
  input: CardIssuerPageBasedPaginationInput;
  filters?: InputMaybe<CardIssuerMerchantsFiltersInput>;
  sortBy?: InputMaybe<CardIssuerMerchantsSortInput>;
}>;


export type MerchanNamePageBasedPaginationQuery = (
  { __typename?: 'Query' }
  & { getPageBasedCardIssuerMerchants: (
    { __typename?: 'MerchantsConnectionPageBased' }
    & { records?: Maybe<Array<(
      { __typename?: 'CardIssuerMerchant' }
      & Pick<CardIssuerMerchant, 'name' | 'logoUrl'>
    )>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  ) }
);

export type GetTransactionsInsightsQueryVariables = Exact<{
  input?: InputMaybe<TransactionsInsightsInput>;
  filters?: InputMaybe<TransactionsInsightsFilterInput>;
  dateRangeFilters?: InputMaybe<CardIssuerTransactionDateFilterInput>;
}>;


export type GetTransactionsInsightsQuery = (
  { __typename?: 'Query' }
  & { getTransactionsInsights: (
    { __typename?: 'CardIssuerTransactionsInsights' }
    & { cardholders: Array<(
      { __typename?: 'TransactionsCardholderInsightsItem' }
      & { aggregate: (
        { __typename?: 'TransactionsInsightsMonetaryDetail' }
        & Pick<TransactionsInsightsMonetaryDetail, 'count'>
      ), cardholder: (
        { __typename?: 'CardholderMembership' }
        & Pick<CardholderMembership, 'id' | 'membershipId' | 'membershipEmail' | 'firstName' | 'lastName' | 'avatarUrl'>
      ), cards: Array<(
        { __typename?: 'TransactionsCardInsightsItem' }
        & { card: (
          { __typename?: 'CardIssuerCard' }
          & Pick<CardIssuerCard, 'refNum' | 'label'>
        ), aggregate: (
          { __typename?: 'TransactionsInsightsMonetaryDetail' }
          & Pick<TransactionsInsightsMonetaryDetail, 'count'>
        ) }
      )> }
    )> }
  ) }
);

export type GetCountCardsQueryVariables = Exact<{
  filters?: InputMaybe<CardIssuerCardsFiltersInput>;
}>;


export type GetCountCardsQuery = (
  { __typename?: 'Query' }
  & { countCards?: Maybe<(
    { __typename?: 'CardsCounts' }
    & Pick<CardsCounts, 'total'>
  )> }
);

export type GetCountCardRequestsQueryVariables = Exact<{
  filters?: InputMaybe<CardIssuerCardRequestsFiltersInput>;
}>;


export type GetCountCardRequestsQuery = (
  { __typename?: 'Query' }
  & { countCardRequests?: Maybe<(
    { __typename?: 'CardRequestsCounts' }
    & Pick<CardRequestsCounts, 'total'>
    & { details: (
      { __typename?: 'CardRequestsCountsDetails' }
      & { IssueNewVirtualCardRequest?: Maybe<(
        { __typename?: 'CardRequestsCountsByTypes' }
        & Pick<CardRequestsCountsByTypes, 'count'>
        & { byStatus: (
          { __typename?: 'CardRequestsCountsByStatuses' }
          & Pick<CardRequestsCountsByStatuses, 'PENDING'>
        ) }
      )>, IssueNewSingleUseVirtualCardRequest?: Maybe<(
        { __typename?: 'CardRequestsCountsByTypes' }
        & Pick<CardRequestsCountsByTypes, 'count'>
        & { byStatus: (
          { __typename?: 'CardRequestsCountsByStatuses' }
          & Pick<CardRequestsCountsByStatuses, 'PENDING'>
        ) }
      )>, IssueNewPhysicalCardRequest?: Maybe<(
        { __typename?: 'CardRequestsCountsByTypes' }
        & Pick<CardRequestsCountsByTypes, 'count'>
        & { byStatus: (
          { __typename?: 'CardRequestsCountsByStatuses' }
          & Pick<CardRequestsCountsByStatuses, 'PENDING'>
        ) }
      )>, ChangeCardLimitRequest?: Maybe<(
        { __typename?: 'CardRequestsCountsByTypes' }
        & Pick<CardRequestsCountsByTypes, 'count'>
        & { byStatus: (
          { __typename?: 'CardRequestsCountsByStatuses' }
          & Pick<CardRequestsCountsByStatuses, 'PENDING'>
        ) }
      )> }
    ) }
  )> }
);

export type GetVendorSuggestionsForRecurringPaymentsQueryVariables = Exact<{
  input?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsInput>;
  filters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsFilterInput>;
  dateRangeFilters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsDateFilterInput>;
  sortBy?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsSortInput>;
}>;


export type GetVendorSuggestionsForRecurringPaymentsQuery = (
  { __typename?: 'Query' }
  & { getVendorSuggestionsForRecurringPayments: (
    { __typename?: 'VendorSuggestionsForRecurringPaymentsConnectionPageBased' }
    & Pick<VendorSuggestionsForRecurringPaymentsConnectionPageBased, 'linkedCount'>
    & { records: Array<(
      { __typename?: 'VendorSuggestionForRecurringPayment' }
      & Pick<VendorSuggestionForRecurringPayment, 'hasTransactions' | 'contactIds'>
      & { vendor: (
        { __typename?: 'RecurringPaymentVendor' }
        & Pick<RecurringPaymentVendor, 'id' | 'name' | 'category' | 'logoUrl' | 'isIgnored'>
      ), sum: (
        { __typename?: 'RecurringPaymentInsightsAggregate' }
        & { details: Array<(
          { __typename?: 'RecurringPaymentInsightsMonetaryDetail' }
          & Pick<RecurringPaymentInsightsMonetaryDetail, 'count'>
          & { value: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency' | 'precision'>
          ), exchangeRate?: Maybe<(
            { __typename?: 'RecurringPaymentExchangeRateDetail' }
            & Pick<RecurringPaymentExchangeRateDetail, 'rate'>
            & { valueInBaseCurrency: (
              { __typename?: 'Money' }
              & Pick<Money, 'amount' | 'currency' | 'precision'>
            ) }
          )> }
        )>, aggregate: (
          { __typename?: 'RecurringPaymentInsightsMonetaryDetail' }
          & Pick<RecurringPaymentInsightsMonetaryDetail, 'count'>
          & { value: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency' | 'precision'>
          ), exchangeRate?: Maybe<(
            { __typename?: 'RecurringPaymentExchangeRateDetail' }
            & Pick<RecurringPaymentExchangeRateDetail, 'rate'>
            & { valueInBaseCurrency: (
              { __typename?: 'Money' }
              & Pick<Money, 'amount' | 'currency' | 'precision'>
            ) }
          )> }
        ) }
      ), maxMonthlyInvoiceAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      ) }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount'>
    ) }
  ) }
);

export type GetVendorsForRecurringPaymentsQueryVariables = Exact<{
  input?: InputMaybe<GetVendorsForRecurringPaymentsInput>;
  filters?: InputMaybe<GetVendorsForRecurringPaymentsFilterInput>;
  sortBy?: InputMaybe<GetVendorsForRecurringPaymentsSortInput>;
}>;


export type GetVendorsForRecurringPaymentsQuery = (
  { __typename?: 'Query' }
  & { getVendorsForRecurringPayments: (
    { __typename?: 'VendorsForRecurringPaymentsConnectionPageBased' }
    & { records: Array<(
      { __typename?: 'VendorForRecurringPaymentData' }
      & Pick<VendorForRecurringPaymentData, 'id'>
      & { vendor: (
        { __typename?: 'RecurringPaymentVendor' }
        & Pick<RecurringPaymentVendor, 'id' | 'name' | 'logoUrl'>
      ), linkedCards: Array<(
        { __typename?: 'LinkedCard' }
        & Pick<LinkedCard, 'id' | 'transactionState'>
        & { card: (
          { __typename?: 'CardIssuerCard' }
          & CardIssuerCardDataFragment
        ) }
      )> }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount' | 'currentPage' | 'pageCount' | 'pageSize'>
    ) }
  ) }
);

export type SetRecurringPaymentVendorIgnoredStateMutationVariables = Exact<{
  input: SetRecurringPaymentVendorIgnoredStateInput;
}>;


export type SetRecurringPaymentVendorIgnoredStateMutation = (
  { __typename?: 'Mutation' }
  & { setRecurringPaymentVendorIgnoredState: (
    { __typename?: 'RecurringPaymentVendor' }
    & Pick<RecurringPaymentVendor, 'id' | 'isIgnored'>
  ) }
);

export type LinkCardForRecurringPaymentVendorMutationVariables = Exact<{
  input: LinkCardForRecurringPaymentVendorInput;
}>;


export type LinkCardForRecurringPaymentVendorMutation = (
  { __typename?: 'Mutation' }
  & { linkCardForRecurringPaymentVendor: (
    { __typename?: 'RecurringPaymentVendorWithLikedCards' }
    & Pick<RecurringPaymentVendorWithLikedCards, 'id'>
  ) }
);

export type GetVendorForRecurringPaymentsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetVendorForRecurringPaymentsByIdQuery = (
  { __typename?: 'Query' }
  & { getVendorForRecurringPaymentsById: (
    { __typename?: 'VendorForRecurringPaymentData' }
    & Pick<VendorForRecurringPaymentData, 'id'>
    & { linkedCards: Array<(
      { __typename?: 'LinkedCard' }
      & Pick<LinkedCard, 'id' | 'transactionState'>
      & { card: (
        { __typename?: 'CardIssuerCard' }
        & Pick<CardIssuerCard, 'id' | 'brand' | 'status' | 'type' | 'label' | 'refNum'>
        & { cardholder: (
          { __typename?: 'CardholderMembership' }
          & Pick<CardholderMembership, 'id' | 'membershipId' | 'firstName' | 'lastName'>
        ) }
      ) }
    )>, vendor: (
      { __typename?: 'RecurringPaymentVendor' }
      & Pick<RecurringPaymentVendor, 'id' | 'name' | 'logoUrl' | 'category' | 'source'>
    ) }
  ) }
);

export type GetVendorCardsSuggestionsForRecurringPaymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetVendorCardsSuggestionsForRecurringPaymentsQuery = (
  { __typename?: 'Query' }
  & { getVendorCardsSuggestionsForRecurringPayments: (
    { __typename?: 'VendorCardsSuggestionsForRecurringPayments' }
    & { suggestions: Array<(
      { __typename?: 'VendorCardSuggestionForRecurringPayments' }
      & { card: (
        { __typename?: 'CardIssuerCard' }
        & Pick<CardIssuerCard, 'id' | 'brand' | 'type' | 'label' | 'refNum'>
        & { cardholder: (
          { __typename?: 'CardholderMembership' }
          & Pick<CardholderMembership, 'id' | 'membershipId' | 'firstName' | 'lastName'>
        ) }
      ) }
    )> }
  ) }
);

export type UpdateCardForRecurringPaymentVendorMutationVariables = Exact<{
  input: UpdateCardForRecurringPaymentVendorInput;
}>;


export type UpdateCardForRecurringPaymentVendorMutation = (
  { __typename?: 'Mutation' }
  & { updateCardForRecurringPaymentVendor: (
    { __typename?: 'RecurringPaymentVendorWithLikedCards' }
    & Pick<RecurringPaymentVendorWithLikedCards, 'id'>
  ) }
);

export type GetVendorCardholdersSuggestionsForRecurringPaymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetVendorCardholdersSuggestionsForRecurringPaymentsQuery = (
  { __typename?: 'Query' }
  & { getVendorCardholdersSuggestionsForRecurringPayments: (
    { __typename?: 'VendorCardholdersSuggestionsForRecurringPayments' }
    & { suggestions?: Maybe<Array<(
      { __typename?: 'VendorCardholderSuggestionForRecurringPayments' }
      & { cardholder: (
        { __typename?: 'MemberInfo' }
        & Pick<MemberInfo, 'firstName' | 'lastName' | 'membershipId'>
      ) }
    )>> }
  ) }
);

export type DeleteCardForRecurringPaymentVendorMutationVariables = Exact<{
  input: DeleteCardForRecurringPaymentVendorInput;
}>;


export type DeleteCardForRecurringPaymentVendorMutation = (
  { __typename?: 'Mutation' }
  & { deleteCardForRecurringPaymentVendor: (
    { __typename?: 'RecurringPaymentVendorWithLikedCards' }
    & Pick<RecurringPaymentVendorWithLikedCards, 'id'>
  ) }
);

export type CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables = Exact<{
  readableName?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<BookingAccountPaginationInput>;
}>;


export type CreateCreditCardGeneralLedgerAccountFieldItemsQuery = (
  { __typename?: 'Query' }
  & { creditCardGeneralLedgerAccountsPagination: (
    { __typename?: 'BookingAccountPaginationResult' }
    & { records: Array<(
      { __typename?: 'BookingAccount' }
      & Pick<BookingAccount, 'id' | 'readableName'>
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    ) }
  ) }
);

export type CreateCreditCardContactFieldItemsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<ContactsPaginationInput>;
}>;


export type CreateCreditCardContactFieldItemsQuery = (
  { __typename?: 'Query' }
  & { createCreditCardContactsPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    ) }
  ) }
);

export type CreateCustomRecurringPaymentVendorMutationVariables = Exact<{
  input: CreateCustomRecurringPaymentVendorInput;
}>;


export type CreateCustomRecurringPaymentVendorMutation = (
  { __typename?: 'Mutation' }
  & { createCustomRecurringPaymentVendor: (
    { __typename?: 'RecurringPaymentVendor' }
    & Pick<RecurringPaymentVendor, 'id'>
  ) }
);

export type CreditCardsAccountingInfoFieldItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type CreditCardsAccountingInfoFieldItemsQuery = (
  { __typename?: 'Query' }
  & { taxCodes: Array<(
    { __typename?: 'BookingKey' }
    & Pick<BookingKey, 'id' | 'taxCode' | 'readableName'>
  )>, workflows: Array<(
    { __typename?: 'WorkflowTemplate' }
    & Pick<WorkflowTemplate, 'id' | 'name'>
    & { steps?: Maybe<Array<(
      { __typename?: 'WorkflowTemplateStep' }
      & { resolvers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>>> }
    )>> }
  )>, approvers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, getOrgMembersAbsence: Array<(
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'membershipId' | 'note' | 'status' | 'toDate'>
  )> }
);

export type UpdateCardIssuerOrganizationSettingsMutationVariables = Exact<{
  input: UpdateCardIssuerOrganizationSettingsInput;
}>;


export type UpdateCardIssuerOrganizationSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateCardIssuerOrganizationSettings: (
    { __typename?: 'OrganizationCreditCardsSettings' }
    & Pick<OrganizationCreditCardsSettings, 'tradeName'>
  ) }
);

export type GetCreditCardsStatementsQueryVariables = Exact<{
  input?: InputMaybe<CardIssuerPageBasedPaginationInput>;
  filters?: InputMaybe<CardIssuerStatementsFilterInput>;
  dateRangeFilters?: InputMaybe<CardIssuerStatementsDateFilterInput>;
  sortBy?: InputMaybe<CardIssuerStatementsSortInput>;
}>;


export type GetCreditCardsStatementsQuery = (
  { __typename?: 'Query' }
  & { cardIssuerStatementsPageBased: (
    { __typename?: 'CardIssuerStatementsConnectionPageBased' }
    & { records?: Maybe<Array<(
      { __typename?: 'CardIssuerStatement' }
      & Pick<CardIssuerStatement, 'id'>
      & { cardIssuer: (
        { __typename?: 'CardIssuerStatementCardIssuerData' }
        & Pick<CardIssuerStatementCardIssuerData, 'isClosed' | 'statementPeriodStart' | 'statementPeriodEnd'>
        & { openingBalance: (
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        ), closingBalance: (
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        ), totalTransactionAmount: (
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        ), totalPaymentAmount: (
          { __typename?: 'TransactionAmount' }
          & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
        ) }
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  ) }
);

export type CardIssuerSettlementsPageBasedQueryVariables = Exact<{
  input?: InputMaybe<CardIssuerPageBasedPaginationInput>;
  filters?: InputMaybe<CardIssuerSettlementsFiltersInput>;
  sortBy?: InputMaybe<CardIssuerSettlementsSortInput>;
  dateRangeFilters?: InputMaybe<CardIssuerSettlementsDateFilterInput>;
}>;


export type CardIssuerSettlementsPageBasedQuery = (
  { __typename?: 'Query' }
  & { cardIssuerSettlementsPageBased: (
    { __typename?: 'CardIssuerSettlementsConnectionPageBased' }
    & { records?: Maybe<Array<(
      { __typename?: 'CardIssuerSettlement' }
      & Pick<CardIssuerSettlement, 'id' | 'publicPaymentId' | 'method' | 'type' | 'status' | 'cutOffDate' | 'actualPaymentDate' | 'plannedPaymentDate' | 'paymentDate' | 'paymentDefermentInDays' | 'paymentCreatedAt'>
      & { amount: (
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount' | 'currentPage' | 'pageCount' | 'pageSize'>
    )> }
  ) }
);

export type CardIssuerStatementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CardIssuerStatementQuery = (
  { __typename?: 'Query' }
  & { cardIssuerStatement: (
    { __typename?: 'CardIssuerStatement' }
    & Pick<CardIssuerStatement, 'id' | 'hasPdfFile' | 'hasCsvFile'>
    & { pdfFile?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url' | 'id'>
    )>, cardIssuer: (
      { __typename?: 'CardIssuerStatementCardIssuerData' }
      & Pick<CardIssuerStatementCardIssuerData, 'isClosed' | 'statementPeriodStart' | 'statementPeriodEnd'>
      & { openingBalance: (
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
      ), closingBalance: (
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
      ), totalTransactionAmount: (
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
      ), totalPaymentAmount: (
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
      ) }
    ) }
  ) }
);

export type GenerateCsvFileForStatementMutationVariables = Exact<{
  input: GenerateCsvFileForStatementInput;
}>;


export type GenerateCsvFileForStatementMutation = (
  { __typename?: 'Mutation' }
  & { generateCsvFileForStatement: (
    { __typename?: 'CardIssuerStatementCsvFile' }
    & Pick<CardIssuerStatementCsvFile, 'id'>
  ) }
);

export type CardIssuerStatementCsvFileQueryVariables = Exact<{
  input: GetCsvFileForStatementInput;
}>;


export type CardIssuerStatementCsvFileQuery = (
  { __typename?: 'Query' }
  & { cardIssuerStatementCsvFile: (
    { __typename?: 'CardIssuerStatementCsvFile' }
    & Pick<CardIssuerStatementCsvFile, 'id' | 'hasFile'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url' | 'id' | 'name'>
    )> }
  ) }
);

export type FinancialInsightsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  dateRangeFilters?: InputMaybe<DocumentDateFilterInput>;
  input?: InputMaybe<FinancialInsightsInput>;
}>;


export type FinancialInsightsQuery = (
  { __typename?: 'Query' }
  & { financialInsights: (
    { __typename?: 'FinancialInsights' }
    & { sum?: Maybe<(
      { __typename?: 'InsightsAggregate' }
      & { details: Array<(
        { __typename?: 'InsightsMonetaryDetail' }
        & Pick<InsightsMonetaryDetail, 'currency' | 'amount' | 'count'>
        & { exchangeRate?: Maybe<(
          { __typename?: 'ExchangeRateDetail' }
          & Pick<ExchangeRateDetail, 'baseCurrency' | 'amountInBaseCurrency' | 'rate'>
        )> }
      )>, aggregate: (
        { __typename?: 'InsightsMonetaryDetail' }
        & Pick<InsightsMonetaryDetail, 'currency' | 'amount' | 'count'>
      ) }
    )> }
  ) }
);

export type GetVendorSuggestionsCountQueryVariables = Exact<{
  input?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsInput>;
  filters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsFilterInput>;
  dateRangeFilters?: InputMaybe<GetVendorSuggestionsForRecurringPaymentsDateFilterInput>;
}>;


export type GetVendorSuggestionsCountQuery = (
  { __typename?: 'Query' }
  & { getVendorSuggestionsCountForRecurringPayments: (
    { __typename?: 'VendorSuggestionsCountForRecurringPayments' }
    & Pick<VendorSuggestionsCountForRecurringPayments, 'linkedCount' | 'totalCount'>
  ) }
);

export type MonitoringActionsQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type MonitoringActionsQuery = (
  { __typename?: 'Query' }
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & { workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & WorkflowVisualizationFragment
    )> }
  )> }
);

export type AssociatePurchaseOrdersToDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  purchaseOrderIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AssociatePurchaseOrdersToDocumentMutation = (
  { __typename?: 'Mutation' }
  & { associatePurchaseOrdersToDocument: Array<(
    { __typename?: 'DocumentBookingAssociation' }
    & DocumentBookingAssociationDataFragment
  )> }
);

export type AssociateGoodsReceiptsToDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  goodsReceiptsIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AssociateGoodsReceiptsToDocumentMutation = (
  { __typename?: 'Mutation' }
  & { associateGoodsReceiptsToDocument: Array<(
    { __typename?: 'DocumentBookingAssociation' }
    & DocumentBookingAssociationDataFragment
  )> }
);

export type DisconnectAllAssociatedDocumentsMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
}>;


export type DisconnectAllAssociatedDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { disconnectAllAssociatedDocuments: (
    { __typename?: 'DisconnectAllAssociatedDocumentsResponse' }
    & Pick<DisconnectAllAssociatedDocumentsResponse, 'documentIds'>
  ) }
);

export type DocumentCollaborationDataFragment = (
  { __typename?: 'DocumentCollaboration' }
  & Pick<DocumentCollaboration, 'id'>
  & { viewers: Array<(
    { __typename?: 'Collaborator' }
    & Pick<Collaborator, 'lastActivity' | 'currentField'>
    & { membership?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )> }
  )>, editors: Array<(
    { __typename?: 'Collaborator' }
    & Pick<Collaborator, 'lastActivity' | 'currentField'>
    & { membership?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )> }
  )> }
);


export type DocumentCollaborationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type UpdatePaymentDataMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePaymentDataInput;
}>;


export type UpdatePaymentDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePaymentData'>
);

export type DocumentCollabsSubscriptionVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type DocumentCollabsSubscription = (
  { __typename?: 'Subscription' }
  & { documentCollaborators: (
    { __typename?: 'DocumentCollaboration' }
    & DocumentCollaborationDataFragment
  ) }
);

export type AvailableDocumentTypesQueryVariables = Exact<{
  includeInactiveTypes?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AvailableDocumentTypesQuery = (
  { __typename?: 'Query' }
  & { availableDocumentTypes: Array<(
    { __typename?: 'AvailableDocumentTypes' }
    & Pick<AvailableDocumentTypes, 'documentType'>
    & { documentCategory?: Maybe<(
      { __typename?: 'DocumentCategory' }
      & Pick<DocumentCategory, 'direction' | 'documentType' | 'isDefault' | 'isRds1_0Exportable' | 'isBdsExportable' | 'supportedCurrencies'>
    )> }
  )> }
);

export type DocumentDataFileRecordsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentDataFileRecordsQuery = (
  { __typename?: 'Query' }
  & { documentDataFileRecords?: Maybe<Array<(
    { __typename?: 'DocumentDataFileRecord' }
    & Pick<DocumentDataFileRecord, 'id' | 'recordCount' | 'fileType' | 'isExpired' | 'status' | 'createdAt' | 'isAcknowledged' | 'query'>
    & { file?: Maybe<(
      { __typename?: 'FileResponse' }
      & Pick<FileResponse, 'id' | 'name' | 'url' | 'size'>
    )>, creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    )>, filters?: Maybe<(
      { __typename?: 'DownloadFilters' }
      & Pick<DownloadFilters, 'isOverdue' | 'isDuplicate' | 'isPayable' | 'isPaid' | 'statuses' | 'hasTransactionLinked' | 'artistSocialInsuranceCodes'>
      & { costCenters?: Maybe<Array<(
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'readableName'>
      )>>, costObjects?: Maybe<Array<(
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'readableName'>
      )>>, extraCostInfos?: Maybe<Array<(
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'readableName'>
      )>>, generalLedgerAccounts?: Maybe<Array<(
        { __typename?: 'BookingAccount' }
        & Pick<BookingAccount, 'readableName'>
      )>>, contacts?: Maybe<Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      )>>, requesters?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'username'>
      )>>, approvers?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'username'>
      )>> }
    )>, dateRangeFilters?: Maybe<(
      { __typename?: 'DocumentDateFilters' }
      & { invoiceDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, invoiceDueDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, invoiceDeliveryDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, paidAt?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, createdAt?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, dueDateWithCashDiscount?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )> }
    )>, ecmFilters?: Maybe<(
      { __typename?: 'EcmDocumentFilters' }
      & Pick<EcmDocumentFilters, 'contractStatus' | 'documentStatus' | 'documentType' | 'documentSubCategory'>
      & { contactId?: Maybe<Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      )>>, documentDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, endDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, notifyPerson?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )>>, responsiblePerson?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )>>, startDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, terminationDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )>, terminationReminderDate?: Maybe<(
        { __typename?: 'DateFilter' }
        & Pick<DateFilter, 'dateFrom' | 'dateTo'>
      )> }
    )> }
  )>> }
);

export type KeepDuplicateMutationVariables = Exact<{
  docId: Scalars['String']['input'];
}>;


export type KeepDuplicateMutation = (
  { __typename?: 'Mutation' }
  & { keepDuplicate?: Maybe<(
    { __typename?: 'DocumentWithId' }
    & Pick<DocumentWithId, 'id'>
  )> }
);

export type DeleteOriginalMutationVariables = Exact<{
  docId: Scalars['String']['input'];
}>;


export type DeleteOriginalMutation = (
  { __typename?: 'Mutation' }
  & { deleteOriginal?: Maybe<(
    { __typename?: 'DeleteByIdResp' }
    & Pick<DeleteByIdResp, 'count'>
  )> }
);

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteById?: Maybe<(
    { __typename?: 'DeleteByIdResp' }
    & Pick<DeleteByIdResp, 'count'>
  )> }
);

export type FileUploadedEventDataFragment = (
  { __typename?: 'FileUploadedEvent' }
  & Pick<FileUploadedEvent, 'uploaderEmail'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, file?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url'>
  )>, clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )> }
);


export type FileUploadedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type EcmDocumentFileFragment = (
  { __typename?: 'AggregatedEcmDocument' }
  & { fileSource?: Maybe<(
    { __typename?: 'EcmFileSource' }
    & Pick<EcmFileSource, 'name' | 'size' | 'url' | 'id'>
  )> }
);


export type EcmDocumentFileFragmentVariables = Exact<{ [key: string]: never; }>;

export type EcmDocumentQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EcmDocumentQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'id' | 'documentType' | 'createdAt' | 'updatedAt' | 'contactId' | 'contactName' | 'invoiceId' | 'notes' | 'documentNumber' | 'documentDate' | 'documentStatus' | 'isSensitive'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & TagFragment
    )> }
  ) }
);

export type EcmDocumentFileQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EcmDocumentFileQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & EcmDocumentFileFragment
  ) }
);

export type EcmDocumentHistoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EcmDocumentHistoryQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'id'>
    & { timeline?: Maybe<Array<(
      { __typename: 'AccountingAreaUpdatedEvent' }
      & Pick<AccountingAreaUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApprovedEvent' }
      & Pick<ApprovedEvent, 'id' | 'createdAt'>
      & ApprovedEventDataFragment
    ) | (
      { __typename: 'ApproverExtractedEvent' }
      & Pick<ApproverExtractedEvent, 'id' | 'createdAt'>
      & ApproverExtractedEventDataFragment
    ) | (
      { __typename: 'AttachmentConvertedToDocumentEvent' }
      & Pick<AttachmentConvertedToDocumentEvent, 'id' | 'createdAt'>
      & AttachmentConvertedToDocumentEventDataFragment
    ) | (
      { __typename: 'AttachmentDeletedFromDocumentEvent' }
      & Pick<AttachmentDeletedFromDocumentEvent, 'id' | 'createdAt'>
      & AttachmentDeletedFromDocumentEventDataFragment
    ) | (
      { __typename: 'AttachmentDetachedFromDocumentEvent' }
      & Pick<AttachmentDetachedFromDocumentEvent, 'id' | 'createdAt'>
      & AttachmentDetachedFromDocumentEventDataFragment
    ) | (
      { __typename: 'ContactExtractionEvent' }
      & Pick<ContactExtractionEvent, 'id' | 'createdAt'>
      & ContactExtractionEventDataFragment
    ) | (
      { __typename: 'ContactSupplierSendEmailEvent' }
      & Pick<ContactSupplierSendEmailEvent, 'id' | 'createdAt'>
      & ContactSupplierSendEmailEventDataFragment
    ) | (
      { __typename: 'CostCenterExtractionEvent' }
      & Pick<CostCenterExtractionEvent, 'id' | 'createdAt'>
      & CostCenterExtractionEventDataFragment
    ) | (
      { __typename: 'DocumentAssociationsCreatedEvent' }
      & Pick<DocumentAssociationsCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsRemovedEvent' }
      & Pick<DocumentAssociationsRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentClassifiedEvent' }
      & Pick<DocumentClassifiedEvent, 'id' | 'createdAt'>
      & DocumentClassifiedEventDataFragment
    ) | (
      { __typename: 'DocumentCommentEvent' }
      & Pick<DocumentCommentEvent, 'id' | 'createdAt'>
      & DocumentCommentEventDataFragment
    ) | (
      { __typename: 'DocumentConvertedEvent' }
      & Pick<DocumentConvertedEvent, 'id' | 'createdAt'>
      & DocumentConvertedEventDataFragment
    ) | (
      { __typename: 'DocumentConvertedToAttachmentEvent' }
      & Pick<DocumentConvertedToAttachmentEvent, 'id' | 'createdAt'>
      & DocumentConvertedToAttachmentEventDataFragment
    ) | (
      { __typename: 'DocumentDeletedEvent' }
      & Pick<DocumentDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentFileReplacedEvent' }
      & Pick<DocumentFileReplacedEvent, 'id' | 'createdAt'>
      & DocumentFileReplacedEventDataFragment
    ) | (
      { __typename: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
      & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentImportedByMigrationEvent' }
      & Pick<DocumentImportedByMigrationEvent, 'id' | 'createdAt'>
      & DocumentImportedByMigrationEventDataFragment
    ) | (
      { __typename: 'DocumentPaymentInfoUpdatedEvent' }
      & Pick<DocumentPaymentInfoUpdatedEvent, 'id' | 'createdAt'>
      & DocumentPaymentInfoUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentRelationshipCreatedEvent' }
      & Pick<DocumentRelationshipCreatedEvent, 'id' | 'createdAt'>
      & DocumentRelationshipCreatedEventDataFragment
    ) | (
      { __typename: 'DocumentRelationshipRemovedEvent' }
      & Pick<DocumentRelationshipRemovedEvent, 'id' | 'createdAt'>
      & DocumentRelationshipRemovedEventDataFragment
    ) | (
      { __typename: 'DocumentResetByCandis' }
      & Pick<DocumentResetByCandis, 'id' | 'createdAt'>
      & DocumentResetByCandisEventDataFragment
    ) | (
      { __typename: 'DocumentResetManualEvent' }
      & Pick<DocumentResetManualEvent, 'id' | 'createdAt'>
      & DocumentResetManualEventDataFragment
    ) | (
      { __typename: 'DocumentResetTransactionAmountUpdatedEvent' }
      & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id' | 'createdAt'>
      & DocumentResetTransactionAmountUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentResetTransactionLinkedEvent' }
      & Pick<DocumentResetTransactionLinkedEvent, 'id' | 'createdAt'>
      & DocumentResetTransactionLinkedEventDataFragment
    ) | (
      { __typename: 'DocumentResetTransactionUnLinkedEvent' }
      & Pick<DocumentResetTransactionUnLinkedEvent, 'id' | 'createdAt'>
      & DocumentResetTransactionUnLinkedEventDataFragment
    ) | (
      { __typename: 'DocumentResetUserDeactivationEvent' }
      & Pick<DocumentResetUserDeactivationEvent, 'id' | 'createdAt'>
      & DocumentResetUserDeactivationEventDataFragment
    ) | (
      { __typename: 'DocumentResetUserRoleChangedEvent' }
      & Pick<DocumentResetUserRoleChangedEvent, 'id' | 'createdAt'>
      & DocumentResetUserRoleChangedEventDataFragment
    ) | (
      { __typename: 'DocumentSepaTransferGeneratedEvent' }
      & Pick<DocumentSepaTransferGeneratedEvent, 'id' | 'createdAt'>
      & SepaTransferGeneratedEventDataFragment
    ) | (
      { __typename: 'DocumentStoredAsEcmDocumentEvent' }
      & Pick<DocumentStoredAsEcmDocumentEvent, 'id' | 'createdAt'>
      & DocumentStoredAsEcmDocumentEventDataFragment
    ) | (
      { __typename: 'DocumentTagUpdatedEvent' }
      & Pick<DocumentTagUpdatedEvent, 'id' | 'createdAt'>
      & DocumentTagUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionCommentCreatedEvent' }
      & Pick<DocumentTransactionCommentCreatedEvent, 'id' | 'createdAt'>
      & DocumentTransactionCommentCreatedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionLinkedEvent' }
      & Pick<DocumentTransactionLinkedEvent, 'id' | 'createdAt'>
      & DocumentTransactionLinkedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionReconciledEvent' }
      & Pick<DocumentTransactionReconciledEvent, 'id' | 'createdAt'>
      & DocumentTransactionReconciledEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionStatusUpdatedEvent' }
      & Pick<DocumentTransactionStatusUpdatedEvent, 'id' | 'createdAt'>
      & DocumentTransactionStatusUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionUnlinkedEvent' }
      & Pick<DocumentTransactionUnlinkedEvent, 'id' | 'createdAt'>
      & DocumentTransactionUnlinkedEventDataFragment
    ) | (
      { __typename: 'DocumentUpdatedAsEcmDocumentEvent' }
      & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id' | 'createdAt'>
      & DocumentUpdatedAsEcmDocumentEventDataFragment
    ) | (
      { __typename: 'ExportedEvent' }
      & Pick<ExportedEvent, 'id' | 'createdAt'>
      & ExportedEventDataFragment
    ) | (
      { __typename: 'ExtractionEvent' }
      & Pick<ExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileAttachedToDocumentEvent' }
      & Pick<FileAttachedToDocumentEvent, 'id' | 'createdAt'>
      & FileAttachedToDocumentEventDataFragment
    ) | (
      { __typename: 'FileUploadedByEmailEvent' }
      & Pick<FileUploadedByEmailEvent, 'id' | 'createdAt'>
      & FileUploadedByEmailEventDataFragment
    ) | (
      { __typename: 'FileUploadedEvent' }
      & Pick<FileUploadedEvent, 'id' | 'createdAt'>
      & FileUploadedEventDataFragment
    ) | (
      { __typename: 'InvalidEInvoiceDetectedEvent' }
      & Pick<InvalidEInvoiceDetectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'MetaDataExtractionEvent' }
      & Pick<MetaDataExtractionEvent, 'id' | 'createdAt'>
      & MetaDataExtractionEventDataFragment
    ) | (
      { __typename: 'ProvisionCreatedEvent' }
      & Pick<ProvisionCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionDeletedEvent' }
      & Pick<ProvisionDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionExportedEvent' }
      & Pick<ProvisionExportedEvent, 'id' | 'createdAt'>
      & ProvisionExportedEventDataFragment
    ) | (
      { __typename: 'PurchaseOrderLinkedEvent' }
      & Pick<PurchaseOrderLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderUnlinkedEvent' }
      & Pick<PurchaseOrderUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RejectedEvent' }
      & Pick<RejectedEvent, 'id' | 'createdAt'>
      & RejectedEventDataFragment
    ) | (
      { __typename: 'RequestApprovalEvent' }
      & Pick<RequestApprovalEvent, 'id' | 'createdAt'>
      & RequestApprovalEventDataFragment
    ) | (
      { __typename: 'ReversalCreatedEvent' }
      & Pick<ReversalCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalExportedEvent' }
      & Pick<ReversalExportedEvent, 'id' | 'createdAt'>
      & ReversalExportedEventDataFragment
    ) | (
      { __typename: 'SingleBookingUpdatedEvent' }
      & Pick<SingleBookingUpdatedEvent, 'id' | 'createdAt'>
      & SingleBookingUpdatedEventDataFragment
    ) | (
      { __typename: 'SplitBookingUpdatedEvent' }
      & Pick<SplitBookingUpdatedEvent, 'id' | 'createdAt'>
      & SplitBookingUpdatedEventDataFragment
    ) | (
      { __typename: 'SubstituteAddedEvent' }
      & Pick<SubstituteAddedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteRemovedEvent' }
      & Pick<SubstituteRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserApprovalDocumentWorkflowStep' }
      & Pick<UserApprovalDocumentWorkflowStep, 'id' | 'createdAt'>
      & UserApprovalDocumentWorkflowStepDataFragment
    ) | (
      { __typename: 'UserRejectedDocumentWorkflowStep' }
      & Pick<UserRejectedDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowCreatedForDocumentEvent' }
      & Pick<WorkflowCreatedForDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowStepSkippedEvent' }
      & Pick<WorkflowStepSkippedEvent, 'id' | 'createdAt'>
      & WorkflowStepSkippedEventDataFragment
    ) | (
      { __typename: 'WorkflowTemplateAppliedToDocumentEvent' }
      & Pick<WorkflowTemplateAppliedToDocumentEvent, 'id' | 'createdAt'>
      & WorkflowTemplateAppliedToDocumentEventDataFragment
    )>> }
  ) }
);

export type SendEmailToSupplierMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  emailInput: SendEmailWithoutTemplateArgs;
}>;


export type SendEmailToSupplierMutation = (
  { __typename?: 'Mutation' }
  & { sendEmailToSupplier?: Maybe<(
    { __typename?: 'SendEmailResponse' }
    & Pick<SendEmailResponse, '_id' | 'status'>
  )> }
);

export type GetOrganizationEmailAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationEmailAccountsQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { emailAccounts?: Maybe<(
      { __typename?: 'OrganizationEmailAccountsResponse' }
      & { records?: Maybe<Array<Maybe<(
        { __typename?: 'OrganizationEmailAccount' }
        & Pick<OrganizationEmailAccount, 'id' | 'address' | 'isEnabled' | 'organizationId'>
      )>>> }
    )> }
  )> }
);

export type StoreEcmDocumentMutationVariables = Exact<{
  input: StoreEcmDocumentInput;
}>;


export type StoreEcmDocumentMutation = (
  { __typename?: 'Mutation' }
  & { storeEcmDocument: (
    { __typename?: 'StoreEcmDocumentResponse' }
    & Pick<StoreEcmDocumentResponse, 'ecmDocumentId'>
  ) }
);

export type StoreSensitiveEcmDocumentMutationVariables = Exact<{
  input: StoreSensitiveEcmDocumentInput;
}>;


export type StoreSensitiveEcmDocumentMutation = (
  { __typename?: 'Mutation' }
  & { storeSensitiveEcmDocument: (
    { __typename?: 'StoreEcmDocumentResponse' }
    & Pick<StoreEcmDocumentResponse, 'ecmDocumentId'>
  ) }
);

export type UpdateEcmDocumentMutationVariables = Exact<{
  input: UpdateEcmDocumentInput;
}>;


export type UpdateEcmDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateEcmDocument: (
    { __typename?: 'UpdateEcmDocumentResponse' }
    & Pick<UpdateEcmDocumentResponse, 'ecmDocumentId'>
  ) }
);

export type RemoveEcmDocumentMutationVariables = Exact<{
  input: RemoveEcmDocumentInput;
}>;


export type RemoveEcmDocumentMutation = (
  { __typename?: 'Mutation' }
  & { removeEcmDocument: (
    { __typename?: 'CreateEcmDocumentResponse' }
    & Pick<CreateEcmDocumentResponse, 'ecmDocumentId'>
  ) }
);

export type StorageFormInitialDataQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type StorageFormInitialDataQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'status' | 'globalDocumentId' | 'isReadOnly'>
    & { contact?: Maybe<(
      { __typename?: 'ContactField' }
      & Pick<ContactField, 'source' | 'confidence'>
      & { value: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      ) }
    )>, extractedContact?: Maybe<(
      { __typename?: 'ExtractedContact' }
      & Pick<ExtractedContact, 'id' | 'cachingKey'>
      & { name?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, iban?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, bankAccountNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, swiftCode?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, taxNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, vatId?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, createTransfer?: Maybe<(
        { __typename?: 'BooleanField' }
        & Pick<BooleanField, 'value' | 'confidence' | 'source'>
      )>, email?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, phoneNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, street?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, postOfficeBox?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, postalCode?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, city?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, countryISOCode?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, customerNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )>, documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'name' | 'size' | 'url'>
    )>, invoiceDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value' | 'confidence' | 'source'>
    )>, invoiceNumber?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value' | 'confidence' | 'source'>
    )>, amount?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value' | 'confidence' | 'source'>
    )>, currency?: Maybe<(
      { __typename?: 'DocumentCurrencyField' }
      & Pick<DocumentCurrencyField, 'value' | 'confidence' | 'source'>
    )>, bookings?: Maybe<Array<(
      { __typename?: 'Booking' }
      & { costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'readableName'>
        ) }
      )> }
    )>>, tags: Array<(
      { __typename: 'Tag' }
      & TagFragment
    )>, accessModificationPermissions: (
      { __typename?: 'InvoiceAccessModificationPermissions' }
      & Pick<InvoiceAccessModificationPermissions, 'viewAccess'>
    ) }
  )> }
);

export type EcmStorageFormInitialDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EcmStorageFormInitialDataQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'id' | 'documentType' | 'type' | 'contactId' | 'contactName' | 'notes' | 'documentDate' | 'documentName' | 'documentNumber' | 'documentStatus' | 'isReadOnly' | 'permissionsForMembership' | 'startDate' | 'endDate' | 'terminationDate' | 'terminationReminderDate' | 'isSensitive' | 'globalDocumentId'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, accessModificationPermissions: (
      { __typename?: 'EcmDocumentAccessModificationPermissions' }
      & Pick<EcmDocumentAccessModificationPermissions, 'viewAccess'>
    ), fileSource?: Maybe<(
      { __typename?: 'EcmFileSource' }
      & Pick<EcmFileSource, 'name'>
    )>, costCenter?: Maybe<(
      { __typename?: 'CostCenter' }
      & Pick<CostCenter, 'id' | 'readableName'>
    )>, documentSubCategory?: Maybe<(
      { __typename?: 'EcmDocumentSubCategoryField' }
      & Pick<EcmDocumentSubCategoryField, 'id' | 'name'>
    )>, amount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, responsiblePerson?: Maybe<(
      { __typename?: 'EcmDocumentResponsiblePerson' }
      & { primary: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )> }
    )>, notifyPerson?: Maybe<(
      { __typename?: 'EcmDocumentNotifyPerson' }
      & { primary: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )> }
    )>, extractedContact?: Maybe<(
      { __typename?: 'EcmDocumentExtractedContact' }
      & Pick<EcmDocumentExtractedContact, 'id' | 'name' | 'iban' | 'bankAccountNumber' | 'swiftCode' | 'taxNumber' | 'vatId' | 'automaticPayment' | 'email' | 'phoneNumber' | 'street' | 'postOfficeBox' | 'postalCode' | 'city' | 'country' | 'customerNumber'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & TagFragment
    )> }
  ) }
);

export type EcmDocumentPermissionsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EcmDocumentPermissionsQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'id' | 'permissionsForMembership' | 'isReadOnly'>
    & { accessModificationPermissions: (
      { __typename?: 'EcmDocumentAccessModificationPermissions' }
      & Pick<EcmDocumentAccessModificationPermissions, 'viewAccess'>
    ) }
  ) }
);

export type EcmDocumentStatusQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EcmDocumentStatusQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'documentStatus' | 'isSensitive'>
  ) }
);

export type MembersByRoleQueryVariables = Exact<{
  filters?: InputMaybe<OrganizationMembershipsFiltersInput>;
}>;


export type MembersByRoleQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )> }
);

export type DocumentFastApproveMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: DocumentFastApproveInput;
  options?: InputMaybe<DocumentFastApproveOptionsInput>;
}>;


export type DocumentFastApproveMutation = (
  { __typename?: 'Mutation' }
  & { documentFastApprove?: Maybe<(
    { __typename: 'Document' }
    & DocumentDataFragment
  ) | (
    { __typename: 'DocumentFastApproveErrors' }
    & { errors: Array<(
      { __typename?: 'AccountingAreaDoesNotExistError' }
      & Pick<AccountingAreaDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'AccountingAreaIsArchivedError' }
      & Pick<AccountingAreaIsArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'AccountingAreaRequiredError' }
      & Pick<AccountingAreaRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingKeyArchivedError' }
      & Pick<BookingKeyArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingKeyDoesNotExistError' }
      & Pick<BookingKeyDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingKeyRequiredError' }
      & Pick<BookingKeyRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingModificationNotAllowedError' }
      & Pick<BookingModificationNotAllowedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingsError' }
      & Pick<BookingsError, 'kind' | 'message'>
      & BookingsErrorDataFragment
    ) | (
      { __typename?: 'ContactAPNRequiredError' }
      & Pick<ContactApnRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ContactARNRequiredError' }
      & Pick<ContactArnRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ContactDoesNotExistError' }
      & Pick<ContactDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ContactIsArchivedError' }
      & Pick<ContactIsArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostCenterArchivedError' }
      & Pick<CostCenterArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostCenterDoesNotExistError' }
      & Pick<CostCenterDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostCenterRequiredError' }
      & Pick<CostCenterRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostObjectArchivedError' }
      & Pick<CostObjectArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostObjectDoesNotExistError' }
      & Pick<CostObjectDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostObjectRequiredError' }
      & Pick<CostObjectRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DeliveryDateConflictError' }
      & Pick<DeliveryDateConflictError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentCannotBeModifedError' }
      & Pick<DocumentCannotBeModifedError, 'kind' | 'message'>
      & DocumentCannotBeModifedErrorDataFragment
    ) | (
      { __typename?: 'DocumentDoesNotExistError' }
      & Pick<DocumentDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentGrossNetTaxRoundingAmountsCalculationError' }
      & Pick<DocumentGrossNetTaxRoundingAmountsCalculationError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentGrossNetTaxRoundingAmountsValidationError' }
      & Pick<DocumentGrossNetTaxRoundingAmountsValidationError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentIsAlreadyRequestedForApprovalError' }
      & Pick<DocumentIsAlreadyRequestedForApprovalError, 'kind' | 'message'>
      & DocumentIsAlreadyRequestedForApprovalErrorDataFragment
    ) | (
      { __typename?: 'ExtraCostInfoArchivedError' }
      & Pick<ExtraCostInfoArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ExtraCostInfoDoesNotExistError' }
      & Pick<ExtraCostInfoDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'GeneralLedgerArchivedError' }
      & Pick<GeneralLedgerArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'GeneralLedgerDoesNotExistError' }
      & Pick<GeneralLedgerDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'GeneralLedgerRequiredError' }
      & Pick<GeneralLedgerRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidBookingAmountError' }
      & Pick<InvalidBookingAmountError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidBookingIdError' }
      & Pick<InvalidBookingIdError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidDeliveryDateError' }
      & Pick<InvalidDeliveryDateError, 'kind' | 'message'>
      & InvalidDeliveryDateErrorDataFragment
    ) | (
      { __typename?: 'InvalidDueDateError' }
      & Pick<InvalidDueDateError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidGrossAmountError' }
      & Pick<InvalidGrossAmountError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidInvoiceDateError' }
      & Pick<InvalidInvoiceDateError, 'kind' | 'message'>
      & InvalidInvoiceDateErrorDataFragment
    ) | (
      { __typename?: 'InvalidSwiftCodeError' }
      & Pick<InvalidSwiftCodeError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvoiceHasNoBookingsError' }
      & Pick<InvoiceHasNoBookingsError, 'kind' | 'message'>
    ) | (
      { __typename?: 'NotCurrentWorkflowStepError' }
      & Pick<NotCurrentWorkflowStepError, 'kind' | 'message'>
    ) | (
      { __typename?: 'SumOfBookingsDoesNotMatchGrossAmountError' }
      & Pick<SumOfBookingsDoesNotMatchGrossAmountError, 'kind' | 'message'>
    ) | (
      { __typename?: 'SwiftCodeAndIbanCountryMismatchError' }
      & Pick<SwiftCodeAndIbanCountryMismatchError, 'kind' | 'message'>
    ) | (
      { __typename?: 'SwiftCodeMissingIBANError' }
      & Pick<SwiftCodeMissingIbanError, 'kind' | 'message'>
    ) | (
      { __typename?: 'TransactionDataMismatchError' }
      & Pick<TransactionDataMismatchError, 'kind' | 'message'>
    ) | (
      { __typename?: 'UnexpectedDocumentApprovalError' }
      & Pick<UnexpectedDocumentApprovalError, 'kind' | 'message'>
    ) | (
      { __typename?: 'WorkflowStepAlreadyApprovedError' }
      & Pick<WorkflowStepAlreadyApprovedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'WorkflowTemplateArchivedError' }
      & Pick<WorkflowTemplateArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'WorkflowTemplateDoesNotExistError' }
      & Pick<WorkflowTemplateDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'WrongDocumentTypeConnectedToSapEntityError' }
      & Pick<WrongDocumentTypeConnectedToSapEntityError, 'kind' | 'message'>
    ) | (
      { __typename?: 'XRechnungInvoiceHasNoPDFError' }
      & Pick<XRechnungInvoiceHasNoPdfError, 'kind' | 'message'>
    )> }
  )> }
);

export type DocumentRequestApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  value: DocumentRequestApprovalInput;
  options?: InputMaybe<DocumentRequestApprovalOptionsInput>;
}>;


export type DocumentRequestApprovalMutation = (
  { __typename?: 'Mutation' }
  & { documentRequestApprovalUnionResponse?: Maybe<(
    { __typename: 'Document' }
    & DocumentDataFragment
  ) | (
    { __typename: 'DocumentRequestApprovalErrors' }
    & { errors: Array<(
      { __typename?: 'AccountingAreaDoesNotExistError' }
      & Pick<AccountingAreaDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'AccountingAreaIsArchivedError' }
      & Pick<AccountingAreaIsArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'AccountingAreaRequiredError' }
      & Pick<AccountingAreaRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingKeyArchivedError' }
      & Pick<BookingKeyArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingKeyDoesNotExistError' }
      & Pick<BookingKeyDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'BookingsError' }
      & Pick<BookingsError, 'kind' | 'message'>
      & BookingsErrorDataFragment
    ) | (
      { __typename?: 'ContactAPNRequiredError' }
      & Pick<ContactApnRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ContactARNRequiredError' }
      & Pick<ContactArnRequiredError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ContactDoesNotExistError' }
      & Pick<ContactDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ContactIsArchivedError' }
      & Pick<ContactIsArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostCenterArchivedError' }
      & Pick<CostCenterArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostCenterDoesNotExistError' }
      & Pick<CostCenterDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostObjectArchivedError' }
      & Pick<CostObjectArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'CostObjectDoesNotExistError' }
      & Pick<CostObjectDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DeliveryDateConflictError' }
      & Pick<DeliveryDateConflictError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentCannotBeModifedError' }
      & Pick<DocumentCannotBeModifedError, 'kind' | 'message'>
      & DocumentCannotBeModifedErrorDataFragment
    ) | (
      { __typename?: 'DocumentDoesNotExistError' }
      & Pick<DocumentDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentGrossNetTaxRoundingAmountsCalculationError' }
      & Pick<DocumentGrossNetTaxRoundingAmountsCalculationError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentGrossNetTaxRoundingAmountsValidationError' }
      & Pick<DocumentGrossNetTaxRoundingAmountsValidationError, 'kind' | 'message'>
    ) | (
      { __typename?: 'DocumentIsAlreadyRequestedForApprovalError' }
      & Pick<DocumentIsAlreadyRequestedForApprovalError, 'kind' | 'message'>
      & DocumentIsAlreadyRequestedForApprovalErrorDataFragment
    ) | (
      { __typename?: 'ExtraCostInfoArchivedError' }
      & Pick<ExtraCostInfoArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'ExtraCostInfoDoesNotExistError' }
      & Pick<ExtraCostInfoDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'GeneralLedgerArchivedError' }
      & Pick<GeneralLedgerArchivedError, 'kind' | 'message'>
    ) | (
      { __typename?: 'GeneralLedgerDoesNotExistError' }
      & Pick<GeneralLedgerDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidBookingAmountError' }
      & Pick<InvalidBookingAmountError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidDeliveryDateError' }
      & Pick<InvalidDeliveryDateError, 'kind' | 'message'>
      & InvalidDeliveryDateErrorDataFragment
    ) | (
      { __typename?: 'InvalidDueDateError' }
      & Pick<InvalidDueDateError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidGrossAmountError' }
      & Pick<InvalidGrossAmountError, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidInvoiceDateError' }
      & Pick<InvalidInvoiceDateError, 'kind' | 'message'>
      & InvalidInvoiceDateErrorDataFragment
    ) | (
      { __typename?: 'InvalidSwiftCodeError' }
      & Pick<InvalidSwiftCodeError, 'kind' | 'message'>
    ) | (
      { __typename?: 'PayeeDoesNotExistError' }
      & Pick<PayeeDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'SumOfBookingsDoesNotMatchGrossAmountError' }
      & Pick<SumOfBookingsDoesNotMatchGrossAmountError, 'kind' | 'message'>
    ) | (
      { __typename?: 'SwiftCodeAndIbanCountryMismatchError' }
      & Pick<SwiftCodeAndIbanCountryMismatchError, 'kind' | 'message'>
    ) | (
      { __typename?: 'SwiftCodeMissingIBANError' }
      & Pick<SwiftCodeMissingIbanError, 'kind' | 'message'>
    ) | (
      { __typename?: 'TransactionDataMismatchError' }
      & Pick<TransactionDataMismatchError, 'kind' | 'message'>
    ) | (
      { __typename?: 'UnexpectedDocumentApprovalError' }
      & Pick<UnexpectedDocumentApprovalError, 'kind' | 'message'>
    ) | (
      { __typename?: 'WorkflowTemplateArchivedError' }
      & Pick<WorkflowTemplateArchivedError, 'kind' | 'message'>
      & WorkflowTemplateArchivedErrorDataFragment
    ) | (
      { __typename?: 'WorkflowTemplateDoesNotExistError' }
      & Pick<WorkflowTemplateDoesNotExistError, 'kind' | 'message'>
    ) | (
      { __typename?: 'WrongDocumentTypeConnectedToSapEntityError' }
      & Pick<WrongDocumentTypeConnectedToSapEntityError, 'kind' | 'message'>
    ) | (
      { __typename?: 'XRechnungInvoiceHasNoPDFError' }
      & Pick<XRechnungInvoiceHasNoPdfError, 'kind' | 'message'>
    )> }
  )> }
);

export type InvoiceDocumentPermissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InvoiceDocumentPermissionsQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'permissionsForMembership' | 'isReadOnly'>
    & { accessModificationPermissions: (
      { __typename?: 'InvoiceAccessModificationPermissions' }
      & Pick<InvoiceAccessModificationPermissions, 'viewAccess'>
    ) }
  )> }
);

export type GetEInvoiceValidationProtocolDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetEInvoiceValidationProtocolDataQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & { timeline?: Maybe<Array<(
      { __typename: 'AccountingAreaUpdatedEvent' }
      & Pick<AccountingAreaUpdatedEvent, 'id'>
    ) | (
      { __typename: 'ApprovedEvent' }
      & Pick<ApprovedEvent, 'id'>
    ) | (
      { __typename: 'ApproverExtractedEvent' }
      & Pick<ApproverExtractedEvent, 'id'>
    ) | (
      { __typename: 'AttachmentConvertedToDocumentEvent' }
      & Pick<AttachmentConvertedToDocumentEvent, 'id'>
    ) | (
      { __typename: 'AttachmentDeletedFromDocumentEvent' }
      & Pick<AttachmentDeletedFromDocumentEvent, 'id'>
    ) | (
      { __typename: 'AttachmentDetachedFromDocumentEvent' }
      & Pick<AttachmentDetachedFromDocumentEvent, 'id'>
    ) | (
      { __typename: 'ContactExtractionEvent' }
      & Pick<ContactExtractionEvent, 'id'>
    ) | (
      { __typename: 'ContactSupplierSendEmailEvent' }
      & Pick<ContactSupplierSendEmailEvent, 'id'>
    ) | (
      { __typename: 'CostCenterExtractionEvent' }
      & Pick<CostCenterExtractionEvent, 'id'>
    ) | (
      { __typename: 'DocumentAssociationsCreatedEvent' }
      & Pick<DocumentAssociationsCreatedEvent, 'id'>
    ) | (
      { __typename: 'DocumentAssociationsRemovedEvent' }
      & Pick<DocumentAssociationsRemovedEvent, 'id'>
    ) | (
      { __typename: 'DocumentClassifiedEvent' }
      & Pick<DocumentClassifiedEvent, 'id'>
    ) | (
      { __typename: 'DocumentCommentEvent' }
      & Pick<DocumentCommentEvent, 'id'>
    ) | (
      { __typename: 'DocumentConvertedEvent' }
      & Pick<DocumentConvertedEvent, 'id'>
    ) | (
      { __typename: 'DocumentConvertedToAttachmentEvent' }
      & Pick<DocumentConvertedToAttachmentEvent, 'id'>
    ) | (
      { __typename: 'DocumentDeletedEvent' }
      & Pick<DocumentDeletedEvent, 'id'>
    ) | (
      { __typename: 'DocumentFileReplacedEvent' }
      & Pick<DocumentFileReplacedEvent, 'id'>
    ) | (
      { __typename: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
      & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id'>
    ) | (
      { __typename: 'DocumentImportedByMigrationEvent' }
      & Pick<DocumentImportedByMigrationEvent, 'id'>
    ) | (
      { __typename: 'DocumentPaymentInfoUpdatedEvent' }
      & Pick<DocumentPaymentInfoUpdatedEvent, 'id'>
    ) | (
      { __typename: 'DocumentRelationshipCreatedEvent' }
      & Pick<DocumentRelationshipCreatedEvent, 'id'>
    ) | (
      { __typename: 'DocumentRelationshipRemovedEvent' }
      & Pick<DocumentRelationshipRemovedEvent, 'id'>
    ) | (
      { __typename: 'DocumentResetByCandis' }
      & Pick<DocumentResetByCandis, 'id'>
    ) | (
      { __typename: 'DocumentResetManualEvent' }
      & Pick<DocumentResetManualEvent, 'id'>
    ) | (
      { __typename: 'DocumentResetTransactionAmountUpdatedEvent' }
      & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id'>
    ) | (
      { __typename: 'DocumentResetTransactionLinkedEvent' }
      & Pick<DocumentResetTransactionLinkedEvent, 'id'>
    ) | (
      { __typename: 'DocumentResetTransactionUnLinkedEvent' }
      & Pick<DocumentResetTransactionUnLinkedEvent, 'id'>
    ) | (
      { __typename: 'DocumentResetUserDeactivationEvent' }
      & Pick<DocumentResetUserDeactivationEvent, 'id'>
    ) | (
      { __typename: 'DocumentResetUserRoleChangedEvent' }
      & Pick<DocumentResetUserRoleChangedEvent, 'id'>
    ) | (
      { __typename: 'DocumentSepaTransferGeneratedEvent' }
      & Pick<DocumentSepaTransferGeneratedEvent, 'id'>
    ) | (
      { __typename: 'DocumentStoredAsEcmDocumentEvent' }
      & Pick<DocumentStoredAsEcmDocumentEvent, 'id'>
    ) | (
      { __typename: 'DocumentTagUpdatedEvent' }
      & Pick<DocumentTagUpdatedEvent, 'id'>
    ) | (
      { __typename: 'DocumentTransactionCommentCreatedEvent' }
      & Pick<DocumentTransactionCommentCreatedEvent, 'id'>
    ) | (
      { __typename: 'DocumentTransactionLinkedEvent' }
      & Pick<DocumentTransactionLinkedEvent, 'id'>
    ) | (
      { __typename: 'DocumentTransactionReconciledEvent' }
      & Pick<DocumentTransactionReconciledEvent, 'id'>
    ) | (
      { __typename: 'DocumentTransactionStatusUpdatedEvent' }
      & Pick<DocumentTransactionStatusUpdatedEvent, 'id'>
    ) | (
      { __typename: 'DocumentTransactionUnlinkedEvent' }
      & Pick<DocumentTransactionUnlinkedEvent, 'id'>
    ) | (
      { __typename: 'DocumentUpdatedAsEcmDocumentEvent' }
      & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id'>
    ) | (
      { __typename: 'ExportedEvent' }
      & Pick<ExportedEvent, 'id'>
    ) | (
      { __typename: 'ExtractionEvent' }
      & Pick<ExtractionEvent, 'id'>
    ) | (
      { __typename: 'FileAttachedToDocumentEvent' }
      & Pick<FileAttachedToDocumentEvent, 'id'>
    ) | (
      { __typename: 'FileUploadedByEmailEvent' }
      & Pick<FileUploadedByEmailEvent, 'id'>
    ) | (
      { __typename: 'FileUploadedEvent' }
      & Pick<FileUploadedEvent, 'id'>
    ) | (
      { __typename: 'InvalidEInvoiceDetectedEvent' }
      & Pick<InvalidEInvoiceDetectedEvent, 'id'>
      & InvalidEInvoiceDetectedEventDataFragment
    ) | (
      { __typename: 'MetaDataExtractionEvent' }
      & Pick<MetaDataExtractionEvent, 'id'>
    ) | (
      { __typename: 'ProvisionCreatedEvent' }
      & Pick<ProvisionCreatedEvent, 'id'>
    ) | (
      { __typename: 'ProvisionDeletedEvent' }
      & Pick<ProvisionDeletedEvent, 'id'>
    ) | (
      { __typename: 'ProvisionExportedEvent' }
      & Pick<ProvisionExportedEvent, 'id'>
    ) | (
      { __typename: 'PurchaseOrderLinkedEvent' }
      & Pick<PurchaseOrderLinkedEvent, 'id'>
    ) | (
      { __typename: 'PurchaseOrderUnlinkedEvent' }
      & Pick<PurchaseOrderUnlinkedEvent, 'id'>
    ) | (
      { __typename: 'RejectedEvent' }
      & Pick<RejectedEvent, 'id'>
    ) | (
      { __typename: 'RequestApprovalEvent' }
      & Pick<RequestApprovalEvent, 'id'>
    ) | (
      { __typename: 'ReversalCreatedEvent' }
      & Pick<ReversalCreatedEvent, 'id'>
    ) | (
      { __typename: 'ReversalExportedEvent' }
      & Pick<ReversalExportedEvent, 'id'>
    ) | (
      { __typename: 'SingleBookingUpdatedEvent' }
      & Pick<SingleBookingUpdatedEvent, 'id'>
    ) | (
      { __typename: 'SplitBookingUpdatedEvent' }
      & Pick<SplitBookingUpdatedEvent, 'id'>
    ) | (
      { __typename: 'SubstituteAddedEvent' }
      & Pick<SubstituteAddedEvent, 'id'>
    ) | (
      { __typename: 'SubstituteRemovedEvent' }
      & Pick<SubstituteRemovedEvent, 'id'>
    ) | (
      { __typename: 'UserApprovalDocumentWorkflowStep' }
      & Pick<UserApprovalDocumentWorkflowStep, 'id'>
    ) | (
      { __typename: 'UserRejectedDocumentWorkflowStep' }
      & Pick<UserRejectedDocumentWorkflowStep, 'id'>
    ) | (
      { __typename: 'WorkflowCreatedForDocumentEvent' }
      & Pick<WorkflowCreatedForDocumentEvent, 'id'>
    ) | (
      { __typename: 'WorkflowStepSkippedEvent' }
      & Pick<WorkflowStepSkippedEvent, 'id'>
    ) | (
      { __typename: 'WorkflowTemplateAppliedToDocumentEvent' }
      & Pick<WorkflowTemplateAppliedToDocumentEvent, 'id'>
    )>>, eInvoiceErrors?: Maybe<Array<(
      { __typename?: 'EInvoiceValidationError' }
      & Pick<EInvoiceValidationError, 'message' | 'code' | 'location'>
      & { codeHelpCenterUrl?: Maybe<(
        { __typename?: 'EInvoiceErrorCodeUrls' }
        & Pick<EInvoiceErrorCodeUrls, 'en' | 'de'>
      )> }
    )>>, eInvoice?: Maybe<(
      { __typename?: 'DocumentEInvoiceField' }
      & Pick<DocumentEInvoiceField, 'error' | 'format'>
    )> }
  )> }
);

export type GetDocumentForDraftQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentForDraftQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'isDuplicate' | 'isOverdue' | 'isEInvoice' | 'eInvoiceValidationErrors' | 'isReadOnly' | 'isWaitingForClarification' | 'hasAttachments' | 'hasTransactionLinked' | 'status' | 'roundingAmount' | 'rolesThatAreEligibleToApprove' | 'globalDocumentId'>
    & { eInvoice?: Maybe<(
      { __typename?: 'DocumentEInvoiceField' }
      & Pick<DocumentEInvoiceField, 'format' | 'error'>
    )>, accessModificationPermissions: (
      { __typename?: 'InvoiceAccessModificationPermissions' }
      & Pick<InvoiceAccessModificationPermissions, 'viewAccess'>
    ), transactions?: Maybe<Array<(
      { __typename?: 'CardIssuerTransaction' }
      & TransactionDetailsDataFragment
    )>>, purchaseOrderData?: Maybe<(
      { __typename?: 'PurchaseOrderData' }
      & Pick<PurchaseOrderData, 'orderNumber' | 'purchaseOrderId' | 'integrationType'>
    )>, category?: Maybe<(
      { __typename?: 'DocumentCategoryField' }
      & { direction?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence'>
      )>, documentType?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )>, contact?: Maybe<(
      { __typename?: 'ContactField' }
      & Pick<ContactField, 'source' | 'confidence'>
      & { value: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'accountsPayableNumber' | 'accountsReceivableNumber' | 'swiftCode' | 'paymentConditionId' | 'email'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ), iban?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, taxNumber?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, vatId?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, createTransfer?: Maybe<(
          { __typename?: 'BooleanField' }
          & Pick<BooleanField, 'value'>
        )>, dueDateOffset?: Maybe<(
          { __typename?: 'IntField' }
          & Pick<IntField, 'value'>
        )> }
      ) }
    )>, accountingArea?: Maybe<(
      { __typename?: 'AccountingAreaField' }
      & Pick<AccountingAreaField, 'confidence' | 'source'>
      & { value: (
        { __typename?: 'AccountingArea' }
        & Pick<AccountingArea, 'id' | 'name'>
      ) }
    )>, invoiceNumber?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value' | 'confidence' | 'source'>
    )>, invoiceDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value' | 'confidence' | 'source'>
    )>, invoiceDeliveryDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value' | 'confidence' | 'source'>
    )>, invoicePostingDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value' | 'confidence' | 'source'>
    )>, amount?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value' | 'confidence' | 'source'>
    )>, currency?: Maybe<(
      { __typename?: 'DocumentCurrencyField' }
      & Pick<DocumentCurrencyField, 'value' | 'confidence' | 'source'>
    )>, iban?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value' | 'confidence' | 'source'>
    )>, swiftCode?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value' | 'confidence' | 'source'>
    )>, createTransfer?: Maybe<(
      { __typename?: 'BooleanField' }
      & Pick<BooleanField, 'value' | 'confidence' | 'source'>
    )>, paymentState: (
      { __typename: 'PaidDocumentState' }
      & Pick<PaidDocumentState, 'paidAt'>
    ) | { __typename: 'UnpaidDocumentState' }, approvers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>>, suggestedApprovers?: Maybe<(
      { __typename?: 'SuggestedApproversField' }
      & Pick<SuggestedApproversField, 'confidence' | 'source'>
      & { value: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )> }
    )>, documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'name'>
    )>, requester?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, originalDocumentId?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value' | 'confidence'>
    )>, extractedContact?: Maybe<(
      { __typename?: 'ExtractedContact' }
      & Pick<ExtractedContact, 'id' | 'cachingKey'>
      & { name?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, iban?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, bankAccountNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, swiftCode?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, taxNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, vatId?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, createTransfer?: Maybe<(
        { __typename?: 'BooleanField' }
        & Pick<BooleanField, 'value' | 'confidence' | 'source'>
      )>, email?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, phoneNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, street?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, postOfficeBox?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, postalCode?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, city?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, countryISOCode?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, customerNumber?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )>, currentWorkflowStep?: Maybe<(
      { __typename?: 'DocumentApprovalWorkflowStep' }
      & Pick<DocumentApprovalWorkflowStep, 'id'>
    ) | (
      { __typename?: 'SystemApprovalWorkflowStep' }
      & Pick<SystemApprovalWorkflowStep, 'id'>
    ) | (
      { __typename?: 'UserApprovalWorkflowStep' }
      & Pick<UserApprovalWorkflowStep, 'id'>
    )>, workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'id' | 'name' | 'description'>
      & { workflowTemplate?: Maybe<(
        { __typename?: 'WorkflowTemplate' }
        & Pick<WorkflowTemplate, 'id' | 'name'>
        & { steps?: Maybe<Array<(
          { __typename?: 'WorkflowTemplateStep' }
          & { resolvers?: Maybe<Array<Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )>>> }
        )>> }
      )>, steps?: Maybe<Array<(
        { __typename?: 'DocumentApprovalWorkflowStep' }
        & Pick<DocumentApprovalWorkflowStep, 'id'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'name'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'name'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        )>> }
      ) | (
        { __typename?: 'SystemApprovalWorkflowStep' }
        & Pick<SystemApprovalWorkflowStep, 'id'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'name'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'name'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        )>> }
      ) | (
        { __typename?: 'UserApprovalWorkflowStep' }
        & Pick<UserApprovalWorkflowStep, 'id'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'name'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'name'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        )>> }
      )>> }
    )>, bookings?: Maybe<Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'taxAmount' | 'netAmount' | 'postingText' | 'artistSocialInsuranceCode'>
      & { amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, dueDate?: Maybe<(
        { __typename?: 'DateField' }
        & Pick<DateField, 'value' | 'confidence' | 'source'>
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & Pick<BookingAccountField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'accountCode' | 'readableName' | 'name'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & Pick<BookingKeyField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'taxCode' | 'readableName' | 'isAcquisitionReverse'>
        ) }
      )>, costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'code' | 'name' | 'readableName'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'avatarUrl'>
          )>> }
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'code' | 'name' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'code' | 'name' | 'readableName'>
        ) }
      )>, discountAmount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, discountPercentage?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, discountPaymentDate?: Maybe<(
        { __typename?: 'DateField' }
        & Pick<DateField, 'value' | 'confidence' | 'source'>
      )>, paymentConditionId?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )>, projectCode?: Maybe<(
        { __typename?: 'ProjectCodeField' }
        & Pick<ProjectCodeField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'ProjectCode' }
          & Pick<ProjectCode, 'id' | 'code' | 'name'>
        ) }
      )> }
    )>>, tags: Array<(
      { __typename?: 'Tag' }
      & TagFragment
    )> }
  )> }
);

export type GetDocumentTransactionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentTransactionQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & { transactions?: Maybe<Array<(
      { __typename?: 'CardIssuerTransaction' }
      & TransactionDetailsDataFragment
    )>> }
  )> }
);

export type GetDocumentGlobalIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentGlobalIdQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'globalDocumentId'>
  )> }
);

export type GetDocumentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'rolesThatAreEligibleToApprove' | 'isOverdue' | 'isReadOnly' | 'isEInvoice' | 'isWaitingForClarification'>
    & { transactions?: Maybe<Array<(
      { __typename?: 'CardIssuerTransaction' }
      & TransactionDetailsDataFragment
    )>>, purchaseOrderData?: Maybe<(
      { __typename?: 'PurchaseOrderData' }
      & Pick<PurchaseOrderData, 'orderNumber' | 'purchaseOrderId' | 'integrationType'>
    )>, paymentState: (
      { __typename: 'PaidDocumentState' }
      & Pick<PaidDocumentState, 'paidAt'>
    ) | { __typename: 'UnpaidDocumentState' }, currentWorkflowStep?: Maybe<(
      { __typename?: 'DocumentApprovalWorkflowStep' }
      & Pick<DocumentApprovalWorkflowStep, 'id'>
    ) | (
      { __typename?: 'SystemApprovalWorkflowStep' }
      & Pick<SystemApprovalWorkflowStep, 'id'>
    ) | (
      { __typename?: 'UserApprovalWorkflowStep' }
      & Pick<UserApprovalWorkflowStep, 'id'>
    )>, eInvoice?: Maybe<(
      { __typename?: 'DocumentEInvoiceField' }
      & Pick<DocumentEInvoiceField, 'format' | 'error'>
    )>, accessModificationPermissions: (
      { __typename?: 'InvoiceAccessModificationPermissions' }
      & Pick<InvoiceAccessModificationPermissions, 'viewAccess'>
    ), workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'id'>
      & { steps?: Maybe<Array<(
        { __typename?: 'DocumentApprovalWorkflowStep' }
        & Pick<DocumentApprovalWorkflowStep, 'id'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'name'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'name'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        )>> }
      ) | (
        { __typename?: 'SystemApprovalWorkflowStep' }
        & Pick<SystemApprovalWorkflowStep, 'id'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'name'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'name'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        )>> }
      ) | (
        { __typename?: 'UserApprovalWorkflowStep' }
        & Pick<UserApprovalWorkflowStep, 'id'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'name'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'name'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name'>
          )> }
        )>> }
      )>> }
    )>, bookings?: Maybe<Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'taxAmount' | 'netAmount' | 'postingText' | 'artistSocialInsuranceCode'>
      & { amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )>, dueDate?: Maybe<(
        { __typename?: 'DateField' }
        & Pick<DateField, 'value'>
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'accountCode' | 'readableName'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'taxCode' | 'readableName'>
        ) }
      )>, costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, paymentConditionId?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )> }
    )>> }
    & DocumentDataFragment
  )> }
);

export type GetHasLinkedTransactionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetHasLinkedTransactionQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'hasTransactionLinked'>
  )> }
);

export type GetWorkflowTemplatesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetWorkflowTemplatesQuery = (
  { __typename?: 'Query' }
  & { workflowTemplates: Array<(
    { __typename?: 'WorkflowTemplate' }
    & Pick<WorkflowTemplate, 'id' | 'name' | 'description'>
  )> }
);

export type GetMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMembersQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'roles' | 'avatarUrl'>
  )> }
);

export type DeleteByIdMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteByIdMutation = (
  { __typename?: 'Mutation' }
  & { deleteById?: Maybe<(
    { __typename?: 'DeleteByIdResp' }
    & Pick<DeleteByIdResp, 'count'>
  )> }
);

export type SmartFieldSuggestionsForRequesterQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
}>;


export type SmartFieldSuggestionsForRequesterQuery = (
  { __typename?: 'Query' }
  & { sfsSuggestionForRequester: (
    { __typename?: 'SfsSuggestions' }
    & { costCenter1?: Maybe<(
      { __typename?: 'SfsCostCenterSuggestion' }
      & Pick<SfsCostCenterSuggestion, 'confidence'>
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName' | 'approverIds'>
      ) }
    )>, costCenter2?: Maybe<(
      { __typename?: 'SfsCostCenterSuggestion' }
      & Pick<SfsCostCenterSuggestion, 'confidence'>
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
      ) }
    )>, generalLedgerAccount?: Maybe<(
      { __typename?: 'SfsGeneralLedgerAccountSuggestion' }
      & Pick<SfsGeneralLedgerAccountSuggestion, 'confidence'>
      & { value: (
        { __typename?: 'BookingAccount' }
        & Pick<BookingAccount, 'id' | 'name' | 'readableName'>
        & { code: BookingAccount['accountCode'] }
      ) }
    )>, bookingKey?: Maybe<(
      { __typename?: 'SfsBookingKeySuggestion' }
      & Pick<SfsBookingKeySuggestion, 'confidence'>
      & { value: (
        { __typename?: 'BookingKey' }
        & Pick<BookingKey, 'id' | 'readableName'>
      ) }
    )>, workflowTemplate?: Maybe<(
      { __typename?: 'SfsWorkflowTemplateSuggestion' }
      & Pick<SfsWorkflowTemplateSuggestion, 'confidence'>
      & { value: (
        { __typename?: 'SfsWorkflowTemplateSuggestionValue' }
        & Pick<SfsWorkflowTemplateSuggestionValue, 'id' | 'name'>
      ) }
    )>, approverUserIds?: Maybe<(
      { __typename?: 'SfsApproversSuggestion' }
      & Pick<SfsApproversSuggestion, 'confidence' | 'value'>
    )> }
  ) }
);

export type UnassociatedCardIssuerTransactionsQueryVariables = Exact<{
  input: CardIssuerTransactionPaginationInput;
  filters?: InputMaybe<InboxCardIssuerTransactionFiltersInput>;
  sortBy?: InputMaybe<CardIssuerTransactionSortInput>;
}>;


export type UnassociatedCardIssuerTransactionsQuery = (
  { __typename?: 'Query' }
  & { unassociatedCardIssuerTransactions: (
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type LinkDocumentAndTransactionsMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  transactionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type LinkDocumentAndTransactionsMutation = (
  { __typename?: 'Mutation' }
  & { linkDocumentAndTransactions?: Maybe<Array<Maybe<(
    { __typename?: 'CardIssuerTransaction' }
    & Pick<CardIssuerTransaction, 'id'>
  )>>> }
);

export type ProcessingFormFieldItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProcessingFormFieldItemsQuery = (
  { __typename?: 'Query' }
  & { types: Array<(
    { __typename?: 'DocumentCategory' }
    & Pick<DocumentCategory, 'documentType' | 'direction' | 'isDefault' | 'isRds1_0Exportable'>
  )>, paymentConditions: Array<(
    { __typename?: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'paymentConditionNumber' | 'paymentConditionName' | 'dueDateOffset' | 'discountOffset' | 'discountPercentage'>
  )>, taxCodes: Array<(
    { __typename?: 'BookingKey' }
    & Pick<BookingKey, 'id' | 'taxCode' | 'readableName' | 'isAcquisitionReverse'>
  )>, accountingAreas: Array<(
    { __typename?: 'AccountingArea' }
    & Pick<AccountingArea, 'id' | 'name' | 'description'>
  )>, workflows: Array<(
    { __typename?: 'WorkflowTemplate' }
    & Pick<WorkflowTemplate, 'id' | 'name'>
    & { steps?: Maybe<Array<(
      { __typename?: 'WorkflowTemplateStep' }
      & { resolvers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>>> }
    )>> }
  )>, approvers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, getOrgMembersAbsence: Array<(
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'membershipId' | 'note' | 'status' | 'toDate' | 'substituteMembershipId'>
  )> }
);

export type ProcessingFormContactDataFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'accountsPayableNumber' | 'accountsReceivableNumber' | 'bankAccountNumber' | 'swiftCode' | 'email' | 'phoneNumber'>
  & { name: (
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  ), createTransfer?: Maybe<(
    { __typename?: 'BooleanField' }
    & Pick<BooleanField, 'value'>
  )>, iban?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, paymentCondition?: Maybe<(
    { __typename?: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id'>
  )>, taxNumber?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, vatId?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )> }
);


export type ProcessingFormContactDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ProcessingFormContactFieldItemsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ContactsFilterInput>;
}>;


export type ProcessingFormContactFieldItemsQuery = (
  { __typename?: 'Query' }
  & { contactsPagination: (
    { __typename?: 'ContactsPaginationResult' }
    & { records: Array<(
      { __typename?: 'Contact' }
      & ProcessingFormContactDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfo' }
      & Pick<SimplePageInfo, 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type ProcessingFormGeneralLedgerAccountFieldItemsQueryVariables = Exact<{
  readableName?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProcessingFormGeneralLedgerAccountFieldItemsQuery = (
  { __typename?: 'Query' }
  & { generalLedgerAccountsPagination: (
    { __typename?: 'BookingAccountPaginationResult' }
    & { records: Array<(
      { __typename?: 'BookingAccount' }
      & Pick<BookingAccount, 'id' | 'readableName' | 'accountCode'>
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery = (
  { __typename?: 'Query' }
  & { generalLedgerAccountsPagination: (
    { __typename?: 'BookingAccountPaginationResult' }
    & { records: Array<(
      { __typename?: 'BookingAccount' }
      & Pick<BookingAccount, 'id' | 'readableName' | 'accountCode'>
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type ProcessingFormCostCenterFieldItemsQueryVariables = Exact<{
  type: CostCenterTypes;
  readableName?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProcessingFormCostCenterFieldItemsQuery = (
  { __typename?: 'Query' }
  & { costCentersPagination: (
    { __typename?: 'CostCenterPaginationResult' }
    & { records: Array<(
      { __typename?: 'CostCenter' }
      & Pick<CostCenter, 'id' | 'readableName'>
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type ProcessingFormCostCenterFieldItemsByIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProcessingFormCostCenterFieldItemsByIdsQuery = (
  { __typename?: 'Query' }
  & { costCentersPagination: (
    { __typename?: 'CostCenterPaginationResult' }
    & { records: Array<(
      { __typename?: 'CostCenter' }
      & Pick<CostCenter, 'id' | 'readableName'>
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type ProcessingFormActionsQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type ProcessingFormActionsQuery = (
  { __typename?: 'Query' }
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'isDuplicate' | 'status'>
    & { originalDocumentId?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, transactions?: Maybe<Array<(
      { __typename?: 'CardIssuerTransaction' }
      & Pick<CardIssuerTransaction, 'id'>
    )>> }
  )> }
);

export type UpdateWaitingForClarificationMutationVariables = Exact<{
  documentId: Scalars['ID']['input'];
  isWaiting: Scalars['Boolean']['input'];
}>;


export type UpdateWaitingForClarificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWaitingForClarification'>
);

export type MobilePromotionDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MobilePromotionDataQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'status' | 'hasTransactionLinked'>
    & { sourceClassification?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, timeline?: Maybe<Array<(
      { __typename: 'AccountingAreaUpdatedEvent' }
      & Pick<AccountingAreaUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApprovedEvent' }
      & Pick<ApprovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ApproverExtractedEvent' }
      & Pick<ApproverExtractedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentConvertedToDocumentEvent' }
      & Pick<AttachmentConvertedToDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentDeletedFromDocumentEvent' }
      & Pick<AttachmentDeletedFromDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'AttachmentDetachedFromDocumentEvent' }
      & Pick<AttachmentDetachedFromDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ContactExtractionEvent' }
      & Pick<ContactExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ContactSupplierSendEmailEvent' }
      & Pick<ContactSupplierSendEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'CostCenterExtractionEvent' }
      & Pick<CostCenterExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsCreatedEvent' }
      & Pick<DocumentAssociationsCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentAssociationsRemovedEvent' }
      & Pick<DocumentAssociationsRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentClassifiedEvent' }
      & Pick<DocumentClassifiedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentCommentEvent' }
      & Pick<DocumentCommentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedEvent' }
      & Pick<DocumentConvertedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentConvertedToAttachmentEvent' }
      & Pick<DocumentConvertedToAttachmentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentDeletedEvent' }
      & Pick<DocumentDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentFileReplacedEvent' }
      & Pick<DocumentFileReplacedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
      & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentImportedByMigrationEvent' }
      & Pick<DocumentImportedByMigrationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentPaymentInfoUpdatedEvent' }
      & Pick<DocumentPaymentInfoUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipCreatedEvent' }
      & Pick<DocumentRelationshipCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentRelationshipRemovedEvent' }
      & Pick<DocumentRelationshipRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetByCandis' }
      & Pick<DocumentResetByCandis, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetManualEvent' }
      & Pick<DocumentResetManualEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionAmountUpdatedEvent' }
      & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionLinkedEvent' }
      & Pick<DocumentResetTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetTransactionUnLinkedEvent' }
      & Pick<DocumentResetTransactionUnLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserDeactivationEvent' }
      & Pick<DocumentResetUserDeactivationEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentResetUserRoleChangedEvent' }
      & Pick<DocumentResetUserRoleChangedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentSepaTransferGeneratedEvent' }
      & Pick<DocumentSepaTransferGeneratedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentStoredAsEcmDocumentEvent' }
      & Pick<DocumentStoredAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTagUpdatedEvent' }
      & Pick<DocumentTagUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionCommentCreatedEvent' }
      & Pick<DocumentTransactionCommentCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionLinkedEvent' }
      & Pick<DocumentTransactionLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionReconciledEvent' }
      & Pick<DocumentTransactionReconciledEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionStatusUpdatedEvent' }
      & Pick<DocumentTransactionStatusUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentTransactionUnlinkedEvent' }
      & Pick<DocumentTransactionUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentUpdatedAsEcmDocumentEvent' }
      & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExportedEvent' }
      & Pick<ExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExtractionEvent' }
      & Pick<ExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileAttachedToDocumentEvent' }
      & Pick<FileAttachedToDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileUploadedByEmailEvent' }
      & Pick<FileUploadedByEmailEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileUploadedEvent' }
      & Pick<FileUploadedEvent, 'id' | 'createdAt'>
      & FileUploadedEventDataFragment
    ) | (
      { __typename: 'InvalidEInvoiceDetectedEvent' }
      & Pick<InvalidEInvoiceDetectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'MetaDataExtractionEvent' }
      & Pick<MetaDataExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionCreatedEvent' }
      & Pick<ProvisionCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionDeletedEvent' }
      & Pick<ProvisionDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionExportedEvent' }
      & Pick<ProvisionExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderLinkedEvent' }
      & Pick<PurchaseOrderLinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'PurchaseOrderUnlinkedEvent' }
      & Pick<PurchaseOrderUnlinkedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RejectedEvent' }
      & Pick<RejectedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'RequestApprovalEvent' }
      & Pick<RequestApprovalEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalCreatedEvent' }
      & Pick<ReversalCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalExportedEvent' }
      & Pick<ReversalExportedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SingleBookingUpdatedEvent' }
      & Pick<SingleBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SplitBookingUpdatedEvent' }
      & Pick<SplitBookingUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteAddedEvent' }
      & Pick<SubstituteAddedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'SubstituteRemovedEvent' }
      & Pick<SubstituteRemovedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserApprovalDocumentWorkflowStep' }
      & Pick<UserApprovalDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'UserRejectedDocumentWorkflowStep' }
      & Pick<UserRejectedDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowCreatedForDocumentEvent' }
      & Pick<WorkflowCreatedForDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowStepSkippedEvent' }
      & Pick<WorkflowStepSkippedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowTemplateAppliedToDocumentEvent' }
      & Pick<WorkflowTemplateAppliedToDocumentEvent, 'id' | 'createdAt'>
    )>> }
  )> }
);

export type GetDocumentBookingAssociationsByInvoiceIdQueryVariables = Exact<{
  invoiceId: Scalars['String']['input'];
}>;


export type GetDocumentBookingAssociationsByInvoiceIdQuery = (
  { __typename?: 'Query' }
  & { getDocumentBookingAssociationsByInvoiceId?: Maybe<Array<(
    { __typename?: 'DocumentBookingAssociation' }
    & DocumentBookingAssociationDataFragment
  )>> }
);

export type GetSapPurchaseOrdersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSapPurchaseOrdersQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & { purchaseOrders?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrder' }
      & { goodsReceipts?: Maybe<Array<Maybe<(
        { __typename?: 'GoodsReceipt' }
        & Pick<GoodsReceipt, '_id' | 'receiptNumber' | 'deliveryDate' | 'status'>
        & { goodsLineItems?: Maybe<Array<(
          { __typename?: 'GoodsReceiptGoodsLineItem' }
          & Pick<GoodsReceiptGoodsLineItem, 'description' | 'quantity' | 'itemNumber' | 'lineNumber'>
        )>> }
      )>>> }
      & PurchaseOrderListDataFragment
    )>>> }
  )> }
);

export type ProcessingFormProjectCodeFieldItemsQueryVariables = Exact<{
  readableName?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProcessingFormProjectCodeFieldItemsQuery = (
  { __typename?: 'Query' }
  & { projectCodesPagination: (
    { __typename?: 'ProjectCodePaginationResult' }
    & { records: Array<(
      { __typename?: 'ProjectCode' }
      & Pick<ProjectCode, 'id' | 'name' | 'code'>
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type ProcessingFormProjectCodeFieldItemsByIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProcessingFormProjectCodeFieldItemsByIdsQuery = (
  { __typename?: 'Query' }
  & { projectCodesPagination: (
    { __typename?: 'ProjectCodePaginationResult' }
    & { records: Array<(
      { __typename?: 'ProjectCode' }
      & Pick<ProjectCode, 'id' | 'code' | 'name'>
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount'>
    ) }
  ) }
);

export type GetSapDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSapDocumentsQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & { associatedDocuments: Array<Maybe<(
      { __typename?: 'GoodsReceipt' }
      & AssociatedGoodsReceiptDataFragment
    ) | (
      { __typename?: 'PurchaseOrder' }
      & AssociatedPurchaseOrderDataFragment
    )>> }
  )> }
);

export type GetEcmSensitiveDocumentsQueryVariables = Exact<{
  next?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmSortInput>;
  filter?: InputMaybe<EcmFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type GetEcmSensitiveDocumentsQuery = (
  { __typename?: 'Query' }
  & { getEcmDocuments: (
    { __typename?: 'GetEcmDocumentsResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'EcmDocumentEdge' }
      & Pick<EcmDocumentEdge, 'cursor'>
      & { record: (
        { __typename?: 'EcmDocument' }
        & Pick<EcmDocument, 'contactId' | 'contactName' | 'createdAt' | 'documentName' | 'documentNumber' | 'endDate' | 'id' | 'globalDocumentId' | 'invoiceId'>
        & { tags: Array<(
          { __typename?: 'Tag' }
          & TagFragment
        )> }
      ), highlights: Array<(
        { __typename?: 'EcmHighlight' }
        & Pick<EcmHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>>, pageInfo: (
      { __typename?: 'EcmPageInfo' }
      & Pick<EcmPageInfo, 'hasNextPage' | 'nextPageCursor' | 'totalCount'>
    ) }
  ) }
);

export type GetEcmSensitiveDocumentsCountQueryVariables = Exact<{
  filter?: InputMaybe<EcmFilterInput>;
}>;


export type GetEcmSensitiveDocumentsCountQuery = (
  { __typename?: 'Query' }
  & { getEcmDocuments: (
    { __typename?: 'GetEcmDocumentsResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'EcmDocumentEdge' }
      & Pick<EcmDocumentEdge, 'cursor'>
    )>>, pageInfo: (
      { __typename?: 'EcmPageInfo' }
      & Pick<EcmPageInfo, 'totalCount'>
    ) }
  ) }
);

export type GetInboxOtherDocumentsQueryVariables = Exact<{
  next?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EcmSortInput>;
  filter?: InputMaybe<EcmFilterInput>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type GetInboxOtherDocumentsQuery = (
  { __typename?: 'Query' }
  & { getEcmDocuments: (
    { __typename?: 'GetEcmDocumentsResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'EcmDocumentEdge' }
      & Pick<EcmDocumentEdge, 'cursor'>
      & { record: (
        { __typename?: 'EcmDocument' }
        & Pick<EcmDocument, 'id' | 'globalDocumentId' | 'contactId' | 'contactName' | 'documentDate' | 'documentName' | 'documentNumber' | 'documentStatus' | 'documentType' | 'invoiceId' | 'isSensitive' | 'notes'>
        & { responsiblePerson?: Maybe<(
          { __typename?: 'EcmDocumentResponsiblePerson' }
          & { primary: Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'avatarUrl'>
          )> }
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & TagFragment
        )> }
      ), highlights: Array<(
        { __typename?: 'EcmHighlight' }
        & Pick<EcmHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>>, pageInfo: (
      { __typename?: 'EcmPageInfo' }
      & Pick<EcmPageInfo, 'hasNextPage' | 'nextPageCursor' | 'totalCount'>
    ) }
  ) }
);

export type ReimbursementListDataFragment = (
  { __typename?: 'ReimbursementCase' }
  & Pick<ReimbursementCase, 'id' | 'createdAt' | 'title' | 'status'>
  & { targetMembership?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  )>, totalAmount: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ) }
);


export type ReimbursementListDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type InboxReimbursementCasesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type InboxReimbursementCasesQuery = (
  { __typename?: 'Query' }
  & { inboxReimbursementCases: (
    { __typename?: 'InboxReimbursementCasesResponse' }
    & { pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ), edges?: Maybe<Array<(
      { __typename?: 'InboxReimbursementCasesEdge' }
      & Pick<InboxReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'InboxReimbursementCasesListItem' }
        & Pick<InboxReimbursementCasesListItem, 'id' | 'createdAt' | 'firstSubmittedForReviewAt' | 'title' | 'status'>
        & { targetMembership?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>, totalAmount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        ) }
      ) }
    )>> }
  ) }
);

export type InboxCardIssuerTransactionsQueryVariables = Exact<{
  input: CardIssuerTransactionPaginationInput;
  filters?: InputMaybe<InboxCardIssuerTransactionFiltersInput>;
  sortBy?: InputMaybe<CardIssuerTransactionSortInput>;
  queries?: InputMaybe<CardIssuerTransactionsQueryInput>;
}>;


export type InboxCardIssuerTransactionsQuery = (
  { __typename?: 'Query' }
  & { inboxCardIssuerTransactions?: Maybe<(
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  )> }
);

export type GetPaginatedInsightsWidgetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaginatedInsightsWidgetsQuery = (
  { __typename?: 'Query' }
  & { getPaginatedInsightsWidgets: (
    { __typename?: 'InsightsWidgetsPaginationResult' }
    & { records: Array<(
      { __typename?: 'InsightsWidget' }
      & Pick<InsightsWidget, 'id' | 'title' | 'isOwnedByCurrentUser'>
      & { preferences: (
        { __typename?: 'InsightsWidgetPreference' }
        & Pick<InsightsWidgetPreference, 'onDashboard' | 'widgetId'>
      ), budget?: Maybe<Array<(
        { __typename?: 'InsightsWidgetBudgets' }
        & Pick<InsightsWidgetBudgets, 'year' | 'type'>
        & { monthlyBudgets: (
          { __typename?: 'InsightsWidgetMonthlyBudgets' }
          & Pick<InsightsWidgetMonthlyBudgets, 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'>
        ) }
      )>>, filters?: Maybe<(
        { __typename?: 'DocumentFilters' }
        & { costCenters?: Maybe<Array<(
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id'>
        )>>, contacts?: Maybe<Array<(
          { __typename?: 'Contact' }
          & Pick<Contact, 'id'>
          & { name: (
            { __typename?: 'StringField' }
            & Pick<StringField, 'value'>
          ) }
        )>>, costObjects?: Maybe<Array<(
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id'>
        )>>, generalLedgerAccounts?: Maybe<Array<(
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id'>
        )>> }
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )>, unsubscribedUsers?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )>>, sharedWithUsers?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )>> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'totalCount' | 'hasNextPage'>
    )> }
  ) }
);

export type GetInsightsWidgetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetInsightsWidgetQuery = (
  { __typename?: 'Query' }
  & { getInsightsWidget?: Maybe<(
    { __typename?: 'InsightsWidget' }
    & Pick<InsightsWidget, 'id' | 'title' | 'isOwnedByCurrentUser'>
    & { budget?: Maybe<Array<(
      { __typename?: 'InsightsWidgetBudgets' }
      & Pick<InsightsWidgetBudgets, 'year' | 'type'>
      & { monthlyBudgets: (
        { __typename?: 'InsightsWidgetMonthlyBudgets' }
        & Pick<InsightsWidgetMonthlyBudgets, 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'>
      ) }
    )>>, filters?: Maybe<(
      { __typename?: 'DocumentFilters' }
      & { costCenters?: Maybe<Array<(
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'readableName'>
      )>>, contacts?: Maybe<Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      )>>, costObjects?: Maybe<Array<(
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'readableName'>
      )>>, generalLedgerAccounts?: Maybe<Array<(
        { __typename?: 'BookingAccount' }
        & Pick<BookingAccount, 'id' | 'readableName'>
      )>> }
    )>, creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>, sharedWithUsers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>> }
  )> }
);

export type DeleteInsightsWidgetsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type DeleteInsightsWidgetsMutation = (
  { __typename?: 'Mutation' }
  & { deleteInsightsWidgets?: Maybe<(
    { __typename?: 'DeleteInsightsWidgetsResult' }
    & Pick<DeleteInsightsWidgetsResult, 'deletedCount'>
  )> }
);

export type UnsubscribeInsightsWidgetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnsubscribeInsightsWidgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unsubscribeInsightsWidget'>
);

export type UpdateInsightsWidgetPreferencesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: InsightsWidgetPreferenceInput;
}>;


export type UpdateInsightsWidgetPreferencesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateInsightsWidgetPreferences'>
);

export type ExportSapB1SagaMutationVariables = Exact<{
  exportGuid: Scalars['String']['input'];
  isReexport?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportSapB1SagaMutation = (
  { __typename?: 'Mutation' }
  & { exportSapB1Saga: (
    { __typename?: 'SapB1ExportErrorResponse' }
    & Pick<SapB1ExportErrorResponse, 'code'>
  ) | (
    { __typename?: 'SapB1ExportResponse' }
    & Pick<SapB1ExportResponse, 'exportGuid'>
  ) }
);

export type ExportDatevXmlMutationVariables = Exact<{
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  transactionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  cardSettlementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reimbursementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportDatevXmlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportDatevXml'>
);

export type ExportArchiveDownloadUrlMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ExportArchiveDownloadUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportArchiveDownloadUrl'>
);

export type ExportArchiveDownloadUrlsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ExportArchiveDownloadUrlsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportArchiveDownloadUrls'>
);

export type ExportDatevDuoMutationVariables = Exact<{
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  transactionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  cardSettlementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportDatevDuoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportDatevDuo'>
);

export type ExportDatevBdsMutationVariables = Exact<{
  timeFrame: TimeFrameInput;
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  transactionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  cardSettlementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  provisionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reversalIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reimbursementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
  exportAllContacts?: InputMaybe<Scalars['Boolean']['input']>;
  exportAllPaymentConditions?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportDatevBdsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportDatevBds'>
);

export type ExportZipMutationVariables = Exact<{
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reimbursementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportZipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportZip'>
);

export type ExportAddisonFormatMutationVariables = Exact<{
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  transactionIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  cardSettlements?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  reimbursementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  originExportId?: InputMaybe<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportAddisonFormatMutation = (
  { __typename?: 'Mutation' }
  & { exportAddisonFormat: (
    { __typename?: 'NewExportZIPResponse' }
    & Pick<NewExportZipResponse, 'id' | 'hash'>
  ) }
);

export type ExportDatevFormatMutationVariables = Exact<{
  timeFrame: TimeFrameInput;
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  transactionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  cardSettlementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  provisionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reversalIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reimbursementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  originalExportGuid?: InputMaybe<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportDatevFormatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportDatevFormat'>
);

export type NewExportSapCsvMutationVariables = Exact<{
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  timeFrame: TimeFrameInput;
  originExportId?: InputMaybe<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NewExportSapCsvMutation = (
  { __typename?: 'Mutation' }
  & { newExportSapCsv: (
    { __typename?: 'CsvExportResponse' }
    & Pick<CsvExportResponse, 'id' | 'hash'>
  ) }
);

export type ExportCandisCsvMutationVariables = Exact<{
  documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reversalIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  reimbursementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  timeFrame: TimeFrameInput;
  originExportId?: InputMaybe<Scalars['String']['input']>;
  exportAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ExportCandisCsvMutation = (
  { __typename?: 'Mutation' }
  & { exportCandisCsv: (
    { __typename?: 'CsvExportResponse' }
    & Pick<CsvExportResponse, 'id' | 'hash'>
  ) }
);

export type SetFiscalYearConfiguredMutationVariables = Exact<{ [key: string]: never; }>;


export type SetFiscalYearConfiguredMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setFiscalYearConfigured'>
);

export type ExportFragment = (
  { __typename?: 'Export' }
  & Pick<Export, 'id' | 'status' | 'type'>
  & { exportableEntityInfo?: Maybe<Array<(
    { __typename: 'ExportableEntityInfo' }
    & Pick<ExportableEntityInfo, 'id' | 'type' | 'status'>
  )>> }
);


export type ExportFragmentVariables = Exact<{ [key: string]: never; }>;

export type ProvisionFragment = (
  { __typename?: 'Provision' }
  & Pick<Provision, 'id' | 'deliveryDate' | 'glAccount'>
  & { contact: (
    { __typename?: 'ProvisionContact' }
    & Pick<ProvisionContact, 'name'>
  ), entity: (
    { __typename?: 'ProvisionEntity' }
    & Pick<ProvisionEntity, 'status' | 'id' | 'type'>
  ), netAmount: (
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount' | 'precision'>
  ), bookings?: Maybe<Array<(
    { __typename?: 'ProvisionBooking' }
    & Pick<ProvisionBooking, 'glAccount' | 'account'>
  )>> }
);


export type ProvisionFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReversalFragment = (
  { __typename?: 'Reversal' }
  & Pick<Reversal, 'id' | 'deliveryDate' | 'invoiceDate' | 'glAccount'>
  & { contact: (
    { __typename?: 'ReversalContact' }
    & Pick<ReversalContact, 'name'>
  ), entity: (
    { __typename?: 'ProvisionEntity' }
    & Pick<ProvisionEntity, 'status' | 'id' | 'type'>
  ), netAmount: (
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount' | 'precision'>
  ), bookings?: Maybe<Array<(
    { __typename?: 'ProvisionBooking' }
    & Pick<ProvisionBooking, 'glAccount' | 'account'>
  )>> }
);


export type ReversalFragmentVariables = Exact<{ [key: string]: never; }>;

export type _ExportsQueryVariables = Exact<{
  input?: InputMaybe<ExportFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type _ExportsQuery = (
  { __typename?: 'Query' }
  & { _exports: (
    { __typename?: 'ExportConnection' }
    & { node?: Maybe<Array<(
      { __typename?: 'Export' }
      & Pick<Export, 'createdAt'>
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
      & ExportFragment
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type AnExportableDocumentFragment = (
  { __typename?: 'ExportableDocument' }
  & Pick<ExportableDocument, 'id' | 'documentGuid' | 'invoiceDate' | 'invoiceNumber' | 'transactionStatus' | 'direction' | 'documentType'>
  & { contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { name: (
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    ) }
  ), amount: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ), paymentState: (
    { __typename: 'PaidDocumentState' }
    & Pick<PaidDocumentState, 'isPaid' | 'paidAt' | 'method'>
  ) | (
    { __typename: 'UnpaidDocumentState' }
    & Pick<UnpaidDocumentState, 'isPaid'>
  ) }
);


export type AnExportableDocumentFragmentVariables = Exact<{ [key: string]: never; }>;

export type AnExportableDocumentEsFragment = (
  { __typename: 'ExportDocumentEs' }
  & Pick<ExportDocumentEs, 'id' | 'documentGuid' | 'invoiceDate' | 'invoiceNumber' | 'transactionStatus' | 'direction' | 'documentType'>
  & { contact?: Maybe<(
    { __typename: 'ExportDocumentContact' }
    & Pick<ExportDocumentContact, 'id' | 'name'>
  )>, amount?: Maybe<(
    { __typename: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, paymentState: (
    { __typename?: 'ExportDocumentPayment' }
    & Pick<ExportDocumentPayment, 'isPaid' | 'paidAt' | 'method'>
  ) }
);


export type AnExportableDocumentEsFragmentVariables = Exact<{ [key: string]: never; }>;

export type AnExportableTransactionFragment = (
  { __typename?: 'CardIssuerTransaction' }
  & Pick<CardIssuerTransaction, 'id' | 'invoiceAssociationStatus' | 'transactionCreatedAt' | 'merchantLegalName' | 'status' | 'type' | 'cardName'>
  & { billingAmount?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, accountingData?: Maybe<(
    { __typename?: 'TransactionCardAccountingData' }
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    )> }
  )>, member: (
    { __typename?: 'MemberInfo' }
    & Pick<MemberInfo, 'firstName' | 'lastName'>
  ) }
);


export type AnExportableTransactionFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentsForExportQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  sort?: InputMaybe<SortInput>;
}>;


export type DocumentsForExportQuery = (
  { __typename?: 'Query' }
  & { documentsForExport: (
    { __typename?: 'ExportableDocumentConnection' }
    & { node?: Maybe<Array<(
      { __typename?: 'ExportableDocument' }
      & AnExportableDocumentFragment
    )>>, edges?: Maybe<Array<(
      { __typename?: 'ExportableDocumentEdge' }
      & Pick<ExportableDocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'ExportableDocument' }
        & AnExportableDocumentFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type ExportDocumentsEsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ExportDocumentsFilterInput>;
  sort?: InputMaybe<ExportDocumentsSortInput>;
}>;


export type ExportDocumentsEsQuery = (
  { __typename?: 'Query' }
  & { exportDocumentsEs: (
    { __typename?: 'ExportDocumentsEsResponse' }
    & { edges: Array<(
      { __typename?: 'ExportDocumentEsEdge' }
      & Pick<ExportDocumentEsEdge, 'cursor'>
      & { node: (
        { __typename?: 'ExportDocumentEs' }
        & AnExportableDocumentEsFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    ) }
  ) }
);

export type DocumentsForExportContactsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
}>;


export type DocumentsForExportContactsQuery = (
  { __typename?: 'Query' }
  & { documentsForExportContacts: (
    { __typename?: 'ExportableDocumentConnection' }
    & { node?: Maybe<Array<(
      { __typename?: 'ExportableDocument' }
      & Pick<ExportableDocument, 'id'>
      & { contact: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type CardTransactionsReadyForExportQueryVariables = Exact<{ [key: string]: never; }>;


export type CardTransactionsReadyForExportQuery = (
  { __typename?: 'Query' }
  & { cardTransactionsReadyForExport: (
    { __typename?: 'ExportableCardTransaction' }
    & { associatedCardTransactions?: Maybe<Array<(
      { __typename?: 'ExportableDocument' }
      & AnExportableDocumentFragment
    )>>, unassociatedCardTransactions?: Maybe<Array<(
      { __typename?: 'CardIssuerTransaction' }
      & AnExportableTransactionFragment
    )>> }
  ) }
);

export type CardTransactionsNotReadyForExportQueryVariables = Exact<{ [key: string]: never; }>;


export type CardTransactionsNotReadyForExportQuery = (
  { __typename?: 'Query' }
  & { cardTransactionsNotReadyForExport: (
    { __typename?: 'ExportableCardTransaction' }
    & { associatedCardTransactions?: Maybe<Array<(
      { __typename?: 'ExportableDocument' }
      & AnExportableDocumentFragment
    )>>, unassociatedCardTransactions?: Maybe<Array<(
      { __typename?: 'CardIssuerTransaction' }
      & AnExportableTransactionFragment
    )>> }
  ) }
);

export type CardSettlementsReadyForExportQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  statuses: Array<PaymentSettlementStatus> | PaymentSettlementStatus;
  paidSettlements?: InputMaybe<CardIssuerSettlementPaidSettlementsFilter>;
}>;


export type CardSettlementsReadyForExportQuery = (
  { __typename?: 'Query' }
  & { cardSettlementsReadyForExport: (
    { __typename?: 'PaymentSettlementConnection' }
    & { node?: Maybe<Array<(
      { __typename?: 'CardIssuerPaymentSettlement' }
      & Pick<CardIssuerPaymentSettlement, 'id' | 'status' | 'actualPaymentDate' | 'paymentCreatedAt' | 'plannedPaymentDate' | 'publicPaymentId'>
      & { amount: (
        { __typename?: 'PaymentSettlementAmount' }
        & Pick<PaymentSettlementAmount, 'value' | 'precision' | 'currency'>
      ), statement?: Maybe<(
        { __typename?: 'CardIssuerStatement' }
        & Pick<CardIssuerStatement, 'id' | 'hasFile'>
      )> }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type ProvisionsReadyForExportQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters: ExportablePovisionFilters;
}>;


export type ProvisionsReadyForExportQuery = (
  { __typename?: 'Query' }
  & { provisionsReadyForExport: (
    { __typename?: 'ProvisionConnection' }
    & { node?: Maybe<Array<(
      { __typename: 'Provision' }
      & ProvisionFragment
    )>>, edges?: Maybe<Array<(
      { __typename?: 'Provision' }
      & Pick<Provision, 'id'>
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor'>
    )> }
  ) }
);

export type ReversalsReadyForExportQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters: ExportableReversalFilters;
}>;


export type ReversalsReadyForExportQuery = (
  { __typename?: 'Query' }
  & { reversalsReadyForExport: (
    { __typename?: 'ReversalConnection' }
    & { node?: Maybe<Array<(
      { __typename: 'Reversal' }
      & ReversalFragment
    )>>, edges?: Maybe<Array<(
      { __typename?: 'Reversal' }
      & Pick<Reversal, 'id'>
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor'>
    )> }
  ) }
);

export type ReimbursementItemsReadyForExportQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ExportableReimbursementItemFilters>;
  sort?: InputMaybe<ExportableReimbursementItemSortInput>;
}>;


export type ReimbursementItemsReadyForExportQuery = (
  { __typename?: 'Query' }
  & { reimbursementItemsReadyForExport: (
    { __typename?: 'ExportableReimbursementItemConnection' }
    & { node: Array<(
      { __typename: 'ExportableReimbursementItem' }
      & Pick<ExportableReimbursementItem, 'id' | 'title' | 'type' | 'reimbursementItemDate'>
      & { totalAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'precision' | 'amount'>
      ), reimbursementCase: (
        { __typename?: 'ExportableReimbursementItemCase' }
        & Pick<ExportableReimbursementItemCase, 'id' | 'title' | 'submittedForReviewAt'>
        & { targetMembership: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        ) }
      ) }
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type ExportNotificationsQueryVariables = Exact<{
  input?: InputMaybe<ExportFilters>;
}>;


export type ExportNotificationsQuery = (
  { __typename?: 'Query' }
  & { exports: Array<(
    { __typename?: 'Export' }
    & Pick<Export, 'hash'>
    & { integration?: Maybe<(
      { __typename?: 'ExportIntegration' }
      & Pick<ExportIntegration, 'details'>
    )> }
    & ExportFragment
  )> }
);

export type DatevStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type DatevStatusQuery = (
  { __typename?: 'Query' }
  & { datevStatus: (
    { __typename?: 'DatevOperationalStatus' }
    & Pick<DatevOperationalStatus, 'isOperational' | 'hasIncidentOnCandis' | 'hasIncidentOnDatev'>
  ) }
);

export type ContactsForExportQueryVariables = Exact<{
  fetchAllRecords: Scalars['Boolean']['input'];
}>;


export type ContactsForExportQuery = (
  { __typename?: 'Query' }
  & { contactsForExport?: Maybe<Array<(
    { __typename?: 'ExportableContact' }
    & Pick<ExportableContact, 'id' | 'name' | 'status' | 'relationshipType' | 'membershipId'>
  )>> }
);

export type PaymentConditionsForExportQueryVariables = Exact<{
  fetchAllRecords: Scalars['Boolean']['input'];
}>;


export type PaymentConditionsForExportQuery = (
  { __typename?: 'Query' }
  & { paymentConditionsForExport?: Maybe<Array<(
    { __typename?: 'ExportablePaymentCondition' }
    & Pick<ExportablePaymentCondition, 'id' | 'paymentConditionNumber' | 'dueDateOffset' | 'discountOffset' | 'discountPercentage' | 'status'>
  )>> }
);

export type CountExportableContactQueryVariables = Exact<{ [key: string]: never; }>;


export type CountExportableContactQuery = (
  { __typename?: 'Query' }
  & { evaluateCoreDataExport: (
    { __typename?: 'EvaluateCoreDataExport' }
    & { exportableCoreDataCount: (
      { __typename?: 'ExportableCoreDataMetric' }
      & { exportableContactCount: (
        { __typename?: 'DocumentMetric' }
        & Pick<DocumentMetric, 'count'>
      ), newAndUpdatedExportableContactCount: (
        { __typename?: 'DocumentMetric' }
        & Pick<DocumentMetric, 'count'>
      ) }
    ) }
  ) }
);

export type CountExportablePaymentConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountExportablePaymentConditionsQuery = (
  { __typename?: 'Query' }
  & { evaluateCoreDataExport: (
    { __typename?: 'EvaluateCoreDataExport' }
    & { exportableCoreDataCount: (
      { __typename?: 'ExportableCoreDataMetric' }
      & { exportablePaymentConditionCount: (
        { __typename?: 'DocumentMetric' }
        & Pick<DocumentMetric, 'count'>
      ), newAndUpdatedExportablePaymentConditionCount: (
        { __typename?: 'DocumentMetric' }
        & Pick<DocumentMetric, 'count'>
      ) }
    ) }
  ) }
);

export type GetCoreDataExportStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCoreDataExportStatusQuery = (
  { __typename?: 'Query' }
  & { evaluateCoreDataExport: (
    { __typename?: 'EvaluateCoreDataExport' }
    & { coreDataExportStatus: (
      { __typename?: 'CoreDataExportStatus' }
      & Pick<CoreDataExportStatus, 'hasExportedContacts' | 'hasExportedPaymentConditions'>
    ) }
  ) }
);

export type GetAvailableMonthsQueryVariables = Exact<{
  originalExportId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAvailableMonthsQuery = (
  { __typename?: 'Query' }
  & { getAvailableMonths: Array<(
    { __typename?: 'AvailableMonth' }
    & Pick<AvailableMonth, 'month' | 'year' | 'numberOfTxnBasedDocuments' | 'numberOfDocuments' | 'numberOfTxnWithoutDocuments' | 'numberOfSettlements' | 'numberOfDocumentProvisions' | 'numberOfTxnProvisions' | 'numberOfDocumentReversals' | 'numberOfTxnReversals' | 'numberOfReimbursementItems'>
  )> }
);

export type ExportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ExportQuery = (
  { __typename?: 'Query' }
  & { export?: Maybe<(
    { __typename?: 'Export' }
    & Pick<Export, 'id' | 'type' | 'clientNumber' | 'consultantNumber' | 'status' | 'client' | 'originExportId' | 'createdAt' | 'updatedAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
    )>, documents?: Maybe<Array<(
      { __typename?: 'ExportDocument' }
      & { document: (
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'status'>
        & { contact?: Maybe<(
          { __typename?: 'ContactField' }
          & { value: (
            { __typename?: 'Contact' }
            & Pick<Contact, 'id'>
            & { name: (
              { __typename?: 'StringField' }
              & Pick<StringField, 'value'>
            ) }
          ) }
        )>, amount?: Maybe<(
          { __typename?: 'FloatField' }
          & Pick<FloatField, 'value'>
        )>, category?: Maybe<(
          { __typename?: 'DocumentCategoryField' }
          & { direction?: Maybe<(
            { __typename?: 'StringField' }
            & Pick<StringField, 'value'>
          )> }
        )>, invoiceDate?: Maybe<(
          { __typename?: 'DateField' }
          & Pick<DateField, 'value'>
        )>, currency?: Maybe<(
          { __typename?: 'DocumentCurrencyField' }
          & Pick<DocumentCurrencyField, 'value'>
        )>, invoiceNumber?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, documentFile?: Maybe<(
          { __typename?: 'DocumentFile' }
          & Pick<DocumentFile, 'id' | 'name'>
        )>, datevGuid?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, paymentState: (
          { __typename: 'PaidDocumentState' }
          & Pick<PaidDocumentState, 'isPaid' | 'paidAt' | 'method'>
        ) | (
          { __typename: 'UnpaidDocumentState' }
          & Pick<UnpaidDocumentState, 'isPaid'>
        ) }
      ), docStatus?: Maybe<(
        { __typename?: 'ExportDocumentStatus' }
        & Pick<ExportDocumentStatus, 'type' | 'details' | 'warnings' | 'exportMethod'>
        & { exportError?: Maybe<(
          { __typename?: 'ExportError' }
          & Pick<ExportError, 'message' | 'errorCode' | 'errorDescription' | 'errorUri'>
        )> }
      )> }
    )>>, transactions: Array<(
      { __typename?: 'CardIssuerTransaction' }
      & Pick<CardIssuerTransaction, 'id' | 'invoiceAssociationStatus' | 'transactionCreatedAt' | 'merchantLegalName' | 'status' | 'type' | 'cardName'>
      & { accountingData?: Maybe<(
        { __typename?: 'TransactionCardAccountingData' }
        & { contact?: Maybe<(
          { __typename?: 'Contact' }
          & Pick<Contact, 'id'>
          & { name: (
            { __typename?: 'StringField' }
            & Pick<StringField, 'value'>
          ) }
        )> }
      )>, billingAmount?: Maybe<(
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
      )>, billingAmountForAccounting?: Maybe<(
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
      )>, member: (
        { __typename?: 'MemberInfo' }
        & Pick<MemberInfo, 'firstName' | 'lastName'>
      ) }
    )>, cardSettlements: Array<(
      { __typename?: 'CardIssuerPaymentSettlement' }
      & Pick<CardIssuerPaymentSettlement, 'id' | 'status' | 'actualPaymentDate' | 'paymentCreatedAt' | 'plannedPaymentDate' | 'publicPaymentId'>
      & { amount: (
        { __typename?: 'PaymentSettlementAmount' }
        & Pick<PaymentSettlementAmount, 'value' | 'currency' | 'precision'>
      ), statement?: Maybe<(
        { __typename?: 'CardIssuerStatement' }
        & Pick<CardIssuerStatement, 'id' | 'hasFile'>
      )> }
    )>, provisions: Array<(
      { __typename: 'Provision' }
      & ProvisionFragment
    )>, reversals: Array<(
      { __typename: 'Reversal' }
      & ReversalFragment
      & ReversalFragment
    )>, integration?: Maybe<(
      { __typename?: 'ExportIntegration' }
      & Pick<ExportIntegration, 'type' | 'details'>
    )>, timeFrame?: Maybe<(
      { __typename?: 'TimeFrame' }
      & Pick<TimeFrame, 'year' | 'month'>
    )>, exportableEntities?: Maybe<Array<(
      { __typename: 'ExportableEntity' }
      & Pick<ExportableEntity, 'id' | 'type'>
    )>>, exportableEntityInfo?: Maybe<Array<(
      { __typename: 'ExportableEntityInfo' }
      & Pick<ExportableEntityInfo, 'id' | 'type' | 'status' | 'details' | 'warnings' | 'exportMethod'>
      & { exportError?: Maybe<(
        { __typename: 'ExportError' }
        & Pick<ExportError, 'message' | 'errorCode' | 'errorDescription' | 'errorUri'>
      )> }
    )>>, reimbursementItems: Array<(
      { __typename: 'ExportableReimbursementItem' }
      & Pick<ExportableReimbursementItem, 'id' | 'title' | 'type' | 'reimbursementItemDate'>
      & { totalAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'precision' | 'amount'>
      ), reimbursementCase: (
        { __typename?: 'ExportableReimbursementItemCase' }
        & Pick<ExportableReimbursementItemCase, 'id' | 'title' | 'submittedForReviewAt'>
        & { targetMembership: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        ) }
      ) }
    )> }
  )> }
);

export type GetInitialBdsExportsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
}>;


export type GetInitialBdsExportsQuery = (
  { __typename?: 'Query' }
  & { getInitialBdsExports: Array<(
    { __typename?: 'Export' }
    & Pick<Export, 'id' | 'type' | 'clientNumber' | 'consultantNumber' | 'status' | 'client' | 'originExportId' | 'createdAt' | 'updatedAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
    )>, documents?: Maybe<Array<(
      { __typename?: 'ExportDocument' }
      & { document: (
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'status'>
        & { contact?: Maybe<(
          { __typename?: 'ContactField' }
          & { value: (
            { __typename?: 'Contact' }
            & Pick<Contact, 'id'>
            & { name: (
              { __typename?: 'StringField' }
              & Pick<StringField, 'value'>
            ) }
          ) }
        )>, amount?: Maybe<(
          { __typename?: 'FloatField' }
          & Pick<FloatField, 'value'>
        )>, category?: Maybe<(
          { __typename?: 'DocumentCategoryField' }
          & { direction?: Maybe<(
            { __typename?: 'StringField' }
            & Pick<StringField, 'value'>
          )> }
        )>, invoiceDate?: Maybe<(
          { __typename?: 'DateField' }
          & Pick<DateField, 'value'>
        )>, currency?: Maybe<(
          { __typename?: 'DocumentCurrencyField' }
          & Pick<DocumentCurrencyField, 'value'>
        )>, invoiceNumber?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, documentFile?: Maybe<(
          { __typename?: 'DocumentFile' }
          & Pick<DocumentFile, 'id' | 'name'>
        )>, datevGuid?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, paymentState: (
          { __typename: 'PaidDocumentState' }
          & Pick<PaidDocumentState, 'isPaid' | 'paidAt' | 'method'>
        ) | (
          { __typename: 'UnpaidDocumentState' }
          & Pick<UnpaidDocumentState, 'isPaid'>
        ) }
      ), docStatus?: Maybe<(
        { __typename?: 'ExportDocumentStatus' }
        & Pick<ExportDocumentStatus, 'type' | 'details' | 'warnings' | 'exportMethod'>
        & { exportError?: Maybe<(
          { __typename?: 'ExportError' }
          & Pick<ExportError, 'message' | 'errorCode' | 'errorDescription' | 'errorUri'>
        )> }
      )> }
    )>>, transactions: Array<(
      { __typename?: 'CardIssuerTransaction' }
      & Pick<CardIssuerTransaction, 'id' | 'transactionCreatedAt' | 'merchantName' | 'status' | 'type' | 'cardName'>
      & { billingAmount?: Maybe<(
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
      )>, member: (
        { __typename?: 'MemberInfo' }
        & Pick<MemberInfo, 'firstName' | 'lastName'>
      ) }
    )>, cardSettlements: Array<(
      { __typename?: 'CardIssuerPaymentSettlement' }
      & Pick<CardIssuerPaymentSettlement, 'id' | 'status' | 'actualPaymentDate' | 'paymentCreatedAt' | 'plannedPaymentDate' | 'publicPaymentId'>
      & { amount: (
        { __typename?: 'PaymentSettlementAmount' }
        & Pick<PaymentSettlementAmount, 'value' | 'currency' | 'precision'>
      ) }
    )>, provisions: Array<(
      { __typename: 'Provision' }
      & ProvisionFragment
    )>, reversals: Array<(
      { __typename: 'Reversal' }
      & ReversalFragment
      & ReversalFragment
    )>, integration?: Maybe<(
      { __typename?: 'ExportIntegration' }
      & Pick<ExportIntegration, 'type' | 'details'>
    )>, timeFrame?: Maybe<(
      { __typename?: 'TimeFrame' }
      & Pick<TimeFrame, 'year' | 'month'>
    )>, exportableEntities?: Maybe<Array<(
      { __typename: 'ExportableEntity' }
      & Pick<ExportableEntity, 'id' | 'type'>
    )>>, exportableEntityInfo?: Maybe<Array<(
      { __typename: 'ExportableEntityInfo' }
      & Pick<ExportableEntityInfo, 'id' | 'type' | 'status' | 'details' | 'warnings' | 'exportMethod'>
      & { exportError?: Maybe<(
        { __typename: 'ExportError' }
        & Pick<ExportError, 'message' | 'errorCode' | 'errorDescription' | 'errorUri'>
      )> }
    )>> }
  )> }
);

export type GetDocumentForManageRelationshipsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentForManageRelationshipsQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'globalDocumentId' | 'createdAt' | 'status'>
    & { globalId: Document['globalDocumentId'] }
    & { contact?: Maybe<(
      { __typename?: 'ContactField' }
      & { value: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      ) }
    )>, note?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'name'>
    )>, invoiceNumber?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & TagFragment
    )> }
  )> }
);

export type GetEcmDocumentForManageRelationshipsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEcmDocumentForManageRelationshipsQuery = (
  { __typename?: 'Query' }
  & { getAggregatedEcmDocument: (
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'id' | 'globalDocumentId' | 'type' | 'contactName' | 'notes' | 'documentName' | 'documentNumber' | 'documentDate' | 'documentStatus' | 'isSensitive' | 'createdAt'>
    & { documentSubCategory?: Maybe<(
      { __typename?: 'EcmDocumentSubCategoryField' }
      & Pick<EcmDocumentSubCategoryField, 'id' | 'name'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & TagFragment
    )> }
  ) }
);

export type MoneyFieldsFragment = (
  { __typename?: 'Money' }
  & Pick<Money, 'amount' | 'currency' | 'precision'>
);


export type MoneyFieldsFragmentVariables = Exact<{ [key: string]: never; }>;

export type PayableDocumentData_DocumentWithIncompletePaymentData_Fragment = (
  { __typename: 'DocumentWithIncompletePaymentData' }
  & Pick<DocumentWithIncompletePaymentData, 'id' | 'status' | 'currency' | 'invoiceDate' | 'invoiceNumber' | 'invoiceDueDate' | 'discountPercentage' | 'discountPaymentDate'>
  & { amount: (
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  ), contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { name: (
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    ) }
  ), discountAmount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  )> }
);

export type PayableDocumentData_SepaExportableDocument_Fragment = (
  { __typename: 'SepaExportableDocument' }
  & Pick<SepaExportableDocument, 'creditorIban' | 'creditorSwiftCode' | 'id' | 'status' | 'currency' | 'invoiceDate' | 'invoiceNumber' | 'invoiceDueDate' | 'discountPercentage' | 'discountPaymentDate'>
  & { amount: (
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  ), contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { name: (
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    ) }
  ), discountAmount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  )> }
);

export type PayableDocumentDataFragment = PayableDocumentData_DocumentWithIncompletePaymentData_Fragment | PayableDocumentData_SepaExportableDocument_Fragment;


export type PayableDocumentDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PayableDocumentsPaginationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
}>;


export type PayableDocumentsPaginationQuery = (
  { __typename?: 'Query' }
  & { payableDocumentsPagination: (
    { __typename?: 'PayableDocumentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'PayableDocumentEdge' }
      & Pick<PayableDocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'DocumentWithIncompletePaymentData' }
        & PayableDocumentData_DocumentWithIncompletePaymentData_Fragment
      ) | (
        { __typename?: 'SepaExportableDocument' }
        & PayableDocumentData_SepaExportableDocument_Fragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type SepaTransferPaymentHistoryDataFragment = (
  { __typename?: 'SepaTransferPayment' }
  & Pick<SepaTransferPayment, 'id' | 'creationDate' | 'numberOfPaidDocuments' | 'currency'>
  & { paymentInitiator: (
    { __typename?: 'User' }
    & Pick<User, 'name'>
  ), totalAmount: (
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  ) }
);


export type SepaTransferPaymentHistoryDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PaymentsHistoryQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type PaymentsHistoryQuery = (
  { __typename?: 'Query' }
  & { payments: (
    { __typename?: 'PaymentsResponse' }
    & { records: Array<(
      { __typename: 'SepaTransferPayment' }
      & SepaTransferPaymentHistoryDataFragment
    )>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type PaidDocumentDataFragment = (
  { __typename?: 'PaidDocument' }
  & Pick<PaidDocument, 'documentId' | 'creditorIban' | 'creditorSwiftCode' | 'invoiceNumber' | 'currency' | 'invoiceDate' | 'invoiceDueDate' | 'discountPaymentDate'>
  & { amount: (
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  ), creditor: (
    { __typename?: 'CreditorContact' }
    & Pick<CreditorContact, 'name'>
  ), discountAmount?: Maybe<(
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  )> }
);


export type PaidDocumentDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SepaTransferPaymentDataFragment = (
  { __typename?: 'SepaTransferPayment' }
  & Pick<SepaTransferPayment, 'id' | 'creationDate' | 'currency' | 'swiftCode'>
  & { paymentInitiator: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  ), paidDocuments: Array<(
    { __typename?: 'PaidDocument' }
    & PaidDocumentDataFragment
  )>, totalAmount: (
    { __typename?: 'Money' }
    & MoneyFieldsFragment
  ) }
);


export type SepaTransferPaymentDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PaymentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PaymentQuery = (
  { __typename?: 'Query' }
  & { payment?: Maybe<(
    { __typename: 'SepaTransferPayment' }
    & SepaTransferPaymentDataFragment
  )> }
);

export type SepaTransferXmlQueryVariables = Exact<{
  paymentId: Scalars['ID']['input'];
}>;


export type SepaTransferXmlQuery = (
  { __typename?: 'Query' }
  & { sepaTransferXml?: Maybe<(
    { __typename: 'SepaXml' }
    & Pick<SepaXml, 'currency' | 'fileName' | 'content'>
  ) | (
    { __typename: 'SepaXmlValidationError' }
    & Pick<SepaXmlValidationError, 'message'>
  )> }
);

export type GenerateSepaTransferXmlMutationVariables = Exact<{
  input: SepaXmlInput;
}>;


export type GenerateSepaTransferXmlMutation = (
  { __typename?: 'Mutation' }
  & { generateSepaTransferXml: (
    { __typename?: 'SepaXml' }
    & Pick<SepaXml, 'currency' | 'fileName' | 'content'>
  ) | (
    { __typename?: 'SepaXmlValidationError' }
    & Pick<SepaXmlValidationError, 'message'>
  ) }
);

export type ActivatePaymentMutationVariables = Exact<{
  startDate: Scalars['Date']['input'];
}>;


export type ActivatePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activatePayment'>
);

export type DeactivatePaymentMutationVariables = Exact<{ [key: string]: never; }>;


export type DeactivatePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivatePayment'>
);

export type UpdatePaymentStartDateMutationVariables = Exact<{
  startDate: Scalars['Date']['input'];
}>;


export type UpdatePaymentStartDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePaymentStartDate'>
);

export type UpdateExportConfigurationMutationVariables = Exact<{
  datevSettings: DatevSettingsInput;
}>;


export type UpdateExportConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateExportConfiguration'>
);

export type GetPayableDocumentsInfoQueryVariables = Exact<{
  hasIban?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetPayableDocumentsInfoQuery = (
  { __typename?: 'Query' }
  & { getPayableDocumentsInfo: (
    { __typename?: 'PayableDocumentsResponse' }
    & Pick<PayableDocumentsResponse, 'euValidIbanDocumentsCount' | 'gbValidIbanDocumentsCount'>
  ) }
);

export type GetPayableAmountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayableAmountsQuery = (
  { __typename?: 'Query' }
  & { getPayableAmounts?: Maybe<(
    { __typename?: 'PayableAmounts' }
    & { totalPayableAmount?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFieldsFragment
    )>, discountedAmount?: Maybe<(
      { __typename?: 'Money' }
      & MoneyFieldsFragment
    )> }
  )> }
);

export type PayableDocumentNewEsDataFragment = (
  { __typename?: 'PayableDocumentEs' }
  & Pick<PayableDocumentEs, 'id' | 'globalDocumentId' | 'status' | 'iban' | 'invoiceNumber' | 'isSEPAExportable' | 'isPayable' | 'invoiceDate' | 'createdAt' | 'updatedAt' | 'invoiceDueDate' | 'discountPercentage' | 'discountPaymentDate'>
  & { contact?: Maybe<(
    { __typename?: 'PayableDocumentContact' }
    & Pick<PayableDocumentContact, 'id' | 'name'>
  )>, bookings?: Maybe<Array<Maybe<(
    { __typename?: 'PayableDocumentBooking' }
    & Pick<PayableDocumentBooking, 'artistSocialInsuranceCode' | 'dueDate' | 'discountAmount' | 'discountPercentage' | 'discountPaymentDate' | 'paymentConditionId'>
  )>>>, amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, discountAmount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )> }
);


export type PayableDocumentNewEsDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PayableDocumentsEsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<PayableDocumentsSortInput>;
}>;


export type PayableDocumentsEsQuery = (
  { __typename?: 'Query' }
  & { payableDocumentsEs: (
    { __typename: 'PayableDocumentsEsResponse' }
    & { edges: Array<(
      { __typename: 'PayableDocumentEsEdge' }
      & Pick<PayableDocumentEsEdge, 'cursor'>
      & { node: (
        { __typename?: 'PayableDocumentEs' }
        & PayableDocumentNewEsDataFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'totalCount' | 'hasNextPage'>
    ) }
  ) }
);

export type ExpenseItemBookingDataFragment = (
  { __typename?: 'ExpenseItemBooking' }
  & Pick<ExpenseItemBooking, 'artistSocialInsuranceCode' | 'amount' | 'vatRate' | 'note' | 'postingText' | 'taxAmount' | 'netAmount'>
  & { costCenter?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'readableName'>
  )>, costObject?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'readableName'>
  )>, extraCostInfo?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'readableName'>
  )>, generalLedgerAccount?: Maybe<(
    { __typename?: 'BookingAccount' }
    & Pick<BookingAccount, 'readableName'>
  )>, bookingKey?: Maybe<(
    { __typename?: 'BookingKey' }
    & Pick<BookingKey, 'readableName'>
  )> }
);


export type ExpenseItemBookingDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseTitleUpdatedEventDataFragment = (
  { __typename?: 'ReimbursementCaseTitleUpdatedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseTitleUpdated' }
    & Pick<ActivityReimbursementCaseTitleUpdated, 'oldTitle' | 'newTitle'>
  ) }
);


export type ReimbursementCaseTitleUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemTitleUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemTitleUpdatedEvent' }
  & Pick<ExpenseItemTitleUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemTitleUpdated' }
    & Pick<ActivityExpenseItemTitleUpdated, 'oldTitle' | 'newTitle'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemTitleUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseAmendmentRequestedEventDataFragment = (
  { __typename?: 'ReimbursementCaseAmendmentRequestedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseAmendmentRequested' }
    & Pick<ActivityReimbursementCaseAmendmentRequested, 'requestedAt'>
    & { comment: (
      { __typename?: 'ActivityComment' }
      & Pick<ActivityComment, 'id' | 'text'>
      & { author?: Maybe<(
        { __typename?: 'ActivityUser' }
        & Pick<ActivityUser, 'name'>
      )> }
    ) }
  ) }
);


export type ReimbursementCaseAmendmentRequestedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseApprovedEventDataFragment = (
  { __typename?: 'ReimbursementCaseApprovedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseApproved' }
    & Pick<ActivityReimbursementCaseApproved, 'approvedAt'>
  ) }
);


export type ReimbursementCaseApprovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseCreatedEventDataFragment = (
  { __typename?: 'ReimbursementCaseCreatedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseCreated' }
    & Pick<ActivityReimbursementCaseCreated, 'createdAt'>
  ) }
);


export type ReimbursementCaseCreatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseSubmittedForReviewEventDataFragment = (
  { __typename?: 'ReimbursementCaseSubmittedForReviewEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseSubmittedForReview' }
    & Pick<ActivityReimbursementCaseSubmittedForReview, 'submittedAt'>
  ) }
);


export type ReimbursementCaseSubmittedForReviewEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseTargetMembershipUpdatedEventDataFragment = (
  { __typename?: 'ReimbursementCaseTargetMembershipUpdatedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseTargetMembershipUpdated' }
    & { oldTargetMembership?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'name'>
    )>, newTargetMembership?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'name'>
    )> }
  ) }
);


export type ReimbursementCaseTargetMembershipUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseNoteUpdatedEventDataFragment = (
  { __typename?: 'ReimbursementCaseNoteUpdatedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseNoteUpdated' }
    & Pick<ActivityReimbursementCaseNoteUpdated, 'oldNote' | 'newNote'>
  ) }
);


export type ReimbursementCaseNoteUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemTotalAmountUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemTotalAmountUpdatedEvent' }
  & Pick<ExpenseItemTotalAmountUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemTotalAmountUpdated' }
    & Pick<ActivityExpenseItemTotalAmountUpdated, 'oldTotalAmount' | 'newTotalAmount' | 'oldCurrency' | 'newCurrency'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemTotalAmountUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemFileAddedEventDataFragment = (
  { __typename?: 'ExpenseItemFileAddedEvent' }
  & Pick<ExpenseItemFileAddedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemFileAdded' }
    & Pick<ActivityExpenseItemFileAdded, 'fileId'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )>, clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )> }
);


export type ExpenseItemFileAddedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemFileRemovedEventDataFragment = (
  { __typename?: 'ExpenseItemFileRemovedEvent' }
  & Pick<ExpenseItemFileRemovedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemFileRemoved' }
    & Pick<ActivityExpenseItemFileRemoved, 'fileId'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemFileRemovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemExpenseDateUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemExpenseDateUpdatedEvent' }
  & Pick<ExpenseItemExpenseDateUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemExpenseDateUpdated' }
    & Pick<ActivityExpenseItemExpenseDateUpdated, 'oldExpenseDate' | 'newExpenseDate'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemExpenseDateUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseCommentAddedEventDataFragment = (
  { __typename?: 'ReimbursementCaseCommentAddedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseCommentAdded' }
    & { comment: (
      { __typename?: 'ActivityComment' }
      & Pick<ActivityComment, 'text'>
    ) }
  ) }
);


export type ReimbursementCaseCommentAddedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemArchivedEventDataFragment = (
  { __typename?: 'ExpenseItemArchivedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemArchived' }
    & Pick<ActivityExpenseItemArchived, 'archivedAt'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemArchivedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemHospitalityExternalGuestsUpdatedEvent' }
  & Pick<ExpenseItemHospitalityExternalGuestsUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemHospitalityExternalGuestsUpdated' }
    & Pick<ActivityExpenseItemHospitalityExternalGuestsUpdated, 'oldExternalGuests' | 'newExternalGuests'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemHospitalityInternalGuestsUpdatedEvent' }
  & Pick<ExpenseItemHospitalityInternalGuestsUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemHospitalityInternalGuestsUpdated' }
    & Pick<ActivityExpenseItemHospitalityInternalGuestsUpdated, 'oldInternalGuests' | 'newInternalGuests'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title'>
  )> }
);


export type ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemHospitalityLocationUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemHospitalityLocationUpdatedEvent' }
  & Pick<ExpenseItemHospitalityLocationUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemHospitalityLocationUpdated' }
    & Pick<ActivityExpenseItemHospitalityLocationUpdated, 'oldLocation' | 'newLocation'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemHospitalityLocationUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemHospitalityReceiptAmountUpdatedEvent' }
  & Pick<ExpenseItemHospitalityReceiptAmountUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemHospitalityReceiptAmountUpdated' }
    & Pick<ActivityExpenseItemHospitalityReceiptAmountUpdated, 'oldReceiptAmount' | 'newReceiptAmount' | 'oldCurrency' | 'newCurrency'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemHospitalityTipAmountUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemHospitalityTipAmountUpdatedEvent' }
  & Pick<ExpenseItemHospitalityTipAmountUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemHospitalityTipAmountUpdated' }
    & Pick<ActivityExpenseItemHospitalityTipAmountUpdated, 'oldTipAmount' | 'newTipAmount' | 'oldCurrency' | 'newCurrency'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemHospitalityTipAmountUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemBookingAddedEventDataFragment = (
  { __typename?: 'ExpenseItemBookingAddedEvent' }
  & Pick<ExpenseItemBookingAddedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemBookingAdded' }
    & Pick<ActivityExpenseItemBookingAdded, 'currency'>
    & { booking?: Maybe<(
      { __typename?: 'ExpenseItemBooking' }
      & ExpenseItemBookingDataFragment
    )> }
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemBookingAddedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemBookingRemovedEventDataFragment = (
  { __typename?: 'ExpenseItemBookingRemovedEvent' }
  & Pick<ExpenseItemBookingRemovedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemBookingRemoved' }
    & { booking?: Maybe<(
      { __typename?: 'ExpenseItemBooking' }
      & ExpenseItemBookingDataFragment
    )> }
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemBookingRemovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemBookingUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemBookingUpdatedEvent' }
  & Pick<ExpenseItemBookingUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemBookingUpdated' }
    & Pick<ActivityExpenseItemBookingUpdated, 'currency'>
    & { newBooking?: Maybe<(
      { __typename?: 'ExpenseItemBooking' }
      & ExpenseItemBookingDataFragment
    )>, oldBooking?: Maybe<(
      { __typename?: 'ExpenseItemBooking' }
      & ExpenseItemBookingDataFragment
    )> }
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemBookingUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseResetApprovalEventDataFragment = (
  { __typename?: 'ReimbursementCaseResetApprovalEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseResetApproval' }
    & Pick<ActivityReimbursementCaseResetApproval, 'resetAt'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ReimbursementCaseResetApprovalEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseItemAddedEventDataFragment = (
  { __typename?: 'ReimbursementCaseItemAddedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseItemAdded' }
    & Pick<ActivityReimbursementCaseItemAdded, 'expenseItemType'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ReimbursementCaseItemAddedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseItemRemovedEventDataFragment = (
  { __typename?: 'ReimbursementCaseItemRemovedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseItemRemoved' }
    & Pick<ActivityReimbursementCaseItemRemoved, 'expenseItemType'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ReimbursementCaseItemRemovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseItemIncludedEventDataFragment = (
  { __typename?: 'ReimbursementCaseItemIncludedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseItemIncluded' }
    & Pick<ActivityReimbursementCaseItemIncluded, 'expenseItemType'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ReimbursementCaseItemIncludedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseItemExcludedEventDataFragment = (
  { __typename?: 'ReimbursementCaseItemExcludedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseItemExcluded' }
    & Pick<ActivityReimbursementCaseItemExcluded, 'expenseItemType'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ReimbursementCaseItemExcludedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemInvoiceNumberUpdatedEventDataFragment = (
  { __typename?: 'ExpenseItemInvoiceNumberUpdatedEvent' }
  & Pick<ExpenseItemInvoiceNumberUpdatedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityExpenseItemInvoiceNumberUpdated' }
    & Pick<ActivityExpenseItemInvoiceNumberUpdated, 'newInvoiceNumber'>
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemInvoiceNumberUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseItemExportStatusUpdatedEventDataFragment = (
  { __typename?: 'ReimbursementCaseItemExportStatusUpdatedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseItemExportStatusUpdated' }
    & Pick<ActivityReimbursementCaseItemExportStatusUpdated, 'exportGuid' | 'exportType'>
    & { failedItems?: Maybe<Array<Maybe<(
      { __typename?: 'ExportStatusFailedItem' }
      & Pick<ExportStatusFailedItem, 'reimbursementItemId' | 'reimbursementItemTitle' | 'reimbursementItemType'>
    )>>>, successfulItems?: Maybe<Array<Maybe<(
      { __typename?: 'ExportStatusSuccessfulItem' }
      & Pick<ExportStatusSuccessfulItem, 'reimbursementItemId' | 'reimbursementItemTitle' | 'reimbursementItemType'>
      & { datevLocationParams?: Maybe<(
        { __typename?: 'ReimbursementCaseItemDatevLocationParams' }
        & Pick<ReimbursementCaseItemDatevLocationParams, 'datevClientNumber' | 'datevConsultantNumber' | 'entityExportGuid'>
      )> }
    )>>> }
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ReimbursementCaseItemExportStatusUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseSubmittedForApprovalEventDataFragment = (
  { __typename?: 'ReimbursementCaseSubmittedForApprovalEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseSubmittedForApproval' }
    & Pick<ActivityReimbursementCaseSubmittedForApproval, 'submittedAt'>
    & { approvers?: Maybe<Array<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'name'>
    )>> }
  ) }
);


export type ReimbursementCaseSubmittedForApprovalEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseTriggeredWorkflowEventDataFragment = (
  { __typename?: 'ReimbursementCaseTriggeredWorkflowEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseTriggeredWorkflow' }
    & Pick<ActivityReimbursementCaseTriggeredWorkflow, 'submittedAt'>
    & { workflow: (
      { __typename?: 'ActivityWorkflow' }
      & Pick<ActivityWorkflow, 'name' | 'description'>
    ) }
  ) }
);


export type ReimbursementCaseTriggeredWorkflowEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseWorkflowStepApprovedEventDataFragment = (
  { __typename?: 'ReimbursementCaseWorkflowStepApprovedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseWorkflowStepApproved' }
    & Pick<ActivityReimbursementCaseWorkflowStepApproved, 'approvedAt'>
    & { approvedBy: (
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'name'>
    ) }
  ) }
);


export type ReimbursementCaseWorkflowStepApprovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseWorkflowStepRejectedEventDataFragment = (
  { __typename?: 'ReimbursementCaseWorkflowStepRejectedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), properties: (
    { __typename?: 'ActivityReimbursementCaseWorkflowStepRejected' }
    & Pick<ActivityReimbursementCaseWorkflowStepRejected, 'rejectedAt'>
    & { rejectedBy: (
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'name'>
    ) }
  ) }
);


export type ReimbursementCaseWorkflowStepRejectedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseRejectedEventDataFragment = (
  { __typename?: 'ReimbursementCaseRejectedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, properties: (
    { __typename?: 'ActivityReimbursementCaseRejected' }
    & Pick<ActivityReimbursementCaseRejected, 'rejectedAt'>
    & { rejectedBy: (
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'name'>
    ), comment: (
      { __typename?: 'ActivityComment' }
      & Pick<ActivityComment, 'text'>
    ) }
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ReimbursementCaseRejectedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemHospitalityDataExtractedEventDataFragment = (
  { __typename?: 'ExpenseItemHospitalityDataExtractedEvent' }
  & Pick<ExpenseItemHospitalityDataExtractedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, properties: (
    { __typename?: 'ActivityExpenseItemHospitalityDataExtracted' }
    & Pick<ActivityExpenseItemHospitalityDataExtracted, 'invoiceNumber' | 'expenseDate' | 'sourceFileId' | 'location'>
    & { receiptAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, tipAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )> }
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemHospitalityDataExtractedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExpenseItemGeneralDataExtractedEventDataFragment = (
  { __typename?: 'ExpenseItemGeneralDataExtractedEvent' }
  & Pick<ExpenseItemGeneralDataExtractedEvent, 'resourceId'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, properties: (
    { __typename?: 'ActivityExpenseItemGeneralDataExtracted' }
    & Pick<ActivityExpenseItemGeneralDataExtracted, 'invoiceNumber' | 'expenseDate' | 'sourceFileId'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )> }
  ), reimbursementItemMetadata?: Maybe<(
    { __typename?: 'ReimbursementItemMetadata' }
    & Pick<ReimbursementItemMetadata, 'title' | 'type'>
  )> }
);


export type ExpenseItemGeneralDataExtractedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseWorkflowStepSkippedEventDataFragment = (
  { __typename?: 'ReimbursementCaseWorkflowStepSkippedEvent' }
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, properties: (
    { __typename?: 'ActivityReimbursementCaseWorkflowStepSkipped' }
    & Pick<ActivityReimbursementCaseWorkflowStepSkipped, 'skippedAt'>
    & { workflowStep?: Maybe<(
      { __typename?: 'ActivityWorkflowStep' }
      & { conditions?: Maybe<Array<(
        { __typename?: 'WorkflowStepCondition' }
        & Pick<WorkflowStepCondition, 'field' | 'operator' | 'values'>
      )>> }
    )>, skippedApprovers?: Maybe<Array<Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'id' | 'name'>
    )>>> }
  ) }
);


export type ReimbursementCaseWorkflowStepSkippedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementItemsListGeneralExpenseDataFragment = (
  { __typename?: 'GeneralExpenseItem' }
  & Pick<GeneralExpenseItem, 'id' | 'type' | 'status' | 'title' | 'updatedAt' | 'extractedDataAccepted'>
  & { totalAmount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, errors: Array<(
    { __typename?: 'ReimbursementError' }
    & Pick<ReimbursementError, 'field' | 'errors'>
  )> }
);


export type ReimbursementItemsListGeneralExpenseDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementItemsListHospitalityExpenseDataFragment = (
  { __typename?: 'HospitalityExpenseItem' }
  & Pick<HospitalityExpenseItem, 'id' | 'type' | 'status' | 'title' | 'updatedAt' | 'extractedDataAccepted'>
  & { totalAmount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, errors: Array<(
    { __typename?: 'ReimbursementError' }
    & Pick<ReimbursementError, 'field' | 'errors'>
  )> }
);


export type ReimbursementItemsListHospitalityExpenseDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementItemsListPerDiemDataFragment = (
  { __typename?: 'PerDiemItem' }
  & Pick<PerDiemItem, 'id' | 'type' | 'status' | 'currentStep' | 'updatedAt'>
  & { totalAmount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, errors: Array<(
    { __typename?: 'ReimbursementError' }
    & Pick<ReimbursementError, 'field' | 'errors'>
  )> }
);


export type ReimbursementItemsListPerDiemDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementItemsListMileageDataFragment = (
  { __typename?: 'MileageReimbursementItem' }
  & Pick<MileageReimbursementItem, 'id' | 'type' | 'status' | 'updatedAt'>
  & { totalAmount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )> }
);


export type ReimbursementItemsListMileageDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CreateReimbursementCaseMutationVariables = Exact<{
  input: CreateReimbursementCaseInput;
}>;


export type CreateReimbursementCaseMutation = (
  { __typename?: 'Mutation' }
  & { createReimbursementCase: (
    { __typename?: 'ReimbursementCase' }
    & Pick<ReimbursementCase, 'id'>
  ) }
);

export type UpdateReimbursementCaseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateReimbursementCaseInput;
}>;


export type UpdateReimbursementCaseMutation = (
  { __typename?: 'Mutation' }
  & { updateReimbursementCase: (
    { __typename?: 'ReimbursementCase' }
    & Pick<ReimbursementCase, 'id'>
  ) }
);

export type ArchiveOrDeleteReimbursementCaseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchiveOrDeleteReimbursementCaseMutation = (
  { __typename?: 'Mutation' }
  & { archiveOrDeleteReimbursementCase: (
    { __typename?: 'ArchiveOrDeleteReimbursementCaseResponse' }
    & Pick<ArchiveOrDeleteReimbursementCaseResponse, 'deleted' | 'archived'>
  ) }
);

export type GetFileUploadUrlMutationVariables = Exact<{
  params: FileUploadParams;
}>;


export type GetFileUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { getFileUploadUrl: (
    { __typename?: 'FileUpload' }
    & Pick<FileUpload, 'id' | 'url'>
    & { file: (
      { __typename?: 'UploadedFile' }
      & Pick<UploadedFile, 'id' | 'name' | 'url'>
    ), postOptions?: Maybe<Array<(
      { __typename?: 'FileUploadPostOption' }
      & Pick<FileUploadPostOption, 'field' | 'value'>
    )>> }
  ) }
);

export type CreateHospitalityExpenseItemMutationVariables = Exact<{
  input: HospitalityExpenseItemInput;
}>;


export type CreateHospitalityExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & { createHospitalityExpenseItem: (
    { __typename?: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id'>
  ) }
);

export type UpdateHospitalityExpenseItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: HospitalityExpenseItemInput;
}>;


export type UpdateHospitalityExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & { updateHospitalityExpenseItem: (
    { __typename?: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id'>
  ) }
);

export type ExtractHospitalityExpenseInvoiceDataMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: HospitalityExpenseItemInput;
}>;


export type ExtractHospitalityExpenseInvoiceDataMutation = (
  { __typename?: 'Mutation' }
  & { extractHospitalityExpenseInvoiceData: (
    { __typename?: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id' | 'extractedDataAccepted'>
    & { extractedData?: Maybe<Array<(
      { __typename?: 'HospitalityExpenseItemExtraction' }
      & Pick<HospitalityExpenseItemExtraction, 'invoiceNumber' | 'expenseDate' | 'location'>
      & { receiptAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      )>, tipAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      )> }
    )>> }
  ) }
);

export type UpdateGeneralExpenseItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: GeneralExpenseItemInput;
}>;


export type UpdateGeneralExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & { updateGeneralExpenseItem: (
    { __typename?: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id'>
  ) }
);

export type ExtractGeneralExpenseInvoiceDataMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: GeneralExpenseItemInput;
}>;


export type ExtractGeneralExpenseInvoiceDataMutation = (
  { __typename?: 'Mutation' }
  & { extractGeneralExpenseInvoiceData: (
    { __typename?: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id' | 'extractedDataAccepted'>
    & { extractedData?: Maybe<Array<(
      { __typename?: 'GeneralExpenseItemExtraction' }
      & Pick<GeneralExpenseItemExtraction, 'invoiceNumber' | 'expenseDate'>
      & { totalAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      )> }
    )>> }
  ) }
);

export type DeleteReimbursementItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteReimbursementItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteReimbursementItem: (
    { __typename?: 'DeleteReimbursementItemResponse' }
    & Pick<DeleteReimbursementItemResponse, 'deleted'>
  ) }
);

export type CreateGeneralExpenseItemMutationVariables = Exact<{
  input: GeneralExpenseItemInput;
}>;


export type CreateGeneralExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & { createGeneralExpenseItem: (
    { __typename?: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id'>
  ) }
);

export type SubmitReimbursementCaseForReviewMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubmitReimbursementCaseForReviewMutation = (
  { __typename?: 'Mutation' }
  & { submitReimbursementCaseForReview: (
    { __typename?: 'ReimbursementCaseStateChangeResponse' }
    & Pick<ReimbursementCaseStateChangeResponse, 'success'>
    & { errors: (
      { __typename?: 'ReimbursementCaseAndItemErrors' }
      & { reimbursementCaseErrors?: Maybe<(
        { __typename?: 'ReimbursementCaseErrors' }
        & Pick<ReimbursementCaseErrors, 'id' | 'title'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>, reimbursementItemsErrors?: Maybe<Array<(
        { __typename?: 'ReimbursementItemErrors' }
        & Pick<ReimbursementItemErrors, 'id' | 'title' | 'type'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>> }
    ) }
  ) }
);

export type FastApproveReimbursementCaseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FastApproveReimbursementCaseMutation = (
  { __typename?: 'Mutation' }
  & { fastApproveReimbursementCase: (
    { __typename?: 'ReimbursementCaseStateChangeResponse' }
    & Pick<ReimbursementCaseStateChangeResponse, 'success'>
    & { errors: (
      { __typename?: 'ReimbursementCaseAndItemErrors' }
      & { reimbursementCaseErrors?: Maybe<(
        { __typename?: 'ReimbursementCaseErrors' }
        & Pick<ReimbursementCaseErrors, 'id' | 'title'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>, reimbursementItemsErrors?: Maybe<Array<(
        { __typename?: 'ReimbursementItemErrors' }
        & Pick<ReimbursementItemErrors, 'id' | 'title' | 'type'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>> }
    ) }
  ) }
);

export type RequestReimbursementCaseAmendmentMutationVariables = Exact<{
  input: RequestReimbursementCaseAmendmentInput;
}>;


export type RequestReimbursementCaseAmendmentMutation = (
  { __typename?: 'Mutation' }
  & { requestReimbursementCaseAmendment: (
    { __typename?: 'ReimbursementCaseStateChangeResponse' }
    & Pick<ReimbursementCaseStateChangeResponse, 'success'>
  ) }
);

export type CreateCommentOnReimbursementCaseMutationVariables = Exact<{
  input: CreateCommentInput;
}>;


export type CreateCommentOnReimbursementCaseMutation = (
  { __typename?: 'Mutation' }
  & { createCommentOnReimbursementCase: (
    { __typename?: 'CommentCreated' }
    & Pick<CommentCreated, 'commentId'>
  ) | (
    { __typename?: 'CommentCreatedError' }
    & Pick<CommentCreatedError, 'message' | 'kind'>
  ) }
);

export type ResetReimbursementCaseApprovalMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ResetReimbursementCaseApprovalMutation = (
  { __typename?: 'Mutation' }
  & { resetReimbursementCaseApproval: (
    { __typename?: 'ReimbursementCaseStateChangeResponse' }
    & Pick<ReimbursementCaseStateChangeResponse, 'success'>
  ) }
);

export type SubmitReimbursementCaseForApprovalMutationVariables = Exact<{
  input: SubmitReimbursementCaseForApprovalInput;
}>;


export type SubmitReimbursementCaseForApprovalMutation = (
  { __typename?: 'Mutation' }
  & { submitReimbursementCaseForApproval: (
    { __typename?: 'ReimbursementCaseStateChangeResponse' }
    & Pick<ReimbursementCaseStateChangeResponse, 'success'>
    & { errors: (
      { __typename?: 'ReimbursementCaseAndItemErrors' }
      & { reimbursementCaseErrors?: Maybe<(
        { __typename?: 'ReimbursementCaseErrors' }
        & Pick<ReimbursementCaseErrors, 'id' | 'title'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>, reimbursementItemsErrors?: Maybe<Array<(
        { __typename?: 'ReimbursementItemErrors' }
        & Pick<ReimbursementItemErrors, 'id' | 'title' | 'type'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>> }
    ) }
  ) }
);

export type SubmitReimbursementCaseWorkflowStepMutationVariables = Exact<{
  input: SubmitReimbursementCaseWorkflowStepInput;
}>;


export type SubmitReimbursementCaseWorkflowStepMutation = (
  { __typename?: 'Mutation' }
  & { submitReimbursementCaseWorkflowStep: (
    { __typename?: 'ReimbursementCaseStateChangeResponse' }
    & Pick<ReimbursementCaseStateChangeResponse, 'success'>
    & { errors: (
      { __typename?: 'ReimbursementCaseAndItemErrors' }
      & { reimbursementCaseErrors?: Maybe<(
        { __typename?: 'ReimbursementCaseErrors' }
        & Pick<ReimbursementCaseErrors, 'id' | 'title'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>, reimbursementItemsErrors?: Maybe<Array<(
        { __typename?: 'ReimbursementItemErrors' }
        & Pick<ReimbursementItemErrors, 'id' | 'title' | 'type'>
        & { errors: Array<(
          { __typename?: 'ReimbursementError' }
          & Pick<ReimbursementError, 'errors' | 'field'>
        )> }
      )>> }
    ) }
  ) }
);

export type CreatePerDiemItemMutationVariables = Exact<{
  input: CreatePerDiemItemInput;
}>;


export type CreatePerDiemItemMutation = (
  { __typename?: 'Mutation' }
  & { createPerDiemItem: (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id'>
  ) }
);

export type UpdatePerDiemItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePerDiemItemInput;
}>;


export type UpdatePerDiemItemMutation = (
  { __typename?: 'Mutation' }
  & { updatePerDiemItem: (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id'>
    & { days: Array<(
      { __typename?: 'PerDiemDay' }
      & Pick<PerDiemDay, 'id' | 'date' | 'providedBreakfast' | 'providedLunch' | 'providedDinner'>
    )> }
  ) }
);

export type CreateMileageReimbursementItemMutationVariables = Exact<{
  input: MileageReimbursementItemInput;
}>;


export type CreateMileageReimbursementItemMutation = (
  { __typename?: 'Mutation' }
  & { createMileageReimbursementItem: (
    { __typename?: 'MileageReimbursementItem' }
    & Pick<MileageReimbursementItem, 'id'>
  ) }
);

export type AddPerDiemSegmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePerDiemItemInput;
}>;


export type AddPerDiemSegmentMutation = (
  { __typename?: 'Mutation' }
  & { addPerDiemSegment: (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id'>
  ) }
);

export type EditPerDiemSegmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdatePerDiemItemInput;
}>;


export type EditPerDiemSegmentMutation = (
  { __typename?: 'Mutation' }
  & { editPerDiemSegment: (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id'>
  ) }
);

export type ReimbursementCaseDataFragment = (
  { __typename?: 'ReimbursementCase' }
  & Pick<ReimbursementCase, 'id' | 'organizationId' | 'createdAt' | 'updatedAt' | 'status' | 'createTransfer' | 'isPaid' | 'paidAt' | 'title' | 'note' | 'advancedAccountingEnabled'>
  & { contact?: Maybe<(
    { __typename?: 'ReimbursementCaseContact' }
    & Pick<ReimbursementCaseContact, 'contactId' | 'iban'>
  )>, createdByMembership: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ), targetMembership?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  )>, totalAmount: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ), suggestedCostCenter?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'id' | 'readableName'>
  )>, suggestedCostObject?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'id' | 'readableName'>
  )>, suggestedGeneralLedgerAccount?: Maybe<(
    { __typename?: 'BookingAccount' }
    & Pick<BookingAccount, 'id' | 'readableName'>
  )>, errors: Array<(
    { __typename?: 'ReimbursementError' }
    & Pick<ReimbursementError, 'field' | 'errors'>
  )> }
);


export type ReimbursementCaseDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ArchiveReimbursementCasesListItemDataFragment = (
  { __typename?: 'ArchiveReimbursementCasesListItem' }
  & Pick<ArchiveReimbursementCasesListItem, 'id' | 'organizationId' | 'createdAt' | 'status' | 'title'>
  & { totalAmount: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ) }
);


export type ArchiveReimbursementCasesListItemDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReimbursementCaseByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReimbursementCaseByIdQuery = (
  { __typename?: 'Query' }
  & { reimbursementCaseById: (
    { __typename?: 'ReimbursementCase' }
    & ReimbursementCaseDataFragment
  ) }
);

export type ArchiveReimbursementCasesCountQueryVariables = Exact<{
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
}>;


export type ArchiveReimbursementCasesCountQuery = (
  { __typename?: 'Query' }
  & { archiveReimbursementCasesCount: (
    { __typename?: 'ReimbursementCasesCountResponse' }
    & Pick<ReimbursementCasesCountResponse, 'totalCount'>
  ) }
);

export type InboxReimbursementCasesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type InboxReimbursementCasesCountQuery = (
  { __typename?: 'Query' }
  & { inboxReimbursementCasesCount: (
    { __typename?: 'ReimbursementCasesCountResponse' }
    & Pick<ReimbursementCasesCountResponse, 'totalCount'>
  ) }
);

export type ReimbursementItemsQueryVariables = Exact<{
  filters: ReimbursementItemFiltersInput;
}>;


export type ReimbursementItemsQuery = (
  { __typename?: 'Query' }
  & { reimbursementItems: Array<(
    { __typename?: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id' | 'reimbursementCaseId' | 'createdAt' | 'updatedAt' | 'type' | 'status' | 'title' | 'expenseDate' | 'invoiceNumber' | 'extractedDataAccepted'>
    & { files?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'url' | 'name'>
    )>>, totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, extractedData?: Maybe<Array<(
      { __typename?: 'GeneralExpenseItemExtraction' }
      & Pick<GeneralExpenseItemExtraction, 'expenseDate' | 'invoiceNumber'>
      & { sourceFile: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'url' | 'name'>
      ), totalAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      )> }
    )>> }
  ) | (
    { __typename?: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id' | 'reimbursementCaseId' | 'createdAt' | 'updatedAt' | 'type' | 'status' | 'title' | 'expenseDate' | 'location' | 'invoiceNumber' | 'internalGuests' | 'externalGuests' | 'extractedDataAccepted'>
    & { files?: Maybe<Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'url'>
    )>>, totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, receiptAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, tipAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, extractedData?: Maybe<Array<(
      { __typename?: 'HospitalityExpenseItemExtraction' }
      & Pick<HospitalityExpenseItemExtraction, 'expenseDate' | 'invoiceNumber' | 'location'>
      & { sourceFile: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'url' | 'name'>
      ), receiptAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      )>, tipAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      )> }
    )>> }
  ) | { __typename?: 'MileageReimbursementItem' } | (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id' | 'type' | 'status' | 'currentStep'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, segments?: Maybe<Array<(
      { __typename?: 'PerDiemSegment' }
      & Pick<PerDiemSegment, 'id' | 'startAt' | 'endAt'>
      & { location?: Maybe<(
        { __typename?: 'PerDiemLocation' }
        & Pick<PerDiemLocation, 'id'>
      )> }
    )>>, days: Array<(
      { __typename?: 'PerDiemDay' }
      & Pick<PerDiemDay, 'id' | 'date' | 'providedBreakfast' | 'providedLunch' | 'providedDinner'>
    )> }
  )> }
);

export type ReimbursementItemsListQueryVariables = Exact<{
  filters: ReimbursementItemFiltersInput;
}>;


export type ReimbursementItemsListQuery = (
  { __typename?: 'Query' }
  & { reimbursementItemsList: Array<(
    { __typename?: 'GeneralExpenseItem' }
    & ReimbursementItemsListGeneralExpenseDataFragment
  ) | (
    { __typename?: 'HospitalityExpenseItem' }
    & ReimbursementItemsListHospitalityExpenseDataFragment
  ) | (
    { __typename?: 'MileageReimbursementItem' }
    & ReimbursementItemsListMileageDataFragment
  ) | (
    { __typename?: 'PerDiemItem' }
    & ReimbursementItemsListPerDiemDataFragment
  )> }
);

export type ReimbursementsCursorBasedNavigationQueryVariables = Exact<{
  next?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
  searchInput?: InputMaybe<SearchInput>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReimbursementsCursorBasedNavigationQuery = (
  { __typename?: 'Query' }
  & { prevReimbursement: (
    { __typename?: 'ArchiveReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ArchiveReimbursementCasesEdge' }
      & Pick<ArchiveReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'ArchiveReimbursementCasesListItem' }
        & Pick<ArchiveReimbursementCasesListItem, 'id'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ) }
  ), nextReimbursement: (
    { __typename?: 'ArchiveReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ArchiveReimbursementCasesEdge' }
      & Pick<ArchiveReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'ArchiveReimbursementCasesListItem' }
        & Pick<ArchiveReimbursementCasesListItem, 'id'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ) }
  ) }
);

export type ReimbursementsInboxNavigationQueryVariables = Exact<{
  next?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
  searchInput?: InputMaybe<SearchInput>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReimbursementsInboxNavigationQuery = (
  { __typename?: 'Query' }
  & { prevReimbursement: (
    { __typename?: 'InboxReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'InboxReimbursementCasesEdge' }
      & Pick<InboxReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'InboxReimbursementCasesListItem' }
        & Pick<InboxReimbursementCasesListItem, 'id'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ) }
  ), nextReimbursement: (
    { __typename?: 'InboxReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'InboxReimbursementCasesEdge' }
      & Pick<InboxReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'InboxReimbursementCasesListItem' }
        & Pick<InboxReimbursementCasesListItem, 'id'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ) }
  ) }
);

export type ReimbursementsApprovalsNavigationQueryVariables = Exact<{
  next?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ReimbursementCasesFilterInput>;
  sorts?: InputMaybe<ReimbursementCasesSortInput>;
  searchInput?: InputMaybe<SearchInput>;
  previous?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReimbursementsApprovalsNavigationQuery = (
  { __typename?: 'Query' }
  & { prevReimbursement: (
    { __typename?: 'ApprovalReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ApprovalReimbursementCasesEdge' }
      & Pick<ApprovalReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'ApprovalReimbursementCasesListItem' }
        & Pick<ApprovalReimbursementCasesListItem, 'id'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ) }
  ), nextReimbursement: (
    { __typename?: 'ApprovalReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ApprovalReimbursementCasesEdge' }
      & Pick<ApprovalReimbursementCasesEdge, 'cursor'>
      & { node: (
        { __typename?: 'ApprovalReimbursementCasesListItem' }
        & Pick<ApprovalReimbursementCasesListItem, 'id'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount' | 'hasNextPage'>
      & { endCursor: ReimbursementCasesPageInfo['nextPageCursor'] }
    ) }
  ) }
);

export type ReimbursementItemsSplitBookingsQueryVariables = Exact<{
  filters: ReimbursementItemFiltersInput;
}>;


export type ReimbursementItemsSplitBookingsQuery = (
  { __typename?: 'Query' }
  & { reimbursementItemsSplitBookings: Array<(
    { __typename?: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id' | 'type' | 'status' | 'title'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, bookings: Array<(
      { __typename?: 'ReimbursementBooking' }
      & Pick<ReimbursementBooking, 'id' | 'artistSocialInsuranceCode' | 'postingText'>
      & { costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & Pick<BookingAccountField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'name' | 'accountCode' | 'readableName'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & Pick<BookingKeyField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'readableName'>
        ) }
      )>, amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )> }
  ) | (
    { __typename?: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id' | 'type' | 'status' | 'title'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, bookings: Array<(
      { __typename?: 'ReimbursementBooking' }
      & Pick<ReimbursementBooking, 'id' | 'artistSocialInsuranceCode' | 'postingText'>
      & { costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & Pick<BookingAccountField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'name' | 'accountCode' | 'readableName'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & Pick<BookingKeyField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'taxCode' | 'readableName'>
        ) }
      )>, amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )> }
  ) | { __typename?: 'MileageReimbursementItem' } | (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id' | 'type' | 'status' | 'title'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, bookings: Array<(
      { __typename?: 'ReimbursementBooking' }
      & Pick<ReimbursementBooking, 'id' | 'artistSocialInsuranceCode' | 'postingText'>
      & { costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & Pick<BookingAccountField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'name' | 'accountCode' | 'readableName'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & Pick<BookingKeyField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'taxCode' | 'readableName'>
        ) }
      )>, amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )> }
  )> }
);

export type ReimbursementItemsSplitBookingsInitialSnapshotQueryVariables = Exact<{
  filters: ReimbursementItemFiltersInput;
}>;


export type ReimbursementItemsSplitBookingsInitialSnapshotQuery = (
  { __typename?: 'Query' }
  & { reimbursementItemsSplitBookingsInitialSnapshot: Array<(
    { __typename?: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id' | 'type' | 'status' | 'title'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, bookings: Array<(
      { __typename?: 'ReimbursementBooking' }
      & Pick<ReimbursementBooking, 'id' | 'artistSocialInsuranceCode' | 'postingText'>
      & { costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & Pick<BookingAccountField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'name' | 'accountCode' | 'readableName'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & Pick<BookingKeyField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'readableName'>
        ) }
      )>, amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )> }
  ) | (
    { __typename?: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id' | 'type' | 'status' | 'title'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, bookings: Array<(
      { __typename?: 'ReimbursementBooking' }
      & Pick<ReimbursementBooking, 'id' | 'artistSocialInsuranceCode' | 'postingText'>
      & { costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & Pick<BookingAccountField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'name' | 'accountCode' | 'readableName'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & Pick<BookingKeyField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'taxCode' | 'readableName'>
        ) }
      )>, amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )> }
  ) | { __typename?: 'MileageReimbursementItem' } | (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id' | 'type' | 'status' | 'title'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )>, bookings: Array<(
      { __typename?: 'ReimbursementBooking' }
      & Pick<ReimbursementBooking, 'id' | 'artistSocialInsuranceCode' | 'postingText'>
      & { costCenter?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, costObject?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, extraCostInfo?: Maybe<(
        { __typename?: 'CostCenterField' }
        & Pick<CostCenterField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'CostCenter' }
          & Pick<CostCenter, 'id' | 'name' | 'code' | 'readableName'>
        ) }
      )>, generalLedgerAccount?: Maybe<(
        { __typename?: 'BookingAccountField' }
        & Pick<BookingAccountField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingAccount' }
          & Pick<BookingAccount, 'id' | 'name' | 'accountCode' | 'readableName'>
        ) }
      )>, bookingKey?: Maybe<(
        { __typename?: 'BookingKeyField' }
        & Pick<BookingKeyField, 'confidence' | 'source'>
        & { value: (
          { __typename?: 'BookingKey' }
          & Pick<BookingKey, 'id' | 'taxCode' | 'readableName'>
        ) }
      )>, amount?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, vatRate?: Maybe<(
        { __typename?: 'FloatField' }
        & Pick<FloatField, 'value' | 'confidence' | 'source'>
      )>, note?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'confidence' | 'source'>
      )> }
    )> }
  )> }
);

export type GetReimbursementHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetReimbursementHistoryQuery = (
  { __typename?: 'Query' }
  & { reimbursementCaseById: (
    { __typename?: 'ReimbursementCase' }
    & Pick<ReimbursementCase, 'id'>
    & { timeline?: Maybe<Array<(
      { __typename: 'ExpenseItemArchivedEvent' }
      & Pick<ExpenseItemArchivedEvent, 'id' | 'createdAt'>
      & ExpenseItemArchivedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemBookingAddedEvent' }
      & Pick<ExpenseItemBookingAddedEvent, 'id' | 'createdAt'>
      & ExpenseItemBookingAddedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemBookingRemovedEvent' }
      & Pick<ExpenseItemBookingRemovedEvent, 'id' | 'createdAt'>
      & ExpenseItemBookingRemovedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemBookingUpdatedEvent' }
      & Pick<ExpenseItemBookingUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemBookingUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemExpenseDateUpdatedEvent' }
      & Pick<ExpenseItemExpenseDateUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemExpenseDateUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemFileAddedEvent' }
      & Pick<ExpenseItemFileAddedEvent, 'id' | 'createdAt'>
      & ExpenseItemFileAddedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemFileRemovedEvent' }
      & Pick<ExpenseItemFileRemovedEvent, 'id' | 'createdAt'>
      & ExpenseItemFileRemovedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemGeneralDataExtractedEvent' }
      & Pick<ExpenseItemGeneralDataExtractedEvent, 'id' | 'createdAt'>
      & ExpenseItemGeneralDataExtractedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemHospitalityDataExtractedEvent' }
      & Pick<ExpenseItemHospitalityDataExtractedEvent, 'id' | 'createdAt'>
      & ExpenseItemHospitalityDataExtractedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemHospitalityExternalGuestsUpdatedEvent' }
      & Pick<ExpenseItemHospitalityExternalGuestsUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemHospitalityInternalGuestsUpdatedEvent' }
      & Pick<ExpenseItemHospitalityInternalGuestsUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemHospitalityLocationUpdatedEvent' }
      & Pick<ExpenseItemHospitalityLocationUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemHospitalityLocationUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemHospitalityReceiptAmountUpdatedEvent' }
      & Pick<ExpenseItemHospitalityReceiptAmountUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemHospitalityTipAmountUpdatedEvent' }
      & Pick<ExpenseItemHospitalityTipAmountUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemHospitalityTipAmountUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemInvoiceNumberUpdatedEvent' }
      & Pick<ExpenseItemInvoiceNumberUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemInvoiceNumberUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemPerDiemMealUpdatedEvent' }
      & Pick<ExpenseItemPerDiemMealUpdatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ExpenseItemTitleUpdatedEvent' }
      & Pick<ExpenseItemTitleUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemTitleUpdatedEventDataFragment
    ) | (
      { __typename: 'ExpenseItemTotalAmountUpdatedEvent' }
      & Pick<ExpenseItemTotalAmountUpdatedEvent, 'id' | 'createdAt'>
      & ExpenseItemTotalAmountUpdatedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseAmendmentRequestedEvent' }
      & Pick<ReimbursementCaseAmendmentRequestedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseAmendmentRequestedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseApprovedEvent' }
      & Pick<ReimbursementCaseApprovedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseApprovedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseCommentAddedEvent' }
      & Pick<ReimbursementCaseCommentAddedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseCommentAddedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseCreatedEvent' }
      & Pick<ReimbursementCaseCreatedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseCreatedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseItemAddedEvent' }
      & Pick<ReimbursementCaseItemAddedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseItemAddedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseItemExcludedEvent' }
      & Pick<ReimbursementCaseItemExcludedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseItemExcludedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseItemExportStatusUpdatedEvent' }
      & Pick<ReimbursementCaseItemExportStatusUpdatedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseItemExportStatusUpdatedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseItemIncludedEvent' }
      & Pick<ReimbursementCaseItemIncludedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseItemIncludedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseItemRemovedEvent' }
      & Pick<ReimbursementCaseItemRemovedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseItemRemovedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseNoteUpdatedEvent' }
      & Pick<ReimbursementCaseNoteUpdatedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseNoteUpdatedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseRejectedEvent' }
      & Pick<ReimbursementCaseRejectedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseRejectedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseResetApprovalEvent' }
      & Pick<ReimbursementCaseResetApprovalEvent, 'id' | 'createdAt'>
      & ReimbursementCaseResetApprovalEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseSubmittedForApprovalEvent' }
      & Pick<ReimbursementCaseSubmittedForApprovalEvent, 'id' | 'createdAt'>
      & ReimbursementCaseSubmittedForApprovalEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseSubmittedForReviewEvent' }
      & Pick<ReimbursementCaseSubmittedForReviewEvent, 'id' | 'createdAt'>
      & ReimbursementCaseSubmittedForReviewEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseTargetMembershipUpdatedEvent' }
      & Pick<ReimbursementCaseTargetMembershipUpdatedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseTargetMembershipUpdatedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseTitleUpdatedEvent' }
      & Pick<ReimbursementCaseTitleUpdatedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseTitleUpdatedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseTriggeredWorkflowEvent' }
      & Pick<ReimbursementCaseTriggeredWorkflowEvent, 'id' | 'createdAt'>
      & ReimbursementCaseTriggeredWorkflowEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseWorkflowStepApprovedEvent' }
      & Pick<ReimbursementCaseWorkflowStepApprovedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseWorkflowStepApprovedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseWorkflowStepRejectedEvent' }
      & Pick<ReimbursementCaseWorkflowStepRejectedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseWorkflowStepRejectedEventDataFragment
    ) | (
      { __typename: 'ReimbursementCaseWorkflowStepSkippedEvent' }
      & Pick<ReimbursementCaseWorkflowStepSkippedEvent, 'id' | 'createdAt'>
      & ReimbursementCaseWorkflowStepSkippedEventDataFragment
    )>> }
  ) }
);

export type ReimbursementCaseErrorsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReimbursementCaseErrorsByIdQuery = (
  { __typename?: 'Query' }
  & { reimbursementCaseErrorsById: (
    { __typename?: 'ReimbursementCase' }
    & Pick<ReimbursementCase, 'id'>
    & { errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )> }
  ) }
);

export type ReimbursementItemsErrorsQueryVariables = Exact<{
  filters: ReimbursementItemFiltersInput;
}>;


export type ReimbursementItemsErrorsQuery = (
  { __typename?: 'Query' }
  & { reimbursementItemsErrors: Array<(
    { __typename?: 'GeneralExpenseItem' }
    & Pick<GeneralExpenseItem, 'id' | 'type' | 'title' | 'status'>
    & { errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )> }
  ) | (
    { __typename?: 'HospitalityExpenseItem' }
    & Pick<HospitalityExpenseItem, 'id' | 'type' | 'title' | 'status'>
    & { errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )> }
  ) | { __typename?: 'MileageReimbursementItem' } | (
    { __typename?: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id' | 'type' | 'title' | 'status'>
    & { errors: Array<(
      { __typename?: 'ReimbursementError' }
      & Pick<ReimbursementError, 'field' | 'errors'>
    )> }
  )> }
);

export type ReimbursementCaseWorkflowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReimbursementCaseWorkflowQuery = (
  { __typename?: 'Query' }
  & { reimbursementCaseWorkflow: (
    { __typename?: 'ReimbursementCase' }
    & { workflow?: Maybe<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'id' | 'description' | 'name'>
      & { workflowTemplate?: Maybe<(
        { __typename?: 'WorkflowTemplate' }
        & Pick<WorkflowTemplate, 'id' | 'name'>
        & { steps?: Maybe<Array<(
          { __typename?: 'WorkflowTemplateStep' }
          & { resolvers?: Maybe<Array<Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          )>>> }
        )>> }
      )>, currentStep?: Maybe<(
        { __typename?: 'DocumentApprovalWorkflowStep' }
        & Pick<DocumentApprovalWorkflowStep, 'id'>
        & { approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>> }
      ) | (
        { __typename?: 'SystemApprovalWorkflowStep' }
        & Pick<SystemApprovalWorkflowStep, 'id'>
        & { approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>> }
      ) | (
        { __typename?: 'UserApprovalWorkflowStep' }
        & Pick<UserApprovalWorkflowStep, 'id'>
        & { approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        )>> }
      )>, steps?: Maybe<Array<(
        { __typename?: 'DocumentApprovalWorkflowStep' }
        & Pick<DocumentApprovalWorkflowStep, 'id' | 'type' | 'name'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type' | 'comment'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          )> }
        )>> }
      ) | (
        { __typename?: 'SystemApprovalWorkflowStep' }
        & Pick<SystemApprovalWorkflowStep, 'id' | 'type' | 'name'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type' | 'comment'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          )> }
        )>> }
      ) | (
        { __typename?: 'UserApprovalWorkflowStep' }
        & Pick<UserApprovalWorkflowStep, 'id' | 'type' | 'name'>
        & { resolution?: Maybe<(
          { __typename?: 'WorkflowStepResolution' }
          & Pick<WorkflowStepResolution, 'type' | 'comment'>
          & { approvers?: Maybe<Array<(
            { __typename?: 'WorkflowUser' }
            & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          )>> }
        )>, approvers?: Maybe<Array<(
          { __typename?: 'WorkflowUser' }
          & Pick<WorkflowUser, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          & { substitute?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
          )> }
        )>> }
      )>> }
    )> }
  ) }
);

export type GetReimbursementCasesSummaryQueryVariables = Exact<{
  targetMembershipIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetReimbursementCasesSummaryQuery = (
  { __typename?: 'Query' }
  & { reimbursementCasesSummary: (
    { __typename?: 'ReimbursementCasesSummary' }
    & ReimbursementCasesSummaryFragmentFragment
  ) }
);

export type ReimbursementCasesSummaryFragmentFragment = (
  { __typename?: 'ReimbursementCasesSummary' }
  & { draft: (
    { __typename?: 'ReimbursementCaseDetails' }
    & Pick<ReimbursementCaseDetails, 'totalCount' | 'withoutInvoiceCount'>
    & { totalAmount: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ) }
  ), open: (
    { __typename?: 'ReimbursementCaseDetails' }
    & Pick<ReimbursementCaseDetails, 'totalCount' | 'olderThan14DaysCount'>
    & { totalAmount: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ) }
  ), closed: (
    { __typename?: 'ReimbursementCaseDetails' }
    & Pick<ReimbursementCaseDetails, 'totalCount' | 'last30DaysCount'>
  ), approved: (
    { __typename?: 'ReimbursementCaseDetails' }
    & Pick<ReimbursementCaseDetails, 'approvedInLast30DaysCount'>
    & { approvedInLast30DaysAmount: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ) }
  ) }
);


export type ReimbursementCasesSummaryFragmentFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetAggregatedReimbursementCasesQueryVariables = Exact<{
  targetMembershipIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  draftStatuses: Array<ReimbursementCaseStatus> | ReimbursementCaseStatus;
  openStatuses: Array<ReimbursementCaseStatus> | ReimbursementCaseStatus;
  closedStatuses: Array<ReimbursementCaseStatus> | ReimbursementCaseStatus;
  sortOrder: ReimbursementCasesSortOrder;
  lastClosedAt: ReimbursementCasesDateFilter;
}>;


export type GetAggregatedReimbursementCasesQuery = (
  { __typename?: 'Query' }
  & { draftCases: (
    { __typename?: 'ArchiveReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ArchiveReimbursementCasesEdge' }
      & { node: (
        { __typename?: 'ArchiveReimbursementCasesListItem' }
        & Pick<ArchiveReimbursementCasesListItem, 'id' | 'title' | 'status'>
        & { totalAmount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount'>
    ) }
  ), openCases: (
    { __typename?: 'ArchiveReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ArchiveReimbursementCasesEdge' }
      & { node: (
        { __typename?: 'ArchiveReimbursementCasesListItem' }
        & Pick<ArchiveReimbursementCasesListItem, 'id' | 'title' | 'status'>
        & { totalAmount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount'>
    ) }
  ), closedCases: (
    { __typename?: 'ArchiveReimbursementCasesResponse' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ArchiveReimbursementCasesEdge' }
      & { node: (
        { __typename?: 'ArchiveReimbursementCasesListItem' }
        & Pick<ArchiveReimbursementCasesListItem, 'id' | 'title' | 'status'>
        & { totalAmount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency' | 'precision'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'ReimbursementCasesPageInfo' }
      & Pick<ReimbursementCasesPageInfo, 'totalCount'>
    ) }
  ) }
);

export type MembershipByIdQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type MembershipByIdQuery = (
  { __typename?: 'Query' }
  & { membership: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'firstName' | 'lastName' | 'roles' | 'locale' | 'email' | 'avatarUrl' | 'status' | 'createdTimestamp' | 'username'>
    & { paymentInfo?: Maybe<(
      { __typename?: 'PaymentInfo' }
      & Pick<PaymentInfo, 'iban' | 'swiftCode'>
    )>, membershipRoles: Array<(
      { __typename: 'MembershipRole' }
      & Pick<MembershipRole, 'id' | 'name' | 'description' | 'isArchived' | 'isBuiltinRole' | 'isLegacyRole'>
    )> }
  ) }
);

export type PerDiemLocationsQueryVariables = Exact<{
  input: PerDiemLocationsInput;
}>;


export type PerDiemLocationsQuery = (
  { __typename?: 'Query' }
  & { perDiemLocations: (
    { __typename?: 'PerDiemLocationResponse' }
    & { locations: Array<(
      { __typename?: 'PerDiemLocation' }
      & Pick<PerDiemLocation, 'id' | 'countryIsoCode' | 'region' | 'country' | 'location'>
    )> }
  ) }
);

export type PerDiemItemDetailsQueryVariables = Exact<{
  filters: ReimbursementItemFiltersInput;
}>;


export type PerDiemItemDetailsQuery = (
  { __typename?: 'Query' }
  & { perDiemItemDetails: Array<{ __typename?: 'GeneralExpenseItem' | 'HospitalityExpenseItem' | 'MileageReimbursementItem' } | (
    { __typename: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id' | 'status' | 'currentStep'>
    & { totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )>, segments?: Maybe<Array<(
      { __typename?: 'PerDiemSegment' }
      & Pick<PerDiemSegment, 'id' | 'startAt' | 'endAt'>
      & { location?: Maybe<(
        { __typename?: 'PerDiemLocation' }
        & Pick<PerDiemLocation, 'id' | 'countryIsoCode' | 'country' | 'location'>
      )> }
    )>> }
  )> }
);

export type PerDiemItemCalculationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PerDiemItemCalculationQuery = (
  { __typename?: 'Query' }
  & { reimbursementItem: { __typename?: 'GeneralExpenseItem' | 'HospitalityExpenseItem' | 'MileageReimbursementItem' } | (
    { __typename: 'PerDiemItem' }
    & Pick<PerDiemItem, 'id' | 'status'>
    & { days: Array<(
      { __typename?: 'PerDiemDay' }
      & Pick<PerDiemDay, 'id' | 'date' | 'isFullDay'>
      & { dayRate: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      ), reimbursableRate: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'precision'>
      ), meals: (
        { __typename?: 'PerDiemDayMeals' }
        & { breakfast: (
          { __typename?: 'PerDiemDayMeal' }
          & Pick<PerDiemDayMeal, 'provided' | 'rateRatio'>
          & { deduction: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency' | 'precision'>
          ) }
        ), lunch: (
          { __typename?: 'PerDiemDayMeal' }
          & Pick<PerDiemDayMeal, 'provided' | 'rateRatio'>
          & { deduction: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency' | 'precision'>
          ) }
        ), dinner: (
          { __typename?: 'PerDiemDayMeal' }
          & Pick<PerDiemDayMeal, 'provided' | 'rateRatio'>
          & { deduction: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency' | 'precision'>
          ) }
        ) }
      ), location: (
        { __typename?: 'PerDiemLocation' }
        & Pick<PerDiemLocation, 'id' | 'countryIsoCode' | 'country' | 'location'>
      ) }
    )>, totalAmount?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    )> }
  ) }
);

export type AccountingAreaFragment = (
  { __typename?: 'AccountingArea' }
  & Pick<AccountingArea, 'id' | 'name' | 'description' | 'isArchived' | 'createdAt'>
);


export type AccountingAreaFragmentVariables = Exact<{ [key: string]: never; }>;

export type CreateAccountingAreaMutationVariables = Exact<{
  input: CreateAccountingAreaInput;
}>;


export type CreateAccountingAreaMutation = (
  { __typename?: 'Mutation' }
  & { createAccountingArea: (
    { __typename?: 'AccountingAreaError' }
    & Pick<AccountingAreaError, 'name' | 'kind' | 'message'>
  ) | (
    { __typename?: 'AccountingAreaSuccess' }
    & Pick<AccountingAreaSuccess, 'id'>
  ) }
);

export type UpdateAccountingAreaMutationVariables = Exact<{
  input: UpdateAccountingAreaInput;
}>;


export type UpdateAccountingAreaMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountingArea: (
    { __typename?: 'AccountingAreaError' }
    & Pick<AccountingAreaError, 'name' | 'kind' | 'message'>
  ) | (
    { __typename?: 'AccountingAreaSuccess' }
    & Pick<AccountingAreaSuccess, 'id'>
  ) }
);

export type ArchiveAccountingAreaMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchiveAccountingAreaMutation = (
  { __typename?: 'Mutation' }
  & { archiveAccountingArea: (
    { __typename?: 'AccountingAreaError' }
    & Pick<AccountingAreaError, 'name' | 'kind' | 'message'>
  ) | (
    { __typename?: 'AccountingAreaSuccess' }
    & Pick<AccountingAreaSuccess, 'id'>
  ) }
);

export type ActivateAccountingAreaMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ActivateAccountingAreaMutation = (
  { __typename?: 'Mutation' }
  & { activateAccountingArea: (
    { __typename?: 'AccountingAreaError' }
    & Pick<AccountingAreaError, 'name' | 'kind' | 'message'>
  ) | (
    { __typename?: 'AccountingAreaSuccess' }
    & Pick<AccountingAreaSuccess, 'id'>
  ) }
);

export type AccountingAreasQueryVariables = Exact<{
  filter: AccountingAreasFilterInput;
}>;


export type AccountingAreasQuery = (
  { __typename?: 'Query' }
  & { accountingAreas: Array<(
    { __typename?: 'AccountingArea' }
    & AccountingAreaFragment
  )> }
);

export type AccountingAreaByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AccountingAreaByIdQuery = (
  { __typename?: 'Query' }
  & { accountingAreaById: (
    { __typename?: 'AccountingArea' }
    & AccountingAreaFragment
  ) | { __typename?: 'AccountingAreaError' } }
);

export type CountAccountingAreasQueryVariables = Exact<{
  filter: AccountingAreasFilterInput;
}>;


export type CountAccountingAreasQuery = (
  { __typename?: 'Query' }
  & { countAccountingAreas: (
    { __typename?: 'AccountingAreaCount' }
    & Pick<AccountingAreaCount, 'count'>
  ) }
);

export type ListCandisApiSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCandisApiSessionsQuery = (
  { __typename?: 'Query' }
  & { listCandisAPISessions: Array<(
    { __typename?: 'CandisAPISession' }
    & Pick<CandisApiSession, 'sessionId' | 'clientName' | 'connectionDate'>
    & { authorizationUser: (
      { __typename?: 'AuthorizationUser' }
      & Pick<AuthorizationUser, 'id' | 'name'>
    ) }
  )> }
);

export type RevokeCandisApiSessionMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type RevokeCandisApiSessionMutation = (
  { __typename?: 'Mutation' }
  & { revokeCandisAPISession: (
    { __typename?: 'RevokeCandisAPISessionError' }
    & Pick<RevokeCandisApiSessionError, 'kind' | 'message'>
  ) | (
    { __typename?: 'RevokeCandisAPISessionSuccessful' }
    & Pick<RevokeCandisApiSessionSuccessful, 'isRevoked'>
  ) }
);

export type BulkCreateBookingAccountsMutationVariables = Exact<{
  input: Array<CreateBookingAccountInput> | CreateBookingAccountInput;
  fileId?: InputMaybe<Scalars['String']['input']>;
}>;


export type BulkCreateBookingAccountsMutation = (
  { __typename?: 'Mutation' }
  & { bulkCreateBookingAccounts: (
    { __typename?: 'BookingAccountError' }
    & Pick<BookingAccountError, 'accountCode' | 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingAccountsBulkImportReport' }
    & { imported: Array<(
      { __typename?: 'BookingAccountSuccess' }
      & Pick<BookingAccountSuccess, 'id'>
    )>, errors: Array<(
      { __typename?: 'BookingAccountError' }
      & Pick<BookingAccountError, 'accountCode' | 'kind' | 'message'>
    )> }
  ) }
);

export type BookingAccountImportFileUploadUrlMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
}>;


export type BookingAccountImportFileUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { bookingAccountImportFileUploadURL: (
    { __typename?: 'UploadFilesResponse' }
    & Pick<UploadFilesResponse, 'id' | 'url'>
    & { postOptions?: Maybe<Array<Maybe<(
      { __typename?: 'UploadPostOption' }
      & Pick<UploadPostOption, 'field' | 'value'>
    )>>> }
  ) }
);

export type BookingAccountsDuplicateCheckQueryVariables = Exact<{
  accountCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type BookingAccountsDuplicateCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bookingAccountsDuplicateCheck'>
);

export type BookingAccountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BookingAccountQuery = (
  { __typename?: 'Query' }
  & { bookingAccount: (
    { __typename: 'BookingAccount' }
    & BookingAccountDataFragment
  ) | (
    { __typename: 'BookingAccountError' }
    & Pick<BookingAccountError, 'kind'>
  ) }
);

export type CreateBookingAccountMutationVariables = Exact<{
  input: CreateBookingAccountInput;
}>;


export type CreateBookingAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBookingAccount: (
    { __typename: 'BookingAccountError' }
    & Pick<BookingAccountError, 'accountCode' | 'kind'>
  ) | (
    { __typename: 'BookingAccountSuccess' }
    & Pick<BookingAccountSuccess, 'id'>
  ) }
);

export type UpdateBookingAccountMutationVariables = Exact<{
  input: UpdateBookingAccountInput;
}>;


export type UpdateBookingAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingAccount: (
    { __typename: 'BookingAccountError' }
    & Pick<BookingAccountError, 'accountCode' | 'kind'>
  ) | (
    { __typename: 'BookingAccountSuccess' }
    & Pick<BookingAccountSuccess, 'id'>
  ) }
);

export type ArchiveBookingAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchiveBookingAccountMutation = (
  { __typename?: 'Mutation' }
  & { archiveBookingAccount: (
    { __typename: 'BookingAccountError' }
    & Pick<BookingAccountError, 'accountCode' | 'kind'>
  ) | (
    { __typename: 'BookingAccountSuccess' }
    & Pick<BookingAccountSuccess, 'id'>
  ) }
);

export type ActivateBookingAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ActivateBookingAccountMutation = (
  { __typename?: 'Mutation' }
  & { activateBookingAccount: (
    { __typename: 'BookingAccountError' }
    & Pick<BookingAccountError, 'accountCode' | 'kind'>
  ) | (
    { __typename: 'BookingAccountSuccess' }
    & Pick<BookingAccountSuccess, 'id'>
  ) }
);

export type BookingAccountsImportHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingAccountsImportHistoryQuery = (
  { __typename?: 'Query' }
  & { bookingAccountsImportHistory: Array<(
    { __typename?: 'BookingAccountImportHistory' }
    & Pick<BookingAccountImportHistory, 'id' | 'organizationId' | 'createdAt'>
    & { file: (
      { __typename?: 'BookingAccountImportHistoryFile' }
      & Pick<BookingAccountImportHistoryFile, 'id' | 'name' | 'url'>
    ), createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    ) }
  )> }
);

export type BookingAccountSearchQueryVariables = Exact<{
  input: BookingAccountSearchInput;
}>;


export type BookingAccountSearchQuery = (
  { __typename?: 'Query' }
  & { bookingAccountSearch: Array<(
    { __typename?: 'BookingAccount' }
    & BookingAccountDataFragment
  )> }
);

export type BookingAccountPaginationQueryVariables = Exact<{
  input?: InputMaybe<BookingAccountPaginationInput>;
  filters?: InputMaybe<BookingAccountFilterInput>;
  sortBy?: InputMaybe<BookingAccountSortInput>;
}>;


export type BookingAccountPaginationQuery = (
  { __typename?: 'Query' }
  & { bookingAccountPagination: (
    { __typename?: 'BookingAccountPaginationResult' }
    & { records: Array<(
      { __typename?: 'BookingAccount' }
      & BookingAccountDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    ) }
  ) }
);

export type CountBookingAccountsQueryVariables = Exact<{
  filters?: InputMaybe<BookingAccountFilterInput>;
}>;


export type CountBookingAccountsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countBookingAccounts'>
);

export type ContractSubCategoryFragment = (
  { __typename?: 'ContractSubCategory' }
  & Pick<ContractSubCategory, 'id' | 'name' | 'description' | 'isActive'>
);


export type ContractSubCategoryFragmentVariables = Exact<{ [key: string]: never; }>;

export type ContractSubCategoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ContractSubCategoryQuery = (
  { __typename?: 'Query' }
  & { contractSubCategory: (
    { __typename?: 'ContractSubCategory' }
    & ContractSubCategoryFragment
  ) }
);

export type ContractSubCategoriesWithDocumentCountQueryVariables = Exact<{
  input?: InputMaybe<GetContractSubCategoriesInput>;
}>;


export type ContractSubCategoriesWithDocumentCountQuery = (
  { __typename?: 'Query' }
  & { contractSubCategories: Array<(
    { __typename?: 'ContractSubCategory' }
    & Pick<ContractSubCategory, 'documentCount'>
    & ContractSubCategoryFragment
  )> }
);

export type ContractSubCategoriesQueryVariables = Exact<{
  input?: InputMaybe<GetContractSubCategoriesInput>;
}>;


export type ContractSubCategoriesQuery = (
  { __typename?: 'Query' }
  & { contractSubCategories: Array<(
    { __typename?: 'ContractSubCategory' }
    & ContractSubCategoryFragment
  )> }
);

export type CreateContractSubCategoryMutationVariables = Exact<{
  input: CreateContractSubCategoryInput;
}>;


export type CreateContractSubCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createContractSubCategory: (
    { __typename?: 'ContractSubCategory' }
    & ContractSubCategoryFragment
  ) }
);

export type UpdateContractSubCategoryMutationVariables = Exact<{
  input: UpdateContractSubCategoryInput;
}>;


export type UpdateContractSubCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateContractSubCategory: (
    { __typename?: 'ContractSubCategory' }
    & ContractSubCategoryFragment
  ) }
);

export type UpdateContractSubCategoryStatusMutationVariables = Exact<{
  input: UpdateContractSubCategoryStatusInput;
}>;


export type UpdateContractSubCategoryStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateContractSubCategoryStatus: (
    { __typename?: 'ContractSubCategory' }
    & ContractSubCategoryFragment
  ) }
);

export type CostCentersGetForDuplicationCheckQueryVariables = Exact<{
  costCenterCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
  costCenterType: CostCenterTypes;
}>;


export type CostCentersGetForDuplicationCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCentersGetForDuplicationCheck'>
);

export type BulkImportCostCentersMutationVariables = Exact<{
  input: Array<CreateCostCenterInput> | CreateCostCenterInput;
  fileId?: InputMaybe<Scalars['String']['input']>;
}>;


export type BulkImportCostCentersMutation = (
  { __typename?: 'Mutation' }
  & { bulkImportCostCenters: (
    { __typename?: 'CostCentersBulkImportResult' }
    & Pick<CostCentersBulkImportResult, 'imported' | 'duplicates' | 'errors'>
  ) }
);

export type CostCenterRequestImportFileUploadUrlMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
}>;


export type CostCenterRequestImportFileUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { costCenterRequestImportFileUploadURL: (
    { __typename?: 'UploadFilesResponse' }
    & Pick<UploadFilesResponse, 'id' | 'url'>
    & { postOptions?: Maybe<Array<Maybe<(
      { __typename?: 'UploadPostOption' }
      & Pick<UploadPostOption, 'field' | 'value'>
    )>>> }
  ) }
);

export type CostCenterGetImportHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type CostCenterGetImportHistoryQuery = (
  { __typename?: 'Query' }
  & { costCenterGetImportHistory: Array<(
    { __typename?: 'CostCenterImportHistoryItem' }
    & Pick<CostCenterImportHistoryItem, 'importedAt'>
    & { membership: (
      { __typename?: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    ), file: (
      { __typename?: 'CostCenterImportHistoryFile' }
      & Pick<CostCenterImportHistoryFile, 'name' | 'url'>
    ) }
  )> }
);

export type CreateCostCenter_NewMutationVariables = Exact<{
  input: CreateCostCenterInput;
  source?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateCostCenter_NewMutation = (
  { __typename?: 'Mutation' }
  & { createCostCenter_new: (
    { __typename: 'CostCenterAlreadyExists' }
    & Pick<CostCenterAlreadyExists, 'code'>
  ) | (
    { __typename: 'CostCenterCreated' }
    & Pick<CostCenterCreated, 'id'>
  ) }
);

export type UpdateCostCenter_NewMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateCostCenterInput;
}>;


export type UpdateCostCenter_NewMutation = (
  { __typename?: 'Mutation' }
  & { updateCostCenter_new: (
    { __typename: 'CostCenterAlreadyExists' }
    & Pick<CostCenterAlreadyExists, 'code'>
  ) | (
    { __typename: 'CostCenterUpdated' }
    & Pick<CostCenterUpdated, 'id'>
  ) }
);

export type GetCostCenterQueryVariables = Exact<{
  input: GetCostCenterInput;
}>;


export type GetCostCenterQuery = (
  { __typename?: 'Query' }
  & { costCenter?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'createdAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
    & CostCenterDataFragment
  )> }
);

export type UpdateCostCenterStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateCostCenterInput;
}>;


export type UpdateCostCenterStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateCostCenterStatus?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'createdAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
    & CostCenterDataFragment
  )> }
);

export type FetchCardIssuerSettlementsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCardIssuerSettlementsQuery = (
  { __typename?: 'Query' }
  & { fetchCardIssuerPaymentSettlements: (
    { __typename?: 'PaymentSettlementConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerPaymentSettlementEdge' }
      & { node: (
        { __typename?: 'CardIssuerPaymentSettlement' }
        & Pick<CardIssuerPaymentSettlement, 'status' | 'id' | 'actualPaymentDate'>
        & { amount: (
          { __typename?: 'PaymentSettlementAmount' }
          & Pick<PaymentSettlementAmount, 'currency' | 'value'>
        ) }
      ) }
    )>> }
  ) }
);

export type ApplyForCreditCardsMutationVariables = Exact<{
  input: ApplyForCreditCardsInput;
}>;


export type ApplyForCreditCardsMutation = (
  { __typename?: 'Mutation' }
  & { applyForCreditCards: (
    { __typename?: 'ApplyForCreditCardsOutput' }
    & Pick<ApplyForCreditCardsOutput, 'accepted'>
  ) }
);

export type GetCardIssuerOrganizationOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardIssuerOrganizationOnboardingQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerOrganizationOnboarding?: Maybe<(
    { __typename?: 'CardIssuerOrganizationOnboarding' }
    & Pick<CardIssuerOrganizationOnboarding, 'requestedAt' | 'status'>
    & { requestedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type UpdateDatevCreditCardsLedgerMutationVariables = Exact<{
  bookingAccount?: InputMaybe<Scalars['String']['input']>;
  transitAccount?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateDatevCreditCardsLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDatevCreditCardsLedger'>
);

export type UpdateCreditCardsLedgerMutationVariables = Exact<{
  bookingAccount?: InputMaybe<Scalars['String']['input']>;
  transitAccount?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateCreditCardsLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCreditCardsLedger'>
);

export type GetProcessingAccountDataForCardManagerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetProcessingAccountDataForCardManagerQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & { creditCardsSettings?: Maybe<(
      { __typename?: 'OrganizationCreditCardsSettings' }
      & { processingAccount: (
        { __typename?: 'OrganizationCreditCardsProcessingAccount' }
        & Pick<OrganizationCreditCardsProcessingAccount, 'beneficiary' | 'bankName' | 'iban' | 'bic'>
      ) }
    )> }
  )> }
);

export type SapB1SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SapB1SettingsQuery = (
  { __typename?: 'Query' }
  & { sapB1Settings?: Maybe<(
    { __typename?: 'SapB1Settings' }
    & Pick<SapB1Settings, 'purchaseInvoiceSeries' | 'outgoingPaymentSeries' | 'moneyInTransitGeneralLedgerAccount' | 'creditCardId' | 'creditCardGeneralLedgerAccount' | 'sapB1Version'>
    & { connectionDetails?: Maybe<(
      { __typename?: 'ConnectionDetails' }
      & Pick<ConnectionDetails, 'connectionStatus'>
      & { connectionError?: Maybe<(
        { __typename?: 'ConnectionError' }
        & { errorMessage: (
          { __typename?: 'ConnectionMessage' }
          & Pick<ConnectionMessage, 'de' | 'en'>
        ), resolutionMessage: (
          { __typename?: 'ConnectionMessage' }
          & Pick<ConnectionMessage, 'de' | 'en'>
        ) }
      )> }
    )> }
  )> }
);

export type UpdateSapB1SettingsMutationVariables = Exact<{
  sapB1Settings: SapB1SettingsInput;
}>;


export type UpdateSapB1SettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateSapB1Settings?: Maybe<(
    { __typename?: 'SapB1Response' }
    & Pick<SapB1Response, 'code'>
  )> }
);

export type DocumentDataExtractionSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentDataExtractionSettingsQuery = (
  { __typename?: 'Query' }
  & { documentDataExtractionSettings?: Maybe<(
    { __typename?: 'DocumentDataExtractionSettings' }
    & Pick<DocumentDataExtractionSettings, 'suggestOutgoingInvoices'>
  )> }
);

export type UpdateDocumentDataExtractionSettingsMutationVariables = Exact<{
  settings: UpdateDocumentDataExtractionSettingsInput;
}>;


export type UpdateDocumentDataExtractionSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocumentDataExtractionSettings'>
);

export type TagFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'description' | 'isActive' | 'createdAt'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type TagFragmentVariables = Exact<{ [key: string]: never; }>;

export type TagQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type TagQuery = (
  { __typename?: 'Query' }
  & { tag: (
    { __typename?: 'Tag' }
    & TagFragment
  ) }
);

export type TagsQueryVariables = Exact<{
  input?: InputMaybe<GetTagsInput>;
}>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'documentCount'>
    & TagFragment
  )> }
);

export type CheckTagNameDuplicationQueryVariables = Exact<{
  name: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CheckTagNameDuplicationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tagsForDuplicationCheck'>
);

export type CreateTagMutationVariables = Exact<{
  input: CreateTagInput;
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag: (
    { __typename?: 'Tag' }
    & TagFragment
  ) }
);

export type UpdateTagMutationVariables = Exact<{
  input: UpdateTagInput;
}>;


export type UpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { updateTag: (
    { __typename?: 'Tag' }
    & TagFragment
  ) }
);

export type UpdateTagStatusMutationVariables = Exact<{
  input: UpdateTagStatusInput;
}>;


export type UpdateTagStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateTagStatus: (
    { __typename?: 'Tag' }
    & TagFragment
  ) }
);

export type UpdateDocumentTagsMutationVariables = Exact<{
  input: UpdateDocumentTagsInput;
}>;


export type UpdateDocumentTagsMutation = (
  { __typename?: 'Mutation' }
  & { updateDocumentTags: Array<(
    { __typename?: 'Tag' }
    & TagFragment
  )> }
);

export type MergeTagsMutationVariables = Exact<{
  input: MergeTagsInput;
}>;


export type MergeTagsMutation = (
  { __typename?: 'Mutation' }
  & { mergeTags: (
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
  ) }
);

export type DocumentTypeSettingFragment = (
  { __typename?: 'EcmDocumentTypeSettingRecord' }
  & Pick<EcmDocumentTypeSettingRecord, 'name' | 'isVisible' | 'documentCount' | 'isNonEcmType'>
);


export type DocumentTypeSettingFragmentVariables = Exact<{ [key: string]: never; }>;

export type EcmDocumentTypeSettingsQueryVariables = Exact<{
  input: GetEcmDocumentTypeSettingsInput;
}>;


export type EcmDocumentTypeSettingsQuery = (
  { __typename?: 'Query' }
  & { ecmDocumentTypeSettings: Array<(
    { __typename?: 'EcmDocumentTypeSettingRecord' }
    & DocumentTypeSettingFragment
  )> }
);

export type UpdateEcmDocumentTypePreferencesMutationVariables = Exact<{
  input: UpdateEcmDocumentTypeSettingsInput;
}>;


export type UpdateEcmDocumentTypePreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateEcmDocumentTypePreferences: (
    { __typename?: 'EcmDocumentTypeSettingRecord' }
    & Pick<EcmDocumentTypeSettingRecord, 'isVisible' | 'isNonEcmType'>
  ) }
);

export type UpdateDatevClientCashLedgerConfiguredMutationVariables = Exact<{
  cashLedgerConfigured: Scalars['Boolean']['input'];
}>;


export type UpdateDatevClientCashLedgerConfiguredMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDatevClientCashLedgerConfigured'>
);

export type SyncDocumentTypesMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncDocumentTypesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncDocumentTypes'>
);

export type New_DatevSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type New_DatevSettingsQuery = (
  { __typename?: 'Query' }
  & { new_datevSettings?: Maybe<(
    { __typename?: 'new_DatevSettings' }
    & Pick<New_DatevSettings, 'hasAuthTokenForExportDocuments' | 'hasAuthTokenForReadClients' | 'connectionType' | 'isFiscalYearConfigured'>
    & { client?: Maybe<(
      { __typename: 'new_DatevClient' }
      & Pick<New_DatevClient, 'clientNumber' | 'connectedAt' | 'connectedBy' | 'consultantNumber' | 'exportType' | 'id' | 'isDxsoCashLedgerConfigured' | 'isDxsoJobsAvailable' | 'isExtfsFilesAvailable' | 'isBdsPermissionVerified' | 'name'>
    )>, documentTypes?: Maybe<Array<(
      { __typename?: 'new_DatevDocumentType' }
      & Pick<New_DatevDocumentType, 'isActive' | 'name' | 'category' | 'bookingAccount'>
    )>>, creditCardsLedger?: Maybe<(
      { __typename?: 'CreditCardsLedger' }
      & Pick<CreditCardsLedger, 'bookingAccount' | 'transitAccount'>
    )>, provisionsLedger?: Maybe<(
      { __typename?: 'ProvisionsLedger' }
      & Pick<ProvisionsLedger, 'provisionAccountNumber' | 'otherAssetsAccountNumber'>
    )>, chartOfAccount?: Maybe<(
      { __typename?: 'ChartOfAccount' }
      & Pick<ChartOfAccount, 'accountLength' | 'code'>
    )>, datevCoreDataSource?: Maybe<(
      { __typename?: 'DatevCoreDataSource' }
      & Pick<DatevCoreDataSource, 'isAccountLengthSourceDatev' | 'isChartOfAccountCodeSourceDatev' | 'isFiscalStartDateSourceDatev'>
    )> }
  )> }
);

export type CreditCardsLedgerQueryVariables = Exact<{ [key: string]: never; }>;


export type CreditCardsLedgerQuery = (
  { __typename?: 'Query' }
  & { creditCardsLedger: (
    { __typename?: 'CreditCardsLedger' }
    & Pick<CreditCardsLedger, 'bookingAccount' | 'transitAccount'>
  ) }
);

export type New_DatevClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type New_DatevClientsQuery = (
  { __typename?: 'Query' }
  & { new_datevClients: Array<(
    { __typename?: 'new_DatevClient' }
    & Pick<New_DatevClient, 'clientNumber' | 'consultantNumber' | 'id' | 'name' | 'isDxsoJobsAvailable' | 'isDxsoCashLedgerConfigured' | 'exportType'>
  )> }
);

export type DatevClientInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DatevClientInfoQuery = (
  { __typename?: 'Query' }
  & { datevClientInfo: (
    { __typename?: 'DatevClientInfo' }
    & Pick<DatevClientInfo, 'id' | 'is_document_management_available' | 'client_number' | 'consultant_number'>
    & { ledgerState: (
      { __typename?: 'DatevClientLedgerStates' }
      & Pick<DatevClientLedgerStates, 'cash' | 'accountsPayable' | 'accountsReceivable'>
    ) }
  ) }
);

export type AvailableDocumentCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableDocumentCategoriesQuery = (
  { __typename?: 'Query' }
  & { availableDocumentCategories: Array<(
    { __typename?: 'DocumentCategory' }
    & Pick<DocumentCategory, 'direction' | 'documentType' | 'isDefault' | 'isRds1_0Exportable' | 'isBdsExportable' | 'supportedCurrencies'>
  )> }
);

export type AllDocumentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllDocumentTypesQuery = (
  { __typename?: 'Query' }
  & { availableDocumentCategories: Array<(
    { __typename?: 'DocumentCategory' }
    & Pick<DocumentCategory, 'documentType' | 'isDefault'>
  )> }
);

export type DatevBookingAccountValidationQueryVariables = Exact<{ [key: string]: never; }>;


export type DatevBookingAccountValidationQuery = (
  { __typename?: 'Query' }
  & { datevBookingAccountValidation: (
    { __typename?: 'BookingAccountValidation' }
    & Pick<BookingAccountValidation, 'minLength' | 'maxLength'>
  ) }
);

export type DatevAvailableServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type DatevAvailableServicesQuery = (
  { __typename?: 'Query' }
  & { datevAvailableServices: Array<(
    { __typename?: 'DatevAvailableService' }
    & Pick<DatevAvailableService, 'service' | 'warning'>
  )> }
);

export type DatevBdsPreRequisitesQueryVariables = Exact<{ [key: string]: never; }>;


export type DatevBdsPreRequisitesQuery = (
  { __typename?: 'Query' }
  & { datevBDSPreRequisites: Array<(
    { __typename?: 'DatevBDSPreRequisites' }
    & Pick<DatevBdsPreRequisites, 'step' | 'completed'>
  )> }
);

export type DatevBdsPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type DatevBdsPermissionQuery = (
  { __typename?: 'Query' }
  & { datevBdsPermission: (
    { __typename: 'BdsPermissionErrorResult' }
    & Pick<BdsPermissionErrorResult, 'status' | 'error'>
  ) | (
    { __typename: 'BdsPermissionSuccessResult' }
    & Pick<BdsPermissionSuccessResult, 'status'>
  ) }
);

export type UpdateDatevProvisionsLedgerMutationVariables = Exact<{
  provisionAccountNumber?: InputMaybe<Scalars['String']['input']>;
  otherAssetsAccountNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateDatevProvisionsLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDatevProvisionsLedger'>
);

export type New_BuildDatevAuthUrlForClientsMutationVariables = Exact<{
  state: Scalars['String']['input'];
  connectionType?: InputMaybe<DatevConnectionType>;
}>;


export type New_BuildDatevAuthUrlForClientsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'new_buildDatevAuthUrlForClients'>
);

export type New_BuildDatevAuthUrlForExportDocumentsMutationVariables = Exact<{
  state: Scalars['String']['input'];
}>;


export type New_BuildDatevAuthUrlForExportDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'new_buildDatevAuthUrlForExportDocuments'>
);

export type ConnectDatevClientMutationVariables = Exact<{
  input: ClientInput;
}>;


export type ConnectDatevClientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'connectDatevClient'>
);

export type New_UpdateDatevExportTypeMutationVariables = Exact<{
  exportType: DatevClientExportType;
}>;


export type New_UpdateDatevExportTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'new_updateDatevExportType'>
);

export type DisconnectDatevClientMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectDatevClientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disconnectDatevClient'>
);

export type ActivateDocumentTypeMutationVariables = Exact<{
  documentTypeId: DocumentTypeId;
}>;


export type ActivateDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateDocumentType'>
);

export type DeactivateDocumentTypeMutationVariables = Exact<{
  documentTypeId: DocumentTypeId;
}>;


export type DeactivateDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateDocumentType'>
);

export type UpdateDocumentTypeBookingAccountMutationVariables = Exact<{
  documentTypeId: DocumentTypeId;
  bookingAccount?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateDocumentTypeBookingAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocumentTypeBookingAccount'>
);

export type UpdateExportConfigurationNewMutationVariables = Exact<{
  fiscalYearStartDay?: InputMaybe<Scalars['Int']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateExportConfigurationNewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateExportConfigurationNew'>
);

export type UpdateDatevChartOfAccountMutationVariables = Exact<{
  accountLength?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<ChartOfAccountCode>;
}>;


export type UpdateDatevChartOfAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChartOfAccount'>
);

export type SendEmailMutationVariables = Exact<{
  payload: SendEmailWithoutTemplateArgs;
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendEmail?: Maybe<(
    { __typename?: 'SendEmailResponse' }
    & Pick<SendEmailResponse, '_id' | 'status'>
  )> }
);

export type ImportDatevCoreDataMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportDatevCoreDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importDatevCoreData'>
);

export type UpdateDatevBdsPreRequisitesMutationVariables = Exact<{
  step: BdsPreRequisiteStep;
  completed: Scalars['Boolean']['input'];
}>;


export type UpdateDatevBdsPreRequisitesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDatevBDSPreRequisites'>
);

export type CreateTestBdsExtfImportJobMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateTestBdsExtfImportJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTestBDSExtfImportJob'>
);

export type SapB1CredentialsQueryVariables = Exact<{ [key: string]: never; }>;


export type SapB1CredentialsQuery = (
  { __typename?: 'Query' }
  & { sapB1Credentials?: Maybe<(
    { __typename?: 'SapB1Credentials' }
    & Pick<SapB1Credentials, 'companyDbId' | 'serviceLayerUrl' | 'username'>
  )> }
);

export type VerifySapB1CredentialsQueryVariables = Exact<{
  sapB1Credentials?: InputMaybe<SapB1CredentialsInput>;
}>;


export type VerifySapB1CredentialsQuery = (
  { __typename?: 'Query' }
  & { verifySapB1Credentials?: Maybe<(
    { __typename?: 'SapB1Response' }
    & Pick<SapB1Response, 'code'>
  )> }
);

export type CreateSapB1CredentialsMutationVariables = Exact<{
  sapB1Credentials: SapB1CredentialsInput;
}>;


export type CreateSapB1CredentialsMutation = (
  { __typename?: 'Mutation' }
  & { createSapB1Credentials?: Maybe<(
    { __typename?: 'SapB1Response' }
    & Pick<SapB1Response, 'code'>
  )> }
);

export type DeleteSapB1CredentialsMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteSapB1CredentialsMutation = (
  { __typename?: 'Mutation' }
  & { deleteSapB1Credentials?: Maybe<(
    { __typename?: 'SapB1Response' }
    & Pick<SapB1Response, 'code'>
  )> }
);

export type ReconnectAndVerifySapB1ConnectionMutationVariables = Exact<{ [key: string]: never; }>;


export type ReconnectAndVerifySapB1ConnectionMutation = (
  { __typename?: 'Mutation' }
  & { reconnectAndVerifySapB1Connection?: Maybe<(
    { __typename?: 'SapB1Settings' }
    & Pick<SapB1Settings, 'purchaseInvoiceSeries' | 'outgoingPaymentSeries' | 'moneyInTransitGeneralLedgerAccount' | 'creditCardId' | 'creditCardGeneralLedgerAccount' | 'sapB1Version'>
    & { connectionDetails?: Maybe<(
      { __typename?: 'ConnectionDetails' }
      & Pick<ConnectionDetails, 'connectionStatus'>
      & { connectionError?: Maybe<(
        { __typename?: 'ConnectionError' }
        & { errorMessage: (
          { __typename?: 'ConnectionMessage' }
          & Pick<ConnectionMessage, 'de' | 'en'>
        ), resolutionMessage: (
          { __typename?: 'ConnectionMessage' }
          & Pick<ConnectionMessage, 'de' | 'en'>
        ) }
      )> }
    )> }
  )> }
);

export type OrganizationsEmailAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationsEmailAccountsQuery = (
  { __typename?: 'Query' }
  & { getOrganizations?: Maybe<Array<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'slug'>
    & { emailAccounts?: Maybe<(
      { __typename?: 'OrganizationEmailAccountsResponse' }
      & { records?: Maybe<Array<Maybe<(
        { __typename?: 'OrganizationEmailAccount' }
        & Pick<OrganizationEmailAccount, 'id' | 'address' | 'isEnabled'>
      )>>> }
    )> }
  )>> }
);

export type EmailFragment = (
  { __typename?: 'EmailInboxMessage' }
  & Pick<EmailInboxMessage, 'id' | 'status' | 'from' | 'subject' | 'createdAt'>
);


export type EmailFragmentVariables = Exact<{ [key: string]: never; }>;

export type EmailInboxMessageWithAttachmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EmailInboxMessageWithAttachmentsQuery = (
  { __typename?: 'Query' }
  & { emailInboxMessage?: Maybe<(
    { __typename?: 'EmailInboxMessage' }
    & Pick<EmailInboxMessage, 'htmlLink' | 'textLink' | 'sender' | 'recipient' | 'receivedAt'>
    & { history?: Maybe<Array<(
      { __typename?: 'Activity' }
      & Pick<Activity, 'id' | 'type' | 'createdAt'>
      & { events?: Maybe<Array<(
        { __typename?: 'ActivityEvent' }
        & Pick<ActivityEvent, 'id' | 'type' | 'data'>
      )>> }
    )>>, attachments?: Maybe<Array<(
      { __typename?: 'EmailInboxMessageAttachmentWithDocument' }
      & Pick<EmailInboxMessageAttachmentWithDocument, 'documentFileName' | 'documentId' | 'isEInvoice' | 'url' | 'globalDocumentId' | 'documentType'>
    ) | (
      { __typename?: 'EmailInboxMessageAttachmentWithError' }
      & Pick<EmailInboxMessageAttachmentWithError, 'fileName' | 'fileUrl'>
      & { error: (
        { __typename: 'AttachmentDocumentNotFoundError' }
        & Pick<AttachmentDocumentNotFoundError, 'errorCode'>
      ) | (
        { __typename: 'AttachmentDocumentRemovedError' }
        & Pick<AttachmentDocumentRemovedError, 'removedAt' | 'removedBy' | 'errorCode'>
      ) | (
        { __typename: 'EmailMessageVirusDetected' }
        & Pick<EmailMessageVirusDetected, 'errorCode'>
      ) | { __typename: 'InvalidXRechnungAttachment' } | (
        { __typename: 'MaxAttachmentSizeExceeded' }
        & Pick<MaxAttachmentSizeExceeded, 'errorCode'>
      ) | (
        { __typename: 'UnprocessableAttachment' }
        & Pick<UnprocessableAttachment, 'errorCode'>
      ) | (
        { __typename: 'UnprocessableXmlAttachment' }
        & Pick<UnprocessableXmlAttachment, 'errorCode'>
      ) | (
        { __typename: 'UnsupportedAttachmentType' }
        & Pick<UnsupportedAttachmentType, 'errorCode'>
      ) }
    )>> }
    & EmailFragment
  )> }
);

export type EmailInboxMessageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EmailInboxMessageQuery = (
  { __typename?: 'Query' }
  & { emailInboxMessage?: Maybe<(
    { __typename?: 'EmailInboxMessage' }
    & Pick<EmailInboxMessage, 'htmlLink' | 'textLink' | 'sender' | 'recipient' | 'receivedAt'>
    & { history?: Maybe<Array<(
      { __typename?: 'Activity' }
      & Pick<Activity, 'id' | 'type' | 'createdAt'>
      & { events?: Maybe<Array<(
        { __typename?: 'ActivityEvent' }
        & Pick<ActivityEvent, 'id' | 'type' | 'data'>
      )>> }
    )>> }
    & EmailFragment
  )> }
);

export type EmailInboxPaginationQueryVariables = Exact<{
  input?: InputMaybe<EmailInboxPaginationInput>;
  filters?: InputMaybe<EmailInboxFilterInput>;
  sortBy?: InputMaybe<EmailInboxSortInput>;
}>;


export type EmailInboxPaginationQuery = (
  { __typename?: 'Query' }
  & { emailInboxPagination?: Maybe<(
    { __typename?: 'emailInboxPaginationResponse' }
    & { records?: Maybe<Array<Maybe<(
      { __typename?: 'EmailInboxMessage' }
      & Pick<EmailInboxMessage, 'numberOfGeneratedDocuments' | 'numberOfErroneousAttachments'>
      & EmailFragment
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  )> }
);

export type EmailInboxInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<EmailInboxPaginationInput>;
  filters?: InputMaybe<EmailInboxFilterInput>;
  sortBy?: InputMaybe<EmailInboxSortInput>;
}>;


export type EmailInboxInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { emailInboxPagination?: Maybe<(
    { __typename?: 'emailInboxPaginationResponse' }
    & { records?: Maybe<Array<Maybe<(
      { __typename?: 'EmailInboxMessage' }
      & Pick<EmailInboxMessage, 'numberOfGeneratedDocuments' | 'numberOfErroneousAttachments'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'EmailInboxMessageAttachmentWithDocument' }
        & Pick<EmailInboxMessageAttachmentWithDocument, 'documentFileName' | 'documentId' | 'isEInvoice' | 'url'>
      ) | { __typename?: 'EmailInboxMessageAttachmentWithError' }>> }
      & EmailFragment
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  )> }
);

export type UpdateMutationVariables = Exact<{
  realmName?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<UpdateOrganizationsInput>;
}>;


export type UpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationDataFragment
  )> }
);

export type TransferOwnershipMutationVariables = Exact<{
  input: TransferOwnershipInput;
}>;


export type TransferOwnershipMutation = (
  { __typename?: 'Mutation' }
  & { transferOwnership: (
    { __typename?: 'BuiltinRole' }
    & Pick<BuiltinRole, 'id'>
  ) | (
    { __typename?: 'TransferOwnershipError' }
    & Pick<TransferOwnershipError, 'kind' | 'message'>
  ) }
);

export type OrganizationDataFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name' | 'slug' | 'locale' | 'realmName' | 'ssoConfigurationId'>
  & { organizationContactInformation?: Maybe<(
    { __typename?: 'OrganizationContactInformation' }
    & Pick<OrganizationContactInformation, 'vatId' | 'taxNumber'>
  )> }
);


export type OrganizationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type UpdateOrganizationContactInformationMutationVariables = Exact<{
  input: OrganizationContactInformationInput;
}>;


export type UpdateOrganizationContactInformationMutation = (
  { __typename?: 'Mutation' }
  & { organizationContactInformation: (
    { __typename: 'OrganizationContactInformation' }
    & Pick<OrganizationContactInformation, 'vatId' | 'taxNumber'>
  ) | (
    { __typename: 'OrganizationContactInformationErrors' }
    & { errors: Array<(
      { __typename?: 'InvalidTaxNumber' }
      & Pick<InvalidTaxNumber, 'kind' | 'message'>
    ) | (
      { __typename?: 'InvalidVatID' }
      & Pick<InvalidVatId, 'kind' | 'message'>
    )> }
  ) }
);

export type PaymentConditionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PaymentConditionQuery = (
  { __typename?: 'Query' }
  & { paymentCondition: (
    { __typename: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'paymentConditionNumber' | 'paymentConditionName' | 'dueDateOffset' | 'discountOffset' | 'discountPercentage' | 'isArchived'>
  ) | (
    { __typename: 'PaymentConditionError' }
    & Pick<PaymentConditionError, 'kind' | 'message'>
  ) }
);

export type CreatePaymentConditionMutationVariables = Exact<{
  input: CreatePaymentConditionInput;
}>;


export type CreatePaymentConditionMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentCondition: (
    { __typename: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'paymentConditionNumber' | 'dueDateOffset' | 'discountOffset' | 'discountPercentage'>
  ) | (
    { __typename: 'PaymentConditionError' }
    & Pick<PaymentConditionError, 'kind' | 'message'>
  ) }
);

export type UpdatePaymentConditionMutationVariables = Exact<{
  input: UpdatePaymentConditionInput;
}>;


export type UpdatePaymentConditionMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentCondition: (
    { __typename: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'paymentConditionNumber' | 'dueDateOffset' | 'discountOffset' | 'discountPercentage'>
  ) | (
    { __typename: 'PaymentConditionError' }
    & Pick<PaymentConditionError, 'kind' | 'message'>
  ) }
);

export type ArchivePaymentConditionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchivePaymentConditionMutation = (
  { __typename?: 'Mutation' }
  & { archivePaymentCondition: (
    { __typename: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'isArchived'>
  ) | (
    { __typename: 'PaymentConditionError' }
    & Pick<PaymentConditionError, 'kind' | 'message'>
  ) }
);

export type RestorePaymentConditionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RestorePaymentConditionMutation = (
  { __typename?: 'Mutation' }
  & { unarchivePaymentCondition: (
    { __typename: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'isArchived'>
  ) | (
    { __typename: 'PaymentConditionError' }
    & Pick<PaymentConditionError, 'kind' | 'message'>
  ) }
);

export type ImportPaymentConditionsDryRunQueryVariables = Exact<{
  input: Array<CreatePaymentConditionInput> | CreatePaymentConditionInput;
}>;


export type ImportPaymentConditionsDryRunQuery = (
  { __typename?: 'Query' }
  & { importPaymentConditionsWithUpdate: Array<(
    { __typename?: 'PaymentConditionImportResult' }
    & { newValue: (
      { __typename: 'PaymentCondition' }
      & Pick<PaymentCondition, 'paymentConditionNumber' | 'discountOffset' | 'discountPercentage' | 'dueDateOffset'>
    ) | (
      { __typename: 'PaymentConditionError' }
      & { errorKind: PaymentConditionError['kind'], errorPaymentConditionNumber: PaymentConditionError['paymentConditionNumber'], errorDiscountOffset: PaymentConditionError['discountOffset'], errorDiscountPercentage: PaymentConditionError['discountPercentage'], errorDueDateOffset: PaymentConditionError['dueDateOffset'] }
    ), oldValue?: Maybe<{ __typename: 'PaymentCondition' }> }
  )> }
);

export type ImportPaymentConditionsMutationVariables = Exact<{
  input: Array<CreatePaymentConditionInput> | CreatePaymentConditionInput;
  fileId: Scalars['String']['input'];
}>;


export type ImportPaymentConditionsMutation = (
  { __typename?: 'Mutation' }
  & { importPaymentConditionsWithUpdate: Array<(
    { __typename?: 'PaymentConditionImportResult' }
    & { newValue: (
      { __typename: 'PaymentCondition' }
      & Pick<PaymentCondition, 'id' | 'paymentConditionNumber' | 'discountOffset' | 'discountPercentage' | 'dueDateOffset' | 'updatedAt'>
      & { updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'avatarUrl'>
      )> }
    ) | (
      { __typename: 'PaymentConditionError' }
      & Pick<PaymentConditionError, 'message'>
      & { errorKind: PaymentConditionError['kind'] }
    ) }
  )> }
);

export type PaymentConditionsImportHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentConditionsImportHistoryQuery = (
  { __typename?: 'Query' }
  & { paymentConditionsImportHistory?: Maybe<Array<(
    { __typename: 'PaymentConditionsImportHistory' }
    & Pick<PaymentConditionsImportHistory, 'id' | 'importedConditions' | 'createdAt' | 'status'>
    & { file?: Maybe<(
      { __typename: 'PaymentConditionsImportFile' }
      & Pick<PaymentConditionsImportFile, 'name' | 'url'>
    )>, errorFile?: Maybe<(
      { __typename: 'PaymentConditionsImportErrorFile' }
      & Pick<PaymentConditionsImportErrorFile, 'name' | 'url'>
    )>, createdBy?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    )> }
  )>> }
);

export type PaymentConditionsQueryVariables = Exact<{
  filter: PaymentConditionsFilter;
}>;


export type PaymentConditionsQuery = (
  { __typename?: 'Query' }
  & { paymentConditions: Array<(
    { __typename?: 'PaymentCondition' }
    & Pick<PaymentCondition, 'id' | 'paymentConditionNumber' | 'paymentConditionName' | 'dueDateOffset' | 'discountOffset' | 'discountPercentage' | 'updatedAt' | 'isArchived'>
    & { updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    )> }
  )> }
);

export type PaymentConditionsCountQueryVariables = Exact<{
  filter: PaymentConditionsFilter;
}>;


export type PaymentConditionsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'paymentConditionsCount'>
);

export type ExportPaymentConditionsMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportPaymentConditionsMutation = (
  { __typename?: 'Mutation' }
  & { exportPaymentConditions: (
    { __typename: 'PaymentConditionsExport' }
    & { file?: Maybe<(
      { __typename?: 'PaymentConditionsExportFile' }
      & Pick<PaymentConditionsExportFile, 'url'>
    )> }
  ) | (
    { __typename: 'PaymentConditionsExportError' }
    & Pick<PaymentConditionsExportError, 'kind'>
  ) }
);

export type ExportPaymentConditionsGenericMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportPaymentConditionsGenericMutation = (
  { __typename?: 'Mutation' }
  & { exportPaymentConditionsGeneric: (
    { __typename?: 'ExportPaymentConditionsGenericResult' }
    & Pick<ExportPaymentConditionsGenericResult, 'url'>
  ) }
);

export type BankAccountsIbanDataFragment = (
  { __typename?: 'Iban' }
  & Pick<Iban, 'id' | 'name' | 'iban' | 'swiftCode' | 'isDefault' | 'logoUrl'>
);


export type BankAccountsIbanDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CreateIbanMutationVariables = Exact<{
  iban: Scalars['IBAN']['input'];
  name: Scalars['String']['input'];
  swiftCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateIbanMutation = (
  { __typename?: 'Mutation' }
  & { createIban: (
    { __typename?: 'Iban' }
    & BankAccountsIbanDataFragment
  ) | (
    { __typename?: 'IbanError' }
    & Pick<IbanError, 'kind'>
  ) }
);

export type UpdateIbanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  iban: Scalars['IBAN']['input'];
  swiftCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}>;


export type UpdateIbanMutation = (
  { __typename?: 'Mutation' }
  & { updateIban: (
    { __typename?: 'Iban' }
    & BankAccountsIbanDataFragment
  ) | (
    { __typename?: 'IbanError' }
    & Pick<IbanError, 'kind'>
  ) }
);

export type DeleteIbanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteIbanMutation = (
  { __typename?: 'Mutation' }
  & { deleteIban: (
    { __typename?: 'DeleteIbanSuccessful' }
    & Pick<DeleteIbanSuccessful, 'isDeleted'>
  ) | { __typename?: 'IbanError' } }
);

export type SetIbanAsOrgDefaultMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SetIbanAsOrgDefaultMutation = (
  { __typename?: 'Mutation' }
  & { setIbanAsOrgDefault: (
    { __typename?: 'Iban' }
    & BankAccountsIbanDataFragment
  ) | { __typename?: 'IbanError' } }
);

export type ListIbansQueryVariables = Exact<{ [key: string]: never; }>;


export type ListIbansQuery = (
  { __typename?: 'Query' }
  & { listIbans: Array<(
    { __typename?: 'Iban' }
    & BankAccountsIbanDataFragment
  )> }
);

export type ProjectCodesImportHistoryPaginationQueryVariables = Exact<{
  input?: InputMaybe<ListImportHistoryEntriesInput>;
}>;


export type ProjectCodesImportHistoryPaginationQuery = (
  { __typename?: 'Query' }
  & { importHistories?: Maybe<(
    { __typename?: 'ListImportHistoryEntriesResult' }
    & { records: Array<Maybe<(
      { __typename?: 'ImportHistory' }
      & Pick<ImportHistory, '_id' | 'status' | 'createdAt' | 'successfullyCreatedEntitiesCount' | 'successfullyUpdatedEntitiesCount'>
      & { createdBy: (
        { __typename?: 'CreatedBy' }
        & Pick<CreatedBy, 'name'>
      ), importErrorDetails?: Maybe<(
        { __typename?: 'ImportErrorDetails' }
        & Pick<ImportErrorDetails, 'errorCode' | 'errorMessage' | 'formattedErrorMessage'>
      )>, failedEntities?: Maybe<Array<{ __typename?: 'GoodReceiptsFailedEntity' } | (
        { __typename?: 'ProjectCodeFailedEntity' }
        & { rawEntity: (
          { __typename?: 'ProjectCodeRawEntity' }
          & Pick<ProjectCodeRawEntity, 'code' | 'name'>
        ) }
      )>> }
    )>>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    ) }
  )> }
);

export type GetProjectCodeImportHistoryByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetProjectCodeImportHistoryByIdQuery = (
  { __typename?: 'Query' }
  & { importHistoryById?: Maybe<(
    { __typename?: 'ImportHistory' }
    & Pick<ImportHistory, '_id' | 'status' | 'createdAt' | 'successfullyCreatedEntitiesCount' | 'successfullyUpdatedEntitiesCount'>
    & { createdBy: (
      { __typename?: 'CreatedBy' }
      & Pick<CreatedBy, 'name'>
    ), importErrorDetails?: Maybe<(
      { __typename?: 'ImportErrorDetails' }
      & Pick<ImportErrorDetails, 'errorCode' | 'errorMessage' | 'formattedErrorMessage'>
    )>, failedEntities?: Maybe<Array<{ __typename?: 'GoodReceiptsFailedEntity' } | (
      { __typename?: 'ProjectCodeFailedEntity' }
      & { rawEntity: (
        { __typename?: 'ProjectCodeRawEntity' }
        & Pick<ProjectCodeRawEntity, 'code' | 'name'>
      ), errorDetails?: Maybe<Array<(
        { __typename?: 'ErrorDetails' }
        & Pick<ErrorDetails, 'errorCode' | 'errorMessage' | 'formattedErrorMessage'>
      )>> }
    )>> }
  )> }
);

export type ProjectCodeInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<ProjectCodePaginationInput>;
  filters?: InputMaybe<ProjectCodeFilterInput>;
  queries?: InputMaybe<ProjectCodeQueryInput>;
  sortBy?: InputMaybe<ProjectCodeSortInput>;
}>;


export type ProjectCodeInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { projectCodePagination: (
    { __typename?: 'ProjectCodePaginationResult' }
    & { records: Array<(
      { __typename?: 'ProjectCode' }
      & ProjectCodeDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    ) }
  ) }
);

export type ProjectCodeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectCodeQuery = (
  { __typename?: 'Query' }
  & { projectCode?: Maybe<(
    { __typename?: 'ProjectCode' }
    & ProjectCodeDataFragment
  )> }
);

export type CountProjectCodesQueryVariables = Exact<{
  filters?: InputMaybe<ProjectCodeFilterInput>;
}>;


export type CountProjectCodesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countProjectCodes'>
);

export type CreateCustomRoleMutationVariables = Exact<{
  input: CreateCustomRoleInput;
}>;


export type CreateCustomRoleMutation = (
  { __typename?: 'Mutation' }
  & { createCustomRole: (
    { __typename?: 'CustomRole' }
    & Pick<CustomRole, 'id'>
  ) }
);

export type UpdateCustomRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateCustomRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomRole: (
    { __typename?: 'CustomRole' }
    & Pick<CustomRole, 'id'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'isActive'>
    )> }
  ) }
);

export type SetRoleIsArchivedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
}>;


export type SetRoleIsArchivedMutation = (
  { __typename?: 'Mutation' }
  & { setRoleIsArchived: (
    { __typename?: 'CustomRole' }
    & Pick<CustomRole, 'id'>
  ) }
);

export type BuiltInRoleDataFragment = (
  { __typename: 'BuiltinRole' }
  & Pick<BuiltinRole, 'id' | 'isLegacyRole'>
  & { builtInRoleName: BuiltinRole['name'] }
);


export type BuiltInRoleDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CustomRoleDataFragment = (
  { __typename: 'CustomRole' }
  & Pick<CustomRole, 'id' | 'updatedAt'>
  & { customRoleName: CustomRole['name'] }
  & { updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);


export type CustomRoleDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PermissionDataFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'id' | 'name' | 'description' | 'category' | 'isActive'>
);


export type PermissionDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetRolesQueryVariables = Exact<{
  input: RolesPageBasedPaginationInput;
  queries?: InputMaybe<RolesQueryInput>;
  filters?: InputMaybe<RolesFiltersInput>;
  sortBy?: InputMaybe<RolesSortInput>;
}>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & { roles: (
    { __typename?: 'PageBasedRolesList' }
    & { records: Array<(
      { __typename: 'BuiltinRole' }
      & Pick<BuiltinRole, 'id' | 'description' | 'isArchived'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )> }
      & BuiltInRoleDataFragment
    ) | (
      { __typename: 'CustomRole' }
      & Pick<CustomRole, 'id' | 'description' | 'isArchived'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )> }
      & CustomRoleDataFragment
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  ) }
);

export type RoleByNameQueryVariables = Exact<{
  input: RolesPageBasedPaginationInput;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type RoleByNameQuery = (
  { __typename?: 'Query' }
  & { roleByName: (
    { __typename?: 'PageBasedRolesList' }
    & { records: Array<(
      { __typename: 'BuiltinRole' }
      & Pick<BuiltinRole, 'id'>
      & BuiltInRoleDataFragment
    ) | (
      { __typename: 'CustomRole' }
      & Pick<CustomRole, 'id'>
      & CustomRoleDataFragment
    )> }
  ) }
);

export type GetRoleByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetRoleByIdQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'BuiltinRole' }
    & Pick<BuiltinRole, 'id' | 'description' | 'isArchived'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>, permissions: Array<(
      { __typename?: 'Permission' }
      & PermissionDataFragment
    )> }
    & BuiltInRoleDataFragment
  ) | (
    { __typename?: 'CustomRole' }
    & Pick<CustomRole, 'id' | 'description' | 'isArchived'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>, permissions: Array<(
      { __typename?: 'Permission' }
      & PermissionDataFragment
    )> }
    & CustomRoleDataFragment
  ) }
);

export type GetPermissionsForNewRoleQueryVariables = Exact<{
  filters?: InputMaybe<PermissionFilterInput>;
}>;


export type GetPermissionsForNewRoleQuery = (
  { __typename?: 'Query' }
  & { permissionsForRole?: Maybe<(
    { __typename?: 'PermissionsForRole' }
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & PermissionDataFragment
    )> }
  )> }
);

export type UpdateOrganizationSsoConfigurationMutationVariables = Exact<{
  ssoConfigurationId: Scalars['String']['input'];
  organizationSlug: Scalars['String']['input'];
}>;


export type UpdateOrganizationSsoConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrganizationSSOConfiguration'>
);

export type RemoveOrganizationSsoConfigurationMutationVariables = Exact<{
  organizationSlug: Scalars['String']['input'];
}>;


export type RemoveOrganizationSsoConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOrganizationSSOConfiguration'>
);

export type CreateIdentityProviderMutationVariables = Exact<{
  input: IdentityProviderInput;
}>;


export type CreateIdentityProviderMutation = (
  { __typename?: 'Mutation' }
  & { createIdentityProvider: (
    { __typename?: 'CreateIdentityProviderResponse' }
    & Pick<CreateIdentityProviderResponse, 'idpAlias' | 'ssoConfigurationId'>
  ) }
);

export type UpdateIdentityProviderMutationVariables = Exact<{
  ssoConfigurationId: Scalars['String']['input'];
  input: IdentityProviderInput;
}>;


export type UpdateIdentityProviderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateIdentityProvider'>
);

export type ListSsoConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSsoConfigurationsQuery = (
  { __typename?: 'Query' }
  & { listSSOConfigurations: Array<(
    { __typename?: 'SSOConfiguration' }
    & Pick<SsoConfiguration, 'id' | 'idpAlias' | 'isMembershipRolesUpdatedViaSSO' | 'canUserRegisterViaSSO' | 'owner' | 'emailDomains'>
  )> }
);

export type GetIdentitiyProviderQueryVariables = Exact<{
  ssoConfigurationId: Scalars['String']['input'];
}>;


export type GetIdentitiyProviderQuery = (
  { __typename?: 'Query' }
  & { getIdentitiyProvider: (
    { __typename?: 'IdentityProvider' }
    & Pick<IdentityProvider, 'alias' | 'displayName' | 'enabled' | 'firstBrokerLoginFlowAlias'>
    & { config: (
      { __typename: 'IdentityProviderOIDC' }
      & Pick<IdentityProviderOidc, 'tokenUrl' | 'authorizationUrl' | 'clientId'>
    ) | (
      { __typename: 'IdentityProviderSAML' }
      & Pick<IdentityProviderSaml, 'idpEntityId' | 'entityId' | 'singleSignOnServiceUrl' | 'validateSignature' | 'signingCertificate' | 'syncMode' | 'nameIDPolicyFormat' | 'principalType' | 'postBindingAuthnRequest' | 'postBindingResponse'>
    ) }
  ) }
);

export type SetAccountsPayableNumberSettingsMutationVariables = Exact<{
  settings: AccountsPayableNumberSettingsInput;
}>;


export type SetAccountsPayableNumberSettingsMutation = (
  { __typename?: 'Mutation' }
  & { accountsPayableNumberSettings: (
    { __typename?: 'AccountsPayableNumberSettings' }
    & Pick<AccountsPayableNumberSettings, 'enabled' | 'startNumber'>
  ) }
);

export type SetEmployeesAccountsPayableNumberMutationVariables = Exact<{
  settings: AccountsPayableNumberSettingsInput;
}>;


export type SetEmployeesAccountsPayableNumberMutation = (
  { __typename?: 'Mutation' }
  & { setAccountsPayableNumberSettingsForEmployees: (
    { __typename?: 'AccountsPayableNumberSettings' }
    & Pick<AccountsPayableNumberSettings, 'enabled' | 'startNumber'>
  ) }
);

export type AccountsPayableNumberSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsPayableNumberSettingsQuery = (
  { __typename?: 'Query' }
  & { accountsPayableNumberSettings: (
    { __typename?: 'AccountsPayableNumberSettings' }
    & Pick<AccountsPayableNumberSettings, 'startNumber' | 'enabled'>
  ) }
);

export type AccountsPayableNumberSettingsForEmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsPayableNumberSettingsForEmployeesQuery = (
  { __typename?: 'Query' }
  & { accountsPayableNumberSettingsForEmployees: (
    { __typename?: 'AccountsPayableNumberSettings' }
    & Pick<AccountsPayableNumberSettings, 'startNumber' | 'enabled'>
  ) }
);

export type SfsSettingsForRequesterQueryVariables = Exact<{ [key: string]: never; }>;


export type SfsSettingsForRequesterQuery = (
  { __typename?: 'Query' }
  & { sfsSettings: (
    { __typename?: 'SfsSettings' }
    & { costCenter: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ), costObject: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ), generalLedgerAccount: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ), bookingKey: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ) }
  ) }
);

export type SfsSettingsForApproverQueryVariables = Exact<{ [key: string]: never; }>;


export type SfsSettingsForApproverQuery = (
  { __typename?: 'Query' }
  & { sfsSettingsForApprover: (
    { __typename?: 'SfsSettings' }
    & { costCenter: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ), costObject: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ), generalLedgerAccount: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ), bookingKey: (
      { __typename?: 'SfsSettingsSwitch' }
      & Pick<SfsSettingsSwitch, 'on' | 'method'>
    ) }
  ) }
);

export type SfsSwitchCostCenterOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostCenterOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostCenterOffManually'>
);

export type SfsSwitchCostCenterOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostCenterOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostCenterOnManually'>
);

export type SfsSwitchCostObjectOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostObjectOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostObjectOffManually'>
);

export type SfsSwitchCostObjectOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostObjectOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostObjectOnManually'>
);

export type SfsSwitchGeneralLedgerAccountOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchGeneralLedgerAccountOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchGeneralLedgerAccountOnManually'>
);

export type SfsSwitchGeneralLedgerAccountOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchGeneralLedgerAccountOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchGeneralLedgerAccountOffManually'>
);

export type SfsSwitchBookingKeyOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchBookingKeyOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchBookingKeyOnManually'>
);

export type SfsSwitchBookingKeyOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchBookingKeyOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchBookingKeyOffManually'>
);

export type SfsSwitchCostObjectForApproverOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostObjectForApproverOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostObjectForApproverOffManually'>
);

export type SfsSwitchCostObjectForApproverOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostObjectForApproverOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostObjectForApproverOnManually'>
);

export type SfsSwitchCostCenterForApproverOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostCenterForApproverOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostCenterForApproverOffManually'>
);

export type SfsSwitchCostCenterForApproverOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchCostCenterForApproverOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchCostCenterForApproverOnManually'>
);

export type SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchGeneralLedgerAccountForApproverOffManually'>
);

export type SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchGeneralLedgerAccountForApproverOnManually'>
);

export type SfsSwitchBookingKeyForApproverOffManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchBookingKeyForApproverOffManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchBookingKeyForApproverOffManually'>
);

export type SfsSwitchBookingKeyForApproverOnManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SfsSwitchBookingKeyForApproverOnManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sfsSwitchBookingKeyForApproverOnManually'>
);

export type BookingKeyDataFragment = (
  { __typename?: 'BookingKey' }
  & Pick<BookingKey, 'id' | 'taxCode' | 'description' | 'readableName' | 'createdAt' | 'isArchived' | 'isAcquisitionReverse' | 'taxPercentage'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type BookingKeyDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BookingKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingKeysQuery = (
  { __typename?: 'Query' }
  & { bookingKeys: Array<(
    { __typename?: 'BookingKey' }
    & BookingKeyDataFragment
  )> }
);

export type BookingKeyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BookingKeyQuery = (
  { __typename?: 'Query' }
  & { bookingKey: (
    { __typename: 'BookingKey' }
    & BookingKeyDataFragment
  ) | (
    { __typename: 'BookingKeyError' }
    & Pick<BookingKeyError, 'kind'>
  ) }
);

export type BookingKeysActiveQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingKeysActiveQuery = (
  { __typename?: 'Query' }
  & { bookingKeysActive: Array<(
    { __typename?: 'BookingKey' }
    & BookingKeyDataFragment
  )> }
);

export type BookingKeysArchivedQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingKeysArchivedQuery = (
  { __typename?: 'Query' }
  & { bookingKeysArchived: Array<(
    { __typename?: 'BookingKey' }
    & BookingKeyDataFragment
  )> }
);

export type CreateBookingKeyMutationVariables = Exact<{
  input: CreateBookingKeyInput;
}>;


export type CreateBookingKeyMutation = (
  { __typename?: 'Mutation' }
  & { createBookingKey: (
    { __typename: 'BookingKeyError' }
    & Pick<BookingKeyError, 'taxCode' | 'kind'>
  ) | (
    { __typename: 'BookingKeySuccess' }
    & Pick<BookingKeySuccess, 'id'>
  ) }
);

export type UpdateBookingKeyMutationVariables = Exact<{
  input: UpdateBookingKeyInput;
}>;


export type UpdateBookingKeyMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingKey: (
    { __typename: 'BookingKeyError' }
    & Pick<BookingKeyError, 'taxCode' | 'kind'>
  ) | (
    { __typename: 'BookingKeySuccess' }
    & Pick<BookingKeySuccess, 'id'>
  ) }
);

export type ArchiveBookingKeyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchiveBookingKeyMutation = (
  { __typename?: 'Mutation' }
  & { archiveBookingKey: (
    { __typename: 'BookingKeyError' }
    & Pick<BookingKeyError, 'taxCode' | 'kind'>
  ) | (
    { __typename: 'BookingKeySuccess' }
    & Pick<BookingKeySuccess, 'id'>
  ) }
);

export type ActivateBookingKeyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ActivateBookingKeyMutation = (
  { __typename?: 'Mutation' }
  & { activateBookingKey: (
    { __typename: 'BookingKeyError' }
    & Pick<BookingKeyError, 'taxCode' | 'kind'>
  ) | (
    { __typename: 'BookingKeySuccess' }
    & Pick<BookingKeySuccess, 'id'>
  ) }
);

export type BulkCreateBookingKeysMutationVariables = Exact<{
  input: Array<CreateBookingKeyInput> | CreateBookingKeyInput;
}>;


export type BulkCreateBookingKeysMutation = (
  { __typename?: 'Mutation' }
  & { bulkCreateBookingKeys: (
    { __typename?: 'BookingKeyError' }
    & Pick<BookingKeyError, 'taxCode' | 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingKeysBulkImportReport' }
    & { imported: Array<(
      { __typename?: 'BookingKeySuccess' }
      & Pick<BookingKeySuccess, 'id'>
    )>, errors: Array<(
      { __typename?: 'BookingKeyError' }
      & Pick<BookingKeyError, 'taxCode' | 'kind' | 'message'>
    )> }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CreateUserSsoMutationVariables = Exact<{
  input: CreateUserSsoInput;
}>;


export type CreateUserSsoMutation = (
  { __typename?: 'Mutation' }
  & { createUserSSO?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CancelInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type CancelInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelInvitation'>
);

export type ResendInvitationMutationVariables = Exact<{
  membershipId: Scalars['String']['input'];
  organizationSlug?: InputMaybe<Scalars['String']['input']>;
}>;


export type ResendInvitationMutation = (
  { __typename?: 'Mutation' }
  & { resendInvitation?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type DeactivateMembershipMutationVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type DeactivateMembershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateMembership'>
);

export type ActivateMembershipMutationVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type ActivateMembershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateMembership'>
);

export type UpdateMembershipMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateUserInput;
}>;


export type UpdateMembershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMembership'>
);

export type ForceUpdateMembershipMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateUserInput;
}>;


export type ForceUpdateMembershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forceUpdateMembership'>
);

export type ForceDeactivateMembershipMutationVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type ForceDeactivateMembershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forceDeactivateMembership'>
);

export type SendMobileAppInvitationEmailMutationVariables = Exact<{
  input: SendMobileAppInvitationEmailInput;
}>;


export type SendMobileAppInvitationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMobileAppInvitationEmail'>
);

export type UpdateAccountsPayableNumberForEmployeeContactMutationVariables = Exact<{
  membershipId: Scalars['ID']['input'];
  accountsPayableNumber?: InputMaybe<Scalars['AccountsPayableNumber']['input']>;
}>;


export type UpdateAccountsPayableNumberForEmployeeContactMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountsPayableNumberForEmployeeContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { name: (
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    ) }
  )> }
);

export type TeamMembersQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type TeamMembersQuery = (
  { __typename?: 'Query' }
  & { teamMembers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'avatarUrl' | 'name' | 'firstName' | 'lastName' | 'email' | 'locale' | 'roles' | 'username' | 'createdTimestamp' | 'status'>
    & { membershipRoles: Array<(
      { __typename: 'MembershipRole' }
      & Pick<MembershipRole, 'id' | 'name' | 'description' | 'isArchived' | 'isBuiltinRole'>
    )> }
  )> }
);

export type GetRolesForUserQueryQueryVariables = Exact<{
  input: RolesPageBasedPaginationInput;
  queries?: InputMaybe<RolesQueryInput>;
  filters?: InputMaybe<RolesFiltersInput>;
  sortBy?: InputMaybe<RolesSortInput>;
}>;


export type GetRolesForUserQueryQuery = (
  { __typename?: 'Query' }
  & { roles: (
    { __typename?: 'PageBasedRolesList' }
    & { records: Array<(
      { __typename: 'BuiltinRole' }
      & Pick<BuiltinRole, 'id' | 'description' | 'isArchived'>
      & BuiltInRoleDataFragment
    ) | (
      { __typename: 'CustomRole' }
      & Pick<CustomRole, 'id' | 'description' | 'isArchived'>
      & CustomRoleDataFragment
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  ) }
);

export type PendingInvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingInvitesQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'firstName' | 'lastName' | 'email' | 'locale' | 'roles' | 'username' | 'invitedTimestamp' | 'createdTimestamp' | 'status'>
    & { membershipRoles: Array<(
      { __typename?: 'MembershipRole' }
      & Pick<MembershipRole, 'id' | 'name' | 'isBuiltinRole' | 'isLegacyRole'>
    )> }
  )> }
);

export type UsersFieldOptionsQueryVariables = Exact<{
  filters?: InputMaybe<OrganizationMembershipsFiltersInput>;
}>;


export type UsersFieldOptionsQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )> }
);

export type CostCenterCountForApproverQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type CostCenterCountForApproverQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCenterCountForApprover'>
);

export type CostCenterArchivedCountForApproverQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type CostCenterArchivedCountForApproverQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCenterArchivedCountForApprover'>
);

export type GetCountOfWorkflowTemplatesByApproverIdQueryVariables = Exact<{
  approverId: Scalars['String']['input'];
}>;


export type GetCountOfWorkflowTemplatesByApproverIdQuery = (
  { __typename?: 'Query' }
  & { getCountOfWorkflowTemplatesByApproverId: (
    { __typename?: 'WorkflowTemplateCounters' }
    & Pick<WorkflowTemplateCounters, 'active' | 'archived' | 'all'>
  ) }
);

export type CountPendingDocumentsByMembershipQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type CountPendingDocumentsByMembershipQuery = (
  { __typename?: 'Query' }
  & { countPendingDocumentsByMembership: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CountPendingDocumentsAndReimbursementsByMembershipQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type CountPendingDocumentsAndReimbursementsByMembershipQuery = (
  { __typename?: 'Query' }
  & { pendingDocuments: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ), pendingReimbursements: (
    { __typename?: 'DocumentMetric' }
    & Pick<DocumentMetric, 'count'>
  ) }
);

export type CreditCardInvolvementsQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type CreditCardInvolvementsQuery = (
  { __typename?: 'Query' }
  & { creditCardInvolvements: (
    { __typename?: 'CreditCardInvolvements' }
    & { cards?: Maybe<(
      { __typename?: 'CardsInvolvement' }
      & Pick<CardsInvolvement, 'total'>
    )>, cardholder?: Maybe<(
      { __typename?: 'CardIssuerCardholder' }
      & Pick<CardIssuerCardholder, 'id'>
    )> }
  ) }
);

export type GetUserInvolvementForEcmDocumentsQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type GetUserInvolvementForEcmDocumentsQuery = (
  { __typename?: 'Query' }
  & { getUserInvolvementForEcmDocuments?: Maybe<(
    { __typename?: 'GetUserInvolvementForEcmDocumentResponse' }
    & { responsiblePerson: (
      { __typename?: 'GetUserInvolvementForEcmDocumentCount' }
      & Pick<GetUserInvolvementForEcmDocumentCount, 'count'>
    ), notifyPerson: (
      { __typename?: 'GetUserInvolvementForEcmDocumentCount' }
      & Pick<GetUserInvolvementForEcmDocumentCount, 'count'>
    ) }
  )> }
);

export type GetAllUsersEmailsQueryVariables = Exact<{
  filters?: InputMaybe<OrganizationMembershipsFiltersInput>;
  sortBy?: InputMaybe<MemberSortInput>;
}>;


export type GetAllUsersEmailsQuery = (
  { __typename?: 'Query' }
  & { organizationMemberships: Array<(
    { __typename?: 'User' }
    & Pick<User, 'email'>
  )> }
);

export type ApproversQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type ApproversQuery = (
  { __typename?: 'Query' }
  & { approvers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'roles' | 'avatarUrl'>
  )> }
);

export type OrganizationMembershipCountByStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationMembershipCountByStatusQuery = (
  { __typename?: 'Query' }
  & { organizationMembershipCountByStatus: (
    { __typename?: 'OrganizationMembershipCountByStatus' }
    & Pick<OrganizationMembershipCountByStatus, 'active' | 'inActive' | 'pending'>
  ) }
);

export type TeamFieldOptionsQueryVariables = Exact<{
  input: TeamsPageBasedPaginationInput;
  queries?: InputMaybe<TeamQueryInput>;
  filters?: InputMaybe<TeamsFilterInput>;
  sortBy?: InputMaybe<TeamsSortInput>;
}>;


export type TeamFieldOptionsQuery = (
  { __typename?: 'Query' }
  & { teams?: Maybe<(
    { __typename?: 'PageBasedTeamList' }
    & { records: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  )> }
);

export type SelectedTeamsOptionsQueryVariables = Exact<{
  input: TeamsPageBasedPaginationInput;
  queries?: InputMaybe<TeamQueryInput>;
  filters?: InputMaybe<TeamsFilterInput>;
  sortBy?: InputMaybe<TeamsSortInput>;
}>;


export type SelectedTeamsOptionsQuery = (
  { __typename?: 'Query' }
  & { teams?: Maybe<(
    { __typename?: 'PageBasedTeamList' }
    & { records: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  )> }
);

export type TeamInvolvementsByMembershipIdQueryVariables = Exact<{
  input: TeamsPageBasedPaginationInput;
  filters?: InputMaybe<TeamsFilterInput>;
  sortBy?: InputMaybe<TeamsSortInput>;
}>;


export type TeamInvolvementsByMembershipIdQuery = (
  { __typename?: 'Query' }
  & { teamInvolvementsByMembershipId?: Maybe<(
    { __typename?: 'PageBasedTeamList' }
    & { records: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  )> }
);

export type GetAccountPayableNumberByMembershipIdQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type GetAccountPayableNumberByMembershipIdQuery = (
  { __typename?: 'Query' }
  & { contactByEmployeeMembershipId?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'accountsPayableNumber'>
    & { name: (
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    ) }
  )> }
);

export type GetContactEmployeeTypeByMembershipIdQueryVariables = Exact<{
  membershipId: Scalars['String']['input'];
}>;


export type GetContactEmployeeTypeByMembershipIdQuery = (
  { __typename?: 'Query' }
  & { contactByEmployeeMembershipId?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { name: (
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    ), employeeMembership?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type GetNextFreeAccountsPayableNumberForEmployeesQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;


export type GetNextFreeAccountsPayableNumberForEmployeesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNextFreeAccountsPayableNumberForEmployees'>
);

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamBodyInput;
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )> }
);

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateTeamBodyInput;
}>;


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )> }
);

export type SetTeamSettingsForCreditCardsMutationVariables = Exact<{
  input: SetTeamSettingsForCreditCardsInput;
}>;


export type SetTeamSettingsForCreditCardsMutation = (
  { __typename?: 'Mutation' }
  & { setTeamSettingsForCreditCards: (
    { __typename?: 'TeamSettingsForCreditCards' }
    & Pick<TeamSettingsForCreditCards, 'linkedCardIds' | 'teamAdminCanManageCards'>
  ) }
);

export type SetTeamIsArchivedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
}>;


export type SetTeamIsArchivedMutation = (
  { __typename?: 'Mutation' }
  & { setTeamIsArchived?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'isArchived'>
  )> }
);

export type TeamsQueryVariables = Exact<{
  input: TeamsPageBasedPaginationInput;
  queries?: InputMaybe<TeamQueryInput>;
  filters?: InputMaybe<TeamsFilterInput>;
  sortBy?: InputMaybe<TeamsSortInput>;
}>;


export type TeamsQuery = (
  { __typename?: 'Query' }
  & { teams?: Maybe<(
    { __typename?: 'PageBasedTeamList' }
    & { records: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'isArchived'>
      & { members: Array<(
        { __typename?: 'TeamMember' }
        & Pick<TeamMember, 'isAdmin'>
        & { membership: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
        ) }
      )> }
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  )> }
);

export type TeamQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TeamQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'isArchived' | 'updatedAt'>
    & { members: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'isAdmin'>
      & { membership: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
      ) }
    )> }
  )> }
);

export type TeamsByNameQueryVariables = Exact<{
  input: TeamsPageBasedPaginationInput;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type TeamsByNameQuery = (
  { __typename?: 'Query' }
  & { teamsByName?: Maybe<(
    { __typename?: 'PageBasedTeamList' }
    & { records: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    )> }
  )> }
);

export type TeamSettingsForCreditCardsQueryVariables = Exact<{
  input: TeamSettingsForCreditCardsInput;
}>;


export type TeamSettingsForCreditCardsQuery = (
  { __typename?: 'Query' }
  & { teamSettingsForCreditCards: (
    { __typename?: 'TeamSettingsForCreditCards' }
    & Pick<TeamSettingsForCreditCards, 'teamAdminCanManageCards' | 'linkedCardIds'>
    & { limitThresholds?: Maybe<(
      { __typename?: 'CreditCardLimitThresholds' }
      & { VIRTUAL?: Maybe<(
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
      )>, PHYSICAL?: Maybe<(
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
      )>, SINGLE_USE?: Maybe<(
        { __typename?: 'TransactionAmount' }
        & Pick<TransactionAmount, 'value' | 'currency' | 'precision'>
      )> }
    )> }
  ) }
);

export type CountTeamsQueryVariables = Exact<{
  filters?: InputMaybe<TeamsFilterInput>;
}>;


export type CountTeamsQuery = (
  { __typename?: 'Query' }
  & { countTeams?: Maybe<(
    { __typename?: 'TeamCount' }
    & Pick<TeamCount, 'total'>
  )> }
);

export type WorkflowTemplateDataFragment = (
  { __typename?: 'WorkflowTemplate' }
  & Pick<WorkflowTemplate, 'id' | 'name' | 'description' | 'isArchived'>
);


export type WorkflowTemplateDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CreateWorkflowTemplateMutationVariables = Exact<{
  input: CreateWorkflowTemplateInput;
}>;


export type CreateWorkflowTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createWorkflowTemplate: (
    { __typename?: 'WorkflowTemplate' }
    & WorkflowTemplateDataFragment
  ) }
);

export type GetWorkflowTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetWorkflowTemplateQuery = (
  { __typename?: 'Query' }
  & { workflowTemplate: (
    { __typename?: 'WorkflowTemplate' }
    & { steps?: Maybe<Array<(
      { __typename?: 'WorkflowTemplateStep' }
      & { conditions?: Maybe<Array<(
        { __typename?: 'WorkflowTemplateCondition' }
        & Pick<WorkflowTemplateCondition, 'field' | 'operator' | 'values'>
      )>>, resolvers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )>>> }
    )>> }
    & WorkflowTemplateDataFragment
  ) }
);

export type UpdateWorkflowTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateWorkflowTemplateInput;
}>;


export type UpdateWorkflowTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkflowTemplate: (
    { __typename?: 'WorkflowTemplate' }
    & { steps?: Maybe<Array<(
      { __typename?: 'WorkflowTemplateStep' }
      & { conditions?: Maybe<Array<(
        { __typename?: 'WorkflowTemplateCondition' }
        & Pick<WorkflowTemplateCondition, 'field' | 'operator' | 'values'>
      )>>, resolvers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )>>> }
    )>> }
    & WorkflowTemplateDataFragment
  ) }
);

export type ArchiveWorkflowTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchiveWorkflowTemplateMutation = (
  { __typename?: 'Mutation' }
  & { archiveWorkflowTemplate: (
    { __typename?: 'WorkflowTemplate' }
    & WorkflowTemplateDataFragment
  ) }
);

export type RestoreWorkflowTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RestoreWorkflowTemplateMutation = (
  { __typename?: 'Mutation' }
  & { restoreWorkflowTemplate: (
    { __typename?: 'WorkflowTemplate' }
    & WorkflowTemplateDataFragment
  ) }
);

export type WorkflowTemplatesCountersQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkflowTemplatesCountersQuery = (
  { __typename?: 'Query' }
  & { workflowTemplatesCounters: (
    { __typename?: 'WorkflowTemplateCounters' }
    & Pick<WorkflowTemplateCounters, 'archived' | 'active'>
  ) }
);

export type WorkflowTemplatesQueryVariables = Exact<{
  input?: InputMaybe<GetWorkflowTemplatesInput>;
  sort?: InputMaybe<SortInput>;
}>;


export type WorkflowTemplatesQuery = (
  { __typename?: 'Query' }
  & { workflowTemplates: Array<(
    { __typename?: 'WorkflowTemplate' }
    & Pick<WorkflowTemplate, 'id' | 'name' | 'description' | 'createdAt'>
    & { creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'avatarUrl'>
    )>, steps?: Maybe<Array<(
      { __typename?: 'WorkflowTemplateStep' }
      & { resolvers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )>>> }
    )>> }
  )> }
);

export type WorkflowTemplatesFieldItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkflowTemplatesFieldItemsQuery = (
  { __typename?: 'Query' }
  & { approvers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, getOrgMembersAbsence: Array<(
    { __typename?: 'Absence' }
    & Pick<Absence, 'fromDate' | 'membershipId' | 'note' | 'status' | 'toDate' | 'substituteMembershipId'>
  )> }
);

export type SetTransactionInvoiceNeededMutationVariables = Exact<{
  input: SetTransactionInvoiceNeededInput;
}>;


export type SetTransactionInvoiceNeededMutation = (
  { __typename?: 'Mutation' }
  & { setTransactionInvoiceNeeded: (
    { __typename?: 'CardIssuerTransaction' }
    & Pick<CardIssuerTransaction, 'id'>
  ) }
);

export type SetTransactionInvoiceNotNeededMutationVariables = Exact<{
  input: SetTransactionInvoiceNotNeededInput;
}>;


export type SetTransactionInvoiceNotNeededMutation = (
  { __typename?: 'Mutation' }
  & { setTransactionInvoiceNotNeeded: (
    { __typename?: 'CardIssuerTransaction' }
    & Pick<CardIssuerTransaction, 'id'>
  ) }
);

export type GetCardIssuerTransactionByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCardIssuerTransactionByIdQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerTransactionById: (
    { __typename?: 'CardIssuerTransaction' }
    & TransactionDetailsDataFragment
  ) }
);

export type UnlinkTransactionFromDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
}>;


export type UnlinkTransactionFromDocumentMutation = (
  { __typename?: 'Mutation' }
  & { unlinkTransactionFromDocument?: Maybe<(
    { __typename?: 'CardIssuerTransaction' }
    & Pick<CardIssuerTransaction, 'id'>
  )> }
);

export type DeleteFileMutationVariables = Exact<{
  fileId: Scalars['String']['input'];
}>;


export type DeleteFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFile'>
);

export type InboxTransactionAssociationsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type InboxTransactionAssociationsQuery = (
  { __typename?: 'Query' }
  & { prevTransaction?: Maybe<(
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionDetailsDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  )>, nextTransaction?: Maybe<(
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionDetailsDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  )> }
);

export type ArchiveTransactionAssociationsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type ArchiveTransactionAssociationsQuery = (
  { __typename?: 'Query' }
  & { prevTransaction: (
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionDetailsDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ), nextTransaction: (
    { __typename?: 'TransactionConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'CardIssuerTransactionEdge' }
      & Pick<CardIssuerTransactionEdge, 'cursor'>
      & { node: (
        { __typename?: 'CardIssuerTransaction' }
        & TransactionDetailsDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type ListDocumentsWithNoTransactionQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  sort?: InputMaybe<SortInput>;
}>;


export type ListDocumentsWithNoTransactionQuery = (
  { __typename?: 'Query' }
  & { listInboxDocuments: (
    { __typename?: 'DocumentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DocumentEdge' }
      & Pick<DocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'Document' }
        & Pick<Document, 'isDuplicate' | 'isOverdue' | 'id' | 'status' | 'createdAt' | 'hasAttachments'>
        & { documentFile?: Maybe<(
          { __typename?: 'DocumentFile' }
          & Pick<DocumentFile, 'name'>
        )>, contact?: Maybe<(
          { __typename?: 'ContactField' }
          & { value: (
            { __typename?: 'Contact' }
            & Pick<Contact, 'id'>
            & { name: (
              { __typename?: 'StringField' }
              & Pick<StringField, 'value'>
            ) }
          ) }
        )>, currency?: Maybe<(
          { __typename?: 'DocumentCurrencyField' }
          & Pick<DocumentCurrencyField, 'value'>
        )>, extractedContact?: Maybe<(
          { __typename?: 'ExtractedContact' }
          & Pick<ExtractedContact, 'cachingKey'>
          & { name?: Maybe<(
            { __typename?: 'StringField' }
            & Pick<StringField, 'value'>
          )> }
        )>, amount?: Maybe<(
          { __typename?: 'FloatField' }
          & Pick<FloatField, 'value'>
        )>, invoiceNumber?: Maybe<(
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        )>, invoiceDate?: Maybe<(
          { __typename?: 'DateField' }
          & Pick<DateField, 'value'>
        )> }
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type CreateTransactionCommentMutationVariables = Exact<{
  transactionId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  mentionedMembershipIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type CreateTransactionCommentMutation = (
  { __typename?: 'Mutation' }
  & { createTransactionComment: (
    { __typename?: 'CreateTransactionCommentResponse' }
    & Pick<CreateTransactionCommentResponse, 'id'>
  ) }
);

export type GetTransactionHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTransactionHistoryQuery = (
  { __typename?: 'Query' }
  & { getCardIssuerTransactionById: (
    { __typename?: 'CardIssuerTransaction' }
    & Pick<CardIssuerTransaction, 'id' | 'status'>
    & { timeline?: Maybe<Array<(
      { __typename: 'TransactionAmountChangedEvent' }
      & TransactionAmountChangedEventDataFragment
    ) | (
      { __typename: 'TransactionCommentEvent' }
      & TransactionCommentEventDataFragment
    ) | (
      { __typename: 'TransactionMarkedAsExportedEvent' }
      & TransactionMarkedAsExportedEventDataFragment
    ) | (
      { __typename: 'TransactionMarkedAsInvoiceNeededEvent' }
      & TransactionMarkedAsInvoiceNeededEventDataFragment
    ) | (
      { __typename: 'TransactionMarkedAsInvoiceNotNeededEvent' }
      & TransactionMarkedAsInvoiceNotNeededEventDataFragment
    ) | (
      { __typename: 'TransactionReconciledEvent' }
      & TransactionReconciledEventDataFragment
    ) | (
      { __typename: 'TransactionStatusUpdatedEvent' }
      & TransactionStatusUpdatedEventDataFragment
    )>> }
  ) }
);

export type GetPageBasedTransactionDownloadHistoryQueryVariables = Exact<{
  input: TransactionDownloadHistoryPageBasedPaginationInput;
  sortBy?: InputMaybe<TransactionDownloadHistorySortInput>;
}>;


export type GetPageBasedTransactionDownloadHistoryQuery = (
  { __typename?: 'Query' }
  & { getPageBasedTransactionDownloadHistory: (
    { __typename?: 'PageBasedTransactionDownloadHistoryList' }
    & { records: Array<(
      { __typename?: 'TransactionDownloadHistory' }
      & Pick<TransactionDownloadHistory, 'id' | 'locale' | 'createdAt' | 'updatedAt'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'avatarUrl'>
      )>, file?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'url'>
      )>, filters: (
        { __typename?: 'TransactionDownloadHistoryFilter' }
        & Pick<TransactionDownloadHistoryFilter, 'statuses' | 'types' | 'categories' | 'invoiceAssociationStatuses' | 'merchantNames'>
        & { cards?: Maybe<Array<(
          { __typename?: 'CardIssuerCard' }
          & Pick<CardIssuerCard, 'id' | 'refNum'>
          & { cardholder: (
            { __typename?: 'CardholderMembership' }
            & Pick<CardholderMembership, 'firstName' | 'lastName'>
          ) }
        )>>, dateRangeFilters?: Maybe<(
          { __typename?: 'CardIssuerTransactionDateFilter' }
          & { transactionCreatedAt: (
            { __typename?: 'DateFilterType' }
            & Pick<DateFilterType, 'dateFrom' | 'dateTo'>
          ) }
        )> }
      ) }
    )>, pageInfo?: Maybe<(
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'totalCount' | 'pageSize' | 'currentPage' | 'pageCount'>
    )> }
  ) }
);

export type BookingAccountDataFragment = (
  { __typename?: 'BookingAccount' }
  & Pick<BookingAccount, 'id' | 'type' | 'accountCode' | 'name' | 'readableName' | 'createdAt' | 'isArchived'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type BookingAccountDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BookingAccountInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<BookingAccountPaginationInput>;
  filters?: InputMaybe<BookingAccountFilterInput>;
  queries?: InputMaybe<BookingAccountQueryInput>;
  sortBy?: InputMaybe<BookingAccountSortInput>;
}>;


export type BookingAccountInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { bookingAccountPagination: (
    { __typename?: 'BookingAccountPaginationResult' }
    & { records: Array<(
      { __typename?: 'BookingAccount' }
      & BookingAccountDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    ) }
  ) }
);

export type NextFreeAccountsPayableNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type NextFreeAccountsPayableNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'nextFreeAccountsPayableNumber'>
);

export type ContactsImportedForCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactsImportedForCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactsImportedForCompany'>
);

export type ContactByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type ContactByNameQuery = (
  { __typename?: 'Query' }
  & { contactByName?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'relationshipType'>
    & { name: (
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    ) }
  )> }
);

export type ContactByAccountsNumberQueryVariables = Exact<{
  accountsNumber: Scalars['String']['input'];
}>;


export type ContactByAccountsNumberQuery = (
  { __typename?: 'Query' }
  & { contactByAccountsNumber?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type ContactByAccountsPayableNumberQueryVariables = Exact<{
  accountsPayableNumber: Scalars['AccountsPayableNumber']['input'];
}>;


export type ContactByAccountsPayableNumberQuery = (
  { __typename?: 'Query' }
  & { contactByAccountsPayableNumber?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type SetNextFreeAccountsPayableNumberForContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  accountsPayableNumber?: InputMaybe<Scalars['AccountsPayableNumber']['input']>;
}>;


export type SetNextFreeAccountsPayableNumberForContactMutation = (
  { __typename?: 'Mutation' }
  & { setNextFreeAccountsPayableNumberForContact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactDataFragment
  )> }
);

export type CountAllContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountAllContactsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countContacts'>
);

export type CountExportableContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountExportableContactsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countExportableContacts'>
);

export type CountWithoutAccountsPayableNumberContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountWithoutAccountsPayableNumberContactsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countContacts'>
);

export type CostCenterDataFragment = (
  { __typename?: 'CostCenter' }
  & Pick<CostCenter, 'id' | 'name' | 'type' | 'code' | 'readableName' | 'isArchived' | 'approverIds' | 'createdAt'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name' | 'avatarUrl'>
  )>, approvers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>> }
);


export type CostCenterDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CostCenterSearchQueryVariables = Exact<{
  input: CostCenterSearchInput;
}>;


export type CostCenterSearchQuery = (
  { __typename?: 'Query' }
  & { costCenterSearch: Array<(
    { __typename?: 'CostCenter' }
    & CostCenterDataFragment
  )> }
);

export type CostCenterPaginationQueryVariables = Exact<{
  input?: InputMaybe<CostCenterPaginationInput>;
  filters?: InputMaybe<CostCenterFilterInput>;
  sortBy?: InputMaybe<CostCenterSortInput>;
}>;


export type CostCenterPaginationQuery = (
  { __typename?: 'Query' }
  & { costCenterPagination: (
    { __typename?: 'CostCenterPaginationResult' }
    & { records: Array<(
      { __typename?: 'CostCenter' }
      & CostCenterDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    ) }
  ) }
);

export type CostCenterInfiniteScrollPaginationQueryVariables = Exact<{
  input?: InputMaybe<CostCenterPaginationInput>;
  filters?: InputMaybe<CostCenterFilterInput>;
  queries?: InputMaybe<CostCenterQueryInput>;
  sortBy?: InputMaybe<CostCenterSortInput>;
}>;


export type CostCenterInfiniteScrollPaginationQuery = (
  { __typename?: 'Query' }
  & { costCenterPagination: (
    { __typename?: 'CostCenterPaginationResult' }
    & { records: Array<(
      { __typename?: 'CostCenter' }
      & CostCenterDataFragment
    )>, pageInfo: (
      { __typename?: 'SimplePageInfoDetails' }
      & Pick<SimplePageInfoDetails, 'currentPage' | 'pageCount' | 'pageSize' | 'totalCount'>
    ) }
  ) }
);

export type CountCostCentersQueryVariables = Exact<{
  filters?: InputMaybe<CostCenterFilterInput>;
}>;


export type CountCostCentersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countCostCenters'>
);

export type CostCenterNameFilterQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCenterFilterOptionsInput>;
}>;


export type CostCenterNameFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCenterNameFilter'>
);

export type CostCenterCodeFilterQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCenterFilterOptionsInput>;
}>;


export type CostCenterCodeFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCenterCodeFilter'>
);

export type OrganizationCreditCardsSettingsDataFragment = (
  { __typename?: 'OrganizationCreditCardsSettings' }
  & Pick<OrganizationCreditCardsSettings, 'tradeName' | 'legalForm' | 'foundationDate' | 'country' | 'accountType' | 'nationalRegisterCourt' | 'nationalRegisterId' | 'nationalRegisterType' | 'tradeRegisterNumber' | 'vatId' | 'billingEmail' | 'isCompanyNamePrintable' | 'deliveryAddressSameAsBillingAddress'>
  & { billingAddress: (
    { __typename?: 'OrganizationCreditCardsAddress' }
    & Pick<OrganizationCreditCardsAddress, 'country' | 'city' | 'postalCode' | 'streetFull' | 'streetName' | 'streetNumber' | 'streetAdditional'>
  ), deliveryAddress?: Maybe<(
    { __typename?: 'OrganizationCreditCardsAddress' }
    & Pick<OrganizationCreditCardsAddress, 'country' | 'city' | 'postalCode' | 'streetFull' | 'streetName' | 'streetNumber' | 'streetAdditional'>
  )>, processingAccount: (
    { __typename?: 'OrganizationCreditCardsProcessingAccount' }
    & Pick<OrganizationCreditCardsProcessingAccount, 'bankName' | 'iban'>
  ) }
);


export type OrganizationCreditCardsSettingsDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentDataFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'isDuplicate' | 'isOverdue' | 'isWaitingForClarification' | 'status' | 'isEInvoice' | 'createdAt' | 'hasTransactionLinked' | 'globalDocumentId'>
  & { contact?: Maybe<(
    { __typename?: 'ContactField' }
    & { value: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'accountsPayableNumber' | 'accountsReceivableNumber'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    ) }
  )>, eInvoice?: Maybe<(
    { __typename?: 'DocumentEInvoiceField' }
    & Pick<DocumentEInvoiceField, 'format' | 'error'>
  )>, invoiceNumber?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, purchaseOrderData?: Maybe<(
    { __typename?: 'PurchaseOrderData' }
    & Pick<PurchaseOrderData, 'orderNumber'>
  )>, category?: Maybe<(
    { __typename?: 'DocumentCategoryField' }
    & { direction?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, documentType?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )> }
  )>, invoiceDate?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, accountingArea?: Maybe<(
    { __typename?: 'AccountingAreaField' }
    & { value: (
      { __typename?: 'AccountingArea' }
      & Pick<AccountingArea, 'id' | 'name'>
    ) }
  )>, bookings?: Maybe<Array<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'postingText' | 'artistSocialInsuranceCode'>
    & { dueDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value'>
    )>, note?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, costCenter?: Maybe<(
      { __typename?: 'CostCenterField' }
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'name' | 'readableName'>
      ) }
    )>, costObject?: Maybe<(
      { __typename?: 'CostCenterField' }
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'name' | 'readableName'>
      ) }
    )>, extraCostInfo?: Maybe<(
      { __typename?: 'CostCenterField' }
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'name' | 'readableName'>
      ) }
    )>, generalLedgerAccount?: Maybe<(
      { __typename?: 'BookingAccountField' }
      & { value: (
        { __typename?: 'BookingAccount' }
        & Pick<BookingAccount, 'id' | 'accountCode' | 'readableName'>
      ) }
    )>, vatRate?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value'>
    )>, discountAmount?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value'>
    )>, discountPercentage?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value'>
    )>, discountPaymentDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value'>
    )>, projectCode?: Maybe<(
      { __typename?: 'ProjectCodeField' }
      & { value: (
        { __typename?: 'ProjectCode' }
        & Pick<ProjectCode, 'id' | 'code' | 'name'>
      ) }
    )> }
  )>>, invoiceDeliveryDate?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, invoicePostingDate?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, amount?: Maybe<(
    { __typename?: 'FloatField' }
    & Pick<FloatField, 'value'>
  )>, currency?: Maybe<(
    { __typename?: 'DocumentCurrencyField' }
    & Pick<DocumentCurrencyField, 'value'>
  )>, requestedAt?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, requester?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, iban?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, swiftCode?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, createTransfer?: Maybe<(
    { __typename?: 'BooleanField' }
    & Pick<BooleanField, 'value'>
  )>, approvers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>>, approvedBy?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>>, suggestedApprovers?: Maybe<(
    { __typename?: 'SuggestedApproversField' }
    & { value: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )> }
  )>, documentFile?: Maybe<(
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'name' | 'url' | 'versions' | 'pages'>
  )>, originalDocumentId?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, extractedContact?: Maybe<(
    { __typename?: 'ExtractedContact' }
    & Pick<ExtractedContact, 'id' | 'cachingKey'>
    & { name?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, iban?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, taxNumber?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, vatId?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )> }
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & TagFragment
  )> }
);


export type DocumentDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type InboxInvoiceDocumentDataFragment = (
  { __typename?: 'InboxInvoiceDocument' }
  & Pick<InboxInvoiceDocument, 'id' | 'invoiceNumber' | 'status' | 'isPayable' | 'invoiceDeliveryDate' | 'createdAt' | 'isOverdue' | 'isDuplicate' | 'invoiceDate' | 'isEInvoice'>
  & { eInvoice?: Maybe<(
    { __typename?: 'DocumentEInvoiceField' }
    & Pick<DocumentEInvoiceField, 'format' | 'error'>
  )>, bookings?: Maybe<Array<Maybe<(
    { __typename?: 'InboxInvoiceDocumentBooking' }
    & Pick<InboxInvoiceDocumentBooking, 'artistSocialInsuranceCode' | 'dueDate' | 'discountAmount' | 'discountPercentage' | 'discountPaymentDate' | 'paymentConditionId'>
    & { costCenter?: Maybe<(
      { __typename?: 'InboxInvoiceDocumentCostCenter' }
      & Pick<InboxInvoiceDocumentCostCenter, 'id' | 'readableName' | 'cachingKey'>
    )>, costObject?: Maybe<(
      { __typename?: 'InboxInvoiceDocumentCostCenter' }
      & Pick<InboxInvoiceDocumentCostCenter, 'id' | 'readableName' | 'cachingKey'>
    )>, extraCostInfo?: Maybe<(
      { __typename?: 'InboxInvoiceDocumentCostCenter' }
      & Pick<InboxInvoiceDocumentCostCenter, 'id' | 'readableName' | 'cachingKey'>
    )> }
  )>>>, contact?: Maybe<(
    { __typename?: 'InboxInvoiceDocumentContact' }
    & MakeOptional<Pick<InboxInvoiceDocumentContact, 'id' | 'name' | 'cachingKey' | 'accountsPayableNumber' | 'accountsReceivableNumber'>, 'accountsReceivableNumber'>
  )>, payment?: Maybe<(
    { __typename?: 'InboxInvoiceDocumentPayment' }
    & Pick<InboxInvoiceDocumentPayment, 'id' | 'isPaid' | 'method'>
  )>, amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, purchaseOrderData?: Maybe<(
    { __typename?: 'PurchaseOrderData' }
    & Pick<PurchaseOrderData, 'orderNumber' | 'integrationType'>
  )>, purchaseOrders?: Maybe<Array<Maybe<(
    { __typename?: 'PurchaseOrder' }
    & Pick<PurchaseOrder, 'orderNumber'>
    & { goodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceipt' }
      & Pick<GoodsReceipt, '_id'>
    )>>> }
  )>>>, associatedDocuments?: Maybe<Array<Maybe<(
    { __typename: 'GoodsReceipt' }
    & Pick<GoodsReceipt, '_id' | 'receiptNumber'>
    & { purchaseOrders?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrder' }
      & Pick<PurchaseOrder, '_id' | 'orderNumber'>
    )>>> }
  ) | (
    { __typename: 'PurchaseOrder' }
    & Pick<PurchaseOrder, '_id' | 'orderNumber'>
    & { goodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceipt' }
      & Pick<GoodsReceipt, '_id' | 'receiptNumber'>
    )>>> }
  )>>>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);


export type InboxInvoiceDocumentDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ApprovalInvoiceDocumentDataFragment = (
  { __typename?: 'ApprovalInvoiceDocument' }
  & Pick<ApprovalInvoiceDocument, 'id' | 'invoiceNumber' | 'status' | 'isPayable' | 'invoiceDeliveryDate' | 'createdAt' | 'isOverdue' | 'invoiceDate' | 'iban' | 'isEInvoice' | 'requestedAt'>
  & { eInvoice?: Maybe<(
    { __typename?: 'DocumentEInvoiceField' }
    & Pick<DocumentEInvoiceField, 'format' | 'error'>
  )>, accountingArea?: Maybe<(
    { __typename?: 'ApprovalInvoiceDocumentAccountingArea' }
    & Pick<ApprovalInvoiceDocumentAccountingArea, 'id' | 'name' | 'cachingKey'>
  )>, bookings?: Maybe<Array<Maybe<(
    { __typename?: 'ApprovalInvoiceBooking' }
    & Pick<ApprovalInvoiceBooking, 'artistSocialInsuranceCode' | 'dueDate' | 'discountAmount' | 'discountPercentage' | 'discountPaymentDate' | 'paymentConditionId'>
    & { costCenter?: Maybe<(
      { __typename?: 'ApprovalInvoiceCostCenter' }
      & Pick<ApprovalInvoiceCostCenter, 'id' | 'readableName' | 'cachingKey'>
    )>, costObject?: Maybe<(
      { __typename?: 'ApprovalInvoiceCostCenter' }
      & Pick<ApprovalInvoiceCostCenter, 'id' | 'readableName' | 'cachingKey'>
    )>, extraCostInfo?: Maybe<(
      { __typename?: 'ApprovalInvoiceCostCenter' }
      & Pick<ApprovalInvoiceCostCenter, 'id' | 'readableName' | 'cachingKey'>
    )> }
  )>>>, contact?: Maybe<(
    { __typename?: 'ApprovalInvoiceContact' }
    & MakeOptional<Pick<ApprovalInvoiceContact, 'id' | 'name' | 'cachingKey' | 'accountsPayableNumber' | 'accountsReceivableNumber'>, 'accountsReceivableNumber'>
  )>, payment?: Maybe<(
    { __typename?: 'ApprovalInvoicePayment' }
    & Pick<ApprovalInvoicePayment, 'id' | 'isPaid' | 'method'>
  )>, amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, purchaseOrderData?: Maybe<(
    { __typename?: 'PurchaseOrderData' }
    & Pick<PurchaseOrderData, 'orderNumber' | 'integrationType'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, requester?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, approvers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>> }
);


export type ApprovalInvoiceDocumentDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ArchiveInvoiceDocumentsDataFragment = (
  { __typename?: 'ArchiveInvoiceDocument' }
  & Pick<ArchiveInvoiceDocument, 'id' | 'globalDocumentId' | 'invoiceNumber' | 'status' | 'isPayable' | 'invoiceDeliveryDate' | 'isOverdue' | 'isDuplicate' | 'invoiceDate' | 'createdAt' | 'updatedAt' | 'invoiceCategoryType' | 'iban' | 'swiftCode' | 'requestedAt'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, contact?: Maybe<(
    { __typename?: 'ArchiveInvoiceDocumentContact' }
    & MakeOptional<Pick<ArchiveInvoiceDocumentContact, 'id' | 'name' | 'cachingKey' | 'accountsPayableNumber' | 'accountsReceivableNumber'>, 'accountsReceivableNumber'>
  )>, bookings: Array<(
    { __typename?: 'ArchiveInvoiceDocumentBooking' }
    & Pick<ArchiveInvoiceDocumentBooking, 'note' | 'artistSocialInsuranceCode' | 'dueDate' | 'discountAmount' | 'discountPercentage' | 'discountPaymentDate' | 'paymentConditionId' | 'postingText'>
    & { costCenter?: Maybe<(
      { __typename?: 'ArchiveInvoiceDocumentCostCenter' }
      & Pick<ArchiveInvoiceDocumentCostCenter, 'id' | 'name' | 'code' | 'readableName' | 'cachingKey'>
    )>, costObject?: Maybe<(
      { __typename?: 'ArchiveInvoiceDocumentCostCenter' }
      & Pick<ArchiveInvoiceDocumentCostCenter, 'id' | 'name' | 'code' | 'readableName' | 'cachingKey'>
    )>, extraCostInfo?: Maybe<(
      { __typename?: 'ArchiveInvoiceDocumentCostCenter' }
      & Pick<ArchiveInvoiceDocumentCostCenter, 'id' | 'name' | 'code' | 'readableName' | 'cachingKey'>
    )>, generalLedgerAccount?: Maybe<(
      { __typename?: 'ArchiveInvoiceDocumentGeneralLedgerAccount' }
      & Pick<ArchiveInvoiceDocumentGeneralLedgerAccount, 'id' | 'name' | 'accountCode' | 'readableName' | 'cachingKey'>
    )> }
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & TagFragment
  )>, payment?: Maybe<(
    { __typename?: 'ArchiveInvoiceDocumentPayment' }
    & Pick<ArchiveInvoiceDocumentPayment, 'id' | 'isPaid' | 'paidAt' | 'method' | 'cachingKey'>
  )>, eInvoice?: Maybe<(
    { __typename?: 'DocumentEInvoiceField' }
    & Pick<DocumentEInvoiceField, 'format' | 'error'>
  )>, accountingArea?: Maybe<(
    { __typename?: 'ArchiveInvoiceDocumentAccountingArea' }
    & Pick<ArchiveInvoiceDocumentAccountingArea, 'id' | 'name' | 'cachingKey'>
  )>, requester?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, approvers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, purchaseOrderData?: Maybe<(
    { __typename?: 'PurchaseOrderData' }
    & Pick<PurchaseOrderData, 'orderNumber' | 'integrationType'>
  )>, purchaseOrders?: Maybe<Array<Maybe<(
    { __typename?: 'PurchaseOrder' }
    & Pick<PurchaseOrder, 'orderNumber'>
    & { goodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceipt' }
      & Pick<GoodsReceipt, '_id'>
    )>>> }
  )>>>, associatedDocuments?: Maybe<Array<Maybe<(
    { __typename: 'GoodsReceipt' }
    & Pick<GoodsReceipt, '_id' | 'receiptNumber'>
    & { purchaseOrders?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrder' }
      & Pick<PurchaseOrder, '_id' | 'orderNumber'>
    )>>> }
  ) | (
    { __typename: 'PurchaseOrder' }
    & Pick<PurchaseOrder, '_id' | 'orderNumber'>
    & { goodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceipt' }
      & Pick<GoodsReceipt, '_id' | 'receiptNumber'>
    )>>> }
  )>>>, documentFile?: Maybe<(
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'name'>
  )> }
);


export type ArchiveInvoiceDocumentsDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentListDataFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'isOverdue' | 'isWaitingForClarification' | 'isDuplicate' | 'isPayable' | 'isEInvoice' | 'status' | 'createdAt'>
  & { eInvoice?: Maybe<(
    { __typename?: 'DocumentEInvoiceField' }
    & Pick<DocumentEInvoiceField, 'format' | 'error'>
  )>, contact?: Maybe<(
    { __typename?: 'ContactField' }
    & { value: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'accountsPayableNumber' | 'accountsReceivableNumber'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    ) }
  )>, category?: Maybe<(
    { __typename?: 'DocumentCategoryField' }
    & { direction?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, documentType?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )> }
  )>, invoiceNumber?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, amount?: Maybe<(
    { __typename?: 'FloatField' }
    & Pick<FloatField, 'value'>
  )>, currency?: Maybe<(
    { __typename?: 'DocumentCurrencyField' }
    & Pick<DocumentCurrencyField, 'value'>
  )>, requestedAt?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, invoiceDate?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, invoiceDeliveryDate?: Maybe<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'value'>
  )>, extractedContact?: Maybe<(
    { __typename?: 'ExtractedContact' }
    & Pick<ExtractedContact, 'id' | 'cachingKey'>
    & { name?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )> }
  )>, iban?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, swiftCode?: Maybe<(
    { __typename?: 'StringField' }
    & Pick<StringField, 'value'>
  )>, accountingArea?: Maybe<(
    { __typename?: 'AccountingAreaField' }
    & { value: (
      { __typename?: 'AccountingArea' }
      & Pick<AccountingArea, 'id' | 'name'>
    ) }
  )>, bookings?: Maybe<Array<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'artistSocialInsuranceCode' | 'postingText'>
    & { costCenter?: Maybe<(
      { __typename?: 'CostCenterField' }
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'readableName'>
      ) }
    )>, costObject?: Maybe<(
      { __typename?: 'CostCenterField' }
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'readableName'>
      ) }
    )>, extraCostInfo?: Maybe<(
      { __typename?: 'CostCenterField' }
      & { value: (
        { __typename?: 'CostCenter' }
        & Pick<CostCenter, 'id' | 'name' | 'readableName'>
      ) }
    )>, generalLedgerAccount?: Maybe<(
      { __typename?: 'BookingAccountField' }
      & { value: (
        { __typename?: 'BookingAccount' }
        & Pick<BookingAccount, 'id' | 'readableName'>
      ) }
    )>, bookingKey?: Maybe<(
      { __typename?: 'BookingKeyField' }
      & { value: (
        { __typename?: 'BookingKey' }
        & Pick<BookingKey, 'id' | 'readableName'>
      ) }
    )>, dueDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value'>
    )>, note?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, discountAmount?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value'>
    )>, discountPercentage?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value'>
    )>, discountPaymentDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value'>
    )> }
  )>>, requester?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>, approvers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatarUrl'>
  )>>, approvedBy?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>>, paymentState: (
    { __typename: 'PaidDocumentState' }
    & Pick<PaidDocumentState, 'paidAt' | 'method'>
  ) | { __typename: 'UnpaidDocumentState' }, purchaseOrderData?: Maybe<(
    { __typename?: 'PurchaseOrderData' }
    & Pick<PurchaseOrderData, 'orderNumber' | 'integrationType'>
  )>, purchaseOrders?: Maybe<Array<Maybe<(
    { __typename?: 'PurchaseOrder' }
    & Pick<PurchaseOrder, 'orderNumber'>
    & { goodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceipt' }
      & Pick<GoodsReceipt, '_id'>
    )>>> }
  )>>>, associatedDocuments?: Array<Maybe<(
    { __typename: 'GoodsReceipt' }
    & Pick<GoodsReceipt, '_id' | 'receiptNumber'>
    & { purchaseOrders?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrder' }
      & Pick<PurchaseOrder, '_id' | 'orderNumber'>
    )>>> }
  ) | (
    { __typename: 'PurchaseOrder' }
    & Pick<PurchaseOrder, '_id' | 'orderNumber'>
    & { goodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceipt' }
      & Pick<GoodsReceipt, '_id' | 'receiptNumber'>
    )>>> }
  )>>, tags: Array<(
    { __typename?: 'Tag' }
    & TagFragment
  )>, documentFile?: Maybe<(
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'name' | 'size'>
  )> }
);


export type DocumentListDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BookingKeyArchivedErrorDataFragment = (
  { __typename?: 'BookingKeyArchivedError' }
  & { bookingKey: (
    { __typename?: 'BookingKey' }
    & Pick<BookingKey, 'id' | 'readableName'>
  ) }
);


export type BookingKeyArchivedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CostCenterArchivedErrorDataFragment = (
  { __typename?: 'CostCenterArchivedError' }
  & { costCenter: (
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'id' | 'readableName'>
  ) }
);


export type CostCenterArchivedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CostObjectArchivedErrorDataFragment = (
  { __typename?: 'CostObjectArchivedError' }
  & { costObject: (
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'id' | 'readableName'>
  ) }
);


export type CostObjectArchivedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExtraCostInfoArchivedErrorDataFragment = (
  { __typename?: 'ExtraCostInfoArchivedError' }
  & { extraCostInfo: (
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'id' | 'readableName'>
  ) }
);


export type ExtraCostInfoArchivedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentCannotBeModifedErrorDataFragment = (
  { __typename?: 'DocumentCannotBeModifedError' }
  & Pick<DocumentCannotBeModifedError, 'status'>
);


export type DocumentCannotBeModifedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentIsAlreadyRequestedForApprovalErrorDataFragment = (
  { __typename?: 'DocumentIsAlreadyRequestedForApprovalError' }
  & Pick<DocumentIsAlreadyRequestedForApprovalError, 'status'>
);


export type DocumentIsAlreadyRequestedForApprovalErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GeneralLedgerArchivedErrorDataFragment = (
  { __typename?: 'GeneralLedgerArchivedError' }
  & { generalLedgerAccount: (
    { __typename?: 'BookingAccount' }
    & Pick<BookingAccount, 'id' | 'readableName'>
  ) }
);


export type GeneralLedgerArchivedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ContactDoesNotExistErrorDataFragment = (
  { __typename?: 'ContactDoesNotExistError' }
  & Pick<ContactDoesNotExistError, 'kind' | 'message'>
);


export type ContactDoesNotExistErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type WorkflowTemplateArchivedErrorDataFragment = (
  { __typename?: 'WorkflowTemplateArchivedError' }
  & { workflowTemplate: (
    { __typename?: 'WorkflowTemplate' }
    & Pick<WorkflowTemplate, 'id' | 'name'>
  ) }
);


export type WorkflowTemplateArchivedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type WorkflowStepAlreadyApprovedErrorDataFragment = (
  { __typename?: 'WorkflowStepAlreadyApprovedError' }
  & { approvedByUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ) }
);


export type WorkflowStepAlreadyApprovedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type InvalidInvoiceDateErrorDataFragment = (
  { __typename?: 'InvalidInvoiceDateError' }
  & Pick<InvalidInvoiceDateError, 'invoiceDate' | 'referenceDate'>
);


export type InvalidInvoiceDateErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type InvalidDueDateErrorDataFragment = (
  { __typename?: 'InvalidDueDateError' }
  & Pick<InvalidDueDateError, 'dueDate' | 'referenceDate'>
);


export type InvalidDueDateErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type InvalidDeliveryDateErrorDataFragment = (
  { __typename?: 'InvalidDeliveryDateError' }
  & Pick<InvalidDeliveryDateError, 'deliveryDate' | 'referenceDate'>
);


export type InvalidDeliveryDateErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ProjectCodeArchivedErrorDataFragment = (
  { __typename?: 'ProjectCodeArchivedError' }
  & { projectCode: (
    { __typename?: 'ProjectCode' }
    & Pick<ProjectCode, 'id' | 'name' | 'code'>
  ) }
);


export type ProjectCodeArchivedErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BookingsErrorDataFragment = (
  { __typename?: 'BookingsError' }
  & Pick<BookingsError, 'index'>
  & { error: (
    { __typename?: 'BookingKeyArchivedError' }
    & Pick<BookingKeyArchivedError, 'kind' | 'message'>
    & BookingKeyArchivedErrorDataFragment
  ) | (
    { __typename?: 'BookingKeyChangedError' }
    & Pick<BookingKeyChangedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingKeyDocumentDirectionError' }
    & Pick<BookingKeyDocumentDirectionError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingKeyDoesNotExistError' }
    & Pick<BookingKeyDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'BookingKeyRequiredError' }
    & Pick<BookingKeyRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CashDiscountAllFieldsDoesNotExistError' }
    & Pick<CashDiscountAllFieldsDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostCenterArchivedError' }
    & Pick<CostCenterArchivedError, 'kind' | 'message'>
    & CostCenterArchivedErrorDataFragment
  ) | (
    { __typename?: 'CostCenterChangedError' }
    & Pick<CostCenterChangedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostCenterDoesNotExistError' }
    & Pick<CostCenterDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostCenterRequiredError' }
    & Pick<CostCenterRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostObjectArchivedError' }
    & Pick<CostObjectArchivedError, 'kind' | 'message'>
    & CostObjectArchivedErrorDataFragment
  ) | (
    { __typename?: 'CostObjectChangedError' }
    & Pick<CostObjectChangedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostObjectDoesNotExistError' }
    & Pick<CostObjectDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'CostObjectRequiredError' }
    & Pick<CostObjectRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'DocumentAcquisitionReversalValidationError' }
    & Pick<DocumentAcquisitionReversalValidationError, 'kind' | 'message'>
  ) | (
    { __typename?: 'DocumentHasBookingKeyButMissingGLAError' }
    & Pick<DocumentHasBookingKeyButMissingGlaError, 'kind' | 'message'>
  ) | (
    { __typename?: 'ExtraCostInfoArchivedError' }
    & Pick<ExtraCostInfoArchivedError, 'kind' | 'message'>
    & ExtraCostInfoArchivedErrorDataFragment
  ) | (
    { __typename?: 'ExtraCostInfoChangedError' }
    & Pick<ExtraCostInfoChangedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'ExtraCostInfoDoesNotExistError' }
    & Pick<ExtraCostInfoDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'GeneralLedgerArchivedError' }
    & Pick<GeneralLedgerArchivedError, 'kind' | 'message'>
    & GeneralLedgerArchivedErrorDataFragment
  ) | (
    { __typename?: 'GeneralLedgerChangedError' }
    & Pick<GeneralLedgerChangedError, 'kind' | 'message'>
  ) | (
    { __typename?: 'GeneralLedgerDoesNotExistError' }
    & Pick<GeneralLedgerDoesNotExistError, 'kind' | 'message'>
  ) | (
    { __typename?: 'GeneralLedgerRequiredError' }
    & Pick<GeneralLedgerRequiredError, 'kind' | 'message'>
  ) | (
    { __typename?: 'InvalidDiscountAmountError' }
    & Pick<InvalidDiscountAmountError, 'kind' | 'message'>
  ) | (
    { __typename?: 'InvalidDiscountPaymentDateError' }
    & Pick<InvalidDiscountPaymentDateError, 'kind' | 'message'>
  ) | (
    { __typename?: 'InvalidDiscountPercentageError' }
    & Pick<InvalidDiscountPercentageError, 'kind' | 'message'>
  ) | (
    { __typename?: 'ProjectCodeArchivedError' }
    & Pick<ProjectCodeArchivedError, 'kind' | 'message'>
    & ProjectCodeArchivedErrorDataFragment
  ) | (
    { __typename?: 'ProjectCodeDoesNotExistError' }
    & Pick<ProjectCodeDoesNotExistError, 'kind' | 'message'>
  ) }
);


export type BookingsErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionDataMismatchErrorDataFragment = (
  { __typename?: 'TransactionDataMismatchError' }
  & Pick<TransactionDataMismatchError, 'kind' | 'message'>
);


export type TransactionDataMismatchErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SumOfBookingsDoesNotMatchGrossAmountErrorDataFragment = (
  { __typename?: 'SumOfBookingsDoesNotMatchGrossAmountError' }
  & Pick<SumOfBookingsDoesNotMatchGrossAmountError, 'kind' | 'message' | 'grossAmount' | 'bookingsSum'>
);


export type SumOfBookingsDoesNotMatchGrossAmountErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentDoesNotExistErrorDataFragment = (
  { __typename?: 'DocumentDoesNotExistError' }
  & Pick<DocumentDoesNotExistError, 'kind' | 'message'>
);


export type DocumentDoesNotExistErrorDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BasicDocumentConnectionDataFragment = (
  { __typename?: 'DocumentConnection' }
  & { edges?: Maybe<Array<(
    { __typename?: 'DocumentEdge' }
    & Pick<DocumentEdge, 'cursor'>
    & { node: (
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    ) }
  )>>, pageInfo?: Maybe<(
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'totalCount'>
  )> }
);


export type BasicDocumentConnectionDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BasicInboxInvoiceDocumentConnectionDataFragment = (
  { __typename?: 'InboxInvoiceDocumentsResponse' }
  & { edges: Array<(
    { __typename?: 'InboxInvoiceDocumentEdge' }
    & Pick<InboxInvoiceDocumentEdge, 'cursor'>
    & { node: (
      { __typename?: 'InboxInvoiceDocument' }
      & Pick<InboxInvoiceDocument, 'id'>
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'startCursor' | 'hasNextPage' | 'totalCount'>
  ) }
);


export type BasicInboxInvoiceDocumentConnectionDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BasicApprovalDocumentConnectionDataFragment = (
  { __typename?: 'ApprovalInvoiceDocumentsResponse' }
  & { edges: Array<(
    { __typename?: 'ApprovalInvoiceEdge' }
    & Pick<ApprovalInvoiceEdge, 'cursor'>
    & { node: (
      { __typename?: 'ApprovalInvoiceDocument' }
      & Pick<ApprovalInvoiceDocument, 'id'>
    ) }
  )>, pageInfo: (
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'startCursor' | 'hasNextPage' | 'totalCount'>
  ) }
);


export type BasicApprovalDocumentConnectionDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type BasicArchiveInvoiceDocumentConnectionDataFragment = (
  { __typename?: 'ArchiveInvoiceDocumentsResponse' }
  & { edges?: Maybe<Array<(
    { __typename?: 'ArchiveInvoiceDocumentEdge' }
    & Pick<ArchiveInvoiceDocumentEdge, 'cursor'>
    & { node: (
      { __typename?: 'ArchiveInvoiceDocument' }
      & Pick<ArchiveInvoiceDocument, 'id'>
    ) }
  )>>, pageInfo: (
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'startCursor' | 'hasNextPage' | 'totalCount'>
  ) }
);


export type BasicArchiveInvoiceDocumentConnectionDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionDetailsDataFragment = (
  { __typename?: 'CardIssuerTransaction' }
  & Pick<CardIssuerTransaction, 'id' | 'status' | 'cardName' | 'cardId' | 'cardRefNum' | 'cardType' | 'cardBrand' | 'category' | 'invoiceAssociationStatus' | 'merchantName' | 'merchantLegalName' | 'merchantLogoUrl' | 'transactionCreatedAt' | 'type' | 'note' | 'isExported'>
  & { member: (
    { __typename?: 'MemberInfo' }
    & Pick<MemberInfo, 'membershipId' | 'firstName' | 'lastName' | 'avatarUrl'>
  ), documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'globalDocumentId' | 'isEInvoice' | 'eInvoiceValidationErrors' | 'status'>
    & { invoiceDate?: Maybe<(
      { __typename?: 'DateField' }
      & Pick<DateField, 'value'>
    )>, eInvoice?: Maybe<(
      { __typename?: 'DocumentEInvoiceField' }
      & Pick<DocumentEInvoiceField, 'format' | 'error'>
    )>, invoiceNumber?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, amount?: Maybe<(
      { __typename?: 'FloatField' }
      & Pick<FloatField, 'value'>
    )>, currency?: Maybe<(
      { __typename?: 'DocumentCurrencyField' }
      & Pick<DocumentCurrencyField, 'value'>
    )>, contact?: Maybe<(
      { __typename?: 'ContactField' }
      & { value: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'accountsPayableNumber' | 'accountsReceivableNumber' | 'email'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      ) }
    )>, extractedContact?: Maybe<(
      { __typename?: 'ExtractedContact' }
      & Pick<ExtractedContact, 'cachingKey'>
      & { name?: Maybe<(
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      )> }
    )>, attachments?: Maybe<Array<(
      { __typename?: 'DocumentAttachment' }
      & Pick<DocumentAttachment, 'name' | 'id' | 'url' | 'size' | 'isDetachable' | 'originalDocumentId'>
      & { attachedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>, documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'name' | 'url' | 'size'>
      & { eInvoice?: Maybe<(
        { __typename?: 'EInvoiceFile' }
        & Pick<EInvoiceFile, 'id' | 'url' | 'name'>
      )> }
    )> }
  )>, transactionAmount?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, billingAmount?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, billingAmountForAccounting?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, localizedDeclineReason?: Maybe<(
    { __typename?: 'TransactionDeclinedReason' }
    & { title?: Maybe<(
      { __typename?: 'TransactionDeclinedReasonTitle' }
      & Pick<TransactionDeclinedReasonTitle, 'en' | 'de'>
    )>, description?: Maybe<(
      { __typename?: 'TransactionDeclinedReasonDescription' }
      & Pick<TransactionDeclinedReasonDescription, 'en' | 'de'>
    )> }
  )>, invoiceNotNeeded?: Maybe<(
    { __typename?: 'CardIssuerTransactionInvoiceNotNeeded' }
    & Pick<CardIssuerTransactionInvoiceNotNeeded, 'comment' | 'reason'>
  )>, accountingData?: Maybe<(
    { __typename?: 'TransactionCardAccountingData' }
    & Pick<TransactionCardAccountingData, 'contactId' | 'costCenterId' | 'costObjectId' | 'generalLedgerAccountId' | 'bookingKeyId' | 'artistSocialInsuranceCode' | 'extraCostInfoId' | 'paymentConditionId' | 'approvalWorkflowTemplateId'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id'>
      & { name: (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value'>
      ) }
    )>, approvers?: Maybe<Array<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatarUrl'>
    )>>> }
  )> }
);


export type TransactionDetailsDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionListDataFragment = (
  { __typename?: 'CardIssuerTransaction' }
  & Pick<CardIssuerTransaction, 'id' | 'status' | 'cardId' | 'cardRefNum' | 'invoiceAssociationStatus' | 'merchantLegalName' | 'merchantLogoUrl' | 'transactionCreatedAt' | 'category' | 'type'>
  & { localizedDeclineReason?: Maybe<(
    { __typename?: 'TransactionDeclinedReason' }
    & { description?: Maybe<(
      { __typename?: 'TransactionDeclinedReasonDescription' }
      & Pick<TransactionDeclinedReasonDescription, 'en' | 'de'>
    )> }
  )>, member: (
    { __typename?: 'MemberInfo' }
    & Pick<MemberInfo, 'firstName' | 'lastName' | 'avatarUrl' | 'membershipId'>
  ), billingAmount?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )>, transactionAmount?: Maybe<(
    { __typename?: 'TransactionAmount' }
    & Pick<TransactionAmount, 'value' | 'precision' | 'currency'>
  )> }
);


export type TransactionListDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentBookingAssociationDataFragment = (
  { __typename?: 'DocumentBookingAssociation' }
  & Pick<DocumentBookingAssociation, 'id' | 'documentId' | 'bookingId' | 'articleId' | 'quantity' | 'source'>
  & { goodsReceiptsInfo?: Maybe<Array<(
    { __typename?: 'GoodsReceiptInfo' }
    & Pick<GoodsReceiptInfo, 'goodsReceiptId' | 'goodsReceiptLineNumber' | 'goodsReceiptCreationDate' | 'quantity' | 'articleId' | 'linkedPurchaseOrderId'>
  )>>, purchaseOrdersInfo?: Maybe<Array<(
    { __typename?: 'PurchaseOrderInfo' }
    & Pick<PurchaseOrderInfo, 'purchaseOrderId' | 'purchaseOrderLineNumber' | 'purchaseOrderCreationDate' | 'quantity' | 'articleId'>
    & { unitPrice: (
      { __typename: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ) }
  )>>, additionalExpenseInfo?: Maybe<(
    { __typename?: 'AdditionalExpenseInfo' }
    & Pick<AdditionalExpenseInfo, 'expenseType'>
  )>, unitPrice: (
    { __typename: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ) }
);


export type DocumentBookingAssociationDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PageInfoDataFragment = (
  { __typename?: 'PageInfo' }
  & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage' | 'totalCount'>
);


export type PageInfoDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionCommentEventDataFragment = (
  { __typename?: 'TransactionCommentEvent' }
  & Pick<TransactionCommentEvent, 'createdAt'>
  & { clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, comment: (
    { __typename?: 'ActivityComment' }
    & Pick<ActivityComment, 'id' | 'text'>
    & { author?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'avatarUrl'>
    )> }
  ), user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ) }
);


export type TransactionCommentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionStatusUpdatedEventDataFragment = (
  { __typename?: 'TransactionStatusUpdatedEvent' }
  & Pick<TransactionStatusUpdatedEvent, 'createdAt'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ), properties: (
    { __typename?: 'ActivityTransactionStatusUpdated' }
    & Pick<ActivityTransactionStatusUpdated, 'newStatus'>
  ) }
);


export type TransactionStatusUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionReconciledEventDataFragment = (
  { __typename?: 'TransactionReconciledEvent' }
  & Pick<TransactionReconciledEvent, 'createdAt'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ), properties: (
    { __typename?: 'ActivityTransactionReconciled' }
    & Pick<ActivityTransactionReconciled, 'merchantName'>
    & { amount: (
      { __typename?: 'TransactionAmount' }
      & Pick<TransactionAmount, 'value' | 'currency'>
    ) }
  ) }
);


export type TransactionReconciledEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionAmountChangedEventDataFragment = (
  { __typename?: 'TransactionAmountChangedEvent' }
  & Pick<TransactionAmountChangedEvent, 'createdAt'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ), properties: (
    { __typename?: 'ActivityTransactionAmountUpdated' }
    & { newAmount: (
      { __typename?: 'TransactionAmount' }
      & Pick<TransactionAmount, 'value' | 'currency'>
    ) }
  ) }
);


export type TransactionAmountChangedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionMarkedAsInvoiceNeededEventDataFragment = (
  { __typename?: 'TransactionMarkedAsInvoiceNeededEvent' }
  & Pick<TransactionMarkedAsInvoiceNeededEvent, 'createdAt'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ) }
);


export type TransactionMarkedAsInvoiceNeededEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionMarkedAsInvoiceNotNeededEventDataFragment = (
  { __typename?: 'TransactionMarkedAsInvoiceNotNeededEvent' }
  & Pick<TransactionMarkedAsInvoiceNotNeededEvent, 'createdAt'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ), properties: (
    { __typename?: 'ActivityTransactionMarkedAsInvoiceNotNeeded' }
    & Pick<ActivityTransactionMarkedAsInvoiceNotNeeded, 'reason' | 'reasonComment'>
  ) }
);


export type TransactionMarkedAsInvoiceNotNeededEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type TransactionMarkedAsExportedEventDataFragment = (
  { __typename?: 'TransactionMarkedAsExportedEvent' }
  & Pick<TransactionMarkedAsExportedEvent, 'createdAt'>
  & { user: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ), properties: (
    { __typename?: 'ActivityTransactionMarkedAsExported' }
    & Pick<ActivityTransactionMarkedAsExported, 'exportId'>
  ) }
);


export type TransactionMarkedAsExportedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GoodsReceiptsImportHistoryDataFragment = (
  { __typename?: 'GoodsReceiptImportHistoryEntry' }
  & Pick<GoodsReceiptImportHistoryEntry, 'id' | 'createdAt' | 'updatedAt' | 'finishedAt' | 'status'>
  & { createdBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ), importResult?: Maybe<(
    { __typename?: 'GoodsReceiptImportHistoryEntryImportResult' }
    & Pick<GoodsReceiptImportHistoryEntryImportResult, 'successfullyCreatedDocumentsCount' | 'successfullyUpdatedDocumentsCount' | 'failedToImportDocumentsCount'>
  )> }
);


export type GoodsReceiptsImportHistoryDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ProjectCodeDataFragment = (
  { __typename?: 'ProjectCode' }
  & Pick<ProjectCode, 'id' | 'code' | 'name' | 'validFrom' | 'validTo' | 'createdAt' | 'updatedAt' | 'isArchived'>
);


export type ProjectCodeDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PurchaseOrderBaseFragment = (
  { __typename?: 'PurchaseOrder' }
  & Pick<PurchaseOrder, '_id' | 'orderNumber' | 'contactName' | 'accountsPayableNumber' | 'postingDate'>
  & { amount: (
    { __typename: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ) }
);


export type PurchaseOrderBaseFragmentVariables = Exact<{ [key: string]: never; }>;

export type GoodsLineItemsFragment = (
  { __typename?: 'GoodsLineItem' }
  & Pick<GoodsLineItem, 'itemNumber' | 'quantity' | 'description' | 'lineNumber'>
  & { unitPrice: (
    { __typename: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ) }
);


export type GoodsLineItemsFragmentVariables = Exact<{ [key: string]: never; }>;

export type GoodsReceiptLineItemsFragment = (
  { __typename?: 'GoodsReceiptGoodsLineItem' }
  & Pick<GoodsReceiptGoodsLineItem, 'itemNumber' | 'quantity' | 'description' | 'lineNumber'>
  & { unitPrice: (
    { __typename: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ) }
);


export type GoodsReceiptLineItemsFragmentVariables = Exact<{ [key: string]: never; }>;

export type ConnectedInvoicesFragment = (
  { __typename?: 'ConnectedInvoicesData' }
  & Pick<ConnectedInvoicesData, 'invoiceId' | 'invoiceNumber' | 'source'>
);


export type ConnectedInvoicesFragmentVariables = Exact<{ [key: string]: never; }>;

export type SapB1DataFragment = (
  { __typename?: 'SapB1Data' }
  & Pick<SapB1Data, 'docNumber' | 'docEntry' | 'supplierRefNumber'>
);


export type SapB1DataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PurchaseOrderListDataFragment = (
  { __typename?: 'PurchaseOrder' }
  & Pick<PurchaseOrder, 'status' | 'invoiceAssociationStatus' | 'purchaseOrderType'>
  & { goodsLineItems?: Maybe<Array<(
    { __typename?: 'GoodsLineItem' }
    & GoodsLineItemsFragment
  )>>, serviceLineItems?: Maybe<Array<(
    { __typename?: 'ServiceLineItem' }
    & Pick<ServiceLineItem, 'itemNumber' | 'description' | 'generalLedgerAccount' | 'taxCode'>
    & { lineTotal: (
      { __typename: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ) }
  )>>, connectedInvoices?: Maybe<Array<(
    { __typename?: 'ConnectedInvoicesData' }
    & ConnectedInvoicesFragment
  )>>, sapB1Data?: Maybe<(
    { __typename?: 'SapB1Data' }
    & SapB1DataFragment
  )>, goodsReceipts?: Maybe<Array<Maybe<(
    { __typename?: 'GoodsReceipt' }
    & Pick<GoodsReceipt, '_id' | 'status'>
  )>>> }
  & PurchaseOrderBaseFragment
);


export type PurchaseOrderListDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type AssociatedPurchaseOrderDataFragment = (
  { __typename?: 'PurchaseOrder' }
  & Pick<PurchaseOrder, 'purchaseOrderType'>
  & { purchaseOrderStatus: PurchaseOrder['status'] }
  & { goodsLineItems?: Maybe<Array<(
    { __typename?: 'GoodsLineItem' }
    & GoodsLineItemsFragment
  )>>, connectedInvoices?: Maybe<Array<(
    { __typename?: 'ConnectedInvoicesData' }
    & ConnectedInvoicesFragment
  )>>, sapB1Data?: Maybe<(
    { __typename?: 'SapB1Data' }
    & SapB1DataFragment
  )>, goodsReceipts?: Maybe<Array<Maybe<(
    { __typename?: 'GoodsReceipt' }
    & Pick<GoodsReceipt, '_id' | 'receiptNumber' | 'status' | 'deliveryDate'>
    & { amount: (
      { __typename: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'precision'>
    ), goodsLineItems?: Maybe<Array<(
      { __typename?: 'GoodsReceiptGoodsLineItem' }
      & GoodsReceiptLineItemsFragment
    )>>, sapB1Data?: Maybe<(
      { __typename?: 'SapB1Data' }
      & SapB1DataFragment
    )> }
  )>>> }
  & PurchaseOrderBaseFragment
);


export type AssociatedPurchaseOrderDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type AssociatedGoodsReceiptDataFragment = (
  { __typename?: 'GoodsReceipt' }
  & Pick<GoodsReceipt, '_id' | 'deliveryDate' | 'contactName' | 'accountsPayableNumber' | 'receiptNumber'>
  & { goodsReceiptStatus: GoodsReceipt['status'] }
  & { amount: (
    { __typename: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  ), goodsLineItems?: Maybe<Array<(
    { __typename?: 'GoodsReceiptGoodsLineItem' }
    & GoodsReceiptLineItemsFragment
  )>>, connectedPurchaseOrders?: Maybe<Array<Maybe<(
    { __typename?: 'ConnectedPurchaseOrdersData' }
    & Pick<ConnectedPurchaseOrdersData, 'purchaseOrderId' | 'purchaseOrderNumber'>
  )>>>, purchaseOrders?: Maybe<Array<Maybe<(
    { __typename?: 'PurchaseOrder' }
    & Pick<PurchaseOrder, 'status'>
    & { goodsLineItems?: Maybe<Array<(
      { __typename?: 'GoodsLineItem' }
      & GoodsLineItemsFragment
    )>>, sapB1Data?: Maybe<(
      { __typename?: 'SapB1Data' }
      & SapB1DataFragment
    )> }
    & PurchaseOrderBaseFragment
  )>>>, sapB1Data?: Maybe<(
    { __typename?: 'SapB1Data' }
    & SapB1DataFragment
  )> }
);


export type AssociatedGoodsReceiptDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentCommentEventDataFragment = (
  { __typename: 'DocumentCommentEvent' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  )>, clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, comment?: Maybe<(
    { __typename?: 'ActivityComment' }
    & Pick<ActivityComment, 'id' | 'text'>
    & { author?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'avatarUrl'>
    )> }
  )> }
);


export type DocumentCommentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type RequestApprovalEventDataFragment = (
  { __typename?: 'RequestApprovalEvent' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, approvers?: Maybe<Array<Maybe<(
    { __typename?: 'SubstituteUser' }
    & Pick<SubstituteUser, 'id' | 'avatarUrl' | 'name'>
    & { isSubstituting?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
    )> }
  )>>> }
);


export type RequestApprovalEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SubstituteAddedEventDataFragment = (
  { __typename?: 'SubstituteAddedEvent' }
  & { substitute?: Maybe<(
    { __typename?: 'SubstituteUser' }
    & Pick<SubstituteUser, 'id' | 'name' | 'email' | 'avatarUrl'>
    & { isSubstituting?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
    )> }
  )> }
);


export type SubstituteAddedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SubstituteRemovedEventDataFragment = (
  { __typename?: 'SubstituteRemovedEvent' }
  & { substitute?: Maybe<(
    { __typename?: 'SubstituteUser' }
    & Pick<SubstituteUser, 'id' | 'name' | 'email' | 'avatarUrl'>
    & { isSubstituting?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
    )> }
  )> }
);


export type SubstituteRemovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentResetManualEventDataFragment = (
  { __typename?: 'DocumentResetManualEvent' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type DocumentResetManualEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentResetUserDeactivationEventDataFragment = (
  { __typename?: 'DocumentResetUserDeactivationEvent' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, targetUser?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email'>
  )> }
);


export type DocumentResetUserDeactivationEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentResetUserRoleChangedEventDataFragment = (
  { __typename?: 'DocumentResetUserRoleChangedEvent' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, targetUser?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'email'>
  )> }
);


export type DocumentResetUserRoleChangedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type FileUploadedByEmailEventDataFragment = (
  { __typename?: 'FileUploadedByEmailEvent' }
  & Pick<FileUploadedByEmailEvent, 'uploaderEmail'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, file?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url'>
  )> }
);


export type FileUploadedByEmailEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ApprovedEventDataFragment = (
  { __typename?: 'ApprovedEvent' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, users?: Maybe<Array<Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>>> }
);


export type ApprovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ContactSupplierSendEmailEventDataFragment = (
  { __typename?: 'ContactSupplierSendEmailEvent' }
  & { user?: Maybe<(
    { __typename: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, emailPayload?: Maybe<(
    { __typename?: 'ContactSupplierEmailPayload' }
    & Pick<ContactSupplierEmailPayload, 'subject' | 'textBody' | 'htmlBody' | 'recipients' | 'replyTo' | 'cc'>
  )> }
);


export type ContactSupplierSendEmailEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type InvalidEInvoiceDetectedEventDataFragment = (
  { __typename?: 'InvalidEInvoiceDetectedEvent' }
  & Pick<InvalidEInvoiceDetectedEvent, 'eInvoiceFormat' | 'eInvoiceError' | 'eInvoiceVersion'>
);


export type InvalidEInvoiceDetectedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type MetaDataExtractionEventDataFragment = (
  { __typename?: 'MetaDataExtractionEvent' }
  & { extractedFields?: Maybe<Array<Maybe<(
    { __typename?: 'ExtractedField' }
    & Pick<ExtractedField, 'value' | 'key'>
  )>>> }
);


export type MetaDataExtractionEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ContactExtractionEventDataFragment = (
  { __typename: 'ContactExtractionEvent' }
  & { contact?: Maybe<(
    { __typename?: 'ActivityContact' }
    & Pick<ActivityContact, 'id'>
    & { name: (
      { __typename?: 'ActivityContactName' }
      & Pick<ActivityContactName, 'value'>
    ) }
  )> }
);


export type ContactExtractionEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type CostCenterExtractionEventDataFragment = (
  { __typename?: 'CostCenterExtractionEvent' }
  & { costCenter1?: Maybe<(
    { __typename?: 'ActivityCostCenter' }
    & Pick<ActivityCostCenter, 'id' | 'readableName'>
  )>, costCenter2?: Maybe<(
    { __typename?: 'ActivityCostCenter' }
    & Pick<ActivityCostCenter, 'id' | 'readableName'>
  )> }
);


export type CostCenterExtractionEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type WorkflowTemplateAppliedToDocumentEventDataFragment = (
  { __typename?: 'WorkflowTemplateAppliedToDocumentEvent' }
  & { workflow?: Maybe<(
    { __typename?: 'ActivityWorkflow' }
    & Pick<ActivityWorkflow, 'name' | 'description'>
  )> }
);


export type WorkflowTemplateAppliedToDocumentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ApproverExtractedEventDataFragment = (
  { __typename?: 'ApproverExtractedEvent' }
  & { approvers?: Maybe<Array<Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>>> }
);


export type ApproverExtractedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type WorkflowStepSkippedEventDataFragment = (
  { __typename?: 'WorkflowStepSkippedEvent' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>>>, workflowStep?: Maybe<(
    { __typename?: 'ActivityWorkflowStep' }
    & { resolution?: Maybe<(
      { __typename?: 'WorkflowStepResolution' }
      & Pick<WorkflowStepResolution, 'comment'>
    )>, conditions?: Maybe<Array<(
      { __typename?: 'WorkflowStepCondition' }
      & Pick<WorkflowStepCondition, 'field' | 'operator' | 'values'>
    )>> }
  )> }
);


export type WorkflowStepSkippedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type UserApprovalDocumentWorkflowStepDataFragment = (
  { __typename?: 'UserApprovalDocumentWorkflowStep' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type UserApprovalDocumentWorkflowStepDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type RejectedEventDataFragment = (
  { __typename?: 'RejectedEvent' }
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, comment?: Maybe<(
    { __typename?: 'ActivityComment' }
    & Pick<ActivityComment, 'text'>
  )> }
);


export type RejectedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ExportedEventDataFragment = (
  { __typename?: 'ExportedEvent' }
  & Pick<ExportedEvent, 'exportId' | 'exportType'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, datevLocationParams?: Maybe<(
    { __typename?: 'DatevLocationParams' }
    & Pick<DatevLocationParams, 'clientNumber' | 'consultantNumber' | 'documentExportGuid'>
  )> }
);


export type ExportedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ProvisionExportedEventDataFragment = (
  { __typename?: 'ProvisionExportedEvent' }
  & Pick<ProvisionExportedEvent, 'exportId' | 'exportType'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type ProvisionExportedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type ReversalExportedEventDataFragment = (
  { __typename?: 'ReversalExportedEvent' }
  & Pick<ReversalExportedEvent, 'exportId' | 'exportType'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type ReversalExportedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SepaTransferGeneratedEventDataFragment = (
  { __typename?: 'DocumentSepaTransferGeneratedEvent' }
  & Pick<DocumentSepaTransferGeneratedEvent, 'paymentId' | 'executionDate'>
  & { generatedBy: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name'>
  ) }
);


export type SepaTransferGeneratedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentPaymentInfoUpdatedEventDataFragment = (
  { __typename?: 'DocumentPaymentInfoUpdatedEvent' }
  & { updatedBy: (
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  ), updatedPaymentFields: Array<(
    { __typename?: 'UpdatedCreateTransferField' }
    & Pick<UpdatedCreateTransferField, 'createTransfer'>
  ) | (
    { __typename?: 'UpdatedIBANField' }
    & Pick<UpdatedIbanField, 'iban'>
  ) | (
    { __typename?: 'UpdatedPaymentStateField' }
    & { paymentState: (
      { __typename?: 'PaymentStateValue' }
      & Pick<PaymentStateValue, 'isPaid' | 'paidAt'>
    ) }
  ) | (
    { __typename?: 'UpdatedSwiftCodeField' }
    & Pick<UpdatedSwiftCodeField, 'swiftCode'>
  )> }
);


export type DocumentPaymentInfoUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentResetByCandisEventDataFragment = (
  { __typename?: 'DocumentResetByCandis' }
  & Pick<DocumentResetByCandis, 'reason'>
);


export type DocumentResetByCandisEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type FileAttachedToDocumentEventDataFragment = (
  { __typename?: 'FileAttachedToDocumentEvent' }
  & Pick<FileAttachedToDocumentEvent, 'id' | 'createdAt' | 'sinceDetachedOrDeleted' | 'originalDocumentId' | 'fromEInvoice'>
  & { file?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url' | 'id'>
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type FileAttachedToDocumentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SplitBookingUpdatedEventDataFragment = (
  { __typename?: 'SplitBookingUpdatedEvent' }
  & Pick<SplitBookingUpdatedEvent, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )>, bookings: Array<(
    { __typename?: 'SplitBooking' }
    & Pick<SplitBooking, 'number' | 'action'>
    & { fields: Array<(
      { __typename?: 'BookingField' }
      & Pick<BookingField, 'key' | 'value'>
    )> }
  )> }
);


export type SplitBookingUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type SingleBookingUpdatedEventDataFragment = (
  { __typename?: 'SingleBookingUpdatedEvent' }
  & Pick<SingleBookingUpdatedEvent, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )>, fields: Array<(
    { __typename?: 'BookingField' }
    & Pick<BookingField, 'key' | 'value'>
  )> }
);


export type SingleBookingUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type AttachmentDeletedFromDocumentEventDataFragment = (
  { __typename?: 'AttachmentDeletedFromDocumentEvent' }
  & Pick<AttachmentDeletedFromDocumentEvent, 'id' | 'createdAt' | 'fileName'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type AttachmentDeletedFromDocumentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type AttachmentDetachedFromDocumentEventDataFragment = (
  { __typename?: 'AttachmentDetachedFromDocumentEvent' }
  & Pick<AttachmentDetachedFromDocumentEvent, 'id' | 'createdAt' | 'fileName'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type AttachmentDetachedFromDocumentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentConvertedToAttachmentEventDataFragment = (
  { __typename?: 'DocumentConvertedToAttachmentEvent' }
  & Pick<DocumentConvertedToAttachmentEvent, 'id' | 'createdAt'>
  & { target?: Maybe<(
    { __typename?: 'DocumentData' }
    & Pick<DocumentData, 'fileName'>
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type DocumentConvertedToAttachmentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type AttachmentConvertedToDocumentEventDataFragment = (
  { __typename?: 'AttachmentConvertedToDocumentEvent' }
  & Pick<AttachmentConvertedToDocumentEvent, 'id' | 'createdAt'>
  & { source?: Maybe<(
    { __typename?: 'DocumentData' }
    & Pick<DocumentData, 'fileName'>
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )> }
);


export type AttachmentConvertedToDocumentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentFileReplacedEventDataFragment = (
  { __typename?: 'DocumentFileReplacedEvent' }
  & Pick<DocumentFileReplacedEvent, 'id' | 'createdAt'>
  & { oldFile?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url'>
  )> }
);


export type DocumentFileReplacedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentTransactionLinkedEventDataFragment = (
  { __typename?: 'DocumentTransactionLinkedEvent' }
  & Pick<DocumentTransactionLinkedEvent, 'id' | 'createdAt' | 'merchantName' | 'transactionDate' | 'receiptAutomatchingTaskId'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )> }
);


export type DocumentTransactionLinkedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentTransactionCommentCreatedEventDataFragment = (
  { __typename?: 'DocumentTransactionCommentCreatedEvent' }
  & Pick<DocumentTransactionCommentCreatedEvent, 'id' | 'createdAt'>
  & { comment?: Maybe<(
    { __typename?: 'ActivityComment' }
    & Pick<ActivityComment, 'id' | 'text'>
    & { author?: Maybe<(
      { __typename?: 'ActivityUser' }
      & Pick<ActivityUser, 'avatarUrl'>
    )> }
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )> }
);


export type DocumentTransactionCommentCreatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentTransactionUnlinkedEventDataFragment = (
  { __typename?: 'DocumentTransactionUnlinkedEvent' }
  & Pick<DocumentTransactionUnlinkedEvent, 'id' | 'createdAt' | 'merchantName' | 'transactionDate'>
  & { amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  )>, clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )> }
);


export type DocumentTransactionUnlinkedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentTransactionReconciledEventDataFragment = (
  { __typename?: 'DocumentTransactionReconciledEvent' }
  & Pick<DocumentTransactionReconciledEvent, 'id' | 'createdAt'>
  & { properties: (
    { __typename?: 'ActivityTransactionReconciled' }
    & Pick<ActivityTransactionReconciled, 'merchantName' | 'transactionCreatedAt'>
    & { amount: (
      { __typename?: 'TransactionAmount' }
      & Pick<TransactionAmount, 'currency' | 'value' | 'precision'>
    ) }
  ), user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'name' | 'email' | 'avatarUrl'>
  )> }
);


export type DocumentTransactionReconciledEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentTransactionStatusUpdatedEventDataFragment = (
  { __typename?: 'DocumentTransactionStatusUpdatedEvent' }
  & Pick<DocumentTransactionStatusUpdatedEvent, 'id' | 'createdAt' | 'oldStatus' | 'newStatus'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )> }
);


export type DocumentTransactionStatusUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentResetTransactionUnLinkedEventDataFragment = (
  { __typename?: 'DocumentResetTransactionUnLinkedEvent' }
  & Pick<DocumentResetTransactionUnLinkedEvent, 'id'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type DocumentResetTransactionUnLinkedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentResetTransactionLinkedEventDataFragment = (
  { __typename?: 'DocumentResetTransactionLinkedEvent' }
  & Pick<DocumentResetTransactionLinkedEvent, 'id'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type DocumentResetTransactionLinkedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentResetTransactionAmountUpdatedEventDataFragment = (
  { __typename?: 'DocumentResetTransactionAmountUpdatedEvent' }
  & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type DocumentResetTransactionAmountUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentImportedByMigrationEventDataFragment = (
  { __typename?: 'DocumentImportedByMigrationEvent' }
  & Pick<DocumentImportedByMigrationEvent, 'importedFromSystem'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, file?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'name' | 'url'>
  )> }
);


export type DocumentImportedByMigrationEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PurchaseLinkDataFragment = (
  { __typename?: 'PurchaseOrderLinkedEvent' }
  & Pick<PurchaseOrderLinkedEvent, 'id' | 'orderNumber' | 'purchaseOrderId' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type PurchaseLinkDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type PurchaseUnlinkDataFragment = (
  { __typename?: 'PurchaseOrderUnlinkedEvent' }
  & Pick<PurchaseOrderUnlinkedEvent, 'id' | 'orderNumber' | 'purchaseOrderId' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type PurchaseUnlinkDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type RelatedDocumentActivityFragment = (
  { __typename?: 'RelatedDocument' }
  & Pick<RelatedDocument, 'id' | 'type'>
  & { document?: Maybe<(
    { __typename?: 'AggregatedEcmDocument' }
    & Pick<AggregatedEcmDocument, 'documentName'>
  ) | (
    { __typename?: 'Document' }
    & { invoiceNumber?: Maybe<(
      { __typename?: 'StringField' }
      & Pick<StringField, 'value'>
    )>, contact?: Maybe<(
      { __typename?: 'ContactField' }
      & { value: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id'>
        & { name: (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value'>
        ) }
      ) }
    )>, documentFile?: Maybe<(
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'name'>
    )> }
  )> }
);


export type RelatedDocumentActivityFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentRelationshipCreatedEventDataFragment = (
  { __typename?: 'DocumentRelationshipCreatedEvent' }
  & Pick<DocumentRelationshipCreatedEvent, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )>, linkedDocuments?: Maybe<Array<Maybe<(
    { __typename?: 'RelatedDocument' }
    & RelatedDocumentActivityFragment
  ) | { __typename?: 'RelatedDocumentError' }>>> }
);


export type DocumentRelationshipCreatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentRelationshipRemovedEventDataFragment = (
  { __typename?: 'DocumentRelationshipRemovedEvent' }
  & Pick<DocumentRelationshipRemovedEvent, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )>, unlinkedDocument?: Maybe<(
    { __typename?: 'RelatedDocument' }
    & RelatedDocumentActivityFragment
  ) | { __typename?: 'RelatedDocumentError' }> }
);


export type DocumentRelationshipRemovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentTagUpdatedEventDataFragment = (
  { __typename?: 'DocumentTagUpdatedEvent' }
  & Pick<DocumentTagUpdatedEvent, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'description'>
  )> }
);


export type DocumentTagUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentGrossNetTaxRoundingAmountChangedEventDataFragment = (
  { __typename?: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
  & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id' | 'createdAt' | 'documentGrossAmount' | 'documentNetAmount' | 'documentTaxAmount' | 'documentRoundingAmount'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type DocumentGrossNetTaxRoundingAmountChangedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentAssociationsCreatedEventDataFragment = (
  { __typename?: 'DocumentAssociationsCreatedEvent' }
  & Pick<DocumentAssociationsCreatedEvent, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, associatedPurchaseOrdersWithGoodsReceipts?: Maybe<Array<(
    { __typename?: 'PurchaseOrderAssociationWithGoodsReceipts' }
    & Pick<PurchaseOrderAssociationWithGoodsReceipts, 'purchaseOrderId' | 'orderNumber'>
    & { associatedGoodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceiptAssociationDetails' }
      & Pick<GoodsReceiptAssociationDetails, 'goodsReceiptId' | 'receiptNumber'>
    )>>> }
  )>>, associatedGoodsReceiptsWithPurchaseOrders?: Maybe<Array<(
    { __typename?: 'GoodsReceiptAssociationWithPurchaseOrders' }
    & Pick<GoodsReceiptAssociationWithPurchaseOrders, 'receiptNumber' | 'goodsReceiptId'>
    & { associatedPurchaseOrders?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderAssociationDetails' }
      & Pick<PurchaseOrderAssociationDetails, 'purchaseOrderId' | 'orderNumber'>
    )>>> }
  )>> }
);


export type DocumentAssociationsCreatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentAssociationsRemovedEventDataFragment = (
  { __typename?: 'DocumentAssociationsRemovedEvent' }
  & Pick<DocumentAssociationsRemovedEvent, 'id' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'avatarUrl' | 'name'>
  )>, disconnectedPurchaseOrdersWithGoodsReceipts?: Maybe<Array<Maybe<(
    { __typename?: 'PurchaseOrderAssociationWithGoodsReceipts' }
    & Pick<PurchaseOrderAssociationWithGoodsReceipts, 'purchaseOrderId' | 'orderNumber'>
    & { associatedGoodsReceipts?: Maybe<Array<Maybe<(
      { __typename?: 'GoodsReceiptAssociationDetails' }
      & Pick<GoodsReceiptAssociationDetails, 'goodsReceiptId' | 'receiptNumber'>
    )>>> }
  )>>>, disconnectedGoodsReceiptsWithPurchaseOrders?: Maybe<Array<Maybe<(
    { __typename?: 'GoodsReceiptAssociationWithPurchaseOrders' }
    & Pick<GoodsReceiptAssociationWithPurchaseOrders, 'receiptNumber' | 'goodsReceiptId'>
    & { associatedPurchaseOrders?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderAssociationDetails' }
      & Pick<PurchaseOrderAssociationDetails, 'purchaseOrderId' | 'orderNumber'>
    )>>> }
  )>>> }
);


export type DocumentAssociationsRemovedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type AccountingAreaUpdatedEventDataFragment = (
  { __typename?: 'AccountingAreaUpdatedEvent' }
  & Pick<AccountingAreaUpdatedEvent, 'id' | 'createdAt' | 'accountingAreaName' | 'accountingAreaId'>
  & { clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type AccountingAreaUpdatedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentStoredAsEcmDocumentEventDataFragment = (
  { __typename?: 'DocumentStoredAsEcmDocumentEvent' }
  & Pick<DocumentStoredAsEcmDocumentEvent, 'id' | 'createdAt' | 'documentDate' | 'documentName' | 'documentNumber' | 'documentType' | 'endDate' | 'notes' | 'startDate' | 'terminationDate' | 'terminationReminderDate'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )>, amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, contact?: Maybe<(
    { __typename?: 'ActivityContact' }
    & Pick<ActivityContact, 'id'>
    & { name: (
      { __typename?: 'ActivityContactName' }
      & Pick<ActivityContactName, 'value'>
    ) }
  )>, costCenter?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'readableName'>
  )>, notifyPerson?: Maybe<(
    { __typename?: 'EcmDocumentNotifyPerson' }
    & { primary: Array<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
  )>, responsiblePerson?: Maybe<(
    { __typename?: 'EcmDocumentResponsiblePerson' }
    & { primary: Array<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
  )>, documentSubCategory?: Maybe<(
    { __typename?: 'EcmDocumentSubCategoryField' }
    & Pick<EcmDocumentSubCategoryField, 'id' | 'name'>
  )> }
);


export type DocumentStoredAsEcmDocumentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentUpdatedAsEcmDocumentEventDataFragment = (
  { __typename?: 'DocumentUpdatedAsEcmDocumentEvent' }
  & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id' | 'createdAt' | 'documentDate' | 'documentName' | 'documentNumber' | 'documentType' | 'endDate' | 'notes' | 'startDate' | 'terminationDate' | 'terminationReminderDate' | 'updatedFields'>
  & { user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'email' | 'avatarUrl'>
  )>, amount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'precision'>
  )>, contact?: Maybe<(
    { __typename?: 'ActivityContact' }
    & Pick<ActivityContact, 'id'>
    & { name: (
      { __typename?: 'ActivityContactName' }
      & Pick<ActivityContactName, 'value'>
    ) }
  )>, costCenter?: Maybe<(
    { __typename?: 'CostCenter' }
    & Pick<CostCenter, 'readableName'>
  )>, notifyPerson?: Maybe<(
    { __typename?: 'EcmDocumentNotifyPerson' }
    & { primary: Array<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
  )>, responsiblePerson?: Maybe<(
    { __typename?: 'EcmDocumentResponsiblePerson' }
    & { primary: Array<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
  )>, documentSubCategory?: Maybe<(
    { __typename?: 'EcmDocumentSubCategoryField' }
    & Pick<EcmDocumentSubCategoryField, 'id' | 'name'>
  )> }
);


export type DocumentUpdatedAsEcmDocumentEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentConvertedEventDataFragment = (
  { __typename?: 'DocumentConvertedEvent' }
  & Pick<DocumentConvertedEvent, 'id' | 'createdAt' | 'documentType' | 'convertedFrom'>
  & { clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )>, user?: Maybe<(
    { __typename?: 'ActivityUser' }
    & Pick<ActivityUser, 'id' | 'name' | 'avatarUrl'>
  )> }
);


export type DocumentConvertedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type DocumentClassifiedEventDataFragment = (
  { __typename?: 'DocumentClassifiedEvent' }
  & Pick<DocumentClassifiedEvent, 'id' | 'createdAt'>
  & { newDocumentType: DocumentClassifiedEvent['documentType'] }
  & { clientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'name'>
  )> }
);


export type DocumentClassifiedEventDataFragmentVariables = Exact<{ [key: string]: never; }>;

export type GetDocumentHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDocumentHistoryQuery = (
  { __typename?: 'Query' }
  & { getDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'status'>
    & { timeline?: Maybe<Array<(
      { __typename: 'AccountingAreaUpdatedEvent' }
      & Pick<AccountingAreaUpdatedEvent, 'id' | 'createdAt'>
      & AccountingAreaUpdatedEventDataFragment
    ) | (
      { __typename: 'ApprovedEvent' }
      & Pick<ApprovedEvent, 'id' | 'createdAt'>
      & ApprovedEventDataFragment
    ) | (
      { __typename: 'ApproverExtractedEvent' }
      & Pick<ApproverExtractedEvent, 'id' | 'createdAt'>
      & ApproverExtractedEventDataFragment
    ) | (
      { __typename: 'AttachmentConvertedToDocumentEvent' }
      & Pick<AttachmentConvertedToDocumentEvent, 'id' | 'createdAt'>
      & AttachmentConvertedToDocumentEventDataFragment
    ) | (
      { __typename: 'AttachmentDeletedFromDocumentEvent' }
      & Pick<AttachmentDeletedFromDocumentEvent, 'id' | 'createdAt'>
      & AttachmentDeletedFromDocumentEventDataFragment
    ) | (
      { __typename: 'AttachmentDetachedFromDocumentEvent' }
      & Pick<AttachmentDetachedFromDocumentEvent, 'id' | 'createdAt'>
      & AttachmentDetachedFromDocumentEventDataFragment
    ) | (
      { __typename: 'ContactExtractionEvent' }
      & Pick<ContactExtractionEvent, 'id' | 'createdAt'>
      & ContactExtractionEventDataFragment
    ) | (
      { __typename: 'ContactSupplierSendEmailEvent' }
      & Pick<ContactSupplierSendEmailEvent, 'id' | 'createdAt'>
      & ContactSupplierSendEmailEventDataFragment
    ) | (
      { __typename: 'CostCenterExtractionEvent' }
      & Pick<CostCenterExtractionEvent, 'id' | 'createdAt'>
      & CostCenterExtractionEventDataFragment
    ) | (
      { __typename: 'DocumentAssociationsCreatedEvent' }
      & Pick<DocumentAssociationsCreatedEvent, 'id' | 'createdAt'>
      & DocumentAssociationsCreatedEventDataFragment
    ) | (
      { __typename: 'DocumentAssociationsRemovedEvent' }
      & Pick<DocumentAssociationsRemovedEvent, 'id' | 'createdAt'>
      & DocumentAssociationsRemovedEventDataFragment
    ) | (
      { __typename: 'DocumentClassifiedEvent' }
      & Pick<DocumentClassifiedEvent, 'id' | 'createdAt'>
      & DocumentClassifiedEventDataFragment
    ) | (
      { __typename: 'DocumentCommentEvent' }
      & Pick<DocumentCommentEvent, 'id' | 'createdAt'>
      & DocumentCommentEventDataFragment
    ) | (
      { __typename: 'DocumentConvertedEvent' }
      & Pick<DocumentConvertedEvent, 'id' | 'createdAt'>
      & DocumentConvertedEventDataFragment
    ) | (
      { __typename: 'DocumentConvertedToAttachmentEvent' }
      & Pick<DocumentConvertedToAttachmentEvent, 'id' | 'createdAt'>
      & DocumentConvertedToAttachmentEventDataFragment
    ) | (
      { __typename: 'DocumentDeletedEvent' }
      & Pick<DocumentDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'DocumentFileReplacedEvent' }
      & Pick<DocumentFileReplacedEvent, 'id' | 'createdAt'>
      & DocumentFileReplacedEventDataFragment
    ) | (
      { __typename: 'DocumentGrossNetTaxRoundingAmountChangedEvent' }
      & Pick<DocumentGrossNetTaxRoundingAmountChangedEvent, 'id' | 'createdAt'>
      & DocumentGrossNetTaxRoundingAmountChangedEventDataFragment
    ) | (
      { __typename: 'DocumentImportedByMigrationEvent' }
      & Pick<DocumentImportedByMigrationEvent, 'id' | 'createdAt'>
      & DocumentImportedByMigrationEventDataFragment
    ) | (
      { __typename: 'DocumentPaymentInfoUpdatedEvent' }
      & Pick<DocumentPaymentInfoUpdatedEvent, 'id' | 'createdAt'>
      & DocumentPaymentInfoUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentRelationshipCreatedEvent' }
      & Pick<DocumentRelationshipCreatedEvent, 'id' | 'createdAt'>
      & DocumentRelationshipCreatedEventDataFragment
    ) | (
      { __typename: 'DocumentRelationshipRemovedEvent' }
      & Pick<DocumentRelationshipRemovedEvent, 'id' | 'createdAt'>
      & DocumentRelationshipRemovedEventDataFragment
    ) | (
      { __typename: 'DocumentResetByCandis' }
      & Pick<DocumentResetByCandis, 'id' | 'createdAt'>
      & DocumentResetByCandisEventDataFragment
    ) | (
      { __typename: 'DocumentResetManualEvent' }
      & Pick<DocumentResetManualEvent, 'id' | 'createdAt'>
      & DocumentResetManualEventDataFragment
    ) | (
      { __typename: 'DocumentResetTransactionAmountUpdatedEvent' }
      & Pick<DocumentResetTransactionAmountUpdatedEvent, 'id' | 'createdAt'>
      & DocumentResetTransactionAmountUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentResetTransactionLinkedEvent' }
      & Pick<DocumentResetTransactionLinkedEvent, 'id' | 'createdAt'>
      & DocumentResetTransactionLinkedEventDataFragment
    ) | (
      { __typename: 'DocumentResetTransactionUnLinkedEvent' }
      & Pick<DocumentResetTransactionUnLinkedEvent, 'id' | 'createdAt'>
      & DocumentResetTransactionUnLinkedEventDataFragment
    ) | (
      { __typename: 'DocumentResetUserDeactivationEvent' }
      & Pick<DocumentResetUserDeactivationEvent, 'id' | 'createdAt'>
      & DocumentResetUserDeactivationEventDataFragment
    ) | (
      { __typename: 'DocumentResetUserRoleChangedEvent' }
      & Pick<DocumentResetUserRoleChangedEvent, 'id' | 'createdAt'>
      & DocumentResetUserRoleChangedEventDataFragment
    ) | (
      { __typename: 'DocumentSepaTransferGeneratedEvent' }
      & Pick<DocumentSepaTransferGeneratedEvent, 'id' | 'createdAt'>
      & SepaTransferGeneratedEventDataFragment
    ) | (
      { __typename: 'DocumentStoredAsEcmDocumentEvent' }
      & Pick<DocumentStoredAsEcmDocumentEvent, 'id' | 'createdAt'>
      & DocumentStoredAsEcmDocumentEventDataFragment
    ) | (
      { __typename: 'DocumentTagUpdatedEvent' }
      & Pick<DocumentTagUpdatedEvent, 'id' | 'createdAt'>
      & DocumentTagUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionCommentCreatedEvent' }
      & Pick<DocumentTransactionCommentCreatedEvent, 'id' | 'createdAt'>
      & DocumentTransactionCommentCreatedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionLinkedEvent' }
      & Pick<DocumentTransactionLinkedEvent, 'id' | 'createdAt'>
      & DocumentTransactionLinkedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionReconciledEvent' }
      & Pick<DocumentTransactionReconciledEvent, 'id' | 'createdAt'>
      & DocumentTransactionReconciledEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionStatusUpdatedEvent' }
      & Pick<DocumentTransactionStatusUpdatedEvent, 'id' | 'createdAt'>
      & DocumentTransactionStatusUpdatedEventDataFragment
    ) | (
      { __typename: 'DocumentTransactionUnlinkedEvent' }
      & Pick<DocumentTransactionUnlinkedEvent, 'id' | 'createdAt'>
      & DocumentTransactionUnlinkedEventDataFragment
    ) | (
      { __typename: 'DocumentUpdatedAsEcmDocumentEvent' }
      & Pick<DocumentUpdatedAsEcmDocumentEvent, 'id' | 'createdAt'>
      & DocumentUpdatedAsEcmDocumentEventDataFragment
    ) | (
      { __typename: 'ExportedEvent' }
      & Pick<ExportedEvent, 'id' | 'createdAt'>
      & ExportedEventDataFragment
    ) | (
      { __typename: 'ExtractionEvent' }
      & Pick<ExtractionEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'FileAttachedToDocumentEvent' }
      & Pick<FileAttachedToDocumentEvent, 'id' | 'createdAt'>
      & FileAttachedToDocumentEventDataFragment
    ) | (
      { __typename: 'FileUploadedByEmailEvent' }
      & Pick<FileUploadedByEmailEvent, 'id' | 'createdAt'>
      & FileUploadedByEmailEventDataFragment
    ) | (
      { __typename: 'FileUploadedEvent' }
      & Pick<FileUploadedEvent, 'id' | 'createdAt'>
      & FileUploadedEventDataFragment
    ) | (
      { __typename: 'InvalidEInvoiceDetectedEvent' }
      & Pick<InvalidEInvoiceDetectedEvent, 'id' | 'createdAt'>
      & InvalidEInvoiceDetectedEventDataFragment
    ) | (
      { __typename: 'MetaDataExtractionEvent' }
      & Pick<MetaDataExtractionEvent, 'id' | 'createdAt'>
      & MetaDataExtractionEventDataFragment
    ) | (
      { __typename: 'ProvisionCreatedEvent' }
      & Pick<ProvisionCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionDeletedEvent' }
      & Pick<ProvisionDeletedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ProvisionExportedEvent' }
      & Pick<ProvisionExportedEvent, 'id' | 'createdAt'>
      & ProvisionExportedEventDataFragment
    ) | (
      { __typename: 'PurchaseOrderLinkedEvent' }
      & Pick<PurchaseOrderLinkedEvent, 'id' | 'createdAt'>
      & PurchaseLinkDataFragment
    ) | (
      { __typename: 'PurchaseOrderUnlinkedEvent' }
      & Pick<PurchaseOrderUnlinkedEvent, 'id' | 'createdAt'>
      & PurchaseUnlinkDataFragment
    ) | (
      { __typename: 'RejectedEvent' }
      & Pick<RejectedEvent, 'id' | 'createdAt'>
      & RejectedEventDataFragment
    ) | (
      { __typename: 'RequestApprovalEvent' }
      & Pick<RequestApprovalEvent, 'id' | 'createdAt'>
      & RequestApprovalEventDataFragment
    ) | (
      { __typename: 'ReversalCreatedEvent' }
      & Pick<ReversalCreatedEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'ReversalExportedEvent' }
      & Pick<ReversalExportedEvent, 'id' | 'createdAt'>
      & ReversalExportedEventDataFragment
    ) | (
      { __typename: 'SingleBookingUpdatedEvent' }
      & Pick<SingleBookingUpdatedEvent, 'id' | 'createdAt'>
      & SingleBookingUpdatedEventDataFragment
    ) | (
      { __typename: 'SplitBookingUpdatedEvent' }
      & Pick<SplitBookingUpdatedEvent, 'id' | 'createdAt'>
      & SplitBookingUpdatedEventDataFragment
    ) | (
      { __typename: 'SubstituteAddedEvent' }
      & Pick<SubstituteAddedEvent, 'id' | 'createdAt'>
      & SubstituteAddedEventDataFragment
    ) | (
      { __typename: 'SubstituteRemovedEvent' }
      & Pick<SubstituteRemovedEvent, 'id' | 'createdAt'>
      & SubstituteRemovedEventDataFragment
    ) | (
      { __typename: 'UserApprovalDocumentWorkflowStep' }
      & Pick<UserApprovalDocumentWorkflowStep, 'id' | 'createdAt'>
      & UserApprovalDocumentWorkflowStepDataFragment
    ) | (
      { __typename: 'UserRejectedDocumentWorkflowStep' }
      & Pick<UserRejectedDocumentWorkflowStep, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowCreatedForDocumentEvent' }
      & Pick<WorkflowCreatedForDocumentEvent, 'id' | 'createdAt'>
    ) | (
      { __typename: 'WorkflowStepSkippedEvent' }
      & Pick<WorkflowStepSkippedEvent, 'id' | 'createdAt'>
      & WorkflowStepSkippedEventDataFragment
    ) | (
      { __typename: 'WorkflowTemplateAppliedToDocumentEvent' }
      & Pick<WorkflowTemplateAppliedToDocumentEvent, 'id' | 'createdAt'>
      & WorkflowTemplateAppliedToDocumentEventDataFragment
    )>> }
  )> }
);

export type ListInboxDocumentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterInput>;
  sort?: InputMaybe<SortInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListInboxDocumentsQuery = (
  { __typename?: 'Query' }
  & { listInboxDocuments: (
    { __typename?: 'DocumentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'DocumentEdge' }
      & Pick<DocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'Document' }
        & Pick<Document, 'isDuplicate'>
        & DocumentListDataFragment
      ) }
    )>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    )> }
  ) }
);

export type GetInboxInvoiceDocumentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<InboxInvoiceDocumentsFilterInput>;
  sort?: InputMaybe<SortInput>;
  withPurchaseOrderData?: InputMaybe<Scalars['Boolean']['input']>;
  withAccountsReceivableNumber?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<SearchInput>;
}>;


export type GetInboxInvoiceDocumentsQuery = (
  { __typename?: 'Query' }
  & { inboxInvoiceDocuments: (
    { __typename?: 'InboxInvoiceDocumentsResponse' }
    & { edges: Array<(
      { __typename?: 'InboxInvoiceDocumentEdge' }
      & Pick<InboxInvoiceDocumentEdge, 'cursor'>
      & { node: (
        { __typename?: 'InboxInvoiceDocument' }
        & InboxInvoiceDocumentDataFragment
      ), highlights: Array<(
        { __typename?: 'InboxHighlight' }
        & Pick<InboxHighlight, 'field'>
        & { chunks: Array<(
          { __typename?: 'HighlightChunk' }
          & Pick<HighlightChunk, 'text'>
          & { matches: Array<(
            { __typename?: 'HighlightMatch' }
            & Pick<HighlightMatch, 'offset' | 'length'>
          )> }
        )> }
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoDataFragment
    ) }
  ) }
);

export type ListExportDocumentsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ListExportDocumentsCountQuery = (
  { __typename?: 'Query' }
  & { listExportDocuments: (
    { __typename?: 'DocumentsListResponse' }
    & Pick<DocumentsListResponse, 'count'>
  ) }
);

export const DocumentCannotBeModifedErrorDataFragmentDoc = gql`
    fragment DocumentCannotBeModifedErrorData on DocumentCannotBeModifedError {
  status
}
    `;
export const WorkflowStepAlreadyApprovedErrorDataFragmentDoc = gql`
    fragment WorkflowStepAlreadyApprovedErrorData on WorkflowStepAlreadyApprovedError {
  approvedByUser {
    id
    name
  }
}
    `;
export const BookingKeyArchivedErrorDataFragmentDoc = gql`
    fragment BookingKeyArchivedErrorData on BookingKeyArchivedError {
  bookingKey {
    id
    readableName
  }
}
    `;
export const CostCenterArchivedErrorDataFragmentDoc = gql`
    fragment CostCenterArchivedErrorData on CostCenterArchivedError {
  costCenter {
    id
    readableName
  }
}
    `;
export const CostObjectArchivedErrorDataFragmentDoc = gql`
    fragment CostObjectArchivedErrorData on CostObjectArchivedError {
  costObject {
    id
    readableName
  }
}
    `;
export const ExtraCostInfoArchivedErrorDataFragmentDoc = gql`
    fragment ExtraCostInfoArchivedErrorData on ExtraCostInfoArchivedError {
  extraCostInfo {
    id
    readableName
  }
}
    `;
export const GeneralLedgerArchivedErrorDataFragmentDoc = gql`
    fragment GeneralLedgerArchivedErrorData on GeneralLedgerArchivedError {
  generalLedgerAccount {
    id
    readableName
  }
}
    `;
export const ProjectCodeArchivedErrorDataFragmentDoc = gql`
    fragment ProjectCodeArchivedErrorData on ProjectCodeArchivedError {
  projectCode {
    id
    name
    code
  }
}
    `;
export const BookingsErrorDataFragmentDoc = gql`
    fragment BookingsErrorData on BookingsError {
  index
  error {
    ...BookingKeyArchivedErrorData
    ...CostCenterArchivedErrorData
    ...CostObjectArchivedErrorData
    ...ExtraCostInfoArchivedErrorData
    ...GeneralLedgerArchivedErrorData
    ...ProjectCodeArchivedErrorData
    ... on DocumentApprovalError {
      kind
      message
    }
  }
}
    `;
export const DocumentSubmitWorkflowStepErrorsFragmentDoc = gql`
    fragment DocumentSubmitWorkflowStepErrors on DocumentSubmitWorkflowStepErrors {
  errors {
    ...DocumentCannotBeModifedErrorData
    ...WorkflowStepAlreadyApprovedErrorData
    ...BookingsErrorData
    ... on DocumentApprovalError {
      kind
      message
    }
  }
}
    `;
export const DocumentApprovalStepFragmentDoc = gql`
    fragment DocumentApprovalStep on DocumentApprovalWorkflowStep {
  id
  resolution {
    approvers {
      id
      name
    }
    type
  }
  approvers {
    id
    name
    substitute {
      id
      name
    }
  }
}
    `;
export const UserApprovalStepFragmentDoc = gql`
    fragment UserApprovalStep on UserApprovalWorkflowStep {
  id
  resolution {
    approvers {
      id
      name
    }
    type
  }
  approvers {
    id
    name
    substitute {
      id
      name
    }
  }
}
    `;
export const SystemApprovalStepFragmentDoc = gql`
    fragment SystemApprovalStep on SystemApprovalWorkflowStep {
  id
  resolution {
    approvers {
      id
      name
    }
    type
  }
  approvers {
    id
    name
    substitute {
      id
      name
    }
  }
}
    `;
export const WorkflowVisualizationFragmentDoc = gql`
    fragment WorkflowVisualization on Workflow {
  id
  name
  description
  workflowTemplate {
    id
    name
    steps {
      resolvers {
        id
        name
      }
    }
  }
  steps {
    ... on DocumentApprovalWorkflowStep {
      ...DocumentApprovalStep
    }
    ... on UserApprovalWorkflowStep {
      ...UserApprovalStep
    }
    ... on SystemApprovalWorkflowStep {
      ...SystemApprovalStep
    }
  }
}
    `;
export const VirtualCardRequestDataFragmentDoc = gql`
    fragment VirtualCardRequestData on VirtualCardRequest {
  validityPeriod
  approvedLabel
  label
  comment
  requestedLimit {
    value
    precision
    currency
  }
  requestedLimitRenewFrequency
  requestedTransactionLimit {
    value
    precision
    currency
  }
}
    `;
export const SingleUseVirtualCardRequestDataFragmentDoc = gql`
    fragment SingleUseVirtualCardRequestData on SingleUseVirtualCardRequest {
  purpose
  requestedTransactionLimit {
    value
    precision
    currency
  }
}
    `;
export const PhysicalCardRequestDataFragmentDoc = gql`
    fragment PhysicalCardRequestData on PhysicalCardRequest {
  comment
  label
  requestedLimitRenewFrequency
  requestedLimit {
    value
    precision
    currency
  }
  requestedTransactionLimit {
    value
    precision
    currency
  }
}
    `;
export const CardLimitChangeRequestDataFragmentDoc = gql`
    fragment CardLimitChangeRequestData on CardLimitChangeRequest {
  comment
  requestedLimitRenewFrequency
  requestedTransactionLimit {
    value
    precision
    currency
  }
  requestedLimit {
    value
    precision
    currency
  }
}
    `;
export const CreditCardByIdDataFragmentDoc = gql`
    fragment creditCardByIdData on CardIssuerCard {
  id
  permissionsForMembership
  cardholder {
    id
    membershipId
    membershipEmail
    firstName
    lastName
  }
  type
  status
  label
  refNum
  issuingDate
  shippingDate
  expiryDate
  purpose
  limitRenewFrequency
  limitRenewDate
  limit {
    value
    precision
    currency
  }
  spendingBalance {
    value
    precision
    currency
  }
  availableBalance {
    value
    precision
    currency
  }
  transactionLimit {
    value
    precision
    currency
  }
  brand
  pendingRequest {
    __typename
    id
    status
    createdAt
    ...VirtualCardRequestData
    ...SingleUseVirtualCardRequestData
    ...PhysicalCardRequestData
    ...CardLimitChangeRequestData
  }
  allRequests {
    __typename
    id
    status
    createdAt
    ...VirtualCardRequestData
    ...SingleUseVirtualCardRequestData
    ...PhysicalCardRequestData
    ...CardLimitChangeRequestData
  }
  pendingActions {
    id
    name
    createdAt
  }
  linkedVendors {
    records {
      vendor {
        name
      }
      linkedCards {
        card {
          id
        }
        transactionState
      }
    }
    pageInfo {
      totalCount
    }
  }
  hasAccountingData
  accountingData {
    contactId
    costCenterId
    costObjectId
    generalLedgerAccountId
    bookingKeyId
    artistSocialInsuranceCode
    extraCostInfoId
    paymentConditionId
    approvalWorkflowTemplateId
    approverIds
  }
  replacedByCardId
  replacedCardId
  replacedBy {
    firstName
    lastName
  }
  isInvoiceNeeded
}
    `;
export const AggregatedEcmDocumentFragmentDoc = gql`
    fragment AggregatedEcmDocument on AggregatedEcmDocument {
  globalDocumentId
  fileSource {
    id
    name
  }
  amount {
    amount
    currency
    precision
  }
  contactName
  createdBy {
    avatarUrl
    name
  }
  createdAt
  tags {
    id
    name
    description
  }
  documentName
  notes
  documentNumber
  documentStatus
  documentDate
  isSensitive
  notifyPerson {
    primary {
      avatarUrl
      name
    }
  }
  responsiblePerson {
    primary {
      avatarUrl
      name
    }
  }
  type
  terminationReminderDate
  startDate
  endDate
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on Document {
  globalId: globalDocumentId
  status
  amount {
    value
  }
  currency {
    value
  }
  contact {
    value {
      id
      name {
        value
      }
    }
  }
  invoiceDate {
    value
  }
  invoiceNumber {
    value
  }
  isOverdue
  eInvoice {
    format
    error
  }
  eInvoiceValidationErrors
  isEInvoice
  createdAt
  tags {
    id
    name
    description
  }
  documentFile {
    id
    name
  }
  bookings {
    note {
      value
    }
  }
  note {
    value
  }
  attachments {
    id
  }
}
    `;
export const RelatedDocumentFragmentDoc = gql`
    fragment RelatedDocument on RelatedDocument {
  ... on RelatedDocument {
    id
    document {
      ... on AggregatedEcmDocument {
        ...AggregatedEcmDocument
      }
      ... on Document {
        ...Document
      }
    }
  }
}
    `;
export const InAppNotificationDataFragmentDoc = gql`
    fragment InAppNotificationData on InAppNotification {
  __typename
  id
  isRead
  notificationCreatedAt
  organizationId
}
    `;
export const CardRequestApprovedInAppNotificationDataFragmentDoc = gql`
    fragment CardRequestApprovedInAppNotificationData on CardRequestApprovedInAppNotification {
  __typename
  cardRequestType
  resolutionComment
  limit {
    value
    precision
    currency
  }
  transactionLimit {
    value
    precision
    currency
  }
  cardManager {
    avatarUrl
    name
  }
  cardId
  cardRefNum
  cardRequestStatusChangedAt
}
    `;
export const CardRequestCreatedInAppNotificationDataFragmentDoc = gql`
    fragment CardRequestCreatedInAppNotificationData on CardRequestCreatedInAppNotification {
  __typename
  cardRequestCreatedAt
  cardRequestType
  requester {
    name
    avatarUrl
  }
  cardId
  cardRefNum
  newRequestedLimit {
    value
    precision
    currency
  }
  newRequestedTransactionLimit {
    value
    precision
    currency
  }
}
    `;
export const CardRequestRejectedInAppNotificationDataFragmentDoc = gql`
    fragment CardRequestRejectedInAppNotificationData on CardRequestRejectedInAppNotification {
  __typename
  organizationId
  cardRequestStatusChangedAt
  cardRequestType
  resolutionComment
  limit {
    value
    precision
    currency
  }
  transactionLimit {
    value
    precision
    currency
  }
  cardManager {
    avatarUrl
    name
  }
  cardRefNum
}
    `;
export const DocumentCommentCreatedInAppNotificationDataFragmentDoc = gql`
    fragment DocumentCommentCreatedInAppNotificationData on DocumentCommentCreatedInAppNotification {
  __typename
  documentId
  globalDocumentId
  documentType
  commentText
  commentCreatedAt
  documentNumberCommentCreated: documentNumber
  invoiceType
  invoiceContact
  invoiceAmount {
    value
    currencyCode
  }
  invoiceStatus
  fileName
  organizationId
  author {
    name
    avatarUrl
  }
}
    `;
export const TransactionDeclinedInAppNotificationDataFragmentDoc = gql`
    fragment TransactionDeclinedInAppNotificationData on TransactionDeclinedInAppNotification {
  __typename
  transactionId
  merchantName
  cardRefNum
  organizationId
  transactionCreatedAt
  declineReason {
    title {
      de
      en
    }
  }
  notificationCreatedAt
  transactionAmount {
    value
    precision
    currency
  }
}
    `;
export const DocumentRejectedInAppNotificationDataFragmentDoc = gql`
    fragment DocumentRejectedInAppNotificationData on DocumentRejectedInAppNotification {
  __typename
  documentId
  commentText
  rejectedAt
  invoiceContact
  documentNumber
  invoiceAmount {
    value
    currencyCode
    __typename
  }
  rejectedBy {
    name
    avatarUrl
  }
  fileName
  organizationId
}
    `;
export const TransactionCommentCreatedInAppNotificationDataFragmentDoc = gql`
    fragment TransactionCommentCreatedInAppNotificationData on TransactionCommentCreatedInAppNotification {
  __typename
  transactionId
  commentText
  commentCreatedAt
  cardId
  cardRefNum
  cardLabel
  merchantName
  transactionAmount {
    value
    currency
    precision
  }
  author {
    name
    avatarUrl
  }
  organizationId
}
    `;
export const ReimbursementCaseRejectedInAppNotificationDataFragmentDoc = gql`
    fragment ReimbursementCaseRejectedInAppNotificationData on ReimbursementCaseRejectedInAppNotification {
  __typename
  reimbursementCaseId
  reimbursementCaseTitle
  commentText
  reimbursementCaseTotalAmount {
    value
    precision
    currency
  }
  rejectedBy {
    name
    avatarUrl
  }
  rejectedAt
}
    `;
export const ReimbursementCaseItemExcludedInAppNotificationDataFragmentDoc = gql`
    fragment ReimbursementCaseItemExcludedInAppNotificationData on ReimbursementCaseItemExcludedInAppNotification {
  __typename
  title
  type
  reimbursementCaseId
  reimbursementCaseTitle
  totalAmount {
    value
    precision
    currency
  }
  updatedBy {
    name
    avatarUrl
  }
  updatedAt
}
    `;
export const ReimbursementCaseAmendmentRequestedInAppNotificationDataFragmentDoc = gql`
    fragment ReimbursementCaseAmendmentRequestedInAppNotificationData on ReimbursementCaseAmendmentRequestedInAppNotification {
  __typename
  reimbursementCaseId
  reimbursementCaseTitle
  commentText
  reimbursementCaseTotalAmount {
    value
    precision
    currency
  }
  reviewedBy {
    name
    avatarUrl
  }
  reviewedAt
}
    `;
export const ReimbursementCaseApprovedInAppNotificationDataFragmentDoc = gql`
    fragment ReimbursementCaseApprovedInAppNotificationData on ReimbursementCaseApprovedInAppNotification {
  __typename
  reimbursementCaseId
  reimbursementCaseTitle
  approvedBy {
    name
    avatarUrl
  }
  approvedAt
}
    `;
export const ReimbursementCaseCommentCreatedInAppNotificationDataFragmentDoc = gql`
    fragment ReimbursementCaseCommentCreatedInAppNotificationData on ReimbursementCaseCommentCreatedInAppNotification {
  __typename
  reimbursementCaseId
  reimbursementCaseTitle
  commentText
  author {
    name
    avatarUrl
  }
  commentCreatedAt
  reimbursementCaseTotalAmount {
    value
    precision
    currency
  }
}
    `;
export const DocumentWorkflowStatusDataFragmentDoc = gql`
    fragment DocumentWorkflowStatusData on Document {
  id
  status
  currentWorkflowStep {
    id
  }
  workflow {
    id
    steps {
      id
      resolution {
        type
      }
      approvers {
        id
        substitute {
          id
          name
        }
      }
    }
  }
}
    `;
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  name
  description
  isActive
  createdAt
  createdBy {
    id
    name
    avatarUrl
  }
}
    `;
export const EcmDocumentConnectionDataFragmentDoc = gql`
    fragment EcmDocumentConnectionData on GetEcmDocumentsResponse {
  edges {
    cursor
    node: record {
      id
      invoiceId
      documentType
      tags {
        ...Tag
      }
    }
  }
  pageInfo {
    totalCount
  }
}
    `;
export const AllDocumentConnectionDataFragmentDoc = gql`
    fragment AllDocumentConnectionData on ArchiveAllDocumentsResponse {
  edges {
    cursor
    node {
      id
      invoiceId
      documentType
      reimbursementItem {
        caseId
      }
      tags {
        ...Tag
      }
    }
  }
  pageInfo {
    totalCount
  }
}
    `;
export const SapB1ImportResponseDataFragmentDoc = gql`
    fragment SapB1ImportResponseData on SapB1ImportResponse {
  __typename
  code
}
    `;
export const PurchaseOrderImportHistoryEntryDataFragmentDoc = gql`
    fragment PurchaseOrderImportHistoryEntryData on PurchaseOrderImportHistoryEntry {
  __typename
  id
}
    `;
export const GoodsReceiptImportHistoryEntryDataFragmentDoc = gql`
    fragment GoodsReceiptImportHistoryEntryData on GoodsReceiptImportHistoryEntry {
  __typename
  id
}
    `;
export const CardIssuerCardEdgeDataFragmentDoc = gql`
    fragment CardIssuerCardEdgeData on CardIssuerCardEdge {
  cursor
  node {
    id
    cardholder {
      id
      firstName
      lastName
      avatarUrl
      membershipEmail
      membershipId
    }
    type
    status
    label
    refNum
    category
    issuingDate
    lastTransactionCreatedAt
    purpose
    expiryDate
    limit {
      value
      precision
      currency
    }
    spendingBalance {
      value
      precision
      currency
    }
    availableBalance {
      value
      precision
      currency
    }
    transactionLimit {
      value
      precision
      currency
    }
    brand
    limitRenewFrequency
    hasAccountingData
    pendingRequest {
      __typename
      id
      status
      createdAt
      ...VirtualCardRequestData
      ...SingleUseVirtualCardRequestData
      ...PhysicalCardRequestData
      ...CardLimitChangeRequestData
    }
  }
}
    `;
export const GeneralExpenseItemDataFragmentDoc = gql`
    fragment GeneralExpenseItemData on GeneralExpenseItem {
  files {
    name
    url
    id
  }
  type
}
    `;
export const HospitalityExpenseItemDataFragmentDoc = gql`
    fragment HospitalityExpenseItemData on HospitalityExpenseItem {
  files {
    name
    url
    id
  }
  type
}
    `;
export const PerDiemItemDataFragmentDoc = gql`
    fragment PerDiemItemData on PerDiemItem {
  files {
    name
    url
    id
  }
  type
}
    `;
export const PurchaseOrdersImportHistoryDataFragmentDoc = gql`
    fragment PurchaseOrdersImportHistoryData on PurchaseOrderImportHistoryEntry {
  id
  createdBy {
    id
    name
  }
  createdAt
  updatedAt
  finishedAt
  status
  importResult {
    successfullyCreatedDocumentsCount
    successfullyUpdatedDocumentsCount
    failedToImportDocumentsCount
  }
}
    `;
export const ContactDataFragmentDoc = gql`
    fragment ContactData on Contact {
  id
  name {
    value
  }
  organizationId
  email
  phoneNumber
  iban {
    value
  }
  taxNumber {
    value
  }
  vatId {
    value
  }
  dueDateOffset {
    value
  }
  paymentCondition {
    id
    dueDateOffset
    discountOffset
    discountPercentage
    paymentConditionName
  }
  createTransfer {
    value
  }
  accountsPayableNumber
  accountsReceivableNumber
  createdAt
  updatedAt
  additionalContactInfo {
    contactType
    shortName
    companyName
    individualFirstName
    individualLastName
    notSpecifiedName
    paymentMedium
  }
  relationshipType
  swiftCode
  bankAccountNumber
  street
  postOfficeBox
  postalCode
  city
  countryISOCode
  customerNumber
  isArchived
}
    `;
export const CardIssuerCardDataFragmentDoc = gql`
    fragment CardIssuerCardData on CardIssuerCard {
  id
  team {
    id
  }
  cardholder {
    id
    firstName
    lastName
    avatarUrl
    membershipEmail
    membershipId
  }
  availableBalance {
    value
    currency
    precision
  }
  limit {
    value
    currency
    precision
  }
  type
  status
  label
  purpose
  refNum
  brand
  pendingActions {
    name
  }
}
    `;
export const DocumentCollaborationDataFragmentDoc = gql`
    fragment DocumentCollaborationData on DocumentCollaboration {
  id
  viewers {
    membership {
      id
      name
      avatarUrl
    }
    lastActivity
    currentField
  }
  editors {
    membership {
      id
      name
      avatarUrl
    }
    lastActivity
    currentField
  }
}
    `;
export const FileUploadedEventDataFragmentDoc = gql`
    fragment FileUploadedEventData on FileUploadedEvent {
  user {
    id
    avatarUrl
    name
  }
  file {
    name
    url
  }
  clientInfo {
    name
  }
  uploaderEmail
}
    `;
export const EcmDocumentFileFragmentDoc = gql`
    fragment EcmDocumentFile on AggregatedEcmDocument {
  fileSource {
    name
    size
    url
    id
  }
}
    `;
export const ProcessingFormContactDataFragmentDoc = gql`
    fragment ProcessingFormContactData on Contact {
  id
  name {
    value
  }
  accountsPayableNumber
  accountsReceivableNumber
  createTransfer {
    value
  }
  iban {
    value
  }
  bankAccountNumber
  paymentCondition {
    id
  }
  taxNumber {
    value
  }
  vatId {
    value
  }
  swiftCode
  email
  phoneNumber
}
    `;
export const ReimbursementListDataFragmentDoc = gql`
    fragment ReimbursementListData on ReimbursementCase {
  id
  createdAt
  title
  status
  targetMembership {
    id
    firstName
    lastName
    avatarUrl
  }
  totalAmount {
    amount
    currency
    precision
  }
}
    `;
export const ExportFragmentDoc = gql`
    fragment Export on Export {
  id
  status
  type
  exportableEntityInfo {
    id
    type
    status
    __typename
  }
}
    `;
export const ProvisionFragmentDoc = gql`
    fragment Provision on Provision {
  id
  deliveryDate
  glAccount
  contact {
    name
  }
  entity {
    status
    id
    type
  }
  netAmount {
    currency
    amount
    precision
  }
  bookings {
    glAccount
    account
  }
}
    `;
export const ReversalFragmentDoc = gql`
    fragment Reversal on Reversal {
  id
  deliveryDate
  invoiceDate
  glAccount
  contact {
    name
  }
  entity {
    status
    id
    type
  }
  netAmount {
    currency
    amount
    precision
  }
  bookings {
    glAccount
    account
  }
}
    `;
export const AnExportableDocumentFragmentDoc = gql`
    fragment AnExportableDocument on ExportableDocument {
  id
  documentGuid
  contact {
    id
    name {
      value
    }
  }
  amount {
    amount
    currency
    precision
  }
  paymentState {
    __typename
    ... on PaidDocumentState {
      isPaid
      paidAt
      method
    }
    ... on UnpaidDocumentState {
      isPaid
    }
  }
  invoiceDate
  invoiceNumber
  transactionStatus
  direction
  documentType
}
    `;
export const AnExportableDocumentEsFragmentDoc = gql`
    fragment AnExportableDocumentEs on ExportDocumentEs {
  id
  documentGuid
  contact {
    id
    name
    __typename
  }
  amount {
    amount
    currency
    precision
    __typename
  }
  paymentState {
    isPaid
    paidAt
    method
  }
  invoiceDate
  invoiceNumber
  transactionStatus
  direction
  documentType
  __typename
}
    `;
export const AnExportableTransactionFragmentDoc = gql`
    fragment AnExportableTransaction on CardIssuerTransaction {
  id
  invoiceAssociationStatus
  billingAmount {
    value
    precision
    currency
  }
  transactionCreatedAt
  merchantLegalName
  accountingData {
    contact {
      id
      name {
        value
      }
    }
  }
  status
  member {
    firstName
    lastName
  }
  type
  cardName
}
    `;
export const MoneyFieldsFragmentDoc = gql`
    fragment MoneyFields on Money {
  amount
  currency
  precision
}
    `;
export const PayableDocumentDataFragmentDoc = gql`
    fragment PayableDocumentData on PayableDocument {
  id
  amount {
    ...MoneyFields
  }
  contact {
    id
    name {
      value
    }
  }
  status
  currency
  invoiceDate
  invoiceNumber
  invoiceDueDate
  discountAmount {
    ...MoneyFields
  }
  discountPercentage
  discountPaymentDate
  ... on SepaExportableDocument {
    __typename
    creditorIban
    creditorSwiftCode
  }
  ... on DocumentWithIncompletePaymentData {
    __typename
  }
}
    `;
export const SepaTransferPaymentHistoryDataFragmentDoc = gql`
    fragment SepaTransferPaymentHistoryData on SepaTransferPayment {
  ... on SepaTransferPayment {
    id
    creationDate
    paymentInitiator {
      name
    }
    numberOfPaidDocuments
    totalAmount {
      ...MoneyFields
    }
    currency
  }
}
    `;
export const PaidDocumentDataFragmentDoc = gql`
    fragment PaidDocumentData on PaidDocument {
  documentId
  creditorIban
  creditorSwiftCode
  invoiceNumber
  amount {
    ...MoneyFields
  }
  currency
  invoiceDate
  invoiceDueDate
  creditor {
    name
  }
  discountAmount {
    ...MoneyFields
  }
  discountPaymentDate
}
    `;
export const SepaTransferPaymentDataFragmentDoc = gql`
    fragment SepaTransferPaymentData on SepaTransferPayment {
  ... on SepaTransferPayment {
    id
    creationDate
    paymentInitiator {
      name
      avatarUrl
    }
    paidDocuments {
      ...PaidDocumentData
    }
    totalAmount {
      ...MoneyFields
    }
    currency
    swiftCode
  }
}
    `;
export const PayableDocumentNewEsDataFragmentDoc = gql`
    fragment PayableDocumentNewEsData on PayableDocumentEs {
  id
  globalDocumentId
  status
  contact {
    id
    name
  }
  iban
  invoiceNumber
  isSEPAExportable
  bookings {
    artistSocialInsuranceCode
    dueDate
    discountAmount
    discountPercentage
    discountPaymentDate
    paymentConditionId
  }
  amount {
    amount
    currency
    precision
  }
  isPayable
  invoiceDate
  createdAt
  updatedAt
  invoiceDueDate
  discountAmount {
    amount
    currency
    precision
  }
  discountPercentage
  discountPaymentDate
}
    `;
export const ReimbursementCaseTitleUpdatedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseTitleUpdatedEventData on ReimbursementCaseTitleUpdatedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldTitle
    newTitle
  }
}
    `;
export const ExpenseItemTitleUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemTitleUpdatedEventData on ExpenseItemTitleUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldTitle
    newTitle
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseAmendmentRequestedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseAmendmentRequestedEventData on ReimbursementCaseAmendmentRequestedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    requestedAt
    comment {
      author {
        name
      }
      id
      text
    }
  }
}
    `;
export const ReimbursementCaseApprovedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseApprovedEventData on ReimbursementCaseApprovedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    approvedAt
  }
}
    `;
export const ReimbursementCaseCreatedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseCreatedEventData on ReimbursementCaseCreatedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    createdAt
  }
}
    `;
export const ReimbursementCaseSubmittedForReviewEventDataFragmentDoc = gql`
    fragment ReimbursementCaseSubmittedForReviewEventData on ReimbursementCaseSubmittedForReviewEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    submittedAt
  }
}
    `;
export const ReimbursementCaseTargetMembershipUpdatedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseTargetMembershipUpdatedEventData on ReimbursementCaseTargetMembershipUpdatedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldTargetMembership {
      name
    }
    newTargetMembership {
      name
    }
  }
}
    `;
export const ReimbursementCaseNoteUpdatedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseNoteUpdatedEventData on ReimbursementCaseNoteUpdatedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldNote
    newNote
  }
}
    `;
export const ExpenseItemTotalAmountUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemTotalAmountUpdatedEventData on ExpenseItemTotalAmountUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldTotalAmount
    newTotalAmount
    oldCurrency
    newCurrency
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemFileAddedEventDataFragmentDoc = gql`
    fragment ExpenseItemFileAddedEventData on ExpenseItemFileAddedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    fileId
  }
  reimbursementItemMetadata {
    title
    type
  }
  clientInfo {
    name
  }
}
    `;
export const ExpenseItemFileRemovedEventDataFragmentDoc = gql`
    fragment ExpenseItemFileRemovedEventData on ExpenseItemFileRemovedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    fileId
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemExpenseDateUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemExpenseDateUpdatedEventData on ExpenseItemExpenseDateUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldExpenseDate
    newExpenseDate
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseCommentAddedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseCommentAddedEventData on ReimbursementCaseCommentAddedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    comment {
      text
    }
  }
}
    `;
export const ExpenseItemArchivedEventDataFragmentDoc = gql`
    fragment ExpenseItemArchivedEventData on ExpenseItemArchivedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    archivedAt
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemHospitalityExternalGuestsUpdatedEventData on ExpenseItemHospitalityExternalGuestsUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldExternalGuests
    newExternalGuests
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemHospitalityInternalGuestsUpdatedEventData on ExpenseItemHospitalityInternalGuestsUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldInternalGuests
    newInternalGuests
  }
  reimbursementItemMetadata {
    title
  }
}
    `;
export const ExpenseItemHospitalityLocationUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemHospitalityLocationUpdatedEventData on ExpenseItemHospitalityLocationUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldLocation
    newLocation
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemHospitalityReceiptAmountUpdatedEventData on ExpenseItemHospitalityReceiptAmountUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldReceiptAmount
    newReceiptAmount
    oldCurrency
    newCurrency
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemHospitalityTipAmountUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemHospitalityTipAmountUpdatedEventData on ExpenseItemHospitalityTipAmountUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    oldTipAmount
    newTipAmount
    oldCurrency
    newCurrency
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemBookingDataFragmentDoc = gql`
    fragment ExpenseItemBookingData on ExpenseItemBooking {
  costCenter {
    readableName
  }
  costObject {
    readableName
  }
  extraCostInfo {
    readableName
  }
  artistSocialInsuranceCode
  generalLedgerAccount {
    readableName
  }
  bookingKey {
    readableName
  }
  amount
  vatRate
  note
  postingText
  taxAmount
  netAmount
}
    `;
export const ExpenseItemBookingAddedEventDataFragmentDoc = gql`
    fragment ExpenseItemBookingAddedEventData on ExpenseItemBookingAddedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    currency
    booking {
      ...ExpenseItemBookingData
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemBookingRemovedEventDataFragmentDoc = gql`
    fragment ExpenseItemBookingRemovedEventData on ExpenseItemBookingRemovedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    booking {
      ...ExpenseItemBookingData
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemBookingUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemBookingUpdatedEventData on ExpenseItemBookingUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    currency
    newBooking {
      ...ExpenseItemBookingData
    }
    oldBooking {
      ...ExpenseItemBookingData
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseResetApprovalEventDataFragmentDoc = gql`
    fragment ReimbursementCaseResetApprovalEventData on ReimbursementCaseResetApprovalEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    resetAt
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseItemAddedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseItemAddedEventData on ReimbursementCaseItemAddedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    expenseItemType
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseItemRemovedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseItemRemovedEventData on ReimbursementCaseItemRemovedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    expenseItemType
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseItemIncludedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseItemIncludedEventData on ReimbursementCaseItemIncludedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    expenseItemType
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseItemExcludedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseItemExcludedEventData on ReimbursementCaseItemExcludedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    expenseItemType
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemInvoiceNumberUpdatedEventDataFragmentDoc = gql`
    fragment ExpenseItemInvoiceNumberUpdatedEventData on ExpenseItemInvoiceNumberUpdatedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  properties {
    newInvoiceNumber
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseItemExportStatusUpdatedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseItemExportStatusUpdatedEventData on ReimbursementCaseItemExportStatusUpdatedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    exportGuid
    exportType
    failedItems {
      reimbursementItemId
      reimbursementItemTitle
      reimbursementItemType
    }
    successfulItems {
      datevLocationParams {
        datevClientNumber
        datevConsultantNumber
        entityExportGuid
      }
      reimbursementItemId
      reimbursementItemTitle
      reimbursementItemType
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseSubmittedForApprovalEventDataFragmentDoc = gql`
    fragment ReimbursementCaseSubmittedForApprovalEventData on ReimbursementCaseSubmittedForApprovalEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    submittedAt
    approvers {
      name
    }
  }
}
    `;
export const ReimbursementCaseTriggeredWorkflowEventDataFragmentDoc = gql`
    fragment ReimbursementCaseTriggeredWorkflowEventData on ReimbursementCaseTriggeredWorkflowEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    submittedAt
    workflow {
      name
      description
    }
  }
}
    `;
export const ReimbursementCaseWorkflowStepApprovedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseWorkflowStepApprovedEventData on ReimbursementCaseWorkflowStepApprovedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    approvedBy {
      name
    }
    approvedAt
  }
}
    `;
export const ReimbursementCaseWorkflowStepRejectedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseWorkflowStepRejectedEventData on ReimbursementCaseWorkflowStepRejectedEvent {
  user {
    id
    name
    avatarUrl
  }
  properties {
    rejectedBy {
      name
    }
    rejectedAt
  }
}
    `;
export const ReimbursementCaseRejectedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseRejectedEventData on ReimbursementCaseRejectedEvent {
  user {
    id
    name
    avatarUrl
  }
  clientInfo {
    name
  }
  properties {
    rejectedAt
    rejectedBy {
      name
    }
    comment {
      text
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemHospitalityDataExtractedEventDataFragmentDoc = gql`
    fragment ExpenseItemHospitalityDataExtractedEventData on ExpenseItemHospitalityDataExtractedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  clientInfo {
    name
  }
  properties {
    invoiceNumber
    expenseDate
    sourceFileId
    location
    receiptAmount {
      amount
      currency
      precision
    }
    tipAmount {
      amount
      currency
      precision
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ExpenseItemGeneralDataExtractedEventDataFragmentDoc = gql`
    fragment ExpenseItemGeneralDataExtractedEventData on ExpenseItemGeneralDataExtractedEvent {
  resourceId
  user {
    id
    name
    avatarUrl
  }
  clientInfo {
    name
  }
  properties {
    invoiceNumber
    expenseDate
    sourceFileId
    totalAmount {
      amount
      currency
      precision
    }
  }
  reimbursementItemMetadata {
    title
    type
  }
}
    `;
export const ReimbursementCaseWorkflowStepSkippedEventDataFragmentDoc = gql`
    fragment ReimbursementCaseWorkflowStepSkippedEventData on ReimbursementCaseWorkflowStepSkippedEvent {
  user {
    id
    name
    avatarUrl
  }
  clientInfo {
    name
  }
  properties {
    skippedAt
    workflowStep {
      conditions {
        field
        operator
        values
      }
    }
    skippedApprovers: approvers {
      id
      name
    }
  }
}
    `;
export const ReimbursementItemsListGeneralExpenseDataFragmentDoc = gql`
    fragment ReimbursementItemsListGeneralExpenseData on GeneralExpenseItem {
  id
  type
  status
  title
  updatedAt
  extractedDataAccepted
  totalAmount {
    amount
    currency
    precision
  }
  errors {
    field
    errors
  }
}
    `;
export const ReimbursementItemsListHospitalityExpenseDataFragmentDoc = gql`
    fragment ReimbursementItemsListHospitalityExpenseData on HospitalityExpenseItem {
  id
  type
  status
  title
  updatedAt
  extractedDataAccepted
  totalAmount {
    amount
    currency
    precision
  }
  errors {
    field
    errors
  }
}
    `;
export const ReimbursementItemsListPerDiemDataFragmentDoc = gql`
    fragment ReimbursementItemsListPerDiemData on PerDiemItem {
  id
  type
  status
  currentStep
  updatedAt
  totalAmount {
    amount
    currency
    precision
  }
  errors {
    field
    errors
  }
}
    `;
export const ReimbursementItemsListMileageDataFragmentDoc = gql`
    fragment ReimbursementItemsListMileageData on MileageReimbursementItem {
  id
  type
  status
  updatedAt
  totalAmount {
    amount
    currency
    precision
  }
}
    `;
export const ReimbursementCaseDataFragmentDoc = gql`
    fragment ReimbursementCaseData on ReimbursementCase {
  id
  organizationId
  createdAt
  updatedAt
  status
  createTransfer
  contact {
    contactId
    iban
  }
  isPaid
  paidAt
  title
  note
  createdByMembership {
    id
    firstName
    lastName
    avatarUrl
  }
  targetMembership {
    id
    firstName
    lastName
    avatarUrl
  }
  totalAmount {
    amount
    currency
    precision
  }
  advancedAccountingEnabled
  suggestedCostCenter {
    id
    readableName
  }
  suggestedCostObject {
    id
    readableName
  }
  suggestedGeneralLedgerAccount {
    id
    readableName
  }
  errors {
    field
    errors
  }
}
    `;
export const ArchiveReimbursementCasesListItemDataFragmentDoc = gql`
    fragment ArchiveReimbursementCasesListItemData on ArchiveReimbursementCasesListItem {
  id
  organizationId
  createdAt
  status
  title
  totalAmount {
    amount
    currency
    precision
  }
}
    `;
export const ReimbursementCasesSummaryFragmentFragmentDoc = gql`
    fragment ReimbursementCasesSummaryFragment on ReimbursementCasesSummary {
  draft {
    totalCount
    totalAmount {
      amount
      currency
      precision
    }
    withoutInvoiceCount
  }
  open {
    totalCount
    totalAmount {
      amount
      currency
      precision
    }
    olderThan14DaysCount
  }
  closed {
    totalCount
    last30DaysCount
  }
  approved {
    approvedInLast30DaysCount
    approvedInLast30DaysAmount {
      amount
      currency
      precision
    }
  }
}
    `;
export const AccountingAreaFragmentDoc = gql`
    fragment AccountingArea on AccountingArea {
  id
  name
  description
  isArchived
  createdAt
}
    `;
export const ContractSubCategoryFragmentDoc = gql`
    fragment ContractSubCategory on ContractSubCategory {
  id
  name
  description
  isActive
}
    `;
export const DocumentTypeSettingFragmentDoc = gql`
    fragment DocumentTypeSetting on EcmDocumentTypeSettingRecord {
  name
  isVisible
  documentCount
  isNonEcmType
}
    `;
export const EmailFragmentDoc = gql`
    fragment Email on EmailInboxMessage {
  id
  status
  from
  subject
  createdAt
}
    `;
export const OrganizationDataFragmentDoc = gql`
    fragment OrganizationData on Organization {
  id
  name
  slug
  locale
  realmName
  ssoConfigurationId
  organizationContactInformation {
    vatId
    taxNumber
  }
}
    `;
export const BankAccountsIbanDataFragmentDoc = gql`
    fragment BankAccountsIbanData on Iban {
  ... on Iban {
    id
    name
    iban
    swiftCode
    isDefault
    logoUrl
  }
}
    `;
export const BuiltInRoleDataFragmentDoc = gql`
    fragment BuiltInRoleData on BuiltinRole {
  builtInRoleName: name
  id
  isLegacyRole
  __typename
}
    `;
export const CustomRoleDataFragmentDoc = gql`
    fragment CustomRoleData on CustomRole {
  customRoleName: name
  id
  updatedAt
  updatedBy {
    id
    name
  }
  __typename
}
    `;
export const PermissionDataFragmentDoc = gql`
    fragment PermissionData on Permission {
  id
  name
  description
  category
  isActive
}
    `;
export const BookingKeyDataFragmentDoc = gql`
    fragment BookingKeyData on BookingKey {
  id
  createdBy {
    id
    name
    avatarUrl
  }
  taxCode
  description
  readableName
  createdAt
  isArchived
  isAcquisitionReverse
  taxPercentage
}
    `;
export const WorkflowTemplateDataFragmentDoc = gql`
    fragment WorkflowTemplateData on WorkflowTemplate {
  id
  name
  description
  isArchived
}
    `;
export const BookingAccountDataFragmentDoc = gql`
    fragment BookingAccountData on BookingAccount {
  id
  createdBy {
    id
    name
    avatarUrl
  }
  type
  accountCode
  name
  readableName
  createdAt
  isArchived
}
    `;
export const CostCenterDataFragmentDoc = gql`
    fragment CostCenterData on CostCenter {
  id
  name
  type
  code
  readableName
  isArchived
  approverIds
  createdAt
  createdBy {
    name
    avatarUrl
  }
  approvers {
    id
    name
    avatarUrl
  }
}
    `;
export const OrganizationCreditCardsSettingsDataFragmentDoc = gql`
    fragment OrganizationCreditCardsSettingsData on OrganizationCreditCardsSettings {
  tradeName
  legalForm
  foundationDate
  country
  accountType
  nationalRegisterCourt
  nationalRegisterId
  nationalRegisterType
  tradeRegisterNumber
  vatId
  billingEmail
  isCompanyNamePrintable
  deliveryAddressSameAsBillingAddress
  billingAddress {
    country
    city
    postalCode
    streetFull
    streetName
    streetNumber
    streetAdditional
  }
  deliveryAddress {
    country
    city
    postalCode
    streetFull
    streetName
    streetNumber
    streetAdditional
  }
  processingAccount {
    bankName
    iban
  }
}
    `;
export const DocumentDataFragmentDoc = gql`
    fragment DocumentData on Document {
  id
  isDuplicate
  isOverdue
  isWaitingForClarification
  contact {
    value {
      id
      accountsPayableNumber
      accountsReceivableNumber
      name {
        value
      }
    }
  }
  status
  eInvoice {
    format
    error
  }
  isEInvoice
  invoiceNumber {
    value
  }
  purchaseOrderData {
    orderNumber
  }
  category {
    direction {
      value
    }
    documentType {
      value
    }
  }
  invoiceDate {
    value
  }
  accountingArea {
    value {
      id
      name
    }
  }
  bookings {
    dueDate {
      value
    }
    note {
      value
    }
    postingText
    artistSocialInsuranceCode
    costCenter {
      value {
        id
        name
        readableName
      }
    }
    costObject {
      value {
        id
        name
        readableName
      }
    }
    extraCostInfo {
      value {
        id
        name
        readableName
      }
    }
    generalLedgerAccount {
      value {
        id
        accountCode
        readableName
      }
    }
    vatRate {
      value
    }
    discountAmount {
      value
    }
    discountPercentage {
      value
    }
    discountPaymentDate {
      value
    }
    projectCode {
      value {
        id
        code
        name
      }
    }
  }
  invoiceDeliveryDate {
    value
  }
  invoicePostingDate {
    value
  }
  amount {
    value
  }
  currency {
    value
  }
  invoiceDate {
    value
  }
  requestedAt {
    value
  }
  createdAt
  requester {
    id
    name
    avatarUrl
  }
  iban {
    value
  }
  swiftCode {
    value
  }
  createTransfer {
    value
  }
  approvers {
    id
    name
    avatarUrl
  }
  approvedBy {
    id
  }
  suggestedApprovers {
    value {
      id
      name
      avatarUrl
    }
  }
  documentFile {
    id
    name
    url
    versions
    pages
  }
  originalDocumentId {
    value
  }
  extractedContact {
    id
    cachingKey
    name {
      value
    }
    iban {
      value
    }
    taxNumber {
      value
    }
    vatId {
      value
    }
  }
  hasTransactionLinked
  globalDocumentId
  tags {
    ...Tag
  }
}
    `;
export const InboxInvoiceDocumentDataFragmentDoc = gql`
    fragment InboxInvoiceDocumentData on InboxInvoiceDocument {
  id
  invoiceNumber
  status
  isPayable
  invoiceDeliveryDate
  createdAt
  isOverdue
  isDuplicate
  invoiceDate
  eInvoice {
    format
    error
  }
  isEInvoice
  bookings {
    artistSocialInsuranceCode
    dueDate
    discountAmount
    discountPercentage
    discountPaymentDate
    paymentConditionId
    costCenter {
      id
      readableName
      cachingKey
    }
    costObject {
      id
      readableName
      cachingKey
    }
    extraCostInfo {
      id
      readableName
      cachingKey
    }
  }
  contact {
    id
    name
    cachingKey
    accountsPayableNumber
    accountsReceivableNumber @include(if: $withAccountsReceivableNumber)
  }
  payment {
    id
    isPaid
    method
  }
  amount {
    amount
    currency
    precision
  }
  purchaseOrderData @include(if: $withPurchaseOrderData) {
    orderNumber
    integrationType
  }
  purchaseOrders @include(if: $withPurchaseOrderData) {
    orderNumber
    goodsReceipts {
      _id
    }
  }
  associatedDocuments @include(if: $withPurchaseOrderData) {
    __typename
    ... on PurchaseOrder {
      _id
      orderNumber
      goodsReceipts {
        _id
        receiptNumber
      }
    }
    ... on GoodsReceipt {
      _id
      receiptNumber
      purchaseOrders {
        _id
        orderNumber
      }
    }
  }
  tags {
    id
    name
  }
}
    `;
export const ApprovalInvoiceDocumentDataFragmentDoc = gql`
    fragment ApprovalInvoiceDocumentData on ApprovalInvoiceDocument {
  id
  invoiceNumber
  status
  isPayable
  invoiceDeliveryDate
  createdAt
  isOverdue
  invoiceDate
  iban
  eInvoice {
    format
    error
  }
  accountingArea {
    id
    name
    cachingKey
  }
  isEInvoice
  bookings {
    artistSocialInsuranceCode
    dueDate
    discountAmount
    discountPercentage
    discountPaymentDate
    paymentConditionId
    costCenter {
      id
      readableName
      cachingKey
    }
    costObject {
      id
      readableName
      cachingKey
    }
    extraCostInfo {
      id
      readableName
      cachingKey
    }
  }
  contact {
    id
    name
    cachingKey
    accountsPayableNumber
    accountsReceivableNumber @include(if: $withAccountsReceivableNumber)
  }
  payment {
    id
    isPaid
    method
  }
  amount {
    amount
    currency
    precision
  }
  purchaseOrderData @include(if: $withPurchaseOrderData) {
    orderNumber
    integrationType
  }
  tags {
    id
    name
  }
  requester {
    id
    name
    avatarUrl
  }
  approvers {
    id
    name
    avatarUrl
  }
  requestedAt
}
    `;
export const ArchiveInvoiceDocumentsDataFragmentDoc = gql`
    fragment ArchiveInvoiceDocumentsData on ArchiveInvoiceDocument {
  id
  globalDocumentId
  invoiceNumber
  status
  isPayable
  invoiceDeliveryDate
  isOverdue
  isDuplicate
  invoiceDate
  createdAt
  updatedAt
  invoiceCategoryType
  iban
  swiftCode
  requestedAt
  amount {
    amount
    currency
    precision
  }
  contact {
    id
    name
    cachingKey
    accountsPayableNumber
    accountsReceivableNumber @include(if: $withAccountsReceivableNumber)
  }
  bookings {
    note
    artistSocialInsuranceCode
    dueDate
    discountAmount
    discountPercentage
    discountPaymentDate
    paymentConditionId
    costCenter {
      id
      name
      code
      readableName
      cachingKey
    }
    costObject {
      id
      name
      code
      readableName
      cachingKey
    }
    extraCostInfo {
      id
      name
      code
      readableName
      cachingKey
    }
    generalLedgerAccount {
      id
      name
      accountCode
      readableName
      cachingKey
    }
    postingText
  }
  tags {
    ...Tag
  }
  payment {
    id
    isPaid
    paidAt
    method
    cachingKey
  }
  eInvoice {
    format
    error
  }
  accountingArea {
    id
    name
    cachingKey
  }
  requester {
    id
    name
    avatarUrl
  }
  approvers {
    id
    name
    avatarUrl
  }
  purchaseOrderData @include(if: $withPurchaseOrderData) {
    orderNumber
    integrationType
  }
  purchaseOrders @include(if: $withPurchaseOrderData) {
    orderNumber
    goodsReceipts {
      _id
    }
  }
  associatedDocuments @include(if: $withPurchaseOrderData) {
    __typename
    ... on PurchaseOrder {
      _id
      orderNumber
      goodsReceipts {
        _id
        receiptNumber
      }
    }
    ... on GoodsReceipt {
      _id
      receiptNumber
      purchaseOrders {
        _id
        orderNumber
      }
    }
  }
  documentFile {
    name
  }
}
    `;
export const DocumentListDataFragmentDoc = gql`
    fragment DocumentListData on Document {
  id
  isOverdue
  isWaitingForClarification
  isDuplicate
  isPayable
  isEInvoice
  eInvoice {
    format
    error
  }
  isEInvoice
  contact {
    value {
      id
      accountsPayableNumber
      accountsReceivableNumber
      name {
        value
      }
    }
  }
  status
  category {
    direction {
      value
    }
    documentType {
      value
    }
  }
  invoiceNumber {
    value
  }
  amount {
    value
  }
  currency {
    value
  }
  createdAt
  requestedAt {
    value
  }
  invoiceDate {
    value
  }
  invoiceDeliveryDate {
    value
  }
  extractedContact {
    id
    cachingKey
    name {
      value
    }
  }
  iban {
    value
  }
  swiftCode {
    value
  }
  accountingArea {
    value {
      id
      name
    }
  }
  bookings {
    id
    artistSocialInsuranceCode
    costCenter {
      value {
        id
        readableName
      }
    }
    costObject {
      value {
        id
        readableName
      }
    }
    extraCostInfo {
      value {
        id
        name
        readableName
      }
    }
    generalLedgerAccount {
      value {
        id
        readableName
      }
    }
    bookingKey {
      value {
        id
        readableName
      }
    }
    dueDate {
      value
    }
    note {
      value
    }
    postingText
    discountAmount {
      value
    }
    discountPercentage {
      value
    }
    discountPaymentDate {
      value
    }
  }
  requester {
    id
    name
    avatarUrl
  }
  approvers {
    id
    name
    avatarUrl
  }
  approvedBy {
    id
  }
  paymentState {
    __typename
    ... on PaidDocumentState {
      paidAt
      method
    }
  }
  purchaseOrderData @include(if: $withPurchaseOrderData) {
    orderNumber
    integrationType
  }
  purchaseOrders @include(if: $withPurchaseOrderData) {
    orderNumber
    goodsReceipts {
      _id
    }
  }
  associatedDocuments @include(if: $withPurchaseOrderData) {
    __typename
    ... on PurchaseOrder {
      _id
      orderNumber
      goodsReceipts {
        _id
        receiptNumber
      }
    }
    ... on GoodsReceipt {
      _id
      receiptNumber
      purchaseOrders {
        _id
        orderNumber
      }
    }
  }
  tags {
    ...Tag
  }
  documentFile {
    name
    size
  }
}
    `;
export const DocumentIsAlreadyRequestedForApprovalErrorDataFragmentDoc = gql`
    fragment DocumentIsAlreadyRequestedForApprovalErrorData on DocumentIsAlreadyRequestedForApprovalError {
  status
}
    `;
export const ContactDoesNotExistErrorDataFragmentDoc = gql`
    fragment ContactDoesNotExistErrorData on ContactDoesNotExistError {
  kind
  message
}
    `;
export const WorkflowTemplateArchivedErrorDataFragmentDoc = gql`
    fragment WorkflowTemplateArchivedErrorData on WorkflowTemplateArchivedError {
  workflowTemplate {
    id
    name
  }
}
    `;
export const InvalidInvoiceDateErrorDataFragmentDoc = gql`
    fragment InvalidInvoiceDateErrorData on InvalidInvoiceDateError {
  invoiceDate
  referenceDate
}
    `;
export const InvalidDueDateErrorDataFragmentDoc = gql`
    fragment InvalidDueDateErrorData on InvalidDueDateError {
  dueDate
  referenceDate
}
    `;
export const InvalidDeliveryDateErrorDataFragmentDoc = gql`
    fragment InvalidDeliveryDateErrorData on InvalidDeliveryDateError {
  deliveryDate
  referenceDate
}
    `;
export const TransactionDataMismatchErrorDataFragmentDoc = gql`
    fragment TransactionDataMismatchErrorData on TransactionDataMismatchError {
  kind
  message
}
    `;
export const SumOfBookingsDoesNotMatchGrossAmountErrorDataFragmentDoc = gql`
    fragment SumOfBookingsDoesNotMatchGrossAmountErrorData on SumOfBookingsDoesNotMatchGrossAmountError {
  kind
  message
  grossAmount
  bookingsSum
}
    `;
export const DocumentDoesNotExistErrorDataFragmentDoc = gql`
    fragment DocumentDoesNotExistErrorData on DocumentDoesNotExistError {
  kind
  message
}
    `;
export const BasicDocumentConnectionDataFragmentDoc = gql`
    fragment BasicDocumentConnectionData on DocumentConnection {
  edges {
    cursor
    node {
      id
    }
  }
  pageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    totalCount
  }
}
    `;
export const BasicInboxInvoiceDocumentConnectionDataFragmentDoc = gql`
    fragment BasicInboxInvoiceDocumentConnectionData on InboxInvoiceDocumentsResponse {
  edges {
    cursor
    node {
      id
    }
  }
  pageInfo {
    startCursor
    hasNextPage
    totalCount
  }
}
    `;
export const BasicApprovalDocumentConnectionDataFragmentDoc = gql`
    fragment BasicApprovalDocumentConnectionData on ApprovalInvoiceDocumentsResponse {
  edges {
    cursor
    node {
      id
    }
  }
  pageInfo {
    startCursor
    hasNextPage
    totalCount
  }
}
    `;
export const BasicArchiveInvoiceDocumentConnectionDataFragmentDoc = gql`
    fragment BasicArchiveInvoiceDocumentConnectionData on ArchiveInvoiceDocumentsResponse {
  edges {
    cursor
    node {
      id
    }
  }
  pageInfo {
    startCursor
    hasNextPage
    totalCount
  }
}
    `;
export const TransactionDetailsDataFragmentDoc = gql`
    fragment TransactionDetailsData on CardIssuerTransaction {
  id
  status
  cardName
  cardId
  cardRefNum
  cardType
  cardBrand
  category
  invoiceAssociationStatus
  merchantName
  merchantLegalName
  merchantLogoUrl
  transactionCreatedAt
  type
  note
  member {
    membershipId
    firstName
    lastName
    avatarUrl
  }
  documents {
    id
    globalDocumentId
    invoiceDate {
      value
    }
    isEInvoice
    eInvoice {
      format
      error
    }
    eInvoiceValidationErrors
    invoiceNumber {
      value
    }
    amount {
      value
    }
    currency {
      value
    }
    contact {
      value {
        id
        name {
          value
        }
        accountsPayableNumber
        accountsReceivableNumber
        email
      }
    }
    extractedContact {
      cachingKey
      name {
        value
      }
    }
    status
    attachments {
      name
      id
      url
      size
      attachedBy {
        id
      }
      isDetachable
      originalDocumentId
    }
    documentFile {
      id
      name
      url
      size
      eInvoice {
        id
        url
        name
      }
    }
  }
  transactionAmount {
    value
    precision
    currency
  }
  billingAmount {
    value
    precision
    currency
  }
  billingAmountForAccounting {
    value
    precision
    currency
  }
  localizedDeclineReason {
    title {
      en
      de
    }
    description {
      en
      de
    }
  }
  invoiceNotNeeded {
    comment
    reason
  }
  isExported
  accountingData {
    contact {
      id
      name {
        value
      }
    }
    contactId
    costCenterId
    costObjectId
    generalLedgerAccountId
    bookingKeyId
    artistSocialInsuranceCode
    extraCostInfoId
    paymentConditionId
    approvalWorkflowTemplateId
    approvers {
      id
      name
      avatarUrl
    }
    approvalWorkflowTemplateId
  }
}
    `;
export const TransactionListDataFragmentDoc = gql`
    fragment transactionListData on CardIssuerTransaction {
  id
  status
  cardId
  cardRefNum
  invoiceAssociationStatus
  merchantLegalName
  merchantLogoUrl
  transactionCreatedAt
  localizedDeclineReason {
    description {
      en
      de
    }
  }
  category
  type
  member {
    firstName
    lastName
    avatarUrl
    membershipId
  }
  billingAmount {
    value
    precision
    currency
  }
  transactionAmount {
    value
    precision
    currency
  }
}
    `;
export const DocumentBookingAssociationDataFragmentDoc = gql`
    fragment DocumentBookingAssociationData on DocumentBookingAssociation {
  id
  documentId
  bookingId
  articleId
  quantity
  goodsReceiptsInfo {
    goodsReceiptId
    goodsReceiptLineNumber
    goodsReceiptCreationDate
    quantity
    articleId
    linkedPurchaseOrderId
  }
  purchaseOrdersInfo {
    purchaseOrderId
    purchaseOrderLineNumber
    purchaseOrderCreationDate
    quantity
    articleId
    unitPrice {
      __typename
      amount
      currency
      precision
    }
  }
  additionalExpenseInfo {
    expenseType
  }
  unitPrice {
    __typename
    amount
    currency
    precision
  }
  source
}
    `;
export const PageInfoDataFragmentDoc = gql`
    fragment PageInfoData on PageInfo {
  startCursor
  hasPreviousPage
  endCursor
  hasNextPage
  totalCount
}
    `;
export const TransactionCommentEventDataFragmentDoc = gql`
    fragment TransactionCommentEventData on TransactionCommentEvent {
  createdAt
  clientInfo {
    name
  }
  comment {
    id
    text
    author {
      avatarUrl
    }
  }
  user {
    id
    name
  }
}
    `;
export const TransactionStatusUpdatedEventDataFragmentDoc = gql`
    fragment TransactionStatusUpdatedEventData on TransactionStatusUpdatedEvent {
  user {
    id
    name
  }
  createdAt
  properties {
    newStatus
  }
}
    `;
export const TransactionReconciledEventDataFragmentDoc = gql`
    fragment TransactionReconciledEventData on TransactionReconciledEvent {
  user {
    id
    name
  }
  createdAt
  properties {
    merchantName
    amount {
      value
      currency
    }
  }
}
    `;
export const TransactionAmountChangedEventDataFragmentDoc = gql`
    fragment TransactionAmountChangedEventData on TransactionAmountChangedEvent {
  user {
    id
    name
  }
  createdAt
  properties {
    newAmount {
      value
      currency
    }
  }
}
    `;
export const TransactionMarkedAsInvoiceNeededEventDataFragmentDoc = gql`
    fragment TransactionMarkedAsInvoiceNeededEventData on TransactionMarkedAsInvoiceNeededEvent {
  user {
    id
    name
  }
  createdAt
}
    `;
export const TransactionMarkedAsInvoiceNotNeededEventDataFragmentDoc = gql`
    fragment TransactionMarkedAsInvoiceNotNeededEventData on TransactionMarkedAsInvoiceNotNeededEvent {
  user {
    id
    name
  }
  properties {
    reason
    reasonComment
  }
  createdAt
}
    `;
export const TransactionMarkedAsExportedEventDataFragmentDoc = gql`
    fragment TransactionMarkedAsExportedEventData on TransactionMarkedAsExportedEvent {
  createdAt
  user {
    id
    name
  }
  properties {
    exportId
  }
}
    `;
export const GoodsReceiptsImportHistoryDataFragmentDoc = gql`
    fragment GoodsReceiptsImportHistoryData on GoodsReceiptImportHistoryEntry {
  id
  createdBy {
    id
    name
  }
  createdAt
  updatedAt
  finishedAt
  status
  importResult {
    successfullyCreatedDocumentsCount
    successfullyUpdatedDocumentsCount
    failedToImportDocumentsCount
  }
}
    `;
export const ProjectCodeDataFragmentDoc = gql`
    fragment ProjectCodeData on ProjectCode {
  id
  code
  name
  validFrom
  validTo
  createdAt
  updatedAt
  isArchived
}
    `;
export const PurchaseOrderBaseFragmentDoc = gql`
    fragment PurchaseOrderBase on PurchaseOrder {
  _id
  orderNumber
  contactName
  accountsPayableNumber
  postingDate
  amount {
    __typename
    amount
    currency
    precision
  }
}
    `;
export const GoodsLineItemsFragmentDoc = gql`
    fragment GoodsLineItems on GoodsLineItem {
  itemNumber
  quantity
  description
  unitPrice {
    __typename
    amount
    currency
    precision
  }
  lineNumber
}
    `;
export const ConnectedInvoicesFragmentDoc = gql`
    fragment ConnectedInvoices on ConnectedInvoicesData {
  invoiceId
  invoiceNumber
  source
}
    `;
export const SapB1DataFragmentDoc = gql`
    fragment SapB1Data on SapB1Data {
  docNumber
  docEntry
  supplierRefNumber
}
    `;
export const PurchaseOrderListDataFragmentDoc = gql`
    fragment purchaseOrderListData on PurchaseOrder {
  ...PurchaseOrderBase
  status
  invoiceAssociationStatus
  purchaseOrderType
  goodsLineItems {
    ...GoodsLineItems
  }
  serviceLineItems {
    itemNumber
    description
    generalLedgerAccount
    taxCode
    lineTotal {
      __typename
      amount
      currency
      precision
    }
  }
  connectedInvoices {
    ...ConnectedInvoices
  }
  sapB1Data {
    ...SapB1Data
  }
  goodsReceipts {
    _id
    status
  }
}
    `;
export const GoodsReceiptLineItemsFragmentDoc = gql`
    fragment GoodsReceiptLineItems on GoodsReceiptGoodsLineItem {
  itemNumber
  quantity
  description
  unitPrice {
    __typename
    amount
    currency
    precision
  }
  lineNumber
}
    `;
export const AssociatedPurchaseOrderDataFragmentDoc = gql`
    fragment AssociatedPurchaseOrderData on PurchaseOrder {
  ...PurchaseOrderBase
  purchaseOrderStatus: status
  purchaseOrderType
  goodsLineItems {
    ...GoodsLineItems
  }
  connectedInvoices {
    ...ConnectedInvoices
  }
  sapB1Data {
    ...SapB1Data
  }
  goodsReceipts {
    _id
    receiptNumber
    status
    deliveryDate
    amount {
      __typename
      amount
      currency
      precision
    }
    goodsLineItems {
      ...GoodsReceiptLineItems
    }
    sapB1Data {
      ...SapB1Data
    }
  }
}
    `;
export const AssociatedGoodsReceiptDataFragmentDoc = gql`
    fragment AssociatedGoodsReceiptData on GoodsReceipt {
  _id
  deliveryDate
  contactName
  accountsPayableNumber
  goodsReceiptStatus: status
  amount {
    __typename
    amount
    currency
    precision
  }
  goodsLineItems {
    ...GoodsReceiptLineItems
  }
  receiptNumber
  connectedPurchaseOrders {
    purchaseOrderId
    purchaseOrderNumber
  }
  purchaseOrders {
    status
    ...PurchaseOrderBase
    goodsLineItems {
      ...GoodsLineItems
    }
    sapB1Data {
      ...SapB1Data
    }
  }
  sapB1Data {
    ...SapB1Data
  }
}
    `;
export const DocumentCommentEventDataFragmentDoc = gql`
    fragment DocumentCommentEventData on DocumentCommentEvent {
  __typename
  user {
    id
    name
  }
  clientInfo {
    name
  }
  comment {
    id
    text
    author {
      avatarUrl
    }
  }
}
    `;
export const RequestApprovalEventDataFragmentDoc = gql`
    fragment RequestApprovalEventData on RequestApprovalEvent {
  user {
    id
    avatarUrl
    name
  }
  approvers {
    id
    avatarUrl
    name
    isSubstituting {
      id
      name
      avatarUrl
    }
  }
}
    `;
export const SubstituteAddedEventDataFragmentDoc = gql`
    fragment SubstituteAddedEventData on SubstituteAddedEvent {
  substitute {
    id
    name
    email
    avatarUrl
    isSubstituting {
      id
      name
      email
      avatarUrl
    }
  }
}
    `;
export const SubstituteRemovedEventDataFragmentDoc = gql`
    fragment SubstituteRemovedEventData on SubstituteRemovedEvent {
  substitute {
    id
    name
    email
    avatarUrl
    isSubstituting {
      id
      name
      email
      avatarUrl
    }
  }
}
    `;
export const DocumentResetManualEventDataFragmentDoc = gql`
    fragment DocumentResetManualEventData on DocumentResetManualEvent {
  user {
    id
    avatarUrl
    name
  }
}
    `;
export const DocumentResetUserDeactivationEventDataFragmentDoc = gql`
    fragment DocumentResetUserDeactivationEventData on DocumentResetUserDeactivationEvent {
  user {
    id
    avatarUrl
    name
  }
  targetUser {
    id
    name
    email
  }
}
    `;
export const DocumentResetUserRoleChangedEventDataFragmentDoc = gql`
    fragment DocumentResetUserRoleChangedEventData on DocumentResetUserRoleChangedEvent {
  user {
    id
    avatarUrl
    name
  }
  targetUser {
    id
    email
  }
}
    `;
export const FileUploadedByEmailEventDataFragmentDoc = gql`
    fragment FileUploadedByEmailEventData on FileUploadedByEmailEvent {
  user {
    id
    avatarUrl
    name
  }
  file {
    name
    url
  }
  uploaderEmail
}
    `;
export const ApprovedEventDataFragmentDoc = gql`
    fragment ApprovedEventData on ApprovedEvent {
  user {
    id
    avatarUrl
    name
  }
  clientInfo {
    name
  }
  users {
    id
    avatarUrl
    name
  }
}
    `;
export const ContactSupplierSendEmailEventDataFragmentDoc = gql`
    fragment ContactSupplierSendEmailEventData on ContactSupplierSendEmailEvent {
  user {
    id
    avatarUrl
    name
    __typename
  }
  emailPayload {
    subject
    textBody
    htmlBody
    recipients
    replyTo
    cc
  }
}
    `;
export const InvalidEInvoiceDetectedEventDataFragmentDoc = gql`
    fragment InvalidEInvoiceDetectedEventData on InvalidEInvoiceDetectedEvent {
  eInvoiceFormat
  eInvoiceError
  eInvoiceVersion
}
    `;
export const MetaDataExtractionEventDataFragmentDoc = gql`
    fragment MetaDataExtractionEventData on MetaDataExtractionEvent {
  extractedFields {
    value
    key
  }
}
    `;
export const ContactExtractionEventDataFragmentDoc = gql`
    fragment ContactExtractionEventData on ContactExtractionEvent {
  __typename
  contact {
    id
    name {
      value
    }
  }
}
    `;
export const CostCenterExtractionEventDataFragmentDoc = gql`
    fragment CostCenterExtractionEventData on CostCenterExtractionEvent {
  costCenter1 {
    id
    readableName
  }
  costCenter2 {
    id
    readableName
  }
}
    `;
export const WorkflowTemplateAppliedToDocumentEventDataFragmentDoc = gql`
    fragment WorkflowTemplateAppliedToDocumentEventData on WorkflowTemplateAppliedToDocumentEvent {
  workflow {
    name
    description
  }
}
    `;
export const ApproverExtractedEventDataFragmentDoc = gql`
    fragment ApproverExtractedEventData on ApproverExtractedEvent {
  approvers {
    id
    avatarUrl
    name
  }
}
    `;
export const WorkflowStepSkippedEventDataFragmentDoc = gql`
    fragment WorkflowStepSkippedEventData on WorkflowStepSkippedEvent {
  users {
    id
    avatarUrl
    name
  }
  workflowStep {
    resolution {
      comment
    }
    conditions {
      field
      operator
      values
    }
  }
}
    `;
export const UserApprovalDocumentWorkflowStepDataFragmentDoc = gql`
    fragment UserApprovalDocumentWorkflowStepData on UserApprovalDocumentWorkflowStep {
  user {
    id
    avatarUrl
    name
  }
}
    `;
export const RejectedEventDataFragmentDoc = gql`
    fragment RejectedEventData on RejectedEvent {
  user {
    id
    avatarUrl
    name
  }
  clientInfo {
    name
  }
  comment {
    text
  }
}
    `;
export const ExportedEventDataFragmentDoc = gql`
    fragment ExportedEventData on ExportedEvent {
  exportId
  user {
    id
    avatarUrl
    name
  }
  exportType
  datevLocationParams {
    clientNumber
    consultantNumber
    documentExportGuid
  }
}
    `;
export const ProvisionExportedEventDataFragmentDoc = gql`
    fragment ProvisionExportedEventData on ProvisionExportedEvent {
  exportId
  user {
    id
    avatarUrl
    name
  }
  exportType
}
    `;
export const ReversalExportedEventDataFragmentDoc = gql`
    fragment ReversalExportedEventData on ReversalExportedEvent {
  exportId
  user {
    id
    avatarUrl
    name
  }
  exportType
}
    `;
export const SepaTransferGeneratedEventDataFragmentDoc = gql`
    fragment SepaTransferGeneratedEventData on DocumentSepaTransferGeneratedEvent {
  paymentId
  generatedBy {
    id
    name
  }
  executionDate
}
    `;
export const DocumentPaymentInfoUpdatedEventDataFragmentDoc = gql`
    fragment DocumentPaymentInfoUpdatedEventData on DocumentPaymentInfoUpdatedEvent {
  updatedBy {
    id
    name
    avatarUrl
  }
  updatedPaymentFields {
    ... on UpdatedIBANField {
      iban
    }
    ... on UpdatedSwiftCodeField {
      swiftCode
    }
    ... on UpdatedCreateTransferField {
      createTransfer
    }
    ... on UpdatedPaymentStateField {
      paymentState {
        isPaid
        paidAt
      }
    }
  }
}
    `;
export const DocumentResetByCandisEventDataFragmentDoc = gql`
    fragment DocumentResetByCandisEventData on DocumentResetByCandis {
  reason
}
    `;
export const FileAttachedToDocumentEventDataFragmentDoc = gql`
    fragment FileAttachedToDocumentEventData on FileAttachedToDocumentEvent {
  id
  createdAt
  sinceDetachedOrDeleted
  originalDocumentId
  fromEInvoice
  file {
    name
    url
    id
  }
  user {
    id
    avatarUrl
    name
  }
}
    `;
export const SplitBookingUpdatedEventDataFragmentDoc = gql`
    fragment SplitBookingUpdatedEventData on SplitBookingUpdatedEvent {
  id
  createdAt
  user {
    id
    name
    email
    avatarUrl
  }
  bookings {
    number
    action
    fields {
      key
      value
    }
  }
}
    `;
export const SingleBookingUpdatedEventDataFragmentDoc = gql`
    fragment SingleBookingUpdatedEventData on SingleBookingUpdatedEvent {
  id
  createdAt
  user {
    id
    name
    email
    avatarUrl
  }
  fields {
    key
    value
  }
}
    `;
export const AttachmentDeletedFromDocumentEventDataFragmentDoc = gql`
    fragment AttachmentDeletedFromDocumentEventData on AttachmentDeletedFromDocumentEvent {
  id
  createdAt
  fileName
  user {
    id
    avatarUrl
    name
  }
}
    `;
export const AttachmentDetachedFromDocumentEventDataFragmentDoc = gql`
    fragment AttachmentDetachedFromDocumentEventData on AttachmentDetachedFromDocumentEvent {
  id
  createdAt
  fileName
  user {
    id
    avatarUrl
    name
  }
}
    `;
export const DocumentConvertedToAttachmentEventDataFragmentDoc = gql`
    fragment DocumentConvertedToAttachmentEventData on DocumentConvertedToAttachmentEvent {
  id
  createdAt
  target {
    fileName
  }
  user {
    id
    avatarUrl
    name
  }
}
    `;
export const AttachmentConvertedToDocumentEventDataFragmentDoc = gql`
    fragment AttachmentConvertedToDocumentEventData on AttachmentConvertedToDocumentEvent {
  id
  createdAt
  source {
    fileName
  }
  user {
    id
    avatarUrl
    name
  }
}
    `;
export const DocumentFileReplacedEventDataFragmentDoc = gql`
    fragment DocumentFileReplacedEventData on DocumentFileReplacedEvent {
  id
  createdAt
  oldFile {
    name
    url
  }
}
    `;
export const DocumentTransactionLinkedEventDataFragmentDoc = gql`
    fragment DocumentTransactionLinkedEventData on DocumentTransactionLinkedEvent {
  id
  createdAt
  merchantName
  amount {
    amount
    currency
  }
  transactionDate
  user {
    id
    name
    email
    avatarUrl
  }
  receiptAutomatchingTaskId
}
    `;
export const DocumentTransactionCommentCreatedEventDataFragmentDoc = gql`
    fragment DocumentTransactionCommentCreatedEventData on DocumentTransactionCommentCreatedEvent {
  id
  createdAt
  comment {
    id
    text
    author {
      avatarUrl
    }
  }
  user {
    id
    name
    email
    avatarUrl
  }
}
    `;
export const DocumentTransactionUnlinkedEventDataFragmentDoc = gql`
    fragment DocumentTransactionUnlinkedEventData on DocumentTransactionUnlinkedEvent {
  id
  createdAt
  merchantName
  amount {
    amount
    currency
  }
  transactionDate
  clientInfo {
    name
  }
  user {
    id
    name
    email
    avatarUrl
  }
}
    `;
export const DocumentTransactionReconciledEventDataFragmentDoc = gql`
    fragment DocumentTransactionReconciledEventData on DocumentTransactionReconciledEvent {
  id
  createdAt
  properties {
    amount {
      currency
      value
      precision
    }
    merchantName
    transactionCreatedAt
  }
  user {
    name
    email
    avatarUrl
  }
}
    `;
export const DocumentTransactionStatusUpdatedEventDataFragmentDoc = gql`
    fragment DocumentTransactionStatusUpdatedEventData on DocumentTransactionStatusUpdatedEvent {
  id
  createdAt
  user {
    id
    name
    email
    avatarUrl
  }
  oldStatus
  newStatus
}
    `;
export const DocumentResetTransactionUnLinkedEventDataFragmentDoc = gql`
    fragment DocumentResetTransactionUnLinkedEventData on DocumentResetTransactionUnLinkedEvent {
  id
  user {
    id
    name
    avatarUrl
  }
}
    `;
export const DocumentResetTransactionLinkedEventDataFragmentDoc = gql`
    fragment DocumentResetTransactionLinkedEventData on DocumentResetTransactionLinkedEvent {
  id
  user {
    id
    name
    avatarUrl
  }
}
    `;
export const DocumentResetTransactionAmountUpdatedEventDataFragmentDoc = gql`
    fragment DocumentResetTransactionAmountUpdatedEventData on DocumentResetTransactionAmountUpdatedEvent {
  id
  user {
    id
    name
    avatarUrl
  }
}
    `;
export const DocumentImportedByMigrationEventDataFragmentDoc = gql`
    fragment DocumentImportedByMigrationEventData on DocumentImportedByMigrationEvent {
  user {
    id
    avatarUrl
    name
  }
  file {
    name
    url
  }
  importedFromSystem
}
    `;
export const PurchaseLinkDataFragmentDoc = gql`
    fragment PurchaseLinkData on PurchaseOrderLinkedEvent {
  id
  orderNumber
  purchaseOrderId
  createdAt
  user {
    id
    name
    avatarUrl
  }
}
    `;
export const PurchaseUnlinkDataFragmentDoc = gql`
    fragment PurchaseUnlinkData on PurchaseOrderUnlinkedEvent {
  id
  orderNumber
  purchaseOrderId
  createdAt
  user {
    id
    name
    avatarUrl
  }
}
    `;
export const RelatedDocumentActivityFragmentDoc = gql`
    fragment RelatedDocumentActivity on RelatedDocument {
  id
  type
  document {
    ... on Document {
      invoiceNumber {
        value
      }
      contact {
        value {
          id
          name {
            value
          }
        }
      }
      documentFile {
        name
      }
    }
    ... on AggregatedEcmDocument {
      documentName
    }
  }
}
    `;
export const DocumentRelationshipCreatedEventDataFragmentDoc = gql`
    fragment DocumentRelationshipCreatedEventData on DocumentRelationshipCreatedEvent {
  id
  createdAt
  user {
    id
    name
    avatarUrl
  }
  linkedDocuments {
    ...RelatedDocumentActivity
  }
}
    `;
export const DocumentRelationshipRemovedEventDataFragmentDoc = gql`
    fragment DocumentRelationshipRemovedEventData on DocumentRelationshipRemovedEvent {
  id
  createdAt
  user {
    id
    name
    avatarUrl
  }
  unlinkedDocument {
    ...RelatedDocumentActivity
  }
}
    `;
export const DocumentTagUpdatedEventDataFragmentDoc = gql`
    fragment DocumentTagUpdatedEventData on DocumentTagUpdatedEvent {
  id
  createdAt
  user {
    id
    name
    avatarUrl
  }
  tags {
    id
    name
    description
  }
}
    `;
export const DocumentGrossNetTaxRoundingAmountChangedEventDataFragmentDoc = gql`
    fragment DocumentGrossNetTaxRoundingAmountChangedEventData on DocumentGrossNetTaxRoundingAmountChangedEvent {
  id
  createdAt
  user {
    id
    name
    avatarUrl
  }
  documentGrossAmount
  documentNetAmount
  documentTaxAmount
  documentRoundingAmount
}
    `;
export const DocumentAssociationsCreatedEventDataFragmentDoc = gql`
    fragment DocumentAssociationsCreatedEventData on DocumentAssociationsCreatedEvent {
  id
  createdAt
  user {
    id
    avatarUrl
    name
  }
  associatedPurchaseOrdersWithGoodsReceipts {
    purchaseOrderId
    orderNumber
    associatedGoodsReceipts {
      goodsReceiptId
      receiptNumber
    }
  }
  associatedGoodsReceiptsWithPurchaseOrders {
    receiptNumber
    goodsReceiptId
    associatedPurchaseOrders {
      purchaseOrderId
      orderNumber
    }
  }
}
    `;
export const DocumentAssociationsRemovedEventDataFragmentDoc = gql`
    fragment DocumentAssociationsRemovedEventData on DocumentAssociationsRemovedEvent {
  id
  createdAt
  user {
    id
    avatarUrl
    name
  }
  disconnectedPurchaseOrdersWithGoodsReceipts {
    purchaseOrderId
    orderNumber
    associatedGoodsReceipts {
      goodsReceiptId
      receiptNumber
    }
  }
  disconnectedGoodsReceiptsWithPurchaseOrders {
    receiptNumber
    goodsReceiptId
    associatedPurchaseOrders {
      purchaseOrderId
      orderNumber
    }
  }
}
    `;
export const AccountingAreaUpdatedEventDataFragmentDoc = gql`
    fragment AccountingAreaUpdatedEventData on AccountingAreaUpdatedEvent {
  id
  createdAt
  clientInfo {
    name
  }
  user {
    id
    name
    avatarUrl
  }
  accountingAreaName
  accountingAreaId
}
    `;
export const DocumentStoredAsEcmDocumentEventDataFragmentDoc = gql`
    fragment DocumentStoredAsEcmDocumentEventData on DocumentStoredAsEcmDocumentEvent {
  id
  createdAt
  user {
    id
    name
    email
    avatarUrl
  }
  amount {
    amount
    currency
    precision
  }
  contact {
    id
    name {
      value
    }
  }
  costCenter {
    readableName
  }
  documentDate
  documentName
  documentNumber
  documentType
  endDate
  notes
  notifyPerson {
    primary {
      name
    }
  }
  responsiblePerson {
    primary {
      name
    }
  }
  startDate
  documentSubCategory {
    id
    name
  }
  terminationDate
  terminationReminderDate
}
    `;
export const DocumentUpdatedAsEcmDocumentEventDataFragmentDoc = gql`
    fragment DocumentUpdatedAsEcmDocumentEventData on DocumentUpdatedAsEcmDocumentEvent {
  id
  createdAt
  user {
    id
    name
    email
    avatarUrl
  }
  amount {
    amount
    currency
    precision
  }
  contact {
    id
    name {
      value
    }
  }
  costCenter {
    readableName
  }
  documentDate
  documentName
  documentNumber
  documentType
  endDate
  notes
  notifyPerson {
    primary {
      name
    }
  }
  responsiblePerson {
    primary {
      name
    }
  }
  startDate
  documentSubCategory {
    id
    name
  }
  terminationDate
  terminationReminderDate
  updatedFields
}
    `;
export const DocumentConvertedEventDataFragmentDoc = gql`
    fragment DocumentConvertedEventData on DocumentConvertedEvent {
  id
  createdAt
  clientInfo {
    name
  }
  user {
    id
    name
    avatarUrl
  }
  documentType
  convertedFrom
}
    `;
export const DocumentClassifiedEventDataFragmentDoc = gql`
    fragment DocumentClassifiedEventData on DocumentClassifiedEvent {
  id
  createdAt
  clientInfo {
    name
  }
  newDocumentType: documentType
}
    `;
export const GetMembershipsDocument = gql`
    query getMemberships {
  organizationMemberships(filters: {status: ACTIVE, emailVerified: true}) {
    id
    name
    roles
    avatarUrl
  }
}
    `;

/**
 * __useGetMembershipsQuery__
 *
 * To run a query within a React component, call `useGetMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<GetMembershipsQuery, GetMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembershipsQuery, GetMembershipsQueryVariables>(GetMembershipsDocument, options);
      }
export function useGetMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipsQuery, GetMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembershipsQuery, GetMembershipsQueryVariables>(GetMembershipsDocument, options);
        }
export type GetMembershipsQueryHookResult = ReturnType<typeof useGetMembershipsQuery>;
export type GetMembershipsLazyQueryHookResult = ReturnType<typeof useGetMembershipsLazyQuery>;
export type GetMembershipsQueryResult = Apollo.QueryResult<GetMembershipsQuery, GetMembershipsQueryVariables>;
export const CreateDocumentCommentDocument = gql`
    mutation createDocumentComment($id: ID!, $text: String!, $mentionedUserIds: [String!]) {
  createDocumentComment(id: $id, text: $text, mentionedUserIds: $mentionedUserIds) {
    id
  }
}
    `;
export type CreateDocumentCommentMutationFn = Apollo.MutationFunction<CreateDocumentCommentMutation, CreateDocumentCommentMutationVariables>;

/**
 * __useCreateDocumentCommentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentCommentMutation, { data, loading, error }] = useCreateDocumentCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      mentionedUserIds: // value for 'mentionedUserIds'
 *   },
 * });
 */
export function useCreateDocumentCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentCommentMutation, CreateDocumentCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentCommentMutation, CreateDocumentCommentMutationVariables>(CreateDocumentCommentDocument, options);
      }
export type CreateDocumentCommentMutationHookResult = ReturnType<typeof useCreateDocumentCommentMutation>;
export type CreateDocumentCommentMutationResult = Apollo.MutationResult<CreateDocumentCommentMutation>;
export type CreateDocumentCommentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentCommentMutation, CreateDocumentCommentMutationVariables>;
export const CreateCommentOnInvoiceDocument = gql`
    mutation createCommentOnInvoice($input: CreateCommentInput!) {
  createCommentOnInvoice(input: $input) {
    ... on CommentCreated {
      commentId
    }
    ... on CommentCreatedError {
      message
      kind
    }
  }
}
    `;
export type CreateCommentOnInvoiceMutationFn = Apollo.MutationFunction<CreateCommentOnInvoiceMutation, CreateCommentOnInvoiceMutationVariables>;

/**
 * __useCreateCommentOnInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateCommentOnInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentOnInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentOnInvoiceMutation, { data, loading, error }] = useCreateCommentOnInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentOnInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentOnInvoiceMutation, CreateCommentOnInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentOnInvoiceMutation, CreateCommentOnInvoiceMutationVariables>(CreateCommentOnInvoiceDocument, options);
      }
export type CreateCommentOnInvoiceMutationHookResult = ReturnType<typeof useCreateCommentOnInvoiceMutation>;
export type CreateCommentOnInvoiceMutationResult = Apollo.MutationResult<CreateCommentOnInvoiceMutation>;
export type CreateCommentOnInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateCommentOnInvoiceMutation, CreateCommentOnInvoiceMutationVariables>;
export const CreateCommentOnEcmDocumentDocument = gql`
    mutation createCommentOnEcmDocument($input: CreateCommentInput!) {
  createCommentOnECMDocument(input: $input) {
    ... on CommentCreated {
      commentId
    }
    ... on CommentCreatedError {
      message
      kind
    }
  }
}
    `;
export type CreateCommentOnEcmDocumentMutationFn = Apollo.MutationFunction<CreateCommentOnEcmDocumentMutation, CreateCommentOnEcmDocumentMutationVariables>;

/**
 * __useCreateCommentOnEcmDocumentMutation__
 *
 * To run a mutation, you first call `useCreateCommentOnEcmDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentOnEcmDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentOnEcmDocumentMutation, { data, loading, error }] = useCreateCommentOnEcmDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentOnEcmDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentOnEcmDocumentMutation, CreateCommentOnEcmDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentOnEcmDocumentMutation, CreateCommentOnEcmDocumentMutationVariables>(CreateCommentOnEcmDocumentDocument, options);
      }
export type CreateCommentOnEcmDocumentMutationHookResult = ReturnType<typeof useCreateCommentOnEcmDocumentMutation>;
export type CreateCommentOnEcmDocumentMutationResult = Apollo.MutationResult<CreateCommentOnEcmDocumentMutation>;
export type CreateCommentOnEcmDocumentMutationOptions = Apollo.BaseMutationOptions<CreateCommentOnEcmDocumentMutation, CreateCommentOnEcmDocumentMutationVariables>;
export const DocumentAccessLevelsDocument = gql`
    query documentAccessLevels($globalDocumentId: String!) {
  documentAccessLevels(globalDocumentId: $globalDocumentId) {
    __typename
    ... on AccessLevels {
      id
      levels {
        name
        users {
          id
          name
          email
          avatarUrl
        }
      }
    }
    ... on AccessLevelError {
      type
      message
    }
  }
}
    `;

/**
 * __useDocumentAccessLevelsQuery__
 *
 * To run a query within a React component, call `useDocumentAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAccessLevelsQuery({
 *   variables: {
 *      globalDocumentId: // value for 'globalDocumentId'
 *   },
 * });
 */
export function useDocumentAccessLevelsQuery(baseOptions: Apollo.QueryHookOptions<DocumentAccessLevelsQuery, DocumentAccessLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentAccessLevelsQuery, DocumentAccessLevelsQueryVariables>(DocumentAccessLevelsDocument, options);
      }
export function useDocumentAccessLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentAccessLevelsQuery, DocumentAccessLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentAccessLevelsQuery, DocumentAccessLevelsQueryVariables>(DocumentAccessLevelsDocument, options);
        }
export type DocumentAccessLevelsQueryHookResult = ReturnType<typeof useDocumentAccessLevelsQuery>;
export type DocumentAccessLevelsLazyQueryHookResult = ReturnType<typeof useDocumentAccessLevelsLazyQuery>;
export type DocumentAccessLevelsQueryResult = Apollo.QueryResult<DocumentAccessLevelsQuery, DocumentAccessLevelsQueryVariables>;
export const ModifyDocumentAccessDocument = gql`
    mutation modifyDocumentAccess($input: ModifyAccessInput!) {
  modifyDocumentAccess(input: $input) {
    __typename
    ... on AccessLevels {
      id
      levels {
        name
        users {
          id
          name
          email
          avatarUrl
        }
      }
    }
    ... on ModifyAccessError {
      type
      message
    }
  }
}
    `;
export type ModifyDocumentAccessMutationFn = Apollo.MutationFunction<ModifyDocumentAccessMutation, ModifyDocumentAccessMutationVariables>;

/**
 * __useModifyDocumentAccessMutation__
 *
 * To run a mutation, you first call `useModifyDocumentAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyDocumentAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyDocumentAccessMutation, { data, loading, error }] = useModifyDocumentAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyDocumentAccessMutation(baseOptions?: Apollo.MutationHookOptions<ModifyDocumentAccessMutation, ModifyDocumentAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyDocumentAccessMutation, ModifyDocumentAccessMutationVariables>(ModifyDocumentAccessDocument, options);
      }
export type ModifyDocumentAccessMutationHookResult = ReturnType<typeof useModifyDocumentAccessMutation>;
export type ModifyDocumentAccessMutationResult = Apollo.MutationResult<ModifyDocumentAccessMutation>;
export type ModifyDocumentAccessMutationOptions = Apollo.BaseMutationOptions<ModifyDocumentAccessMutation, ModifyDocumentAccessMutationVariables>;
export const DocumentDetailsCardDocument = gql`
    query documentDetailsCard($id: ID!) {
  getDocument(id: $id) {
    id
    status
    amount {
      value
    }
    currency {
      value
    }
    invoiceDate {
      value
    }
    invoiceNumber {
      value
    }
    contact {
      value {
        id
        name {
          value
        }
      }
    }
    originalDocumentId {
      value
    }
    extractedContact {
      cachingKey
      name {
        value
      }
    }
  }
}
    `;

/**
 * __useDocumentDetailsCardQuery__
 *
 * To run a query within a React component, call `useDocumentDetailsCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDetailsCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDetailsCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentDetailsCardQuery(baseOptions: Apollo.QueryHookOptions<DocumentDetailsCardQuery, DocumentDetailsCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDetailsCardQuery, DocumentDetailsCardQueryVariables>(DocumentDetailsCardDocument, options);
      }
export function useDocumentDetailsCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDetailsCardQuery, DocumentDetailsCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDetailsCardQuery, DocumentDetailsCardQueryVariables>(DocumentDetailsCardDocument, options);
        }
export type DocumentDetailsCardQueryHookResult = ReturnType<typeof useDocumentDetailsCardQuery>;
export type DocumentDetailsCardLazyQueryHookResult = ReturnType<typeof useDocumentDetailsCardLazyQuery>;
export type DocumentDetailsCardQueryResult = Apollo.QueryResult<DocumentDetailsCardQuery, DocumentDetailsCardQueryVariables>;
export const ListInboxDocumentsForAttachmentsDocument = gql`
    query listInboxDocumentsForAttachments($limit: Int, $after: String, $filters: DocumentFilterInput, $sort: SortInput) {
  listInboxDocuments(limit: $limit, after: $after, filters: $filters, sort: $sort) @connection(key: "inboxDocumentsForAttachments", filter: ["filters", "sort"]) {
    edges {
      cursor
      node {
        isDuplicate
        isOverdue
        isWaitingForClarification
        id
        status
        contact {
          value {
            id
            name {
              value
            }
          }
        }
        extractedContact {
          cachingKey
          name {
            value
          }
        }
        createdAt
        documentFile {
          id
          name
          size
        }
        isEInvoice
        eInvoice {
          format
        }
        hasAttachments
        tags {
          ...Tag
        }
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${TagFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useListInboxDocumentsForAttachmentsQuery__
 *
 * To run a query within a React component, call `useListInboxDocumentsForAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInboxDocumentsForAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInboxDocumentsForAttachmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListInboxDocumentsForAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<ListInboxDocumentsForAttachmentsQuery, ListInboxDocumentsForAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInboxDocumentsForAttachmentsQuery, ListInboxDocumentsForAttachmentsQueryVariables>(ListInboxDocumentsForAttachmentsDocument, options);
      }
export function useListInboxDocumentsForAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInboxDocumentsForAttachmentsQuery, ListInboxDocumentsForAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInboxDocumentsForAttachmentsQuery, ListInboxDocumentsForAttachmentsQueryVariables>(ListInboxDocumentsForAttachmentsDocument, options);
        }
export type ListInboxDocumentsForAttachmentsQueryHookResult = ReturnType<typeof useListInboxDocumentsForAttachmentsQuery>;
export type ListInboxDocumentsForAttachmentsLazyQueryHookResult = ReturnType<typeof useListInboxDocumentsForAttachmentsLazyQuery>;
export type ListInboxDocumentsForAttachmentsQueryResult = Apollo.QueryResult<ListInboxDocumentsForAttachmentsQuery, ListInboxDocumentsForAttachmentsQueryVariables>;
export const GetInboxInvoiceDocumentsForAttachmentsDocument = gql`
    query getInboxInvoiceDocumentsForAttachments($limit: Int, $after: String, $filters: InboxInvoiceDocumentsFilterInput, $sort: SortInput, $withPurchaseOrderData: Boolean = false, $withAccountsReceivableNumber: Boolean = false, $query: String) {
  inboxInvoiceDocuments(
    limit: $limit
    after: $after
    filters: $filters
    sort: $sort
    query: $query
  ) @connection(key: "inboxDocumentsPaginationV2", filter: ["filters", "sort", "query"]) {
    edges {
      cursor
      node {
        ...InboxInvoiceDocumentData
        hasAttachments
        documentFile {
          name
          size
        }
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${InboxInvoiceDocumentDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useGetInboxInvoiceDocumentsForAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetInboxInvoiceDocumentsForAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxInvoiceDocumentsForAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxInvoiceDocumentsForAttachmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *      withAccountsReceivableNumber: // value for 'withAccountsReceivableNumber'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetInboxInvoiceDocumentsForAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetInboxInvoiceDocumentsForAttachmentsQuery, GetInboxInvoiceDocumentsForAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInboxInvoiceDocumentsForAttachmentsQuery, GetInboxInvoiceDocumentsForAttachmentsQueryVariables>(GetInboxInvoiceDocumentsForAttachmentsDocument, options);
      }
export function useGetInboxInvoiceDocumentsForAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInboxInvoiceDocumentsForAttachmentsQuery, GetInboxInvoiceDocumentsForAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInboxInvoiceDocumentsForAttachmentsQuery, GetInboxInvoiceDocumentsForAttachmentsQueryVariables>(GetInboxInvoiceDocumentsForAttachmentsDocument, options);
        }
export type GetInboxInvoiceDocumentsForAttachmentsQueryHookResult = ReturnType<typeof useGetInboxInvoiceDocumentsForAttachmentsQuery>;
export type GetInboxInvoiceDocumentsForAttachmentsLazyQueryHookResult = ReturnType<typeof useGetInboxInvoiceDocumentsForAttachmentsLazyQuery>;
export type GetInboxInvoiceDocumentsForAttachmentsQueryResult = Apollo.QueryResult<GetInboxInvoiceDocumentsForAttachmentsQuery, GetInboxInvoiceDocumentsForAttachmentsQueryVariables>;
export const GetDocumentFileDocument = gql`
    query getDocumentFile($id: ID!) {
  getDocument(id: $id) {
    id
    status
    documentFile {
      id
      name
      url
      versions
      pages
      size
      eInvoice {
        id
        url
        name
      }
    }
    isEInvoice
    eInvoice {
      format
      error
    }
    attachments {
      id
      name
      url
      pages
      rank
      size
      isDetachable
      originalDocumentId
      attachedBy {
        id
      }
    }
  }
}
    `;

/**
 * __useGetDocumentFileQuery__
 *
 * To run a query within a React component, call `useGetDocumentFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentFileQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentFileQuery, GetDocumentFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentFileQuery, GetDocumentFileQueryVariables>(GetDocumentFileDocument, options);
      }
export function useGetDocumentFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentFileQuery, GetDocumentFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentFileQuery, GetDocumentFileQueryVariables>(GetDocumentFileDocument, options);
        }
export type GetDocumentFileQueryHookResult = ReturnType<typeof useGetDocumentFileQuery>;
export type GetDocumentFileLazyQueryHookResult = ReturnType<typeof useGetDocumentFileLazyQuery>;
export type GetDocumentFileQueryResult = Apollo.QueryResult<GetDocumentFileQuery, GetDocumentFileQueryVariables>;
export const DocumentAttachmentUploadUrlDocument = gql`
    mutation documentAttachmentUploadUrl($options: UploadOptions!) {
  documentAttachmentUploadUrl(options: $options) {
    id
    url
    postOptions {
      field
      value
    }
  }
}
    `;
export type DocumentAttachmentUploadUrlMutationFn = Apollo.MutationFunction<DocumentAttachmentUploadUrlMutation, DocumentAttachmentUploadUrlMutationVariables>;

/**
 * __useDocumentAttachmentUploadUrlMutation__
 *
 * To run a mutation, you first call `useDocumentAttachmentUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentAttachmentUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentAttachmentUploadUrlMutation, { data, loading, error }] = useDocumentAttachmentUploadUrlMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDocumentAttachmentUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<DocumentAttachmentUploadUrlMutation, DocumentAttachmentUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentAttachmentUploadUrlMutation, DocumentAttachmentUploadUrlMutationVariables>(DocumentAttachmentUploadUrlDocument, options);
      }
export type DocumentAttachmentUploadUrlMutationHookResult = ReturnType<typeof useDocumentAttachmentUploadUrlMutation>;
export type DocumentAttachmentUploadUrlMutationResult = Apollo.MutationResult<DocumentAttachmentUploadUrlMutation>;
export type DocumentAttachmentUploadUrlMutationOptions = Apollo.BaseMutationOptions<DocumentAttachmentUploadUrlMutation, DocumentAttachmentUploadUrlMutationVariables>;
export const AttachFilesDocument = gql`
    mutation attachFiles($input: AttachFilesInput!) {
  attachFiles(input: $input) {
    __typename
    id
    timeline {
      __typename
      id
      createdAt
      ...FileAttachedToDocumentEventData
    }
    attachments {
      id
      name
      url
      pages
      rank
      size
    }
  }
}
    ${FileAttachedToDocumentEventDataFragmentDoc}`;
export type AttachFilesMutationFn = Apollo.MutationFunction<AttachFilesMutation, AttachFilesMutationVariables>;

/**
 * __useAttachFilesMutation__
 *
 * To run a mutation, you first call `useAttachFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFilesMutation, { data, loading, error }] = useAttachFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachFilesMutation(baseOptions?: Apollo.MutationHookOptions<AttachFilesMutation, AttachFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachFilesMutation, AttachFilesMutationVariables>(AttachFilesDocument, options);
      }
export type AttachFilesMutationHookResult = ReturnType<typeof useAttachFilesMutation>;
export type AttachFilesMutationResult = Apollo.MutationResult<AttachFilesMutation>;
export type AttachFilesMutationOptions = Apollo.BaseMutationOptions<AttachFilesMutation, AttachFilesMutationVariables>;
export const DeleteAttachmentsDocument = gql`
    mutation deleteAttachments($input: DeleteAttachmentsInput!) {
  deleteAttachments(input: $input) {
    __typename
    id
    timeline {
      __typename
      id
      createdAt
      ...AttachmentDeletedFromDocumentEventData
    }
    attachments {
      id
      name
      url
      pages
      rank
      size
    }
  }
}
    ${AttachmentDeletedFromDocumentEventDataFragmentDoc}`;
export type DeleteAttachmentsMutationFn = Apollo.MutationFunction<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>;

/**
 * __useDeleteAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentsMutation, { data, loading, error }] = useDeleteAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>(DeleteAttachmentsDocument, options);
      }
export type DeleteAttachmentsMutationHookResult = ReturnType<typeof useDeleteAttachmentsMutation>;
export type DeleteAttachmentsMutationResult = Apollo.MutationResult<DeleteAttachmentsMutation>;
export type DeleteAttachmentsMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>;
export const ConvertDocumentsToAttachmentsDocument = gql`
    mutation convertDocumentsToAttachments($input: ConvertDocumentsToAttachmentsInput!) {
  convertDocumentsToAttachments(input: $input) {
    id
  }
}
    `;
export type ConvertDocumentsToAttachmentsMutationFn = Apollo.MutationFunction<ConvertDocumentsToAttachmentsMutation, ConvertDocumentsToAttachmentsMutationVariables>;

/**
 * __useConvertDocumentsToAttachmentsMutation__
 *
 * To run a mutation, you first call `useConvertDocumentsToAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertDocumentsToAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertDocumentsToAttachmentsMutation, { data, loading, error }] = useConvertDocumentsToAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertDocumentsToAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<ConvertDocumentsToAttachmentsMutation, ConvertDocumentsToAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertDocumentsToAttachmentsMutation, ConvertDocumentsToAttachmentsMutationVariables>(ConvertDocumentsToAttachmentsDocument, options);
      }
export type ConvertDocumentsToAttachmentsMutationHookResult = ReturnType<typeof useConvertDocumentsToAttachmentsMutation>;
export type ConvertDocumentsToAttachmentsMutationResult = Apollo.MutationResult<ConvertDocumentsToAttachmentsMutation>;
export type ConvertDocumentsToAttachmentsMutationOptions = Apollo.BaseMutationOptions<ConvertDocumentsToAttachmentsMutation, ConvertDocumentsToAttachmentsMutationVariables>;
export const DetachAttachmentsDocument = gql`
    mutation detachAttachments($input: DetachAttachmentsInput!) {
  detachAttachments(input: $input) {
    __typename
    id
    timeline {
      __typename
      id
      createdAt
      ...AttachmentDetachedFromDocumentEventData
    }
    attachments {
      id
      name
      url
      pages
      rank
      size
    }
  }
}
    ${AttachmentDetachedFromDocumentEventDataFragmentDoc}`;
export type DetachAttachmentsMutationFn = Apollo.MutationFunction<DetachAttachmentsMutation, DetachAttachmentsMutationVariables>;

/**
 * __useDetachAttachmentsMutation__
 *
 * To run a mutation, you first call `useDetachAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAttachmentsMutation, { data, loading, error }] = useDetachAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<DetachAttachmentsMutation, DetachAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DetachAttachmentsMutation, DetachAttachmentsMutationVariables>(DetachAttachmentsDocument, options);
      }
export type DetachAttachmentsMutationHookResult = ReturnType<typeof useDetachAttachmentsMutation>;
export type DetachAttachmentsMutationResult = Apollo.MutationResult<DetachAttachmentsMutation>;
export type DetachAttachmentsMutationOptions = Apollo.BaseMutationOptions<DetachAttachmentsMutation, DetachAttachmentsMutationVariables>;
export const SubmitWorkflowStepDocument = gql`
    mutation submitWorkflowStep($id: ID!, $input: DocumentWorkflowStepSubmitInput!) {
  documentWorkflowStepSubmitUnionResponse(id: $id, input: $input) {
    __typename
    ...DocumentData
    ...DocumentSubmitWorkflowStepErrors
  }
}
    ${DocumentDataFragmentDoc}
${TagFragmentDoc}
${DocumentSubmitWorkflowStepErrorsFragmentDoc}
${DocumentCannotBeModifedErrorDataFragmentDoc}
${WorkflowStepAlreadyApprovedErrorDataFragmentDoc}
${BookingsErrorDataFragmentDoc}
${BookingKeyArchivedErrorDataFragmentDoc}
${CostCenterArchivedErrorDataFragmentDoc}
${CostObjectArchivedErrorDataFragmentDoc}
${ExtraCostInfoArchivedErrorDataFragmentDoc}
${GeneralLedgerArchivedErrorDataFragmentDoc}
${ProjectCodeArchivedErrorDataFragmentDoc}`;
export type SubmitWorkflowStepMutationFn = Apollo.MutationFunction<SubmitWorkflowStepMutation, SubmitWorkflowStepMutationVariables>;

/**
 * __useSubmitWorkflowStepMutation__
 *
 * To run a mutation, you first call `useSubmitWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitWorkflowStepMutation, { data, loading, error }] = useSubmitWorkflowStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitWorkflowStepMutation(baseOptions?: Apollo.MutationHookOptions<SubmitWorkflowStepMutation, SubmitWorkflowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitWorkflowStepMutation, SubmitWorkflowStepMutationVariables>(SubmitWorkflowStepDocument, options);
      }
export type SubmitWorkflowStepMutationHookResult = ReturnType<typeof useSubmitWorkflowStepMutation>;
export type SubmitWorkflowStepMutationResult = Apollo.MutationResult<SubmitWorkflowStepMutation>;
export type SubmitWorkflowStepMutationOptions = Apollo.BaseMutationOptions<SubmitWorkflowStepMutation, SubmitWorkflowStepMutationVariables>;
export const SmartFieldSuggestionsForApproverDocument = gql`
    query smartFieldSuggestionsForApprover($documentId: String!, $contactId: String!) {
  sfsSuggestionForApprover(documentId: $documentId, contactId: $contactId) {
    costCenter1 {
      confidence
      value {
        id
        readableName
      }
    }
    costCenter2 {
      confidence
      value {
        id
        readableName
      }
    }
    generalLedgerAccount {
      confidence
      value {
        id
        readableName
      }
    }
    bookingKey {
      confidence
      value {
        id
        readableName
      }
    }
  }
}
    `;

/**
 * __useSmartFieldSuggestionsForApproverQuery__
 *
 * To run a query within a React component, call `useSmartFieldSuggestionsForApproverQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartFieldSuggestionsForApproverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartFieldSuggestionsForApproverQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useSmartFieldSuggestionsForApproverQuery(baseOptions: Apollo.QueryHookOptions<SmartFieldSuggestionsForApproverQuery, SmartFieldSuggestionsForApproverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmartFieldSuggestionsForApproverQuery, SmartFieldSuggestionsForApproverQueryVariables>(SmartFieldSuggestionsForApproverDocument, options);
      }
export function useSmartFieldSuggestionsForApproverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartFieldSuggestionsForApproverQuery, SmartFieldSuggestionsForApproverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmartFieldSuggestionsForApproverQuery, SmartFieldSuggestionsForApproverQueryVariables>(SmartFieldSuggestionsForApproverDocument, options);
        }
export type SmartFieldSuggestionsForApproverQueryHookResult = ReturnType<typeof useSmartFieldSuggestionsForApproverQuery>;
export type SmartFieldSuggestionsForApproverLazyQueryHookResult = ReturnType<typeof useSmartFieldSuggestionsForApproverLazyQuery>;
export type SmartFieldSuggestionsForApproverQueryResult = Apollo.QueryResult<SmartFieldSuggestionsForApproverQuery, SmartFieldSuggestionsForApproverQueryVariables>;
export const ActiveMembersDocument = gql`
    query activeMembers {
  organizationMemberships(filters: {status: ACTIVE, emailVerified: true}) {
    id
    name
    roles
    avatarUrl
  }
}
    `;

/**
 * __useActiveMembersQuery__
 *
 * To run a query within a React component, call `useActiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveMembersQuery(baseOptions?: Apollo.QueryHookOptions<ActiveMembersQuery, ActiveMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveMembersQuery, ActiveMembersQueryVariables>(ActiveMembersDocument, options);
      }
export function useActiveMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveMembersQuery, ActiveMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveMembersQuery, ActiveMembersQueryVariables>(ActiveMembersDocument, options);
        }
export type ActiveMembersQueryHookResult = ReturnType<typeof useActiveMembersQuery>;
export type ActiveMembersLazyQueryHookResult = ReturnType<typeof useActiveMembersLazyQuery>;
export type ActiveMembersQueryResult = Apollo.QueryResult<ActiveMembersQuery, ActiveMembersQueryVariables>;
export const CalculateDocumentGrossNetTaxRoundingAmountsDocument = gql`
    query calculateDocumentGrossNetTaxRoundingAmounts($input: CalculateDocumentGrossNetTaxRoundingAmountsInput!) {
  calculateDocumentGrossNetTaxRoundingAmounts(input: $input) {
    ... on CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult {
      currency
      netAmount
      grossAmount
      taxAmount
      rounding
    }
    ... on CalculateDocumentGrossNetTaxRoundingAmountsErrors {
      errors {
        kind
        message
      }
    }
  }
}
    `;

/**
 * __useCalculateDocumentGrossNetTaxRoundingAmountsQuery__
 *
 * To run a query within a React component, call `useCalculateDocumentGrossNetTaxRoundingAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateDocumentGrossNetTaxRoundingAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateDocumentGrossNetTaxRoundingAmountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateDocumentGrossNetTaxRoundingAmountsQuery(baseOptions: Apollo.QueryHookOptions<CalculateDocumentGrossNetTaxRoundingAmountsQuery, CalculateDocumentGrossNetTaxRoundingAmountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateDocumentGrossNetTaxRoundingAmountsQuery, CalculateDocumentGrossNetTaxRoundingAmountsQueryVariables>(CalculateDocumentGrossNetTaxRoundingAmountsDocument, options);
      }
export function useCalculateDocumentGrossNetTaxRoundingAmountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateDocumentGrossNetTaxRoundingAmountsQuery, CalculateDocumentGrossNetTaxRoundingAmountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateDocumentGrossNetTaxRoundingAmountsQuery, CalculateDocumentGrossNetTaxRoundingAmountsQueryVariables>(CalculateDocumentGrossNetTaxRoundingAmountsDocument, options);
        }
export type CalculateDocumentGrossNetTaxRoundingAmountsQueryHookResult = ReturnType<typeof useCalculateDocumentGrossNetTaxRoundingAmountsQuery>;
export type CalculateDocumentGrossNetTaxRoundingAmountsLazyQueryHookResult = ReturnType<typeof useCalculateDocumentGrossNetTaxRoundingAmountsLazyQuery>;
export type CalculateDocumentGrossNetTaxRoundingAmountsQueryResult = Apollo.QueryResult<CalculateDocumentGrossNetTaxRoundingAmountsQuery, CalculateDocumentGrossNetTaxRoundingAmountsQueryVariables>;
export const CreateInsightsWidgetDocument = gql`
    mutation createInsightsWidget($input: InsightsWidgetInput!) {
  createInsightsWidget(input: $input)
}
    `;
export type CreateInsightsWidgetMutationFn = Apollo.MutationFunction<CreateInsightsWidgetMutation, CreateInsightsWidgetMutationVariables>;

/**
 * __useCreateInsightsWidgetMutation__
 *
 * To run a mutation, you first call `useCreateInsightsWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsightsWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsightsWidgetMutation, { data, loading, error }] = useCreateInsightsWidgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsightsWidgetMutation(baseOptions?: Apollo.MutationHookOptions<CreateInsightsWidgetMutation, CreateInsightsWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInsightsWidgetMutation, CreateInsightsWidgetMutationVariables>(CreateInsightsWidgetDocument, options);
      }
export type CreateInsightsWidgetMutationHookResult = ReturnType<typeof useCreateInsightsWidgetMutation>;
export type CreateInsightsWidgetMutationResult = Apollo.MutationResult<CreateInsightsWidgetMutation>;
export type CreateInsightsWidgetMutationOptions = Apollo.BaseMutationOptions<CreateInsightsWidgetMutation, CreateInsightsWidgetMutationVariables>;
export const UpdateInsightsWidgetDocument = gql`
    mutation updateInsightsWidget($id: ID!, $input: InsightsWidgetInput!) {
  updateInsightsWidget(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateInsightsWidgetMutationFn = Apollo.MutationFunction<UpdateInsightsWidgetMutation, UpdateInsightsWidgetMutationVariables>;

/**
 * __useUpdateInsightsWidgetMutation__
 *
 * To run a mutation, you first call `useUpdateInsightsWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsightsWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsightsWidgetMutation, { data, loading, error }] = useUpdateInsightsWidgetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsightsWidgetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInsightsWidgetMutation, UpdateInsightsWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInsightsWidgetMutation, UpdateInsightsWidgetMutationVariables>(UpdateInsightsWidgetDocument, options);
      }
export type UpdateInsightsWidgetMutationHookResult = ReturnType<typeof useUpdateInsightsWidgetMutation>;
export type UpdateInsightsWidgetMutationResult = Apollo.MutationResult<UpdateInsightsWidgetMutation>;
export type UpdateInsightsWidgetMutationOptions = Apollo.BaseMutationOptions<UpdateInsightsWidgetMutation, UpdateInsightsWidgetMutationVariables>;
export const InsightsPreferencesDocument = gql`
    query insightsPreferences {
  insightsPreferences {
    unit
  }
}
    `;

/**
 * __useInsightsPreferencesQuery__
 *
 * To run a query within a React component, call `useInsightsPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsightsPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<InsightsPreferencesQuery, InsightsPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsPreferencesQuery, InsightsPreferencesQueryVariables>(InsightsPreferencesDocument, options);
      }
export function useInsightsPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsPreferencesQuery, InsightsPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsPreferencesQuery, InsightsPreferencesQueryVariables>(InsightsPreferencesDocument, options);
        }
export type InsightsPreferencesQueryHookResult = ReturnType<typeof useInsightsPreferencesQuery>;
export type InsightsPreferencesLazyQueryHookResult = ReturnType<typeof useInsightsPreferencesLazyQuery>;
export type InsightsPreferencesQueryResult = Apollo.QueryResult<InsightsPreferencesQuery, InsightsPreferencesQueryVariables>;
export const UpdateInsightsPreferencesDocument = gql`
    mutation updateInsightsPreferences($input: InsightsPreferencesInput!) {
  updateInsightsPreferences(input: $input) {
    unit
  }
}
    `;
export type UpdateInsightsPreferencesMutationFn = Apollo.MutationFunction<UpdateInsightsPreferencesMutation, UpdateInsightsPreferencesMutationVariables>;

/**
 * __useUpdateInsightsPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateInsightsPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsightsPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsightsPreferencesMutation, { data, loading, error }] = useUpdateInsightsPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsightsPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInsightsPreferencesMutation, UpdateInsightsPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInsightsPreferencesMutation, UpdateInsightsPreferencesMutationVariables>(UpdateInsightsPreferencesDocument, options);
      }
export type UpdateInsightsPreferencesMutationHookResult = ReturnType<typeof useUpdateInsightsPreferencesMutation>;
export type UpdateInsightsPreferencesMutationResult = Apollo.MutationResult<UpdateInsightsPreferencesMutation>;
export type UpdateInsightsPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateInsightsPreferencesMutation, UpdateInsightsPreferencesMutationVariables>;
export const ExportBookingAccountsGenericDocument = gql`
    mutation exportBookingAccountsGeneric {
  exportBookingAccountsGeneric {
    url
  }
}
    `;
export type ExportBookingAccountsGenericMutationFn = Apollo.MutationFunction<ExportBookingAccountsGenericMutation, ExportBookingAccountsGenericMutationVariables>;

/**
 * __useExportBookingAccountsGenericMutation__
 *
 * To run a mutation, you first call `useExportBookingAccountsGenericMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportBookingAccountsGenericMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportBookingAccountsGenericMutation, { data, loading, error }] = useExportBookingAccountsGenericMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportBookingAccountsGenericMutation(baseOptions?: Apollo.MutationHookOptions<ExportBookingAccountsGenericMutation, ExportBookingAccountsGenericMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportBookingAccountsGenericMutation, ExportBookingAccountsGenericMutationVariables>(ExportBookingAccountsGenericDocument, options);
      }
export type ExportBookingAccountsGenericMutationHookResult = ReturnType<typeof useExportBookingAccountsGenericMutation>;
export type ExportBookingAccountsGenericMutationResult = Apollo.MutationResult<ExportBookingAccountsGenericMutation>;
export type ExportBookingAccountsGenericMutationOptions = Apollo.BaseMutationOptions<ExportBookingAccountsGenericMutation, ExportBookingAccountsGenericMutationVariables>;
export const ExportBookingKeysGenericDocument = gql`
    mutation exportBookingKeysGeneric {
  exportBookingKeysGeneric {
    url
  }
}
    `;
export type ExportBookingKeysGenericMutationFn = Apollo.MutationFunction<ExportBookingKeysGenericMutation, ExportBookingKeysGenericMutationVariables>;

/**
 * __useExportBookingKeysGenericMutation__
 *
 * To run a mutation, you first call `useExportBookingKeysGenericMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportBookingKeysGenericMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportBookingKeysGenericMutation, { data, loading, error }] = useExportBookingKeysGenericMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportBookingKeysGenericMutation(baseOptions?: Apollo.MutationHookOptions<ExportBookingKeysGenericMutation, ExportBookingKeysGenericMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportBookingKeysGenericMutation, ExportBookingKeysGenericMutationVariables>(ExportBookingKeysGenericDocument, options);
      }
export type ExportBookingKeysGenericMutationHookResult = ReturnType<typeof useExportBookingKeysGenericMutation>;
export type ExportBookingKeysGenericMutationResult = Apollo.MutationResult<ExportBookingKeysGenericMutation>;
export type ExportBookingKeysGenericMutationOptions = Apollo.BaseMutationOptions<ExportBookingKeysGenericMutation, ExportBookingKeysGenericMutationVariables>;
export const ExportCostCentersGenericDocument = gql`
    mutation exportCostCentersGeneric {
  exportCostCentersGeneric {
    url
  }
}
    `;
export type ExportCostCentersGenericMutationFn = Apollo.MutationFunction<ExportCostCentersGenericMutation, ExportCostCentersGenericMutationVariables>;

/**
 * __useExportCostCentersGenericMutation__
 *
 * To run a mutation, you first call `useExportCostCentersGenericMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCostCentersGenericMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCostCentersGenericMutation, { data, loading, error }] = useExportCostCentersGenericMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportCostCentersGenericMutation(baseOptions?: Apollo.MutationHookOptions<ExportCostCentersGenericMutation, ExportCostCentersGenericMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCostCentersGenericMutation, ExportCostCentersGenericMutationVariables>(ExportCostCentersGenericDocument, options);
      }
export type ExportCostCentersGenericMutationHookResult = ReturnType<typeof useExportCostCentersGenericMutation>;
export type ExportCostCentersGenericMutationResult = Apollo.MutationResult<ExportCostCentersGenericMutation>;
export type ExportCostCentersGenericMutationOptions = Apollo.BaseMutationOptions<ExportCostCentersGenericMutation, ExportCostCentersGenericMutationVariables>;
export const GetDocumentForSidebarDocument = gql`
    query getDocumentForSidebar($id: ID!) {
  getDocument(id: $id) {
    id
    datevGuid {
      value
    }
    documentFile {
      id
      url
    }
    status
  }
}
    `;

/**
 * __useGetDocumentForSidebarQuery__
 *
 * To run a query within a React component, call `useGetDocumentForSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentForSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentForSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentForSidebarQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentForSidebarQuery, GetDocumentForSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentForSidebarQuery, GetDocumentForSidebarQueryVariables>(GetDocumentForSidebarDocument, options);
      }
export function useGetDocumentForSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentForSidebarQuery, GetDocumentForSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentForSidebarQuery, GetDocumentForSidebarQueryVariables>(GetDocumentForSidebarDocument, options);
        }
export type GetDocumentForSidebarQueryHookResult = ReturnType<typeof useGetDocumentForSidebarQuery>;
export type GetDocumentForSidebarLazyQueryHookResult = ReturnType<typeof useGetDocumentForSidebarLazyQuery>;
export type GetDocumentForSidebarQueryResult = Apollo.QueryResult<GetDocumentForSidebarQuery, GetDocumentForSidebarQueryVariables>;
export const GetDatevLocationParamsForDocumentDocument = gql`
    query getDatevLocationParamsForDocument($documentId: ID!) {
  getLatestDatevExportOfDocument(documentId: $documentId) {
    clientNumber
    consultantNumber
  }
}
    `;

/**
 * __useGetDatevLocationParamsForDocumentQuery__
 *
 * To run a query within a React component, call `useGetDatevLocationParamsForDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatevLocationParamsForDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatevLocationParamsForDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDatevLocationParamsForDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetDatevLocationParamsForDocumentQuery, GetDatevLocationParamsForDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatevLocationParamsForDocumentQuery, GetDatevLocationParamsForDocumentQueryVariables>(GetDatevLocationParamsForDocumentDocument, options);
      }
export function useGetDatevLocationParamsForDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatevLocationParamsForDocumentQuery, GetDatevLocationParamsForDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatevLocationParamsForDocumentQuery, GetDatevLocationParamsForDocumentQueryVariables>(GetDatevLocationParamsForDocumentDocument, options);
        }
export type GetDatevLocationParamsForDocumentQueryHookResult = ReturnType<typeof useGetDatevLocationParamsForDocumentQuery>;
export type GetDatevLocationParamsForDocumentLazyQueryHookResult = ReturnType<typeof useGetDatevLocationParamsForDocumentLazyQuery>;
export type GetDatevLocationParamsForDocumentQueryResult = Apollo.QueryResult<GetDatevLocationParamsForDocumentQuery, GetDatevLocationParamsForDocumentQueryVariables>;
export const ContactIbanFilterDocument = gql`
    query contactIbanFilter($iban: String!) {
  contactIbanFilter(iban: $iban)
}
    `;

/**
 * __useContactIbanFilterQuery__
 *
 * To run a query within a React component, call `useContactIbanFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactIbanFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactIbanFilterQuery({
 *   variables: {
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useContactIbanFilterQuery(baseOptions: Apollo.QueryHookOptions<ContactIbanFilterQuery, ContactIbanFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactIbanFilterQuery, ContactIbanFilterQueryVariables>(ContactIbanFilterDocument, options);
      }
export function useContactIbanFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactIbanFilterQuery, ContactIbanFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactIbanFilterQuery, ContactIbanFilterQueryVariables>(ContactIbanFilterDocument, options);
        }
export type ContactIbanFilterQueryHookResult = ReturnType<typeof useContactIbanFilterQuery>;
export type ContactIbanFilterLazyQueryHookResult = ReturnType<typeof useContactIbanFilterLazyQuery>;
export type ContactIbanFilterQueryResult = Apollo.QueryResult<ContactIbanFilterQuery, ContactIbanFilterQueryVariables>;
export const ContactNameFilterDocument = gql`
    query contactNameFilter($name: String!) {
  contactNameFilter(name: $name)
}
    `;

/**
 * __useContactNameFilterQuery__
 *
 * To run a query within a React component, call `useContactNameFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactNameFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactNameFilterQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useContactNameFilterQuery(baseOptions: Apollo.QueryHookOptions<ContactNameFilterQuery, ContactNameFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactNameFilterQuery, ContactNameFilterQueryVariables>(ContactNameFilterDocument, options);
      }
export function useContactNameFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactNameFilterQuery, ContactNameFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactNameFilterQuery, ContactNameFilterQueryVariables>(ContactNameFilterDocument, options);
        }
export type ContactNameFilterQueryHookResult = ReturnType<typeof useContactNameFilterQuery>;
export type ContactNameFilterLazyQueryHookResult = ReturnType<typeof useContactNameFilterLazyQuery>;
export type ContactNameFilterQueryResult = Apollo.QueryResult<ContactNameFilterQuery, ContactNameFilterQueryVariables>;
export const ContactTaxNumberFilterDocument = gql`
    query contactTaxNumberFilter($taxNumber: String!) {
  contactTaxNumberFilter(taxNumber: $taxNumber)
}
    `;

/**
 * __useContactTaxNumberFilterQuery__
 *
 * To run a query within a React component, call `useContactTaxNumberFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactTaxNumberFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactTaxNumberFilterQuery({
 *   variables: {
 *      taxNumber: // value for 'taxNumber'
 *   },
 * });
 */
export function useContactTaxNumberFilterQuery(baseOptions: Apollo.QueryHookOptions<ContactTaxNumberFilterQuery, ContactTaxNumberFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactTaxNumberFilterQuery, ContactTaxNumberFilterQueryVariables>(ContactTaxNumberFilterDocument, options);
      }
export function useContactTaxNumberFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactTaxNumberFilterQuery, ContactTaxNumberFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactTaxNumberFilterQuery, ContactTaxNumberFilterQueryVariables>(ContactTaxNumberFilterDocument, options);
        }
export type ContactTaxNumberFilterQueryHookResult = ReturnType<typeof useContactTaxNumberFilterQuery>;
export type ContactTaxNumberFilterLazyQueryHookResult = ReturnType<typeof useContactTaxNumberFilterLazyQuery>;
export type ContactTaxNumberFilterQueryResult = Apollo.QueryResult<ContactTaxNumberFilterQuery, ContactTaxNumberFilterQueryVariables>;
export const ContactVatIdFilterDocument = gql`
    query contactVatIdFilter($vatId: String!) {
  contactVatIdFilter(vatId: $vatId)
}
    `;

/**
 * __useContactVatIdFilterQuery__
 *
 * To run a query within a React component, call `useContactVatIdFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactVatIdFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactVatIdFilterQuery({
 *   variables: {
 *      vatId: // value for 'vatId'
 *   },
 * });
 */
export function useContactVatIdFilterQuery(baseOptions: Apollo.QueryHookOptions<ContactVatIdFilterQuery, ContactVatIdFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactVatIdFilterQuery, ContactVatIdFilterQueryVariables>(ContactVatIdFilterDocument, options);
      }
export function useContactVatIdFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactVatIdFilterQuery, ContactVatIdFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactVatIdFilterQuery, ContactVatIdFilterQueryVariables>(ContactVatIdFilterDocument, options);
        }
export type ContactVatIdFilterQueryHookResult = ReturnType<typeof useContactVatIdFilterQuery>;
export type ContactVatIdFilterLazyQueryHookResult = ReturnType<typeof useContactVatIdFilterLazyQuery>;
export type ContactVatIdFilterQueryResult = Apollo.QueryResult<ContactVatIdFilterQuery, ContactVatIdFilterQueryVariables>;
export const ContactAccountsPayableNumberFilterDocument = gql`
    query contactAccountsPayableNumberFilter($accountsPayableNumber: String!) {
  contactAccountsPayableNumberFilter(
    accountsPayableNumber: $accountsPayableNumber
  )
}
    `;

/**
 * __useContactAccountsPayableNumberFilterQuery__
 *
 * To run a query within a React component, call `useContactAccountsPayableNumberFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactAccountsPayableNumberFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactAccountsPayableNumberFilterQuery({
 *   variables: {
 *      accountsPayableNumber: // value for 'accountsPayableNumber'
 *   },
 * });
 */
export function useContactAccountsPayableNumberFilterQuery(baseOptions: Apollo.QueryHookOptions<ContactAccountsPayableNumberFilterQuery, ContactAccountsPayableNumberFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactAccountsPayableNumberFilterQuery, ContactAccountsPayableNumberFilterQueryVariables>(ContactAccountsPayableNumberFilterDocument, options);
      }
export function useContactAccountsPayableNumberFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactAccountsPayableNumberFilterQuery, ContactAccountsPayableNumberFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactAccountsPayableNumberFilterQuery, ContactAccountsPayableNumberFilterQueryVariables>(ContactAccountsPayableNumberFilterDocument, options);
        }
export type ContactAccountsPayableNumberFilterQueryHookResult = ReturnType<typeof useContactAccountsPayableNumberFilterQuery>;
export type ContactAccountsPayableNumberFilterLazyQueryHookResult = ReturnType<typeof useContactAccountsPayableNumberFilterLazyQuery>;
export type ContactAccountsPayableNumberFilterQueryResult = Apollo.QueryResult<ContactAccountsPayableNumberFilterQuery, ContactAccountsPayableNumberFilterQueryVariables>;
export const ContactAccountsReceivableNumberFilterDocument = gql`
    query contactAccountsReceivableNumberFilter($accountsReceivableNumber: String!) {
  contactAccountsReceivableNumberFilter(
    accountsReceivableNumber: $accountsReceivableNumber
  )
}
    `;

/**
 * __useContactAccountsReceivableNumberFilterQuery__
 *
 * To run a query within a React component, call `useContactAccountsReceivableNumberFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactAccountsReceivableNumberFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactAccountsReceivableNumberFilterQuery({
 *   variables: {
 *      accountsReceivableNumber: // value for 'accountsReceivableNumber'
 *   },
 * });
 */
export function useContactAccountsReceivableNumberFilterQuery(baseOptions: Apollo.QueryHookOptions<ContactAccountsReceivableNumberFilterQuery, ContactAccountsReceivableNumberFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactAccountsReceivableNumberFilterQuery, ContactAccountsReceivableNumberFilterQueryVariables>(ContactAccountsReceivableNumberFilterDocument, options);
      }
export function useContactAccountsReceivableNumberFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactAccountsReceivableNumberFilterQuery, ContactAccountsReceivableNumberFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactAccountsReceivableNumberFilterQuery, ContactAccountsReceivableNumberFilterQueryVariables>(ContactAccountsReceivableNumberFilterDocument, options);
        }
export type ContactAccountsReceivableNumberFilterQueryHookResult = ReturnType<typeof useContactAccountsReceivableNumberFilterQuery>;
export type ContactAccountsReceivableNumberFilterLazyQueryHookResult = ReturnType<typeof useContactAccountsReceivableNumberFilterLazyQuery>;
export type ContactAccountsReceivableNumberFilterQueryResult = Apollo.QueryResult<ContactAccountsReceivableNumberFilterQuery, ContactAccountsReceivableNumberFilterQueryVariables>;
export const GetCardStatusByIdDocument = gql`
    query getCardStatusById($id: ID!) {
  getCardIssuerCardById(id: $id) {
    status
  }
}
    `;

/**
 * __useGetCardStatusByIdQuery__
 *
 * To run a query within a React component, call `useGetCardStatusByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardStatusByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardStatusByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardStatusByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardStatusByIdQuery, GetCardStatusByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardStatusByIdQuery, GetCardStatusByIdQueryVariables>(GetCardStatusByIdDocument, options);
      }
export function useGetCardStatusByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardStatusByIdQuery, GetCardStatusByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardStatusByIdQuery, GetCardStatusByIdQueryVariables>(GetCardStatusByIdDocument, options);
        }
export type GetCardStatusByIdQueryHookResult = ReturnType<typeof useGetCardStatusByIdQuery>;
export type GetCardStatusByIdLazyQueryHookResult = ReturnType<typeof useGetCardStatusByIdLazyQuery>;
export type GetCardStatusByIdQueryResult = Apollo.QueryResult<GetCardStatusByIdQuery, GetCardStatusByIdQueryVariables>;
export const CountEcmDocumentsWithFullTextSearchDocument = gql`
    query countEcmDocumentsWithFullTextSearch($search: String, $filter: EcmFilterInput) {
  countEcmDocumentsWithFullTextSearch(search: $search, filter: $filter) {
    count
  }
}
    `;

/**
 * __useCountEcmDocumentsWithFullTextSearchQuery__
 *
 * To run a query within a React component, call `useCountEcmDocumentsWithFullTextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountEcmDocumentsWithFullTextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountEcmDocumentsWithFullTextSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountEcmDocumentsWithFullTextSearchQuery(baseOptions?: Apollo.QueryHookOptions<CountEcmDocumentsWithFullTextSearchQuery, CountEcmDocumentsWithFullTextSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountEcmDocumentsWithFullTextSearchQuery, CountEcmDocumentsWithFullTextSearchQueryVariables>(CountEcmDocumentsWithFullTextSearchDocument, options);
      }
export function useCountEcmDocumentsWithFullTextSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountEcmDocumentsWithFullTextSearchQuery, CountEcmDocumentsWithFullTextSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountEcmDocumentsWithFullTextSearchQuery, CountEcmDocumentsWithFullTextSearchQueryVariables>(CountEcmDocumentsWithFullTextSearchDocument, options);
        }
export type CountEcmDocumentsWithFullTextSearchQueryHookResult = ReturnType<typeof useCountEcmDocumentsWithFullTextSearchQuery>;
export type CountEcmDocumentsWithFullTextSearchLazyQueryHookResult = ReturnType<typeof useCountEcmDocumentsWithFullTextSearchLazyQuery>;
export type CountEcmDocumentsWithFullTextSearchQueryResult = Apollo.QueryResult<CountEcmDocumentsWithFullTextSearchQuery, CountEcmDocumentsWithFullTextSearchQueryVariables>;
export const GetOrgMembersAbsenceDocument = gql`
    query getOrgMembersAbsence {
  getOrgMembersAbsence {
    fromDate
    toDate
    note
    status
    membershipId
    substituteMembershipId
  }
}
    `;

/**
 * __useGetOrgMembersAbsenceQuery__
 *
 * To run a query within a React component, call `useGetOrgMembersAbsenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgMembersAbsenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgMembersAbsenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgMembersAbsenceQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgMembersAbsenceQuery, GetOrgMembersAbsenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgMembersAbsenceQuery, GetOrgMembersAbsenceQueryVariables>(GetOrgMembersAbsenceDocument, options);
      }
export function useGetOrgMembersAbsenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgMembersAbsenceQuery, GetOrgMembersAbsenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgMembersAbsenceQuery, GetOrgMembersAbsenceQueryVariables>(GetOrgMembersAbsenceDocument, options);
        }
export type GetOrgMembersAbsenceQueryHookResult = ReturnType<typeof useGetOrgMembersAbsenceQuery>;
export type GetOrgMembersAbsenceLazyQueryHookResult = ReturnType<typeof useGetOrgMembersAbsenceLazyQuery>;
export type GetOrgMembersAbsenceQueryResult = Apollo.QueryResult<GetOrgMembersAbsenceQuery, GetOrgMembersAbsenceQueryVariables>;
export const ConvertDocumentDocument = gql`
    mutation convertDocument($input: ConvertDocumentInput!) {
  convertDocument(input: $input) {
    globalDocumentId
    invoiceId
  }
}
    `;
export type ConvertDocumentMutationFn = Apollo.MutationFunction<ConvertDocumentMutation, ConvertDocumentMutationVariables>;

/**
 * __useConvertDocumentMutation__
 *
 * To run a mutation, you first call `useConvertDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertDocumentMutation, { data, loading, error }] = useConvertDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ConvertDocumentMutation, ConvertDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertDocumentMutation, ConvertDocumentMutationVariables>(ConvertDocumentDocument, options);
      }
export type ConvertDocumentMutationHookResult = ReturnType<typeof useConvertDocumentMutation>;
export type ConvertDocumentMutationResult = Apollo.MutationResult<ConvertDocumentMutation>;
export type ConvertDocumentMutationOptions = Apollo.BaseMutationOptions<ConvertDocumentMutation, ConvertDocumentMutationVariables>;
export const CountClassifyingDocumentsDocument = gql`
    query countClassifyingDocuments {
  countClassifyingDocuments {
    count
  }
}
    `;

/**
 * __useCountClassifyingDocumentsQuery__
 *
 * To run a query within a React component, call `useCountClassifyingDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountClassifyingDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountClassifyingDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountClassifyingDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<CountClassifyingDocumentsQuery, CountClassifyingDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountClassifyingDocumentsQuery, CountClassifyingDocumentsQueryVariables>(CountClassifyingDocumentsDocument, options);
      }
export function useCountClassifyingDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountClassifyingDocumentsQuery, CountClassifyingDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountClassifyingDocumentsQuery, CountClassifyingDocumentsQueryVariables>(CountClassifyingDocumentsDocument, options);
        }
export type CountClassifyingDocumentsQueryHookResult = ReturnType<typeof useCountClassifyingDocumentsQuery>;
export type CountClassifyingDocumentsLazyQueryHookResult = ReturnType<typeof useCountClassifyingDocumentsLazyQuery>;
export type CountClassifyingDocumentsQueryResult = Apollo.QueryResult<CountClassifyingDocumentsQuery, CountClassifyingDocumentsQueryVariables>;
export const OrganizationMembershipsDocument = gql`
    query organizationMemberships($filters: OrganizationMembershipsFiltersInput, $sortBy: MemberSortInput) {
  organizationMemberships(filters: $filters, sortBy: $sortBy) {
    id
    name
    firstName
    lastName
    roles
    locale
    email
    avatarUrl
    status
    createdTimestamp
    username
    paymentInfo {
      iban
      swiftCode
    }
    membershipRoles {
      __typename
      id
      name
      description
      isArchived
      isBuiltinRole
      isLegacyRole
    }
  }
}
    `;

/**
 * __useOrganizationMembershipsQuery__
 *
 * To run a query within a React component, call `useOrganizationMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembershipsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOrganizationMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationMembershipsQuery, OrganizationMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationMembershipsQuery, OrganizationMembershipsQueryVariables>(OrganizationMembershipsDocument, options);
      }
export function useOrganizationMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationMembershipsQuery, OrganizationMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationMembershipsQuery, OrganizationMembershipsQueryVariables>(OrganizationMembershipsDocument, options);
        }
export type OrganizationMembershipsQueryHookResult = ReturnType<typeof useOrganizationMembershipsQuery>;
export type OrganizationMembershipsLazyQueryHookResult = ReturnType<typeof useOrganizationMembershipsLazyQuery>;
export type OrganizationMembershipsQueryResult = Apollo.QueryResult<OrganizationMembershipsQuery, OrganizationMembershipsQueryVariables>;
export const GetMostActiveCardholdersDocument = gql`
    query getMostActiveCardholders($input: CardIssuerPaginationInput!, $filters: CardIssuerCardholdersFiltersInput, $sortBy: CardIssuerCardholdersSortInput) {
  getMostActiveCardholders: getCardIssuerCardholders(
    input: $input
    filters: $filters
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        firstName
        lastName
        avatarUrl
        memberships {
          membershipId
          candisOrganizationId
        }
      }
    }
  }
}
    `;

/**
 * __useGetMostActiveCardholdersQuery__
 *
 * To run a query within a React component, call `useGetMostActiveCardholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostActiveCardholdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostActiveCardholdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetMostActiveCardholdersQuery(baseOptions: Apollo.QueryHookOptions<GetMostActiveCardholdersQuery, GetMostActiveCardholdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMostActiveCardholdersQuery, GetMostActiveCardholdersQueryVariables>(GetMostActiveCardholdersDocument, options);
      }
export function useGetMostActiveCardholdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostActiveCardholdersQuery, GetMostActiveCardholdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMostActiveCardholdersQuery, GetMostActiveCardholdersQueryVariables>(GetMostActiveCardholdersDocument, options);
        }
export type GetMostActiveCardholdersQueryHookResult = ReturnType<typeof useGetMostActiveCardholdersQuery>;
export type GetMostActiveCardholdersLazyQueryHookResult = ReturnType<typeof useGetMostActiveCardholdersLazyQuery>;
export type GetMostActiveCardholdersQueryResult = Apollo.QueryResult<GetMostActiveCardholdersQuery, GetMostActiveCardholdersQueryVariables>;
export const ActivateCardDocument = gql`
    mutation activateCard($input: ActivateCardInput!) {
  activateCard(input: $input)
}
    `;
export type ActivateCardMutationFn = Apollo.MutationFunction<ActivateCardMutation, ActivateCardMutationVariables>;

/**
 * __useActivateCardMutation__
 *
 * To run a mutation, you first call `useActivateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCardMutation, { data, loading, error }] = useActivateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCardMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCardMutation, ActivateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCardMutation, ActivateCardMutationVariables>(ActivateCardDocument, options);
      }
export type ActivateCardMutationHookResult = ReturnType<typeof useActivateCardMutation>;
export type ActivateCardMutationResult = Apollo.MutationResult<ActivateCardMutation>;
export type ActivateCardMutationOptions = Apollo.BaseMutationOptions<ActivateCardMutation, ActivateCardMutationVariables>;
export const LockUnlockCardDocument = gql`
    mutation lockUnlockCard($input: CardIssuerLockUnlockCardInput!) {
  lockUnlockCard(input: $input)
}
    `;
export type LockUnlockCardMutationFn = Apollo.MutationFunction<LockUnlockCardMutation, LockUnlockCardMutationVariables>;

/**
 * __useLockUnlockCardMutation__
 *
 * To run a mutation, you first call `useLockUnlockCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockUnlockCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockUnlockCardMutation, { data, loading, error }] = useLockUnlockCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLockUnlockCardMutation(baseOptions?: Apollo.MutationHookOptions<LockUnlockCardMutation, LockUnlockCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LockUnlockCardMutation, LockUnlockCardMutationVariables>(LockUnlockCardDocument, options);
      }
export type LockUnlockCardMutationHookResult = ReturnType<typeof useLockUnlockCardMutation>;
export type LockUnlockCardMutationResult = Apollo.MutationResult<LockUnlockCardMutation>;
export type LockUnlockCardMutationOptions = Apollo.BaseMutationOptions<LockUnlockCardMutation, LockUnlockCardMutationVariables>;
export const GetCardIssuerCardUniqueTokenByIdDocument = gql`
    query getCardIssuerCardUniqueTokenById($id: ID!) {
  getCardIssuerCardById(id: $id) {
    id
    token
    uniqueToken
  }
}
    `;

/**
 * __useGetCardIssuerCardUniqueTokenByIdQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerCardUniqueTokenByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerCardUniqueTokenByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerCardUniqueTokenByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardIssuerCardUniqueTokenByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerCardUniqueTokenByIdQuery, GetCardIssuerCardUniqueTokenByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerCardUniqueTokenByIdQuery, GetCardIssuerCardUniqueTokenByIdQueryVariables>(GetCardIssuerCardUniqueTokenByIdDocument, options);
      }
export function useGetCardIssuerCardUniqueTokenByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerCardUniqueTokenByIdQuery, GetCardIssuerCardUniqueTokenByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerCardUniqueTokenByIdQuery, GetCardIssuerCardUniqueTokenByIdQueryVariables>(GetCardIssuerCardUniqueTokenByIdDocument, options);
        }
export type GetCardIssuerCardUniqueTokenByIdQueryHookResult = ReturnType<typeof useGetCardIssuerCardUniqueTokenByIdQuery>;
export type GetCardIssuerCardUniqueTokenByIdLazyQueryHookResult = ReturnType<typeof useGetCardIssuerCardUniqueTokenByIdLazyQuery>;
export type GetCardIssuerCardUniqueTokenByIdQueryResult = Apollo.QueryResult<GetCardIssuerCardUniqueTokenByIdQuery, GetCardIssuerCardUniqueTokenByIdQueryVariables>;
export const CardCredentialByCardIdDocument = gql`
    query cardCredentialByCardId($cardId: ID!) {
  getCardIssuerCardCredential(id: $cardId) {
    __typename
    ... on CardIssuerCardCredential {
      token
      uniqueToken
    }
    ... on RecentAuthnRequiredError {
      requiredAuthType
      validForSeconds
    }
  }
}
    `;

/**
 * __useCardCredentialByCardIdQuery__
 *
 * To run a query within a React component, call `useCardCredentialByCardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardCredentialByCardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardCredentialByCardIdQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardCredentialByCardIdQuery(baseOptions: Apollo.QueryHookOptions<CardCredentialByCardIdQuery, CardCredentialByCardIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardCredentialByCardIdQuery, CardCredentialByCardIdQueryVariables>(CardCredentialByCardIdDocument, options);
      }
export function useCardCredentialByCardIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardCredentialByCardIdQuery, CardCredentialByCardIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardCredentialByCardIdQuery, CardCredentialByCardIdQueryVariables>(CardCredentialByCardIdDocument, options);
        }
export type CardCredentialByCardIdQueryHookResult = ReturnType<typeof useCardCredentialByCardIdQuery>;
export type CardCredentialByCardIdLazyQueryHookResult = ReturnType<typeof useCardCredentialByCardIdLazyQuery>;
export type CardCredentialByCardIdQueryResult = Apollo.QueryResult<CardCredentialByCardIdQuery, CardCredentialByCardIdQueryVariables>;
export const GetPinForCardDocument = gql`
    query getPINForCard($cardId: String!) {
  getPINForCard(cardId: $cardId)
}
    `;

/**
 * __useGetPinForCardQuery__
 *
 * To run a query within a React component, call `useGetPinForCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPinForCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPinForCardQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useGetPinForCardQuery(baseOptions: Apollo.QueryHookOptions<GetPinForCardQuery, GetPinForCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPinForCardQuery, GetPinForCardQueryVariables>(GetPinForCardDocument, options);
      }
export function useGetPinForCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPinForCardQuery, GetPinForCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPinForCardQuery, GetPinForCardQueryVariables>(GetPinForCardDocument, options);
        }
export type GetPinForCardQueryHookResult = ReturnType<typeof useGetPinForCardQuery>;
export type GetPinForCardLazyQueryHookResult = ReturnType<typeof useGetPinForCardLazyQuery>;
export type GetPinForCardQueryResult = Apollo.QueryResult<GetPinForCardQuery, GetPinForCardQueryVariables>;
export const CardIssuerCardsForCardholderDocument = gql`
    query cardIssuerCardsForCardholder($cursor: String) {
  prevCard: getCardIssuerCardsForCardholder(input: {limit: 1, before: $cursor}) {
    edges {
      cursor
      node {
        id
      }
    }
  }
  nextCard: getCardIssuerCardsForCardholder(input: {limit: 1, after: $cursor}) {
    edges {
      cursor
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useCardIssuerCardsForCardholderQuery__
 *
 * To run a query within a React component, call `useCardIssuerCardsForCardholderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardIssuerCardsForCardholderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardIssuerCardsForCardholderQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCardIssuerCardsForCardholderQuery(baseOptions?: Apollo.QueryHookOptions<CardIssuerCardsForCardholderQuery, CardIssuerCardsForCardholderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardIssuerCardsForCardholderQuery, CardIssuerCardsForCardholderQueryVariables>(CardIssuerCardsForCardholderDocument, options);
      }
export function useCardIssuerCardsForCardholderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardIssuerCardsForCardholderQuery, CardIssuerCardsForCardholderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardIssuerCardsForCardholderQuery, CardIssuerCardsForCardholderQueryVariables>(CardIssuerCardsForCardholderDocument, options);
        }
export type CardIssuerCardsForCardholderQueryHookResult = ReturnType<typeof useCardIssuerCardsForCardholderQuery>;
export type CardIssuerCardsForCardholderLazyQueryHookResult = ReturnType<typeof useCardIssuerCardsForCardholderLazyQuery>;
export type CardIssuerCardsForCardholderQueryResult = Apollo.QueryResult<CardIssuerCardsForCardholderQuery, CardIssuerCardsForCardholderQueryVariables>;
export const AcceptCardUsageAgreementDocument = gql`
    mutation acceptCardUsageAgreement($input: CardIssuerCardUsageAgreementInput!) {
  acceptCardUsageAgreement(input: $input) {
    id
  }
}
    `;
export type AcceptCardUsageAgreementMutationFn = Apollo.MutationFunction<AcceptCardUsageAgreementMutation, AcceptCardUsageAgreementMutationVariables>;

/**
 * __useAcceptCardUsageAgreementMutation__
 *
 * To run a mutation, you first call `useAcceptCardUsageAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCardUsageAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCardUsageAgreementMutation, { data, loading, error }] = useAcceptCardUsageAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCardUsageAgreementMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCardUsageAgreementMutation, AcceptCardUsageAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCardUsageAgreementMutation, AcceptCardUsageAgreementMutationVariables>(AcceptCardUsageAgreementDocument, options);
      }
export type AcceptCardUsageAgreementMutationHookResult = ReturnType<typeof useAcceptCardUsageAgreementMutation>;
export type AcceptCardUsageAgreementMutationResult = Apollo.MutationResult<AcceptCardUsageAgreementMutation>;
export type AcceptCardUsageAgreementMutationOptions = Apollo.BaseMutationOptions<AcceptCardUsageAgreementMutation, AcceptCardUsageAgreementMutationVariables>;
export const GetCardPendingActionsDocument = gql`
    query getCardPendingActions($id: ID!) {
  getCardIssuerCardById(id: $id) {
    id
    pendingActions {
      id
      name
      createdAt
    }
  }
}
    `;

/**
 * __useGetCardPendingActionsQuery__
 *
 * To run a query within a React component, call `useGetCardPendingActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardPendingActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardPendingActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardPendingActionsQuery(baseOptions: Apollo.QueryHookOptions<GetCardPendingActionsQuery, GetCardPendingActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardPendingActionsQuery, GetCardPendingActionsQueryVariables>(GetCardPendingActionsDocument, options);
      }
export function useGetCardPendingActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardPendingActionsQuery, GetCardPendingActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardPendingActionsQuery, GetCardPendingActionsQueryVariables>(GetCardPendingActionsDocument, options);
        }
export type GetCardPendingActionsQueryHookResult = ReturnType<typeof useGetCardPendingActionsQuery>;
export type GetCardPendingActionsLazyQueryHookResult = ReturnType<typeof useGetCardPendingActionsLazyQuery>;
export type GetCardPendingActionsQueryResult = Apollo.QueryResult<GetCardPendingActionsQuery, GetCardPendingActionsQueryVariables>;
export const CardDocument = gql`
    subscription card($input: CardSubscriptionInput) {
  card(input: $input) {
    ...creditCardByIdData
  }
}
    ${CreditCardByIdDataFragmentDoc}
${VirtualCardRequestDataFragmentDoc}
${SingleUseVirtualCardRequestDataFragmentDoc}
${PhysicalCardRequestDataFragmentDoc}
${CardLimitChangeRequestDataFragmentDoc}`;

/**
 * __useCardSubscription__
 *
 * To run a query within a React component, call `useCardSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCardSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CardSubscription, CardSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CardSubscription, CardSubscriptionVariables>(CardDocument, options);
      }
export type CardSubscriptionHookResult = ReturnType<typeof useCardSubscription>;
export type CardSubscriptionResult = Apollo.SubscriptionResult<CardSubscription>;
export const CardForCardholderDocument = gql`
    subscription cardForCardholder($input: CardSubscriptionInput) {
  card(input: $input) @connection(key: "cardForCardholder") {
    ...creditCardByIdData
  }
}
    ${CreditCardByIdDataFragmentDoc}
${VirtualCardRequestDataFragmentDoc}
${SingleUseVirtualCardRequestDataFragmentDoc}
${PhysicalCardRequestDataFragmentDoc}
${CardLimitChangeRequestDataFragmentDoc}`;

/**
 * __useCardForCardholderSubscription__
 *
 * To run a query within a React component, call `useCardForCardholderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCardForCardholderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardForCardholderSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCardForCardholderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CardForCardholderSubscription, CardForCardholderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CardForCardholderSubscription, CardForCardholderSubscriptionVariables>(CardForCardholderDocument, options);
      }
export type CardForCardholderSubscriptionHookResult = ReturnType<typeof useCardForCardholderSubscription>;
export type CardForCardholderSubscriptionResult = Apollo.SubscriptionResult<CardForCardholderSubscription>;
export const RequestVirtualCardIssuerCardDocument = gql`
    mutation requestVirtualCardIssuerCard($input: RequestVirtualCardInput!) {
  requestVirtualCardIssuerCard(input: $input) {
    id
  }
}
    `;
export type RequestVirtualCardIssuerCardMutationFn = Apollo.MutationFunction<RequestVirtualCardIssuerCardMutation, RequestVirtualCardIssuerCardMutationVariables>;

/**
 * __useRequestVirtualCardIssuerCardMutation__
 *
 * To run a mutation, you first call `useRequestVirtualCardIssuerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestVirtualCardIssuerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestVirtualCardIssuerCardMutation, { data, loading, error }] = useRequestVirtualCardIssuerCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestVirtualCardIssuerCardMutation(baseOptions?: Apollo.MutationHookOptions<RequestVirtualCardIssuerCardMutation, RequestVirtualCardIssuerCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestVirtualCardIssuerCardMutation, RequestVirtualCardIssuerCardMutationVariables>(RequestVirtualCardIssuerCardDocument, options);
      }
export type RequestVirtualCardIssuerCardMutationHookResult = ReturnType<typeof useRequestVirtualCardIssuerCardMutation>;
export type RequestVirtualCardIssuerCardMutationResult = Apollo.MutationResult<RequestVirtualCardIssuerCardMutation>;
export type RequestVirtualCardIssuerCardMutationOptions = Apollo.BaseMutationOptions<RequestVirtualCardIssuerCardMutation, RequestVirtualCardIssuerCardMutationVariables>;
export const RequestSingleUseVirtualCardIssuerCardDocument = gql`
    mutation requestSingleUseVirtualCardIssuerCard($input: RequestSingleUseVirtualCardInput!) {
  requestSingleUseVirtualCardIssuerCard(input: $input) {
    id
  }
}
    `;
export type RequestSingleUseVirtualCardIssuerCardMutationFn = Apollo.MutationFunction<RequestSingleUseVirtualCardIssuerCardMutation, RequestSingleUseVirtualCardIssuerCardMutationVariables>;

/**
 * __useRequestSingleUseVirtualCardIssuerCardMutation__
 *
 * To run a mutation, you first call `useRequestSingleUseVirtualCardIssuerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSingleUseVirtualCardIssuerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSingleUseVirtualCardIssuerCardMutation, { data, loading, error }] = useRequestSingleUseVirtualCardIssuerCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestSingleUseVirtualCardIssuerCardMutation(baseOptions?: Apollo.MutationHookOptions<RequestSingleUseVirtualCardIssuerCardMutation, RequestSingleUseVirtualCardIssuerCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestSingleUseVirtualCardIssuerCardMutation, RequestSingleUseVirtualCardIssuerCardMutationVariables>(RequestSingleUseVirtualCardIssuerCardDocument, options);
      }
export type RequestSingleUseVirtualCardIssuerCardMutationHookResult = ReturnType<typeof useRequestSingleUseVirtualCardIssuerCardMutation>;
export type RequestSingleUseVirtualCardIssuerCardMutationResult = Apollo.MutationResult<RequestSingleUseVirtualCardIssuerCardMutation>;
export type RequestSingleUseVirtualCardIssuerCardMutationOptions = Apollo.BaseMutationOptions<RequestSingleUseVirtualCardIssuerCardMutation, RequestSingleUseVirtualCardIssuerCardMutationVariables>;
export const RequestPhysicalCardIssuerCardDocument = gql`
    mutation requestPhysicalCardIssuerCard($input: RequestPhysicalCardInput!) {
  requestPhysicalCardIssuerCard(input: $input) {
    id
  }
}
    `;
export type RequestPhysicalCardIssuerCardMutationFn = Apollo.MutationFunction<RequestPhysicalCardIssuerCardMutation, RequestPhysicalCardIssuerCardMutationVariables>;

/**
 * __useRequestPhysicalCardIssuerCardMutation__
 *
 * To run a mutation, you first call `useRequestPhysicalCardIssuerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPhysicalCardIssuerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPhysicalCardIssuerCardMutation, { data, loading, error }] = useRequestPhysicalCardIssuerCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPhysicalCardIssuerCardMutation(baseOptions?: Apollo.MutationHookOptions<RequestPhysicalCardIssuerCardMutation, RequestPhysicalCardIssuerCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPhysicalCardIssuerCardMutation, RequestPhysicalCardIssuerCardMutationVariables>(RequestPhysicalCardIssuerCardDocument, options);
      }
export type RequestPhysicalCardIssuerCardMutationHookResult = ReturnType<typeof useRequestPhysicalCardIssuerCardMutation>;
export type RequestPhysicalCardIssuerCardMutationResult = Apollo.MutationResult<RequestPhysicalCardIssuerCardMutation>;
export type RequestPhysicalCardIssuerCardMutationOptions = Apollo.BaseMutationOptions<RequestPhysicalCardIssuerCardMutation, RequestPhysicalCardIssuerCardMutationVariables>;
export const IssueVirtualCardIssuerCardDocument = gql`
    mutation issueVirtualCardIssuerCard($input: IssueVirtualCardInput!) {
  issueVirtualCardIssuerCard(input: $input) {
    id
  }
}
    `;
export type IssueVirtualCardIssuerCardMutationFn = Apollo.MutationFunction<IssueVirtualCardIssuerCardMutation, IssueVirtualCardIssuerCardMutationVariables>;

/**
 * __useIssueVirtualCardIssuerCardMutation__
 *
 * To run a mutation, you first call `useIssueVirtualCardIssuerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueVirtualCardIssuerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueVirtualCardIssuerCardMutation, { data, loading, error }] = useIssueVirtualCardIssuerCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueVirtualCardIssuerCardMutation(baseOptions?: Apollo.MutationHookOptions<IssueVirtualCardIssuerCardMutation, IssueVirtualCardIssuerCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueVirtualCardIssuerCardMutation, IssueVirtualCardIssuerCardMutationVariables>(IssueVirtualCardIssuerCardDocument, options);
      }
export type IssueVirtualCardIssuerCardMutationHookResult = ReturnType<typeof useIssueVirtualCardIssuerCardMutation>;
export type IssueVirtualCardIssuerCardMutationResult = Apollo.MutationResult<IssueVirtualCardIssuerCardMutation>;
export type IssueVirtualCardIssuerCardMutationOptions = Apollo.BaseMutationOptions<IssueVirtualCardIssuerCardMutation, IssueVirtualCardIssuerCardMutationVariables>;
export const IssueSingleUseVirtualCardIssuerCardDocument = gql`
    mutation issueSingleUseVirtualCardIssuerCard($input: IssueSingleUseVirtualCardInput!) {
  issueSingleUseVirtualCardIssuerCard(input: $input) {
    id
  }
}
    `;
export type IssueSingleUseVirtualCardIssuerCardMutationFn = Apollo.MutationFunction<IssueSingleUseVirtualCardIssuerCardMutation, IssueSingleUseVirtualCardIssuerCardMutationVariables>;

/**
 * __useIssueSingleUseVirtualCardIssuerCardMutation__
 *
 * To run a mutation, you first call `useIssueSingleUseVirtualCardIssuerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueSingleUseVirtualCardIssuerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueSingleUseVirtualCardIssuerCardMutation, { data, loading, error }] = useIssueSingleUseVirtualCardIssuerCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueSingleUseVirtualCardIssuerCardMutation(baseOptions?: Apollo.MutationHookOptions<IssueSingleUseVirtualCardIssuerCardMutation, IssueSingleUseVirtualCardIssuerCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueSingleUseVirtualCardIssuerCardMutation, IssueSingleUseVirtualCardIssuerCardMutationVariables>(IssueSingleUseVirtualCardIssuerCardDocument, options);
      }
export type IssueSingleUseVirtualCardIssuerCardMutationHookResult = ReturnType<typeof useIssueSingleUseVirtualCardIssuerCardMutation>;
export type IssueSingleUseVirtualCardIssuerCardMutationResult = Apollo.MutationResult<IssueSingleUseVirtualCardIssuerCardMutation>;
export type IssueSingleUseVirtualCardIssuerCardMutationOptions = Apollo.BaseMutationOptions<IssueSingleUseVirtualCardIssuerCardMutation, IssueSingleUseVirtualCardIssuerCardMutationVariables>;
export const IssuePhysicalCardIssuerCardDocument = gql`
    mutation issuePhysicalCardIssuerCard($input: IssuePhysicalCardInput!) {
  issuePhysicalCardIssuerCard(input: $input) {
    id
  }
}
    `;
export type IssuePhysicalCardIssuerCardMutationFn = Apollo.MutationFunction<IssuePhysicalCardIssuerCardMutation, IssuePhysicalCardIssuerCardMutationVariables>;

/**
 * __useIssuePhysicalCardIssuerCardMutation__
 *
 * To run a mutation, you first call `useIssuePhysicalCardIssuerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssuePhysicalCardIssuerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issuePhysicalCardIssuerCardMutation, { data, loading, error }] = useIssuePhysicalCardIssuerCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssuePhysicalCardIssuerCardMutation(baseOptions?: Apollo.MutationHookOptions<IssuePhysicalCardIssuerCardMutation, IssuePhysicalCardIssuerCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssuePhysicalCardIssuerCardMutation, IssuePhysicalCardIssuerCardMutationVariables>(IssuePhysicalCardIssuerCardDocument, options);
      }
export type IssuePhysicalCardIssuerCardMutationHookResult = ReturnType<typeof useIssuePhysicalCardIssuerCardMutation>;
export type IssuePhysicalCardIssuerCardMutationResult = Apollo.MutationResult<IssuePhysicalCardIssuerCardMutation>;
export type IssuePhysicalCardIssuerCardMutationOptions = Apollo.BaseMutationOptions<IssuePhysicalCardIssuerCardMutation, IssuePhysicalCardIssuerCardMutationVariables>;
export const ApproveVirtualCardRequestDocument = gql`
    mutation approveVirtualCardRequest($input: ApproveVirtualCardRequestInput!) {
  approveVirtualCardRequest(input: $input)
}
    `;
export type ApproveVirtualCardRequestMutationFn = Apollo.MutationFunction<ApproveVirtualCardRequestMutation, ApproveVirtualCardRequestMutationVariables>;

/**
 * __useApproveVirtualCardRequestMutation__
 *
 * To run a mutation, you first call `useApproveVirtualCardRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveVirtualCardRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveVirtualCardRequestMutation, { data, loading, error }] = useApproveVirtualCardRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveVirtualCardRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveVirtualCardRequestMutation, ApproveVirtualCardRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveVirtualCardRequestMutation, ApproveVirtualCardRequestMutationVariables>(ApproveVirtualCardRequestDocument, options);
      }
export type ApproveVirtualCardRequestMutationHookResult = ReturnType<typeof useApproveVirtualCardRequestMutation>;
export type ApproveVirtualCardRequestMutationResult = Apollo.MutationResult<ApproveVirtualCardRequestMutation>;
export type ApproveVirtualCardRequestMutationOptions = Apollo.BaseMutationOptions<ApproveVirtualCardRequestMutation, ApproveVirtualCardRequestMutationVariables>;
export const ApproveSingleUseVirtualCardRequestDocument = gql`
    mutation approveSingleUseVirtualCardRequest($input: ApproveSingleUseVirtualCardRequestInput!) {
  approveSingleUseVirtualCardRequest(input: $input)
}
    `;
export type ApproveSingleUseVirtualCardRequestMutationFn = Apollo.MutationFunction<ApproveSingleUseVirtualCardRequestMutation, ApproveSingleUseVirtualCardRequestMutationVariables>;

/**
 * __useApproveSingleUseVirtualCardRequestMutation__
 *
 * To run a mutation, you first call `useApproveSingleUseVirtualCardRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSingleUseVirtualCardRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSingleUseVirtualCardRequestMutation, { data, loading, error }] = useApproveSingleUseVirtualCardRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveSingleUseVirtualCardRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveSingleUseVirtualCardRequestMutation, ApproveSingleUseVirtualCardRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveSingleUseVirtualCardRequestMutation, ApproveSingleUseVirtualCardRequestMutationVariables>(ApproveSingleUseVirtualCardRequestDocument, options);
      }
export type ApproveSingleUseVirtualCardRequestMutationHookResult = ReturnType<typeof useApproveSingleUseVirtualCardRequestMutation>;
export type ApproveSingleUseVirtualCardRequestMutationResult = Apollo.MutationResult<ApproveSingleUseVirtualCardRequestMutation>;
export type ApproveSingleUseVirtualCardRequestMutationOptions = Apollo.BaseMutationOptions<ApproveSingleUseVirtualCardRequestMutation, ApproveSingleUseVirtualCardRequestMutationVariables>;
export const ApprovePhysicalCardRequestDocument = gql`
    mutation approvePhysicalCardRequest($input: ApprovePhysicalCardRequestInput!) {
  approvePhysicalCardRequest(input: $input)
}
    `;
export type ApprovePhysicalCardRequestMutationFn = Apollo.MutationFunction<ApprovePhysicalCardRequestMutation, ApprovePhysicalCardRequestMutationVariables>;

/**
 * __useApprovePhysicalCardRequestMutation__
 *
 * To run a mutation, you first call `useApprovePhysicalCardRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePhysicalCardRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePhysicalCardRequestMutation, { data, loading, error }] = useApprovePhysicalCardRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovePhysicalCardRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApprovePhysicalCardRequestMutation, ApprovePhysicalCardRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApprovePhysicalCardRequestMutation, ApprovePhysicalCardRequestMutationVariables>(ApprovePhysicalCardRequestDocument, options);
      }
export type ApprovePhysicalCardRequestMutationHookResult = ReturnType<typeof useApprovePhysicalCardRequestMutation>;
export type ApprovePhysicalCardRequestMutationResult = Apollo.MutationResult<ApprovePhysicalCardRequestMutation>;
export type ApprovePhysicalCardRequestMutationOptions = Apollo.BaseMutationOptions<ApprovePhysicalCardRequestMutation, ApprovePhysicalCardRequestMutationVariables>;
export const RejectCardRequestDocument = gql`
    mutation rejectCardRequest($input: RejectCardRequestInput!) {
  rejectCardRequest(input: $input)
}
    `;
export type RejectCardRequestMutationFn = Apollo.MutationFunction<RejectCardRequestMutation, RejectCardRequestMutationVariables>;

/**
 * __useRejectCardRequestMutation__
 *
 * To run a mutation, you first call `useRejectCardRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCardRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCardRequestMutation, { data, loading, error }] = useRejectCardRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectCardRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectCardRequestMutation, RejectCardRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectCardRequestMutation, RejectCardRequestMutationVariables>(RejectCardRequestDocument, options);
      }
export type RejectCardRequestMutationHookResult = ReturnType<typeof useRejectCardRequestMutation>;
export type RejectCardRequestMutationResult = Apollo.MutationResult<RejectCardRequestMutation>;
export type RejectCardRequestMutationOptions = Apollo.BaseMutationOptions<RejectCardRequestMutation, RejectCardRequestMutationVariables>;
export const CancelCardRequestDocument = gql`
    mutation cancelCardRequest($input: CancelCardRequestInput!) {
  cancelCardRequest(input: $input)
}
    `;
export type CancelCardRequestMutationFn = Apollo.MutationFunction<CancelCardRequestMutation, CancelCardRequestMutationVariables>;

/**
 * __useCancelCardRequestMutation__
 *
 * To run a mutation, you first call `useCancelCardRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCardRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCardRequestMutation, { data, loading, error }] = useCancelCardRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCardRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelCardRequestMutation, CancelCardRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCardRequestMutation, CancelCardRequestMutationVariables>(CancelCardRequestDocument, options);
      }
export type CancelCardRequestMutationHookResult = ReturnType<typeof useCancelCardRequestMutation>;
export type CancelCardRequestMutationResult = Apollo.MutationResult<CancelCardRequestMutation>;
export type CancelCardRequestMutationOptions = Apollo.BaseMutationOptions<CancelCardRequestMutation, CancelCardRequestMutationVariables>;
export const RequestCardLimitChangeDocument = gql`
    mutation requestCardLimitChange($input: RequestCardLimitChangeInput!) {
  requestCardLimitChange(input: $input)
}
    `;
export type RequestCardLimitChangeMutationFn = Apollo.MutationFunction<RequestCardLimitChangeMutation, RequestCardLimitChangeMutationVariables>;

/**
 * __useRequestCardLimitChangeMutation__
 *
 * To run a mutation, you first call `useRequestCardLimitChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCardLimitChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCardLimitChangeMutation, { data, loading, error }] = useRequestCardLimitChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCardLimitChangeMutation(baseOptions?: Apollo.MutationHookOptions<RequestCardLimitChangeMutation, RequestCardLimitChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestCardLimitChangeMutation, RequestCardLimitChangeMutationVariables>(RequestCardLimitChangeDocument, options);
      }
export type RequestCardLimitChangeMutationHookResult = ReturnType<typeof useRequestCardLimitChangeMutation>;
export type RequestCardLimitChangeMutationResult = Apollo.MutationResult<RequestCardLimitChangeMutation>;
export type RequestCardLimitChangeMutationOptions = Apollo.BaseMutationOptions<RequestCardLimitChangeMutation, RequestCardLimitChangeMutationVariables>;
export const ChangeCardLimitDocument = gql`
    mutation changeCardLimit($input: ChangeCardLimitInput!) {
  changeCardLimit(input: $input)
}
    `;
export type ChangeCardLimitMutationFn = Apollo.MutationFunction<ChangeCardLimitMutation, ChangeCardLimitMutationVariables>;

/**
 * __useChangeCardLimitMutation__
 *
 * To run a mutation, you first call `useChangeCardLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCardLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCardLimitMutation, { data, loading, error }] = useChangeCardLimitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeCardLimitMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCardLimitMutation, ChangeCardLimitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCardLimitMutation, ChangeCardLimitMutationVariables>(ChangeCardLimitDocument, options);
      }
export type ChangeCardLimitMutationHookResult = ReturnType<typeof useChangeCardLimitMutation>;
export type ChangeCardLimitMutationResult = Apollo.MutationResult<ChangeCardLimitMutation>;
export type ChangeCardLimitMutationOptions = Apollo.BaseMutationOptions<ChangeCardLimitMutation, ChangeCardLimitMutationVariables>;
export const ApproveCardLimitChangeRequestDocument = gql`
    mutation approveCardLimitChangeRequest($input: ApproveCardLimitChangeRequestInput!) {
  approveCardLimitChangeRequest(input: $input)
}
    `;
export type ApproveCardLimitChangeRequestMutationFn = Apollo.MutationFunction<ApproveCardLimitChangeRequestMutation, ApproveCardLimitChangeRequestMutationVariables>;

/**
 * __useApproveCardLimitChangeRequestMutation__
 *
 * To run a mutation, you first call `useApproveCardLimitChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCardLimitChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCardLimitChangeRequestMutation, { data, loading, error }] = useApproveCardLimitChangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveCardLimitChangeRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveCardLimitChangeRequestMutation, ApproveCardLimitChangeRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveCardLimitChangeRequestMutation, ApproveCardLimitChangeRequestMutationVariables>(ApproveCardLimitChangeRequestDocument, options);
      }
export type ApproveCardLimitChangeRequestMutationHookResult = ReturnType<typeof useApproveCardLimitChangeRequestMutation>;
export type ApproveCardLimitChangeRequestMutationResult = Apollo.MutationResult<ApproveCardLimitChangeRequestMutation>;
export type ApproveCardLimitChangeRequestMutationOptions = Apollo.BaseMutationOptions<ApproveCardLimitChangeRequestMutation, ApproveCardLimitChangeRequestMutationVariables>;
export const UpdateCardAccountingDataDocument = gql`
    mutation updateCardAccountingData($input: UpdateCardAccountingDataInput!) {
  updateCardAccountingData(input: $input)
}
    `;
export type UpdateCardAccountingDataMutationFn = Apollo.MutationFunction<UpdateCardAccountingDataMutation, UpdateCardAccountingDataMutationVariables>;

/**
 * __useUpdateCardAccountingDataMutation__
 *
 * To run a mutation, you first call `useUpdateCardAccountingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardAccountingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardAccountingDataMutation, { data, loading, error }] = useUpdateCardAccountingDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardAccountingDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardAccountingDataMutation, UpdateCardAccountingDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardAccountingDataMutation, UpdateCardAccountingDataMutationVariables>(UpdateCardAccountingDataDocument, options);
      }
export type UpdateCardAccountingDataMutationHookResult = ReturnType<typeof useUpdateCardAccountingDataMutation>;
export type UpdateCardAccountingDataMutationResult = Apollo.MutationResult<UpdateCardAccountingDataMutation>;
export type UpdateCardAccountingDataMutationOptions = Apollo.BaseMutationOptions<UpdateCardAccountingDataMutation, UpdateCardAccountingDataMutationVariables>;
export const ReplaceCardDocument = gql`
    mutation replaceCard($input: CardIssuerReplaceCardInput!) {
  replaceCard(input: $input) {
    id
    refNum
  }
}
    `;
export type ReplaceCardMutationFn = Apollo.MutationFunction<ReplaceCardMutation, ReplaceCardMutationVariables>;

/**
 * __useReplaceCardMutation__
 *
 * To run a mutation, you first call `useReplaceCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceCardMutation, { data, loading, error }] = useReplaceCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceCardMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceCardMutation, ReplaceCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceCardMutation, ReplaceCardMutationVariables>(ReplaceCardDocument, options);
      }
export type ReplaceCardMutationHookResult = ReturnType<typeof useReplaceCardMutation>;
export type ReplaceCardMutationResult = Apollo.MutationResult<ReplaceCardMutation>;
export type ReplaceCardMutationOptions = Apollo.BaseMutationOptions<ReplaceCardMutation, ReplaceCardMutationVariables>;
export const UpdateCardSettingsDataDocument = gql`
    mutation updateCardSettingsData($input: UpdateCardSettingsDataInput!) {
  updateCardSettingsData(input: $input)
}
    `;
export type UpdateCardSettingsDataMutationFn = Apollo.MutationFunction<UpdateCardSettingsDataMutation, UpdateCardSettingsDataMutationVariables>;

/**
 * __useUpdateCardSettingsDataMutation__
 *
 * To run a mutation, you first call `useUpdateCardSettingsDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardSettingsDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardSettingsDataMutation, { data, loading, error }] = useUpdateCardSettingsDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardSettingsDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardSettingsDataMutation, UpdateCardSettingsDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardSettingsDataMutation, UpdateCardSettingsDataMutationVariables>(UpdateCardSettingsDataDocument, options);
      }
export type UpdateCardSettingsDataMutationHookResult = ReturnType<typeof useUpdateCardSettingsDataMutation>;
export type UpdateCardSettingsDataMutationResult = Apollo.MutationResult<UpdateCardSettingsDataMutation>;
export type UpdateCardSettingsDataMutationOptions = Apollo.BaseMutationOptions<UpdateCardSettingsDataMutation, UpdateCardSettingsDataMutationVariables>;
export const GetCardIssuerCardByIdDocument = gql`
    query getCardIssuerCardById($id: ID!) {
  getCardIssuerCardById(id: $id) {
    ...creditCardByIdData
  }
}
    ${CreditCardByIdDataFragmentDoc}
${VirtualCardRequestDataFragmentDoc}
${SingleUseVirtualCardRequestDataFragmentDoc}
${PhysicalCardRequestDataFragmentDoc}
${CardLimitChangeRequestDataFragmentDoc}`;

/**
 * __useGetCardIssuerCardByIdQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerCardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerCardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerCardByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardIssuerCardByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerCardByIdQuery, GetCardIssuerCardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerCardByIdQuery, GetCardIssuerCardByIdQueryVariables>(GetCardIssuerCardByIdDocument, options);
      }
export function useGetCardIssuerCardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerCardByIdQuery, GetCardIssuerCardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerCardByIdQuery, GetCardIssuerCardByIdQueryVariables>(GetCardIssuerCardByIdDocument, options);
        }
export type GetCardIssuerCardByIdQueryHookResult = ReturnType<typeof useGetCardIssuerCardByIdQuery>;
export type GetCardIssuerCardByIdLazyQueryHookResult = ReturnType<typeof useGetCardIssuerCardByIdLazyQuery>;
export type GetCardIssuerCardByIdQueryResult = Apollo.QueryResult<GetCardIssuerCardByIdQuery, GetCardIssuerCardByIdQueryVariables>;
export const UpdateCardLabelDocument = gql`
    mutation updateCardLabel($input: UpdateCardLabelInput!) {
  updateCardLabel(input: $input)
}
    `;
export type UpdateCardLabelMutationFn = Apollo.MutationFunction<UpdateCardLabelMutation, UpdateCardLabelMutationVariables>;

/**
 * __useUpdateCardLabelMutation__
 *
 * To run a mutation, you first call `useUpdateCardLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardLabelMutation, { data, loading, error }] = useUpdateCardLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardLabelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardLabelMutation, UpdateCardLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardLabelMutation, UpdateCardLabelMutationVariables>(UpdateCardLabelDocument, options);
      }
export type UpdateCardLabelMutationHookResult = ReturnType<typeof useUpdateCardLabelMutation>;
export type UpdateCardLabelMutationResult = Apollo.MutationResult<UpdateCardLabelMutation>;
export type UpdateCardLabelMutationOptions = Apollo.BaseMutationOptions<UpdateCardLabelMutation, UpdateCardLabelMutationVariables>;
export const TerminateCardDocument = gql`
    mutation terminateCard($input: CardIssuerTerminateCardInput!) {
  terminateCard(input: $input)
}
    `;
export type TerminateCardMutationFn = Apollo.MutationFunction<TerminateCardMutation, TerminateCardMutationVariables>;

/**
 * __useTerminateCardMutation__
 *
 * To run a mutation, you first call `useTerminateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCardMutation, { data, loading, error }] = useTerminateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateCardMutation(baseOptions?: Apollo.MutationHookOptions<TerminateCardMutation, TerminateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateCardMutation, TerminateCardMutationVariables>(TerminateCardDocument, options);
      }
export type TerminateCardMutationHookResult = ReturnType<typeof useTerminateCardMutation>;
export type TerminateCardMutationResult = Apollo.MutationResult<TerminateCardMutation>;
export type TerminateCardMutationOptions = Apollo.BaseMutationOptions<TerminateCardMutation, TerminateCardMutationVariables>;
export const TerminateProcessingCardDocument = gql`
    mutation terminateProcessingCard($input: CardIssuerCancelParkedCardInput!) {
  cancelParkedCard(input: $input)
}
    `;
export type TerminateProcessingCardMutationFn = Apollo.MutationFunction<TerminateProcessingCardMutation, TerminateProcessingCardMutationVariables>;

/**
 * __useTerminateProcessingCardMutation__
 *
 * To run a mutation, you first call `useTerminateProcessingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateProcessingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateProcessingCardMutation, { data, loading, error }] = useTerminateProcessingCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateProcessingCardMutation(baseOptions?: Apollo.MutationHookOptions<TerminateProcessingCardMutation, TerminateProcessingCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateProcessingCardMutation, TerminateProcessingCardMutationVariables>(TerminateProcessingCardDocument, options);
      }
export type TerminateProcessingCardMutationHookResult = ReturnType<typeof useTerminateProcessingCardMutation>;
export type TerminateProcessingCardMutationResult = Apollo.MutationResult<TerminateProcessingCardMutation>;
export type TerminateProcessingCardMutationOptions = Apollo.BaseMutationOptions<TerminateProcessingCardMutation, TerminateProcessingCardMutationVariables>;
export const UpdateCardPurposeDocument = gql`
    mutation updateCardPurpose($input: UpdateCardPurposeInput!) {
  updateCardPurpose(input: $input)
}
    `;
export type UpdateCardPurposeMutationFn = Apollo.MutationFunction<UpdateCardPurposeMutation, UpdateCardPurposeMutationVariables>;

/**
 * __useUpdateCardPurposeMutation__
 *
 * To run a mutation, you first call `useUpdateCardPurposeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardPurposeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardPurposeMutation, { data, loading, error }] = useUpdateCardPurposeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardPurposeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardPurposeMutation, UpdateCardPurposeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardPurposeMutation, UpdateCardPurposeMutationVariables>(UpdateCardPurposeDocument, options);
      }
export type UpdateCardPurposeMutationHookResult = ReturnType<typeof useUpdateCardPurposeMutation>;
export type UpdateCardPurposeMutationResult = Apollo.MutationResult<UpdateCardPurposeMutation>;
export type UpdateCardPurposeMutationOptions = Apollo.BaseMutationOptions<UpdateCardPurposeMutation, UpdateCardPurposeMutationVariables>;
export const DocumentRelationshipsDocument = gql`
    query documentRelationships($input: GetDocumentRelationshipInput!) {
  documentRelationships(input: $input) {
    relationships {
      id
      linkedDocument {
        ...RelatedDocument
      }
    }
  }
}
    ${RelatedDocumentFragmentDoc}
${AggregatedEcmDocumentFragmentDoc}
${DocumentFragmentDoc}`;

/**
 * __useDocumentRelationshipsQuery__
 *
 * To run a query within a React component, call `useDocumentRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentRelationshipsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentRelationshipsQuery(baseOptions: Apollo.QueryHookOptions<DocumentRelationshipsQuery, DocumentRelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentRelationshipsQuery, DocumentRelationshipsQueryVariables>(DocumentRelationshipsDocument, options);
      }
export function useDocumentRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentRelationshipsQuery, DocumentRelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentRelationshipsQuery, DocumentRelationshipsQueryVariables>(DocumentRelationshipsDocument, options);
        }
export type DocumentRelationshipsQueryHookResult = ReturnType<typeof useDocumentRelationshipsQuery>;
export type DocumentRelationshipsLazyQueryHookResult = ReturnType<typeof useDocumentRelationshipsLazyQuery>;
export type DocumentRelationshipsQueryResult = Apollo.QueryResult<DocumentRelationshipsQuery, DocumentRelationshipsQueryVariables>;
export const DocumentRelationshipsFilesDocument = gql`
    query documentRelationshipsFiles($input: GetDocumentRelationshipInput!) {
  documentRelationships(input: $input) {
    relationships {
      linkedDocument {
        ... on RelatedDocument {
          document {
            __typename
            ... on AggregatedEcmDocument {
              fileSource {
                name
                url
                id
              }
            }
            ... on Document {
              documentFile {
                name
                url
                id
              }
              attachments {
                name
                url
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDocumentRelationshipsFilesQuery__
 *
 * To run a query within a React component, call `useDocumentRelationshipsFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentRelationshipsFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentRelationshipsFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentRelationshipsFilesQuery(baseOptions: Apollo.QueryHookOptions<DocumentRelationshipsFilesQuery, DocumentRelationshipsFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentRelationshipsFilesQuery, DocumentRelationshipsFilesQueryVariables>(DocumentRelationshipsFilesDocument, options);
      }
export function useDocumentRelationshipsFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentRelationshipsFilesQuery, DocumentRelationshipsFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentRelationshipsFilesQuery, DocumentRelationshipsFilesQueryVariables>(DocumentRelationshipsFilesDocument, options);
        }
export type DocumentRelationshipsFilesQueryHookResult = ReturnType<typeof useDocumentRelationshipsFilesQuery>;
export type DocumentRelationshipsFilesLazyQueryHookResult = ReturnType<typeof useDocumentRelationshipsFilesLazyQuery>;
export type DocumentRelationshipsFilesQueryResult = Apollo.QueryResult<DocumentRelationshipsFilesQuery, DocumentRelationshipsFilesQueryVariables>;
export const DeleteDocumentRelationshipDocument = gql`
    mutation deleteDocumentRelationship($relationshipId: String!) {
  deleteDocumentRelationship(relationshipId: $relationshipId) {
    id
  }
}
    `;
export type DeleteDocumentRelationshipMutationFn = Apollo.MutationFunction<DeleteDocumentRelationshipMutation, DeleteDocumentRelationshipMutationVariables>;

/**
 * __useDeleteDocumentRelationshipMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentRelationshipMutation, { data, loading, error }] = useDeleteDocumentRelationshipMutation({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *   },
 * });
 */
export function useDeleteDocumentRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentRelationshipMutation, DeleteDocumentRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentRelationshipMutation, DeleteDocumentRelationshipMutationVariables>(DeleteDocumentRelationshipDocument, options);
      }
export type DeleteDocumentRelationshipMutationHookResult = ReturnType<typeof useDeleteDocumentRelationshipMutation>;
export type DeleteDocumentRelationshipMutationResult = Apollo.MutationResult<DeleteDocumentRelationshipMutation>;
export type DeleteDocumentRelationshipMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentRelationshipMutation, DeleteDocumentRelationshipMutationVariables>;
export const CreateDocumentRelationshipsDocument = gql`
    mutation createDocumentRelationships($input: CreateDocumentRelationshipsInput!) {
  createDocumentRelationships(input: $input) {
    __typename
  }
}
    `;
export type CreateDocumentRelationshipsMutationFn = Apollo.MutationFunction<CreateDocumentRelationshipsMutation, CreateDocumentRelationshipsMutationVariables>;

/**
 * __useCreateDocumentRelationshipsMutation__
 *
 * To run a mutation, you first call `useCreateDocumentRelationshipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentRelationshipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentRelationshipsMutation, { data, loading, error }] = useCreateDocumentRelationshipsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentRelationshipsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentRelationshipsMutation, CreateDocumentRelationshipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentRelationshipsMutation, CreateDocumentRelationshipsMutationVariables>(CreateDocumentRelationshipsDocument, options);
      }
export type CreateDocumentRelationshipsMutationHookResult = ReturnType<typeof useCreateDocumentRelationshipsMutation>;
export type CreateDocumentRelationshipsMutationResult = Apollo.MutationResult<CreateDocumentRelationshipsMutation>;
export type CreateDocumentRelationshipsMutationOptions = Apollo.BaseMutationOptions<CreateDocumentRelationshipsMutation, CreateDocumentRelationshipsMutationVariables>;
export const InAppNotificationDocument = gql`
    subscription inAppNotification {
  inAppNotification {
    __typename
    id
    isRead
    notificationCreatedAt
    organizationId
    ...InAppNotificationData
    ...CardRequestApprovedInAppNotificationData
    ...CardRequestCreatedInAppNotificationData
    ...CardRequestRejectedInAppNotificationData
    ...DocumentCommentCreatedInAppNotificationData
    ...DocumentRejectedInAppNotificationData
    ...TransactionCommentCreatedInAppNotificationData
    ...TransactionDeclinedInAppNotificationData
    ...ReimbursementCaseRejectedInAppNotificationData
    ...ReimbursementCaseItemExcludedInAppNotificationData
    ...ReimbursementCaseAmendmentRequestedInAppNotificationData
    ...ReimbursementCaseApprovedInAppNotificationData
    ...ReimbursementCaseCommentCreatedInAppNotificationData
  }
}
    ${InAppNotificationDataFragmentDoc}
${CardRequestApprovedInAppNotificationDataFragmentDoc}
${CardRequestCreatedInAppNotificationDataFragmentDoc}
${CardRequestRejectedInAppNotificationDataFragmentDoc}
${DocumentCommentCreatedInAppNotificationDataFragmentDoc}
${DocumentRejectedInAppNotificationDataFragmentDoc}
${TransactionCommentCreatedInAppNotificationDataFragmentDoc}
${TransactionDeclinedInAppNotificationDataFragmentDoc}
${ReimbursementCaseRejectedInAppNotificationDataFragmentDoc}
${ReimbursementCaseItemExcludedInAppNotificationDataFragmentDoc}
${ReimbursementCaseAmendmentRequestedInAppNotificationDataFragmentDoc}
${ReimbursementCaseApprovedInAppNotificationDataFragmentDoc}
${ReimbursementCaseCommentCreatedInAppNotificationDataFragmentDoc}`;

/**
 * __useInAppNotificationSubscription__
 *
 * To run a query within a React component, call `useInAppNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInAppNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInAppNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInAppNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<InAppNotificationSubscription, InAppNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InAppNotificationSubscription, InAppNotificationSubscriptionVariables>(InAppNotificationDocument, options);
      }
export type InAppNotificationSubscriptionHookResult = ReturnType<typeof useInAppNotificationSubscription>;
export type InAppNotificationSubscriptionResult = Apollo.SubscriptionResult<InAppNotificationSubscription>;
export const InAppNotificationsPageBasedDocument = gql`
    query inAppNotificationsPageBased($input: InAppNotificationPaginationInput, $filters: InAppNotificationFilterInput, $dateRangeFilters: InAppNotificationDateFilterInput, $sortBy: InAppNotificationSortInput) {
  inAppNotifications(
    input: $input
    filters: $filters
    dateRangeFilters: $dateRangeFilters
    sortBy: $sortBy
  ) @connection(key: "inAppNotificationsPageBased", filter: ["filters", "dateRangeFilters", "sortBy"]) {
    records {
      __typename
      id
      isRead
      notificationCreatedAt
      organizationId
      ...InAppNotificationData
      ...CardRequestApprovedInAppNotificationData
      ...CardRequestCreatedInAppNotificationData
      ...CardRequestRejectedInAppNotificationData
      ...DocumentCommentCreatedInAppNotificationData
      ...DocumentRejectedInAppNotificationData
      ...TransactionCommentCreatedInAppNotificationData
      ...TransactionDeclinedInAppNotificationData
      ...ReimbursementCaseRejectedInAppNotificationData
      ...ReimbursementCaseItemExcludedInAppNotificationData
      ...ReimbursementCaseAmendmentRequestedInAppNotificationData
      ...ReimbursementCaseApprovedInAppNotificationData
      ...ReimbursementCaseCommentCreatedInAppNotificationData
    }
    pageInfo {
      __typename
      totalCount
      currentPage
      pageCount
      pageSize
    }
  }
}
    ${InAppNotificationDataFragmentDoc}
${CardRequestApprovedInAppNotificationDataFragmentDoc}
${CardRequestCreatedInAppNotificationDataFragmentDoc}
${CardRequestRejectedInAppNotificationDataFragmentDoc}
${DocumentCommentCreatedInAppNotificationDataFragmentDoc}
${DocumentRejectedInAppNotificationDataFragmentDoc}
${TransactionCommentCreatedInAppNotificationDataFragmentDoc}
${TransactionDeclinedInAppNotificationDataFragmentDoc}
${ReimbursementCaseRejectedInAppNotificationDataFragmentDoc}
${ReimbursementCaseItemExcludedInAppNotificationDataFragmentDoc}
${ReimbursementCaseAmendmentRequestedInAppNotificationDataFragmentDoc}
${ReimbursementCaseApprovedInAppNotificationDataFragmentDoc}
${ReimbursementCaseCommentCreatedInAppNotificationDataFragmentDoc}`;

/**
 * __useInAppNotificationsPageBasedQuery__
 *
 * To run a query within a React component, call `useInAppNotificationsPageBasedQuery` and pass it any options that fit your needs.
 * When your component renders, `useInAppNotificationsPageBasedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInAppNotificationsPageBasedQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useInAppNotificationsPageBasedQuery(baseOptions?: Apollo.QueryHookOptions<InAppNotificationsPageBasedQuery, InAppNotificationsPageBasedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InAppNotificationsPageBasedQuery, InAppNotificationsPageBasedQueryVariables>(InAppNotificationsPageBasedDocument, options);
      }
export function useInAppNotificationsPageBasedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InAppNotificationsPageBasedQuery, InAppNotificationsPageBasedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InAppNotificationsPageBasedQuery, InAppNotificationsPageBasedQueryVariables>(InAppNotificationsPageBasedDocument, options);
        }
export type InAppNotificationsPageBasedQueryHookResult = ReturnType<typeof useInAppNotificationsPageBasedQuery>;
export type InAppNotificationsPageBasedLazyQueryHookResult = ReturnType<typeof useInAppNotificationsPageBasedLazyQuery>;
export type InAppNotificationsPageBasedQueryResult = Apollo.QueryResult<InAppNotificationsPageBasedQuery, InAppNotificationsPageBasedQueryVariables>;
export const OrganizationsByMembershipDocument = gql`
    query organizationsByMembership($membershipId: ID!) {
  organizationsByMembership(membershipId: $membershipId) {
    id
    name
    slug
  }
}
    `;

/**
 * __useOrganizationsByMembershipQuery__
 *
 * To run a query within a React component, call `useOrganizationsByMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsByMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsByMembershipQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useOrganizationsByMembershipQuery(baseOptions: Apollo.QueryHookOptions<OrganizationsByMembershipQuery, OrganizationsByMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsByMembershipQuery, OrganizationsByMembershipQueryVariables>(OrganizationsByMembershipDocument, options);
      }
export function useOrganizationsByMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsByMembershipQuery, OrganizationsByMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsByMembershipQuery, OrganizationsByMembershipQueryVariables>(OrganizationsByMembershipDocument, options);
        }
export type OrganizationsByMembershipQueryHookResult = ReturnType<typeof useOrganizationsByMembershipQuery>;
export type OrganizationsByMembershipLazyQueryHookResult = ReturnType<typeof useOrganizationsByMembershipLazyQuery>;
export type OrganizationsByMembershipQueryResult = Apollo.QueryResult<OrganizationsByMembershipQuery, OrganizationsByMembershipQueryVariables>;
export const InAppNotificationsCountDocument = gql`
    query inAppNotificationsCount($filters: InAppNotificationFilterInput, $dateRangeFilters: InAppNotificationDateFilterInput) {
  inAppNotificationsCount(filters: $filters, dateRangeFilters: $dateRangeFilters)
}
    `;

/**
 * __useInAppNotificationsCountQuery__
 *
 * To run a query within a React component, call `useInAppNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInAppNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInAppNotificationsCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useInAppNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<InAppNotificationsCountQuery, InAppNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InAppNotificationsCountQuery, InAppNotificationsCountQueryVariables>(InAppNotificationsCountDocument, options);
      }
export function useInAppNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InAppNotificationsCountQuery, InAppNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InAppNotificationsCountQuery, InAppNotificationsCountQueryVariables>(InAppNotificationsCountDocument, options);
        }
export type InAppNotificationsCountQueryHookResult = ReturnType<typeof useInAppNotificationsCountQuery>;
export type InAppNotificationsCountLazyQueryHookResult = ReturnType<typeof useInAppNotificationsCountLazyQuery>;
export type InAppNotificationsCountQueryResult = Apollo.QueryResult<InAppNotificationsCountQuery, InAppNotificationsCountQueryVariables>;
export const ToggleInAppNotificationsDocument = gql`
    mutation toggleInAppNotifications($input: ToggleInAppNotificationsInput!, $filters: InAppNotificationFilterInput, $dateRangeFilters: InAppNotificationDateFilterInput) {
  toggleInAppNotifications(
    input: $input
    filters: $filters
    dateRangeFilters: $dateRangeFilters
  )
}
    `;
export type ToggleInAppNotificationsMutationFn = Apollo.MutationFunction<ToggleInAppNotificationsMutation, ToggleInAppNotificationsMutationVariables>;

/**
 * __useToggleInAppNotificationsMutation__
 *
 * To run a mutation, you first call `useToggleInAppNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleInAppNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleInAppNotificationsMutation, { data, loading, error }] = useToggleInAppNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useToggleInAppNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleInAppNotificationsMutation, ToggleInAppNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleInAppNotificationsMutation, ToggleInAppNotificationsMutationVariables>(ToggleInAppNotificationsDocument, options);
      }
export type ToggleInAppNotificationsMutationHookResult = ReturnType<typeof useToggleInAppNotificationsMutation>;
export type ToggleInAppNotificationsMutationResult = Apollo.MutationResult<ToggleInAppNotificationsMutation>;
export type ToggleInAppNotificationsMutationOptions = Apollo.BaseMutationOptions<ToggleInAppNotificationsMutation, ToggleInAppNotificationsMutationVariables>;
export const DocumentWorkflowStatusDocument = gql`
    query documentWorkflowStatus($id: ID!) {
  getDocument(id: $id) {
    ...DocumentWorkflowStatusData
  }
}
    ${DocumentWorkflowStatusDataFragmentDoc}`;

/**
 * __useDocumentWorkflowStatusQuery__
 *
 * To run a query within a React component, call `useDocumentWorkflowStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentWorkflowStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentWorkflowStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentWorkflowStatusQuery(baseOptions: Apollo.QueryHookOptions<DocumentWorkflowStatusQuery, DocumentWorkflowStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentWorkflowStatusQuery, DocumentWorkflowStatusQueryVariables>(DocumentWorkflowStatusDocument, options);
      }
export function useDocumentWorkflowStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentWorkflowStatusQuery, DocumentWorkflowStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentWorkflowStatusQuery, DocumentWorkflowStatusQueryVariables>(DocumentWorkflowStatusDocument, options);
        }
export type DocumentWorkflowStatusQueryHookResult = ReturnType<typeof useDocumentWorkflowStatusQuery>;
export type DocumentWorkflowStatusLazyQueryHookResult = ReturnType<typeof useDocumentWorkflowStatusLazyQuery>;
export type DocumentWorkflowStatusQueryResult = Apollo.QueryResult<DocumentWorkflowStatusQuery, DocumentWorkflowStatusQueryVariables>;
export const GetCountExportableEntitiesDocument = gql`
    query getCountExportableEntities {
  getCountExportableEntities {
    documentReadyForExportCount {
      count
    }
    transactionReadyForExportCount {
      count
    }
    pendingTransactionCount {
      count
    }
    settlementsReadyForExportCount {
      count
    }
    settlementsNotReadyForExportCount {
      count
    }
    provisionsReadyForExportCount {
      count
    }
    reversalsReadyForExportCount {
      count
    }
    reimbursementItemsReadyForExportCount {
      count
    }
  }
}
    `;

/**
 * __useGetCountExportableEntitiesQuery__
 *
 * To run a query within a React component, call `useGetCountExportableEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountExportableEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountExportableEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountExportableEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountExportableEntitiesQuery, GetCountExportableEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountExportableEntitiesQuery, GetCountExportableEntitiesQueryVariables>(GetCountExportableEntitiesDocument, options);
      }
export function useGetCountExportableEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountExportableEntitiesQuery, GetCountExportableEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountExportableEntitiesQuery, GetCountExportableEntitiesQueryVariables>(GetCountExportableEntitiesDocument, options);
        }
export type GetCountExportableEntitiesQueryHookResult = ReturnType<typeof useGetCountExportableEntitiesQuery>;
export type GetCountExportableEntitiesLazyQueryHookResult = ReturnType<typeof useGetCountExportableEntitiesLazyQuery>;
export type GetCountExportableEntitiesQueryResult = Apollo.QueryResult<GetCountExportableEntitiesQuery, GetCountExportableEntitiesQueryVariables>;
export const CountDocumentsByStatusNewDocument = gql`
    query countDocumentsByStatusNew {
  countDocumentsByStatus(status: NEW) {
    count
  }
}
    `;

/**
 * __useCountDocumentsByStatusNewQuery__
 *
 * To run a query within a React component, call `useCountDocumentsByStatusNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountDocumentsByStatusNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountDocumentsByStatusNewQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountDocumentsByStatusNewQuery(baseOptions?: Apollo.QueryHookOptions<CountDocumentsByStatusNewQuery, CountDocumentsByStatusNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountDocumentsByStatusNewQuery, CountDocumentsByStatusNewQueryVariables>(CountDocumentsByStatusNewDocument, options);
      }
export function useCountDocumentsByStatusNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountDocumentsByStatusNewQuery, CountDocumentsByStatusNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountDocumentsByStatusNewQuery, CountDocumentsByStatusNewQueryVariables>(CountDocumentsByStatusNewDocument, options);
        }
export type CountDocumentsByStatusNewQueryHookResult = ReturnType<typeof useCountDocumentsByStatusNewQuery>;
export type CountDocumentsByStatusNewLazyQueryHookResult = ReturnType<typeof useCountDocumentsByStatusNewLazyQuery>;
export type CountDocumentsByStatusNewQueryResult = Apollo.QueryResult<CountDocumentsByStatusNewQuery, CountDocumentsByStatusNewQueryVariables>;
export const CountDocumentsByStatusRejectedForSidebarDocument = gql`
    query countDocumentsByStatusRejectedForSidebar {
  countDocumentsByStatus(status: REJECTED) {
    count
  }
}
    `;

/**
 * __useCountDocumentsByStatusRejectedForSidebarQuery__
 *
 * To run a query within a React component, call `useCountDocumentsByStatusRejectedForSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountDocumentsByStatusRejectedForSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountDocumentsByStatusRejectedForSidebarQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountDocumentsByStatusRejectedForSidebarQuery(baseOptions?: Apollo.QueryHookOptions<CountDocumentsByStatusRejectedForSidebarQuery, CountDocumentsByStatusRejectedForSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountDocumentsByStatusRejectedForSidebarQuery, CountDocumentsByStatusRejectedForSidebarQueryVariables>(CountDocumentsByStatusRejectedForSidebarDocument, options);
      }
export function useCountDocumentsByStatusRejectedForSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountDocumentsByStatusRejectedForSidebarQuery, CountDocumentsByStatusRejectedForSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountDocumentsByStatusRejectedForSidebarQuery, CountDocumentsByStatusRejectedForSidebarQueryVariables>(CountDocumentsByStatusRejectedForSidebarDocument, options);
        }
export type CountDocumentsByStatusRejectedForSidebarQueryHookResult = ReturnType<typeof useCountDocumentsByStatusRejectedForSidebarQuery>;
export type CountDocumentsByStatusRejectedForSidebarLazyQueryHookResult = ReturnType<typeof useCountDocumentsByStatusRejectedForSidebarLazyQuery>;
export type CountDocumentsByStatusRejectedForSidebarQueryResult = Apollo.QueryResult<CountDocumentsByStatusRejectedForSidebarQuery, CountDocumentsByStatusRejectedForSidebarQueryVariables>;
export const CountDocumentsByStatusApprovedForApprovalBlockDocument = gql`
    query countDocumentsByStatusApprovedForApprovalBlock {
  countDocumentsByStatus(status: APPROVED) {
    count
  }
}
    `;

/**
 * __useCountDocumentsByStatusApprovedForApprovalBlockQuery__
 *
 * To run a query within a React component, call `useCountDocumentsByStatusApprovedForApprovalBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountDocumentsByStatusApprovedForApprovalBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountDocumentsByStatusApprovedForApprovalBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountDocumentsByStatusApprovedForApprovalBlockQuery(baseOptions?: Apollo.QueryHookOptions<CountDocumentsByStatusApprovedForApprovalBlockQuery, CountDocumentsByStatusApprovedForApprovalBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountDocumentsByStatusApprovedForApprovalBlockQuery, CountDocumentsByStatusApprovedForApprovalBlockQueryVariables>(CountDocumentsByStatusApprovedForApprovalBlockDocument, options);
      }
export function useCountDocumentsByStatusApprovedForApprovalBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountDocumentsByStatusApprovedForApprovalBlockQuery, CountDocumentsByStatusApprovedForApprovalBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountDocumentsByStatusApprovedForApprovalBlockQuery, CountDocumentsByStatusApprovedForApprovalBlockQueryVariables>(CountDocumentsByStatusApprovedForApprovalBlockDocument, options);
        }
export type CountDocumentsByStatusApprovedForApprovalBlockQueryHookResult = ReturnType<typeof useCountDocumentsByStatusApprovedForApprovalBlockQuery>;
export type CountDocumentsByStatusApprovedForApprovalBlockLazyQueryHookResult = ReturnType<typeof useCountDocumentsByStatusApprovedForApprovalBlockLazyQuery>;
export type CountDocumentsByStatusApprovedForApprovalBlockQueryResult = Apollo.QueryResult<CountDocumentsByStatusApprovedForApprovalBlockQuery, CountDocumentsByStatusApprovedForApprovalBlockQueryVariables>;
export const CountDocumentsIRejectedDocument = gql`
    query countDocumentsIRejected {
  countDocumentsIRejected {
    count
  }
}
    `;

/**
 * __useCountDocumentsIRejectedQuery__
 *
 * To run a query within a React component, call `useCountDocumentsIRejectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountDocumentsIRejectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountDocumentsIRejectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountDocumentsIRejectedQuery(baseOptions?: Apollo.QueryHookOptions<CountDocumentsIRejectedQuery, CountDocumentsIRejectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountDocumentsIRejectedQuery, CountDocumentsIRejectedQueryVariables>(CountDocumentsIRejectedDocument, options);
      }
export function useCountDocumentsIRejectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountDocumentsIRejectedQuery, CountDocumentsIRejectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountDocumentsIRejectedQuery, CountDocumentsIRejectedQueryVariables>(CountDocumentsIRejectedDocument, options);
        }
export type CountDocumentsIRejectedQueryHookResult = ReturnType<typeof useCountDocumentsIRejectedQuery>;
export type CountDocumentsIRejectedLazyQueryHookResult = ReturnType<typeof useCountDocumentsIRejectedLazyQuery>;
export type CountDocumentsIRejectedQueryResult = Apollo.QueryResult<CountDocumentsIRejectedQuery, CountDocumentsIRejectedQueryVariables>;
export const CountOpenApprovalsDocument = gql`
    query countOpenApprovals {
  countOpenApprovals {
    count
  }
}
    `;

/**
 * __useCountOpenApprovalsQuery__
 *
 * To run a query within a React component, call `useCountOpenApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountOpenApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountOpenApprovalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountOpenApprovalsQuery(baseOptions?: Apollo.QueryHookOptions<CountOpenApprovalsQuery, CountOpenApprovalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountOpenApprovalsQuery, CountOpenApprovalsQueryVariables>(CountOpenApprovalsDocument, options);
      }
export function useCountOpenApprovalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountOpenApprovalsQuery, CountOpenApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountOpenApprovalsQuery, CountOpenApprovalsQueryVariables>(CountOpenApprovalsDocument, options);
        }
export type CountOpenApprovalsQueryHookResult = ReturnType<typeof useCountOpenApprovalsQuery>;
export type CountOpenApprovalsLazyQueryHookResult = ReturnType<typeof useCountOpenApprovalsLazyQuery>;
export type CountOpenApprovalsQueryResult = Apollo.QueryResult<CountOpenApprovalsQuery, CountOpenApprovalsQueryVariables>;
export const CountPayableDocumentsDocument = gql`
    query countPayableDocuments($hasIban: Boolean) {
  countPayableDocuments(hasIban: $hasIban) {
    count
  }
}
    `;

/**
 * __useCountPayableDocumentsQuery__
 *
 * To run a query within a React component, call `useCountPayableDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountPayableDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountPayableDocumentsQuery({
 *   variables: {
 *      hasIban: // value for 'hasIban'
 *   },
 * });
 */
export function useCountPayableDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<CountPayableDocumentsQuery, CountPayableDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountPayableDocumentsQuery, CountPayableDocumentsQueryVariables>(CountPayableDocumentsDocument, options);
      }
export function useCountPayableDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountPayableDocumentsQuery, CountPayableDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountPayableDocumentsQuery, CountPayableDocumentsQueryVariables>(CountPayableDocumentsDocument, options);
        }
export type CountPayableDocumentsQueryHookResult = ReturnType<typeof useCountPayableDocumentsQuery>;
export type CountPayableDocumentsLazyQueryHookResult = ReturnType<typeof useCountPayableDocumentsLazyQuery>;
export type CountPayableDocumentsQueryResult = Apollo.QueryResult<CountPayableDocumentsQuery, CountPayableDocumentsQueryVariables>;
export const CountCardIssuerTransactionsDocument = gql`
    query countCardIssuerTransactions($input: CardIssuerTransactionCountInput!, $filters: CardIssuerTransactionFilterInput) {
  countCardIssuerTransactions(input: $input, filters: $filters) {
    count
  }
}
    `;

/**
 * __useCountCardIssuerTransactionsQuery__
 *
 * To run a query within a React component, call `useCountCardIssuerTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCardIssuerTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCardIssuerTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCountCardIssuerTransactionsQuery(baseOptions: Apollo.QueryHookOptions<CountCardIssuerTransactionsQuery, CountCardIssuerTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCardIssuerTransactionsQuery, CountCardIssuerTransactionsQueryVariables>(CountCardIssuerTransactionsDocument, options);
      }
export function useCountCardIssuerTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCardIssuerTransactionsQuery, CountCardIssuerTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCardIssuerTransactionsQuery, CountCardIssuerTransactionsQueryVariables>(CountCardIssuerTransactionsDocument, options);
        }
export type CountCardIssuerTransactionsQueryHookResult = ReturnType<typeof useCountCardIssuerTransactionsQuery>;
export type CountCardIssuerTransactionsLazyQueryHookResult = ReturnType<typeof useCountCardIssuerTransactionsLazyQuery>;
export type CountCardIssuerTransactionsQueryResult = Apollo.QueryResult<CountCardIssuerTransactionsQuery, CountCardIssuerTransactionsQueryVariables>;
export const InboxDocumentNavigationDataDocument = gql`
    query inboxDocumentNavigationData($cursor: String, $query: String) {
  prevDocument: listInboxDocuments(limit: 1, before: $cursor, query: $query) {
    ...BasicDocumentConnectionData
  }
  nextDocument: listInboxDocuments(limit: 1, after: $cursor, query: $query) {
    ...BasicDocumentConnectionData
  }
}
    ${BasicDocumentConnectionDataFragmentDoc}`;

/**
 * __useInboxDocumentNavigationDataQuery__
 *
 * To run a query within a React component, call `useInboxDocumentNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxDocumentNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxDocumentNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useInboxDocumentNavigationDataQuery(baseOptions?: Apollo.QueryHookOptions<InboxDocumentNavigationDataQuery, InboxDocumentNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxDocumentNavigationDataQuery, InboxDocumentNavigationDataQueryVariables>(InboxDocumentNavigationDataDocument, options);
      }
export function useInboxDocumentNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxDocumentNavigationDataQuery, InboxDocumentNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxDocumentNavigationDataQuery, InboxDocumentNavigationDataQueryVariables>(InboxDocumentNavigationDataDocument, options);
        }
export type InboxDocumentNavigationDataQueryHookResult = ReturnType<typeof useInboxDocumentNavigationDataQuery>;
export type InboxDocumentNavigationDataLazyQueryHookResult = ReturnType<typeof useInboxDocumentNavigationDataLazyQuery>;
export type InboxDocumentNavigationDataQueryResult = Apollo.QueryResult<InboxDocumentNavigationDataQuery, InboxDocumentNavigationDataQueryVariables>;
export const InboxInvoiceDocumentsNavigationDataDocument = gql`
    query inboxInvoiceDocumentsNavigationData($cursor: String, $query: String) {
  prevDocument: inboxInvoiceDocuments(limit: 1, before: $cursor, query: $query) {
    ...BasicInboxInvoiceDocumentConnectionData
  }
  nextDocument: inboxInvoiceDocuments(limit: 1, after: $cursor, query: $query) {
    ...BasicInboxInvoiceDocumentConnectionData
  }
}
    ${BasicInboxInvoiceDocumentConnectionDataFragmentDoc}`;

/**
 * __useInboxInvoiceDocumentsNavigationDataQuery__
 *
 * To run a query within a React component, call `useInboxInvoiceDocumentsNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxInvoiceDocumentsNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxInvoiceDocumentsNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useInboxInvoiceDocumentsNavigationDataQuery(baseOptions?: Apollo.QueryHookOptions<InboxInvoiceDocumentsNavigationDataQuery, InboxInvoiceDocumentsNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxInvoiceDocumentsNavigationDataQuery, InboxInvoiceDocumentsNavigationDataQueryVariables>(InboxInvoiceDocumentsNavigationDataDocument, options);
      }
export function useInboxInvoiceDocumentsNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxInvoiceDocumentsNavigationDataQuery, InboxInvoiceDocumentsNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxInvoiceDocumentsNavigationDataQuery, InboxInvoiceDocumentsNavigationDataQueryVariables>(InboxInvoiceDocumentsNavigationDataDocument, options);
        }
export type InboxInvoiceDocumentsNavigationDataQueryHookResult = ReturnType<typeof useInboxInvoiceDocumentsNavigationDataQuery>;
export type InboxInvoiceDocumentsNavigationDataLazyQueryHookResult = ReturnType<typeof useInboxInvoiceDocumentsNavigationDataLazyQuery>;
export type InboxInvoiceDocumentsNavigationDataQueryResult = Apollo.QueryResult<InboxInvoiceDocumentsNavigationDataQuery, InboxInvoiceDocumentsNavigationDataQueryVariables>;
export const ApprovalsDocumentNavigationDataDocument = gql`
    query approvalsDocumentNavigationData($cursor: String, $query: String) {
  prevDocument: listApprovalsDocuments(limit: 1, before: $cursor, query: $query) {
    ...BasicDocumentConnectionData
  }
  nextDocument: listApprovalsDocuments(limit: 1, after: $cursor, query: $query) {
    ...BasicDocumentConnectionData
  }
}
    ${BasicDocumentConnectionDataFragmentDoc}`;

/**
 * __useApprovalsDocumentNavigationDataQuery__
 *
 * To run a query within a React component, call `useApprovalsDocumentNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalsDocumentNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalsDocumentNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useApprovalsDocumentNavigationDataQuery(baseOptions?: Apollo.QueryHookOptions<ApprovalsDocumentNavigationDataQuery, ApprovalsDocumentNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalsDocumentNavigationDataQuery, ApprovalsDocumentNavigationDataQueryVariables>(ApprovalsDocumentNavigationDataDocument, options);
      }
export function useApprovalsDocumentNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalsDocumentNavigationDataQuery, ApprovalsDocumentNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalsDocumentNavigationDataQuery, ApprovalsDocumentNavigationDataQueryVariables>(ApprovalsDocumentNavigationDataDocument, options);
        }
export type ApprovalsDocumentNavigationDataQueryHookResult = ReturnType<typeof useApprovalsDocumentNavigationDataQuery>;
export type ApprovalsDocumentNavigationDataLazyQueryHookResult = ReturnType<typeof useApprovalsDocumentNavigationDataLazyQuery>;
export type ApprovalsDocumentNavigationDataQueryResult = Apollo.QueryResult<ApprovalsDocumentNavigationDataQuery, ApprovalsDocumentNavigationDataQueryVariables>;
export const ApprovalsDocumentNewEsNavigationDataDocument = gql`
    query approvalsDocumentNewEsNavigationData($cursor: String, $query: String) {
  prevDocument: approvalInvoiceDocuments(limit: 1, before: $cursor, query: $query) {
    ...BasicApprovalDocumentConnectionData
  }
  nextDocument: approvalInvoiceDocuments(limit: 1, after: $cursor, query: $query) {
    ...BasicApprovalDocumentConnectionData
  }
}
    ${BasicApprovalDocumentConnectionDataFragmentDoc}`;

/**
 * __useApprovalsDocumentNewEsNavigationDataQuery__
 *
 * To run a query within a React component, call `useApprovalsDocumentNewEsNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalsDocumentNewEsNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalsDocumentNewEsNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useApprovalsDocumentNewEsNavigationDataQuery(baseOptions?: Apollo.QueryHookOptions<ApprovalsDocumentNewEsNavigationDataQuery, ApprovalsDocumentNewEsNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalsDocumentNewEsNavigationDataQuery, ApprovalsDocumentNewEsNavigationDataQueryVariables>(ApprovalsDocumentNewEsNavigationDataDocument, options);
      }
export function useApprovalsDocumentNewEsNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalsDocumentNewEsNavigationDataQuery, ApprovalsDocumentNewEsNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalsDocumentNewEsNavigationDataQuery, ApprovalsDocumentNewEsNavigationDataQueryVariables>(ApprovalsDocumentNewEsNavigationDataDocument, options);
        }
export type ApprovalsDocumentNewEsNavigationDataQueryHookResult = ReturnType<typeof useApprovalsDocumentNewEsNavigationDataQuery>;
export type ApprovalsDocumentNewEsNavigationDataLazyQueryHookResult = ReturnType<typeof useApprovalsDocumentNewEsNavigationDataLazyQuery>;
export type ApprovalsDocumentNewEsNavigationDataQueryResult = Apollo.QueryResult<ApprovalsDocumentNewEsNavigationDataQuery, ApprovalsDocumentNewEsNavigationDataQueryVariables>;
export const ArchiveDocumentNavigationDataDocument = gql`
    query archiveDocumentNavigationData($cursor: String) {
  prevDocument: archivedDocuments(limit: 1, before: $cursor) {
    ...BasicDocumentConnectionData
  }
  nextDocument: archivedDocuments(limit: 1, after: $cursor) {
    ...BasicDocumentConnectionData
  }
}
    ${BasicDocumentConnectionDataFragmentDoc}`;

/**
 * __useArchiveDocumentNavigationDataQuery__
 *
 * To run a query within a React component, call `useArchiveDocumentNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveDocumentNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveDocumentNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useArchiveDocumentNavigationDataQuery(baseOptions?: Apollo.QueryHookOptions<ArchiveDocumentNavigationDataQuery, ArchiveDocumentNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveDocumentNavigationDataQuery, ArchiveDocumentNavigationDataQueryVariables>(ArchiveDocumentNavigationDataDocument, options);
      }
export function useArchiveDocumentNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveDocumentNavigationDataQuery, ArchiveDocumentNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveDocumentNavigationDataQuery, ArchiveDocumentNavigationDataQueryVariables>(ArchiveDocumentNavigationDataDocument, options);
        }
export type ArchiveDocumentNavigationDataQueryHookResult = ReturnType<typeof useArchiveDocumentNavigationDataQuery>;
export type ArchiveDocumentNavigationDataLazyQueryHookResult = ReturnType<typeof useArchiveDocumentNavigationDataLazyQuery>;
export type ArchiveDocumentNavigationDataQueryResult = Apollo.QueryResult<ArchiveDocumentNavigationDataQuery, ArchiveDocumentNavigationDataQueryVariables>;
export const ArchiveDocumentsNavigationWithSearchNavigationDataDocument = gql`
    query archiveDocumentsNavigationWithSearchNavigationData($cursor: String, $query: String!) {
  prevDocument: searchDocuments(limit: 1, before: $cursor, query: $query) {
    ...BasicDocumentConnectionData
  }
  nextDocument: searchDocuments(limit: 1, after: $cursor, query: $query) {
    ...BasicDocumentConnectionData
  }
}
    ${BasicDocumentConnectionDataFragmentDoc}`;

/**
 * __useArchiveDocumentsNavigationWithSearchNavigationDataQuery__
 *
 * To run a query within a React component, call `useArchiveDocumentsNavigationWithSearchNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveDocumentsNavigationWithSearchNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveDocumentsNavigationWithSearchNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useArchiveDocumentsNavigationWithSearchNavigationDataQuery(baseOptions: Apollo.QueryHookOptions<ArchiveDocumentsNavigationWithSearchNavigationDataQuery, ArchiveDocumentsNavigationWithSearchNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveDocumentsNavigationWithSearchNavigationDataQuery, ArchiveDocumentsNavigationWithSearchNavigationDataQueryVariables>(ArchiveDocumentsNavigationWithSearchNavigationDataDocument, options);
      }
export function useArchiveDocumentsNavigationWithSearchNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveDocumentsNavigationWithSearchNavigationDataQuery, ArchiveDocumentsNavigationWithSearchNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveDocumentsNavigationWithSearchNavigationDataQuery, ArchiveDocumentsNavigationWithSearchNavigationDataQueryVariables>(ArchiveDocumentsNavigationWithSearchNavigationDataDocument, options);
        }
export type ArchiveDocumentsNavigationWithSearchNavigationDataQueryHookResult = ReturnType<typeof useArchiveDocumentsNavigationWithSearchNavigationDataQuery>;
export type ArchiveDocumentsNavigationWithSearchNavigationDataLazyQueryHookResult = ReturnType<typeof useArchiveDocumentsNavigationWithSearchNavigationDataLazyQuery>;
export type ArchiveDocumentsNavigationWithSearchNavigationDataQueryResult = Apollo.QueryResult<ArchiveDocumentsNavigationWithSearchNavigationDataQuery, ArchiveDocumentsNavigationWithSearchNavigationDataQueryVariables>;
export const ArchiveInvoiceDocumentsNavigationDataDocument = gql`
    query archiveInvoiceDocumentsNavigationData($cursor: String, $query: String!) {
  prevDocument: archiveInvoiceDocuments(limit: 1, before: $cursor, query: $query) {
    ...BasicArchiveInvoiceDocumentConnectionData
  }
  nextDocument: archiveInvoiceDocuments(limit: 1, after: $cursor, query: $query) {
    ...BasicArchiveInvoiceDocumentConnectionData
  }
}
    ${BasicArchiveInvoiceDocumentConnectionDataFragmentDoc}`;

/**
 * __useArchiveInvoiceDocumentsNavigationDataQuery__
 *
 * To run a query within a React component, call `useArchiveInvoiceDocumentsNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveInvoiceDocumentsNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveInvoiceDocumentsNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useArchiveInvoiceDocumentsNavigationDataQuery(baseOptions: Apollo.QueryHookOptions<ArchiveInvoiceDocumentsNavigationDataQuery, ArchiveInvoiceDocumentsNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveInvoiceDocumentsNavigationDataQuery, ArchiveInvoiceDocumentsNavigationDataQueryVariables>(ArchiveInvoiceDocumentsNavigationDataDocument, options);
      }
export function useArchiveInvoiceDocumentsNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveInvoiceDocumentsNavigationDataQuery, ArchiveInvoiceDocumentsNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveInvoiceDocumentsNavigationDataQuery, ArchiveInvoiceDocumentsNavigationDataQueryVariables>(ArchiveInvoiceDocumentsNavigationDataDocument, options);
        }
export type ArchiveInvoiceDocumentsNavigationDataQueryHookResult = ReturnType<typeof useArchiveInvoiceDocumentsNavigationDataQuery>;
export type ArchiveInvoiceDocumentsNavigationDataLazyQueryHookResult = ReturnType<typeof useArchiveInvoiceDocumentsNavigationDataLazyQuery>;
export type ArchiveInvoiceDocumentsNavigationDataQueryResult = Apollo.QueryResult<ArchiveInvoiceDocumentsNavigationDataQuery, ArchiveInvoiceDocumentsNavigationDataQueryVariables>;
export const EcmDocumentNavigationDataDocument = gql`
    query ecmDocumentNavigationData($cursor: String, $query: String, $filter: EcmFilterInput) {
  prevDocument: getEcmDocuments(
    limit: 1
    previous: $cursor
    search: $query
    filter: $filter
  ) {
    ...EcmDocumentConnectionData
  }
  nextDocument: getEcmDocuments(
    limit: 1
    next: $cursor
    search: $query
    filter: $filter
  ) {
    ...EcmDocumentConnectionData
  }
}
    ${EcmDocumentConnectionDataFragmentDoc}
${TagFragmentDoc}`;

/**
 * __useEcmDocumentNavigationDataQuery__
 *
 * To run a query within a React component, call `useEcmDocumentNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmDocumentNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmDocumentNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEcmDocumentNavigationDataQuery(baseOptions?: Apollo.QueryHookOptions<EcmDocumentNavigationDataQuery, EcmDocumentNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmDocumentNavigationDataQuery, EcmDocumentNavigationDataQueryVariables>(EcmDocumentNavigationDataDocument, options);
      }
export function useEcmDocumentNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmDocumentNavigationDataQuery, EcmDocumentNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmDocumentNavigationDataQuery, EcmDocumentNavigationDataQueryVariables>(EcmDocumentNavigationDataDocument, options);
        }
export type EcmDocumentNavigationDataQueryHookResult = ReturnType<typeof useEcmDocumentNavigationDataQuery>;
export type EcmDocumentNavigationDataLazyQueryHookResult = ReturnType<typeof useEcmDocumentNavigationDataLazyQuery>;
export type EcmDocumentNavigationDataQueryResult = Apollo.QueryResult<EcmDocumentNavigationDataQuery, EcmDocumentNavigationDataQueryVariables>;
export const AllDocumentNavigationDataDocument = gql`
    query allDocumentNavigationData($cursor: String, $query: String, $filter: ArchiveAllDocumentsFilterInput) {
  prevDocument: archiveAllDocuments(
    limit: 1
    previous: $cursor
    search: $query
    filter: $filter
  ) {
    ...AllDocumentConnectionData
  }
  nextDocument: archiveAllDocuments(
    limit: 1
    next: $cursor
    search: $query
    filter: $filter
  ) {
    ...AllDocumentConnectionData
  }
}
    ${AllDocumentConnectionDataFragmentDoc}
${TagFragmentDoc}`;

/**
 * __useAllDocumentNavigationDataQuery__
 *
 * To run a query within a React component, call `useAllDocumentNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDocumentNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDocumentNavigationDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllDocumentNavigationDataQuery(baseOptions?: Apollo.QueryHookOptions<AllDocumentNavigationDataQuery, AllDocumentNavigationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDocumentNavigationDataQuery, AllDocumentNavigationDataQueryVariables>(AllDocumentNavigationDataDocument, options);
      }
export function useAllDocumentNavigationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDocumentNavigationDataQuery, AllDocumentNavigationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDocumentNavigationDataQuery, AllDocumentNavigationDataQueryVariables>(AllDocumentNavigationDataDocument, options);
        }
export type AllDocumentNavigationDataQueryHookResult = ReturnType<typeof useAllDocumentNavigationDataQuery>;
export type AllDocumentNavigationDataLazyQueryHookResult = ReturnType<typeof useAllDocumentNavigationDataLazyQuery>;
export type AllDocumentNavigationDataQueryResult = Apollo.QueryResult<AllDocumentNavigationDataQuery, AllDocumentNavigationDataQueryVariables>;
export const ImportSapB1GeneralLedgerAccountsDocument = gql`
    mutation importSapB1GeneralLedgerAccounts {
  importSapB1GeneralLedgerAccounts {
    code
  }
}
    `;
export type ImportSapB1GeneralLedgerAccountsMutationFn = Apollo.MutationFunction<ImportSapB1GeneralLedgerAccountsMutation, ImportSapB1GeneralLedgerAccountsMutationVariables>;

/**
 * __useImportSapB1GeneralLedgerAccountsMutation__
 *
 * To run a mutation, you first call `useImportSapB1GeneralLedgerAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1GeneralLedgerAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1GeneralLedgerAccountsMutation, { data, loading, error }] = useImportSapB1GeneralLedgerAccountsMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportSapB1GeneralLedgerAccountsMutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1GeneralLedgerAccountsMutation, ImportSapB1GeneralLedgerAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1GeneralLedgerAccountsMutation, ImportSapB1GeneralLedgerAccountsMutationVariables>(ImportSapB1GeneralLedgerAccountsDocument, options);
      }
export type ImportSapB1GeneralLedgerAccountsMutationHookResult = ReturnType<typeof useImportSapB1GeneralLedgerAccountsMutation>;
export type ImportSapB1GeneralLedgerAccountsMutationResult = Apollo.MutationResult<ImportSapB1GeneralLedgerAccountsMutation>;
export type ImportSapB1GeneralLedgerAccountsMutationOptions = Apollo.BaseMutationOptions<ImportSapB1GeneralLedgerAccountsMutation, ImportSapB1GeneralLedgerAccountsMutationVariables>;
export const ImportSapB1TaxCodesDocument = gql`
    mutation importSapB1TaxCodes {
  importSapB1TaxCodes {
    code
  }
}
    `;
export type ImportSapB1TaxCodesMutationFn = Apollo.MutationFunction<ImportSapB1TaxCodesMutation, ImportSapB1TaxCodesMutationVariables>;

/**
 * __useImportSapB1TaxCodesMutation__
 *
 * To run a mutation, you first call `useImportSapB1TaxCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1TaxCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1TaxCodesMutation, { data, loading, error }] = useImportSapB1TaxCodesMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportSapB1TaxCodesMutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1TaxCodesMutation, ImportSapB1TaxCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1TaxCodesMutation, ImportSapB1TaxCodesMutationVariables>(ImportSapB1TaxCodesDocument, options);
      }
export type ImportSapB1TaxCodesMutationHookResult = ReturnType<typeof useImportSapB1TaxCodesMutation>;
export type ImportSapB1TaxCodesMutationResult = Apollo.MutationResult<ImportSapB1TaxCodesMutation>;
export type ImportSapB1TaxCodesMutationOptions = Apollo.BaseMutationOptions<ImportSapB1TaxCodesMutation, ImportSapB1TaxCodesMutationVariables>;
export const ImportSapB1CostObjectsDocument = gql`
    mutation importSapB1CostObjects {
  importSapB1CostObjects {
    code
  }
}
    `;
export type ImportSapB1CostObjectsMutationFn = Apollo.MutationFunction<ImportSapB1CostObjectsMutation, ImportSapB1CostObjectsMutationVariables>;

/**
 * __useImportSapB1CostObjectsMutation__
 *
 * To run a mutation, you first call `useImportSapB1CostObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1CostObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1CostObjectsMutation, { data, loading, error }] = useImportSapB1CostObjectsMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportSapB1CostObjectsMutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1CostObjectsMutation, ImportSapB1CostObjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1CostObjectsMutation, ImportSapB1CostObjectsMutationVariables>(ImportSapB1CostObjectsDocument, options);
      }
export type ImportSapB1CostObjectsMutationHookResult = ReturnType<typeof useImportSapB1CostObjectsMutation>;
export type ImportSapB1CostObjectsMutationResult = Apollo.MutationResult<ImportSapB1CostObjectsMutation>;
export type ImportSapB1CostObjectsMutationOptions = Apollo.BaseMutationOptions<ImportSapB1CostObjectsMutation, ImportSapB1CostObjectsMutationVariables>;
export const ImportSapB1ContactsDocument = gql`
    mutation importSapB1Contacts {
  importSapB1Contacts {
    code
  }
}
    `;
export type ImportSapB1ContactsMutationFn = Apollo.MutationFunction<ImportSapB1ContactsMutation, ImportSapB1ContactsMutationVariables>;

/**
 * __useImportSapB1ContactsMutation__
 *
 * To run a mutation, you first call `useImportSapB1ContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1ContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1ContactsMutation, { data, loading, error }] = useImportSapB1ContactsMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportSapB1ContactsMutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1ContactsMutation, ImportSapB1ContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1ContactsMutation, ImportSapB1ContactsMutationVariables>(ImportSapB1ContactsDocument, options);
      }
export type ImportSapB1ContactsMutationHookResult = ReturnType<typeof useImportSapB1ContactsMutation>;
export type ImportSapB1ContactsMutationResult = Apollo.MutationResult<ImportSapB1ContactsMutation>;
export type ImportSapB1ContactsMutationOptions = Apollo.BaseMutationOptions<ImportSapB1ContactsMutation, ImportSapB1ContactsMutationVariables>;
export const ImportSapB1PurchaseOrdersV2Document = gql`
    mutation importSapB1PurchaseOrdersV2($importFilters: SapB1PurchaseOrdersImportFilters) {
  importSapB1PurchaseOrdersV2(importFilters: $importFilters) {
    ...SapB1ImportResponseData
    ...PurchaseOrderImportHistoryEntryData
  }
}
    ${SapB1ImportResponseDataFragmentDoc}
${PurchaseOrderImportHistoryEntryDataFragmentDoc}`;
export type ImportSapB1PurchaseOrdersV2MutationFn = Apollo.MutationFunction<ImportSapB1PurchaseOrdersV2Mutation, ImportSapB1PurchaseOrdersV2MutationVariables>;

/**
 * __useImportSapB1PurchaseOrdersV2Mutation__
 *
 * To run a mutation, you first call `useImportSapB1PurchaseOrdersV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1PurchaseOrdersV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1PurchaseOrdersV2Mutation, { data, loading, error }] = useImportSapB1PurchaseOrdersV2Mutation({
 *   variables: {
 *      importFilters: // value for 'importFilters'
 *   },
 * });
 */
export function useImportSapB1PurchaseOrdersV2Mutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1PurchaseOrdersV2Mutation, ImportSapB1PurchaseOrdersV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1PurchaseOrdersV2Mutation, ImportSapB1PurchaseOrdersV2MutationVariables>(ImportSapB1PurchaseOrdersV2Document, options);
      }
export type ImportSapB1PurchaseOrdersV2MutationHookResult = ReturnType<typeof useImportSapB1PurchaseOrdersV2Mutation>;
export type ImportSapB1PurchaseOrdersV2MutationResult = Apollo.MutationResult<ImportSapB1PurchaseOrdersV2Mutation>;
export type ImportSapB1PurchaseOrdersV2MutationOptions = Apollo.BaseMutationOptions<ImportSapB1PurchaseOrdersV2Mutation, ImportSapB1PurchaseOrdersV2MutationVariables>;
export const ImportSapB1GoodsReceiptsDocument = gql`
    mutation importSapB1GoodsReceipts {
  importSapB1GoodsReceipts {
    ...SapB1ImportResponseData
  }
}
    ${SapB1ImportResponseDataFragmentDoc}`;
export type ImportSapB1GoodsReceiptsMutationFn = Apollo.MutationFunction<ImportSapB1GoodsReceiptsMutation, ImportSapB1GoodsReceiptsMutationVariables>;

/**
 * __useImportSapB1GoodsReceiptsMutation__
 *
 * To run a mutation, you first call `useImportSapB1GoodsReceiptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1GoodsReceiptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1GoodsReceiptsMutation, { data, loading, error }] = useImportSapB1GoodsReceiptsMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportSapB1GoodsReceiptsMutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1GoodsReceiptsMutation, ImportSapB1GoodsReceiptsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1GoodsReceiptsMutation, ImportSapB1GoodsReceiptsMutationVariables>(ImportSapB1GoodsReceiptsDocument, options);
      }
export type ImportSapB1GoodsReceiptsMutationHookResult = ReturnType<typeof useImportSapB1GoodsReceiptsMutation>;
export type ImportSapB1GoodsReceiptsMutationResult = Apollo.MutationResult<ImportSapB1GoodsReceiptsMutation>;
export type ImportSapB1GoodsReceiptsMutationOptions = Apollo.BaseMutationOptions<ImportSapB1GoodsReceiptsMutation, ImportSapB1GoodsReceiptsMutationVariables>;
export const ImportSapB1GoodsReceiptsV2Document = gql`
    mutation importSapB1GoodsReceiptsV2($importFilters: SapB1GoodsReceiptsImportFilters) {
  importSapB1GoodsReceiptsV2(importFilters: $importFilters) {
    ...SapB1ImportResponseData
    ...GoodsReceiptImportHistoryEntryData
  }
}
    ${SapB1ImportResponseDataFragmentDoc}
${GoodsReceiptImportHistoryEntryDataFragmentDoc}`;
export type ImportSapB1GoodsReceiptsV2MutationFn = Apollo.MutationFunction<ImportSapB1GoodsReceiptsV2Mutation, ImportSapB1GoodsReceiptsV2MutationVariables>;

/**
 * __useImportSapB1GoodsReceiptsV2Mutation__
 *
 * To run a mutation, you first call `useImportSapB1GoodsReceiptsV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1GoodsReceiptsV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1GoodsReceiptsV2Mutation, { data, loading, error }] = useImportSapB1GoodsReceiptsV2Mutation({
 *   variables: {
 *      importFilters: // value for 'importFilters'
 *   },
 * });
 */
export function useImportSapB1GoodsReceiptsV2Mutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1GoodsReceiptsV2Mutation, ImportSapB1GoodsReceiptsV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1GoodsReceiptsV2Mutation, ImportSapB1GoodsReceiptsV2MutationVariables>(ImportSapB1GoodsReceiptsV2Document, options);
      }
export type ImportSapB1GoodsReceiptsV2MutationHookResult = ReturnType<typeof useImportSapB1GoodsReceiptsV2Mutation>;
export type ImportSapB1GoodsReceiptsV2MutationResult = Apollo.MutationResult<ImportSapB1GoodsReceiptsV2Mutation>;
export type ImportSapB1GoodsReceiptsV2MutationOptions = Apollo.BaseMutationOptions<ImportSapB1GoodsReceiptsV2Mutation, ImportSapB1GoodsReceiptsV2MutationVariables>;
export const ImportSapB1ProjectCodesDocument = gql`
    mutation importSapB1ProjectCodes {
  importSapB1ProjectCodes {
    code
  }
}
    `;
export type ImportSapB1ProjectCodesMutationFn = Apollo.MutationFunction<ImportSapB1ProjectCodesMutation, ImportSapB1ProjectCodesMutationVariables>;

/**
 * __useImportSapB1ProjectCodesMutation__
 *
 * To run a mutation, you first call `useImportSapB1ProjectCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSapB1ProjectCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSapB1ProjectCodesMutation, { data, loading, error }] = useImportSapB1ProjectCodesMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportSapB1ProjectCodesMutation(baseOptions?: Apollo.MutationHookOptions<ImportSapB1ProjectCodesMutation, ImportSapB1ProjectCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSapB1ProjectCodesMutation, ImportSapB1ProjectCodesMutationVariables>(ImportSapB1ProjectCodesDocument, options);
      }
export type ImportSapB1ProjectCodesMutationHookResult = ReturnType<typeof useImportSapB1ProjectCodesMutation>;
export type ImportSapB1ProjectCodesMutationResult = Apollo.MutationResult<ImportSapB1ProjectCodesMutation>;
export type ImportSapB1ProjectCodesMutationOptions = Apollo.BaseMutationOptions<ImportSapB1ProjectCodesMutation, ImportSapB1ProjectCodesMutationVariables>;
export const TrackEventDocument = gql`
    mutation trackEvent($input: TrackEventInput!) {
  trackEvent(input: $input)
}
    `;
export type TrackEventMutationFn = Apollo.MutationFunction<TrackEventMutation, TrackEventMutationVariables>;

/**
 * __useTrackEventMutation__
 *
 * To run a mutation, you first call `useTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventMutation, { data, loading, error }] = useTrackEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackEventMutation(baseOptions?: Apollo.MutationHookOptions<TrackEventMutation, TrackEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackEventMutation, TrackEventMutationVariables>(TrackEventDocument, options);
      }
export type TrackEventMutationHookResult = ReturnType<typeof useTrackEventMutation>;
export type TrackEventMutationResult = Apollo.MutationResult<TrackEventMutation>;
export type TrackEventMutationOptions = Apollo.BaseMutationOptions<TrackEventMutation, TrackEventMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser($id: String!) {
  currentUser(id: $id) {
    id
    name
    email
    phoneNumber
    firstName
    lastName
    locale
    createdTimestamp
    analyticsId
    intercomHash
    membershipRoles {
      __typename
      id
      name
      isBuiltinRole
    }
    preferences {
      tables {
        id
        columns {
          id
          isVisible
          isFixed
          label
        }
      }
      promos {
        promoId
        seenAt
      }
    }
    roles
    cardRoles
    avatarUrl
    trackingConfiguration {
      intercom
      trackingEnabled
    }
    paymentInfo {
      iban
      swiftCode
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UpdateCurrentUserDocument = gql`
    mutation updateCurrentUser($input: UpdateCurrentUserInput!) {
  updateCurrentUser(input: $input) {
    id
    firstName
    lastName
    locale
  }
}
    `;
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(UpdateCurrentUserDocument, options);
      }
export type UpdateCurrentUserMutationHookResult = ReturnType<typeof useUpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationResult = Apollo.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export const SaveTableConfigurationsDocument = gql`
    mutation saveTableConfigurations($input: SaveTablePreferenceInput!) {
  saveTablePreferences(input: $input) {
    id
    columns {
      id
      isVisible
      isFixed
      label
    }
  }
}
    `;
export type SaveTableConfigurationsMutationFn = Apollo.MutationFunction<SaveTableConfigurationsMutation, SaveTableConfigurationsMutationVariables>;

/**
 * __useSaveTableConfigurationsMutation__
 *
 * To run a mutation, you first call `useSaveTableConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTableConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTableConfigurationsMutation, { data, loading, error }] = useSaveTableConfigurationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTableConfigurationsMutation(baseOptions?: Apollo.MutationHookOptions<SaveTableConfigurationsMutation, SaveTableConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTableConfigurationsMutation, SaveTableConfigurationsMutationVariables>(SaveTableConfigurationsDocument, options);
      }
export type SaveTableConfigurationsMutationHookResult = ReturnType<typeof useSaveTableConfigurationsMutation>;
export type SaveTableConfigurationsMutationResult = Apollo.MutationResult<SaveTableConfigurationsMutation>;
export type SaveTableConfigurationsMutationOptions = Apollo.BaseMutationOptions<SaveTableConfigurationsMutation, SaveTableConfigurationsMutationVariables>;
export const ResetTableConfigurationsDocument = gql`
    mutation resetTableConfigurations($input: DeleteTablePreferenceInput!) {
  deleteTablePreferences(input: $input)
}
    `;
export type ResetTableConfigurationsMutationFn = Apollo.MutationFunction<ResetTableConfigurationsMutation, ResetTableConfigurationsMutationVariables>;

/**
 * __useResetTableConfigurationsMutation__
 *
 * To run a mutation, you first call `useResetTableConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTableConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTableConfigurationsMutation, { data, loading, error }] = useResetTableConfigurationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetTableConfigurationsMutation(baseOptions?: Apollo.MutationHookOptions<ResetTableConfigurationsMutation, ResetTableConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetTableConfigurationsMutation, ResetTableConfigurationsMutationVariables>(ResetTableConfigurationsDocument, options);
      }
export type ResetTableConfigurationsMutationHookResult = ReturnType<typeof useResetTableConfigurationsMutation>;
export type ResetTableConfigurationsMutationResult = Apollo.MutationResult<ResetTableConfigurationsMutation>;
export type ResetTableConfigurationsMutationOptions = Apollo.BaseMutationOptions<ResetTableConfigurationsMutation, ResetTableConfigurationsMutationVariables>;
export const SavePromoPreferenceDocument = gql`
    mutation savePromoPreference($input: SavePromoPreferenceInput!) {
  savePromoPreference(input: $input) {
    promoId
  }
}
    `;
export type SavePromoPreferenceMutationFn = Apollo.MutationFunction<SavePromoPreferenceMutation, SavePromoPreferenceMutationVariables>;

/**
 * __useSavePromoPreferenceMutation__
 *
 * To run a mutation, you first call `useSavePromoPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePromoPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePromoPreferenceMutation, { data, loading, error }] = useSavePromoPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePromoPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<SavePromoPreferenceMutation, SavePromoPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePromoPreferenceMutation, SavePromoPreferenceMutationVariables>(SavePromoPreferenceDocument, options);
      }
export type SavePromoPreferenceMutationHookResult = ReturnType<typeof useSavePromoPreferenceMutation>;
export type SavePromoPreferenceMutationResult = Apollo.MutationResult<SavePromoPreferenceMutation>;
export type SavePromoPreferenceMutationOptions = Apollo.BaseMutationOptions<SavePromoPreferenceMutation, SavePromoPreferenceMutationVariables>;
export const DeletePromoPreferenceDocument = gql`
    mutation deletePromoPreference($promoId: String!) {
  deletePromoPreference(promoId: $promoId)
}
    `;
export type DeletePromoPreferenceMutationFn = Apollo.MutationFunction<DeletePromoPreferenceMutation, DeletePromoPreferenceMutationVariables>;

/**
 * __useDeletePromoPreferenceMutation__
 *
 * To run a mutation, you first call `useDeletePromoPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoPreferenceMutation, { data, loading, error }] = useDeletePromoPreferenceMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *   },
 * });
 */
export function useDeletePromoPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePromoPreferenceMutation, DeletePromoPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePromoPreferenceMutation, DeletePromoPreferenceMutationVariables>(DeletePromoPreferenceDocument, options);
      }
export type DeletePromoPreferenceMutationHookResult = ReturnType<typeof useDeletePromoPreferenceMutation>;
export type DeletePromoPreferenceMutationResult = Apollo.MutationResult<DeletePromoPreferenceMutation>;
export type DeletePromoPreferenceMutationOptions = Apollo.BaseMutationOptions<DeletePromoPreferenceMutation, DeletePromoPreferenceMutationVariables>;
export const GetCardIssuerCardholdersDocument = gql`
    query getCardIssuerCardholders($input: CardIssuerPaginationInput!, $filters: CardIssuerCardholdersFiltersInput) {
  getCardIssuerCardholders(input: $input, filters: $filters) {
    edges {
      node {
        id
        firstName
        lastName
        memberships {
          membershipId
          organizationId
        }
      }
    }
  }
}
    `;

/**
 * __useGetCardIssuerCardholdersQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerCardholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerCardholdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerCardholdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCardIssuerCardholdersQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerCardholdersQuery, GetCardIssuerCardholdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerCardholdersQuery, GetCardIssuerCardholdersQueryVariables>(GetCardIssuerCardholdersDocument, options);
      }
export function useGetCardIssuerCardholdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerCardholdersQuery, GetCardIssuerCardholdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerCardholdersQuery, GetCardIssuerCardholdersQueryVariables>(GetCardIssuerCardholdersDocument, options);
        }
export type GetCardIssuerCardholdersQueryHookResult = ReturnType<typeof useGetCardIssuerCardholdersQuery>;
export type GetCardIssuerCardholdersLazyQueryHookResult = ReturnType<typeof useGetCardIssuerCardholdersLazyQuery>;
export type GetCardIssuerCardholdersQueryResult = Apollo.QueryResult<GetCardIssuerCardholdersQuery, GetCardIssuerCardholdersQueryVariables>;
export const GetCardIssuerCardsForCardholderDocument = gql`
    query getCardIssuerCardsForCardholder($input: CardIssuerPaginationInput!, $filters: CardIssuerCardsForCardholderFiltersInput, $queries: CardIssuerCardsQueryInput, $sortBy: CardIssuerCardsSortInput) {
  getCardIssuerCardsForCardholder(
    input: $input
    filters: $filters
    queries: $queries
    sortBy: $sortBy
  ) {
    edges {
      cursor
      node {
        id
        cardholder {
          id
          membershipId
          membershipEmail
          avatarUrl
          firstName
          lastName
        }
        lastTransactionCreatedAt
        type
        status
        label
        refNum
        issuingDate
        expiryDate
        purpose
        limitRenewFrequency
        limit {
          value
          precision
          currency
        }
        spendingBalance {
          value
          precision
          currency
        }
        availableBalance {
          value
          precision
          currency
        }
        transactionLimit {
          value
          precision
          currency
        }
        brand
        hasAccountingData
        pendingRequest {
          __typename
          id
          status
          createdAt
          ...VirtualCardRequestData
          ...SingleUseVirtualCardRequestData
          ...PhysicalCardRequestData
          ...CardLimitChangeRequestData
        }
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${VirtualCardRequestDataFragmentDoc}
${SingleUseVirtualCardRequestDataFragmentDoc}
${PhysicalCardRequestDataFragmentDoc}
${CardLimitChangeRequestDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useGetCardIssuerCardsForCardholderQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerCardsForCardholderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerCardsForCardholderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerCardsForCardholderQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetCardIssuerCardsForCardholderQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerCardsForCardholderQuery, GetCardIssuerCardsForCardholderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerCardsForCardholderQuery, GetCardIssuerCardsForCardholderQueryVariables>(GetCardIssuerCardsForCardholderDocument, options);
      }
export function useGetCardIssuerCardsForCardholderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerCardsForCardholderQuery, GetCardIssuerCardsForCardholderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerCardsForCardholderQuery, GetCardIssuerCardsForCardholderQueryVariables>(GetCardIssuerCardsForCardholderDocument, options);
        }
export type GetCardIssuerCardsForCardholderQueryHookResult = ReturnType<typeof useGetCardIssuerCardsForCardholderQuery>;
export type GetCardIssuerCardsForCardholderLazyQueryHookResult = ReturnType<typeof useGetCardIssuerCardsForCardholderLazyQuery>;
export type GetCardIssuerCardsForCardholderQueryResult = Apollo.QueryResult<GetCardIssuerCardsForCardholderQuery, GetCardIssuerCardsForCardholderQueryVariables>;
export const GetCardIssuerCardsForCardManagerDocument = gql`
    query getCardIssuerCardsForCardManager($input: CardIssuerPaginationInput!, $filters: CardIssuerCardsForCardManagerFiltersInput, $sortBy: CardIssuerCardsSortInput, $queries: CardIssuerCardsQueryInput) {
  getCardIssuerCardsForCardManager(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) {
    edges {
      ...CardIssuerCardEdgeData
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${CardIssuerCardEdgeDataFragmentDoc}
${VirtualCardRequestDataFragmentDoc}
${SingleUseVirtualCardRequestDataFragmentDoc}
${PhysicalCardRequestDataFragmentDoc}
${CardLimitChangeRequestDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useGetCardIssuerCardsForCardManagerQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerCardsForCardManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerCardsForCardManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerCardsForCardManagerQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      queries: // value for 'queries'
 *   },
 * });
 */
export function useGetCardIssuerCardsForCardManagerQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerCardsForCardManagerQuery, GetCardIssuerCardsForCardManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerCardsForCardManagerQuery, GetCardIssuerCardsForCardManagerQueryVariables>(GetCardIssuerCardsForCardManagerDocument, options);
      }
export function useGetCardIssuerCardsForCardManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerCardsForCardManagerQuery, GetCardIssuerCardsForCardManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerCardsForCardManagerQuery, GetCardIssuerCardsForCardManagerQueryVariables>(GetCardIssuerCardsForCardManagerDocument, options);
        }
export type GetCardIssuerCardsForCardManagerQueryHookResult = ReturnType<typeof useGetCardIssuerCardsForCardManagerQuery>;
export type GetCardIssuerCardsForCardManagerLazyQueryHookResult = ReturnType<typeof useGetCardIssuerCardsForCardManagerLazyQuery>;
export type GetCardIssuerCardsForCardManagerQueryResult = Apollo.QueryResult<GetCardIssuerCardsForCardManagerQuery, GetCardIssuerCardsForCardManagerQueryVariables>;
export const GetCardIssuerCardsRefNumbersAndLabelsDocument = gql`
    query getCardIssuerCardsRefNumbersAndLabels($input: CardIssuerPaginationInput!) {
  getCardIssuerCardsRefNumbersAndLabels: getCardIssuerCardsForCardManager(
    input: $input
  ) {
    edges {
      node {
        id
        refNum
        label
      }
    }
  }
}
    `;

/**
 * __useGetCardIssuerCardsRefNumbersAndLabelsQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerCardsRefNumbersAndLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerCardsRefNumbersAndLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerCardsRefNumbersAndLabelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCardIssuerCardsRefNumbersAndLabelsQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerCardsRefNumbersAndLabelsQuery, GetCardIssuerCardsRefNumbersAndLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerCardsRefNumbersAndLabelsQuery, GetCardIssuerCardsRefNumbersAndLabelsQueryVariables>(GetCardIssuerCardsRefNumbersAndLabelsDocument, options);
      }
export function useGetCardIssuerCardsRefNumbersAndLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerCardsRefNumbersAndLabelsQuery, GetCardIssuerCardsRefNumbersAndLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerCardsRefNumbersAndLabelsQuery, GetCardIssuerCardsRefNumbersAndLabelsQueryVariables>(GetCardIssuerCardsRefNumbersAndLabelsDocument, options);
        }
export type GetCardIssuerCardsRefNumbersAndLabelsQueryHookResult = ReturnType<typeof useGetCardIssuerCardsRefNumbersAndLabelsQuery>;
export type GetCardIssuerCardsRefNumbersAndLabelsLazyQueryHookResult = ReturnType<typeof useGetCardIssuerCardsRefNumbersAndLabelsLazyQuery>;
export type GetCardIssuerCardsRefNumbersAndLabelsQueryResult = Apollo.QueryResult<GetCardIssuerCardsRefNumbersAndLabelsQuery, GetCardIssuerCardsRefNumbersAndLabelsQueryVariables>;
export const GetCardIssuerCardholderByMembershipIdDocument = gql`
    query getCardIssuerCardholderByMembershipId($membershipId: ID!) {
  getCardIssuerCardholderByMembershipId(membershipId: $membershipId) {
    id
  }
}
    `;

/**
 * __useGetCardIssuerCardholderByMembershipIdQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerCardholderByMembershipIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerCardholderByMembershipIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerCardholderByMembershipIdQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useGetCardIssuerCardholderByMembershipIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerCardholderByMembershipIdQuery, GetCardIssuerCardholderByMembershipIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerCardholderByMembershipIdQuery, GetCardIssuerCardholderByMembershipIdQueryVariables>(GetCardIssuerCardholderByMembershipIdDocument, options);
      }
export function useGetCardIssuerCardholderByMembershipIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerCardholderByMembershipIdQuery, GetCardIssuerCardholderByMembershipIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerCardholderByMembershipIdQuery, GetCardIssuerCardholderByMembershipIdQueryVariables>(GetCardIssuerCardholderByMembershipIdDocument, options);
        }
export type GetCardIssuerCardholderByMembershipIdQueryHookResult = ReturnType<typeof useGetCardIssuerCardholderByMembershipIdQuery>;
export type GetCardIssuerCardholderByMembershipIdLazyQueryHookResult = ReturnType<typeof useGetCardIssuerCardholderByMembershipIdLazyQuery>;
export type GetCardIssuerCardholderByMembershipIdQueryResult = Apollo.QueryResult<GetCardIssuerCardholderByMembershipIdQuery, GetCardIssuerCardholderByMembershipIdQueryVariables>;
export const AddFileUploadDocument = gql`
    mutation addFileUpload($file: FileInput) {
  addFileUpload(file: $file) @client
}
    `;
export type AddFileUploadMutationFn = Apollo.MutationFunction<AddFileUploadMutation, AddFileUploadMutationVariables>;

/**
 * __useAddFileUploadMutation__
 *
 * To run a mutation, you first call `useAddFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileUploadMutation, { data, loading, error }] = useAddFileUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<AddFileUploadMutation, AddFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFileUploadMutation, AddFileUploadMutationVariables>(AddFileUploadDocument, options);
      }
export type AddFileUploadMutationHookResult = ReturnType<typeof useAddFileUploadMutation>;
export type AddFileUploadMutationResult = Apollo.MutationResult<AddFileUploadMutation>;
export type AddFileUploadMutationOptions = Apollo.BaseMutationOptions<AddFileUploadMutation, AddFileUploadMutationVariables>;
export const UpdateFileUploadDocument = gql`
    mutation updateFileUpload($file: FileInput) {
  updateFileUpload(file: $file) @client
}
    `;
export type UpdateFileUploadMutationFn = Apollo.MutationFunction<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>;

/**
 * __useUpdateFileUploadMutation__
 *
 * To run a mutation, you first call `useUpdateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileUploadMutation, { data, loading, error }] = useUpdateFileUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>(UpdateFileUploadDocument, options);
      }
export type UpdateFileUploadMutationHookResult = ReturnType<typeof useUpdateFileUploadMutation>;
export type UpdateFileUploadMutationResult = Apollo.MutationResult<UpdateFileUploadMutation>;
export type UpdateFileUploadMutationOptions = Apollo.BaseMutationOptions<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>;
export const RemoveAllDocument = gql`
    mutation RemoveAll {
  removeAll @client
}
    `;
export type RemoveAllMutationFn = Apollo.MutationFunction<RemoveAllMutation, RemoveAllMutationVariables>;

/**
 * __useRemoveAllMutation__
 *
 * To run a mutation, you first call `useRemoveAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllMutation, { data, loading, error }] = useRemoveAllMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAllMutation, RemoveAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAllMutation, RemoveAllMutationVariables>(RemoveAllDocument, options);
      }
export type RemoveAllMutationHookResult = ReturnType<typeof useRemoveAllMutation>;
export type RemoveAllMutationResult = Apollo.MutationResult<RemoveAllMutation>;
export type RemoveAllMutationOptions = Apollo.BaseMutationOptions<RemoveAllMutation, RemoveAllMutationVariables>;
export const RemoveFileUploadByIdDocument = gql`
    mutation removeFileUploadById($id: String!) {
  removeFileUploadById(id: $id) @client
}
    `;
export type RemoveFileUploadByIdMutationFn = Apollo.MutationFunction<RemoveFileUploadByIdMutation, RemoveFileUploadByIdMutationVariables>;

/**
 * __useRemoveFileUploadByIdMutation__
 *
 * To run a mutation, you first call `useRemoveFileUploadByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileUploadByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileUploadByIdMutation, { data, loading, error }] = useRemoveFileUploadByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFileUploadByIdMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFileUploadByIdMutation, RemoveFileUploadByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFileUploadByIdMutation, RemoveFileUploadByIdMutationVariables>(RemoveFileUploadByIdDocument, options);
      }
export type RemoveFileUploadByIdMutationHookResult = ReturnType<typeof useRemoveFileUploadByIdMutation>;
export type RemoveFileUploadByIdMutationResult = Apollo.MutationResult<RemoveFileUploadByIdMutation>;
export type RemoveFileUploadByIdMutationOptions = Apollo.BaseMutationOptions<RemoveFileUploadByIdMutation, RemoveFileUploadByIdMutationVariables>;
export const SoftDeleteFileUploadByFileIdDocument = gql`
    mutation softDeleteFileUploadByFileId($id: String!) {
  softDeleteFileUploadByFileId(id: $id) @client
}
    `;
export type SoftDeleteFileUploadByFileIdMutationFn = Apollo.MutationFunction<SoftDeleteFileUploadByFileIdMutation, SoftDeleteFileUploadByFileIdMutationVariables>;

/**
 * __useSoftDeleteFileUploadByFileIdMutation__
 *
 * To run a mutation, you first call `useSoftDeleteFileUploadByFileIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteFileUploadByFileIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteFileUploadByFileIdMutation, { data, loading, error }] = useSoftDeleteFileUploadByFileIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteFileUploadByFileIdMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteFileUploadByFileIdMutation, SoftDeleteFileUploadByFileIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteFileUploadByFileIdMutation, SoftDeleteFileUploadByFileIdMutationVariables>(SoftDeleteFileUploadByFileIdDocument, options);
      }
export type SoftDeleteFileUploadByFileIdMutationHookResult = ReturnType<typeof useSoftDeleteFileUploadByFileIdMutation>;
export type SoftDeleteFileUploadByFileIdMutationResult = Apollo.MutationResult<SoftDeleteFileUploadByFileIdMutation>;
export type SoftDeleteFileUploadByFileIdMutationOptions = Apollo.BaseMutationOptions<SoftDeleteFileUploadByFileIdMutation, SoftDeleteFileUploadByFileIdMutationVariables>;
export const FileUploadsDocument = gql`
    query fileUploads {
  fileUploads @client {
    id
    createdAt
    fileName
    state
    errorDetails
    documentId
    fileId
    isDeleted
  }
}
    `;

/**
 * __useFileUploadsQuery__
 *
 * To run a query within a React component, call `useFileUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileUploadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFileUploadsQuery(baseOptions?: Apollo.QueryHookOptions<FileUploadsQuery, FileUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileUploadsQuery, FileUploadsQueryVariables>(FileUploadsDocument, options);
      }
export function useFileUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileUploadsQuery, FileUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileUploadsQuery, FileUploadsQueryVariables>(FileUploadsDocument, options);
        }
export type FileUploadsQueryHookResult = ReturnType<typeof useFileUploadsQuery>;
export type FileUploadsLazyQueryHookResult = ReturnType<typeof useFileUploadsLazyQuery>;
export type FileUploadsQueryResult = Apollo.QueryResult<FileUploadsQuery, FileUploadsQueryVariables>;
export const FileUploadModalDocument = gql`
    query fileUploadModal {
  fileUploadModal @client {
    uploadModalVisible
  }
}
    `;

/**
 * __useFileUploadModalQuery__
 *
 * To run a query within a React component, call `useFileUploadModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileUploadModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileUploadModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useFileUploadModalQuery(baseOptions?: Apollo.QueryHookOptions<FileUploadModalQuery, FileUploadModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileUploadModalQuery, FileUploadModalQueryVariables>(FileUploadModalDocument, options);
      }
export function useFileUploadModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileUploadModalQuery, FileUploadModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileUploadModalQuery, FileUploadModalQueryVariables>(FileUploadModalDocument, options);
        }
export type FileUploadModalQueryHookResult = ReturnType<typeof useFileUploadModalQuery>;
export type FileUploadModalLazyQueryHookResult = ReturnType<typeof useFileUploadModalLazyQuery>;
export type FileUploadModalQueryResult = Apollo.QueryResult<FileUploadModalQuery, FileUploadModalQueryVariables>;
export const OrganizationDocument = gql`
    query organization($id: ID) {
  organization(id: $id) {
    id
    realmName
    name
    hasCostCenters
    hasCostObjects
    hasExtraCostInfos
    hasActiveBookingAccounts
    hasActiveBookingKeys
    hasActiveWorkflowTemplates
    hasTransactionsActivated
    permissionsForMembership
    hasContacts
    slug
    salesforceAccountId
    locale
    chargebeeSubscriptionId
    permissionsForMembership
    payment {
      isActive
      startDate
    }
    creditCardsDetails {
      paymentFrequency
    }
    creditCardsSettings {
      tradeName
      legalForm
      foundationDate
      country
      accountType
      nationalRegisterCourt
      nationalRegisterId
      nationalRegisterType
      tradeRegisterNumber
      vatId
      billingEmail
      isCompanyNamePrintable
      deliveryAddressSameAsBillingAddress
      billingAddress {
        country
        city
        postalCode
        streetFull
        streetName
        streetNumber
        streetAdditional
      }
      deliveryAddress {
        country
        city
        postalCode
        streetFull
        streetName
        streetNumber
        streetAdditional
      }
      processingAccount {
        bankName
        iban
      }
    }
    exportConfiguration {
      isReweEnabled
      fiscalYearStartDay
      fiscalYearStartMonth
    }
    integrationName
    hasIdpSSO
    idpConfig {
      isMembershipRolesUpdatedViaSSO
      isUserDataUpdatedViaSSO
    }
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const SetConsentForTrackingConfigurationDocument = gql`
    mutation setConsentForTrackingConfiguration($trackingEnabled: Boolean!, $intercom: Boolean!) {
  setConsentForTrackingConfiguration(
    trackingEnabled: $trackingEnabled
    intercom: $intercom
  )
}
    `;
export type SetConsentForTrackingConfigurationMutationFn = Apollo.MutationFunction<SetConsentForTrackingConfigurationMutation, SetConsentForTrackingConfigurationMutationVariables>;

/**
 * __useSetConsentForTrackingConfigurationMutation__
 *
 * To run a mutation, you first call `useSetConsentForTrackingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConsentForTrackingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConsentForTrackingConfigurationMutation, { data, loading, error }] = useSetConsentForTrackingConfigurationMutation({
 *   variables: {
 *      trackingEnabled: // value for 'trackingEnabled'
 *      intercom: // value for 'intercom'
 *   },
 * });
 */
export function useSetConsentForTrackingConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<SetConsentForTrackingConfigurationMutation, SetConsentForTrackingConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConsentForTrackingConfigurationMutation, SetConsentForTrackingConfigurationMutationVariables>(SetConsentForTrackingConfigurationDocument, options);
      }
export type SetConsentForTrackingConfigurationMutationHookResult = ReturnType<typeof useSetConsentForTrackingConfigurationMutation>;
export type SetConsentForTrackingConfigurationMutationResult = Apollo.MutationResult<SetConsentForTrackingConfigurationMutation>;
export type SetConsentForTrackingConfigurationMutationOptions = Apollo.BaseMutationOptions<SetConsentForTrackingConfigurationMutation, SetConsentForTrackingConfigurationMutationVariables>;
export const GetOrganizationsOverallTaskSummaryDocument = gql`
    query getOrganizationsOverallTaskSummary {
  getOrganizationsOverallTaskSummary {
    total
    overdue
  }
}
    `;

/**
 * __useGetOrganizationsOverallTaskSummaryQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsOverallTaskSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsOverallTaskSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsOverallTaskSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsOverallTaskSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsOverallTaskSummaryQuery, GetOrganizationsOverallTaskSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsOverallTaskSummaryQuery, GetOrganizationsOverallTaskSummaryQueryVariables>(GetOrganizationsOverallTaskSummaryDocument, options);
      }
export function useGetOrganizationsOverallTaskSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsOverallTaskSummaryQuery, GetOrganizationsOverallTaskSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsOverallTaskSummaryQuery, GetOrganizationsOverallTaskSummaryQueryVariables>(GetOrganizationsOverallTaskSummaryDocument, options);
        }
export type GetOrganizationsOverallTaskSummaryQueryHookResult = ReturnType<typeof useGetOrganizationsOverallTaskSummaryQuery>;
export type GetOrganizationsOverallTaskSummaryLazyQueryHookResult = ReturnType<typeof useGetOrganizationsOverallTaskSummaryLazyQuery>;
export type GetOrganizationsOverallTaskSummaryQueryResult = Apollo.QueryResult<GetOrganizationsOverallTaskSummaryQuery, GetOrganizationsOverallTaskSummaryQueryVariables>;
export const GetOrganizationsInsightsDetailsDocument = gql`
    query getOrganizationsInsightsDetails($ids: [ID!]) {
  getOrganizationsInsightsDetails(ids: $ids) {
    organization {
      id
      name
      slug
    }
    membership {
      id
    }
    summary {
      total
      overdue
    }
    toProcess {
      total
      overdue
    }
    toApprove {
      total
      overdue
    }
    toExport {
      total
      overdue
    }
    monitoringMyRequests {
      toApprove {
        overdue
        total
      }
    }
    reimbursementCases {
      toReview {
        total
        overdue
      }
      toCheck {
        total
        overdue
      }
      toApprove {
        total
        overdue
      }
      toExport {
        total
        overdue
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationsInsightsDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsInsightsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsInsightsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsInsightsDetailsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetOrganizationsInsightsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsInsightsDetailsQuery, GetOrganizationsInsightsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsInsightsDetailsQuery, GetOrganizationsInsightsDetailsQueryVariables>(GetOrganizationsInsightsDetailsDocument, options);
      }
export function useGetOrganizationsInsightsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsInsightsDetailsQuery, GetOrganizationsInsightsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsInsightsDetailsQuery, GetOrganizationsInsightsDetailsQueryVariables>(GetOrganizationsInsightsDetailsDocument, options);
        }
export type GetOrganizationsInsightsDetailsQueryHookResult = ReturnType<typeof useGetOrganizationsInsightsDetailsQuery>;
export type GetOrganizationsInsightsDetailsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsInsightsDetailsLazyQuery>;
export type GetOrganizationsInsightsDetailsQueryResult = Apollo.QueryResult<GetOrganizationsInsightsDetailsQuery, GetOrganizationsInsightsDetailsQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications {
  notifications {
    records {
      id
      data {
        ... on EmailImportErrorNotification {
          fromAddress
          emailMessageId
        }
      }
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const ClearAllNotificationsDocument = gql`
    mutation clearAllNotifications {
  clearAllNotifications
}
    `;
export type ClearAllNotificationsMutationFn = Apollo.MutationFunction<ClearAllNotificationsMutation, ClearAllNotificationsMutationVariables>;

/**
 * __useClearAllNotificationsMutation__
 *
 * To run a mutation, you first call `useClearAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAllNotificationsMutation, { data, loading, error }] = useClearAllNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ClearAllNotificationsMutation, ClearAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearAllNotificationsMutation, ClearAllNotificationsMutationVariables>(ClearAllNotificationsDocument, options);
      }
export type ClearAllNotificationsMutationHookResult = ReturnType<typeof useClearAllNotificationsMutation>;
export type ClearAllNotificationsMutationResult = Apollo.MutationResult<ClearAllNotificationsMutation>;
export type ClearAllNotificationsMutationOptions = Apollo.BaseMutationOptions<ClearAllNotificationsMutation, ClearAllNotificationsMutationVariables>;
export const GenerateTestTransactionsDocument = gql`
    mutation generateTestTransactions($input: CardIssuerGenerateTestTransactionsInput!) {
  generateTestTransactions(input: $input)
}
    `;
export type GenerateTestTransactionsMutationFn = Apollo.MutationFunction<GenerateTestTransactionsMutation, GenerateTestTransactionsMutationVariables>;

/**
 * __useGenerateTestTransactionsMutation__
 *
 * To run a mutation, you first call `useGenerateTestTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTestTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTestTransactionsMutation, { data, loading, error }] = useGenerateTestTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTestTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTestTransactionsMutation, GenerateTestTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTestTransactionsMutation, GenerateTestTransactionsMutationVariables>(GenerateTestTransactionsDocument, options);
      }
export type GenerateTestTransactionsMutationHookResult = ReturnType<typeof useGenerateTestTransactionsMutation>;
export type GenerateTestTransactionsMutationResult = Apollo.MutationResult<GenerateTestTransactionsMutation>;
export type GenerateTestTransactionsMutationOptions = Apollo.BaseMutationOptions<GenerateTestTransactionsMutation, GenerateTestTransactionsMutationVariables>;
export const SetCurrentUserAbsenceDocument = gql`
    mutation setCurrentUserAbsence($input: SetCurrentUserAbsenceInput!) {
  setCurrentUserAbsence(input: $input) {
    ... on Absence {
      fromDate
      toDate
      status
      note
      substituteMembershipId
    }
    ... on SetAbsenceError {
      kind
      message
    }
  }
}
    `;
export type SetCurrentUserAbsenceMutationFn = Apollo.MutationFunction<SetCurrentUserAbsenceMutation, SetCurrentUserAbsenceMutationVariables>;

/**
 * __useSetCurrentUserAbsenceMutation__
 *
 * To run a mutation, you first call `useSetCurrentUserAbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentUserAbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentUserAbsenceMutation, { data, loading, error }] = useSetCurrentUserAbsenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCurrentUserAbsenceMutation(baseOptions?: Apollo.MutationHookOptions<SetCurrentUserAbsenceMutation, SetCurrentUserAbsenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCurrentUserAbsenceMutation, SetCurrentUserAbsenceMutationVariables>(SetCurrentUserAbsenceDocument, options);
      }
export type SetCurrentUserAbsenceMutationHookResult = ReturnType<typeof useSetCurrentUserAbsenceMutation>;
export type SetCurrentUserAbsenceMutationResult = Apollo.MutationResult<SetCurrentUserAbsenceMutation>;
export type SetCurrentUserAbsenceMutationOptions = Apollo.BaseMutationOptions<SetCurrentUserAbsenceMutation, SetCurrentUserAbsenceMutationVariables>;
export const SetAbsenceForMemberDocument = gql`
    mutation setAbsenceForMember($input: SetAbsenceForTeamMemberInput!) {
  setAbsenceForMember(input: $input) {
    ... on Absence {
      fromDate
      toDate
      status
      note
      membershipId
      substituteMembershipId
    }
    ... on SetAbsenceError {
      kind
      message
    }
  }
}
    `;
export type SetAbsenceForMemberMutationFn = Apollo.MutationFunction<SetAbsenceForMemberMutation, SetAbsenceForMemberMutationVariables>;

/**
 * __useSetAbsenceForMemberMutation__
 *
 * To run a mutation, you first call `useSetAbsenceForMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAbsenceForMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAbsenceForMemberMutation, { data, loading, error }] = useSetAbsenceForMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAbsenceForMemberMutation(baseOptions?: Apollo.MutationHookOptions<SetAbsenceForMemberMutation, SetAbsenceForMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAbsenceForMemberMutation, SetAbsenceForMemberMutationVariables>(SetAbsenceForMemberDocument, options);
      }
export type SetAbsenceForMemberMutationHookResult = ReturnType<typeof useSetAbsenceForMemberMutation>;
export type SetAbsenceForMemberMutationResult = Apollo.MutationResult<SetAbsenceForMemberMutation>;
export type SetAbsenceForMemberMutationOptions = Apollo.BaseMutationOptions<SetAbsenceForMemberMutation, SetAbsenceForMemberMutationVariables>;
export const GetCurrentUserAbsenceDocument = gql`
    query getCurrentUserAbsence {
  getCurrentUserAbsence {
    fromDate
    toDate
    status
    note
    substituteMembershipId
  }
}
    `;

/**
 * __useGetCurrentUserAbsenceQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserAbsenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserAbsenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserAbsenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserAbsenceQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserAbsenceQuery, GetCurrentUserAbsenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserAbsenceQuery, GetCurrentUserAbsenceQueryVariables>(GetCurrentUserAbsenceDocument, options);
      }
export function useGetCurrentUserAbsenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserAbsenceQuery, GetCurrentUserAbsenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserAbsenceQuery, GetCurrentUserAbsenceQueryVariables>(GetCurrentUserAbsenceDocument, options);
        }
export type GetCurrentUserAbsenceQueryHookResult = ReturnType<typeof useGetCurrentUserAbsenceQuery>;
export type GetCurrentUserAbsenceLazyQueryHookResult = ReturnType<typeof useGetCurrentUserAbsenceLazyQuery>;
export type GetCurrentUserAbsenceQueryResult = Apollo.QueryResult<GetCurrentUserAbsenceQuery, GetCurrentUserAbsenceQueryVariables>;
export const GetMemberAbsenceDocument = gql`
    query getMemberAbsence($id: String!) {
  getMemberAbsence(id: $id) {
    fromDate
    toDate
    status
    note
    membershipId
    substituteMembershipId
  }
}
    `;

/**
 * __useGetMemberAbsenceQuery__
 *
 * To run a query within a React component, call `useGetMemberAbsenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberAbsenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberAbsenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberAbsenceQuery(baseOptions: Apollo.QueryHookOptions<GetMemberAbsenceQuery, GetMemberAbsenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberAbsenceQuery, GetMemberAbsenceQueryVariables>(GetMemberAbsenceDocument, options);
      }
export function useGetMemberAbsenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberAbsenceQuery, GetMemberAbsenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberAbsenceQuery, GetMemberAbsenceQueryVariables>(GetMemberAbsenceDocument, options);
        }
export type GetMemberAbsenceQueryHookResult = ReturnType<typeof useGetMemberAbsenceQuery>;
export type GetMemberAbsenceLazyQueryHookResult = ReturnType<typeof useGetMemberAbsenceLazyQuery>;
export type GetMemberAbsenceQueryResult = Apollo.QueryResult<GetMemberAbsenceQuery, GetMemberAbsenceQueryVariables>;
export const RequestUploadAvatarUrlDocument = gql`
    mutation requestUploadAvatarUrl {
  requestUploadAvatarUrl {
    uploadUrl
    downloadUrl
    postOptions {
      field
      value
    }
  }
}
    `;
export type RequestUploadAvatarUrlMutationFn = Apollo.MutationFunction<RequestUploadAvatarUrlMutation, RequestUploadAvatarUrlMutationVariables>;

/**
 * __useRequestUploadAvatarUrlMutation__
 *
 * To run a mutation, you first call `useRequestUploadAvatarUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUploadAvatarUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUploadAvatarUrlMutation, { data, loading, error }] = useRequestUploadAvatarUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestUploadAvatarUrlMutation(baseOptions?: Apollo.MutationHookOptions<RequestUploadAvatarUrlMutation, RequestUploadAvatarUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestUploadAvatarUrlMutation, RequestUploadAvatarUrlMutationVariables>(RequestUploadAvatarUrlDocument, options);
      }
export type RequestUploadAvatarUrlMutationHookResult = ReturnType<typeof useRequestUploadAvatarUrlMutation>;
export type RequestUploadAvatarUrlMutationResult = Apollo.MutationResult<RequestUploadAvatarUrlMutation>;
export type RequestUploadAvatarUrlMutationOptions = Apollo.BaseMutationOptions<RequestUploadAvatarUrlMutation, RequestUploadAvatarUrlMutationVariables>;
export const RemoveAvatarDocument = gql`
    mutation removeAvatar($membershipId: String!) {
  removeAvatar(membershipId: $membershipId)
}
    `;
export type RemoveAvatarMutationFn = Apollo.MutationFunction<RemoveAvatarMutation, RemoveAvatarMutationVariables>;

/**
 * __useRemoveAvatarMutation__
 *
 * To run a mutation, you first call `useRemoveAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAvatarMutation, { data, loading, error }] = useRemoveAvatarMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useRemoveAvatarMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAvatarMutation, RemoveAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAvatarMutation, RemoveAvatarMutationVariables>(RemoveAvatarDocument, options);
      }
export type RemoveAvatarMutationHookResult = ReturnType<typeof useRemoveAvatarMutation>;
export type RemoveAvatarMutationResult = Apollo.MutationResult<RemoveAvatarMutation>;
export type RemoveAvatarMutationOptions = Apollo.BaseMutationOptions<RemoveAvatarMutation, RemoveAvatarMutationVariables>;
export const UpdateAvatarDocument = gql`
    mutation updateAvatar($id: String!, $url: String!) {
  updateAvatar(id: $id, url: $url)
}
    `;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<UpdateAvatarMutation, UpdateAvatarMutationVariables>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUpdateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
      }
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>;
export const PendingChangeMembershipEmailDocument = gql`
    query pendingChangeMembershipEmail($id: String!) {
  pendingChangeMembershipEmail(id: $id) {
    newEmail
  }
}
    `;

/**
 * __usePendingChangeMembershipEmailQuery__
 *
 * To run a query within a React component, call `usePendingChangeMembershipEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingChangeMembershipEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingChangeMembershipEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePendingChangeMembershipEmailQuery(baseOptions: Apollo.QueryHookOptions<PendingChangeMembershipEmailQuery, PendingChangeMembershipEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingChangeMembershipEmailQuery, PendingChangeMembershipEmailQueryVariables>(PendingChangeMembershipEmailDocument, options);
      }
export function usePendingChangeMembershipEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingChangeMembershipEmailQuery, PendingChangeMembershipEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingChangeMembershipEmailQuery, PendingChangeMembershipEmailQueryVariables>(PendingChangeMembershipEmailDocument, options);
        }
export type PendingChangeMembershipEmailQueryHookResult = ReturnType<typeof usePendingChangeMembershipEmailQuery>;
export type PendingChangeMembershipEmailLazyQueryHookResult = ReturnType<typeof usePendingChangeMembershipEmailLazyQuery>;
export type PendingChangeMembershipEmailQueryResult = Apollo.QueryResult<PendingChangeMembershipEmailQuery, PendingChangeMembershipEmailQueryVariables>;
export const RequestChangeUserEmailDocument = gql`
    mutation requestChangeUserEmail($input: RequestChangeUserEmailInput!) {
  requestChangeUserEmail(input: $input) {
    __typename
    ... on RequestChangeUserEmailSucceeded {
      email
    }
    ... on RequestChangeUserEmailConflict {
      email
    }
  }
}
    `;
export type RequestChangeUserEmailMutationFn = Apollo.MutationFunction<RequestChangeUserEmailMutation, RequestChangeUserEmailMutationVariables>;

/**
 * __useRequestChangeUserEmailMutation__
 *
 * To run a mutation, you first call `useRequestChangeUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangeUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangeUserEmailMutation, { data, loading, error }] = useRequestChangeUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestChangeUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<RequestChangeUserEmailMutation, RequestChangeUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestChangeUserEmailMutation, RequestChangeUserEmailMutationVariables>(RequestChangeUserEmailDocument, options);
      }
export type RequestChangeUserEmailMutationHookResult = ReturnType<typeof useRequestChangeUserEmailMutation>;
export type RequestChangeUserEmailMutationResult = Apollo.MutationResult<RequestChangeUserEmailMutation>;
export type RequestChangeUserEmailMutationOptions = Apollo.BaseMutationOptions<RequestChangeUserEmailMutation, RequestChangeUserEmailMutationVariables>;
export const CancelRequestChangeUserEmailDocument = gql`
    mutation cancelRequestChangeUserEmail($membershipId: String!) {
  cancelRequestChangeUserEmail(id: $membershipId)
}
    `;
export type CancelRequestChangeUserEmailMutationFn = Apollo.MutationFunction<CancelRequestChangeUserEmailMutation, CancelRequestChangeUserEmailMutationVariables>;

/**
 * __useCancelRequestChangeUserEmailMutation__
 *
 * To run a mutation, you first call `useCancelRequestChangeUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRequestChangeUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRequestChangeUserEmailMutation, { data, loading, error }] = useCancelRequestChangeUserEmailMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useCancelRequestChangeUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<CancelRequestChangeUserEmailMutation, CancelRequestChangeUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRequestChangeUserEmailMutation, CancelRequestChangeUserEmailMutationVariables>(CancelRequestChangeUserEmailDocument, options);
      }
export type CancelRequestChangeUserEmailMutationHookResult = ReturnType<typeof useCancelRequestChangeUserEmailMutation>;
export type CancelRequestChangeUserEmailMutationResult = Apollo.MutationResult<CancelRequestChangeUserEmailMutation>;
export type CancelRequestChangeUserEmailMutationOptions = Apollo.BaseMutationOptions<CancelRequestChangeUserEmailMutation, CancelRequestChangeUserEmailMutationVariables>;
export const ResendChangeEmailRequestVerificationEmailDocument = gql`
    mutation resendChangeEmailRequestVerificationEmail($membershipId: String!) {
  resendChangeEmailRequestVerificationEmail(id: $membershipId)
}
    `;
export type ResendChangeEmailRequestVerificationEmailMutationFn = Apollo.MutationFunction<ResendChangeEmailRequestVerificationEmailMutation, ResendChangeEmailRequestVerificationEmailMutationVariables>;

/**
 * __useResendChangeEmailRequestVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendChangeEmailRequestVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendChangeEmailRequestVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendChangeEmailRequestVerificationEmailMutation, { data, loading, error }] = useResendChangeEmailRequestVerificationEmailMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useResendChangeEmailRequestVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendChangeEmailRequestVerificationEmailMutation, ResendChangeEmailRequestVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendChangeEmailRequestVerificationEmailMutation, ResendChangeEmailRequestVerificationEmailMutationVariables>(ResendChangeEmailRequestVerificationEmailDocument, options);
      }
export type ResendChangeEmailRequestVerificationEmailMutationHookResult = ReturnType<typeof useResendChangeEmailRequestVerificationEmailMutation>;
export type ResendChangeEmailRequestVerificationEmailMutationResult = Apollo.MutationResult<ResendChangeEmailRequestVerificationEmailMutation>;
export type ResendChangeEmailRequestVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendChangeEmailRequestVerificationEmailMutation, ResendChangeEmailRequestVerificationEmailMutationVariables>;
export const PendingPhoneNumberVerificationDocument = gql`
    query pendingPhoneNumberVerification {
  pendingPhoneNumberVerification {
    id
    phoneNumber
  }
}
    `;

/**
 * __usePendingPhoneNumberVerificationQuery__
 *
 * To run a query within a React component, call `usePendingPhoneNumberVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingPhoneNumberVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingPhoneNumberVerificationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingPhoneNumberVerificationQuery(baseOptions?: Apollo.QueryHookOptions<PendingPhoneNumberVerificationQuery, PendingPhoneNumberVerificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingPhoneNumberVerificationQuery, PendingPhoneNumberVerificationQueryVariables>(PendingPhoneNumberVerificationDocument, options);
      }
export function usePendingPhoneNumberVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingPhoneNumberVerificationQuery, PendingPhoneNumberVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingPhoneNumberVerificationQuery, PendingPhoneNumberVerificationQueryVariables>(PendingPhoneNumberVerificationDocument, options);
        }
export type PendingPhoneNumberVerificationQueryHookResult = ReturnType<typeof usePendingPhoneNumberVerificationQuery>;
export type PendingPhoneNumberVerificationLazyQueryHookResult = ReturnType<typeof usePendingPhoneNumberVerificationLazyQuery>;
export type PendingPhoneNumberVerificationQueryResult = Apollo.QueryResult<PendingPhoneNumberVerificationQuery, PendingPhoneNumberVerificationQueryVariables>;
export const RequestPhoneNumberVerificationDocument = gql`
    mutation requestPhoneNumberVerification($phoneNumber: String!) {
  requestPhoneNumberVerification(phoneNumber: $phoneNumber) {
    id
    phoneNumber
  }
}
    `;
export type RequestPhoneNumberVerificationMutationFn = Apollo.MutationFunction<RequestPhoneNumberVerificationMutation, RequestPhoneNumberVerificationMutationVariables>;

/**
 * __useRequestPhoneNumberVerificationMutation__
 *
 * To run a mutation, you first call `useRequestPhoneNumberVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPhoneNumberVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPhoneNumberVerificationMutation, { data, loading, error }] = useRequestPhoneNumberVerificationMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRequestPhoneNumberVerificationMutation(baseOptions?: Apollo.MutationHookOptions<RequestPhoneNumberVerificationMutation, RequestPhoneNumberVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPhoneNumberVerificationMutation, RequestPhoneNumberVerificationMutationVariables>(RequestPhoneNumberVerificationDocument, options);
      }
export type RequestPhoneNumberVerificationMutationHookResult = ReturnType<typeof useRequestPhoneNumberVerificationMutation>;
export type RequestPhoneNumberVerificationMutationResult = Apollo.MutationResult<RequestPhoneNumberVerificationMutation>;
export type RequestPhoneNumberVerificationMutationOptions = Apollo.BaseMutationOptions<RequestPhoneNumberVerificationMutation, RequestPhoneNumberVerificationMutationVariables>;
export const CancelPendingPhoneNumberVerificationDocument = gql`
    mutation cancelPendingPhoneNumberVerification {
  cancelPendingPhoneNumberVerification
}
    `;
export type CancelPendingPhoneNumberVerificationMutationFn = Apollo.MutationFunction<CancelPendingPhoneNumberVerificationMutation, CancelPendingPhoneNumberVerificationMutationVariables>;

/**
 * __useCancelPendingPhoneNumberVerificationMutation__
 *
 * To run a mutation, you first call `useCancelPendingPhoneNumberVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPendingPhoneNumberVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPendingPhoneNumberVerificationMutation, { data, loading, error }] = useCancelPendingPhoneNumberVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelPendingPhoneNumberVerificationMutation(baseOptions?: Apollo.MutationHookOptions<CancelPendingPhoneNumberVerificationMutation, CancelPendingPhoneNumberVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPendingPhoneNumberVerificationMutation, CancelPendingPhoneNumberVerificationMutationVariables>(CancelPendingPhoneNumberVerificationDocument, options);
      }
export type CancelPendingPhoneNumberVerificationMutationHookResult = ReturnType<typeof useCancelPendingPhoneNumberVerificationMutation>;
export type CancelPendingPhoneNumberVerificationMutationResult = Apollo.MutationResult<CancelPendingPhoneNumberVerificationMutation>;
export type CancelPendingPhoneNumberVerificationMutationOptions = Apollo.BaseMutationOptions<CancelPendingPhoneNumberVerificationMutation, CancelPendingPhoneNumberVerificationMutationVariables>;
export const ResendPendingPhoneNumberVerificationDocument = gql`
    mutation resendPendingPhoneNumberVerification {
  resendPendingPhoneNumberVerification
}
    `;
export type ResendPendingPhoneNumberVerificationMutationFn = Apollo.MutationFunction<ResendPendingPhoneNumberVerificationMutation, ResendPendingPhoneNumberVerificationMutationVariables>;

/**
 * __useResendPendingPhoneNumberVerificationMutation__
 *
 * To run a mutation, you first call `useResendPendingPhoneNumberVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPendingPhoneNumberVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPendingPhoneNumberVerificationMutation, { data, loading, error }] = useResendPendingPhoneNumberVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendPendingPhoneNumberVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ResendPendingPhoneNumberVerificationMutation, ResendPendingPhoneNumberVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendPendingPhoneNumberVerificationMutation, ResendPendingPhoneNumberVerificationMutationVariables>(ResendPendingPhoneNumberVerificationDocument, options);
      }
export type ResendPendingPhoneNumberVerificationMutationHookResult = ReturnType<typeof useResendPendingPhoneNumberVerificationMutation>;
export type ResendPendingPhoneNumberVerificationMutationResult = Apollo.MutationResult<ResendPendingPhoneNumberVerificationMutation>;
export type ResendPendingPhoneNumberVerificationMutationOptions = Apollo.BaseMutationOptions<ResendPendingPhoneNumberVerificationMutation, ResendPendingPhoneNumberVerificationMutationVariables>;
export const VerifyPhoneNumberDocument = gql`
    mutation verifyPhoneNumber($otpCode: String!) {
  verifyPhoneNumber(otpCode: $otpCode) {
    verified
    phoneNumber
  }
}
    `;
export type VerifyPhoneNumberMutationFn = Apollo.MutationFunction<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>;

/**
 * __useVerifyPhoneNumberMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneNumberMutation, { data, loading, error }] = useVerifyPhoneNumberMutation({
 *   variables: {
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function useVerifyPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>(VerifyPhoneNumberDocument, options);
      }
export type VerifyPhoneNumberMutationHookResult = ReturnType<typeof useVerifyPhoneNumberMutation>;
export type VerifyPhoneNumberMutationResult = Apollo.MutationResult<VerifyPhoneNumberMutation>;
export type VerifyPhoneNumberMutationOptions = Apollo.BaseMutationOptions<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>;
export const AuthenticatorDeviceDocument = gql`
    query authenticatorDevice {
  authenticatorDevice {
    id
    name
  }
}
    `;

/**
 * __useAuthenticatorDeviceQuery__
 *
 * To run a query within a React component, call `useAuthenticatorDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticatorDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatorDeviceQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticatorDeviceQuery(baseOptions?: Apollo.QueryHookOptions<AuthenticatorDeviceQuery, AuthenticatorDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthenticatorDeviceQuery, AuthenticatorDeviceQueryVariables>(AuthenticatorDeviceDocument, options);
      }
export function useAuthenticatorDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthenticatorDeviceQuery, AuthenticatorDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthenticatorDeviceQuery, AuthenticatorDeviceQueryVariables>(AuthenticatorDeviceDocument, options);
        }
export type AuthenticatorDeviceQueryHookResult = ReturnType<typeof useAuthenticatorDeviceQuery>;
export type AuthenticatorDeviceLazyQueryHookResult = ReturnType<typeof useAuthenticatorDeviceLazyQuery>;
export type AuthenticatorDeviceQueryResult = Apollo.QueryResult<AuthenticatorDeviceQuery, AuthenticatorDeviceQueryVariables>;
export const AddAuthenticatorDeviceDocument = gql`
    mutation addAuthenticatorDevice($name: String!) {
  addAuthenticatorDevice(name: $name) {
    id
    name
    secret
    secretQRDataUrl
  }
}
    `;
export type AddAuthenticatorDeviceMutationFn = Apollo.MutationFunction<AddAuthenticatorDeviceMutation, AddAuthenticatorDeviceMutationVariables>;

/**
 * __useAddAuthenticatorDeviceMutation__
 *
 * To run a mutation, you first call `useAddAuthenticatorDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAuthenticatorDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAuthenticatorDeviceMutation, { data, loading, error }] = useAddAuthenticatorDeviceMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddAuthenticatorDeviceMutation(baseOptions?: Apollo.MutationHookOptions<AddAuthenticatorDeviceMutation, AddAuthenticatorDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAuthenticatorDeviceMutation, AddAuthenticatorDeviceMutationVariables>(AddAuthenticatorDeviceDocument, options);
      }
export type AddAuthenticatorDeviceMutationHookResult = ReturnType<typeof useAddAuthenticatorDeviceMutation>;
export type AddAuthenticatorDeviceMutationResult = Apollo.MutationResult<AddAuthenticatorDeviceMutation>;
export type AddAuthenticatorDeviceMutationOptions = Apollo.BaseMutationOptions<AddAuthenticatorDeviceMutation, AddAuthenticatorDeviceMutationVariables>;
export const AddAuthenticatorDeviceProtectedDocument = gql`
    mutation addAuthenticatorDeviceProtected($name: String!) {
  addAuthenticatorDeviceProtected(name: $name) {
    __typename
    ... on PendingAuthenticatorDeviceVerification {
      id
      name
      secret
      secretQRDataUrl
    }
    ... on RecentAuthnRequiredError {
      requiredAuthType
      validForSeconds
    }
  }
}
    `;
export type AddAuthenticatorDeviceProtectedMutationFn = Apollo.MutationFunction<AddAuthenticatorDeviceProtectedMutation, AddAuthenticatorDeviceProtectedMutationVariables>;

/**
 * __useAddAuthenticatorDeviceProtectedMutation__
 *
 * To run a mutation, you first call `useAddAuthenticatorDeviceProtectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAuthenticatorDeviceProtectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAuthenticatorDeviceProtectedMutation, { data, loading, error }] = useAddAuthenticatorDeviceProtectedMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddAuthenticatorDeviceProtectedMutation(baseOptions?: Apollo.MutationHookOptions<AddAuthenticatorDeviceProtectedMutation, AddAuthenticatorDeviceProtectedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAuthenticatorDeviceProtectedMutation, AddAuthenticatorDeviceProtectedMutationVariables>(AddAuthenticatorDeviceProtectedDocument, options);
      }
export type AddAuthenticatorDeviceProtectedMutationHookResult = ReturnType<typeof useAddAuthenticatorDeviceProtectedMutation>;
export type AddAuthenticatorDeviceProtectedMutationResult = Apollo.MutationResult<AddAuthenticatorDeviceProtectedMutation>;
export type AddAuthenticatorDeviceProtectedMutationOptions = Apollo.BaseMutationOptions<AddAuthenticatorDeviceProtectedMutation, AddAuthenticatorDeviceProtectedMutationVariables>;
export const VerifyAuthenticatorDeviceDocument = gql`
    mutation verifyAuthenticatorDevice($deviceId: String!, $token: String!, $name: String) {
  verifyAuthenticatorDevice(deviceId: $deviceId, token: $token, name: $name) {
    verified
  }
}
    `;
export type VerifyAuthenticatorDeviceMutationFn = Apollo.MutationFunction<VerifyAuthenticatorDeviceMutation, VerifyAuthenticatorDeviceMutationVariables>;

/**
 * __useVerifyAuthenticatorDeviceMutation__
 *
 * To run a mutation, you first call `useVerifyAuthenticatorDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAuthenticatorDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAuthenticatorDeviceMutation, { data, loading, error }] = useVerifyAuthenticatorDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      token: // value for 'token'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useVerifyAuthenticatorDeviceMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAuthenticatorDeviceMutation, VerifyAuthenticatorDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAuthenticatorDeviceMutation, VerifyAuthenticatorDeviceMutationVariables>(VerifyAuthenticatorDeviceDocument, options);
      }
export type VerifyAuthenticatorDeviceMutationHookResult = ReturnType<typeof useVerifyAuthenticatorDeviceMutation>;
export type VerifyAuthenticatorDeviceMutationResult = Apollo.MutationResult<VerifyAuthenticatorDeviceMutation>;
export type VerifyAuthenticatorDeviceMutationOptions = Apollo.BaseMutationOptions<VerifyAuthenticatorDeviceMutation, VerifyAuthenticatorDeviceMutationVariables>;
export const UpdateUserPaymentInfoDocument = gql`
    mutation updateUserPaymentInfo($iban: String, $swiftCode: String, $bankAccountNumber: String) {
  updateUserPaymentInfo(
    iban: $iban
    swiftCode: $swiftCode
    bankAccountNumber: $bankAccountNumber
  ) {
    success
  }
}
    `;
export type UpdateUserPaymentInfoMutationFn = Apollo.MutationFunction<UpdateUserPaymentInfoMutation, UpdateUserPaymentInfoMutationVariables>;

/**
 * __useUpdateUserPaymentInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserPaymentInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPaymentInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPaymentInfoMutation, { data, loading, error }] = useUpdateUserPaymentInfoMutation({
 *   variables: {
 *      iban: // value for 'iban'
 *      swiftCode: // value for 'swiftCode'
 *      bankAccountNumber: // value for 'bankAccountNumber'
 *   },
 * });
 */
export function useUpdateUserPaymentInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPaymentInfoMutation, UpdateUserPaymentInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPaymentInfoMutation, UpdateUserPaymentInfoMutationVariables>(UpdateUserPaymentInfoDocument, options);
      }
export type UpdateUserPaymentInfoMutationHookResult = ReturnType<typeof useUpdateUserPaymentInfoMutation>;
export type UpdateUserPaymentInfoMutationResult = Apollo.MutationResult<UpdateUserPaymentInfoMutation>;
export type UpdateUserPaymentInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserPaymentInfoMutation, UpdateUserPaymentInfoMutationVariables>;
export const DocumentFileUploadUrlDocument = gql`
    mutation documentFileUploadUrl($options: UploadOptions!) {
  documentFileUploadUrl(options: $options) {
    id
    url
    file {
      id
      url
      name
    }
    postOptions {
      field
      value
    }
  }
}
    `;
export type DocumentFileUploadUrlMutationFn = Apollo.MutationFunction<DocumentFileUploadUrlMutation, DocumentFileUploadUrlMutationVariables>;

/**
 * __useDocumentFileUploadUrlMutation__
 *
 * To run a mutation, you first call `useDocumentFileUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentFileUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentFileUploadUrlMutation, { data, loading, error }] = useDocumentFileUploadUrlMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDocumentFileUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<DocumentFileUploadUrlMutation, DocumentFileUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentFileUploadUrlMutation, DocumentFileUploadUrlMutationVariables>(DocumentFileUploadUrlDocument, options);
      }
export type DocumentFileUploadUrlMutationHookResult = ReturnType<typeof useDocumentFileUploadUrlMutation>;
export type DocumentFileUploadUrlMutationResult = Apollo.MutationResult<DocumentFileUploadUrlMutation>;
export type DocumentFileUploadUrlMutationOptions = Apollo.BaseMutationOptions<DocumentFileUploadUrlMutation, DocumentFileUploadUrlMutationVariables>;
export const ProcessFileDocument = gql`
    mutation processFile($input: ProcessFileInput!) {
  processFile(input: $input) {
    globalDocumentId
  }
}
    `;
export type ProcessFileMutationFn = Apollo.MutationFunction<ProcessFileMutation, ProcessFileMutationVariables>;

/**
 * __useProcessFileMutation__
 *
 * To run a mutation, you first call `useProcessFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processFileMutation, { data, loading, error }] = useProcessFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessFileMutation(baseOptions?: Apollo.MutationHookOptions<ProcessFileMutation, ProcessFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessFileMutation, ProcessFileMutationVariables>(ProcessFileDocument, options);
      }
export type ProcessFileMutationHookResult = ReturnType<typeof useProcessFileMutation>;
export type ProcessFileMutationResult = Apollo.MutationResult<ProcessFileMutation>;
export type ProcessFileMutationOptions = Apollo.BaseMutationOptions<ProcessFileMutation, ProcessFileMutationVariables>;
export const ProcessDocumentDocument = gql`
    mutation processDocument($fileId: String!, $transactionIds: [String!]) {
  processDocument(fileId: $fileId, transactionIds: $transactionIds) {
    id
  }
}
    `;
export type ProcessDocumentMutationFn = Apollo.MutationFunction<ProcessDocumentMutation, ProcessDocumentMutationVariables>;

/**
 * __useProcessDocumentMutation__
 *
 * To run a mutation, you first call `useProcessDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processDocumentMutation, { data, loading, error }] = useProcessDocumentMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      transactionIds: // value for 'transactionIds'
 *   },
 * });
 */
export function useProcessDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ProcessDocumentMutation, ProcessDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessDocumentMutation, ProcessDocumentMutationVariables>(ProcessDocumentDocument, options);
      }
export type ProcessDocumentMutationHookResult = ReturnType<typeof useProcessDocumentMutation>;
export type ProcessDocumentMutationResult = Apollo.MutationResult<ProcessDocumentMutation>;
export type ProcessDocumentMutationOptions = Apollo.BaseMutationOptions<ProcessDocumentMutation, ProcessDocumentMutationVariables>;
export const ProcessEcmDocumentDocument = gql`
    mutation processEcmDocument($input: ProcessEcmDocumentInput!) {
  processEcmDocument(input: $input) {
    ecmDocumentId
  }
}
    `;
export type ProcessEcmDocumentMutationFn = Apollo.MutationFunction<ProcessEcmDocumentMutation, ProcessEcmDocumentMutationVariables>;

/**
 * __useProcessEcmDocumentMutation__
 *
 * To run a mutation, you first call `useProcessEcmDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessEcmDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processEcmDocumentMutation, { data, loading, error }] = useProcessEcmDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessEcmDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ProcessEcmDocumentMutation, ProcessEcmDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessEcmDocumentMutation, ProcessEcmDocumentMutationVariables>(ProcessEcmDocumentDocument, options);
      }
export type ProcessEcmDocumentMutationHookResult = ReturnType<typeof useProcessEcmDocumentMutation>;
export type ProcessEcmDocumentMutationResult = Apollo.MutationResult<ProcessEcmDocumentMutation>;
export type ProcessEcmDocumentMutationOptions = Apollo.BaseMutationOptions<ProcessEcmDocumentMutation, ProcessEcmDocumentMutationVariables>;
export const GetOrganizationLocalDocument = gql`
    query GetOrganizationLocal($id: ID!) {
  organization(id: $id) {
    locale
  }
}
    `;

/**
 * __useGetOrganizationLocalQuery__
 *
 * To run a query within a React component, call `useGetOrganizationLocalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationLocalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationLocalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationLocalQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationLocalQuery, GetOrganizationLocalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationLocalQuery, GetOrganizationLocalQueryVariables>(GetOrganizationLocalDocument, options);
      }
export function useGetOrganizationLocalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationLocalQuery, GetOrganizationLocalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationLocalQuery, GetOrganizationLocalQueryVariables>(GetOrganizationLocalDocument, options);
        }
export type GetOrganizationLocalQueryHookResult = ReturnType<typeof useGetOrganizationLocalQuery>;
export type GetOrganizationLocalLazyQueryHookResult = ReturnType<typeof useGetOrganizationLocalLazyQuery>;
export type GetOrganizationLocalQueryResult = Apollo.QueryResult<GetOrganizationLocalQuery, GetOrganizationLocalQueryVariables>;
export const CurrentSlackTokenDocument = gql`
    query currentSlackToken {
  currentSlackToken {
    tokenValid
    redirectUrl
  }
}
    `;

/**
 * __useCurrentSlackTokenQuery__
 *
 * To run a query within a React component, call `useCurrentSlackTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSlackTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSlackTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSlackTokenQuery(baseOptions?: Apollo.QueryHookOptions<CurrentSlackTokenQuery, CurrentSlackTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSlackTokenQuery, CurrentSlackTokenQueryVariables>(CurrentSlackTokenDocument, options);
      }
export function useCurrentSlackTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSlackTokenQuery, CurrentSlackTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSlackTokenQuery, CurrentSlackTokenQueryVariables>(CurrentSlackTokenDocument, options);
        }
export type CurrentSlackTokenQueryHookResult = ReturnType<typeof useCurrentSlackTokenQuery>;
export type CurrentSlackTokenLazyQueryHookResult = ReturnType<typeof useCurrentSlackTokenLazyQuery>;
export type CurrentSlackTokenQueryResult = Apollo.QueryResult<CurrentSlackTokenQuery, CurrentSlackTokenQueryVariables>;
export const ObtainNewSlackTokenDocument = gql`
    mutation obtainNewSlackToken($token: ObtainSlackTokenInput!) {
  obtainNewSlackToken(token: $token)
}
    `;
export type ObtainNewSlackTokenMutationFn = Apollo.MutationFunction<ObtainNewSlackTokenMutation, ObtainNewSlackTokenMutationVariables>;

/**
 * __useObtainNewSlackTokenMutation__
 *
 * To run a mutation, you first call `useObtainNewSlackTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useObtainNewSlackTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [obtainNewSlackTokenMutation, { data, loading, error }] = useObtainNewSlackTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useObtainNewSlackTokenMutation(baseOptions?: Apollo.MutationHookOptions<ObtainNewSlackTokenMutation, ObtainNewSlackTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ObtainNewSlackTokenMutation, ObtainNewSlackTokenMutationVariables>(ObtainNewSlackTokenDocument, options);
      }
export type ObtainNewSlackTokenMutationHookResult = ReturnType<typeof useObtainNewSlackTokenMutation>;
export type ObtainNewSlackTokenMutationResult = Apollo.MutationResult<ObtainNewSlackTokenMutation>;
export type ObtainNewSlackTokenMutationOptions = Apollo.BaseMutationOptions<ObtainNewSlackTokenMutation, ObtainNewSlackTokenMutationVariables>;
export const DeleteFileUploadAttemptDocument = gql`
    mutation deleteFileUploadAttempt($input: DeleteFileUploadAttemptInput!) {
  deleteFileUploadAttempt(input: $input) {
    id
  }
}
    `;
export type DeleteFileUploadAttemptMutationFn = Apollo.MutationFunction<DeleteFileUploadAttemptMutation, DeleteFileUploadAttemptMutationVariables>;

/**
 * __useDeleteFileUploadAttemptMutation__
 *
 * To run a mutation, you first call `useDeleteFileUploadAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileUploadAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileUploadAttemptMutation, { data, loading, error }] = useDeleteFileUploadAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileUploadAttemptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileUploadAttemptMutation, DeleteFileUploadAttemptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileUploadAttemptMutation, DeleteFileUploadAttemptMutationVariables>(DeleteFileUploadAttemptDocument, options);
      }
export type DeleteFileUploadAttemptMutationHookResult = ReturnType<typeof useDeleteFileUploadAttemptMutation>;
export type DeleteFileUploadAttemptMutationResult = Apollo.MutationResult<DeleteFileUploadAttemptMutation>;
export type DeleteFileUploadAttemptMutationOptions = Apollo.BaseMutationOptions<DeleteFileUploadAttemptMutation, DeleteFileUploadAttemptMutationVariables>;
export const GetFileUploadAttemptsDocument = gql`
    query getFileUploadAttempts {
  getFileUploadAttempts {
    id
    createdAt
    documentId
    documentType
    errorMessages
    file {
      id
      name
      size
      contentType
    }
    globalDocumentId
    status
    updatedAt
    wasDeleted
  }
}
    `;

/**
 * __useGetFileUploadAttemptsQuery__
 *
 * To run a query within a React component, call `useGetFileUploadAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUploadAttemptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFileUploadAttemptsQuery(baseOptions?: Apollo.QueryHookOptions<GetFileUploadAttemptsQuery, GetFileUploadAttemptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileUploadAttemptsQuery, GetFileUploadAttemptsQueryVariables>(GetFileUploadAttemptsDocument, options);
      }
export function useGetFileUploadAttemptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileUploadAttemptsQuery, GetFileUploadAttemptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileUploadAttemptsQuery, GetFileUploadAttemptsQueryVariables>(GetFileUploadAttemptsDocument, options);
        }
export type GetFileUploadAttemptsQueryHookResult = ReturnType<typeof useGetFileUploadAttemptsQuery>;
export type GetFileUploadAttemptsLazyQueryHookResult = ReturnType<typeof useGetFileUploadAttemptsLazyQuery>;
export type GetFileUploadAttemptsQueryResult = Apollo.QueryResult<GetFileUploadAttemptsQuery, GetFileUploadAttemptsQueryVariables>;
export const ListApprovalsDocumentsDocument = gql`
    query listApprovalsDocuments($limit: Int, $after: String, $filters: DocumentFilterInput, $sort: SortInput, $withPurchaseOrderData: Boolean = false, $query: String) {
  listApprovalsDocuments(
    limit: $limit
    after: $after
    filters: $filters
    sort: $sort
    query: $query
  ) @connection(key: "approvalDocumentsPagination", filter: ["filters", "sort", "query"]) {
    edges {
      cursor
      node {
        ...DocumentListData
      }
    }
    pageInfo {
      startCursor
      endCursor
      totalCount
      hasNextPage
    }
  }
}
    ${DocumentListDataFragmentDoc}
${TagFragmentDoc}`;

/**
 * __useListApprovalsDocumentsQuery__
 *
 * To run a query within a React component, call `useListApprovalsDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApprovalsDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApprovalsDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useListApprovalsDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ListApprovalsDocumentsQuery, ListApprovalsDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListApprovalsDocumentsQuery, ListApprovalsDocumentsQueryVariables>(ListApprovalsDocumentsDocument, options);
      }
export function useListApprovalsDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApprovalsDocumentsQuery, ListApprovalsDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListApprovalsDocumentsQuery, ListApprovalsDocumentsQueryVariables>(ListApprovalsDocumentsDocument, options);
        }
export type ListApprovalsDocumentsQueryHookResult = ReturnType<typeof useListApprovalsDocumentsQuery>;
export type ListApprovalsDocumentsLazyQueryHookResult = ReturnType<typeof useListApprovalsDocumentsLazyQuery>;
export type ListApprovalsDocumentsQueryResult = Apollo.QueryResult<ListApprovalsDocumentsQuery, ListApprovalsDocumentsQueryVariables>;
export const ListApprovalsDocumentsBasicDocument = gql`
    query listApprovalsDocumentsBasic($limit: Int, $before: String, $after: String, $filters: DocumentFilterInput, $sort: SortInput) {
  listApprovalsDocuments(
    limit: $limit
    before: $before
    after: $after
    filters: $filters
    sort: $sort
  ) @connection(key: "approvalsBasic", filter: ["filters", "sort"]) {
    ...BasicDocumentConnectionData
  }
}
    ${BasicDocumentConnectionDataFragmentDoc}`;

/**
 * __useListApprovalsDocumentsBasicQuery__
 *
 * To run a query within a React component, call `useListApprovalsDocumentsBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApprovalsDocumentsBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApprovalsDocumentsBasicQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListApprovalsDocumentsBasicQuery(baseOptions?: Apollo.QueryHookOptions<ListApprovalsDocumentsBasicQuery, ListApprovalsDocumentsBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListApprovalsDocumentsBasicQuery, ListApprovalsDocumentsBasicQueryVariables>(ListApprovalsDocumentsBasicDocument, options);
      }
export function useListApprovalsDocumentsBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApprovalsDocumentsBasicQuery, ListApprovalsDocumentsBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListApprovalsDocumentsBasicQuery, ListApprovalsDocumentsBasicQueryVariables>(ListApprovalsDocumentsBasicDocument, options);
        }
export type ListApprovalsDocumentsBasicQueryHookResult = ReturnType<typeof useListApprovalsDocumentsBasicQuery>;
export type ListApprovalsDocumentsBasicLazyQueryHookResult = ReturnType<typeof useListApprovalsDocumentsBasicLazyQuery>;
export type ListApprovalsDocumentsBasicQueryResult = Apollo.QueryResult<ListApprovalsDocumentsBasicQuery, ListApprovalsDocumentsBasicQueryVariables>;
export const GetApprovalInvoiceDocumentsDocument = gql`
    query getApprovalInvoiceDocuments($limit: Int, $after: String, $filters: ApprovalInvoicesFilterInput, $sort: SortInput, $withPurchaseOrderData: Boolean = false, $withAccountsReceivableNumber: Boolean = false, $query: String, $searchInput: SearchInput) {
  approvalInvoiceDocuments(
    limit: $limit
    after: $after
    filters: $filters
    sort: $sort
    query: $query
    searchInput: $searchInput
  ) @connection(key: "approvalDocumentsPaginationV2", filter: ["filters", "sort", "query", "searchInput"]) {
    edges {
      cursor
      node {
        ...ApprovalInvoiceDocumentData
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      totalCount
      hasNextPage
    }
  }
}
    ${ApprovalInvoiceDocumentDataFragmentDoc}`;

/**
 * __useGetApprovalInvoiceDocumentsQuery__
 *
 * To run a query within a React component, call `useGetApprovalInvoiceDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalInvoiceDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalInvoiceDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *      withAccountsReceivableNumber: // value for 'withAccountsReceivableNumber'
 *      query: // value for 'query'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetApprovalInvoiceDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalInvoiceDocumentsQuery, GetApprovalInvoiceDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalInvoiceDocumentsQuery, GetApprovalInvoiceDocumentsQueryVariables>(GetApprovalInvoiceDocumentsDocument, options);
      }
export function useGetApprovalInvoiceDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalInvoiceDocumentsQuery, GetApprovalInvoiceDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalInvoiceDocumentsQuery, GetApprovalInvoiceDocumentsQueryVariables>(GetApprovalInvoiceDocumentsDocument, options);
        }
export type GetApprovalInvoiceDocumentsQueryHookResult = ReturnType<typeof useGetApprovalInvoiceDocumentsQuery>;
export type GetApprovalInvoiceDocumentsLazyQueryHookResult = ReturnType<typeof useGetApprovalInvoiceDocumentsLazyQuery>;
export type GetApprovalInvoiceDocumentsQueryResult = Apollo.QueryResult<GetApprovalInvoiceDocumentsQuery, GetApprovalInvoiceDocumentsQueryVariables>;
export const GetApprovalInvoiceDocumentsBasicsDocument = gql`
    query getApprovalInvoiceDocumentsBasics($limit: Int, $before: String, $after: String, $filters: ApprovalInvoicesFilterInput, $sort: SortInput, $searchInput: SearchInput) {
  approvalInvoiceDocuments(
    limit: $limit
    before: $before
    after: $after
    filters: $filters
    sort: $sort
    searchInput: $searchInput
  ) @connection(key: "approvalsBasic", filter: ["filters", "sort", "searchInput"]) {
    ...BasicApprovalDocumentConnectionData
  }
}
    ${BasicApprovalDocumentConnectionDataFragmentDoc}`;

/**
 * __useGetApprovalInvoiceDocumentsBasicsQuery__
 *
 * To run a query within a React component, call `useGetApprovalInvoiceDocumentsBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalInvoiceDocumentsBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalInvoiceDocumentsBasicsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetApprovalInvoiceDocumentsBasicsQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalInvoiceDocumentsBasicsQuery, GetApprovalInvoiceDocumentsBasicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalInvoiceDocumentsBasicsQuery, GetApprovalInvoiceDocumentsBasicsQueryVariables>(GetApprovalInvoiceDocumentsBasicsDocument, options);
      }
export function useGetApprovalInvoiceDocumentsBasicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalInvoiceDocumentsBasicsQuery, GetApprovalInvoiceDocumentsBasicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalInvoiceDocumentsBasicsQuery, GetApprovalInvoiceDocumentsBasicsQueryVariables>(GetApprovalInvoiceDocumentsBasicsDocument, options);
        }
export type GetApprovalInvoiceDocumentsBasicsQueryHookResult = ReturnType<typeof useGetApprovalInvoiceDocumentsBasicsQuery>;
export type GetApprovalInvoiceDocumentsBasicsLazyQueryHookResult = ReturnType<typeof useGetApprovalInvoiceDocumentsBasicsLazyQuery>;
export type GetApprovalInvoiceDocumentsBasicsQueryResult = Apollo.QueryResult<GetApprovalInvoiceDocumentsBasicsQuery, GetApprovalInvoiceDocumentsBasicsQueryVariables>;
export const ApprovalReimbursementCasesDocument = gql`
    query approvalReimbursementCases($search: String, $sorts: ReimbursementCasesSortInput, $filters: ReimbursementCasesFilterInput, $limit: Int, $next: String, $searchInput: SearchInput) {
  approvalReimbursementCases(
    search: $search
    sorts: $sorts
    filters: $filters
    limit: $limit
    next: $next
    searchInput: $searchInput
  ) {
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
    edges {
      cursor
      node {
        id
        createdAt
        title
        status
        requestedAt
        requester {
          id
          firstName
          lastName
          avatarUrl
        }
        approvers {
          id
          firstName
          lastName
          avatarUrl
        }
        targetMembership {
          id
          firstName
          lastName
          avatarUrl
        }
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
  }
}
    `;

/**
 * __useApprovalReimbursementCasesQuery__
 *
 * To run a query within a React component, call `useApprovalReimbursementCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalReimbursementCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalReimbursementCasesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sorts: // value for 'sorts'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      next: // value for 'next'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useApprovalReimbursementCasesQuery(baseOptions?: Apollo.QueryHookOptions<ApprovalReimbursementCasesQuery, ApprovalReimbursementCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalReimbursementCasesQuery, ApprovalReimbursementCasesQueryVariables>(ApprovalReimbursementCasesDocument, options);
      }
export function useApprovalReimbursementCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalReimbursementCasesQuery, ApprovalReimbursementCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalReimbursementCasesQuery, ApprovalReimbursementCasesQueryVariables>(ApprovalReimbursementCasesDocument, options);
        }
export type ApprovalReimbursementCasesQueryHookResult = ReturnType<typeof useApprovalReimbursementCasesQuery>;
export type ApprovalReimbursementCasesLazyQueryHookResult = ReturnType<typeof useApprovalReimbursementCasesLazyQuery>;
export type ApprovalReimbursementCasesQueryResult = Apollo.QueryResult<ApprovalReimbursementCasesQuery, ApprovalReimbursementCasesQueryVariables>;
export const ApprovalReimbursementCasesCountDocument = gql`
    query approvalReimbursementCasesCount($filters: ReimbursementCasesFilterInput) {
  approvalReimbursementCasesCount(filters: $filters) {
    totalCount
  }
}
    `;

/**
 * __useApprovalReimbursementCasesCountQuery__
 *
 * To run a query within a React component, call `useApprovalReimbursementCasesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalReimbursementCasesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalReimbursementCasesCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useApprovalReimbursementCasesCountQuery(baseOptions?: Apollo.QueryHookOptions<ApprovalReimbursementCasesCountQuery, ApprovalReimbursementCasesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalReimbursementCasesCountQuery, ApprovalReimbursementCasesCountQueryVariables>(ApprovalReimbursementCasesCountDocument, options);
      }
export function useApprovalReimbursementCasesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalReimbursementCasesCountQuery, ApprovalReimbursementCasesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalReimbursementCasesCountQuery, ApprovalReimbursementCasesCountQueryVariables>(ApprovalReimbursementCasesCountDocument, options);
        }
export type ApprovalReimbursementCasesCountQueryHookResult = ReturnType<typeof useApprovalReimbursementCasesCountQuery>;
export type ApprovalReimbursementCasesCountLazyQueryHookResult = ReturnType<typeof useApprovalReimbursementCasesCountLazyQuery>;
export type ApprovalReimbursementCasesCountQueryResult = Apollo.QueryResult<ApprovalReimbursementCasesCountQuery, ApprovalReimbursementCasesCountQueryVariables>;
export const GetExpenseForPreviewDocument = gql`
    query getExpenseForPreview($id: ID!) {
  reimbursementItem(id: $id) {
    __typename
    id
    ...GeneralExpenseItemData
    ...HospitalityExpenseItemData
    ...PerDiemItemData
  }
}
    ${GeneralExpenseItemDataFragmentDoc}
${HospitalityExpenseItemDataFragmentDoc}
${PerDiemItemDataFragmentDoc}`;

/**
 * __useGetExpenseForPreviewQuery__
 *
 * To run a query within a React component, call `useGetExpenseForPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseForPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseForPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExpenseForPreviewQuery(baseOptions: Apollo.QueryHookOptions<GetExpenseForPreviewQuery, GetExpenseForPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpenseForPreviewQuery, GetExpenseForPreviewQueryVariables>(GetExpenseForPreviewDocument, options);
      }
export function useGetExpenseForPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpenseForPreviewQuery, GetExpenseForPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpenseForPreviewQuery, GetExpenseForPreviewQueryVariables>(GetExpenseForPreviewDocument, options);
        }
export type GetExpenseForPreviewQueryHookResult = ReturnType<typeof useGetExpenseForPreviewQuery>;
export type GetExpenseForPreviewLazyQueryHookResult = ReturnType<typeof useGetExpenseForPreviewLazyQuery>;
export type GetExpenseForPreviewQueryResult = Apollo.QueryResult<GetExpenseForPreviewQuery, GetExpenseForPreviewQueryVariables>;
export const _ListArchiveDocumentsDocument = gql`
    query _listArchiveDocuments($limit: Int, $before: String, $after: String, $filters: DocumentFilterInput, $dateRangeFilters: DocumentDateFilterInput, $sort: SortInput, $withPurchaseOrderData: Boolean = false) {
  archivedDocuments(
    limit: $limit
    before: $before
    after: $after
    filters: $filters
    dateRangeFilters: $dateRangeFilters
    sort: $sort
  ) @connection(key: "archivedDocumentsPagination", filter: ["filters", "dateRangeFilters", "sort"]) {
    edges {
      cursor
      node {
        ...DocumentListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${DocumentListDataFragmentDoc}
${TagFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __use_ListArchiveDocumentsQuery__
 *
 * To run a query within a React component, call `use_ListArchiveDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_ListArchiveDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_ListArchiveDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      sort: // value for 'sort'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *   },
 * });
 */
export function use_ListArchiveDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<_ListArchiveDocumentsQuery, _ListArchiveDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<_ListArchiveDocumentsQuery, _ListArchiveDocumentsQueryVariables>(_ListArchiveDocumentsDocument, options);
      }
export function use_ListArchiveDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<_ListArchiveDocumentsQuery, _ListArchiveDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<_ListArchiveDocumentsQuery, _ListArchiveDocumentsQueryVariables>(_ListArchiveDocumentsDocument, options);
        }
export type _ListArchiveDocumentsQueryHookResult = ReturnType<typeof use_ListArchiveDocumentsQuery>;
export type _ListArchiveDocumentsLazyQueryHookResult = ReturnType<typeof use_ListArchiveDocumentsLazyQuery>;
export type _ListArchiveDocumentsQueryResult = Apollo.QueryResult<_ListArchiveDocumentsQuery, _ListArchiveDocumentsQueryVariables>;
export const ListMatchedArchiveDocumentsDocument = gql`
    query listMatchedArchiveDocuments($query: String!, $limit: Int, $before: String, $after: String, $dateRangeFilters: DocumentDateFilterInput, $filters: DocumentFilterInput, $withPurchaseOrderData: Boolean = false) {
  searchDocuments(
    query: $query
    limit: $limit
    before: $before
    after: $after
    filters: $filters
    dateRangeFilters: $dateRangeFilters
  ) @connection(key: "archivedSearchDocuments", filter: ["filters", "dateRangeFilters", "query"]) {
    edges {
      cursor
      node {
        ...DocumentListData
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalCount
    }
  }
}
    ${DocumentListDataFragmentDoc}
${TagFragmentDoc}`;

/**
 * __useListMatchedArchiveDocumentsQuery__
 *
 * To run a query within a React component, call `useListMatchedArchiveDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMatchedArchiveDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMatchedArchiveDocumentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      filters: // value for 'filters'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *   },
 * });
 */
export function useListMatchedArchiveDocumentsQuery(baseOptions: Apollo.QueryHookOptions<ListMatchedArchiveDocumentsQuery, ListMatchedArchiveDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMatchedArchiveDocumentsQuery, ListMatchedArchiveDocumentsQueryVariables>(ListMatchedArchiveDocumentsDocument, options);
      }
export function useListMatchedArchiveDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMatchedArchiveDocumentsQuery, ListMatchedArchiveDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMatchedArchiveDocumentsQuery, ListMatchedArchiveDocumentsQueryVariables>(ListMatchedArchiveDocumentsDocument, options);
        }
export type ListMatchedArchiveDocumentsQueryHookResult = ReturnType<typeof useListMatchedArchiveDocumentsQuery>;
export type ListMatchedArchiveDocumentsLazyQueryHookResult = ReturnType<typeof useListMatchedArchiveDocumentsLazyQuery>;
export type ListMatchedArchiveDocumentsQueryResult = Apollo.QueryResult<ListMatchedArchiveDocumentsQuery, ListMatchedArchiveDocumentsQueryVariables>;
export const ArchiveInvoiceDocumentsDocument = gql`
    query archiveInvoiceDocuments($query: String!, $limit: Int, $before: String, $after: String, $filters: ArchiveInvoiceDocumentsFilterInput, $sort: SortInput, $withPurchaseOrderData: Boolean = false, $withAccountsReceivableNumber: Boolean = false, $searchInput: SearchInput) {
  archiveInvoiceDocuments(
    query: $query
    limit: $limit
    before: $before
    after: $after
    filters: $filters
    sort: $sort
    searchInput: $searchInput
  ) @connection(key: "archiveInvoiceDocuments", filter: ["filters", "query", "sort", "searchInput"]) {
    edges {
      cursor
      node {
        ...ArchiveInvoiceDocumentsData
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalCount
    }
  }
}
    ${ArchiveInvoiceDocumentsDataFragmentDoc}
${TagFragmentDoc}`;

/**
 * __useArchiveInvoiceDocumentsQuery__
 *
 * To run a query within a React component, call `useArchiveInvoiceDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveInvoiceDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveInvoiceDocumentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *      withAccountsReceivableNumber: // value for 'withAccountsReceivableNumber'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useArchiveInvoiceDocumentsQuery(baseOptions: Apollo.QueryHookOptions<ArchiveInvoiceDocumentsQuery, ArchiveInvoiceDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveInvoiceDocumentsQuery, ArchiveInvoiceDocumentsQueryVariables>(ArchiveInvoiceDocumentsDocument, options);
      }
export function useArchiveInvoiceDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveInvoiceDocumentsQuery, ArchiveInvoiceDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveInvoiceDocumentsQuery, ArchiveInvoiceDocumentsQueryVariables>(ArchiveInvoiceDocumentsDocument, options);
        }
export type ArchiveInvoiceDocumentsQueryHookResult = ReturnType<typeof useArchiveInvoiceDocumentsQuery>;
export type ArchiveInvoiceDocumentsLazyQueryHookResult = ReturnType<typeof useArchiveInvoiceDocumentsLazyQuery>;
export type ArchiveInvoiceDocumentsQueryResult = Apollo.QueryResult<ArchiveInvoiceDocumentsQuery, ArchiveInvoiceDocumentsQueryVariables>;
export const UnacknowledgedDocumentDataFileRecordsDocument = gql`
    query unacknowledgedDocumentDataFileRecords {
  unacknowledgedDocumentDataFileRecords {
    id
    isAcknowledged
  }
}
    `;

/**
 * __useUnacknowledgedDocumentDataFileRecordsQuery__
 *
 * To run a query within a React component, call `useUnacknowledgedDocumentDataFileRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnacknowledgedDocumentDataFileRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnacknowledgedDocumentDataFileRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnacknowledgedDocumentDataFileRecordsQuery(baseOptions?: Apollo.QueryHookOptions<UnacknowledgedDocumentDataFileRecordsQuery, UnacknowledgedDocumentDataFileRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnacknowledgedDocumentDataFileRecordsQuery, UnacknowledgedDocumentDataFileRecordsQueryVariables>(UnacknowledgedDocumentDataFileRecordsDocument, options);
      }
export function useUnacknowledgedDocumentDataFileRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnacknowledgedDocumentDataFileRecordsQuery, UnacknowledgedDocumentDataFileRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnacknowledgedDocumentDataFileRecordsQuery, UnacknowledgedDocumentDataFileRecordsQueryVariables>(UnacknowledgedDocumentDataFileRecordsDocument, options);
        }
export type UnacknowledgedDocumentDataFileRecordsQueryHookResult = ReturnType<typeof useUnacknowledgedDocumentDataFileRecordsQuery>;
export type UnacknowledgedDocumentDataFileRecordsLazyQueryHookResult = ReturnType<typeof useUnacknowledgedDocumentDataFileRecordsLazyQuery>;
export type UnacknowledgedDocumentDataFileRecordsQueryResult = Apollo.QueryResult<UnacknowledgedDocumentDataFileRecordsQuery, UnacknowledgedDocumentDataFileRecordsQueryVariables>;
export const MarkDocumentDataFileRecordsAsAcknowledgedDocument = gql`
    mutation markDocumentDataFileRecordsAsAcknowledged($ids: [ID!]!) {
  markDocumentDataFileRecordsAsAcknowledged(ids: $ids)
}
    `;
export type MarkDocumentDataFileRecordsAsAcknowledgedMutationFn = Apollo.MutationFunction<MarkDocumentDataFileRecordsAsAcknowledgedMutation, MarkDocumentDataFileRecordsAsAcknowledgedMutationVariables>;

/**
 * __useMarkDocumentDataFileRecordsAsAcknowledgedMutation__
 *
 * To run a mutation, you first call `useMarkDocumentDataFileRecordsAsAcknowledgedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDocumentDataFileRecordsAsAcknowledgedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDocumentDataFileRecordsAsAcknowledgedMutation, { data, loading, error }] = useMarkDocumentDataFileRecordsAsAcknowledgedMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkDocumentDataFileRecordsAsAcknowledgedMutation(baseOptions?: Apollo.MutationHookOptions<MarkDocumentDataFileRecordsAsAcknowledgedMutation, MarkDocumentDataFileRecordsAsAcknowledgedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkDocumentDataFileRecordsAsAcknowledgedMutation, MarkDocumentDataFileRecordsAsAcknowledgedMutationVariables>(MarkDocumentDataFileRecordsAsAcknowledgedDocument, options);
      }
export type MarkDocumentDataFileRecordsAsAcknowledgedMutationHookResult = ReturnType<typeof useMarkDocumentDataFileRecordsAsAcknowledgedMutation>;
export type MarkDocumentDataFileRecordsAsAcknowledgedMutationResult = Apollo.MutationResult<MarkDocumentDataFileRecordsAsAcknowledgedMutation>;
export type MarkDocumentDataFileRecordsAsAcknowledgedMutationOptions = Apollo.BaseMutationOptions<MarkDocumentDataFileRecordsAsAcknowledgedMutation, MarkDocumentDataFileRecordsAsAcknowledgedMutationVariables>;
export const CreateDataFileForDocumentsDocument = gql`
    mutation createDataFileForDocuments($type: DocumentDataFileType, $filters: DocumentFilterInput, $query: String, $dateRangeFilters: DocumentDateFilterInput, $searchInput: SearchInput) {
  createDataFileForDocuments(
    type: $type
    filters: $filters
    query: $query
    dateRangeFilters: $dateRangeFilters
    searchInput: $searchInput
  ) {
    id
    recordCount
    file {
      id
      name
      url
    }
  }
}
    `;
export type CreateDataFileForDocumentsMutationFn = Apollo.MutationFunction<CreateDataFileForDocumentsMutation, CreateDataFileForDocumentsMutationVariables>;

/**
 * __useCreateDataFileForDocumentsMutation__
 *
 * To run a mutation, you first call `useCreateDataFileForDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataFileForDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataFileForDocumentsMutation, { data, loading, error }] = useCreateDataFileForDocumentsMutation({
 *   variables: {
 *      type: // value for 'type'
 *      filters: // value for 'filters'
 *      query: // value for 'query'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useCreateDataFileForDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataFileForDocumentsMutation, CreateDataFileForDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataFileForDocumentsMutation, CreateDataFileForDocumentsMutationVariables>(CreateDataFileForDocumentsDocument, options);
      }
export type CreateDataFileForDocumentsMutationHookResult = ReturnType<typeof useCreateDataFileForDocumentsMutation>;
export type CreateDataFileForDocumentsMutationResult = Apollo.MutationResult<CreateDataFileForDocumentsMutation>;
export type CreateDataFileForDocumentsMutationOptions = Apollo.BaseMutationOptions<CreateDataFileForDocumentsMutation, CreateDataFileForDocumentsMutationVariables>;
export const CreateDataFileForEcmDocumentsDocument = gql`
    mutation createDataFileForEcmDocuments($type: DocumentDataFileType, $search: String, $sort: EcmSortInput, $filter: EcmFilterInput, $searchInput: SearchInput) {
  createDataFileForEcmDocuments(
    type: $type
    search: $search
    sort: $sort
    filter: $filter
    searchInput: $searchInput
  ) {
    id
    recordCount
    file {
      id
      name
      url
    }
  }
}
    `;
export type CreateDataFileForEcmDocumentsMutationFn = Apollo.MutationFunction<CreateDataFileForEcmDocumentsMutation, CreateDataFileForEcmDocumentsMutationVariables>;

/**
 * __useCreateDataFileForEcmDocumentsMutation__
 *
 * To run a mutation, you first call `useCreateDataFileForEcmDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataFileForEcmDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataFileForEcmDocumentsMutation, { data, loading, error }] = useCreateDataFileForEcmDocumentsMutation({
 *   variables: {
 *      type: // value for 'type'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useCreateDataFileForEcmDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataFileForEcmDocumentsMutation, CreateDataFileForEcmDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataFileForEcmDocumentsMutation, CreateDataFileForEcmDocumentsMutationVariables>(CreateDataFileForEcmDocumentsDocument, options);
      }
export type CreateDataFileForEcmDocumentsMutationHookResult = ReturnType<typeof useCreateDataFileForEcmDocumentsMutation>;
export type CreateDataFileForEcmDocumentsMutationResult = Apollo.MutationResult<CreateDataFileForEcmDocumentsMutation>;
export type CreateDataFileForEcmDocumentsMutationOptions = Apollo.BaseMutationOptions<CreateDataFileForEcmDocumentsMutation, CreateDataFileForEcmDocumentsMutationVariables>;
export const CreateZipFileForDocumentsDocument = gql`
    mutation createZipFileForDocuments($filters: DocumentFilterInput, $query: String, $dateRangeFilters: DocumentDateFilterInput, $searchInput: SearchInput) {
  createPDFZipFileForDocuments(
    filters: $filters
    query: $query
    dateRangeFilters: $dateRangeFilters
    searchInput: $searchInput
  ) {
    ... on DownloadDocumentPDFsError {
      message
      kind
    }
    ... on DownloadDocumentPDFsResult {
      response
    }
  }
}
    `;
export type CreateZipFileForDocumentsMutationFn = Apollo.MutationFunction<CreateZipFileForDocumentsMutation, CreateZipFileForDocumentsMutationVariables>;

/**
 * __useCreateZipFileForDocumentsMutation__
 *
 * To run a mutation, you first call `useCreateZipFileForDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateZipFileForDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createZipFileForDocumentsMutation, { data, loading, error }] = useCreateZipFileForDocumentsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      query: // value for 'query'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useCreateZipFileForDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateZipFileForDocumentsMutation, CreateZipFileForDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateZipFileForDocumentsMutation, CreateZipFileForDocumentsMutationVariables>(CreateZipFileForDocumentsDocument, options);
      }
export type CreateZipFileForDocumentsMutationHookResult = ReturnType<typeof useCreateZipFileForDocumentsMutation>;
export type CreateZipFileForDocumentsMutationResult = Apollo.MutationResult<CreateZipFileForDocumentsMutation>;
export type CreateZipFileForDocumentsMutationOptions = Apollo.BaseMutationOptions<CreateZipFileForDocumentsMutation, CreateZipFileForDocumentsMutationVariables>;
export const CreatePdfZipFileForEcmDocumentsDocument = gql`
    mutation createPDFZipFileForEcmDocuments($filters: EcmFilterInput, $search: String, $sort: EcmSortInput, $searchInput: SearchInput) {
  createPDFZipFileForEcmDocuments(
    filter: $filters
    search: $search
    sort: $sort
    searchInput: $searchInput
  ) {
    ... on DownloadDocumentPDFsError {
      kind
      message
    }
    ... on DownloadDocumentPDFsResult {
      response
    }
  }
}
    `;
export type CreatePdfZipFileForEcmDocumentsMutationFn = Apollo.MutationFunction<CreatePdfZipFileForEcmDocumentsMutation, CreatePdfZipFileForEcmDocumentsMutationVariables>;

/**
 * __useCreatePdfZipFileForEcmDocumentsMutation__
 *
 * To run a mutation, you first call `useCreatePdfZipFileForEcmDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePdfZipFileForEcmDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPdfZipFileForEcmDocumentsMutation, { data, loading, error }] = useCreatePdfZipFileForEcmDocumentsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useCreatePdfZipFileForEcmDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<CreatePdfZipFileForEcmDocumentsMutation, CreatePdfZipFileForEcmDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePdfZipFileForEcmDocumentsMutation, CreatePdfZipFileForEcmDocumentsMutationVariables>(CreatePdfZipFileForEcmDocumentsDocument, options);
      }
export type CreatePdfZipFileForEcmDocumentsMutationHookResult = ReturnType<typeof useCreatePdfZipFileForEcmDocumentsMutation>;
export type CreatePdfZipFileForEcmDocumentsMutationResult = Apollo.MutationResult<CreatePdfZipFileForEcmDocumentsMutation>;
export type CreatePdfZipFileForEcmDocumentsMutationOptions = Apollo.BaseMutationOptions<CreatePdfZipFileForEcmDocumentsMutation, CreatePdfZipFileForEcmDocumentsMutationVariables>;
export const DownloadPdfFilesForArchiveAllDocumentsDocument = gql`
    mutation downloadPdfFilesForArchiveAllDocuments($search: String, $sort: ArchiveAllDocumentsSortInput, $filter: ArchiveAllDocumentsFilterInput, $searchInput: SearchInput) {
  downloadPdfFilesForArchiveAllDocuments(
    search: $search
    filter: $filter
    sort: $sort
    searchInput: $searchInput
  ) {
    ... on DownloadDocumentPDFsError {
      kind
      message
    }
    ... on DownloadDocumentPDFsResult {
      response
    }
  }
}
    `;
export type DownloadPdfFilesForArchiveAllDocumentsMutationFn = Apollo.MutationFunction<DownloadPdfFilesForArchiveAllDocumentsMutation, DownloadPdfFilesForArchiveAllDocumentsMutationVariables>;

/**
 * __useDownloadPdfFilesForArchiveAllDocumentsMutation__
 *
 * To run a mutation, you first call `useDownloadPdfFilesForArchiveAllDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadPdfFilesForArchiveAllDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadPdfFilesForArchiveAllDocumentsMutation, { data, loading, error }] = useDownloadPdfFilesForArchiveAllDocumentsMutation({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useDownloadPdfFilesForArchiveAllDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<DownloadPdfFilesForArchiveAllDocumentsMutation, DownloadPdfFilesForArchiveAllDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadPdfFilesForArchiveAllDocumentsMutation, DownloadPdfFilesForArchiveAllDocumentsMutationVariables>(DownloadPdfFilesForArchiveAllDocumentsDocument, options);
      }
export type DownloadPdfFilesForArchiveAllDocumentsMutationHookResult = ReturnType<typeof useDownloadPdfFilesForArchiveAllDocumentsMutation>;
export type DownloadPdfFilesForArchiveAllDocumentsMutationResult = Apollo.MutationResult<DownloadPdfFilesForArchiveAllDocumentsMutation>;
export type DownloadPdfFilesForArchiveAllDocumentsMutationOptions = Apollo.BaseMutationOptions<DownloadPdfFilesForArchiveAllDocumentsMutation, DownloadPdfFilesForArchiveAllDocumentsMutationVariables>;
export const GetDocumentForPreviewDocument = gql`
    query getDocumentForPreview($id: ID!) {
  getDocument(id: $id) {
    id
    documentFile {
      id
      name
      url
    }
    attachments {
      id
      name
      url
    }
    contact {
      value {
        id
        name {
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetDocumentForPreviewQuery__
 *
 * To run a query within a React component, call `useGetDocumentForPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentForPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentForPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentForPreviewQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentForPreviewQuery, GetDocumentForPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentForPreviewQuery, GetDocumentForPreviewQueryVariables>(GetDocumentForPreviewDocument, options);
      }
export function useGetDocumentForPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentForPreviewQuery, GetDocumentForPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentForPreviewQuery, GetDocumentForPreviewQueryVariables>(GetDocumentForPreviewDocument, options);
        }
export type GetDocumentForPreviewQueryHookResult = ReturnType<typeof useGetDocumentForPreviewQuery>;
export type GetDocumentForPreviewLazyQueryHookResult = ReturnType<typeof useGetDocumentForPreviewLazyQuery>;
export type GetDocumentForPreviewQueryResult = Apollo.QueryResult<GetDocumentForPreviewQuery, GetDocumentForPreviewQueryVariables>;
export const GetEcmDocumentForPreviewDocument = gql`
    query getEcmDocumentForPreview($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    id
    fileSource {
      name
      url
    }
    type
    contactName
  }
}
    `;

/**
 * __useGetEcmDocumentForPreviewQuery__
 *
 * To run a query within a React component, call `useGetEcmDocumentForPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcmDocumentForPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcmDocumentForPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEcmDocumentForPreviewQuery(baseOptions: Apollo.QueryHookOptions<GetEcmDocumentForPreviewQuery, GetEcmDocumentForPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcmDocumentForPreviewQuery, GetEcmDocumentForPreviewQueryVariables>(GetEcmDocumentForPreviewDocument, options);
      }
export function useGetEcmDocumentForPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcmDocumentForPreviewQuery, GetEcmDocumentForPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcmDocumentForPreviewQuery, GetEcmDocumentForPreviewQueryVariables>(GetEcmDocumentForPreviewDocument, options);
        }
export type GetEcmDocumentForPreviewQueryHookResult = ReturnType<typeof useGetEcmDocumentForPreviewQuery>;
export type GetEcmDocumentForPreviewLazyQueryHookResult = ReturnType<typeof useGetEcmDocumentForPreviewLazyQuery>;
export type GetEcmDocumentForPreviewQueryResult = Apollo.QueryResult<GetEcmDocumentForPreviewQuery, GetEcmDocumentForPreviewQueryVariables>;
export const GetEcmContractsDocument = gql`
    query getEcmContracts($next: String, $limit: Int, $search: String, $sort: EcmSortInput, $filter: EcmFilterInput, $searchInput: SearchInput) {
  getEcmDocuments(
    next: $next
    limit: $limit
    search: $search
    sort: $sort
    filter: $filter
    searchInput: $searchInput
  ) {
    edges {
      cursor
      record {
        amount {
          amount
          currency
          precision
        }
        contactId
        contactName
        costCenterId
        costCenterName
        documentName
        documentNumber
        documentType
        invoiceId
        endDate
        id
        globalDocumentId
        isSensitive
        notes
        notifyPerson {
          primary {
            id
            name
            avatarUrl
          }
        }
        responsiblePerson {
          primary {
            id
            name
            avatarUrl
          }
        }
        startDate
        documentSubCategory {
          id
          name
        }
        terminationDate
        terminationReminderDate
        tags {
          ...Tag
        }
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      nextPageCursor
      totalCount
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useGetEcmContractsQuery__
 *
 * To run a query within a React component, call `useGetEcmContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcmContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcmContractsQuery({
 *   variables: {
 *      next: // value for 'next'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetEcmContractsQuery(baseOptions?: Apollo.QueryHookOptions<GetEcmContractsQuery, GetEcmContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcmContractsQuery, GetEcmContractsQueryVariables>(GetEcmContractsDocument, options);
      }
export function useGetEcmContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcmContractsQuery, GetEcmContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcmContractsQuery, GetEcmContractsQueryVariables>(GetEcmContractsDocument, options);
        }
export type GetEcmContractsQueryHookResult = ReturnType<typeof useGetEcmContractsQuery>;
export type GetEcmContractsLazyQueryHookResult = ReturnType<typeof useGetEcmContractsLazyQuery>;
export type GetEcmContractsQueryResult = Apollo.QueryResult<GetEcmContractsQuery, GetEcmContractsQueryVariables>;
export const GetEcmDocumentsDocument = gql`
    query GetEcmDocuments($next: String, $limit: Int, $search: String, $sort: EcmSortInput, $filter: EcmFilterInput, $searchInput: SearchInput) {
  getEcmDocuments(
    next: $next
    limit: $limit
    search: $search
    sort: $sort
    filter: $filter
    searchInput: $searchInput
  ) {
    edges {
      cursor
      record {
        id
        globalDocumentId
        contactId
        contactName
        documentDate
        documentName
        documentNumber
        documentStatus
        documentType
        invoiceId
        isSensitive
        isEInvoice
        notes
        responsiblePerson {
          primary {
            id
            name
            avatarUrl
          }
        }
        tags {
          ...Tag
        }
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      nextPageCursor
      totalCount
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useGetEcmDocumentsQuery__
 *
 * To run a query within a React component, call `useGetEcmDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcmDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcmDocumentsQuery({
 *   variables: {
 *      next: // value for 'next'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetEcmDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetEcmDocumentsQuery, GetEcmDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcmDocumentsQuery, GetEcmDocumentsQueryVariables>(GetEcmDocumentsDocument, options);
      }
export function useGetEcmDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcmDocumentsQuery, GetEcmDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcmDocumentsQuery, GetEcmDocumentsQueryVariables>(GetEcmDocumentsDocument, options);
        }
export type GetEcmDocumentsQueryHookResult = ReturnType<typeof useGetEcmDocumentsQuery>;
export type GetEcmDocumentsLazyQueryHookResult = ReturnType<typeof useGetEcmDocumentsLazyQuery>;
export type GetEcmDocumentsQueryResult = Apollo.QueryResult<GetEcmDocumentsQuery, GetEcmDocumentsQueryVariables>;
export const ArchiveAllDocumentsDocument = gql`
    query archiveAllDocuments($search: String, $sort: ArchiveAllDocumentsSortInput, $filter: ArchiveAllDocumentsFilterInput, $limit: Int, $next: String, $previous: String, $searchInput: SearchInput) {
  archiveAllDocuments(
    search: $search
    sort: $sort
    filter: $filter
    limit: $limit
    next: $next
    previous: $previous
    searchInput: $searchInput
  ) {
    edges {
      cursor
      node {
        id
        globalDocumentId
        contactId
        contactName
        documentDate
        documentName
        documentNumber
        documentStatus
        documentType
        invoiceId
        isSensitive
        isEInvoice
        notes
        reimbursementItem {
          caseId
        }
        tags {
          ...Tag
        }
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor: nextPageCursor
      totalCount
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useArchiveAllDocumentsQuery__
 *
 * To run a query within a React component, call `useArchiveAllDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveAllDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveAllDocumentsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      next: // value for 'next'
 *      previous: // value for 'previous'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useArchiveAllDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ArchiveAllDocumentsQuery, ArchiveAllDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveAllDocumentsQuery, ArchiveAllDocumentsQueryVariables>(ArchiveAllDocumentsDocument, options);
      }
export function useArchiveAllDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveAllDocumentsQuery, ArchiveAllDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveAllDocumentsQuery, ArchiveAllDocumentsQueryVariables>(ArchiveAllDocumentsDocument, options);
        }
export type ArchiveAllDocumentsQueryHookResult = ReturnType<typeof useArchiveAllDocumentsQuery>;
export type ArchiveAllDocumentsLazyQueryHookResult = ReturnType<typeof useArchiveAllDocumentsLazyQuery>;
export type ArchiveAllDocumentsQueryResult = Apollo.QueryResult<ArchiveAllDocumentsQuery, ArchiveAllDocumentsQueryVariables>;
export const GetGoodsReceiptsDocument = gql`
    query getGoodsReceipts($input: GoodsReceiptPaginationInput!, $query: String, $queryFields: [GoodsReceiptQueryField!], $filters: GoodsReceiptFilterInput, $sortBy: GoodsReceiptSortInput) {
  inboxGoodsReceipts(
    input: $input
    query: $query
    queryFields: $queryFields
    filters: $filters
    sortBy: $sortBy
  ) {
    edges {
      cursor
      node {
        _id
        accountsPayableNumber
        contactName
        connectedPurchaseOrders {
          purchaseOrderId
          purchaseOrderNumber
        }
        deliveryDate
        status
        receiptNumber
        organizationId
        goodsLineItems {
          quantity
          itemNumber
          description
        }
        connectedInvoices {
          invoiceId
          invoiceNumber
        }
        amount {
          __typename
          amount
          currency
          precision
        }
        sapB1Data {
          supplierRefNumber
        }
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${PageInfoDataFragmentDoc}`;

/**
 * __useGetGoodsReceiptsQuery__
 *
 * To run a query within a React component, call `useGetGoodsReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodsReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodsReceiptsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *      queryFields: // value for 'queryFields'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetGoodsReceiptsQuery(baseOptions: Apollo.QueryHookOptions<GetGoodsReceiptsQuery, GetGoodsReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoodsReceiptsQuery, GetGoodsReceiptsQueryVariables>(GetGoodsReceiptsDocument, options);
      }
export function useGetGoodsReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoodsReceiptsQuery, GetGoodsReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoodsReceiptsQuery, GetGoodsReceiptsQueryVariables>(GetGoodsReceiptsDocument, options);
        }
export type GetGoodsReceiptsQueryHookResult = ReturnType<typeof useGetGoodsReceiptsQuery>;
export type GetGoodsReceiptsLazyQueryHookResult = ReturnType<typeof useGetGoodsReceiptsLazyQuery>;
export type GetGoodsReceiptsQueryResult = Apollo.QueryResult<GetGoodsReceiptsQuery, GetGoodsReceiptsQueryVariables>;
export const GoodsReceiptsImportHistoryPaginationDocument = gql`
    query goodsReceiptsImportHistoryPagination($input: ListGoodsReceiptImportHistoryEntriesInput) {
  listGoodsReceiptImportHistoryEntries(input: $input) @connection(key: "goodsReceiptsImportHistoryPagination") {
    records {
      ...GoodsReceiptsImportHistoryData
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    ${GoodsReceiptsImportHistoryDataFragmentDoc}`;

/**
 * __useGoodsReceiptsImportHistoryPaginationQuery__
 *
 * To run a query within a React component, call `useGoodsReceiptsImportHistoryPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoodsReceiptsImportHistoryPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoodsReceiptsImportHistoryPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoodsReceiptsImportHistoryPaginationQuery(baseOptions?: Apollo.QueryHookOptions<GoodsReceiptsImportHistoryPaginationQuery, GoodsReceiptsImportHistoryPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoodsReceiptsImportHistoryPaginationQuery, GoodsReceiptsImportHistoryPaginationQueryVariables>(GoodsReceiptsImportHistoryPaginationDocument, options);
      }
export function useGoodsReceiptsImportHistoryPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoodsReceiptsImportHistoryPaginationQuery, GoodsReceiptsImportHistoryPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoodsReceiptsImportHistoryPaginationQuery, GoodsReceiptsImportHistoryPaginationQueryVariables>(GoodsReceiptsImportHistoryPaginationDocument, options);
        }
export type GoodsReceiptsImportHistoryPaginationQueryHookResult = ReturnType<typeof useGoodsReceiptsImportHistoryPaginationQuery>;
export type GoodsReceiptsImportHistoryPaginationLazyQueryHookResult = ReturnType<typeof useGoodsReceiptsImportHistoryPaginationLazyQuery>;
export type GoodsReceiptsImportHistoryPaginationQueryResult = Apollo.QueryResult<GoodsReceiptsImportHistoryPaginationQuery, GoodsReceiptsImportHistoryPaginationQueryVariables>;
export const ArchivePurchaseOrdersDocument = gql`
    query archivePurchaseOrders($input: PurchaseOrderPaginationInput!, $query: String, $queryFields: [PurchaserOrderQueryField!], $filters: PurchaseOrderFilterInput, $sortBy: PurchaseOrderSortInput) {
  inboxPurchaseOrders(
    input: $input
    query: $query
    queryFields: $queryFields
    filters: $filters
    sortBy: $sortBy
  ) {
    edges {
      cursor
      node {
        ...purchaseOrderListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${PurchaseOrderListDataFragmentDoc}
${PurchaseOrderBaseFragmentDoc}
${GoodsLineItemsFragmentDoc}
${ConnectedInvoicesFragmentDoc}
${SapB1DataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useArchivePurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useArchivePurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivePurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivePurchaseOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *      queryFields: // value for 'queryFields'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useArchivePurchaseOrdersQuery(baseOptions: Apollo.QueryHookOptions<ArchivePurchaseOrdersQuery, ArchivePurchaseOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchivePurchaseOrdersQuery, ArchivePurchaseOrdersQueryVariables>(ArchivePurchaseOrdersDocument, options);
      }
export function useArchivePurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchivePurchaseOrdersQuery, ArchivePurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchivePurchaseOrdersQuery, ArchivePurchaseOrdersQueryVariables>(ArchivePurchaseOrdersDocument, options);
        }
export type ArchivePurchaseOrdersQueryHookResult = ReturnType<typeof useArchivePurchaseOrdersQuery>;
export type ArchivePurchaseOrdersLazyQueryHookResult = ReturnType<typeof useArchivePurchaseOrdersLazyQuery>;
export type ArchivePurchaseOrdersQueryResult = Apollo.QueryResult<ArchivePurchaseOrdersQuery, ArchivePurchaseOrdersQueryVariables>;
export const PurchaseOrderImportHistoryPaginationDocument = gql`
    query purchaseOrderImportHistoryPagination($input: ListPurchaseOrderImportHistoryEntriesInput) {
  listPurchaseOrderImportHistoryEntries(input: $input) @connection(key: "purchaseOrderImportHistoryPagination") {
    records {
      ...PurchaseOrdersImportHistoryData
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    ${PurchaseOrdersImportHistoryDataFragmentDoc}`;

/**
 * __usePurchaseOrderImportHistoryPaginationQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderImportHistoryPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderImportHistoryPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderImportHistoryPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrderImportHistoryPaginationQuery(baseOptions?: Apollo.QueryHookOptions<PurchaseOrderImportHistoryPaginationQuery, PurchaseOrderImportHistoryPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrderImportHistoryPaginationQuery, PurchaseOrderImportHistoryPaginationQueryVariables>(PurchaseOrderImportHistoryPaginationDocument, options);
      }
export function usePurchaseOrderImportHistoryPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrderImportHistoryPaginationQuery, PurchaseOrderImportHistoryPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrderImportHistoryPaginationQuery, PurchaseOrderImportHistoryPaginationQueryVariables>(PurchaseOrderImportHistoryPaginationDocument, options);
        }
export type PurchaseOrderImportHistoryPaginationQueryHookResult = ReturnType<typeof usePurchaseOrderImportHistoryPaginationQuery>;
export type PurchaseOrderImportHistoryPaginationLazyQueryHookResult = ReturnType<typeof usePurchaseOrderImportHistoryPaginationLazyQuery>;
export type PurchaseOrderImportHistoryPaginationQueryResult = Apollo.QueryResult<PurchaseOrderImportHistoryPaginationQuery, PurchaseOrderImportHistoryPaginationQueryVariables>;
export const PurchaseOrderImportHistoryByIdDocument = gql`
    query purchaseOrderImportHistoryById($id: ID!) {
  getPurchaseOrderImportHistoryEntryById(id: $id) {
    ...PurchaseOrdersImportHistoryData
  }
}
    ${PurchaseOrdersImportHistoryDataFragmentDoc}`;

/**
 * __usePurchaseOrderImportHistoryByIdQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderImportHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderImportHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderImportHistoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePurchaseOrderImportHistoryByIdQuery(baseOptions: Apollo.QueryHookOptions<PurchaseOrderImportHistoryByIdQuery, PurchaseOrderImportHistoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrderImportHistoryByIdQuery, PurchaseOrderImportHistoryByIdQueryVariables>(PurchaseOrderImportHistoryByIdDocument, options);
      }
export function usePurchaseOrderImportHistoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrderImportHistoryByIdQuery, PurchaseOrderImportHistoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrderImportHistoryByIdQuery, PurchaseOrderImportHistoryByIdQueryVariables>(PurchaseOrderImportHistoryByIdDocument, options);
        }
export type PurchaseOrderImportHistoryByIdQueryHookResult = ReturnType<typeof usePurchaseOrderImportHistoryByIdQuery>;
export type PurchaseOrderImportHistoryByIdLazyQueryHookResult = ReturnType<typeof usePurchaseOrderImportHistoryByIdLazyQuery>;
export type PurchaseOrderImportHistoryByIdQueryResult = Apollo.QueryResult<PurchaseOrderImportHistoryByIdQuery, PurchaseOrderImportHistoryByIdQueryVariables>;
export const CreateDataFileForReimbursementCasesDocument = gql`
    mutation createDataFileForReimbursementCases($type: DocumentDataFileType, $search: String, $sort: ReimbursementCasesSortInput, $filter: ReimbursementCasesFilterInput, $searchInput: SearchInput) {
  createDataFileForReimbursementCases(
    type: $type
    search: $search
    sort: $sort
    filter: $filter
    searchInput: $searchInput
  ) {
    id
    recordCount
    file {
      id
      name
      url
    }
  }
}
    `;
export type CreateDataFileForReimbursementCasesMutationFn = Apollo.MutationFunction<CreateDataFileForReimbursementCasesMutation, CreateDataFileForReimbursementCasesMutationVariables>;

/**
 * __useCreateDataFileForReimbursementCasesMutation__
 *
 * To run a mutation, you first call `useCreateDataFileForReimbursementCasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataFileForReimbursementCasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataFileForReimbursementCasesMutation, { data, loading, error }] = useCreateDataFileForReimbursementCasesMutation({
 *   variables: {
 *      type: // value for 'type'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useCreateDataFileForReimbursementCasesMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataFileForReimbursementCasesMutation, CreateDataFileForReimbursementCasesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataFileForReimbursementCasesMutation, CreateDataFileForReimbursementCasesMutationVariables>(CreateDataFileForReimbursementCasesDocument, options);
      }
export type CreateDataFileForReimbursementCasesMutationHookResult = ReturnType<typeof useCreateDataFileForReimbursementCasesMutation>;
export type CreateDataFileForReimbursementCasesMutationResult = Apollo.MutationResult<CreateDataFileForReimbursementCasesMutation>;
export type CreateDataFileForReimbursementCasesMutationOptions = Apollo.BaseMutationOptions<CreateDataFileForReimbursementCasesMutation, CreateDataFileForReimbursementCasesMutationVariables>;
export const ArchiveReimbursementCasesDocument = gql`
    query archiveReimbursementCases($search: String, $sorts: ReimbursementCasesSortInput, $filters: ReimbursementCasesFilterInput, $limit: Int, $next: String, $searchInput: SearchInput) {
  archiveReimbursementCases(
    search: $search
    sorts: $sorts
    filters: $filters
    limit: $limit
    next: $next
    searchInput: $searchInput
  ) {
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
    edges {
      cursor
      node {
        id
        createdAt
        isPaid
        paidAt
        firstSubmittedForReviewAt
        lastClosedAt
        title
        status
        lastApprovedByMembership {
          id
          firstName
          lastName
          avatarUrl
        }
        targetMembership {
          id
          firstName
          lastName
          avatarUrl
        }
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
  }
}
    `;

/**
 * __useArchiveReimbursementCasesQuery__
 *
 * To run a query within a React component, call `useArchiveReimbursementCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveReimbursementCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveReimbursementCasesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sorts: // value for 'sorts'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      next: // value for 'next'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useArchiveReimbursementCasesQuery(baseOptions?: Apollo.QueryHookOptions<ArchiveReimbursementCasesQuery, ArchiveReimbursementCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveReimbursementCasesQuery, ArchiveReimbursementCasesQueryVariables>(ArchiveReimbursementCasesDocument, options);
      }
export function useArchiveReimbursementCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveReimbursementCasesQuery, ArchiveReimbursementCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveReimbursementCasesQuery, ArchiveReimbursementCasesQueryVariables>(ArchiveReimbursementCasesDocument, options);
        }
export type ArchiveReimbursementCasesQueryHookResult = ReturnType<typeof useArchiveReimbursementCasesQuery>;
export type ArchiveReimbursementCasesLazyQueryHookResult = ReturnType<typeof useArchiveReimbursementCasesLazyQuery>;
export type ArchiveReimbursementCasesQueryResult = Apollo.QueryResult<ArchiveReimbursementCasesQuery, ArchiveReimbursementCasesQueryVariables>;
export const CreateCsvFileForTransactionsDocument = gql`
    mutation createCsvFileForTransactions($filters: CardIssuerTransactionFilterInput, $queries: CardIssuerTransactionsQueryInput) {
  createCsvFileForTransactions(filters: $filters, queries: $queries) {
    file {
      id
      name
      url
    }
  }
}
    `;
export type CreateCsvFileForTransactionsMutationFn = Apollo.MutationFunction<CreateCsvFileForTransactionsMutation, CreateCsvFileForTransactionsMutationVariables>;

/**
 * __useCreateCsvFileForTransactionsMutation__
 *
 * To run a mutation, you first call `useCreateCsvFileForTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCsvFileForTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCsvFileForTransactionsMutation, { data, loading, error }] = useCreateCsvFileForTransactionsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *   },
 * });
 */
export function useCreateCsvFileForTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCsvFileForTransactionsMutation, CreateCsvFileForTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCsvFileForTransactionsMutation, CreateCsvFileForTransactionsMutationVariables>(CreateCsvFileForTransactionsDocument, options);
      }
export type CreateCsvFileForTransactionsMutationHookResult = ReturnType<typeof useCreateCsvFileForTransactionsMutation>;
export type CreateCsvFileForTransactionsMutationResult = Apollo.MutationResult<CreateCsvFileForTransactionsMutation>;
export type CreateCsvFileForTransactionsMutationOptions = Apollo.BaseMutationOptions<CreateCsvFileForTransactionsMutation, CreateCsvFileForTransactionsMutationVariables>;
export const ArchiveCardIssuerTransactionsDocument = gql`
    query archiveCardIssuerTransactions($input: CardIssuerTransactionPaginationInput!, $filters: CardIssuerTransactionFilterInput, $sortBy: CardIssuerTransactionSortInput, $queries: CardIssuerTransactionsQueryInput) {
  archiveCardIssuerTransactions(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) {
    edges {
      cursor
      node {
        ...transactionListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${TransactionListDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useArchiveCardIssuerTransactionsQuery__
 *
 * To run a query within a React component, call `useArchiveCardIssuerTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveCardIssuerTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveCardIssuerTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      queries: // value for 'queries'
 *   },
 * });
 */
export function useArchiveCardIssuerTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ArchiveCardIssuerTransactionsQuery, ArchiveCardIssuerTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveCardIssuerTransactionsQuery, ArchiveCardIssuerTransactionsQueryVariables>(ArchiveCardIssuerTransactionsDocument, options);
      }
export function useArchiveCardIssuerTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveCardIssuerTransactionsQuery, ArchiveCardIssuerTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveCardIssuerTransactionsQuery, ArchiveCardIssuerTransactionsQueryVariables>(ArchiveCardIssuerTransactionsDocument, options);
        }
export type ArchiveCardIssuerTransactionsQueryHookResult = ReturnType<typeof useArchiveCardIssuerTransactionsQuery>;
export type ArchiveCardIssuerTransactionsLazyQueryHookResult = ReturnType<typeof useArchiveCardIssuerTransactionsLazyQuery>;
export type ArchiveCardIssuerTransactionsQueryResult = Apollo.QueryResult<ArchiveCardIssuerTransactionsQuery, ArchiveCardIssuerTransactionsQueryVariables>;
export const GetForeignCurrencyFeeSavingsDocument = gql`
    query getForeignCurrencyFeeSavings($filters: DocumentFilterInput, $dateRangeFilters: DocumentDateFilterInput) {
  getForeignCurrencyFeeSavings(
    filters: $filters
    dateRangeFilters: $dateRangeFilters
  ) {
    aggregate {
      currency
      totalAmount
      estimatedFeesSaving
    }
  }
}
    `;

/**
 * __useGetForeignCurrencyFeeSavingsQuery__
 *
 * To run a query within a React component, call `useGetForeignCurrencyFeeSavingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForeignCurrencyFeeSavingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForeignCurrencyFeeSavingsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useGetForeignCurrencyFeeSavingsQuery(baseOptions?: Apollo.QueryHookOptions<GetForeignCurrencyFeeSavingsQuery, GetForeignCurrencyFeeSavingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetForeignCurrencyFeeSavingsQuery, GetForeignCurrencyFeeSavingsQueryVariables>(GetForeignCurrencyFeeSavingsDocument, options);
      }
export function useGetForeignCurrencyFeeSavingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetForeignCurrencyFeeSavingsQuery, GetForeignCurrencyFeeSavingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetForeignCurrencyFeeSavingsQuery, GetForeignCurrencyFeeSavingsQueryVariables>(GetForeignCurrencyFeeSavingsDocument, options);
        }
export type GetForeignCurrencyFeeSavingsQueryHookResult = ReturnType<typeof useGetForeignCurrencyFeeSavingsQuery>;
export type GetForeignCurrencyFeeSavingsLazyQueryHookResult = ReturnType<typeof useGetForeignCurrencyFeeSavingsLazyQuery>;
export type GetForeignCurrencyFeeSavingsQueryResult = Apollo.QueryResult<GetForeignCurrencyFeeSavingsQuery, GetForeignCurrencyFeeSavingsQueryVariables>;
export const New_RequestDatevAuthTokensDocument = gql`
    mutation new_requestDatevAuthTokens($authUrl: String!) {
  new_requestDatevAuthTokens(authUrl: $authUrl)
}
    `;
export type New_RequestDatevAuthTokensMutationFn = Apollo.MutationFunction<New_RequestDatevAuthTokensMutation, New_RequestDatevAuthTokensMutationVariables>;

/**
 * __useNew_RequestDatevAuthTokensMutation__
 *
 * To run a mutation, you first call `useNew_RequestDatevAuthTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNew_RequestDatevAuthTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newRequestDatevAuthTokensMutation, { data, loading, error }] = useNew_RequestDatevAuthTokensMutation({
 *   variables: {
 *      authUrl: // value for 'authUrl'
 *   },
 * });
 */
export function useNew_RequestDatevAuthTokensMutation(baseOptions?: Apollo.MutationHookOptions<New_RequestDatevAuthTokensMutation, New_RequestDatevAuthTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<New_RequestDatevAuthTokensMutation, New_RequestDatevAuthTokensMutationVariables>(New_RequestDatevAuthTokensDocument, options);
      }
export type New_RequestDatevAuthTokensMutationHookResult = ReturnType<typeof useNew_RequestDatevAuthTokensMutation>;
export type New_RequestDatevAuthTokensMutationResult = Apollo.MutationResult<New_RequestDatevAuthTokensMutation>;
export type New_RequestDatevAuthTokensMutationOptions = Apollo.BaseMutationOptions<New_RequestDatevAuthTokensMutation, New_RequestDatevAuthTokensMutationVariables>;
export const ContactHistoryDocument = gql`
    query contactHistory($entityId: String!, $entityType: EntityType!) {
  entityAuditLogs(entityId: $entityId, entityType: $entityType) {
    uniqueId
    actionType
    operationName
    actionPerformedAt
    organizationId
    actionPerformedBy {
      id
      name
      avatarUrl
    }
    wasImpersonated
    changedValues {
      propertyName
      propertyValue
    }
  }
}
    `;

/**
 * __useContactHistoryQuery__
 *
 * To run a query within a React component, call `useContactHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactHistoryQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useContactHistoryQuery(baseOptions: Apollo.QueryHookOptions<ContactHistoryQuery, ContactHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactHistoryQuery, ContactHistoryQueryVariables>(ContactHistoryDocument, options);
      }
export function useContactHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactHistoryQuery, ContactHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactHistoryQuery, ContactHistoryQueryVariables>(ContactHistoryDocument, options);
        }
export type ContactHistoryQueryHookResult = ReturnType<typeof useContactHistoryQuery>;
export type ContactHistoryLazyQueryHookResult = ReturnType<typeof useContactHistoryLazyQuery>;
export type ContactHistoryQueryResult = Apollo.QueryResult<ContactHistoryQuery, ContactHistoryQueryVariables>;
export const ContactsImportHistoryByIdDocument = gql`
    query contactsImportHistoryById($id: ID!) {
  contactsImportHistoryById(id: $id) {
    id
    organizationId
    importedContacts
    importErrors {
      contactType
    }
    status
  }
}
    `;

/**
 * __useContactsImportHistoryByIdQuery__
 *
 * To run a query within a React component, call `useContactsImportHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsImportHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsImportHistoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactsImportHistoryByIdQuery(baseOptions: Apollo.QueryHookOptions<ContactsImportHistoryByIdQuery, ContactsImportHistoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsImportHistoryByIdQuery, ContactsImportHistoryByIdQueryVariables>(ContactsImportHistoryByIdDocument, options);
      }
export function useContactsImportHistoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsImportHistoryByIdQuery, ContactsImportHistoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsImportHistoryByIdQuery, ContactsImportHistoryByIdQueryVariables>(ContactsImportHistoryByIdDocument, options);
        }
export type ContactsImportHistoryByIdQueryHookResult = ReturnType<typeof useContactsImportHistoryByIdQuery>;
export type ContactsImportHistoryByIdLazyQueryHookResult = ReturnType<typeof useContactsImportHistoryByIdLazyQuery>;
export type ContactsImportHistoryByIdQueryResult = Apollo.QueryResult<ContactsImportHistoryByIdQuery, ContactsImportHistoryByIdQueryVariables>;
export const BulkCreateOrUpdateContactsInBackgroundDocument = gql`
    mutation bulkCreateOrUpdateContactsInBackground($input: [ImportAndUpdateContactInput!]!, $fileId: String!) {
  bulkCreateOrUpdateContactsInBackground(input: $input, fileId: $fileId) {
    ... on ContactsBulkImportRecord {
      importId
    }
  }
}
    `;
export type BulkCreateOrUpdateContactsInBackgroundMutationFn = Apollo.MutationFunction<BulkCreateOrUpdateContactsInBackgroundMutation, BulkCreateOrUpdateContactsInBackgroundMutationVariables>;

/**
 * __useBulkCreateOrUpdateContactsInBackgroundMutation__
 *
 * To run a mutation, you first call `useBulkCreateOrUpdateContactsInBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateOrUpdateContactsInBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateOrUpdateContactsInBackgroundMutation, { data, loading, error }] = useBulkCreateOrUpdateContactsInBackgroundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useBulkCreateOrUpdateContactsInBackgroundMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateOrUpdateContactsInBackgroundMutation, BulkCreateOrUpdateContactsInBackgroundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateOrUpdateContactsInBackgroundMutation, BulkCreateOrUpdateContactsInBackgroundMutationVariables>(BulkCreateOrUpdateContactsInBackgroundDocument, options);
      }
export type BulkCreateOrUpdateContactsInBackgroundMutationHookResult = ReturnType<typeof useBulkCreateOrUpdateContactsInBackgroundMutation>;
export type BulkCreateOrUpdateContactsInBackgroundMutationResult = Apollo.MutationResult<BulkCreateOrUpdateContactsInBackgroundMutation>;
export type BulkCreateOrUpdateContactsInBackgroundMutationOptions = Apollo.BaseMutationOptions<BulkCreateOrUpdateContactsInBackgroundMutation, BulkCreateOrUpdateContactsInBackgroundMutationVariables>;
export const ContactsImportFileUploadUrlDocument = gql`
    mutation contactsImportFileUploadURL($fileName: String!, $contentType: String!) {
  contactsImportFileUploadURL(fileName: $fileName, contentType: $contentType) {
    id
    url
    postOptions {
      field
      value
    }
  }
}
    `;
export type ContactsImportFileUploadUrlMutationFn = Apollo.MutationFunction<ContactsImportFileUploadUrlMutation, ContactsImportFileUploadUrlMutationVariables>;

/**
 * __useContactsImportFileUploadUrlMutation__
 *
 * To run a mutation, you first call `useContactsImportFileUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactsImportFileUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactsImportFileUploadUrlMutation, { data, loading, error }] = useContactsImportFileUploadUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useContactsImportFileUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<ContactsImportFileUploadUrlMutation, ContactsImportFileUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactsImportFileUploadUrlMutation, ContactsImportFileUploadUrlMutationVariables>(ContactsImportFileUploadUrlDocument, options);
      }
export type ContactsImportFileUploadUrlMutationHookResult = ReturnType<typeof useContactsImportFileUploadUrlMutation>;
export type ContactsImportFileUploadUrlMutationResult = Apollo.MutationResult<ContactsImportFileUploadUrlMutation>;
export type ContactsImportFileUploadUrlMutationOptions = Apollo.BaseMutationOptions<ContactsImportFileUploadUrlMutation, ContactsImportFileUploadUrlMutationVariables>;
export const PaymentConditionNumbersDocument = gql`
    query paymentConditionNumbers($filter: PaymentConditionsFilter!) {
  paymentConditions(filter: $filter) {
    paymentConditionNumber
    isArchived
  }
}
    `;

/**
 * __usePaymentConditionNumbersQuery__
 *
 * To run a query within a React component, call `usePaymentConditionNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConditionNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConditionNumbersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaymentConditionNumbersQuery(baseOptions: Apollo.QueryHookOptions<PaymentConditionNumbersQuery, PaymentConditionNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentConditionNumbersQuery, PaymentConditionNumbersQueryVariables>(PaymentConditionNumbersDocument, options);
      }
export function usePaymentConditionNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentConditionNumbersQuery, PaymentConditionNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentConditionNumbersQuery, PaymentConditionNumbersQueryVariables>(PaymentConditionNumbersDocument, options);
        }
export type PaymentConditionNumbersQueryHookResult = ReturnType<typeof usePaymentConditionNumbersQuery>;
export type PaymentConditionNumbersLazyQueryHookResult = ReturnType<typeof usePaymentConditionNumbersLazyQuery>;
export type PaymentConditionNumbersQueryResult = Apollo.QueryResult<PaymentConditionNumbersQuery, PaymentConditionNumbersQueryVariables>;
export const IbanInfiniteScrollPaginationDocument = gql`
    query ibanInfiniteScrollPagination($input: ContactsPaginationInput, $filters: ContactsFilterInput, $queries: ContactsQueryInput, $sortBy: ContactsSortInput) {
  ibanPagination: contactsPagination(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) @connection(key: "ibanInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      id
      name {
        value
      }
      iban {
        value
      }
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useIbanInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useIbanInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIbanInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIbanInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useIbanInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<IbanInfiniteScrollPaginationQuery, IbanInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IbanInfiniteScrollPaginationQuery, IbanInfiniteScrollPaginationQueryVariables>(IbanInfiniteScrollPaginationDocument, options);
      }
export function useIbanInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IbanInfiniteScrollPaginationQuery, IbanInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IbanInfiniteScrollPaginationQuery, IbanInfiniteScrollPaginationQueryVariables>(IbanInfiniteScrollPaginationDocument, options);
        }
export type IbanInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useIbanInfiniteScrollPaginationQuery>;
export type IbanInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useIbanInfiniteScrollPaginationLazyQuery>;
export type IbanInfiniteScrollPaginationQueryResult = Apollo.QueryResult<IbanInfiniteScrollPaginationQuery, IbanInfiniteScrollPaginationQueryVariables>;
export const VatIdInfiniteScrollPaginationDocument = gql`
    query vatIdInfiniteScrollPagination($input: ContactsPaginationInput, $filters: ContactsFilterInput, $queries: ContactsQueryInput, $sortBy: ContactsSortInput) {
  vatIdPagination: contactsPagination(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) @connection(key: "vatIdInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      id
      name {
        value
      }
      vatId {
        value
      }
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useVatIdInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useVatIdInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVatIdInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVatIdInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useVatIdInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<VatIdInfiniteScrollPaginationQuery, VatIdInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VatIdInfiniteScrollPaginationQuery, VatIdInfiniteScrollPaginationQueryVariables>(VatIdInfiniteScrollPaginationDocument, options);
      }
export function useVatIdInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VatIdInfiniteScrollPaginationQuery, VatIdInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VatIdInfiniteScrollPaginationQuery, VatIdInfiniteScrollPaginationQueryVariables>(VatIdInfiniteScrollPaginationDocument, options);
        }
export type VatIdInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useVatIdInfiniteScrollPaginationQuery>;
export type VatIdInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useVatIdInfiniteScrollPaginationLazyQuery>;
export type VatIdInfiniteScrollPaginationQueryResult = Apollo.QueryResult<VatIdInfiniteScrollPaginationQuery, VatIdInfiniteScrollPaginationQueryVariables>;
export const TaxNumberInfiniteScrollPaginationDocument = gql`
    query taxNumberInfiniteScrollPagination($input: ContactsPaginationInput, $filters: ContactsFilterInput, $queries: ContactsQueryInput, $sortBy: ContactsSortInput) {
  taxNumberPagination: contactsPagination(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) @connection(key: "taxNumberInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      id
      name {
        value
      }
      taxNumber {
        value
      }
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useTaxNumberInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useTaxNumberInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxNumberInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxNumberInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useTaxNumberInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<TaxNumberInfiniteScrollPaginationQuery, TaxNumberInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxNumberInfiniteScrollPaginationQuery, TaxNumberInfiniteScrollPaginationQueryVariables>(TaxNumberInfiniteScrollPaginationDocument, options);
      }
export function useTaxNumberInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxNumberInfiniteScrollPaginationQuery, TaxNumberInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxNumberInfiniteScrollPaginationQuery, TaxNumberInfiniteScrollPaginationQueryVariables>(TaxNumberInfiniteScrollPaginationDocument, options);
        }
export type TaxNumberInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useTaxNumberInfiniteScrollPaginationQuery>;
export type TaxNumberInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useTaxNumberInfiniteScrollPaginationLazyQuery>;
export type TaxNumberInfiniteScrollPaginationQueryResult = Apollo.QueryResult<TaxNumberInfiniteScrollPaginationQuery, TaxNumberInfiniteScrollPaginationQueryVariables>;
export const AccountsPayableNumberInfiniteScrollPaginationDocument = gql`
    query accountsPayableNumberInfiniteScrollPagination($input: ContactsPaginationInput, $filters: ContactsFilterInput, $queries: ContactsQueryInput, $sortBy: ContactsSortInput) {
  accountsPayableNumberPagination: contactsPagination(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) @connection(key: "accountsPayableNumberInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      id
      name {
        value
      }
      accountsPayableNumber
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useAccountsPayableNumberInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useAccountsPayableNumberInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsPayableNumberInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsPayableNumberInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAccountsPayableNumberInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<AccountsPayableNumberInfiniteScrollPaginationQuery, AccountsPayableNumberInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsPayableNumberInfiniteScrollPaginationQuery, AccountsPayableNumberInfiniteScrollPaginationQueryVariables>(AccountsPayableNumberInfiniteScrollPaginationDocument, options);
      }
export function useAccountsPayableNumberInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsPayableNumberInfiniteScrollPaginationQuery, AccountsPayableNumberInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsPayableNumberInfiniteScrollPaginationQuery, AccountsPayableNumberInfiniteScrollPaginationQueryVariables>(AccountsPayableNumberInfiniteScrollPaginationDocument, options);
        }
export type AccountsPayableNumberInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useAccountsPayableNumberInfiniteScrollPaginationQuery>;
export type AccountsPayableNumberInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useAccountsPayableNumberInfiniteScrollPaginationLazyQuery>;
export type AccountsPayableNumberInfiniteScrollPaginationQueryResult = Apollo.QueryResult<AccountsPayableNumberInfiniteScrollPaginationQuery, AccountsPayableNumberInfiniteScrollPaginationQueryVariables>;
export const AccountsReceivableNumberInfiniteScrollPaginationDocument = gql`
    query accountsReceivableNumberInfiniteScrollPagination($input: ContactsPaginationInput, $filters: ContactsFilterInput, $queries: ContactsQueryInput, $sortBy: ContactsSortInput) {
  accountsReceivableNumberPagination: contactsPagination(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) @connection(key: "accountsReceivableNumberInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      id
      name {
        value
      }
      accountsReceivableNumber
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useAccountsReceivableNumberInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useAccountsReceivableNumberInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsReceivableNumberInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsReceivableNumberInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAccountsReceivableNumberInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<AccountsReceivableNumberInfiniteScrollPaginationQuery, AccountsReceivableNumberInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsReceivableNumberInfiniteScrollPaginationQuery, AccountsReceivableNumberInfiniteScrollPaginationQueryVariables>(AccountsReceivableNumberInfiniteScrollPaginationDocument, options);
      }
export function useAccountsReceivableNumberInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsReceivableNumberInfiniteScrollPaginationQuery, AccountsReceivableNumberInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsReceivableNumberInfiniteScrollPaginationQuery, AccountsReceivableNumberInfiniteScrollPaginationQueryVariables>(AccountsReceivableNumberInfiniteScrollPaginationDocument, options);
        }
export type AccountsReceivableNumberInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useAccountsReceivableNumberInfiniteScrollPaginationQuery>;
export type AccountsReceivableNumberInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useAccountsReceivableNumberInfiniteScrollPaginationLazyQuery>;
export type AccountsReceivableNumberInfiniteScrollPaginationQueryResult = Apollo.QueryResult<AccountsReceivableNumberInfiniteScrollPaginationQuery, AccountsReceivableNumberInfiniteScrollPaginationQueryVariables>;
export const ContactFilterOptionsInfiniteScrollPaginationDocument = gql`
    query contactFilterOptionsInfiniteScrollPagination($input: ContactsPaginationInput, $query: ContactFilterOptionsQueryInput!, $sortBy: ContactsSortInput) {
  contactFilterOptionsPagination(input: $input, query: $query, sortBy: $sortBy) @connection(key: "contactFilterOptionsInfiniteScrollPagination", filter: ["query", "sortBy"]) {
    records {
      id
      name {
        value
      }
      iban {
        value
      }
      bankAccountNumber
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
      exceedsPageLimit
    }
  }
}
    `;

/**
 * __useContactFilterOptionsInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useContactFilterOptionsInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactFilterOptionsInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactFilterOptionsInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useContactFilterOptionsInfiniteScrollPaginationQuery(baseOptions: Apollo.QueryHookOptions<ContactFilterOptionsInfiniteScrollPaginationQuery, ContactFilterOptionsInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactFilterOptionsInfiniteScrollPaginationQuery, ContactFilterOptionsInfiniteScrollPaginationQueryVariables>(ContactFilterOptionsInfiniteScrollPaginationDocument, options);
      }
export function useContactFilterOptionsInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactFilterOptionsInfiniteScrollPaginationQuery, ContactFilterOptionsInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactFilterOptionsInfiniteScrollPaginationQuery, ContactFilterOptionsInfiniteScrollPaginationQueryVariables>(ContactFilterOptionsInfiniteScrollPaginationDocument, options);
        }
export type ContactFilterOptionsInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useContactFilterOptionsInfiniteScrollPaginationQuery>;
export type ContactFilterOptionsInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useContactFilterOptionsInfiniteScrollPaginationLazyQuery>;
export type ContactFilterOptionsInfiniteScrollPaginationQueryResult = Apollo.QueryResult<ContactFilterOptionsInfiniteScrollPaginationQuery, ContactFilterOptionsInfiniteScrollPaginationQueryVariables>;
export const MergeContactsDocument = gql`
    mutation mergeContacts($input: MergeContactsInput!) {
  mergeContacts(input: $input) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;
export type MergeContactsMutationFn = Apollo.MutationFunction<MergeContactsMutation, MergeContactsMutationVariables>;

/**
 * __useMergeContactsMutation__
 *
 * To run a mutation, you first call `useMergeContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeContactsMutation, { data, loading, error }] = useMergeContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeContactsMutation(baseOptions?: Apollo.MutationHookOptions<MergeContactsMutation, MergeContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeContactsMutation, MergeContactsMutationVariables>(MergeContactsDocument, options);
      }
export type MergeContactsMutationHookResult = ReturnType<typeof useMergeContactsMutation>;
export type MergeContactsMutationResult = Apollo.MutationResult<MergeContactsMutation>;
export type MergeContactsMutationOptions = Apollo.BaseMutationOptions<MergeContactsMutation, MergeContactsMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactInput!) {
  createContact(input: $input) {
    __typename
    ... on ContactCreated {
      id
    }
    ... on ContactAlreadyExists {
      name
    }
    ... on AccountsPayableNumberAlreadyAssigned {
      id
      name
      accountsPayableNumber
    }
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $input: UpdateContactInput!) {
  updateContact(id: $id, input: $input) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const ContactDocument = gql`
    query contact($input: GetContactInput!) {
  contact(input: $input) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactQuery(baseOptions: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
      }
export function useContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
        }
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<ContactQuery, ContactQueryVariables>;
export const ContactsPaginationDocument = gql`
    query contactsPagination($input: ContactsPaginationInput, $filters: ContactsFilterInput, $queries: ContactsQueryInput, $sortBy: ContactsSortInput) {
  contactsPagination(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) {
    records {
      ...ContactData
    }
    pageInfo {
      totalCount
    }
  }
}
    ${ContactDataFragmentDoc}`;

/**
 * __useContactsPaginationQuery__
 *
 * To run a query within a React component, call `useContactsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useContactsPaginationQuery(baseOptions?: Apollo.QueryHookOptions<ContactsPaginationQuery, ContactsPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsPaginationQuery, ContactsPaginationQueryVariables>(ContactsPaginationDocument, options);
      }
export function useContactsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsPaginationQuery, ContactsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsPaginationQuery, ContactsPaginationQueryVariables>(ContactsPaginationDocument, options);
        }
export type ContactsPaginationQueryHookResult = ReturnType<typeof useContactsPaginationQuery>;
export type ContactsPaginationLazyQueryHookResult = ReturnType<typeof useContactsPaginationLazyQuery>;
export type ContactsPaginationQueryResult = Apollo.QueryResult<ContactsPaginationQuery, ContactsPaginationQueryVariables>;
export const ContactsInfiniteScrollPaginationDocument = gql`
    query contactsInfiniteScrollPagination($input: ContactsPaginationInput, $filters: ContactsFilterInput, $queries: ContactsQueryInput, $sortBy: ContactsSortInput) {
  contactsPagination(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) @connection(key: "contactsInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      ...ContactData
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    ${ContactDataFragmentDoc}`;

/**
 * __useContactsInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useContactsInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useContactsInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<ContactsInfiniteScrollPaginationQuery, ContactsInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsInfiniteScrollPaginationQuery, ContactsInfiniteScrollPaginationQueryVariables>(ContactsInfiniteScrollPaginationDocument, options);
      }
export function useContactsInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsInfiniteScrollPaginationQuery, ContactsInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsInfiniteScrollPaginationQuery, ContactsInfiniteScrollPaginationQueryVariables>(ContactsInfiniteScrollPaginationDocument, options);
        }
export type ContactsInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useContactsInfiniteScrollPaginationQuery>;
export type ContactsInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useContactsInfiniteScrollPaginationLazyQuery>;
export type ContactsInfiniteScrollPaginationQueryResult = Apollo.QueryResult<ContactsInfiniteScrollPaginationQuery, ContactsInfiniteScrollPaginationQueryVariables>;
export const SetAccountsPayableNumberForContactDocument = gql`
    mutation setAccountsPayableNumberForContact($id: ID!, $accountsPayableNumber: AccountsPayableNumber!) {
  setAccountsPayableNumberForContact(
    id: $id
    accountsPayableNumber: $accountsPayableNumber
  ) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;
export type SetAccountsPayableNumberForContactMutationFn = Apollo.MutationFunction<SetAccountsPayableNumberForContactMutation, SetAccountsPayableNumberForContactMutationVariables>;

/**
 * __useSetAccountsPayableNumberForContactMutation__
 *
 * To run a mutation, you first call `useSetAccountsPayableNumberForContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountsPayableNumberForContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountsPayableNumberForContactMutation, { data, loading, error }] = useSetAccountsPayableNumberForContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountsPayableNumber: // value for 'accountsPayableNumber'
 *   },
 * });
 */
export function useSetAccountsPayableNumberForContactMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountsPayableNumberForContactMutation, SetAccountsPayableNumberForContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountsPayableNumberForContactMutation, SetAccountsPayableNumberForContactMutationVariables>(SetAccountsPayableNumberForContactDocument, options);
      }
export type SetAccountsPayableNumberForContactMutationHookResult = ReturnType<typeof useSetAccountsPayableNumberForContactMutation>;
export type SetAccountsPayableNumberForContactMutationResult = Apollo.MutationResult<SetAccountsPayableNumberForContactMutation>;
export type SetAccountsPayableNumberForContactMutationOptions = Apollo.BaseMutationOptions<SetAccountsPayableNumberForContactMutation, SetAccountsPayableNumberForContactMutationVariables>;
export const UnsetAccountsPayableNumberForContactDocument = gql`
    mutation unsetAccountsPayableNumberForContact($id: ID!) {
  unsetAccountsPayableNumberForContact(id: $id) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;
export type UnsetAccountsPayableNumberForContactMutationFn = Apollo.MutationFunction<UnsetAccountsPayableNumberForContactMutation, UnsetAccountsPayableNumberForContactMutationVariables>;

/**
 * __useUnsetAccountsPayableNumberForContactMutation__
 *
 * To run a mutation, you first call `useUnsetAccountsPayableNumberForContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetAccountsPayableNumberForContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetAccountsPayableNumberForContactMutation, { data, loading, error }] = useUnsetAccountsPayableNumberForContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsetAccountsPayableNumberForContactMutation(baseOptions?: Apollo.MutationHookOptions<UnsetAccountsPayableNumberForContactMutation, UnsetAccountsPayableNumberForContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetAccountsPayableNumberForContactMutation, UnsetAccountsPayableNumberForContactMutationVariables>(UnsetAccountsPayableNumberForContactDocument, options);
      }
export type UnsetAccountsPayableNumberForContactMutationHookResult = ReturnType<typeof useUnsetAccountsPayableNumberForContactMutation>;
export type UnsetAccountsPayableNumberForContactMutationResult = Apollo.MutationResult<UnsetAccountsPayableNumberForContactMutation>;
export type UnsetAccountsPayableNumberForContactMutationOptions = Apollo.BaseMutationOptions<UnsetAccountsPayableNumberForContactMutation, UnsetAccountsPayableNumberForContactMutationVariables>;
export const ContactsImportHistoryDocument = gql`
    query contactsImportHistory {
  contactsImportHistory {
    id
    organizationId
    file {
      id
      name
      url
    }
    errorFile {
      id
      name
      url
    }
    createdBy {
      name
      avatarUrl
    }
    createdAt
    countFailedToCreate
    countSuccessfullyCreated
  }
}
    `;

/**
 * __useContactsImportHistoryQuery__
 *
 * To run a query within a React component, call `useContactsImportHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsImportHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsImportHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsImportHistoryQuery(baseOptions?: Apollo.QueryHookOptions<ContactsImportHistoryQuery, ContactsImportHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsImportHistoryQuery, ContactsImportHistoryQueryVariables>(ContactsImportHistoryDocument, options);
      }
export function useContactsImportHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsImportHistoryQuery, ContactsImportHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsImportHistoryQuery, ContactsImportHistoryQueryVariables>(ContactsImportHistoryDocument, options);
        }
export type ContactsImportHistoryQueryHookResult = ReturnType<typeof useContactsImportHistoryQuery>;
export type ContactsImportHistoryLazyQueryHookResult = ReturnType<typeof useContactsImportHistoryLazyQuery>;
export type ContactsImportHistoryQueryResult = Apollo.QueryResult<ContactsImportHistoryQuery, ContactsImportHistoryQueryVariables>;
export const ContactsImportHistoryErrorDocument = gql`
    query contactsImportHistoryError {
  contactsImportHistoryError {
    id
    errorFile {
      id
      name
      url
    }
    importedBy {
      name
      avatarUrl
    }
    importedAt
  }
}
    `;

/**
 * __useContactsImportHistoryErrorQuery__
 *
 * To run a query within a React component, call `useContactsImportHistoryErrorQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsImportHistoryErrorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsImportHistoryErrorQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsImportHistoryErrorQuery(baseOptions?: Apollo.QueryHookOptions<ContactsImportHistoryErrorQuery, ContactsImportHistoryErrorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsImportHistoryErrorQuery, ContactsImportHistoryErrorQueryVariables>(ContactsImportHistoryErrorDocument, options);
      }
export function useContactsImportHistoryErrorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsImportHistoryErrorQuery, ContactsImportHistoryErrorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsImportHistoryErrorQuery, ContactsImportHistoryErrorQueryVariables>(ContactsImportHistoryErrorDocument, options);
        }
export type ContactsImportHistoryErrorQueryHookResult = ReturnType<typeof useContactsImportHistoryErrorQuery>;
export type ContactsImportHistoryErrorLazyQueryHookResult = ReturnType<typeof useContactsImportHistoryErrorLazyQuery>;
export type ContactsImportHistoryErrorQueryResult = Apollo.QueryResult<ContactsImportHistoryErrorQuery, ContactsImportHistoryErrorQueryVariables>;
export const ContactsImportHistoriesWithUnacknowledgedErrorsDocument = gql`
    query contactsImportHistoriesWithUnacknowledgedErrors {
  contactsImportHistoriesWithUnacknowledgedErrors {
    id
    organizationId
    hasErrorsToAcknowledge
  }
}
    `;

/**
 * __useContactsImportHistoriesWithUnacknowledgedErrorsQuery__
 *
 * To run a query within a React component, call `useContactsImportHistoriesWithUnacknowledgedErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsImportHistoriesWithUnacknowledgedErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsImportHistoriesWithUnacknowledgedErrorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsImportHistoriesWithUnacknowledgedErrorsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsImportHistoriesWithUnacknowledgedErrorsQuery, ContactsImportHistoriesWithUnacknowledgedErrorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsImportHistoriesWithUnacknowledgedErrorsQuery, ContactsImportHistoriesWithUnacknowledgedErrorsQueryVariables>(ContactsImportHistoriesWithUnacknowledgedErrorsDocument, options);
      }
export function useContactsImportHistoriesWithUnacknowledgedErrorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsImportHistoriesWithUnacknowledgedErrorsQuery, ContactsImportHistoriesWithUnacknowledgedErrorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsImportHistoriesWithUnacknowledgedErrorsQuery, ContactsImportHistoriesWithUnacknowledgedErrorsQueryVariables>(ContactsImportHistoriesWithUnacknowledgedErrorsDocument, options);
        }
export type ContactsImportHistoriesWithUnacknowledgedErrorsQueryHookResult = ReturnType<typeof useContactsImportHistoriesWithUnacknowledgedErrorsQuery>;
export type ContactsImportHistoriesWithUnacknowledgedErrorsLazyQueryHookResult = ReturnType<typeof useContactsImportHistoriesWithUnacknowledgedErrorsLazyQuery>;
export type ContactsImportHistoriesWithUnacknowledgedErrorsQueryResult = Apollo.QueryResult<ContactsImportHistoriesWithUnacknowledgedErrorsQuery, ContactsImportHistoriesWithUnacknowledgedErrorsQueryVariables>;
export const AcknowledgeContactsImportHistoryErrorsDocument = gql`
    mutation acknowledgeContactsImportHistoryErrors($ids: [ID!]!) {
  acknowledgeContactsImportHistoryErrors(ids: $ids)
}
    `;
export type AcknowledgeContactsImportHistoryErrorsMutationFn = Apollo.MutationFunction<AcknowledgeContactsImportHistoryErrorsMutation, AcknowledgeContactsImportHistoryErrorsMutationVariables>;

/**
 * __useAcknowledgeContactsImportHistoryErrorsMutation__
 *
 * To run a mutation, you first call `useAcknowledgeContactsImportHistoryErrorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeContactsImportHistoryErrorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeContactsImportHistoryErrorsMutation, { data, loading, error }] = useAcknowledgeContactsImportHistoryErrorsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAcknowledgeContactsImportHistoryErrorsMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgeContactsImportHistoryErrorsMutation, AcknowledgeContactsImportHistoryErrorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgeContactsImportHistoryErrorsMutation, AcknowledgeContactsImportHistoryErrorsMutationVariables>(AcknowledgeContactsImportHistoryErrorsDocument, options);
      }
export type AcknowledgeContactsImportHistoryErrorsMutationHookResult = ReturnType<typeof useAcknowledgeContactsImportHistoryErrorsMutation>;
export type AcknowledgeContactsImportHistoryErrorsMutationResult = Apollo.MutationResult<AcknowledgeContactsImportHistoryErrorsMutation>;
export type AcknowledgeContactsImportHistoryErrorsMutationOptions = Apollo.BaseMutationOptions<AcknowledgeContactsImportHistoryErrorsMutation, AcknowledgeContactsImportHistoryErrorsMutationVariables>;
export const ExportContactsDocument = gql`
    mutation exportContacts {
  exportContacts {
    url
  }
}
    `;
export type ExportContactsMutationFn = Apollo.MutationFunction<ExportContactsMutation, ExportContactsMutationVariables>;

/**
 * __useExportContactsMutation__
 *
 * To run a mutation, you first call `useExportContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportContactsMutation, { data, loading, error }] = useExportContactsMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportContactsMutation(baseOptions?: Apollo.MutationHookOptions<ExportContactsMutation, ExportContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportContactsMutation, ExportContactsMutationVariables>(ExportContactsDocument, options);
      }
export type ExportContactsMutationHookResult = ReturnType<typeof useExportContactsMutation>;
export type ExportContactsMutationResult = Apollo.MutationResult<ExportContactsMutation>;
export type ExportContactsMutationOptions = Apollo.BaseMutationOptions<ExportContactsMutation, ExportContactsMutationVariables>;
export const ExportContactsGenericDocument = gql`
    mutation exportContactsGeneric {
  exportContactsGeneric {
    url
  }
}
    `;
export type ExportContactsGenericMutationFn = Apollo.MutationFunction<ExportContactsGenericMutation, ExportContactsGenericMutationVariables>;

/**
 * __useExportContactsGenericMutation__
 *
 * To run a mutation, you first call `useExportContactsGenericMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportContactsGenericMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportContactsGenericMutation, { data, loading, error }] = useExportContactsGenericMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportContactsGenericMutation(baseOptions?: Apollo.MutationHookOptions<ExportContactsGenericMutation, ExportContactsGenericMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportContactsGenericMutation, ExportContactsGenericMutationVariables>(ExportContactsGenericDocument, options);
      }
export type ExportContactsGenericMutationHookResult = ReturnType<typeof useExportContactsGenericMutation>;
export type ExportContactsGenericMutationResult = Apollo.MutationResult<ExportContactsGenericMutation>;
export type ExportContactsGenericMutationOptions = Apollo.BaseMutationOptions<ExportContactsGenericMutation, ExportContactsGenericMutationVariables>;
export const GetNextFreeAccountsPayableNumberDocument = gql`
    query getNextFreeAccountsPayableNumber($input: String) {
  getNextFreeAccountsPayableNumber(input: $input)
}
    `;

/**
 * __useGetNextFreeAccountsPayableNumberQuery__
 *
 * To run a query within a React component, call `useGetNextFreeAccountsPayableNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextFreeAccountsPayableNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextFreeAccountsPayableNumberQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNextFreeAccountsPayableNumberQuery(baseOptions?: Apollo.QueryHookOptions<GetNextFreeAccountsPayableNumberQuery, GetNextFreeAccountsPayableNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextFreeAccountsPayableNumberQuery, GetNextFreeAccountsPayableNumberQueryVariables>(GetNextFreeAccountsPayableNumberDocument, options);
      }
export function useGetNextFreeAccountsPayableNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextFreeAccountsPayableNumberQuery, GetNextFreeAccountsPayableNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextFreeAccountsPayableNumberQuery, GetNextFreeAccountsPayableNumberQueryVariables>(GetNextFreeAccountsPayableNumberDocument, options);
        }
export type GetNextFreeAccountsPayableNumberQueryHookResult = ReturnType<typeof useGetNextFreeAccountsPayableNumberQuery>;
export type GetNextFreeAccountsPayableNumberLazyQueryHookResult = ReturnType<typeof useGetNextFreeAccountsPayableNumberLazyQuery>;
export type GetNextFreeAccountsPayableNumberQueryResult = Apollo.QueryResult<GetNextFreeAccountsPayableNumberQuery, GetNextFreeAccountsPayableNumberQueryVariables>;
export const ArchiveContactsDocument = gql`
    mutation archiveContacts($ids: [ID!]!) {
  archiveContacts(ids: $ids) {
    ... on ContactArchived {
      archivedContactIds
    }
    ... on ContactArchivedError {
      message
      code
    }
  }
}
    `;
export type ArchiveContactsMutationFn = Apollo.MutationFunction<ArchiveContactsMutation, ArchiveContactsMutationVariables>;

/**
 * __useArchiveContactsMutation__
 *
 * To run a mutation, you first call `useArchiveContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveContactsMutation, { data, loading, error }] = useArchiveContactsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveContactsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveContactsMutation, ArchiveContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveContactsMutation, ArchiveContactsMutationVariables>(ArchiveContactsDocument, options);
      }
export type ArchiveContactsMutationHookResult = ReturnType<typeof useArchiveContactsMutation>;
export type ArchiveContactsMutationResult = Apollo.MutationResult<ArchiveContactsMutation>;
export type ArchiveContactsMutationOptions = Apollo.BaseMutationOptions<ArchiveContactsMutation, ArchiveContactsMutationVariables>;
export const ActivateContactsDocument = gql`
    mutation activateContacts($ids: [ID!]!) {
  activateContacts(ids: $ids) {
    ... on ContactActivated {
      activatedContactIds
    }
    ... on ContactActivatedError {
      message
      code
    }
  }
}
    `;
export type ActivateContactsMutationFn = Apollo.MutationFunction<ActivateContactsMutation, ActivateContactsMutationVariables>;

/**
 * __useActivateContactsMutation__
 *
 * To run a mutation, you first call `useActivateContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateContactsMutation, { data, loading, error }] = useActivateContactsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useActivateContactsMutation(baseOptions?: Apollo.MutationHookOptions<ActivateContactsMutation, ActivateContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateContactsMutation, ActivateContactsMutationVariables>(ActivateContactsDocument, options);
      }
export type ActivateContactsMutationHookResult = ReturnType<typeof useActivateContactsMutation>;
export type ActivateContactsMutationResult = Apollo.MutationResult<ActivateContactsMutation>;
export type ActivateContactsMutationOptions = Apollo.BaseMutationOptions<ActivateContactsMutation, ActivateContactsMutationVariables>;
export const CandisApiCoreDataImportHistoryDocument = gql`
    query candisApiCoreDataImportHistory($type: CoreDataTypes!) {
  candisApiCoreDataImportHistory(type: $type) {
    processId
    organizationId
    createdBy {
      firstName
      lastName
      avatarUrl
      membershipId
    }
    status
    operation
    type
    createdAt
    updatedAt
    successfulCandisAPICoreDataImportCount
    failedCandisAPICoreDataImportCount
  }
}
    `;

/**
 * __useCandisApiCoreDataImportHistoryQuery__
 *
 * To run a query within a React component, call `useCandisApiCoreDataImportHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandisApiCoreDataImportHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandisApiCoreDataImportHistoryQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCandisApiCoreDataImportHistoryQuery(baseOptions: Apollo.QueryHookOptions<CandisApiCoreDataImportHistoryQuery, CandisApiCoreDataImportHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandisApiCoreDataImportHistoryQuery, CandisApiCoreDataImportHistoryQueryVariables>(CandisApiCoreDataImportHistoryDocument, options);
      }
export function useCandisApiCoreDataImportHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandisApiCoreDataImportHistoryQuery, CandisApiCoreDataImportHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandisApiCoreDataImportHistoryQuery, CandisApiCoreDataImportHistoryQueryVariables>(CandisApiCoreDataImportHistoryDocument, options);
        }
export type CandisApiCoreDataImportHistoryQueryHookResult = ReturnType<typeof useCandisApiCoreDataImportHistoryQuery>;
export type CandisApiCoreDataImportHistoryLazyQueryHookResult = ReturnType<typeof useCandisApiCoreDataImportHistoryLazyQuery>;
export type CandisApiCoreDataImportHistoryQueryResult = Apollo.QueryResult<CandisApiCoreDataImportHistoryQuery, CandisApiCoreDataImportHistoryQueryVariables>;
export const GenerateCoreDataImportErrorFileByIdDocument = gql`
    mutation generateCoreDataImportErrorFileById($processId: ID!, $type: CoreDataTypes!) {
  generateCoreDataImportErrorFileById(processId: $processId, type: $type) {
    id
    url
    name
    size
  }
}
    `;
export type GenerateCoreDataImportErrorFileByIdMutationFn = Apollo.MutationFunction<GenerateCoreDataImportErrorFileByIdMutation, GenerateCoreDataImportErrorFileByIdMutationVariables>;

/**
 * __useGenerateCoreDataImportErrorFileByIdMutation__
 *
 * To run a mutation, you first call `useGenerateCoreDataImportErrorFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCoreDataImportErrorFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCoreDataImportErrorFileByIdMutation, { data, loading, error }] = useGenerateCoreDataImportErrorFileByIdMutation({
 *   variables: {
 *      processId: // value for 'processId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGenerateCoreDataImportErrorFileByIdMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCoreDataImportErrorFileByIdMutation, GenerateCoreDataImportErrorFileByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCoreDataImportErrorFileByIdMutation, GenerateCoreDataImportErrorFileByIdMutationVariables>(GenerateCoreDataImportErrorFileByIdDocument, options);
      }
export type GenerateCoreDataImportErrorFileByIdMutationHookResult = ReturnType<typeof useGenerateCoreDataImportErrorFileByIdMutation>;
export type GenerateCoreDataImportErrorFileByIdMutationResult = Apollo.MutationResult<GenerateCoreDataImportErrorFileByIdMutation>;
export type GenerateCoreDataImportErrorFileByIdMutationOptions = Apollo.BaseMutationOptions<GenerateCoreDataImportErrorFileByIdMutation, GenerateCoreDataImportErrorFileByIdMutationVariables>;
export const GetManagerCreditCardInsightsDocument = gql`
    query getManagerCreditCardInsights($cardInput: CardIssuerPaginationInput!, $cardRequestFilters: CardIssuerCardsForCardManagerFiltersInput, $cardLimitFilters: CardIssuerCardsForCardManagerFiltersInput, $cardQueries: CardIssuerCardsQueryInput, $cardSortBy: CardIssuerCardsSortInput, $transactionInput: CardIssuerTransactionPaginationInput!, $transactionFilters: CardIssuerTransactionFilterInput, $transactionSortBy: CardIssuerTransactionSortInput, $transactionQueries: CardIssuerTransactionsQueryInput) {
  cardRequests: getCardIssuerCardsForCardManager(
    input: $cardInput
    filters: $cardRequestFilters
    sortBy: $cardSortBy
    queries: $cardQueries
  ) {
    edges {
      ...CardIssuerCardEdgeData
    }
    pageInfo {
      ...PageInfoData
    }
  }
  limitRequests: getCardIssuerCardsForCardManager(
    input: $cardInput
    filters: $cardLimitFilters
    sortBy: $cardSortBy
    queries: $cardQueries
  ) {
    edges {
      ...CardIssuerCardEdgeData
    }
    pageInfo {
      ...PageInfoData
    }
  }
  declinedTransactions: archiveCardIssuerTransactions(
    input: $transactionInput
    filters: $transactionFilters
    sortBy: $transactionSortBy
    queries: $transactionQueries
  ) {
    edges {
      cursor
      node {
        ...transactionListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${CardIssuerCardEdgeDataFragmentDoc}
${VirtualCardRequestDataFragmentDoc}
${SingleUseVirtualCardRequestDataFragmentDoc}
${PhysicalCardRequestDataFragmentDoc}
${CardLimitChangeRequestDataFragmentDoc}
${PageInfoDataFragmentDoc}
${TransactionListDataFragmentDoc}`;

/**
 * __useGetManagerCreditCardInsightsQuery__
 *
 * To run a query within a React component, call `useGetManagerCreditCardInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerCreditCardInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerCreditCardInsightsQuery({
 *   variables: {
 *      cardInput: // value for 'cardInput'
 *      cardRequestFilters: // value for 'cardRequestFilters'
 *      cardLimitFilters: // value for 'cardLimitFilters'
 *      cardQueries: // value for 'cardQueries'
 *      cardSortBy: // value for 'cardSortBy'
 *      transactionInput: // value for 'transactionInput'
 *      transactionFilters: // value for 'transactionFilters'
 *      transactionSortBy: // value for 'transactionSortBy'
 *      transactionQueries: // value for 'transactionQueries'
 *   },
 * });
 */
export function useGetManagerCreditCardInsightsQuery(baseOptions: Apollo.QueryHookOptions<GetManagerCreditCardInsightsQuery, GetManagerCreditCardInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagerCreditCardInsightsQuery, GetManagerCreditCardInsightsQueryVariables>(GetManagerCreditCardInsightsDocument, options);
      }
export function useGetManagerCreditCardInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerCreditCardInsightsQuery, GetManagerCreditCardInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagerCreditCardInsightsQuery, GetManagerCreditCardInsightsQueryVariables>(GetManagerCreditCardInsightsDocument, options);
        }
export type GetManagerCreditCardInsightsQueryHookResult = ReturnType<typeof useGetManagerCreditCardInsightsQuery>;
export type GetManagerCreditCardInsightsLazyQueryHookResult = ReturnType<typeof useGetManagerCreditCardInsightsLazyQuery>;
export type GetManagerCreditCardInsightsQueryResult = Apollo.QueryResult<GetManagerCreditCardInsightsQuery, GetManagerCreditCardInsightsQueryVariables>;
export const GetCardholderCreditCardInsightsDocument = gql`
    query getCardholderCreditCardInsights($cardInput: CardIssuerPaginationInput!, $cardRequestFilters: CardIssuerCardsForCardholderFiltersInput, $cardLimitFilters: CardIssuerCardsForCardholderFiltersInput, $cardQueries: CardIssuerCardsQueryInput, $cardSortBy: CardIssuerCardsSortInput, $transactionInput: CardIssuerTransactionPaginationInput!, $transactionFilters: CardIssuerTransactionFilterInput, $transactionSortBy: CardIssuerTransactionSortInput, $transactionQueries: CardIssuerTransactionsQueryInput) {
  cardRequests: getCardIssuerCardsForCardholder(
    input: $cardInput
    filters: $cardRequestFilters
    sortBy: $cardSortBy
    queries: $cardQueries
  ) {
    edges {
      ...CardIssuerCardEdgeData
    }
    pageInfo {
      ...PageInfoData
    }
  }
  limitRequests: getCardIssuerCardsForCardholder(
    input: $cardInput
    filters: $cardLimitFilters
    sortBy: $cardSortBy
    queries: $cardQueries
  ) {
    edges {
      ...CardIssuerCardEdgeData
    }
    pageInfo {
      ...PageInfoData
    }
  }
  declinedTransactions: archiveCardIssuerTransactions(
    input: $transactionInput
    filters: $transactionFilters
    sortBy: $transactionSortBy
    queries: $transactionQueries
  ) {
    edges {
      cursor
      node {
        ...transactionListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${CardIssuerCardEdgeDataFragmentDoc}
${VirtualCardRequestDataFragmentDoc}
${SingleUseVirtualCardRequestDataFragmentDoc}
${PhysicalCardRequestDataFragmentDoc}
${CardLimitChangeRequestDataFragmentDoc}
${PageInfoDataFragmentDoc}
${TransactionListDataFragmentDoc}`;

/**
 * __useGetCardholderCreditCardInsightsQuery__
 *
 * To run a query within a React component, call `useGetCardholderCreditCardInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardholderCreditCardInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardholderCreditCardInsightsQuery({
 *   variables: {
 *      cardInput: // value for 'cardInput'
 *      cardRequestFilters: // value for 'cardRequestFilters'
 *      cardLimitFilters: // value for 'cardLimitFilters'
 *      cardQueries: // value for 'cardQueries'
 *      cardSortBy: // value for 'cardSortBy'
 *      transactionInput: // value for 'transactionInput'
 *      transactionFilters: // value for 'transactionFilters'
 *      transactionSortBy: // value for 'transactionSortBy'
 *      transactionQueries: // value for 'transactionQueries'
 *   },
 * });
 */
export function useGetCardholderCreditCardInsightsQuery(baseOptions: Apollo.QueryHookOptions<GetCardholderCreditCardInsightsQuery, GetCardholderCreditCardInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardholderCreditCardInsightsQuery, GetCardholderCreditCardInsightsQueryVariables>(GetCardholderCreditCardInsightsDocument, options);
      }
export function useGetCardholderCreditCardInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardholderCreditCardInsightsQuery, GetCardholderCreditCardInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardholderCreditCardInsightsQuery, GetCardholderCreditCardInsightsQueryVariables>(GetCardholderCreditCardInsightsDocument, options);
        }
export type GetCardholderCreditCardInsightsQueryHookResult = ReturnType<typeof useGetCardholderCreditCardInsightsQuery>;
export type GetCardholderCreditCardInsightsLazyQueryHookResult = ReturnType<typeof useGetCardholderCreditCardInsightsLazyQuery>;
export type GetCardholderCreditCardInsightsQueryResult = Apollo.QueryResult<GetCardholderCreditCardInsightsQuery, GetCardholderCreditCardInsightsQueryVariables>;
export const CreditCardLimitOrgDocument = gql`
    query creditCardLimitOrg {
  organization {
    creditCardsDetails {
      availableLimit {
        value
        currency
        precision
      }
      totalLimit {
        value
        currency
        precision
      }
    }
  }
}
    `;

/**
 * __useCreditCardLimitOrgQuery__
 *
 * To run a query within a React component, call `useCreditCardLimitOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardLimitOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardLimitOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditCardLimitOrgQuery(baseOptions?: Apollo.QueryHookOptions<CreditCardLimitOrgQuery, CreditCardLimitOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditCardLimitOrgQuery, CreditCardLimitOrgQueryVariables>(CreditCardLimitOrgDocument, options);
      }
export function useCreditCardLimitOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditCardLimitOrgQuery, CreditCardLimitOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditCardLimitOrgQuery, CreditCardLimitOrgQueryVariables>(CreditCardLimitOrgDocument, options);
        }
export type CreditCardLimitOrgQueryHookResult = ReturnType<typeof useCreditCardLimitOrgQuery>;
export type CreditCardLimitOrgLazyQueryHookResult = ReturnType<typeof useCreditCardLimitOrgLazyQuery>;
export type CreditCardLimitOrgQueryResult = Apollo.QueryResult<CreditCardLimitOrgQuery, CreditCardLimitOrgQueryVariables>;
export const GetPlannedPaymentDateDocument = gql`
    query getPlannedPaymentDate($dateRangeFilters: CardIssuerSettlementsDateFilterInput) {
  cardIssuerSettlementsPageBased(
    input: {limit: 1}
    dateRangeFilters: $dateRangeFilters
    filters: {statuses: [UPCOMING, PENDING, READY_FOR_PMT, PLANNED, DEFERRED]}
    sortBy: {direction: ASC, field: PLANNED_PAYMENT_DATE}
  ) {
    records {
      id
      plannedPaymentDate
    }
  }
}
    `;

/**
 * __useGetPlannedPaymentDateQuery__
 *
 * To run a query within a React component, call `useGetPlannedPaymentDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlannedPaymentDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlannedPaymentDateQuery({
 *   variables: {
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useGetPlannedPaymentDateQuery(baseOptions?: Apollo.QueryHookOptions<GetPlannedPaymentDateQuery, GetPlannedPaymentDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlannedPaymentDateQuery, GetPlannedPaymentDateQueryVariables>(GetPlannedPaymentDateDocument, options);
      }
export function useGetPlannedPaymentDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlannedPaymentDateQuery, GetPlannedPaymentDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlannedPaymentDateQuery, GetPlannedPaymentDateQueryVariables>(GetPlannedPaymentDateDocument, options);
        }
export type GetPlannedPaymentDateQueryHookResult = ReturnType<typeof useGetPlannedPaymentDateQuery>;
export type GetPlannedPaymentDateLazyQueryHookResult = ReturnType<typeof useGetPlannedPaymentDateLazyQuery>;
export type GetPlannedPaymentDateQueryResult = Apollo.QueryResult<GetPlannedPaymentDateQuery, GetPlannedPaymentDateQueryVariables>;
export const GetPageBasedCardIssuerCardsDocument = gql`
    query getPageBasedCardIssuerCards($input: CardIssuerPageBasedPaginationInput!, $queries: CardIssuerCardsQueryInput, $filters: CardIssuerCardsForCardManagerFiltersInput, $sortBy: CardIssuerCardsSortInput) {
  getPageBasedCardIssuerCards(
    input: $input
    queries: $queries
    filters: $filters
    sortBy: $sortBy
  ) {
    records {
      ...CardIssuerCardData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${CardIssuerCardDataFragmentDoc}`;

/**
 * __useGetPageBasedCardIssuerCardsQuery__
 *
 * To run a query within a React component, call `useGetPageBasedCardIssuerCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageBasedCardIssuerCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageBasedCardIssuerCardsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetPageBasedCardIssuerCardsQuery(baseOptions: Apollo.QueryHookOptions<GetPageBasedCardIssuerCardsQuery, GetPageBasedCardIssuerCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageBasedCardIssuerCardsQuery, GetPageBasedCardIssuerCardsQueryVariables>(GetPageBasedCardIssuerCardsDocument, options);
      }
export function useGetPageBasedCardIssuerCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageBasedCardIssuerCardsQuery, GetPageBasedCardIssuerCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageBasedCardIssuerCardsQuery, GetPageBasedCardIssuerCardsQueryVariables>(GetPageBasedCardIssuerCardsDocument, options);
        }
export type GetPageBasedCardIssuerCardsQueryHookResult = ReturnType<typeof useGetPageBasedCardIssuerCardsQuery>;
export type GetPageBasedCardIssuerCardsLazyQueryHookResult = ReturnType<typeof useGetPageBasedCardIssuerCardsLazyQuery>;
export type GetPageBasedCardIssuerCardsQueryResult = Apollo.QueryResult<GetPageBasedCardIssuerCardsQuery, GetPageBasedCardIssuerCardsQueryVariables>;
export const GetCardsInfiniteScrollPaginationDocument = gql`
    query getCardsInfiniteScrollPagination($input: CardIssuerPageBasedPaginationInput!, $queries: CardIssuerCardsQueryInput, $filters: CardIssuerCardsForCardManagerFiltersInput, $sortBy: CardIssuerCardsSortInput) {
  getPageBasedCardIssuerCards(
    input: $input
    queries: $queries
    filters: $filters
    sortBy: $sortBy
  ) @connection(key: "getCardsInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      ...CardIssuerCardData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${CardIssuerCardDataFragmentDoc}`;

/**
 * __useGetCardsInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useGetCardsInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetCardsInfiniteScrollPaginationQuery(baseOptions: Apollo.QueryHookOptions<GetCardsInfiniteScrollPaginationQuery, GetCardsInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardsInfiniteScrollPaginationQuery, GetCardsInfiniteScrollPaginationQueryVariables>(GetCardsInfiniteScrollPaginationDocument, options);
      }
export function useGetCardsInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsInfiniteScrollPaginationQuery, GetCardsInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardsInfiniteScrollPaginationQuery, GetCardsInfiniteScrollPaginationQueryVariables>(GetCardsInfiniteScrollPaginationDocument, options);
        }
export type GetCardsInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useGetCardsInfiniteScrollPaginationQuery>;
export type GetCardsInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useGetCardsInfiniteScrollPaginationLazyQuery>;
export type GetCardsInfiniteScrollPaginationQueryResult = Apollo.QueryResult<GetCardsInfiniteScrollPaginationQuery, GetCardsInfiniteScrollPaginationQueryVariables>;
export const GetSpendOverviewInsightsDocument = gql`
    query getSpendOverviewInsights($input: SpendOverviewInsightsInput, $filters: SpendOverviewInsightsFilterInput, $dateRangeFilters: SpendOverviewInsightsDateFilterInput) {
  getSpendOverviewInsights(
    input: $input
    filters: $filters
    dateRangeFilters: $dateRangeFilters
  ) {
    details {
      dateLabel
      spentAmount {
        amount
        currency
        precision
      }
    }
  }
}
    `;

/**
 * __useGetSpendOverviewInsightsQuery__
 *
 * To run a query within a React component, call `useGetSpendOverviewInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpendOverviewInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpendOverviewInsightsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useGetSpendOverviewInsightsQuery(baseOptions?: Apollo.QueryHookOptions<GetSpendOverviewInsightsQuery, GetSpendOverviewInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpendOverviewInsightsQuery, GetSpendOverviewInsightsQueryVariables>(GetSpendOverviewInsightsDocument, options);
      }
export function useGetSpendOverviewInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpendOverviewInsightsQuery, GetSpendOverviewInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpendOverviewInsightsQuery, GetSpendOverviewInsightsQueryVariables>(GetSpendOverviewInsightsDocument, options);
        }
export type GetSpendOverviewInsightsQueryHookResult = ReturnType<typeof useGetSpendOverviewInsightsQuery>;
export type GetSpendOverviewInsightsLazyQueryHookResult = ReturnType<typeof useGetSpendOverviewInsightsLazyQuery>;
export type GetSpendOverviewInsightsQueryResult = Apollo.QueryResult<GetSpendOverviewInsightsQuery, GetSpendOverviewInsightsQueryVariables>;
export const MerchantNamesInfinteScrollPaginationDocument = gql`
    query merchantNamesInfinteScrollPagination($input: CardIssuerPageBasedPaginationInput!, $queries: CardIssuerMerchantsQueryInput, $sortBy: CardIssuerMerchantsSortInput) {
  getPageBasedCardIssuerMerchants(
    input: $input
    queries: $queries
    sortBy: $sortBy
  ) @connection(key: "merchantNamesInfinteScrollPagination", filter: ["queries", "sortBy"]) {
    records {
      id
      name
      logoUrl
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useMerchantNamesInfinteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useMerchantNamesInfinteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantNamesInfinteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantNamesInfinteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMerchantNamesInfinteScrollPaginationQuery(baseOptions: Apollo.QueryHookOptions<MerchantNamesInfinteScrollPaginationQuery, MerchantNamesInfinteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MerchantNamesInfinteScrollPaginationQuery, MerchantNamesInfinteScrollPaginationQueryVariables>(MerchantNamesInfinteScrollPaginationDocument, options);
      }
export function useMerchantNamesInfinteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantNamesInfinteScrollPaginationQuery, MerchantNamesInfinteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MerchantNamesInfinteScrollPaginationQuery, MerchantNamesInfinteScrollPaginationQueryVariables>(MerchantNamesInfinteScrollPaginationDocument, options);
        }
export type MerchantNamesInfinteScrollPaginationQueryHookResult = ReturnType<typeof useMerchantNamesInfinteScrollPaginationQuery>;
export type MerchantNamesInfinteScrollPaginationLazyQueryHookResult = ReturnType<typeof useMerchantNamesInfinteScrollPaginationLazyQuery>;
export type MerchantNamesInfinteScrollPaginationQueryResult = Apollo.QueryResult<MerchantNamesInfinteScrollPaginationQuery, MerchantNamesInfinteScrollPaginationQueryVariables>;
export const MerchanNamePageBasedPaginationDocument = gql`
    query merchanNamePageBasedPagination($input: CardIssuerPageBasedPaginationInput!, $filters: CardIssuerMerchantsFiltersInput, $sortBy: CardIssuerMerchantsSortInput) {
  getPageBasedCardIssuerMerchants(
    input: $input
    filters: $filters
    sortBy: $sortBy
  ) {
    records {
      name
      logoUrl
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useMerchanNamePageBasedPaginationQuery__
 *
 * To run a query within a React component, call `useMerchanNamePageBasedPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchanNamePageBasedPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchanNamePageBasedPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMerchanNamePageBasedPaginationQuery(baseOptions: Apollo.QueryHookOptions<MerchanNamePageBasedPaginationQuery, MerchanNamePageBasedPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MerchanNamePageBasedPaginationQuery, MerchanNamePageBasedPaginationQueryVariables>(MerchanNamePageBasedPaginationDocument, options);
      }
export function useMerchanNamePageBasedPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchanNamePageBasedPaginationQuery, MerchanNamePageBasedPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MerchanNamePageBasedPaginationQuery, MerchanNamePageBasedPaginationQueryVariables>(MerchanNamePageBasedPaginationDocument, options);
        }
export type MerchanNamePageBasedPaginationQueryHookResult = ReturnType<typeof useMerchanNamePageBasedPaginationQuery>;
export type MerchanNamePageBasedPaginationLazyQueryHookResult = ReturnType<typeof useMerchanNamePageBasedPaginationLazyQuery>;
export type MerchanNamePageBasedPaginationQueryResult = Apollo.QueryResult<MerchanNamePageBasedPaginationQuery, MerchanNamePageBasedPaginationQueryVariables>;
export const GetTransactionsInsightsDocument = gql`
    query getTransactionsInsights($input: TransactionsInsightsInput, $filters: TransactionsInsightsFilterInput, $dateRangeFilters: CardIssuerTransactionDateFilterInput) {
  getTransactionsInsights(
    input: $input
    filters: $filters
    dateRangeFilters: $dateRangeFilters
  ) {
    cardholders {
      aggregate {
        count
      }
      cardholder {
        id
        membershipId
        membershipEmail
        firstName
        lastName
        avatarUrl
      }
      cards {
        card {
          refNum
          label
        }
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetTransactionsInsightsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsInsightsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useGetTransactionsInsightsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsInsightsQuery, GetTransactionsInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsInsightsQuery, GetTransactionsInsightsQueryVariables>(GetTransactionsInsightsDocument, options);
      }
export function useGetTransactionsInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsInsightsQuery, GetTransactionsInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsInsightsQuery, GetTransactionsInsightsQueryVariables>(GetTransactionsInsightsDocument, options);
        }
export type GetTransactionsInsightsQueryHookResult = ReturnType<typeof useGetTransactionsInsightsQuery>;
export type GetTransactionsInsightsLazyQueryHookResult = ReturnType<typeof useGetTransactionsInsightsLazyQuery>;
export type GetTransactionsInsightsQueryResult = Apollo.QueryResult<GetTransactionsInsightsQuery, GetTransactionsInsightsQueryVariables>;
export const GetCountCardsDocument = gql`
    query getCountCards($filters: CardIssuerCardsFiltersInput) {
  countCards(filters: $filters) {
    total
  }
}
    `;

/**
 * __useGetCountCardsQuery__
 *
 * To run a query within a React component, call `useGetCountCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountCardsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCountCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetCountCardsQuery, GetCountCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountCardsQuery, GetCountCardsQueryVariables>(GetCountCardsDocument, options);
      }
export function useGetCountCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountCardsQuery, GetCountCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountCardsQuery, GetCountCardsQueryVariables>(GetCountCardsDocument, options);
        }
export type GetCountCardsQueryHookResult = ReturnType<typeof useGetCountCardsQuery>;
export type GetCountCardsLazyQueryHookResult = ReturnType<typeof useGetCountCardsLazyQuery>;
export type GetCountCardsQueryResult = Apollo.QueryResult<GetCountCardsQuery, GetCountCardsQueryVariables>;
export const GetCountCardRequestsDocument = gql`
    query getCountCardRequests($filters: CardIssuerCardRequestsFiltersInput) {
  countCardRequests(filters: $filters) {
    total
    details {
      IssueNewVirtualCardRequest {
        count
        byStatus {
          PENDING
        }
      }
      IssueNewSingleUseVirtualCardRequest {
        count
        byStatus {
          PENDING
        }
      }
      IssueNewPhysicalCardRequest {
        count
        byStatus {
          PENDING
        }
      }
      ChangeCardLimitRequest {
        count
        byStatus {
          PENDING
        }
      }
    }
  }
}
    `;

/**
 * __useGetCountCardRequestsQuery__
 *
 * To run a query within a React component, call `useGetCountCardRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountCardRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountCardRequestsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCountCardRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetCountCardRequestsQuery, GetCountCardRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountCardRequestsQuery, GetCountCardRequestsQueryVariables>(GetCountCardRequestsDocument, options);
      }
export function useGetCountCardRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountCardRequestsQuery, GetCountCardRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountCardRequestsQuery, GetCountCardRequestsQueryVariables>(GetCountCardRequestsDocument, options);
        }
export type GetCountCardRequestsQueryHookResult = ReturnType<typeof useGetCountCardRequestsQuery>;
export type GetCountCardRequestsLazyQueryHookResult = ReturnType<typeof useGetCountCardRequestsLazyQuery>;
export type GetCountCardRequestsQueryResult = Apollo.QueryResult<GetCountCardRequestsQuery, GetCountCardRequestsQueryVariables>;
export const GetVendorSuggestionsForRecurringPaymentsDocument = gql`
    query getVendorSuggestionsForRecurringPayments($input: GetVendorSuggestionsForRecurringPaymentsInput, $filters: GetVendorSuggestionsForRecurringPaymentsFilterInput, $dateRangeFilters: GetVendorSuggestionsForRecurringPaymentsDateFilterInput, $sortBy: GetVendorSuggestionsForRecurringPaymentsSortInput) {
  getVendorSuggestionsForRecurringPayments(
    input: $input
    filters: $filters
    dateRangeFilters: $dateRangeFilters
    sortBy: $sortBy
  ) {
    records {
      hasTransactions
      contactIds
      vendor {
        id
        name
        category
        logoUrl
        isIgnored
      }
      sum {
        details {
          value {
            amount
            currency
            precision
          }
          count
          exchangeRate {
            rate
            valueInBaseCurrency {
              amount
              currency
              precision
            }
          }
        }
        aggregate {
          count
          value {
            amount
            currency
            precision
          }
          exchangeRate {
            rate
            valueInBaseCurrency {
              amount
              currency
              precision
            }
          }
        }
      }
      maxMonthlyInvoiceAmount {
        amount
        currency
        precision
      }
    }
    linkedCount
    pageInfo {
      totalCount
    }
  }
}
    `;

/**
 * __useGetVendorSuggestionsForRecurringPaymentsQuery__
 *
 * To run a query within a React component, call `useGetVendorSuggestionsForRecurringPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorSuggestionsForRecurringPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorSuggestionsForRecurringPaymentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetVendorSuggestionsForRecurringPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorSuggestionsForRecurringPaymentsQuery, GetVendorSuggestionsForRecurringPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorSuggestionsForRecurringPaymentsQuery, GetVendorSuggestionsForRecurringPaymentsQueryVariables>(GetVendorSuggestionsForRecurringPaymentsDocument, options);
      }
export function useGetVendorSuggestionsForRecurringPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorSuggestionsForRecurringPaymentsQuery, GetVendorSuggestionsForRecurringPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorSuggestionsForRecurringPaymentsQuery, GetVendorSuggestionsForRecurringPaymentsQueryVariables>(GetVendorSuggestionsForRecurringPaymentsDocument, options);
        }
export type GetVendorSuggestionsForRecurringPaymentsQueryHookResult = ReturnType<typeof useGetVendorSuggestionsForRecurringPaymentsQuery>;
export type GetVendorSuggestionsForRecurringPaymentsLazyQueryHookResult = ReturnType<typeof useGetVendorSuggestionsForRecurringPaymentsLazyQuery>;
export type GetVendorSuggestionsForRecurringPaymentsQueryResult = Apollo.QueryResult<GetVendorSuggestionsForRecurringPaymentsQuery, GetVendorSuggestionsForRecurringPaymentsQueryVariables>;
export const GetVendorsForRecurringPaymentsDocument = gql`
    query getVendorsForRecurringPayments($input: GetVendorsForRecurringPaymentsInput, $filters: GetVendorsForRecurringPaymentsFilterInput, $sortBy: GetVendorsForRecurringPaymentsSortInput) {
  getVendorsForRecurringPayments(
    input: $input
    filters: $filters
    sortBy: $sortBy
  ) {
    records {
      id
      vendor {
        id
        name
        logoUrl
      }
      linkedCards {
        id
        transactionState
        card {
          ...CardIssuerCardData
        }
      }
    }
    pageInfo {
      totalCount
      currentPage
      pageCount
      pageSize
    }
  }
}
    ${CardIssuerCardDataFragmentDoc}`;

/**
 * __useGetVendorsForRecurringPaymentsQuery__
 *
 * To run a query within a React component, call `useGetVendorsForRecurringPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsForRecurringPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsForRecurringPaymentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetVendorsForRecurringPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorsForRecurringPaymentsQuery, GetVendorsForRecurringPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorsForRecurringPaymentsQuery, GetVendorsForRecurringPaymentsQueryVariables>(GetVendorsForRecurringPaymentsDocument, options);
      }
export function useGetVendorsForRecurringPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorsForRecurringPaymentsQuery, GetVendorsForRecurringPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorsForRecurringPaymentsQuery, GetVendorsForRecurringPaymentsQueryVariables>(GetVendorsForRecurringPaymentsDocument, options);
        }
export type GetVendorsForRecurringPaymentsQueryHookResult = ReturnType<typeof useGetVendorsForRecurringPaymentsQuery>;
export type GetVendorsForRecurringPaymentsLazyQueryHookResult = ReturnType<typeof useGetVendorsForRecurringPaymentsLazyQuery>;
export type GetVendorsForRecurringPaymentsQueryResult = Apollo.QueryResult<GetVendorsForRecurringPaymentsQuery, GetVendorsForRecurringPaymentsQueryVariables>;
export const SetRecurringPaymentVendorIgnoredStateDocument = gql`
    mutation setRecurringPaymentVendorIgnoredState($input: SetRecurringPaymentVendorIgnoredStateInput!) {
  setRecurringPaymentVendorIgnoredState(input: $input) {
    id
    isIgnored
  }
}
    `;
export type SetRecurringPaymentVendorIgnoredStateMutationFn = Apollo.MutationFunction<SetRecurringPaymentVendorIgnoredStateMutation, SetRecurringPaymentVendorIgnoredStateMutationVariables>;

/**
 * __useSetRecurringPaymentVendorIgnoredStateMutation__
 *
 * To run a mutation, you first call `useSetRecurringPaymentVendorIgnoredStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRecurringPaymentVendorIgnoredStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRecurringPaymentVendorIgnoredStateMutation, { data, loading, error }] = useSetRecurringPaymentVendorIgnoredStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRecurringPaymentVendorIgnoredStateMutation(baseOptions?: Apollo.MutationHookOptions<SetRecurringPaymentVendorIgnoredStateMutation, SetRecurringPaymentVendorIgnoredStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRecurringPaymentVendorIgnoredStateMutation, SetRecurringPaymentVendorIgnoredStateMutationVariables>(SetRecurringPaymentVendorIgnoredStateDocument, options);
      }
export type SetRecurringPaymentVendorIgnoredStateMutationHookResult = ReturnType<typeof useSetRecurringPaymentVendorIgnoredStateMutation>;
export type SetRecurringPaymentVendorIgnoredStateMutationResult = Apollo.MutationResult<SetRecurringPaymentVendorIgnoredStateMutation>;
export type SetRecurringPaymentVendorIgnoredStateMutationOptions = Apollo.BaseMutationOptions<SetRecurringPaymentVendorIgnoredStateMutation, SetRecurringPaymentVendorIgnoredStateMutationVariables>;
export const LinkCardForRecurringPaymentVendorDocument = gql`
    mutation linkCardForRecurringPaymentVendor($input: LinkCardForRecurringPaymentVendorInput!) {
  linkCardForRecurringPaymentVendor(input: $input) {
    id
  }
}
    `;
export type LinkCardForRecurringPaymentVendorMutationFn = Apollo.MutationFunction<LinkCardForRecurringPaymentVendorMutation, LinkCardForRecurringPaymentVendorMutationVariables>;

/**
 * __useLinkCardForRecurringPaymentVendorMutation__
 *
 * To run a mutation, you first call `useLinkCardForRecurringPaymentVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkCardForRecurringPaymentVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkCardForRecurringPaymentVendorMutation, { data, loading, error }] = useLinkCardForRecurringPaymentVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkCardForRecurringPaymentVendorMutation(baseOptions?: Apollo.MutationHookOptions<LinkCardForRecurringPaymentVendorMutation, LinkCardForRecurringPaymentVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkCardForRecurringPaymentVendorMutation, LinkCardForRecurringPaymentVendorMutationVariables>(LinkCardForRecurringPaymentVendorDocument, options);
      }
export type LinkCardForRecurringPaymentVendorMutationHookResult = ReturnType<typeof useLinkCardForRecurringPaymentVendorMutation>;
export type LinkCardForRecurringPaymentVendorMutationResult = Apollo.MutationResult<LinkCardForRecurringPaymentVendorMutation>;
export type LinkCardForRecurringPaymentVendorMutationOptions = Apollo.BaseMutationOptions<LinkCardForRecurringPaymentVendorMutation, LinkCardForRecurringPaymentVendorMutationVariables>;
export const GetVendorForRecurringPaymentsByIdDocument = gql`
    query getVendorForRecurringPaymentsById($id: ID!) {
  getVendorForRecurringPaymentsById(id: $id) {
    id
    linkedCards {
      id
      transactionState
      card {
        id
        brand
        status
        type
        label
        refNum
        cardholder {
          id
          membershipId
          firstName
          lastName
        }
      }
    }
    vendor {
      id
      name
      logoUrl
      category
      source
    }
  }
}
    `;

/**
 * __useGetVendorForRecurringPaymentsByIdQuery__
 *
 * To run a query within a React component, call `useGetVendorForRecurringPaymentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorForRecurringPaymentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorForRecurringPaymentsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorForRecurringPaymentsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetVendorForRecurringPaymentsByIdQuery, GetVendorForRecurringPaymentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorForRecurringPaymentsByIdQuery, GetVendorForRecurringPaymentsByIdQueryVariables>(GetVendorForRecurringPaymentsByIdDocument, options);
      }
export function useGetVendorForRecurringPaymentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorForRecurringPaymentsByIdQuery, GetVendorForRecurringPaymentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorForRecurringPaymentsByIdQuery, GetVendorForRecurringPaymentsByIdQueryVariables>(GetVendorForRecurringPaymentsByIdDocument, options);
        }
export type GetVendorForRecurringPaymentsByIdQueryHookResult = ReturnType<typeof useGetVendorForRecurringPaymentsByIdQuery>;
export type GetVendorForRecurringPaymentsByIdLazyQueryHookResult = ReturnType<typeof useGetVendorForRecurringPaymentsByIdLazyQuery>;
export type GetVendorForRecurringPaymentsByIdQueryResult = Apollo.QueryResult<GetVendorForRecurringPaymentsByIdQuery, GetVendorForRecurringPaymentsByIdQueryVariables>;
export const GetVendorCardsSuggestionsForRecurringPaymentsDocument = gql`
    query getVendorCardsSuggestionsForRecurringPayments($id: ID!) {
  getVendorCardsSuggestionsForRecurringPayments(id: $id) {
    suggestions {
      card {
        id
        brand
        type
        label
        refNum
        cardholder {
          id
          membershipId
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetVendorCardsSuggestionsForRecurringPaymentsQuery__
 *
 * To run a query within a React component, call `useGetVendorCardsSuggestionsForRecurringPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorCardsSuggestionsForRecurringPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorCardsSuggestionsForRecurringPaymentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorCardsSuggestionsForRecurringPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetVendorCardsSuggestionsForRecurringPaymentsQuery, GetVendorCardsSuggestionsForRecurringPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorCardsSuggestionsForRecurringPaymentsQuery, GetVendorCardsSuggestionsForRecurringPaymentsQueryVariables>(GetVendorCardsSuggestionsForRecurringPaymentsDocument, options);
      }
export function useGetVendorCardsSuggestionsForRecurringPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorCardsSuggestionsForRecurringPaymentsQuery, GetVendorCardsSuggestionsForRecurringPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorCardsSuggestionsForRecurringPaymentsQuery, GetVendorCardsSuggestionsForRecurringPaymentsQueryVariables>(GetVendorCardsSuggestionsForRecurringPaymentsDocument, options);
        }
export type GetVendorCardsSuggestionsForRecurringPaymentsQueryHookResult = ReturnType<typeof useGetVendorCardsSuggestionsForRecurringPaymentsQuery>;
export type GetVendorCardsSuggestionsForRecurringPaymentsLazyQueryHookResult = ReturnType<typeof useGetVendorCardsSuggestionsForRecurringPaymentsLazyQuery>;
export type GetVendorCardsSuggestionsForRecurringPaymentsQueryResult = Apollo.QueryResult<GetVendorCardsSuggestionsForRecurringPaymentsQuery, GetVendorCardsSuggestionsForRecurringPaymentsQueryVariables>;
export const UpdateCardForRecurringPaymentVendorDocument = gql`
    mutation updateCardForRecurringPaymentVendor($input: UpdateCardForRecurringPaymentVendorInput!) {
  updateCardForRecurringPaymentVendor(input: $input) {
    id
  }
}
    `;
export type UpdateCardForRecurringPaymentVendorMutationFn = Apollo.MutationFunction<UpdateCardForRecurringPaymentVendorMutation, UpdateCardForRecurringPaymentVendorMutationVariables>;

/**
 * __useUpdateCardForRecurringPaymentVendorMutation__
 *
 * To run a mutation, you first call `useUpdateCardForRecurringPaymentVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardForRecurringPaymentVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardForRecurringPaymentVendorMutation, { data, loading, error }] = useUpdateCardForRecurringPaymentVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardForRecurringPaymentVendorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardForRecurringPaymentVendorMutation, UpdateCardForRecurringPaymentVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardForRecurringPaymentVendorMutation, UpdateCardForRecurringPaymentVendorMutationVariables>(UpdateCardForRecurringPaymentVendorDocument, options);
      }
export type UpdateCardForRecurringPaymentVendorMutationHookResult = ReturnType<typeof useUpdateCardForRecurringPaymentVendorMutation>;
export type UpdateCardForRecurringPaymentVendorMutationResult = Apollo.MutationResult<UpdateCardForRecurringPaymentVendorMutation>;
export type UpdateCardForRecurringPaymentVendorMutationOptions = Apollo.BaseMutationOptions<UpdateCardForRecurringPaymentVendorMutation, UpdateCardForRecurringPaymentVendorMutationVariables>;
export const GetVendorCardholdersSuggestionsForRecurringPaymentsDocument = gql`
    query getVendorCardholdersSuggestionsForRecurringPayments($id: ID!) {
  getVendorCardholdersSuggestionsForRecurringPayments(id: $id) {
    suggestions {
      cardholder {
        firstName
        lastName
        membershipId
      }
    }
  }
}
    `;

/**
 * __useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery__
 *
 * To run a query within a React component, call `useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetVendorCardholdersSuggestionsForRecurringPaymentsQuery, GetVendorCardholdersSuggestionsForRecurringPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorCardholdersSuggestionsForRecurringPaymentsQuery, GetVendorCardholdersSuggestionsForRecurringPaymentsQueryVariables>(GetVendorCardholdersSuggestionsForRecurringPaymentsDocument, options);
      }
export function useGetVendorCardholdersSuggestionsForRecurringPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorCardholdersSuggestionsForRecurringPaymentsQuery, GetVendorCardholdersSuggestionsForRecurringPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorCardholdersSuggestionsForRecurringPaymentsQuery, GetVendorCardholdersSuggestionsForRecurringPaymentsQueryVariables>(GetVendorCardholdersSuggestionsForRecurringPaymentsDocument, options);
        }
export type GetVendorCardholdersSuggestionsForRecurringPaymentsQueryHookResult = ReturnType<typeof useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery>;
export type GetVendorCardholdersSuggestionsForRecurringPaymentsLazyQueryHookResult = ReturnType<typeof useGetVendorCardholdersSuggestionsForRecurringPaymentsLazyQuery>;
export type GetVendorCardholdersSuggestionsForRecurringPaymentsQueryResult = Apollo.QueryResult<GetVendorCardholdersSuggestionsForRecurringPaymentsQuery, GetVendorCardholdersSuggestionsForRecurringPaymentsQueryVariables>;
export const DeleteCardForRecurringPaymentVendorDocument = gql`
    mutation deleteCardForRecurringPaymentVendor($input: DeleteCardForRecurringPaymentVendorInput!) {
  deleteCardForRecurringPaymentVendor(input: $input) {
    id
  }
}
    `;
export type DeleteCardForRecurringPaymentVendorMutationFn = Apollo.MutationFunction<DeleteCardForRecurringPaymentVendorMutation, DeleteCardForRecurringPaymentVendorMutationVariables>;

/**
 * __useDeleteCardForRecurringPaymentVendorMutation__
 *
 * To run a mutation, you first call `useDeleteCardForRecurringPaymentVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardForRecurringPaymentVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardForRecurringPaymentVendorMutation, { data, loading, error }] = useDeleteCardForRecurringPaymentVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCardForRecurringPaymentVendorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardForRecurringPaymentVendorMutation, DeleteCardForRecurringPaymentVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardForRecurringPaymentVendorMutation, DeleteCardForRecurringPaymentVendorMutationVariables>(DeleteCardForRecurringPaymentVendorDocument, options);
      }
export type DeleteCardForRecurringPaymentVendorMutationHookResult = ReturnType<typeof useDeleteCardForRecurringPaymentVendorMutation>;
export type DeleteCardForRecurringPaymentVendorMutationResult = Apollo.MutationResult<DeleteCardForRecurringPaymentVendorMutation>;
export type DeleteCardForRecurringPaymentVendorMutationOptions = Apollo.BaseMutationOptions<DeleteCardForRecurringPaymentVendorMutation, DeleteCardForRecurringPaymentVendorMutationVariables>;
export const CreateCreditCardGeneralLedgerAccountFieldItemsDocument = gql`
    query createCreditCardGeneralLedgerAccountFieldItems($readableName: String, $input: BookingAccountPaginationInput) {
  creditCardGeneralLedgerAccountsPagination: bookingAccountPagination(
    input: $input
    filters: {isArchived: false}
    queries: {readableName: $readableName}
  ) @connection(key: "creditCardGeneralLedgerAccountsPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      id
      readableName
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useCreateCreditCardGeneralLedgerAccountFieldItemsQuery__
 *
 * To run a query within a React component, call `useCreateCreditCardGeneralLedgerAccountFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardGeneralLedgerAccountFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCreditCardGeneralLedgerAccountFieldItemsQuery({
 *   variables: {
 *      readableName: // value for 'readableName'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditCardGeneralLedgerAccountFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<CreateCreditCardGeneralLedgerAccountFieldItemsQuery, CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateCreditCardGeneralLedgerAccountFieldItemsQuery, CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables>(CreateCreditCardGeneralLedgerAccountFieldItemsDocument, options);
      }
export function useCreateCreditCardGeneralLedgerAccountFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateCreditCardGeneralLedgerAccountFieldItemsQuery, CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateCreditCardGeneralLedgerAccountFieldItemsQuery, CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables>(CreateCreditCardGeneralLedgerAccountFieldItemsDocument, options);
        }
export type CreateCreditCardGeneralLedgerAccountFieldItemsQueryHookResult = ReturnType<typeof useCreateCreditCardGeneralLedgerAccountFieldItemsQuery>;
export type CreateCreditCardGeneralLedgerAccountFieldItemsLazyQueryHookResult = ReturnType<typeof useCreateCreditCardGeneralLedgerAccountFieldItemsLazyQuery>;
export type CreateCreditCardGeneralLedgerAccountFieldItemsQueryResult = Apollo.QueryResult<CreateCreditCardGeneralLedgerAccountFieldItemsQuery, CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables>;
export const CreateCreditCardContactFieldItemsDocument = gql`
    query createCreditCardContactFieldItems($name: String, $input: ContactsPaginationInput) {
  createCreditCardContactsPagination: contactsPagination(
    input: $input
    queries: {name: $name}
  ) @connection(key: "createCreditCardContactsPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      id
      name {
        value
      }
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useCreateCreditCardContactFieldItemsQuery__
 *
 * To run a query within a React component, call `useCreateCreditCardContactFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardContactFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCreditCardContactFieldItemsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditCardContactFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<CreateCreditCardContactFieldItemsQuery, CreateCreditCardContactFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateCreditCardContactFieldItemsQuery, CreateCreditCardContactFieldItemsQueryVariables>(CreateCreditCardContactFieldItemsDocument, options);
      }
export function useCreateCreditCardContactFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateCreditCardContactFieldItemsQuery, CreateCreditCardContactFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateCreditCardContactFieldItemsQuery, CreateCreditCardContactFieldItemsQueryVariables>(CreateCreditCardContactFieldItemsDocument, options);
        }
export type CreateCreditCardContactFieldItemsQueryHookResult = ReturnType<typeof useCreateCreditCardContactFieldItemsQuery>;
export type CreateCreditCardContactFieldItemsLazyQueryHookResult = ReturnType<typeof useCreateCreditCardContactFieldItemsLazyQuery>;
export type CreateCreditCardContactFieldItemsQueryResult = Apollo.QueryResult<CreateCreditCardContactFieldItemsQuery, CreateCreditCardContactFieldItemsQueryVariables>;
export const CreateCustomRecurringPaymentVendorDocument = gql`
    mutation createCustomRecurringPaymentVendor($input: CreateCustomRecurringPaymentVendorInput!) {
  createCustomRecurringPaymentVendor(input: $input) {
    id
  }
}
    `;
export type CreateCustomRecurringPaymentVendorMutationFn = Apollo.MutationFunction<CreateCustomRecurringPaymentVendorMutation, CreateCustomRecurringPaymentVendorMutationVariables>;

/**
 * __useCreateCustomRecurringPaymentVendorMutation__
 *
 * To run a mutation, you first call `useCreateCustomRecurringPaymentVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomRecurringPaymentVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomRecurringPaymentVendorMutation, { data, loading, error }] = useCreateCustomRecurringPaymentVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomRecurringPaymentVendorMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomRecurringPaymentVendorMutation, CreateCustomRecurringPaymentVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomRecurringPaymentVendorMutation, CreateCustomRecurringPaymentVendorMutationVariables>(CreateCustomRecurringPaymentVendorDocument, options);
      }
export type CreateCustomRecurringPaymentVendorMutationHookResult = ReturnType<typeof useCreateCustomRecurringPaymentVendorMutation>;
export type CreateCustomRecurringPaymentVendorMutationResult = Apollo.MutationResult<CreateCustomRecurringPaymentVendorMutation>;
export type CreateCustomRecurringPaymentVendorMutationOptions = Apollo.BaseMutationOptions<CreateCustomRecurringPaymentVendorMutation, CreateCustomRecurringPaymentVendorMutationVariables>;
export const CreditCardsAccountingInfoFieldItemsDocument = gql`
    query creditCardsAccountingInfoFieldItems {
  taxCodes: bookingKeysActive {
    id
    taxCode
    readableName
  }
  workflows: workflowTemplates(input: {isArchived: false}) {
    id
    name
    steps {
      resolvers {
        id
      }
    }
  }
  approvers {
    id
    name
    avatarUrl
  }
  getOrgMembersAbsence {
    fromDate
    membershipId
    note
    status
    toDate
  }
}
    `;

/**
 * __useCreditCardsAccountingInfoFieldItemsQuery__
 *
 * To run a query within a React component, call `useCreditCardsAccountingInfoFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardsAccountingInfoFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardsAccountingInfoFieldItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditCardsAccountingInfoFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<CreditCardsAccountingInfoFieldItemsQuery, CreditCardsAccountingInfoFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditCardsAccountingInfoFieldItemsQuery, CreditCardsAccountingInfoFieldItemsQueryVariables>(CreditCardsAccountingInfoFieldItemsDocument, options);
      }
export function useCreditCardsAccountingInfoFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditCardsAccountingInfoFieldItemsQuery, CreditCardsAccountingInfoFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditCardsAccountingInfoFieldItemsQuery, CreditCardsAccountingInfoFieldItemsQueryVariables>(CreditCardsAccountingInfoFieldItemsDocument, options);
        }
export type CreditCardsAccountingInfoFieldItemsQueryHookResult = ReturnType<typeof useCreditCardsAccountingInfoFieldItemsQuery>;
export type CreditCardsAccountingInfoFieldItemsLazyQueryHookResult = ReturnType<typeof useCreditCardsAccountingInfoFieldItemsLazyQuery>;
export type CreditCardsAccountingInfoFieldItemsQueryResult = Apollo.QueryResult<CreditCardsAccountingInfoFieldItemsQuery, CreditCardsAccountingInfoFieldItemsQueryVariables>;
export const UpdateCardIssuerOrganizationSettingsDocument = gql`
    mutation updateCardIssuerOrganizationSettings($input: UpdateCardIssuerOrganizationSettingsInput!) {
  updateCardIssuerOrganizationSettings(input: $input) {
    tradeName
  }
}
    `;
export type UpdateCardIssuerOrganizationSettingsMutationFn = Apollo.MutationFunction<UpdateCardIssuerOrganizationSettingsMutation, UpdateCardIssuerOrganizationSettingsMutationVariables>;

/**
 * __useUpdateCardIssuerOrganizationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCardIssuerOrganizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardIssuerOrganizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardIssuerOrganizationSettingsMutation, { data, loading, error }] = useUpdateCardIssuerOrganizationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardIssuerOrganizationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardIssuerOrganizationSettingsMutation, UpdateCardIssuerOrganizationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardIssuerOrganizationSettingsMutation, UpdateCardIssuerOrganizationSettingsMutationVariables>(UpdateCardIssuerOrganizationSettingsDocument, options);
      }
export type UpdateCardIssuerOrganizationSettingsMutationHookResult = ReturnType<typeof useUpdateCardIssuerOrganizationSettingsMutation>;
export type UpdateCardIssuerOrganizationSettingsMutationResult = Apollo.MutationResult<UpdateCardIssuerOrganizationSettingsMutation>;
export type UpdateCardIssuerOrganizationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateCardIssuerOrganizationSettingsMutation, UpdateCardIssuerOrganizationSettingsMutationVariables>;
export const GetCreditCardsStatementsDocument = gql`
    query getCreditCardsStatements($input: CardIssuerPageBasedPaginationInput, $filters: CardIssuerStatementsFilterInput, $dateRangeFilters: CardIssuerStatementsDateFilterInput, $sortBy: CardIssuerStatementsSortInput) {
  cardIssuerStatementsPageBased(
    input: $input
    filters: $filters
    dateRangeFilters: $dateRangeFilters
    sortBy: $sortBy
  ) {
    records {
      id
      cardIssuer {
        isClosed
        statementPeriodStart
        statementPeriodEnd
        openingBalance {
          value
          precision
          currency
        }
        closingBalance {
          value
          precision
          currency
        }
        totalTransactionAmount {
          value
          precision
          currency
        }
        totalPaymentAmount {
          value
          precision
          currency
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useGetCreditCardsStatementsQuery__
 *
 * To run a query within a React component, call `useGetCreditCardsStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditCardsStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditCardsStatementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetCreditCardsStatementsQuery(baseOptions?: Apollo.QueryHookOptions<GetCreditCardsStatementsQuery, GetCreditCardsStatementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreditCardsStatementsQuery, GetCreditCardsStatementsQueryVariables>(GetCreditCardsStatementsDocument, options);
      }
export function useGetCreditCardsStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreditCardsStatementsQuery, GetCreditCardsStatementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreditCardsStatementsQuery, GetCreditCardsStatementsQueryVariables>(GetCreditCardsStatementsDocument, options);
        }
export type GetCreditCardsStatementsQueryHookResult = ReturnType<typeof useGetCreditCardsStatementsQuery>;
export type GetCreditCardsStatementsLazyQueryHookResult = ReturnType<typeof useGetCreditCardsStatementsLazyQuery>;
export type GetCreditCardsStatementsQueryResult = Apollo.QueryResult<GetCreditCardsStatementsQuery, GetCreditCardsStatementsQueryVariables>;
export const CardIssuerSettlementsPageBasedDocument = gql`
    query cardIssuerSettlementsPageBased($input: CardIssuerPageBasedPaginationInput, $filters: CardIssuerSettlementsFiltersInput, $sortBy: CardIssuerSettlementsSortInput, $dateRangeFilters: CardIssuerSettlementsDateFilterInput) {
  cardIssuerSettlementsPageBased(
    input: $input
    filters: $filters
    sortBy: $sortBy
    dateRangeFilters: $dateRangeFilters
  ) {
    records {
      id
      publicPaymentId
      method
      type
      status
      cutOffDate
      actualPaymentDate
      plannedPaymentDate
      paymentDate
      type
      paymentDefermentInDays
      paymentCreatedAt
      amount {
        value
        currency
        precision
      }
    }
    pageInfo {
      totalCount
      currentPage
      pageCount
      pageSize
    }
  }
}
    `;

/**
 * __useCardIssuerSettlementsPageBasedQuery__
 *
 * To run a query within a React component, call `useCardIssuerSettlementsPageBasedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardIssuerSettlementsPageBasedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardIssuerSettlementsPageBasedQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useCardIssuerSettlementsPageBasedQuery(baseOptions?: Apollo.QueryHookOptions<CardIssuerSettlementsPageBasedQuery, CardIssuerSettlementsPageBasedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardIssuerSettlementsPageBasedQuery, CardIssuerSettlementsPageBasedQueryVariables>(CardIssuerSettlementsPageBasedDocument, options);
      }
export function useCardIssuerSettlementsPageBasedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardIssuerSettlementsPageBasedQuery, CardIssuerSettlementsPageBasedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardIssuerSettlementsPageBasedQuery, CardIssuerSettlementsPageBasedQueryVariables>(CardIssuerSettlementsPageBasedDocument, options);
        }
export type CardIssuerSettlementsPageBasedQueryHookResult = ReturnType<typeof useCardIssuerSettlementsPageBasedQuery>;
export type CardIssuerSettlementsPageBasedLazyQueryHookResult = ReturnType<typeof useCardIssuerSettlementsPageBasedLazyQuery>;
export type CardIssuerSettlementsPageBasedQueryResult = Apollo.QueryResult<CardIssuerSettlementsPageBasedQuery, CardIssuerSettlementsPageBasedQueryVariables>;
export const CardIssuerStatementDocument = gql`
    query cardIssuerStatement($id: ID!) {
  cardIssuerStatement(id: $id) {
    id
    hasPdfFile
    hasCsvFile
    pdfFile {
      url
      id
    }
    cardIssuer {
      isClosed
      statementPeriodStart
      statementPeriodEnd
      openingBalance {
        value
        precision
        currency
      }
      closingBalance {
        value
        precision
        currency
      }
      totalTransactionAmount {
        value
        precision
        currency
      }
      totalPaymentAmount {
        value
        precision
        currency
      }
    }
  }
}
    `;

/**
 * __useCardIssuerStatementQuery__
 *
 * To run a query within a React component, call `useCardIssuerStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardIssuerStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardIssuerStatementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCardIssuerStatementQuery(baseOptions: Apollo.QueryHookOptions<CardIssuerStatementQuery, CardIssuerStatementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardIssuerStatementQuery, CardIssuerStatementQueryVariables>(CardIssuerStatementDocument, options);
      }
export function useCardIssuerStatementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardIssuerStatementQuery, CardIssuerStatementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardIssuerStatementQuery, CardIssuerStatementQueryVariables>(CardIssuerStatementDocument, options);
        }
export type CardIssuerStatementQueryHookResult = ReturnType<typeof useCardIssuerStatementQuery>;
export type CardIssuerStatementLazyQueryHookResult = ReturnType<typeof useCardIssuerStatementLazyQuery>;
export type CardIssuerStatementQueryResult = Apollo.QueryResult<CardIssuerStatementQuery, CardIssuerStatementQueryVariables>;
export const GenerateCsvFileForStatementDocument = gql`
    mutation generateCsvFileForStatement($input: GenerateCsvFileForStatementInput!) {
  generateCsvFileForStatement(input: $input) {
    id
  }
}
    `;
export type GenerateCsvFileForStatementMutationFn = Apollo.MutationFunction<GenerateCsvFileForStatementMutation, GenerateCsvFileForStatementMutationVariables>;

/**
 * __useGenerateCsvFileForStatementMutation__
 *
 * To run a mutation, you first call `useGenerateCsvFileForStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCsvFileForStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCsvFileForStatementMutation, { data, loading, error }] = useGenerateCsvFileForStatementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCsvFileForStatementMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCsvFileForStatementMutation, GenerateCsvFileForStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCsvFileForStatementMutation, GenerateCsvFileForStatementMutationVariables>(GenerateCsvFileForStatementDocument, options);
      }
export type GenerateCsvFileForStatementMutationHookResult = ReturnType<typeof useGenerateCsvFileForStatementMutation>;
export type GenerateCsvFileForStatementMutationResult = Apollo.MutationResult<GenerateCsvFileForStatementMutation>;
export type GenerateCsvFileForStatementMutationOptions = Apollo.BaseMutationOptions<GenerateCsvFileForStatementMutation, GenerateCsvFileForStatementMutationVariables>;
export const CardIssuerStatementCsvFileDocument = gql`
    query cardIssuerStatementCsvFile($input: GetCsvFileForStatementInput!) {
  cardIssuerStatementCsvFile(input: $input) {
    id
    file {
      url
      id
      name
    }
    hasFile
  }
}
    `;

/**
 * __useCardIssuerStatementCsvFileQuery__
 *
 * To run a query within a React component, call `useCardIssuerStatementCsvFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardIssuerStatementCsvFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardIssuerStatementCsvFileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCardIssuerStatementCsvFileQuery(baseOptions: Apollo.QueryHookOptions<CardIssuerStatementCsvFileQuery, CardIssuerStatementCsvFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardIssuerStatementCsvFileQuery, CardIssuerStatementCsvFileQueryVariables>(CardIssuerStatementCsvFileDocument, options);
      }
export function useCardIssuerStatementCsvFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardIssuerStatementCsvFileQuery, CardIssuerStatementCsvFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardIssuerStatementCsvFileQuery, CardIssuerStatementCsvFileQueryVariables>(CardIssuerStatementCsvFileDocument, options);
        }
export type CardIssuerStatementCsvFileQueryHookResult = ReturnType<typeof useCardIssuerStatementCsvFileQuery>;
export type CardIssuerStatementCsvFileLazyQueryHookResult = ReturnType<typeof useCardIssuerStatementCsvFileLazyQuery>;
export type CardIssuerStatementCsvFileQueryResult = Apollo.QueryResult<CardIssuerStatementCsvFileQuery, CardIssuerStatementCsvFileQueryVariables>;
export const FinancialInsightsDocument = gql`
    query financialInsights($userId: String, $filters: DocumentFilterInput, $dateRangeFilters: DocumentDateFilterInput, $input: FinancialInsightsInput) {
  financialInsights(
    userId: $userId
    filters: $filters
    dateRangeFilters: $dateRangeFilters
    input: $input
  ) {
    sum {
      details {
        currency
        amount
        count
        exchangeRate {
          baseCurrency
          amountInBaseCurrency
          rate
        }
      }
      aggregate {
        currency
        amount
        count
      }
    }
  }
}
    `;

/**
 * __useFinancialInsightsQuery__
 *
 * To run a query within a React component, call `useFinancialInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialInsightsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinancialInsightsQuery(baseOptions?: Apollo.QueryHookOptions<FinancialInsightsQuery, FinancialInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinancialInsightsQuery, FinancialInsightsQueryVariables>(FinancialInsightsDocument, options);
      }
export function useFinancialInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinancialInsightsQuery, FinancialInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinancialInsightsQuery, FinancialInsightsQueryVariables>(FinancialInsightsDocument, options);
        }
export type FinancialInsightsQueryHookResult = ReturnType<typeof useFinancialInsightsQuery>;
export type FinancialInsightsLazyQueryHookResult = ReturnType<typeof useFinancialInsightsLazyQuery>;
export type FinancialInsightsQueryResult = Apollo.QueryResult<FinancialInsightsQuery, FinancialInsightsQueryVariables>;
export const GetVendorSuggestionsCountDocument = gql`
    query getVendorSuggestionsCount($input: GetVendorSuggestionsForRecurringPaymentsInput, $filters: GetVendorSuggestionsForRecurringPaymentsFilterInput, $dateRangeFilters: GetVendorSuggestionsForRecurringPaymentsDateFilterInput) {
  getVendorSuggestionsCountForRecurringPayments(
    input: $input
    filters: $filters
    dateRangeFilters: $dateRangeFilters
  ) {
    linkedCount
    totalCount
  }
}
    `;

/**
 * __useGetVendorSuggestionsCountQuery__
 *
 * To run a query within a React component, call `useGetVendorSuggestionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorSuggestionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorSuggestionsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      dateRangeFilters: // value for 'dateRangeFilters'
 *   },
 * });
 */
export function useGetVendorSuggestionsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorSuggestionsCountQuery, GetVendorSuggestionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorSuggestionsCountQuery, GetVendorSuggestionsCountQueryVariables>(GetVendorSuggestionsCountDocument, options);
      }
export function useGetVendorSuggestionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorSuggestionsCountQuery, GetVendorSuggestionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorSuggestionsCountQuery, GetVendorSuggestionsCountQueryVariables>(GetVendorSuggestionsCountDocument, options);
        }
export type GetVendorSuggestionsCountQueryHookResult = ReturnType<typeof useGetVendorSuggestionsCountQuery>;
export type GetVendorSuggestionsCountLazyQueryHookResult = ReturnType<typeof useGetVendorSuggestionsCountLazyQuery>;
export type GetVendorSuggestionsCountQueryResult = Apollo.QueryResult<GetVendorSuggestionsCountQuery, GetVendorSuggestionsCountQueryVariables>;
export const MonitoringActionsDocument = gql`
    query monitoringActions($documentId: ID!) {
  document: getDocument(id: $documentId) {
    id
    workflow {
      ...WorkflowVisualization
    }
  }
}
    ${WorkflowVisualizationFragmentDoc}
${DocumentApprovalStepFragmentDoc}
${UserApprovalStepFragmentDoc}
${SystemApprovalStepFragmentDoc}`;

/**
 * __useMonitoringActionsQuery__
 *
 * To run a query within a React component, call `useMonitoringActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringActionsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useMonitoringActionsQuery(baseOptions: Apollo.QueryHookOptions<MonitoringActionsQuery, MonitoringActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonitoringActionsQuery, MonitoringActionsQueryVariables>(MonitoringActionsDocument, options);
      }
export function useMonitoringActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonitoringActionsQuery, MonitoringActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonitoringActionsQuery, MonitoringActionsQueryVariables>(MonitoringActionsDocument, options);
        }
export type MonitoringActionsQueryHookResult = ReturnType<typeof useMonitoringActionsQuery>;
export type MonitoringActionsLazyQueryHookResult = ReturnType<typeof useMonitoringActionsLazyQuery>;
export type MonitoringActionsQueryResult = Apollo.QueryResult<MonitoringActionsQuery, MonitoringActionsQueryVariables>;
export const AssociatePurchaseOrdersToDocumentDocument = gql`
    mutation associatePurchaseOrdersToDocument($documentId: String!, $purchaseOrderIds: [String!]!) {
  associatePurchaseOrdersToDocument(
    documentId: $documentId
    purchaseOrderIds: $purchaseOrderIds
  ) {
    ...DocumentBookingAssociationData
  }
}
    ${DocumentBookingAssociationDataFragmentDoc}`;
export type AssociatePurchaseOrdersToDocumentMutationFn = Apollo.MutationFunction<AssociatePurchaseOrdersToDocumentMutation, AssociatePurchaseOrdersToDocumentMutationVariables>;

/**
 * __useAssociatePurchaseOrdersToDocumentMutation__
 *
 * To run a mutation, you first call `useAssociatePurchaseOrdersToDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociatePurchaseOrdersToDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associatePurchaseOrdersToDocumentMutation, { data, loading, error }] = useAssociatePurchaseOrdersToDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      purchaseOrderIds: // value for 'purchaseOrderIds'
 *   },
 * });
 */
export function useAssociatePurchaseOrdersToDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AssociatePurchaseOrdersToDocumentMutation, AssociatePurchaseOrdersToDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssociatePurchaseOrdersToDocumentMutation, AssociatePurchaseOrdersToDocumentMutationVariables>(AssociatePurchaseOrdersToDocumentDocument, options);
      }
export type AssociatePurchaseOrdersToDocumentMutationHookResult = ReturnType<typeof useAssociatePurchaseOrdersToDocumentMutation>;
export type AssociatePurchaseOrdersToDocumentMutationResult = Apollo.MutationResult<AssociatePurchaseOrdersToDocumentMutation>;
export type AssociatePurchaseOrdersToDocumentMutationOptions = Apollo.BaseMutationOptions<AssociatePurchaseOrdersToDocumentMutation, AssociatePurchaseOrdersToDocumentMutationVariables>;
export const AssociateGoodsReceiptsToDocumentDocument = gql`
    mutation associateGoodsReceiptsToDocument($documentId: String!, $goodsReceiptsIds: [String!]!) {
  associateGoodsReceiptsToDocument(
    documentId: $documentId
    goodsReceiptsIds: $goodsReceiptsIds
  ) {
    ...DocumentBookingAssociationData
  }
}
    ${DocumentBookingAssociationDataFragmentDoc}`;
export type AssociateGoodsReceiptsToDocumentMutationFn = Apollo.MutationFunction<AssociateGoodsReceiptsToDocumentMutation, AssociateGoodsReceiptsToDocumentMutationVariables>;

/**
 * __useAssociateGoodsReceiptsToDocumentMutation__
 *
 * To run a mutation, you first call `useAssociateGoodsReceiptsToDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateGoodsReceiptsToDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateGoodsReceiptsToDocumentMutation, { data, loading, error }] = useAssociateGoodsReceiptsToDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      goodsReceiptsIds: // value for 'goodsReceiptsIds'
 *   },
 * });
 */
export function useAssociateGoodsReceiptsToDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AssociateGoodsReceiptsToDocumentMutation, AssociateGoodsReceiptsToDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssociateGoodsReceiptsToDocumentMutation, AssociateGoodsReceiptsToDocumentMutationVariables>(AssociateGoodsReceiptsToDocumentDocument, options);
      }
export type AssociateGoodsReceiptsToDocumentMutationHookResult = ReturnType<typeof useAssociateGoodsReceiptsToDocumentMutation>;
export type AssociateGoodsReceiptsToDocumentMutationResult = Apollo.MutationResult<AssociateGoodsReceiptsToDocumentMutation>;
export type AssociateGoodsReceiptsToDocumentMutationOptions = Apollo.BaseMutationOptions<AssociateGoodsReceiptsToDocumentMutation, AssociateGoodsReceiptsToDocumentMutationVariables>;
export const DisconnectAllAssociatedDocumentsDocument = gql`
    mutation disconnectAllAssociatedDocuments($invoiceId: String!) {
  disconnectAllAssociatedDocuments(invoiceId: $invoiceId) {
    documentIds
  }
}
    `;
export type DisconnectAllAssociatedDocumentsMutationFn = Apollo.MutationFunction<DisconnectAllAssociatedDocumentsMutation, DisconnectAllAssociatedDocumentsMutationVariables>;

/**
 * __useDisconnectAllAssociatedDocumentsMutation__
 *
 * To run a mutation, you first call `useDisconnectAllAssociatedDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectAllAssociatedDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectAllAssociatedDocumentsMutation, { data, loading, error }] = useDisconnectAllAssociatedDocumentsMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useDisconnectAllAssociatedDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectAllAssociatedDocumentsMutation, DisconnectAllAssociatedDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectAllAssociatedDocumentsMutation, DisconnectAllAssociatedDocumentsMutationVariables>(DisconnectAllAssociatedDocumentsDocument, options);
      }
export type DisconnectAllAssociatedDocumentsMutationHookResult = ReturnType<typeof useDisconnectAllAssociatedDocumentsMutation>;
export type DisconnectAllAssociatedDocumentsMutationResult = Apollo.MutationResult<DisconnectAllAssociatedDocumentsMutation>;
export type DisconnectAllAssociatedDocumentsMutationOptions = Apollo.BaseMutationOptions<DisconnectAllAssociatedDocumentsMutation, DisconnectAllAssociatedDocumentsMutationVariables>;
export const UpdatePaymentDataDocument = gql`
    mutation updatePaymentData($id: ID!, $input: UpdatePaymentDataInput!) {
  updatePaymentData(id: $id, input: $input)
}
    `;
export type UpdatePaymentDataMutationFn = Apollo.MutationFunction<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>;

/**
 * __useUpdatePaymentDataMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentDataMutation, { data, loading, error }] = useUpdatePaymentDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>(UpdatePaymentDataDocument, options);
      }
export type UpdatePaymentDataMutationHookResult = ReturnType<typeof useUpdatePaymentDataMutation>;
export type UpdatePaymentDataMutationResult = Apollo.MutationResult<UpdatePaymentDataMutation>;
export type UpdatePaymentDataMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentDataMutation, UpdatePaymentDataMutationVariables>;
export const DocumentCollabsDocument = gql`
    subscription documentCollabs($documentId: ID!) {
  documentCollaborators(documentId: $documentId) {
    ...DocumentCollaborationData
  }
}
    ${DocumentCollaborationDataFragmentDoc}`;

/**
 * __useDocumentCollabsSubscription__
 *
 * To run a query within a React component, call `useDocumentCollabsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDocumentCollabsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentCollabsSubscription({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentCollabsSubscription(baseOptions: Apollo.SubscriptionHookOptions<DocumentCollabsSubscription, DocumentCollabsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DocumentCollabsSubscription, DocumentCollabsSubscriptionVariables>(DocumentCollabsDocument, options);
      }
export type DocumentCollabsSubscriptionHookResult = ReturnType<typeof useDocumentCollabsSubscription>;
export type DocumentCollabsSubscriptionResult = Apollo.SubscriptionResult<DocumentCollabsSubscription>;
export const AvailableDocumentTypesDocument = gql`
    query availableDocumentTypes($includeInactiveTypes: Boolean) {
  availableDocumentTypes(includeInactiveTypes: $includeInactiveTypes) {
    documentType
    documentCategory {
      direction
      documentType
      isDefault
      isRds1_0Exportable
      isBdsExportable
      supportedCurrencies
    }
  }
}
    `;

/**
 * __useAvailableDocumentTypesQuery__
 *
 * To run a query within a React component, call `useAvailableDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDocumentTypesQuery({
 *   variables: {
 *      includeInactiveTypes: // value for 'includeInactiveTypes'
 *   },
 * });
 */
export function useAvailableDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableDocumentTypesQuery, AvailableDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableDocumentTypesQuery, AvailableDocumentTypesQueryVariables>(AvailableDocumentTypesDocument, options);
      }
export function useAvailableDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableDocumentTypesQuery, AvailableDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableDocumentTypesQuery, AvailableDocumentTypesQueryVariables>(AvailableDocumentTypesDocument, options);
        }
export type AvailableDocumentTypesQueryHookResult = ReturnType<typeof useAvailableDocumentTypesQuery>;
export type AvailableDocumentTypesLazyQueryHookResult = ReturnType<typeof useAvailableDocumentTypesLazyQuery>;
export type AvailableDocumentTypesQueryResult = Apollo.QueryResult<AvailableDocumentTypesQuery, AvailableDocumentTypesQueryVariables>;
export const DocumentDataFileRecordsDocument = gql`
    query documentDataFileRecords {
  documentDataFileRecords {
    id
    recordCount
    fileType
    isExpired
    status
    file {
      id
      name
      url
      size
    }
    creator {
      name
      avatarUrl
    }
    createdAt
    isAcknowledged
    filters {
      costCenters {
        readableName
      }
      costObjects {
        readableName
      }
      extraCostInfos {
        readableName
      }
      generalLedgerAccounts {
        readableName
      }
      contacts {
        id
        name {
          value
        }
      }
      requesters {
        name
        username
      }
      approvers {
        name
        username
      }
      isOverdue
      isDuplicate
      isPayable
      isPaid
      statuses
      hasTransactionLinked
      artistSocialInsuranceCodes
    }
    query
    dateRangeFilters {
      invoiceDate {
        dateFrom
        dateTo
      }
      invoiceDueDate {
        dateFrom
        dateTo
      }
      invoiceDeliveryDate {
        dateFrom
        dateTo
      }
      paidAt {
        dateFrom
        dateTo
      }
      createdAt {
        dateFrom
        dateTo
      }
      dueDateWithCashDiscount {
        dateFrom
        dateTo
      }
    }
    ecmFilters {
      contactId {
        id
        name {
          value
        }
      }
      contractStatus
      documentDate {
        dateFrom
        dateTo
      }
      documentStatus
      documentType
      endDate {
        dateFrom
        dateTo
      }
      notifyPerson {
        name
      }
      responsiblePerson {
        name
      }
      startDate {
        dateFrom
        dateTo
      }
      documentSubCategory
      terminationDate {
        dateFrom
        dateTo
      }
      terminationReminderDate {
        dateFrom
        dateTo
      }
    }
  }
}
    `;

/**
 * __useDocumentDataFileRecordsQuery__
 *
 * To run a query within a React component, call `useDocumentDataFileRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDataFileRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDataFileRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentDataFileRecordsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentDataFileRecordsQuery, DocumentDataFileRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDataFileRecordsQuery, DocumentDataFileRecordsQueryVariables>(DocumentDataFileRecordsDocument, options);
      }
export function useDocumentDataFileRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDataFileRecordsQuery, DocumentDataFileRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDataFileRecordsQuery, DocumentDataFileRecordsQueryVariables>(DocumentDataFileRecordsDocument, options);
        }
export type DocumentDataFileRecordsQueryHookResult = ReturnType<typeof useDocumentDataFileRecordsQuery>;
export type DocumentDataFileRecordsLazyQueryHookResult = ReturnType<typeof useDocumentDataFileRecordsLazyQuery>;
export type DocumentDataFileRecordsQueryResult = Apollo.QueryResult<DocumentDataFileRecordsQuery, DocumentDataFileRecordsQueryVariables>;
export const KeepDuplicateDocument = gql`
    mutation keepDuplicate($docId: String!) {
  keepDuplicate(docId: $docId) {
    id
  }
}
    `;
export type KeepDuplicateMutationFn = Apollo.MutationFunction<KeepDuplicateMutation, KeepDuplicateMutationVariables>;

/**
 * __useKeepDuplicateMutation__
 *
 * To run a mutation, you first call `useKeepDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeepDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keepDuplicateMutation, { data, loading, error }] = useKeepDuplicateMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useKeepDuplicateMutation(baseOptions?: Apollo.MutationHookOptions<KeepDuplicateMutation, KeepDuplicateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KeepDuplicateMutation, KeepDuplicateMutationVariables>(KeepDuplicateDocument, options);
      }
export type KeepDuplicateMutationHookResult = ReturnType<typeof useKeepDuplicateMutation>;
export type KeepDuplicateMutationResult = Apollo.MutationResult<KeepDuplicateMutation>;
export type KeepDuplicateMutationOptions = Apollo.BaseMutationOptions<KeepDuplicateMutation, KeepDuplicateMutationVariables>;
export const DeleteOriginalDocument = gql`
    mutation deleteOriginal($docId: String!) {
  deleteOriginal(docId: $docId) {
    count
  }
}
    `;
export type DeleteOriginalMutationFn = Apollo.MutationFunction<DeleteOriginalMutation, DeleteOriginalMutationVariables>;

/**
 * __useDeleteOriginalMutation__
 *
 * To run a mutation, you first call `useDeleteOriginalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOriginalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOriginalMutation, { data, loading, error }] = useDeleteOriginalMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useDeleteOriginalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOriginalMutation, DeleteOriginalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOriginalMutation, DeleteOriginalMutationVariables>(DeleteOriginalDocument, options);
      }
export type DeleteOriginalMutationHookResult = ReturnType<typeof useDeleteOriginalMutation>;
export type DeleteOriginalMutationResult = Apollo.MutationResult<DeleteOriginalMutation>;
export type DeleteOriginalMutationOptions = Apollo.BaseMutationOptions<DeleteOriginalMutation, DeleteOriginalMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: ID!) {
  deleteById(id: $id) {
    count
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const EcmDocumentDocument = gql`
    query ecmDocument($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    id
    documentType
    createdAt
    createdBy {
      id
      name
      avatarUrl
    }
    updatedAt
    updatedBy {
      id
      name
      avatarUrl
    }
    contactId
    contactName
    invoiceId
    notes
    documentNumber
    documentDate
    documentStatus
    isSensitive
    tags {
      ...Tag
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useEcmDocumentQuery__
 *
 * To run a query within a React component, call `useEcmDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEcmDocumentQuery(baseOptions: Apollo.QueryHookOptions<EcmDocumentQuery, EcmDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmDocumentQuery, EcmDocumentQueryVariables>(EcmDocumentDocument, options);
      }
export function useEcmDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmDocumentQuery, EcmDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmDocumentQuery, EcmDocumentQueryVariables>(EcmDocumentDocument, options);
        }
export type EcmDocumentQueryHookResult = ReturnType<typeof useEcmDocumentQuery>;
export type EcmDocumentLazyQueryHookResult = ReturnType<typeof useEcmDocumentLazyQuery>;
export type EcmDocumentQueryResult = Apollo.QueryResult<EcmDocumentQuery, EcmDocumentQueryVariables>;
export const EcmDocumentFileDocument = gql`
    query ecmDocumentFile($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    ...EcmDocumentFile
  }
}
    ${EcmDocumentFileFragmentDoc}`;

/**
 * __useEcmDocumentFileQuery__
 *
 * To run a query within a React component, call `useEcmDocumentFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmDocumentFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmDocumentFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEcmDocumentFileQuery(baseOptions: Apollo.QueryHookOptions<EcmDocumentFileQuery, EcmDocumentFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmDocumentFileQuery, EcmDocumentFileQueryVariables>(EcmDocumentFileDocument, options);
      }
export function useEcmDocumentFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmDocumentFileQuery, EcmDocumentFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmDocumentFileQuery, EcmDocumentFileQueryVariables>(EcmDocumentFileDocument, options);
        }
export type EcmDocumentFileQueryHookResult = ReturnType<typeof useEcmDocumentFileQuery>;
export type EcmDocumentFileLazyQueryHookResult = ReturnType<typeof useEcmDocumentFileLazyQuery>;
export type EcmDocumentFileQueryResult = Apollo.QueryResult<EcmDocumentFileQuery, EcmDocumentFileQueryVariables>;
export const EcmDocumentHistoryDocument = gql`
    query ecmDocumentHistory($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    id
    timeline {
      __typename
      id
      createdAt
      ...ApprovedEventData
      ...ApproverExtractedEventData
      ...AttachmentConvertedToDocumentEventData
      ...AttachmentDeletedFromDocumentEventData
      ...AttachmentDetachedFromDocumentEventData
      ...ContactExtractionEventData
      ...CostCenterExtractionEventData
      ...DocumentCommentEventData
      ...DocumentConvertedToAttachmentEventData
      ...DocumentFileReplacedEventData
      ...DocumentPaymentInfoUpdatedEventData
      ...DocumentResetByCandisEventData
      ...DocumentResetManualEventData
      ...DocumentResetTransactionAmountUpdatedEventData
      ...DocumentResetTransactionLinkedEventData
      ...DocumentResetTransactionUnLinkedEventData
      ...DocumentResetUserDeactivationEventData
      ...DocumentResetUserRoleChangedEventData
      ...DocumentTransactionCommentCreatedEventData
      ...DocumentTransactionLinkedEventData
      ...DocumentTransactionStatusUpdatedEventData
      ...DocumentTransactionReconciledEventData
      ...DocumentTransactionUnlinkedEventData
      ...ExportedEventData
      ...FileAttachedToDocumentEventData
      ...FileUploadedByEmailEventData
      ...FileUploadedEventData
      ...MetaDataExtractionEventData
      ...ProvisionExportedEventData
      ...ReversalExportedEventData
      ...RejectedEventData
      ...RequestApprovalEventData
      ...SepaTransferGeneratedEventData
      ...SingleBookingUpdatedEventData
      ...SplitBookingUpdatedEventData
      ...UserApprovalDocumentWorkflowStepData
      ...WorkflowStepSkippedEventData
      ...WorkflowTemplateAppliedToDocumentEventData
      ...ContactSupplierSendEmailEventData
      ...DocumentStoredAsEcmDocumentEventData
      ...DocumentUpdatedAsEcmDocumentEventData
      ...DocumentImportedByMigrationEventData
      ...DocumentRelationshipCreatedEventData
      ...DocumentRelationshipRemovedEventData
      ...DocumentTagUpdatedEventData
      ...DocumentConvertedEventData
      ...DocumentClassifiedEventData
    }
  }
}
    ${ApprovedEventDataFragmentDoc}
${ApproverExtractedEventDataFragmentDoc}
${AttachmentConvertedToDocumentEventDataFragmentDoc}
${AttachmentDeletedFromDocumentEventDataFragmentDoc}
${AttachmentDetachedFromDocumentEventDataFragmentDoc}
${ContactExtractionEventDataFragmentDoc}
${CostCenterExtractionEventDataFragmentDoc}
${DocumentCommentEventDataFragmentDoc}
${DocumentConvertedToAttachmentEventDataFragmentDoc}
${DocumentFileReplacedEventDataFragmentDoc}
${DocumentPaymentInfoUpdatedEventDataFragmentDoc}
${DocumentResetByCandisEventDataFragmentDoc}
${DocumentResetManualEventDataFragmentDoc}
${DocumentResetTransactionAmountUpdatedEventDataFragmentDoc}
${DocumentResetTransactionLinkedEventDataFragmentDoc}
${DocumentResetTransactionUnLinkedEventDataFragmentDoc}
${DocumentResetUserDeactivationEventDataFragmentDoc}
${DocumentResetUserRoleChangedEventDataFragmentDoc}
${DocumentTransactionCommentCreatedEventDataFragmentDoc}
${DocumentTransactionLinkedEventDataFragmentDoc}
${DocumentTransactionStatusUpdatedEventDataFragmentDoc}
${DocumentTransactionReconciledEventDataFragmentDoc}
${DocumentTransactionUnlinkedEventDataFragmentDoc}
${ExportedEventDataFragmentDoc}
${FileAttachedToDocumentEventDataFragmentDoc}
${FileUploadedByEmailEventDataFragmentDoc}
${FileUploadedEventDataFragmentDoc}
${MetaDataExtractionEventDataFragmentDoc}
${ProvisionExportedEventDataFragmentDoc}
${ReversalExportedEventDataFragmentDoc}
${RejectedEventDataFragmentDoc}
${RequestApprovalEventDataFragmentDoc}
${SepaTransferGeneratedEventDataFragmentDoc}
${SingleBookingUpdatedEventDataFragmentDoc}
${SplitBookingUpdatedEventDataFragmentDoc}
${UserApprovalDocumentWorkflowStepDataFragmentDoc}
${WorkflowStepSkippedEventDataFragmentDoc}
${WorkflowTemplateAppliedToDocumentEventDataFragmentDoc}
${ContactSupplierSendEmailEventDataFragmentDoc}
${DocumentStoredAsEcmDocumentEventDataFragmentDoc}
${DocumentUpdatedAsEcmDocumentEventDataFragmentDoc}
${DocumentImportedByMigrationEventDataFragmentDoc}
${DocumentRelationshipCreatedEventDataFragmentDoc}
${RelatedDocumentActivityFragmentDoc}
${DocumentRelationshipRemovedEventDataFragmentDoc}
${DocumentTagUpdatedEventDataFragmentDoc}
${DocumentConvertedEventDataFragmentDoc}
${DocumentClassifiedEventDataFragmentDoc}`;

/**
 * __useEcmDocumentHistoryQuery__
 *
 * To run a query within a React component, call `useEcmDocumentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmDocumentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmDocumentHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEcmDocumentHistoryQuery(baseOptions: Apollo.QueryHookOptions<EcmDocumentHistoryQuery, EcmDocumentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmDocumentHistoryQuery, EcmDocumentHistoryQueryVariables>(EcmDocumentHistoryDocument, options);
      }
export function useEcmDocumentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmDocumentHistoryQuery, EcmDocumentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmDocumentHistoryQuery, EcmDocumentHistoryQueryVariables>(EcmDocumentHistoryDocument, options);
        }
export type EcmDocumentHistoryQueryHookResult = ReturnType<typeof useEcmDocumentHistoryQuery>;
export type EcmDocumentHistoryLazyQueryHookResult = ReturnType<typeof useEcmDocumentHistoryLazyQuery>;
export type EcmDocumentHistoryQueryResult = Apollo.QueryResult<EcmDocumentHistoryQuery, EcmDocumentHistoryQueryVariables>;
export const SendEmailToSupplierDocument = gql`
    mutation sendEmailToSupplier($documentId: String!, $emailInput: SendEmailWithoutTemplateArgs!) {
  sendEmailToSupplier(input: {documentId: $documentId, emailInput: $emailInput}) {
    _id
    status
  }
}
    `;
export type SendEmailToSupplierMutationFn = Apollo.MutationFunction<SendEmailToSupplierMutation, SendEmailToSupplierMutationVariables>;

/**
 * __useSendEmailToSupplierMutation__
 *
 * To run a mutation, you first call `useSendEmailToSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSupplierMutation, { data, loading, error }] = useSendEmailToSupplierMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      emailInput: // value for 'emailInput'
 *   },
 * });
 */
export function useSendEmailToSupplierMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailToSupplierMutation, SendEmailToSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailToSupplierMutation, SendEmailToSupplierMutationVariables>(SendEmailToSupplierDocument, options);
      }
export type SendEmailToSupplierMutationHookResult = ReturnType<typeof useSendEmailToSupplierMutation>;
export type SendEmailToSupplierMutationResult = Apollo.MutationResult<SendEmailToSupplierMutation>;
export type SendEmailToSupplierMutationOptions = Apollo.BaseMutationOptions<SendEmailToSupplierMutation, SendEmailToSupplierMutationVariables>;
export const GetOrganizationEmailAccountsDocument = gql`
    query getOrganizationEmailAccounts {
  organization {
    id
    emailAccounts {
      records {
        id
        address
        isEnabled
        organizationId
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationEmailAccountsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationEmailAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationEmailAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationEmailAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationEmailAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationEmailAccountsQuery, GetOrganizationEmailAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationEmailAccountsQuery, GetOrganizationEmailAccountsQueryVariables>(GetOrganizationEmailAccountsDocument, options);
      }
export function useGetOrganizationEmailAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationEmailAccountsQuery, GetOrganizationEmailAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationEmailAccountsQuery, GetOrganizationEmailAccountsQueryVariables>(GetOrganizationEmailAccountsDocument, options);
        }
export type GetOrganizationEmailAccountsQueryHookResult = ReturnType<typeof useGetOrganizationEmailAccountsQuery>;
export type GetOrganizationEmailAccountsLazyQueryHookResult = ReturnType<typeof useGetOrganizationEmailAccountsLazyQuery>;
export type GetOrganizationEmailAccountsQueryResult = Apollo.QueryResult<GetOrganizationEmailAccountsQuery, GetOrganizationEmailAccountsQueryVariables>;
export const StoreEcmDocumentDocument = gql`
    mutation storeEcmDocument($input: StoreEcmDocumentInput!) {
  storeEcmDocument(input: $input) {
    ecmDocumentId
  }
}
    `;
export type StoreEcmDocumentMutationFn = Apollo.MutationFunction<StoreEcmDocumentMutation, StoreEcmDocumentMutationVariables>;

/**
 * __useStoreEcmDocumentMutation__
 *
 * To run a mutation, you first call `useStoreEcmDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreEcmDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeEcmDocumentMutation, { data, loading, error }] = useStoreEcmDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoreEcmDocumentMutation(baseOptions?: Apollo.MutationHookOptions<StoreEcmDocumentMutation, StoreEcmDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreEcmDocumentMutation, StoreEcmDocumentMutationVariables>(StoreEcmDocumentDocument, options);
      }
export type StoreEcmDocumentMutationHookResult = ReturnType<typeof useStoreEcmDocumentMutation>;
export type StoreEcmDocumentMutationResult = Apollo.MutationResult<StoreEcmDocumentMutation>;
export type StoreEcmDocumentMutationOptions = Apollo.BaseMutationOptions<StoreEcmDocumentMutation, StoreEcmDocumentMutationVariables>;
export const StoreSensitiveEcmDocumentDocument = gql`
    mutation storeSensitiveEcmDocument($input: StoreSensitiveEcmDocumentInput!) {
  storeSensitiveEcmDocument(input: $input) {
    ecmDocumentId
  }
}
    `;
export type StoreSensitiveEcmDocumentMutationFn = Apollo.MutationFunction<StoreSensitiveEcmDocumentMutation, StoreSensitiveEcmDocumentMutationVariables>;

/**
 * __useStoreSensitiveEcmDocumentMutation__
 *
 * To run a mutation, you first call `useStoreSensitiveEcmDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreSensitiveEcmDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeSensitiveEcmDocumentMutation, { data, loading, error }] = useStoreSensitiveEcmDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoreSensitiveEcmDocumentMutation(baseOptions?: Apollo.MutationHookOptions<StoreSensitiveEcmDocumentMutation, StoreSensitiveEcmDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreSensitiveEcmDocumentMutation, StoreSensitiveEcmDocumentMutationVariables>(StoreSensitiveEcmDocumentDocument, options);
      }
export type StoreSensitiveEcmDocumentMutationHookResult = ReturnType<typeof useStoreSensitiveEcmDocumentMutation>;
export type StoreSensitiveEcmDocumentMutationResult = Apollo.MutationResult<StoreSensitiveEcmDocumentMutation>;
export type StoreSensitiveEcmDocumentMutationOptions = Apollo.BaseMutationOptions<StoreSensitiveEcmDocumentMutation, StoreSensitiveEcmDocumentMutationVariables>;
export const UpdateEcmDocumentDocument = gql`
    mutation updateEcmDocument($input: UpdateEcmDocumentInput!) {
  updateEcmDocument(input: $input) {
    ecmDocumentId
  }
}
    `;
export type UpdateEcmDocumentMutationFn = Apollo.MutationFunction<UpdateEcmDocumentMutation, UpdateEcmDocumentMutationVariables>;

/**
 * __useUpdateEcmDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateEcmDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEcmDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEcmDocumentMutation, { data, loading, error }] = useUpdateEcmDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEcmDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEcmDocumentMutation, UpdateEcmDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEcmDocumentMutation, UpdateEcmDocumentMutationVariables>(UpdateEcmDocumentDocument, options);
      }
export type UpdateEcmDocumentMutationHookResult = ReturnType<typeof useUpdateEcmDocumentMutation>;
export type UpdateEcmDocumentMutationResult = Apollo.MutationResult<UpdateEcmDocumentMutation>;
export type UpdateEcmDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateEcmDocumentMutation, UpdateEcmDocumentMutationVariables>;
export const RemoveEcmDocumentDocument = gql`
    mutation removeEcmDocument($input: RemoveEcmDocumentInput!) {
  removeEcmDocument(input: $input) {
    ecmDocumentId
  }
}
    `;
export type RemoveEcmDocumentMutationFn = Apollo.MutationFunction<RemoveEcmDocumentMutation, RemoveEcmDocumentMutationVariables>;

/**
 * __useRemoveEcmDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveEcmDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEcmDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEcmDocumentMutation, { data, loading, error }] = useRemoveEcmDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEcmDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEcmDocumentMutation, RemoveEcmDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEcmDocumentMutation, RemoveEcmDocumentMutationVariables>(RemoveEcmDocumentDocument, options);
      }
export type RemoveEcmDocumentMutationHookResult = ReturnType<typeof useRemoveEcmDocumentMutation>;
export type RemoveEcmDocumentMutationResult = Apollo.MutationResult<RemoveEcmDocumentMutation>;
export type RemoveEcmDocumentMutationOptions = Apollo.BaseMutationOptions<RemoveEcmDocumentMutation, RemoveEcmDocumentMutationVariables>;
export const StorageFormInitialDataDocument = gql`
    query storageFormInitialData($documentId: ID!) {
  getDocument(id: $documentId) {
    contact {
      value {
        id
        name {
          value
        }
      }
      source
      confidence
    }
    extractedContact {
      id
      cachingKey
      name {
        value
        confidence
        source
      }
      iban {
        value
        confidence
        source
      }
      bankAccountNumber {
        value
        confidence
        source
      }
      swiftCode {
        value
        confidence
        source
      }
      taxNumber {
        value
        confidence
        source
      }
      vatId {
        value
        confidence
        source
      }
      createTransfer {
        value
        confidence
        source
      }
      email {
        value
        confidence
        source
      }
      phoneNumber {
        value
        confidence
        source
      }
      street {
        value
        confidence
        source
      }
      postOfficeBox {
        value
        confidence
        source
      }
      postalCode {
        value
        confidence
        source
      }
      city {
        value
        confidence
        source
      }
      countryISOCode {
        value
        confidence
        source
      }
      customerNumber {
        value
        confidence
        source
      }
    }
    documentFile {
      name
      size
      url
    }
    status
    invoiceDate {
      value
      confidence
      source
    }
    invoiceNumber {
      value
      confidence
      source
    }
    amount {
      value
      confidence
      source
    }
    currency {
      value
      confidence
      source
    }
    bookings {
      costCenter {
        value {
          id
          readableName
        }
        confidence
        source
      }
    }
    tags {
      ...Tag
      __typename
    }
    globalDocumentId
    isReadOnly
    accessModificationPermissions {
      viewAccess
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useStorageFormInitialDataQuery__
 *
 * To run a query within a React component, call `useStorageFormInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageFormInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageFormInitialDataQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useStorageFormInitialDataQuery(baseOptions: Apollo.QueryHookOptions<StorageFormInitialDataQuery, StorageFormInitialDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorageFormInitialDataQuery, StorageFormInitialDataQueryVariables>(StorageFormInitialDataDocument, options);
      }
export function useStorageFormInitialDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorageFormInitialDataQuery, StorageFormInitialDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorageFormInitialDataQuery, StorageFormInitialDataQueryVariables>(StorageFormInitialDataDocument, options);
        }
export type StorageFormInitialDataQueryHookResult = ReturnType<typeof useStorageFormInitialDataQuery>;
export type StorageFormInitialDataLazyQueryHookResult = ReturnType<typeof useStorageFormInitialDataLazyQuery>;
export type StorageFormInitialDataQueryResult = Apollo.QueryResult<StorageFormInitialDataQuery, StorageFormInitialDataQueryVariables>;
export const EcmStorageFormInitialDataDocument = gql`
    query ecmStorageFormInitialData($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    id
    documentType
    type
    contactId
    contactName
    createdBy {
      id
      name
    }
    notes
    documentDate
    documentName
    documentNumber
    documentStatus
    isReadOnly
    permissionsForMembership
    accessModificationPermissions {
      viewAccess
    }
    fileSource {
      name
    }
    costCenter {
      id
      readableName
    }
    documentSubCategory {
      id
      name
    }
    startDate
    endDate
    terminationDate
    terminationReminderDate
    amount {
      amount
      currency
      precision
    }
    responsiblePerson {
      primary {
        id
        name
        avatarUrl
      }
    }
    notifyPerson {
      primary {
        id
        name
        avatarUrl
      }
    }
    isSensitive
    extractedContact {
      id
      name
      iban
      bankAccountNumber
      swiftCode
      taxNumber
      vatId
      automaticPayment
      email
      phoneNumber
      street
      postOfficeBox
      postalCode
      city
      country
      customerNumber
    }
    globalDocumentId
    tags {
      ...Tag
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useEcmStorageFormInitialDataQuery__
 *
 * To run a query within a React component, call `useEcmStorageFormInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmStorageFormInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmStorageFormInitialDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEcmStorageFormInitialDataQuery(baseOptions: Apollo.QueryHookOptions<EcmStorageFormInitialDataQuery, EcmStorageFormInitialDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmStorageFormInitialDataQuery, EcmStorageFormInitialDataQueryVariables>(EcmStorageFormInitialDataDocument, options);
      }
export function useEcmStorageFormInitialDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmStorageFormInitialDataQuery, EcmStorageFormInitialDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmStorageFormInitialDataQuery, EcmStorageFormInitialDataQueryVariables>(EcmStorageFormInitialDataDocument, options);
        }
export type EcmStorageFormInitialDataQueryHookResult = ReturnType<typeof useEcmStorageFormInitialDataQuery>;
export type EcmStorageFormInitialDataLazyQueryHookResult = ReturnType<typeof useEcmStorageFormInitialDataLazyQuery>;
export type EcmStorageFormInitialDataQueryResult = Apollo.QueryResult<EcmStorageFormInitialDataQuery, EcmStorageFormInitialDataQueryVariables>;
export const EcmDocumentPermissionsDocument = gql`
    query ecmDocumentPermissions($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    id
    permissionsForMembership
    isReadOnly
    accessModificationPermissions {
      viewAccess
    }
  }
}
    `;

/**
 * __useEcmDocumentPermissionsQuery__
 *
 * To run a query within a React component, call `useEcmDocumentPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmDocumentPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmDocumentPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEcmDocumentPermissionsQuery(baseOptions: Apollo.QueryHookOptions<EcmDocumentPermissionsQuery, EcmDocumentPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmDocumentPermissionsQuery, EcmDocumentPermissionsQueryVariables>(EcmDocumentPermissionsDocument, options);
      }
export function useEcmDocumentPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmDocumentPermissionsQuery, EcmDocumentPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmDocumentPermissionsQuery, EcmDocumentPermissionsQueryVariables>(EcmDocumentPermissionsDocument, options);
        }
export type EcmDocumentPermissionsQueryHookResult = ReturnType<typeof useEcmDocumentPermissionsQuery>;
export type EcmDocumentPermissionsLazyQueryHookResult = ReturnType<typeof useEcmDocumentPermissionsLazyQuery>;
export type EcmDocumentPermissionsQueryResult = Apollo.QueryResult<EcmDocumentPermissionsQuery, EcmDocumentPermissionsQueryVariables>;
export const EcmDocumentStatusDocument = gql`
    query ecmDocumentStatus($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    documentStatus
    isSensitive
  }
}
    `;

/**
 * __useEcmDocumentStatusQuery__
 *
 * To run a query within a React component, call `useEcmDocumentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmDocumentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmDocumentStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEcmDocumentStatusQuery(baseOptions: Apollo.QueryHookOptions<EcmDocumentStatusQuery, EcmDocumentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmDocumentStatusQuery, EcmDocumentStatusQueryVariables>(EcmDocumentStatusDocument, options);
      }
export function useEcmDocumentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmDocumentStatusQuery, EcmDocumentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmDocumentStatusQuery, EcmDocumentStatusQueryVariables>(EcmDocumentStatusDocument, options);
        }
export type EcmDocumentStatusQueryHookResult = ReturnType<typeof useEcmDocumentStatusQuery>;
export type EcmDocumentStatusLazyQueryHookResult = ReturnType<typeof useEcmDocumentStatusLazyQuery>;
export type EcmDocumentStatusQueryResult = Apollo.QueryResult<EcmDocumentStatusQuery, EcmDocumentStatusQueryVariables>;
export const MembersByRoleDocument = gql`
    query membersByRole($filters: OrganizationMembershipsFiltersInput) {
  organizationMemberships(filters: $filters) {
    id
    name
    avatarUrl
  }
}
    `;

/**
 * __useMembersByRoleQuery__
 *
 * To run a query within a React component, call `useMembersByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersByRoleQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMembersByRoleQuery(baseOptions?: Apollo.QueryHookOptions<MembersByRoleQuery, MembersByRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersByRoleQuery, MembersByRoleQueryVariables>(MembersByRoleDocument, options);
      }
export function useMembersByRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersByRoleQuery, MembersByRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersByRoleQuery, MembersByRoleQueryVariables>(MembersByRoleDocument, options);
        }
export type MembersByRoleQueryHookResult = ReturnType<typeof useMembersByRoleQuery>;
export type MembersByRoleLazyQueryHookResult = ReturnType<typeof useMembersByRoleLazyQuery>;
export type MembersByRoleQueryResult = Apollo.QueryResult<MembersByRoleQuery, MembersByRoleQueryVariables>;
export const DocumentFastApproveDocument = gql`
    mutation documentFastApprove($id: ID!, $input: DocumentFastApproveInput!, $options: DocumentFastApproveOptionsInput) {
  documentFastApprove(id: $id, input: $input, options: $options) {
    __typename
    ...DocumentData
    ... on DocumentFastApproveErrors {
      errors {
        ...DocumentCannotBeModifedErrorData
        ...DocumentIsAlreadyRequestedForApprovalErrorData
        ...InvalidInvoiceDateErrorData
        ...InvalidDeliveryDateErrorData
        ...BookingsErrorData
        ... on DocumentApprovalError {
          kind
          message
        }
      }
    }
  }
}
    ${DocumentDataFragmentDoc}
${TagFragmentDoc}
${DocumentCannotBeModifedErrorDataFragmentDoc}
${DocumentIsAlreadyRequestedForApprovalErrorDataFragmentDoc}
${InvalidInvoiceDateErrorDataFragmentDoc}
${InvalidDeliveryDateErrorDataFragmentDoc}
${BookingsErrorDataFragmentDoc}
${BookingKeyArchivedErrorDataFragmentDoc}
${CostCenterArchivedErrorDataFragmentDoc}
${CostObjectArchivedErrorDataFragmentDoc}
${ExtraCostInfoArchivedErrorDataFragmentDoc}
${GeneralLedgerArchivedErrorDataFragmentDoc}
${ProjectCodeArchivedErrorDataFragmentDoc}`;
export type DocumentFastApproveMutationFn = Apollo.MutationFunction<DocumentFastApproveMutation, DocumentFastApproveMutationVariables>;

/**
 * __useDocumentFastApproveMutation__
 *
 * To run a mutation, you first call `useDocumentFastApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentFastApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentFastApproveMutation, { data, loading, error }] = useDocumentFastApproveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDocumentFastApproveMutation(baseOptions?: Apollo.MutationHookOptions<DocumentFastApproveMutation, DocumentFastApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentFastApproveMutation, DocumentFastApproveMutationVariables>(DocumentFastApproveDocument, options);
      }
export type DocumentFastApproveMutationHookResult = ReturnType<typeof useDocumentFastApproveMutation>;
export type DocumentFastApproveMutationResult = Apollo.MutationResult<DocumentFastApproveMutation>;
export type DocumentFastApproveMutationOptions = Apollo.BaseMutationOptions<DocumentFastApproveMutation, DocumentFastApproveMutationVariables>;
export const DocumentRequestApprovalDocument = gql`
    mutation documentRequestApproval($id: ID!, $value: DocumentRequestApprovalInput!, $options: DocumentRequestApprovalOptionsInput) {
  documentRequestApprovalUnionResponse(id: $id, input: $value, options: $options) {
    __typename
    ...DocumentData
    ... on DocumentRequestApprovalErrors {
      errors {
        ...DocumentCannotBeModifedErrorData
        ...DocumentIsAlreadyRequestedForApprovalErrorData
        ...InvalidInvoiceDateErrorData
        ...InvalidDeliveryDateErrorData
        ...WorkflowTemplateArchivedErrorData
        ...BookingsErrorData
        ... on DocumentApprovalError {
          kind
          message
        }
      }
    }
  }
}
    ${DocumentDataFragmentDoc}
${TagFragmentDoc}
${DocumentCannotBeModifedErrorDataFragmentDoc}
${DocumentIsAlreadyRequestedForApprovalErrorDataFragmentDoc}
${InvalidInvoiceDateErrorDataFragmentDoc}
${InvalidDeliveryDateErrorDataFragmentDoc}
${WorkflowTemplateArchivedErrorDataFragmentDoc}
${BookingsErrorDataFragmentDoc}
${BookingKeyArchivedErrorDataFragmentDoc}
${CostCenterArchivedErrorDataFragmentDoc}
${CostObjectArchivedErrorDataFragmentDoc}
${ExtraCostInfoArchivedErrorDataFragmentDoc}
${GeneralLedgerArchivedErrorDataFragmentDoc}
${ProjectCodeArchivedErrorDataFragmentDoc}`;
export type DocumentRequestApprovalMutationFn = Apollo.MutationFunction<DocumentRequestApprovalMutation, DocumentRequestApprovalMutationVariables>;

/**
 * __useDocumentRequestApprovalMutation__
 *
 * To run a mutation, you first call `useDocumentRequestApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentRequestApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentRequestApprovalMutation, { data, loading, error }] = useDocumentRequestApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDocumentRequestApprovalMutation(baseOptions?: Apollo.MutationHookOptions<DocumentRequestApprovalMutation, DocumentRequestApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentRequestApprovalMutation, DocumentRequestApprovalMutationVariables>(DocumentRequestApprovalDocument, options);
      }
export type DocumentRequestApprovalMutationHookResult = ReturnType<typeof useDocumentRequestApprovalMutation>;
export type DocumentRequestApprovalMutationResult = Apollo.MutationResult<DocumentRequestApprovalMutation>;
export type DocumentRequestApprovalMutationOptions = Apollo.BaseMutationOptions<DocumentRequestApprovalMutation, DocumentRequestApprovalMutationVariables>;
export const InvoiceDocumentPermissionsDocument = gql`
    query invoiceDocumentPermissions($id: ID!) {
  getDocument(id: $id) {
    id
    permissionsForMembership
    isReadOnly
    accessModificationPermissions {
      viewAccess
    }
  }
}
    `;

/**
 * __useInvoiceDocumentPermissionsQuery__
 *
 * To run a query within a React component, call `useInvoiceDocumentPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDocumentPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceDocumentPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceDocumentPermissionsQuery(baseOptions: Apollo.QueryHookOptions<InvoiceDocumentPermissionsQuery, InvoiceDocumentPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceDocumentPermissionsQuery, InvoiceDocumentPermissionsQueryVariables>(InvoiceDocumentPermissionsDocument, options);
      }
export function useInvoiceDocumentPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceDocumentPermissionsQuery, InvoiceDocumentPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceDocumentPermissionsQuery, InvoiceDocumentPermissionsQueryVariables>(InvoiceDocumentPermissionsDocument, options);
        }
export type InvoiceDocumentPermissionsQueryHookResult = ReturnType<typeof useInvoiceDocumentPermissionsQuery>;
export type InvoiceDocumentPermissionsLazyQueryHookResult = ReturnType<typeof useInvoiceDocumentPermissionsLazyQuery>;
export type InvoiceDocumentPermissionsQueryResult = Apollo.QueryResult<InvoiceDocumentPermissionsQuery, InvoiceDocumentPermissionsQueryVariables>;
export const GetEInvoiceValidationProtocolDataDocument = gql`
    query getEInvoiceValidationProtocolData($id: ID!) {
  getDocument(id: $id) {
    id
    timeline {
      __typename
      id
      ...InvalidEInvoiceDetectedEventData
    }
    eInvoiceErrors {
      message
      code
      location
      codeHelpCenterUrl {
        en
        de
      }
    }
    eInvoice {
      error
      format
    }
  }
}
    ${InvalidEInvoiceDetectedEventDataFragmentDoc}`;

/**
 * __useGetEInvoiceValidationProtocolDataQuery__
 *
 * To run a query within a React component, call `useGetEInvoiceValidationProtocolDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEInvoiceValidationProtocolDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEInvoiceValidationProtocolDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEInvoiceValidationProtocolDataQuery(baseOptions: Apollo.QueryHookOptions<GetEInvoiceValidationProtocolDataQuery, GetEInvoiceValidationProtocolDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEInvoiceValidationProtocolDataQuery, GetEInvoiceValidationProtocolDataQueryVariables>(GetEInvoiceValidationProtocolDataDocument, options);
      }
export function useGetEInvoiceValidationProtocolDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEInvoiceValidationProtocolDataQuery, GetEInvoiceValidationProtocolDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEInvoiceValidationProtocolDataQuery, GetEInvoiceValidationProtocolDataQueryVariables>(GetEInvoiceValidationProtocolDataDocument, options);
        }
export type GetEInvoiceValidationProtocolDataQueryHookResult = ReturnType<typeof useGetEInvoiceValidationProtocolDataQuery>;
export type GetEInvoiceValidationProtocolDataLazyQueryHookResult = ReturnType<typeof useGetEInvoiceValidationProtocolDataLazyQuery>;
export type GetEInvoiceValidationProtocolDataQueryResult = Apollo.QueryResult<GetEInvoiceValidationProtocolDataQuery, GetEInvoiceValidationProtocolDataQueryVariables>;
export const GetDocumentForDraftDocument = gql`
    query getDocumentForDraft($id: ID!) {
  getDocument(id: $id) {
    id
    isDuplicate
    isOverdue
    eInvoice {
      format
      error
    }
    isEInvoice
    eInvoiceValidationErrors
    isReadOnly
    isWaitingForClarification
    hasAttachments
    accessModificationPermissions {
      viewAccess
    }
    hasTransactionLinked
    transactions {
      ...TransactionDetailsData
    }
    purchaseOrderData {
      orderNumber
      purchaseOrderId
      integrationType
    }
    category {
      direction {
        value
        confidence
      }
      documentType {
        value
        confidence
        source
      }
    }
    contact {
      value {
        id
        accountsPayableNumber
        accountsReceivableNumber
        name {
          value
        }
        iban {
          value
        }
        swiftCode
        taxNumber {
          value
        }
        vatId {
          value
        }
        createTransfer {
          value
        }
        dueDateOffset {
          value
        }
        paymentConditionId
        email
      }
      source
      confidence
    }
    status
    roundingAmount
    accountingArea {
      value {
        id
        name
      }
      confidence
      source
    }
    invoiceNumber {
      value
      confidence
      source
    }
    invoiceDate {
      value
      confidence
      source
    }
    invoiceDeliveryDate {
      value
      confidence
      source
    }
    invoicePostingDate {
      value
      confidence
      source
    }
    amount {
      value
      confidence
      source
    }
    currency {
      value
      confidence
      source
    }
    iban {
      value
      confidence
      source
    }
    swiftCode {
      value
      confidence
      source
    }
    createTransfer {
      value
      confidence
      source
    }
    paymentState {
      __typename
      ... on PaidDocumentState {
        paidAt
      }
    }
    approvers {
      id
      name
      avatarUrl
    }
    suggestedApprovers {
      value {
        id
        name
        avatarUrl
      }
      confidence
      source
    }
    documentFile {
      id
      name
    }
    approvers {
      id
    }
    requester {
      id
    }
    rolesThatAreEligibleToApprove
    originalDocumentId {
      value
      confidence
    }
    extractedContact {
      id
      cachingKey
      name {
        value
        confidence
        source
      }
      iban {
        value
        confidence
        source
      }
      bankAccountNumber {
        value
        confidence
        source
      }
      swiftCode {
        value
        confidence
        source
      }
      taxNumber {
        value
        confidence
        source
      }
      vatId {
        value
        confidence
        source
      }
      createTransfer {
        value
        confidence
        source
      }
      email {
        value
        confidence
        source
      }
      phoneNumber {
        value
        confidence
        source
      }
      street {
        value
        confidence
        source
      }
      postOfficeBox {
        value
        confidence
        source
      }
      postalCode {
        value
        confidence
        source
      }
      city {
        value
        confidence
        source
      }
      countryISOCode {
        value
        confidence
        source
      }
      customerNumber {
        value
        confidence
        source
      }
    }
    currentWorkflowStep {
      id
    }
    workflow {
      id
      name
      description
      workflowTemplate {
        id
        name
        steps {
          resolvers {
            id
            name
          }
        }
      }
      steps {
        id
        resolution {
          approvers {
            id
            name
          }
          type
        }
        approvers {
          id
          name
          substitute {
            id
            name
          }
        }
      }
    }
    bookings {
      id
      amount {
        value
      }
      taxAmount
      netAmount
      vatRate {
        value
        confidence
        source
      }
      note {
        value
        confidence
        source
      }
      postingText
      dueDate {
        value
        confidence
        source
      }
      generalLedgerAccount {
        value {
          id
          accountCode
          readableName
          name
        }
        confidence
        source
      }
      bookingKey {
        value {
          id
          taxCode
          readableName
          isAcquisitionReverse
        }
        confidence
        source
      }
      costCenter {
        value {
          id
          code
          name
          readableName
          approvers {
            id
            name
            avatarUrl
          }
        }
        confidence
        source
      }
      costObject {
        value {
          id
          code
          name
          readableName
        }
        confidence
        source
      }
      extraCostInfo {
        value {
          id
          code
          name
          readableName
        }
        confidence
        source
      }
      artistSocialInsuranceCode
      discountAmount {
        value
        confidence
        source
      }
      discountPercentage {
        value
        confidence
        source
      }
      discountPaymentDate {
        value
        confidence
        source
      }
      paymentConditionId {
        value
        confidence
        source
      }
      projectCode {
        value {
          id
          code
          name
        }
        confidence
        source
      }
    }
    globalDocumentId
    tags {
      ...Tag
    }
  }
}
    ${TransactionDetailsDataFragmentDoc}
${TagFragmentDoc}`;

/**
 * __useGetDocumentForDraftQuery__
 *
 * To run a query within a React component, call `useGetDocumentForDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentForDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentForDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentForDraftQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentForDraftQuery, GetDocumentForDraftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentForDraftQuery, GetDocumentForDraftQueryVariables>(GetDocumentForDraftDocument, options);
      }
export function useGetDocumentForDraftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentForDraftQuery, GetDocumentForDraftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentForDraftQuery, GetDocumentForDraftQueryVariables>(GetDocumentForDraftDocument, options);
        }
export type GetDocumentForDraftQueryHookResult = ReturnType<typeof useGetDocumentForDraftQuery>;
export type GetDocumentForDraftLazyQueryHookResult = ReturnType<typeof useGetDocumentForDraftLazyQuery>;
export type GetDocumentForDraftQueryResult = Apollo.QueryResult<GetDocumentForDraftQuery, GetDocumentForDraftQueryVariables>;
export const GetDocumentTransactionDocument = gql`
    query getDocumentTransaction($id: ID!) {
  getDocument(id: $id) {
    transactions {
      ...TransactionDetailsData
    }
  }
}
    ${TransactionDetailsDataFragmentDoc}`;

/**
 * __useGetDocumentTransactionQuery__
 *
 * To run a query within a React component, call `useGetDocumentTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentTransactionQuery, GetDocumentTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentTransactionQuery, GetDocumentTransactionQueryVariables>(GetDocumentTransactionDocument, options);
      }
export function useGetDocumentTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTransactionQuery, GetDocumentTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentTransactionQuery, GetDocumentTransactionQueryVariables>(GetDocumentTransactionDocument, options);
        }
export type GetDocumentTransactionQueryHookResult = ReturnType<typeof useGetDocumentTransactionQuery>;
export type GetDocumentTransactionLazyQueryHookResult = ReturnType<typeof useGetDocumentTransactionLazyQuery>;
export type GetDocumentTransactionQueryResult = Apollo.QueryResult<GetDocumentTransactionQuery, GetDocumentTransactionQueryVariables>;
export const GetDocumentGlobalIdDocument = gql`
    query getDocumentGlobalId($id: ID!) {
  getDocument(id: $id) {
    globalDocumentId
  }
}
    `;

/**
 * __useGetDocumentGlobalIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentGlobalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentGlobalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentGlobalIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentGlobalIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentGlobalIdQuery, GetDocumentGlobalIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentGlobalIdQuery, GetDocumentGlobalIdQueryVariables>(GetDocumentGlobalIdDocument, options);
      }
export function useGetDocumentGlobalIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentGlobalIdQuery, GetDocumentGlobalIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentGlobalIdQuery, GetDocumentGlobalIdQueryVariables>(GetDocumentGlobalIdDocument, options);
        }
export type GetDocumentGlobalIdQueryHookResult = ReturnType<typeof useGetDocumentGlobalIdQuery>;
export type GetDocumentGlobalIdLazyQueryHookResult = ReturnType<typeof useGetDocumentGlobalIdLazyQuery>;
export type GetDocumentGlobalIdQueryResult = Apollo.QueryResult<GetDocumentGlobalIdQuery, GetDocumentGlobalIdQueryVariables>;
export const GetDocumentDocument = gql`
    query getDocument($id: ID!) {
  getDocument(id: $id) {
    ...DocumentData
    transactions {
      ...TransactionDetailsData
    }
    purchaseOrderData {
      orderNumber
      purchaseOrderId
      integrationType
    }
    paymentState {
      __typename
      ... on PaidDocumentState {
        paidAt
      }
    }
    currentWorkflowStep {
      id
    }
    rolesThatAreEligibleToApprove
    isOverdue
    eInvoice {
      format
      error
    }
    isReadOnly
    accessModificationPermissions {
      viewAccess
    }
    isEInvoice
    isWaitingForClarification
    workflow {
      id
      steps {
        id
        resolution {
          approvers {
            id
            name
          }
          type
        }
        approvers {
          id
          name
          substitute {
            id
            name
          }
        }
      }
    }
    bookings {
      id
      amount {
        value
      }
      taxAmount
      netAmount
      vatRate {
        value
      }
      note {
        value
      }
      postingText
      dueDate {
        value
      }
      generalLedgerAccount {
        value {
          id
          accountCode
          readableName
        }
      }
      bookingKey {
        value {
          id
          taxCode
          readableName
        }
      }
      costCenter {
        value {
          id
          name
          code
          readableName
        }
      }
      costObject {
        value {
          id
          name
          code
          readableName
        }
      }
      extraCostInfo {
        value {
          id
          name
          code
          readableName
        }
      }
      paymentConditionId {
        value
      }
      artistSocialInsuranceCode
    }
  }
}
    ${DocumentDataFragmentDoc}
${TagFragmentDoc}
${TransactionDetailsDataFragmentDoc}`;

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
      }
export function useGetDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
        }
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>;
export type GetDocumentLazyQueryHookResult = ReturnType<typeof useGetDocumentLazyQuery>;
export type GetDocumentQueryResult = Apollo.QueryResult<GetDocumentQuery, GetDocumentQueryVariables>;
export const GetHasLinkedTransactionDocument = gql`
    query getHasLinkedTransaction($id: ID!) {
  getDocument(id: $id) {
    hasTransactionLinked
  }
}
    `;

/**
 * __useGetHasLinkedTransactionQuery__
 *
 * To run a query within a React component, call `useGetHasLinkedTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHasLinkedTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHasLinkedTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHasLinkedTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetHasLinkedTransactionQuery, GetHasLinkedTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHasLinkedTransactionQuery, GetHasLinkedTransactionQueryVariables>(GetHasLinkedTransactionDocument, options);
      }
export function useGetHasLinkedTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHasLinkedTransactionQuery, GetHasLinkedTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHasLinkedTransactionQuery, GetHasLinkedTransactionQueryVariables>(GetHasLinkedTransactionDocument, options);
        }
export type GetHasLinkedTransactionQueryHookResult = ReturnType<typeof useGetHasLinkedTransactionQuery>;
export type GetHasLinkedTransactionLazyQueryHookResult = ReturnType<typeof useGetHasLinkedTransactionLazyQuery>;
export type GetHasLinkedTransactionQueryResult = Apollo.QueryResult<GetHasLinkedTransactionQuery, GetHasLinkedTransactionQueryVariables>;
export const GetWorkflowTemplatesDocument = gql`
    query getWorkflowTemplates($name: String, $isArchived: Boolean = false) {
  workflowTemplates(input: {name: $name, isArchived: $isArchived}) {
    id
    name
    description
  }
}
    `;

/**
 * __useGetWorkflowTemplatesQuery__
 *
 * To run a query within a React component, call `useGetWorkflowTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowTemplatesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useGetWorkflowTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkflowTemplatesQuery, GetWorkflowTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkflowTemplatesQuery, GetWorkflowTemplatesQueryVariables>(GetWorkflowTemplatesDocument, options);
      }
export function useGetWorkflowTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkflowTemplatesQuery, GetWorkflowTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkflowTemplatesQuery, GetWorkflowTemplatesQueryVariables>(GetWorkflowTemplatesDocument, options);
        }
export type GetWorkflowTemplatesQueryHookResult = ReturnType<typeof useGetWorkflowTemplatesQuery>;
export type GetWorkflowTemplatesLazyQueryHookResult = ReturnType<typeof useGetWorkflowTemplatesLazyQuery>;
export type GetWorkflowTemplatesQueryResult = Apollo.QueryResult<GetWorkflowTemplatesQuery, GetWorkflowTemplatesQueryVariables>;
export const GetMembersDocument = gql`
    query getMembers {
  organizationMemberships @connection(key: "membersForAbsence") {
    id
    name
    roles
    avatarUrl
  }
}
    `;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const DeleteByIdDocument = gql`
    mutation deleteById($id: ID!) {
  deleteById(id: $id) {
    count
  }
}
    `;
export type DeleteByIdMutationFn = Apollo.MutationFunction<DeleteByIdMutation, DeleteByIdMutationVariables>;

/**
 * __useDeleteByIdMutation__
 *
 * To run a mutation, you first call `useDeleteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteByIdMutation, { data, loading, error }] = useDeleteByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteByIdMutation, DeleteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteByIdMutation, DeleteByIdMutationVariables>(DeleteByIdDocument, options);
      }
export type DeleteByIdMutationHookResult = ReturnType<typeof useDeleteByIdMutation>;
export type DeleteByIdMutationResult = Apollo.MutationResult<DeleteByIdMutation>;
export type DeleteByIdMutationOptions = Apollo.BaseMutationOptions<DeleteByIdMutation, DeleteByIdMutationVariables>;
export const SmartFieldSuggestionsForRequesterDocument = gql`
    query smartFieldSuggestionsForRequester($documentId: String!, $contactId: String!) {
  sfsSuggestionForRequester(documentId: $documentId, contactId: $contactId) {
    costCenter1 {
      confidence
      value {
        id
        name
        code
        readableName
        approverIds
      }
    }
    costCenter2 {
      confidence
      value {
        id
        name
        code
        readableName
      }
    }
    generalLedgerAccount {
      confidence
      value {
        id
        name
        code: accountCode
        readableName
      }
    }
    bookingKey {
      confidence
      value {
        id
        readableName
      }
    }
    workflowTemplate {
      confidence
      value {
        id
        name
      }
    }
    approverUserIds {
      confidence
      value
    }
  }
}
    `;

/**
 * __useSmartFieldSuggestionsForRequesterQuery__
 *
 * To run a query within a React component, call `useSmartFieldSuggestionsForRequesterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartFieldSuggestionsForRequesterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartFieldSuggestionsForRequesterQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useSmartFieldSuggestionsForRequesterQuery(baseOptions: Apollo.QueryHookOptions<SmartFieldSuggestionsForRequesterQuery, SmartFieldSuggestionsForRequesterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmartFieldSuggestionsForRequesterQuery, SmartFieldSuggestionsForRequesterQueryVariables>(SmartFieldSuggestionsForRequesterDocument, options);
      }
export function useSmartFieldSuggestionsForRequesterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartFieldSuggestionsForRequesterQuery, SmartFieldSuggestionsForRequesterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmartFieldSuggestionsForRequesterQuery, SmartFieldSuggestionsForRequesterQueryVariables>(SmartFieldSuggestionsForRequesterDocument, options);
        }
export type SmartFieldSuggestionsForRequesterQueryHookResult = ReturnType<typeof useSmartFieldSuggestionsForRequesterQuery>;
export type SmartFieldSuggestionsForRequesterLazyQueryHookResult = ReturnType<typeof useSmartFieldSuggestionsForRequesterLazyQuery>;
export type SmartFieldSuggestionsForRequesterQueryResult = Apollo.QueryResult<SmartFieldSuggestionsForRequesterQuery, SmartFieldSuggestionsForRequesterQueryVariables>;
export const UnassociatedCardIssuerTransactionsDocument = gql`
    query unassociatedCardIssuerTransactions($input: CardIssuerTransactionPaginationInput!, $filters: InboxCardIssuerTransactionFiltersInput, $sortBy: CardIssuerTransactionSortInput) {
  unassociatedCardIssuerTransactions(
    input: $input
    filters: $filters
    sortBy: $sortBy
  ) {
    edges {
      cursor
      node {
        ...transactionListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${TransactionListDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useUnassociatedCardIssuerTransactionsQuery__
 *
 * To run a query within a React component, call `useUnassociatedCardIssuerTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassociatedCardIssuerTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassociatedCardIssuerTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useUnassociatedCardIssuerTransactionsQuery(baseOptions: Apollo.QueryHookOptions<UnassociatedCardIssuerTransactionsQuery, UnassociatedCardIssuerTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnassociatedCardIssuerTransactionsQuery, UnassociatedCardIssuerTransactionsQueryVariables>(UnassociatedCardIssuerTransactionsDocument, options);
      }
export function useUnassociatedCardIssuerTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnassociatedCardIssuerTransactionsQuery, UnassociatedCardIssuerTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnassociatedCardIssuerTransactionsQuery, UnassociatedCardIssuerTransactionsQueryVariables>(UnassociatedCardIssuerTransactionsDocument, options);
        }
export type UnassociatedCardIssuerTransactionsQueryHookResult = ReturnType<typeof useUnassociatedCardIssuerTransactionsQuery>;
export type UnassociatedCardIssuerTransactionsLazyQueryHookResult = ReturnType<typeof useUnassociatedCardIssuerTransactionsLazyQuery>;
export type UnassociatedCardIssuerTransactionsQueryResult = Apollo.QueryResult<UnassociatedCardIssuerTransactionsQuery, UnassociatedCardIssuerTransactionsQueryVariables>;
export const LinkDocumentAndTransactionsDocument = gql`
    mutation linkDocumentAndTransactions($documentId: String!, $transactionIds: [String!]!) {
  linkDocumentAndTransactions(
    documentId: $documentId
    transactionIds: $transactionIds
  ) {
    id
  }
}
    `;
export type LinkDocumentAndTransactionsMutationFn = Apollo.MutationFunction<LinkDocumentAndTransactionsMutation, LinkDocumentAndTransactionsMutationVariables>;

/**
 * __useLinkDocumentAndTransactionsMutation__
 *
 * To run a mutation, you first call `useLinkDocumentAndTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkDocumentAndTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkDocumentAndTransactionsMutation, { data, loading, error }] = useLinkDocumentAndTransactionsMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      transactionIds: // value for 'transactionIds'
 *   },
 * });
 */
export function useLinkDocumentAndTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<LinkDocumentAndTransactionsMutation, LinkDocumentAndTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkDocumentAndTransactionsMutation, LinkDocumentAndTransactionsMutationVariables>(LinkDocumentAndTransactionsDocument, options);
      }
export type LinkDocumentAndTransactionsMutationHookResult = ReturnType<typeof useLinkDocumentAndTransactionsMutation>;
export type LinkDocumentAndTransactionsMutationResult = Apollo.MutationResult<LinkDocumentAndTransactionsMutation>;
export type LinkDocumentAndTransactionsMutationOptions = Apollo.BaseMutationOptions<LinkDocumentAndTransactionsMutation, LinkDocumentAndTransactionsMutationVariables>;
export const ProcessingFormFieldItemsDocument = gql`
    query processingFormFieldItems {
  types: availableDocumentCategories {
    documentType
    direction
    isDefault
    isRds1_0Exportable
  }
  paymentConditions(filter: {isArchived: false}) {
    id
    paymentConditionNumber
    paymentConditionName
    dueDateOffset
    discountOffset
    discountPercentage
  }
  taxCodes: bookingKeysActive {
    id
    taxCode
    readableName
    isAcquisitionReverse
  }
  accountingAreas: accountingAreas(filter: {isArchived: false}) {
    id
    name
    description
  }
  workflows: workflowTemplates(input: {isArchived: false}) {
    id
    name
    steps {
      resolvers {
        id
      }
    }
  }
  approvers {
    id
    name
    avatarUrl
  }
  getOrgMembersAbsence {
    fromDate
    membershipId
    note
    status
    toDate
    substituteMembershipId
  }
}
    `;

/**
 * __useProcessingFormFieldItemsQuery__
 *
 * To run a query within a React component, call `useProcessingFormFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormFieldItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcessingFormFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingFormFieldItemsQuery, ProcessingFormFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormFieldItemsQuery, ProcessingFormFieldItemsQueryVariables>(ProcessingFormFieldItemsDocument, options);
      }
export function useProcessingFormFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormFieldItemsQuery, ProcessingFormFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormFieldItemsQuery, ProcessingFormFieldItemsQueryVariables>(ProcessingFormFieldItemsDocument, options);
        }
export type ProcessingFormFieldItemsQueryHookResult = ReturnType<typeof useProcessingFormFieldItemsQuery>;
export type ProcessingFormFieldItemsLazyQueryHookResult = ReturnType<typeof useProcessingFormFieldItemsLazyQuery>;
export type ProcessingFormFieldItemsQueryResult = Apollo.QueryResult<ProcessingFormFieldItemsQuery, ProcessingFormFieldItemsQueryVariables>;
export const ProcessingFormContactFieldItemsDocument = gql`
    query processingFormContactFieldItems($name: String, $page: Int, $filters: ContactsFilterInput) {
  contactsPagination(
    input: {page: $page, limit: 20}
    queries: {name: $name}
    sortBy: {field: NAME, direction: ASC}
    filters: $filters
  ) @connection(key: "processingFormContactFieldItems", filter: ["filters", "queries"]) {
    records {
      ...ProcessingFormContactData
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
    ${ProcessingFormContactDataFragmentDoc}`;

/**
 * __useProcessingFormContactFieldItemsQuery__
 *
 * To run a query within a React component, call `useProcessingFormContactFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormContactFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormContactFieldItemsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProcessingFormContactFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingFormContactFieldItemsQuery, ProcessingFormContactFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormContactFieldItemsQuery, ProcessingFormContactFieldItemsQueryVariables>(ProcessingFormContactFieldItemsDocument, options);
      }
export function useProcessingFormContactFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormContactFieldItemsQuery, ProcessingFormContactFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormContactFieldItemsQuery, ProcessingFormContactFieldItemsQueryVariables>(ProcessingFormContactFieldItemsDocument, options);
        }
export type ProcessingFormContactFieldItemsQueryHookResult = ReturnType<typeof useProcessingFormContactFieldItemsQuery>;
export type ProcessingFormContactFieldItemsLazyQueryHookResult = ReturnType<typeof useProcessingFormContactFieldItemsLazyQuery>;
export type ProcessingFormContactFieldItemsQueryResult = Apollo.QueryResult<ProcessingFormContactFieldItemsQuery, ProcessingFormContactFieldItemsQueryVariables>;
export const ProcessingFormGeneralLedgerAccountFieldItemsDocument = gql`
    query processingFormGeneralLedgerAccountFieldItems($readableName: String, $page: Int) {
  generalLedgerAccountsPagination: bookingAccountPagination(
    input: {page: $page, limit: 20}
    filters: {isArchived: false}
    queries: {readableName: $readableName}
    sortBy: {field: ACCOUNT_NUMBER, direction: ASC}
  ) @connection(key: "processingFormGeneralLedgerAccountFieldItems", filter: ["filters", "queries"]) {
    records {
      id
      readableName
      accountCode
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useProcessingFormGeneralLedgerAccountFieldItemsQuery__
 *
 * To run a query within a React component, call `useProcessingFormGeneralLedgerAccountFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormGeneralLedgerAccountFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormGeneralLedgerAccountFieldItemsQuery({
 *   variables: {
 *      readableName: // value for 'readableName'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProcessingFormGeneralLedgerAccountFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingFormGeneralLedgerAccountFieldItemsQuery, ProcessingFormGeneralLedgerAccountFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormGeneralLedgerAccountFieldItemsQuery, ProcessingFormGeneralLedgerAccountFieldItemsQueryVariables>(ProcessingFormGeneralLedgerAccountFieldItemsDocument, options);
      }
export function useProcessingFormGeneralLedgerAccountFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormGeneralLedgerAccountFieldItemsQuery, ProcessingFormGeneralLedgerAccountFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormGeneralLedgerAccountFieldItemsQuery, ProcessingFormGeneralLedgerAccountFieldItemsQueryVariables>(ProcessingFormGeneralLedgerAccountFieldItemsDocument, options);
        }
export type ProcessingFormGeneralLedgerAccountFieldItemsQueryHookResult = ReturnType<typeof useProcessingFormGeneralLedgerAccountFieldItemsQuery>;
export type ProcessingFormGeneralLedgerAccountFieldItemsLazyQueryHookResult = ReturnType<typeof useProcessingFormGeneralLedgerAccountFieldItemsLazyQuery>;
export type ProcessingFormGeneralLedgerAccountFieldItemsQueryResult = Apollo.QueryResult<ProcessingFormGeneralLedgerAccountFieldItemsQuery, ProcessingFormGeneralLedgerAccountFieldItemsQueryVariables>;
export const ProcessingFormGeneralLedgerAccountFieldItemsByIdsDocument = gql`
    query processingFormGeneralLedgerAccountFieldItemsByIds($ids: [String!], $page: Int) {
  generalLedgerAccountsPagination: bookingAccountPagination(
    input: {page: $page, limit: 20}
    filters: {id: $ids}
  ) @connection(key: "processingFormGeneralLedgerAccountFieldItemsByIds", filter: ["filters", "queries"]) {
    records {
      id
      readableName
      accountCode
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery__
 *
 * To run a query within a React component, call `useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery, ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery, ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryVariables>(ProcessingFormGeneralLedgerAccountFieldItemsByIdsDocument, options);
      }
export function useProcessingFormGeneralLedgerAccountFieldItemsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery, ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery, ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryVariables>(ProcessingFormGeneralLedgerAccountFieldItemsByIdsDocument, options);
        }
export type ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryHookResult = ReturnType<typeof useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery>;
export type ProcessingFormGeneralLedgerAccountFieldItemsByIdsLazyQueryHookResult = ReturnType<typeof useProcessingFormGeneralLedgerAccountFieldItemsByIdsLazyQuery>;
export type ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryResult = Apollo.QueryResult<ProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery, ProcessingFormGeneralLedgerAccountFieldItemsByIdsQueryVariables>;
export const ProcessingFormCostCenterFieldItemsDocument = gql`
    query processingFormCostCenterFieldItems($type: CostCenterTypes!, $readableName: String, $page: Int) {
  costCentersPagination: costCenterPagination(
    input: {page: $page, limit: 20}
    filters: {isArchived: false, type: [$type]}
    queries: {readableName: $readableName}
    sortBy: {field: CODE, direction: ASC}
  ) @connection(key: "processingFormCostCenterFieldItems", filter: ["filters", "queries"]) {
    records {
      id
      readableName
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useProcessingFormCostCenterFieldItemsQuery__
 *
 * To run a query within a React component, call `useProcessingFormCostCenterFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormCostCenterFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormCostCenterFieldItemsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      readableName: // value for 'readableName'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProcessingFormCostCenterFieldItemsQuery(baseOptions: Apollo.QueryHookOptions<ProcessingFormCostCenterFieldItemsQuery, ProcessingFormCostCenterFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormCostCenterFieldItemsQuery, ProcessingFormCostCenterFieldItemsQueryVariables>(ProcessingFormCostCenterFieldItemsDocument, options);
      }
export function useProcessingFormCostCenterFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormCostCenterFieldItemsQuery, ProcessingFormCostCenterFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormCostCenterFieldItemsQuery, ProcessingFormCostCenterFieldItemsQueryVariables>(ProcessingFormCostCenterFieldItemsDocument, options);
        }
export type ProcessingFormCostCenterFieldItemsQueryHookResult = ReturnType<typeof useProcessingFormCostCenterFieldItemsQuery>;
export type ProcessingFormCostCenterFieldItemsLazyQueryHookResult = ReturnType<typeof useProcessingFormCostCenterFieldItemsLazyQuery>;
export type ProcessingFormCostCenterFieldItemsQueryResult = Apollo.QueryResult<ProcessingFormCostCenterFieldItemsQuery, ProcessingFormCostCenterFieldItemsQueryVariables>;
export const ProcessingFormCostCenterFieldItemsByIdsDocument = gql`
    query processingFormCostCenterFieldItemsByIds($ids: [String!], $page: Int) {
  costCentersPagination: costCenterPagination(
    input: {page: $page, limit: 20}
    filters: {id: $ids}
  ) @connection(key: "processingFormCostCenterFieldItemsByIds", filter: ["filters", "queries"]) {
    records {
      id
      readableName
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useProcessingFormCostCenterFieldItemsByIdsQuery__
 *
 * To run a query within a React component, call `useProcessingFormCostCenterFieldItemsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormCostCenterFieldItemsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormCostCenterFieldItemsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProcessingFormCostCenterFieldItemsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingFormCostCenterFieldItemsByIdsQuery, ProcessingFormCostCenterFieldItemsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormCostCenterFieldItemsByIdsQuery, ProcessingFormCostCenterFieldItemsByIdsQueryVariables>(ProcessingFormCostCenterFieldItemsByIdsDocument, options);
      }
export function useProcessingFormCostCenterFieldItemsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormCostCenterFieldItemsByIdsQuery, ProcessingFormCostCenterFieldItemsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormCostCenterFieldItemsByIdsQuery, ProcessingFormCostCenterFieldItemsByIdsQueryVariables>(ProcessingFormCostCenterFieldItemsByIdsDocument, options);
        }
export type ProcessingFormCostCenterFieldItemsByIdsQueryHookResult = ReturnType<typeof useProcessingFormCostCenterFieldItemsByIdsQuery>;
export type ProcessingFormCostCenterFieldItemsByIdsLazyQueryHookResult = ReturnType<typeof useProcessingFormCostCenterFieldItemsByIdsLazyQuery>;
export type ProcessingFormCostCenterFieldItemsByIdsQueryResult = Apollo.QueryResult<ProcessingFormCostCenterFieldItemsByIdsQuery, ProcessingFormCostCenterFieldItemsByIdsQueryVariables>;
export const ProcessingFormActionsDocument = gql`
    query processingFormActions($documentId: ID!) {
  document: getDocument(id: $documentId) {
    isDuplicate
    originalDocumentId {
      value
    }
    status
    transactions {
      id
    }
  }
}
    `;

/**
 * __useProcessingFormActionsQuery__
 *
 * To run a query within a React component, call `useProcessingFormActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormActionsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useProcessingFormActionsQuery(baseOptions: Apollo.QueryHookOptions<ProcessingFormActionsQuery, ProcessingFormActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormActionsQuery, ProcessingFormActionsQueryVariables>(ProcessingFormActionsDocument, options);
      }
export function useProcessingFormActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormActionsQuery, ProcessingFormActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormActionsQuery, ProcessingFormActionsQueryVariables>(ProcessingFormActionsDocument, options);
        }
export type ProcessingFormActionsQueryHookResult = ReturnType<typeof useProcessingFormActionsQuery>;
export type ProcessingFormActionsLazyQueryHookResult = ReturnType<typeof useProcessingFormActionsLazyQuery>;
export type ProcessingFormActionsQueryResult = Apollo.QueryResult<ProcessingFormActionsQuery, ProcessingFormActionsQueryVariables>;
export const UpdateWaitingForClarificationDocument = gql`
    mutation updateWaitingForClarification($documentId: ID!, $isWaiting: Boolean!) {
  updateWaitingForClarification(id: $documentId, isWaiting: $isWaiting)
}
    `;
export type UpdateWaitingForClarificationMutationFn = Apollo.MutationFunction<UpdateWaitingForClarificationMutation, UpdateWaitingForClarificationMutationVariables>;

/**
 * __useUpdateWaitingForClarificationMutation__
 *
 * To run a mutation, you first call `useUpdateWaitingForClarificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWaitingForClarificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWaitingForClarificationMutation, { data, loading, error }] = useUpdateWaitingForClarificationMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      isWaiting: // value for 'isWaiting'
 *   },
 * });
 */
export function useUpdateWaitingForClarificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWaitingForClarificationMutation, UpdateWaitingForClarificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWaitingForClarificationMutation, UpdateWaitingForClarificationMutationVariables>(UpdateWaitingForClarificationDocument, options);
      }
export type UpdateWaitingForClarificationMutationHookResult = ReturnType<typeof useUpdateWaitingForClarificationMutation>;
export type UpdateWaitingForClarificationMutationResult = Apollo.MutationResult<UpdateWaitingForClarificationMutation>;
export type UpdateWaitingForClarificationMutationOptions = Apollo.BaseMutationOptions<UpdateWaitingForClarificationMutation, UpdateWaitingForClarificationMutationVariables>;
export const MobilePromotionDataDocument = gql`
    query mobilePromotionData($id: ID!) {
  getDocument(id: $id) {
    id
    status
    sourceClassification {
      value
    }
    hasTransactionLinked
    timeline {
      __typename
      id
      createdAt
      ...FileUploadedEventData
    }
  }
}
    ${FileUploadedEventDataFragmentDoc}`;

/**
 * __useMobilePromotionDataQuery__
 *
 * To run a query within a React component, call `useMobilePromotionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobilePromotionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobilePromotionDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMobilePromotionDataQuery(baseOptions: Apollo.QueryHookOptions<MobilePromotionDataQuery, MobilePromotionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MobilePromotionDataQuery, MobilePromotionDataQueryVariables>(MobilePromotionDataDocument, options);
      }
export function useMobilePromotionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobilePromotionDataQuery, MobilePromotionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MobilePromotionDataQuery, MobilePromotionDataQueryVariables>(MobilePromotionDataDocument, options);
        }
export type MobilePromotionDataQueryHookResult = ReturnType<typeof useMobilePromotionDataQuery>;
export type MobilePromotionDataLazyQueryHookResult = ReturnType<typeof useMobilePromotionDataLazyQuery>;
export type MobilePromotionDataQueryResult = Apollo.QueryResult<MobilePromotionDataQuery, MobilePromotionDataQueryVariables>;
export const GetDocumentBookingAssociationsByInvoiceIdDocument = gql`
    query getDocumentBookingAssociationsByInvoiceId($invoiceId: String!) {
  getDocumentBookingAssociationsByInvoiceId(invoiceId: $invoiceId) {
    ...DocumentBookingAssociationData
  }
}
    ${DocumentBookingAssociationDataFragmentDoc}`;

/**
 * __useGetDocumentBookingAssociationsByInvoiceIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentBookingAssociationsByInvoiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentBookingAssociationsByInvoiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentBookingAssociationsByInvoiceIdQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetDocumentBookingAssociationsByInvoiceIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentBookingAssociationsByInvoiceIdQuery, GetDocumentBookingAssociationsByInvoiceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentBookingAssociationsByInvoiceIdQuery, GetDocumentBookingAssociationsByInvoiceIdQueryVariables>(GetDocumentBookingAssociationsByInvoiceIdDocument, options);
      }
export function useGetDocumentBookingAssociationsByInvoiceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentBookingAssociationsByInvoiceIdQuery, GetDocumentBookingAssociationsByInvoiceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentBookingAssociationsByInvoiceIdQuery, GetDocumentBookingAssociationsByInvoiceIdQueryVariables>(GetDocumentBookingAssociationsByInvoiceIdDocument, options);
        }
export type GetDocumentBookingAssociationsByInvoiceIdQueryHookResult = ReturnType<typeof useGetDocumentBookingAssociationsByInvoiceIdQuery>;
export type GetDocumentBookingAssociationsByInvoiceIdLazyQueryHookResult = ReturnType<typeof useGetDocumentBookingAssociationsByInvoiceIdLazyQuery>;
export type GetDocumentBookingAssociationsByInvoiceIdQueryResult = Apollo.QueryResult<GetDocumentBookingAssociationsByInvoiceIdQuery, GetDocumentBookingAssociationsByInvoiceIdQueryVariables>;
export const GetSapPurchaseOrdersDocument = gql`
    query getSapPurchaseOrders($id: ID!) {
  getDocument(id: $id) {
    id
    purchaseOrders {
      ...purchaseOrderListData
      goodsReceipts {
        _id
        receiptNumber
        deliveryDate
        status
        goodsLineItems {
          description
          quantity
          itemNumber
          lineNumber
        }
      }
    }
  }
}
    ${PurchaseOrderListDataFragmentDoc}
${PurchaseOrderBaseFragmentDoc}
${GoodsLineItemsFragmentDoc}
${ConnectedInvoicesFragmentDoc}
${SapB1DataFragmentDoc}`;

/**
 * __useGetSapPurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useGetSapPurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSapPurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSapPurchaseOrdersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSapPurchaseOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetSapPurchaseOrdersQuery, GetSapPurchaseOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSapPurchaseOrdersQuery, GetSapPurchaseOrdersQueryVariables>(GetSapPurchaseOrdersDocument, options);
      }
export function useGetSapPurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSapPurchaseOrdersQuery, GetSapPurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSapPurchaseOrdersQuery, GetSapPurchaseOrdersQueryVariables>(GetSapPurchaseOrdersDocument, options);
        }
export type GetSapPurchaseOrdersQueryHookResult = ReturnType<typeof useGetSapPurchaseOrdersQuery>;
export type GetSapPurchaseOrdersLazyQueryHookResult = ReturnType<typeof useGetSapPurchaseOrdersLazyQuery>;
export type GetSapPurchaseOrdersQueryResult = Apollo.QueryResult<GetSapPurchaseOrdersQuery, GetSapPurchaseOrdersQueryVariables>;
export const ProcessingFormProjectCodeFieldItemsDocument = gql`
    query processingFormProjectCodeFieldItems($readableName: String, $page: Int) {
  projectCodesPagination: projectCodePagination(
    input: {page: $page, limit: 20}
    filters: {isArchived: false}
    queries: {readableName: $readableName}
    sortBy: {field: CREATED_AT, direction: DESC}
  ) @connection(key: "processingFormProjectCodeFieldItems", filter: ["filters", "queries"]) {
    records {
      id
      name
      code
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useProcessingFormProjectCodeFieldItemsQuery__
 *
 * To run a query within a React component, call `useProcessingFormProjectCodeFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormProjectCodeFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormProjectCodeFieldItemsQuery({
 *   variables: {
 *      readableName: // value for 'readableName'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProcessingFormProjectCodeFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingFormProjectCodeFieldItemsQuery, ProcessingFormProjectCodeFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormProjectCodeFieldItemsQuery, ProcessingFormProjectCodeFieldItemsQueryVariables>(ProcessingFormProjectCodeFieldItemsDocument, options);
      }
export function useProcessingFormProjectCodeFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormProjectCodeFieldItemsQuery, ProcessingFormProjectCodeFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormProjectCodeFieldItemsQuery, ProcessingFormProjectCodeFieldItemsQueryVariables>(ProcessingFormProjectCodeFieldItemsDocument, options);
        }
export type ProcessingFormProjectCodeFieldItemsQueryHookResult = ReturnType<typeof useProcessingFormProjectCodeFieldItemsQuery>;
export type ProcessingFormProjectCodeFieldItemsLazyQueryHookResult = ReturnType<typeof useProcessingFormProjectCodeFieldItemsLazyQuery>;
export type ProcessingFormProjectCodeFieldItemsQueryResult = Apollo.QueryResult<ProcessingFormProjectCodeFieldItemsQuery, ProcessingFormProjectCodeFieldItemsQueryVariables>;
export const ProcessingFormProjectCodeFieldItemsByIdsDocument = gql`
    query processingFormProjectCodeFieldItemsByIds($ids: [String!], $page: Int) {
  projectCodesPagination: projectCodePagination(
    input: {page: $page, limit: 20}
    filters: {id: $ids}
  ) @connection(key: "processingFormProjectCodeFieldItemsByIds", filter: ["filters", "queries"]) {
    records {
      id
      code
      name
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useProcessingFormProjectCodeFieldItemsByIdsQuery__
 *
 * To run a query within a React component, call `useProcessingFormProjectCodeFieldItemsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingFormProjectCodeFieldItemsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingFormProjectCodeFieldItemsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProcessingFormProjectCodeFieldItemsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessingFormProjectCodeFieldItemsByIdsQuery, ProcessingFormProjectCodeFieldItemsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessingFormProjectCodeFieldItemsByIdsQuery, ProcessingFormProjectCodeFieldItemsByIdsQueryVariables>(ProcessingFormProjectCodeFieldItemsByIdsDocument, options);
      }
export function useProcessingFormProjectCodeFieldItemsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessingFormProjectCodeFieldItemsByIdsQuery, ProcessingFormProjectCodeFieldItemsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessingFormProjectCodeFieldItemsByIdsQuery, ProcessingFormProjectCodeFieldItemsByIdsQueryVariables>(ProcessingFormProjectCodeFieldItemsByIdsDocument, options);
        }
export type ProcessingFormProjectCodeFieldItemsByIdsQueryHookResult = ReturnType<typeof useProcessingFormProjectCodeFieldItemsByIdsQuery>;
export type ProcessingFormProjectCodeFieldItemsByIdsLazyQueryHookResult = ReturnType<typeof useProcessingFormProjectCodeFieldItemsByIdsLazyQuery>;
export type ProcessingFormProjectCodeFieldItemsByIdsQueryResult = Apollo.QueryResult<ProcessingFormProjectCodeFieldItemsByIdsQuery, ProcessingFormProjectCodeFieldItemsByIdsQueryVariables>;
export const GetSapDocumentsDocument = gql`
    query getSapDocuments($id: ID!) {
  getDocument(id: $id) {
    id
    associatedDocuments {
      ... on PurchaseOrder {
        ...AssociatedPurchaseOrderData
      }
      ... on GoodsReceipt {
        ...AssociatedGoodsReceiptData
      }
    }
  }
}
    ${AssociatedPurchaseOrderDataFragmentDoc}
${PurchaseOrderBaseFragmentDoc}
${GoodsLineItemsFragmentDoc}
${ConnectedInvoicesFragmentDoc}
${SapB1DataFragmentDoc}
${GoodsReceiptLineItemsFragmentDoc}
${AssociatedGoodsReceiptDataFragmentDoc}`;

/**
 * __useGetSapDocumentsQuery__
 *
 * To run a query within a React component, call `useGetSapDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSapDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSapDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSapDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetSapDocumentsQuery, GetSapDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSapDocumentsQuery, GetSapDocumentsQueryVariables>(GetSapDocumentsDocument, options);
      }
export function useGetSapDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSapDocumentsQuery, GetSapDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSapDocumentsQuery, GetSapDocumentsQueryVariables>(GetSapDocumentsDocument, options);
        }
export type GetSapDocumentsQueryHookResult = ReturnType<typeof useGetSapDocumentsQuery>;
export type GetSapDocumentsLazyQueryHookResult = ReturnType<typeof useGetSapDocumentsLazyQuery>;
export type GetSapDocumentsQueryResult = Apollo.QueryResult<GetSapDocumentsQuery, GetSapDocumentsQueryVariables>;
export const GetEcmSensitiveDocumentsDocument = gql`
    query getEcmSensitiveDocuments($next: String, $limit: Int, $search: String, $sort: EcmSortInput, $filter: EcmFilterInput, $searchInput: SearchInput) {
  getEcmDocuments(
    next: $next
    limit: $limit
    search: $search
    sort: $sort
    filter: $filter
    searchInput: $searchInput
  ) {
    edges {
      cursor
      record {
        contactId
        contactName
        createdAt
        documentName
        documentNumber
        endDate
        id
        globalDocumentId
        invoiceId
        tags {
          ...Tag
        }
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      nextPageCursor
      totalCount
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useGetEcmSensitiveDocumentsQuery__
 *
 * To run a query within a React component, call `useGetEcmSensitiveDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcmSensitiveDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcmSensitiveDocumentsQuery({
 *   variables: {
 *      next: // value for 'next'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetEcmSensitiveDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetEcmSensitiveDocumentsQuery, GetEcmSensitiveDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcmSensitiveDocumentsQuery, GetEcmSensitiveDocumentsQueryVariables>(GetEcmSensitiveDocumentsDocument, options);
      }
export function useGetEcmSensitiveDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcmSensitiveDocumentsQuery, GetEcmSensitiveDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcmSensitiveDocumentsQuery, GetEcmSensitiveDocumentsQueryVariables>(GetEcmSensitiveDocumentsDocument, options);
        }
export type GetEcmSensitiveDocumentsQueryHookResult = ReturnType<typeof useGetEcmSensitiveDocumentsQuery>;
export type GetEcmSensitiveDocumentsLazyQueryHookResult = ReturnType<typeof useGetEcmSensitiveDocumentsLazyQuery>;
export type GetEcmSensitiveDocumentsQueryResult = Apollo.QueryResult<GetEcmSensitiveDocumentsQuery, GetEcmSensitiveDocumentsQueryVariables>;
export const GetEcmSensitiveDocumentsCountDocument = gql`
    query getEcmSensitiveDocumentsCount($filter: EcmFilterInput) {
  getEcmDocuments(filter: $filter) {
    edges {
      cursor
    }
    pageInfo {
      totalCount
    }
  }
}
    `;

/**
 * __useGetEcmSensitiveDocumentsCountQuery__
 *
 * To run a query within a React component, call `useGetEcmSensitiveDocumentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcmSensitiveDocumentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcmSensitiveDocumentsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEcmSensitiveDocumentsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetEcmSensitiveDocumentsCountQuery, GetEcmSensitiveDocumentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcmSensitiveDocumentsCountQuery, GetEcmSensitiveDocumentsCountQueryVariables>(GetEcmSensitiveDocumentsCountDocument, options);
      }
export function useGetEcmSensitiveDocumentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcmSensitiveDocumentsCountQuery, GetEcmSensitiveDocumentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcmSensitiveDocumentsCountQuery, GetEcmSensitiveDocumentsCountQueryVariables>(GetEcmSensitiveDocumentsCountDocument, options);
        }
export type GetEcmSensitiveDocumentsCountQueryHookResult = ReturnType<typeof useGetEcmSensitiveDocumentsCountQuery>;
export type GetEcmSensitiveDocumentsCountLazyQueryHookResult = ReturnType<typeof useGetEcmSensitiveDocumentsCountLazyQuery>;
export type GetEcmSensitiveDocumentsCountQueryResult = Apollo.QueryResult<GetEcmSensitiveDocumentsCountQuery, GetEcmSensitiveDocumentsCountQueryVariables>;
export const GetInboxOtherDocumentsDocument = gql`
    query getInboxOtherDocuments($next: String, $limit: Int, $search: String, $sort: EcmSortInput, $filter: EcmFilterInput, $searchInput: SearchInput) {
  getEcmDocuments(
    next: $next
    limit: $limit
    search: $search
    sort: $sort
    filter: $filter
    searchInput: $searchInput
  ) {
    edges {
      cursor
      record {
        id
        globalDocumentId
        contactId
        contactName
        documentDate
        documentName
        documentNumber
        documentStatus
        documentType
        invoiceId
        isSensitive
        notes
        responsiblePerson {
          primary {
            id
            name
            avatarUrl
          }
        }
        tags {
          ...Tag
        }
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      nextPageCursor
      totalCount
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useGetInboxOtherDocumentsQuery__
 *
 * To run a query within a React component, call `useGetInboxOtherDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxOtherDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxOtherDocumentsQuery({
 *   variables: {
 *      next: // value for 'next'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetInboxOtherDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetInboxOtherDocumentsQuery, GetInboxOtherDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInboxOtherDocumentsQuery, GetInboxOtherDocumentsQueryVariables>(GetInboxOtherDocumentsDocument, options);
      }
export function useGetInboxOtherDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInboxOtherDocumentsQuery, GetInboxOtherDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInboxOtherDocumentsQuery, GetInboxOtherDocumentsQueryVariables>(GetInboxOtherDocumentsDocument, options);
        }
export type GetInboxOtherDocumentsQueryHookResult = ReturnType<typeof useGetInboxOtherDocumentsQuery>;
export type GetInboxOtherDocumentsLazyQueryHookResult = ReturnType<typeof useGetInboxOtherDocumentsLazyQuery>;
export type GetInboxOtherDocumentsQueryResult = Apollo.QueryResult<GetInboxOtherDocumentsQuery, GetInboxOtherDocumentsQueryVariables>;
export const InboxReimbursementCasesDocument = gql`
    query inboxReimbursementCases($search: String, $sorts: ReimbursementCasesSortInput, $filters: ReimbursementCasesFilterInput, $limit: Int, $next: String, $searchInput: SearchInput) {
  inboxReimbursementCases(
    search: $search
    sorts: $sorts
    filters: $filters
    limit: $limit
    next: $next
    searchInput: $searchInput
  ) {
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
    edges {
      cursor
      node {
        id
        createdAt
        firstSubmittedForReviewAt
        title
        status
        targetMembership {
          id
          firstName
          lastName
          avatarUrl
        }
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
  }
}
    `;

/**
 * __useInboxReimbursementCasesQuery__
 *
 * To run a query within a React component, call `useInboxReimbursementCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxReimbursementCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxReimbursementCasesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sorts: // value for 'sorts'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      next: // value for 'next'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useInboxReimbursementCasesQuery(baseOptions?: Apollo.QueryHookOptions<InboxReimbursementCasesQuery, InboxReimbursementCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxReimbursementCasesQuery, InboxReimbursementCasesQueryVariables>(InboxReimbursementCasesDocument, options);
      }
export function useInboxReimbursementCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxReimbursementCasesQuery, InboxReimbursementCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxReimbursementCasesQuery, InboxReimbursementCasesQueryVariables>(InboxReimbursementCasesDocument, options);
        }
export type InboxReimbursementCasesQueryHookResult = ReturnType<typeof useInboxReimbursementCasesQuery>;
export type InboxReimbursementCasesLazyQueryHookResult = ReturnType<typeof useInboxReimbursementCasesLazyQuery>;
export type InboxReimbursementCasesQueryResult = Apollo.QueryResult<InboxReimbursementCasesQuery, InboxReimbursementCasesQueryVariables>;
export const InboxCardIssuerTransactionsDocument = gql`
    query inboxCardIssuerTransactions($input: CardIssuerTransactionPaginationInput!, $filters: InboxCardIssuerTransactionFiltersInput, $sortBy: CardIssuerTransactionSortInput, $queries: CardIssuerTransactionsQueryInput) {
  inboxCardIssuerTransactions(
    input: $input
    filters: $filters
    sortBy: $sortBy
    queries: $queries
  ) {
    edges {
      cursor
      node {
        ...transactionListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${TransactionListDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useInboxCardIssuerTransactionsQuery__
 *
 * To run a query within a React component, call `useInboxCardIssuerTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxCardIssuerTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxCardIssuerTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      queries: // value for 'queries'
 *   },
 * });
 */
export function useInboxCardIssuerTransactionsQuery(baseOptions: Apollo.QueryHookOptions<InboxCardIssuerTransactionsQuery, InboxCardIssuerTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxCardIssuerTransactionsQuery, InboxCardIssuerTransactionsQueryVariables>(InboxCardIssuerTransactionsDocument, options);
      }
export function useInboxCardIssuerTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxCardIssuerTransactionsQuery, InboxCardIssuerTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxCardIssuerTransactionsQuery, InboxCardIssuerTransactionsQueryVariables>(InboxCardIssuerTransactionsDocument, options);
        }
export type InboxCardIssuerTransactionsQueryHookResult = ReturnType<typeof useInboxCardIssuerTransactionsQuery>;
export type InboxCardIssuerTransactionsLazyQueryHookResult = ReturnType<typeof useInboxCardIssuerTransactionsLazyQuery>;
export type InboxCardIssuerTransactionsQueryResult = Apollo.QueryResult<InboxCardIssuerTransactionsQuery, InboxCardIssuerTransactionsQueryVariables>;
export const GetPaginatedInsightsWidgetsDocument = gql`
    query getPaginatedInsightsWidgets {
  getPaginatedInsightsWidgets(input: {}) {
    records {
      id
      title
      preferences {
        onDashboard
        widgetId
      }
      budget {
        year
        type
        monthlyBudgets {
          january
          february
          march
          april
          may
          june
          july
          august
          september
          october
          november
          december
        }
      }
      isOwnedByCurrentUser
      filters {
        costCenters {
          id
        }
        contacts {
          id
          name {
            value
          }
        }
        costObjects {
          id
        }
        generalLedgerAccounts {
          id
        }
      }
      creator {
        id
        name
        avatarUrl
      }
      unsubscribedUsers {
        id
        name
        avatarUrl
      }
      sharedWithUsers {
        id
        name
        avatarUrl
      }
    }
    pageInfo {
      totalCount
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPaginatedInsightsWidgetsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedInsightsWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedInsightsWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedInsightsWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaginatedInsightsWidgetsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaginatedInsightsWidgetsQuery, GetPaginatedInsightsWidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedInsightsWidgetsQuery, GetPaginatedInsightsWidgetsQueryVariables>(GetPaginatedInsightsWidgetsDocument, options);
      }
export function useGetPaginatedInsightsWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedInsightsWidgetsQuery, GetPaginatedInsightsWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedInsightsWidgetsQuery, GetPaginatedInsightsWidgetsQueryVariables>(GetPaginatedInsightsWidgetsDocument, options);
        }
export type GetPaginatedInsightsWidgetsQueryHookResult = ReturnType<typeof useGetPaginatedInsightsWidgetsQuery>;
export type GetPaginatedInsightsWidgetsLazyQueryHookResult = ReturnType<typeof useGetPaginatedInsightsWidgetsLazyQuery>;
export type GetPaginatedInsightsWidgetsQueryResult = Apollo.QueryResult<GetPaginatedInsightsWidgetsQuery, GetPaginatedInsightsWidgetsQueryVariables>;
export const GetInsightsWidgetDocument = gql`
    query getInsightsWidget($id: ID!) {
  getInsightsWidget(id: $id) {
    id
    title
    isOwnedByCurrentUser
    budget {
      year
      type
      monthlyBudgets {
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
      }
    }
    filters {
      costCenters {
        id
        readableName
      }
      contacts {
        id
        name {
          value
        }
      }
      costObjects {
        id
        readableName
      }
      generalLedgerAccounts {
        id
        readableName
      }
    }
    creator {
      id
      name
      avatarUrl
    }
    sharedWithUsers {
      id
      name
      avatarUrl
    }
  }
}
    `;

/**
 * __useGetInsightsWidgetQuery__
 *
 * To run a query within a React component, call `useGetInsightsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsightsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsightsWidgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInsightsWidgetQuery(baseOptions: Apollo.QueryHookOptions<GetInsightsWidgetQuery, GetInsightsWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsightsWidgetQuery, GetInsightsWidgetQueryVariables>(GetInsightsWidgetDocument, options);
      }
export function useGetInsightsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsightsWidgetQuery, GetInsightsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsightsWidgetQuery, GetInsightsWidgetQueryVariables>(GetInsightsWidgetDocument, options);
        }
export type GetInsightsWidgetQueryHookResult = ReturnType<typeof useGetInsightsWidgetQuery>;
export type GetInsightsWidgetLazyQueryHookResult = ReturnType<typeof useGetInsightsWidgetLazyQuery>;
export type GetInsightsWidgetQueryResult = Apollo.QueryResult<GetInsightsWidgetQuery, GetInsightsWidgetQueryVariables>;
export const DeleteInsightsWidgetsDocument = gql`
    mutation deleteInsightsWidgets($ids: [ID!]!) {
  deleteInsightsWidgets(ids: $ids) {
    deletedCount
  }
}
    `;
export type DeleteInsightsWidgetsMutationFn = Apollo.MutationFunction<DeleteInsightsWidgetsMutation, DeleteInsightsWidgetsMutationVariables>;

/**
 * __useDeleteInsightsWidgetsMutation__
 *
 * To run a mutation, you first call `useDeleteInsightsWidgetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsightsWidgetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsightsWidgetsMutation, { data, loading, error }] = useDeleteInsightsWidgetsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteInsightsWidgetsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInsightsWidgetsMutation, DeleteInsightsWidgetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInsightsWidgetsMutation, DeleteInsightsWidgetsMutationVariables>(DeleteInsightsWidgetsDocument, options);
      }
export type DeleteInsightsWidgetsMutationHookResult = ReturnType<typeof useDeleteInsightsWidgetsMutation>;
export type DeleteInsightsWidgetsMutationResult = Apollo.MutationResult<DeleteInsightsWidgetsMutation>;
export type DeleteInsightsWidgetsMutationOptions = Apollo.BaseMutationOptions<DeleteInsightsWidgetsMutation, DeleteInsightsWidgetsMutationVariables>;
export const UnsubscribeInsightsWidgetDocument = gql`
    mutation unsubscribeInsightsWidget($id: ID!) {
  unsubscribeInsightsWidget(id: $id)
}
    `;
export type UnsubscribeInsightsWidgetMutationFn = Apollo.MutationFunction<UnsubscribeInsightsWidgetMutation, UnsubscribeInsightsWidgetMutationVariables>;

/**
 * __useUnsubscribeInsightsWidgetMutation__
 *
 * To run a mutation, you first call `useUnsubscribeInsightsWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeInsightsWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeInsightsWidgetMutation, { data, loading, error }] = useUnsubscribeInsightsWidgetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsubscribeInsightsWidgetMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeInsightsWidgetMutation, UnsubscribeInsightsWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeInsightsWidgetMutation, UnsubscribeInsightsWidgetMutationVariables>(UnsubscribeInsightsWidgetDocument, options);
      }
export type UnsubscribeInsightsWidgetMutationHookResult = ReturnType<typeof useUnsubscribeInsightsWidgetMutation>;
export type UnsubscribeInsightsWidgetMutationResult = Apollo.MutationResult<UnsubscribeInsightsWidgetMutation>;
export type UnsubscribeInsightsWidgetMutationOptions = Apollo.BaseMutationOptions<UnsubscribeInsightsWidgetMutation, UnsubscribeInsightsWidgetMutationVariables>;
export const UpdateInsightsWidgetPreferencesDocument = gql`
    mutation updateInsightsWidgetPreferences($id: ID!, $input: InsightsWidgetPreferenceInput!) {
  updateInsightsWidgetPreferences(id: $id, input: $input)
}
    `;
export type UpdateInsightsWidgetPreferencesMutationFn = Apollo.MutationFunction<UpdateInsightsWidgetPreferencesMutation, UpdateInsightsWidgetPreferencesMutationVariables>;

/**
 * __useUpdateInsightsWidgetPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateInsightsWidgetPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsightsWidgetPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsightsWidgetPreferencesMutation, { data, loading, error }] = useUpdateInsightsWidgetPreferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsightsWidgetPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInsightsWidgetPreferencesMutation, UpdateInsightsWidgetPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInsightsWidgetPreferencesMutation, UpdateInsightsWidgetPreferencesMutationVariables>(UpdateInsightsWidgetPreferencesDocument, options);
      }
export type UpdateInsightsWidgetPreferencesMutationHookResult = ReturnType<typeof useUpdateInsightsWidgetPreferencesMutation>;
export type UpdateInsightsWidgetPreferencesMutationResult = Apollo.MutationResult<UpdateInsightsWidgetPreferencesMutation>;
export type UpdateInsightsWidgetPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateInsightsWidgetPreferencesMutation, UpdateInsightsWidgetPreferencesMutationVariables>;
export const ExportSapB1SagaDocument = gql`
    mutation exportSapB1Saga($exportGuid: String!, $isReexport: Boolean) {
  exportSapB1Saga(exportGuid: $exportGuid, isReexport: $isReexport) {
    ... on SapB1ExportResponse {
      exportGuid
    }
    ... on SapB1ExportErrorResponse {
      code
    }
  }
}
    `;
export type ExportSapB1SagaMutationFn = Apollo.MutationFunction<ExportSapB1SagaMutation, ExportSapB1SagaMutationVariables>;

/**
 * __useExportSapB1SagaMutation__
 *
 * To run a mutation, you first call `useExportSapB1SagaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportSapB1SagaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportSapB1SagaMutation, { data, loading, error }] = useExportSapB1SagaMutation({
 *   variables: {
 *      exportGuid: // value for 'exportGuid'
 *      isReexport: // value for 'isReexport'
 *   },
 * });
 */
export function useExportSapB1SagaMutation(baseOptions?: Apollo.MutationHookOptions<ExportSapB1SagaMutation, ExportSapB1SagaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportSapB1SagaMutation, ExportSapB1SagaMutationVariables>(ExportSapB1SagaDocument, options);
      }
export type ExportSapB1SagaMutationHookResult = ReturnType<typeof useExportSapB1SagaMutation>;
export type ExportSapB1SagaMutationResult = Apollo.MutationResult<ExportSapB1SagaMutation>;
export type ExportSapB1SagaMutationOptions = Apollo.BaseMutationOptions<ExportSapB1SagaMutation, ExportSapB1SagaMutationVariables>;
export const ExportDatevXmlDocument = gql`
    mutation exportDatevXml($documentIds: [String!]!, $transactionIds: [String!]!, $cardSettlementIds: [String!]!, $reimbursementIds: [String!]!, $originalExportGuid: String, $exportAll: Boolean) {
  exportDatevXml(
    documentIds: $documentIds
    transactionIds: $transactionIds
    cardSettlementIds: $cardSettlementIds
    reimbursementIds: $reimbursementIds
    originalExportGuid: $originalExportGuid
    exportAll: $exportAll
  )
}
    `;
export type ExportDatevXmlMutationFn = Apollo.MutationFunction<ExportDatevXmlMutation, ExportDatevXmlMutationVariables>;

/**
 * __useExportDatevXmlMutation__
 *
 * To run a mutation, you first call `useExportDatevXmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDatevXmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDatevXmlMutation, { data, loading, error }] = useExportDatevXmlMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      transactionIds: // value for 'transactionIds'
 *      cardSettlementIds: // value for 'cardSettlementIds'
 *      reimbursementIds: // value for 'reimbursementIds'
 *      originalExportGuid: // value for 'originalExportGuid'
 *      exportAll: // value for 'exportAll'
 *   },
 * });
 */
export function useExportDatevXmlMutation(baseOptions?: Apollo.MutationHookOptions<ExportDatevXmlMutation, ExportDatevXmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportDatevXmlMutation, ExportDatevXmlMutationVariables>(ExportDatevXmlDocument, options);
      }
export type ExportDatevXmlMutationHookResult = ReturnType<typeof useExportDatevXmlMutation>;
export type ExportDatevXmlMutationResult = Apollo.MutationResult<ExportDatevXmlMutation>;
export type ExportDatevXmlMutationOptions = Apollo.BaseMutationOptions<ExportDatevXmlMutation, ExportDatevXmlMutationVariables>;
export const ExportArchiveDownloadUrlDocument = gql`
    mutation exportArchiveDownloadUrl($id: ID!) {
  exportArchiveDownloadUrl(id: $id)
}
    `;
export type ExportArchiveDownloadUrlMutationFn = Apollo.MutationFunction<ExportArchiveDownloadUrlMutation, ExportArchiveDownloadUrlMutationVariables>;

/**
 * __useExportArchiveDownloadUrlMutation__
 *
 * To run a mutation, you first call `useExportArchiveDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportArchiveDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportArchiveDownloadUrlMutation, { data, loading, error }] = useExportArchiveDownloadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchiveDownloadUrlMutation(baseOptions?: Apollo.MutationHookOptions<ExportArchiveDownloadUrlMutation, ExportArchiveDownloadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportArchiveDownloadUrlMutation, ExportArchiveDownloadUrlMutationVariables>(ExportArchiveDownloadUrlDocument, options);
      }
export type ExportArchiveDownloadUrlMutationHookResult = ReturnType<typeof useExportArchiveDownloadUrlMutation>;
export type ExportArchiveDownloadUrlMutationResult = Apollo.MutationResult<ExportArchiveDownloadUrlMutation>;
export type ExportArchiveDownloadUrlMutationOptions = Apollo.BaseMutationOptions<ExportArchiveDownloadUrlMutation, ExportArchiveDownloadUrlMutationVariables>;
export const ExportArchiveDownloadUrlsDocument = gql`
    mutation exportArchiveDownloadUrls($id: ID!) {
  exportArchiveDownloadUrls(id: $id)
}
    `;
export type ExportArchiveDownloadUrlsMutationFn = Apollo.MutationFunction<ExportArchiveDownloadUrlsMutation, ExportArchiveDownloadUrlsMutationVariables>;

/**
 * __useExportArchiveDownloadUrlsMutation__
 *
 * To run a mutation, you first call `useExportArchiveDownloadUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportArchiveDownloadUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportArchiveDownloadUrlsMutation, { data, loading, error }] = useExportArchiveDownloadUrlsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchiveDownloadUrlsMutation(baseOptions?: Apollo.MutationHookOptions<ExportArchiveDownloadUrlsMutation, ExportArchiveDownloadUrlsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportArchiveDownloadUrlsMutation, ExportArchiveDownloadUrlsMutationVariables>(ExportArchiveDownloadUrlsDocument, options);
      }
export type ExportArchiveDownloadUrlsMutationHookResult = ReturnType<typeof useExportArchiveDownloadUrlsMutation>;
export type ExportArchiveDownloadUrlsMutationResult = Apollo.MutationResult<ExportArchiveDownloadUrlsMutation>;
export type ExportArchiveDownloadUrlsMutationOptions = Apollo.BaseMutationOptions<ExportArchiveDownloadUrlsMutation, ExportArchiveDownloadUrlsMutationVariables>;
export const ExportDatevDuoDocument = gql`
    mutation exportDatevDuo($documentIds: [String!]!, $transactionIds: [String!]!, $cardSettlementIds: [String!]!, $originalExportGuid: String, $reimbursementIds: [String!]!, $exportAll: Boolean) {
  exportDatevDuo(
    documentIds: $documentIds
    transactionIds: $transactionIds
    cardSettlementIds: $cardSettlementIds
    reimbursementIds: $reimbursementIds
    originalExportGuid: $originalExportGuid
    exportAll: $exportAll
  )
}
    `;
export type ExportDatevDuoMutationFn = Apollo.MutationFunction<ExportDatevDuoMutation, ExportDatevDuoMutationVariables>;

/**
 * __useExportDatevDuoMutation__
 *
 * To run a mutation, you first call `useExportDatevDuoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDatevDuoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDatevDuoMutation, { data, loading, error }] = useExportDatevDuoMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      transactionIds: // value for 'transactionIds'
 *      cardSettlementIds: // value for 'cardSettlementIds'
 *      originalExportGuid: // value for 'originalExportGuid'
 *      reimbursementIds: // value for 'reimbursementIds'
 *      exportAll: // value for 'exportAll'
 *   },
 * });
 */
export function useExportDatevDuoMutation(baseOptions?: Apollo.MutationHookOptions<ExportDatevDuoMutation, ExportDatevDuoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportDatevDuoMutation, ExportDatevDuoMutationVariables>(ExportDatevDuoDocument, options);
      }
export type ExportDatevDuoMutationHookResult = ReturnType<typeof useExportDatevDuoMutation>;
export type ExportDatevDuoMutationResult = Apollo.MutationResult<ExportDatevDuoMutation>;
export type ExportDatevDuoMutationOptions = Apollo.BaseMutationOptions<ExportDatevDuoMutation, ExportDatevDuoMutationVariables>;
export const ExportDatevBdsDocument = gql`
    mutation exportDatevBds($timeFrame: TimeFrameInput!, $documentIds: [String!]!, $transactionIds: [String!]!, $cardSettlementIds: [String!]!, $provisionIds: [String!]!, $reversalIds: [String!]!, $reimbursementIds: [String!]!, $originalExportGuid: String, $exportAll: Boolean, $exportAllContacts: Boolean, $exportAllPaymentConditions: Boolean) {
  exportDatevBds(
    timeFrame: $timeFrame
    documentIds: $documentIds
    transactionIds: $transactionIds
    reimbursementIds: $reimbursementIds
    cardSettlementIds: $cardSettlementIds
    provisionIds: $provisionIds
    reversalIds: $reversalIds
    originalExportGuid: $originalExportGuid
    exportAll: $exportAll
    exportAllContacts: $exportAllContacts
    exportAllPaymentConditions: $exportAllPaymentConditions
  )
}
    `;
export type ExportDatevBdsMutationFn = Apollo.MutationFunction<ExportDatevBdsMutation, ExportDatevBdsMutationVariables>;

/**
 * __useExportDatevBdsMutation__
 *
 * To run a mutation, you first call `useExportDatevBdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDatevBdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDatevBdsMutation, { data, loading, error }] = useExportDatevBdsMutation({
 *   variables: {
 *      timeFrame: // value for 'timeFrame'
 *      documentIds: // value for 'documentIds'
 *      transactionIds: // value for 'transactionIds'
 *      cardSettlementIds: // value for 'cardSettlementIds'
 *      provisionIds: // value for 'provisionIds'
 *      reversalIds: // value for 'reversalIds'
 *      reimbursementIds: // value for 'reimbursementIds'
 *      originalExportGuid: // value for 'originalExportGuid'
 *      exportAll: // value for 'exportAll'
 *      exportAllContacts: // value for 'exportAllContacts'
 *      exportAllPaymentConditions: // value for 'exportAllPaymentConditions'
 *   },
 * });
 */
export function useExportDatevBdsMutation(baseOptions?: Apollo.MutationHookOptions<ExportDatevBdsMutation, ExportDatevBdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportDatevBdsMutation, ExportDatevBdsMutationVariables>(ExportDatevBdsDocument, options);
      }
export type ExportDatevBdsMutationHookResult = ReturnType<typeof useExportDatevBdsMutation>;
export type ExportDatevBdsMutationResult = Apollo.MutationResult<ExportDatevBdsMutation>;
export type ExportDatevBdsMutationOptions = Apollo.BaseMutationOptions<ExportDatevBdsMutation, ExportDatevBdsMutationVariables>;
export const ExportZipDocument = gql`
    mutation exportZip($documentIds: [String!]!, $reimbursementIds: [String!]!, $originalExportGuid: String, $exportAll: Boolean) {
  exportZip(
    documentIds: $documentIds
    reimbursementIds: $reimbursementIds
    originalExportGuid: $originalExportGuid
    exportAll: $exportAll
  )
}
    `;
export type ExportZipMutationFn = Apollo.MutationFunction<ExportZipMutation, ExportZipMutationVariables>;

/**
 * __useExportZipMutation__
 *
 * To run a mutation, you first call `useExportZipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportZipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportZipMutation, { data, loading, error }] = useExportZipMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      reimbursementIds: // value for 'reimbursementIds'
 *      originalExportGuid: // value for 'originalExportGuid'
 *      exportAll: // value for 'exportAll'
 *   },
 * });
 */
export function useExportZipMutation(baseOptions?: Apollo.MutationHookOptions<ExportZipMutation, ExportZipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportZipMutation, ExportZipMutationVariables>(ExportZipDocument, options);
      }
export type ExportZipMutationHookResult = ReturnType<typeof useExportZipMutation>;
export type ExportZipMutationResult = Apollo.MutationResult<ExportZipMutation>;
export type ExportZipMutationOptions = Apollo.BaseMutationOptions<ExportZipMutation, ExportZipMutationVariables>;
export const ExportAddisonFormatDocument = gql`
    mutation exportAddisonFormat($documentIds: [String!]!, $transactionIds: [String!], $cardSettlements: [String!], $reimbursementIds: [String!]!, $originExportId: String, $exportAll: Boolean) {
  exportAddisonFormat(
    documentIds: $documentIds
    transactionIds: $transactionIds
    cardSettlements: $cardSettlements
    reimbursementIds: $reimbursementIds
    originExportId: $originExportId
    exportAll: $exportAll
  ) {
    id
    hash
  }
}
    `;
export type ExportAddisonFormatMutationFn = Apollo.MutationFunction<ExportAddisonFormatMutation, ExportAddisonFormatMutationVariables>;

/**
 * __useExportAddisonFormatMutation__
 *
 * To run a mutation, you first call `useExportAddisonFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAddisonFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAddisonFormatMutation, { data, loading, error }] = useExportAddisonFormatMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      transactionIds: // value for 'transactionIds'
 *      cardSettlements: // value for 'cardSettlements'
 *      reimbursementIds: // value for 'reimbursementIds'
 *      originExportId: // value for 'originExportId'
 *      exportAll: // value for 'exportAll'
 *   },
 * });
 */
export function useExportAddisonFormatMutation(baseOptions?: Apollo.MutationHookOptions<ExportAddisonFormatMutation, ExportAddisonFormatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportAddisonFormatMutation, ExportAddisonFormatMutationVariables>(ExportAddisonFormatDocument, options);
      }
export type ExportAddisonFormatMutationHookResult = ReturnType<typeof useExportAddisonFormatMutation>;
export type ExportAddisonFormatMutationResult = Apollo.MutationResult<ExportAddisonFormatMutation>;
export type ExportAddisonFormatMutationOptions = Apollo.BaseMutationOptions<ExportAddisonFormatMutation, ExportAddisonFormatMutationVariables>;
export const ExportDatevFormatDocument = gql`
    mutation exportDatevFormat($timeFrame: TimeFrameInput!, $documentIds: [String!]!, $transactionIds: [String!]!, $cardSettlementIds: [String!]!, $provisionIds: [String!]!, $reversalIds: [String!]!, $reimbursementIds: [String!]!, $originalExportGuid: String, $exportAll: Boolean) {
  exportDatevFormat(
    timeFrame: $timeFrame
    documentIds: $documentIds
    transactionIds: $transactionIds
    cardSettlementIds: $cardSettlementIds
    reimbursementIds: $reimbursementIds
    provisionIds: $provisionIds
    reversalIds: $reversalIds
    originalExportGuid: $originalExportGuid
    exportAll: $exportAll
  )
}
    `;
export type ExportDatevFormatMutationFn = Apollo.MutationFunction<ExportDatevFormatMutation, ExportDatevFormatMutationVariables>;

/**
 * __useExportDatevFormatMutation__
 *
 * To run a mutation, you first call `useExportDatevFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDatevFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDatevFormatMutation, { data, loading, error }] = useExportDatevFormatMutation({
 *   variables: {
 *      timeFrame: // value for 'timeFrame'
 *      documentIds: // value for 'documentIds'
 *      transactionIds: // value for 'transactionIds'
 *      cardSettlementIds: // value for 'cardSettlementIds'
 *      provisionIds: // value for 'provisionIds'
 *      reversalIds: // value for 'reversalIds'
 *      reimbursementIds: // value for 'reimbursementIds'
 *      originalExportGuid: // value for 'originalExportGuid'
 *      exportAll: // value for 'exportAll'
 *   },
 * });
 */
export function useExportDatevFormatMutation(baseOptions?: Apollo.MutationHookOptions<ExportDatevFormatMutation, ExportDatevFormatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportDatevFormatMutation, ExportDatevFormatMutationVariables>(ExportDatevFormatDocument, options);
      }
export type ExportDatevFormatMutationHookResult = ReturnType<typeof useExportDatevFormatMutation>;
export type ExportDatevFormatMutationResult = Apollo.MutationResult<ExportDatevFormatMutation>;
export type ExportDatevFormatMutationOptions = Apollo.BaseMutationOptions<ExportDatevFormatMutation, ExportDatevFormatMutationVariables>;
export const NewExportSapCsvDocument = gql`
    mutation newExportSapCsv($documentIds: [String!]!, $timeFrame: TimeFrameInput!, $originExportId: String, $exportAll: Boolean) {
  newExportSapCsv(
    documentIds: $documentIds
    timeFrame: $timeFrame
    originExportId: $originExportId
    exportAll: $exportAll
  ) {
    id
    hash
  }
}
    `;
export type NewExportSapCsvMutationFn = Apollo.MutationFunction<NewExportSapCsvMutation, NewExportSapCsvMutationVariables>;

/**
 * __useNewExportSapCsvMutation__
 *
 * To run a mutation, you first call `useNewExportSapCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewExportSapCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newExportSapCsvMutation, { data, loading, error }] = useNewExportSapCsvMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      timeFrame: // value for 'timeFrame'
 *      originExportId: // value for 'originExportId'
 *      exportAll: // value for 'exportAll'
 *   },
 * });
 */
export function useNewExportSapCsvMutation(baseOptions?: Apollo.MutationHookOptions<NewExportSapCsvMutation, NewExportSapCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewExportSapCsvMutation, NewExportSapCsvMutationVariables>(NewExportSapCsvDocument, options);
      }
export type NewExportSapCsvMutationHookResult = ReturnType<typeof useNewExportSapCsvMutation>;
export type NewExportSapCsvMutationResult = Apollo.MutationResult<NewExportSapCsvMutation>;
export type NewExportSapCsvMutationOptions = Apollo.BaseMutationOptions<NewExportSapCsvMutation, NewExportSapCsvMutationVariables>;
export const ExportCandisCsvDocument = gql`
    mutation exportCandisCsv($documentIds: [String!]!, $reversalIds: [String!]!, $reimbursementIds: [String!]!, $timeFrame: TimeFrameInput!, $originExportId: String, $exportAll: Boolean) {
  exportCandisCsv(
    documentIds: $documentIds
    reversalIds: $reversalIds
    reimbursementIds: $reimbursementIds
    timeFrame: $timeFrame
    originExportId: $originExportId
    exportAll: $exportAll
  ) {
    id
    hash
  }
}
    `;
export type ExportCandisCsvMutationFn = Apollo.MutationFunction<ExportCandisCsvMutation, ExportCandisCsvMutationVariables>;

/**
 * __useExportCandisCsvMutation__
 *
 * To run a mutation, you first call `useExportCandisCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCandisCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCandisCsvMutation, { data, loading, error }] = useExportCandisCsvMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      reversalIds: // value for 'reversalIds'
 *      reimbursementIds: // value for 'reimbursementIds'
 *      timeFrame: // value for 'timeFrame'
 *      originExportId: // value for 'originExportId'
 *      exportAll: // value for 'exportAll'
 *   },
 * });
 */
export function useExportCandisCsvMutation(baseOptions?: Apollo.MutationHookOptions<ExportCandisCsvMutation, ExportCandisCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCandisCsvMutation, ExportCandisCsvMutationVariables>(ExportCandisCsvDocument, options);
      }
export type ExportCandisCsvMutationHookResult = ReturnType<typeof useExportCandisCsvMutation>;
export type ExportCandisCsvMutationResult = Apollo.MutationResult<ExportCandisCsvMutation>;
export type ExportCandisCsvMutationOptions = Apollo.BaseMutationOptions<ExportCandisCsvMutation, ExportCandisCsvMutationVariables>;
export const SetFiscalYearConfiguredDocument = gql`
    mutation setFiscalYearConfigured {
  setFiscalYearConfigured
}
    `;
export type SetFiscalYearConfiguredMutationFn = Apollo.MutationFunction<SetFiscalYearConfiguredMutation, SetFiscalYearConfiguredMutationVariables>;

/**
 * __useSetFiscalYearConfiguredMutation__
 *
 * To run a mutation, you first call `useSetFiscalYearConfiguredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFiscalYearConfiguredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFiscalYearConfiguredMutation, { data, loading, error }] = useSetFiscalYearConfiguredMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetFiscalYearConfiguredMutation(baseOptions?: Apollo.MutationHookOptions<SetFiscalYearConfiguredMutation, SetFiscalYearConfiguredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFiscalYearConfiguredMutation, SetFiscalYearConfiguredMutationVariables>(SetFiscalYearConfiguredDocument, options);
      }
export type SetFiscalYearConfiguredMutationHookResult = ReturnType<typeof useSetFiscalYearConfiguredMutation>;
export type SetFiscalYearConfiguredMutationResult = Apollo.MutationResult<SetFiscalYearConfiguredMutation>;
export type SetFiscalYearConfiguredMutationOptions = Apollo.BaseMutationOptions<SetFiscalYearConfiguredMutation, SetFiscalYearConfiguredMutationVariables>;
export const _ExportsDocument = gql`
    query _exports($input: ExportFilters, $limit: Int, $after: String) {
  _exports(input: $input, limit: $limit, after: $after) @connection(key: "exportsHistory", filter: ["input"]) {
    node {
      ...Export
      createdAt
      creator {
        id
        name
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${ExportFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __use_ExportsQuery__
 *
 * To run a query within a React component, call `use_ExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `use_ExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = use_ExportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function use_ExportsQuery(baseOptions?: Apollo.QueryHookOptions<_ExportsQuery, _ExportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<_ExportsQuery, _ExportsQueryVariables>(_ExportsDocument, options);
      }
export function use_ExportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<_ExportsQuery, _ExportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<_ExportsQuery, _ExportsQueryVariables>(_ExportsDocument, options);
        }
export type _ExportsQueryHookResult = ReturnType<typeof use_ExportsQuery>;
export type _ExportsLazyQueryHookResult = ReturnType<typeof use_ExportsLazyQuery>;
export type _ExportsQueryResult = Apollo.QueryResult<_ExportsQuery, _ExportsQueryVariables>;
export const DocumentsForExportDocument = gql`
    query documentsForExport($limit: Int, $after: String, $filters: DocumentFilterInput, $sort: SortInput) {
  documentsForExport(limit: $limit, after: $after, filters: $filters, sort: $sort) @connection(key: "documentsForExportPagination", filter: ["filters", "sort"]) {
    node {
      ...AnExportableDocument
    }
    edges {
      cursor
      node {
        ...AnExportableDocument
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${AnExportableDocumentFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useDocumentsForExportQuery__
 *
 * To run a query within a React component, call `useDocumentsForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsForExportQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDocumentsForExportQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsForExportQuery, DocumentsForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsForExportQuery, DocumentsForExportQueryVariables>(DocumentsForExportDocument, options);
      }
export function useDocumentsForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsForExportQuery, DocumentsForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsForExportQuery, DocumentsForExportQueryVariables>(DocumentsForExportDocument, options);
        }
export type DocumentsForExportQueryHookResult = ReturnType<typeof useDocumentsForExportQuery>;
export type DocumentsForExportLazyQueryHookResult = ReturnType<typeof useDocumentsForExportLazyQuery>;
export type DocumentsForExportQueryResult = Apollo.QueryResult<DocumentsForExportQuery, DocumentsForExportQueryVariables>;
export const ExportDocumentsEsDocument = gql`
    query exportDocumentsEs($limit: Int, $after: String, $filters: ExportDocumentsFilterInput, $sort: ExportDocumentsSortInput) {
  exportDocumentsEs(limit: $limit, after: $after, filters: $filters, sort: $sort) @connection(key: "documentsForExportPagination", filter: ["filters", "sort"]) {
    edges {
      cursor
      node {
        ...AnExportableDocumentEs
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${AnExportableDocumentEsFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useExportDocumentsEsQuery__
 *
 * To run a query within a React component, call `useExportDocumentsEsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDocumentsEsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDocumentsEsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useExportDocumentsEsQuery(baseOptions?: Apollo.QueryHookOptions<ExportDocumentsEsQuery, ExportDocumentsEsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportDocumentsEsQuery, ExportDocumentsEsQueryVariables>(ExportDocumentsEsDocument, options);
      }
export function useExportDocumentsEsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportDocumentsEsQuery, ExportDocumentsEsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportDocumentsEsQuery, ExportDocumentsEsQueryVariables>(ExportDocumentsEsDocument, options);
        }
export type ExportDocumentsEsQueryHookResult = ReturnType<typeof useExportDocumentsEsQuery>;
export type ExportDocumentsEsLazyQueryHookResult = ReturnType<typeof useExportDocumentsEsLazyQuery>;
export type ExportDocumentsEsQueryResult = Apollo.QueryResult<ExportDocumentsEsQuery, ExportDocumentsEsQueryVariables>;
export const DocumentsForExportContactsDocument = gql`
    query documentsForExportContacts($limit: Int, $filters: DocumentFilterInput) {
  documentsForExportContacts: documentsForExport(limit: $limit, filters: $filters) {
    node {
      id
      contact {
        id
        name {
          value
        }
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${PageInfoDataFragmentDoc}`;

/**
 * __useDocumentsForExportContactsQuery__
 *
 * To run a query within a React component, call `useDocumentsForExportContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsForExportContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsForExportContactsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDocumentsForExportContactsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsForExportContactsQuery, DocumentsForExportContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsForExportContactsQuery, DocumentsForExportContactsQueryVariables>(DocumentsForExportContactsDocument, options);
      }
export function useDocumentsForExportContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsForExportContactsQuery, DocumentsForExportContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsForExportContactsQuery, DocumentsForExportContactsQueryVariables>(DocumentsForExportContactsDocument, options);
        }
export type DocumentsForExportContactsQueryHookResult = ReturnType<typeof useDocumentsForExportContactsQuery>;
export type DocumentsForExportContactsLazyQueryHookResult = ReturnType<typeof useDocumentsForExportContactsLazyQuery>;
export type DocumentsForExportContactsQueryResult = Apollo.QueryResult<DocumentsForExportContactsQuery, DocumentsForExportContactsQueryVariables>;
export const CardTransactionsReadyForExportDocument = gql`
    query cardTransactionsReadyForExport {
  cardTransactionsReadyForExport {
    associatedCardTransactions {
      ...AnExportableDocument
    }
    unassociatedCardTransactions {
      ...AnExportableTransaction
    }
  }
}
    ${AnExportableDocumentFragmentDoc}
${AnExportableTransactionFragmentDoc}`;

/**
 * __useCardTransactionsReadyForExportQuery__
 *
 * To run a query within a React component, call `useCardTransactionsReadyForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardTransactionsReadyForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardTransactionsReadyForExportQuery({
 *   variables: {
 *   },
 * });
 */
export function useCardTransactionsReadyForExportQuery(baseOptions?: Apollo.QueryHookOptions<CardTransactionsReadyForExportQuery, CardTransactionsReadyForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardTransactionsReadyForExportQuery, CardTransactionsReadyForExportQueryVariables>(CardTransactionsReadyForExportDocument, options);
      }
export function useCardTransactionsReadyForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardTransactionsReadyForExportQuery, CardTransactionsReadyForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardTransactionsReadyForExportQuery, CardTransactionsReadyForExportQueryVariables>(CardTransactionsReadyForExportDocument, options);
        }
export type CardTransactionsReadyForExportQueryHookResult = ReturnType<typeof useCardTransactionsReadyForExportQuery>;
export type CardTransactionsReadyForExportLazyQueryHookResult = ReturnType<typeof useCardTransactionsReadyForExportLazyQuery>;
export type CardTransactionsReadyForExportQueryResult = Apollo.QueryResult<CardTransactionsReadyForExportQuery, CardTransactionsReadyForExportQueryVariables>;
export const CardTransactionsNotReadyForExportDocument = gql`
    query cardTransactionsNotReadyForExport {
  cardTransactionsNotReadyForExport {
    associatedCardTransactions {
      ...AnExportableDocument
    }
    unassociatedCardTransactions {
      ...AnExportableTransaction
    }
  }
}
    ${AnExportableDocumentFragmentDoc}
${AnExportableTransactionFragmentDoc}`;

/**
 * __useCardTransactionsNotReadyForExportQuery__
 *
 * To run a query within a React component, call `useCardTransactionsNotReadyForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardTransactionsNotReadyForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardTransactionsNotReadyForExportQuery({
 *   variables: {
 *   },
 * });
 */
export function useCardTransactionsNotReadyForExportQuery(baseOptions?: Apollo.QueryHookOptions<CardTransactionsNotReadyForExportQuery, CardTransactionsNotReadyForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardTransactionsNotReadyForExportQuery, CardTransactionsNotReadyForExportQueryVariables>(CardTransactionsNotReadyForExportDocument, options);
      }
export function useCardTransactionsNotReadyForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardTransactionsNotReadyForExportQuery, CardTransactionsNotReadyForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardTransactionsNotReadyForExportQuery, CardTransactionsNotReadyForExportQueryVariables>(CardTransactionsNotReadyForExportDocument, options);
        }
export type CardTransactionsNotReadyForExportQueryHookResult = ReturnType<typeof useCardTransactionsNotReadyForExportQuery>;
export type CardTransactionsNotReadyForExportLazyQueryHookResult = ReturnType<typeof useCardTransactionsNotReadyForExportLazyQuery>;
export type CardTransactionsNotReadyForExportQueryResult = Apollo.QueryResult<CardTransactionsNotReadyForExportQuery, CardTransactionsNotReadyForExportQueryVariables>;
export const CardSettlementsReadyForExportDocument = gql`
    query cardSettlementsReadyForExport($before: String, $after: String, $limit: Int, $statuses: [PaymentSettlementStatus!]!, $paidSettlements: CardIssuerSettlementPaidSettlementsFilter) {
  cardSettlementsReadyForExport(
    before: $before
    after: $after
    limit: $limit
    statuses: $statuses
    paidSettlements: $paidSettlements
  ) {
    node {
      id
      status
      amount {
        value
        precision
        currency
      }
      actualPaymentDate
      paymentCreatedAt
      plannedPaymentDate
      publicPaymentId
      statement {
        id
        hasFile
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${PageInfoDataFragmentDoc}`;

/**
 * __useCardSettlementsReadyForExportQuery__
 *
 * To run a query within a React component, call `useCardSettlementsReadyForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardSettlementsReadyForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardSettlementsReadyForExportQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      statuses: // value for 'statuses'
 *      paidSettlements: // value for 'paidSettlements'
 *   },
 * });
 */
export function useCardSettlementsReadyForExportQuery(baseOptions: Apollo.QueryHookOptions<CardSettlementsReadyForExportQuery, CardSettlementsReadyForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardSettlementsReadyForExportQuery, CardSettlementsReadyForExportQueryVariables>(CardSettlementsReadyForExportDocument, options);
      }
export function useCardSettlementsReadyForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardSettlementsReadyForExportQuery, CardSettlementsReadyForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardSettlementsReadyForExportQuery, CardSettlementsReadyForExportQueryVariables>(CardSettlementsReadyForExportDocument, options);
        }
export type CardSettlementsReadyForExportQueryHookResult = ReturnType<typeof useCardSettlementsReadyForExportQuery>;
export type CardSettlementsReadyForExportLazyQueryHookResult = ReturnType<typeof useCardSettlementsReadyForExportLazyQuery>;
export type CardSettlementsReadyForExportQueryResult = Apollo.QueryResult<CardSettlementsReadyForExportQuery, CardSettlementsReadyForExportQueryVariables>;
export const ProvisionsReadyForExportDocument = gql`
    query provisionsReadyForExport($before: String, $after: String, $limit: Int, $filters: ExportablePovisionFilters!) {
  provisionsReadyForExport(
    before: $before
    after: $after
    limit: $limit
    filters: $filters
  ) {
    node {
      ...Provision
      __typename
    }
    edges {
      id
    }
    pageInfo {
      startCursor
    }
  }
}
    ${ProvisionFragmentDoc}`;

/**
 * __useProvisionsReadyForExportQuery__
 *
 * To run a query within a React component, call `useProvisionsReadyForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvisionsReadyForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvisionsReadyForExportQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProvisionsReadyForExportQuery(baseOptions: Apollo.QueryHookOptions<ProvisionsReadyForExportQuery, ProvisionsReadyForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvisionsReadyForExportQuery, ProvisionsReadyForExportQueryVariables>(ProvisionsReadyForExportDocument, options);
      }
export function useProvisionsReadyForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvisionsReadyForExportQuery, ProvisionsReadyForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvisionsReadyForExportQuery, ProvisionsReadyForExportQueryVariables>(ProvisionsReadyForExportDocument, options);
        }
export type ProvisionsReadyForExportQueryHookResult = ReturnType<typeof useProvisionsReadyForExportQuery>;
export type ProvisionsReadyForExportLazyQueryHookResult = ReturnType<typeof useProvisionsReadyForExportLazyQuery>;
export type ProvisionsReadyForExportQueryResult = Apollo.QueryResult<ProvisionsReadyForExportQuery, ProvisionsReadyForExportQueryVariables>;
export const ReversalsReadyForExportDocument = gql`
    query reversalsReadyForExport($before: String, $after: String, $limit: Int, $filters: ExportableReversalFilters!) {
  reversalsReadyForExport(
    before: $before
    after: $after
    limit: $limit
    filters: $filters
  ) {
    node {
      ...Reversal
      __typename
    }
    edges {
      id
    }
    pageInfo {
      startCursor
    }
  }
}
    ${ReversalFragmentDoc}`;

/**
 * __useReversalsReadyForExportQuery__
 *
 * To run a query within a React component, call `useReversalsReadyForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReversalsReadyForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReversalsReadyForExportQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReversalsReadyForExportQuery(baseOptions: Apollo.QueryHookOptions<ReversalsReadyForExportQuery, ReversalsReadyForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReversalsReadyForExportQuery, ReversalsReadyForExportQueryVariables>(ReversalsReadyForExportDocument, options);
      }
export function useReversalsReadyForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReversalsReadyForExportQuery, ReversalsReadyForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReversalsReadyForExportQuery, ReversalsReadyForExportQueryVariables>(ReversalsReadyForExportDocument, options);
        }
export type ReversalsReadyForExportQueryHookResult = ReturnType<typeof useReversalsReadyForExportQuery>;
export type ReversalsReadyForExportLazyQueryHookResult = ReturnType<typeof useReversalsReadyForExportLazyQuery>;
export type ReversalsReadyForExportQueryResult = Apollo.QueryResult<ReversalsReadyForExportQuery, ReversalsReadyForExportQueryVariables>;
export const ReimbursementItemsReadyForExportDocument = gql`
    query reimbursementItemsReadyForExport($before: String, $after: String, $limit: Int, $filters: ExportableReimbursementItemFilters, $sort: ExportableReimbursementItemSortInput) {
  reimbursementItemsReadyForExport(
    before: $before
    after: $after
    limit: $limit
    filters: $filters
    sort: $sort
  ) {
    node {
      __typename
      id
      title
      type
      totalAmount {
        currency
        precision
        amount
      }
      reimbursementCase {
        id
        title
        submittedForReviewAt
        targetMembership {
          id
          firstName
          lastName
          avatarUrl
        }
      }
      reimbursementItemDate
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${PageInfoDataFragmentDoc}`;

/**
 * __useReimbursementItemsReadyForExportQuery__
 *
 * To run a query within a React component, call `useReimbursementItemsReadyForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementItemsReadyForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementItemsReadyForExportQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReimbursementItemsReadyForExportQuery(baseOptions?: Apollo.QueryHookOptions<ReimbursementItemsReadyForExportQuery, ReimbursementItemsReadyForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementItemsReadyForExportQuery, ReimbursementItemsReadyForExportQueryVariables>(ReimbursementItemsReadyForExportDocument, options);
      }
export function useReimbursementItemsReadyForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementItemsReadyForExportQuery, ReimbursementItemsReadyForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementItemsReadyForExportQuery, ReimbursementItemsReadyForExportQueryVariables>(ReimbursementItemsReadyForExportDocument, options);
        }
export type ReimbursementItemsReadyForExportQueryHookResult = ReturnType<typeof useReimbursementItemsReadyForExportQuery>;
export type ReimbursementItemsReadyForExportLazyQueryHookResult = ReturnType<typeof useReimbursementItemsReadyForExportLazyQuery>;
export type ReimbursementItemsReadyForExportQueryResult = Apollo.QueryResult<ReimbursementItemsReadyForExportQuery, ReimbursementItemsReadyForExportQueryVariables>;
export const ExportNotificationsDocument = gql`
    query exportNotifications($input: ExportFilters) {
  exports(input: $input) {
    ...Export
    hash
    integration {
      details
    }
  }
}
    ${ExportFragmentDoc}`;

/**
 * __useExportNotificationsQuery__
 *
 * To run a query within a React component, call `useExportNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<ExportNotificationsQuery, ExportNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportNotificationsQuery, ExportNotificationsQueryVariables>(ExportNotificationsDocument, options);
      }
export function useExportNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportNotificationsQuery, ExportNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportNotificationsQuery, ExportNotificationsQueryVariables>(ExportNotificationsDocument, options);
        }
export type ExportNotificationsQueryHookResult = ReturnType<typeof useExportNotificationsQuery>;
export type ExportNotificationsLazyQueryHookResult = ReturnType<typeof useExportNotificationsLazyQuery>;
export type ExportNotificationsQueryResult = Apollo.QueryResult<ExportNotificationsQuery, ExportNotificationsQueryVariables>;
export const DatevStatusDocument = gql`
    query datevStatus {
  datevStatus {
    isOperational
    hasIncidentOnCandis
    hasIncidentOnDatev
  }
}
    `;

/**
 * __useDatevStatusQuery__
 *
 * To run a query within a React component, call `useDatevStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatevStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatevStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatevStatusQuery(baseOptions?: Apollo.QueryHookOptions<DatevStatusQuery, DatevStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatevStatusQuery, DatevStatusQueryVariables>(DatevStatusDocument, options);
      }
export function useDatevStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatevStatusQuery, DatevStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatevStatusQuery, DatevStatusQueryVariables>(DatevStatusDocument, options);
        }
export type DatevStatusQueryHookResult = ReturnType<typeof useDatevStatusQuery>;
export type DatevStatusLazyQueryHookResult = ReturnType<typeof useDatevStatusLazyQuery>;
export type DatevStatusQueryResult = Apollo.QueryResult<DatevStatusQuery, DatevStatusQueryVariables>;
export const ContactsForExportDocument = gql`
    query contactsForExport($fetchAllRecords: Boolean!) {
  contactsForExport(fetchAllRecords: $fetchAllRecords) {
    id
    name
    status
    relationshipType
    membershipId
  }
}
    `;

/**
 * __useContactsForExportQuery__
 *
 * To run a query within a React component, call `useContactsForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsForExportQuery({
 *   variables: {
 *      fetchAllRecords: // value for 'fetchAllRecords'
 *   },
 * });
 */
export function useContactsForExportQuery(baseOptions: Apollo.QueryHookOptions<ContactsForExportQuery, ContactsForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsForExportQuery, ContactsForExportQueryVariables>(ContactsForExportDocument, options);
      }
export function useContactsForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsForExportQuery, ContactsForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsForExportQuery, ContactsForExportQueryVariables>(ContactsForExportDocument, options);
        }
export type ContactsForExportQueryHookResult = ReturnType<typeof useContactsForExportQuery>;
export type ContactsForExportLazyQueryHookResult = ReturnType<typeof useContactsForExportLazyQuery>;
export type ContactsForExportQueryResult = Apollo.QueryResult<ContactsForExportQuery, ContactsForExportQueryVariables>;
export const PaymentConditionsForExportDocument = gql`
    query paymentConditionsForExport($fetchAllRecords: Boolean!) {
  paymentConditionsForExport(fetchAllRecords: $fetchAllRecords) {
    id
    paymentConditionNumber
    dueDateOffset
    discountOffset
    discountPercentage
    status
  }
}
    `;

/**
 * __usePaymentConditionsForExportQuery__
 *
 * To run a query within a React component, call `usePaymentConditionsForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConditionsForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConditionsForExportQuery({
 *   variables: {
 *      fetchAllRecords: // value for 'fetchAllRecords'
 *   },
 * });
 */
export function usePaymentConditionsForExportQuery(baseOptions: Apollo.QueryHookOptions<PaymentConditionsForExportQuery, PaymentConditionsForExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentConditionsForExportQuery, PaymentConditionsForExportQueryVariables>(PaymentConditionsForExportDocument, options);
      }
export function usePaymentConditionsForExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentConditionsForExportQuery, PaymentConditionsForExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentConditionsForExportQuery, PaymentConditionsForExportQueryVariables>(PaymentConditionsForExportDocument, options);
        }
export type PaymentConditionsForExportQueryHookResult = ReturnType<typeof usePaymentConditionsForExportQuery>;
export type PaymentConditionsForExportLazyQueryHookResult = ReturnType<typeof usePaymentConditionsForExportLazyQuery>;
export type PaymentConditionsForExportQueryResult = Apollo.QueryResult<PaymentConditionsForExportQuery, PaymentConditionsForExportQueryVariables>;
export const CountExportableContactDocument = gql`
    query countExportableContact {
  evaluateCoreDataExport {
    exportableCoreDataCount {
      exportableContactCount {
        count
      }
      newAndUpdatedExportableContactCount {
        count
      }
    }
  }
}
    `;

/**
 * __useCountExportableContactQuery__
 *
 * To run a query within a React component, call `useCountExportableContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountExportableContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountExportableContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountExportableContactQuery(baseOptions?: Apollo.QueryHookOptions<CountExportableContactQuery, CountExportableContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountExportableContactQuery, CountExportableContactQueryVariables>(CountExportableContactDocument, options);
      }
export function useCountExportableContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountExportableContactQuery, CountExportableContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountExportableContactQuery, CountExportableContactQueryVariables>(CountExportableContactDocument, options);
        }
export type CountExportableContactQueryHookResult = ReturnType<typeof useCountExportableContactQuery>;
export type CountExportableContactLazyQueryHookResult = ReturnType<typeof useCountExportableContactLazyQuery>;
export type CountExportableContactQueryResult = Apollo.QueryResult<CountExportableContactQuery, CountExportableContactQueryVariables>;
export const CountExportablePaymentConditionsDocument = gql`
    query countExportablePaymentConditions {
  evaluateCoreDataExport {
    exportableCoreDataCount {
      exportablePaymentConditionCount {
        count
      }
      newAndUpdatedExportablePaymentConditionCount {
        count
      }
    }
  }
}
    `;

/**
 * __useCountExportablePaymentConditionsQuery__
 *
 * To run a query within a React component, call `useCountExportablePaymentConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountExportablePaymentConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountExportablePaymentConditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountExportablePaymentConditionsQuery(baseOptions?: Apollo.QueryHookOptions<CountExportablePaymentConditionsQuery, CountExportablePaymentConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountExportablePaymentConditionsQuery, CountExportablePaymentConditionsQueryVariables>(CountExportablePaymentConditionsDocument, options);
      }
export function useCountExportablePaymentConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountExportablePaymentConditionsQuery, CountExportablePaymentConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountExportablePaymentConditionsQuery, CountExportablePaymentConditionsQueryVariables>(CountExportablePaymentConditionsDocument, options);
        }
export type CountExportablePaymentConditionsQueryHookResult = ReturnType<typeof useCountExportablePaymentConditionsQuery>;
export type CountExportablePaymentConditionsLazyQueryHookResult = ReturnType<typeof useCountExportablePaymentConditionsLazyQuery>;
export type CountExportablePaymentConditionsQueryResult = Apollo.QueryResult<CountExportablePaymentConditionsQuery, CountExportablePaymentConditionsQueryVariables>;
export const GetCoreDataExportStatusDocument = gql`
    query getCoreDataExportStatus {
  evaluateCoreDataExport {
    coreDataExportStatus {
      hasExportedContacts
      hasExportedPaymentConditions
    }
  }
}
    `;

/**
 * __useGetCoreDataExportStatusQuery__
 *
 * To run a query within a React component, call `useGetCoreDataExportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreDataExportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreDataExportStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoreDataExportStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetCoreDataExportStatusQuery, GetCoreDataExportStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoreDataExportStatusQuery, GetCoreDataExportStatusQueryVariables>(GetCoreDataExportStatusDocument, options);
      }
export function useGetCoreDataExportStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoreDataExportStatusQuery, GetCoreDataExportStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoreDataExportStatusQuery, GetCoreDataExportStatusQueryVariables>(GetCoreDataExportStatusDocument, options);
        }
export type GetCoreDataExportStatusQueryHookResult = ReturnType<typeof useGetCoreDataExportStatusQuery>;
export type GetCoreDataExportStatusLazyQueryHookResult = ReturnType<typeof useGetCoreDataExportStatusLazyQuery>;
export type GetCoreDataExportStatusQueryResult = Apollo.QueryResult<GetCoreDataExportStatusQuery, GetCoreDataExportStatusQueryVariables>;
export const GetAvailableMonthsDocument = gql`
    query getAvailableMonths($originalExportId: String) {
  getAvailableMonths(originalExportId: $originalExportId) {
    month
    year
    numberOfTxnBasedDocuments
    numberOfDocuments
    numberOfTxnWithoutDocuments
    numberOfSettlements
    numberOfDocumentProvisions
    numberOfTxnProvisions
    numberOfDocumentReversals
    numberOfTxnReversals
    numberOfReimbursementItems
  }
}
    `;

/**
 * __useGetAvailableMonthsQuery__
 *
 * To run a query within a React component, call `useGetAvailableMonthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableMonthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableMonthsQuery({
 *   variables: {
 *      originalExportId: // value for 'originalExportId'
 *   },
 * });
 */
export function useGetAvailableMonthsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableMonthsQuery, GetAvailableMonthsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableMonthsQuery, GetAvailableMonthsQueryVariables>(GetAvailableMonthsDocument, options);
      }
export function useGetAvailableMonthsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableMonthsQuery, GetAvailableMonthsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableMonthsQuery, GetAvailableMonthsQueryVariables>(GetAvailableMonthsDocument, options);
        }
export type GetAvailableMonthsQueryHookResult = ReturnType<typeof useGetAvailableMonthsQuery>;
export type GetAvailableMonthsLazyQueryHookResult = ReturnType<typeof useGetAvailableMonthsLazyQuery>;
export type GetAvailableMonthsQueryResult = Apollo.QueryResult<GetAvailableMonthsQuery, GetAvailableMonthsQueryVariables>;
export const ExportDocument = gql`
    query export($id: ID!) {
  export(id: $id) {
    id
    type
    clientNumber
    consultantNumber
    creator {
      id
      firstName
      lastName
      avatarUrl
    }
    documents {
      document {
        id
        contact {
          value {
            id
            name {
              value
            }
          }
        }
        status
        amount {
          value
        }
        category {
          direction {
            value
          }
        }
        invoiceDate {
          value
        }
        currency {
          value
        }
        invoiceNumber {
          value
        }
        documentFile {
          id
          name
        }
        datevGuid {
          value
        }
        paymentState {
          __typename
          ... on PaidDocumentState {
            isPaid
            paidAt
            method
          }
          ... on UnpaidDocumentState {
            isPaid
          }
        }
      }
      docStatus {
        type
        details
        warnings
        exportMethod
        exportError {
          message
          errorCode
          errorDescription
          errorUri
        }
      }
    }
    transactions {
      id
      invoiceAssociationStatus
      accountingData {
        contact {
          id
          name {
            value
          }
        }
      }
      billingAmount {
        value
        precision
        currency
      }
      billingAmountForAccounting {
        value
        precision
        currency
      }
      transactionCreatedAt
      merchantLegalName
      status
      member {
        firstName
        lastName
      }
      type
      cardName
    }
    cardSettlements {
      id
      status
      amount {
        value
        currency
        precision
      }
      actualPaymentDate
      paymentCreatedAt
      plannedPaymentDate
      publicPaymentId
      statement {
        id
        hasFile
      }
    }
    provisions {
      ...Provision
      __typename
    }
    reversals {
      ...Reversal
      __typename
    }
    reversals {
      ...Reversal
      __typename
    }
    status
    client
    integration {
      type
      details
    }
    timeFrame {
      year
      month
    }
    originExportId
    createdAt
    updatedAt
    exportableEntities {
      id
      type
      __typename
    }
    exportableEntityInfo {
      id
      type
      status
      details
      warnings
      exportMethod
      exportError {
        message
        errorCode
        errorDescription
        errorUri
        __typename
      }
      __typename
    }
    reimbursementItems {
      __typename
      id
      title
      type
      totalAmount {
        currency
        precision
        amount
      }
      reimbursementItemDate
      reimbursementCase {
        id
        title
        submittedForReviewAt
        targetMembership {
          id
          firstName
          lastName
          avatarUrl
        }
      }
    }
  }
}
    ${ProvisionFragmentDoc}
${ReversalFragmentDoc}`;

/**
 * __useExportQuery__
 *
 * To run a query within a React component, call `useExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportQuery(baseOptions: Apollo.QueryHookOptions<ExportQuery, ExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportQuery, ExportQueryVariables>(ExportDocument, options);
      }
export function useExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportQuery, ExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportQuery, ExportQueryVariables>(ExportDocument, options);
        }
export type ExportQueryHookResult = ReturnType<typeof useExportQuery>;
export type ExportLazyQueryHookResult = ReturnType<typeof useExportLazyQuery>;
export type ExportQueryResult = Apollo.QueryResult<ExportQuery, ExportQueryVariables>;
export const GetInitialBdsExportsDocument = gql`
    query getInitialBdsExports($limit: Int!) {
  getInitialBdsExports(limit: $limit) {
    id
    type
    clientNumber
    consultantNumber
    creator {
      id
      firstName
      lastName
      avatarUrl
    }
    documents {
      document {
        id
        contact {
          value {
            id
            name {
              value
            }
          }
        }
        status
        amount {
          value
        }
        category {
          direction {
            value
          }
        }
        invoiceDate {
          value
        }
        currency {
          value
        }
        invoiceNumber {
          value
        }
        documentFile {
          id
          name
        }
        datevGuid {
          value
        }
        paymentState {
          __typename
          ... on PaidDocumentState {
            isPaid
            paidAt
            method
          }
          ... on UnpaidDocumentState {
            isPaid
          }
        }
      }
      docStatus {
        type
        details
        warnings
        exportMethod
        exportError {
          message
          errorCode
          errorDescription
          errorUri
        }
      }
    }
    transactions {
      id
      billingAmount {
        value
        precision
        currency
      }
      transactionCreatedAt
      merchantName
      status
      member {
        firstName
        lastName
      }
      type
      cardName
    }
    cardSettlements {
      id
      status
      amount {
        value
        currency
        precision
      }
      actualPaymentDate
      paymentCreatedAt
      plannedPaymentDate
      publicPaymentId
    }
    provisions {
      ...Provision
      __typename
    }
    reversals {
      ...Reversal
      __typename
    }
    reversals {
      ...Reversal
      __typename
    }
    status
    client
    integration {
      type
      details
    }
    timeFrame {
      year
      month
    }
    originExportId
    createdAt
    updatedAt
    exportableEntities {
      id
      type
      __typename
    }
    exportableEntityInfo {
      id
      type
      status
      details
      warnings
      exportMethod
      exportError {
        message
        errorCode
        errorDescription
        errorUri
        __typename
      }
      __typename
    }
  }
}
    ${ProvisionFragmentDoc}
${ReversalFragmentDoc}`;

/**
 * __useGetInitialBdsExportsQuery__
 *
 * To run a query within a React component, call `useGetInitialBdsExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialBdsExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialBdsExportsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetInitialBdsExportsQuery(baseOptions: Apollo.QueryHookOptions<GetInitialBdsExportsQuery, GetInitialBdsExportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInitialBdsExportsQuery, GetInitialBdsExportsQueryVariables>(GetInitialBdsExportsDocument, options);
      }
export function useGetInitialBdsExportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialBdsExportsQuery, GetInitialBdsExportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInitialBdsExportsQuery, GetInitialBdsExportsQueryVariables>(GetInitialBdsExportsDocument, options);
        }
export type GetInitialBdsExportsQueryHookResult = ReturnType<typeof useGetInitialBdsExportsQuery>;
export type GetInitialBdsExportsLazyQueryHookResult = ReturnType<typeof useGetInitialBdsExportsLazyQuery>;
export type GetInitialBdsExportsQueryResult = Apollo.QueryResult<GetInitialBdsExportsQuery, GetInitialBdsExportsQueryVariables>;
export const GetDocumentForManageRelationshipsDocument = gql`
    query getDocumentForManageRelationships($id: ID!) {
  getDocument(id: $id) {
    id
    globalDocumentId
    globalId: globalDocumentId
    contact {
      value {
        id
        name {
          value
        }
      }
    }
    note {
      value
    }
    documentFile {
      id
      name
    }
    invoiceNumber {
      value
    }
    createdAt
    status
    tags {
      ...Tag
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useGetDocumentForManageRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetDocumentForManageRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentForManageRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentForManageRelationshipsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentForManageRelationshipsQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentForManageRelationshipsQuery, GetDocumentForManageRelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentForManageRelationshipsQuery, GetDocumentForManageRelationshipsQueryVariables>(GetDocumentForManageRelationshipsDocument, options);
      }
export function useGetDocumentForManageRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentForManageRelationshipsQuery, GetDocumentForManageRelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentForManageRelationshipsQuery, GetDocumentForManageRelationshipsQueryVariables>(GetDocumentForManageRelationshipsDocument, options);
        }
export type GetDocumentForManageRelationshipsQueryHookResult = ReturnType<typeof useGetDocumentForManageRelationshipsQuery>;
export type GetDocumentForManageRelationshipsLazyQueryHookResult = ReturnType<typeof useGetDocumentForManageRelationshipsLazyQuery>;
export type GetDocumentForManageRelationshipsQueryResult = Apollo.QueryResult<GetDocumentForManageRelationshipsQuery, GetDocumentForManageRelationshipsQueryVariables>;
export const GetEcmDocumentForManageRelationshipsDocument = gql`
    query getEcmDocumentForManageRelationships($id: String!) {
  getAggregatedEcmDocument(id: $id) {
    id
    globalDocumentId
    type
    documentSubCategory {
      id
      name
    }
    contactName
    notes
    documentName
    documentNumber
    documentDate
    documentStatus
    isSensitive
    createdAt
    tags {
      ...Tag
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useGetEcmDocumentForManageRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetEcmDocumentForManageRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcmDocumentForManageRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcmDocumentForManageRelationshipsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEcmDocumentForManageRelationshipsQuery(baseOptions: Apollo.QueryHookOptions<GetEcmDocumentForManageRelationshipsQuery, GetEcmDocumentForManageRelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcmDocumentForManageRelationshipsQuery, GetEcmDocumentForManageRelationshipsQueryVariables>(GetEcmDocumentForManageRelationshipsDocument, options);
      }
export function useGetEcmDocumentForManageRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcmDocumentForManageRelationshipsQuery, GetEcmDocumentForManageRelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcmDocumentForManageRelationshipsQuery, GetEcmDocumentForManageRelationshipsQueryVariables>(GetEcmDocumentForManageRelationshipsDocument, options);
        }
export type GetEcmDocumentForManageRelationshipsQueryHookResult = ReturnType<typeof useGetEcmDocumentForManageRelationshipsQuery>;
export type GetEcmDocumentForManageRelationshipsLazyQueryHookResult = ReturnType<typeof useGetEcmDocumentForManageRelationshipsLazyQuery>;
export type GetEcmDocumentForManageRelationshipsQueryResult = Apollo.QueryResult<GetEcmDocumentForManageRelationshipsQuery, GetEcmDocumentForManageRelationshipsQueryVariables>;
export const PayableDocumentsPaginationDocument = gql`
    query payableDocumentsPagination($limit: Int, $after: String, $sort: SortInput) {
  payableDocumentsPagination(limit: $limit, after: $after, sort: $sort) {
    edges {
      cursor
      node {
        ...PayableDocumentData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${PayableDocumentDataFragmentDoc}
${MoneyFieldsFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __usePayableDocumentsPaginationQuery__
 *
 * To run a query within a React component, call `usePayableDocumentsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayableDocumentsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayableDocumentsPaginationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePayableDocumentsPaginationQuery(baseOptions?: Apollo.QueryHookOptions<PayableDocumentsPaginationQuery, PayableDocumentsPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayableDocumentsPaginationQuery, PayableDocumentsPaginationQueryVariables>(PayableDocumentsPaginationDocument, options);
      }
export function usePayableDocumentsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayableDocumentsPaginationQuery, PayableDocumentsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayableDocumentsPaginationQuery, PayableDocumentsPaginationQueryVariables>(PayableDocumentsPaginationDocument, options);
        }
export type PayableDocumentsPaginationQueryHookResult = ReturnType<typeof usePayableDocumentsPaginationQuery>;
export type PayableDocumentsPaginationLazyQueryHookResult = ReturnType<typeof usePayableDocumentsPaginationLazyQuery>;
export type PayableDocumentsPaginationQueryResult = Apollo.QueryResult<PayableDocumentsPaginationQuery, PayableDocumentsPaginationQueryVariables>;
export const PaymentsHistoryDocument = gql`
    query paymentsHistory($before: String, $limit: Int, $after: String) {
  payments(before: $before, limit: $limit, after: $after) @connection(key: "paymentsHistory") {
    records {
      __typename
      ...SepaTransferPaymentHistoryData
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${SepaTransferPaymentHistoryDataFragmentDoc}
${MoneyFieldsFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __usePaymentsHistoryQuery__
 *
 * To run a query within a React component, call `usePaymentsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsHistoryQuery({
 *   variables: {
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaymentsHistoryQuery(baseOptions?: Apollo.QueryHookOptions<PaymentsHistoryQuery, PaymentsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsHistoryQuery, PaymentsHistoryQueryVariables>(PaymentsHistoryDocument, options);
      }
export function usePaymentsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsHistoryQuery, PaymentsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsHistoryQuery, PaymentsHistoryQueryVariables>(PaymentsHistoryDocument, options);
        }
export type PaymentsHistoryQueryHookResult = ReturnType<typeof usePaymentsHistoryQuery>;
export type PaymentsHistoryLazyQueryHookResult = ReturnType<typeof usePaymentsHistoryLazyQuery>;
export type PaymentsHistoryQueryResult = Apollo.QueryResult<PaymentsHistoryQuery, PaymentsHistoryQueryVariables>;
export const PaymentDocument = gql`
    query payment($id: ID!) {
  payment(id: $id) {
    __typename
    ...SepaTransferPaymentData
  }
}
    ${SepaTransferPaymentDataFragmentDoc}
${PaidDocumentDataFragmentDoc}
${MoneyFieldsFragmentDoc}`;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentQuery(baseOptions: Apollo.QueryHookOptions<PaymentQuery, PaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentQuery, PaymentQueryVariables>(PaymentDocument, options);
      }
export function usePaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentQuery, PaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentQuery, PaymentQueryVariables>(PaymentDocument, options);
        }
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentQueryResult = Apollo.QueryResult<PaymentQuery, PaymentQueryVariables>;
export const SepaTransferXmlDocument = gql`
    query sepaTransferXml($paymentId: ID!) {
  sepaTransferXml(paymentId: $paymentId) {
    __typename
    ... on SepaXml {
      currency
      fileName
      content
    }
    ... on SepaXmlValidationError {
      message
    }
  }
}
    `;

/**
 * __useSepaTransferXmlQuery__
 *
 * To run a query within a React component, call `useSepaTransferXmlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSepaTransferXmlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSepaTransferXmlQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useSepaTransferXmlQuery(baseOptions: Apollo.QueryHookOptions<SepaTransferXmlQuery, SepaTransferXmlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SepaTransferXmlQuery, SepaTransferXmlQueryVariables>(SepaTransferXmlDocument, options);
      }
export function useSepaTransferXmlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SepaTransferXmlQuery, SepaTransferXmlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SepaTransferXmlQuery, SepaTransferXmlQueryVariables>(SepaTransferXmlDocument, options);
        }
export type SepaTransferXmlQueryHookResult = ReturnType<typeof useSepaTransferXmlQuery>;
export type SepaTransferXmlLazyQueryHookResult = ReturnType<typeof useSepaTransferXmlLazyQuery>;
export type SepaTransferXmlQueryResult = Apollo.QueryResult<SepaTransferXmlQuery, SepaTransferXmlQueryVariables>;
export const GenerateSepaTransferXmlDocument = gql`
    mutation generateSepaTransferXml($input: SepaXmlInput!) {
  generateSepaTransferXml(input: $input) {
    ... on SepaXml {
      currency
      fileName
      content
    }
    ... on SepaXmlValidationError {
      message
    }
  }
}
    `;
export type GenerateSepaTransferXmlMutationFn = Apollo.MutationFunction<GenerateSepaTransferXmlMutation, GenerateSepaTransferXmlMutationVariables>;

/**
 * __useGenerateSepaTransferXmlMutation__
 *
 * To run a mutation, you first call `useGenerateSepaTransferXmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSepaTransferXmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSepaTransferXmlMutation, { data, loading, error }] = useGenerateSepaTransferXmlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSepaTransferXmlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSepaTransferXmlMutation, GenerateSepaTransferXmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSepaTransferXmlMutation, GenerateSepaTransferXmlMutationVariables>(GenerateSepaTransferXmlDocument, options);
      }
export type GenerateSepaTransferXmlMutationHookResult = ReturnType<typeof useGenerateSepaTransferXmlMutation>;
export type GenerateSepaTransferXmlMutationResult = Apollo.MutationResult<GenerateSepaTransferXmlMutation>;
export type GenerateSepaTransferXmlMutationOptions = Apollo.BaseMutationOptions<GenerateSepaTransferXmlMutation, GenerateSepaTransferXmlMutationVariables>;
export const ActivatePaymentDocument = gql`
    mutation activatePayment($startDate: Date!) {
  activatePayment(startDate: $startDate)
}
    `;
export type ActivatePaymentMutationFn = Apollo.MutationFunction<ActivatePaymentMutation, ActivatePaymentMutationVariables>;

/**
 * __useActivatePaymentMutation__
 *
 * To run a mutation, you first call `useActivatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activatePaymentMutation, { data, loading, error }] = useActivatePaymentMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useActivatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<ActivatePaymentMutation, ActivatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivatePaymentMutation, ActivatePaymentMutationVariables>(ActivatePaymentDocument, options);
      }
export type ActivatePaymentMutationHookResult = ReturnType<typeof useActivatePaymentMutation>;
export type ActivatePaymentMutationResult = Apollo.MutationResult<ActivatePaymentMutation>;
export type ActivatePaymentMutationOptions = Apollo.BaseMutationOptions<ActivatePaymentMutation, ActivatePaymentMutationVariables>;
export const DeactivatePaymentDocument = gql`
    mutation deactivatePayment {
  deactivatePayment
}
    `;
export type DeactivatePaymentMutationFn = Apollo.MutationFunction<DeactivatePaymentMutation, DeactivatePaymentMutationVariables>;

/**
 * __useDeactivatePaymentMutation__
 *
 * To run a mutation, you first call `useDeactivatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePaymentMutation, { data, loading, error }] = useDeactivatePaymentMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeactivatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<DeactivatePaymentMutation, DeactivatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivatePaymentMutation, DeactivatePaymentMutationVariables>(DeactivatePaymentDocument, options);
      }
export type DeactivatePaymentMutationHookResult = ReturnType<typeof useDeactivatePaymentMutation>;
export type DeactivatePaymentMutationResult = Apollo.MutationResult<DeactivatePaymentMutation>;
export type DeactivatePaymentMutationOptions = Apollo.BaseMutationOptions<DeactivatePaymentMutation, DeactivatePaymentMutationVariables>;
export const UpdatePaymentStartDateDocument = gql`
    mutation updatePaymentStartDate($startDate: Date!) {
  updatePaymentStartDate(startDate: $startDate)
}
    `;
export type UpdatePaymentStartDateMutationFn = Apollo.MutationFunction<UpdatePaymentStartDateMutation, UpdatePaymentStartDateMutationVariables>;

/**
 * __useUpdatePaymentStartDateMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentStartDateMutation, { data, loading, error }] = useUpdatePaymentStartDateMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useUpdatePaymentStartDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentStartDateMutation, UpdatePaymentStartDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentStartDateMutation, UpdatePaymentStartDateMutationVariables>(UpdatePaymentStartDateDocument, options);
      }
export type UpdatePaymentStartDateMutationHookResult = ReturnType<typeof useUpdatePaymentStartDateMutation>;
export type UpdatePaymentStartDateMutationResult = Apollo.MutationResult<UpdatePaymentStartDateMutation>;
export type UpdatePaymentStartDateMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentStartDateMutation, UpdatePaymentStartDateMutationVariables>;
export const UpdateExportConfigurationDocument = gql`
    mutation updateExportConfiguration($datevSettings: DatevSettingsInput!) {
  updateExportConfiguration(datevSettings: $datevSettings)
}
    `;
export type UpdateExportConfigurationMutationFn = Apollo.MutationFunction<UpdateExportConfigurationMutation, UpdateExportConfigurationMutationVariables>;

/**
 * __useUpdateExportConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateExportConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExportConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExportConfigurationMutation, { data, loading, error }] = useUpdateExportConfigurationMutation({
 *   variables: {
 *      datevSettings: // value for 'datevSettings'
 *   },
 * });
 */
export function useUpdateExportConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExportConfigurationMutation, UpdateExportConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExportConfigurationMutation, UpdateExportConfigurationMutationVariables>(UpdateExportConfigurationDocument, options);
      }
export type UpdateExportConfigurationMutationHookResult = ReturnType<typeof useUpdateExportConfigurationMutation>;
export type UpdateExportConfigurationMutationResult = Apollo.MutationResult<UpdateExportConfigurationMutation>;
export type UpdateExportConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateExportConfigurationMutation, UpdateExportConfigurationMutationVariables>;
export const GetPayableDocumentsInfoDocument = gql`
    query getPayableDocumentsInfo($hasIban: Boolean) {
  getPayableDocumentsInfo(hasIban: $hasIban) {
    euValidIbanDocumentsCount
    gbValidIbanDocumentsCount
  }
}
    `;

/**
 * __useGetPayableDocumentsInfoQuery__
 *
 * To run a query within a React component, call `useGetPayableDocumentsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayableDocumentsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayableDocumentsInfoQuery({
 *   variables: {
 *      hasIban: // value for 'hasIban'
 *   },
 * });
 */
export function useGetPayableDocumentsInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetPayableDocumentsInfoQuery, GetPayableDocumentsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayableDocumentsInfoQuery, GetPayableDocumentsInfoQueryVariables>(GetPayableDocumentsInfoDocument, options);
      }
export function useGetPayableDocumentsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayableDocumentsInfoQuery, GetPayableDocumentsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayableDocumentsInfoQuery, GetPayableDocumentsInfoQueryVariables>(GetPayableDocumentsInfoDocument, options);
        }
export type GetPayableDocumentsInfoQueryHookResult = ReturnType<typeof useGetPayableDocumentsInfoQuery>;
export type GetPayableDocumentsInfoLazyQueryHookResult = ReturnType<typeof useGetPayableDocumentsInfoLazyQuery>;
export type GetPayableDocumentsInfoQueryResult = Apollo.QueryResult<GetPayableDocumentsInfoQuery, GetPayableDocumentsInfoQueryVariables>;
export const GetPayableAmountsDocument = gql`
    query getPayableAmounts {
  getPayableAmounts {
    totalPayableAmount {
      ...MoneyFields
    }
    discountedAmount {
      ...MoneyFields
    }
  }
}
    ${MoneyFieldsFragmentDoc}`;

/**
 * __useGetPayableAmountsQuery__
 *
 * To run a query within a React component, call `useGetPayableAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayableAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayableAmountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayableAmountsQuery(baseOptions?: Apollo.QueryHookOptions<GetPayableAmountsQuery, GetPayableAmountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayableAmountsQuery, GetPayableAmountsQueryVariables>(GetPayableAmountsDocument, options);
      }
export function useGetPayableAmountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayableAmountsQuery, GetPayableAmountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayableAmountsQuery, GetPayableAmountsQueryVariables>(GetPayableAmountsDocument, options);
        }
export type GetPayableAmountsQueryHookResult = ReturnType<typeof useGetPayableAmountsQuery>;
export type GetPayableAmountsLazyQueryHookResult = ReturnType<typeof useGetPayableAmountsLazyQuery>;
export type GetPayableAmountsQueryResult = Apollo.QueryResult<GetPayableAmountsQuery, GetPayableAmountsQueryVariables>;
export const PayableDocumentsEsDocument = gql`
    query payableDocumentsEs($limit: Int, $after: String, $sort: PayableDocumentsSortInput) {
  payableDocumentsEs(limit: $limit, after: $after, sort: $sort) {
    edges {
      cursor
      node {
        ...PayableDocumentNewEsData
      }
      __typename
    }
    pageInfo {
      startCursor
      endCursor
      totalCount
      hasNextPage
    }
    __typename
  }
}
    ${PayableDocumentNewEsDataFragmentDoc}`;

/**
 * __usePayableDocumentsEsQuery__
 *
 * To run a query within a React component, call `usePayableDocumentsEsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayableDocumentsEsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayableDocumentsEsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePayableDocumentsEsQuery(baseOptions?: Apollo.QueryHookOptions<PayableDocumentsEsQuery, PayableDocumentsEsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayableDocumentsEsQuery, PayableDocumentsEsQueryVariables>(PayableDocumentsEsDocument, options);
      }
export function usePayableDocumentsEsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayableDocumentsEsQuery, PayableDocumentsEsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayableDocumentsEsQuery, PayableDocumentsEsQueryVariables>(PayableDocumentsEsDocument, options);
        }
export type PayableDocumentsEsQueryHookResult = ReturnType<typeof usePayableDocumentsEsQuery>;
export type PayableDocumentsEsLazyQueryHookResult = ReturnType<typeof usePayableDocumentsEsLazyQuery>;
export type PayableDocumentsEsQueryResult = Apollo.QueryResult<PayableDocumentsEsQuery, PayableDocumentsEsQueryVariables>;
export const CreateReimbursementCaseDocument = gql`
    mutation createReimbursementCase($input: CreateReimbursementCaseInput!) {
  createReimbursementCase(input: $input) {
    id
  }
}
    `;
export type CreateReimbursementCaseMutationFn = Apollo.MutationFunction<CreateReimbursementCaseMutation, CreateReimbursementCaseMutationVariables>;

/**
 * __useCreateReimbursementCaseMutation__
 *
 * To run a mutation, you first call `useCreateReimbursementCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReimbursementCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReimbursementCaseMutation, { data, loading, error }] = useCreateReimbursementCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReimbursementCaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateReimbursementCaseMutation, CreateReimbursementCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReimbursementCaseMutation, CreateReimbursementCaseMutationVariables>(CreateReimbursementCaseDocument, options);
      }
export type CreateReimbursementCaseMutationHookResult = ReturnType<typeof useCreateReimbursementCaseMutation>;
export type CreateReimbursementCaseMutationResult = Apollo.MutationResult<CreateReimbursementCaseMutation>;
export type CreateReimbursementCaseMutationOptions = Apollo.BaseMutationOptions<CreateReimbursementCaseMutation, CreateReimbursementCaseMutationVariables>;
export const UpdateReimbursementCaseDocument = gql`
    mutation updateReimbursementCase($id: ID!, $input: UpdateReimbursementCaseInput!) {
  updateReimbursementCase(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateReimbursementCaseMutationFn = Apollo.MutationFunction<UpdateReimbursementCaseMutation, UpdateReimbursementCaseMutationVariables>;

/**
 * __useUpdateReimbursementCaseMutation__
 *
 * To run a mutation, you first call `useUpdateReimbursementCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReimbursementCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReimbursementCaseMutation, { data, loading, error }] = useUpdateReimbursementCaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReimbursementCaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReimbursementCaseMutation, UpdateReimbursementCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReimbursementCaseMutation, UpdateReimbursementCaseMutationVariables>(UpdateReimbursementCaseDocument, options);
      }
export type UpdateReimbursementCaseMutationHookResult = ReturnType<typeof useUpdateReimbursementCaseMutation>;
export type UpdateReimbursementCaseMutationResult = Apollo.MutationResult<UpdateReimbursementCaseMutation>;
export type UpdateReimbursementCaseMutationOptions = Apollo.BaseMutationOptions<UpdateReimbursementCaseMutation, UpdateReimbursementCaseMutationVariables>;
export const ArchiveOrDeleteReimbursementCaseDocument = gql`
    mutation archiveOrDeleteReimbursementCase($id: ID!) {
  archiveOrDeleteReimbursementCase(id: $id) {
    deleted
    archived
  }
}
    `;
export type ArchiveOrDeleteReimbursementCaseMutationFn = Apollo.MutationFunction<ArchiveOrDeleteReimbursementCaseMutation, ArchiveOrDeleteReimbursementCaseMutationVariables>;

/**
 * __useArchiveOrDeleteReimbursementCaseMutation__
 *
 * To run a mutation, you first call `useArchiveOrDeleteReimbursementCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrDeleteReimbursementCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrDeleteReimbursementCaseMutation, { data, loading, error }] = useArchiveOrDeleteReimbursementCaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveOrDeleteReimbursementCaseMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveOrDeleteReimbursementCaseMutation, ArchiveOrDeleteReimbursementCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveOrDeleteReimbursementCaseMutation, ArchiveOrDeleteReimbursementCaseMutationVariables>(ArchiveOrDeleteReimbursementCaseDocument, options);
      }
export type ArchiveOrDeleteReimbursementCaseMutationHookResult = ReturnType<typeof useArchiveOrDeleteReimbursementCaseMutation>;
export type ArchiveOrDeleteReimbursementCaseMutationResult = Apollo.MutationResult<ArchiveOrDeleteReimbursementCaseMutation>;
export type ArchiveOrDeleteReimbursementCaseMutationOptions = Apollo.BaseMutationOptions<ArchiveOrDeleteReimbursementCaseMutation, ArchiveOrDeleteReimbursementCaseMutationVariables>;
export const GetFileUploadUrlDocument = gql`
    mutation getFileUploadUrl($params: FileUploadParams!) {
  getFileUploadUrl(params: $params) {
    id
    url
    file {
      id
      name
      url
    }
    postOptions {
      field
      value
    }
  }
}
    `;
export type GetFileUploadUrlMutationFn = Apollo.MutationFunction<GetFileUploadUrlMutation, GetFileUploadUrlMutationVariables>;

/**
 * __useGetFileUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetFileUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFileUploadUrlMutation, { data, loading, error }] = useGetFileUploadUrlMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetFileUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetFileUploadUrlMutation, GetFileUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetFileUploadUrlMutation, GetFileUploadUrlMutationVariables>(GetFileUploadUrlDocument, options);
      }
export type GetFileUploadUrlMutationHookResult = ReturnType<typeof useGetFileUploadUrlMutation>;
export type GetFileUploadUrlMutationResult = Apollo.MutationResult<GetFileUploadUrlMutation>;
export type GetFileUploadUrlMutationOptions = Apollo.BaseMutationOptions<GetFileUploadUrlMutation, GetFileUploadUrlMutationVariables>;
export const CreateHospitalityExpenseItemDocument = gql`
    mutation createHospitalityExpenseItem($input: HospitalityExpenseItemInput!) {
  createHospitalityExpenseItem(input: $input) {
    id
  }
}
    `;
export type CreateHospitalityExpenseItemMutationFn = Apollo.MutationFunction<CreateHospitalityExpenseItemMutation, CreateHospitalityExpenseItemMutationVariables>;

/**
 * __useCreateHospitalityExpenseItemMutation__
 *
 * To run a mutation, you first call `useCreateHospitalityExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHospitalityExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHospitalityExpenseItemMutation, { data, loading, error }] = useCreateHospitalityExpenseItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHospitalityExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateHospitalityExpenseItemMutation, CreateHospitalityExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHospitalityExpenseItemMutation, CreateHospitalityExpenseItemMutationVariables>(CreateHospitalityExpenseItemDocument, options);
      }
export type CreateHospitalityExpenseItemMutationHookResult = ReturnType<typeof useCreateHospitalityExpenseItemMutation>;
export type CreateHospitalityExpenseItemMutationResult = Apollo.MutationResult<CreateHospitalityExpenseItemMutation>;
export type CreateHospitalityExpenseItemMutationOptions = Apollo.BaseMutationOptions<CreateHospitalityExpenseItemMutation, CreateHospitalityExpenseItemMutationVariables>;
export const UpdateHospitalityExpenseItemDocument = gql`
    mutation updateHospitalityExpenseItem($id: ID!, $input: HospitalityExpenseItemInput!) {
  updateHospitalityExpenseItem(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateHospitalityExpenseItemMutationFn = Apollo.MutationFunction<UpdateHospitalityExpenseItemMutation, UpdateHospitalityExpenseItemMutationVariables>;

/**
 * __useUpdateHospitalityExpenseItemMutation__
 *
 * To run a mutation, you first call `useUpdateHospitalityExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHospitalityExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHospitalityExpenseItemMutation, { data, loading, error }] = useUpdateHospitalityExpenseItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHospitalityExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHospitalityExpenseItemMutation, UpdateHospitalityExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHospitalityExpenseItemMutation, UpdateHospitalityExpenseItemMutationVariables>(UpdateHospitalityExpenseItemDocument, options);
      }
export type UpdateHospitalityExpenseItemMutationHookResult = ReturnType<typeof useUpdateHospitalityExpenseItemMutation>;
export type UpdateHospitalityExpenseItemMutationResult = Apollo.MutationResult<UpdateHospitalityExpenseItemMutation>;
export type UpdateHospitalityExpenseItemMutationOptions = Apollo.BaseMutationOptions<UpdateHospitalityExpenseItemMutation, UpdateHospitalityExpenseItemMutationVariables>;
export const ExtractHospitalityExpenseInvoiceDataDocument = gql`
    mutation extractHospitalityExpenseInvoiceData($id: ID!, $input: HospitalityExpenseItemInput!) {
  extractHospitalityExpenseInvoiceData: updateHospitalityExpenseItem(
    id: $id
    input: $input
  ) {
    id
    extractedDataAccepted
    extractedData {
      invoiceNumber
      expenseDate
      location
      receiptAmount {
        amount
        currency
        precision
      }
      tipAmount {
        amount
        currency
        precision
      }
    }
  }
}
    `;
export type ExtractHospitalityExpenseInvoiceDataMutationFn = Apollo.MutationFunction<ExtractHospitalityExpenseInvoiceDataMutation, ExtractHospitalityExpenseInvoiceDataMutationVariables>;

/**
 * __useExtractHospitalityExpenseInvoiceDataMutation__
 *
 * To run a mutation, you first call `useExtractHospitalityExpenseInvoiceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractHospitalityExpenseInvoiceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractHospitalityExpenseInvoiceDataMutation, { data, loading, error }] = useExtractHospitalityExpenseInvoiceDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtractHospitalityExpenseInvoiceDataMutation(baseOptions?: Apollo.MutationHookOptions<ExtractHospitalityExpenseInvoiceDataMutation, ExtractHospitalityExpenseInvoiceDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtractHospitalityExpenseInvoiceDataMutation, ExtractHospitalityExpenseInvoiceDataMutationVariables>(ExtractHospitalityExpenseInvoiceDataDocument, options);
      }
export type ExtractHospitalityExpenseInvoiceDataMutationHookResult = ReturnType<typeof useExtractHospitalityExpenseInvoiceDataMutation>;
export type ExtractHospitalityExpenseInvoiceDataMutationResult = Apollo.MutationResult<ExtractHospitalityExpenseInvoiceDataMutation>;
export type ExtractHospitalityExpenseInvoiceDataMutationOptions = Apollo.BaseMutationOptions<ExtractHospitalityExpenseInvoiceDataMutation, ExtractHospitalityExpenseInvoiceDataMutationVariables>;
export const UpdateGeneralExpenseItemDocument = gql`
    mutation updateGeneralExpenseItem($id: ID!, $input: GeneralExpenseItemInput!) {
  updateGeneralExpenseItem(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateGeneralExpenseItemMutationFn = Apollo.MutationFunction<UpdateGeneralExpenseItemMutation, UpdateGeneralExpenseItemMutationVariables>;

/**
 * __useUpdateGeneralExpenseItemMutation__
 *
 * To run a mutation, you first call `useUpdateGeneralExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralExpenseItemMutation, { data, loading, error }] = useUpdateGeneralExpenseItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGeneralExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGeneralExpenseItemMutation, UpdateGeneralExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGeneralExpenseItemMutation, UpdateGeneralExpenseItemMutationVariables>(UpdateGeneralExpenseItemDocument, options);
      }
export type UpdateGeneralExpenseItemMutationHookResult = ReturnType<typeof useUpdateGeneralExpenseItemMutation>;
export type UpdateGeneralExpenseItemMutationResult = Apollo.MutationResult<UpdateGeneralExpenseItemMutation>;
export type UpdateGeneralExpenseItemMutationOptions = Apollo.BaseMutationOptions<UpdateGeneralExpenseItemMutation, UpdateGeneralExpenseItemMutationVariables>;
export const ExtractGeneralExpenseInvoiceDataDocument = gql`
    mutation extractGeneralExpenseInvoiceData($id: ID!, $input: GeneralExpenseItemInput!) {
  extractGeneralExpenseInvoiceData: updateGeneralExpenseItem(
    id: $id
    input: $input
  ) {
    id
    extractedDataAccepted
    extractedData {
      invoiceNumber
      expenseDate
      totalAmount {
        amount
        currency
        precision
      }
    }
  }
}
    `;
export type ExtractGeneralExpenseInvoiceDataMutationFn = Apollo.MutationFunction<ExtractGeneralExpenseInvoiceDataMutation, ExtractGeneralExpenseInvoiceDataMutationVariables>;

/**
 * __useExtractGeneralExpenseInvoiceDataMutation__
 *
 * To run a mutation, you first call `useExtractGeneralExpenseInvoiceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractGeneralExpenseInvoiceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractGeneralExpenseInvoiceDataMutation, { data, loading, error }] = useExtractGeneralExpenseInvoiceDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtractGeneralExpenseInvoiceDataMutation(baseOptions?: Apollo.MutationHookOptions<ExtractGeneralExpenseInvoiceDataMutation, ExtractGeneralExpenseInvoiceDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtractGeneralExpenseInvoiceDataMutation, ExtractGeneralExpenseInvoiceDataMutationVariables>(ExtractGeneralExpenseInvoiceDataDocument, options);
      }
export type ExtractGeneralExpenseInvoiceDataMutationHookResult = ReturnType<typeof useExtractGeneralExpenseInvoiceDataMutation>;
export type ExtractGeneralExpenseInvoiceDataMutationResult = Apollo.MutationResult<ExtractGeneralExpenseInvoiceDataMutation>;
export type ExtractGeneralExpenseInvoiceDataMutationOptions = Apollo.BaseMutationOptions<ExtractGeneralExpenseInvoiceDataMutation, ExtractGeneralExpenseInvoiceDataMutationVariables>;
export const DeleteReimbursementItemDocument = gql`
    mutation deleteReimbursementItem($id: ID!) {
  deleteReimbursementItem(id: $id) {
    deleted
  }
}
    `;
export type DeleteReimbursementItemMutationFn = Apollo.MutationFunction<DeleteReimbursementItemMutation, DeleteReimbursementItemMutationVariables>;

/**
 * __useDeleteReimbursementItemMutation__
 *
 * To run a mutation, you first call `useDeleteReimbursementItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReimbursementItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReimbursementItemMutation, { data, loading, error }] = useDeleteReimbursementItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReimbursementItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReimbursementItemMutation, DeleteReimbursementItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReimbursementItemMutation, DeleteReimbursementItemMutationVariables>(DeleteReimbursementItemDocument, options);
      }
export type DeleteReimbursementItemMutationHookResult = ReturnType<typeof useDeleteReimbursementItemMutation>;
export type DeleteReimbursementItemMutationResult = Apollo.MutationResult<DeleteReimbursementItemMutation>;
export type DeleteReimbursementItemMutationOptions = Apollo.BaseMutationOptions<DeleteReimbursementItemMutation, DeleteReimbursementItemMutationVariables>;
export const CreateGeneralExpenseItemDocument = gql`
    mutation createGeneralExpenseItem($input: GeneralExpenseItemInput!) {
  createGeneralExpenseItem(input: $input) {
    id
  }
}
    `;
export type CreateGeneralExpenseItemMutationFn = Apollo.MutationFunction<CreateGeneralExpenseItemMutation, CreateGeneralExpenseItemMutationVariables>;

/**
 * __useCreateGeneralExpenseItemMutation__
 *
 * To run a mutation, you first call `useCreateGeneralExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralExpenseItemMutation, { data, loading, error }] = useCreateGeneralExpenseItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGeneralExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateGeneralExpenseItemMutation, CreateGeneralExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGeneralExpenseItemMutation, CreateGeneralExpenseItemMutationVariables>(CreateGeneralExpenseItemDocument, options);
      }
export type CreateGeneralExpenseItemMutationHookResult = ReturnType<typeof useCreateGeneralExpenseItemMutation>;
export type CreateGeneralExpenseItemMutationResult = Apollo.MutationResult<CreateGeneralExpenseItemMutation>;
export type CreateGeneralExpenseItemMutationOptions = Apollo.BaseMutationOptions<CreateGeneralExpenseItemMutation, CreateGeneralExpenseItemMutationVariables>;
export const SubmitReimbursementCaseForReviewDocument = gql`
    mutation submitReimbursementCaseForReview($id: ID!) {
  submitReimbursementCaseForReview(id: $id) {
    success
    errors {
      reimbursementCaseErrors {
        id
        title
        errors {
          errors
          field
        }
      }
      reimbursementItemsErrors {
        id
        title
        type
        errors {
          errors
          field
        }
      }
    }
  }
}
    `;
export type SubmitReimbursementCaseForReviewMutationFn = Apollo.MutationFunction<SubmitReimbursementCaseForReviewMutation, SubmitReimbursementCaseForReviewMutationVariables>;

/**
 * __useSubmitReimbursementCaseForReviewMutation__
 *
 * To run a mutation, you first call `useSubmitReimbursementCaseForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReimbursementCaseForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReimbursementCaseForReviewMutation, { data, loading, error }] = useSubmitReimbursementCaseForReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitReimbursementCaseForReviewMutation(baseOptions?: Apollo.MutationHookOptions<SubmitReimbursementCaseForReviewMutation, SubmitReimbursementCaseForReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitReimbursementCaseForReviewMutation, SubmitReimbursementCaseForReviewMutationVariables>(SubmitReimbursementCaseForReviewDocument, options);
      }
export type SubmitReimbursementCaseForReviewMutationHookResult = ReturnType<typeof useSubmitReimbursementCaseForReviewMutation>;
export type SubmitReimbursementCaseForReviewMutationResult = Apollo.MutationResult<SubmitReimbursementCaseForReviewMutation>;
export type SubmitReimbursementCaseForReviewMutationOptions = Apollo.BaseMutationOptions<SubmitReimbursementCaseForReviewMutation, SubmitReimbursementCaseForReviewMutationVariables>;
export const FastApproveReimbursementCaseDocument = gql`
    mutation fastApproveReimbursementCase($id: ID!) {
  fastApproveReimbursementCase(id: $id) {
    success
    errors {
      reimbursementCaseErrors {
        id
        title
        errors {
          errors
          field
        }
      }
      reimbursementItemsErrors {
        id
        title
        type
        errors {
          errors
          field
        }
      }
    }
  }
}
    `;
export type FastApproveReimbursementCaseMutationFn = Apollo.MutationFunction<FastApproveReimbursementCaseMutation, FastApproveReimbursementCaseMutationVariables>;

/**
 * __useFastApproveReimbursementCaseMutation__
 *
 * To run a mutation, you first call `useFastApproveReimbursementCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastApproveReimbursementCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastApproveReimbursementCaseMutation, { data, loading, error }] = useFastApproveReimbursementCaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFastApproveReimbursementCaseMutation(baseOptions?: Apollo.MutationHookOptions<FastApproveReimbursementCaseMutation, FastApproveReimbursementCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastApproveReimbursementCaseMutation, FastApproveReimbursementCaseMutationVariables>(FastApproveReimbursementCaseDocument, options);
      }
export type FastApproveReimbursementCaseMutationHookResult = ReturnType<typeof useFastApproveReimbursementCaseMutation>;
export type FastApproveReimbursementCaseMutationResult = Apollo.MutationResult<FastApproveReimbursementCaseMutation>;
export type FastApproveReimbursementCaseMutationOptions = Apollo.BaseMutationOptions<FastApproveReimbursementCaseMutation, FastApproveReimbursementCaseMutationVariables>;
export const RequestReimbursementCaseAmendmentDocument = gql`
    mutation requestReimbursementCaseAmendment($input: RequestReimbursementCaseAmendmentInput!) {
  requestReimbursementCaseAmendment(input: $input) {
    success
  }
}
    `;
export type RequestReimbursementCaseAmendmentMutationFn = Apollo.MutationFunction<RequestReimbursementCaseAmendmentMutation, RequestReimbursementCaseAmendmentMutationVariables>;

/**
 * __useRequestReimbursementCaseAmendmentMutation__
 *
 * To run a mutation, you first call `useRequestReimbursementCaseAmendmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestReimbursementCaseAmendmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestReimbursementCaseAmendmentMutation, { data, loading, error }] = useRequestReimbursementCaseAmendmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestReimbursementCaseAmendmentMutation(baseOptions?: Apollo.MutationHookOptions<RequestReimbursementCaseAmendmentMutation, RequestReimbursementCaseAmendmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestReimbursementCaseAmendmentMutation, RequestReimbursementCaseAmendmentMutationVariables>(RequestReimbursementCaseAmendmentDocument, options);
      }
export type RequestReimbursementCaseAmendmentMutationHookResult = ReturnType<typeof useRequestReimbursementCaseAmendmentMutation>;
export type RequestReimbursementCaseAmendmentMutationResult = Apollo.MutationResult<RequestReimbursementCaseAmendmentMutation>;
export type RequestReimbursementCaseAmendmentMutationOptions = Apollo.BaseMutationOptions<RequestReimbursementCaseAmendmentMutation, RequestReimbursementCaseAmendmentMutationVariables>;
export const CreateCommentOnReimbursementCaseDocument = gql`
    mutation createCommentOnReimbursementCase($input: CreateCommentInput!) {
  createCommentOnReimbursementCase(input: $input) {
    ... on CommentCreated {
      commentId
    }
    ... on CommentCreatedError {
      message
      kind
    }
  }
}
    `;
export type CreateCommentOnReimbursementCaseMutationFn = Apollo.MutationFunction<CreateCommentOnReimbursementCaseMutation, CreateCommentOnReimbursementCaseMutationVariables>;

/**
 * __useCreateCommentOnReimbursementCaseMutation__
 *
 * To run a mutation, you first call `useCreateCommentOnReimbursementCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentOnReimbursementCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentOnReimbursementCaseMutation, { data, loading, error }] = useCreateCommentOnReimbursementCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentOnReimbursementCaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentOnReimbursementCaseMutation, CreateCommentOnReimbursementCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentOnReimbursementCaseMutation, CreateCommentOnReimbursementCaseMutationVariables>(CreateCommentOnReimbursementCaseDocument, options);
      }
export type CreateCommentOnReimbursementCaseMutationHookResult = ReturnType<typeof useCreateCommentOnReimbursementCaseMutation>;
export type CreateCommentOnReimbursementCaseMutationResult = Apollo.MutationResult<CreateCommentOnReimbursementCaseMutation>;
export type CreateCommentOnReimbursementCaseMutationOptions = Apollo.BaseMutationOptions<CreateCommentOnReimbursementCaseMutation, CreateCommentOnReimbursementCaseMutationVariables>;
export const ResetReimbursementCaseApprovalDocument = gql`
    mutation resetReimbursementCaseApproval($id: ID!) {
  resetReimbursementCaseApproval(id: $id) {
    success
  }
}
    `;
export type ResetReimbursementCaseApprovalMutationFn = Apollo.MutationFunction<ResetReimbursementCaseApprovalMutation, ResetReimbursementCaseApprovalMutationVariables>;

/**
 * __useResetReimbursementCaseApprovalMutation__
 *
 * To run a mutation, you first call `useResetReimbursementCaseApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetReimbursementCaseApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetReimbursementCaseApprovalMutation, { data, loading, error }] = useResetReimbursementCaseApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetReimbursementCaseApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ResetReimbursementCaseApprovalMutation, ResetReimbursementCaseApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetReimbursementCaseApprovalMutation, ResetReimbursementCaseApprovalMutationVariables>(ResetReimbursementCaseApprovalDocument, options);
      }
export type ResetReimbursementCaseApprovalMutationHookResult = ReturnType<typeof useResetReimbursementCaseApprovalMutation>;
export type ResetReimbursementCaseApprovalMutationResult = Apollo.MutationResult<ResetReimbursementCaseApprovalMutation>;
export type ResetReimbursementCaseApprovalMutationOptions = Apollo.BaseMutationOptions<ResetReimbursementCaseApprovalMutation, ResetReimbursementCaseApprovalMutationVariables>;
export const SubmitReimbursementCaseForApprovalDocument = gql`
    mutation submitReimbursementCaseForApproval($input: SubmitReimbursementCaseForApprovalInput!) {
  submitReimbursementCaseForApproval(input: $input) {
    success
    errors {
      reimbursementCaseErrors {
        id
        title
        errors {
          errors
          field
        }
      }
      reimbursementItemsErrors {
        id
        title
        type
        errors {
          errors
          field
        }
      }
    }
  }
}
    `;
export type SubmitReimbursementCaseForApprovalMutationFn = Apollo.MutationFunction<SubmitReimbursementCaseForApprovalMutation, SubmitReimbursementCaseForApprovalMutationVariables>;

/**
 * __useSubmitReimbursementCaseForApprovalMutation__
 *
 * To run a mutation, you first call `useSubmitReimbursementCaseForApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReimbursementCaseForApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReimbursementCaseForApprovalMutation, { data, loading, error }] = useSubmitReimbursementCaseForApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitReimbursementCaseForApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SubmitReimbursementCaseForApprovalMutation, SubmitReimbursementCaseForApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitReimbursementCaseForApprovalMutation, SubmitReimbursementCaseForApprovalMutationVariables>(SubmitReimbursementCaseForApprovalDocument, options);
      }
export type SubmitReimbursementCaseForApprovalMutationHookResult = ReturnType<typeof useSubmitReimbursementCaseForApprovalMutation>;
export type SubmitReimbursementCaseForApprovalMutationResult = Apollo.MutationResult<SubmitReimbursementCaseForApprovalMutation>;
export type SubmitReimbursementCaseForApprovalMutationOptions = Apollo.BaseMutationOptions<SubmitReimbursementCaseForApprovalMutation, SubmitReimbursementCaseForApprovalMutationVariables>;
export const SubmitReimbursementCaseWorkflowStepDocument = gql`
    mutation submitReimbursementCaseWorkflowStep($input: SubmitReimbursementCaseWorkflowStepInput!) {
  submitReimbursementCaseWorkflowStep(input: $input) {
    success
    errors {
      reimbursementCaseErrors {
        id
        title
        errors {
          errors
          field
        }
      }
      reimbursementItemsErrors {
        id
        title
        type
        errors {
          errors
          field
        }
      }
    }
  }
}
    `;
export type SubmitReimbursementCaseWorkflowStepMutationFn = Apollo.MutationFunction<SubmitReimbursementCaseWorkflowStepMutation, SubmitReimbursementCaseWorkflowStepMutationVariables>;

/**
 * __useSubmitReimbursementCaseWorkflowStepMutation__
 *
 * To run a mutation, you first call `useSubmitReimbursementCaseWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReimbursementCaseWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReimbursementCaseWorkflowStepMutation, { data, loading, error }] = useSubmitReimbursementCaseWorkflowStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitReimbursementCaseWorkflowStepMutation(baseOptions?: Apollo.MutationHookOptions<SubmitReimbursementCaseWorkflowStepMutation, SubmitReimbursementCaseWorkflowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitReimbursementCaseWorkflowStepMutation, SubmitReimbursementCaseWorkflowStepMutationVariables>(SubmitReimbursementCaseWorkflowStepDocument, options);
      }
export type SubmitReimbursementCaseWorkflowStepMutationHookResult = ReturnType<typeof useSubmitReimbursementCaseWorkflowStepMutation>;
export type SubmitReimbursementCaseWorkflowStepMutationResult = Apollo.MutationResult<SubmitReimbursementCaseWorkflowStepMutation>;
export type SubmitReimbursementCaseWorkflowStepMutationOptions = Apollo.BaseMutationOptions<SubmitReimbursementCaseWorkflowStepMutation, SubmitReimbursementCaseWorkflowStepMutationVariables>;
export const CreatePerDiemItemDocument = gql`
    mutation createPerDiemItem($input: CreatePerDiemItemInput!) {
  createPerDiemItem(input: $input) {
    id
  }
}
    `;
export type CreatePerDiemItemMutationFn = Apollo.MutationFunction<CreatePerDiemItemMutation, CreatePerDiemItemMutationVariables>;

/**
 * __useCreatePerDiemItemMutation__
 *
 * To run a mutation, you first call `useCreatePerDiemItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerDiemItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerDiemItemMutation, { data, loading, error }] = useCreatePerDiemItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePerDiemItemMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerDiemItemMutation, CreatePerDiemItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerDiemItemMutation, CreatePerDiemItemMutationVariables>(CreatePerDiemItemDocument, options);
      }
export type CreatePerDiemItemMutationHookResult = ReturnType<typeof useCreatePerDiemItemMutation>;
export type CreatePerDiemItemMutationResult = Apollo.MutationResult<CreatePerDiemItemMutation>;
export type CreatePerDiemItemMutationOptions = Apollo.BaseMutationOptions<CreatePerDiemItemMutation, CreatePerDiemItemMutationVariables>;
export const UpdatePerDiemItemDocument = gql`
    mutation updatePerDiemItem($id: ID!, $input: UpdatePerDiemItemInput!) {
  updatePerDiemItem(id: $id, input: $input) {
    id
    days {
      id
      date
      providedBreakfast
      providedLunch
      providedDinner
    }
  }
}
    `;
export type UpdatePerDiemItemMutationFn = Apollo.MutationFunction<UpdatePerDiemItemMutation, UpdatePerDiemItemMutationVariables>;

/**
 * __useUpdatePerDiemItemMutation__
 *
 * To run a mutation, you first call `useUpdatePerDiemItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerDiemItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerDiemItemMutation, { data, loading, error }] = useUpdatePerDiemItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerDiemItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerDiemItemMutation, UpdatePerDiemItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerDiemItemMutation, UpdatePerDiemItemMutationVariables>(UpdatePerDiemItemDocument, options);
      }
export type UpdatePerDiemItemMutationHookResult = ReturnType<typeof useUpdatePerDiemItemMutation>;
export type UpdatePerDiemItemMutationResult = Apollo.MutationResult<UpdatePerDiemItemMutation>;
export type UpdatePerDiemItemMutationOptions = Apollo.BaseMutationOptions<UpdatePerDiemItemMutation, UpdatePerDiemItemMutationVariables>;
export const CreateMileageReimbursementItemDocument = gql`
    mutation createMileageReimbursementItem($input: MileageReimbursementItemInput!) {
  createMileageReimbursementItem(input: $input) {
    id
  }
}
    `;
export type CreateMileageReimbursementItemMutationFn = Apollo.MutationFunction<CreateMileageReimbursementItemMutation, CreateMileageReimbursementItemMutationVariables>;

/**
 * __useCreateMileageReimbursementItemMutation__
 *
 * To run a mutation, you first call `useCreateMileageReimbursementItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMileageReimbursementItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMileageReimbursementItemMutation, { data, loading, error }] = useCreateMileageReimbursementItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMileageReimbursementItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMileageReimbursementItemMutation, CreateMileageReimbursementItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMileageReimbursementItemMutation, CreateMileageReimbursementItemMutationVariables>(CreateMileageReimbursementItemDocument, options);
      }
export type CreateMileageReimbursementItemMutationHookResult = ReturnType<typeof useCreateMileageReimbursementItemMutation>;
export type CreateMileageReimbursementItemMutationResult = Apollo.MutationResult<CreateMileageReimbursementItemMutation>;
export type CreateMileageReimbursementItemMutationOptions = Apollo.BaseMutationOptions<CreateMileageReimbursementItemMutation, CreateMileageReimbursementItemMutationVariables>;
export const AddPerDiemSegmentDocument = gql`
    mutation addPerDiemSegment($id: ID!, $input: UpdatePerDiemItemInput!) {
  addPerDiemSegment: updatePerDiemItem(id: $id, input: $input) {
    id
  }
}
    `;
export type AddPerDiemSegmentMutationFn = Apollo.MutationFunction<AddPerDiemSegmentMutation, AddPerDiemSegmentMutationVariables>;

/**
 * __useAddPerDiemSegmentMutation__
 *
 * To run a mutation, you first call `useAddPerDiemSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPerDiemSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPerDiemSegmentMutation, { data, loading, error }] = useAddPerDiemSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPerDiemSegmentMutation(baseOptions?: Apollo.MutationHookOptions<AddPerDiemSegmentMutation, AddPerDiemSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPerDiemSegmentMutation, AddPerDiemSegmentMutationVariables>(AddPerDiemSegmentDocument, options);
      }
export type AddPerDiemSegmentMutationHookResult = ReturnType<typeof useAddPerDiemSegmentMutation>;
export type AddPerDiemSegmentMutationResult = Apollo.MutationResult<AddPerDiemSegmentMutation>;
export type AddPerDiemSegmentMutationOptions = Apollo.BaseMutationOptions<AddPerDiemSegmentMutation, AddPerDiemSegmentMutationVariables>;
export const EditPerDiemSegmentDocument = gql`
    mutation editPerDiemSegment($id: ID!, $input: UpdatePerDiemItemInput!) {
  editPerDiemSegment: updatePerDiemItem(id: $id, input: $input) {
    id
  }
}
    `;
export type EditPerDiemSegmentMutationFn = Apollo.MutationFunction<EditPerDiemSegmentMutation, EditPerDiemSegmentMutationVariables>;

/**
 * __useEditPerDiemSegmentMutation__
 *
 * To run a mutation, you first call `useEditPerDiemSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPerDiemSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPerDiemSegmentMutation, { data, loading, error }] = useEditPerDiemSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPerDiemSegmentMutation(baseOptions?: Apollo.MutationHookOptions<EditPerDiemSegmentMutation, EditPerDiemSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPerDiemSegmentMutation, EditPerDiemSegmentMutationVariables>(EditPerDiemSegmentDocument, options);
      }
export type EditPerDiemSegmentMutationHookResult = ReturnType<typeof useEditPerDiemSegmentMutation>;
export type EditPerDiemSegmentMutationResult = Apollo.MutationResult<EditPerDiemSegmentMutation>;
export type EditPerDiemSegmentMutationOptions = Apollo.BaseMutationOptions<EditPerDiemSegmentMutation, EditPerDiemSegmentMutationVariables>;
export const ReimbursementCaseByIdDocument = gql`
    query reimbursementCaseById($id: ID!) {
  reimbursementCaseById(id: $id) {
    ...ReimbursementCaseData
  }
}
    ${ReimbursementCaseDataFragmentDoc}`;

/**
 * __useReimbursementCaseByIdQuery__
 *
 * To run a query within a React component, call `useReimbursementCaseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementCaseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementCaseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReimbursementCaseByIdQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementCaseByIdQuery, ReimbursementCaseByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementCaseByIdQuery, ReimbursementCaseByIdQueryVariables>(ReimbursementCaseByIdDocument, options);
      }
export function useReimbursementCaseByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementCaseByIdQuery, ReimbursementCaseByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementCaseByIdQuery, ReimbursementCaseByIdQueryVariables>(ReimbursementCaseByIdDocument, options);
        }
export type ReimbursementCaseByIdQueryHookResult = ReturnType<typeof useReimbursementCaseByIdQuery>;
export type ReimbursementCaseByIdLazyQueryHookResult = ReturnType<typeof useReimbursementCaseByIdLazyQuery>;
export type ReimbursementCaseByIdQueryResult = Apollo.QueryResult<ReimbursementCaseByIdQuery, ReimbursementCaseByIdQueryVariables>;
export const ArchiveReimbursementCasesCountDocument = gql`
    query archiveReimbursementCasesCount($filters: ReimbursementCasesFilterInput) {
  archiveReimbursementCasesCount(filters: $filters) {
    totalCount
  }
}
    `;

/**
 * __useArchiveReimbursementCasesCountQuery__
 *
 * To run a query within a React component, call `useArchiveReimbursementCasesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveReimbursementCasesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveReimbursementCasesCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useArchiveReimbursementCasesCountQuery(baseOptions?: Apollo.QueryHookOptions<ArchiveReimbursementCasesCountQuery, ArchiveReimbursementCasesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveReimbursementCasesCountQuery, ArchiveReimbursementCasesCountQueryVariables>(ArchiveReimbursementCasesCountDocument, options);
      }
export function useArchiveReimbursementCasesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveReimbursementCasesCountQuery, ArchiveReimbursementCasesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveReimbursementCasesCountQuery, ArchiveReimbursementCasesCountQueryVariables>(ArchiveReimbursementCasesCountDocument, options);
        }
export type ArchiveReimbursementCasesCountQueryHookResult = ReturnType<typeof useArchiveReimbursementCasesCountQuery>;
export type ArchiveReimbursementCasesCountLazyQueryHookResult = ReturnType<typeof useArchiveReimbursementCasesCountLazyQuery>;
export type ArchiveReimbursementCasesCountQueryResult = Apollo.QueryResult<ArchiveReimbursementCasesCountQuery, ArchiveReimbursementCasesCountQueryVariables>;
export const InboxReimbursementCasesCountDocument = gql`
    query inboxReimbursementCasesCount {
  inboxReimbursementCasesCount {
    totalCount
  }
}
    `;

/**
 * __useInboxReimbursementCasesCountQuery__
 *
 * To run a query within a React component, call `useInboxReimbursementCasesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxReimbursementCasesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxReimbursementCasesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useInboxReimbursementCasesCountQuery(baseOptions?: Apollo.QueryHookOptions<InboxReimbursementCasesCountQuery, InboxReimbursementCasesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxReimbursementCasesCountQuery, InboxReimbursementCasesCountQueryVariables>(InboxReimbursementCasesCountDocument, options);
      }
export function useInboxReimbursementCasesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxReimbursementCasesCountQuery, InboxReimbursementCasesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxReimbursementCasesCountQuery, InboxReimbursementCasesCountQueryVariables>(InboxReimbursementCasesCountDocument, options);
        }
export type InboxReimbursementCasesCountQueryHookResult = ReturnType<typeof useInboxReimbursementCasesCountQuery>;
export type InboxReimbursementCasesCountLazyQueryHookResult = ReturnType<typeof useInboxReimbursementCasesCountLazyQuery>;
export type InboxReimbursementCasesCountQueryResult = Apollo.QueryResult<InboxReimbursementCasesCountQuery, InboxReimbursementCasesCountQueryVariables>;
export const ReimbursementItemsDocument = gql`
    query reimbursementItems($filters: ReimbursementItemFiltersInput!) {
  reimbursementItems(filters: $filters) {
    ... on GeneralExpenseItem {
      id
      reimbursementCaseId
      createdAt
      updatedAt
      type
      status
      title
      expenseDate
      updatedAt
      invoiceNumber
      files {
        id
        url
        name
      }
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      extractedDataAccepted
      extractedData {
        expenseDate
        invoiceNumber
        sourceFile {
          id
          url
          name
        }
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
    ... on HospitalityExpenseItem {
      id
      reimbursementCaseId
      createdAt
      updatedAt
      type
      status
      title
      expenseDate
      location
      invoiceNumber
      files {
        id
        url
      }
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      internalGuests
      externalGuests
      receiptAmount {
        amount
        currency
        precision
      }
      tipAmount {
        amount
        currency
        precision
      }
      extractedDataAccepted
      extractedData {
        expenseDate
        invoiceNumber
        location
        sourceFile {
          id
          url
          name
        }
        receiptAmount {
          amount
          currency
          precision
        }
        tipAmount {
          amount
          currency
          precision
        }
      }
    }
    ... on PerDiemItem {
      id
      type
      status
      currentStep
      totalAmount {
        amount
        currency
        precision
      }
      segments {
        id
        startAt
        endAt
        location {
          id
        }
      }
      days {
        id
        date
        providedBreakfast
        providedLunch
        providedDinner
      }
    }
  }
}
    `;

/**
 * __useReimbursementItemsQuery__
 *
 * To run a query within a React component, call `useReimbursementItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementItemsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReimbursementItemsQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementItemsQuery, ReimbursementItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementItemsQuery, ReimbursementItemsQueryVariables>(ReimbursementItemsDocument, options);
      }
export function useReimbursementItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementItemsQuery, ReimbursementItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementItemsQuery, ReimbursementItemsQueryVariables>(ReimbursementItemsDocument, options);
        }
export type ReimbursementItemsQueryHookResult = ReturnType<typeof useReimbursementItemsQuery>;
export type ReimbursementItemsLazyQueryHookResult = ReturnType<typeof useReimbursementItemsLazyQuery>;
export type ReimbursementItemsQueryResult = Apollo.QueryResult<ReimbursementItemsQuery, ReimbursementItemsQueryVariables>;
export const ReimbursementItemsListDocument = gql`
    query reimbursementItemsList($filters: ReimbursementItemFiltersInput!) {
  reimbursementItemsList: reimbursementItems(filters: $filters) @connection(key: "reimbursementItemsList", filter: ["filters"]) {
    ...ReimbursementItemsListGeneralExpenseData
    ...ReimbursementItemsListHospitalityExpenseData
    ...ReimbursementItemsListPerDiemData
    ...ReimbursementItemsListMileageData
  }
}
    ${ReimbursementItemsListGeneralExpenseDataFragmentDoc}
${ReimbursementItemsListHospitalityExpenseDataFragmentDoc}
${ReimbursementItemsListPerDiemDataFragmentDoc}
${ReimbursementItemsListMileageDataFragmentDoc}`;

/**
 * __useReimbursementItemsListQuery__
 *
 * To run a query within a React component, call `useReimbursementItemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementItemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementItemsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReimbursementItemsListQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementItemsListQuery, ReimbursementItemsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementItemsListQuery, ReimbursementItemsListQueryVariables>(ReimbursementItemsListDocument, options);
      }
export function useReimbursementItemsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementItemsListQuery, ReimbursementItemsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementItemsListQuery, ReimbursementItemsListQueryVariables>(ReimbursementItemsListDocument, options);
        }
export type ReimbursementItemsListQueryHookResult = ReturnType<typeof useReimbursementItemsListQuery>;
export type ReimbursementItemsListLazyQueryHookResult = ReturnType<typeof useReimbursementItemsListLazyQuery>;
export type ReimbursementItemsListQueryResult = Apollo.QueryResult<ReimbursementItemsListQuery, ReimbursementItemsListQueryVariables>;
export const ReimbursementsCursorBasedNavigationDocument = gql`
    query reimbursementsCursorBasedNavigation($next: String, $filters: ReimbursementCasesFilterInput, $sorts: ReimbursementCasesSortInput, $searchInput: SearchInput, $previous: String, $search: String) {
  prevReimbursement: archiveReimbursementCases(
    filters: $filters
    sorts: $sorts
    search: $search
    searchInput: $searchInput
    limit: 1
    previous: $previous
  ) {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
  }
  nextReimbursement: archiveReimbursementCases(
    filters: $filters
    sorts: $sorts
    search: $search
    searchInput: $searchInput
    limit: 1
    next: $next
  ) {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
  }
}
    `;

/**
 * __useReimbursementsCursorBasedNavigationQuery__
 *
 * To run a query within a React component, call `useReimbursementsCursorBasedNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementsCursorBasedNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementsCursorBasedNavigationQuery({
 *   variables: {
 *      next: // value for 'next'
 *      filters: // value for 'filters'
 *      sorts: // value for 'sorts'
 *      searchInput: // value for 'searchInput'
 *      previous: // value for 'previous'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReimbursementsCursorBasedNavigationQuery(baseOptions?: Apollo.QueryHookOptions<ReimbursementsCursorBasedNavigationQuery, ReimbursementsCursorBasedNavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementsCursorBasedNavigationQuery, ReimbursementsCursorBasedNavigationQueryVariables>(ReimbursementsCursorBasedNavigationDocument, options);
      }
export function useReimbursementsCursorBasedNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementsCursorBasedNavigationQuery, ReimbursementsCursorBasedNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementsCursorBasedNavigationQuery, ReimbursementsCursorBasedNavigationQueryVariables>(ReimbursementsCursorBasedNavigationDocument, options);
        }
export type ReimbursementsCursorBasedNavigationQueryHookResult = ReturnType<typeof useReimbursementsCursorBasedNavigationQuery>;
export type ReimbursementsCursorBasedNavigationLazyQueryHookResult = ReturnType<typeof useReimbursementsCursorBasedNavigationLazyQuery>;
export type ReimbursementsCursorBasedNavigationQueryResult = Apollo.QueryResult<ReimbursementsCursorBasedNavigationQuery, ReimbursementsCursorBasedNavigationQueryVariables>;
export const ReimbursementsInboxNavigationDocument = gql`
    query reimbursementsInboxNavigation($next: String, $filters: ReimbursementCasesFilterInput, $sorts: ReimbursementCasesSortInput, $searchInput: SearchInput, $previous: String, $search: String) {
  prevReimbursement: inboxReimbursementCases(
    filters: $filters
    sorts: $sorts
    search: $search
    searchInput: $searchInput
    limit: 1
    previous: $previous
  ) {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
  }
  nextReimbursement: inboxReimbursementCases(
    filters: $filters
    sorts: $sorts
    search: $search
    searchInput: $searchInput
    limit: 1
    next: $next
  ) {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
  }
}
    `;

/**
 * __useReimbursementsInboxNavigationQuery__
 *
 * To run a query within a React component, call `useReimbursementsInboxNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementsInboxNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementsInboxNavigationQuery({
 *   variables: {
 *      next: // value for 'next'
 *      filters: // value for 'filters'
 *      sorts: // value for 'sorts'
 *      searchInput: // value for 'searchInput'
 *      previous: // value for 'previous'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReimbursementsInboxNavigationQuery(baseOptions?: Apollo.QueryHookOptions<ReimbursementsInboxNavigationQuery, ReimbursementsInboxNavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementsInboxNavigationQuery, ReimbursementsInboxNavigationQueryVariables>(ReimbursementsInboxNavigationDocument, options);
      }
export function useReimbursementsInboxNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementsInboxNavigationQuery, ReimbursementsInboxNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementsInboxNavigationQuery, ReimbursementsInboxNavigationQueryVariables>(ReimbursementsInboxNavigationDocument, options);
        }
export type ReimbursementsInboxNavigationQueryHookResult = ReturnType<typeof useReimbursementsInboxNavigationQuery>;
export type ReimbursementsInboxNavigationLazyQueryHookResult = ReturnType<typeof useReimbursementsInboxNavigationLazyQuery>;
export type ReimbursementsInboxNavigationQueryResult = Apollo.QueryResult<ReimbursementsInboxNavigationQuery, ReimbursementsInboxNavigationQueryVariables>;
export const ReimbursementsApprovalsNavigationDocument = gql`
    query reimbursementsApprovalsNavigation($next: String, $filters: ReimbursementCasesFilterInput, $sorts: ReimbursementCasesSortInput, $searchInput: SearchInput, $previous: String, $search: String) {
  prevReimbursement: approvalReimbursementCases(
    filters: $filters
    sorts: $sorts
    search: $search
    searchInput: $searchInput
    limit: 1
    previous: $previous
  ) {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
  }
  nextReimbursement: approvalReimbursementCases(
    filters: $filters
    sorts: $sorts
    search: $search
    searchInput: $searchInput
    limit: 1
    next: $next
  ) {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      totalCount
      hasNextPage
      endCursor: nextPageCursor
    }
  }
}
    `;

/**
 * __useReimbursementsApprovalsNavigationQuery__
 *
 * To run a query within a React component, call `useReimbursementsApprovalsNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementsApprovalsNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementsApprovalsNavigationQuery({
 *   variables: {
 *      next: // value for 'next'
 *      filters: // value for 'filters'
 *      sorts: // value for 'sorts'
 *      searchInput: // value for 'searchInput'
 *      previous: // value for 'previous'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReimbursementsApprovalsNavigationQuery(baseOptions?: Apollo.QueryHookOptions<ReimbursementsApprovalsNavigationQuery, ReimbursementsApprovalsNavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementsApprovalsNavigationQuery, ReimbursementsApprovalsNavigationQueryVariables>(ReimbursementsApprovalsNavigationDocument, options);
      }
export function useReimbursementsApprovalsNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementsApprovalsNavigationQuery, ReimbursementsApprovalsNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementsApprovalsNavigationQuery, ReimbursementsApprovalsNavigationQueryVariables>(ReimbursementsApprovalsNavigationDocument, options);
        }
export type ReimbursementsApprovalsNavigationQueryHookResult = ReturnType<typeof useReimbursementsApprovalsNavigationQuery>;
export type ReimbursementsApprovalsNavigationLazyQueryHookResult = ReturnType<typeof useReimbursementsApprovalsNavigationLazyQuery>;
export type ReimbursementsApprovalsNavigationQueryResult = Apollo.QueryResult<ReimbursementsApprovalsNavigationQuery, ReimbursementsApprovalsNavigationQueryVariables>;
export const ReimbursementItemsSplitBookingsDocument = gql`
    query reimbursementItemsSplitBookings($filters: ReimbursementItemFiltersInput!) {
  reimbursementItemsSplitBookings: reimbursementItems(filters: $filters) @connection(key: "reimbursementItemsSplitBookings", filter: ["filters"]) {
    ... on GeneralExpenseItem {
      id
      type
      status
      title
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      bookings {
        id
        artistSocialInsuranceCode
        costCenter {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        costObject {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        extraCostInfo {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        generalLedgerAccount {
          confidence
          source
          value {
            id
            name
            accountCode
            readableName
          }
        }
        bookingKey {
          confidence
          source
          value {
            id
            readableName
          }
        }
        amount {
          value
          confidence
          source
        }
        vatRate {
          value
          confidence
          source
        }
        note {
          value
          confidence
          source
        }
        postingText
      }
    }
    ... on HospitalityExpenseItem {
      id
      type
      status
      title
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      bookings {
        id
        artistSocialInsuranceCode
        costCenter {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        costObject {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        extraCostInfo {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        generalLedgerAccount {
          confidence
          source
          value {
            id
            name
            accountCode
            readableName
          }
        }
        bookingKey {
          confidence
          source
          value {
            id
            taxCode
            readableName
          }
        }
        amount {
          value
          confidence
          source
        }
        vatRate {
          value
          confidence
          source
        }
        note {
          value
          confidence
          source
        }
        postingText
      }
    }
    ... on PerDiemItem {
      id
      type
      status
      title
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      bookings {
        id
        artistSocialInsuranceCode
        costCenter {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        costObject {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        extraCostInfo {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        generalLedgerAccount {
          confidence
          source
          value {
            id
            name
            accountCode
            readableName
          }
        }
        bookingKey {
          confidence
          source
          value {
            id
            taxCode
            readableName
          }
        }
        amount {
          value
          confidence
          source
        }
        vatRate {
          value
          confidence
          source
        }
        note {
          value
          confidence
          source
        }
        postingText
      }
    }
  }
}
    `;

/**
 * __useReimbursementItemsSplitBookingsQuery__
 *
 * To run a query within a React component, call `useReimbursementItemsSplitBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementItemsSplitBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementItemsSplitBookingsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReimbursementItemsSplitBookingsQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementItemsSplitBookingsQuery, ReimbursementItemsSplitBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementItemsSplitBookingsQuery, ReimbursementItemsSplitBookingsQueryVariables>(ReimbursementItemsSplitBookingsDocument, options);
      }
export function useReimbursementItemsSplitBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementItemsSplitBookingsQuery, ReimbursementItemsSplitBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementItemsSplitBookingsQuery, ReimbursementItemsSplitBookingsQueryVariables>(ReimbursementItemsSplitBookingsDocument, options);
        }
export type ReimbursementItemsSplitBookingsQueryHookResult = ReturnType<typeof useReimbursementItemsSplitBookingsQuery>;
export type ReimbursementItemsSplitBookingsLazyQueryHookResult = ReturnType<typeof useReimbursementItemsSplitBookingsLazyQuery>;
export type ReimbursementItemsSplitBookingsQueryResult = Apollo.QueryResult<ReimbursementItemsSplitBookingsQuery, ReimbursementItemsSplitBookingsQueryVariables>;
export const ReimbursementItemsSplitBookingsInitialSnapshotDocument = gql`
    query reimbursementItemsSplitBookingsInitialSnapshot($filters: ReimbursementItemFiltersInput!) {
  reimbursementItemsSplitBookingsInitialSnapshot: reimbursementItems(
    filters: $filters
  ) @connection(key: "reimbursementItemsSplitBookingsInitialSnapshot", filter: ["filters"]) {
    ... on GeneralExpenseItem {
      id
      type
      status
      title
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      bookings {
        id
        artistSocialInsuranceCode
        costCenter {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        costObject {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        extraCostInfo {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        generalLedgerAccount {
          confidence
          source
          value {
            id
            name
            accountCode
            readableName
          }
        }
        bookingKey {
          confidence
          source
          value {
            id
            readableName
          }
        }
        amount {
          value
          confidence
          source
        }
        vatRate {
          value
          confidence
          source
        }
        note {
          value
          confidence
          source
        }
        postingText
      }
    }
    ... on HospitalityExpenseItem {
      id
      type
      status
      title
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      bookings {
        id
        artistSocialInsuranceCode
        costCenter {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        costObject {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        extraCostInfo {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        generalLedgerAccount {
          confidence
          source
          value {
            id
            name
            accountCode
            readableName
          }
        }
        bookingKey {
          confidence
          source
          value {
            id
            taxCode
            readableName
          }
        }
        amount {
          value
          confidence
          source
        }
        vatRate {
          value
          confidence
          source
        }
        note {
          value
          confidence
          source
        }
        postingText
      }
    }
    ... on PerDiemItem {
      id
      type
      status
      title
      totalAmount {
        amount
        currency
        precision
      }
      errors {
        field
        errors
      }
      bookings {
        id
        artistSocialInsuranceCode
        costCenter {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        costObject {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        extraCostInfo {
          confidence
          source
          value {
            id
            name
            code
            readableName
          }
        }
        generalLedgerAccount {
          confidence
          source
          value {
            id
            name
            accountCode
            readableName
          }
        }
        bookingKey {
          confidence
          source
          value {
            id
            taxCode
            readableName
          }
        }
        amount {
          value
          confidence
          source
        }
        vatRate {
          value
          confidence
          source
        }
        note {
          value
          confidence
          source
        }
        postingText
      }
    }
  }
}
    `;

/**
 * __useReimbursementItemsSplitBookingsInitialSnapshotQuery__
 *
 * To run a query within a React component, call `useReimbursementItemsSplitBookingsInitialSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementItemsSplitBookingsInitialSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementItemsSplitBookingsInitialSnapshotQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReimbursementItemsSplitBookingsInitialSnapshotQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementItemsSplitBookingsInitialSnapshotQuery, ReimbursementItemsSplitBookingsInitialSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementItemsSplitBookingsInitialSnapshotQuery, ReimbursementItemsSplitBookingsInitialSnapshotQueryVariables>(ReimbursementItemsSplitBookingsInitialSnapshotDocument, options);
      }
export function useReimbursementItemsSplitBookingsInitialSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementItemsSplitBookingsInitialSnapshotQuery, ReimbursementItemsSplitBookingsInitialSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementItemsSplitBookingsInitialSnapshotQuery, ReimbursementItemsSplitBookingsInitialSnapshotQueryVariables>(ReimbursementItemsSplitBookingsInitialSnapshotDocument, options);
        }
export type ReimbursementItemsSplitBookingsInitialSnapshotQueryHookResult = ReturnType<typeof useReimbursementItemsSplitBookingsInitialSnapshotQuery>;
export type ReimbursementItemsSplitBookingsInitialSnapshotLazyQueryHookResult = ReturnType<typeof useReimbursementItemsSplitBookingsInitialSnapshotLazyQuery>;
export type ReimbursementItemsSplitBookingsInitialSnapshotQueryResult = Apollo.QueryResult<ReimbursementItemsSplitBookingsInitialSnapshotQuery, ReimbursementItemsSplitBookingsInitialSnapshotQueryVariables>;
export const GetReimbursementHistoryDocument = gql`
    query getReimbursementHistory($id: ID!) {
  reimbursementCaseById(id: $id) @connection(key: "getReimbursementHistory", filter: ["id"]) {
    id
    timeline {
      __typename
      id
      createdAt
      ...ReimbursementCaseTitleUpdatedEventData
      ...ExpenseItemTitleUpdatedEventData
      ...ReimbursementCaseAmendmentRequestedEventData
      ...ReimbursementCaseApprovedEventData
      ...ReimbursementCaseCreatedEventData
      ...ReimbursementCaseSubmittedForReviewEventData
      ...ReimbursementCaseTargetMembershipUpdatedEventData
      ...ReimbursementCaseNoteUpdatedEventData
      ...ExpenseItemTotalAmountUpdatedEventData
      ...ExpenseItemFileAddedEventData
      ...ExpenseItemFileRemovedEventData
      ...ExpenseItemExpenseDateUpdatedEventData
      ...ReimbursementCaseCommentAddedEventData
      ...ExpenseItemArchivedEventData
      ...ExpenseItemHospitalityExternalGuestsUpdatedEventData
      ...ExpenseItemHospitalityInternalGuestsUpdatedEventData
      ...ExpenseItemHospitalityLocationUpdatedEventData
      ...ExpenseItemHospitalityReceiptAmountUpdatedEventData
      ...ExpenseItemHospitalityTipAmountUpdatedEventData
      ...ExpenseItemBookingAddedEventData
      ...ExpenseItemBookingRemovedEventData
      ...ExpenseItemBookingUpdatedEventData
      ...ReimbursementCaseResetApprovalEventData
      ...ReimbursementCaseItemAddedEventData
      ...ReimbursementCaseItemRemovedEventData
      ...ReimbursementCaseItemIncludedEventData
      ...ReimbursementCaseItemExcludedEventData
      ...ExpenseItemInvoiceNumberUpdatedEventData
      ...ReimbursementCaseItemExportStatusUpdatedEventData
      ...ReimbursementCaseSubmittedForApprovalEventData
      ...ReimbursementCaseWorkflowStepRejectedEventData
      ...ReimbursementCaseWorkflowStepApprovedEventData
      ...ReimbursementCaseTriggeredWorkflowEventData
      ...ReimbursementCaseRejectedEventData
      ...ExpenseItemGeneralDataExtractedEventData
      ...ExpenseItemHospitalityDataExtractedEventData
      ...ReimbursementCaseWorkflowStepSkippedEventData
    }
  }
}
    ${ReimbursementCaseTitleUpdatedEventDataFragmentDoc}
${ExpenseItemTitleUpdatedEventDataFragmentDoc}
${ReimbursementCaseAmendmentRequestedEventDataFragmentDoc}
${ReimbursementCaseApprovedEventDataFragmentDoc}
${ReimbursementCaseCreatedEventDataFragmentDoc}
${ReimbursementCaseSubmittedForReviewEventDataFragmentDoc}
${ReimbursementCaseTargetMembershipUpdatedEventDataFragmentDoc}
${ReimbursementCaseNoteUpdatedEventDataFragmentDoc}
${ExpenseItemTotalAmountUpdatedEventDataFragmentDoc}
${ExpenseItemFileAddedEventDataFragmentDoc}
${ExpenseItemFileRemovedEventDataFragmentDoc}
${ExpenseItemExpenseDateUpdatedEventDataFragmentDoc}
${ReimbursementCaseCommentAddedEventDataFragmentDoc}
${ExpenseItemArchivedEventDataFragmentDoc}
${ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragmentDoc}
${ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragmentDoc}
${ExpenseItemHospitalityLocationUpdatedEventDataFragmentDoc}
${ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragmentDoc}
${ExpenseItemHospitalityTipAmountUpdatedEventDataFragmentDoc}
${ExpenseItemBookingAddedEventDataFragmentDoc}
${ExpenseItemBookingDataFragmentDoc}
${ExpenseItemBookingRemovedEventDataFragmentDoc}
${ExpenseItemBookingUpdatedEventDataFragmentDoc}
${ReimbursementCaseResetApprovalEventDataFragmentDoc}
${ReimbursementCaseItemAddedEventDataFragmentDoc}
${ReimbursementCaseItemRemovedEventDataFragmentDoc}
${ReimbursementCaseItemIncludedEventDataFragmentDoc}
${ReimbursementCaseItemExcludedEventDataFragmentDoc}
${ExpenseItemInvoiceNumberUpdatedEventDataFragmentDoc}
${ReimbursementCaseItemExportStatusUpdatedEventDataFragmentDoc}
${ReimbursementCaseSubmittedForApprovalEventDataFragmentDoc}
${ReimbursementCaseWorkflowStepRejectedEventDataFragmentDoc}
${ReimbursementCaseWorkflowStepApprovedEventDataFragmentDoc}
${ReimbursementCaseTriggeredWorkflowEventDataFragmentDoc}
${ReimbursementCaseRejectedEventDataFragmentDoc}
${ExpenseItemGeneralDataExtractedEventDataFragmentDoc}
${ExpenseItemHospitalityDataExtractedEventDataFragmentDoc}
${ReimbursementCaseWorkflowStepSkippedEventDataFragmentDoc}`;

/**
 * __useGetReimbursementHistoryQuery__
 *
 * To run a query within a React component, call `useGetReimbursementHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReimbursementHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReimbursementHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReimbursementHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetReimbursementHistoryQuery, GetReimbursementHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReimbursementHistoryQuery, GetReimbursementHistoryQueryVariables>(GetReimbursementHistoryDocument, options);
      }
export function useGetReimbursementHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReimbursementHistoryQuery, GetReimbursementHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReimbursementHistoryQuery, GetReimbursementHistoryQueryVariables>(GetReimbursementHistoryDocument, options);
        }
export type GetReimbursementHistoryQueryHookResult = ReturnType<typeof useGetReimbursementHistoryQuery>;
export type GetReimbursementHistoryLazyQueryHookResult = ReturnType<typeof useGetReimbursementHistoryLazyQuery>;
export type GetReimbursementHistoryQueryResult = Apollo.QueryResult<GetReimbursementHistoryQuery, GetReimbursementHistoryQueryVariables>;
export const ReimbursementCaseErrorsByIdDocument = gql`
    query reimbursementCaseErrorsById($id: ID!) {
  reimbursementCaseErrorsById: reimbursementCaseById(id: $id) {
    id
    errors {
      field
      errors
    }
  }
}
    `;

/**
 * __useReimbursementCaseErrorsByIdQuery__
 *
 * To run a query within a React component, call `useReimbursementCaseErrorsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementCaseErrorsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementCaseErrorsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReimbursementCaseErrorsByIdQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementCaseErrorsByIdQuery, ReimbursementCaseErrorsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementCaseErrorsByIdQuery, ReimbursementCaseErrorsByIdQueryVariables>(ReimbursementCaseErrorsByIdDocument, options);
      }
export function useReimbursementCaseErrorsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementCaseErrorsByIdQuery, ReimbursementCaseErrorsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementCaseErrorsByIdQuery, ReimbursementCaseErrorsByIdQueryVariables>(ReimbursementCaseErrorsByIdDocument, options);
        }
export type ReimbursementCaseErrorsByIdQueryHookResult = ReturnType<typeof useReimbursementCaseErrorsByIdQuery>;
export type ReimbursementCaseErrorsByIdLazyQueryHookResult = ReturnType<typeof useReimbursementCaseErrorsByIdLazyQuery>;
export type ReimbursementCaseErrorsByIdQueryResult = Apollo.QueryResult<ReimbursementCaseErrorsByIdQuery, ReimbursementCaseErrorsByIdQueryVariables>;
export const ReimbursementItemsErrorsDocument = gql`
    query reimbursementItemsErrors($filters: ReimbursementItemFiltersInput!) {
  reimbursementItemsErrors: reimbursementItems(filters: $filters) @connection(key: "reimbursementItemsErrors", filter: ["filters"]) {
    ... on GeneralExpenseItem {
      id
      type
      title
      status
      errors {
        field
        errors
      }
    }
    ... on HospitalityExpenseItem {
      id
      type
      title
      status
      errors {
        field
        errors
      }
    }
    ... on PerDiemItem {
      id
      type
      title
      status
      errors {
        field
        errors
      }
    }
  }
}
    `;

/**
 * __useReimbursementItemsErrorsQuery__
 *
 * To run a query within a React component, call `useReimbursementItemsErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementItemsErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementItemsErrorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReimbursementItemsErrorsQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementItemsErrorsQuery, ReimbursementItemsErrorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementItemsErrorsQuery, ReimbursementItemsErrorsQueryVariables>(ReimbursementItemsErrorsDocument, options);
      }
export function useReimbursementItemsErrorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementItemsErrorsQuery, ReimbursementItemsErrorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementItemsErrorsQuery, ReimbursementItemsErrorsQueryVariables>(ReimbursementItemsErrorsDocument, options);
        }
export type ReimbursementItemsErrorsQueryHookResult = ReturnType<typeof useReimbursementItemsErrorsQuery>;
export type ReimbursementItemsErrorsLazyQueryHookResult = ReturnType<typeof useReimbursementItemsErrorsLazyQuery>;
export type ReimbursementItemsErrorsQueryResult = Apollo.QueryResult<ReimbursementItemsErrorsQuery, ReimbursementItemsErrorsQueryVariables>;
export const ReimbursementCaseWorkflowDocument = gql`
    query reimbursementCaseWorkflow($id: ID!) {
  reimbursementCaseWorkflow: reimbursementCaseById(id: $id) {
    workflow {
      id
      description
      name
      workflowTemplate {
        id
        name
        steps {
          resolvers {
            id
            firstName
            lastName
            avatarUrl
          }
        }
      }
      currentStep {
        id
        approvers {
          id
          firstName
          lastName
          avatarUrl
        }
      }
      steps {
        id
        type
        name
        resolution {
          type
          comment
          approvers {
            id
            firstName
            lastName
            avatarUrl
          }
        }
        approvers {
          id
          firstName
          lastName
          avatarUrl
          substitute {
            id
            firstName
            lastName
            avatarUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useReimbursementCaseWorkflowQuery__
 *
 * To run a query within a React component, call `useReimbursementCaseWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimbursementCaseWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimbursementCaseWorkflowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReimbursementCaseWorkflowQuery(baseOptions: Apollo.QueryHookOptions<ReimbursementCaseWorkflowQuery, ReimbursementCaseWorkflowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReimbursementCaseWorkflowQuery, ReimbursementCaseWorkflowQueryVariables>(ReimbursementCaseWorkflowDocument, options);
      }
export function useReimbursementCaseWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReimbursementCaseWorkflowQuery, ReimbursementCaseWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReimbursementCaseWorkflowQuery, ReimbursementCaseWorkflowQueryVariables>(ReimbursementCaseWorkflowDocument, options);
        }
export type ReimbursementCaseWorkflowQueryHookResult = ReturnType<typeof useReimbursementCaseWorkflowQuery>;
export type ReimbursementCaseWorkflowLazyQueryHookResult = ReturnType<typeof useReimbursementCaseWorkflowLazyQuery>;
export type ReimbursementCaseWorkflowQueryResult = Apollo.QueryResult<ReimbursementCaseWorkflowQuery, ReimbursementCaseWorkflowQueryVariables>;
export const GetReimbursementCasesSummaryDocument = gql`
    query getReimbursementCasesSummary($targetMembershipIds: [String!]!) {
  reimbursementCasesSummary(filters: {targetMembershipIds: $targetMembershipIds}) {
    ...ReimbursementCasesSummaryFragment
  }
}
    ${ReimbursementCasesSummaryFragmentFragmentDoc}`;

/**
 * __useGetReimbursementCasesSummaryQuery__
 *
 * To run a query within a React component, call `useGetReimbursementCasesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReimbursementCasesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReimbursementCasesSummaryQuery({
 *   variables: {
 *      targetMembershipIds: // value for 'targetMembershipIds'
 *   },
 * });
 */
export function useGetReimbursementCasesSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetReimbursementCasesSummaryQuery, GetReimbursementCasesSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReimbursementCasesSummaryQuery, GetReimbursementCasesSummaryQueryVariables>(GetReimbursementCasesSummaryDocument, options);
      }
export function useGetReimbursementCasesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReimbursementCasesSummaryQuery, GetReimbursementCasesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReimbursementCasesSummaryQuery, GetReimbursementCasesSummaryQueryVariables>(GetReimbursementCasesSummaryDocument, options);
        }
export type GetReimbursementCasesSummaryQueryHookResult = ReturnType<typeof useGetReimbursementCasesSummaryQuery>;
export type GetReimbursementCasesSummaryLazyQueryHookResult = ReturnType<typeof useGetReimbursementCasesSummaryLazyQuery>;
export type GetReimbursementCasesSummaryQueryResult = Apollo.QueryResult<GetReimbursementCasesSummaryQuery, GetReimbursementCasesSummaryQueryVariables>;
export const GetAggregatedReimbursementCasesDocument = gql`
    query getAggregatedReimbursementCases($targetMembershipIds: [String!]!, $draftStatuses: [ReimbursementCaseStatus!]!, $openStatuses: [ReimbursementCaseStatus!]!, $closedStatuses: [ReimbursementCaseStatus!]!, $sortOrder: ReimbursementCasesSortOrder!, $lastClosedAt: ReimbursementCasesDateFilter!) {
  draftCases: archiveReimbursementCases(
    limit: 6
    filters: {statuses: $draftStatuses, targetMembershipIds: $targetMembershipIds}
    sorts: {createdAt: $sortOrder}
  ) {
    edges {
      node {
        id
        title
        status
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
    pageInfo {
      totalCount
    }
  }
  openCases: archiveReimbursementCases(
    limit: 6
    filters: {statuses: $openStatuses, targetMembershipIds: $targetMembershipIds}
    sorts: {createdAt: $sortOrder}
  ) {
    edges {
      node {
        id
        title
        status
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
    pageInfo {
      totalCount
    }
  }
  closedCases: archiveReimbursementCases(
    limit: 6
    filters: {statuses: $closedStatuses, targetMembershipIds: $targetMembershipIds, lastClosedAt: $lastClosedAt}
    sorts: {lastClosedAt: $sortOrder}
  ) {
    edges {
      node {
        id
        title
        status
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
    pageInfo {
      totalCount
    }
  }
}
    `;

/**
 * __useGetAggregatedReimbursementCasesQuery__
 *
 * To run a query within a React component, call `useGetAggregatedReimbursementCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregatedReimbursementCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregatedReimbursementCasesQuery({
 *   variables: {
 *      targetMembershipIds: // value for 'targetMembershipIds'
 *      draftStatuses: // value for 'draftStatuses'
 *      openStatuses: // value for 'openStatuses'
 *      closedStatuses: // value for 'closedStatuses'
 *      sortOrder: // value for 'sortOrder'
 *      lastClosedAt: // value for 'lastClosedAt'
 *   },
 * });
 */
export function useGetAggregatedReimbursementCasesQuery(baseOptions: Apollo.QueryHookOptions<GetAggregatedReimbursementCasesQuery, GetAggregatedReimbursementCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAggregatedReimbursementCasesQuery, GetAggregatedReimbursementCasesQueryVariables>(GetAggregatedReimbursementCasesDocument, options);
      }
export function useGetAggregatedReimbursementCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAggregatedReimbursementCasesQuery, GetAggregatedReimbursementCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAggregatedReimbursementCasesQuery, GetAggregatedReimbursementCasesQueryVariables>(GetAggregatedReimbursementCasesDocument, options);
        }
export type GetAggregatedReimbursementCasesQueryHookResult = ReturnType<typeof useGetAggregatedReimbursementCasesQuery>;
export type GetAggregatedReimbursementCasesLazyQueryHookResult = ReturnType<typeof useGetAggregatedReimbursementCasesLazyQuery>;
export type GetAggregatedReimbursementCasesQueryResult = Apollo.QueryResult<GetAggregatedReimbursementCasesQuery, GetAggregatedReimbursementCasesQueryVariables>;
export const MembershipByIdDocument = gql`
    query membershipById($membershipId: String!) {
  membership(membershipId: $membershipId) {
    id
    name
    firstName
    lastName
    roles
    locale
    email
    avatarUrl
    status
    createdTimestamp
    username
    paymentInfo {
      iban
      swiftCode
    }
    membershipRoles {
      __typename
      id
      name
      description
      isArchived
      isBuiltinRole
      isLegacyRole
    }
  }
}
    `;

/**
 * __useMembershipByIdQuery__
 *
 * To run a query within a React component, call `useMembershipByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipByIdQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useMembershipByIdQuery(baseOptions: Apollo.QueryHookOptions<MembershipByIdQuery, MembershipByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipByIdQuery, MembershipByIdQueryVariables>(MembershipByIdDocument, options);
      }
export function useMembershipByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipByIdQuery, MembershipByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipByIdQuery, MembershipByIdQueryVariables>(MembershipByIdDocument, options);
        }
export type MembershipByIdQueryHookResult = ReturnType<typeof useMembershipByIdQuery>;
export type MembershipByIdLazyQueryHookResult = ReturnType<typeof useMembershipByIdLazyQuery>;
export type MembershipByIdQueryResult = Apollo.QueryResult<MembershipByIdQuery, MembershipByIdQueryVariables>;
export const PerDiemLocationsDocument = gql`
    query perDiemLocations($input: PerDiemLocationsInput!) {
  perDiemLocations(input: $input) {
    locations {
      id
      countryIsoCode
      region
      country
      location
    }
  }
}
    `;

/**
 * __usePerDiemLocationsQuery__
 *
 * To run a query within a React component, call `usePerDiemLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerDiemLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerDiemLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerDiemLocationsQuery(baseOptions: Apollo.QueryHookOptions<PerDiemLocationsQuery, PerDiemLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PerDiemLocationsQuery, PerDiemLocationsQueryVariables>(PerDiemLocationsDocument, options);
      }
export function usePerDiemLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerDiemLocationsQuery, PerDiemLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PerDiemLocationsQuery, PerDiemLocationsQueryVariables>(PerDiemLocationsDocument, options);
        }
export type PerDiemLocationsQueryHookResult = ReturnType<typeof usePerDiemLocationsQuery>;
export type PerDiemLocationsLazyQueryHookResult = ReturnType<typeof usePerDiemLocationsLazyQuery>;
export type PerDiemLocationsQueryResult = Apollo.QueryResult<PerDiemLocationsQuery, PerDiemLocationsQueryVariables>;
export const PerDiemItemDetailsDocument = gql`
    query perDiemItemDetails($filters: ReimbursementItemFiltersInput!) {
  perDiemItemDetails: reimbursementItems(filters: $filters) @connection(key: "perDiemItemDetails", filter: ["filters"]) {
    ... on PerDiemItem {
      __typename
      id
      status
      currentStep
      totalAmount {
        amount
        currency
        precision
      }
      segments {
        id
        startAt
        endAt
        location {
          id
          countryIsoCode
          country
          location
        }
      }
    }
  }
}
    `;

/**
 * __usePerDiemItemDetailsQuery__
 *
 * To run a query within a React component, call `usePerDiemItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerDiemItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerDiemItemDetailsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePerDiemItemDetailsQuery(baseOptions: Apollo.QueryHookOptions<PerDiemItemDetailsQuery, PerDiemItemDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PerDiemItemDetailsQuery, PerDiemItemDetailsQueryVariables>(PerDiemItemDetailsDocument, options);
      }
export function usePerDiemItemDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerDiemItemDetailsQuery, PerDiemItemDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PerDiemItemDetailsQuery, PerDiemItemDetailsQueryVariables>(PerDiemItemDetailsDocument, options);
        }
export type PerDiemItemDetailsQueryHookResult = ReturnType<typeof usePerDiemItemDetailsQuery>;
export type PerDiemItemDetailsLazyQueryHookResult = ReturnType<typeof usePerDiemItemDetailsLazyQuery>;
export type PerDiemItemDetailsQueryResult = Apollo.QueryResult<PerDiemItemDetailsQuery, PerDiemItemDetailsQueryVariables>;
export const PerDiemItemCalculationDocument = gql`
    query perDiemItemCalculation($id: ID!) {
  reimbursementItem(id: $id) {
    ... on PerDiemItem {
      __typename
      id
      status
      days {
        id
        date
        isFullDay
        dayRate {
          amount
          currency
          precision
        }
        reimbursableRate {
          amount
          currency
          precision
        }
        meals {
          breakfast {
            provided
            rateRatio
            deduction {
              amount
              currency
              precision
            }
          }
          lunch {
            provided
            rateRatio
            deduction {
              amount
              currency
              precision
            }
          }
          dinner {
            provided
            rateRatio
            deduction {
              amount
              currency
              precision
            }
          }
        }
        location {
          id
          countryIsoCode
          country
          location
        }
      }
      totalAmount {
        amount
        currency
        precision
      }
    }
  }
}
    `;

/**
 * __usePerDiemItemCalculationQuery__
 *
 * To run a query within a React component, call `usePerDiemItemCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerDiemItemCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerDiemItemCalculationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePerDiemItemCalculationQuery(baseOptions: Apollo.QueryHookOptions<PerDiemItemCalculationQuery, PerDiemItemCalculationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PerDiemItemCalculationQuery, PerDiemItemCalculationQueryVariables>(PerDiemItemCalculationDocument, options);
      }
export function usePerDiemItemCalculationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerDiemItemCalculationQuery, PerDiemItemCalculationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PerDiemItemCalculationQuery, PerDiemItemCalculationQueryVariables>(PerDiemItemCalculationDocument, options);
        }
export type PerDiemItemCalculationQueryHookResult = ReturnType<typeof usePerDiemItemCalculationQuery>;
export type PerDiemItemCalculationLazyQueryHookResult = ReturnType<typeof usePerDiemItemCalculationLazyQuery>;
export type PerDiemItemCalculationQueryResult = Apollo.QueryResult<PerDiemItemCalculationQuery, PerDiemItemCalculationQueryVariables>;
export const CreateAccountingAreaDocument = gql`
    mutation createAccountingArea($input: CreateAccountingAreaInput!) {
  createAccountingArea(input: $input) {
    ... on AccountingAreaSuccess {
      id
    }
    ... on AccountingAreaError {
      name
      kind
      message
    }
  }
}
    `;
export type CreateAccountingAreaMutationFn = Apollo.MutationFunction<CreateAccountingAreaMutation, CreateAccountingAreaMutationVariables>;

/**
 * __useCreateAccountingAreaMutation__
 *
 * To run a mutation, you first call `useCreateAccountingAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountingAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountingAreaMutation, { data, loading, error }] = useCreateAccountingAreaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountingAreaMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountingAreaMutation, CreateAccountingAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountingAreaMutation, CreateAccountingAreaMutationVariables>(CreateAccountingAreaDocument, options);
      }
export type CreateAccountingAreaMutationHookResult = ReturnType<typeof useCreateAccountingAreaMutation>;
export type CreateAccountingAreaMutationResult = Apollo.MutationResult<CreateAccountingAreaMutation>;
export type CreateAccountingAreaMutationOptions = Apollo.BaseMutationOptions<CreateAccountingAreaMutation, CreateAccountingAreaMutationVariables>;
export const UpdateAccountingAreaDocument = gql`
    mutation updateAccountingArea($input: UpdateAccountingAreaInput!) {
  updateAccountingArea(input: $input) {
    ... on AccountingAreaSuccess {
      id
    }
    ... on AccountingAreaError {
      name
      kind
      message
    }
  }
}
    `;
export type UpdateAccountingAreaMutationFn = Apollo.MutationFunction<UpdateAccountingAreaMutation, UpdateAccountingAreaMutationVariables>;

/**
 * __useUpdateAccountingAreaMutation__
 *
 * To run a mutation, you first call `useUpdateAccountingAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountingAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountingAreaMutation, { data, loading, error }] = useUpdateAccountingAreaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountingAreaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountingAreaMutation, UpdateAccountingAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountingAreaMutation, UpdateAccountingAreaMutationVariables>(UpdateAccountingAreaDocument, options);
      }
export type UpdateAccountingAreaMutationHookResult = ReturnType<typeof useUpdateAccountingAreaMutation>;
export type UpdateAccountingAreaMutationResult = Apollo.MutationResult<UpdateAccountingAreaMutation>;
export type UpdateAccountingAreaMutationOptions = Apollo.BaseMutationOptions<UpdateAccountingAreaMutation, UpdateAccountingAreaMutationVariables>;
export const ArchiveAccountingAreaDocument = gql`
    mutation archiveAccountingArea($id: ID!) {
  archiveAccountingArea(id: $id) {
    ... on AccountingAreaSuccess {
      id
    }
    ... on AccountingAreaError {
      name
      kind
      message
    }
  }
}
    `;
export type ArchiveAccountingAreaMutationFn = Apollo.MutationFunction<ArchiveAccountingAreaMutation, ArchiveAccountingAreaMutationVariables>;

/**
 * __useArchiveAccountingAreaMutation__
 *
 * To run a mutation, you first call `useArchiveAccountingAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAccountingAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAccountingAreaMutation, { data, loading, error }] = useArchiveAccountingAreaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveAccountingAreaMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveAccountingAreaMutation, ArchiveAccountingAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveAccountingAreaMutation, ArchiveAccountingAreaMutationVariables>(ArchiveAccountingAreaDocument, options);
      }
export type ArchiveAccountingAreaMutationHookResult = ReturnType<typeof useArchiveAccountingAreaMutation>;
export type ArchiveAccountingAreaMutationResult = Apollo.MutationResult<ArchiveAccountingAreaMutation>;
export type ArchiveAccountingAreaMutationOptions = Apollo.BaseMutationOptions<ArchiveAccountingAreaMutation, ArchiveAccountingAreaMutationVariables>;
export const ActivateAccountingAreaDocument = gql`
    mutation activateAccountingArea($id: ID!) {
  activateAccountingArea(id: $id) {
    ... on AccountingAreaSuccess {
      id
    }
    ... on AccountingAreaError {
      name
      kind
      message
    }
  }
}
    `;
export type ActivateAccountingAreaMutationFn = Apollo.MutationFunction<ActivateAccountingAreaMutation, ActivateAccountingAreaMutationVariables>;

/**
 * __useActivateAccountingAreaMutation__
 *
 * To run a mutation, you first call `useActivateAccountingAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountingAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountingAreaMutation, { data, loading, error }] = useActivateAccountingAreaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateAccountingAreaMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAccountingAreaMutation, ActivateAccountingAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAccountingAreaMutation, ActivateAccountingAreaMutationVariables>(ActivateAccountingAreaDocument, options);
      }
export type ActivateAccountingAreaMutationHookResult = ReturnType<typeof useActivateAccountingAreaMutation>;
export type ActivateAccountingAreaMutationResult = Apollo.MutationResult<ActivateAccountingAreaMutation>;
export type ActivateAccountingAreaMutationOptions = Apollo.BaseMutationOptions<ActivateAccountingAreaMutation, ActivateAccountingAreaMutationVariables>;
export const AccountingAreasDocument = gql`
    query accountingAreas($filter: AccountingAreasFilterInput!) {
  accountingAreas(filter: $filter) {
    ...AccountingArea
  }
}
    ${AccountingAreaFragmentDoc}`;

/**
 * __useAccountingAreasQuery__
 *
 * To run a query within a React component, call `useAccountingAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingAreasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAccountingAreasQuery(baseOptions: Apollo.QueryHookOptions<AccountingAreasQuery, AccountingAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingAreasQuery, AccountingAreasQueryVariables>(AccountingAreasDocument, options);
      }
export function useAccountingAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingAreasQuery, AccountingAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingAreasQuery, AccountingAreasQueryVariables>(AccountingAreasDocument, options);
        }
export type AccountingAreasQueryHookResult = ReturnType<typeof useAccountingAreasQuery>;
export type AccountingAreasLazyQueryHookResult = ReturnType<typeof useAccountingAreasLazyQuery>;
export type AccountingAreasQueryResult = Apollo.QueryResult<AccountingAreasQuery, AccountingAreasQueryVariables>;
export const AccountingAreaByIdDocument = gql`
    query accountingAreaById($id: String!) {
  accountingAreaById(id: $id) {
    ...AccountingArea
  }
}
    ${AccountingAreaFragmentDoc}`;

/**
 * __useAccountingAreaByIdQuery__
 *
 * To run a query within a React component, call `useAccountingAreaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingAreaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingAreaByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountingAreaByIdQuery(baseOptions: Apollo.QueryHookOptions<AccountingAreaByIdQuery, AccountingAreaByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingAreaByIdQuery, AccountingAreaByIdQueryVariables>(AccountingAreaByIdDocument, options);
      }
export function useAccountingAreaByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingAreaByIdQuery, AccountingAreaByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingAreaByIdQuery, AccountingAreaByIdQueryVariables>(AccountingAreaByIdDocument, options);
        }
export type AccountingAreaByIdQueryHookResult = ReturnType<typeof useAccountingAreaByIdQuery>;
export type AccountingAreaByIdLazyQueryHookResult = ReturnType<typeof useAccountingAreaByIdLazyQuery>;
export type AccountingAreaByIdQueryResult = Apollo.QueryResult<AccountingAreaByIdQuery, AccountingAreaByIdQueryVariables>;
export const CountAccountingAreasDocument = gql`
    query countAccountingAreas($filter: AccountingAreasFilterInput!) {
  countAccountingAreas(filter: $filter) {
    count
  }
}
    `;

/**
 * __useCountAccountingAreasQuery__
 *
 * To run a query within a React component, call `useCountAccountingAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAccountingAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAccountingAreasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountAccountingAreasQuery(baseOptions: Apollo.QueryHookOptions<CountAccountingAreasQuery, CountAccountingAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountAccountingAreasQuery, CountAccountingAreasQueryVariables>(CountAccountingAreasDocument, options);
      }
export function useCountAccountingAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountAccountingAreasQuery, CountAccountingAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountAccountingAreasQuery, CountAccountingAreasQueryVariables>(CountAccountingAreasDocument, options);
        }
export type CountAccountingAreasQueryHookResult = ReturnType<typeof useCountAccountingAreasQuery>;
export type CountAccountingAreasLazyQueryHookResult = ReturnType<typeof useCountAccountingAreasLazyQuery>;
export type CountAccountingAreasQueryResult = Apollo.QueryResult<CountAccountingAreasQuery, CountAccountingAreasQueryVariables>;
export const ListCandisApiSessionsDocument = gql`
    query listCandisAPISessions {
  listCandisAPISessions {
    sessionId
    clientName
    authorizationUser {
      id
      name
    }
    connectionDate
  }
}
    `;

/**
 * __useListCandisApiSessionsQuery__
 *
 * To run a query within a React component, call `useListCandisApiSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCandisApiSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCandisApiSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCandisApiSessionsQuery(baseOptions?: Apollo.QueryHookOptions<ListCandisApiSessionsQuery, ListCandisApiSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCandisApiSessionsQuery, ListCandisApiSessionsQueryVariables>(ListCandisApiSessionsDocument, options);
      }
export function useListCandisApiSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCandisApiSessionsQuery, ListCandisApiSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCandisApiSessionsQuery, ListCandisApiSessionsQueryVariables>(ListCandisApiSessionsDocument, options);
        }
export type ListCandisApiSessionsQueryHookResult = ReturnType<typeof useListCandisApiSessionsQuery>;
export type ListCandisApiSessionsLazyQueryHookResult = ReturnType<typeof useListCandisApiSessionsLazyQuery>;
export type ListCandisApiSessionsQueryResult = Apollo.QueryResult<ListCandisApiSessionsQuery, ListCandisApiSessionsQueryVariables>;
export const RevokeCandisApiSessionDocument = gql`
    mutation revokeCandisAPISession($sessionId: String!) {
  revokeCandisAPISession(sessionId: $sessionId) {
    ... on RevokeCandisAPISessionSuccessful {
      isRevoked
    }
    ... on RevokeCandisAPISessionError {
      kind
      message
    }
  }
}
    `;
export type RevokeCandisApiSessionMutationFn = Apollo.MutationFunction<RevokeCandisApiSessionMutation, RevokeCandisApiSessionMutationVariables>;

/**
 * __useRevokeCandisApiSessionMutation__
 *
 * To run a mutation, you first call `useRevokeCandisApiSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeCandisApiSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeCandisApiSessionMutation, { data, loading, error }] = useRevokeCandisApiSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useRevokeCandisApiSessionMutation(baseOptions?: Apollo.MutationHookOptions<RevokeCandisApiSessionMutation, RevokeCandisApiSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeCandisApiSessionMutation, RevokeCandisApiSessionMutationVariables>(RevokeCandisApiSessionDocument, options);
      }
export type RevokeCandisApiSessionMutationHookResult = ReturnType<typeof useRevokeCandisApiSessionMutation>;
export type RevokeCandisApiSessionMutationResult = Apollo.MutationResult<RevokeCandisApiSessionMutation>;
export type RevokeCandisApiSessionMutationOptions = Apollo.BaseMutationOptions<RevokeCandisApiSessionMutation, RevokeCandisApiSessionMutationVariables>;
export const BulkCreateBookingAccountsDocument = gql`
    mutation bulkCreateBookingAccounts($input: [CreateBookingAccountInput!]!, $fileId: String) {
  bulkCreateBookingAccounts(input: $input, fileId: $fileId) {
    ... on BookingAccountsBulkImportReport {
      imported {
        id
      }
      errors {
        accountCode
        kind
        message
      }
    }
    ... on BookingAccountError {
      accountCode
      kind
      message
    }
  }
}
    `;
export type BulkCreateBookingAccountsMutationFn = Apollo.MutationFunction<BulkCreateBookingAccountsMutation, BulkCreateBookingAccountsMutationVariables>;

/**
 * __useBulkCreateBookingAccountsMutation__
 *
 * To run a mutation, you first call `useBulkCreateBookingAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateBookingAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateBookingAccountsMutation, { data, loading, error }] = useBulkCreateBookingAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useBulkCreateBookingAccountsMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateBookingAccountsMutation, BulkCreateBookingAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateBookingAccountsMutation, BulkCreateBookingAccountsMutationVariables>(BulkCreateBookingAccountsDocument, options);
      }
export type BulkCreateBookingAccountsMutationHookResult = ReturnType<typeof useBulkCreateBookingAccountsMutation>;
export type BulkCreateBookingAccountsMutationResult = Apollo.MutationResult<BulkCreateBookingAccountsMutation>;
export type BulkCreateBookingAccountsMutationOptions = Apollo.BaseMutationOptions<BulkCreateBookingAccountsMutation, BulkCreateBookingAccountsMutationVariables>;
export const BookingAccountImportFileUploadUrlDocument = gql`
    mutation bookingAccountImportFileUploadURL($fileName: String!, $contentType: String!) {
  bookingAccountImportFileUploadURL(
    fileName: $fileName
    contentType: $contentType
  ) {
    id
    url
    postOptions {
      field
      value
    }
  }
}
    `;
export type BookingAccountImportFileUploadUrlMutationFn = Apollo.MutationFunction<BookingAccountImportFileUploadUrlMutation, BookingAccountImportFileUploadUrlMutationVariables>;

/**
 * __useBookingAccountImportFileUploadUrlMutation__
 *
 * To run a mutation, you first call `useBookingAccountImportFileUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingAccountImportFileUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingAccountImportFileUploadUrlMutation, { data, loading, error }] = useBookingAccountImportFileUploadUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useBookingAccountImportFileUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<BookingAccountImportFileUploadUrlMutation, BookingAccountImportFileUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookingAccountImportFileUploadUrlMutation, BookingAccountImportFileUploadUrlMutationVariables>(BookingAccountImportFileUploadUrlDocument, options);
      }
export type BookingAccountImportFileUploadUrlMutationHookResult = ReturnType<typeof useBookingAccountImportFileUploadUrlMutation>;
export type BookingAccountImportFileUploadUrlMutationResult = Apollo.MutationResult<BookingAccountImportFileUploadUrlMutation>;
export type BookingAccountImportFileUploadUrlMutationOptions = Apollo.BaseMutationOptions<BookingAccountImportFileUploadUrlMutation, BookingAccountImportFileUploadUrlMutationVariables>;
export const BookingAccountsDuplicateCheckDocument = gql`
    query bookingAccountsDuplicateCheck($accountCodes: [String!]!) {
  bookingAccountsDuplicateCheck(accountCodes: $accountCodes)
}
    `;

/**
 * __useBookingAccountsDuplicateCheckQuery__
 *
 * To run a query within a React component, call `useBookingAccountsDuplicateCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAccountsDuplicateCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAccountsDuplicateCheckQuery({
 *   variables: {
 *      accountCodes: // value for 'accountCodes'
 *   },
 * });
 */
export function useBookingAccountsDuplicateCheckQuery(baseOptions: Apollo.QueryHookOptions<BookingAccountsDuplicateCheckQuery, BookingAccountsDuplicateCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingAccountsDuplicateCheckQuery, BookingAccountsDuplicateCheckQueryVariables>(BookingAccountsDuplicateCheckDocument, options);
      }
export function useBookingAccountsDuplicateCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingAccountsDuplicateCheckQuery, BookingAccountsDuplicateCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingAccountsDuplicateCheckQuery, BookingAccountsDuplicateCheckQueryVariables>(BookingAccountsDuplicateCheckDocument, options);
        }
export type BookingAccountsDuplicateCheckQueryHookResult = ReturnType<typeof useBookingAccountsDuplicateCheckQuery>;
export type BookingAccountsDuplicateCheckLazyQueryHookResult = ReturnType<typeof useBookingAccountsDuplicateCheckLazyQuery>;
export type BookingAccountsDuplicateCheckQueryResult = Apollo.QueryResult<BookingAccountsDuplicateCheckQuery, BookingAccountsDuplicateCheckQueryVariables>;
export const BookingAccountDocument = gql`
    query bookingAccount($id: ID!) {
  bookingAccount(id: $id) {
    __typename
    ...BookingAccountData
    ... on BookingAccountError {
      kind
    }
  }
}
    ${BookingAccountDataFragmentDoc}`;

/**
 * __useBookingAccountQuery__
 *
 * To run a query within a React component, call `useBookingAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingAccountQuery(baseOptions: Apollo.QueryHookOptions<BookingAccountQuery, BookingAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingAccountQuery, BookingAccountQueryVariables>(BookingAccountDocument, options);
      }
export function useBookingAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingAccountQuery, BookingAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingAccountQuery, BookingAccountQueryVariables>(BookingAccountDocument, options);
        }
export type BookingAccountQueryHookResult = ReturnType<typeof useBookingAccountQuery>;
export type BookingAccountLazyQueryHookResult = ReturnType<typeof useBookingAccountLazyQuery>;
export type BookingAccountQueryResult = Apollo.QueryResult<BookingAccountQuery, BookingAccountQueryVariables>;
export const CreateBookingAccountDocument = gql`
    mutation createBookingAccount($input: CreateBookingAccountInput!) {
  createBookingAccount(input: $input) {
    __typename
    ... on BookingAccountSuccess {
      id
    }
    ... on BookingAccountError {
      accountCode
      kind
    }
  }
}
    `;
export type CreateBookingAccountMutationFn = Apollo.MutationFunction<CreateBookingAccountMutation, CreateBookingAccountMutationVariables>;

/**
 * __useCreateBookingAccountMutation__
 *
 * To run a mutation, you first call `useCreateBookingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingAccountMutation, { data, loading, error }] = useCreateBookingAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingAccountMutation, CreateBookingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingAccountMutation, CreateBookingAccountMutationVariables>(CreateBookingAccountDocument, options);
      }
export type CreateBookingAccountMutationHookResult = ReturnType<typeof useCreateBookingAccountMutation>;
export type CreateBookingAccountMutationResult = Apollo.MutationResult<CreateBookingAccountMutation>;
export type CreateBookingAccountMutationOptions = Apollo.BaseMutationOptions<CreateBookingAccountMutation, CreateBookingAccountMutationVariables>;
export const UpdateBookingAccountDocument = gql`
    mutation updateBookingAccount($input: UpdateBookingAccountInput!) {
  updateBookingAccount(input: $input) {
    __typename
    ... on BookingAccountSuccess {
      id
    }
    ... on BookingAccountError {
      accountCode
      kind
    }
  }
}
    `;
export type UpdateBookingAccountMutationFn = Apollo.MutationFunction<UpdateBookingAccountMutation, UpdateBookingAccountMutationVariables>;

/**
 * __useUpdateBookingAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBookingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingAccountMutation, { data, loading, error }] = useUpdateBookingAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingAccountMutation, UpdateBookingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingAccountMutation, UpdateBookingAccountMutationVariables>(UpdateBookingAccountDocument, options);
      }
export type UpdateBookingAccountMutationHookResult = ReturnType<typeof useUpdateBookingAccountMutation>;
export type UpdateBookingAccountMutationResult = Apollo.MutationResult<UpdateBookingAccountMutation>;
export type UpdateBookingAccountMutationOptions = Apollo.BaseMutationOptions<UpdateBookingAccountMutation, UpdateBookingAccountMutationVariables>;
export const ArchiveBookingAccountDocument = gql`
    mutation archiveBookingAccount($id: ID!) {
  archiveBookingAccount(id: $id) {
    __typename
    ... on BookingAccountSuccess {
      id
    }
    ... on BookingAccountError {
      accountCode
      kind
    }
  }
}
    `;
export type ArchiveBookingAccountMutationFn = Apollo.MutationFunction<ArchiveBookingAccountMutation, ArchiveBookingAccountMutationVariables>;

/**
 * __useArchiveBookingAccountMutation__
 *
 * To run a mutation, you first call `useArchiveBookingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBookingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBookingAccountMutation, { data, loading, error }] = useArchiveBookingAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveBookingAccountMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveBookingAccountMutation, ArchiveBookingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveBookingAccountMutation, ArchiveBookingAccountMutationVariables>(ArchiveBookingAccountDocument, options);
      }
export type ArchiveBookingAccountMutationHookResult = ReturnType<typeof useArchiveBookingAccountMutation>;
export type ArchiveBookingAccountMutationResult = Apollo.MutationResult<ArchiveBookingAccountMutation>;
export type ArchiveBookingAccountMutationOptions = Apollo.BaseMutationOptions<ArchiveBookingAccountMutation, ArchiveBookingAccountMutationVariables>;
export const ActivateBookingAccountDocument = gql`
    mutation activateBookingAccount($id: ID!) {
  activateBookingAccount(id: $id) {
    __typename
    ... on BookingAccountSuccess {
      id
    }
    ... on BookingAccountError {
      accountCode
      kind
    }
  }
}
    `;
export type ActivateBookingAccountMutationFn = Apollo.MutationFunction<ActivateBookingAccountMutation, ActivateBookingAccountMutationVariables>;

/**
 * __useActivateBookingAccountMutation__
 *
 * To run a mutation, you first call `useActivateBookingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBookingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBookingAccountMutation, { data, loading, error }] = useActivateBookingAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateBookingAccountMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBookingAccountMutation, ActivateBookingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBookingAccountMutation, ActivateBookingAccountMutationVariables>(ActivateBookingAccountDocument, options);
      }
export type ActivateBookingAccountMutationHookResult = ReturnType<typeof useActivateBookingAccountMutation>;
export type ActivateBookingAccountMutationResult = Apollo.MutationResult<ActivateBookingAccountMutation>;
export type ActivateBookingAccountMutationOptions = Apollo.BaseMutationOptions<ActivateBookingAccountMutation, ActivateBookingAccountMutationVariables>;
export const BookingAccountsImportHistoryDocument = gql`
    query bookingAccountsImportHistory {
  bookingAccountsImportHistory {
    id
    organizationId
    file {
      id
      name
      url
    }
    createdBy {
      name
      avatarUrl
    }
    createdAt
  }
}
    `;

/**
 * __useBookingAccountsImportHistoryQuery__
 *
 * To run a query within a React component, call `useBookingAccountsImportHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAccountsImportHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAccountsImportHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingAccountsImportHistoryQuery(baseOptions?: Apollo.QueryHookOptions<BookingAccountsImportHistoryQuery, BookingAccountsImportHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingAccountsImportHistoryQuery, BookingAccountsImportHistoryQueryVariables>(BookingAccountsImportHistoryDocument, options);
      }
export function useBookingAccountsImportHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingAccountsImportHistoryQuery, BookingAccountsImportHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingAccountsImportHistoryQuery, BookingAccountsImportHistoryQueryVariables>(BookingAccountsImportHistoryDocument, options);
        }
export type BookingAccountsImportHistoryQueryHookResult = ReturnType<typeof useBookingAccountsImportHistoryQuery>;
export type BookingAccountsImportHistoryLazyQueryHookResult = ReturnType<typeof useBookingAccountsImportHistoryLazyQuery>;
export type BookingAccountsImportHistoryQueryResult = Apollo.QueryResult<BookingAccountsImportHistoryQuery, BookingAccountsImportHistoryQueryVariables>;
export const BookingAccountSearchDocument = gql`
    query bookingAccountSearch($input: BookingAccountSearchInput!) {
  bookingAccountSearch(input: $input) {
    ...BookingAccountData
  }
}
    ${BookingAccountDataFragmentDoc}`;

/**
 * __useBookingAccountSearchQuery__
 *
 * To run a query within a React component, call `useBookingAccountSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAccountSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAccountSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingAccountSearchQuery(baseOptions: Apollo.QueryHookOptions<BookingAccountSearchQuery, BookingAccountSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingAccountSearchQuery, BookingAccountSearchQueryVariables>(BookingAccountSearchDocument, options);
      }
export function useBookingAccountSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingAccountSearchQuery, BookingAccountSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingAccountSearchQuery, BookingAccountSearchQueryVariables>(BookingAccountSearchDocument, options);
        }
export type BookingAccountSearchQueryHookResult = ReturnType<typeof useBookingAccountSearchQuery>;
export type BookingAccountSearchLazyQueryHookResult = ReturnType<typeof useBookingAccountSearchLazyQuery>;
export type BookingAccountSearchQueryResult = Apollo.QueryResult<BookingAccountSearchQuery, BookingAccountSearchQueryVariables>;
export const BookingAccountPaginationDocument = gql`
    query bookingAccountPagination($input: BookingAccountPaginationInput, $filters: BookingAccountFilterInput, $sortBy: BookingAccountSortInput) {
  bookingAccountPagination(input: $input, filters: $filters, sortBy: $sortBy) {
    records {
      ...BookingAccountData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${BookingAccountDataFragmentDoc}`;

/**
 * __useBookingAccountPaginationQuery__
 *
 * To run a query within a React component, call `useBookingAccountPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAccountPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAccountPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useBookingAccountPaginationQuery(baseOptions?: Apollo.QueryHookOptions<BookingAccountPaginationQuery, BookingAccountPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingAccountPaginationQuery, BookingAccountPaginationQueryVariables>(BookingAccountPaginationDocument, options);
      }
export function useBookingAccountPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingAccountPaginationQuery, BookingAccountPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingAccountPaginationQuery, BookingAccountPaginationQueryVariables>(BookingAccountPaginationDocument, options);
        }
export type BookingAccountPaginationQueryHookResult = ReturnType<typeof useBookingAccountPaginationQuery>;
export type BookingAccountPaginationLazyQueryHookResult = ReturnType<typeof useBookingAccountPaginationLazyQuery>;
export type BookingAccountPaginationQueryResult = Apollo.QueryResult<BookingAccountPaginationQuery, BookingAccountPaginationQueryVariables>;
export const CountBookingAccountsDocument = gql`
    query countBookingAccounts($filters: BookingAccountFilterInput) {
  countBookingAccounts(filters: $filters)
}
    `;

/**
 * __useCountBookingAccountsQuery__
 *
 * To run a query within a React component, call `useCountBookingAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountBookingAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountBookingAccountsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCountBookingAccountsQuery(baseOptions?: Apollo.QueryHookOptions<CountBookingAccountsQuery, CountBookingAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountBookingAccountsQuery, CountBookingAccountsQueryVariables>(CountBookingAccountsDocument, options);
      }
export function useCountBookingAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountBookingAccountsQuery, CountBookingAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountBookingAccountsQuery, CountBookingAccountsQueryVariables>(CountBookingAccountsDocument, options);
        }
export type CountBookingAccountsQueryHookResult = ReturnType<typeof useCountBookingAccountsQuery>;
export type CountBookingAccountsLazyQueryHookResult = ReturnType<typeof useCountBookingAccountsLazyQuery>;
export type CountBookingAccountsQueryResult = Apollo.QueryResult<CountBookingAccountsQuery, CountBookingAccountsQueryVariables>;
export const ContractSubCategoryDocument = gql`
    query contractSubCategory($id: String!) {
  contractSubCategory(id: $id) {
    ...ContractSubCategory
  }
}
    ${ContractSubCategoryFragmentDoc}`;

/**
 * __useContractSubCategoryQuery__
 *
 * To run a query within a React component, call `useContractSubCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractSubCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractSubCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractSubCategoryQuery(baseOptions: Apollo.QueryHookOptions<ContractSubCategoryQuery, ContractSubCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractSubCategoryQuery, ContractSubCategoryQueryVariables>(ContractSubCategoryDocument, options);
      }
export function useContractSubCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractSubCategoryQuery, ContractSubCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractSubCategoryQuery, ContractSubCategoryQueryVariables>(ContractSubCategoryDocument, options);
        }
export type ContractSubCategoryQueryHookResult = ReturnType<typeof useContractSubCategoryQuery>;
export type ContractSubCategoryLazyQueryHookResult = ReturnType<typeof useContractSubCategoryLazyQuery>;
export type ContractSubCategoryQueryResult = Apollo.QueryResult<ContractSubCategoryQuery, ContractSubCategoryQueryVariables>;
export const ContractSubCategoriesWithDocumentCountDocument = gql`
    query contractSubCategoriesWithDocumentCount($input: GetContractSubCategoriesInput) {
  contractSubCategories(input: $input) {
    ...ContractSubCategory
    documentCount
  }
}
    ${ContractSubCategoryFragmentDoc}`;

/**
 * __useContractSubCategoriesWithDocumentCountQuery__
 *
 * To run a query within a React component, call `useContractSubCategoriesWithDocumentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractSubCategoriesWithDocumentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractSubCategoriesWithDocumentCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractSubCategoriesWithDocumentCountQuery(baseOptions?: Apollo.QueryHookOptions<ContractSubCategoriesWithDocumentCountQuery, ContractSubCategoriesWithDocumentCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractSubCategoriesWithDocumentCountQuery, ContractSubCategoriesWithDocumentCountQueryVariables>(ContractSubCategoriesWithDocumentCountDocument, options);
      }
export function useContractSubCategoriesWithDocumentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractSubCategoriesWithDocumentCountQuery, ContractSubCategoriesWithDocumentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractSubCategoriesWithDocumentCountQuery, ContractSubCategoriesWithDocumentCountQueryVariables>(ContractSubCategoriesWithDocumentCountDocument, options);
        }
export type ContractSubCategoriesWithDocumentCountQueryHookResult = ReturnType<typeof useContractSubCategoriesWithDocumentCountQuery>;
export type ContractSubCategoriesWithDocumentCountLazyQueryHookResult = ReturnType<typeof useContractSubCategoriesWithDocumentCountLazyQuery>;
export type ContractSubCategoriesWithDocumentCountQueryResult = Apollo.QueryResult<ContractSubCategoriesWithDocumentCountQuery, ContractSubCategoriesWithDocumentCountQueryVariables>;
export const ContractSubCategoriesDocument = gql`
    query contractSubCategories($input: GetContractSubCategoriesInput) {
  contractSubCategories(input: $input) {
    ...ContractSubCategory
  }
}
    ${ContractSubCategoryFragmentDoc}`;

/**
 * __useContractSubCategoriesQuery__
 *
 * To run a query within a React component, call `useContractSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractSubCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractSubCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ContractSubCategoriesQuery, ContractSubCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractSubCategoriesQuery, ContractSubCategoriesQueryVariables>(ContractSubCategoriesDocument, options);
      }
export function useContractSubCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractSubCategoriesQuery, ContractSubCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractSubCategoriesQuery, ContractSubCategoriesQueryVariables>(ContractSubCategoriesDocument, options);
        }
export type ContractSubCategoriesQueryHookResult = ReturnType<typeof useContractSubCategoriesQuery>;
export type ContractSubCategoriesLazyQueryHookResult = ReturnType<typeof useContractSubCategoriesLazyQuery>;
export type ContractSubCategoriesQueryResult = Apollo.QueryResult<ContractSubCategoriesQuery, ContractSubCategoriesQueryVariables>;
export const CreateContractSubCategoryDocument = gql`
    mutation createContractSubCategory($input: CreateContractSubCategoryInput!) {
  createContractSubCategory(input: $input) {
    ...ContractSubCategory
  }
}
    ${ContractSubCategoryFragmentDoc}`;
export type CreateContractSubCategoryMutationFn = Apollo.MutationFunction<CreateContractSubCategoryMutation, CreateContractSubCategoryMutationVariables>;

/**
 * __useCreateContractSubCategoryMutation__
 *
 * To run a mutation, you first call `useCreateContractSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractSubCategoryMutation, { data, loading, error }] = useCreateContractSubCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateContractSubCategoryMutation, CreateContractSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContractSubCategoryMutation, CreateContractSubCategoryMutationVariables>(CreateContractSubCategoryDocument, options);
      }
export type CreateContractSubCategoryMutationHookResult = ReturnType<typeof useCreateContractSubCategoryMutation>;
export type CreateContractSubCategoryMutationResult = Apollo.MutationResult<CreateContractSubCategoryMutation>;
export type CreateContractSubCategoryMutationOptions = Apollo.BaseMutationOptions<CreateContractSubCategoryMutation, CreateContractSubCategoryMutationVariables>;
export const UpdateContractSubCategoryDocument = gql`
    mutation updateContractSubCategory($input: UpdateContractSubCategoryInput!) {
  updateContractSubCategory(input: $input) {
    ...ContractSubCategory
  }
}
    ${ContractSubCategoryFragmentDoc}`;
export type UpdateContractSubCategoryMutationFn = Apollo.MutationFunction<UpdateContractSubCategoryMutation, UpdateContractSubCategoryMutationVariables>;

/**
 * __useUpdateContractSubCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateContractSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractSubCategoryMutation, { data, loading, error }] = useUpdateContractSubCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContractSubCategoryMutation, UpdateContractSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContractSubCategoryMutation, UpdateContractSubCategoryMutationVariables>(UpdateContractSubCategoryDocument, options);
      }
export type UpdateContractSubCategoryMutationHookResult = ReturnType<typeof useUpdateContractSubCategoryMutation>;
export type UpdateContractSubCategoryMutationResult = Apollo.MutationResult<UpdateContractSubCategoryMutation>;
export type UpdateContractSubCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateContractSubCategoryMutation, UpdateContractSubCategoryMutationVariables>;
export const UpdateContractSubCategoryStatusDocument = gql`
    mutation updateContractSubCategoryStatus($input: UpdateContractSubCategoryStatusInput!) {
  updateContractSubCategoryStatus(input: $input) {
    ...ContractSubCategory
  }
}
    ${ContractSubCategoryFragmentDoc}`;
export type UpdateContractSubCategoryStatusMutationFn = Apollo.MutationFunction<UpdateContractSubCategoryStatusMutation, UpdateContractSubCategoryStatusMutationVariables>;

/**
 * __useUpdateContractSubCategoryStatusMutation__
 *
 * To run a mutation, you first call `useUpdateContractSubCategoryStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractSubCategoryStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractSubCategoryStatusMutation, { data, loading, error }] = useUpdateContractSubCategoryStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractSubCategoryStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContractSubCategoryStatusMutation, UpdateContractSubCategoryStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContractSubCategoryStatusMutation, UpdateContractSubCategoryStatusMutationVariables>(UpdateContractSubCategoryStatusDocument, options);
      }
export type UpdateContractSubCategoryStatusMutationHookResult = ReturnType<typeof useUpdateContractSubCategoryStatusMutation>;
export type UpdateContractSubCategoryStatusMutationResult = Apollo.MutationResult<UpdateContractSubCategoryStatusMutation>;
export type UpdateContractSubCategoryStatusMutationOptions = Apollo.BaseMutationOptions<UpdateContractSubCategoryStatusMutation, UpdateContractSubCategoryStatusMutationVariables>;
export const CostCentersGetForDuplicationCheckDocument = gql`
    query costCentersGetForDuplicationCheck($costCenterCodes: [String!]!, $costCenterType: CostCenterTypes!) {
  costCentersGetForDuplicationCheck(
    costCenterCodes: $costCenterCodes
    costCenterType: $costCenterType
  )
}
    `;

/**
 * __useCostCentersGetForDuplicationCheckQuery__
 *
 * To run a query within a React component, call `useCostCentersGetForDuplicationCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCentersGetForDuplicationCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCentersGetForDuplicationCheckQuery({
 *   variables: {
 *      costCenterCodes: // value for 'costCenterCodes'
 *      costCenterType: // value for 'costCenterType'
 *   },
 * });
 */
export function useCostCentersGetForDuplicationCheckQuery(baseOptions: Apollo.QueryHookOptions<CostCentersGetForDuplicationCheckQuery, CostCentersGetForDuplicationCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCentersGetForDuplicationCheckQuery, CostCentersGetForDuplicationCheckQueryVariables>(CostCentersGetForDuplicationCheckDocument, options);
      }
export function useCostCentersGetForDuplicationCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCentersGetForDuplicationCheckQuery, CostCentersGetForDuplicationCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCentersGetForDuplicationCheckQuery, CostCentersGetForDuplicationCheckQueryVariables>(CostCentersGetForDuplicationCheckDocument, options);
        }
export type CostCentersGetForDuplicationCheckQueryHookResult = ReturnType<typeof useCostCentersGetForDuplicationCheckQuery>;
export type CostCentersGetForDuplicationCheckLazyQueryHookResult = ReturnType<typeof useCostCentersGetForDuplicationCheckLazyQuery>;
export type CostCentersGetForDuplicationCheckQueryResult = Apollo.QueryResult<CostCentersGetForDuplicationCheckQuery, CostCentersGetForDuplicationCheckQueryVariables>;
export const BulkImportCostCentersDocument = gql`
    mutation bulkImportCostCenters($input: [CreateCostCenterInput!]!, $fileId: String) {
  bulkImportCostCenters(input: $input, fileId: $fileId) {
    imported
    duplicates
    errors
  }
}
    `;
export type BulkImportCostCentersMutationFn = Apollo.MutationFunction<BulkImportCostCentersMutation, BulkImportCostCentersMutationVariables>;

/**
 * __useBulkImportCostCentersMutation__
 *
 * To run a mutation, you first call `useBulkImportCostCentersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkImportCostCentersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkImportCostCentersMutation, { data, loading, error }] = useBulkImportCostCentersMutation({
 *   variables: {
 *      input: // value for 'input'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useBulkImportCostCentersMutation(baseOptions?: Apollo.MutationHookOptions<BulkImportCostCentersMutation, BulkImportCostCentersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkImportCostCentersMutation, BulkImportCostCentersMutationVariables>(BulkImportCostCentersDocument, options);
      }
export type BulkImportCostCentersMutationHookResult = ReturnType<typeof useBulkImportCostCentersMutation>;
export type BulkImportCostCentersMutationResult = Apollo.MutationResult<BulkImportCostCentersMutation>;
export type BulkImportCostCentersMutationOptions = Apollo.BaseMutationOptions<BulkImportCostCentersMutation, BulkImportCostCentersMutationVariables>;
export const CostCenterRequestImportFileUploadUrlDocument = gql`
    mutation costCenterRequestImportFileUploadURL($fileName: String!, $contentType: String!) {
  costCenterRequestImportFileUploadURL(
    fileName: $fileName
    contentType: $contentType
  ) {
    id
    url
    postOptions {
      field
      value
    }
  }
}
    `;
export type CostCenterRequestImportFileUploadUrlMutationFn = Apollo.MutationFunction<CostCenterRequestImportFileUploadUrlMutation, CostCenterRequestImportFileUploadUrlMutationVariables>;

/**
 * __useCostCenterRequestImportFileUploadUrlMutation__
 *
 * To run a mutation, you first call `useCostCenterRequestImportFileUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostCenterRequestImportFileUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costCenterRequestImportFileUploadUrlMutation, { data, loading, error }] = useCostCenterRequestImportFileUploadUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useCostCenterRequestImportFileUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CostCenterRequestImportFileUploadUrlMutation, CostCenterRequestImportFileUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CostCenterRequestImportFileUploadUrlMutation, CostCenterRequestImportFileUploadUrlMutationVariables>(CostCenterRequestImportFileUploadUrlDocument, options);
      }
export type CostCenterRequestImportFileUploadUrlMutationHookResult = ReturnType<typeof useCostCenterRequestImportFileUploadUrlMutation>;
export type CostCenterRequestImportFileUploadUrlMutationResult = Apollo.MutationResult<CostCenterRequestImportFileUploadUrlMutation>;
export type CostCenterRequestImportFileUploadUrlMutationOptions = Apollo.BaseMutationOptions<CostCenterRequestImportFileUploadUrlMutation, CostCenterRequestImportFileUploadUrlMutationVariables>;
export const CostCenterGetImportHistoryDocument = gql`
    query costCenterGetImportHistory {
  costCenterGetImportHistory {
    membership {
      name
      avatarUrl
    }
    file {
      name
      url
    }
    importedAt
  }
}
    `;

/**
 * __useCostCenterGetImportHistoryQuery__
 *
 * To run a query within a React component, call `useCostCenterGetImportHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterGetImportHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterGetImportHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCostCenterGetImportHistoryQuery(baseOptions?: Apollo.QueryHookOptions<CostCenterGetImportHistoryQuery, CostCenterGetImportHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterGetImportHistoryQuery, CostCenterGetImportHistoryQueryVariables>(CostCenterGetImportHistoryDocument, options);
      }
export function useCostCenterGetImportHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterGetImportHistoryQuery, CostCenterGetImportHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterGetImportHistoryQuery, CostCenterGetImportHistoryQueryVariables>(CostCenterGetImportHistoryDocument, options);
        }
export type CostCenterGetImportHistoryQueryHookResult = ReturnType<typeof useCostCenterGetImportHistoryQuery>;
export type CostCenterGetImportHistoryLazyQueryHookResult = ReturnType<typeof useCostCenterGetImportHistoryLazyQuery>;
export type CostCenterGetImportHistoryQueryResult = Apollo.QueryResult<CostCenterGetImportHistoryQuery, CostCenterGetImportHistoryQueryVariables>;
export const CreateCostCenter_NewDocument = gql`
    mutation createCostCenter_new($input: CreateCostCenterInput!, $source: String) {
  createCostCenter_new(input: $input, source: $source) {
    __typename
    ... on CostCenterCreated {
      id
    }
    ... on CostCenterAlreadyExists {
      code
    }
  }
}
    `;
export type CreateCostCenter_NewMutationFn = Apollo.MutationFunction<CreateCostCenter_NewMutation, CreateCostCenter_NewMutationVariables>;

/**
 * __useCreateCostCenter_NewMutation__
 *
 * To run a mutation, you first call `useCreateCostCenter_NewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCenter_NewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCenterNewMutation, { data, loading, error }] = useCreateCostCenter_NewMutation({
 *   variables: {
 *      input: // value for 'input'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useCreateCostCenter_NewMutation(baseOptions?: Apollo.MutationHookOptions<CreateCostCenter_NewMutation, CreateCostCenter_NewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCostCenter_NewMutation, CreateCostCenter_NewMutationVariables>(CreateCostCenter_NewDocument, options);
      }
export type CreateCostCenter_NewMutationHookResult = ReturnType<typeof useCreateCostCenter_NewMutation>;
export type CreateCostCenter_NewMutationResult = Apollo.MutationResult<CreateCostCenter_NewMutation>;
export type CreateCostCenter_NewMutationOptions = Apollo.BaseMutationOptions<CreateCostCenter_NewMutation, CreateCostCenter_NewMutationVariables>;
export const UpdateCostCenter_NewDocument = gql`
    mutation updateCostCenter_new($id: ID!, $input: UpdateCostCenterInput!) {
  updateCostCenter_new(id: $id, input: $input) {
    __typename
    ... on CostCenterUpdated {
      id
    }
    ... on CostCenterAlreadyExists {
      code
    }
  }
}
    `;
export type UpdateCostCenter_NewMutationFn = Apollo.MutationFunction<UpdateCostCenter_NewMutation, UpdateCostCenter_NewMutationVariables>;

/**
 * __useUpdateCostCenter_NewMutation__
 *
 * To run a mutation, you first call `useUpdateCostCenter_NewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCenter_NewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCenterNewMutation, { data, loading, error }] = useUpdateCostCenter_NewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCenter_NewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCostCenter_NewMutation, UpdateCostCenter_NewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCostCenter_NewMutation, UpdateCostCenter_NewMutationVariables>(UpdateCostCenter_NewDocument, options);
      }
export type UpdateCostCenter_NewMutationHookResult = ReturnType<typeof useUpdateCostCenter_NewMutation>;
export type UpdateCostCenter_NewMutationResult = Apollo.MutationResult<UpdateCostCenter_NewMutation>;
export type UpdateCostCenter_NewMutationOptions = Apollo.BaseMutationOptions<UpdateCostCenter_NewMutation, UpdateCostCenter_NewMutationVariables>;
export const GetCostCenterDocument = gql`
    query getCostCenter($input: GetCostCenterInput!) {
  costCenter(input: $input) {
    createdAt
    createdBy {
      name
    }
    ...CostCenterData
  }
}
    ${CostCenterDataFragmentDoc}`;

/**
 * __useGetCostCenterQuery__
 *
 * To run a query within a React component, call `useGetCostCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCenterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCostCenterQuery(baseOptions: Apollo.QueryHookOptions<GetCostCenterQuery, GetCostCenterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCostCenterQuery, GetCostCenterQueryVariables>(GetCostCenterDocument, options);
      }
export function useGetCostCenterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCostCenterQuery, GetCostCenterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCostCenterQuery, GetCostCenterQueryVariables>(GetCostCenterDocument, options);
        }
export type GetCostCenterQueryHookResult = ReturnType<typeof useGetCostCenterQuery>;
export type GetCostCenterLazyQueryHookResult = ReturnType<typeof useGetCostCenterLazyQuery>;
export type GetCostCenterQueryResult = Apollo.QueryResult<GetCostCenterQuery, GetCostCenterQueryVariables>;
export const UpdateCostCenterStatusDocument = gql`
    mutation updateCostCenterStatus($id: ID!, $input: UpdateCostCenterInput!) {
  updateCostCenterStatus(id: $id, input: $input) {
    createdAt
    createdBy {
      name
    }
    ...CostCenterData
  }
}
    ${CostCenterDataFragmentDoc}`;
export type UpdateCostCenterStatusMutationFn = Apollo.MutationFunction<UpdateCostCenterStatusMutation, UpdateCostCenterStatusMutationVariables>;

/**
 * __useUpdateCostCenterStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCostCenterStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCenterStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCenterStatusMutation, { data, loading, error }] = useUpdateCostCenterStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCenterStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCostCenterStatusMutation, UpdateCostCenterStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCostCenterStatusMutation, UpdateCostCenterStatusMutationVariables>(UpdateCostCenterStatusDocument, options);
      }
export type UpdateCostCenterStatusMutationHookResult = ReturnType<typeof useUpdateCostCenterStatusMutation>;
export type UpdateCostCenterStatusMutationResult = Apollo.MutationResult<UpdateCostCenterStatusMutation>;
export type UpdateCostCenterStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCostCenterStatusMutation, UpdateCostCenterStatusMutationVariables>;
export const FetchCardIssuerSettlementsDocument = gql`
    query fetchCardIssuerSettlements {
  fetchCardIssuerPaymentSettlements(input: {}) {
    edges {
      node {
        amount {
          currency
          value
        }
        status
        id
        actualPaymentDate
      }
    }
  }
}
    `;

/**
 * __useFetchCardIssuerSettlementsQuery__
 *
 * To run a query within a React component, call `useFetchCardIssuerSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCardIssuerSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCardIssuerSettlementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCardIssuerSettlementsQuery(baseOptions?: Apollo.QueryHookOptions<FetchCardIssuerSettlementsQuery, FetchCardIssuerSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCardIssuerSettlementsQuery, FetchCardIssuerSettlementsQueryVariables>(FetchCardIssuerSettlementsDocument, options);
      }
export function useFetchCardIssuerSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCardIssuerSettlementsQuery, FetchCardIssuerSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCardIssuerSettlementsQuery, FetchCardIssuerSettlementsQueryVariables>(FetchCardIssuerSettlementsDocument, options);
        }
export type FetchCardIssuerSettlementsQueryHookResult = ReturnType<typeof useFetchCardIssuerSettlementsQuery>;
export type FetchCardIssuerSettlementsLazyQueryHookResult = ReturnType<typeof useFetchCardIssuerSettlementsLazyQuery>;
export type FetchCardIssuerSettlementsQueryResult = Apollo.QueryResult<FetchCardIssuerSettlementsQuery, FetchCardIssuerSettlementsQueryVariables>;
export const ApplyForCreditCardsDocument = gql`
    mutation applyForCreditCards($input: ApplyForCreditCardsInput!) {
  applyForCreditCards(input: $input) {
    accepted
  }
}
    `;
export type ApplyForCreditCardsMutationFn = Apollo.MutationFunction<ApplyForCreditCardsMutation, ApplyForCreditCardsMutationVariables>;

/**
 * __useApplyForCreditCardsMutation__
 *
 * To run a mutation, you first call `useApplyForCreditCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForCreditCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForCreditCardsMutation, { data, loading, error }] = useApplyForCreditCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyForCreditCardsMutation(baseOptions?: Apollo.MutationHookOptions<ApplyForCreditCardsMutation, ApplyForCreditCardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyForCreditCardsMutation, ApplyForCreditCardsMutationVariables>(ApplyForCreditCardsDocument, options);
      }
export type ApplyForCreditCardsMutationHookResult = ReturnType<typeof useApplyForCreditCardsMutation>;
export type ApplyForCreditCardsMutationResult = Apollo.MutationResult<ApplyForCreditCardsMutation>;
export type ApplyForCreditCardsMutationOptions = Apollo.BaseMutationOptions<ApplyForCreditCardsMutation, ApplyForCreditCardsMutationVariables>;
export const GetCardIssuerOrganizationOnboardingDocument = gql`
    query getCardIssuerOrganizationOnboarding {
  getCardIssuerOrganizationOnboarding {
    requestedBy {
      firstName
      lastName
      email
    }
    requestedAt
    status
  }
}
    `;

/**
 * __useGetCardIssuerOrganizationOnboardingQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerOrganizationOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerOrganizationOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerOrganizationOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardIssuerOrganizationOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<GetCardIssuerOrganizationOnboardingQuery, GetCardIssuerOrganizationOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerOrganizationOnboardingQuery, GetCardIssuerOrganizationOnboardingQueryVariables>(GetCardIssuerOrganizationOnboardingDocument, options);
      }
export function useGetCardIssuerOrganizationOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerOrganizationOnboardingQuery, GetCardIssuerOrganizationOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerOrganizationOnboardingQuery, GetCardIssuerOrganizationOnboardingQueryVariables>(GetCardIssuerOrganizationOnboardingDocument, options);
        }
export type GetCardIssuerOrganizationOnboardingQueryHookResult = ReturnType<typeof useGetCardIssuerOrganizationOnboardingQuery>;
export type GetCardIssuerOrganizationOnboardingLazyQueryHookResult = ReturnType<typeof useGetCardIssuerOrganizationOnboardingLazyQuery>;
export type GetCardIssuerOrganizationOnboardingQueryResult = Apollo.QueryResult<GetCardIssuerOrganizationOnboardingQuery, GetCardIssuerOrganizationOnboardingQueryVariables>;
export const UpdateDatevCreditCardsLedgerDocument = gql`
    mutation updateDatevCreditCardsLedger($bookingAccount: String, $transitAccount: String) {
  updateDatevCreditCardsLedger(
    creditCardsLedger: {bookingAccount: $bookingAccount, transitAccount: $transitAccount}
  )
}
    `;
export type UpdateDatevCreditCardsLedgerMutationFn = Apollo.MutationFunction<UpdateDatevCreditCardsLedgerMutation, UpdateDatevCreditCardsLedgerMutationVariables>;

/**
 * __useUpdateDatevCreditCardsLedgerMutation__
 *
 * To run a mutation, you first call `useUpdateDatevCreditCardsLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatevCreditCardsLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatevCreditCardsLedgerMutation, { data, loading, error }] = useUpdateDatevCreditCardsLedgerMutation({
 *   variables: {
 *      bookingAccount: // value for 'bookingAccount'
 *      transitAccount: // value for 'transitAccount'
 *   },
 * });
 */
export function useUpdateDatevCreditCardsLedgerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDatevCreditCardsLedgerMutation, UpdateDatevCreditCardsLedgerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDatevCreditCardsLedgerMutation, UpdateDatevCreditCardsLedgerMutationVariables>(UpdateDatevCreditCardsLedgerDocument, options);
      }
export type UpdateDatevCreditCardsLedgerMutationHookResult = ReturnType<typeof useUpdateDatevCreditCardsLedgerMutation>;
export type UpdateDatevCreditCardsLedgerMutationResult = Apollo.MutationResult<UpdateDatevCreditCardsLedgerMutation>;
export type UpdateDatevCreditCardsLedgerMutationOptions = Apollo.BaseMutationOptions<UpdateDatevCreditCardsLedgerMutation, UpdateDatevCreditCardsLedgerMutationVariables>;
export const UpdateCreditCardsLedgerDocument = gql`
    mutation updateCreditCardsLedger($bookingAccount: String, $transitAccount: String) {
  updateCreditCardsLedger(
    creditCardsLedger: {bookingAccount: $bookingAccount, transitAccount: $transitAccount}
  )
}
    `;
export type UpdateCreditCardsLedgerMutationFn = Apollo.MutationFunction<UpdateCreditCardsLedgerMutation, UpdateCreditCardsLedgerMutationVariables>;

/**
 * __useUpdateCreditCardsLedgerMutation__
 *
 * To run a mutation, you first call `useUpdateCreditCardsLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditCardsLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditCardsLedgerMutation, { data, loading, error }] = useUpdateCreditCardsLedgerMutation({
 *   variables: {
 *      bookingAccount: // value for 'bookingAccount'
 *      transitAccount: // value for 'transitAccount'
 *   },
 * });
 */
export function useUpdateCreditCardsLedgerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreditCardsLedgerMutation, UpdateCreditCardsLedgerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreditCardsLedgerMutation, UpdateCreditCardsLedgerMutationVariables>(UpdateCreditCardsLedgerDocument, options);
      }
export type UpdateCreditCardsLedgerMutationHookResult = ReturnType<typeof useUpdateCreditCardsLedgerMutation>;
export type UpdateCreditCardsLedgerMutationResult = Apollo.MutationResult<UpdateCreditCardsLedgerMutation>;
export type UpdateCreditCardsLedgerMutationOptions = Apollo.BaseMutationOptions<UpdateCreditCardsLedgerMutation, UpdateCreditCardsLedgerMutationVariables>;
export const GetProcessingAccountDataForCardManagerDocument = gql`
    query getProcessingAccountDataForCardManager($id: ID) {
  organization(id: $id) {
    creditCardsSettings {
      processingAccount {
        beneficiary
        bankName
        iban
        bic
      }
    }
  }
}
    `;

/**
 * __useGetProcessingAccountDataForCardManagerQuery__
 *
 * To run a query within a React component, call `useGetProcessingAccountDataForCardManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessingAccountDataForCardManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessingAccountDataForCardManagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProcessingAccountDataForCardManagerQuery(baseOptions?: Apollo.QueryHookOptions<GetProcessingAccountDataForCardManagerQuery, GetProcessingAccountDataForCardManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProcessingAccountDataForCardManagerQuery, GetProcessingAccountDataForCardManagerQueryVariables>(GetProcessingAccountDataForCardManagerDocument, options);
      }
export function useGetProcessingAccountDataForCardManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProcessingAccountDataForCardManagerQuery, GetProcessingAccountDataForCardManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProcessingAccountDataForCardManagerQuery, GetProcessingAccountDataForCardManagerQueryVariables>(GetProcessingAccountDataForCardManagerDocument, options);
        }
export type GetProcessingAccountDataForCardManagerQueryHookResult = ReturnType<typeof useGetProcessingAccountDataForCardManagerQuery>;
export type GetProcessingAccountDataForCardManagerLazyQueryHookResult = ReturnType<typeof useGetProcessingAccountDataForCardManagerLazyQuery>;
export type GetProcessingAccountDataForCardManagerQueryResult = Apollo.QueryResult<GetProcessingAccountDataForCardManagerQuery, GetProcessingAccountDataForCardManagerQueryVariables>;
export const SapB1SettingsDocument = gql`
    query sapB1Settings {
  sapB1Settings {
    purchaseInvoiceSeries
    outgoingPaymentSeries
    moneyInTransitGeneralLedgerAccount
    creditCardId
    creditCardGeneralLedgerAccount
    sapB1Version
    connectionDetails {
      connectionStatus
      connectionError {
        errorMessage {
          de
          en
        }
        resolutionMessage {
          de
          en
        }
      }
    }
  }
}
    `;

/**
 * __useSapB1SettingsQuery__
 *
 * To run a query within a React component, call `useSapB1SettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSapB1SettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSapB1SettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSapB1SettingsQuery(baseOptions?: Apollo.QueryHookOptions<SapB1SettingsQuery, SapB1SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SapB1SettingsQuery, SapB1SettingsQueryVariables>(SapB1SettingsDocument, options);
      }
export function useSapB1SettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SapB1SettingsQuery, SapB1SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SapB1SettingsQuery, SapB1SettingsQueryVariables>(SapB1SettingsDocument, options);
        }
export type SapB1SettingsQueryHookResult = ReturnType<typeof useSapB1SettingsQuery>;
export type SapB1SettingsLazyQueryHookResult = ReturnType<typeof useSapB1SettingsLazyQuery>;
export type SapB1SettingsQueryResult = Apollo.QueryResult<SapB1SettingsQuery, SapB1SettingsQueryVariables>;
export const UpdateSapB1SettingsDocument = gql`
    mutation updateSapB1Settings($sapB1Settings: SapB1SettingsInput!) {
  updateSapB1Settings(sapB1Settings: $sapB1Settings) {
    code
  }
}
    `;
export type UpdateSapB1SettingsMutationFn = Apollo.MutationFunction<UpdateSapB1SettingsMutation, UpdateSapB1SettingsMutationVariables>;

/**
 * __useUpdateSapB1SettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSapB1SettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSapB1SettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSapB1SettingsMutation, { data, loading, error }] = useUpdateSapB1SettingsMutation({
 *   variables: {
 *      sapB1Settings: // value for 'sapB1Settings'
 *   },
 * });
 */
export function useUpdateSapB1SettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSapB1SettingsMutation, UpdateSapB1SettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSapB1SettingsMutation, UpdateSapB1SettingsMutationVariables>(UpdateSapB1SettingsDocument, options);
      }
export type UpdateSapB1SettingsMutationHookResult = ReturnType<typeof useUpdateSapB1SettingsMutation>;
export type UpdateSapB1SettingsMutationResult = Apollo.MutationResult<UpdateSapB1SettingsMutation>;
export type UpdateSapB1SettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSapB1SettingsMutation, UpdateSapB1SettingsMutationVariables>;
export const DocumentDataExtractionSettingsDocument = gql`
    query documentDataExtractionSettings {
  documentDataExtractionSettings {
    suggestOutgoingInvoices
  }
}
    `;

/**
 * __useDocumentDataExtractionSettingsQuery__
 *
 * To run a query within a React component, call `useDocumentDataExtractionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDataExtractionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDataExtractionSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentDataExtractionSettingsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentDataExtractionSettingsQuery, DocumentDataExtractionSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDataExtractionSettingsQuery, DocumentDataExtractionSettingsQueryVariables>(DocumentDataExtractionSettingsDocument, options);
      }
export function useDocumentDataExtractionSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDataExtractionSettingsQuery, DocumentDataExtractionSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDataExtractionSettingsQuery, DocumentDataExtractionSettingsQueryVariables>(DocumentDataExtractionSettingsDocument, options);
        }
export type DocumentDataExtractionSettingsQueryHookResult = ReturnType<typeof useDocumentDataExtractionSettingsQuery>;
export type DocumentDataExtractionSettingsLazyQueryHookResult = ReturnType<typeof useDocumentDataExtractionSettingsLazyQuery>;
export type DocumentDataExtractionSettingsQueryResult = Apollo.QueryResult<DocumentDataExtractionSettingsQuery, DocumentDataExtractionSettingsQueryVariables>;
export const UpdateDocumentDataExtractionSettingsDocument = gql`
    mutation updateDocumentDataExtractionSettings($settings: UpdateDocumentDataExtractionSettingsInput!) {
  updateDocumentDataExtractionSettings(settings: $settings)
}
    `;
export type UpdateDocumentDataExtractionSettingsMutationFn = Apollo.MutationFunction<UpdateDocumentDataExtractionSettingsMutation, UpdateDocumentDataExtractionSettingsMutationVariables>;

/**
 * __useUpdateDocumentDataExtractionSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentDataExtractionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentDataExtractionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentDataExtractionSettingsMutation, { data, loading, error }] = useUpdateDocumentDataExtractionSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateDocumentDataExtractionSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentDataExtractionSettingsMutation, UpdateDocumentDataExtractionSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentDataExtractionSettingsMutation, UpdateDocumentDataExtractionSettingsMutationVariables>(UpdateDocumentDataExtractionSettingsDocument, options);
      }
export type UpdateDocumentDataExtractionSettingsMutationHookResult = ReturnType<typeof useUpdateDocumentDataExtractionSettingsMutation>;
export type UpdateDocumentDataExtractionSettingsMutationResult = Apollo.MutationResult<UpdateDocumentDataExtractionSettingsMutation>;
export type UpdateDocumentDataExtractionSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentDataExtractionSettingsMutation, UpdateDocumentDataExtractionSettingsMutationVariables>;
export const TagDocument = gql`
    query tag($id: String!) {
  tag(id: $id) {
    ...Tag
  }
}
    ${TagFragmentDoc}`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const TagsDocument = gql`
    query tags($input: GetTagsInput) {
  tags(input: $input) {
    ...Tag
    documentCount
  }
}
    ${TagFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const CheckTagNameDuplicationDocument = gql`
    query checkTagNameDuplication($name: String!, $isActive: Boolean) {
  tagsForDuplicationCheck(name: $name, isActive: $isActive)
}
    `;

/**
 * __useCheckTagNameDuplicationQuery__
 *
 * To run a query within a React component, call `useCheckTagNameDuplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTagNameDuplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTagNameDuplicationQuery({
 *   variables: {
 *      name: // value for 'name'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useCheckTagNameDuplicationQuery(baseOptions: Apollo.QueryHookOptions<CheckTagNameDuplicationQuery, CheckTagNameDuplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckTagNameDuplicationQuery, CheckTagNameDuplicationQueryVariables>(CheckTagNameDuplicationDocument, options);
      }
export function useCheckTagNameDuplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckTagNameDuplicationQuery, CheckTagNameDuplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckTagNameDuplicationQuery, CheckTagNameDuplicationQueryVariables>(CheckTagNameDuplicationDocument, options);
        }
export type CheckTagNameDuplicationQueryHookResult = ReturnType<typeof useCheckTagNameDuplicationQuery>;
export type CheckTagNameDuplicationLazyQueryHookResult = ReturnType<typeof useCheckTagNameDuplicationLazyQuery>;
export type CheckTagNameDuplicationQueryResult = Apollo.QueryResult<CheckTagNameDuplicationQuery, CheckTagNameDuplicationQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($input: CreateTagInput!) {
  createTag(input: $input) {
    ...Tag
  }
}
    ${TagFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($input: UpdateTagInput!) {
  updateTag(input: $input) {
    ...Tag
  }
}
    ${TagFragmentDoc}`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdateTagStatusDocument = gql`
    mutation updateTagStatus($input: UpdateTagStatusInput!) {
  updateTagStatus(input: $input) {
    ...Tag
  }
}
    ${TagFragmentDoc}`;
export type UpdateTagStatusMutationFn = Apollo.MutationFunction<UpdateTagStatusMutation, UpdateTagStatusMutationVariables>;

/**
 * __useUpdateTagStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTagStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagStatusMutation, { data, loading, error }] = useUpdateTagStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagStatusMutation, UpdateTagStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagStatusMutation, UpdateTagStatusMutationVariables>(UpdateTagStatusDocument, options);
      }
export type UpdateTagStatusMutationHookResult = ReturnType<typeof useUpdateTagStatusMutation>;
export type UpdateTagStatusMutationResult = Apollo.MutationResult<UpdateTagStatusMutation>;
export type UpdateTagStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTagStatusMutation, UpdateTagStatusMutationVariables>;
export const UpdateDocumentTagsDocument = gql`
    mutation updateDocumentTags($input: UpdateDocumentTagsInput!) {
  updateDocumentTags(input: $input) {
    ...Tag
  }
}
    ${TagFragmentDoc}`;
export type UpdateDocumentTagsMutationFn = Apollo.MutationFunction<UpdateDocumentTagsMutation, UpdateDocumentTagsMutationVariables>;

/**
 * __useUpdateDocumentTagsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTagsMutation, { data, loading, error }] = useUpdateDocumentTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentTagsMutation, UpdateDocumentTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentTagsMutation, UpdateDocumentTagsMutationVariables>(UpdateDocumentTagsDocument, options);
      }
export type UpdateDocumentTagsMutationHookResult = ReturnType<typeof useUpdateDocumentTagsMutation>;
export type UpdateDocumentTagsMutationResult = Apollo.MutationResult<UpdateDocumentTagsMutation>;
export type UpdateDocumentTagsMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentTagsMutation, UpdateDocumentTagsMutationVariables>;
export const MergeTagsDocument = gql`
    mutation mergeTags($input: MergeTagsInput!) {
  mergeTags(input: $input) {
    id
  }
}
    `;
export type MergeTagsMutationFn = Apollo.MutationFunction<MergeTagsMutation, MergeTagsMutationVariables>;

/**
 * __useMergeTagsMutation__
 *
 * To run a mutation, you first call `useMergeTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeTagsMutation, { data, loading, error }] = useMergeTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeTagsMutation(baseOptions?: Apollo.MutationHookOptions<MergeTagsMutation, MergeTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeTagsMutation, MergeTagsMutationVariables>(MergeTagsDocument, options);
      }
export type MergeTagsMutationHookResult = ReturnType<typeof useMergeTagsMutation>;
export type MergeTagsMutationResult = Apollo.MutationResult<MergeTagsMutation>;
export type MergeTagsMutationOptions = Apollo.BaseMutationOptions<MergeTagsMutation, MergeTagsMutationVariables>;
export const EcmDocumentTypeSettingsDocument = gql`
    query ecmDocumentTypeSettings($input: GetEcmDocumentTypeSettingsInput!) {
  ecmDocumentTypeSettings(input: $input) {
    ...DocumentTypeSetting
  }
}
    ${DocumentTypeSettingFragmentDoc}`;

/**
 * __useEcmDocumentTypeSettingsQuery__
 *
 * To run a query within a React component, call `useEcmDocumentTypeSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcmDocumentTypeSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcmDocumentTypeSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEcmDocumentTypeSettingsQuery(baseOptions: Apollo.QueryHookOptions<EcmDocumentTypeSettingsQuery, EcmDocumentTypeSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EcmDocumentTypeSettingsQuery, EcmDocumentTypeSettingsQueryVariables>(EcmDocumentTypeSettingsDocument, options);
      }
export function useEcmDocumentTypeSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EcmDocumentTypeSettingsQuery, EcmDocumentTypeSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EcmDocumentTypeSettingsQuery, EcmDocumentTypeSettingsQueryVariables>(EcmDocumentTypeSettingsDocument, options);
        }
export type EcmDocumentTypeSettingsQueryHookResult = ReturnType<typeof useEcmDocumentTypeSettingsQuery>;
export type EcmDocumentTypeSettingsLazyQueryHookResult = ReturnType<typeof useEcmDocumentTypeSettingsLazyQuery>;
export type EcmDocumentTypeSettingsQueryResult = Apollo.QueryResult<EcmDocumentTypeSettingsQuery, EcmDocumentTypeSettingsQueryVariables>;
export const UpdateEcmDocumentTypePreferencesDocument = gql`
    mutation updateEcmDocumentTypePreferences($input: UpdateEcmDocumentTypeSettingsInput!) {
  updateEcmDocumentTypePreferences(input: $input) {
    isVisible
    isNonEcmType
  }
}
    `;
export type UpdateEcmDocumentTypePreferencesMutationFn = Apollo.MutationFunction<UpdateEcmDocumentTypePreferencesMutation, UpdateEcmDocumentTypePreferencesMutationVariables>;

/**
 * __useUpdateEcmDocumentTypePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateEcmDocumentTypePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEcmDocumentTypePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEcmDocumentTypePreferencesMutation, { data, loading, error }] = useUpdateEcmDocumentTypePreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEcmDocumentTypePreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEcmDocumentTypePreferencesMutation, UpdateEcmDocumentTypePreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEcmDocumentTypePreferencesMutation, UpdateEcmDocumentTypePreferencesMutationVariables>(UpdateEcmDocumentTypePreferencesDocument, options);
      }
export type UpdateEcmDocumentTypePreferencesMutationHookResult = ReturnType<typeof useUpdateEcmDocumentTypePreferencesMutation>;
export type UpdateEcmDocumentTypePreferencesMutationResult = Apollo.MutationResult<UpdateEcmDocumentTypePreferencesMutation>;
export type UpdateEcmDocumentTypePreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateEcmDocumentTypePreferencesMutation, UpdateEcmDocumentTypePreferencesMutationVariables>;
export const UpdateDatevClientCashLedgerConfiguredDocument = gql`
    mutation updateDatevClientCashLedgerConfigured($cashLedgerConfigured: Boolean!) {
  updateDatevClientCashLedgerConfigured(
    cashLedgerConfigured: $cashLedgerConfigured
  )
}
    `;
export type UpdateDatevClientCashLedgerConfiguredMutationFn = Apollo.MutationFunction<UpdateDatevClientCashLedgerConfiguredMutation, UpdateDatevClientCashLedgerConfiguredMutationVariables>;

/**
 * __useUpdateDatevClientCashLedgerConfiguredMutation__
 *
 * To run a mutation, you first call `useUpdateDatevClientCashLedgerConfiguredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatevClientCashLedgerConfiguredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatevClientCashLedgerConfiguredMutation, { data, loading, error }] = useUpdateDatevClientCashLedgerConfiguredMutation({
 *   variables: {
 *      cashLedgerConfigured: // value for 'cashLedgerConfigured'
 *   },
 * });
 */
export function useUpdateDatevClientCashLedgerConfiguredMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDatevClientCashLedgerConfiguredMutation, UpdateDatevClientCashLedgerConfiguredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDatevClientCashLedgerConfiguredMutation, UpdateDatevClientCashLedgerConfiguredMutationVariables>(UpdateDatevClientCashLedgerConfiguredDocument, options);
      }
export type UpdateDatevClientCashLedgerConfiguredMutationHookResult = ReturnType<typeof useUpdateDatevClientCashLedgerConfiguredMutation>;
export type UpdateDatevClientCashLedgerConfiguredMutationResult = Apollo.MutationResult<UpdateDatevClientCashLedgerConfiguredMutation>;
export type UpdateDatevClientCashLedgerConfiguredMutationOptions = Apollo.BaseMutationOptions<UpdateDatevClientCashLedgerConfiguredMutation, UpdateDatevClientCashLedgerConfiguredMutationVariables>;
export const SyncDocumentTypesDocument = gql`
    mutation syncDocumentTypes {
  syncDocumentTypes
}
    `;
export type SyncDocumentTypesMutationFn = Apollo.MutationFunction<SyncDocumentTypesMutation, SyncDocumentTypesMutationVariables>;

/**
 * __useSyncDocumentTypesMutation__
 *
 * To run a mutation, you first call `useSyncDocumentTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncDocumentTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncDocumentTypesMutation, { data, loading, error }] = useSyncDocumentTypesMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncDocumentTypesMutation(baseOptions?: Apollo.MutationHookOptions<SyncDocumentTypesMutation, SyncDocumentTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncDocumentTypesMutation, SyncDocumentTypesMutationVariables>(SyncDocumentTypesDocument, options);
      }
export type SyncDocumentTypesMutationHookResult = ReturnType<typeof useSyncDocumentTypesMutation>;
export type SyncDocumentTypesMutationResult = Apollo.MutationResult<SyncDocumentTypesMutation>;
export type SyncDocumentTypesMutationOptions = Apollo.BaseMutationOptions<SyncDocumentTypesMutation, SyncDocumentTypesMutationVariables>;
export const New_DatevSettingsDocument = gql`
    query new_datevSettings {
  new_datevSettings {
    client {
      __typename
      clientNumber
      connectedAt
      connectedBy
      consultantNumber
      exportType
      id
      isDxsoCashLedgerConfigured
      isDxsoJobsAvailable
      isExtfsFilesAvailable
      isBdsPermissionVerified
      name
    }
    documentTypes {
      isActive
      name
      category
      bookingAccount
    }
    creditCardsLedger {
      bookingAccount
      transitAccount
    }
    provisionsLedger {
      provisionAccountNumber
      otherAssetsAccountNumber
    }
    hasAuthTokenForExportDocuments
    hasAuthTokenForReadClients
    connectionType
    chartOfAccount {
      accountLength
      code
    }
    datevCoreDataSource {
      isAccountLengthSourceDatev
      isChartOfAccountCodeSourceDatev
      isFiscalStartDateSourceDatev
    }
    isFiscalYearConfigured
  }
}
    `;

/**
 * __useNew_DatevSettingsQuery__
 *
 * To run a query within a React component, call `useNew_DatevSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNew_DatevSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNew_DatevSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNew_DatevSettingsQuery(baseOptions?: Apollo.QueryHookOptions<New_DatevSettingsQuery, New_DatevSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<New_DatevSettingsQuery, New_DatevSettingsQueryVariables>(New_DatevSettingsDocument, options);
      }
export function useNew_DatevSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<New_DatevSettingsQuery, New_DatevSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<New_DatevSettingsQuery, New_DatevSettingsQueryVariables>(New_DatevSettingsDocument, options);
        }
export type New_DatevSettingsQueryHookResult = ReturnType<typeof useNew_DatevSettingsQuery>;
export type New_DatevSettingsLazyQueryHookResult = ReturnType<typeof useNew_DatevSettingsLazyQuery>;
export type New_DatevSettingsQueryResult = Apollo.QueryResult<New_DatevSettingsQuery, New_DatevSettingsQueryVariables>;
export const CreditCardsLedgerDocument = gql`
    query creditCardsLedger {
  creditCardsLedger {
    bookingAccount
    transitAccount
  }
}
    `;

/**
 * __useCreditCardsLedgerQuery__
 *
 * To run a query within a React component, call `useCreditCardsLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardsLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardsLedgerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditCardsLedgerQuery(baseOptions?: Apollo.QueryHookOptions<CreditCardsLedgerQuery, CreditCardsLedgerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditCardsLedgerQuery, CreditCardsLedgerQueryVariables>(CreditCardsLedgerDocument, options);
      }
export function useCreditCardsLedgerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditCardsLedgerQuery, CreditCardsLedgerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditCardsLedgerQuery, CreditCardsLedgerQueryVariables>(CreditCardsLedgerDocument, options);
        }
export type CreditCardsLedgerQueryHookResult = ReturnType<typeof useCreditCardsLedgerQuery>;
export type CreditCardsLedgerLazyQueryHookResult = ReturnType<typeof useCreditCardsLedgerLazyQuery>;
export type CreditCardsLedgerQueryResult = Apollo.QueryResult<CreditCardsLedgerQuery, CreditCardsLedgerQueryVariables>;
export const New_DatevClientsDocument = gql`
    query new_datevClients {
  new_datevClients {
    clientNumber
    consultantNumber
    id
    name
    isDxsoJobsAvailable
    isDxsoCashLedgerConfigured
    exportType
  }
}
    `;

/**
 * __useNew_DatevClientsQuery__
 *
 * To run a query within a React component, call `useNew_DatevClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNew_DatevClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNew_DatevClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNew_DatevClientsQuery(baseOptions?: Apollo.QueryHookOptions<New_DatevClientsQuery, New_DatevClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<New_DatevClientsQuery, New_DatevClientsQueryVariables>(New_DatevClientsDocument, options);
      }
export function useNew_DatevClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<New_DatevClientsQuery, New_DatevClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<New_DatevClientsQuery, New_DatevClientsQueryVariables>(New_DatevClientsDocument, options);
        }
export type New_DatevClientsQueryHookResult = ReturnType<typeof useNew_DatevClientsQuery>;
export type New_DatevClientsLazyQueryHookResult = ReturnType<typeof useNew_DatevClientsLazyQuery>;
export type New_DatevClientsQueryResult = Apollo.QueryResult<New_DatevClientsQuery, New_DatevClientsQueryVariables>;
export const DatevClientInfoDocument = gql`
    query datevClientInfo {
  datevClientInfo {
    id
    is_document_management_available
    client_number
    consultant_number
    ledgerState {
      cash
      accountsPayable
      accountsReceivable
    }
  }
}
    `;

/**
 * __useDatevClientInfoQuery__
 *
 * To run a query within a React component, call `useDatevClientInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatevClientInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatevClientInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatevClientInfoQuery(baseOptions?: Apollo.QueryHookOptions<DatevClientInfoQuery, DatevClientInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatevClientInfoQuery, DatevClientInfoQueryVariables>(DatevClientInfoDocument, options);
      }
export function useDatevClientInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatevClientInfoQuery, DatevClientInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatevClientInfoQuery, DatevClientInfoQueryVariables>(DatevClientInfoDocument, options);
        }
export type DatevClientInfoQueryHookResult = ReturnType<typeof useDatevClientInfoQuery>;
export type DatevClientInfoLazyQueryHookResult = ReturnType<typeof useDatevClientInfoLazyQuery>;
export type DatevClientInfoQueryResult = Apollo.QueryResult<DatevClientInfoQuery, DatevClientInfoQueryVariables>;
export const AvailableDocumentCategoriesDocument = gql`
    query availableDocumentCategories {
  availableDocumentCategories {
    direction
    documentType
    isDefault
    isRds1_0Exportable
    isBdsExportable
    supportedCurrencies
  }
}
    `;

/**
 * __useAvailableDocumentCategoriesQuery__
 *
 * To run a query within a React component, call `useAvailableDocumentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDocumentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDocumentCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableDocumentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableDocumentCategoriesQuery, AvailableDocumentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableDocumentCategoriesQuery, AvailableDocumentCategoriesQueryVariables>(AvailableDocumentCategoriesDocument, options);
      }
export function useAvailableDocumentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableDocumentCategoriesQuery, AvailableDocumentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableDocumentCategoriesQuery, AvailableDocumentCategoriesQueryVariables>(AvailableDocumentCategoriesDocument, options);
        }
export type AvailableDocumentCategoriesQueryHookResult = ReturnType<typeof useAvailableDocumentCategoriesQuery>;
export type AvailableDocumentCategoriesLazyQueryHookResult = ReturnType<typeof useAvailableDocumentCategoriesLazyQuery>;
export type AvailableDocumentCategoriesQueryResult = Apollo.QueryResult<AvailableDocumentCategoriesQuery, AvailableDocumentCategoriesQueryVariables>;
export const AllDocumentTypesDocument = gql`
    query allDocumentTypes {
  availableDocumentCategories(includeInactiveTypes: true) {
    documentType
    isDefault
  }
}
    `;

/**
 * __useAllDocumentTypesQuery__
 *
 * To run a query within a React component, call `useAllDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<AllDocumentTypesQuery, AllDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDocumentTypesQuery, AllDocumentTypesQueryVariables>(AllDocumentTypesDocument, options);
      }
export function useAllDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDocumentTypesQuery, AllDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDocumentTypesQuery, AllDocumentTypesQueryVariables>(AllDocumentTypesDocument, options);
        }
export type AllDocumentTypesQueryHookResult = ReturnType<typeof useAllDocumentTypesQuery>;
export type AllDocumentTypesLazyQueryHookResult = ReturnType<typeof useAllDocumentTypesLazyQuery>;
export type AllDocumentTypesQueryResult = Apollo.QueryResult<AllDocumentTypesQuery, AllDocumentTypesQueryVariables>;
export const DatevBookingAccountValidationDocument = gql`
    query datevBookingAccountValidation {
  datevBookingAccountValidation {
    minLength
    maxLength
  }
}
    `;

/**
 * __useDatevBookingAccountValidationQuery__
 *
 * To run a query within a React component, call `useDatevBookingAccountValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatevBookingAccountValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatevBookingAccountValidationQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatevBookingAccountValidationQuery(baseOptions?: Apollo.QueryHookOptions<DatevBookingAccountValidationQuery, DatevBookingAccountValidationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatevBookingAccountValidationQuery, DatevBookingAccountValidationQueryVariables>(DatevBookingAccountValidationDocument, options);
      }
export function useDatevBookingAccountValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatevBookingAccountValidationQuery, DatevBookingAccountValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatevBookingAccountValidationQuery, DatevBookingAccountValidationQueryVariables>(DatevBookingAccountValidationDocument, options);
        }
export type DatevBookingAccountValidationQueryHookResult = ReturnType<typeof useDatevBookingAccountValidationQuery>;
export type DatevBookingAccountValidationLazyQueryHookResult = ReturnType<typeof useDatevBookingAccountValidationLazyQuery>;
export type DatevBookingAccountValidationQueryResult = Apollo.QueryResult<DatevBookingAccountValidationQuery, DatevBookingAccountValidationQueryVariables>;
export const DatevAvailableServicesDocument = gql`
    query datevAvailableServices {
  datevAvailableServices {
    service
    warning
  }
}
    `;

/**
 * __useDatevAvailableServicesQuery__
 *
 * To run a query within a React component, call `useDatevAvailableServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatevAvailableServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatevAvailableServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatevAvailableServicesQuery(baseOptions?: Apollo.QueryHookOptions<DatevAvailableServicesQuery, DatevAvailableServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatevAvailableServicesQuery, DatevAvailableServicesQueryVariables>(DatevAvailableServicesDocument, options);
      }
export function useDatevAvailableServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatevAvailableServicesQuery, DatevAvailableServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatevAvailableServicesQuery, DatevAvailableServicesQueryVariables>(DatevAvailableServicesDocument, options);
        }
export type DatevAvailableServicesQueryHookResult = ReturnType<typeof useDatevAvailableServicesQuery>;
export type DatevAvailableServicesLazyQueryHookResult = ReturnType<typeof useDatevAvailableServicesLazyQuery>;
export type DatevAvailableServicesQueryResult = Apollo.QueryResult<DatevAvailableServicesQuery, DatevAvailableServicesQueryVariables>;
export const DatevBdsPreRequisitesDocument = gql`
    query datevBDSPreRequisites {
  datevBDSPreRequisites {
    step
    completed
  }
}
    `;

/**
 * __useDatevBdsPreRequisitesQuery__
 *
 * To run a query within a React component, call `useDatevBdsPreRequisitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatevBdsPreRequisitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatevBdsPreRequisitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatevBdsPreRequisitesQuery(baseOptions?: Apollo.QueryHookOptions<DatevBdsPreRequisitesQuery, DatevBdsPreRequisitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatevBdsPreRequisitesQuery, DatevBdsPreRequisitesQueryVariables>(DatevBdsPreRequisitesDocument, options);
      }
export function useDatevBdsPreRequisitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatevBdsPreRequisitesQuery, DatevBdsPreRequisitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatevBdsPreRequisitesQuery, DatevBdsPreRequisitesQueryVariables>(DatevBdsPreRequisitesDocument, options);
        }
export type DatevBdsPreRequisitesQueryHookResult = ReturnType<typeof useDatevBdsPreRequisitesQuery>;
export type DatevBdsPreRequisitesLazyQueryHookResult = ReturnType<typeof useDatevBdsPreRequisitesLazyQuery>;
export type DatevBdsPreRequisitesQueryResult = Apollo.QueryResult<DatevBdsPreRequisitesQuery, DatevBdsPreRequisitesQueryVariables>;
export const DatevBdsPermissionDocument = gql`
    query datevBdsPermission {
  datevBdsPermission {
    ... on BdsPermissionErrorResult {
      __typename
      status
      error
    }
    ... on BdsPermissionSuccessResult {
      __typename
      status
    }
  }
}
    `;

/**
 * __useDatevBdsPermissionQuery__
 *
 * To run a query within a React component, call `useDatevBdsPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatevBdsPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatevBdsPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatevBdsPermissionQuery(baseOptions?: Apollo.QueryHookOptions<DatevBdsPermissionQuery, DatevBdsPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatevBdsPermissionQuery, DatevBdsPermissionQueryVariables>(DatevBdsPermissionDocument, options);
      }
export function useDatevBdsPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatevBdsPermissionQuery, DatevBdsPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatevBdsPermissionQuery, DatevBdsPermissionQueryVariables>(DatevBdsPermissionDocument, options);
        }
export type DatevBdsPermissionQueryHookResult = ReturnType<typeof useDatevBdsPermissionQuery>;
export type DatevBdsPermissionLazyQueryHookResult = ReturnType<typeof useDatevBdsPermissionLazyQuery>;
export type DatevBdsPermissionQueryResult = Apollo.QueryResult<DatevBdsPermissionQuery, DatevBdsPermissionQueryVariables>;
export const UpdateDatevProvisionsLedgerDocument = gql`
    mutation updateDatevProvisionsLedger($provisionAccountNumber: String, $otherAssetsAccountNumber: String) {
  updateDatevProvisionsLedger(
    provisionsLedger: {provisionAccountNumber: $provisionAccountNumber, otherAssetsAccountNumber: $otherAssetsAccountNumber}
  )
}
    `;
export type UpdateDatevProvisionsLedgerMutationFn = Apollo.MutationFunction<UpdateDatevProvisionsLedgerMutation, UpdateDatevProvisionsLedgerMutationVariables>;

/**
 * __useUpdateDatevProvisionsLedgerMutation__
 *
 * To run a mutation, you first call `useUpdateDatevProvisionsLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatevProvisionsLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatevProvisionsLedgerMutation, { data, loading, error }] = useUpdateDatevProvisionsLedgerMutation({
 *   variables: {
 *      provisionAccountNumber: // value for 'provisionAccountNumber'
 *      otherAssetsAccountNumber: // value for 'otherAssetsAccountNumber'
 *   },
 * });
 */
export function useUpdateDatevProvisionsLedgerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDatevProvisionsLedgerMutation, UpdateDatevProvisionsLedgerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDatevProvisionsLedgerMutation, UpdateDatevProvisionsLedgerMutationVariables>(UpdateDatevProvisionsLedgerDocument, options);
      }
export type UpdateDatevProvisionsLedgerMutationHookResult = ReturnType<typeof useUpdateDatevProvisionsLedgerMutation>;
export type UpdateDatevProvisionsLedgerMutationResult = Apollo.MutationResult<UpdateDatevProvisionsLedgerMutation>;
export type UpdateDatevProvisionsLedgerMutationOptions = Apollo.BaseMutationOptions<UpdateDatevProvisionsLedgerMutation, UpdateDatevProvisionsLedgerMutationVariables>;
export const New_BuildDatevAuthUrlForClientsDocument = gql`
    mutation new_buildDatevAuthUrlForClients($state: String!, $connectionType: DatevConnectionType) {
  new_buildDatevAuthUrlForClients(state: $state, connectionType: $connectionType)
}
    `;
export type New_BuildDatevAuthUrlForClientsMutationFn = Apollo.MutationFunction<New_BuildDatevAuthUrlForClientsMutation, New_BuildDatevAuthUrlForClientsMutationVariables>;

/**
 * __useNew_BuildDatevAuthUrlForClientsMutation__
 *
 * To run a mutation, you first call `useNew_BuildDatevAuthUrlForClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNew_BuildDatevAuthUrlForClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newBuildDatevAuthUrlForClientsMutation, { data, loading, error }] = useNew_BuildDatevAuthUrlForClientsMutation({
 *   variables: {
 *      state: // value for 'state'
 *      connectionType: // value for 'connectionType'
 *   },
 * });
 */
export function useNew_BuildDatevAuthUrlForClientsMutation(baseOptions?: Apollo.MutationHookOptions<New_BuildDatevAuthUrlForClientsMutation, New_BuildDatevAuthUrlForClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<New_BuildDatevAuthUrlForClientsMutation, New_BuildDatevAuthUrlForClientsMutationVariables>(New_BuildDatevAuthUrlForClientsDocument, options);
      }
export type New_BuildDatevAuthUrlForClientsMutationHookResult = ReturnType<typeof useNew_BuildDatevAuthUrlForClientsMutation>;
export type New_BuildDatevAuthUrlForClientsMutationResult = Apollo.MutationResult<New_BuildDatevAuthUrlForClientsMutation>;
export type New_BuildDatevAuthUrlForClientsMutationOptions = Apollo.BaseMutationOptions<New_BuildDatevAuthUrlForClientsMutation, New_BuildDatevAuthUrlForClientsMutationVariables>;
export const New_BuildDatevAuthUrlForExportDocumentsDocument = gql`
    mutation new_buildDatevAuthUrlForExportDocuments($state: String!) {
  new_buildDatevAuthUrlForExportDocuments(state: $state)
}
    `;
export type New_BuildDatevAuthUrlForExportDocumentsMutationFn = Apollo.MutationFunction<New_BuildDatevAuthUrlForExportDocumentsMutation, New_BuildDatevAuthUrlForExportDocumentsMutationVariables>;

/**
 * __useNew_BuildDatevAuthUrlForExportDocumentsMutation__
 *
 * To run a mutation, you first call `useNew_BuildDatevAuthUrlForExportDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNew_BuildDatevAuthUrlForExportDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newBuildDatevAuthUrlForExportDocumentsMutation, { data, loading, error }] = useNew_BuildDatevAuthUrlForExportDocumentsMutation({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useNew_BuildDatevAuthUrlForExportDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<New_BuildDatevAuthUrlForExportDocumentsMutation, New_BuildDatevAuthUrlForExportDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<New_BuildDatevAuthUrlForExportDocumentsMutation, New_BuildDatevAuthUrlForExportDocumentsMutationVariables>(New_BuildDatevAuthUrlForExportDocumentsDocument, options);
      }
export type New_BuildDatevAuthUrlForExportDocumentsMutationHookResult = ReturnType<typeof useNew_BuildDatevAuthUrlForExportDocumentsMutation>;
export type New_BuildDatevAuthUrlForExportDocumentsMutationResult = Apollo.MutationResult<New_BuildDatevAuthUrlForExportDocumentsMutation>;
export type New_BuildDatevAuthUrlForExportDocumentsMutationOptions = Apollo.BaseMutationOptions<New_BuildDatevAuthUrlForExportDocumentsMutation, New_BuildDatevAuthUrlForExportDocumentsMutationVariables>;
export const ConnectDatevClientDocument = gql`
    mutation connectDatevClient($input: ClientInput!) {
  connectDatevClient(input: $input)
}
    `;
export type ConnectDatevClientMutationFn = Apollo.MutationFunction<ConnectDatevClientMutation, ConnectDatevClientMutationVariables>;

/**
 * __useConnectDatevClientMutation__
 *
 * To run a mutation, you first call `useConnectDatevClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectDatevClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectDatevClientMutation, { data, loading, error }] = useConnectDatevClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectDatevClientMutation(baseOptions?: Apollo.MutationHookOptions<ConnectDatevClientMutation, ConnectDatevClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectDatevClientMutation, ConnectDatevClientMutationVariables>(ConnectDatevClientDocument, options);
      }
export type ConnectDatevClientMutationHookResult = ReturnType<typeof useConnectDatevClientMutation>;
export type ConnectDatevClientMutationResult = Apollo.MutationResult<ConnectDatevClientMutation>;
export type ConnectDatevClientMutationOptions = Apollo.BaseMutationOptions<ConnectDatevClientMutation, ConnectDatevClientMutationVariables>;
export const New_UpdateDatevExportTypeDocument = gql`
    mutation new_updateDatevExportType($exportType: DatevClientExportType!) {
  new_updateDatevExportType(exportType: $exportType)
}
    `;
export type New_UpdateDatevExportTypeMutationFn = Apollo.MutationFunction<New_UpdateDatevExportTypeMutation, New_UpdateDatevExportTypeMutationVariables>;

/**
 * __useNew_UpdateDatevExportTypeMutation__
 *
 * To run a mutation, you first call `useNew_UpdateDatevExportTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNew_UpdateDatevExportTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newUpdateDatevExportTypeMutation, { data, loading, error }] = useNew_UpdateDatevExportTypeMutation({
 *   variables: {
 *      exportType: // value for 'exportType'
 *   },
 * });
 */
export function useNew_UpdateDatevExportTypeMutation(baseOptions?: Apollo.MutationHookOptions<New_UpdateDatevExportTypeMutation, New_UpdateDatevExportTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<New_UpdateDatevExportTypeMutation, New_UpdateDatevExportTypeMutationVariables>(New_UpdateDatevExportTypeDocument, options);
      }
export type New_UpdateDatevExportTypeMutationHookResult = ReturnType<typeof useNew_UpdateDatevExportTypeMutation>;
export type New_UpdateDatevExportTypeMutationResult = Apollo.MutationResult<New_UpdateDatevExportTypeMutation>;
export type New_UpdateDatevExportTypeMutationOptions = Apollo.BaseMutationOptions<New_UpdateDatevExportTypeMutation, New_UpdateDatevExportTypeMutationVariables>;
export const DisconnectDatevClientDocument = gql`
    mutation disconnectDatevClient {
  disconnectDatevClient
}
    `;
export type DisconnectDatevClientMutationFn = Apollo.MutationFunction<DisconnectDatevClientMutation, DisconnectDatevClientMutationVariables>;

/**
 * __useDisconnectDatevClientMutation__
 *
 * To run a mutation, you first call `useDisconnectDatevClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectDatevClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectDatevClientMutation, { data, loading, error }] = useDisconnectDatevClientMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectDatevClientMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectDatevClientMutation, DisconnectDatevClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectDatevClientMutation, DisconnectDatevClientMutationVariables>(DisconnectDatevClientDocument, options);
      }
export type DisconnectDatevClientMutationHookResult = ReturnType<typeof useDisconnectDatevClientMutation>;
export type DisconnectDatevClientMutationResult = Apollo.MutationResult<DisconnectDatevClientMutation>;
export type DisconnectDatevClientMutationOptions = Apollo.BaseMutationOptions<DisconnectDatevClientMutation, DisconnectDatevClientMutationVariables>;
export const ActivateDocumentTypeDocument = gql`
    mutation activateDocumentType($documentTypeId: DocumentTypeId!) {
  activateDocumentType(documentTypeId: $documentTypeId)
}
    `;
export type ActivateDocumentTypeMutationFn = Apollo.MutationFunction<ActivateDocumentTypeMutation, ActivateDocumentTypeMutationVariables>;

/**
 * __useActivateDocumentTypeMutation__
 *
 * To run a mutation, you first call `useActivateDocumentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDocumentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDocumentTypeMutation, { data, loading, error }] = useActivateDocumentTypeMutation({
 *   variables: {
 *      documentTypeId: // value for 'documentTypeId'
 *   },
 * });
 */
export function useActivateDocumentTypeMutation(baseOptions?: Apollo.MutationHookOptions<ActivateDocumentTypeMutation, ActivateDocumentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateDocumentTypeMutation, ActivateDocumentTypeMutationVariables>(ActivateDocumentTypeDocument, options);
      }
export type ActivateDocumentTypeMutationHookResult = ReturnType<typeof useActivateDocumentTypeMutation>;
export type ActivateDocumentTypeMutationResult = Apollo.MutationResult<ActivateDocumentTypeMutation>;
export type ActivateDocumentTypeMutationOptions = Apollo.BaseMutationOptions<ActivateDocumentTypeMutation, ActivateDocumentTypeMutationVariables>;
export const DeactivateDocumentTypeDocument = gql`
    mutation deactivateDocumentType($documentTypeId: DocumentTypeId!) {
  deactivateDocumentType(documentTypeId: $documentTypeId)
}
    `;
export type DeactivateDocumentTypeMutationFn = Apollo.MutationFunction<DeactivateDocumentTypeMutation, DeactivateDocumentTypeMutationVariables>;

/**
 * __useDeactivateDocumentTypeMutation__
 *
 * To run a mutation, you first call `useDeactivateDocumentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateDocumentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateDocumentTypeMutation, { data, loading, error }] = useDeactivateDocumentTypeMutation({
 *   variables: {
 *      documentTypeId: // value for 'documentTypeId'
 *   },
 * });
 */
export function useDeactivateDocumentTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateDocumentTypeMutation, DeactivateDocumentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateDocumentTypeMutation, DeactivateDocumentTypeMutationVariables>(DeactivateDocumentTypeDocument, options);
      }
export type DeactivateDocumentTypeMutationHookResult = ReturnType<typeof useDeactivateDocumentTypeMutation>;
export type DeactivateDocumentTypeMutationResult = Apollo.MutationResult<DeactivateDocumentTypeMutation>;
export type DeactivateDocumentTypeMutationOptions = Apollo.BaseMutationOptions<DeactivateDocumentTypeMutation, DeactivateDocumentTypeMutationVariables>;
export const UpdateDocumentTypeBookingAccountDocument = gql`
    mutation updateDocumentTypeBookingAccount($documentTypeId: DocumentTypeId!, $bookingAccount: String) {
  updateDocumentTypeBookingAccount(
    documentTypeId: $documentTypeId
    bookingAccount: $bookingAccount
  )
}
    `;
export type UpdateDocumentTypeBookingAccountMutationFn = Apollo.MutationFunction<UpdateDocumentTypeBookingAccountMutation, UpdateDocumentTypeBookingAccountMutationVariables>;

/**
 * __useUpdateDocumentTypeBookingAccountMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTypeBookingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTypeBookingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTypeBookingAccountMutation, { data, loading, error }] = useUpdateDocumentTypeBookingAccountMutation({
 *   variables: {
 *      documentTypeId: // value for 'documentTypeId'
 *      bookingAccount: // value for 'bookingAccount'
 *   },
 * });
 */
export function useUpdateDocumentTypeBookingAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentTypeBookingAccountMutation, UpdateDocumentTypeBookingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentTypeBookingAccountMutation, UpdateDocumentTypeBookingAccountMutationVariables>(UpdateDocumentTypeBookingAccountDocument, options);
      }
export type UpdateDocumentTypeBookingAccountMutationHookResult = ReturnType<typeof useUpdateDocumentTypeBookingAccountMutation>;
export type UpdateDocumentTypeBookingAccountMutationResult = Apollo.MutationResult<UpdateDocumentTypeBookingAccountMutation>;
export type UpdateDocumentTypeBookingAccountMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentTypeBookingAccountMutation, UpdateDocumentTypeBookingAccountMutationVariables>;
export const UpdateExportConfigurationNewDocument = gql`
    mutation updateExportConfigurationNew($fiscalYearStartDay: Int, $fiscalYearStartMonth: Int) {
  updateExportConfigurationNew(
    input: {fiscalYearStartDay: $fiscalYearStartDay, fiscalYearStartMonth: $fiscalYearStartMonth}
  )
}
    `;
export type UpdateExportConfigurationNewMutationFn = Apollo.MutationFunction<UpdateExportConfigurationNewMutation, UpdateExportConfigurationNewMutationVariables>;

/**
 * __useUpdateExportConfigurationNewMutation__
 *
 * To run a mutation, you first call `useUpdateExportConfigurationNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExportConfigurationNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExportConfigurationNewMutation, { data, loading, error }] = useUpdateExportConfigurationNewMutation({
 *   variables: {
 *      fiscalYearStartDay: // value for 'fiscalYearStartDay'
 *      fiscalYearStartMonth: // value for 'fiscalYearStartMonth'
 *   },
 * });
 */
export function useUpdateExportConfigurationNewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExportConfigurationNewMutation, UpdateExportConfigurationNewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExportConfigurationNewMutation, UpdateExportConfigurationNewMutationVariables>(UpdateExportConfigurationNewDocument, options);
      }
export type UpdateExportConfigurationNewMutationHookResult = ReturnType<typeof useUpdateExportConfigurationNewMutation>;
export type UpdateExportConfigurationNewMutationResult = Apollo.MutationResult<UpdateExportConfigurationNewMutation>;
export type UpdateExportConfigurationNewMutationOptions = Apollo.BaseMutationOptions<UpdateExportConfigurationNewMutation, UpdateExportConfigurationNewMutationVariables>;
export const UpdateDatevChartOfAccountDocument = gql`
    mutation updateDatevChartOfAccount($accountLength: Int, $code: ChartOfAccountCode) {
  updateChartOfAccount(
    chartOfAccountInput: {accountLength: $accountLength, code: $code}
  )
}
    `;
export type UpdateDatevChartOfAccountMutationFn = Apollo.MutationFunction<UpdateDatevChartOfAccountMutation, UpdateDatevChartOfAccountMutationVariables>;

/**
 * __useUpdateDatevChartOfAccountMutation__
 *
 * To run a mutation, you first call `useUpdateDatevChartOfAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatevChartOfAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatevChartOfAccountMutation, { data, loading, error }] = useUpdateDatevChartOfAccountMutation({
 *   variables: {
 *      accountLength: // value for 'accountLength'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUpdateDatevChartOfAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDatevChartOfAccountMutation, UpdateDatevChartOfAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDatevChartOfAccountMutation, UpdateDatevChartOfAccountMutationVariables>(UpdateDatevChartOfAccountDocument, options);
      }
export type UpdateDatevChartOfAccountMutationHookResult = ReturnType<typeof useUpdateDatevChartOfAccountMutation>;
export type UpdateDatevChartOfAccountMutationResult = Apollo.MutationResult<UpdateDatevChartOfAccountMutation>;
export type UpdateDatevChartOfAccountMutationOptions = Apollo.BaseMutationOptions<UpdateDatevChartOfAccountMutation, UpdateDatevChartOfAccountMutationVariables>;
export const SendEmailDocument = gql`
    mutation sendEmail($payload: SendEmailWithoutTemplateArgs!) {
  sendEmail(emailPayload: $payload) {
    _id
    status
  }
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const ImportDatevCoreDataDocument = gql`
    mutation importDatevCoreData {
  importDatevCoreData
}
    `;
export type ImportDatevCoreDataMutationFn = Apollo.MutationFunction<ImportDatevCoreDataMutation, ImportDatevCoreDataMutationVariables>;

/**
 * __useImportDatevCoreDataMutation__
 *
 * To run a mutation, you first call `useImportDatevCoreDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportDatevCoreDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importDatevCoreDataMutation, { data, loading, error }] = useImportDatevCoreDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportDatevCoreDataMutation(baseOptions?: Apollo.MutationHookOptions<ImportDatevCoreDataMutation, ImportDatevCoreDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportDatevCoreDataMutation, ImportDatevCoreDataMutationVariables>(ImportDatevCoreDataDocument, options);
      }
export type ImportDatevCoreDataMutationHookResult = ReturnType<typeof useImportDatevCoreDataMutation>;
export type ImportDatevCoreDataMutationResult = Apollo.MutationResult<ImportDatevCoreDataMutation>;
export type ImportDatevCoreDataMutationOptions = Apollo.BaseMutationOptions<ImportDatevCoreDataMutation, ImportDatevCoreDataMutationVariables>;
export const UpdateDatevBdsPreRequisitesDocument = gql`
    mutation updateDatevBDSPreRequisites($step: BDSPreRequisiteStep!, $completed: Boolean!) {
  updateDatevBDSPreRequisites(
    datevBDSPreRequisites: {step: $step, completed: $completed}
  )
}
    `;
export type UpdateDatevBdsPreRequisitesMutationFn = Apollo.MutationFunction<UpdateDatevBdsPreRequisitesMutation, UpdateDatevBdsPreRequisitesMutationVariables>;

/**
 * __useUpdateDatevBdsPreRequisitesMutation__
 *
 * To run a mutation, you first call `useUpdateDatevBdsPreRequisitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatevBdsPreRequisitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatevBdsPreRequisitesMutation, { data, loading, error }] = useUpdateDatevBdsPreRequisitesMutation({
 *   variables: {
 *      step: // value for 'step'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useUpdateDatevBdsPreRequisitesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDatevBdsPreRequisitesMutation, UpdateDatevBdsPreRequisitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDatevBdsPreRequisitesMutation, UpdateDatevBdsPreRequisitesMutationVariables>(UpdateDatevBdsPreRequisitesDocument, options);
      }
export type UpdateDatevBdsPreRequisitesMutationHookResult = ReturnType<typeof useUpdateDatevBdsPreRequisitesMutation>;
export type UpdateDatevBdsPreRequisitesMutationResult = Apollo.MutationResult<UpdateDatevBdsPreRequisitesMutation>;
export type UpdateDatevBdsPreRequisitesMutationOptions = Apollo.BaseMutationOptions<UpdateDatevBdsPreRequisitesMutation, UpdateDatevBdsPreRequisitesMutationVariables>;
export const CreateTestBdsExtfImportJobDocument = gql`
    mutation createTestBDSExtfImportJob {
  createTestBDSExtfImportJob
}
    `;
export type CreateTestBdsExtfImportJobMutationFn = Apollo.MutationFunction<CreateTestBdsExtfImportJobMutation, CreateTestBdsExtfImportJobMutationVariables>;

/**
 * __useCreateTestBdsExtfImportJobMutation__
 *
 * To run a mutation, you first call `useCreateTestBdsExtfImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestBdsExtfImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestBdsExtfImportJobMutation, { data, loading, error }] = useCreateTestBdsExtfImportJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateTestBdsExtfImportJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateTestBdsExtfImportJobMutation, CreateTestBdsExtfImportJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTestBdsExtfImportJobMutation, CreateTestBdsExtfImportJobMutationVariables>(CreateTestBdsExtfImportJobDocument, options);
      }
export type CreateTestBdsExtfImportJobMutationHookResult = ReturnType<typeof useCreateTestBdsExtfImportJobMutation>;
export type CreateTestBdsExtfImportJobMutationResult = Apollo.MutationResult<CreateTestBdsExtfImportJobMutation>;
export type CreateTestBdsExtfImportJobMutationOptions = Apollo.BaseMutationOptions<CreateTestBdsExtfImportJobMutation, CreateTestBdsExtfImportJobMutationVariables>;
export const SapB1CredentialsDocument = gql`
    query sapB1Credentials {
  sapB1Credentials {
    companyDbId
    serviceLayerUrl
    username
  }
}
    `;

/**
 * __useSapB1CredentialsQuery__
 *
 * To run a query within a React component, call `useSapB1CredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSapB1CredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSapB1CredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSapB1CredentialsQuery(baseOptions?: Apollo.QueryHookOptions<SapB1CredentialsQuery, SapB1CredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SapB1CredentialsQuery, SapB1CredentialsQueryVariables>(SapB1CredentialsDocument, options);
      }
export function useSapB1CredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SapB1CredentialsQuery, SapB1CredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SapB1CredentialsQuery, SapB1CredentialsQueryVariables>(SapB1CredentialsDocument, options);
        }
export type SapB1CredentialsQueryHookResult = ReturnType<typeof useSapB1CredentialsQuery>;
export type SapB1CredentialsLazyQueryHookResult = ReturnType<typeof useSapB1CredentialsLazyQuery>;
export type SapB1CredentialsQueryResult = Apollo.QueryResult<SapB1CredentialsQuery, SapB1CredentialsQueryVariables>;
export const VerifySapB1CredentialsDocument = gql`
    query verifySapB1Credentials($sapB1Credentials: SapB1CredentialsInput) {
  verifySapB1Credentials(sapB1Credentials: $sapB1Credentials) {
    code
  }
}
    `;

/**
 * __useVerifySapB1CredentialsQuery__
 *
 * To run a query within a React component, call `useVerifySapB1CredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifySapB1CredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifySapB1CredentialsQuery({
 *   variables: {
 *      sapB1Credentials: // value for 'sapB1Credentials'
 *   },
 * });
 */
export function useVerifySapB1CredentialsQuery(baseOptions?: Apollo.QueryHookOptions<VerifySapB1CredentialsQuery, VerifySapB1CredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifySapB1CredentialsQuery, VerifySapB1CredentialsQueryVariables>(VerifySapB1CredentialsDocument, options);
      }
export function useVerifySapB1CredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifySapB1CredentialsQuery, VerifySapB1CredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifySapB1CredentialsQuery, VerifySapB1CredentialsQueryVariables>(VerifySapB1CredentialsDocument, options);
        }
export type VerifySapB1CredentialsQueryHookResult = ReturnType<typeof useVerifySapB1CredentialsQuery>;
export type VerifySapB1CredentialsLazyQueryHookResult = ReturnType<typeof useVerifySapB1CredentialsLazyQuery>;
export type VerifySapB1CredentialsQueryResult = Apollo.QueryResult<VerifySapB1CredentialsQuery, VerifySapB1CredentialsQueryVariables>;
export const CreateSapB1CredentialsDocument = gql`
    mutation createSapB1Credentials($sapB1Credentials: SapB1CredentialsInput!) {
  createSapB1Credentials(sapB1Credentials: $sapB1Credentials) {
    code
  }
}
    `;
export type CreateSapB1CredentialsMutationFn = Apollo.MutationFunction<CreateSapB1CredentialsMutation, CreateSapB1CredentialsMutationVariables>;

/**
 * __useCreateSapB1CredentialsMutation__
 *
 * To run a mutation, you first call `useCreateSapB1CredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSapB1CredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSapB1CredentialsMutation, { data, loading, error }] = useCreateSapB1CredentialsMutation({
 *   variables: {
 *      sapB1Credentials: // value for 'sapB1Credentials'
 *   },
 * });
 */
export function useCreateSapB1CredentialsMutation(baseOptions?: Apollo.MutationHookOptions<CreateSapB1CredentialsMutation, CreateSapB1CredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSapB1CredentialsMutation, CreateSapB1CredentialsMutationVariables>(CreateSapB1CredentialsDocument, options);
      }
export type CreateSapB1CredentialsMutationHookResult = ReturnType<typeof useCreateSapB1CredentialsMutation>;
export type CreateSapB1CredentialsMutationResult = Apollo.MutationResult<CreateSapB1CredentialsMutation>;
export type CreateSapB1CredentialsMutationOptions = Apollo.BaseMutationOptions<CreateSapB1CredentialsMutation, CreateSapB1CredentialsMutationVariables>;
export const DeleteSapB1CredentialsDocument = gql`
    mutation deleteSapB1Credentials {
  deleteSapB1Credentials {
    code
  }
}
    `;
export type DeleteSapB1CredentialsMutationFn = Apollo.MutationFunction<DeleteSapB1CredentialsMutation, DeleteSapB1CredentialsMutationVariables>;

/**
 * __useDeleteSapB1CredentialsMutation__
 *
 * To run a mutation, you first call `useDeleteSapB1CredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSapB1CredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSapB1CredentialsMutation, { data, loading, error }] = useDeleteSapB1CredentialsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteSapB1CredentialsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSapB1CredentialsMutation, DeleteSapB1CredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSapB1CredentialsMutation, DeleteSapB1CredentialsMutationVariables>(DeleteSapB1CredentialsDocument, options);
      }
export type DeleteSapB1CredentialsMutationHookResult = ReturnType<typeof useDeleteSapB1CredentialsMutation>;
export type DeleteSapB1CredentialsMutationResult = Apollo.MutationResult<DeleteSapB1CredentialsMutation>;
export type DeleteSapB1CredentialsMutationOptions = Apollo.BaseMutationOptions<DeleteSapB1CredentialsMutation, DeleteSapB1CredentialsMutationVariables>;
export const ReconnectAndVerifySapB1ConnectionDocument = gql`
    mutation reconnectAndVerifySapB1Connection {
  reconnectAndVerifySapB1Connection {
    purchaseInvoiceSeries
    outgoingPaymentSeries
    moneyInTransitGeneralLedgerAccount
    creditCardId
    creditCardGeneralLedgerAccount
    sapB1Version
    connectionDetails {
      connectionStatus
      connectionError {
        errorMessage {
          de
          en
        }
        resolutionMessage {
          de
          en
        }
      }
    }
  }
}
    `;
export type ReconnectAndVerifySapB1ConnectionMutationFn = Apollo.MutationFunction<ReconnectAndVerifySapB1ConnectionMutation, ReconnectAndVerifySapB1ConnectionMutationVariables>;

/**
 * __useReconnectAndVerifySapB1ConnectionMutation__
 *
 * To run a mutation, you first call `useReconnectAndVerifySapB1ConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconnectAndVerifySapB1ConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconnectAndVerifySapB1ConnectionMutation, { data, loading, error }] = useReconnectAndVerifySapB1ConnectionMutation({
 *   variables: {
 *   },
 * });
 */
export function useReconnectAndVerifySapB1ConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ReconnectAndVerifySapB1ConnectionMutation, ReconnectAndVerifySapB1ConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReconnectAndVerifySapB1ConnectionMutation, ReconnectAndVerifySapB1ConnectionMutationVariables>(ReconnectAndVerifySapB1ConnectionDocument, options);
      }
export type ReconnectAndVerifySapB1ConnectionMutationHookResult = ReturnType<typeof useReconnectAndVerifySapB1ConnectionMutation>;
export type ReconnectAndVerifySapB1ConnectionMutationResult = Apollo.MutationResult<ReconnectAndVerifySapB1ConnectionMutation>;
export type ReconnectAndVerifySapB1ConnectionMutationOptions = Apollo.BaseMutationOptions<ReconnectAndVerifySapB1ConnectionMutation, ReconnectAndVerifySapB1ConnectionMutationVariables>;
export const OrganizationsEmailAccountsDocument = gql`
    query organizationsEmailAccounts {
  getOrganizations {
    id
    slug
    emailAccounts {
      records {
        id
        address
        isEnabled
      }
    }
  }
}
    `;

/**
 * __useOrganizationsEmailAccountsQuery__
 *
 * To run a query within a React component, call `useOrganizationsEmailAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsEmailAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsEmailAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsEmailAccountsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsEmailAccountsQuery, OrganizationsEmailAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsEmailAccountsQuery, OrganizationsEmailAccountsQueryVariables>(OrganizationsEmailAccountsDocument, options);
      }
export function useOrganizationsEmailAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsEmailAccountsQuery, OrganizationsEmailAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsEmailAccountsQuery, OrganizationsEmailAccountsQueryVariables>(OrganizationsEmailAccountsDocument, options);
        }
export type OrganizationsEmailAccountsQueryHookResult = ReturnType<typeof useOrganizationsEmailAccountsQuery>;
export type OrganizationsEmailAccountsLazyQueryHookResult = ReturnType<typeof useOrganizationsEmailAccountsLazyQuery>;
export type OrganizationsEmailAccountsQueryResult = Apollo.QueryResult<OrganizationsEmailAccountsQuery, OrganizationsEmailAccountsQueryVariables>;
export const EmailInboxMessageWithAttachmentsDocument = gql`
    query EmailInboxMessageWithAttachments($id: ID!) {
  emailInboxMessage(id: $id) {
    ...Email
    htmlLink
    textLink
    sender
    recipient
    receivedAt
    history {
      id
      type
      createdAt
      events {
        id
        type
        data
      }
    }
    attachments {
      ... on EmailInboxMessageAttachmentWithDocument {
        documentFileName
        documentId
        isEInvoice
        url
        globalDocumentId
        documentType
      }
      ... on EmailInboxMessageAttachmentWithError {
        fileName
        fileUrl
        error {
          ... on AttachmentDocumentRemovedError {
            removedAt
            removedBy
            errorCode
          }
          ... on AttachmentDocumentNotFoundError {
            errorCode
          }
          ... on EmailMessageVirusDetected {
            errorCode
          }
          ... on UnprocessableAttachment {
            errorCode
          }
          ... on MaxAttachmentSizeExceeded {
            errorCode
          }
          ... on UnsupportedAttachmentType {
            errorCode
          }
          ... on UnprocessableXmlAttachment {
            errorCode
          }
          __typename
        }
      }
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useEmailInboxMessageWithAttachmentsQuery__
 *
 * To run a query within a React component, call `useEmailInboxMessageWithAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailInboxMessageWithAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailInboxMessageWithAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailInboxMessageWithAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<EmailInboxMessageWithAttachmentsQuery, EmailInboxMessageWithAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailInboxMessageWithAttachmentsQuery, EmailInboxMessageWithAttachmentsQueryVariables>(EmailInboxMessageWithAttachmentsDocument, options);
      }
export function useEmailInboxMessageWithAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailInboxMessageWithAttachmentsQuery, EmailInboxMessageWithAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailInboxMessageWithAttachmentsQuery, EmailInboxMessageWithAttachmentsQueryVariables>(EmailInboxMessageWithAttachmentsDocument, options);
        }
export type EmailInboxMessageWithAttachmentsQueryHookResult = ReturnType<typeof useEmailInboxMessageWithAttachmentsQuery>;
export type EmailInboxMessageWithAttachmentsLazyQueryHookResult = ReturnType<typeof useEmailInboxMessageWithAttachmentsLazyQuery>;
export type EmailInboxMessageWithAttachmentsQueryResult = Apollo.QueryResult<EmailInboxMessageWithAttachmentsQuery, EmailInboxMessageWithAttachmentsQueryVariables>;
export const EmailInboxMessageDocument = gql`
    query EmailInboxMessage($id: ID!) {
  emailInboxMessage(id: $id) {
    ...Email
    htmlLink
    textLink
    sender
    recipient
    receivedAt
    history {
      id
      type
      createdAt
      events {
        id
        type
        data
      }
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useEmailInboxMessageQuery__
 *
 * To run a query within a React component, call `useEmailInboxMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailInboxMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailInboxMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailInboxMessageQuery(baseOptions: Apollo.QueryHookOptions<EmailInboxMessageQuery, EmailInboxMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailInboxMessageQuery, EmailInboxMessageQueryVariables>(EmailInboxMessageDocument, options);
      }
export function useEmailInboxMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailInboxMessageQuery, EmailInboxMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailInboxMessageQuery, EmailInboxMessageQueryVariables>(EmailInboxMessageDocument, options);
        }
export type EmailInboxMessageQueryHookResult = ReturnType<typeof useEmailInboxMessageQuery>;
export type EmailInboxMessageLazyQueryHookResult = ReturnType<typeof useEmailInboxMessageLazyQuery>;
export type EmailInboxMessageQueryResult = Apollo.QueryResult<EmailInboxMessageQuery, EmailInboxMessageQueryVariables>;
export const EmailInboxPaginationDocument = gql`
    query emailInboxPagination($input: emailInboxPaginationInput, $filters: emailInboxFilterInput, $sortBy: emailInboxSortInput) {
  emailInboxPagination(input: $input, filters: $filters, sortBy: $sortBy) {
    records {
      ...Email
      numberOfGeneratedDocuments
      numberOfErroneousAttachments
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useEmailInboxPaginationQuery__
 *
 * To run a query within a React component, call `useEmailInboxPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailInboxPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailInboxPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useEmailInboxPaginationQuery(baseOptions?: Apollo.QueryHookOptions<EmailInboxPaginationQuery, EmailInboxPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailInboxPaginationQuery, EmailInboxPaginationQueryVariables>(EmailInboxPaginationDocument, options);
      }
export function useEmailInboxPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailInboxPaginationQuery, EmailInboxPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailInboxPaginationQuery, EmailInboxPaginationQueryVariables>(EmailInboxPaginationDocument, options);
        }
export type EmailInboxPaginationQueryHookResult = ReturnType<typeof useEmailInboxPaginationQuery>;
export type EmailInboxPaginationLazyQueryHookResult = ReturnType<typeof useEmailInboxPaginationLazyQuery>;
export type EmailInboxPaginationQueryResult = Apollo.QueryResult<EmailInboxPaginationQuery, EmailInboxPaginationQueryVariables>;
export const EmailInboxInfiniteScrollPaginationDocument = gql`
    query emailInboxInfiniteScrollPagination($input: emailInboxPaginationInput, $filters: emailInboxFilterInput, $sortBy: emailInboxSortInput) {
  emailInboxPagination(input: $input, filters: $filters, sortBy: $sortBy) @connection(key: "emailInboxInfiniteScrollPagination", filter: ["filters", "sortBy"]) {
    records {
      ...Email
      numberOfGeneratedDocuments
      numberOfErroneousAttachments
      attachments {
        ... on EmailInboxMessageAttachmentWithDocument {
          documentFileName
          documentId
          isEInvoice
          url
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${EmailFragmentDoc}`;

/**
 * __useEmailInboxInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useEmailInboxInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailInboxInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailInboxInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useEmailInboxInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<EmailInboxInfiniteScrollPaginationQuery, EmailInboxInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailInboxInfiniteScrollPaginationQuery, EmailInboxInfiniteScrollPaginationQueryVariables>(EmailInboxInfiniteScrollPaginationDocument, options);
      }
export function useEmailInboxInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailInboxInfiniteScrollPaginationQuery, EmailInboxInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailInboxInfiniteScrollPaginationQuery, EmailInboxInfiniteScrollPaginationQueryVariables>(EmailInboxInfiniteScrollPaginationDocument, options);
        }
export type EmailInboxInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useEmailInboxInfiniteScrollPaginationQuery>;
export type EmailInboxInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useEmailInboxInfiniteScrollPaginationLazyQuery>;
export type EmailInboxInfiniteScrollPaginationQueryResult = Apollo.QueryResult<EmailInboxInfiniteScrollPaginationQuery, EmailInboxInfiniteScrollPaginationQueryVariables>;
export const UpdateDocument = gql`
    mutation update($realmName: String, $value: UpdateOrganizationsInput) {
  updateOrganization(realmName: $realmName, input: $value) {
    ...OrganizationData
  }
}
    ${OrganizationDataFragmentDoc}`;
export type UpdateMutationFn = Apollo.MutationFunction<UpdateMutation, UpdateMutationVariables>;

/**
 * __useUpdateMutation__
 *
 * To run a mutation, you first call `useUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMutation, { data, loading, error }] = useUpdateMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMutation, UpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMutation, UpdateMutationVariables>(UpdateDocument, options);
      }
export type UpdateMutationHookResult = ReturnType<typeof useUpdateMutation>;
export type UpdateMutationResult = Apollo.MutationResult<UpdateMutation>;
export type UpdateMutationOptions = Apollo.BaseMutationOptions<UpdateMutation, UpdateMutationVariables>;
export const TransferOwnershipDocument = gql`
    mutation transferOwnership($input: TransferOwnershipInput!) {
  transferOwnership(input: $input) {
    ... on BuiltinRole {
      id
    }
    ... on TransferOwnershipError {
      kind
      message
    }
  }
}
    `;
export type TransferOwnershipMutationFn = Apollo.MutationFunction<TransferOwnershipMutation, TransferOwnershipMutationVariables>;

/**
 * __useTransferOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferOwnershipMutation, { data, loading, error }] = useTransferOwnershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<TransferOwnershipMutation, TransferOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferOwnershipMutation, TransferOwnershipMutationVariables>(TransferOwnershipDocument, options);
      }
export type TransferOwnershipMutationHookResult = ReturnType<typeof useTransferOwnershipMutation>;
export type TransferOwnershipMutationResult = Apollo.MutationResult<TransferOwnershipMutation>;
export type TransferOwnershipMutationOptions = Apollo.BaseMutationOptions<TransferOwnershipMutation, TransferOwnershipMutationVariables>;
export const UpdateOrganizationContactInformationDocument = gql`
    mutation updateOrganizationContactInformation($input: OrganizationContactInformationInput!) {
  organizationContactInformation(input: $input) {
    __typename
    ... on OrganizationContactInformation {
      vatId
      taxNumber
    }
    ... on OrganizationContactInformationErrors {
      errors {
        ... on InvalidVatID {
          kind
          message
        }
        ... on InvalidTaxNumber {
          kind
          message
        }
      }
    }
  }
}
    `;
export type UpdateOrganizationContactInformationMutationFn = Apollo.MutationFunction<UpdateOrganizationContactInformationMutation, UpdateOrganizationContactInformationMutationVariables>;

/**
 * __useUpdateOrganizationContactInformationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationContactInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationContactInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationContactInformationMutation, { data, loading, error }] = useUpdateOrganizationContactInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationContactInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationContactInformationMutation, UpdateOrganizationContactInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationContactInformationMutation, UpdateOrganizationContactInformationMutationVariables>(UpdateOrganizationContactInformationDocument, options);
      }
export type UpdateOrganizationContactInformationMutationHookResult = ReturnType<typeof useUpdateOrganizationContactInformationMutation>;
export type UpdateOrganizationContactInformationMutationResult = Apollo.MutationResult<UpdateOrganizationContactInformationMutation>;
export type UpdateOrganizationContactInformationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationContactInformationMutation, UpdateOrganizationContactInformationMutationVariables>;
export const PaymentConditionDocument = gql`
    query paymentCondition($id: ID!) {
  paymentCondition(id: $id) {
    __typename
    ... on PaymentCondition {
      id
      paymentConditionNumber
      paymentConditionName
      dueDateOffset
      discountOffset
      discountPercentage
      isArchived
    }
    ... on PaymentConditionError {
      kind
      message
    }
  }
}
    `;

/**
 * __usePaymentConditionQuery__
 *
 * To run a query within a React component, call `usePaymentConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConditionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentConditionQuery(baseOptions: Apollo.QueryHookOptions<PaymentConditionQuery, PaymentConditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentConditionQuery, PaymentConditionQueryVariables>(PaymentConditionDocument, options);
      }
export function usePaymentConditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentConditionQuery, PaymentConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentConditionQuery, PaymentConditionQueryVariables>(PaymentConditionDocument, options);
        }
export type PaymentConditionQueryHookResult = ReturnType<typeof usePaymentConditionQuery>;
export type PaymentConditionLazyQueryHookResult = ReturnType<typeof usePaymentConditionLazyQuery>;
export type PaymentConditionQueryResult = Apollo.QueryResult<PaymentConditionQuery, PaymentConditionQueryVariables>;
export const CreatePaymentConditionDocument = gql`
    mutation createPaymentCondition($input: CreatePaymentConditionInput!) {
  createPaymentCondition(input: $input) {
    __typename
    ... on PaymentCondition {
      id
      paymentConditionNumber
      dueDateOffset
      discountOffset
      discountPercentage
    }
    ... on PaymentConditionError {
      kind
      message
    }
  }
}
    `;
export type CreatePaymentConditionMutationFn = Apollo.MutationFunction<CreatePaymentConditionMutation, CreatePaymentConditionMutationVariables>;

/**
 * __useCreatePaymentConditionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentConditionMutation, { data, loading, error }] = useCreatePaymentConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentConditionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentConditionMutation, CreatePaymentConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentConditionMutation, CreatePaymentConditionMutationVariables>(CreatePaymentConditionDocument, options);
      }
export type CreatePaymentConditionMutationHookResult = ReturnType<typeof useCreatePaymentConditionMutation>;
export type CreatePaymentConditionMutationResult = Apollo.MutationResult<CreatePaymentConditionMutation>;
export type CreatePaymentConditionMutationOptions = Apollo.BaseMutationOptions<CreatePaymentConditionMutation, CreatePaymentConditionMutationVariables>;
export const UpdatePaymentConditionDocument = gql`
    mutation updatePaymentCondition($input: UpdatePaymentConditionInput!) {
  updatePaymentCondition(input: $input) {
    __typename
    ... on PaymentCondition {
      id
      paymentConditionNumber
      dueDateOffset
      discountOffset
      discountPercentage
    }
    ... on PaymentConditionError {
      kind
      message
    }
  }
}
    `;
export type UpdatePaymentConditionMutationFn = Apollo.MutationFunction<UpdatePaymentConditionMutation, UpdatePaymentConditionMutationVariables>;

/**
 * __useUpdatePaymentConditionMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentConditionMutation, { data, loading, error }] = useUpdatePaymentConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentConditionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentConditionMutation, UpdatePaymentConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentConditionMutation, UpdatePaymentConditionMutationVariables>(UpdatePaymentConditionDocument, options);
      }
export type UpdatePaymentConditionMutationHookResult = ReturnType<typeof useUpdatePaymentConditionMutation>;
export type UpdatePaymentConditionMutationResult = Apollo.MutationResult<UpdatePaymentConditionMutation>;
export type UpdatePaymentConditionMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentConditionMutation, UpdatePaymentConditionMutationVariables>;
export const ArchivePaymentConditionDocument = gql`
    mutation archivePaymentCondition($id: ID!) {
  archivePaymentCondition(id: $id) {
    __typename
    ... on PaymentCondition {
      id
      isArchived
    }
    ... on PaymentConditionError {
      kind
      message
    }
  }
}
    `;
export type ArchivePaymentConditionMutationFn = Apollo.MutationFunction<ArchivePaymentConditionMutation, ArchivePaymentConditionMutationVariables>;

/**
 * __useArchivePaymentConditionMutation__
 *
 * To run a mutation, you first call `useArchivePaymentConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePaymentConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePaymentConditionMutation, { data, loading, error }] = useArchivePaymentConditionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivePaymentConditionMutation(baseOptions?: Apollo.MutationHookOptions<ArchivePaymentConditionMutation, ArchivePaymentConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchivePaymentConditionMutation, ArchivePaymentConditionMutationVariables>(ArchivePaymentConditionDocument, options);
      }
export type ArchivePaymentConditionMutationHookResult = ReturnType<typeof useArchivePaymentConditionMutation>;
export type ArchivePaymentConditionMutationResult = Apollo.MutationResult<ArchivePaymentConditionMutation>;
export type ArchivePaymentConditionMutationOptions = Apollo.BaseMutationOptions<ArchivePaymentConditionMutation, ArchivePaymentConditionMutationVariables>;
export const RestorePaymentConditionDocument = gql`
    mutation restorePaymentCondition($id: ID!) {
  unarchivePaymentCondition(id: $id) {
    __typename
    ... on PaymentCondition {
      id
      isArchived
    }
    ... on PaymentConditionError {
      kind
      message
    }
  }
}
    `;
export type RestorePaymentConditionMutationFn = Apollo.MutationFunction<RestorePaymentConditionMutation, RestorePaymentConditionMutationVariables>;

/**
 * __useRestorePaymentConditionMutation__
 *
 * To run a mutation, you first call `useRestorePaymentConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePaymentConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePaymentConditionMutation, { data, loading, error }] = useRestorePaymentConditionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestorePaymentConditionMutation(baseOptions?: Apollo.MutationHookOptions<RestorePaymentConditionMutation, RestorePaymentConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestorePaymentConditionMutation, RestorePaymentConditionMutationVariables>(RestorePaymentConditionDocument, options);
      }
export type RestorePaymentConditionMutationHookResult = ReturnType<typeof useRestorePaymentConditionMutation>;
export type RestorePaymentConditionMutationResult = Apollo.MutationResult<RestorePaymentConditionMutation>;
export type RestorePaymentConditionMutationOptions = Apollo.BaseMutationOptions<RestorePaymentConditionMutation, RestorePaymentConditionMutationVariables>;
export const ImportPaymentConditionsDryRunDocument = gql`
    query importPaymentConditionsDryRun($input: [CreatePaymentConditionInput!]!) {
  importPaymentConditionsWithUpdate(input: $input) {
    newValue {
      ... on PaymentCondition {
        __typename
        paymentConditionNumber
        discountOffset
        discountPercentage
        dueDateOffset
      }
      ... on PaymentConditionError {
        __typename
        errorKind: kind
        errorPaymentConditionNumber: paymentConditionNumber
        errorDiscountOffset: discountOffset
        errorDiscountPercentage: discountPercentage
        errorDueDateOffset: dueDateOffset
      }
    }
    oldValue {
      __typename
    }
  }
}
    `;

/**
 * __useImportPaymentConditionsDryRunQuery__
 *
 * To run a query within a React component, call `useImportPaymentConditionsDryRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportPaymentConditionsDryRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportPaymentConditionsDryRunQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportPaymentConditionsDryRunQuery(baseOptions: Apollo.QueryHookOptions<ImportPaymentConditionsDryRunQuery, ImportPaymentConditionsDryRunQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportPaymentConditionsDryRunQuery, ImportPaymentConditionsDryRunQueryVariables>(ImportPaymentConditionsDryRunDocument, options);
      }
export function useImportPaymentConditionsDryRunLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportPaymentConditionsDryRunQuery, ImportPaymentConditionsDryRunQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportPaymentConditionsDryRunQuery, ImportPaymentConditionsDryRunQueryVariables>(ImportPaymentConditionsDryRunDocument, options);
        }
export type ImportPaymentConditionsDryRunQueryHookResult = ReturnType<typeof useImportPaymentConditionsDryRunQuery>;
export type ImportPaymentConditionsDryRunLazyQueryHookResult = ReturnType<typeof useImportPaymentConditionsDryRunLazyQuery>;
export type ImportPaymentConditionsDryRunQueryResult = Apollo.QueryResult<ImportPaymentConditionsDryRunQuery, ImportPaymentConditionsDryRunQueryVariables>;
export const ImportPaymentConditionsDocument = gql`
    mutation importPaymentConditions($input: [CreatePaymentConditionInput!]!, $fileId: String!) {
  importPaymentConditionsWithUpdate(input: $input, fileId: $fileId) {
    newValue {
      ... on PaymentCondition {
        __typename
        id
        paymentConditionNumber
        discountOffset
        discountPercentage
        dueDateOffset
        updatedAt
        updatedBy {
          name
          avatarUrl
        }
      }
      ... on PaymentConditionError {
        __typename
        errorKind: kind
        message
      }
    }
  }
}
    `;
export type ImportPaymentConditionsMutationFn = Apollo.MutationFunction<ImportPaymentConditionsMutation, ImportPaymentConditionsMutationVariables>;

/**
 * __useImportPaymentConditionsMutation__
 *
 * To run a mutation, you first call `useImportPaymentConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPaymentConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPaymentConditionsMutation, { data, loading, error }] = useImportPaymentConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useImportPaymentConditionsMutation(baseOptions?: Apollo.MutationHookOptions<ImportPaymentConditionsMutation, ImportPaymentConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPaymentConditionsMutation, ImportPaymentConditionsMutationVariables>(ImportPaymentConditionsDocument, options);
      }
export type ImportPaymentConditionsMutationHookResult = ReturnType<typeof useImportPaymentConditionsMutation>;
export type ImportPaymentConditionsMutationResult = Apollo.MutationResult<ImportPaymentConditionsMutation>;
export type ImportPaymentConditionsMutationOptions = Apollo.BaseMutationOptions<ImportPaymentConditionsMutation, ImportPaymentConditionsMutationVariables>;
export const PaymentConditionsImportHistoryDocument = gql`
    query paymentConditionsImportHistory {
  paymentConditionsImportHistory {
    id
    file {
      name
      url
      __typename
    }
    errorFile {
      name
      url
      __typename
    }
    importedConditions
    createdBy {
      name
      avatarUrl
      __typename
    }
    createdAt
    status
    __typename
  }
}
    `;

/**
 * __usePaymentConditionsImportHistoryQuery__
 *
 * To run a query within a React component, call `usePaymentConditionsImportHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConditionsImportHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConditionsImportHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentConditionsImportHistoryQuery(baseOptions?: Apollo.QueryHookOptions<PaymentConditionsImportHistoryQuery, PaymentConditionsImportHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentConditionsImportHistoryQuery, PaymentConditionsImportHistoryQueryVariables>(PaymentConditionsImportHistoryDocument, options);
      }
export function usePaymentConditionsImportHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentConditionsImportHistoryQuery, PaymentConditionsImportHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentConditionsImportHistoryQuery, PaymentConditionsImportHistoryQueryVariables>(PaymentConditionsImportHistoryDocument, options);
        }
export type PaymentConditionsImportHistoryQueryHookResult = ReturnType<typeof usePaymentConditionsImportHistoryQuery>;
export type PaymentConditionsImportHistoryLazyQueryHookResult = ReturnType<typeof usePaymentConditionsImportHistoryLazyQuery>;
export type PaymentConditionsImportHistoryQueryResult = Apollo.QueryResult<PaymentConditionsImportHistoryQuery, PaymentConditionsImportHistoryQueryVariables>;
export const PaymentConditionsDocument = gql`
    query paymentConditions($filter: PaymentConditionsFilter!) {
  paymentConditions(filter: $filter) {
    id
    paymentConditionNumber
    paymentConditionName
    dueDateOffset
    discountOffset
    discountPercentage
    updatedAt
    isArchived
    updatedBy {
      name
      avatarUrl
    }
  }
}
    `;

/**
 * __usePaymentConditionsQuery__
 *
 * To run a query within a React component, call `usePaymentConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConditionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaymentConditionsQuery(baseOptions: Apollo.QueryHookOptions<PaymentConditionsQuery, PaymentConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentConditionsQuery, PaymentConditionsQueryVariables>(PaymentConditionsDocument, options);
      }
export function usePaymentConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentConditionsQuery, PaymentConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentConditionsQuery, PaymentConditionsQueryVariables>(PaymentConditionsDocument, options);
        }
export type PaymentConditionsQueryHookResult = ReturnType<typeof usePaymentConditionsQuery>;
export type PaymentConditionsLazyQueryHookResult = ReturnType<typeof usePaymentConditionsLazyQuery>;
export type PaymentConditionsQueryResult = Apollo.QueryResult<PaymentConditionsQuery, PaymentConditionsQueryVariables>;
export const PaymentConditionsCountDocument = gql`
    query paymentConditionsCount($filter: PaymentConditionsFilter!) {
  paymentConditionsCount(filter: $filter)
}
    `;

/**
 * __usePaymentConditionsCountQuery__
 *
 * To run a query within a React component, call `usePaymentConditionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConditionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConditionsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaymentConditionsCountQuery(baseOptions: Apollo.QueryHookOptions<PaymentConditionsCountQuery, PaymentConditionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentConditionsCountQuery, PaymentConditionsCountQueryVariables>(PaymentConditionsCountDocument, options);
      }
export function usePaymentConditionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentConditionsCountQuery, PaymentConditionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentConditionsCountQuery, PaymentConditionsCountQueryVariables>(PaymentConditionsCountDocument, options);
        }
export type PaymentConditionsCountQueryHookResult = ReturnType<typeof usePaymentConditionsCountQuery>;
export type PaymentConditionsCountLazyQueryHookResult = ReturnType<typeof usePaymentConditionsCountLazyQuery>;
export type PaymentConditionsCountQueryResult = Apollo.QueryResult<PaymentConditionsCountQuery, PaymentConditionsCountQueryVariables>;
export const ExportPaymentConditionsDocument = gql`
    mutation exportPaymentConditions {
  exportPaymentConditions {
    ... on PaymentConditionsExportError {
      __typename
      kind
    }
    ... on PaymentConditionsExport {
      __typename
      file {
        url
      }
    }
  }
}
    `;
export type ExportPaymentConditionsMutationFn = Apollo.MutationFunction<ExportPaymentConditionsMutation, ExportPaymentConditionsMutationVariables>;

/**
 * __useExportPaymentConditionsMutation__
 *
 * To run a mutation, you first call `useExportPaymentConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPaymentConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPaymentConditionsMutation, { data, loading, error }] = useExportPaymentConditionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportPaymentConditionsMutation(baseOptions?: Apollo.MutationHookOptions<ExportPaymentConditionsMutation, ExportPaymentConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportPaymentConditionsMutation, ExportPaymentConditionsMutationVariables>(ExportPaymentConditionsDocument, options);
      }
export type ExportPaymentConditionsMutationHookResult = ReturnType<typeof useExportPaymentConditionsMutation>;
export type ExportPaymentConditionsMutationResult = Apollo.MutationResult<ExportPaymentConditionsMutation>;
export type ExportPaymentConditionsMutationOptions = Apollo.BaseMutationOptions<ExportPaymentConditionsMutation, ExportPaymentConditionsMutationVariables>;
export const ExportPaymentConditionsGenericDocument = gql`
    mutation exportPaymentConditionsGeneric {
  exportPaymentConditionsGeneric {
    url
  }
}
    `;
export type ExportPaymentConditionsGenericMutationFn = Apollo.MutationFunction<ExportPaymentConditionsGenericMutation, ExportPaymentConditionsGenericMutationVariables>;

/**
 * __useExportPaymentConditionsGenericMutation__
 *
 * To run a mutation, you first call `useExportPaymentConditionsGenericMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPaymentConditionsGenericMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPaymentConditionsGenericMutation, { data, loading, error }] = useExportPaymentConditionsGenericMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportPaymentConditionsGenericMutation(baseOptions?: Apollo.MutationHookOptions<ExportPaymentConditionsGenericMutation, ExportPaymentConditionsGenericMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportPaymentConditionsGenericMutation, ExportPaymentConditionsGenericMutationVariables>(ExportPaymentConditionsGenericDocument, options);
      }
export type ExportPaymentConditionsGenericMutationHookResult = ReturnType<typeof useExportPaymentConditionsGenericMutation>;
export type ExportPaymentConditionsGenericMutationResult = Apollo.MutationResult<ExportPaymentConditionsGenericMutation>;
export type ExportPaymentConditionsGenericMutationOptions = Apollo.BaseMutationOptions<ExportPaymentConditionsGenericMutation, ExportPaymentConditionsGenericMutationVariables>;
export const CreateIbanDocument = gql`
    mutation createIban($iban: IBAN!, $name: String!, $swiftCode: String) {
  createIban(input: {iban: $iban, name: $name, swiftCode: $swiftCode}) {
    ...BankAccountsIbanData
    ... on IbanError {
      kind
    }
  }
}
    ${BankAccountsIbanDataFragmentDoc}`;
export type CreateIbanMutationFn = Apollo.MutationFunction<CreateIbanMutation, CreateIbanMutationVariables>;

/**
 * __useCreateIbanMutation__
 *
 * To run a mutation, you first call `useCreateIbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIbanMutation, { data, loading, error }] = useCreateIbanMutation({
 *   variables: {
 *      iban: // value for 'iban'
 *      name: // value for 'name'
 *      swiftCode: // value for 'swiftCode'
 *   },
 * });
 */
export function useCreateIbanMutation(baseOptions?: Apollo.MutationHookOptions<CreateIbanMutation, CreateIbanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIbanMutation, CreateIbanMutationVariables>(CreateIbanDocument, options);
      }
export type CreateIbanMutationHookResult = ReturnType<typeof useCreateIbanMutation>;
export type CreateIbanMutationResult = Apollo.MutationResult<CreateIbanMutation>;
export type CreateIbanMutationOptions = Apollo.BaseMutationOptions<CreateIbanMutation, CreateIbanMutationVariables>;
export const UpdateIbanDocument = gql`
    mutation updateIban($id: ID!, $iban: IBAN!, $swiftCode: String, $name: String!) {
  updateIban(id: $id, input: {iban: $iban, swiftCode: $swiftCode, name: $name}) {
    ...BankAccountsIbanData
    ... on IbanError {
      kind
    }
  }
}
    ${BankAccountsIbanDataFragmentDoc}`;
export type UpdateIbanMutationFn = Apollo.MutationFunction<UpdateIbanMutation, UpdateIbanMutationVariables>;

/**
 * __useUpdateIbanMutation__
 *
 * To run a mutation, you first call `useUpdateIbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIbanMutation, { data, loading, error }] = useUpdateIbanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      iban: // value for 'iban'
 *      swiftCode: // value for 'swiftCode'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateIbanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIbanMutation, UpdateIbanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIbanMutation, UpdateIbanMutationVariables>(UpdateIbanDocument, options);
      }
export type UpdateIbanMutationHookResult = ReturnType<typeof useUpdateIbanMutation>;
export type UpdateIbanMutationResult = Apollo.MutationResult<UpdateIbanMutation>;
export type UpdateIbanMutationOptions = Apollo.BaseMutationOptions<UpdateIbanMutation, UpdateIbanMutationVariables>;
export const DeleteIbanDocument = gql`
    mutation deleteIban($id: ID!) {
  deleteIban(id: $id) {
    ... on DeleteIbanSuccessful {
      isDeleted
    }
  }
}
    `;
export type DeleteIbanMutationFn = Apollo.MutationFunction<DeleteIbanMutation, DeleteIbanMutationVariables>;

/**
 * __useDeleteIbanMutation__
 *
 * To run a mutation, you first call `useDeleteIbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIbanMutation, { data, loading, error }] = useDeleteIbanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIbanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIbanMutation, DeleteIbanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIbanMutation, DeleteIbanMutationVariables>(DeleteIbanDocument, options);
      }
export type DeleteIbanMutationHookResult = ReturnType<typeof useDeleteIbanMutation>;
export type DeleteIbanMutationResult = Apollo.MutationResult<DeleteIbanMutation>;
export type DeleteIbanMutationOptions = Apollo.BaseMutationOptions<DeleteIbanMutation, DeleteIbanMutationVariables>;
export const SetIbanAsOrgDefaultDocument = gql`
    mutation setIbanAsOrgDefault($id: ID!) {
  setIbanAsOrgDefault(id: $id) {
    ...BankAccountsIbanData
  }
}
    ${BankAccountsIbanDataFragmentDoc}`;
export type SetIbanAsOrgDefaultMutationFn = Apollo.MutationFunction<SetIbanAsOrgDefaultMutation, SetIbanAsOrgDefaultMutationVariables>;

/**
 * __useSetIbanAsOrgDefaultMutation__
 *
 * To run a mutation, you first call `useSetIbanAsOrgDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIbanAsOrgDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIbanAsOrgDefaultMutation, { data, loading, error }] = useSetIbanAsOrgDefaultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetIbanAsOrgDefaultMutation(baseOptions?: Apollo.MutationHookOptions<SetIbanAsOrgDefaultMutation, SetIbanAsOrgDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIbanAsOrgDefaultMutation, SetIbanAsOrgDefaultMutationVariables>(SetIbanAsOrgDefaultDocument, options);
      }
export type SetIbanAsOrgDefaultMutationHookResult = ReturnType<typeof useSetIbanAsOrgDefaultMutation>;
export type SetIbanAsOrgDefaultMutationResult = Apollo.MutationResult<SetIbanAsOrgDefaultMutation>;
export type SetIbanAsOrgDefaultMutationOptions = Apollo.BaseMutationOptions<SetIbanAsOrgDefaultMutation, SetIbanAsOrgDefaultMutationVariables>;
export const ListIbansDocument = gql`
    query listIbans {
  listIbans {
    ...BankAccountsIbanData
  }
}
    ${BankAccountsIbanDataFragmentDoc}`;

/**
 * __useListIbansQuery__
 *
 * To run a query within a React component, call `useListIbansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIbansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIbansQuery({
 *   variables: {
 *   },
 * });
 */
export function useListIbansQuery(baseOptions?: Apollo.QueryHookOptions<ListIbansQuery, ListIbansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIbansQuery, ListIbansQueryVariables>(ListIbansDocument, options);
      }
export function useListIbansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIbansQuery, ListIbansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIbansQuery, ListIbansQueryVariables>(ListIbansDocument, options);
        }
export type ListIbansQueryHookResult = ReturnType<typeof useListIbansQuery>;
export type ListIbansLazyQueryHookResult = ReturnType<typeof useListIbansLazyQuery>;
export type ListIbansQueryResult = Apollo.QueryResult<ListIbansQuery, ListIbansQueryVariables>;
export const ProjectCodesImportHistoryPaginationDocument = gql`
    query projectCodesImportHistoryPagination($input: ListImportHistoryEntriesInput) {
  importHistories(type: PROJECT_CODE, importHistoryInput: $input) @connection(key: "projectCodesImportHistoryPagination", filter: ["type"]) {
    records {
      _id
      status
      createdAt
      createdBy {
        name
      }
      importErrorDetails {
        errorCode
        errorMessage
        formattedErrorMessage
      }
      successfullyCreatedEntitiesCount
      successfullyUpdatedEntitiesCount
      failedEntities {
        ... on ProjectCodeFailedEntity {
          rawEntity {
            code
            name
          }
        }
      }
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useProjectCodesImportHistoryPaginationQuery__
 *
 * To run a query within a React component, call `useProjectCodesImportHistoryPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCodesImportHistoryPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCodesImportHistoryPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectCodesImportHistoryPaginationQuery(baseOptions?: Apollo.QueryHookOptions<ProjectCodesImportHistoryPaginationQuery, ProjectCodesImportHistoryPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCodesImportHistoryPaginationQuery, ProjectCodesImportHistoryPaginationQueryVariables>(ProjectCodesImportHistoryPaginationDocument, options);
      }
export function useProjectCodesImportHistoryPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCodesImportHistoryPaginationQuery, ProjectCodesImportHistoryPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCodesImportHistoryPaginationQuery, ProjectCodesImportHistoryPaginationQueryVariables>(ProjectCodesImportHistoryPaginationDocument, options);
        }
export type ProjectCodesImportHistoryPaginationQueryHookResult = ReturnType<typeof useProjectCodesImportHistoryPaginationQuery>;
export type ProjectCodesImportHistoryPaginationLazyQueryHookResult = ReturnType<typeof useProjectCodesImportHistoryPaginationLazyQuery>;
export type ProjectCodesImportHistoryPaginationQueryResult = Apollo.QueryResult<ProjectCodesImportHistoryPaginationQuery, ProjectCodesImportHistoryPaginationQueryVariables>;
export const GetProjectCodeImportHistoryByIdDocument = gql`
    query getProjectCodeImportHistoryById($id: String!) {
  importHistoryById(type: PROJECT_CODE, id: $id) {
    _id
    status
    createdAt
    createdBy {
      name
    }
    importErrorDetails {
      errorCode
      errorMessage
      formattedErrorMessage
    }
    successfullyCreatedEntitiesCount
    successfullyUpdatedEntitiesCount
    failedEntities {
      ... on ProjectCodeFailedEntity {
        rawEntity {
          code
          name
        }
        errorDetails {
          errorCode
          errorMessage
          formattedErrorMessage
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectCodeImportHistoryByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectCodeImportHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCodeImportHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCodeImportHistoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectCodeImportHistoryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectCodeImportHistoryByIdQuery, GetProjectCodeImportHistoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectCodeImportHistoryByIdQuery, GetProjectCodeImportHistoryByIdQueryVariables>(GetProjectCodeImportHistoryByIdDocument, options);
      }
export function useGetProjectCodeImportHistoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectCodeImportHistoryByIdQuery, GetProjectCodeImportHistoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectCodeImportHistoryByIdQuery, GetProjectCodeImportHistoryByIdQueryVariables>(GetProjectCodeImportHistoryByIdDocument, options);
        }
export type GetProjectCodeImportHistoryByIdQueryHookResult = ReturnType<typeof useGetProjectCodeImportHistoryByIdQuery>;
export type GetProjectCodeImportHistoryByIdLazyQueryHookResult = ReturnType<typeof useGetProjectCodeImportHistoryByIdLazyQuery>;
export type GetProjectCodeImportHistoryByIdQueryResult = Apollo.QueryResult<GetProjectCodeImportHistoryByIdQuery, GetProjectCodeImportHistoryByIdQueryVariables>;
export const ProjectCodeInfiniteScrollPaginationDocument = gql`
    query projectCodeInfiniteScrollPagination($input: ProjectCodePaginationInput, $filters: ProjectCodeFilterInput, $queries: ProjectCodeQueryInput, $sortBy: ProjectCodeSortInput) {
  projectCodePagination(
    input: $input
    filters: $filters
    queries: $queries
    sortBy: $sortBy
  ) @connection(key: "projectCodeInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      ...ProjectCodeData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${ProjectCodeDataFragmentDoc}`;

/**
 * __useProjectCodeInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useProjectCodeInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCodeInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCodeInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProjectCodeInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<ProjectCodeInfiniteScrollPaginationQuery, ProjectCodeInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCodeInfiniteScrollPaginationQuery, ProjectCodeInfiniteScrollPaginationQueryVariables>(ProjectCodeInfiniteScrollPaginationDocument, options);
      }
export function useProjectCodeInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCodeInfiniteScrollPaginationQuery, ProjectCodeInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCodeInfiniteScrollPaginationQuery, ProjectCodeInfiniteScrollPaginationQueryVariables>(ProjectCodeInfiniteScrollPaginationDocument, options);
        }
export type ProjectCodeInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useProjectCodeInfiniteScrollPaginationQuery>;
export type ProjectCodeInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useProjectCodeInfiniteScrollPaginationLazyQuery>;
export type ProjectCodeInfiniteScrollPaginationQueryResult = Apollo.QueryResult<ProjectCodeInfiniteScrollPaginationQuery, ProjectCodeInfiniteScrollPaginationQueryVariables>;
export const ProjectCodeDocument = gql`
    query projectCode($id: ID!) {
  projectCode(id: $id) {
    ...ProjectCodeData
  }
}
    ${ProjectCodeDataFragmentDoc}`;

/**
 * __useProjectCodeQuery__
 *
 * To run a query within a React component, call `useProjectCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectCodeQuery(baseOptions: Apollo.QueryHookOptions<ProjectCodeQuery, ProjectCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCodeQuery, ProjectCodeQueryVariables>(ProjectCodeDocument, options);
      }
export function useProjectCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCodeQuery, ProjectCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCodeQuery, ProjectCodeQueryVariables>(ProjectCodeDocument, options);
        }
export type ProjectCodeQueryHookResult = ReturnType<typeof useProjectCodeQuery>;
export type ProjectCodeLazyQueryHookResult = ReturnType<typeof useProjectCodeLazyQuery>;
export type ProjectCodeQueryResult = Apollo.QueryResult<ProjectCodeQuery, ProjectCodeQueryVariables>;
export const CountProjectCodesDocument = gql`
    query countProjectCodes($filters: ProjectCodeFilterInput) {
  countProjectCodes(filters: $filters)
}
    `;

/**
 * __useCountProjectCodesQuery__
 *
 * To run a query within a React component, call `useCountProjectCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountProjectCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountProjectCodesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCountProjectCodesQuery(baseOptions?: Apollo.QueryHookOptions<CountProjectCodesQuery, CountProjectCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountProjectCodesQuery, CountProjectCodesQueryVariables>(CountProjectCodesDocument, options);
      }
export function useCountProjectCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountProjectCodesQuery, CountProjectCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountProjectCodesQuery, CountProjectCodesQueryVariables>(CountProjectCodesDocument, options);
        }
export type CountProjectCodesQueryHookResult = ReturnType<typeof useCountProjectCodesQuery>;
export type CountProjectCodesLazyQueryHookResult = ReturnType<typeof useCountProjectCodesLazyQuery>;
export type CountProjectCodesQueryResult = Apollo.QueryResult<CountProjectCodesQuery, CountProjectCodesQueryVariables>;
export const CreateCustomRoleDocument = gql`
    mutation createCustomRole($input: CreateCustomRoleInput!) {
  createCustomRole(input: $input) {
    id
  }
}
    `;
export type CreateCustomRoleMutationFn = Apollo.MutationFunction<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>;

/**
 * __useCreateCustomRoleMutation__
 *
 * To run a mutation, you first call `useCreateCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomRoleMutation, { data, loading, error }] = useCreateCustomRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>(CreateCustomRoleDocument, options);
      }
export type CreateCustomRoleMutationHookResult = ReturnType<typeof useCreateCustomRoleMutation>;
export type CreateCustomRoleMutationResult = Apollo.MutationResult<CreateCustomRoleMutation>;
export type CreateCustomRoleMutationOptions = Apollo.BaseMutationOptions<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>;
export const UpdateCustomRoleDocument = gql`
    mutation updateCustomRole($input: UpdateRoleInput!) {
  updateCustomRole(input: $input) {
    id
    permissions {
      id
      isActive
    }
  }
}
    `;
export type UpdateCustomRoleMutationFn = Apollo.MutationFunction<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>;

/**
 * __useUpdateCustomRoleMutation__
 *
 * To run a mutation, you first call `useUpdateCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomRoleMutation, { data, loading, error }] = useUpdateCustomRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>(UpdateCustomRoleDocument, options);
      }
export type UpdateCustomRoleMutationHookResult = ReturnType<typeof useUpdateCustomRoleMutation>;
export type UpdateCustomRoleMutationResult = Apollo.MutationResult<UpdateCustomRoleMutation>;
export type UpdateCustomRoleMutationOptions = Apollo.BaseMutationOptions<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>;
export const SetRoleIsArchivedDocument = gql`
    mutation setRoleIsArchived($id: ID!, $isArchived: Boolean!) {
  setRoleIsArchived(id: $id, isArchived: $isArchived) {
    id
  }
}
    `;
export type SetRoleIsArchivedMutationFn = Apollo.MutationFunction<SetRoleIsArchivedMutation, SetRoleIsArchivedMutationVariables>;

/**
 * __useSetRoleIsArchivedMutation__
 *
 * To run a mutation, you first call `useSetRoleIsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRoleIsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRoleIsArchivedMutation, { data, loading, error }] = useSetRoleIsArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useSetRoleIsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<SetRoleIsArchivedMutation, SetRoleIsArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRoleIsArchivedMutation, SetRoleIsArchivedMutationVariables>(SetRoleIsArchivedDocument, options);
      }
export type SetRoleIsArchivedMutationHookResult = ReturnType<typeof useSetRoleIsArchivedMutation>;
export type SetRoleIsArchivedMutationResult = Apollo.MutationResult<SetRoleIsArchivedMutation>;
export type SetRoleIsArchivedMutationOptions = Apollo.BaseMutationOptions<SetRoleIsArchivedMutation, SetRoleIsArchivedMutationVariables>;
export const GetRolesDocument = gql`
    query getRoles($input: RolesPageBasedPaginationInput!, $queries: RolesQueryInput, $filters: RolesFiltersInput, $sortBy: RolesSortInput) {
  roles(input: $input, queries: $queries, filters: $filters, sortBy: $sortBy) {
    records {
      __typename
      id
      description
      members {
        id
      }
      isArchived
      permissions {
        id
      }
      ...BuiltInRoleData
      ...CustomRoleData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${BuiltInRoleDataFragmentDoc}
${CustomRoleDataFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const RoleByNameDocument = gql`
    query roleByName($input: RolesPageBasedPaginationInput!, $name: String) {
  roleByName: roles(input: $input, filters: {name: $name}) @connection(key: "roleByName", filter: ["filters"]) {
    records {
      __typename
      id
      ...BuiltInRoleData
      ...CustomRoleData
    }
  }
}
    ${BuiltInRoleDataFragmentDoc}
${CustomRoleDataFragmentDoc}`;

/**
 * __useRoleByNameQuery__
 *
 * To run a query within a React component, call `useRoleByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRoleByNameQuery(baseOptions: Apollo.QueryHookOptions<RoleByNameQuery, RoleByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleByNameQuery, RoleByNameQueryVariables>(RoleByNameDocument, options);
      }
export function useRoleByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleByNameQuery, RoleByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleByNameQuery, RoleByNameQueryVariables>(RoleByNameDocument, options);
        }
export type RoleByNameQueryHookResult = ReturnType<typeof useRoleByNameQuery>;
export type RoleByNameLazyQueryHookResult = ReturnType<typeof useRoleByNameLazyQuery>;
export type RoleByNameQueryResult = Apollo.QueryResult<RoleByNameQuery, RoleByNameQueryVariables>;
export const GetRoleByIdDocument = gql`
    query getRoleById($id: ID!) {
  role(id: $id) {
    id
    description
    isArchived
    members {
      id
      name
      avatarUrl
    }
    permissions {
      ...PermissionData
    }
    ...BuiltInRoleData
    ...CustomRoleData
  }
}
    ${PermissionDataFragmentDoc}
${BuiltInRoleDataFragmentDoc}
${CustomRoleDataFragmentDoc}`;

/**
 * __useGetRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRoleByIdQuery, GetRoleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
      }
export function useGetRoleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleByIdQuery, GetRoleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
        }
export type GetRoleByIdQueryHookResult = ReturnType<typeof useGetRoleByIdQuery>;
export type GetRoleByIdLazyQueryHookResult = ReturnType<typeof useGetRoleByIdLazyQuery>;
export type GetRoleByIdQueryResult = Apollo.QueryResult<GetRoleByIdQuery, GetRoleByIdQueryVariables>;
export const GetPermissionsForNewRoleDocument = gql`
    query getPermissionsForNewRole($filters: PermissionFilterInput) {
  permissionsForRole(filters: $filters) {
    permissions {
      ...PermissionData
    }
  }
}
    ${PermissionDataFragmentDoc}`;

/**
 * __useGetPermissionsForNewRoleQuery__
 *
 * To run a query within a React component, call `useGetPermissionsForNewRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsForNewRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsForNewRoleQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPermissionsForNewRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsForNewRoleQuery, GetPermissionsForNewRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsForNewRoleQuery, GetPermissionsForNewRoleQueryVariables>(GetPermissionsForNewRoleDocument, options);
      }
export function useGetPermissionsForNewRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsForNewRoleQuery, GetPermissionsForNewRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsForNewRoleQuery, GetPermissionsForNewRoleQueryVariables>(GetPermissionsForNewRoleDocument, options);
        }
export type GetPermissionsForNewRoleQueryHookResult = ReturnType<typeof useGetPermissionsForNewRoleQuery>;
export type GetPermissionsForNewRoleLazyQueryHookResult = ReturnType<typeof useGetPermissionsForNewRoleLazyQuery>;
export type GetPermissionsForNewRoleQueryResult = Apollo.QueryResult<GetPermissionsForNewRoleQuery, GetPermissionsForNewRoleQueryVariables>;
export const UpdateOrganizationSsoConfigurationDocument = gql`
    mutation updateOrganizationSSOConfiguration($ssoConfigurationId: String!, $organizationSlug: String!) {
  updateOrganizationSSOConfiguration(
    ssoConfigurationId: $ssoConfigurationId
    organizationSlug: $organizationSlug
  )
}
    `;
export type UpdateOrganizationSsoConfigurationMutationFn = Apollo.MutationFunction<UpdateOrganizationSsoConfigurationMutation, UpdateOrganizationSsoConfigurationMutationVariables>;

/**
 * __useUpdateOrganizationSsoConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSsoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSsoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSsoConfigurationMutation, { data, loading, error }] = useUpdateOrganizationSsoConfigurationMutation({
 *   variables: {
 *      ssoConfigurationId: // value for 'ssoConfigurationId'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useUpdateOrganizationSsoConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationSsoConfigurationMutation, UpdateOrganizationSsoConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationSsoConfigurationMutation, UpdateOrganizationSsoConfigurationMutationVariables>(UpdateOrganizationSsoConfigurationDocument, options);
      }
export type UpdateOrganizationSsoConfigurationMutationHookResult = ReturnType<typeof useUpdateOrganizationSsoConfigurationMutation>;
export type UpdateOrganizationSsoConfigurationMutationResult = Apollo.MutationResult<UpdateOrganizationSsoConfigurationMutation>;
export type UpdateOrganizationSsoConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationSsoConfigurationMutation, UpdateOrganizationSsoConfigurationMutationVariables>;
export const RemoveOrganizationSsoConfigurationDocument = gql`
    mutation removeOrganizationSSOConfiguration($organizationSlug: String!) {
  removeOrganizationSSOConfiguration(organizationSlug: $organizationSlug)
}
    `;
export type RemoveOrganizationSsoConfigurationMutationFn = Apollo.MutationFunction<RemoveOrganizationSsoConfigurationMutation, RemoveOrganizationSsoConfigurationMutationVariables>;

/**
 * __useRemoveOrganizationSsoConfigurationMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationSsoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationSsoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationSsoConfigurationMutation, { data, loading, error }] = useRemoveOrganizationSsoConfigurationMutation({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useRemoveOrganizationSsoConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganizationSsoConfigurationMutation, RemoveOrganizationSsoConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganizationSsoConfigurationMutation, RemoveOrganizationSsoConfigurationMutationVariables>(RemoveOrganizationSsoConfigurationDocument, options);
      }
export type RemoveOrganizationSsoConfigurationMutationHookResult = ReturnType<typeof useRemoveOrganizationSsoConfigurationMutation>;
export type RemoveOrganizationSsoConfigurationMutationResult = Apollo.MutationResult<RemoveOrganizationSsoConfigurationMutation>;
export type RemoveOrganizationSsoConfigurationMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizationSsoConfigurationMutation, RemoveOrganizationSsoConfigurationMutationVariables>;
export const CreateIdentityProviderDocument = gql`
    mutation createIdentityProvider($input: IdentityProviderInput!) {
  createIdentityProvider(input: $input) {
    idpAlias
    ssoConfigurationId
  }
}
    `;
export type CreateIdentityProviderMutationFn = Apollo.MutationFunction<CreateIdentityProviderMutation, CreateIdentityProviderMutationVariables>;

/**
 * __useCreateIdentityProviderMutation__
 *
 * To run a mutation, you first call `useCreateIdentityProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdentityProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdentityProviderMutation, { data, loading, error }] = useCreateIdentityProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIdentityProviderMutation(baseOptions?: Apollo.MutationHookOptions<CreateIdentityProviderMutation, CreateIdentityProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIdentityProviderMutation, CreateIdentityProviderMutationVariables>(CreateIdentityProviderDocument, options);
      }
export type CreateIdentityProviderMutationHookResult = ReturnType<typeof useCreateIdentityProviderMutation>;
export type CreateIdentityProviderMutationResult = Apollo.MutationResult<CreateIdentityProviderMutation>;
export type CreateIdentityProviderMutationOptions = Apollo.BaseMutationOptions<CreateIdentityProviderMutation, CreateIdentityProviderMutationVariables>;
export const UpdateIdentityProviderDocument = gql`
    mutation updateIdentityProvider($ssoConfigurationId: String!, $input: IdentityProviderInput!) {
  updateIdentityProvider(ssoConfigurationId: $ssoConfigurationId, input: $input)
}
    `;
export type UpdateIdentityProviderMutationFn = Apollo.MutationFunction<UpdateIdentityProviderMutation, UpdateIdentityProviderMutationVariables>;

/**
 * __useUpdateIdentityProviderMutation__
 *
 * To run a mutation, you first call `useUpdateIdentityProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdentityProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentityProviderMutation, { data, loading, error }] = useUpdateIdentityProviderMutation({
 *   variables: {
 *      ssoConfigurationId: // value for 'ssoConfigurationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdentityProviderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIdentityProviderMutation, UpdateIdentityProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIdentityProviderMutation, UpdateIdentityProviderMutationVariables>(UpdateIdentityProviderDocument, options);
      }
export type UpdateIdentityProviderMutationHookResult = ReturnType<typeof useUpdateIdentityProviderMutation>;
export type UpdateIdentityProviderMutationResult = Apollo.MutationResult<UpdateIdentityProviderMutation>;
export type UpdateIdentityProviderMutationOptions = Apollo.BaseMutationOptions<UpdateIdentityProviderMutation, UpdateIdentityProviderMutationVariables>;
export const ListSsoConfigurationsDocument = gql`
    query listSSOConfigurations {
  listSSOConfigurations {
    id
    idpAlias
    isMembershipRolesUpdatedViaSSO
    canUserRegisterViaSSO
    owner
    emailDomains
  }
}
    `;

/**
 * __useListSsoConfigurationsQuery__
 *
 * To run a query within a React component, call `useListSsoConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSsoConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSsoConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSsoConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<ListSsoConfigurationsQuery, ListSsoConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSsoConfigurationsQuery, ListSsoConfigurationsQueryVariables>(ListSsoConfigurationsDocument, options);
      }
export function useListSsoConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSsoConfigurationsQuery, ListSsoConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSsoConfigurationsQuery, ListSsoConfigurationsQueryVariables>(ListSsoConfigurationsDocument, options);
        }
export type ListSsoConfigurationsQueryHookResult = ReturnType<typeof useListSsoConfigurationsQuery>;
export type ListSsoConfigurationsLazyQueryHookResult = ReturnType<typeof useListSsoConfigurationsLazyQuery>;
export type ListSsoConfigurationsQueryResult = Apollo.QueryResult<ListSsoConfigurationsQuery, ListSsoConfigurationsQueryVariables>;
export const GetIdentitiyProviderDocument = gql`
    query getIdentitiyProvider($ssoConfigurationId: String!) {
  getIdentitiyProvider(ssoConfigurationId: $ssoConfigurationId) {
    alias
    displayName
    enabled
    firstBrokerLoginFlowAlias
    config {
      __typename
      ... on IdentityProviderSAML {
        idpEntityId
        entityId
        singleSignOnServiceUrl
        validateSignature
        signingCertificate
        syncMode
        nameIDPolicyFormat
        principalType
        postBindingAuthnRequest
        postBindingResponse
      }
      ... on IdentityProviderOIDC {
        tokenUrl
        authorizationUrl
        clientId
      }
    }
  }
}
    `;

/**
 * __useGetIdentitiyProviderQuery__
 *
 * To run a query within a React component, call `useGetIdentitiyProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentitiyProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentitiyProviderQuery({
 *   variables: {
 *      ssoConfigurationId: // value for 'ssoConfigurationId'
 *   },
 * });
 */
export function useGetIdentitiyProviderQuery(baseOptions: Apollo.QueryHookOptions<GetIdentitiyProviderQuery, GetIdentitiyProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIdentitiyProviderQuery, GetIdentitiyProviderQueryVariables>(GetIdentitiyProviderDocument, options);
      }
export function useGetIdentitiyProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIdentitiyProviderQuery, GetIdentitiyProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIdentitiyProviderQuery, GetIdentitiyProviderQueryVariables>(GetIdentitiyProviderDocument, options);
        }
export type GetIdentitiyProviderQueryHookResult = ReturnType<typeof useGetIdentitiyProviderQuery>;
export type GetIdentitiyProviderLazyQueryHookResult = ReturnType<typeof useGetIdentitiyProviderLazyQuery>;
export type GetIdentitiyProviderQueryResult = Apollo.QueryResult<GetIdentitiyProviderQuery, GetIdentitiyProviderQueryVariables>;
export const SetAccountsPayableNumberSettingsDocument = gql`
    mutation setAccountsPayableNumberSettings($settings: AccountsPayableNumberSettingsInput!) {
  accountsPayableNumberSettings(settings: $settings) {
    enabled
    startNumber
  }
}
    `;
export type SetAccountsPayableNumberSettingsMutationFn = Apollo.MutationFunction<SetAccountsPayableNumberSettingsMutation, SetAccountsPayableNumberSettingsMutationVariables>;

/**
 * __useSetAccountsPayableNumberSettingsMutation__
 *
 * To run a mutation, you first call `useSetAccountsPayableNumberSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountsPayableNumberSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountsPayableNumberSettingsMutation, { data, loading, error }] = useSetAccountsPayableNumberSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSetAccountsPayableNumberSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountsPayableNumberSettingsMutation, SetAccountsPayableNumberSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountsPayableNumberSettingsMutation, SetAccountsPayableNumberSettingsMutationVariables>(SetAccountsPayableNumberSettingsDocument, options);
      }
export type SetAccountsPayableNumberSettingsMutationHookResult = ReturnType<typeof useSetAccountsPayableNumberSettingsMutation>;
export type SetAccountsPayableNumberSettingsMutationResult = Apollo.MutationResult<SetAccountsPayableNumberSettingsMutation>;
export type SetAccountsPayableNumberSettingsMutationOptions = Apollo.BaseMutationOptions<SetAccountsPayableNumberSettingsMutation, SetAccountsPayableNumberSettingsMutationVariables>;
export const SetEmployeesAccountsPayableNumberDocument = gql`
    mutation setEmployeesAccountsPayableNumber($settings: AccountsPayableNumberSettingsInput!) {
  setAccountsPayableNumberSettingsForEmployees: accountsPayableNumberSettingsForEmployees(
    settings: $settings
  ) {
    enabled
    startNumber
  }
}
    `;
export type SetEmployeesAccountsPayableNumberMutationFn = Apollo.MutationFunction<SetEmployeesAccountsPayableNumberMutation, SetEmployeesAccountsPayableNumberMutationVariables>;

/**
 * __useSetEmployeesAccountsPayableNumberMutation__
 *
 * To run a mutation, you first call `useSetEmployeesAccountsPayableNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmployeesAccountsPayableNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmployeesAccountsPayableNumberMutation, { data, loading, error }] = useSetEmployeesAccountsPayableNumberMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSetEmployeesAccountsPayableNumberMutation(baseOptions?: Apollo.MutationHookOptions<SetEmployeesAccountsPayableNumberMutation, SetEmployeesAccountsPayableNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEmployeesAccountsPayableNumberMutation, SetEmployeesAccountsPayableNumberMutationVariables>(SetEmployeesAccountsPayableNumberDocument, options);
      }
export type SetEmployeesAccountsPayableNumberMutationHookResult = ReturnType<typeof useSetEmployeesAccountsPayableNumberMutation>;
export type SetEmployeesAccountsPayableNumberMutationResult = Apollo.MutationResult<SetEmployeesAccountsPayableNumberMutation>;
export type SetEmployeesAccountsPayableNumberMutationOptions = Apollo.BaseMutationOptions<SetEmployeesAccountsPayableNumberMutation, SetEmployeesAccountsPayableNumberMutationVariables>;
export const AccountsPayableNumberSettingsDocument = gql`
    query accountsPayableNumberSettings {
  accountsPayableNumberSettings {
    startNumber
    enabled
  }
}
    `;

/**
 * __useAccountsPayableNumberSettingsQuery__
 *
 * To run a query within a React component, call `useAccountsPayableNumberSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsPayableNumberSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsPayableNumberSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsPayableNumberSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsPayableNumberSettingsQuery, AccountsPayableNumberSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsPayableNumberSettingsQuery, AccountsPayableNumberSettingsQueryVariables>(AccountsPayableNumberSettingsDocument, options);
      }
export function useAccountsPayableNumberSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsPayableNumberSettingsQuery, AccountsPayableNumberSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsPayableNumberSettingsQuery, AccountsPayableNumberSettingsQueryVariables>(AccountsPayableNumberSettingsDocument, options);
        }
export type AccountsPayableNumberSettingsQueryHookResult = ReturnType<typeof useAccountsPayableNumberSettingsQuery>;
export type AccountsPayableNumberSettingsLazyQueryHookResult = ReturnType<typeof useAccountsPayableNumberSettingsLazyQuery>;
export type AccountsPayableNumberSettingsQueryResult = Apollo.QueryResult<AccountsPayableNumberSettingsQuery, AccountsPayableNumberSettingsQueryVariables>;
export const AccountsPayableNumberSettingsForEmployeesDocument = gql`
    query accountsPayableNumberSettingsForEmployees {
  accountsPayableNumberSettingsForEmployees {
    startNumber
    enabled
  }
}
    `;

/**
 * __useAccountsPayableNumberSettingsForEmployeesQuery__
 *
 * To run a query within a React component, call `useAccountsPayableNumberSettingsForEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsPayableNumberSettingsForEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsPayableNumberSettingsForEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsPayableNumberSettingsForEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<AccountsPayableNumberSettingsForEmployeesQuery, AccountsPayableNumberSettingsForEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsPayableNumberSettingsForEmployeesQuery, AccountsPayableNumberSettingsForEmployeesQueryVariables>(AccountsPayableNumberSettingsForEmployeesDocument, options);
      }
export function useAccountsPayableNumberSettingsForEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsPayableNumberSettingsForEmployeesQuery, AccountsPayableNumberSettingsForEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsPayableNumberSettingsForEmployeesQuery, AccountsPayableNumberSettingsForEmployeesQueryVariables>(AccountsPayableNumberSettingsForEmployeesDocument, options);
        }
export type AccountsPayableNumberSettingsForEmployeesQueryHookResult = ReturnType<typeof useAccountsPayableNumberSettingsForEmployeesQuery>;
export type AccountsPayableNumberSettingsForEmployeesLazyQueryHookResult = ReturnType<typeof useAccountsPayableNumberSettingsForEmployeesLazyQuery>;
export type AccountsPayableNumberSettingsForEmployeesQueryResult = Apollo.QueryResult<AccountsPayableNumberSettingsForEmployeesQuery, AccountsPayableNumberSettingsForEmployeesQueryVariables>;
export const SfsSettingsForRequesterDocument = gql`
    query sfsSettingsForRequester {
  sfsSettings {
    costCenter {
      on
      method
    }
    costObject {
      on
      method
    }
    generalLedgerAccount {
      on
      method
    }
    bookingKey {
      on
      method
    }
    generalLedgerAccount {
      on
      method
    }
    bookingKey {
      on
      method
    }
  }
}
    `;

/**
 * __useSfsSettingsForRequesterQuery__
 *
 * To run a query within a React component, call `useSfsSettingsForRequesterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSfsSettingsForRequesterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSfsSettingsForRequesterQuery({
 *   variables: {
 *   },
 * });
 */
export function useSfsSettingsForRequesterQuery(baseOptions?: Apollo.QueryHookOptions<SfsSettingsForRequesterQuery, SfsSettingsForRequesterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SfsSettingsForRequesterQuery, SfsSettingsForRequesterQueryVariables>(SfsSettingsForRequesterDocument, options);
      }
export function useSfsSettingsForRequesterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SfsSettingsForRequesterQuery, SfsSettingsForRequesterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SfsSettingsForRequesterQuery, SfsSettingsForRequesterQueryVariables>(SfsSettingsForRequesterDocument, options);
        }
export type SfsSettingsForRequesterQueryHookResult = ReturnType<typeof useSfsSettingsForRequesterQuery>;
export type SfsSettingsForRequesterLazyQueryHookResult = ReturnType<typeof useSfsSettingsForRequesterLazyQuery>;
export type SfsSettingsForRequesterQueryResult = Apollo.QueryResult<SfsSettingsForRequesterQuery, SfsSettingsForRequesterQueryVariables>;
export const SfsSettingsForApproverDocument = gql`
    query sfsSettingsForApprover {
  sfsSettingsForApprover {
    costCenter {
      on
      method
    }
    costObject {
      on
      method
    }
    generalLedgerAccount {
      on
      method
    }
    bookingKey {
      on
      method
    }
    generalLedgerAccount {
      on
      method
    }
    bookingKey {
      on
      method
    }
  }
}
    `;

/**
 * __useSfsSettingsForApproverQuery__
 *
 * To run a query within a React component, call `useSfsSettingsForApproverQuery` and pass it any options that fit your needs.
 * When your component renders, `useSfsSettingsForApproverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSfsSettingsForApproverQuery({
 *   variables: {
 *   },
 * });
 */
export function useSfsSettingsForApproverQuery(baseOptions?: Apollo.QueryHookOptions<SfsSettingsForApproverQuery, SfsSettingsForApproverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SfsSettingsForApproverQuery, SfsSettingsForApproverQueryVariables>(SfsSettingsForApproverDocument, options);
      }
export function useSfsSettingsForApproverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SfsSettingsForApproverQuery, SfsSettingsForApproverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SfsSettingsForApproverQuery, SfsSettingsForApproverQueryVariables>(SfsSettingsForApproverDocument, options);
        }
export type SfsSettingsForApproverQueryHookResult = ReturnType<typeof useSfsSettingsForApproverQuery>;
export type SfsSettingsForApproverLazyQueryHookResult = ReturnType<typeof useSfsSettingsForApproverLazyQuery>;
export type SfsSettingsForApproverQueryResult = Apollo.QueryResult<SfsSettingsForApproverQuery, SfsSettingsForApproverQueryVariables>;
export const SfsSwitchCostCenterOffManuallyDocument = gql`
    mutation sfsSwitchCostCenterOffManually {
  sfsSwitchCostCenterOffManually
}
    `;
export type SfsSwitchCostCenterOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostCenterOffManuallyMutation, SfsSwitchCostCenterOffManuallyMutationVariables>;

/**
 * __useSfsSwitchCostCenterOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostCenterOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostCenterOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostCenterOffManuallyMutation, { data, loading, error }] = useSfsSwitchCostCenterOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostCenterOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostCenterOffManuallyMutation, SfsSwitchCostCenterOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostCenterOffManuallyMutation, SfsSwitchCostCenterOffManuallyMutationVariables>(SfsSwitchCostCenterOffManuallyDocument, options);
      }
export type SfsSwitchCostCenterOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostCenterOffManuallyMutation>;
export type SfsSwitchCostCenterOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostCenterOffManuallyMutation>;
export type SfsSwitchCostCenterOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostCenterOffManuallyMutation, SfsSwitchCostCenterOffManuallyMutationVariables>;
export const SfsSwitchCostCenterOnManuallyDocument = gql`
    mutation sfsSwitchCostCenterOnManually {
  sfsSwitchCostCenterOnManually
}
    `;
export type SfsSwitchCostCenterOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostCenterOnManuallyMutation, SfsSwitchCostCenterOnManuallyMutationVariables>;

/**
 * __useSfsSwitchCostCenterOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostCenterOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostCenterOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostCenterOnManuallyMutation, { data, loading, error }] = useSfsSwitchCostCenterOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostCenterOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostCenterOnManuallyMutation, SfsSwitchCostCenterOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostCenterOnManuallyMutation, SfsSwitchCostCenterOnManuallyMutationVariables>(SfsSwitchCostCenterOnManuallyDocument, options);
      }
export type SfsSwitchCostCenterOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostCenterOnManuallyMutation>;
export type SfsSwitchCostCenterOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostCenterOnManuallyMutation>;
export type SfsSwitchCostCenterOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostCenterOnManuallyMutation, SfsSwitchCostCenterOnManuallyMutationVariables>;
export const SfsSwitchCostObjectOffManuallyDocument = gql`
    mutation sfsSwitchCostObjectOffManually {
  sfsSwitchCostObjectOffManually
}
    `;
export type SfsSwitchCostObjectOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostObjectOffManuallyMutation, SfsSwitchCostObjectOffManuallyMutationVariables>;

/**
 * __useSfsSwitchCostObjectOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostObjectOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostObjectOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostObjectOffManuallyMutation, { data, loading, error }] = useSfsSwitchCostObjectOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostObjectOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostObjectOffManuallyMutation, SfsSwitchCostObjectOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostObjectOffManuallyMutation, SfsSwitchCostObjectOffManuallyMutationVariables>(SfsSwitchCostObjectOffManuallyDocument, options);
      }
export type SfsSwitchCostObjectOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostObjectOffManuallyMutation>;
export type SfsSwitchCostObjectOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostObjectOffManuallyMutation>;
export type SfsSwitchCostObjectOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostObjectOffManuallyMutation, SfsSwitchCostObjectOffManuallyMutationVariables>;
export const SfsSwitchCostObjectOnManuallyDocument = gql`
    mutation sfsSwitchCostObjectOnManually {
  sfsSwitchCostObjectOnManually
}
    `;
export type SfsSwitchCostObjectOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostObjectOnManuallyMutation, SfsSwitchCostObjectOnManuallyMutationVariables>;

/**
 * __useSfsSwitchCostObjectOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostObjectOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostObjectOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostObjectOnManuallyMutation, { data, loading, error }] = useSfsSwitchCostObjectOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostObjectOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostObjectOnManuallyMutation, SfsSwitchCostObjectOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostObjectOnManuallyMutation, SfsSwitchCostObjectOnManuallyMutationVariables>(SfsSwitchCostObjectOnManuallyDocument, options);
      }
export type SfsSwitchCostObjectOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostObjectOnManuallyMutation>;
export type SfsSwitchCostObjectOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostObjectOnManuallyMutation>;
export type SfsSwitchCostObjectOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostObjectOnManuallyMutation, SfsSwitchCostObjectOnManuallyMutationVariables>;
export const SfsSwitchGeneralLedgerAccountOnManuallyDocument = gql`
    mutation sfsSwitchGeneralLedgerAccountOnManually {
  sfsSwitchGeneralLedgerAccountOnManually
}
    `;
export type SfsSwitchGeneralLedgerAccountOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchGeneralLedgerAccountOnManuallyMutation, SfsSwitchGeneralLedgerAccountOnManuallyMutationVariables>;

/**
 * __useSfsSwitchGeneralLedgerAccountOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchGeneralLedgerAccountOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchGeneralLedgerAccountOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchGeneralLedgerAccountOnManuallyMutation, { data, loading, error }] = useSfsSwitchGeneralLedgerAccountOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchGeneralLedgerAccountOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchGeneralLedgerAccountOnManuallyMutation, SfsSwitchGeneralLedgerAccountOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchGeneralLedgerAccountOnManuallyMutation, SfsSwitchGeneralLedgerAccountOnManuallyMutationVariables>(SfsSwitchGeneralLedgerAccountOnManuallyDocument, options);
      }
export type SfsSwitchGeneralLedgerAccountOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchGeneralLedgerAccountOnManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchGeneralLedgerAccountOnManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchGeneralLedgerAccountOnManuallyMutation, SfsSwitchGeneralLedgerAccountOnManuallyMutationVariables>;
export const SfsSwitchGeneralLedgerAccountOffManuallyDocument = gql`
    mutation sfsSwitchGeneralLedgerAccountOffManually {
  sfsSwitchGeneralLedgerAccountOffManually
}
    `;
export type SfsSwitchGeneralLedgerAccountOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchGeneralLedgerAccountOffManuallyMutation, SfsSwitchGeneralLedgerAccountOffManuallyMutationVariables>;

/**
 * __useSfsSwitchGeneralLedgerAccountOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchGeneralLedgerAccountOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchGeneralLedgerAccountOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchGeneralLedgerAccountOffManuallyMutation, { data, loading, error }] = useSfsSwitchGeneralLedgerAccountOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchGeneralLedgerAccountOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchGeneralLedgerAccountOffManuallyMutation, SfsSwitchGeneralLedgerAccountOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchGeneralLedgerAccountOffManuallyMutation, SfsSwitchGeneralLedgerAccountOffManuallyMutationVariables>(SfsSwitchGeneralLedgerAccountOffManuallyDocument, options);
      }
export type SfsSwitchGeneralLedgerAccountOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchGeneralLedgerAccountOffManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchGeneralLedgerAccountOffManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchGeneralLedgerAccountOffManuallyMutation, SfsSwitchGeneralLedgerAccountOffManuallyMutationVariables>;
export const SfsSwitchBookingKeyOnManuallyDocument = gql`
    mutation sfsSwitchBookingKeyOnManually {
  sfsSwitchBookingKeyOnManually
}
    `;
export type SfsSwitchBookingKeyOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchBookingKeyOnManuallyMutation, SfsSwitchBookingKeyOnManuallyMutationVariables>;

/**
 * __useSfsSwitchBookingKeyOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchBookingKeyOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchBookingKeyOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchBookingKeyOnManuallyMutation, { data, loading, error }] = useSfsSwitchBookingKeyOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchBookingKeyOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchBookingKeyOnManuallyMutation, SfsSwitchBookingKeyOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchBookingKeyOnManuallyMutation, SfsSwitchBookingKeyOnManuallyMutationVariables>(SfsSwitchBookingKeyOnManuallyDocument, options);
      }
export type SfsSwitchBookingKeyOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchBookingKeyOnManuallyMutation>;
export type SfsSwitchBookingKeyOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchBookingKeyOnManuallyMutation>;
export type SfsSwitchBookingKeyOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchBookingKeyOnManuallyMutation, SfsSwitchBookingKeyOnManuallyMutationVariables>;
export const SfsSwitchBookingKeyOffManuallyDocument = gql`
    mutation sfsSwitchBookingKeyOffManually {
  sfsSwitchBookingKeyOffManually
}
    `;
export type SfsSwitchBookingKeyOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchBookingKeyOffManuallyMutation, SfsSwitchBookingKeyOffManuallyMutationVariables>;

/**
 * __useSfsSwitchBookingKeyOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchBookingKeyOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchBookingKeyOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchBookingKeyOffManuallyMutation, { data, loading, error }] = useSfsSwitchBookingKeyOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchBookingKeyOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchBookingKeyOffManuallyMutation, SfsSwitchBookingKeyOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchBookingKeyOffManuallyMutation, SfsSwitchBookingKeyOffManuallyMutationVariables>(SfsSwitchBookingKeyOffManuallyDocument, options);
      }
export type SfsSwitchBookingKeyOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchBookingKeyOffManuallyMutation>;
export type SfsSwitchBookingKeyOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchBookingKeyOffManuallyMutation>;
export type SfsSwitchBookingKeyOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchBookingKeyOffManuallyMutation, SfsSwitchBookingKeyOffManuallyMutationVariables>;
export const SfsSwitchCostObjectForApproverOffManuallyDocument = gql`
    mutation sfsSwitchCostObjectForApproverOffManually {
  sfsSwitchCostObjectForApproverOffManually
}
    `;
export type SfsSwitchCostObjectForApproverOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostObjectForApproverOffManuallyMutation, SfsSwitchCostObjectForApproverOffManuallyMutationVariables>;

/**
 * __useSfsSwitchCostObjectForApproverOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostObjectForApproverOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostObjectForApproverOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostObjectForApproverOffManuallyMutation, { data, loading, error }] = useSfsSwitchCostObjectForApproverOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostObjectForApproverOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostObjectForApproverOffManuallyMutation, SfsSwitchCostObjectForApproverOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostObjectForApproverOffManuallyMutation, SfsSwitchCostObjectForApproverOffManuallyMutationVariables>(SfsSwitchCostObjectForApproverOffManuallyDocument, options);
      }
export type SfsSwitchCostObjectForApproverOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostObjectForApproverOffManuallyMutation>;
export type SfsSwitchCostObjectForApproverOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostObjectForApproverOffManuallyMutation>;
export type SfsSwitchCostObjectForApproverOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostObjectForApproverOffManuallyMutation, SfsSwitchCostObjectForApproverOffManuallyMutationVariables>;
export const SfsSwitchCostObjectForApproverOnManuallyDocument = gql`
    mutation sfsSwitchCostObjectForApproverOnManually {
  sfsSwitchCostObjectForApproverOnManually
}
    `;
export type SfsSwitchCostObjectForApproverOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostObjectForApproverOnManuallyMutation, SfsSwitchCostObjectForApproverOnManuallyMutationVariables>;

/**
 * __useSfsSwitchCostObjectForApproverOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostObjectForApproverOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostObjectForApproverOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostObjectForApproverOnManuallyMutation, { data, loading, error }] = useSfsSwitchCostObjectForApproverOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostObjectForApproverOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostObjectForApproverOnManuallyMutation, SfsSwitchCostObjectForApproverOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostObjectForApproverOnManuallyMutation, SfsSwitchCostObjectForApproverOnManuallyMutationVariables>(SfsSwitchCostObjectForApproverOnManuallyDocument, options);
      }
export type SfsSwitchCostObjectForApproverOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostObjectForApproverOnManuallyMutation>;
export type SfsSwitchCostObjectForApproverOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostObjectForApproverOnManuallyMutation>;
export type SfsSwitchCostObjectForApproverOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostObjectForApproverOnManuallyMutation, SfsSwitchCostObjectForApproverOnManuallyMutationVariables>;
export const SfsSwitchCostCenterForApproverOffManuallyDocument = gql`
    mutation sfsSwitchCostCenterForApproverOffManually {
  sfsSwitchCostCenterForApproverOffManually
}
    `;
export type SfsSwitchCostCenterForApproverOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostCenterForApproverOffManuallyMutation, SfsSwitchCostCenterForApproverOffManuallyMutationVariables>;

/**
 * __useSfsSwitchCostCenterForApproverOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostCenterForApproverOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostCenterForApproverOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostCenterForApproverOffManuallyMutation, { data, loading, error }] = useSfsSwitchCostCenterForApproverOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostCenterForApproverOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostCenterForApproverOffManuallyMutation, SfsSwitchCostCenterForApproverOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostCenterForApproverOffManuallyMutation, SfsSwitchCostCenterForApproverOffManuallyMutationVariables>(SfsSwitchCostCenterForApproverOffManuallyDocument, options);
      }
export type SfsSwitchCostCenterForApproverOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostCenterForApproverOffManuallyMutation>;
export type SfsSwitchCostCenterForApproverOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostCenterForApproverOffManuallyMutation>;
export type SfsSwitchCostCenterForApproverOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostCenterForApproverOffManuallyMutation, SfsSwitchCostCenterForApproverOffManuallyMutationVariables>;
export const SfsSwitchCostCenterForApproverOnManuallyDocument = gql`
    mutation sfsSwitchCostCenterForApproverOnManually {
  sfsSwitchCostCenterForApproverOnManually
}
    `;
export type SfsSwitchCostCenterForApproverOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchCostCenterForApproverOnManuallyMutation, SfsSwitchCostCenterForApproverOnManuallyMutationVariables>;

/**
 * __useSfsSwitchCostCenterForApproverOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchCostCenterForApproverOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchCostCenterForApproverOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchCostCenterForApproverOnManuallyMutation, { data, loading, error }] = useSfsSwitchCostCenterForApproverOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchCostCenterForApproverOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchCostCenterForApproverOnManuallyMutation, SfsSwitchCostCenterForApproverOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchCostCenterForApproverOnManuallyMutation, SfsSwitchCostCenterForApproverOnManuallyMutationVariables>(SfsSwitchCostCenterForApproverOnManuallyDocument, options);
      }
export type SfsSwitchCostCenterForApproverOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchCostCenterForApproverOnManuallyMutation>;
export type SfsSwitchCostCenterForApproverOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchCostCenterForApproverOnManuallyMutation>;
export type SfsSwitchCostCenterForApproverOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchCostCenterForApproverOnManuallyMutation, SfsSwitchCostCenterForApproverOnManuallyMutationVariables>;
export const SfsSwitchGeneralLedgerAccountForApproverOffManuallyDocument = gql`
    mutation sfsSwitchGeneralLedgerAccountForApproverOffManually {
  sfsSwitchGeneralLedgerAccountForApproverOffManually
}
    `;
export type SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationVariables>;

/**
 * __useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation, { data, loading, error }] = useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationVariables>(SfsSwitchGeneralLedgerAccountForApproverOffManuallyDocument, options);
      }
export type SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOffManuallyMutationVariables>;
export const SfsSwitchGeneralLedgerAccountForApproverOnManuallyDocument = gql`
    mutation sfsSwitchGeneralLedgerAccountForApproverOnManually {
  sfsSwitchGeneralLedgerAccountForApproverOnManually
}
    `;
export type SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationVariables>;

/**
 * __useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation, { data, loading, error }] = useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationVariables>(SfsSwitchGeneralLedgerAccountForApproverOnManuallyDocument, options);
      }
export type SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation>;
export type SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation, SfsSwitchGeneralLedgerAccountForApproverOnManuallyMutationVariables>;
export const SfsSwitchBookingKeyForApproverOffManuallyDocument = gql`
    mutation sfsSwitchBookingKeyForApproverOffManually {
  sfsSwitchBookingKeyForApproverOffManually
}
    `;
export type SfsSwitchBookingKeyForApproverOffManuallyMutationFn = Apollo.MutationFunction<SfsSwitchBookingKeyForApproverOffManuallyMutation, SfsSwitchBookingKeyForApproverOffManuallyMutationVariables>;

/**
 * __useSfsSwitchBookingKeyForApproverOffManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchBookingKeyForApproverOffManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchBookingKeyForApproverOffManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchBookingKeyForApproverOffManuallyMutation, { data, loading, error }] = useSfsSwitchBookingKeyForApproverOffManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchBookingKeyForApproverOffManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchBookingKeyForApproverOffManuallyMutation, SfsSwitchBookingKeyForApproverOffManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchBookingKeyForApproverOffManuallyMutation, SfsSwitchBookingKeyForApproverOffManuallyMutationVariables>(SfsSwitchBookingKeyForApproverOffManuallyDocument, options);
      }
export type SfsSwitchBookingKeyForApproverOffManuallyMutationHookResult = ReturnType<typeof useSfsSwitchBookingKeyForApproverOffManuallyMutation>;
export type SfsSwitchBookingKeyForApproverOffManuallyMutationResult = Apollo.MutationResult<SfsSwitchBookingKeyForApproverOffManuallyMutation>;
export type SfsSwitchBookingKeyForApproverOffManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchBookingKeyForApproverOffManuallyMutation, SfsSwitchBookingKeyForApproverOffManuallyMutationVariables>;
export const SfsSwitchBookingKeyForApproverOnManuallyDocument = gql`
    mutation sfsSwitchBookingKeyForApproverOnManually {
  sfsSwitchBookingKeyForApproverOnManually
}
    `;
export type SfsSwitchBookingKeyForApproverOnManuallyMutationFn = Apollo.MutationFunction<SfsSwitchBookingKeyForApproverOnManuallyMutation, SfsSwitchBookingKeyForApproverOnManuallyMutationVariables>;

/**
 * __useSfsSwitchBookingKeyForApproverOnManuallyMutation__
 *
 * To run a mutation, you first call `useSfsSwitchBookingKeyForApproverOnManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSfsSwitchBookingKeyForApproverOnManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sfsSwitchBookingKeyForApproverOnManuallyMutation, { data, loading, error }] = useSfsSwitchBookingKeyForApproverOnManuallyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSfsSwitchBookingKeyForApproverOnManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SfsSwitchBookingKeyForApproverOnManuallyMutation, SfsSwitchBookingKeyForApproverOnManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SfsSwitchBookingKeyForApproverOnManuallyMutation, SfsSwitchBookingKeyForApproverOnManuallyMutationVariables>(SfsSwitchBookingKeyForApproverOnManuallyDocument, options);
      }
export type SfsSwitchBookingKeyForApproverOnManuallyMutationHookResult = ReturnType<typeof useSfsSwitchBookingKeyForApproverOnManuallyMutation>;
export type SfsSwitchBookingKeyForApproverOnManuallyMutationResult = Apollo.MutationResult<SfsSwitchBookingKeyForApproverOnManuallyMutation>;
export type SfsSwitchBookingKeyForApproverOnManuallyMutationOptions = Apollo.BaseMutationOptions<SfsSwitchBookingKeyForApproverOnManuallyMutation, SfsSwitchBookingKeyForApproverOnManuallyMutationVariables>;
export const BookingKeysDocument = gql`
    query bookingKeys {
  bookingKeys {
    ...BookingKeyData
  }
}
    ${BookingKeyDataFragmentDoc}`;

/**
 * __useBookingKeysQuery__
 *
 * To run a query within a React component, call `useBookingKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingKeysQuery(baseOptions?: Apollo.QueryHookOptions<BookingKeysQuery, BookingKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingKeysQuery, BookingKeysQueryVariables>(BookingKeysDocument, options);
      }
export function useBookingKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingKeysQuery, BookingKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingKeysQuery, BookingKeysQueryVariables>(BookingKeysDocument, options);
        }
export type BookingKeysQueryHookResult = ReturnType<typeof useBookingKeysQuery>;
export type BookingKeysLazyQueryHookResult = ReturnType<typeof useBookingKeysLazyQuery>;
export type BookingKeysQueryResult = Apollo.QueryResult<BookingKeysQuery, BookingKeysQueryVariables>;
export const BookingKeyDocument = gql`
    query bookingKey($id: ID!) {
  bookingKey(id: $id) {
    __typename
    ...BookingKeyData
    ... on BookingKeyError {
      kind
    }
  }
}
    ${BookingKeyDataFragmentDoc}`;

/**
 * __useBookingKeyQuery__
 *
 * To run a query within a React component, call `useBookingKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingKeyQuery(baseOptions: Apollo.QueryHookOptions<BookingKeyQuery, BookingKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingKeyQuery, BookingKeyQueryVariables>(BookingKeyDocument, options);
      }
export function useBookingKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingKeyQuery, BookingKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingKeyQuery, BookingKeyQueryVariables>(BookingKeyDocument, options);
        }
export type BookingKeyQueryHookResult = ReturnType<typeof useBookingKeyQuery>;
export type BookingKeyLazyQueryHookResult = ReturnType<typeof useBookingKeyLazyQuery>;
export type BookingKeyQueryResult = Apollo.QueryResult<BookingKeyQuery, BookingKeyQueryVariables>;
export const BookingKeysActiveDocument = gql`
    query bookingKeysActive {
  bookingKeysActive {
    ...BookingKeyData
  }
}
    ${BookingKeyDataFragmentDoc}`;

/**
 * __useBookingKeysActiveQuery__
 *
 * To run a query within a React component, call `useBookingKeysActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingKeysActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingKeysActiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingKeysActiveQuery(baseOptions?: Apollo.QueryHookOptions<BookingKeysActiveQuery, BookingKeysActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingKeysActiveQuery, BookingKeysActiveQueryVariables>(BookingKeysActiveDocument, options);
      }
export function useBookingKeysActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingKeysActiveQuery, BookingKeysActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingKeysActiveQuery, BookingKeysActiveQueryVariables>(BookingKeysActiveDocument, options);
        }
export type BookingKeysActiveQueryHookResult = ReturnType<typeof useBookingKeysActiveQuery>;
export type BookingKeysActiveLazyQueryHookResult = ReturnType<typeof useBookingKeysActiveLazyQuery>;
export type BookingKeysActiveQueryResult = Apollo.QueryResult<BookingKeysActiveQuery, BookingKeysActiveQueryVariables>;
export const BookingKeysArchivedDocument = gql`
    query bookingKeysArchived {
  bookingKeysArchived {
    ...BookingKeyData
  }
}
    ${BookingKeyDataFragmentDoc}`;

/**
 * __useBookingKeysArchivedQuery__
 *
 * To run a query within a React component, call `useBookingKeysArchivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingKeysArchivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingKeysArchivedQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingKeysArchivedQuery(baseOptions?: Apollo.QueryHookOptions<BookingKeysArchivedQuery, BookingKeysArchivedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingKeysArchivedQuery, BookingKeysArchivedQueryVariables>(BookingKeysArchivedDocument, options);
      }
export function useBookingKeysArchivedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingKeysArchivedQuery, BookingKeysArchivedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingKeysArchivedQuery, BookingKeysArchivedQueryVariables>(BookingKeysArchivedDocument, options);
        }
export type BookingKeysArchivedQueryHookResult = ReturnType<typeof useBookingKeysArchivedQuery>;
export type BookingKeysArchivedLazyQueryHookResult = ReturnType<typeof useBookingKeysArchivedLazyQuery>;
export type BookingKeysArchivedQueryResult = Apollo.QueryResult<BookingKeysArchivedQuery, BookingKeysArchivedQueryVariables>;
export const CreateBookingKeyDocument = gql`
    mutation createBookingKey($input: CreateBookingKeyInput!) {
  createBookingKey(input: $input) {
    __typename
    ... on BookingKeySuccess {
      id
    }
    ... on BookingKeyError {
      taxCode
      kind
    }
  }
}
    `;
export type CreateBookingKeyMutationFn = Apollo.MutationFunction<CreateBookingKeyMutation, CreateBookingKeyMutationVariables>;

/**
 * __useCreateBookingKeyMutation__
 *
 * To run a mutation, you first call `useCreateBookingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingKeyMutation, { data, loading, error }] = useCreateBookingKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingKeyMutation, CreateBookingKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingKeyMutation, CreateBookingKeyMutationVariables>(CreateBookingKeyDocument, options);
      }
export type CreateBookingKeyMutationHookResult = ReturnType<typeof useCreateBookingKeyMutation>;
export type CreateBookingKeyMutationResult = Apollo.MutationResult<CreateBookingKeyMutation>;
export type CreateBookingKeyMutationOptions = Apollo.BaseMutationOptions<CreateBookingKeyMutation, CreateBookingKeyMutationVariables>;
export const UpdateBookingKeyDocument = gql`
    mutation updateBookingKey($input: UpdateBookingKeyInput!) {
  updateBookingKey(input: $input) {
    __typename
    ... on BookingKeySuccess {
      id
    }
    ... on BookingKeyError {
      taxCode
      kind
    }
  }
}
    `;
export type UpdateBookingKeyMutationFn = Apollo.MutationFunction<UpdateBookingKeyMutation, UpdateBookingKeyMutationVariables>;

/**
 * __useUpdateBookingKeyMutation__
 *
 * To run a mutation, you first call `useUpdateBookingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingKeyMutation, { data, loading, error }] = useUpdateBookingKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingKeyMutation, UpdateBookingKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingKeyMutation, UpdateBookingKeyMutationVariables>(UpdateBookingKeyDocument, options);
      }
export type UpdateBookingKeyMutationHookResult = ReturnType<typeof useUpdateBookingKeyMutation>;
export type UpdateBookingKeyMutationResult = Apollo.MutationResult<UpdateBookingKeyMutation>;
export type UpdateBookingKeyMutationOptions = Apollo.BaseMutationOptions<UpdateBookingKeyMutation, UpdateBookingKeyMutationVariables>;
export const ArchiveBookingKeyDocument = gql`
    mutation archiveBookingKey($id: ID!) {
  archiveBookingKey(id: $id) {
    __typename
    ... on BookingKeySuccess {
      id
    }
    ... on BookingKeyError {
      taxCode
      kind
    }
  }
}
    `;
export type ArchiveBookingKeyMutationFn = Apollo.MutationFunction<ArchiveBookingKeyMutation, ArchiveBookingKeyMutationVariables>;

/**
 * __useArchiveBookingKeyMutation__
 *
 * To run a mutation, you first call `useArchiveBookingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBookingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBookingKeyMutation, { data, loading, error }] = useArchiveBookingKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveBookingKeyMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveBookingKeyMutation, ArchiveBookingKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveBookingKeyMutation, ArchiveBookingKeyMutationVariables>(ArchiveBookingKeyDocument, options);
      }
export type ArchiveBookingKeyMutationHookResult = ReturnType<typeof useArchiveBookingKeyMutation>;
export type ArchiveBookingKeyMutationResult = Apollo.MutationResult<ArchiveBookingKeyMutation>;
export type ArchiveBookingKeyMutationOptions = Apollo.BaseMutationOptions<ArchiveBookingKeyMutation, ArchiveBookingKeyMutationVariables>;
export const ActivateBookingKeyDocument = gql`
    mutation activateBookingKey($id: ID!) {
  activateBookingKey(id: $id) {
    __typename
    ... on BookingKeySuccess {
      id
    }
    ... on BookingKeyError {
      taxCode
      kind
    }
  }
}
    `;
export type ActivateBookingKeyMutationFn = Apollo.MutationFunction<ActivateBookingKeyMutation, ActivateBookingKeyMutationVariables>;

/**
 * __useActivateBookingKeyMutation__
 *
 * To run a mutation, you first call `useActivateBookingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBookingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBookingKeyMutation, { data, loading, error }] = useActivateBookingKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateBookingKeyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBookingKeyMutation, ActivateBookingKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBookingKeyMutation, ActivateBookingKeyMutationVariables>(ActivateBookingKeyDocument, options);
      }
export type ActivateBookingKeyMutationHookResult = ReturnType<typeof useActivateBookingKeyMutation>;
export type ActivateBookingKeyMutationResult = Apollo.MutationResult<ActivateBookingKeyMutation>;
export type ActivateBookingKeyMutationOptions = Apollo.BaseMutationOptions<ActivateBookingKeyMutation, ActivateBookingKeyMutationVariables>;
export const BulkCreateBookingKeysDocument = gql`
    mutation bulkCreateBookingKeys($input: [CreateBookingKeyInput!]!) {
  bulkCreateBookingKeys(input: $input) {
    ... on BookingKeysBulkImportReport {
      imported {
        id
      }
      errors {
        taxCode
        kind
        message
      }
    }
    ... on BookingKeyError {
      taxCode
      kind
      message
    }
  }
}
    `;
export type BulkCreateBookingKeysMutationFn = Apollo.MutationFunction<BulkCreateBookingKeysMutation, BulkCreateBookingKeysMutationVariables>;

/**
 * __useBulkCreateBookingKeysMutation__
 *
 * To run a mutation, you first call `useBulkCreateBookingKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateBookingKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateBookingKeysMutation, { data, loading, error }] = useBulkCreateBookingKeysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateBookingKeysMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateBookingKeysMutation, BulkCreateBookingKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateBookingKeysMutation, BulkCreateBookingKeysMutationVariables>(BulkCreateBookingKeysDocument, options);
      }
export type BulkCreateBookingKeysMutationHookResult = ReturnType<typeof useBulkCreateBookingKeysMutation>;
export type BulkCreateBookingKeysMutationResult = Apollo.MutationResult<BulkCreateBookingKeysMutation>;
export type BulkCreateBookingKeysMutationOptions = Apollo.BaseMutationOptions<BulkCreateBookingKeysMutation, BulkCreateBookingKeysMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateUserSsoDocument = gql`
    mutation createUserSSO($input: CreateUserSsoInput!) {
  createUserSSO(input: $input) {
    id
  }
}
    `;
export type CreateUserSsoMutationFn = Apollo.MutationFunction<CreateUserSsoMutation, CreateUserSsoMutationVariables>;

/**
 * __useCreateUserSsoMutation__
 *
 * To run a mutation, you first call `useCreateUserSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSsoMutation, { data, loading, error }] = useCreateUserSsoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserSsoMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSsoMutation, CreateUserSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSsoMutation, CreateUserSsoMutationVariables>(CreateUserSsoDocument, options);
      }
export type CreateUserSsoMutationHookResult = ReturnType<typeof useCreateUserSsoMutation>;
export type CreateUserSsoMutationResult = Apollo.MutationResult<CreateUserSsoMutation>;
export type CreateUserSsoMutationOptions = Apollo.BaseMutationOptions<CreateUserSsoMutation, CreateUserSsoMutationVariables>;
export const CancelInvitationDocument = gql`
    mutation cancelInvitation($id: String!) {
  cancelInvitation(id: $id)
}
    `;
export type CancelInvitationMutationFn = Apollo.MutationFunction<CancelInvitationMutation, CancelInvitationMutationVariables>;

/**
 * __useCancelInvitationMutation__
 *
 * To run a mutation, you first call `useCancelInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvitationMutation, { data, loading, error }] = useCancelInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelInvitationMutation, CancelInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInvitationMutation, CancelInvitationMutationVariables>(CancelInvitationDocument, options);
      }
export type CancelInvitationMutationHookResult = ReturnType<typeof useCancelInvitationMutation>;
export type CancelInvitationMutationResult = Apollo.MutationResult<CancelInvitationMutation>;
export type CancelInvitationMutationOptions = Apollo.BaseMutationOptions<CancelInvitationMutation, CancelInvitationMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($membershipId: String!, $organizationSlug: String) {
  resendInvitation(
    membershipId: $membershipId
    organizationSlug: $organizationSlug
  ) {
    id
  }
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const DeactivateMembershipDocument = gql`
    mutation deactivateMembership($membershipId: String!) {
  deactivateMembership(membershipId: $membershipId)
}
    `;
export type DeactivateMembershipMutationFn = Apollo.MutationFunction<DeactivateMembershipMutation, DeactivateMembershipMutationVariables>;

/**
 * __useDeactivateMembershipMutation__
 *
 * To run a mutation, you first call `useDeactivateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMembershipMutation, { data, loading, error }] = useDeactivateMembershipMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useDeactivateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateMembershipMutation, DeactivateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateMembershipMutation, DeactivateMembershipMutationVariables>(DeactivateMembershipDocument, options);
      }
export type DeactivateMembershipMutationHookResult = ReturnType<typeof useDeactivateMembershipMutation>;
export type DeactivateMembershipMutationResult = Apollo.MutationResult<DeactivateMembershipMutation>;
export type DeactivateMembershipMutationOptions = Apollo.BaseMutationOptions<DeactivateMembershipMutation, DeactivateMembershipMutationVariables>;
export const ActivateMembershipDocument = gql`
    mutation activateMembership($membershipId: String!) {
  activateMembership(membershipId: $membershipId)
}
    `;
export type ActivateMembershipMutationFn = Apollo.MutationFunction<ActivateMembershipMutation, ActivateMembershipMutationVariables>;

/**
 * __useActivateMembershipMutation__
 *
 * To run a mutation, you first call `useActivateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMembershipMutation, { data, loading, error }] = useActivateMembershipMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useActivateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<ActivateMembershipMutation, ActivateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateMembershipMutation, ActivateMembershipMutationVariables>(ActivateMembershipDocument, options);
      }
export type ActivateMembershipMutationHookResult = ReturnType<typeof useActivateMembershipMutation>;
export type ActivateMembershipMutationResult = Apollo.MutationResult<ActivateMembershipMutation>;
export type ActivateMembershipMutationOptions = Apollo.BaseMutationOptions<ActivateMembershipMutation, ActivateMembershipMutationVariables>;
export const UpdateMembershipDocument = gql`
    mutation updateMembership($id: String!, $input: UpdateUserInput!) {
  updateMembership(id: $id, input: $input)
}
    `;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const ForceUpdateMembershipDocument = gql`
    mutation forceUpdateMembership($id: String!, $input: UpdateUserInput!) {
  forceUpdateMembership(id: $id, input: $input)
}
    `;
export type ForceUpdateMembershipMutationFn = Apollo.MutationFunction<ForceUpdateMembershipMutation, ForceUpdateMembershipMutationVariables>;

/**
 * __useForceUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useForceUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceUpdateMembershipMutation, { data, loading, error }] = useForceUpdateMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<ForceUpdateMembershipMutation, ForceUpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForceUpdateMembershipMutation, ForceUpdateMembershipMutationVariables>(ForceUpdateMembershipDocument, options);
      }
export type ForceUpdateMembershipMutationHookResult = ReturnType<typeof useForceUpdateMembershipMutation>;
export type ForceUpdateMembershipMutationResult = Apollo.MutationResult<ForceUpdateMembershipMutation>;
export type ForceUpdateMembershipMutationOptions = Apollo.BaseMutationOptions<ForceUpdateMembershipMutation, ForceUpdateMembershipMutationVariables>;
export const ForceDeactivateMembershipDocument = gql`
    mutation forceDeactivateMembership($membershipId: String!) {
  forceDeactivateMembership(membershipId: $membershipId)
}
    `;
export type ForceDeactivateMembershipMutationFn = Apollo.MutationFunction<ForceDeactivateMembershipMutation, ForceDeactivateMembershipMutationVariables>;

/**
 * __useForceDeactivateMembershipMutation__
 *
 * To run a mutation, you first call `useForceDeactivateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeactivateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeactivateMembershipMutation, { data, loading, error }] = useForceDeactivateMembershipMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useForceDeactivateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeactivateMembershipMutation, ForceDeactivateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForceDeactivateMembershipMutation, ForceDeactivateMembershipMutationVariables>(ForceDeactivateMembershipDocument, options);
      }
export type ForceDeactivateMembershipMutationHookResult = ReturnType<typeof useForceDeactivateMembershipMutation>;
export type ForceDeactivateMembershipMutationResult = Apollo.MutationResult<ForceDeactivateMembershipMutation>;
export type ForceDeactivateMembershipMutationOptions = Apollo.BaseMutationOptions<ForceDeactivateMembershipMutation, ForceDeactivateMembershipMutationVariables>;
export const SendMobileAppInvitationEmailDocument = gql`
    mutation sendMobileAppInvitationEmail($input: SendMobileAppInvitationEmailInput!) {
  sendMobileAppInvitationEmail(input: $input)
}
    `;
export type SendMobileAppInvitationEmailMutationFn = Apollo.MutationFunction<SendMobileAppInvitationEmailMutation, SendMobileAppInvitationEmailMutationVariables>;

/**
 * __useSendMobileAppInvitationEmailMutation__
 *
 * To run a mutation, you first call `useSendMobileAppInvitationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMobileAppInvitationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMobileAppInvitationEmailMutation, { data, loading, error }] = useSendMobileAppInvitationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMobileAppInvitationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendMobileAppInvitationEmailMutation, SendMobileAppInvitationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMobileAppInvitationEmailMutation, SendMobileAppInvitationEmailMutationVariables>(SendMobileAppInvitationEmailDocument, options);
      }
export type SendMobileAppInvitationEmailMutationHookResult = ReturnType<typeof useSendMobileAppInvitationEmailMutation>;
export type SendMobileAppInvitationEmailMutationResult = Apollo.MutationResult<SendMobileAppInvitationEmailMutation>;
export type SendMobileAppInvitationEmailMutationOptions = Apollo.BaseMutationOptions<SendMobileAppInvitationEmailMutation, SendMobileAppInvitationEmailMutationVariables>;
export const UpdateAccountsPayableNumberForEmployeeContactDocument = gql`
    mutation updateAccountsPayableNumberForEmployeeContact($membershipId: ID!, $accountsPayableNumber: AccountsPayableNumber) {
  updateAccountsPayableNumberForEmployeeContact(
    membershipId: $membershipId
    accountsPayableNumber: $accountsPayableNumber
  ) {
    id
    name {
      value
    }
  }
}
    `;
export type UpdateAccountsPayableNumberForEmployeeContactMutationFn = Apollo.MutationFunction<UpdateAccountsPayableNumberForEmployeeContactMutation, UpdateAccountsPayableNumberForEmployeeContactMutationVariables>;

/**
 * __useUpdateAccountsPayableNumberForEmployeeContactMutation__
 *
 * To run a mutation, you first call `useUpdateAccountsPayableNumberForEmployeeContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountsPayableNumberForEmployeeContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountsPayableNumberForEmployeeContactMutation, { data, loading, error }] = useUpdateAccountsPayableNumberForEmployeeContactMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *      accountsPayableNumber: // value for 'accountsPayableNumber'
 *   },
 * });
 */
export function useUpdateAccountsPayableNumberForEmployeeContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountsPayableNumberForEmployeeContactMutation, UpdateAccountsPayableNumberForEmployeeContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountsPayableNumberForEmployeeContactMutation, UpdateAccountsPayableNumberForEmployeeContactMutationVariables>(UpdateAccountsPayableNumberForEmployeeContactDocument, options);
      }
export type UpdateAccountsPayableNumberForEmployeeContactMutationHookResult = ReturnType<typeof useUpdateAccountsPayableNumberForEmployeeContactMutation>;
export type UpdateAccountsPayableNumberForEmployeeContactMutationResult = Apollo.MutationResult<UpdateAccountsPayableNumberForEmployeeContactMutation>;
export type UpdateAccountsPayableNumberForEmployeeContactMutationOptions = Apollo.BaseMutationOptions<UpdateAccountsPayableNumberForEmployeeContactMutation, UpdateAccountsPayableNumberForEmployeeContactMutationVariables>;
export const TeamMembersDocument = gql`
    query teamMembers($name: String) {
  teamMembers(input: {name: $name}) {
    id
    avatarUrl
    name
    firstName
    lastName
    email
    locale
    roles
    membershipRoles {
      __typename
      id
      name
      description
      isArchived
      isBuiltinRole
    }
    username
    createdTimestamp
    status
  }
}
    `;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
      }
export function useTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = Apollo.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;
export const GetRolesForUserQueryDocument = gql`
    query getRolesForUserQuery($input: RolesPageBasedPaginationInput!, $queries: RolesQueryInput, $filters: RolesFiltersInput, $sortBy: RolesSortInput) {
  roles(input: $input, queries: $queries, filters: $filters, sortBy: $sortBy) {
    records {
      __typename
      id
      description
      isArchived
      ...BuiltInRoleData
      ...CustomRoleData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${BuiltInRoleDataFragmentDoc}
${CustomRoleDataFragmentDoc}`;

/**
 * __useGetRolesForUserQueryQuery__
 *
 * To run a query within a React component, call `useGetRolesForUserQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesForUserQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesForUserQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetRolesForUserQueryQuery(baseOptions: Apollo.QueryHookOptions<GetRolesForUserQueryQuery, GetRolesForUserQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesForUserQueryQuery, GetRolesForUserQueryQueryVariables>(GetRolesForUserQueryDocument, options);
      }
export function useGetRolesForUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesForUserQueryQuery, GetRolesForUserQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesForUserQueryQuery, GetRolesForUserQueryQueryVariables>(GetRolesForUserQueryDocument, options);
        }
export type GetRolesForUserQueryQueryHookResult = ReturnType<typeof useGetRolesForUserQueryQuery>;
export type GetRolesForUserQueryLazyQueryHookResult = ReturnType<typeof useGetRolesForUserQueryLazyQuery>;
export type GetRolesForUserQueryQueryResult = Apollo.QueryResult<GetRolesForUserQueryQuery, GetRolesForUserQueryQueryVariables>;
export const PendingInvitesDocument = gql`
    query pendingInvites {
  organizationMemberships(filters: {emailVerified: false}) {
    id
    name
    firstName
    lastName
    email
    locale
    roles
    membershipRoles {
      id
      name
      isBuiltinRole
      isLegacyRole
    }
    username
    invitedTimestamp
    createdTimestamp
    status
  }
}
    `;

/**
 * __usePendingInvitesQuery__
 *
 * To run a query within a React component, call `usePendingInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingInvitesQuery(baseOptions?: Apollo.QueryHookOptions<PendingInvitesQuery, PendingInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingInvitesQuery, PendingInvitesQueryVariables>(PendingInvitesDocument, options);
      }
export function usePendingInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingInvitesQuery, PendingInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingInvitesQuery, PendingInvitesQueryVariables>(PendingInvitesDocument, options);
        }
export type PendingInvitesQueryHookResult = ReturnType<typeof usePendingInvitesQuery>;
export type PendingInvitesLazyQueryHookResult = ReturnType<typeof usePendingInvitesLazyQuery>;
export type PendingInvitesQueryResult = Apollo.QueryResult<PendingInvitesQuery, PendingInvitesQueryVariables>;
export const UsersFieldOptionsDocument = gql`
    query usersFieldOptions($filters: OrganizationMembershipsFiltersInput) {
  organizationMemberships(filters: $filters) {
    id
    name
    avatarUrl
  }
}
    `;

/**
 * __useUsersFieldOptionsQuery__
 *
 * To run a query within a React component, call `useUsersFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersFieldOptionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersFieldOptionsQuery(baseOptions?: Apollo.QueryHookOptions<UsersFieldOptionsQuery, UsersFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersFieldOptionsQuery, UsersFieldOptionsQueryVariables>(UsersFieldOptionsDocument, options);
      }
export function useUsersFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersFieldOptionsQuery, UsersFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersFieldOptionsQuery, UsersFieldOptionsQueryVariables>(UsersFieldOptionsDocument, options);
        }
export type UsersFieldOptionsQueryHookResult = ReturnType<typeof useUsersFieldOptionsQuery>;
export type UsersFieldOptionsLazyQueryHookResult = ReturnType<typeof useUsersFieldOptionsLazyQuery>;
export type UsersFieldOptionsQueryResult = Apollo.QueryResult<UsersFieldOptionsQuery, UsersFieldOptionsQueryVariables>;
export const CostCenterCountForApproverDocument = gql`
    query costCenterCountForApprover($membershipId: String!) {
  costCenterCountForApprover(membershipId: $membershipId)
}
    `;

/**
 * __useCostCenterCountForApproverQuery__
 *
 * To run a query within a React component, call `useCostCenterCountForApproverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterCountForApproverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterCountForApproverQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useCostCenterCountForApproverQuery(baseOptions: Apollo.QueryHookOptions<CostCenterCountForApproverQuery, CostCenterCountForApproverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterCountForApproverQuery, CostCenterCountForApproverQueryVariables>(CostCenterCountForApproverDocument, options);
      }
export function useCostCenterCountForApproverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterCountForApproverQuery, CostCenterCountForApproverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterCountForApproverQuery, CostCenterCountForApproverQueryVariables>(CostCenterCountForApproverDocument, options);
        }
export type CostCenterCountForApproverQueryHookResult = ReturnType<typeof useCostCenterCountForApproverQuery>;
export type CostCenterCountForApproverLazyQueryHookResult = ReturnType<typeof useCostCenterCountForApproverLazyQuery>;
export type CostCenterCountForApproverQueryResult = Apollo.QueryResult<CostCenterCountForApproverQuery, CostCenterCountForApproverQueryVariables>;
export const CostCenterArchivedCountForApproverDocument = gql`
    query costCenterArchivedCountForApprover($membershipId: String!) {
  costCenterArchivedCountForApprover(membershipId: $membershipId)
}
    `;

/**
 * __useCostCenterArchivedCountForApproverQuery__
 *
 * To run a query within a React component, call `useCostCenterArchivedCountForApproverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterArchivedCountForApproverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterArchivedCountForApproverQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useCostCenterArchivedCountForApproverQuery(baseOptions: Apollo.QueryHookOptions<CostCenterArchivedCountForApproverQuery, CostCenterArchivedCountForApproverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterArchivedCountForApproverQuery, CostCenterArchivedCountForApproverQueryVariables>(CostCenterArchivedCountForApproverDocument, options);
      }
export function useCostCenterArchivedCountForApproverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterArchivedCountForApproverQuery, CostCenterArchivedCountForApproverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterArchivedCountForApproverQuery, CostCenterArchivedCountForApproverQueryVariables>(CostCenterArchivedCountForApproverDocument, options);
        }
export type CostCenterArchivedCountForApproverQueryHookResult = ReturnType<typeof useCostCenterArchivedCountForApproverQuery>;
export type CostCenterArchivedCountForApproverLazyQueryHookResult = ReturnType<typeof useCostCenterArchivedCountForApproverLazyQuery>;
export type CostCenterArchivedCountForApproverQueryResult = Apollo.QueryResult<CostCenterArchivedCountForApproverQuery, CostCenterArchivedCountForApproverQueryVariables>;
export const GetCountOfWorkflowTemplatesByApproverIdDocument = gql`
    query getCountOfWorkflowTemplatesByApproverId($approverId: String!) {
  getCountOfWorkflowTemplatesByApproverId(approverId: $approverId) {
    active
    archived
    all
  }
}
    `;

/**
 * __useGetCountOfWorkflowTemplatesByApproverIdQuery__
 *
 * To run a query within a React component, call `useGetCountOfWorkflowTemplatesByApproverIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfWorkflowTemplatesByApproverIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOfWorkflowTemplatesByApproverIdQuery({
 *   variables: {
 *      approverId: // value for 'approverId'
 *   },
 * });
 */
export function useGetCountOfWorkflowTemplatesByApproverIdQuery(baseOptions: Apollo.QueryHookOptions<GetCountOfWorkflowTemplatesByApproverIdQuery, GetCountOfWorkflowTemplatesByApproverIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountOfWorkflowTemplatesByApproverIdQuery, GetCountOfWorkflowTemplatesByApproverIdQueryVariables>(GetCountOfWorkflowTemplatesByApproverIdDocument, options);
      }
export function useGetCountOfWorkflowTemplatesByApproverIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountOfWorkflowTemplatesByApproverIdQuery, GetCountOfWorkflowTemplatesByApproverIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountOfWorkflowTemplatesByApproverIdQuery, GetCountOfWorkflowTemplatesByApproverIdQueryVariables>(GetCountOfWorkflowTemplatesByApproverIdDocument, options);
        }
export type GetCountOfWorkflowTemplatesByApproverIdQueryHookResult = ReturnType<typeof useGetCountOfWorkflowTemplatesByApproverIdQuery>;
export type GetCountOfWorkflowTemplatesByApproverIdLazyQueryHookResult = ReturnType<typeof useGetCountOfWorkflowTemplatesByApproverIdLazyQuery>;
export type GetCountOfWorkflowTemplatesByApproverIdQueryResult = Apollo.QueryResult<GetCountOfWorkflowTemplatesByApproverIdQuery, GetCountOfWorkflowTemplatesByApproverIdQueryVariables>;
export const CountPendingDocumentsByMembershipDocument = gql`
    query countPendingDocumentsByMembership($membershipId: String!) {
  countPendingDocumentsByMembership(membershipId: $membershipId) {
    count
  }
}
    `;

/**
 * __useCountPendingDocumentsByMembershipQuery__
 *
 * To run a query within a React component, call `useCountPendingDocumentsByMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountPendingDocumentsByMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountPendingDocumentsByMembershipQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useCountPendingDocumentsByMembershipQuery(baseOptions: Apollo.QueryHookOptions<CountPendingDocumentsByMembershipQuery, CountPendingDocumentsByMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountPendingDocumentsByMembershipQuery, CountPendingDocumentsByMembershipQueryVariables>(CountPendingDocumentsByMembershipDocument, options);
      }
export function useCountPendingDocumentsByMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountPendingDocumentsByMembershipQuery, CountPendingDocumentsByMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountPendingDocumentsByMembershipQuery, CountPendingDocumentsByMembershipQueryVariables>(CountPendingDocumentsByMembershipDocument, options);
        }
export type CountPendingDocumentsByMembershipQueryHookResult = ReturnType<typeof useCountPendingDocumentsByMembershipQuery>;
export type CountPendingDocumentsByMembershipLazyQueryHookResult = ReturnType<typeof useCountPendingDocumentsByMembershipLazyQuery>;
export type CountPendingDocumentsByMembershipQueryResult = Apollo.QueryResult<CountPendingDocumentsByMembershipQuery, CountPendingDocumentsByMembershipQueryVariables>;
export const CountPendingDocumentsAndReimbursementsByMembershipDocument = gql`
    query countPendingDocumentsAndReimbursementsByMembership($membershipId: String!) {
  pendingDocuments: countPendingDocumentsByMembership(membershipId: $membershipId) {
    count
  }
  pendingReimbursements: countPendingReimbursementCasesByMembership(
    membershipId: $membershipId
  ) {
    count
  }
}
    `;

/**
 * __useCountPendingDocumentsAndReimbursementsByMembershipQuery__
 *
 * To run a query within a React component, call `useCountPendingDocumentsAndReimbursementsByMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountPendingDocumentsAndReimbursementsByMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountPendingDocumentsAndReimbursementsByMembershipQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useCountPendingDocumentsAndReimbursementsByMembershipQuery(baseOptions: Apollo.QueryHookOptions<CountPendingDocumentsAndReimbursementsByMembershipQuery, CountPendingDocumentsAndReimbursementsByMembershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountPendingDocumentsAndReimbursementsByMembershipQuery, CountPendingDocumentsAndReimbursementsByMembershipQueryVariables>(CountPendingDocumentsAndReimbursementsByMembershipDocument, options);
      }
export function useCountPendingDocumentsAndReimbursementsByMembershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountPendingDocumentsAndReimbursementsByMembershipQuery, CountPendingDocumentsAndReimbursementsByMembershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountPendingDocumentsAndReimbursementsByMembershipQuery, CountPendingDocumentsAndReimbursementsByMembershipQueryVariables>(CountPendingDocumentsAndReimbursementsByMembershipDocument, options);
        }
export type CountPendingDocumentsAndReimbursementsByMembershipQueryHookResult = ReturnType<typeof useCountPendingDocumentsAndReimbursementsByMembershipQuery>;
export type CountPendingDocumentsAndReimbursementsByMembershipLazyQueryHookResult = ReturnType<typeof useCountPendingDocumentsAndReimbursementsByMembershipLazyQuery>;
export type CountPendingDocumentsAndReimbursementsByMembershipQueryResult = Apollo.QueryResult<CountPendingDocumentsAndReimbursementsByMembershipQuery, CountPendingDocumentsAndReimbursementsByMembershipQueryVariables>;
export const CreditCardInvolvementsDocument = gql`
    query creditCardInvolvements($membershipId: String!) {
  creditCardInvolvements(input: {membershipId: $membershipId}) {
    cards {
      total
    }
    cardholder {
      id
    }
  }
}
    `;

/**
 * __useCreditCardInvolvementsQuery__
 *
 * To run a query within a React component, call `useCreditCardInvolvementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardInvolvementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardInvolvementsQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useCreditCardInvolvementsQuery(baseOptions: Apollo.QueryHookOptions<CreditCardInvolvementsQuery, CreditCardInvolvementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditCardInvolvementsQuery, CreditCardInvolvementsQueryVariables>(CreditCardInvolvementsDocument, options);
      }
export function useCreditCardInvolvementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditCardInvolvementsQuery, CreditCardInvolvementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditCardInvolvementsQuery, CreditCardInvolvementsQueryVariables>(CreditCardInvolvementsDocument, options);
        }
export type CreditCardInvolvementsQueryHookResult = ReturnType<typeof useCreditCardInvolvementsQuery>;
export type CreditCardInvolvementsLazyQueryHookResult = ReturnType<typeof useCreditCardInvolvementsLazyQuery>;
export type CreditCardInvolvementsQueryResult = Apollo.QueryResult<CreditCardInvolvementsQuery, CreditCardInvolvementsQueryVariables>;
export const GetUserInvolvementForEcmDocumentsDocument = gql`
    query getUserInvolvementForEcmDocuments($membershipId: String!) {
  getUserInvolvementForEcmDocuments(membershipId: $membershipId) {
    responsiblePerson {
      count
    }
    notifyPerson {
      count
    }
  }
}
    `;

/**
 * __useGetUserInvolvementForEcmDocumentsQuery__
 *
 * To run a query within a React component, call `useGetUserInvolvementForEcmDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvolvementForEcmDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvolvementForEcmDocumentsQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useGetUserInvolvementForEcmDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetUserInvolvementForEcmDocumentsQuery, GetUserInvolvementForEcmDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInvolvementForEcmDocumentsQuery, GetUserInvolvementForEcmDocumentsQueryVariables>(GetUserInvolvementForEcmDocumentsDocument, options);
      }
export function useGetUserInvolvementForEcmDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInvolvementForEcmDocumentsQuery, GetUserInvolvementForEcmDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInvolvementForEcmDocumentsQuery, GetUserInvolvementForEcmDocumentsQueryVariables>(GetUserInvolvementForEcmDocumentsDocument, options);
        }
export type GetUserInvolvementForEcmDocumentsQueryHookResult = ReturnType<typeof useGetUserInvolvementForEcmDocumentsQuery>;
export type GetUserInvolvementForEcmDocumentsLazyQueryHookResult = ReturnType<typeof useGetUserInvolvementForEcmDocumentsLazyQuery>;
export type GetUserInvolvementForEcmDocumentsQueryResult = Apollo.QueryResult<GetUserInvolvementForEcmDocumentsQuery, GetUserInvolvementForEcmDocumentsQueryVariables>;
export const GetAllUsersEmailsDocument = gql`
    query getAllUsersEmails($filters: OrganizationMembershipsFiltersInput, $sortBy: MemberSortInput) {
  organizationMemberships(filters: $filters, sortBy: $sortBy) {
    email
  }
}
    `;

/**
 * __useGetAllUsersEmailsQuery__
 *
 * To run a query within a React component, call `useGetAllUsersEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersEmailsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetAllUsersEmailsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersEmailsQuery, GetAllUsersEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersEmailsQuery, GetAllUsersEmailsQueryVariables>(GetAllUsersEmailsDocument, options);
      }
export function useGetAllUsersEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersEmailsQuery, GetAllUsersEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersEmailsQuery, GetAllUsersEmailsQueryVariables>(GetAllUsersEmailsDocument, options);
        }
export type GetAllUsersEmailsQueryHookResult = ReturnType<typeof useGetAllUsersEmailsQuery>;
export type GetAllUsersEmailsLazyQueryHookResult = ReturnType<typeof useGetAllUsersEmailsLazyQuery>;
export type GetAllUsersEmailsQueryResult = Apollo.QueryResult<GetAllUsersEmailsQuery, GetAllUsersEmailsQueryVariables>;
export const ApproversDocument = gql`
    query approvers($name: String) {
  approvers(name: $name) {
    id
    name
    roles
    avatarUrl
  }
}
    `;

/**
 * __useApproversQuery__
 *
 * To run a query within a React component, call `useApproversQuery` and pass it any options that fit your needs.
 * When your component renders, `useApproversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApproversQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useApproversQuery(baseOptions?: Apollo.QueryHookOptions<ApproversQuery, ApproversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApproversQuery, ApproversQueryVariables>(ApproversDocument, options);
      }
export function useApproversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApproversQuery, ApproversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApproversQuery, ApproversQueryVariables>(ApproversDocument, options);
        }
export type ApproversQueryHookResult = ReturnType<typeof useApproversQuery>;
export type ApproversLazyQueryHookResult = ReturnType<typeof useApproversLazyQuery>;
export type ApproversQueryResult = Apollo.QueryResult<ApproversQuery, ApproversQueryVariables>;
export const OrganizationMembershipCountByStatusDocument = gql`
    query organizationMembershipCountByStatus {
  organizationMembershipCountByStatus {
    active
    inActive
    pending
  }
}
    `;

/**
 * __useOrganizationMembershipCountByStatusQuery__
 *
 * To run a query within a React component, call `useOrganizationMembershipCountByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembershipCountByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembershipCountByStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationMembershipCountByStatusQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationMembershipCountByStatusQuery, OrganizationMembershipCountByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationMembershipCountByStatusQuery, OrganizationMembershipCountByStatusQueryVariables>(OrganizationMembershipCountByStatusDocument, options);
      }
export function useOrganizationMembershipCountByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationMembershipCountByStatusQuery, OrganizationMembershipCountByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationMembershipCountByStatusQuery, OrganizationMembershipCountByStatusQueryVariables>(OrganizationMembershipCountByStatusDocument, options);
        }
export type OrganizationMembershipCountByStatusQueryHookResult = ReturnType<typeof useOrganizationMembershipCountByStatusQuery>;
export type OrganizationMembershipCountByStatusLazyQueryHookResult = ReturnType<typeof useOrganizationMembershipCountByStatusLazyQuery>;
export type OrganizationMembershipCountByStatusQueryResult = Apollo.QueryResult<OrganizationMembershipCountByStatusQuery, OrganizationMembershipCountByStatusQueryVariables>;
export const TeamFieldOptionsDocument = gql`
    query teamFieldOptions($input: TeamsPageBasedPaginationInput!, $queries: TeamQueryInput, $filters: TeamsFilterInput, $sortBy: TeamsSortInput) {
  teams(input: $input, queries: $queries, filters: $filters, sortBy: $sortBy) {
    records {
      id
      name
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useTeamFieldOptionsQuery__
 *
 * To run a query within a React component, call `useTeamFieldOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFieldOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFieldOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useTeamFieldOptionsQuery(baseOptions: Apollo.QueryHookOptions<TeamFieldOptionsQuery, TeamFieldOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamFieldOptionsQuery, TeamFieldOptionsQueryVariables>(TeamFieldOptionsDocument, options);
      }
export function useTeamFieldOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamFieldOptionsQuery, TeamFieldOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamFieldOptionsQuery, TeamFieldOptionsQueryVariables>(TeamFieldOptionsDocument, options);
        }
export type TeamFieldOptionsQueryHookResult = ReturnType<typeof useTeamFieldOptionsQuery>;
export type TeamFieldOptionsLazyQueryHookResult = ReturnType<typeof useTeamFieldOptionsLazyQuery>;
export type TeamFieldOptionsQueryResult = Apollo.QueryResult<TeamFieldOptionsQuery, TeamFieldOptionsQueryVariables>;
export const SelectedTeamsOptionsDocument = gql`
    query selectedTeamsOptions($input: TeamsPageBasedPaginationInput!, $queries: TeamQueryInput, $filters: TeamsFilterInput, $sortBy: TeamsSortInput) {
  teams(input: $input, queries: $queries, filters: $filters, sortBy: $sortBy) @connection(key: "selectedTeamsOptions", filter: ["filters", "sort", "queries"]) {
    records {
      id
      name
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useSelectedTeamsOptionsQuery__
 *
 * To run a query within a React component, call `useSelectedTeamsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedTeamsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedTeamsOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSelectedTeamsOptionsQuery(baseOptions: Apollo.QueryHookOptions<SelectedTeamsOptionsQuery, SelectedTeamsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectedTeamsOptionsQuery, SelectedTeamsOptionsQueryVariables>(SelectedTeamsOptionsDocument, options);
      }
export function useSelectedTeamsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectedTeamsOptionsQuery, SelectedTeamsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectedTeamsOptionsQuery, SelectedTeamsOptionsQueryVariables>(SelectedTeamsOptionsDocument, options);
        }
export type SelectedTeamsOptionsQueryHookResult = ReturnType<typeof useSelectedTeamsOptionsQuery>;
export type SelectedTeamsOptionsLazyQueryHookResult = ReturnType<typeof useSelectedTeamsOptionsLazyQuery>;
export type SelectedTeamsOptionsQueryResult = Apollo.QueryResult<SelectedTeamsOptionsQuery, SelectedTeamsOptionsQueryVariables>;
export const TeamInvolvementsByMembershipIdDocument = gql`
    query teamInvolvementsByMembershipId($input: TeamsPageBasedPaginationInput!, $filters: TeamsFilterInput, $sortBy: TeamsSortInput) {
  teamInvolvementsByMembershipId: teams(
    input: $input
    filters: $filters
    sortBy: $sortBy
  ) @connection(key: "teamInvolvementsByMembershipId", filter: ["filters", "sort"]) {
    records {
      id
      name
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useTeamInvolvementsByMembershipIdQuery__
 *
 * To run a query within a React component, call `useTeamInvolvementsByMembershipIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamInvolvementsByMembershipIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamInvolvementsByMembershipIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useTeamInvolvementsByMembershipIdQuery(baseOptions: Apollo.QueryHookOptions<TeamInvolvementsByMembershipIdQuery, TeamInvolvementsByMembershipIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamInvolvementsByMembershipIdQuery, TeamInvolvementsByMembershipIdQueryVariables>(TeamInvolvementsByMembershipIdDocument, options);
      }
export function useTeamInvolvementsByMembershipIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamInvolvementsByMembershipIdQuery, TeamInvolvementsByMembershipIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamInvolvementsByMembershipIdQuery, TeamInvolvementsByMembershipIdQueryVariables>(TeamInvolvementsByMembershipIdDocument, options);
        }
export type TeamInvolvementsByMembershipIdQueryHookResult = ReturnType<typeof useTeamInvolvementsByMembershipIdQuery>;
export type TeamInvolvementsByMembershipIdLazyQueryHookResult = ReturnType<typeof useTeamInvolvementsByMembershipIdLazyQuery>;
export type TeamInvolvementsByMembershipIdQueryResult = Apollo.QueryResult<TeamInvolvementsByMembershipIdQuery, TeamInvolvementsByMembershipIdQueryVariables>;
export const GetAccountPayableNumberByMembershipIdDocument = gql`
    query getAccountPayableNumberByMembershipId($membershipId: String!) {
  contactByEmployeeMembershipId(membershipId: $membershipId) {
    id
    name {
      value
    }
    accountsPayableNumber
  }
}
    `;

/**
 * __useGetAccountPayableNumberByMembershipIdQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableNumberByMembershipIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableNumberByMembershipIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableNumberByMembershipIdQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useGetAccountPayableNumberByMembershipIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableNumberByMembershipIdQuery, GetAccountPayableNumberByMembershipIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableNumberByMembershipIdQuery, GetAccountPayableNumberByMembershipIdQueryVariables>(GetAccountPayableNumberByMembershipIdDocument, options);
      }
export function useGetAccountPayableNumberByMembershipIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableNumberByMembershipIdQuery, GetAccountPayableNumberByMembershipIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableNumberByMembershipIdQuery, GetAccountPayableNumberByMembershipIdQueryVariables>(GetAccountPayableNumberByMembershipIdDocument, options);
        }
export type GetAccountPayableNumberByMembershipIdQueryHookResult = ReturnType<typeof useGetAccountPayableNumberByMembershipIdQuery>;
export type GetAccountPayableNumberByMembershipIdLazyQueryHookResult = ReturnType<typeof useGetAccountPayableNumberByMembershipIdLazyQuery>;
export type GetAccountPayableNumberByMembershipIdQueryResult = Apollo.QueryResult<GetAccountPayableNumberByMembershipIdQuery, GetAccountPayableNumberByMembershipIdQueryVariables>;
export const GetContactEmployeeTypeByMembershipIdDocument = gql`
    query getContactEmployeeTypeByMembershipId($membershipId: String!) {
  contactByEmployeeMembershipId(membershipId: $membershipId) {
    id
    name {
      value
    }
    employeeMembership {
      id
    }
  }
}
    `;

/**
 * __useGetContactEmployeeTypeByMembershipIdQuery__
 *
 * To run a query within a React component, call `useGetContactEmployeeTypeByMembershipIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactEmployeeTypeByMembershipIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactEmployeeTypeByMembershipIdQuery({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *   },
 * });
 */
export function useGetContactEmployeeTypeByMembershipIdQuery(baseOptions: Apollo.QueryHookOptions<GetContactEmployeeTypeByMembershipIdQuery, GetContactEmployeeTypeByMembershipIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactEmployeeTypeByMembershipIdQuery, GetContactEmployeeTypeByMembershipIdQueryVariables>(GetContactEmployeeTypeByMembershipIdDocument, options);
      }
export function useGetContactEmployeeTypeByMembershipIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactEmployeeTypeByMembershipIdQuery, GetContactEmployeeTypeByMembershipIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactEmployeeTypeByMembershipIdQuery, GetContactEmployeeTypeByMembershipIdQueryVariables>(GetContactEmployeeTypeByMembershipIdDocument, options);
        }
export type GetContactEmployeeTypeByMembershipIdQueryHookResult = ReturnType<typeof useGetContactEmployeeTypeByMembershipIdQuery>;
export type GetContactEmployeeTypeByMembershipIdLazyQueryHookResult = ReturnType<typeof useGetContactEmployeeTypeByMembershipIdLazyQuery>;
export type GetContactEmployeeTypeByMembershipIdQueryResult = Apollo.QueryResult<GetContactEmployeeTypeByMembershipIdQuery, GetContactEmployeeTypeByMembershipIdQueryVariables>;
export const GetNextFreeAccountsPayableNumberForEmployeesDocument = gql`
    query getNextFreeAccountsPayableNumberForEmployees($input: String!) {
  getNextFreeAccountsPayableNumberForEmployees(input: $input)
}
    `;

/**
 * __useGetNextFreeAccountsPayableNumberForEmployeesQuery__
 *
 * To run a query within a React component, call `useGetNextFreeAccountsPayableNumberForEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextFreeAccountsPayableNumberForEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextFreeAccountsPayableNumberForEmployeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNextFreeAccountsPayableNumberForEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetNextFreeAccountsPayableNumberForEmployeesQuery, GetNextFreeAccountsPayableNumberForEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextFreeAccountsPayableNumberForEmployeesQuery, GetNextFreeAccountsPayableNumberForEmployeesQueryVariables>(GetNextFreeAccountsPayableNumberForEmployeesDocument, options);
      }
export function useGetNextFreeAccountsPayableNumberForEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextFreeAccountsPayableNumberForEmployeesQuery, GetNextFreeAccountsPayableNumberForEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextFreeAccountsPayableNumberForEmployeesQuery, GetNextFreeAccountsPayableNumberForEmployeesQueryVariables>(GetNextFreeAccountsPayableNumberForEmployeesDocument, options);
        }
export type GetNextFreeAccountsPayableNumberForEmployeesQueryHookResult = ReturnType<typeof useGetNextFreeAccountsPayableNumberForEmployeesQuery>;
export type GetNextFreeAccountsPayableNumberForEmployeesLazyQueryHookResult = ReturnType<typeof useGetNextFreeAccountsPayableNumberForEmployeesLazyQuery>;
export type GetNextFreeAccountsPayableNumberForEmployeesQueryResult = Apollo.QueryResult<GetNextFreeAccountsPayableNumberForEmployeesQuery, GetNextFreeAccountsPayableNumberForEmployeesQueryVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($input: CreateTeamBodyInput!) {
  createTeam(input: $input) {
    id
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation updateTeam($id: ID!, $input: UpdateTeamBodyInput!) {
  updateTeam(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const SetTeamSettingsForCreditCardsDocument = gql`
    mutation setTeamSettingsForCreditCards($input: SetTeamSettingsForCreditCardsInput!) {
  setTeamSettingsForCreditCards(input: $input) {
    linkedCardIds
    teamAdminCanManageCards
  }
}
    `;
export type SetTeamSettingsForCreditCardsMutationFn = Apollo.MutationFunction<SetTeamSettingsForCreditCardsMutation, SetTeamSettingsForCreditCardsMutationVariables>;

/**
 * __useSetTeamSettingsForCreditCardsMutation__
 *
 * To run a mutation, you first call `useSetTeamSettingsForCreditCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTeamSettingsForCreditCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTeamSettingsForCreditCardsMutation, { data, loading, error }] = useSetTeamSettingsForCreditCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTeamSettingsForCreditCardsMutation(baseOptions?: Apollo.MutationHookOptions<SetTeamSettingsForCreditCardsMutation, SetTeamSettingsForCreditCardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTeamSettingsForCreditCardsMutation, SetTeamSettingsForCreditCardsMutationVariables>(SetTeamSettingsForCreditCardsDocument, options);
      }
export type SetTeamSettingsForCreditCardsMutationHookResult = ReturnType<typeof useSetTeamSettingsForCreditCardsMutation>;
export type SetTeamSettingsForCreditCardsMutationResult = Apollo.MutationResult<SetTeamSettingsForCreditCardsMutation>;
export type SetTeamSettingsForCreditCardsMutationOptions = Apollo.BaseMutationOptions<SetTeamSettingsForCreditCardsMutation, SetTeamSettingsForCreditCardsMutationVariables>;
export const SetTeamIsArchivedDocument = gql`
    mutation setTeamIsArchived($id: ID!, $isArchived: Boolean!) {
  setTeamIsArchived(id: $id, isArchived: $isArchived) {
    isArchived
  }
}
    `;
export type SetTeamIsArchivedMutationFn = Apollo.MutationFunction<SetTeamIsArchivedMutation, SetTeamIsArchivedMutationVariables>;

/**
 * __useSetTeamIsArchivedMutation__
 *
 * To run a mutation, you first call `useSetTeamIsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTeamIsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTeamIsArchivedMutation, { data, loading, error }] = useSetTeamIsArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useSetTeamIsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<SetTeamIsArchivedMutation, SetTeamIsArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTeamIsArchivedMutation, SetTeamIsArchivedMutationVariables>(SetTeamIsArchivedDocument, options);
      }
export type SetTeamIsArchivedMutationHookResult = ReturnType<typeof useSetTeamIsArchivedMutation>;
export type SetTeamIsArchivedMutationResult = Apollo.MutationResult<SetTeamIsArchivedMutation>;
export type SetTeamIsArchivedMutationOptions = Apollo.BaseMutationOptions<SetTeamIsArchivedMutation, SetTeamIsArchivedMutationVariables>;
export const TeamsDocument = gql`
    query teams($input: TeamsPageBasedPaginationInput!, $queries: TeamQueryInput, $filters: TeamsFilterInput, $sortBy: TeamsSortInput) {
  teams(input: $input, queries: $queries, filters: $filters, sortBy: $sortBy) {
    records {
      id
      name
      isArchived
      members {
        membership {
          id
          firstName
          lastName
          avatarUrl
        }
        isAdmin
      }
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      queries: // value for 'queries'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useTeamsQuery(baseOptions: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
      }
export function useTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
        }
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
export const TeamDocument = gql`
    query team($id: ID!) {
  team(id: $id) {
    id
    name
    isArchived
    updatedAt
    members {
      membership {
        id
        firstName
        lastName
        avatarUrl
      }
      isAdmin
    }
  }
}
    `;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamQuery(baseOptions: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const TeamsByNameDocument = gql`
    query teamsByName($input: TeamsPageBasedPaginationInput!, $name: String) {
  teamsByName: teams(input: $input, filters: {name: $name}) @connection(key: "teamsByName", filter: ["filters"]) {
    records {
      id
      name
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    `;

/**
 * __useTeamsByNameQuery__
 *
 * To run a query within a React component, call `useTeamsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTeamsByNameQuery(baseOptions: Apollo.QueryHookOptions<TeamsByNameQuery, TeamsByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsByNameQuery, TeamsByNameQueryVariables>(TeamsByNameDocument, options);
      }
export function useTeamsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsByNameQuery, TeamsByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsByNameQuery, TeamsByNameQueryVariables>(TeamsByNameDocument, options);
        }
export type TeamsByNameQueryHookResult = ReturnType<typeof useTeamsByNameQuery>;
export type TeamsByNameLazyQueryHookResult = ReturnType<typeof useTeamsByNameLazyQuery>;
export type TeamsByNameQueryResult = Apollo.QueryResult<TeamsByNameQuery, TeamsByNameQueryVariables>;
export const TeamSettingsForCreditCardsDocument = gql`
    query teamSettingsForCreditCards($input: TeamSettingsForCreditCardsInput!) {
  teamSettingsForCreditCards(input: $input) {
    teamAdminCanManageCards
    linkedCardIds
    limitThresholds {
      VIRTUAL {
        value
        currency
        precision
      }
      PHYSICAL {
        value
        currency
        precision
      }
      SINGLE_USE {
        value
        currency
        precision
      }
    }
  }
}
    `;

/**
 * __useTeamSettingsForCreditCardsQuery__
 *
 * To run a query within a React component, call `useTeamSettingsForCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSettingsForCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSettingsForCreditCardsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamSettingsForCreditCardsQuery(baseOptions: Apollo.QueryHookOptions<TeamSettingsForCreditCardsQuery, TeamSettingsForCreditCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamSettingsForCreditCardsQuery, TeamSettingsForCreditCardsQueryVariables>(TeamSettingsForCreditCardsDocument, options);
      }
export function useTeamSettingsForCreditCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamSettingsForCreditCardsQuery, TeamSettingsForCreditCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamSettingsForCreditCardsQuery, TeamSettingsForCreditCardsQueryVariables>(TeamSettingsForCreditCardsDocument, options);
        }
export type TeamSettingsForCreditCardsQueryHookResult = ReturnType<typeof useTeamSettingsForCreditCardsQuery>;
export type TeamSettingsForCreditCardsLazyQueryHookResult = ReturnType<typeof useTeamSettingsForCreditCardsLazyQuery>;
export type TeamSettingsForCreditCardsQueryResult = Apollo.QueryResult<TeamSettingsForCreditCardsQuery, TeamSettingsForCreditCardsQueryVariables>;
export const CountTeamsDocument = gql`
    query countTeams($filters: TeamsFilterInput) {
  countTeams(filters: $filters) {
    total
  }
}
    `;

/**
 * __useCountTeamsQuery__
 *
 * To run a query within a React component, call `useCountTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountTeamsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCountTeamsQuery(baseOptions?: Apollo.QueryHookOptions<CountTeamsQuery, CountTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountTeamsQuery, CountTeamsQueryVariables>(CountTeamsDocument, options);
      }
export function useCountTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountTeamsQuery, CountTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountTeamsQuery, CountTeamsQueryVariables>(CountTeamsDocument, options);
        }
export type CountTeamsQueryHookResult = ReturnType<typeof useCountTeamsQuery>;
export type CountTeamsLazyQueryHookResult = ReturnType<typeof useCountTeamsLazyQuery>;
export type CountTeamsQueryResult = Apollo.QueryResult<CountTeamsQuery, CountTeamsQueryVariables>;
export const CreateWorkflowTemplateDocument = gql`
    mutation createWorkflowTemplate($input: CreateWorkflowTemplateInput!) {
  createWorkflowTemplate(input: $input) {
    ...WorkflowTemplateData
  }
}
    ${WorkflowTemplateDataFragmentDoc}`;
export type CreateWorkflowTemplateMutationFn = Apollo.MutationFunction<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>;

/**
 * __useCreateWorkflowTemplateMutation__
 *
 * To run a mutation, you first call `useCreateWorkflowTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkflowTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkflowTemplateMutation, { data, loading, error }] = useCreateWorkflowTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkflowTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>(CreateWorkflowTemplateDocument, options);
      }
export type CreateWorkflowTemplateMutationHookResult = ReturnType<typeof useCreateWorkflowTemplateMutation>;
export type CreateWorkflowTemplateMutationResult = Apollo.MutationResult<CreateWorkflowTemplateMutation>;
export type CreateWorkflowTemplateMutationOptions = Apollo.BaseMutationOptions<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>;
export const GetWorkflowTemplateDocument = gql`
    query getWorkflowTemplate($id: ID!) {
  workflowTemplate(id: $id) {
    ...WorkflowTemplateData
    steps {
      conditions {
        field
        operator
        values
      }
      resolvers {
        id
        name
        avatarUrl
      }
    }
  }
}
    ${WorkflowTemplateDataFragmentDoc}`;

/**
 * __useGetWorkflowTemplateQuery__
 *
 * To run a query within a React component, call `useGetWorkflowTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkflowTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetWorkflowTemplateQuery, GetWorkflowTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkflowTemplateQuery, GetWorkflowTemplateQueryVariables>(GetWorkflowTemplateDocument, options);
      }
export function useGetWorkflowTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkflowTemplateQuery, GetWorkflowTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkflowTemplateQuery, GetWorkflowTemplateQueryVariables>(GetWorkflowTemplateDocument, options);
        }
export type GetWorkflowTemplateQueryHookResult = ReturnType<typeof useGetWorkflowTemplateQuery>;
export type GetWorkflowTemplateLazyQueryHookResult = ReturnType<typeof useGetWorkflowTemplateLazyQuery>;
export type GetWorkflowTemplateQueryResult = Apollo.QueryResult<GetWorkflowTemplateQuery, GetWorkflowTemplateQueryVariables>;
export const UpdateWorkflowTemplateDocument = gql`
    mutation updateWorkflowTemplate($id: ID!, $input: UpdateWorkflowTemplateInput!) {
  updateWorkflowTemplate(id: $id, input: $input) {
    ...WorkflowTemplateData
    steps {
      conditions {
        field
        operator
        values
      }
      resolvers {
        id
        name
        avatarUrl
      }
    }
  }
}
    ${WorkflowTemplateDataFragmentDoc}`;
export type UpdateWorkflowTemplateMutationFn = Apollo.MutationFunction<UpdateWorkflowTemplateMutation, UpdateWorkflowTemplateMutationVariables>;

/**
 * __useUpdateWorkflowTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateWorkflowTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkflowTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkflowTemplateMutation, { data, loading, error }] = useUpdateWorkflowTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkflowTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkflowTemplateMutation, UpdateWorkflowTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkflowTemplateMutation, UpdateWorkflowTemplateMutationVariables>(UpdateWorkflowTemplateDocument, options);
      }
export type UpdateWorkflowTemplateMutationHookResult = ReturnType<typeof useUpdateWorkflowTemplateMutation>;
export type UpdateWorkflowTemplateMutationResult = Apollo.MutationResult<UpdateWorkflowTemplateMutation>;
export type UpdateWorkflowTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateWorkflowTemplateMutation, UpdateWorkflowTemplateMutationVariables>;
export const ArchiveWorkflowTemplateDocument = gql`
    mutation archiveWorkflowTemplate($id: ID!) {
  archiveWorkflowTemplate(id: $id) {
    ...WorkflowTemplateData
  }
}
    ${WorkflowTemplateDataFragmentDoc}`;
export type ArchiveWorkflowTemplateMutationFn = Apollo.MutationFunction<ArchiveWorkflowTemplateMutation, ArchiveWorkflowTemplateMutationVariables>;

/**
 * __useArchiveWorkflowTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveWorkflowTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWorkflowTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWorkflowTemplateMutation, { data, loading, error }] = useArchiveWorkflowTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveWorkflowTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveWorkflowTemplateMutation, ArchiveWorkflowTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveWorkflowTemplateMutation, ArchiveWorkflowTemplateMutationVariables>(ArchiveWorkflowTemplateDocument, options);
      }
export type ArchiveWorkflowTemplateMutationHookResult = ReturnType<typeof useArchiveWorkflowTemplateMutation>;
export type ArchiveWorkflowTemplateMutationResult = Apollo.MutationResult<ArchiveWorkflowTemplateMutation>;
export type ArchiveWorkflowTemplateMutationOptions = Apollo.BaseMutationOptions<ArchiveWorkflowTemplateMutation, ArchiveWorkflowTemplateMutationVariables>;
export const RestoreWorkflowTemplateDocument = gql`
    mutation restoreWorkflowTemplate($id: ID!) {
  restoreWorkflowTemplate(id: $id) {
    ...WorkflowTemplateData
  }
}
    ${WorkflowTemplateDataFragmentDoc}`;
export type RestoreWorkflowTemplateMutationFn = Apollo.MutationFunction<RestoreWorkflowTemplateMutation, RestoreWorkflowTemplateMutationVariables>;

/**
 * __useRestoreWorkflowTemplateMutation__
 *
 * To run a mutation, you first call `useRestoreWorkflowTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreWorkflowTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreWorkflowTemplateMutation, { data, loading, error }] = useRestoreWorkflowTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreWorkflowTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RestoreWorkflowTemplateMutation, RestoreWorkflowTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreWorkflowTemplateMutation, RestoreWorkflowTemplateMutationVariables>(RestoreWorkflowTemplateDocument, options);
      }
export type RestoreWorkflowTemplateMutationHookResult = ReturnType<typeof useRestoreWorkflowTemplateMutation>;
export type RestoreWorkflowTemplateMutationResult = Apollo.MutationResult<RestoreWorkflowTemplateMutation>;
export type RestoreWorkflowTemplateMutationOptions = Apollo.BaseMutationOptions<RestoreWorkflowTemplateMutation, RestoreWorkflowTemplateMutationVariables>;
export const WorkflowTemplatesCountersDocument = gql`
    query workflowTemplatesCounters {
  workflowTemplatesCounters {
    archived
    active
  }
}
    `;

/**
 * __useWorkflowTemplatesCountersQuery__
 *
 * To run a query within a React component, call `useWorkflowTemplatesCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowTemplatesCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowTemplatesCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkflowTemplatesCountersQuery(baseOptions?: Apollo.QueryHookOptions<WorkflowTemplatesCountersQuery, WorkflowTemplatesCountersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowTemplatesCountersQuery, WorkflowTemplatesCountersQueryVariables>(WorkflowTemplatesCountersDocument, options);
      }
export function useWorkflowTemplatesCountersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowTemplatesCountersQuery, WorkflowTemplatesCountersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowTemplatesCountersQuery, WorkflowTemplatesCountersQueryVariables>(WorkflowTemplatesCountersDocument, options);
        }
export type WorkflowTemplatesCountersQueryHookResult = ReturnType<typeof useWorkflowTemplatesCountersQuery>;
export type WorkflowTemplatesCountersLazyQueryHookResult = ReturnType<typeof useWorkflowTemplatesCountersLazyQuery>;
export type WorkflowTemplatesCountersQueryResult = Apollo.QueryResult<WorkflowTemplatesCountersQuery, WorkflowTemplatesCountersQueryVariables>;
export const WorkflowTemplatesDocument = gql`
    query workflowTemplates($input: GetWorkflowTemplatesInput, $sort: SortInput) {
  workflowTemplates(input: $input, sort: $sort) {
    id
    name
    description
    createdAt
    creator {
      name
      avatarUrl
    }
    steps {
      resolvers {
        id
        name
        avatarUrl
      }
    }
  }
}
    `;

/**
 * __useWorkflowTemplatesQuery__
 *
 * To run a query within a React component, call `useWorkflowTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useWorkflowTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>(WorkflowTemplatesDocument, options);
      }
export function useWorkflowTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>(WorkflowTemplatesDocument, options);
        }
export type WorkflowTemplatesQueryHookResult = ReturnType<typeof useWorkflowTemplatesQuery>;
export type WorkflowTemplatesLazyQueryHookResult = ReturnType<typeof useWorkflowTemplatesLazyQuery>;
export type WorkflowTemplatesQueryResult = Apollo.QueryResult<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>;
export const WorkflowTemplatesFieldItemsDocument = gql`
    query workflowTemplatesFieldItems {
  approvers {
    id
    name
    avatarUrl
  }
  getOrgMembersAbsence {
    fromDate
    membershipId
    note
    status
    toDate
    substituteMembershipId
  }
}
    `;

/**
 * __useWorkflowTemplatesFieldItemsQuery__
 *
 * To run a query within a React component, call `useWorkflowTemplatesFieldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowTemplatesFieldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowTemplatesFieldItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkflowTemplatesFieldItemsQuery(baseOptions?: Apollo.QueryHookOptions<WorkflowTemplatesFieldItemsQuery, WorkflowTemplatesFieldItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowTemplatesFieldItemsQuery, WorkflowTemplatesFieldItemsQueryVariables>(WorkflowTemplatesFieldItemsDocument, options);
      }
export function useWorkflowTemplatesFieldItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowTemplatesFieldItemsQuery, WorkflowTemplatesFieldItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowTemplatesFieldItemsQuery, WorkflowTemplatesFieldItemsQueryVariables>(WorkflowTemplatesFieldItemsDocument, options);
        }
export type WorkflowTemplatesFieldItemsQueryHookResult = ReturnType<typeof useWorkflowTemplatesFieldItemsQuery>;
export type WorkflowTemplatesFieldItemsLazyQueryHookResult = ReturnType<typeof useWorkflowTemplatesFieldItemsLazyQuery>;
export type WorkflowTemplatesFieldItemsQueryResult = Apollo.QueryResult<WorkflowTemplatesFieldItemsQuery, WorkflowTemplatesFieldItemsQueryVariables>;
export const SetTransactionInvoiceNeededDocument = gql`
    mutation setTransactionInvoiceNeeded($input: SetTransactionInvoiceNeededInput!) {
  setTransactionInvoiceNeeded(input: $input) {
    id
  }
}
    `;
export type SetTransactionInvoiceNeededMutationFn = Apollo.MutationFunction<SetTransactionInvoiceNeededMutation, SetTransactionInvoiceNeededMutationVariables>;

/**
 * __useSetTransactionInvoiceNeededMutation__
 *
 * To run a mutation, you first call `useSetTransactionInvoiceNeededMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTransactionInvoiceNeededMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTransactionInvoiceNeededMutation, { data, loading, error }] = useSetTransactionInvoiceNeededMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTransactionInvoiceNeededMutation(baseOptions?: Apollo.MutationHookOptions<SetTransactionInvoiceNeededMutation, SetTransactionInvoiceNeededMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTransactionInvoiceNeededMutation, SetTransactionInvoiceNeededMutationVariables>(SetTransactionInvoiceNeededDocument, options);
      }
export type SetTransactionInvoiceNeededMutationHookResult = ReturnType<typeof useSetTransactionInvoiceNeededMutation>;
export type SetTransactionInvoiceNeededMutationResult = Apollo.MutationResult<SetTransactionInvoiceNeededMutation>;
export type SetTransactionInvoiceNeededMutationOptions = Apollo.BaseMutationOptions<SetTransactionInvoiceNeededMutation, SetTransactionInvoiceNeededMutationVariables>;
export const SetTransactionInvoiceNotNeededDocument = gql`
    mutation setTransactionInvoiceNotNeeded($input: SetTransactionInvoiceNotNeededInput!) {
  setTransactionInvoiceNotNeeded(input: $input) {
    id
  }
}
    `;
export type SetTransactionInvoiceNotNeededMutationFn = Apollo.MutationFunction<SetTransactionInvoiceNotNeededMutation, SetTransactionInvoiceNotNeededMutationVariables>;

/**
 * __useSetTransactionInvoiceNotNeededMutation__
 *
 * To run a mutation, you first call `useSetTransactionInvoiceNotNeededMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTransactionInvoiceNotNeededMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTransactionInvoiceNotNeededMutation, { data, loading, error }] = useSetTransactionInvoiceNotNeededMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTransactionInvoiceNotNeededMutation(baseOptions?: Apollo.MutationHookOptions<SetTransactionInvoiceNotNeededMutation, SetTransactionInvoiceNotNeededMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTransactionInvoiceNotNeededMutation, SetTransactionInvoiceNotNeededMutationVariables>(SetTransactionInvoiceNotNeededDocument, options);
      }
export type SetTransactionInvoiceNotNeededMutationHookResult = ReturnType<typeof useSetTransactionInvoiceNotNeededMutation>;
export type SetTransactionInvoiceNotNeededMutationResult = Apollo.MutationResult<SetTransactionInvoiceNotNeededMutation>;
export type SetTransactionInvoiceNotNeededMutationOptions = Apollo.BaseMutationOptions<SetTransactionInvoiceNotNeededMutation, SetTransactionInvoiceNotNeededMutationVariables>;
export const GetCardIssuerTransactionByIdDocument = gql`
    query getCardIssuerTransactionById($id: ID!) {
  getCardIssuerTransactionById(id: $id) {
    ...TransactionDetailsData
  }
}
    ${TransactionDetailsDataFragmentDoc}`;

/**
 * __useGetCardIssuerTransactionByIdQuery__
 *
 * To run a query within a React component, call `useGetCardIssuerTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardIssuerTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardIssuerTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardIssuerTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardIssuerTransactionByIdQuery, GetCardIssuerTransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardIssuerTransactionByIdQuery, GetCardIssuerTransactionByIdQueryVariables>(GetCardIssuerTransactionByIdDocument, options);
      }
export function useGetCardIssuerTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardIssuerTransactionByIdQuery, GetCardIssuerTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardIssuerTransactionByIdQuery, GetCardIssuerTransactionByIdQueryVariables>(GetCardIssuerTransactionByIdDocument, options);
        }
export type GetCardIssuerTransactionByIdQueryHookResult = ReturnType<typeof useGetCardIssuerTransactionByIdQuery>;
export type GetCardIssuerTransactionByIdLazyQueryHookResult = ReturnType<typeof useGetCardIssuerTransactionByIdLazyQuery>;
export type GetCardIssuerTransactionByIdQueryResult = Apollo.QueryResult<GetCardIssuerTransactionByIdQuery, GetCardIssuerTransactionByIdQueryVariables>;
export const UnlinkTransactionFromDocumentDocument = gql`
    mutation unlinkTransactionFromDocument($documentId: String!, $transactionId: String!) {
  unlinkTransactionFromDocument(
    documentId: $documentId
    transactionId: $transactionId
  ) {
    id
  }
}
    `;
export type UnlinkTransactionFromDocumentMutationFn = Apollo.MutationFunction<UnlinkTransactionFromDocumentMutation, UnlinkTransactionFromDocumentMutationVariables>;

/**
 * __useUnlinkTransactionFromDocumentMutation__
 *
 * To run a mutation, you first call `useUnlinkTransactionFromDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkTransactionFromDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkTransactionFromDocumentMutation, { data, loading, error }] = useUnlinkTransactionFromDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useUnlinkTransactionFromDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkTransactionFromDocumentMutation, UnlinkTransactionFromDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkTransactionFromDocumentMutation, UnlinkTransactionFromDocumentMutationVariables>(UnlinkTransactionFromDocumentDocument, options);
      }
export type UnlinkTransactionFromDocumentMutationHookResult = ReturnType<typeof useUnlinkTransactionFromDocumentMutation>;
export type UnlinkTransactionFromDocumentMutationResult = Apollo.MutationResult<UnlinkTransactionFromDocumentMutation>;
export type UnlinkTransactionFromDocumentMutationOptions = Apollo.BaseMutationOptions<UnlinkTransactionFromDocumentMutation, UnlinkTransactionFromDocumentMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($fileId: String!) {
  deleteFile(fileId: $fileId)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const InboxTransactionAssociationsDocument = gql`
    query inboxTransactionAssociations($cursor: String) {
  prevTransaction: inboxCardIssuerTransactions(input: {limit: 1, before: $cursor}) {
    edges {
      cursor
      node {
        ...TransactionDetailsData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  nextTransaction: inboxCardIssuerTransactions(input: {limit: 1, after: $cursor}) {
    edges {
      cursor
      node {
        ...TransactionDetailsData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${TransactionDetailsDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useInboxTransactionAssociationsQuery__
 *
 * To run a query within a React component, call `useInboxTransactionAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxTransactionAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxTransactionAssociationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useInboxTransactionAssociationsQuery(baseOptions?: Apollo.QueryHookOptions<InboxTransactionAssociationsQuery, InboxTransactionAssociationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxTransactionAssociationsQuery, InboxTransactionAssociationsQueryVariables>(InboxTransactionAssociationsDocument, options);
      }
export function useInboxTransactionAssociationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxTransactionAssociationsQuery, InboxTransactionAssociationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxTransactionAssociationsQuery, InboxTransactionAssociationsQueryVariables>(InboxTransactionAssociationsDocument, options);
        }
export type InboxTransactionAssociationsQueryHookResult = ReturnType<typeof useInboxTransactionAssociationsQuery>;
export type InboxTransactionAssociationsLazyQueryHookResult = ReturnType<typeof useInboxTransactionAssociationsLazyQuery>;
export type InboxTransactionAssociationsQueryResult = Apollo.QueryResult<InboxTransactionAssociationsQuery, InboxTransactionAssociationsQueryVariables>;
export const ArchiveTransactionAssociationsDocument = gql`
    query archiveTransactionAssociations($cursor: String) {
  prevTransaction: archiveCardIssuerTransactions(
    input: {limit: 1, before: $cursor}
  ) {
    edges {
      cursor
      node {
        ...TransactionDetailsData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  nextTransaction: archiveCardIssuerTransactions(
    input: {limit: 1, after: $cursor}
  ) {
    edges {
      cursor
      node {
        ...TransactionDetailsData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${TransactionDetailsDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useArchiveTransactionAssociationsQuery__
 *
 * To run a query within a React component, call `useArchiveTransactionAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveTransactionAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveTransactionAssociationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useArchiveTransactionAssociationsQuery(baseOptions?: Apollo.QueryHookOptions<ArchiveTransactionAssociationsQuery, ArchiveTransactionAssociationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchiveTransactionAssociationsQuery, ArchiveTransactionAssociationsQueryVariables>(ArchiveTransactionAssociationsDocument, options);
      }
export function useArchiveTransactionAssociationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchiveTransactionAssociationsQuery, ArchiveTransactionAssociationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchiveTransactionAssociationsQuery, ArchiveTransactionAssociationsQueryVariables>(ArchiveTransactionAssociationsDocument, options);
        }
export type ArchiveTransactionAssociationsQueryHookResult = ReturnType<typeof useArchiveTransactionAssociationsQuery>;
export type ArchiveTransactionAssociationsLazyQueryHookResult = ReturnType<typeof useArchiveTransactionAssociationsLazyQuery>;
export type ArchiveTransactionAssociationsQueryResult = Apollo.QueryResult<ArchiveTransactionAssociationsQuery, ArchiveTransactionAssociationsQueryVariables>;
export const ListDocumentsWithNoTransactionDocument = gql`
    query listDocumentsWithNoTransaction($limit: Int, $after: String, $filters: DocumentFilterInput, $sort: SortInput) {
  listInboxDocuments(limit: $limit, after: $after, filters: $filters, sort: $sort) @connection(key: "listDocumentsWithNoTransaction", filter: ["filters", "sort"]) {
    edges {
      cursor
      node {
        isDuplicate
        isOverdue
        documentFile {
          name
        }
        id
        status
        contact {
          value {
            id
            name {
              value
            }
          }
        }
        currency {
          value
        }
        extractedContact {
          cachingKey
          name {
            value
          }
        }
        createdAt
        hasAttachments
        amount {
          value
        }
        invoiceNumber {
          value
        }
        invoiceDate {
          value
        }
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${PageInfoDataFragmentDoc}`;

/**
 * __useListDocumentsWithNoTransactionQuery__
 *
 * To run a query within a React component, call `useListDocumentsWithNoTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsWithNoTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDocumentsWithNoTransactionQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListDocumentsWithNoTransactionQuery(baseOptions?: Apollo.QueryHookOptions<ListDocumentsWithNoTransactionQuery, ListDocumentsWithNoTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDocumentsWithNoTransactionQuery, ListDocumentsWithNoTransactionQueryVariables>(ListDocumentsWithNoTransactionDocument, options);
      }
export function useListDocumentsWithNoTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDocumentsWithNoTransactionQuery, ListDocumentsWithNoTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDocumentsWithNoTransactionQuery, ListDocumentsWithNoTransactionQueryVariables>(ListDocumentsWithNoTransactionDocument, options);
        }
export type ListDocumentsWithNoTransactionQueryHookResult = ReturnType<typeof useListDocumentsWithNoTransactionQuery>;
export type ListDocumentsWithNoTransactionLazyQueryHookResult = ReturnType<typeof useListDocumentsWithNoTransactionLazyQuery>;
export type ListDocumentsWithNoTransactionQueryResult = Apollo.QueryResult<ListDocumentsWithNoTransactionQuery, ListDocumentsWithNoTransactionQueryVariables>;
export const CreateTransactionCommentDocument = gql`
    mutation createTransactionComment($transactionId: String!, $text: String!, $mentionedMembershipIds: [String!]) {
  createTransactionComment(
    transactionId: $transactionId
    text: $text
    mentionedMembershipIds: $mentionedMembershipIds
  ) {
    id
  }
}
    `;
export type CreateTransactionCommentMutationFn = Apollo.MutationFunction<CreateTransactionCommentMutation, CreateTransactionCommentMutationVariables>;

/**
 * __useCreateTransactionCommentMutation__
 *
 * To run a mutation, you first call `useCreateTransactionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionCommentMutation, { data, loading, error }] = useCreateTransactionCommentMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      text: // value for 'text'
 *      mentionedMembershipIds: // value for 'mentionedMembershipIds'
 *   },
 * });
 */
export function useCreateTransactionCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransactionCommentMutation, CreateTransactionCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransactionCommentMutation, CreateTransactionCommentMutationVariables>(CreateTransactionCommentDocument, options);
      }
export type CreateTransactionCommentMutationHookResult = ReturnType<typeof useCreateTransactionCommentMutation>;
export type CreateTransactionCommentMutationResult = Apollo.MutationResult<CreateTransactionCommentMutation>;
export type CreateTransactionCommentMutationOptions = Apollo.BaseMutationOptions<CreateTransactionCommentMutation, CreateTransactionCommentMutationVariables>;
export const GetTransactionHistoryDocument = gql`
    query getTransactionHistory($id: ID!) {
  getCardIssuerTransactionById(id: $id) {
    id
    status
    timeline {
      __typename
      ...TransactionCommentEventData
      ...TransactionStatusUpdatedEventData
      ...TransactionAmountChangedEventData
      ...TransactionReconciledEventData
      ...TransactionMarkedAsInvoiceNeededEventData
      ...TransactionMarkedAsInvoiceNotNeededEventData
      ...TransactionMarkedAsExportedEventData
    }
  }
}
    ${TransactionCommentEventDataFragmentDoc}
${TransactionStatusUpdatedEventDataFragmentDoc}
${TransactionAmountChangedEventDataFragmentDoc}
${TransactionReconciledEventDataFragmentDoc}
${TransactionMarkedAsInvoiceNeededEventDataFragmentDoc}
${TransactionMarkedAsInvoiceNotNeededEventDataFragmentDoc}
${TransactionMarkedAsExportedEventDataFragmentDoc}`;

/**
 * __useGetTransactionHistoryQuery__
 *
 * To run a query within a React component, call `useGetTransactionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionHistoryQuery, GetTransactionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionHistoryQuery, GetTransactionHistoryQueryVariables>(GetTransactionHistoryDocument, options);
      }
export function useGetTransactionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionHistoryQuery, GetTransactionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionHistoryQuery, GetTransactionHistoryQueryVariables>(GetTransactionHistoryDocument, options);
        }
export type GetTransactionHistoryQueryHookResult = ReturnType<typeof useGetTransactionHistoryQuery>;
export type GetTransactionHistoryLazyQueryHookResult = ReturnType<typeof useGetTransactionHistoryLazyQuery>;
export type GetTransactionHistoryQueryResult = Apollo.QueryResult<GetTransactionHistoryQuery, GetTransactionHistoryQueryVariables>;
export const GetPageBasedTransactionDownloadHistoryDocument = gql`
    query getPageBasedTransactionDownloadHistory($input: TransactionDownloadHistoryPageBasedPaginationInput!, $sortBy: TransactionDownloadHistorySortInput) {
  getPageBasedTransactionDownloadHistory(input: $input, sortBy: $sortBy) {
    records {
      id
      locale
      createdAt
      updatedAt
      createdBy {
        id
        name
        avatarUrl
      }
      file {
        id
        name
        url
      }
      filters {
        cards {
          id
          refNum
          cardholder {
            firstName
            lastName
          }
        }
        statuses
        types
        categories
        invoiceAssociationStatuses
        merchantNames
        dateRangeFilters {
          transactionCreatedAt {
            dateFrom
            dateTo
          }
        }
      }
    }
    pageInfo {
      totalCount
      pageSize
      currentPage
      pageCount
    }
  }
}
    `;

/**
 * __useGetPageBasedTransactionDownloadHistoryQuery__
 *
 * To run a query within a React component, call `useGetPageBasedTransactionDownloadHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageBasedTransactionDownloadHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageBasedTransactionDownloadHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetPageBasedTransactionDownloadHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetPageBasedTransactionDownloadHistoryQuery, GetPageBasedTransactionDownloadHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageBasedTransactionDownloadHistoryQuery, GetPageBasedTransactionDownloadHistoryQueryVariables>(GetPageBasedTransactionDownloadHistoryDocument, options);
      }
export function useGetPageBasedTransactionDownloadHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageBasedTransactionDownloadHistoryQuery, GetPageBasedTransactionDownloadHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageBasedTransactionDownloadHistoryQuery, GetPageBasedTransactionDownloadHistoryQueryVariables>(GetPageBasedTransactionDownloadHistoryDocument, options);
        }
export type GetPageBasedTransactionDownloadHistoryQueryHookResult = ReturnType<typeof useGetPageBasedTransactionDownloadHistoryQuery>;
export type GetPageBasedTransactionDownloadHistoryLazyQueryHookResult = ReturnType<typeof useGetPageBasedTransactionDownloadHistoryLazyQuery>;
export type GetPageBasedTransactionDownloadHistoryQueryResult = Apollo.QueryResult<GetPageBasedTransactionDownloadHistoryQuery, GetPageBasedTransactionDownloadHistoryQueryVariables>;
export const BookingAccountInfiniteScrollPaginationDocument = gql`
    query bookingAccountInfiniteScrollPagination($input: BookingAccountPaginationInput, $filters: BookingAccountFilterInput, $queries: BookingAccountQueryInput, $sortBy: BookingAccountSortInput) {
  bookingAccountPagination(
    input: $input
    filters: $filters
    queries: $queries
    sortBy: $sortBy
  ) @connection(key: "bookingAccountInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      ...BookingAccountData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${BookingAccountDataFragmentDoc}`;

/**
 * __useBookingAccountInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useBookingAccountInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAccountInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAccountInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useBookingAccountInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<BookingAccountInfiniteScrollPaginationQuery, BookingAccountInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingAccountInfiniteScrollPaginationQuery, BookingAccountInfiniteScrollPaginationQueryVariables>(BookingAccountInfiniteScrollPaginationDocument, options);
      }
export function useBookingAccountInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingAccountInfiniteScrollPaginationQuery, BookingAccountInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingAccountInfiniteScrollPaginationQuery, BookingAccountInfiniteScrollPaginationQueryVariables>(BookingAccountInfiniteScrollPaginationDocument, options);
        }
export type BookingAccountInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useBookingAccountInfiniteScrollPaginationQuery>;
export type BookingAccountInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useBookingAccountInfiniteScrollPaginationLazyQuery>;
export type BookingAccountInfiniteScrollPaginationQueryResult = Apollo.QueryResult<BookingAccountInfiniteScrollPaginationQuery, BookingAccountInfiniteScrollPaginationQueryVariables>;
export const NextFreeAccountsPayableNumberDocument = gql`
    query nextFreeAccountsPayableNumber {
  nextFreeAccountsPayableNumber
}
    `;

/**
 * __useNextFreeAccountsPayableNumberQuery__
 *
 * To run a query within a React component, call `useNextFreeAccountsPayableNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextFreeAccountsPayableNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextFreeAccountsPayableNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useNextFreeAccountsPayableNumberQuery(baseOptions?: Apollo.QueryHookOptions<NextFreeAccountsPayableNumberQuery, NextFreeAccountsPayableNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextFreeAccountsPayableNumberQuery, NextFreeAccountsPayableNumberQueryVariables>(NextFreeAccountsPayableNumberDocument, options);
      }
export function useNextFreeAccountsPayableNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextFreeAccountsPayableNumberQuery, NextFreeAccountsPayableNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextFreeAccountsPayableNumberQuery, NextFreeAccountsPayableNumberQueryVariables>(NextFreeAccountsPayableNumberDocument, options);
        }
export type NextFreeAccountsPayableNumberQueryHookResult = ReturnType<typeof useNextFreeAccountsPayableNumberQuery>;
export type NextFreeAccountsPayableNumberLazyQueryHookResult = ReturnType<typeof useNextFreeAccountsPayableNumberLazyQuery>;
export type NextFreeAccountsPayableNumberQueryResult = Apollo.QueryResult<NextFreeAccountsPayableNumberQuery, NextFreeAccountsPayableNumberQueryVariables>;
export const ContactsImportedForCompanyDocument = gql`
    query contactsImportedForCompany {
  contactsImportedForCompany
}
    `;

/**
 * __useContactsImportedForCompanyQuery__
 *
 * To run a query within a React component, call `useContactsImportedForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsImportedForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsImportedForCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsImportedForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<ContactsImportedForCompanyQuery, ContactsImportedForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsImportedForCompanyQuery, ContactsImportedForCompanyQueryVariables>(ContactsImportedForCompanyDocument, options);
      }
export function useContactsImportedForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsImportedForCompanyQuery, ContactsImportedForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsImportedForCompanyQuery, ContactsImportedForCompanyQueryVariables>(ContactsImportedForCompanyDocument, options);
        }
export type ContactsImportedForCompanyQueryHookResult = ReturnType<typeof useContactsImportedForCompanyQuery>;
export type ContactsImportedForCompanyLazyQueryHookResult = ReturnType<typeof useContactsImportedForCompanyLazyQuery>;
export type ContactsImportedForCompanyQueryResult = Apollo.QueryResult<ContactsImportedForCompanyQuery, ContactsImportedForCompanyQueryVariables>;
export const ContactByNameDocument = gql`
    query contactByName($name: String!) {
  contactByName(name: $name) {
    id
    relationshipType
    name {
      value
    }
  }
}
    `;

/**
 * __useContactByNameQuery__
 *
 * To run a query within a React component, call `useContactByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useContactByNameQuery(baseOptions: Apollo.QueryHookOptions<ContactByNameQuery, ContactByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactByNameQuery, ContactByNameQueryVariables>(ContactByNameDocument, options);
      }
export function useContactByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactByNameQuery, ContactByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactByNameQuery, ContactByNameQueryVariables>(ContactByNameDocument, options);
        }
export type ContactByNameQueryHookResult = ReturnType<typeof useContactByNameQuery>;
export type ContactByNameLazyQueryHookResult = ReturnType<typeof useContactByNameLazyQuery>;
export type ContactByNameQueryResult = Apollo.QueryResult<ContactByNameQuery, ContactByNameQueryVariables>;
export const ContactByAccountsNumberDocument = gql`
    query contactByAccountsNumber($accountsNumber: String!) {
  contactByAccountsNumber(accountsNumber: $accountsNumber) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;

/**
 * __useContactByAccountsNumberQuery__
 *
 * To run a query within a React component, call `useContactByAccountsNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactByAccountsNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactByAccountsNumberQuery({
 *   variables: {
 *      accountsNumber: // value for 'accountsNumber'
 *   },
 * });
 */
export function useContactByAccountsNumberQuery(baseOptions: Apollo.QueryHookOptions<ContactByAccountsNumberQuery, ContactByAccountsNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactByAccountsNumberQuery, ContactByAccountsNumberQueryVariables>(ContactByAccountsNumberDocument, options);
      }
export function useContactByAccountsNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactByAccountsNumberQuery, ContactByAccountsNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactByAccountsNumberQuery, ContactByAccountsNumberQueryVariables>(ContactByAccountsNumberDocument, options);
        }
export type ContactByAccountsNumberQueryHookResult = ReturnType<typeof useContactByAccountsNumberQuery>;
export type ContactByAccountsNumberLazyQueryHookResult = ReturnType<typeof useContactByAccountsNumberLazyQuery>;
export type ContactByAccountsNumberQueryResult = Apollo.QueryResult<ContactByAccountsNumberQuery, ContactByAccountsNumberQueryVariables>;
export const ContactByAccountsPayableNumberDocument = gql`
    query contactByAccountsPayableNumber($accountsPayableNumber: AccountsPayableNumber!) {
  contactByAccountsPayableNumber(accountsPayableNumber: $accountsPayableNumber) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;

/**
 * __useContactByAccountsPayableNumberQuery__
 *
 * To run a query within a React component, call `useContactByAccountsPayableNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactByAccountsPayableNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactByAccountsPayableNumberQuery({
 *   variables: {
 *      accountsPayableNumber: // value for 'accountsPayableNumber'
 *   },
 * });
 */
export function useContactByAccountsPayableNumberQuery(baseOptions: Apollo.QueryHookOptions<ContactByAccountsPayableNumberQuery, ContactByAccountsPayableNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactByAccountsPayableNumberQuery, ContactByAccountsPayableNumberQueryVariables>(ContactByAccountsPayableNumberDocument, options);
      }
export function useContactByAccountsPayableNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactByAccountsPayableNumberQuery, ContactByAccountsPayableNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactByAccountsPayableNumberQuery, ContactByAccountsPayableNumberQueryVariables>(ContactByAccountsPayableNumberDocument, options);
        }
export type ContactByAccountsPayableNumberQueryHookResult = ReturnType<typeof useContactByAccountsPayableNumberQuery>;
export type ContactByAccountsPayableNumberLazyQueryHookResult = ReturnType<typeof useContactByAccountsPayableNumberLazyQuery>;
export type ContactByAccountsPayableNumberQueryResult = Apollo.QueryResult<ContactByAccountsPayableNumberQuery, ContactByAccountsPayableNumberQueryVariables>;
export const SetNextFreeAccountsPayableNumberForContactDocument = gql`
    mutation setNextFreeAccountsPayableNumberForContact($id: ID!, $accountsPayableNumber: AccountsPayableNumber) {
  setNextFreeAccountsPayableNumberForContact(
    id: $id
    accountsPayableNumber: $accountsPayableNumber
  ) {
    ...ContactData
  }
}
    ${ContactDataFragmentDoc}`;
export type SetNextFreeAccountsPayableNumberForContactMutationFn = Apollo.MutationFunction<SetNextFreeAccountsPayableNumberForContactMutation, SetNextFreeAccountsPayableNumberForContactMutationVariables>;

/**
 * __useSetNextFreeAccountsPayableNumberForContactMutation__
 *
 * To run a mutation, you first call `useSetNextFreeAccountsPayableNumberForContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNextFreeAccountsPayableNumberForContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNextFreeAccountsPayableNumberForContactMutation, { data, loading, error }] = useSetNextFreeAccountsPayableNumberForContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountsPayableNumber: // value for 'accountsPayableNumber'
 *   },
 * });
 */
export function useSetNextFreeAccountsPayableNumberForContactMutation(baseOptions?: Apollo.MutationHookOptions<SetNextFreeAccountsPayableNumberForContactMutation, SetNextFreeAccountsPayableNumberForContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNextFreeAccountsPayableNumberForContactMutation, SetNextFreeAccountsPayableNumberForContactMutationVariables>(SetNextFreeAccountsPayableNumberForContactDocument, options);
      }
export type SetNextFreeAccountsPayableNumberForContactMutationHookResult = ReturnType<typeof useSetNextFreeAccountsPayableNumberForContactMutation>;
export type SetNextFreeAccountsPayableNumberForContactMutationResult = Apollo.MutationResult<SetNextFreeAccountsPayableNumberForContactMutation>;
export type SetNextFreeAccountsPayableNumberForContactMutationOptions = Apollo.BaseMutationOptions<SetNextFreeAccountsPayableNumberForContactMutation, SetNextFreeAccountsPayableNumberForContactMutationVariables>;
export const CountAllContactsDocument = gql`
    query countAllContacts {
  countContacts
}
    `;

/**
 * __useCountAllContactsQuery__
 *
 * To run a query within a React component, call `useCountAllContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAllContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountAllContactsQuery(baseOptions?: Apollo.QueryHookOptions<CountAllContactsQuery, CountAllContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountAllContactsQuery, CountAllContactsQueryVariables>(CountAllContactsDocument, options);
      }
export function useCountAllContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountAllContactsQuery, CountAllContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountAllContactsQuery, CountAllContactsQueryVariables>(CountAllContactsDocument, options);
        }
export type CountAllContactsQueryHookResult = ReturnType<typeof useCountAllContactsQuery>;
export type CountAllContactsLazyQueryHookResult = ReturnType<typeof useCountAllContactsLazyQuery>;
export type CountAllContactsQueryResult = Apollo.QueryResult<CountAllContactsQuery, CountAllContactsQueryVariables>;
export const CountExportableContactsDocument = gql`
    query countExportableContacts {
  countExportableContacts
}
    `;

/**
 * __useCountExportableContactsQuery__
 *
 * To run a query within a React component, call `useCountExportableContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountExportableContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountExportableContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountExportableContactsQuery(baseOptions?: Apollo.QueryHookOptions<CountExportableContactsQuery, CountExportableContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountExportableContactsQuery, CountExportableContactsQueryVariables>(CountExportableContactsDocument, options);
      }
export function useCountExportableContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountExportableContactsQuery, CountExportableContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountExportableContactsQuery, CountExportableContactsQueryVariables>(CountExportableContactsDocument, options);
        }
export type CountExportableContactsQueryHookResult = ReturnType<typeof useCountExportableContactsQuery>;
export type CountExportableContactsLazyQueryHookResult = ReturnType<typeof useCountExportableContactsLazyQuery>;
export type CountExportableContactsQueryResult = Apollo.QueryResult<CountExportableContactsQuery, CountExportableContactsQueryVariables>;
export const CountWithoutAccountsPayableNumberContactsDocument = gql`
    query countWithoutAccountsPayableNumberContacts {
  countContacts(filters: {hasAccountsPayableNumber: false})
}
    `;

/**
 * __useCountWithoutAccountsPayableNumberContactsQuery__
 *
 * To run a query within a React component, call `useCountWithoutAccountsPayableNumberContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountWithoutAccountsPayableNumberContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountWithoutAccountsPayableNumberContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountWithoutAccountsPayableNumberContactsQuery(baseOptions?: Apollo.QueryHookOptions<CountWithoutAccountsPayableNumberContactsQuery, CountWithoutAccountsPayableNumberContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountWithoutAccountsPayableNumberContactsQuery, CountWithoutAccountsPayableNumberContactsQueryVariables>(CountWithoutAccountsPayableNumberContactsDocument, options);
      }
export function useCountWithoutAccountsPayableNumberContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountWithoutAccountsPayableNumberContactsQuery, CountWithoutAccountsPayableNumberContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountWithoutAccountsPayableNumberContactsQuery, CountWithoutAccountsPayableNumberContactsQueryVariables>(CountWithoutAccountsPayableNumberContactsDocument, options);
        }
export type CountWithoutAccountsPayableNumberContactsQueryHookResult = ReturnType<typeof useCountWithoutAccountsPayableNumberContactsQuery>;
export type CountWithoutAccountsPayableNumberContactsLazyQueryHookResult = ReturnType<typeof useCountWithoutAccountsPayableNumberContactsLazyQuery>;
export type CountWithoutAccountsPayableNumberContactsQueryResult = Apollo.QueryResult<CountWithoutAccountsPayableNumberContactsQuery, CountWithoutAccountsPayableNumberContactsQueryVariables>;
export const CostCenterSearchDocument = gql`
    query costCenterSearch($input: CostCenterSearchInput!) {
  costCenterSearch(input: $input) {
    ...CostCenterData
  }
}
    ${CostCenterDataFragmentDoc}`;

/**
 * __useCostCenterSearchQuery__
 *
 * To run a query within a React component, call `useCostCenterSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCostCenterSearchQuery(baseOptions: Apollo.QueryHookOptions<CostCenterSearchQuery, CostCenterSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterSearchQuery, CostCenterSearchQueryVariables>(CostCenterSearchDocument, options);
      }
export function useCostCenterSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterSearchQuery, CostCenterSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterSearchQuery, CostCenterSearchQueryVariables>(CostCenterSearchDocument, options);
        }
export type CostCenterSearchQueryHookResult = ReturnType<typeof useCostCenterSearchQuery>;
export type CostCenterSearchLazyQueryHookResult = ReturnType<typeof useCostCenterSearchLazyQuery>;
export type CostCenterSearchQueryResult = Apollo.QueryResult<CostCenterSearchQuery, CostCenterSearchQueryVariables>;
export const CostCenterPaginationDocument = gql`
    query costCenterPagination($input: CostCenterPaginationInput, $filters: CostCenterFilterInput, $sortBy: CostCenterSortInput) {
  costCenterPagination(input: $input, filters: $filters, sortBy: $sortBy) {
    records {
      ...CostCenterData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${CostCenterDataFragmentDoc}`;

/**
 * __useCostCenterPaginationQuery__
 *
 * To run a query within a React component, call `useCostCenterPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useCostCenterPaginationQuery(baseOptions?: Apollo.QueryHookOptions<CostCenterPaginationQuery, CostCenterPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterPaginationQuery, CostCenterPaginationQueryVariables>(CostCenterPaginationDocument, options);
      }
export function useCostCenterPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterPaginationQuery, CostCenterPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterPaginationQuery, CostCenterPaginationQueryVariables>(CostCenterPaginationDocument, options);
        }
export type CostCenterPaginationQueryHookResult = ReturnType<typeof useCostCenterPaginationQuery>;
export type CostCenterPaginationLazyQueryHookResult = ReturnType<typeof useCostCenterPaginationLazyQuery>;
export type CostCenterPaginationQueryResult = Apollo.QueryResult<CostCenterPaginationQuery, CostCenterPaginationQueryVariables>;
export const CostCenterInfiniteScrollPaginationDocument = gql`
    query costCenterInfiniteScrollPagination($input: CostCenterPaginationInput, $filters: CostCenterFilterInput, $queries: CostCenterQueryInput, $sortBy: CostCenterSortInput) {
  costCenterPagination(
    input: $input
    filters: $filters
    queries: $queries
    sortBy: $sortBy
  ) @connection(key: "costCenterInfiniteScrollPagination", filter: ["filters", "queries", "sortBy"]) {
    records {
      ...CostCenterData
    }
    pageInfo {
      currentPage
      pageCount
      pageSize
      totalCount
    }
  }
}
    ${CostCenterDataFragmentDoc}`;

/**
 * __useCostCenterInfiniteScrollPaginationQuery__
 *
 * To run a query within a React component, call `useCostCenterInfiniteScrollPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterInfiniteScrollPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterInfiniteScrollPaginationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filters: // value for 'filters'
 *      queries: // value for 'queries'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useCostCenterInfiniteScrollPaginationQuery(baseOptions?: Apollo.QueryHookOptions<CostCenterInfiniteScrollPaginationQuery, CostCenterInfiniteScrollPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterInfiniteScrollPaginationQuery, CostCenterInfiniteScrollPaginationQueryVariables>(CostCenterInfiniteScrollPaginationDocument, options);
      }
export function useCostCenterInfiniteScrollPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterInfiniteScrollPaginationQuery, CostCenterInfiniteScrollPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterInfiniteScrollPaginationQuery, CostCenterInfiniteScrollPaginationQueryVariables>(CostCenterInfiniteScrollPaginationDocument, options);
        }
export type CostCenterInfiniteScrollPaginationQueryHookResult = ReturnType<typeof useCostCenterInfiniteScrollPaginationQuery>;
export type CostCenterInfiniteScrollPaginationLazyQueryHookResult = ReturnType<typeof useCostCenterInfiniteScrollPaginationLazyQuery>;
export type CostCenterInfiniteScrollPaginationQueryResult = Apollo.QueryResult<CostCenterInfiniteScrollPaginationQuery, CostCenterInfiniteScrollPaginationQueryVariables>;
export const CountCostCentersDocument = gql`
    query countCostCenters($filters: CostCenterFilterInput) {
  countCostCenters(filters: $filters)
}
    `;

/**
 * __useCountCostCentersQuery__
 *
 * To run a query within a React component, call `useCountCostCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCostCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCostCentersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCountCostCentersQuery(baseOptions?: Apollo.QueryHookOptions<CountCostCentersQuery, CountCostCentersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCostCentersQuery, CountCostCentersQueryVariables>(CountCostCentersDocument, options);
      }
export function useCountCostCentersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCostCentersQuery, CountCostCentersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCostCentersQuery, CountCostCentersQueryVariables>(CountCostCentersDocument, options);
        }
export type CountCostCentersQueryHookResult = ReturnType<typeof useCountCostCentersQuery>;
export type CountCostCentersLazyQueryHookResult = ReturnType<typeof useCountCostCentersLazyQuery>;
export type CountCostCentersQueryResult = Apollo.QueryResult<CountCostCentersQuery, CountCostCentersQueryVariables>;
export const CostCenterNameFilterDocument = gql`
    query costCenterNameFilter($name: String, $filter: CostCenterFilterOptionsInput) {
  costCenterNameFilter(name: $name, filter: $filter)
}
    `;

/**
 * __useCostCenterNameFilterQuery__
 *
 * To run a query within a React component, call `useCostCenterNameFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterNameFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterNameFilterQuery({
 *   variables: {
 *      name: // value for 'name'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostCenterNameFilterQuery(baseOptions?: Apollo.QueryHookOptions<CostCenterNameFilterQuery, CostCenterNameFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterNameFilterQuery, CostCenterNameFilterQueryVariables>(CostCenterNameFilterDocument, options);
      }
export function useCostCenterNameFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterNameFilterQuery, CostCenterNameFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterNameFilterQuery, CostCenterNameFilterQueryVariables>(CostCenterNameFilterDocument, options);
        }
export type CostCenterNameFilterQueryHookResult = ReturnType<typeof useCostCenterNameFilterQuery>;
export type CostCenterNameFilterLazyQueryHookResult = ReturnType<typeof useCostCenterNameFilterLazyQuery>;
export type CostCenterNameFilterQueryResult = Apollo.QueryResult<CostCenterNameFilterQuery, CostCenterNameFilterQueryVariables>;
export const CostCenterCodeFilterDocument = gql`
    query costCenterCodeFilter($code: String, $filter: CostCenterFilterOptionsInput) {
  costCenterCodeFilter(code: $code, filter: $filter)
}
    `;

/**
 * __useCostCenterCodeFilterQuery__
 *
 * To run a query within a React component, call `useCostCenterCodeFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterCodeFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterCodeFilterQuery({
 *   variables: {
 *      code: // value for 'code'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostCenterCodeFilterQuery(baseOptions?: Apollo.QueryHookOptions<CostCenterCodeFilterQuery, CostCenterCodeFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostCenterCodeFilterQuery, CostCenterCodeFilterQueryVariables>(CostCenterCodeFilterDocument, options);
      }
export function useCostCenterCodeFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostCenterCodeFilterQuery, CostCenterCodeFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostCenterCodeFilterQuery, CostCenterCodeFilterQueryVariables>(CostCenterCodeFilterDocument, options);
        }
export type CostCenterCodeFilterQueryHookResult = ReturnType<typeof useCostCenterCodeFilterQuery>;
export type CostCenterCodeFilterLazyQueryHookResult = ReturnType<typeof useCostCenterCodeFilterLazyQuery>;
export type CostCenterCodeFilterQueryResult = Apollo.QueryResult<CostCenterCodeFilterQuery, CostCenterCodeFilterQueryVariables>;
export const GetDocumentHistoryDocument = gql`
    query getDocumentHistory($id: ID!) {
  getDocument(id: $id) {
    id
    status
    timeline {
      __typename
      id
      createdAt
      ...ApprovedEventData
      ...ApproverExtractedEventData
      ...AttachmentConvertedToDocumentEventData
      ...AttachmentDeletedFromDocumentEventData
      ...AttachmentDetachedFromDocumentEventData
      ...ContactExtractionEventData
      ...CostCenterExtractionEventData
      ...DocumentCommentEventData
      ...DocumentConvertedToAttachmentEventData
      ...DocumentFileReplacedEventData
      ...DocumentPaymentInfoUpdatedEventData
      ...DocumentResetByCandisEventData
      ...DocumentResetManualEventData
      ...DocumentResetTransactionAmountUpdatedEventData
      ...DocumentResetTransactionLinkedEventData
      ...DocumentResetTransactionUnLinkedEventData
      ...DocumentResetUserDeactivationEventData
      ...DocumentResetUserRoleChangedEventData
      ...DocumentTransactionCommentCreatedEventData
      ...DocumentTransactionLinkedEventData
      ...DocumentTransactionStatusUpdatedEventData
      ...DocumentTransactionReconciledEventData
      ...DocumentTransactionUnlinkedEventData
      ...ExportedEventData
      ...FileAttachedToDocumentEventData
      ...FileUploadedByEmailEventData
      ...FileUploadedEventData
      ...InvalidEInvoiceDetectedEventData
      ...MetaDataExtractionEventData
      ...ProvisionExportedEventData
      ...ReversalExportedEventData
      ...RejectedEventData
      ...RequestApprovalEventData
      ...SepaTransferGeneratedEventData
      ...SingleBookingUpdatedEventData
      ...SplitBookingUpdatedEventData
      ...UserApprovalDocumentWorkflowStepData
      ...WorkflowStepSkippedEventData
      ...WorkflowTemplateAppliedToDocumentEventData
      ...ContactSupplierSendEmailEventData
      ...DocumentImportedByMigrationEventData
      ...SubstituteAddedEventData
      ...SubstituteRemovedEventData
      ...PurchaseLinkData
      ...PurchaseUnlinkData
      ...DocumentRelationshipCreatedEventData
      ...DocumentRelationshipRemovedEventData
      ...DocumentTagUpdatedEventData
      ...DocumentGrossNetTaxRoundingAmountChangedEventData
      ...AccountingAreaUpdatedEventData
      ...DocumentAssociationsCreatedEventData
      ...DocumentAssociationsRemovedEventData
      ...DocumentStoredAsEcmDocumentEventData
      ...DocumentUpdatedAsEcmDocumentEventData
      ...DocumentConvertedEventData
      ...DocumentClassifiedEventData
    }
  }
}
    ${ApprovedEventDataFragmentDoc}
${ApproverExtractedEventDataFragmentDoc}
${AttachmentConvertedToDocumentEventDataFragmentDoc}
${AttachmentDeletedFromDocumentEventDataFragmentDoc}
${AttachmentDetachedFromDocumentEventDataFragmentDoc}
${ContactExtractionEventDataFragmentDoc}
${CostCenterExtractionEventDataFragmentDoc}
${DocumentCommentEventDataFragmentDoc}
${DocumentConvertedToAttachmentEventDataFragmentDoc}
${DocumentFileReplacedEventDataFragmentDoc}
${DocumentPaymentInfoUpdatedEventDataFragmentDoc}
${DocumentResetByCandisEventDataFragmentDoc}
${DocumentResetManualEventDataFragmentDoc}
${DocumentResetTransactionAmountUpdatedEventDataFragmentDoc}
${DocumentResetTransactionLinkedEventDataFragmentDoc}
${DocumentResetTransactionUnLinkedEventDataFragmentDoc}
${DocumentResetUserDeactivationEventDataFragmentDoc}
${DocumentResetUserRoleChangedEventDataFragmentDoc}
${DocumentTransactionCommentCreatedEventDataFragmentDoc}
${DocumentTransactionLinkedEventDataFragmentDoc}
${DocumentTransactionStatusUpdatedEventDataFragmentDoc}
${DocumentTransactionReconciledEventDataFragmentDoc}
${DocumentTransactionUnlinkedEventDataFragmentDoc}
${ExportedEventDataFragmentDoc}
${FileAttachedToDocumentEventDataFragmentDoc}
${FileUploadedByEmailEventDataFragmentDoc}
${FileUploadedEventDataFragmentDoc}
${InvalidEInvoiceDetectedEventDataFragmentDoc}
${MetaDataExtractionEventDataFragmentDoc}
${ProvisionExportedEventDataFragmentDoc}
${ReversalExportedEventDataFragmentDoc}
${RejectedEventDataFragmentDoc}
${RequestApprovalEventDataFragmentDoc}
${SepaTransferGeneratedEventDataFragmentDoc}
${SingleBookingUpdatedEventDataFragmentDoc}
${SplitBookingUpdatedEventDataFragmentDoc}
${UserApprovalDocumentWorkflowStepDataFragmentDoc}
${WorkflowStepSkippedEventDataFragmentDoc}
${WorkflowTemplateAppliedToDocumentEventDataFragmentDoc}
${ContactSupplierSendEmailEventDataFragmentDoc}
${DocumentImportedByMigrationEventDataFragmentDoc}
${SubstituteAddedEventDataFragmentDoc}
${SubstituteRemovedEventDataFragmentDoc}
${PurchaseLinkDataFragmentDoc}
${PurchaseUnlinkDataFragmentDoc}
${DocumentRelationshipCreatedEventDataFragmentDoc}
${RelatedDocumentActivityFragmentDoc}
${DocumentRelationshipRemovedEventDataFragmentDoc}
${DocumentTagUpdatedEventDataFragmentDoc}
${DocumentGrossNetTaxRoundingAmountChangedEventDataFragmentDoc}
${AccountingAreaUpdatedEventDataFragmentDoc}
${DocumentAssociationsCreatedEventDataFragmentDoc}
${DocumentAssociationsRemovedEventDataFragmentDoc}
${DocumentStoredAsEcmDocumentEventDataFragmentDoc}
${DocumentUpdatedAsEcmDocumentEventDataFragmentDoc}
${DocumentConvertedEventDataFragmentDoc}
${DocumentClassifiedEventDataFragmentDoc}`;

/**
 * __useGetDocumentHistoryQuery__
 *
 * To run a query within a React component, call `useGetDocumentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentHistoryQuery, GetDocumentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentHistoryQuery, GetDocumentHistoryQueryVariables>(GetDocumentHistoryDocument, options);
      }
export function useGetDocumentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentHistoryQuery, GetDocumentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentHistoryQuery, GetDocumentHistoryQueryVariables>(GetDocumentHistoryDocument, options);
        }
export type GetDocumentHistoryQueryHookResult = ReturnType<typeof useGetDocumentHistoryQuery>;
export type GetDocumentHistoryLazyQueryHookResult = ReturnType<typeof useGetDocumentHistoryLazyQuery>;
export type GetDocumentHistoryQueryResult = Apollo.QueryResult<GetDocumentHistoryQuery, GetDocumentHistoryQueryVariables>;
export const ListInboxDocumentsDocument = gql`
    query listInboxDocuments($limit: Int, $after: String, $filters: DocumentFilterInput, $sort: SortInput, $withPurchaseOrderData: Boolean = false, $query: String) {
  listInboxDocuments(
    limit: $limit
    after: $after
    filters: $filters
    sort: $sort
    query: $query
  ) @connection(key: "inboxDocumentsPagination", filter: ["filters", "sort", "query"]) {
    edges {
      cursor
      node {
        isDuplicate
        ...DocumentListData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${DocumentListDataFragmentDoc}
${TagFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useListInboxDocumentsQuery__
 *
 * To run a query within a React component, call `useListInboxDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInboxDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInboxDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useListInboxDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ListInboxDocumentsQuery, ListInboxDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInboxDocumentsQuery, ListInboxDocumentsQueryVariables>(ListInboxDocumentsDocument, options);
      }
export function useListInboxDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInboxDocumentsQuery, ListInboxDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInboxDocumentsQuery, ListInboxDocumentsQueryVariables>(ListInboxDocumentsDocument, options);
        }
export type ListInboxDocumentsQueryHookResult = ReturnType<typeof useListInboxDocumentsQuery>;
export type ListInboxDocumentsLazyQueryHookResult = ReturnType<typeof useListInboxDocumentsLazyQuery>;
export type ListInboxDocumentsQueryResult = Apollo.QueryResult<ListInboxDocumentsQuery, ListInboxDocumentsQueryVariables>;
export const GetInboxInvoiceDocumentsDocument = gql`
    query getInboxInvoiceDocuments($limit: Int, $after: String, $filters: InboxInvoiceDocumentsFilterInput, $sort: SortInput, $withPurchaseOrderData: Boolean = false, $withAccountsReceivableNumber: Boolean = false, $query: String, $searchInput: SearchInput) {
  inboxInvoiceDocuments(
    limit: $limit
    after: $after
    filters: $filters
    sort: $sort
    query: $query
    searchInput: $searchInput
  ) @connection(key: "inboxDocumentsPaginationV2", filter: ["filters", "sort", "query", "searchInput"]) {
    edges {
      cursor
      node {
        ...InboxInvoiceDocumentData
      }
      highlights {
        field
        chunks {
          text
          matches {
            offset
            length
          }
        }
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
}
    ${InboxInvoiceDocumentDataFragmentDoc}
${PageInfoDataFragmentDoc}`;

/**
 * __useGetInboxInvoiceDocumentsQuery__
 *
 * To run a query within a React component, call `useGetInboxInvoiceDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxInvoiceDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxInvoiceDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      withPurchaseOrderData: // value for 'withPurchaseOrderData'
 *      withAccountsReceivableNumber: // value for 'withAccountsReceivableNumber'
 *      query: // value for 'query'
 *      searchInput: // value for 'searchInput'
 *   },
 * });
 */
export function useGetInboxInvoiceDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetInboxInvoiceDocumentsQuery, GetInboxInvoiceDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInboxInvoiceDocumentsQuery, GetInboxInvoiceDocumentsQueryVariables>(GetInboxInvoiceDocumentsDocument, options);
      }
export function useGetInboxInvoiceDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInboxInvoiceDocumentsQuery, GetInboxInvoiceDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInboxInvoiceDocumentsQuery, GetInboxInvoiceDocumentsQueryVariables>(GetInboxInvoiceDocumentsDocument, options);
        }
export type GetInboxInvoiceDocumentsQueryHookResult = ReturnType<typeof useGetInboxInvoiceDocumentsQuery>;
export type GetInboxInvoiceDocumentsLazyQueryHookResult = ReturnType<typeof useGetInboxInvoiceDocumentsLazyQuery>;
export type GetInboxInvoiceDocumentsQueryResult = Apollo.QueryResult<GetInboxInvoiceDocumentsQuery, GetInboxInvoiceDocumentsQueryVariables>;
export const ListExportDocumentsCountDocument = gql`
    query listExportDocumentsCount {
  listExportDocuments {
    count
  }
}
    `;

/**
 * __useListExportDocumentsCountQuery__
 *
 * To run a query within a React component, call `useListExportDocumentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExportDocumentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExportDocumentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useListExportDocumentsCountQuery(baseOptions?: Apollo.QueryHookOptions<ListExportDocumentsCountQuery, ListExportDocumentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListExportDocumentsCountQuery, ListExportDocumentsCountQueryVariables>(ListExportDocumentsCountDocument, options);
      }
export function useListExportDocumentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListExportDocumentsCountQuery, ListExportDocumentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListExportDocumentsCountQuery, ListExportDocumentsCountQueryVariables>(ListExportDocumentsCountDocument, options);
        }
export type ListExportDocumentsCountQueryHookResult = ReturnType<typeof useListExportDocumentsCountQuery>;
export type ListExportDocumentsCountLazyQueryHookResult = ReturnType<typeof useListExportDocumentsCountLazyQuery>;
export type ListExportDocumentsCountQueryResult = Apollo.QueryResult<ListExportDocumentsCountQuery, ListExportDocumentsCountQueryVariables>;